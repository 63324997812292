import { useLocalStorageState } from 'ahooks';
import { Alert } from 'antd';
import moment from 'moment-timezone';
import { useCallback } from 'react';

interface MaintenanceAnnouncementProps {
  /**
   * 开始维护时的北京日期时间。北京日期时间和北美太平洋日期时间需要至少传入一个。
   *
   * Beijing date time when maintenance starts. Beijing date time and PST date time need to be passed into at least one.
   *
   * @example 2024/12/1 2PM
   */
  beijingDateTime?: string;
  /**
   * 开始维护的北美太平洋日期时间。北京日期时间和北美太平洋日期时间需要至少传入一个。
   *
   * PST date time when maintenance starts. Beijing date time and PST date time need to be passed into at least one.
   *
   * @example 2024/12/1 2PM
   */
  pstDateTime?: string;
  /**
   * 日期时间解析模板。
   *
   * Date time parse template.
   *
   * @default 'YYYY/MM/D hA'
   */
  dateTimeParseTemplate?: string;
  /**
   * 日期时间格式化模板。
   *
   * Date time format template.
   *
   * @default same year 'MM/D hA'
   *
   * @default not same year 'YYYY/MM/D hA'
   */
  dateTimeFormatTemplate?: string;
  /**
   * 维护持续时长。
   *
   * Maintenance duration.
   *
   * @example 2 hours { hours: 2 }
   *
   * @example 1 day 1 hour { days: 1, hours: 1 }
   */
  duration: moment.DurationInputObject;
}

const defaultDateTimeParseTemplate = 'YYYY/MM/D hA';
const defaultSameYearDateTimeFormatTemplate = 'MM/D hA';
const defaultNotSameYearDateTimeFormatTemplate = 'YYYY/MM/D hA';
const beijingTimezone = 'Asia/Shanghai';
const pstTimezone = 'America/Los_Angeles';

const MaintenanceAnnouncement = ({
  beijingDateTime,
  pstDateTime,
  duration,
  dateTimeParseTemplate = defaultDateTimeParseTemplate,
  dateTimeFormatTemplate,
}: MaintenanceAnnouncementProps) => {
  // 至少传入一个日期时间
  if (!beijingDateTime && !pstDateTime) {
    throw new Error('Prop beijingDateTime or pstDateTime is required.');
  }
  // 必须传入持续时长
  if (!duration) {
    throw new Error('Prop duration is required.');
  }

  // 将日期时间转换成内部变量并进行相关计算
  let _beijingDateTime: moment.Moment;
  let _pstDateTime: moment.Moment;
  if (beijingDateTime) {
    _beijingDateTime = moment.tz(
      beijingDateTime,
      dateTimeParseTemplate,
      beijingTimezone,
    );
    _pstDateTime = _beijingDateTime.clone().tz(pstTimezone);
  } else if (pstDateTime) {
    _pstDateTime = moment.tz(pstDateTime, dateTimeParseTemplate, pstTimezone);
    _beijingDateTime = _pstDateTime.clone().tz(beijingTimezone);
  }

  // 将持续时长转换成内部变量并进行相关计算
  const _duration = moment.duration(duration);
  const _durationArray: string[] = [];
  if (_duration.days()) {
    const days = _duration.days();
    _durationArray.push(`${days} ${days === 1 ? 'day' : 'days'}`);
  }
  if (_duration.hours()) {
    const hours = _duration.hours();
    _durationArray.push(`${hours} ${hours === 1 ? 'hour' : 'hours'}`);
  }
  if (_duration.minutes()) {
    const minutes = _duration.minutes();
    _durationArray.push(`${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`);
  }
  const _durationText = _durationArray.join(' '); // 直接使用 format 可能会出现 undefined

  // 是否同年，如果不同年，默认将年份也显示出来
  // @ts-expect-error ts don't know assigned because of low version.
  const isSameYear = moment().isSame(_pstDateTime, 'year');
  const _dateTimeFormatTemplate =
    dateTimeFormatTemplate ??
    (isSameYear
      ? defaultSameYearDateTimeFormatTemplate
      : defaultNotSameYearDateTimeFormatTemplate);

  // 记录关闭状态
  // @ts-expect-error ts don't know assigned because of low version.
  const storageKey = `maintenance-announcement-${_pstDateTime.format()}-${_durationText}-closed`;
  const [closed, setClosed] = useLocalStorageState(storageKey, {
    defaultValue: false,
  });
  const handleClose = useCallback(() => {
    setClosed(true);
  }, [setClosed]);

  // 计算是否在维护期开始后
  // @ts-expect-error ts don't know assigned because of low version.
  const timedOut = moment.tz('America/Los_Angeles').isSameOrAfter(_pstDateTime);

  // @ts-expect-error ts don't know assigned because of low version.
  const message = `The system will undergo maintenance on ${_beijingDateTime.format(
    _dateTimeFormatTemplate,
    // @ts-expect-error ts don't know assigned because of low version.
  )} Beijing Time / ${_pstDateTime.format(
    _dateTimeFormatTemplate,
  )} PST, estimated to last ${_durationText}. Thank you for your support!`;

  // 手动关闭，或已经完成维护，不再显示对应的提示
  if (closed || timedOut) {
    return null;
  }

  return (
    <Alert
      message={message}
      type="info"
      showIcon
      banner
      closable
      onClose={handleClose}
    ></Alert>
  );
};

export default MaintenanceAnnouncement;
