import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Divider,
  Drawer,
  Form,
  Input,
  message,
  Modal,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import React, { useState } from 'react';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { get, has } from 'lodash';
import { useForm } from 'antd/lib/form/Form';
import { DrawerAutoSend } from './components/DrawerAutoSend';
import { DrawerSendDetail } from './components/DrawerSendDetail';
import AutoResizeTable from '@/components/AutoResizeTable';
import { history } from 'umi';
import usePagination from '@/components/usePagination';
import Filter from './components/Filter';
import moment from 'moment';
import { SellRateCompleteButton } from './components/SellRateCompleteButton';
import RequestedRateDrawer from './components/RequestedRateDrawer';

const STATE_COMPLETED = 'completed';
const BUYRATE_PATHNAME = '/rates/buyRates';

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const pagination = usePagination(data);

  const [isModalVisible, setIsModalVisible] = useState<boolean | undefined>(
    false,
  );
  const [newRequestRate, setNewRequestRate] = useState<boolean>(false);

  const [form] = useForm();
  const [filter] = useForm();
  const [rejectId, setRejectId] = useState<number | null>(null);
  const [showAutoSend, setShowAutoSend] = useState(false);
  const [showSendDetail, setShowSendDetail] = useState(false);
  const [selectedRequestedRates, setSelectedRequestedRates] = useState<any>([]);
  const [isRemind, setIsRemind] = useState(false);
  const [record, setRecord] = useState();

  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  // rowSelection object indicates the need for row selection
  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
  };

  const fetchData = async (pagination?: TablePaginationConfig) => {
    setLoading(true);
    try {
      const result = await app.service.get('requestedRates', {
        params: {
          ...filter.getFieldsValue(),
          page: pagination?.current || 1,
          per_page: pagination?.pageSize || 20,
        },
      });
      setData(result);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const handleSellRateComlete = async (id: number) => {
    setLoading(true);
    try {
      await app.service.post(`requestedRates/${id}/sellRateComplete`);
      fetchData(pagination);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const handleBuyRateComlete = async (id: number) => {
    setLoading(true);
    try {
      await app.service.post(`requestedRates/${id}/buyRateComplete`);
      fetchData(pagination);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const handleReject = async (values: any) => {
    setLoading(true);
    try {
      await app.service.put(`requestedRates/${rejectId}/reject`, {
        data: values,
      });
      fetchData(pagination);
      setIsModalVisible(false);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const reject = (id: number) => {
    setRejectId(id);
    setIsModalVisible(true);
  };

  const onOpenAutoSend = () => {
    if (selectedRows.length <= 0) {
      return message.error('Please select data');
    }

    const intermodalRegionId = selectedRows[0].intermodal_region_id;

    if (
      selectedRows.find(
        (record: any) => record.intermodal_region_id != intermodalRegionId,
      )
    ) {
      return message.error(
        'Only the same intermodal Region can send emails in batches',
      );
    }

    setShowAutoSend(true);
    setSelectedRequestedRates(selectedRows);
  };

  const openRemind = (record: any) => {
    setShowAutoSend(true);
    setIsRemind(true);
    setSelectedRequestedRates([record]);
  };

  const handleOnSent = () => {
    onCloseAutoSend();
    fetchData(pagination);
  };

  const onCloseAutoSend = () => {
    setSelectedRowKeys([]);
    setSelectedRows([]);
    setSelectedRequestedRates([]);
    setIsRemind(false);
    setShowAutoSend(false);
  };

  const sendDetail = (record: any) => {
    setRecord(record);
    setShowSendDetail(true);
  };

  const onCloseSendDetail = () => {
    setRecord(undefined);
    setShowSendDetail(false);
  };

  const handleViewBuyRate = async (record: any) => {
    await app.store.search.setToCity(record.to_city);
    await app.store.search.setIntermodalRegionId(record.intermodal_region_id);

    return history.push({
      pathname: BUYRATE_PATHNAME,
    });
  };

  const addNew = () => {
    form.resetFields();
    form.setFieldsValue({
      billing_interval: 'monthly',
      enabled: true,
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 80,
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      width: 200,
      render: (text, record) => <>{get(record, 'user.name', 'UNKNOWN')}</>,
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      width: 200,
    },
    {
      title: 'View',
      dataIndex: 'view',
      key: 'view',
      width: 200,
      render: (text, record) => (
        <>
          {record.buy_rate_completed_at && (
            <Typography.Link onClick={() => handleViewBuyRate(record)}>
              View
            </Typography.Link>
          )}
        </>
      ),
    },
    {
      title: 'Sell Rate',
      dataIndex: ['sell_rate', 'uid'],
      key: 'sell_rate_id',
      width: 100,
    },
    {
      title: 'Reject reason',
      dataIndex: 'rejected_reason',
      key: 'rejected_reason',
      width: 200,
    },
    {
      title: 'Intermodal Region',
      dataIndex: 'intermodal_region',
      key: 'intermodal_region',
      width: 200,
      render: (text, record) => (
        <>{get(record, 'intermodal_region.name', '')}</>
      ),
    },
    {
      title: 'Warehouse City',
      dataIndex: 'to_city',
      key: 'to_city',
      width: 230,
      render: (text, record) => {
        return (
          <div>
            {get(record, 'to_city.full_name', '')}
            {has(record, 'last_duplicate.created_at') && (
              <Tag color="red" style={{ fontSize: '11px' }}>
                The last rate requested was in{' '}
                {moment(get(record, 'last_duplicate.created_at')).format(
                  'YYYY-MM-DD',
                )}
              </Tag>
            )}
          </div>
        );
      },
    },
    {
      title: 'Warehouse Zipcode',
      dataIndex: 'to_zipcode',
      key: 'to_zipcode',
      width: 200,
    },
    {
      title: 'CNTR Size',
      dataIndex: 'cntr_size',
      key: 'cntr_size',
      width: 80,
    },
    {
      title: 'Input / Sent Count',
      dataIndex: 'input_sent_count',
      key: 'input_sent_count',
      width: 200,
      render: (text, record) => (
        <>
          {get(record, 'input_count', 0)} / {get(record, 'sent_count', 0)}
        </>
      ),
    },
    {
      title: 'Memo',
      dataIndex: 'memo',
      key: 'memo',
      width: 200,
    },
    {
      title: 'Operator',
      dataIndex: 'operator',
      key: 'operator',
      width: 200,
      render: (text, record) => <>{get(record, 'operator.username', '')}</>,
    },
    {
      title: 'BR Completed At',
      dataIndex: 'buy_rate_completed_at',
      key: 'buy_rate_completed_at',
      width: 200,
    },
    {
      title: 'SR Completed At',
      dataIndex: 'sell_rate_completed_at',
      key: 'sell_rate_completed_at',
      width: 200,
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 200,
    },
    {
      title: 'Updated At',
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: 200,
    },
    {
      align: 'center',
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 550,
      render: (text, record) => (
        <Space>
          <Button type="ghost" size="small" onClick={() => sendDetail(record)}>
            Send Detail
          </Button>
          <Divider type="vertical" />
          <Button type="ghost" size="small" onClick={() => openRemind(record)}>
            Remind
          </Button>
          <Divider type="vertical" />
          <Button
            disabled={
              (record.state !== 'created' && record.state !== 'rfq sent') ||
              record.buy_rate_completed_at
            }
            size="small"
            type="primary"
            onClick={() => handleBuyRateComlete(record.id)}
          >
            BR Complete
          </Button>
          {/* <Button
            disabled={
              (record.state !== 'created' && record.state !== 'rfq sent') ||
              record.sell_rate_completed_at
            }
            size="small"
            type="default"
            onClick={() => handleSellRateComlete(record.id)}>
            SR Complete
          </Button> */}
          <SellRateCompleteButton
            requestedRate={record}
            setLoading={(loading: boolean) => setLoading(loading)}
            onCompleted={fetchData}
          />
          <Divider type="vertical" />
          <Button
            disabled={record.state !== 'created'}
            size="small"
            danger
            onClick={() => reject(record.id)}
          >
            Reject
          </Button>
        </Space>
      ),
    },
  ];

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.main}>
      <Header
        title="Rate Requests"
        rightElement={
          <Space>
            <Button size="small" type="primary" onClick={onOpenAutoSend}>
              Auto Send
            </Button>
            <Button
              size="small"
              type="primary"
              onClick={() => setNewRequestRate(true)}
            >
              New Request Rate
            </Button>
          </Space>
        }
      ></Header>

      <div className={styles.filter}>
        <Filter filter={filter} onSubmit={fetchData} loading={loading} />
      </div>
      <div>
        <AutoResizeTable
          loading={loading}
          pagination={pagination}
          size="small"
          rowKey="id"
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          onChange={(pagination) => {
            fetchData(pagination);
          }}
          columns={columns}
          dataSource={data?.data || []}
          scroll={{ x: 1500 }}
          sticky
        />
      </div>
      <Modal
        footer={false}
        onCancel={() => setIsModalVisible(false)}
        destroyOnClose
        open={isModalVisible}
      >
        <Form form={form} layout="vertical" onFinish={handleReject}>
          <Form.Item
            name={'reason'}
            rules={[{ required: true }]}
            label="Reason"
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item label=" " colon={false}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {showAutoSend && selectedRequestedRates?.length > 0 && (
        <DrawerAutoSend
          visible={showAutoSend}
          rates={selectedRequestedRates}
          isRemind={isRemind}
          onClose={onCloseAutoSend}
          onSent={handleOnSent}
        />
      )}

      <Drawer
        title="Send Detail"
        placement="right"
        width="90%"
        onClose={onCloseSendDetail}
        destroyOnClose={true}
        open={showSendDetail}
        extra={
          <Space>
            <Button onClick={onCloseSendDetail}>Close</Button>
          </Space>
        }
      >
        <DrawerSendDetail record={record} />
      </Drawer>

      <RequestedRateDrawer
        open={newRequestRate}
        onClose={() => setNewRequestRate(false)}
        onSaved={() => {
          fetchData();
          setNewRequestRate(false);
        }}
      />
    </div>
  );
};

export default Index;
