import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Form,
  Input,
  Select,
  Drawer,
  Tag,
  Switch,
  message,
  Divider,
  TablePaginationConfig,
} from 'antd';
import { SearchOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import CSFForm from './components/CSFForm';
import styles from 'res/css/ui.scss';
import { Header } from '@/components/CommonHeader';
import { useApp } from '@/utils/useapp';
import { ColumnType } from 'antd/es/table';
import { TCollection } from '@/types';
import { TCFSLocation } from './interface';
import AutoResizeTable from '@/components/AutoResizeTable';
import usePagination from '@/components/usePagination';
import { CFSFacilityType, CFSFacilityTypeLabelMap } from '../constants';
const CFS = () => {
  const [searchForm] = Form.useForm();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedCFS, setSelectedCFS] = useState<TCFSLocation | null>(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<TCollection<TCFSLocation>>();

  const pagination = usePagination(data);
  const app = useApp();

  const handleNewCFS = () => {
    setSelectedCFS(null); // Reset selected CFS
    setDrawerVisible(true);
  };

  const handleEditCFS = (record: TCFSLocation) => {
    setSelectedCFS(record);
    setDrawerVisible(true);
  };

  const getData = useCallback(async (pagination?: TablePaginationConfig) => {
    setLoading(true);
    try {
      const resp = await app.service.get('cfs/cfsLocations', {
        params: {
          ...searchForm.getFieldsValue(),
          page: pagination?.current || 1,
          per_page: pagination?.pageSize || 20,
        },
      });
      setData(resp);
    } catch (error) {
      message.error('Failed to get data');
    } finally {
      setLoading(false);
    }
  }, []);

  const handleCloseDrawer = () => {
    setDrawerVisible(false);
    setSelectedCFS(null);
  };

  const handleChangeStatus = async (record: TCFSLocation, value: boolean) => {
    setLoading(true);
    try {
      await app.service.patch(`cfs/cfsLocations/${record.id}/enabled`, {
        data: {
          enabled: value,
        },
      });
      getData(pagination);
    } catch (error) {
      message.error('Failed to change enabled');
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (values: any) => {
    setLoading(true);
    try {
      const resp = await app.service.get('cfs/cfsLocations', {
        params: values,
      });
      setData(resp);
    } catch (error) {
      message.error('Failed to search');
    } finally {
      setLoading(false);
    }
  };

  const handleSuccess = () => {
    handleCloseDrawer();
    getData(pagination);
  };

  useEffect(() => {
    getData();
  }, [getData]);

  const columns: ColumnType<TCFSLocation>[] = [
    { title: 'FirmsCode', dataIndex: 'firms_code', key: 'firms_code' },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    {
      title: 'Facility Type',
      dataIndex: 'facility_type',
      key: 'facility_type',
      render: (_, record) =>
        CFSFacilityTypeLabelMap[record.facility_type as CFSFacilityType],
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      render: (_, record) =>
        `${record.address1 || ''} ${record.address2 || ''} ${
          record.city?.name || ''
        }, ${record.city?.state || ''} ${record.zipcode || ''}`,
    },
    {
      title: 'Verified',
      dataIndex: 'verified',
      key: 'verified',
      render: (_, record) => (
        <Tag color={record.verified ? 'green' : 'red'}>
          {record.verified ? 'Yes' : 'No'}
        </Tag>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'enabled',
      key: 'enabled',
      render: (_, record) => (
        <Switch
          checked={record.enabled}
          onChange={(value) => handleChangeStatus(record, value)}
        />
      ),
    },
    {
      title: 'Track Link',
      key: 'trackLink',
      render: (_, record) => (
        <Button
          type="link"
          disabled={!record.track_link}
          onClick={() => {
            window.open(record.track_link, '_blank');
          }}
        >
          View
        </Button>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => handleEditCFS(record)}
          icon={<EditOutlined />}
        >
          Edit
        </Button>
      ),
    },
  ];

  return (
    <div className={styles.main}>
      <Header
        title="CFS Locations"
        rightElement={
          <Button type="primary" icon={<PlusOutlined />} onClick={handleNewCFS}>
            New CFS Location
          </Button>
        }
      />
      <div className={styles.filter}>
        <div
          style={{
            width: '100%',
          }}
        >
          <Form
            form={searchForm}
            onFinish={handleSearch}
            layout="inline"
            className="search-form"
          >
            <Form.Item name="firms_code">
              <Input placeholder="FirmsCode" allowClear />
            </Form.Item>
            <Form.Item name="name">
              <Input placeholder="Name" allowClear />
            </Form.Item>
            <Form.Item name="zipcode">
              <Input placeholder="Zipcode" allowClear />
            </Form.Item>
            <Form.Item name="enabled">
              <Select placeholder="Status" allowClear>
                <Select.Option value={1}>Enabled</Select.Option>
                <Select.Option value={0}>Disabled</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="facility_type">
              <Select
                mode="multiple"
                allowClear
                style={{ width: '400px' }}
                placeholder="Type"
              >
                {Object.keys(CFSFacilityTypeLabelMap).map((key) => (
                  <Select.Option key={key} value={key}>
                    {
                      CFSFacilityTypeLabelMap[
                        (key as unknown) as keyof typeof CFSFacilityTypeLabelMap
                      ]
                    }
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Button type="primary" icon={<SearchOutlined />} htmlType="submit">
              Search
            </Button>
            <Button htmlType="reset">Clear All</Button>
          </Form>
        </div>
      </div>
      <Divider />
      {/* CFS Table */}
      <AutoResizeTable
        size="small"
        rowKey="id"
        loading={loading}
        columns={columns}
        dataSource={data?.data}
        pagination={pagination}
        onChange={getData}
      />

      {/* CFS Form Drawer */}
      <Drawer
        title={selectedCFS ? 'Update CFS' : 'New CFS'}
        width={1200}
        onClose={handleCloseDrawer}
        open={drawerVisible}
        destroyOnClose
      >
        <CSFForm
          cfsId={selectedCFS?.id}
          initialData={selectedCFS}
          onSuccess={handleSuccess}
        />
      </Drawer>
    </div>
  );
};

export default CFS;
