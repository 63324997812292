import React from 'react';
import { Space, Tooltip } from 'antd';
import { TagBadge } from '@/pages/orders/components/OrderIdColumn';
import { FaHandHoldingDollar } from 'react-icons/fa6';

import {
  STAY_IN_WAREHOUSE,
  TBD,
} from '@/components/ContainerTable/components/data';
import { HAZMAT_CLASS_MAP } from './ContainerTable/components/Attribute/data';

const CntrAttributeBadge: React.FC<{
  order_is_prepaid: boolean;
  container: any;
}> = ({ order_is_prepaid, container }) => {
  const list = [];

  let title = '';
  if (container.is_soc) {
    if (container?.soc_return_location_id == -1) {
      title = STAY_IN_WAREHOUSE;
    } else if (
      container?.soc_return_location_id === 0 ||
      container?.soc_return_location_id === null
    ) {
      title = TBD;
    } else {
      title = container.soc_return_location?.address || '';
    }
  }

  let dgTitle = '';
  if (container.is_dg) {
    dgTitle = container?.hazmat_classes
      ?.map(
        (item: string) =>
          HAZMAT_CLASS_MAP[
            (item as unknown) as keyof typeof HAZMAT_CLASS_MAP
          ] || item,
      )
      ?.join(', ');
  }

  container.is_soc &&
    list.push(
      <Tooltip title={title}>
        <span>
          <TagBadge key="soc" text="SOC" />
        </span>
      </Tooltip>,
    );
  container.is_dg &&
    list.push(
      <Tooltip title={dgTitle}>
        <span>
          <TagBadge key="dg" text="DG" />
        </span>
      </Tooltip>,
    );
  container.is_overweight && list.push(<TagBadge key="bd" text="OW" />);
  container.is_bonded && list.push(<TagBadge key="ow" text="BD" />);
  container.is_reefer && list.push(<TagBadge key="reefer" text="REFFER" />);
  container.is_multi_deliveries && list.push(<TagBadge key="md" text="MD" />);
  container.transload && list.push(<TagBadge key="md" text="TL" />);
  container.is_out_of_gauge && list.push(<TagBadge key="oog" text="OOG" />);
  order_is_prepaid &&
    list.push(
      <Tooltip title="Prepaid">
        <span className="text-primary">
          <FaHandHoldingDollar />
        </span>
      </Tooltip>,
    );

  return (
    <Space direction="horizontal" size={4} wrap>
      {list}
    </Space>
  );
};

export default CntrAttributeBadge;
