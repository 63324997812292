import { TContainer } from '@/types';
import { Modal, Tag, Form, DatePicker } from 'antd';
import React, { FC, useMemo } from 'react';
import ExtraCostTable from './ExtraCostTable';
import moment from 'moment';
import { get } from 'lodash';

export const EditableContainerExtraCosts: FC<{
  container: TContainer;
  color?: string;
  isShowDetailText?: boolean;
  customizeText?: string;
  onUpdated: (id: number, data: any[]) => void;
  setOpen?: (open: boolean) => void;
}> = ({
  container,
  color = 'green',
  isShowDetailText = false,
  customizeText = null,
  onUpdated,
  setOpen,
}) => {
  const [show, setShow] = React.useState(false);
  const costs = container.extra_costs;

  const text = useMemo(() => {
    if (customizeText) {
      return customizeText;
    }

    return costs && costs.length > 0 ? (
      isShowDetailText ? (
        <>
          {costs
            .map(
              (cost: any) =>
                get(cost, 'billing_code.name') + ': ' + cost.amount,
            )
            ?.join(', ')}
        </>
      ) : (
        <>
          {get(costs[0], 'billing_code.name')}
          {costs.length > 1 && <>+{costs.length - 1}</>}
        </>
      )
    ) : (
      ''
    );
  }, [isShowDetailText, customizeText]);

  const handleShow = (_show: boolean) => {
    setShow(_show);
    setOpen && setOpen(_show);
  };
  return (
    <>
      <div className="cursor-pointer" onClick={() => handleShow(true)}>
        {text ? <Tag color={color}>{text}</Tag> : '-'}
      </div>
      {show && (
        <Modal
          width={1000}
          title="Extra Costs"
          footer={false}
          open={show}
          onCancel={() => handleShow(false)}>
          <ExtraCostTable
            container={container}
            onUpdated={(data: any) => onUpdated(container.id || 0, data)}
          />
        </Modal>
      )}
    </>
  );
};
