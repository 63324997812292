import { CODE_CLEAN_TRUCK_FEE } from '../../data';
import { isInIgnoreScacCodes } from '@/utils/oceanCarrier';

// export const CHASSIS_FEE_IGNORE_SCAC_CODES = ['EGLV', 'MEDU'];

export const BILLING_CODE_PIERPASS = 'PIEPA';
export const BILLING_CODE_CLEAN_TRUCK_FEE = 'CLETR';
export const BILLING_CODE_GATE_FEE = 'GATFE';
export const BILLING_CODE_SECURITY_FEE = 'PORSE';
export const BILLING_CODE_PORT_USAGE_FEE = 'PRTUG';

export const BILLING_CODE_CHASP = 'CHASP';
export const BILLING_CODE_CHAFL = 'CHAFL';
export const BILLING_CODE_FUESU = 'FUESU';
export const BILLING_CODE_TRUCH = 'TRUCH';
export const BILLING_CODE_CHAFE = 'CHAFE';
export const BILLING_CODE_PRECH = 'PRECH';
export const BILLING_CODE_YARST = 'YARST';
export const BILLING_CODE_OTHCH = 'OTHCH';
export const BILLING_CODE_DEMCH = 'DEMCH';
export const BILLING_CODE_RAIST = 'RAIST';
export const BILLING_CODE_PERDI = 'PERDI';
export const BILLING_CODE_TOLFE = 'TOLFE';
export const BILLING_CODE_EXAFE = 'EXAFE';
export const BILLING_CODE_DRYRU = 'DRYRU';
export const BILLING_CODE_TERWA = 'TERWA';
export const BILLING_CODE_OVECH = 'OVECH';
export const BILLING_CODE_WARWA = 'WARWA';
export const BILLING_CODE_LAYOV = 'LAYOV';
export const BILLING_CODE_HANCH = 'HANCH';
export const BILLING_CODE_RESDE = 'RESDE';
export const BILLING_CODE_TRIFE = 'TRIFE';
export const BILLING_CODE_STOOF = 'STOOF';
export const BILLING_CODE_HAZPREM = 'HAZPREM';
export const BILLING_CODE_RUSH = 'RUSH';
export const BILLING_CODE_WKNDHD = 'WKNDHD';
export const BILLING_CODE_HAZCA = 'HAZCA';
export const BILLING_CODE_REESU = 'REESU';
export const BILLING_CODE_REVOR = 'REVOR';
export const BILLING_CODE_DROPF = 'DROPF';

export const REMIND_POSSIBLE_FLIP = 'Possible Flip Fee';
export const REMIND_POSSIBLE_PREPULL = 'Possible Prepull';
export const REMIND_POSSIBLE_YARD_STORAGE = 'Possible Yard Storage';
export const REMIND_POSSIBLE_WHS_DETENTION = 'Possible WHS Detention';
export const REMIND_POSSIBLE_TERMINAL_DETENTION = 'Possible Terminal Detention';
export const REMIND_POSSIBLE_TERMINAL_FEE = 'Possible Terminal Fees';
export const REMIND_POSSIBLE_OW = 'Possible OW Fees';
export const REMIND_POSSIBLE_TRIX_AXLE_FEES = 'Possible Tri-axle Fees';

export const REMIND_POSSIBLE_DROP_FEE = 'Possible Drop Fee';
export const REMIND_POSSIBLE_CHASSIS_SPLIT_FEE = 'Possible Split Fee';
export const REMIND_POSSIBLE_DG = 'Possible DG';
export const REMIND_POSSIBLE_RESIDENTIAL_DELIVERY_FEE =
  'Possible Residential Delivery Fee';
export const REMIND_POSSIBLE_REEFER_SURCHARGE = 'Possible Reefer Surcharge';
export const REMIND_POSSIBLE_TOLL = 'Possible Toll';
export const REMIND_POSSIBLE_NYCT_TOLL = 'Possible NYCT Toll';
export const REMIND_POSSIBLE_DRY_RUN = 'Possible Dry Run';
export const REMIND_POSSIBLE_STOP_OFF = 'Possible Stop Off';
export const REMIND_POSSIBLE_HAZMAT_PLACARD_REMOVAL =
  'Possible HAZMAT PLACARD REMOVAL';
export const REMIND_POSSIBLE_RE_DELIVERY_FEE = 'Possible Re-delivery fee';
export const REMIND_POSSIBLE_RUSH_DO_FEE = 'Possible Rush DO Fee';
export const REMIND_POSSIBLE_CANCELLATION_FEE = 'Possible Cancellation Fee';
export const REMIND_POSSIBLE_WEEKEND_SURCHARGE = 'Possible Weekend Surcharge';
export const REMIND_POSSIBLE_PER_DIEM = 'Possible Per Diem';
export const REMIND_POSSIBLE_EXAM_FEE = 'Possible Exam Fee';
export const REMIND_POSSIBLE_TERMINAL_WAITING_TIME_DETENTION =
  'Possible Terminal Waiting Time (Detention)';
export const REMIND_POSSIBLE_ADMIN_FEE = 'Possible Admin Fee';
export const REMIND_POSSIBLE_OTHER = 'Possible Other';

export const REMIND_POSSIBLE_FEE = 'Possible Fees';
export const REMIND_POSSIBLE_DEMURRAGE_CHARGES_FEE =
  'Possible Demurrage Charges fees';
export const REMIND_POSSIBLE_RAIL_STORAGE_FEE = 'Possible Rail Storage fees';

export const BILLING_CODE_REMIND_MAP = {
  [BILLING_CODE_CHASP]: REMIND_POSSIBLE_CHASSIS_SPLIT_FEE,
  [BILLING_CODE_CHAFL]: REMIND_POSSIBLE_FLIP,
  [BILLING_CODE_PRECH]: REMIND_POSSIBLE_PREPULL,
  [BILLING_CODE_YARST]: REMIND_POSSIBLE_YARD_STORAGE,
  [BILLING_CODE_WARWA]: REMIND_POSSIBLE_WHS_DETENTION,
  [BILLING_CODE_TERWA]: REMIND_POSSIBLE_TERMINAL_DETENTION,
  [BILLING_CODE_OVECH]: REMIND_POSSIBLE_OW,
  [BILLING_CODE_TRIFE]: REMIND_POSSIBLE_TRIX_AXLE_FEES,
  [BILLING_CODE_STOOF]: REMIND_POSSIBLE_STOP_OFF,
  [BILLING_CODE_HAZCA]: REMIND_POSSIBLE_DG,
  [BILLING_CODE_RESDE]: REMIND_POSSIBLE_RESIDENTIAL_DELIVERY_FEE,
  [BILLING_CODE_REESU]: REMIND_POSSIBLE_REEFER_SURCHARGE,
  [BILLING_CODE_TOLFE]: REMIND_POSSIBLE_TOLL,
  [BILLING_CODE_DRYRU]: REMIND_POSSIBLE_DRY_RUN,
  [BILLING_CODE_HAZPREM]: REMIND_POSSIBLE_HAZMAT_PLACARD_REMOVAL,
  [BILLING_CODE_DEMCH]: REMIND_POSSIBLE_DEMURRAGE_CHARGES_FEE,
  [BILLING_CODE_RAIST]: REMIND_POSSIBLE_RAIL_STORAGE_FEE,
  [BILLING_CODE_RUSH]: REMIND_POSSIBLE_RUSH_DO_FEE,
  [BILLING_CODE_REVOR]: REMIND_POSSIBLE_CANCELLATION_FEE,
  [BILLING_CODE_WKNDHD]: REMIND_POSSIBLE_WEEKEND_SURCHARGE,
  [BILLING_CODE_OTHCH]: REMIND_POSSIBLE_OTHER,
  [BILLING_CODE_PERDI]: REMIND_POSSIBLE_PER_DIEM,
  [BILLING_CODE_EXAFE]: REMIND_POSSIBLE_EXAM_FEE,
  [BILLING_CODE_HANCH]: REMIND_POSSIBLE_ADMIN_FEE,
  [BILLING_CODE_PIERPASS]: REMIND_POSSIBLE_TERMINAL_FEE,
  [BILLING_CODE_CLEAN_TRUCK_FEE]: REMIND_POSSIBLE_TERMINAL_FEE,
  [BILLING_CODE_GATE_FEE]: REMIND_POSSIBLE_TERMINAL_FEE,
  [BILLING_CODE_SECURITY_FEE]: REMIND_POSSIBLE_TERMINAL_FEE,
  [BILLING_CODE_PORT_USAGE_FEE]: REMIND_POSSIBLE_TERMINAL_FEE,
  [BILLING_CODE_DROPF]: REMIND_POSSIBLE_DROP_FEE,
};

export const formatChassisDaysRemind = (
  mblNumber: string,
  chassisDay: number,
) => {
  if (chassisDay) {
    return `Chassis Day: ${chassisDay}`;
  }

  if (isInIgnoreScacCodes(mblNumber)) {
    const scacCode = mblNumber.substring(0, 4);

    return `No Estimate Chassis (${scacCode})`;
  }

  return '';
};
