import { useApp } from '@/utils/useapp';
import { Button, Drawer, message, Space } from 'antd';
import React from 'react';
import AlgoCard from './AlgoCard';

interface AlgoHistoryProps {
  rateEngineConfigId: number;
  enabledAlgoId: number;
}

const AlgoHistory: React.FC<AlgoHistoryProps> = ({
  rateEngineConfigId,
  enabledAlgoId,
}) => {
  const app = useApp();

  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const resp = await app.service.get('rateEngineAlgos', {
        params: { rate_engine_config_id: rateEngineConfigId },
      });
      setData(resp.data);
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (open) {
      fetchData();
    } else {
      setData([]);
    }
  }, [open]);

  return (
    <>
      <Button size="small" onClick={() => setOpen(!open)}>
        Algo History
      </Button>

      {open && (
        <Drawer
          title="Algo History"
          placement="right"
          onClose={() => setOpen(false)}
          open={open}>
          <>
            <Space direction="vertical">
              {data.map((row: any) => (
                <AlgoCard
                  isActive={!!enabledAlgoId && enabledAlgoId == row.id}
                  key={row.id}
                  algoId={row.id}
                  name={row.name}
                  rateEngineConfigId={rateEngineConfigId}
                  algoFormular={row.formular}
                  footer={`Created At ${row.created_at}`}
                  showAlgoEnableBtn
                />
              ))}
            </Space>
          </>
        </Drawer>
      )}
    </>
  );
};

export default AlgoHistory;
