export const TIER_0 = 0;
export const TIER_1 = 1;
export const TIER_2 = 2;
export const TIER_3 = 3;
export const TIER_4 = 4;

export const TIER_MAP = {
  [TIER_0]: 'Tier 0',
  [TIER_1]: 'Tier 1',
  [TIER_2]: 'Tier 2',
  [TIER_3]: 'Tier 3',
  [TIER_4]: 'Tier 4',
};
