import { Button, Modal, Table, message } from 'antd';
import React, { useEffect } from 'react';
import { useApp } from '@/utils/useapp';
import moment from 'moment';
import type { ColumnsType } from 'antd/es/table/interface';
import { flatMap, get } from 'lodash';
import { OverdueDataList } from './OverdueDataList';
import {
  WHOSE_TASK_CPS,
  WHOSE_TASK_OP,
  WHOSE_TASK_OP_ASSIST,
  WHOSE_TASK_MAP,
} from '@/pages/dispatchs/components/data';
import { FormInstance } from 'antd/es/form';
import { RenderWhoseTaskTag } from '@/pages/dispatchs/components/ActionTasks/RenderTaskColumn/RenderWhoseTaskTag';
import FileSaver from 'file-saver';

const defaultOwnerFilter = {
  text: 'CPS',
  value: WHOSE_TASK_MAP[WHOSE_TASK_CPS],
};

const OverdueReport: React.FC<{
  filter: FormInstance;
}> = ({ filter }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [statistics, setStatistics] = React.useState<any>([]);
  const [originalStatistics, setOriginalStatistics] = React.useState<any>([]);
  const [originalOverdueData, setOriginalOverdueData] = React.useState<any>([]);
  const [overdueDataList, setOverdueDataList] = React.useState<any>([]);
  const [filterOptions, setFilterOptions] = React.useState<any>({});
  const [loading, setLoading] = React.useState(false);
  const [ownerFilters, setOwnerFilters] = React.useState([defaultOwnerFilter]);

  const app = useApp();

  const fetchStatistic = async (_filters?: any, _sorter?: any) => {
    let params = { ...filter };
    if (_filters) {
      params = {
        ...params,
        ..._filters,
      };
    }
    setLoading(true);
    try {
      const resp = await app.service.get('dispatchDashboard/overdue/report', {
        params: {
          ..._filters,
          sort_by: _sorter?.columnKey || _sorter?.field,
          sort_value:
            _sorter?.order === 'ascend'
              ? 'asc'
              : _sorter?.order === 'descend'
                ? 'desc'
                : undefined,
        },
      });

      setOwnerFilters([
        defaultOwnerFilter,
        ...(flatMap(resp.statistics, 'owner')
          ?.filter((o) => o)
          ?.map((owner: any) => ({
            text: owner?.name,
            value: owner?.id,
          })) || []),
      ]);
      setOriginalStatistics(resp.statistics);
      setOriginalOverdueData(resp.data);
    } catch (err: any) {
      message.error(err?.data?.message || 'System Error');
    } finally {
      setLoading(false);
    }
  };

  const filterDept = (dept: any) => {
    if (!filterOptions || !filterOptions.dept) {
      return true;
    }
    return filterOptions.dept?.includes(dept);
  };

  const filterStatisticsItem = (owner: any) => {
    if (!filterOptions || !filterOptions.ownerId) {
      return true;
    }
    return filterOptions.ownerId?.includes(owner?.id);
  };

  const filterDataItem = (item: any) => {
    if (!filterOptions || !filterOptions.ownerId) {
      return true;
    }
    const hasCps = filterOptions.ownerId.includes(
      WHOSE_TASK_MAP[WHOSE_TASK_CPS],
    );

    if (!filterOptions.dept) {
      return (
        filterOptions.ownerId?.includes(get(item, 'order.operator.id')) ||
        filterOptions.ownerId?.includes(
          get(item, 'order.operator_assistant.id'),
        ) ||
        (hasCps && item.whose_task == WHOSE_TASK_CPS)
      );
    }

    for (const i in filterOptions.dept) {
      switch (filterOptions.dept[i]) {
        case WHOSE_TASK_CPS:
          return hasCps && item.whose_task == WHOSE_TASK_CPS;
        case WHOSE_TASK_OP:
          return filterOptions.ownerId?.includes(
            get(item, 'order.operator.id'),
          );
        case WHOSE_TASK_OP_ASSIST:
          return filterOptions.ownerId?.includes(
            get(item, 'order.operator_assistant.id'),
          );
      }
    }

    return false;
  };

  useEffect(() => {
    let _data = originalOverdueData;
    let _statistics = originalStatistics;
    if (filterOptions) {
      _data = _data.filter(
        (item) => filterDept(item.whose_task) && filterDataItem(item),
      );

      _statistics = _statistics.filter(
        (item: any) =>
          filterDept(item.dept) && filterStatisticsItem(item.owner),
      );
    }

    setStatistics(_statistics);
    setOverdueDataList(_data);
  }, [originalOverdueData, originalStatistics, filterOptions]);

  const handleMemoSaved = () => {
    fetchStatistic(filterOptions);
  };

  const handleExport = async (selectedRowKeys: any) => {
    // if (selectedRowKeys?.length <= 0) {
    //   message.error('Please select data to export');
    //   return false;
    // }

    setLoading(true);
    try {
      const resp = await app.service.get('dispatchDashboard/overdue/excel', {
        responseType: 'blob',
        params: {
          ...filter,
          ...filterOptions,
          // ids: selectedRowKeys,
        },
      });
      const file = new Blob([resp]);

      const filename = `OverdueReport_${moment().format('YYYYMMDD_HHmmss')}`;

      FileSaver.saveAs(file, `${filename}.xlsx`);
    } catch (err: any) {
      message.error(err?.data?.message || 'System Error');
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    setFilterOptions({});
    // fetchStatistic();
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setFilterOptions({});
  };

  useEffect(() => {
    isModalOpen && fetchStatistic();
  }, [isModalOpen]);

  const columns: ColumnsType<any> = React.useMemo(
    () => [
      {
        title: 'Dept',
        dataIndex: 'dept',
        key: 'dept',
        filters: Object.keys(WHOSE_TASK_MAP).map((key) => ({
          text: WHOSE_TASK_MAP[key as unknown as keyof typeof WHOSE_TASK_MAP],
          value: +key,
        })),
        render: (text, record, index) => (
          <RenderWhoseTaskTag whoseTask={text} />
        ),
      },
      {
        title: 'Owner',
        dataIndex: ['owner', 'name'],
        key: 'ownerId',
        align: 'center',
        filters: ownerFilters,
      },
      {
        title: 'Count',
        dataIndex: 'count',
        key: 'count',
      },
    ],
    [ownerFilters],
  );

  return (
    <>
      {
        <>
          <Button type="primary" onClick={showModal}>
            Overdue Report
          </Button>
          {isModalOpen && (
            <Modal
              destroyOnClose
              width={'90%'}
              title="Overdue Report"
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={false}>
              <Table
                pagination={false}
                loading={loading}
                onChange={(_pagination, filters, sorter: any) => {
                  setFilterOptions(filters);
                  // fetchStatistic(filters)
                }}
                onRow={(record: any, rowIndex: any) => {
                  return {
                    onClick: (event) => {
                      setFilterOptions({
                        dept: record.dept ? [record.dept] : null,
                        ownerId: record?.owner?.id ? [record.owner.id] : null,
                      });
                    },
                  };
                }}
                columns={columns}
                scroll={{ y: 200 }}
                dataSource={statistics || []}
                size="small"
              />
              <OverdueDataList
                loading={loading}
                data={overdueDataList}
                onMemoSaved={handleMemoSaved}
                onExport={handleExport}
                onReset={handleReset}
                onChange={(pagination, filters, sorter) => {
                  fetchStatistic(filters, sorter);
                }}
              />
            </Modal>
          )}
        </>
      }
    </>
  );
};

export default OverdueReport;
