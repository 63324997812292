import { Weight } from './components/Weight';
import { ContainerTable } from './components/ContainerTable';

export { Weight };

export const validateContainerNumber = (value: string) => {
  const regex = new RegExp(/^[a-zA-Z]{4}\d{7}$/);
  return regex.test(value);
};

export default ContainerTable;
