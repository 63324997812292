import { useApp } from '@/utils/useapp';
import { Table } from 'antd';
import { useEffect, useState } from 'react';

const CustomerSummary = () => {
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState<any[]>([]);
  const app = useApp();

  const fetchCustomerSummary = async () => {
    try {
      setLoading(true);
      const res = await app.service.get('risks/customerSummary', {});
      setSummary(res.data);
    } catch (e: any) {
      console.log(e.error || 'network error');
    }
    setLoading(false);
  };

  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  useEffect(() => {
    fetchCustomerSummary();
  }, []);

  const columns = [
    {
      title: 'Customer User Name',
      dataIndex: 'user_name',
      key: 'user_name',
      sorter: (a: any, b: any) => a.user_name.localeCompare(b.user_name),
    },
    {
      title: 'Customer Company Name',
      dataIndex: 'company_name',
      key: 'company_name',
      sorter: (a: any, b: any) => a.company_name.localeCompare(b.company_name),
    },
    {
      title: 'Sales Name',
      dataIndex: 'sales_name',
      key: 'sales_name',
      sorter: (a: any, b: any) => a.sales_name.localeCompare(b.sales_name),
    },
    {
      title: 'Profit Up to Date',
      dataIndex: 'profit',
      key: 'profit',
      render: (text: any) => {
        return text ? USDollar.format(text) : '-';
      },
      sorter: (a: any, b: any) => a.profit - b.profit,
    },
    {
      title: 'First Order Date',
      dataIndex: 'first_order_date',
      key: 'first_order_date',
      sorter: (a: any, b: any) =>
        a.first_order_date.localeCompare(b.first_order_date),
    },
    {
      title: 'Last Order Date',
      dataIndex: 'last_order_date',
      key: 'last_order_date',
      sorter: (a: any, b: any) =>
        a.last_order_date.localeCompare(b.last_order_date),
    },
    {
      title: 'On Going Container Count',
      dataIndex: 'ongoing_cntr_count',
      key: 'ongoing_cntr_count',
      sorter: (a: any, b: any) => a.ongoing_cntr_count - b.ongoing_cntr_count,
    },
    {
      title: 'Finished Container Count',
      dataIndex: 'finished_cntr_count',
      key: 'finished_cntr_count',
      sorter: (a: any, b: any) => a.finished_cntr_count - b.finished_cntr_count,
    },
    {
      title: 'Ongoing CNTR#',
      dataIndex: 'ongoing_cntr_count',
      key: 'ongoing_cntr_count',
      sorter: (a: any, b: any) => a.ongoing_cntr_count - b.ongoing_cntr_count,
    },
    {
      title: 'Finished CNTR#',
      dataIndex: 'finished_cntr_count',
      key: 'finished_cntr_count',
      sorter: (a: any, b: any) => a.finished_cntr_count - b.finished_cntr_count,
    },
    {
      title: 'Total CNTR#',
      dataIndex: 'total_cntr_count',
      key: 'total_cntr_count',
      sorter: (a: any, b: any) => a.total_cntr_count - b.total_cntr_count,
    },
    {
      title: 'Rate Search Count',
      dataIndex: 'rate_search_count',
      key: 'rate_search_count',
      sorter: (a: any, b: any) => a.rate_search_count - b.rate_search_count,
    },
    {
      title: 'Last Rate Search Date',
      dataIndex: 'rate_search_last_date',
      key: 'rate_search_last_date',
      sorter: (a: any, b: any) => {
        if (a.rate_search_last_date === null) {
          return -1;
        } else if (b.rate_search_last_date === null) {
          return 1;
        } else {
          return a.rate_search_last_date.localeCompare(b.rate_search_last_date);
        }
      },
    },
  ];

  return (
    <>
      <h2>Customer Summary</h2>
      <Table
        size="small"
        pagination={false}
        loading={loading}
        columns={columns}
        dataSource={summary}
      />
    </>
  );
};

export default CustomerSummary;
