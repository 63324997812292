import React from 'react';
import numeral from 'numeral';

interface Props {
  amount: number;
}

const AmountFormatter: React.FC<Props> = ({ amount }) => {
  const formattedAmount = numeral(amount).format('0,0.00');

  return <div>{formattedAmount}</div>;
};

export default AmountFormatter;
