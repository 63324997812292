import { Tag } from 'antd';
import React, { useMemo } from 'react';

interface RenderMissingVendorPossibleChargeTag {
  missVendorPossibleCharges: any;
}

export const RenderMissingVendorPossibleChargeTags: React.FC<
RenderMissingVendorPossibleChargeTag
> = ({ missVendorPossibleCharges }) => {
  if (!missVendorPossibleCharges || missVendorPossibleCharges?.length <= 0) {
    return <></>;
  }

  const text = useMemo(
    () => missVendorPossibleCharges?.map((p: any) => p.name).join(', '),
    [missVendorPossibleCharges],
  );

  return <Tag color="red">Missing possible fees: {text}</Tag>;
};
