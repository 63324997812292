import React from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Col,
  DatePicker,
  DatePickerProps,
  Drawer,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Switch,
  Table,
  TablePaginationConfig,
  Tooltip,
} from 'antd';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import PaymentForm from './components/PaymentForm';
import { useForm } from 'antd/lib/form/Form';
import { TInvoicePayment, TCollection } from '@/types';
import usePagination from '@/components/usePagination';
import moment from 'moment';
import { SorterResult } from 'antd/lib/table/interface';
import { truncate } from 'lodash';
import { BankAccountSelect } from '@/components/BankAccountSelect';
import AutoResizeTable from '@/components/AutoResizeTable';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { PAYMENT_METHOD_MAP } from './components/data';

const DepositeModal: React.FC<{ onComfirm: (date: string) => void }> = ({
  onComfirm,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [date, setDate] = React.useState<string>('');
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    if (!date) {
      return;
    }
    onComfirm && onComfirm(date);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    // console.log(date, dateString);
    setDate(dateString);
  };

  return (
    <>
      <a onClick={showModal}>Deposite</a>
      <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <DatePicker value={date ? moment(date) : null} onChange={onChange} />
      </Modal>
    </>
  );
};

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [savable, setSavable] = React.useState(true);
  const [data, setData] = React.useState<TCollection<TInvoicePayment>>();
  const [filter] = useForm();
  const [form] = useForm();

  const pagination = usePagination(data);

  const fetchData = React.useCallback(
    async (
      pagination?: TablePaginationConfig,
      _?: any,
      sorter?: SorterResult<TInvoicePayment>,
    ) => {
      setLoading(true);

      try {
        const result = await app.service.get('orderInvoicePayments', {
          params: {
            ...filter.getFieldsValue(),
            page: pagination?.current || 1,
            per_page: pagination?.pageSize || 20,
            sort_by: sorter?.columnKey || sorter?.field,
            sort_value:
              sorter?.order === 'ascend'
                ? 'asc'
                : sorter?.order === 'descend'
                ? 'desc'
                : undefined,
          },
        });

        setData(result);
      } catch (e: any) {
        message.error(e.data.message || e.data.error);
      }

      setLoading(false);
    },
    [],
  );

  const handleSave = async () => {
    const values = await form.validateFields();

    setLoading(true);

    try {
      !values.id
        ? await app.service.post('orderInvoicePayments', { data: values })
        : await app.service.put(`orderInvoicePayments/${values.id}`, {
            data: values,
          });

      message.success('Saved');

      fetchData();
    } catch (e: any) {
      message.error(e.data.message || e.data.error);
    }

    setLoading(false);
  };

  const handlePrint = async (payment: TInvoicePayment) => {
    setLoading(true);

    try {
      const file = await app.service.get(
        `orderInvoicePayments/${payment.id}/print`,
        {
          responseType: 'blob',
        },
      );
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank');
    } catch (e: any) {
      message.error(e.data.message || e.data.error);
    }

    setLoading(false);
  };

  const handleEdit = async (payment: TInvoicePayment) => {
    setLoading(true);

    try {
      form.resetFields();
      form.setFieldsValue(payment);
      setVisible(true);
      setSavable(!payment.void_at);
    } catch (e: any) {
      message.error(e.data.message || e.data.error);
    }

    setLoading(false);
  };

  const handleReconcile = async (
    checked: boolean,
    payment: TInvoicePayment,
  ) => {
    setLoading(true);

    try {
      await app.service.put(`orderInvoicePayments/${payment.id}/reconcile`, {
        data: { reconciled: checked },
      });
      fetchData();
    } catch (e: any) {
      message.error(e.data.message || e.data.error);
    }

    setLoading(false);
  };

  const handleDoVoid = async (payment: TInvoicePayment) => {
    setLoading(true);

    try {
      await app.service.put(`orderInvoicePayments/${payment.id}/void`);
      fetchData();
    } catch (e: any) {
      message.error(e.data.message || e.data.error);
    }

    setLoading(false);
  };

  const handleDeposit = async (date: string, payment: TInvoicePayment) => {
    setLoading(true);

    try {
      await app.service.put(`orderInvoicePayments/${payment.id}/deposit`, {
        data: {
          date,
        },
      });
      fetchData();
    } catch (e: any) {
      message.error(e.data.message || e.data.error);
    }

    setLoading(false);
  };

  const handleUnDeposit = async (payment: TInvoicePayment) => {
    setLoading(true);

    try {
      await app.service.put(`orderInvoicePayments/${payment.id}/undeposit`);
      fetchData();
    } catch (e: any) {
      message.error(e.data.message || e.data.error);
    }

    setLoading(false);
  };

  const handleClearAll = async () => {
    filter.resetFields();
    await fetchData();
  };

  const handleExport = async () => {
    setLoading(true);
    try {
      await app.service.download('orderInvoicePayments/export', {
        params: {
          ...filter.getFieldsValue(),
        },
      });
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
    setLoading(false);
  };

  const columns: ColumnsType<any> = React.useMemo(
    () => [
      {
        title: '#',
        dataIndex: 'id',
        key: 'id',
        width: 80,
      },
      {
        title: 'Customer',
        dataIndex: ['user', 'name'],
        width: 100,
      },
      {
        title: 'INV#',
        width: 100,
        render: (_, record: TInvoicePayment) => {
          const text = record.invoices?.map((i) => i.uid).join(',');
          return (
            <Tooltip placement="topRight" title={text}>
              {truncate(text)}
            </Tooltip>
          );
        },
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        width: 100,
      },
      {
        title: 'Payment Method',
        dataIndex: 'payment_method_name',
        width: 100,
      },
      {
        title: 'Bank Account',
        dataIndex: ['bank_account', 'name'],
        width: 120,
      },
      {
        title: 'Check#',
        dataIndex: 'check_number',
        width: 100,
      },
      {
        title: 'Post Date',
        dataIndex: 'post_at',
        width: 100,
      },
      {
        title: 'Deposited Date',
        dataIndex: 'deposited_at',
        width: 100,
        render: (_, record: TInvoicePayment) => {
          return <>{record.deposited_at}</>;
        },
      },
      {
        title: 'Void Date',
        dataIndex: 'void_at',
        width: 100,
        render: (_, record: TInvoicePayment) => {
          return (
            <>{record.void_at || record.deposited_at ? record.void_at : ''}</>
          );
        },
      },
      {
        title: 'Reconciled',
        dataIndex: 'reconciled',
        width: 100,
        render: (_, record: TInvoicePayment) => {
          return (
            <Switch
              checked={!!record.reconciled}
              onChange={(checked) => handleReconcile(checked, record)}
            />
          );
        },
      },
      {
        align: 'center',
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 150,
        render: (text, record) => (
          <Space split="|">
            <a onClick={() => handleEdit(record)}>Edit</a>
            <a onClick={() => handlePrint(record)}>Print</a>
            {!(record.void_at || record.deposited_at) && (
              <Popconfirm
                title="Are you sure？"
                onConfirm={() => handleDoVoid(record)}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              >
                <a>Void</a>
              </Popconfirm>
            )}
            {!record.void_at && (
              <>
                {record.deposited_at ? (
                  <Popconfirm
                    title="Are you sure？"
                    onConfirm={() => handleUnDeposit(record)}
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                  >
                    <a>Undo Deposited</a>
                  </Popconfirm>
                ) : (
                  <DepositeModal
                    onComfirm={(date: string) => handleDeposit(date, record)}
                  />
                )}
              </>
            )}
          </Space>
        ),
      },
    ],
    [],
  );

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.main}>
      <Header title="Invoice Payment" />

      <div className={styles.filter}>
        <Form
          layout="vertical"
          form={filter}
          onFinish={fetchData}
          initialValues={{ date_field: 'post_at' }}
          style={{ width: '100%' }}
        >
          <Row gutter={16}>
            <Col md={3}>
              <Form.Item name="invoice_id" label="INV#">
                <Input />
              </Form.Item>
            </Col>
            <Col md={3}>
              <Form.Item name="check_number" label="Check#">
                <Input />
              </Form.Item>
            </Col>
            <Col md={4}>
              <Form.Item label="Bank Account" name="bank_account_id">
                <BankAccountSelect />
              </Form.Item>
            </Col>
            <Col md={4}>
              <Form.Item name="user_id" label="Customer" shouldUpdate>
                <UserSyncSelect />
              </Form.Item>
            </Col>
            <Col md={3}>
              <Form.Item name="reconciled" label="Reconciled">
                <Select allowClear>
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item name="amount_from" noStyle>
                <Input hidden />
              </Form.Item>
              <Form.Item name="amount_to" noStyle>
                <Input hidden />
              </Form.Item>
              <Form.Item label="Amount Range" shouldUpdate>
                {({ getFieldValue, setFieldsValue }) => {
                  return (
                    <Input.Group>
                      <InputNumber
                        value={getFieldValue('amount_from')}
                        onChange={(v) => setFieldsValue({ amount_from: v })}
                        style={{ width: 'cal(50% - 15px)' }}
                      />
                      <span> ~ </span>
                      <InputNumber
                        value={getFieldValue('amount_to')}
                        onChange={(v) => setFieldsValue({ amount_to: v })}
                        style={{ width: 'cal(50% - 15px)' }}
                      />
                    </Input.Group>
                  );
                }}
              </Form.Item>
            </Col>
            <Col md={10}>
              <Form.Item name="start_date" noStyle>
                <Input hidden />
              </Form.Item>
              <Form.Item name="end_date" noStyle>
                <Input hidden />
              </Form.Item>
              <Form.Item name="date_field" noStyle>
                <Input hidden />
              </Form.Item>
              <Form.Item label="Date" shouldUpdate>
                {({ getFieldValue, setFieldsValue }) => {
                  const startDate = getFieldValue('start_date');
                  const endDate = getFieldValue('end_date');
                  return (
                    <Input.Group compact>
                      <Select
                        value={getFieldValue('date_field')}
                        onChange={(value) =>
                          setFieldsValue({ date_field: value })
                        }
                        style={{ width: '30%' }}
                      >
                        <Select.Option value="post_at">Post Date</Select.Option>
                        <Select.Option value="created_at">
                          Create Date
                        </Select.Option>
                        <Select.Option value="deposited_at">
                          Deposited Date
                        </Select.Option>
                      </Select>
                      <DatePicker.RangePicker
                        style={{ width: '70%' }}
                        value={
                          startDate && endDate
                            ? [moment(startDate), moment(endDate)]
                            : undefined
                        }
                        onChange={(value) =>
                          setFieldsValue({
                            start_date: value
                              ? value[0]?.format('YYYY-MM-DD')
                              : null,
                            end_date: value
                              ? value[1]?.format('YYYY-MM-DD')
                              : null,
                          })
                        }
                      />
                    </Input.Group>
                  );
                }}
              </Form.Item>
            </Col>
            <Col md={2}>
              <Form.Item name="is_voided" label="Void">
                <Select allowClear>
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={2}>
              <Form.Item name="payment_method" label="Payment method">
                <Select allowClear>
                  {Object.keys(PAYMENT_METHOD_MAP).map((k) => (
                    <Select.Option key={k} value={k}>
                      {PAYMENT_METHOD_MAP[k]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={4}>
              <Form.Item label={' '}>
                <Space>
                  <Button htmlType="submit" type="primary">
                    Search
                  </Button>
                  <Button onClick={handleClearAll}>Clear All</Button>
                  <Button onClick={handleExport}>Export</Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <AutoResizeTable
        loading={loading}
        pagination={pagination}
        size="small"
        rowKey="id"
        columns={columns}
        onChange={fetchData}
        dataSource={data?.data || []}
        sticky
        scroll={{
          x: 'auto',
        }}
      />

      <Drawer
        title={form.getFieldValue('id')}
        placement="right"
        width={'80%'}
        onClose={() => setVisible(false)}
        destroyOnClose={true}
        open={visible}
        extra={
          <Space>
            <Button onClick={() => setVisible(false)}>Close</Button>
            <Button
              type="primary"
              disabled={!savable}
              loading={loading}
              onClick={handleSave}
            >
              Save
            </Button>
          </Space>
        }
      >
        <PaymentForm form={form} invoices={form.getFieldValue('invoices')} />
      </Drawer>
    </div>
  );
};

export default Index;
