import { useApp } from '@/utils/useapp';
import { Drawer, Space, Button, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';
import _, { get } from 'lodash';
import { DropZoneForm } from './DropZoneForm';
import { serialize } from 'object-to-formdata';

interface DropZone {
  id: number;
  open: boolean;
  onClose: () => void;
  onSaved?: () => void;
}

export const DropZoneDrawer: React.FC<DropZone> = ({
  id,
  open,
  onClose,
  onSaved,
}) => {
  const app = useApp();

  const [form] = useForm();
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);

    try {
      const resp = await app.service.get(`dropZoneRates/${id}`);
      form.setFieldsValue({
        ...resp.data,
      });
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  const handleOnClose = () => {
    form.resetFields();
    onClose();
  };

  const handleSave = async () => {
    const data = await form.validateFields();

    setLoading(true);
    const values = Object.assign({}, data);

    values.vendor_id = values.vendor ? values.vendor.id : null;
    delete values.vendor;
    values.city_id = values.city ? values.city.id : null;
    delete values.city;

    try {
      const response = id
        ? await app.service.put(`dropZoneRates/${id}`, { data: values })
        : await app.service.post('dropZoneRates', { data: values });
      onSaved && onSaved();
      handleOnClose();
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  useEffect(() => {
    open && id && fetchData();
  }, [open, id]);

  return (
    <>
      {open && (
        <Drawer
          title=""
          placement={'right'}
          width={'40%'}
          onClose={handleOnClose}
          destroyOnClose={true}
          open={open}
          extra={
            <Space>
              <Button onClick={handleOnClose}>Close</Button>
              <Button type="primary" loading={loading} onClick={handleSave}>
                Save
              </Button>
            </Space>
          }>
          <DropZoneForm form={form} />
        </Drawer>
      )}
    </>
  );
};
