import { Button, DatePicker, List, Popover, Space, Tag } from 'antd';
import React from 'react';
import { get } from 'lodash';
import moment from 'moment';
import { ACTIONS_TO_SHOW } from '../data';
import { RenderIcon } from './RenderIcon';
import { RenderActions } from './RenderActions/index';

const ACTIONS_TO_SHOW_COLOR_MAP = {
  YES: 'green',
  NO: 'red',
};

export const formatActionInfo = (
  type: string,
  task: string,
  action: string,
  value?: any,
) => {
  const data = {
    action,
    task,
  };

  return {
    type,
    data,
    value,
  };
};

export type TItem = {
  action: string;
  task: string;
  editable?: any;
  done?: boolean;
  action_at?: string;
  actions?: Array<string>;
};

export type TContainerProps = {
  container: any;
};
export type TCommonProps = {
  type: 'file' | 'vendor' | 'warehouse';
} & TContainerProps;

export type TOnDoneProps = {
  onDone?: () => void;
};

export type TOnCheckProps = {
  onCheck: (_: {
    task: string;
    action:
    | 'DONE'
    | 'NA'
    | 'UNDO'
    | 'YES'
    | 'NO'
    | 'INPUT'
    | 'INPUTANDACTION'
    | 'SEND POD';
    value?: any;
  }) => void;
};

export type TItemProps = {
  item: TItem;
};

export type FileActionTipsProps = {
  children?: React.ReactElement;
  color: string;
  list: any[];
} & TCommonProps &
TOnDoneProps &
TOnCheckProps;

export const FileActionTips: React.FC<FileActionTipsProps> = ({
  type,
  children,
  color,
  onDone,
  onCheck,
  list,
  container,
}) => {
  return (
    <>
      <Popover
        zIndex={100}
        mouseEnterDelay={0.05}
        mouseLeaveDelay={0.05}
        placement="left"
        content={
          <List
            pagination={false}
            bordered
            size="small"
            dataSource={list}
            renderItem={(item) => {
              let editable = null;

              if (item.editable) {
                const editableValue = get(container, item.editable);

                if (item.editable_type === 'datetime') {
                  editable = (
                    <DatePicker
                      getPopupContainer={(trigger: any) =>
                        trigger.parentElement
                      }
                      showTime
                      size="small"
                      value={editableValue ? moment(editableValue) : null}
                      format="YYYY-MM-DD HH:mm"
                      onChange={(value) =>
                        onCheck({
                          task: item.task,
                          action: 'INPUTANDACTION',
                          value: value
                            ? value.format('YYYY-MM-DD HH:mm:ss')
                            : null,
                        })
                      }
                    />
                  );
                }
              }

              return (
                <List.Item>
                  <Space>
                    <RenderIcon item={item} />
                    <span>{item.task}</span>
                    <span>{editable}</span>
                    {!!item.action_by &&
                      ACTIONS_TO_SHOW.includes(item.action) && (
                      <Tag
                        color={
                          ACTIONS_TO_SHOW_COLOR_MAP[
                            item.action as unknown as keyof typeof ACTIONS_TO_SHOW_COLOR_MAP
                          ]
                        }>
                        {item.action}
                      </Tag>
                    )}
                    {!!item.action_by && (
                      <small className="text-gray">by {item.action_by}</small>
                    )}
                    {!!item.action_by && (
                      <small className="text-gray">at {item.action_at}</small>
                    )}

                    <RenderActions
                      type={type}
                      item={item}
                      container={container}
                      onCheck={onCheck}
                      onDone={() => onDone && onDone()}
                    />
                  </Space>
                </List.Item>
              );
            }}
          />
        }>
        <Button type="link" size="small">
          <span className={`text-${color}`}>{children}</span>
        </Button>
      </Popover>
    </>
  );
};
