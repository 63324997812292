import { Header } from '@/components/CommonHeader';
import { useApp } from '@/utils/useapp';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  message,
  Row,
  Select,
  Space,
  Statistic,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import React, { FC, useEffect, useState } from 'react';
import styles from 'res/css/ui.scss';
import { Column } from '@ant-design/plots';
import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'umi';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { valueType } from 'antd/lib/statistic/utils';
import { UserSyncSelect } from '@/components/UserSyncSelect';

const { Text, Title } = Typography;

type TStatistic = {
  rateTodayCount: number;
  requestedRateTodayCount: number;
  orderTodayCount: number;
  containerTodayCount: number;
  rateCount: number;
  requestedRateCount: number;
  orderCount: number;
  containerCount: number;
  searchRateLogCount: number;
  searchRateLogTodayCount: number;
  containerChart: { x: string; y: number }[];
  rateChart: { x: string; y: number }[];
};

const BarChart: React.FC<{
  data: Record<string, any>[];
  title: string;
  meta: any;
}> = ({ data, title = '', meta = {} }) => {
  return (
    <>
      {title ? <Title level={5}>{title}</Title> : ''}
      <Column
        data={data || []}
        xField={'x'}
        yField={'y'}
        meta={meta}
        label={{
          position: 'middle',
          style: {
            fill: '#FFFFFF',
            opacity: 0.6,
          },
        }}
        xAxis={{
          label: {
            autoHide: true,
            autoRotate: false,
          },
        }}
      />
    </>
  );
};

const BaseAnalysis: React.FC<{
  title: string;
  columns: ColumnsType<any>;
  data: any[];
  maxRows?: number;
}> = ({ title, columns = [], data = [], maxRows = 5 }) => {
  const [isShowMore, setIsShowMore] = useState(false);
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [defaultRows, setDefaultRows] = useState<Array<any>>([]);
  const [hideRows, setHideRows] = useState<Array<any>>([]);

  const handleShowMoreOrLess = () => {
    changeTableData();
    setIsShowMore(!isShowMore);
  };

  const changeTableData = () => {
    if (isShowMore) {
      setTableData([...defaultRows, ...hideRows]);
    } else {
      setTableData([...defaultRows]);
    }
  };

  useEffect(() => {
    setDefaultRows([...data.slice(0, maxRows)]);

    setHideRows([...data.slice(maxRows)]);

    setTableData(defaultRows);

    if (data.length > maxRows) {
      setIsShowMore(true);
    } else {
      setIsShowMore(false);
    }
  }, [data, title, columns]);

  return (
    <Card title={title}>
      <Table
        size="small"
        rowKey={(record) => record.name}
        columns={columns}
        dataSource={tableData}
        pagination={false}
        scroll={{ y: '36vh' }}></Table>
      {data.length > maxRows && (
        <div className="flex justify-center text-align mt-md">
          <Button onClick={handleShowMoreOrLess}>
            {isShowMore ? 'Show More' : 'Show Less'}
          </Button>
        </div>
      )}
    </Card>
  );
};

const TerminalAnalysis = ({ data = [] }) => {
  return (
    <BaseAnalysis
      columns={[
        {
          title: 'Terminal Name',
          key: 'name',
          dataIndex: 'name',
          render: (text, record: any) => (
            <Link to={record.link}>{record.name}</Link>
          ),
        },
        {
          title: 'Firms',
          key: 'firms',
          dataIndex: 'firms',
        },
        {
          title: 'IR',
          key: 'ir',
          dataIndex: 'ir',
        },
        {
          title: 'CNTR Qty',
          key: 'containers_count',
          dataIndex: 'containers_count',
        },
      ]}
      data={data}
      title="Terminal Analysis"
    />
  );
};

const IRAnalysis = ({ data = [] }) => {
  const getColor = (percentage: number) => {
    let color = '';
    if (percentage <= 0) {
      color = 'default';
    } else if (percentage <= 20) {
      color = 'error';
    } else if (percentage <= 40) {
      color = 'warning';
    } else {
      color = 'success';
    }
    return color;
  };
  return (
    <BaseAnalysis
      columns={[
        {
          title: 'IR Name',
          key: 'name',
          dataIndex: 'name',
          render: (text, record: any) => (
            <Link to={record.link}>{record.name}</Link>
          ),
        },
        {
          title: 'Order Qty',
          key: 'orders_count',
          dataIndex: 'orders_count',
        },
        {
          title: 'CNTR Qty',
          key: 'containers_count',
          dataIndex: 'containers_count',
        },
        {
          title: () => (
            <>
              Past 7{' '}
              <Tooltip placement="topLeft" title={'Past 7'}>
                <QuestionCircleOutlined />
              </Tooltip>
            </>
          ),
          key: 'last_month_demand_trend_percentage',
          dataIndex: 'last_month_demand_trend_percentage',
          render: (text, record: any) => (
            <Tag color={getColor(record.last_month_demand_trend_percentage)}>
              {record.last_month_demand_trend_percentage}%
            </Tag>
          ),
        },
        {
          title: (
            <>
              Past 14{' '}
              <Tooltip placement="topLeft" title={'Past 14'}>
                <QuestionCircleOutlined />
              </Tooltip>
            </>
          ),
          key: 'last_two_weeks_demand_trend_percentage',
          dataIndex: 'last_two_weeks_demand_trend_percentage',
          render: (text, record: any) => (
            <Tag
              color={getColor(record.last_two_weeks_demand_trend_percentage)}>
              {record.last_two_weeks_demand_trend_percentage}%
            </Tag>
          ),
        },
        {
          title: (
            <>
              Past 30{' '}
              <Tooltip placement="topLeft" title={'Past 30'}>
                <QuestionCircleOutlined />
              </Tooltip>
            </>
          ),
          key: 'last_week_demand_trend_percentage',
          dataIndex: 'last_week_demand_trend_percentage',
          render: (text, record: any) => (
            <Tag color={getColor(record.last_week_demand_trend_percentage)}>
              {record.last_week_demand_trend_percentage}%
            </Tag>
          ),
        },
      ]}
      data={data}
      title="IR Analysis"
    />
  );
};

const SalesAnalysis = ({ data = [] }) => {
  return (
    <BaseAnalysis
      columns={[
        {
          title: 'Sales Name',
          key: 'name',
          dataIndex: 'name',
          render: (text, record: any) => (
            <Link to={record.link}>{record.name}</Link>
          ),
        },
        {
          title: 'Order Qty',
          key: 'orders_count',
          dataIndex: 'orders_count',
        },
        {
          title: 'CNTR Qty',
          key: 'containers_count',
          dataIndex: 'containers_count',
        },
        {
          title: 'Customer Qty',
          key: 'customers_count',
          dataIndex: 'customers_count',
        },
      ]}
      data={data}
      title="Sales Analysis"
    />
  );
};

const CustomerAnalysis = ({ data = [] }) => {
  return (
    <BaseAnalysis
      columns={[
        {
          title: 'Customer Name',
          key: 'name',
          dataIndex: 'name',
          render: (text, record: any) => (
            <Link to={record.link}>{record.name}</Link>
          ),
        },
        {
          title: 'Order Qty',
          key: 'orders_count',
          dataIndex: 'orders_count',
        },
        {
          title: 'CNTR Qty',
          key: 'containers_count',
          dataIndex: 'containers_count',
        },
      ]}
      data={data}
      title="Customer Analysis"
    />
  );
};

const VendorAnalysis = ({ data = [] }) => {
  return (
    <BaseAnalysis
      columns={[
        {
          title: 'Vendor Name',
          key: 'name',
          dataIndex: 'name',
          render: (text, record: any) => (
            <Link to={record.link}>{record.name}</Link>
          ),
        },
        {
          title: 'Order Qty',
          key: 'orders_count',
          dataIndex: 'orders_count',
        },
        {
          title: 'CNTR Qty',
          key: 'containers_count',
          dataIndex: 'containers_count',
        },
      ]}
      data={data}
      title="Vendor Analysis"
    />
  );
};

const CityAnalysis = ({ data = [] }) => {
  return (
    <BaseAnalysis
      columns={[
        {
          title: 'To City Name',
          key: 'name',
          dataIndex: 'name',
          render: (text, record: any) => (
            <Link to={record.link}>{record.name}</Link>
          ),
        },
        {
          title: 'Order Qty',
          key: 'orders_count',
          dataIndex: 'orders_count',
        },
        {
          title: 'CNTR Qty',
          key: 'containers_count',
          dataIndex: 'containers_count',
        },
      ]}
      data={data}
      title="To City Analysis"
    />
  );
};

const SSLAnalysis = ({ data = [] }) => {
  return (
    <BaseAnalysis
      columns={[
        {
          title: 'SSL Code',
          key: 'name',
          dataIndex: 'name',
          render: (text, record: any) => (
            <Link to={record.link}>{record.name}</Link>
          ),
        },
        {
          title: 'Order Qty',
          key: 'orders_count',
          dataIndex: 'orders_count',
        },
        {
          title: 'CNTR Qty',
          key: 'containers_count',
          dataIndex: 'containers_count',
        },
      ]}
      data={data}
      title="SSL Analysis"
    />
  );
};

const DashboardCard: FC<{ title: string; count: number; today: number }> = ({
  title,
  count,
  today,
}) => {
  const formatter = (num: valueType) => {
    num = num.toString().replace(/[^0-9.]/g, '');
    if (num < 1000) {
      return num;
    }
    const si = [
      { v: 1e3, s: 'K' },
      { v: 1e6, s: 'M' },
      { v: 1e9, s: 'B' },
      { v: 1e12, s: 'T' },
      { v: 1e15, s: 'P' },
      { v: 1e18, s: 'E' },
    ];
    let index;
    for (index = si.length - 1; index > 0; index--) {
      if (num >= si[index].v) {
        break;
      }
    }
    return (
      (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') +
      si[index].s
    );
  };

  return (
    <Card>
      <div className={styles.dashboardCard}>
        <Statistic title={title} value={count} formatter={formatter} />
        <span className="mb-sm">+{today} today</span>
      </div>
    </Card>
  );
};

const Index = () => {
  const app = useApp();
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState<TStatistic | null>(null);
  const [filter, setFilter] = useState({
    user_id: undefined,
    type: 'created_at',
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
  });
  const [analysis, setAnalysis] = useState<{
    city: [];
    customer: [];
    ir: [];
    ssl: [];
    terminal: [];
    vendor: [];
  }>({ city: [], customer: [], ir: [], ssl: [], terminal: [], vendor: [] });

  const loadAnalysis = async (refresh = false) => {
    setLoading(true);
    try {
      const resp = await app.service.get('dashboard/analysis', {
        params: { ...filter, refresh },
      });
      setAnalysis(resp);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    loadAnalysis(true);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const resp = await app.service.get('dashboard');
      setStats(resp.data);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    const startDate = moment().startOf('month').format('YYYY-MM-DD');
    const endDate = moment().endOf('month').format('YYYY-MM-DD');
    setFilter({
      user_id: undefined,
      type: 'port_of_discharge_eta',
      startDate,
      endDate,
    });
  };

  useEffect(() => {
    fetchData();
    loadAnalysis();
  }, []);

  const flex = '1 0 20%';

  return (
    <div className={styles.dashboard}>
      <Header title="Dashboard"></Header>
      <Row className="ml-sm mr-sm" gutter={16}>
        <Col flex={flex}>
          <DashboardCard
            title="Rates"
            count={stats?.rateCount || 0}
            today={stats?.rateTodayCount || 0}
          />
        </Col>
        <Col flex={flex}>
          <DashboardCard
            title="Requests"
            count={stats?.requestedRateCount || 0}
            today={stats?.requestedRateTodayCount || 0}
          />
        </Col>
        <Col flex={flex}>
          <DashboardCard
            title="Rate Search"
            count={stats?.searchRateLogCount || 0}
            today={stats?.searchRateLogTodayCount || 0}
          />
        </Col>
        <Col flex={flex}>
          <DashboardCard
            title="Orders"
            count={stats?.orderCount || 0}
            today={stats?.orderTodayCount || 0}
          />
        </Col>
        <Col flex={flex}>
          <DashboardCard
            title="Containers"
            count={stats?.containerCount || 0}
            today={stats?.containerTodayCount || 0}
          />
        </Col>
      </Row>
      <Divider></Divider>
      <Row gutter={10} className="ml-sm mr-sm mt-lg">
        <Col span={4}>
          <Form.Item
            className="mb0"
            label="Customer"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}>
            <UserSyncSelect
              placeholder="All"
              value={filter.user_id}
              onChange={(v) => {
                setFilter({ ...filter, user_id: v });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            className="mb0"
            label="Date Type"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}>
            <Select
              value={filter.type}
              onChange={(v) => {
                setFilter({ ...filter, type: v });
              }}>
              <Select.Option value="final_port_eta">IR ETA</Select.Option>
              <Select.Option value="port_of_discharge_eta">
                POD ETA
              </Select.Option>
              <Select.Option value="created_at">Create Date</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            className="mb0"
            label="Date Range"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}>
            <DatePicker.RangePicker
              value={[
                filter.startDate ? moment(filter.startDate) : null,
                filter.endDate ? moment(filter.endDate) : null,
              ]}
              style={{ width: '100%' }}
              onChange={(date, dataStrings) => {
                setFilter({
                  ...filter,
                  startDate: dataStrings[0],
                  endDate: dataStrings[1],
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="&nbsp;"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}>
            <Row gutter={10}>
              <Col span={4}>
                <Button
                  type="primary"
                  disabled={loading}
                  onClick={handleSearch}>
                  Search
                </Button>
              </Col>
              <Col span={6}>
                <Button onClick={handleReset}>Clear All</Button>
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
      <Row className="mt-lg">
        <Col span={8}>
          <IRAnalysis data={analysis.ir} />
        </Col>

        <Col span={8}>
          <CustomerAnalysis data={analysis.customer} />
        </Col>

        <Col span={8}>
          <SalesAnalysis data={analysis.sales} />
        </Col>

        <Col span={8}>
          <TerminalAnalysis data={analysis.terminal} />
        </Col>

        <Col span={8}>
          <VendorAnalysis data={analysis.vendor} />
        </Col>

        <Col span={8}>
          <CityAnalysis data={analysis.city} />
        </Col>

        <Col span={8}>
          <SSLAnalysis data={analysis.ssl} />
        </Col>
      </Row>
      <Row className="ml-sm mr-sm mt-lg" gutter={20}>
        <Col span={12}>
          <BarChart
            data={stats?.rateChart || []}
            title="Search Rate Log"
            meta={{
              x: {
                alias: '',
              },
              y: {
                alias: 'Rates Created',
              },
            }}
          />
        </Col>
        <Col span={12}>
          <BarChart
            data={stats?.containerChart || []}
            title="Container"
            meta={{
              x: {
                alias: '',
              },
              y: {
                alias: 'CNTR Count',
              },
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Index;
