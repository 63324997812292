export const ACTION_VIEW = 'VIEW';
export const ACTION_VIEW_WITH_ORIGINAL_INVOICES = 'VIEW_WITH_ORIGINAL_INVOICES';
export const ACTION_DOWNLOAD = 'DOWNLOAD';
export const ACTION_DOWNLOAD_WITH_ORIGINAL_INVOICES =
  'DOWNLOAD_WITH_ORIGINAL_INVOICES';
export const ACTION_EXCEL = 'ECEL';
export const ACTION_SEND_EMIAL = 'SEND_EMIAL';
export const ACTION_RELOAD = 'RELOAD';
export const ACTION_REGENERATE = 'REGENERATE';

export const ACTION_MAP = {
  [ACTION_VIEW]: 'View',
  [ACTION_VIEW_WITH_ORIGINAL_INVOICES]: 'View With Original Invoices',
  [ACTION_DOWNLOAD]: 'Download',
  [ACTION_DOWNLOAD_WITH_ORIGINAL_INVOICES]: 'Download With Original Invoices',
  [ACTION_EXCEL]: 'Excel',
  [ACTION_SEND_EMIAL]: 'Send Email',
  [ACTION_RELOAD]: 'Reload',
  [ACTION_REGENERATE]: 'Regenerate',
};
