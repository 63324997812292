import React, { ReactElement, useEffect, useState } from 'react';
import { Space, Tooltip, Divider, Button, Popover } from 'antd';
import { useApp } from '@/utils/useapp';

import { TItemActionProps, TItemProps, TOnDoneProps } from '..';
import useActionTasks from '../useActionTasks';

export const ConfirmTimeBasedUpdate: React.FC<
{
  container?: any;
  updatingData: TItemActionProps;

  handleOnCheck: (data: TItemActionProps) => void;
} & TOnDoneProps
> = ({ container, updatingData, handleOnCheck, onDone, ...props }) => {
  const app = useApp();

  const { checkTimeValid } = useActionTasks();
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [errors, setErrors] = useState<Array<string>>([]);

  const onChecked = async () => {
    await handleOnCheck(updatingData);
    init();
    // onDone && onDone();
  };
  const checkValidUpdatingData = async () => {
    const errors = await checkTimeValid(container.id, updatingData);
    if (errors) {
      setErrors(errors);
      setIsOpenConfirm(true);
    } else {
      onChecked();
    }
  };

  const handleCancel = () => {
    init();
  };

  const init = () => {
    setErrors([]);
    setIsOpenConfirm(false);
  };

  useEffect(() => {
    if (updatingData) {
      checkValidUpdatingData();
    }
  }, [updatingData]);

  return (
    <>
      {props.children}
      {isOpenConfirm && (
        <Popover
          placement="topRight"
          open={isOpenConfirm}
          content={
            <div style={{ width: '400px' }}>
              {errors.map((e, i) => (
                <div key={i}>{e}</div>
              ))}
              Are your sure to update?
              <Divider />
              <div className="flex justify-end">
                <Button onClick={handleCancel} size="small">
                  Cancel
                </Button>
                <Button type="primary" onClick={onChecked} size="small">
                  Confirm
                </Button>
              </div>
            </div>
          }>
          <></>
        </Popover>
      )}
    </>
  );
};
