import React from 'react';
import { Modal, Button, message, Space } from 'antd';
import { useApp } from '@/utils/useapp';
import { Excels } from '@/components/Upload/Excels';

const Import: React.FC<{
  onImported: () => void;
}> = ({ onImported }) => {
  const app = useApp();

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [enableEmptyUpload, setEnableEmptyUpload] = React.useState(false);

  const showModal = () => {
    setEnableEmptyUpload(true);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setEnableEmptyUpload(true);
    setIsModalVisible(false);
  };

  const downloadTemplate = async () => {
    try {
      await app.service.download('dropZoneRates/template');
    } catch (e: any) {
      message.error('System Error');
    }
  };

  const handleImported = () => {
    setEnableEmptyUpload(true);
    onImported();
    handleCancel();
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Import
      </Button>
      <Modal
        title="Import"
        open={isModalVisible}
        okText="Download Template"
        onCancel={handleCancel}
        footer={
          <Space>
            <Button onClick={handleCancel}> Cancle</Button>
            <Button type="primary" onClick={downloadTemplate}>
              Download Template
            </Button>
          </Space>
        }>
        <Excels
          uploadAction="dropZoneRates/import"
          enableEmptyUpload={enableEmptyUpload}
          onUploaded={handleImported}
        />
      </Modal>
    </>
  );
};

export default Import;
