import React, { FC, Ref } from 'react';
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown, Space, Input } from 'antd';

interface IAppendText {
  dropdownLabel: string;
  elementObj: HTMLElement | null;
  appendText: string;
  setText: (text: string) => void;
}

export const AppendText: FC<IAppendText> = ({
  dropdownLabel,
  elementObj,
  appendText,
  setText,
}) => {
  const handleAppendAtTheBottom = () => {
    if (!elementObj) {
      return;
    }

    setText(elementObj?.value + appendText);
  };
  const handleAppendAtTheCursorPosition = () => {
    if (!elementObj) {
      return;
    }

    const selectionStart = elementObj?.selectionStart;
    const selectionEnd = elementObj?.selectionEnd;
    const textBeforeCursor = elementObj?.value?.substring(0, selectionStart);
    const textAfterCursor = elementObj?.value?.substring(selectionEnd);
    setText(textBeforeCursor + appendText + textAfterCursor);
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Append at the bottom',
      onClick: handleAppendAtTheBottom,
    },
    {
      key: '2',
      label: 'Append at the cursor position',
      onClick: handleAppendAtTheCursorPosition,
    },
  ];

  return (
    <Dropdown menu={{ items }}>
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          {dropdownLabel}
          <DownOutlined />
        </Space>
      </a>
    </Dropdown>
  );
};
