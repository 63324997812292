import { Button, Modal, Space } from 'antd';
import React from 'react';

interface ISyncContainerState {
  open: boolean;
  loading: boolean;
  onNo: () => void;
  onYes: () => void;
}

export const SyncContainerState: React.FC<ISyncContainerState> = ({
  open,
  loading,
  onNo,
  onYes,
}) => {
  return (
    <Modal
      title=""
      destroyOnClose
      cancelText="Close"
      footer={
        <Space>
          <Button type="primary" disabled={loading} onClick={onYes}>
            Yes
          </Button>
          <Button disabled={loading} onClick={onNo}>
            No
          </Button>
        </Space>
      }
      open={open}
      onCancel={onNo}>
      Are you sure to sync status for containers ?
    </Modal>
  );
};
