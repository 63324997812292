import { Button, message, Space, Divider } from 'antd';
import { useState } from 'react';
import { useApp } from '@/utils/useapp';
import { EditOutlined } from '@ant-design/icons';
import { EditMentionsInput } from './EditMentionsInput';
import { appendMemoAttributeToData } from './data';
import WithMentionHighlighter from '../WithMentionHighlighter';

export const EditableItem = ({
  item,
  memoAttribute,
  disabled = false,
  pinConment,
  onSaved,
}: {
  item: any;
  memoAttribute?: string;
  disabled?: boolean;
  pinConment: (item: any, pin: boolean) => void;
  onSaved: () => void;
}) => {
  const [edit, setEdit] = useState(false);
  const [comment, setComment] = useState<string>(item.comment);
  const [loading, setLoading] = useState(false);
  const app = useApp();

  const handleSave = async () => {
    try {
      const resp = await app.service.put(`adminComments/${item.id}`, {
        data: appendMemoAttributeToData({ comment }, memoAttribute),
      });
      setEdit(false);
      setComment(resp.data.comment);
      onSaved();
    } catch (err: any) {
      message.error(err?.data?.message || err?.data?.error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {!edit ? (
        <div className="flex justify-between">
          <WithMentionHighlighter
            content={comment}
            highlighterColor={'#000000'}
          />
          {!disabled && (
            <Space split={<Divider type="vertical" />}>
              {app.store.auth?.user?.id == item.admin_id && (
                <EditOutlined
                  className="text-primary"
                  onClick={() => setEdit(true)}
                />
              )}
              <a onClick={() => pinConment(item, true)}>Pin</a>
            </Space>
          )}
        </div>
      ) : (
        <>
          <EditMentionsInput
            comment={comment}
            disabled={disabled}
            setComment={(comment) => setComment(comment)}
          />
          <br />
          <Space>
            <Button
              disabled={loading || disabled}
              type="primary"
              onClick={() => handleSave()}>
              Save
            </Button>
            <Button
              disabled={loading || disabled}
              onClick={() => setEdit(false)}>
              Cancel
            </Button>
          </Space>
        </>
      )}
    </>
  );
};
