import React from 'react';
import {
  Button,
  Form,
  Input,
  DatePicker,
  Row,
  Col,
  Table,
  TableColumnsType,
  Space,
  InputNumber,
  Select,
} from 'antd';
import { TBill } from '@/types';
import { FormInstance, useWatch } from 'antd/lib/form/Form';
import { BankAccountSelect } from '@/components/BankAccountSelect';
import moment from 'moment';
import { sumBy, values } from 'lodash';
import { VendorSelect } from '@/components/VendorSelect';
import { TerminalSelect } from '@/components/TerminalSelect';
import { OceanCarrierSelect } from '@/components/OceanCarrierSelect';
import classNames from 'classnames';
import { TLVendorSelect } from '@/components/TLVendorSelect';
import { TLType } from '@/components/constants';

interface Props {
  form: FormInstance;
  bills: TBill[];
}

type BillsAmount = { [key: string]: number };

const PaymentForm: React.FC<Props> = ({ form, bills = [] }) => {
  const billsAmount = useWatch('bills_amount', form) || {};

  const payInFull = () => {
    const _billsAmount = {};

    for (const i in bills) {
      _billsAmount[bills[i].id] = bills[i].amount_due;
    }

    form.setFieldValue('bills_amount', _billsAmount);
  };

  const columns: TableColumnsType<any> = React.useMemo(
    () => [
      {
        title: () => (
          <Space>
            <span>INV#</span>
            <Button type="link" size="small" onClick={payInFull}>
              Pay In Full
            </Button>
          </Space>
        ),
        dataIndex: 'invoice_number',
      },
      {
        title: 'Bill Date',
        dataIndex: 'billed_at',
      },
      {
        title: 'Due Date',
        dataIndex: 'due_at',
      },
      {
        title: 'Amount Total',
        dataIndex: 'amount_total',
      },
      {
        title: 'Amount Due',
        dataIndex: 'amount_due',
        // add background red color if amount_due == 0
        render: (value) => {
          return (
            <span
              className={classNames({ 'bg-warning': parseFloat(value) === 0 })}
            >
              {value}
            </span>
          );
        },
      },
      {
        title: 'Amount Paid',
        dataIndex: 'amount_paid',
        render: (_, record: TBill, index) => {
          return (
            <InputNumber
              style={{ width: '100%' }}
              value={billsAmount[record.id]}
              onChange={(value) =>
                form.setFieldValue('bills_amount', {
                  ...billsAmount,
                  [record.id]: value,
                })
              }
            />
          );
        },
      },
      {
        title: 'Order#',
        dataIndex: 'order_id',
      },
    ],
    [billsAmount],
  );

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          bill_to_type: bills[0]?.bill_to_type || 'App\\Models\\Vendor',
        }}
      >
        <Form.Item name="id" noStyle>
          <Input hidden />
        </Form.Item>

        <Form.Item label="Bill From" required shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => {
            return (
              <Input.Group>
                <Form.Item
                  name="bill_to_type"
                  noStyle
                  rules={[
                    { required: true, message: 'Bill From Type is required.' },
                  ]}
                >
                  <Form.Item noStyle>
                    <Select
                      style={{ width: '20%' }}
                      value={getFieldValue('bill_to_type')}
                      onChange={(value) =>
                        setFieldsValue({ bill_to_type: value })
                      }
                    >
                      <Select.Option value="App\Models\Vendor">
                        Vendor
                      </Select.Option>
                      <Select.Option value="App\Models\Terminal">
                        Terminal
                      </Select.Option>
                      <Select.Option value="App\Models\OceanCarrier">
                        OceanCarrier
                      </Select.Option>
                      <Select.Option value="App\Domains\TL\Models\LTLVendor">
                        LTL Vendor
                      </Select.Option>
                      <Select.Option value="App\Domains\FTL\Models\FTLVendor">
                        FTL Vendor
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Form.Item>
                <Form.Item
                  name="bill_to_id"
                  noStyle
                  rules={[
                    { required: true, message: 'Bill From is required.' },
                  ]}
                >
                  <Form.Item noStyle>
                    {getFieldValue('bill_to_type') ===
                      'App\\Models\\Vendor' && (
                      <VendorSelect
                        style={{ width: '80%' }}
                        selected={getFieldValue('bill_to')}
                        onSelect={(row) =>
                          setFieldsValue({
                            bill_to: row,
                            bill_to_id: row?.id,
                            bill_to_type: 'App\\Models\\Vendor',
                          })
                        }
                      />
                    )}
                    {getFieldValue('bill_to_type') ===
                      'App\\Models\\Terminal' && (
                      <TerminalSelect
                        style={{ width: '80%' }}
                        selected={getFieldValue('bill_to')}
                        onSelect={(row) =>
                          setFieldsValue({
                            bill_to: row,
                            bill_to_id: row?.id,
                            bill_to_type: 'App\\Models\\Terminal',
                          })
                        }
                      />
                    )}
                    {getFieldValue('bill_to_type') ===
                      'App\\Models\\OceanCarrier' && (
                      <OceanCarrierSelect
                        style={{ width: '80%' }}
                        value={getFieldValue('bill_to_id')}
                        onSelect={(row) =>
                          setFieldsValue({
                            bill_to: row,
                            bill_to_id: row?.id,
                            bill_to_type: 'App\\Models\\OceanCarrier',
                          })
                        }
                      />
                    )}
                    {getFieldValue('bill_to_type') ===
                      'App\\Domains\\TL\\Models\\LTLVendor' && (
                      <TLVendorSelect
                        tlType={TLType.LTL}
                        style={{ width: '80%' }}
                        selected={getFieldValue('bill_to')}
                        onSelect={(row) =>
                          setFieldsValue({
                            bill_to: row,
                            bill_to_id: row?.id,
                            bill_to_type: 'App\\Domains\\TL\\Models\\LTLVendor',
                          })
                        }
                      />
                    )}
                    {getFieldValue('bill_to_type') ===
                      'App\\Domains\\FTL\\Models\\FTLVendor' && (
                      <TLVendorSelect
                        tlType={TLType.FTL}
                        style={{ width: '80%' }}
                        selected={getFieldValue('bill_to')}
                        onSelect={(row) =>
                          setFieldsValue({
                            bill_to: row,
                            bill_to_id: row?.id,
                            bill_to_type:
                              'App\\Domains\\FTL\\Models\\FTLVendor',
                          })
                        }
                      />
                    )}
                  </Form.Item>
                </Form.Item>
              </Input.Group>
            );
          }}
        </Form.Item>
        <Form.Item
          name="bank_account_id"
          label="Bank Account"
          rules={[{ required: true, message: 'Bank Account is required' }]}
        >
          <BankAccountSelect />
        </Form.Item>
        <Form.Item name="check_number" label="Check#">
          <Input />
        </Form.Item>
        <Row gutter={24}>
          <Col md={8}>
            <Form.Item label="Clear Date" name="cleared_at">
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.cleared_at !== curValues.cleared_at
                }
              >
                {({ getFieldValue, setFieldsValue }) => {
                  const value = getFieldValue('cleared_at');
                  return (
                    <DatePicker
                      style={{ width: '100%' }}
                      value={value ? moment(value) : null}
                      onChange={(v) =>
                        setFieldsValue({
                          cleared_at: v?.format('YYYY-MM-DD'),
                        })
                      }
                    />
                  );
                }}
              </Form.Item>
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item
              label="Post Date"
              name="post_at"
              required
              rules={[{ required: true, message: 'Post Date is required' }]}
            >
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.post_at !== curValues.post_at
                }
              >
                {({ getFieldValue, setFieldsValue }) => {
                  const value = getFieldValue('post_at');
                  return (
                    <DatePicker
                      style={{ width: '100%' }}
                      value={value ? moment(value) : null}
                      onChange={(v) =>
                        setFieldsValue({
                          post_at: v?.format('YYYY-MM-DD'),
                        })
                      }
                    />
                  );
                }}
              </Form.Item>
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item name="void_at" label="Void Date">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="bills_amount" noStyle>
          <Input hidden />
        </Form.Item>
        <Table
          className="mb-md"
          bordered
          size="small"
          rowKey="id"
          columns={columns}
          dataSource={bills}
          scroll={{ y: 300 }}
          pagination={false}
          summary={(pageData) => {
            let amountTotal = 0,
              amountDue = 0;

            const amountPaid = sumBy(values(billsAmount), (v) =>
              parseFloat(v || 0),
            );

            pageData.forEach(({ amount_due, amount_total }) => {
              amountTotal += parseFloat(amount_total || 0);
              amountDue += parseFloat(amount_due || 0);
            });

            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={3} align="right">
                    <strong>Total</strong>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    <strong>{amountTotal.toFixed(2)}</strong>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5}>
                    <strong>{amountDue.toFixed(2)}</strong>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6}>
                    <strong>{amountPaid.toFixed(2)}</strong>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7} colSpan={100} />
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
        <Form.Item name="remark" label="Remark">
          <Input.TextArea rows={5} />
        </Form.Item>
      </Form>
    </>
  );
};

export default PaymentForm;
