import request from './request';

export const kgToLb = (kg: number, ciel = false) => {
  return ciel ? Math.ceil(kg / 0.4535) : kg / 0.4535;
};

export const lbToKg = (lb: number, ciel = false) => {
  return ciel ? Math.ceil(lb / 2.20462) : lb / 2.20462;
};

export const ft3ToM3 = (ft3: number, ciel = false) => {
  return ciel ? Math.ceil(ft3 * 0.0283168) : ft3 * 0.0283168;
};

export const m3ToFt3 = (m3: number, ciel = false) => {
  return ciel ? Math.ceil(m3 * 35.3147) : m3 * 35.3147;
};

export const m3ToIn3 = (m3: number, ciel = false) => {
  return ciel ? Math.ceil(m3 * 1728 * 35.3146667) : m3 * 1728 * 35.3146667;
};

export const in3ToM3 = (in3: number, ciel = false) => {
  return ciel ? Math.ceil(in3 / 1728 / 35.315) : in3 / 1728 / 35.315;
};

export const m3ToCm3 = (m3: number, ciel = false) => {
  return ciel ? Math.ceil(m3 * 1000000) : m3 * 1000000;
};

export const cm3ToM3 = (cm3: number, ciel = false) => {
  return ciel ? Math.ceil(cm3 / 1000000) : cm3 / 1000000;
};

export const cm3ToFt3 = (cm3: number, ciel = false) => {
  return ciel ? Math.ceil(cm3 * 0.00003531) : cm3 * 0.00003531;
};

export const in3ToFt3 = (in3: number, ciel = false) => {
  return ciel ? Math.ceil(in3 / 1728) : in3 / 1728;
};

export const inToCm = (inch: number, ciel = false) => {
  return ciel ? Math.ceil(inch * 2.54) : inch * 2.54;
};

export const cmToIn = (cm: number, ciel = false) => {
  return ciel ? Math.ceil(cm / 2.54) : cm / 2.54;
};

export const freightClasses = [
  {
    threshold: 30,
    classCode: '60',
  },
  {
    threshold: 22.5,
    classCode: '65',
  },
  {
    threshold: 15,
    classCode: '70',
  },
  {
    threshold: 12,
    classCode: '85',
  },
  {
    threshold: 10,
    classCode: '92.5',
  },
  {
    threshold: 8,
    classCode: '100',
  },
  {
    threshold: 6,
    classCode: '125',
  },
  {
    threshold: 4,
    classCode: '175',
  },
  {
    threshold: 2,
    classCode: '250',
  },
  { threshold: 1, classCode: '300' },
  {
    threshold: 0,
    classCode: '400',
  },
];

export const imperialFreightClass = (lb: number, ft3: number) => {
  const density = lb / ft3;
  for (let i = 0; i < freightClasses.length; i++) {
    if (density >= freightClasses[i].threshold) {
      return freightClasses[i].classCode;
    }
  }

  return '400';
};

export enum MeasureType {
  Cargo = 'Cargo',
  Total = 'Total',
}

export enum UnitType {
  Metric = 'Metric',
  Imperial = 'Imperial',
}

export enum WeightUnit {
  LBS = 'lbs',
  KG = 'kg',
}

export enum DimensionUnit {
  IN = 'in',
  CM = 'cm',
}

export interface QuoteItem {
  class: string;
  package_type: string;
  height: number;
  length: number;
  pieces: number;
  total_weight: number;
  units: number;
  width: number;
  weight_unit: WeightUnit;
  dimension_unit: DimensionUnit;
}

export const calcFreightClass = (
  weight: number,
  length: number,
  width: number,
  height: number,
  units: number,
  weight_unit: WeightUnit,
  dimension_unit: DimensionUnit,
) => {
  const _dimensionTransform = (value: number) => {
    return dimension_unit === DimensionUnit.CM ? cmToIn(value, true) : value;
  };

  const _weightTransform = (value: number) => {
    return weight_unit === WeightUnit.KG ? kgToLb(value, true) : value;
  };

  const lb = _weightTransform(weight);
  const volume =
    _dimensionTransform(length) *
    _dimensionTransform(width) *
    _dimensionTransform(height);
  const ft3PerUnit = in3ToFt3(volume);
  return imperialFreightClass(lb, units * ft3PerUnit);
};

export const genItemsWithClass = (items: any) => {
  return items.map((item: any) => ({
    ...item,
    class: calcFreightClass(
      item.total_weight,
      item.length,
      item.width,
      item.height,
      item.units,
      item.weight_unit,
      item.dimension_unit,
    ),
  }));
};

export const packageingTypes = [
  'Bag',
  'Bale',
  'Box',
  'Bucket',
  'Bundle',
  'Can',
  'Carton',
  'Case',
  'Coil',
  'Crate',
  'Cylinder',
  'Drums',
  'Pail',
  'Pieces',
  'Pallet',
  'Reel',
  'Roll',
  'Skid',
  'Tube',
  'Tote',
];

export const serviceTypes = ['Full', 'Partial'];

export const trailerTypes = [
  { name: '53_Foot_Dry_Van', key: '53ft' },
  { name: '48_Foot_Dry_Van', key: '48ft' },
  { name: 'Sprinter_Van', key: 'sprinter_van' },
  { name: 'Straight_Truck', key: 'straight_truck' },
  { name: 'Small_Straight_Truck', key: 'small_straight_truck' },
  { name: 'Other', key: 'other' },
];

export const pickupAccesorials = [
  { name: 'Inside Pickup', key: 'inside' },
  { name: 'Liftgate Pickup', key: 'liftgate' },
];

export const destinationAccesorials = [
  { name: 'Liftgate Delivery', key: 'liftgate' },
  { name: 'Appointment', key: 'appointment' },
  { name: 'Inside Delivery', key: 'inside' },
];

export const shipmentAccesorials = [
  { name: 'Hazardous materials', key: 'hazmat' },
];

export const freightClassRequiredFields = [
  'dimension_unit',
  'units',
  'width',
  'length',
  'height',
  'weight_unit',
  'total_weight',
] as const;

export const fetchItemFreightClass = (item: QuoteItem) => {
  return request.get('/api/tool/freight/class/calculate', {
    params: {
      width: item.width,
      length: item.length,
      height: item.height,
      total_weight: item.total_weight,
      units: item.units,
      dimension_unit: item.dimension_unit,
      weight_unit: item.weight_unit,
    },
  });
};

export const fetchItemsFreightClass = (items: QuoteItem[]) => {
  return request.post('/api/tool/freight/class/items/calculate', {
    data: items,
  });
};
