export const TARGET_USER = 'user';
export const TARGET_COMPANY = 'company';
export const TARGET_CONTAINER = 'container';
export const TARGET_CONTAINER_TASK = 'container_task';
export const TARGET_LTL_SHIPMENT = 'ltl_shipment';

export const MEMO_ATTRIBUTE_USER_ACCOUNTING = 'accounting_memo';
export const MEMO_ATTRIBUTE_COMPANY_ACCOUNTING = 'accounting_memo';
export const MEMO_ATTRIBUTE_CONTAINER_INTERNAL = 'internal_memo';
export const MEMO_ATTRIBUTE_CONTAINER_TASK_RESET_SLA_MEMO = 'reset_sla_memo';

export const appendMemoAttributeToData = (
  data: { [key: string]: any },
  memoAttribute?: string,
) => {
  if (!memoAttribute) {
    return data;
  }

  return {
    ...data,
    ...{
      memo_attribute: memoAttribute,
    },
  };
};

export const style = {
  control: {
    backgroundColor: '#fff',
    fontSize: 16,
    // fontWeight: 'normal',
  },

  '&multiLine': {
    control: {
      // fontFamily: 'monospace',
      minHeight: 60,
    },
    highlighter: {
      padding: 9,
      border: '1px solid transparent',
    },
    input: {
      padding: 9,
      border: '1px solid silver',
    },
  },

  '&singleLine': {
    display: 'inline-block',
    width: 180,

    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },
    input: {
      padding: 1,
      border: '2px inset',
    },
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 16,
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
};
