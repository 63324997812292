import { useApp } from '@/utils/useapp';
import { Space } from 'antd';
import { Moment } from 'moment-timezone';
import { useMemo } from 'react';
import { GetCalendarItem } from './';

interface DateItemProps {
  key: string;
  nums: number | string;
  inactive: boolean;
  // icon: React.ReactNode;
  // inactiveIcon: React.ReactNode;
  title: string;
  textColor: string;
  borderColor: string;
  bgColor: string;
}

export enum ProgressMapKeysEnum {
  in_transit = 'in_transit',
  arriving = 'arriving',
  avaliable = 'avaliable',
  pickup = 'pickup',
  delivered = 'delivered',
  empty_returned = 'empty_returned',
  completed = 'completed',
  canceled = 'canceled',
}

export interface DateRenderProps {
  currentData: GetCalendarItem;
  isPre: boolean;
  isToday: boolean;
  isFuture: boolean;
  goToDispatch: (container_ids: number[]) => void;
}

export const DateRender: React.FC<DateRenderProps> = ({
  currentData,
  isPre,
  isToday,
  isFuture,
  goToDispatch,
}) => {
  const app = useApp();

  const pickupNums = useMemo(() => {
    if (isToday) {
      return (
        (currentData?.actual_terminal_pickup_total || 0) +
        ' / ' +
        (currentData?.schedule_terminal_pickup_total || 0)
      );
    }

    return (
      currentData?.[
        isPre
          ? 'actual_terminal_pickup_total'
          : 'schedule_terminal_pickup_total'
      ] ?? 0
    );
  }, [isToday, currentData]);

  const deliveryNums = useMemo(() => {
    if (isToday) {
      return (
        (currentData?.actual_delivery_total || 0) +
        ' / ' +
        (currentData?.scheduled_delivery_total || 0)
      );
    }

    return (
      currentData?.[
        isPre ? 'actual_delivery_total' : 'scheduled_delivery_total'
      ] ?? 0
    );
  }, [isToday, currentData]);

  const emptyReturnedNums = currentData?.actual_empty_returned_total ?? 0;

  const handleGoToDispatch = (key: string) => {
    app.store.opDashboard.setCurrentDate(currentData?.date);
    app.store.opDashboard.setCurrentProgressKey(key);
    goToDispatch(currentData?.container_ids || []);
  };

  const items: DateItemProps[] = [
    {
      key: ProgressMapKeysEnum.pickup,
      title: 'Picked Up',
      inactive: isFuture,
      nums: pickupNums,
      // icon: <ProgressPickedUpIcon />,
      // inactiveIcon: <ProgressPickedUpInactiveIcon />,
      textColor: '#FA541C',
      borderColor: '#FFD8BF',
      bgColor: '#FFF2E8',
    },
    {
      key: ProgressMapKeysEnum.delivered,
      title: 'Delivered',
      inactive: isFuture,
      nums: deliveryNums,
      // icon: <ProgressDeliveredIcon />,
      // inactiveIcon: <ProgressDeliveredInactiveIcon />,
      textColor: '#52C41A',
      borderColor: '#D9F7BE',
      bgColor: '#F6FFED',
    },
    {
      key: ProgressMapKeysEnum.empty_returned,
      title: 'Returned',
      inactive: isFuture,
      nums: emptyReturnedNums,
      // icon: <ProgressEmptyReturnedIcon />,
      // inactiveIcon: <ProgressEmptyReturnedInactiveIcon />,
      textColor: '#08979C',
      borderColor: '#B5F5EC',
      bgColor: '#E6FFFB',
    },
  ];

  return (
    <>
      {items.map(
        (item) =>
          item.nums !== 0 && (
            <div>
              {!item.inactive ? (
                <div
                  style={{
                    color: item.textColor,
                    borderColor: item.borderColor,
                    border: '1px solid',
                    backgroundColor: item.bgColor,
                    borderRadius: '16px',
                    margin: '5px 0',
                    padding: '0 6px',
                  }}
                  className={'flex justify-between'}
                  onClick={() => handleGoToDispatch(item.key)}>
                  <div className="flex items-center">
                    {/* {icon} */}
                    <div>{item.title}</div>
                  </div>
                  <div>{item.nums}</div>
                </div>
              ) : (
                <div
                  className="flex justify-between"
                  style={{
                    color: '#b5b6b8',
                    borderColor: '#adaeb0',
                    border: '1px dash',
                    backgroundColor: '#fafafa',
                    borderRadius: '16px',
                    margin: '5px 0',
                    padding: '0 6px',
                  }}
                  onClick={() => handleGoToDispatch(item.key)}>
                  <div className="flex items-center">
                    {/* {inactiveIcon} */}
                    <div>{item.title}</div>
                  </div>
                  <div>{item.nums}</div>
                </div>
              )}
            </div>
          ),
      )}
    </>
  );
};
