import moment, { Moment } from 'moment';

export type TRangePickerValue = [Moment, Moment];

interface IRangeItem {
  label: string;
  value: TRangePickerValue;
}

const today = moment().endOf('day');
const yesterday = moment().subtract(1, 'day').endOf('day');

const generalItem = (label: string, value: TRangePickerValue): IRangeItem => ({
  label,
  value,
});

const subtractMonthRange = (month: number): TRangePickerValue => [
  moment()
    .subtract(month, month > 1 ? 'months' : 'month')
    .startOf('month'),
  today,
];

const TODAY: IRangeItem = generalItem('Today', [today, today]);
const YESTERDAY: IRangeItem = generalItem('Yesterday', [yesterday, yesterday]);
const LAST_7_DAYS: IRangeItem = generalItem('Last 7 days', [
  moment().startOf('day').subtract(6, 'days'),
  today,
]);
const THIS_WEEK: IRangeItem = generalItem('This week', [
  moment().startOf('week'),
  today,
]);
const LAST_WEEK: IRangeItem = generalItem('Last week', [
  moment().subtract(7, 'days').startOf('week'),
  moment().subtract(7, 'days').endOf('week'),
]);
const LAST_30_DAYS: IRangeItem = generalItem('Last 30 days', [
  moment().startOf('day').subtract(29, 'days'),
  today,
]);
const THIS_MONTH: IRangeItem = generalItem('This month', [
  moment().startOf('month'),
  today,
]);
const LAST_MONTH: IRangeItem = generalItem('Last month', subtractMonthRange(1));
const PAST_1_MONTH: IRangeItem = generalItem(
  'Past 1 month',
  subtractMonthRange(1),
);
const PAST_2_MONTHS: IRangeItem = generalItem(
  'Past 2 months',
  subtractMonthRange(2),
);
export const PAST_3_MONTHS: IRangeItem = generalItem(
  'Past 3 months',
  subtractMonthRange(3),
);
const PAST_4_MONTHS: IRangeItem = generalItem(
  'Past 4 months',
  subtractMonthRange(4),
);
const PAST_5_MONTHS: IRangeItem = generalItem(
  'Past 5 months',
  subtractMonthRange(5),
);
const PAST_6_MONTHS: IRangeItem = generalItem(
  'Past 6 months',
  subtractMonthRange(6),
);
const PAST_7_MONTHS: IRangeItem = generalItem(
  'Past 7 months',
  subtractMonthRange(7),
);
const PAST_8_MONTHS: IRangeItem = generalItem(
  'Past 8 months',
  subtractMonthRange(8),
);
const PAST_9_MONTHS: IRangeItem = generalItem(
  'Past 9 months',
  subtractMonthRange(9),
);
const PAST_10_MONTHS: IRangeItem = generalItem(
  'Past 10 months',
  subtractMonthRange(10),
);
const PAST_11_MONTHS: IRangeItem = generalItem(
  'Past 11 months',
  subtractMonthRange(11),
);
const PAST_12_MONTHS: IRangeItem = generalItem(
  'Past 12 months',
  subtractMonthRange(12),
);

const YEAR_TO_DATE: IRangeItem = generalItem('Year to date', [
  moment().startOf('year'),
  today,
]);
const LAST_YEAR: IRangeItem = generalItem('Last year', [
  moment().subtract(1, 'year').startOf('year'),
  moment().subtract(1, 'year').endOf('year'),
]);

export const RANGE_TYPES_DEFAULT = 'default';
export const DEFAULT_RANGES = {
  [TODAY.label]: TODAY.value,
  [YESTERDAY.label]: YESTERDAY.value,
  [LAST_7_DAYS.label]: LAST_7_DAYS.value,
  [THIS_WEEK.label]: THIS_WEEK.value,
  [LAST_WEEK.label]: LAST_WEEK.value,
  [LAST_30_DAYS.label]: LAST_30_DAYS.value,
  [THIS_MONTH.label]: THIS_MONTH.value,
  [LAST_MONTH.label]: LAST_MONTH.value,
  [YEAR_TO_DATE.label]: YEAR_TO_DATE.value,
  [LAST_YEAR.label]: LAST_YEAR.value,
};

export const RANGE_TYPES_PAST_YEAR_MONTHS = 'past-year-months';
export const PAST_YEAR_MONTHS_RANGES = {
  [PAST_1_MONTH.label]: PAST_1_MONTH.value,
  [PAST_2_MONTHS.label]: PAST_2_MONTHS.value,
  [PAST_3_MONTHS.label]: PAST_3_MONTHS.value,
  [PAST_4_MONTHS.label]: PAST_4_MONTHS.value,
  [PAST_5_MONTHS.label]: PAST_5_MONTHS.value,
  [PAST_6_MONTHS.label]: PAST_6_MONTHS.value,
  [PAST_7_MONTHS.label]: PAST_7_MONTHS.value,
  [PAST_8_MONTHS.label]: PAST_8_MONTHS.value,
  [PAST_9_MONTHS.label]: PAST_9_MONTHS.value,
  [PAST_10_MONTHS.label]: PAST_10_MONTHS.value,
  [PAST_11_MONTHS.label]: PAST_11_MONTHS.value,
  [PAST_12_MONTHS.label]: PAST_12_MONTHS.value,
};

export const RANGE_TYPES_PAST_1_2_3_4_6_12_MONTHS = 'past-1-2-3-4-6-months';
export const PAST_1_2_3_4_6_12_MONTHS_RANGES = {
  [PAST_1_MONTH.label]: PAST_1_MONTH.value,
  [PAST_2_MONTHS.label]: PAST_2_MONTHS.value,
  [PAST_3_MONTHS.label]: PAST_3_MONTHS.value,
  [PAST_4_MONTHS.label]: PAST_4_MONTHS.value,
  [PAST_6_MONTHS.label]: PAST_6_MONTHS.value,
  [PAST_12_MONTHS.label]: PAST_12_MONTHS.value,
};

export const RANGE_TYPES_MAP = {
  [RANGE_TYPES_DEFAULT]: DEFAULT_RANGES,
  [RANGE_TYPES_PAST_YEAR_MONTHS]: PAST_YEAR_MONTHS_RANGES,
  [RANGE_TYPES_PAST_1_2_3_4_6_12_MONTHS]: PAST_1_2_3_4_6_12_MONTHS_RANGES,
};
