import { UserMemoModal } from '@/pages/setting/users/components/UserMemoModal';
import { InfoCircleOutlined } from '@ant-design/icons';
import { CSSProperties, memo, useCallback, useState } from 'react';

const containerStyle: CSSProperties = {
  padding: 0,
  margin: 0,
  marginLeft: '0.5rem',
  background: 'none',
  border: 'none',
  flex: 'none',
};

const EditableCustomerSelectColumnMemo = memo(
  ({ record, refresh }: { record: any; refresh?: () => void }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = useCallback(() => {
      setIsModalOpen(true);
    }, []);

    const containerClassName = `${
      record.user?.memo ? 'text-primary' : 'text-gray'
    } cursor-pointer`;

    const handleSaved = useCallback(() => {
      setIsModalOpen(false);
      refresh?.();
    }, [refresh]);

    const handleClosed = useCallback(() => {
      setIsModalOpen(false);
    }, []);

    if (!record.user) {
      return null;
    }

    return (
      <>
        <button
          type="button"
          className={containerClassName}
          style={containerStyle}
          onClick={handleOpenModal}
        >
          <InfoCircleOutlined />
        </button>
        {isModalOpen && (
          <UserMemoModal
            open={isModalOpen}
            user={record.user}
            onClose={handleClosed}
            onSaved={handleSaved}
          />
        )}
      </>
    );
  },
);

EditableCustomerSelectColumnMemo.displayName =
  'EditableCustomerSelectColumnMemo';

export default EditableCustomerSelectColumnMemo;
