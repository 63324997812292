import { ShipmentInterface } from '@/pages/truckload/ltl/shipments/Interfaces/ShipmentInterface';
import { ContainerInterface } from './ContainerInterface';
import {
  OnDoneInterface,
  TaskInterface,
  onSaveOperationInterface,
} from './TaskInterface';
import { FTLShipmentInterface } from '@/pages/truckload/ftl/shipments/Interfaces/ShipmentInterface';

export const TARGET_TYPE_LTL_SHIPMENT = 'ltl_shipment';
export const TARGET_TYPE_FTL_SHIPMENT = 'ftl_shipment';
export const TARGET_TYPE_LTL_CONTAINERS = 'containers';

export const DOC_TARGET_MAP: {
  [TARGET_TYPE_LTL_SHIPMENT]: string;
  [TARGET_TYPE_FTL_SHIPMENT]: string;
} = {
  [TARGET_TYPE_LTL_SHIPMENT]: 'ltl_doc',
  [TARGET_TYPE_FTL_SHIPMENT]: 'ftl_doc',
};

export type TLtlShipmentAction = TAction & onSaveOperationInterface;

export type TFtlShipmentAction = TAction & onSaveOperationInterface;

export type TShipmentAction = TAction & onSaveOperationInterface;

export type TTargetModel = {
  targetModel: ShipmentInterface | FTLShipmentInterface;
};
export type TTarget = {
  target: 'ltl_shipment' | 'ftl_shipment'; // |
} & TTargetModel;

export type TSendEmailModal = {
  open: boolean;
  admin: any;
  onClose: () => void;
};

export type TAction = {
  task: TaskInterface;
} & TTarget &
  OnDoneInterface;

const DONE = 'DONE';
const INPUT_SR = 'Input SR';
const EDIT_SR = 'Edit SR';
const INPUT_DATE = 'Input Date';
const INPUT_AVAILABLE_TIME = 'Input Available Time';
const INPUT = 'Input';
const INPUT_VENDOR_PU = 'Input Vendor PU';
const INPUT_BR = 'Input BR';
const EDIT_BR = 'Edit BR';
const INPUT_VID = 'Input VID';
const INPUT_PRO_NUMBER = 'Input Pro Number';
const YES = 'YES';
const NO = 'NO';
const UPLOAD = 'UPLOAD';
const RESCHEDULE = 'Reschedule';
const SEND_POD = 'SEND POD';
const SEND_BOL = 'SEND BOL';
const CREAT_BOL = 'CREAT BOL';
const SEND_PU_VERIFICATION = 'SEND PU VERIFICATION';
const SEND_PU_VERIFICATION_SMS = 'SEND PU VERIFICATION SMS';
const SEND_DEL_VERIFICATION = 'SEND DEL VERIFICATION';
const SEND_DEL_VERIFICATION_SMS = 'SEND DEL VERIFICATION SMS';
const SEND_CONFIRMATION = 'Send Confirmation';
const UNDO = 'UNDO';
const INPUT_CACENL_LINK = 'Input Cancel Link';
const RATING = 'Rating';

const ID_LTL_CANCEL_THE_BOOK_IN_BROKER_PLATFORM = 40;
const ID_LTL_CONFIRM_THE_CANCELLATION_WITH_BROKER_THROUGH_EMAIL_PHONE = 41; // TODO

const ACTION_COLORS = {
  YES: 'green',
  NO: 'red',
};

const ALWAY_SHOW_ACTIONS = [
  INPUT_VENDOR_PU,
  INPUT_SR,
  EDIT_SR,
  EDIT_BR,
  INPUT_DATE,
  INPUT_AVAILABLE_TIME,
  RESCHEDULE,
  INPUT_CACENL_LINK,
  RATING,
  INPUT_PRO_NUMBER,
];

const CANCEL_TASKIDS = [
  ID_LTL_CANCEL_THE_BOOK_IN_BROKER_PLATFORM,
  ID_LTL_CONFIRM_THE_CANCELLATION_WITH_BROKER_THROUGH_EMAIL_PHONE,
];

const FORBIDDEN_UNDO_TASKIDS = [
  ID_LTL_CANCEL_THE_BOOK_IN_BROKER_PLATFORM,
  ID_LTL_CONFIRM_THE_CANCELLATION_WITH_BROKER_THROUGH_EMAIL_PHONE,
];

const FORBIDDEN_UNDO_ACTIONS = [RESCHEDULE];

export default {
  DONE,
  INPUT_SR,
  EDIT_SR,
  INPUT_BR,
  EDIT_BR,
  INPUT_VID,
  INPUT_DATE,
  INPUT_AVAILABLE_TIME,
  INPUT,
  INPUT_VENDOR_PU,
  YES,
  NO,
  UPLOAD,
  RESCHEDULE,
  SEND_POD,
  RATING,
  SEND_BOL,
  CREAT_BOL,
  SEND_CONFIRMATION,
  SEND_PU_VERIFICATION,
  SEND_PU_VERIFICATION_SMS,
  SEND_DEL_VERIFICATION,
  SEND_DEL_VERIFICATION_SMS,
  UNDO,
  ACTION_COLORS,
  ALWAY_SHOW_ACTIONS,
  CANCEL_TASKIDS,
  FORBIDDEN_UNDO_ACTIONS,
  INPUT_CACENL_LINK,
  FORBIDDEN_UNDO_TASKIDS,
  INPUT_PRO_NUMBER,
} as const;
