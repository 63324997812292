import React from 'react';
import {
  Form,
  FormInstance,
  Input,
  InputNumber,
  Select,
  Row,
  Col,
  Switch,
  Divider,
} from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import styles from 'res/css/ui.scss';

interface Props {
  form: FormInstance;
  onSubmit: () => void;
}

export const DrawerForm: React.FC<Props> = ({ form, onSubmit }) => {
  return (
    <Form layout="vertical" form={form} onFinish={onSubmit}>
      <Form.Item name="id" noStyle>
        <Input hidden />
      </Form.Item>
      <Row>
        <Col span={11}>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please Input Name' }]}>
            <Input placeholder="Enter name" />
          </Form.Item>
          <Form.Item
            label="Slug"
            name="slug"
            rules={[{ required: true, message: 'Please Input Slug' }]}>
            <Input placeholder="Enter slug" />
          </Form.Item>
          <Form.Item
            label="Subscription Name"
            name="subscription_name"
            rules={[
              { required: true, message: 'Please Input subscription name' },
            ]}>
            <Input placeholder="Enter subscription name" />
          </Form.Item>
          <Form.Item
            label="Stripe Plan"
            name="stripe_plan"
            rules={[{ required: true, message: 'Please Input stripe plan' }]}>
            <Input placeholder="Enter stripe plan" />
          </Form.Item>
          <Form.Item
            label="Cost"
            name="cost"
            rules={[{ required: true, message: 'Please Input cost' }]}>
            <InputNumber
              value={Number(form.getFieldValue('cost'))}
              placeholder="Enter cost"
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item
            label="Billing Interval"
            name="billing_interval"
            rules={[
              { required: true, message: 'Please select billing interval' },
            ]}>
            <Select value={form.getFieldValue('billing_interval')}>
              <Select.Option value="monthly">Monthly</Select.Option>
              <Select.Option value="yearly">Yearly</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            className={styles.customer_switch}
            label="Enabled"
            name="enabled"
            valuePropName="checked"
            rules={[{ required: true }]}>
            <Switch
              className={styles.switch_enabled}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={form.getFieldValue('enabled')}
            />
          </Form.Item>
        </Col>
        {/* <Divider type="vertical" /> */}
        <Col span={1} />
        <Col span={11}>
          <Form.Item name="description" label="Description">
            <Input.TextArea
              value={form.getFieldValue('description')}
              rows={5}
              placeholder="Enter description"
            />
          </Form.Item>
          <Divider />
          <div
            className="ant-row ant-form-item"
            style={{
              textAlign: 'left',
              verticalAlign: 'middle',
              height: '34px',
              lineHeight: '34px',
            }}>
            <div>
              <label title="Settings">Settings</label>
            </div>
          </div>

          <Form.Item
            label="Rate Bookmark Limit"
            name={['settings', 'rate_bookmark_limit']}
            rules={[
              { required: true, message: 'Please Input rate bookmark limit' },
            ]}>
            <Input
              value={form.getFieldValue(['settings', 'rate_bookmark_limit'])}
              placeholder="Enter rate bookmark limit"
            />
          </Form.Item>
          <Form.Item
            label="Rate Search Limit"
            name={['settings', 'rate_search_limit']}
            rules={[
              { required: true, message: 'Please Input rate search limit' },
            ]}>
            <Input
              value={form.getFieldValue(['settings', 'rate_search_limit'])}
              placeholder="Enter rate search limit"
            />
          </Form.Item>
          <Form.Item
            label="Requested Rate Create Limit"
            name={['settings', 'requested_rate_create_limit']}
            rules={[
              {
                required: true,
                message: 'Please Input requested rate create limit',
              },
            ]}>
            <Input
              value={form.getFieldValue([
                'settings',
                'requested_rate_create_limit',
              ])}
              placeholder="Enter requested rate create limit"
            />
          </Form.Item>
          <Form.Item
            label="Statistics Route Search Limit"
            name={['settings', 'statistics_route_search_limit']}
            rules={[
              {
                required: true,
                message: 'Please Input statistics route search limit',
              },
            ]}>
            <Input
              value={form.getFieldValue([
                'settings',
                'statistics_route_search_limit',
              ])}
              placeholder="Enter statistics route search limit"
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
