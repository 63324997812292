import { ContainerTypeSelect } from '@/components/ContainerTypeSelect';
import { TerminalSelect } from '@/components/TerminalSelect';
import {
  Form,
  Row,
  Col,
  Input,
  DatePicker,
  Checkbox,
  Card,
  Table,
  Select,
  InputNumber,
} from 'antd';
import { FormInstance } from 'antd/lib/form';
import { uniqueId } from 'lodash';
import moment from 'moment';
import {
  CHASSIS_OWNER_MAP,
  CHASSIS_OWNER_NO_IDEA,
} from '@/pages/orders/components/data';
import { CHASSIS_TYPE_MAP, CHASSIS_TYPE_NO_IDEA } from './data';
import { validateContainerNumber } from '@/components/ContainerTable';

export const DrawerForm: React.FC<{ form: FormInstance }> = ({ form }) => {
  return (
    <Form form={form} layout="horizontal">
      <Form.Item noStyle name="id"></Form.Item>
      <Row>
        <Col span={24}>
          <Form.Item
            label="#"
            name="number"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            labelAlign="left"
            rules={[
              {
                required: true,
                message: 'Please input order number!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || validateContainerNumber(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'Must be a unique alpha-numeric combination of seven numbers and four letters.',
                    ),
                  );
                },
              }),
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="User ID"
            name="user_id"
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}>
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="Consignee ID"
            name="consignee_id"
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}>
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="Vendor ID"
            name="vendor_id"
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Type"
            name="type"
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            rules={[{ required: true, message: 'Please select type!' }]}>
            <ContainerTypeSelect getValueForOption="code" />
          </Form.Item>
          <Form.Item
            label="Seal No."
            name="seal_number"
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Package"
            name="package"
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            rules={[{ required: true, message: 'Please input package' }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Weight"
            name="weight"
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            rules={[{ required: true, message: 'Please input weight' }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Pickup No."
            name="pickup_number"
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}>
            <Input />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => {
              return (
                <Form.Item
                  label="L.F.D"
                  name="lfd"
                  labelAlign="left"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}>
                  <div>
                    <DatePicker
                      className="w100"
                      style={{ width: '100%' }}
                      value={
                        getFieldValue('lfd')
                          ? moment(getFieldValue('lfd'))
                          : null
                      }
                      onChange={(date, dateString) => {
                        setFieldsValue({
                          lfd: dateString,
                        });
                      }}
                    />
                  </div>
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item
            label="Delivery Reference"
            name="delivery_reference"
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}>
            <Input />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              <Form.Item
                label="POD ETA (synced by D/O)"
                name="port_of_discharge_eta"
                labelAlign="left"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}>
                <div>
                  <DatePicker
                    className="w100"
                    value={
                      getFieldValue('port_of_discharge_eta')
                        ? moment(getFieldValue('port_of_discharge_eta'))
                        : null
                    }
                    disabled={true}
                  />
                </div>
              </Form.Item>;
            }}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              <Form.Item
                label=" Final Port ETA (synced by D/O)"
                name="final_port_eta"
                labelAlign="left"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}>
                <div>
                  <DatePicker
                    className="w100"
                    value={
                      getFieldValue('final_port_eta')
                        ? moment(getFieldValue('final_port_eta'))
                        : null
                    }
                    disabled={true}
                  />
                </div>
                {/* <DatePicker className="w100" disabled={true} /> */}
              </Form.Item>;
            }}
          </Form.Item>

          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <Form.Item
                label="Chassis Owner"
                name="chassis_owner"
                labelAlign="left"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}>
                <Select>
                  {Object.keys(CHASSIS_OWNER_MAP).map((key: string) => {
                    return (
                      <Select.Option key={key} value={Number(key)}>
                        {
                          CHASSIS_OWNER_MAP[
                            key as unknown as keyof typeof CHASSIS_OWNER_MAP
                          ]
                        }
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <Form.Item
                label="Chassis Type"
                name="chassis_type"
                labelAlign="left"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}>
                <Select>
                  {Object.keys(CHASSIS_TYPE_MAP).map((key: string) => {
                    return (
                      <Select.Option key={key} value={Number(key)}>
                        {
                          CHASSIS_TYPE_MAP[
                            key as unknown as keyof typeof CHASSIS_TYPE_MAP
                          ]
                        }
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <Form.Item
                label="Chassis Fee"
                name="chassis_fee"
                labelAlign="left"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}>
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            )}
          </Form.Item>

          <Row gutter={10}>
            <Col span={2}>
              <Form.Item label="DG" name="is_dg" valuePropName="checked">
                <Checkbox />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item label="SOC" name="is_soc" valuePropName="checked">
                <Checkbox />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item
                label="OW"
                name="is_overweight"
                valuePropName="checked">
                <Checkbox />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                label="REEFER"
                name="is_reefer"
                valuePropName="checked">
                <Checkbox />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Card title="Release Status" bordered={false}>
            <Row gutter={10}>
              <Col span={8}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    return (
                      <Form.Item
                        label="Freight Released"
                        name="freight_released_at"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}>
                        <div>
                          <DatePicker
                            className="w100"
                            value={
                              getFieldValue('freight_released_at')
                                ? moment(getFieldValue('freight_released_at'))
                                : null
                            }
                            onChange={(date, dateString) => {
                              form.setFieldsValue({
                                freight_released_at: dateString,
                              });
                            }}
                          />
                        </div>
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    return (
                      <Form.Item
                        label="Customs Released"
                        name="customs_released_at"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}>
                        <div>
                          <DatePicker
                            className="w100"
                            value={
                              getFieldValue('customs_released_at')
                                ? moment(getFieldValue('customs_released_at'))
                                : null
                            }
                            onChange={(date, dateString) => {
                              form.setFieldsValue({
                                customs_released_at: dateString,
                              });
                            }}
                          />
                        </div>
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    return (
                      <Form.Item
                        label="Terminal Released"
                        name="terminal_released_at"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}>
                        <div>
                          <DatePicker
                            className="w100"
                            value={
                              getFieldValue('terminal_released_at')
                                ? moment(getFieldValue('terminal_released_at'))
                                : null
                            }
                            onChange={(date, dateString) => {
                              form.setFieldsValue({
                                terminal_released_at: dateString,
                              });
                            }}
                          />
                        </div>
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24}>
          <Card title="Shipping Status" bordered={false}>
            <Row gutter={10}>
              <Col span={8}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    return (
                      <Form.Item
                        label="Vessel Arrived"
                        name="vessel_arrived_at"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}>
                        <div>
                          <DatePicker
                            className="w100"
                            value={
                              getFieldValue('vessel_arrived_at')
                                ? moment(getFieldValue('vessel_arrived_at'))
                                : null
                            }
                            onChange={(date, dateString) => {
                              form.setFieldsValue({
                                vessel_arrived_at: dateString,
                              });
                            }}
                          />
                        </div>
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    return (
                      <Form.Item
                        label="Load On Rail"
                        name="load_on_rail_at"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}>
                        <div>
                          <DatePicker
                            className="w100"
                            value={
                              getFieldValue('load_on_rail_at')
                                ? moment(getFieldValue('load_on_rail_at'))
                                : null
                            }
                            onChange={(date, dateString) => {
                              form.setFieldsValue({
                                load_on_rail_at: dateString,
                              });
                            }}
                          />
                        </div>
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    return (
                      <Form.Item
                        label="Container Discharged"
                        name="discharged_at"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}>
                        <div>
                          <DatePicker
                            className="w100"
                            value={
                              getFieldValue('discharged_at')
                                ? moment(getFieldValue('discharged_at'))
                                : null
                            }
                            onChange={(date, dateString) => {
                              form.setFieldsValue({
                                discharged_at: dateString,
                              });
                            }}
                          />
                        </div>
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
              {/* <Col span={8}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    return (
                      <Form.Item
                        label="Container Gate Out"
                        name="gate_out_at"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}>
                        <div>
                          <DatePicker
                            className="w100"
                            value={
                              getFieldValue('gate_out_at')
                                ? moment(getFieldValue('gate_out_at'))
                                : null
                            }
                            onChange={(date, dateString) => {
                              form.setFieldsValue({
                                gate_out_at: dateString,
                              });
                            }}
                          />
                        </div>
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col> */}
              <Col span={8}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    return (
                      <Form.Item
                        label="Container Returned"
                        name="empty_returned_at"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}>
                        <div>
                          <DatePicker
                            className="w100"
                            value={
                              getFieldValue('empty_returned_at')
                                ? moment(getFieldValue('empty_returned_at'))
                                : null
                            }
                            onChange={(date, dateString) => {
                              form.setFieldsValue({
                                empty_returned_at: dateString,
                              });
                            }}
                          />
                        </div>
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={24}>
                <h3>Events</h3>
                <Form.Item noStyle shouldUpdate name="shipping_events">
                  <Table
                    rowKey={(row) => uniqueId(row)}
                    columns={[
                      {
                        title: 'Event',
                        dataIndex: 'activity',
                        key: 'activity',
                      },
                      {
                        title: 'Activity',
                        dataIndex: 'activity',
                        key: 'activity',
                      },
                      {
                        title: 'Location',
                        dataIndex: 'location',
                        key: 'location',
                      },
                      {
                        title: 'Vessel',
                        dataIndex: 'vessel',
                        key: 'vessel',
                      },
                      {
                        title: 'Voyage',
                        dataIndex: 'voyage',
                        key: 'voyage',
                      },
                    ]}
                    dataSource={form.getFieldValue('shipping_events') || []}
                    pagination={false}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Card title="Schedule">
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      return (
                        <Form.Item
                          label="Terminal Pickup"
                          name="schedule_terminal_pickup_at"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}>
                          <div>
                            <DatePicker
                              className="w100"
                              value={
                                getFieldValue('schedule_terminal_pickup_at')
                                  ? moment(
                                    getFieldValue(
                                      'schedule_terminal_pickup_at',
                                    ),
                                  )
                                  : null
                              }
                              onChange={(date, dateString) => {
                                form.setFieldsValue({
                                  schedule_terminal_pickup_at: dateString,
                                });
                              }}
                            />
                          </div>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      return (
                        <Form.Item
                          label="Schedule Delivery"
                          name="schedule_delivery_at"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}>
                          <div>
                            <DatePicker
                              className="w100"
                              value={
                                getFieldValue('schedule_delivery_at')
                                  ? moment(
                                    getFieldValue('schedule_delivery_at'),
                                  )
                                  : null
                              }
                              onChange={(date, dateString) => {
                                form.setFieldsValue({
                                  schedule_delivery_at: dateString,
                                });
                              }}
                            />
                          </div>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                  {/* <Form.Item
                    name="schedule_loading_type"
                    label="Drop or Live"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}>
                    <Select>
                      <Select.Option value="Drop">Drop</Select.Option>
                      <Select.Option value="Live">Live</Select.Option>
                    </Select>
                  </Form.Item> */}
                </Card>
              </Col>
              <Col span={12}>
                <Card title="Actual">
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      return (
                        <Form.Item
                          label="Terminal Pickup"
                          name="actual_terminal_pickuped_at"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}>
                          <div>
                            <DatePicker
                              className="w100"
                              value={
                                getFieldValue('actual_terminal_pickuped_at')
                                  ? moment(
                                    getFieldValue(
                                      'actual_terminal_pickuped_at',
                                    ),
                                  )
                                  : null
                              }
                              onChange={(date, dateString) => {
                                form.setFieldsValue({
                                  actual_terminal_pickuped_at: dateString,
                                });
                              }}
                            />
                          </div>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                  {/* <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      return (
                        <Form.Item
                          label="Moved to Exam Site (Optional)"
                          name="actual_moved_to_exam_site_at"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}>
                          <div>
                            <DatePicker
                              className="w100"
                              value={
                                getFieldValue('actual_moved_to_exam_site_at')
                                  ? moment(
                                    getFieldValue(
                                      'actual_moved_to_exam_site_at',
                                    ),
                                  )
                                  : null
                              }
                              onChange={(date, dateString) => {
                                form.setFieldsValue({
                                  actual_moved_to_exam_site_at: dateString,
                                });
                              }}
                            />
                          </div>
                        </Form.Item>
                      );
                    }}
                  </Form.Item> */}
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      return (
                        <Form.Item
                          label="Delivered"
                          name="actual_delivered_at"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}>
                          <div>
                            <DatePicker
                              className="w100"
                              value={
                                getFieldValue('actual_delivered_at')
                                  ? moment(getFieldValue('actual_delivered_at'))
                                  : null
                              }
                              onChange={(date, dateString) => {
                                form.setFieldsValue({
                                  actual_delivered_at: dateString,
                                });
                              }}
                            />
                          </div>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                  {/* <Form.Item
                    name="actual_loading_type"
                    label="Drop or Live"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}>
                    <Select>
                      <Select.Option value="Drop">Drop</Select.Option>
                      <Select.Option value="Live">Live</Select.Option>
                    </Select>
                  </Form.Item> */}
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      return (
                        <Form.Item
                          label="Empty (From Consignee)"
                          name="actual_empty_at"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}>
                          <div>
                            <DatePicker
                              className="w100"
                              value={
                                getFieldValue('actual_empty_at')
                                  ? moment(getFieldValue('actual_empty_at'))
                                  : null
                              }
                              onChange={(date, dateString) => {
                                form.setFieldsValue({
                                  actual_empty_at: dateString,
                                });
                              }}
                            />
                          </div>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      return (
                        <Form.Item
                          label="Empty Return"
                          name="actual_empty_returned_at"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}>
                          <div>
                            <DatePicker
                              className="w100"
                              value={
                                getFieldValue('actual_empty_returned_at')
                                  ? moment(
                                    getFieldValue('actual_empty_returned_at'),
                                  )
                                  : null
                              }
                              onChange={(date, dateString) => {
                                form.setFieldsValue({
                                  actual_empty_returned_at: dateString,
                                });
                              }}
                            />
                          </div>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Card>
              </Col>
              <Col span={12}>
                <Card title="Inside Terminal Exam Site" bordered={false}>
                  {/* <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      return (
                        <Form.Item
                          label="Date"
                          name="inside_terminal_exam_site_at"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}>
                          <div>
                            <DatePicker
                              className="w100"
                              value={
                                getFieldValue('inside_terminal_exam_site_at')
                                  ? moment(
                                    getFieldValue(
                                      'inside_terminal_exam_site_at',
                                    ),
                                  )
                                  : null
                              }
                              onChange={(date, dateString) => {
                                form.setFieldsValue({
                                  inside_terminal_exam_site_at: dateString,
                                });
                              }}
                            />
                          </div>
                        </Form.Item>
                      );
                    }}
                  </Form.Item> */}
                  {/* <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      return (
                        <Form.Item
                          label="Released Date"
                          name="inside_terminal_exam_site_released_at"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}>
                          <div>
                            <DatePicker
                              className="w100"
                              value={
                                getFieldValue(
                                  'inside_terminal_exam_site_released_at',
                                )
                                  ? moment(
                                    getFieldValue(
                                      'inside_terminal_exam_site_released_at',
                                    ),
                                  )
                                  : null
                              }
                              onChange={(date, dateString) => {
                                form.setFieldsValue({
                                  inside_terminal_exam_site_released_at:
                                    dateString,
                                });
                              }}
                            />
                          </div>
                        </Form.Item>
                      );
                    }}
                  </Form.Item> */}
                </Card>
              </Col>
              <Col span={12}>
                <Card title="Outside Terminal Exam Site" bordered={false}>
                  {/* <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      return (
                        <Form.Item
                          label="Date"
                          name="outside_terminal_exam_site_at"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}>
                          <div>
                            <DatePicker
                              className="w100"
                              value={
                                getFieldValue('outside_terminal_exam_site_at')
                                  ? moment(
                                    getFieldValue(
                                      'outside_terminal_exam_site_at',
                                    ),
                                  )
                                  : null
                              }
                              onChange={(date, dateString) => {
                                form.setFieldsValue({
                                  outside_terminal_exam_site_at: dateString,
                                });
                              }}
                            />
                          </div>
                        </Form.Item>
                      );
                    }}
                  </Form.Item> */}
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      return (
                        <Form.Item
                          label="Terminal"
                          name="outside_terminal_exam_site_terminal"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}>
                          <>
                            <TerminalSelect
                              selected={
                                getFieldValue(
                                  'outside_terminal_exam_site_terminal',
                                ) || null
                              }
                              onSelect={(value: any) => {
                                form.setFieldsValue({
                                  outside_terminal_exam_site_terminal: value,
                                });
                              }}
                            />
                          </>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                  {/* <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      return (
                        <Form.Item
                          label="Released Date"
                          name="outside_terminal_exam_site_released_at"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}>
                          <div>
                            <DatePicker
                              className="w100"
                              value={
                                getFieldValue(
                                  'outside_terminal_exam_site_released_at',
                                )
                                  ? moment(
                                    getFieldValue(
                                      'outside_terminal_exam_site_released_at',
                                    ),
                                  )
                                  : null
                              }
                              onChange={(date, dateString) => {
                                form.setFieldsValue({
                                  outside_terminal_exam_site_released_at:
                                    dateString,
                                });
                              }}
                            />
                          </div>
                        </Form.Item>
                      );
                    }}
                  </Form.Item> */}
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Remark To Customer"
            name="remark_to_customer"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}>
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Internal Memo"
            name="internal_memo"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}>
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Consignee Memo"
            name="consignee_memo"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}>
            <Input.TextArea rows={4} disabled />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Vendor Memo"
            name="vendor_memo"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}>
            <Input.TextArea rows={4} disabled />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
