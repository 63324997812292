import { useState, useRef, useEffect } from 'react';
import gsap from 'gsap';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const Slider = ({ children }) => {
  const scrl = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);

  //Slide click
  const slide = (shift: number) => {
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift);

    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  //Anim
  const anim = (e: any) => {
    gsap.from(e.target, { scale: 1 });
    gsap.to(e.target, { scale: 1.5 });
  };
  const anim2 = (e: any) => {
    gsap.from(e.target, { scale: 1.5 });
    gsap.to(e.target, { scale: 1 });
  };

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  useEffect(() => {
    scrollCheck();
  }, [children]);

  return (
    <div className="customer-slider">
      {scrollX !== 0 && (
        <div
          // className="prev"
          onClick={() => slide(-200)}
          onMouseEnter={(e) => anim(e)}
          onMouseLeave={(e) => anim2(e)}>
          <LeftOutlined />
        </div>
      )}
      <ul
        ref={scrl}
        style={{ alignItems: 'center', margin: '0px' }}
        onScroll={scrollCheck}>
        {children}
      </ul>
      {!scrolEnd && (
        <div
          // className="next"
          onClick={() => slide(+200)}
          onMouseEnter={(e) => anim(e)}
          onMouseLeave={(e) => anim2(e)}>
          <RightOutlined />
        </div>
      )}
    </div>
  );
};

export default Slider;
