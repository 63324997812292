import styles from 'res/css/ui.scss';
import { Button, Col, Form, Input, Row, Select, Space } from 'antd';
import { CitySelect } from '@/components/CitySelect';
import { VendorSelect } from '@/components/VendorSelect';
import React from 'react';
import { handleEnterKeyDown } from '@/utils/handleEnterKeyDown';
import { FormInstance } from 'antd/es/form';
import { DROPZONE_TYPE_DRAYEASY, DROPZONE_TYPE_MAP } from './constants';

export type TFilter = {
  loading: boolean;
  filter: FormInstance;
  onSearch: () => void;
};

export const Filter: React.FC<TFilter> = ({ loading, filter, onSearch }) => {
  const handleSearch = () => {
    onSearch();
  };

  const handleReset = () => {
    filter.resetFields();
  };

  return (
    <div className={styles.filter}>
      <div
        style={{
          width: '70%',
        }}>
        <Form
          form={filter}
          layout="vertical"
          onKeyDown={handleEnterKeyDown(() => handleSearch())}>
          <Row gutter={10}>
            <Col span={4}>
              <Form.Item name="type" label="Type">
                <Select
                  options={Object.keys(DROPZONE_TYPE_MAP).map((k: number) => ({
                    value: k,
                    label: DROPZONE_TYPE_MAP[k],
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue, setFieldValue }) => (
                  <Form.Item name="vendor" label="Vendor">
                    <VendorSelect
                      withPossibleCharges
                      style={{ width: '100%' }}
                      selected={getFieldValue('vendor')}
                      onSelect={(vendor) => {
                        setFieldValue('vendor', vendor);
                      }}
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue, setFieldValue }) => (
                  <Form.Item name="city" label="Cities">
                    <CitySelect
                      selected={getFieldValue('city')}
                      onSelect={(city: any) => {
                        setFieldValue('city', city);
                      }}
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label={' '}>
                <Space>
                  <Button
                    type="primary"
                    disabled={loading}
                    onClick={handleSearch}>
                    Search
                  </Button>
                  <Button onClick={handleReset}>Clear All</Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};
