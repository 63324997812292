import { useApp } from '@/utils/useapp';
import { Button, Image, Input, message, Switch } from 'antd';
import Form from 'antd/es/form';
import { Header } from '@/components/CommonHeader';
import { useCallback, useEffect, useState } from 'react';
import styles from 'res/css/ui.scss';

const Index = () => {
  const app = useApp();

  const [isActive, setIsActive] = useState(false);
  const [switchLoading, setSwitchLoading] = useState(false);
  const [qrcode, setQrcode] = useState('');
  const [form] = Form.useForm();

  const getActive = useCallback(async () => {
    try {
      setSwitchLoading(true);
      const { active } = await app.service.get('2fa');
      setIsActive(active);
    } catch (error: any) {
      message.error(error.data?.message || error.data?.error);
    } finally {
      setSwitchLoading(false);
    }
  }, []);

  const handleChange = async (checked: boolean) => {
    try {
      setSwitchLoading(true);
      if (checked) {
        const { qrcode } = await app.service.post('2fa');
        setQrcode(qrcode);
        setIsActive(true);
        app.store.auth.set2faEnabled(true);
      } else {
        await app.service.delete('2fa');
        message.success('Disabled 2FA.');
        await getActive();
        app.store.auth.set2faEnabled(false);
      }
    } catch (error: any) {
      message.error(error.data?.message || error.data?.error);
    } finally {
      setSwitchLoading(false);
    }
  };

  const handleCodeActive = async () => {
    try {
      await app.service.put('2fa/verify', { data: form.getFieldsValue(true) });
      message.success('Enabled 2FA.');
    } catch (error: any) {
      message.error(error.data?.message || error.data?.error);
    }
  };

  useEffect(() => {
    getActive();
  }, []);

  return (
    <div className={styles.main}>
      <Header title="2FA" />
      <div style={{ margin: '18px' }}>
        <div
          style={{
            color: '#004085',
            backgroundColor: '#cce5ff',
            borderColor: '#b8daff',
            padding: '.75rem 1.25rem',
            marginBottom: '1rem',
            border: '1px solid transparent',
            borderRadius: '.25rem',
          }}>
          Add an extra layer of security to your account by requiring access to
          your phone when you log in. You will need an app like{' '}
          <a
            style={{ color: '#002752', fontWeight: '700' }}
            href="https://support.google.com/accounts/answer/1066447?hl=en">
            Google Authenticator
          </a>{' '}
          or{' '}
          <a
            style={{ color: '#002752', fontWeight: '700' }}
            href="https://www.microsoft.com/en-us/security/mobile-authenticator-app">
            Microsoft Authenticator
          </a>{' '}
          to enable it.
        </div>
        <Form.Item label="Two Factor Auth">
          <Switch
            checked={isActive}
            loading={switchLoading}
            onChange={(checked) => handleChange(checked)}
          />
        </Form.Item>

        {!!qrcode && (
          <div>
            <div style={{ marginTop: '18px' }}>
              Scan the QR code and enter the validation code.
            </div>
            <Image src={qrcode} />
            <Form
              form={form}
              onFinish={() => {
                handleCodeActive();
              }}
              layout={'vertical'}>
              <Form.Item name="code" label="Code">
                <Input style={{ width: '200px' }} />
              </Form.Item>
              <Button htmlType="submit" type="primary">
                Active
              </Button>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};

export default Index;
