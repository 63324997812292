import { Input, DatePicker, Typography, Select, Empty } from 'antd';
import type { RecognitionResult, FileStatus } from '../../interface/types';
import { VendorSelect } from '@/components/VendorSelect';
import { TerminalSelect } from '@/components/TerminalSelect';
import { OceanCarrierSelect } from '@/components/OceanCarrierSelect';
import moment from 'moment';

const { Text } = Typography;

interface Props {
  result: RecognitionResult;
  onResultChange?: (key: string, value: any) => void;
  file: FileStatus | null;
}

const BasicInformation = ({ result, onResultChange, file }: Props) => {
  const inputStyle = {
    fontSize: '14px',
    fontWeight: 500,
    padding: '4px 8px',
    border: '1px solid #d9d9d9',
    borderRadius: '4px',
  };

  const handleValueChange = (key: string, value: any) => {
    if (onResultChange) {
      onResultChange(key, value);

      if (key === 'invoice_date' || key === 'due_date') {
        const invoiceDate =
          key === 'invoice_date' ? value : result?.invoice_date;
        const dueDate = key === 'due_date' ? value : result?.due_date;

        if (invoiceDate && dueDate) {
          const start = moment(invoiceDate);
          const end = moment(dueDate);
          const daysDiff = end.diff(start, 'days');

          if (daysDiff >= 0) {
            onResultChange('terms', daysDiff.toString());
          }
        }
      }

      if (key === 'bill_from_name') {
        onResultChange('bill_from_id', value?.id);
        if (result?.bill_from_type === 'App\\Models\\Vendor') {
          const terms = value?.terms;
          if (terms) {
            onResultChange('terms', terms);
            onResultChange(
              'due_date',
              moment(result?.invoice_date)
                .add(terms, 'days')
                .format('YYYY-MM-DD'),
            );
          }
        }
      }
    }
  };

  const renderLTLFTLResults = (result: RecognitionResult) => {
    return (
      <div style={{ padding: '0px' }}>
        {/* Basic Information Section */}
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: '16px',
            marginBottom: '24px',
          }}
        >
          <div>
            <Text
              type="secondary"
              style={{
                fontSize: '12px',
                display: 'block',
                marginBottom: '4px',
              }}
            >
              Invoice No
            </Text>
            <Input
              value={result.invoice_number}
              onChange={(e) =>
                handleValueChange('invoice_number', e.target.value)
              }
              style={inputStyle}
            />
          </div>

          <div>
            <Text
              type="secondary"
              style={{
                fontSize: '12px',
                display: 'block',
                marginBottom: '4px',
              }}
            >
              Invoice Date
            </Text>
            <DatePicker
              value={result.invoice_date ? moment(result.invoice_date) : null}
              onChange={(date) =>
                handleValueChange(
                  'invoice_date',
                  date ? date.format('YYYY-MM-DD') : '',
                )
              }
              style={{ ...inputStyle, width: '100%' }}
            />
          </div>

          <div>
            <Text
              type="secondary"
              style={{
                fontSize: '12px',
                display: 'block',
                marginBottom: '4px',
              }}
            >
              Due Date
            </Text>
            <DatePicker
              value={result.due_date ? moment(result.due_date) : null}
              onChange={(date) =>
                handleValueChange(
                  'due_date',
                  date ? date.format('YYYY-MM-DD') : '',
                )
              }
              style={{ ...inputStyle, width: '100%' }}
            />
          </div>

          <div>
            <Text
              type="secondary"
              style={{
                fontSize: '12px',
                display: 'block',
                marginBottom: '4px',
              }}
            >
              Terms
            </Text>
            <Input
              value={result.terms}
              onChange={(e) => handleValueChange('terms', e.target.value)}
              style={inputStyle}
            />
          </div>

          <div style={{ gridColumn: '1 / -1' }}>
            <Text
              type="secondary"
              style={{
                fontSize: '12px',
                display: 'block',
                marginBottom: '4px',
              }}
            >
              Bill From
            </Text>
            <Input
              value={result.bill_from}
              onChange={(e) => handleValueChange('bill_from', e.target.value)}
              style={inputStyle}
            />
          </div>

          <div style={{ gridColumn: '1 / -1' }}>
            <Text
              type="secondary"
              style={{
                fontSize: '12px',
                display: 'block',
                marginBottom: '4px',
              }}
            >
              Reference No
            </Text>
            <Input
              value={result.reference_number}
              onChange={(e) =>
                handleValueChange('reference_number', e.target.value)
              }
              style={inputStyle}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderDrayResults = (result: RecognitionResult) => {
    return (
      <div style={{ padding: '0px' }}>
        {/* Basic Information Section */}
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: '16px',
            marginBottom: '24px',
          }}
        >
          {/* Invoice No */}
          <div style={{ gridColumn: '1 / -1' }}>
            <Text
              type="secondary"
              style={{
                fontSize: '12px',
                display: 'block',
                marginBottom: '4px',
              }}
            >
              Invoice No
            </Text>
            <Input
              value={result.invoice_number}
              onChange={(e) =>
                handleValueChange('invoice_number', e.target.value)
              }
              style={inputStyle}
            />
          </div>

          {/* Invoice Date */}
          <div>
            <Text
              type="secondary"
              style={{
                fontSize: '12px',
                display: 'block',
                marginBottom: '4px',
              }}
            >
              Invoice Date
            </Text>
            <DatePicker
              value={result.invoice_date ? moment(result.invoice_date) : null}
              onChange={(date) =>
                handleValueChange(
                  'invoice_date',
                  date ? date.format('YYYY-MM-DD') : '',
                )
              }
              style={{ ...inputStyle, width: '100%' }}
            />
          </div>

          {/* Terms */}
          <div>
            <Text
              type="secondary"
              style={{
                fontSize: '12px',
                display: 'block',
                marginBottom: '4px',
              }}
            >
              Terms
            </Text>
            <Input
              value={result.terms}
              onChange={(e) => handleValueChange('terms', e.target.value)}
              style={inputStyle}
            />
          </div>

          {/* Due Date */}
          <div>
            <Text
              type="secondary"
              style={{
                fontSize: '12px',
                display: 'block',
                marginBottom: '4px',
              }}
            >
              Due Date
            </Text>
            <DatePicker
              value={result.due_date ? moment(result.due_date) : null}
              onChange={(date) =>
                handleValueChange(
                  'due_date',
                  date ? date.format('YYYY-MM-DD') : '',
                )
              }
              style={{ ...inputStyle, width: '100%' }}
            />
          </div>

          <div style={{ gridColumn: '1 / -1' }}>
            <Text
              type="secondary"
              style={{
                fontSize: '12px',
                display: 'block',
                marginBottom: '4px',
              }}
            >
              Bill To Type
            </Text>
            <Input.Group compact style={{ width: '100%' }}>
              <Select
                style={{ width: '20%' }}
                value={result.bill_from_type}
                onChange={(value) => handleValueChange('bill_from_type', value)}
              >
                <Select.Option value="App\Models\Vendor">Vendor</Select.Option>
                <Select.Option value="App\Models\Terminal">
                  Terminal
                </Select.Option>
                <Select.Option value="App\Models\OceanCarrier">
                  OceanCarrier
                </Select.Option>
              </Select>
              <div style={{ width: '80%' }}>
                {result.bill_from_type === 'App\\Models\\Vendor' && (
                  <VendorSelect
                    selected={result.bill_from_name}
                    onSelect={(row: any) =>
                      handleValueChange('bill_from_name', row)
                    }
                  />
                )}
                {result.bill_from_type === 'App\\Models\\Terminal' && (
                  <TerminalSelect
                    selected={result.bill_from_name}
                    onSelect={(row) => handleValueChange('bill_from_name', row)}
                  />
                )}
                {result.bill_from_type === 'App\\Models\\OceanCarrier' && (
                  <OceanCarrierSelect
                    style={{ width: '100%' }}
                    value={result.bill_from_id}
                    onSelect={(row) => handleValueChange('bill_from_name', row)}
                  />
                )}
              </div>
            </Input.Group>
          </div>

          <div style={{ gridColumn: '1 / -1' }}>
            <Text
              type="secondary"
              style={{
                fontSize: '12px',
                display: 'block',
                marginBottom: '4px',
              }}
            >
              Bill From
            </Text>
            <Input
              value={result.bill_from}
              onChange={(e) => handleValueChange('bill_from', e.target.value)}
              style={inputStyle}
            />
          </div>

          {/* Container No */}
          <div>
            <Text
              type="secondary"
              style={{
                fontSize: '12px',
                display: 'block',
                marginBottom: '4px',
              }}
            >
              Container No
            </Text>
            <Input
              value={result.container_number}
              onChange={(e) =>
                handleValueChange('container_number', e.target.value)
              }
              style={inputStyle}
            />
          </div>

          {/* MBL No */}
          <div>
            <Text
              type="secondary"
              style={{
                fontSize: '12px',
                display: 'block',
                marginBottom: '4px',
              }}
            >
              MBL No
            </Text>
            <Input
              value={result.mbl_number}
              onChange={(e) => handleValueChange('mbl_number', e.target.value)}
              style={inputStyle}
            />
          </div>

          {/* Reference No */}
          <div style={{ gridColumn: '1 / -1' }}>
            <Text
              type="secondary"
              style={{
                fontSize: '12px',
                display: 'block',
                marginBottom: '4px',
              }}
            >
              Reference No
            </Text>
            <Input
              value={result.reference_number}
              onChange={(e) =>
                handleValueChange('reference_number', e.target.value)
              }
              style={inputStyle}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {result ? (
        ['LTL', 'FTL'].includes(file?.invoiceType || '') ? (
          renderLTLFTLResults(result)
        ) : (
          renderDrayResults(result)
        )
      ) : (
        <Empty description="No recognition results" />
      )}
    </>
  );
};

export default BasicInformation;
