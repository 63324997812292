import { useApp } from '@/utils/useapp';
import { message, Table, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useMemo, useState } from 'react';
import { EnterOutlined } from '@ant-design/icons';
import TimeText from '@/pages/dispatchs/components/RerserveTime/TimeText';
import { RESCHEDULED_DUE_TO_MAP } from './data';
import type { ColumnsType } from 'antd/lib/table';
import { truncate, get } from 'lodash';
import { TAction } from '../../Interfaces/ActionInterface';

export const HistoryReschedules: React.FC<
TAction & {
  onEdit: (record: any) => void;
  toRefresh: boolean;
}
> = ({ target, targetModel, task, toRefresh = false, onEdit }) => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = useState();

  const fetchData = React.useCallback(async () => {
    if (!targetModel?.id) {
      return false;
    }

    setLoading(true);

    try {
      const result = await app.service.get(
        `task_reschedules/${target}/${targetModel.id}/${task.id}`,
        {
          params: {},
        },
      );

      setData(result.data);
    } catch (e: any) {
      message.error(e.error || 'System Error');
    }

    setLoading(false);
  }, []);

  const handleEdit = async (record: any) => {
    onEdit(record);
  };

  useEffect(() => {
    target && targetModel?.id && fetchData();
  }, [targetModel, target]);

  useEffect(() => {
    if (toRefresh) {
      fetchData();
    }
  }, [toRefresh]);

  const columns: ColumnsType<any> = React.useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 80,
      },
      {
        title: 'Reschedule due to',
        dataIndex: 'reschedule_due_to',
        key: 'reschedule_due_to',
        width: 160,
        sorter: true,
        render: (text, record) =>
          RESCHEDULED_DUE_TO_MAP[
            (text as unknown) as keyof typeof RESCHEDULED_DUE_TO_MAP
          ],
      },
      {
        title: 'Reschedule At',
        dataIndex: 'reschedule_at',
        key: 'reschedule_at',
        width: 160,
        sorter: true,
        render: (text, record) => {
          console.log('record', record.reschedule_at_reserve_time?.time);
          return (
            <div>
              <div>{text}</div>
              <div>
                <TimeText time={record.reschedule_at_reserve_time?.time} />
              </div>
            </div>
          );
        },
      },
      {
        title: 'Front Link',
        dataIndex: 'front_link',
        key: 'front_link',
        width: 100,
        render: (text, record) => (
          <a href={text} target="_blank" rel="noreferrer">
            <EnterOutlined />
          </a>
        ),
      },
      {
        title: 'Reason',
        dataIndex: 'reason',
        key: 'reason',
        width: 200,
        render: (text, record) =>
          truncate(get(record, 'reason', ''), {
            length: 300,
          }),
      },

      {
        title: 'Creator',
        dataIndex: ['creator', 'name'],
        key: 'creator_name',
        width: 100,
      },
      {
        align: 'center',
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 150,
        render: (text, record) => (
          <Space>
            <a onClick={() => handleEdit(record)}>Edit</a>
          </Space>
        ),
      },
    ],
    [data],
  );

  return (
    <Table
      loading={loading}
      pagination={false}
      size="small"
      rowKey="id"
      columns={columns}
      dataSource={data || []}
      sticky
      scroll={{
        x: 'auto',
      }}
    />
  );
};
