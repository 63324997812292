import { CitySelect } from '@/components/CitySelect';
import { PortSelect } from '@/components/PortSelect';
import { UserSelect } from '@/components/UserSelect';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { Button, Col, Form, Input, Row, Select, Space } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import styles from 'res/css/ui.scss';

interface SpotRateFilterProps {
  form: FormInstance<any>;
  onFinish: () => any;
  onSearch: () => any;
}

const Index = ({ form, onFinish, onSearch }: SpotRateFilterProps) => {
  return (
    <div className={styles.filter}>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        style={{ width: '100%' }}>
        <Row gutter={16}>
          <Col flex="1 1 0">
            <Form.Item name="id" label="ID">
              <Input />
            </Form.Item>
          </Col>
          <Col flex="1 1 0">
            <Form.Item name="name" label="Name">
              <Input />
            </Form.Item>
          </Col>
          <Col flex="1 1 0">
            <Form.Item name="sales_id" label="Sales">
              <UserSyncSelect type="admin" allowClear />
            </Form.Item>
          </Col>
          <Col flex="1 1 0">
            <Form.Item name="type" label="Type">
              <Select defaultValue={''}>
                <Select.Option value="">{''}</Select.Option>
                <Select.Option value="customer">{'customer'}</Select.Option>
                <Select.Option value="agent">{'agent'}</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col flex="1 1 0">
            <Form.Item name="user" label="Customer">
              <UserSyncSelect getValueForOption="name" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col flex="1 1 0">
            <Form.Item name="pod" label="POD">
              <PortSelect getValueForOption="name" />
            </Form.Item>
          </Col>
          <Col flex="1 1 0">
            <Form.Item name="del" label="DEL(IR)">
              <PortSelect getValueForOption="name" />
            </Form.Item>
          </Col>
          <Col flex="1 1 0">
            <Form.Item noStyle shouldUpdate>
              {() => (
                <Form.Item label="F.Dest">
                  <CitySelect
                    selected={form.getFieldValue('fdest')}
                    onSelect={(v) => {
                      form.setFieldValue('fdest', v);
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
          <Col flex="1 1 0"></Col>
          <Col flex="1 1 0"></Col>
        </Row>

        <Row>
          <Col>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit" onClick={onSearch}>
                  Search
                </Button>
                <Button onClick={() => form.resetFields()}>Clear All</Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Index;
