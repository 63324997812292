import {
  Button,
  Checkbox,
  Col,
  Tooltip,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Switch,
  Typography,
  Card,
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { CitySelect } from '@/components/CitySelect';
import { VendorSelect } from '@/components/VendorSelect';
import { IntermodalRegionSelect } from '@/components/IntermodalRegionSelect';
import { FacilityTypeSelect } from '@/components/FacilityTypeSelect';
import type { FormInstance } from 'antd/es/form';
import { DeleteFilled } from '@ant-design/icons';
import { includes } from 'lodash';
import moment from 'moment';
import { useApp } from '@/utils/useapp';
import { COPY } from '@/pages/rates/buyRates/index';
import { Documents } from '@/components/Upload/Documents';
import { TerminalsSelect } from '@/components/TerminalsSelect';
import { AntDDatePicker } from 'antd-datepicker';
import ContainerSizeSelect from '@/components/ContainerSizeSelect';
import { PossibleCharges } from '@/components/PossibleCharges';
import { useWatch } from 'antd/es/form/Form';
import { BillingCodeSelect } from '@/components/BillingCodeSelect';
import { RenderMissingVendorPossibleChargeTags } from './RenderMissingVendorPossibleChargeTag';
import { TModifyProps } from '@/components/Rate/data';
import { SpecialTerms } from '@/pages/entity/vendors/components/VendorDrawer/SpecialTermsTab';
import CurrencyView from '@/components/CurrencyView';
import { DocumentsStoreLocalFormItem } from '@/components/Upload/DocumentsStoreLocalFormItem';

const { Title } = Typography;

export const RATEITEM_TO_TYPE_BASE_RATE = 1;
export const RATEITEM_TO_TYPE_FUEL_SURCHARGE = 2;
export const RATEITEM_TO_TYPE_CHASSIS_FEE = 3;
export const RATEITEM_TO_TYPE_CHASSIS_SPLIT_FEE = 4;
export const RATEITEM_TO_TYPE_OTHER = 0;

export const RATEITEM_UNIT_CNTR = 'CNTR';
export const RATEITEM_UNIT_DAY = 'DAY';
export const RATEITEM_UNIT_TIME = 'TIME';

export const RATEITEM_UNITS = [
  RATEITEM_UNIT_CNTR,
  RATEITEM_UNIT_DAY,
  RATEITEM_UNIT_TIME,
];

type TRateItem = {
  type: number;
  name: string;
  rate: number;
  unit: string;
  qty: number;
  display_rate: number;
  currency: string;
};

const USD = 'USD';

const convert = (rate = 0, currencyRate = 1) => {
  return +(Math.round(+rate * +currencyRate * 100) / 100).toFixed(2);
};

const RateItems: React.FC<{
  form: FormInstance;
  disabled?: boolean;
  currencyRate?: number;
}> = ({ form, disabled, currencyRate = null }) => {
  // const [currency, setCurrency] = useState(USD);
  const [init, setInit] = useState(true);
  const items = useWatch('regular_rate_items', form);
  const otherItems = useWatch('other_rate_items', form) || [];
  const dropFee = useWatch('drop_fee', form);
  const vendor = useWatch('vendor', form);

  const currency = useMemo(() => {
    return vendor?.currency || 'USD';
  }, [vendor]);

  useEffect(() => {
    const data = items;
    if (init && data && data[0] && data[0].id && currencyRate) {
      const charges = data[0] ? [...data] : [];
      const _otherItems = [...otherItems];
      for (let i = 0; i < charges.length; i++) {
        charges[i].display_rate =
          charges[i].currency == USD
            ? charges[i].rate
            : convert(charges[i].rate, currencyRate);
      }

      for (let i = 0; i < _otherItems.length; i++) {
        _otherItems[i].display_rate =
          _otherItems[i].currency == USD
            ? _otherItems[i].rate
            : convert(_otherItems[i].rate, currencyRate);
      }

      // setCurrency(data[0]?.currency || USD);

      form.setFieldsValue({
        regular_rate_items: charges,
        other_rate_items: _otherItems,
      });

      setInit(false);
    }
  }, [items, otherItems, currencyRate]);

  const regularCharge = () => {
    const rate_items = form.getFieldValue('regular_rate_items') || [];
    return rate_items.reduce((accumulator: any, rateItem: TRateItem) => {
      return accumulator + (rateItem.display_rate || 0) * (rateItem.qty || 1);
    }, 0);
  };

  const otherCharge = () => {
    const rate_items = form.getFieldValue('other_rate_items') || [];
    return rate_items.reduce((accumulator: any, rateItem: TRateItem) => {
      return accumulator + (rateItem.display_rate || 0) * (rateItem.qty || 1);
    }, 0);
  };

  const totalRate = () => {
    return otherCharge() + regularCharge();
  };

  const handleAddRateItem = () => {
    const regular_rate_items = form.getFieldValue('regular_rate_items');
    if (!regular_rate_items || regular_rate_items.length < 2) {
      const items = regular_rate_items || [];
      if (!items.find((item: any) => item.type == RATEITEM_TO_TYPE_BASE_RATE)) {
        items.unshift({
          type: RATEITEM_TO_TYPE_BASE_RATE,
          name: 'Base Rate',
          rate: '',
          unit: RATEITEM_UNIT_CNTR,
          qty: 1,
          currency: currency,
          // currency_rate: currencyRate,
        });
      }
      if (
        !items.find((item: any) => item.type == RATEITEM_TO_TYPE_FUEL_SURCHARGE)
      ) {
        items.push({
          type: RATEITEM_TO_TYPE_FUEL_SURCHARGE,
          name: 'Fuel Surcharge',
          rate: '',
          unit: RATEITEM_UNIT_CNTR,
          qty: 1,
          currency: currency,
          // currency_rate: currencyRate,
        });
      }
      form.setFieldsValue({
        regular_rate_items: items,
      });
    } else {
      form.setFieldsValue({
        other_rate_items: [
          ...form.getFieldValue('other_rate_items'),
          {
            code: '',
            type: RATEITEM_TO_TYPE_OTHER,
            name: '',
            rate: 0,
            unit: RATEITEM_UNIT_CNTR,
            qty: 1,
            amount: 0,
            currency: currency,
            // currency_rate: currencyRate,
          },
        ],
      });
    }
  };

  const handleRemoveRateItem = (index: number) => {
    const other_rate_items = [...form.getFieldValue('other_rate_items')];
    other_rate_items.splice(index, 1);
    form.setFieldsValue({
      other_rate_items: other_rate_items,
    });
  };

  const handleRegularRateChange = (e: any, index: number) => {
    const rates = form.getFieldValue('regular_rate_items');
    const { name, value } = e.target;
    rates[index]['rate'] =
      rates[index]['currency'] === USD ? +value : +value / currencyRate;
    rates[index]['display_rate'] = +value;
    rates[index]['amount'] = +rates[index]['rate'] * +rates[index]['qty'];

    form.setFieldsValue({
      regular_rate_items: rates,
    });
  };

  const handleRegularQtyChange = (e: any, index: number) => {
    const rates = form.getFieldValue('regular_rate_items');
    const { name, value } = e.target;

    rates[index]['qty'] = value;
    rates[index]['amount'] = +rates[index]['rate'] * +value;

    form.setFieldsValue({
      regular_rate_items: rates,
    });
  };

  const handleOtherRateChange = (e: any, index: number) => {
    const rates = form.getFieldValue('other_rate_items');
    const { name, value } = e.target;

    rates[index]['rate'] =
      rates[index]['currency'] === USD ? +value : +value / currencyRate;
    rates[index]['display_rate'] = +value;
    rates[index]['amount'] = +value * +rates[index]['qty'];

    form.setFieldsValue({
      other_rate_items: rates,
    });
  };

  const handleOtherQtyChange = (e: any, index: number) => {
    const rates = form.getFieldValue('other_rate_items');
    const { name, value } = e.target;

    rates[index]['qty'] = value;
    rates[index]['amount'] = +rates[index]['rate'] * +value;

    form.setFieldsValue({
      other_rate_items: rates,
    });
  };

  const handlePercentageChange = (e: any, index: number) => {
    const rates = form.getFieldValue('regular_rate_items');
    const { name, value } = e.target;
    const base = form.getFieldValue('regular_rate_items')[0].rate;
    if (!value) {
      rates[index]['rate'] = 0;
      rates[index]['amount'] = 0;
      rates[index]['display_rate'] = 0;
    } else {
      rates[index]['rate'] = (base * +value) / 100;
      rates[index]['amount'] = (base * +value) / 100;
      rates[index]['display_rate'] = convert(
        rates[index]['rate'],
        rates[index]['currency'] != USD ? currencyRate : 1,
        // rates[index]['currency_rate'],
      );
    }

    form.setFieldsValue({
      regular_rate_items: rates,
    });
  };

  const toPercentage = (value: number) => {
    const base = form.getFieldValue('regular_rate_items')
      ? form.getFieldValue('regular_rate_items')[0].rate
      : 0;
    return value ? Math.round((value / base) * 10000) / 100 : '';
  };

  const onCurrencyChange = (value: string) => {
    // setCurrency(value);
    if (!currencyRate || !value) {
      return;
    }
    const data = form.getFieldValue('regular_rate_items');
    if (!data) {
      return;
    }
    const charges = [...data];
    const _otherItems = [...form.getFieldValue('other_rate_items')];
    const currency_rate = +currencyRate;
    // setCurrencyRate(currency_rate);
    for (let i = 0; i < charges.length; i++) {
      charges[i].rate =
        value == USD
          ? charges[i].display_rate
          : charges[i].display_rate / currency_rate;
      charges[i].currency = value;
    }

    for (let i = 0; i < _otherItems.length; i++) {
      _otherItems[i].rate =
        value == USD
          ? _otherItems[i].display_rate
          : _otherItems[i].display_rate / currency_rate;
      _otherItems[i].currency = value;
    }

    form.setFieldsValue({
      regular_rate_items: charges,
      other_rate_items: _otherItems,
    });
  };

  useEffect(() => {
    onCurrencyChange(currency);
  }, [currency]);

  return (
    <>
      <h4>
        <Space>
          Fixed Charges
          <Button onClick={handleAddRateItem}>Add</Button>
          {/* <Select
            disabled
            value={currency}
            onSelect={onCurrencyChange}
            options={[
              { label: USD, value: USD },
              { label: 'CAD', value: 'CAD' },
            ]}></Select> */}
          {currency !== USD && (
            <CurrencyView
              data={[
                ...(form.getFieldValue('regular_rate_items') || []),
                ...(form.getFieldValue('other_rate_items') || []),
              ]}
            />
          )}
        </Space>
      </h4>
      <Row gutter={5}>
        <Col span={6}>
          <Title level={5}>Name</Title>
        </Col>
        <Col span={7}>
          <Title level={5}>Rate</Title>
        </Col>
        <Col span={3}>
          <Title level={5}>Unit</Title>
        </Col>
        <Col span={2}>
          <Title level={5}>Qty</Title>
        </Col>
        <Col span={4}>
          <Title level={5}>Amount</Title>
        </Col>
      </Row>
      {form.getFieldValue('regular_rate_items') &&
        form
          .getFieldValue('regular_rate_items')
          .map((item: TRateItem, index: number) => {
            return (
              <Row key={`regular_rate_items_${index}`} gutter={5}>
                <Form.Item
                  name={['regular_rate_items', index, 'rate']}
                  hidden
                  className="mb-sm"
                >
                  <Input />
                </Form.Item>
                <Col span={6}>
                  <Form.Item
                    className="mb-sm"
                    name={['regular_rate_items', index, 'name']}
                  >
                    <Input
                      className="mb0"
                      disabled={
                        item.type !== RATEITEM_TO_TYPE_OTHER || disabled
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  {includes(['fuel surcharge'], item.name?.toLowerCase()) ? (
                    <Row gutter={1}>
                      <Col span={14}>
                        <Form.Item
                          className="mb-sm"
                          name={['regular_rate_items', index, 'rate']}
                          hidden
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item className="mb-sm">
                          <Input
                            type="number"
                            name="display_rate"
                            value={item.display_rate}
                            disabled={disabled}
                            onChange={(e) => handleRegularRateChange(e, index)}
                            addonAfter={item.currency}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item className="mb-sm" shouldUpdate>
                          {() => {
                            return (
                              <Input
                                type="number"
                                disabled={disabled}
                                value={toPercentage(item.rate)}
                                onChange={(e) =>
                                  handlePercentageChange(e, index)
                                }
                                addonAfter="%"
                              />
                            );
                          }}
                        </Form.Item>
                      </Col>
                    </Row>
                  ) : (
                    <Form.Item className="mb-sm">
                      <Form.Item
                        className="mb-sm"
                        name={['regular_rate_items', index, 'rate']}
                        hidden
                      >
                        <Input />
                      </Form.Item>
                      <Input
                        type="number"
                        disabled={disabled}
                        name="rate"
                        value={item.display_rate}
                        onChange={(e) => handleRegularRateChange(e, index)}
                        addonAfter={item.currency}
                      />
                    </Form.Item>
                  )}
                </Col>
                <Col span={3}>
                  <Form.Item
                    className="mb-sm"
                    name={['regular_rate_items', index, 'unit']}
                  >
                    <Select disabled={disabled}>
                      {RATEITEM_UNITS.map((v) => (
                        <Select.Option key={v} value={v}>
                          {' '}
                          {v}{' '}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item className="mb-sm">
                    <Input
                      disabled={disabled}
                      type="number"
                      value={item.qty}
                      onChange={(e) => handleRegularQtyChange(e, index)}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name={['regular_rate_items', index, 'amount']}
                    hidden
                  >
                    <Input type="number" disabled />
                  </Form.Item>
                  <Form.Item className="mb-sm" shouldUpdate noStyle>
                    {({ getFieldValue }) => {
                      return (
                        <Form.Item className="mb-sm">
                          <Input
                            type="number"
                            value={
                              getFieldValue([
                                'regular_rate_items',
                                index,
                                'display_rate',
                              ]) *
                              getFieldValue([
                                'regular_rate_items',
                                index,
                                'qty',
                              ])
                            }
                            disabled
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
              </Row>
            );
          })}
      {form.getFieldValue('other_rate_items') &&
        form
          .getFieldValue('other_rate_items')
          .map((item: TRateItem, index: number) => {
            const isDisabled =
              !!form.getFieldValue([
                'other_rate_items',
                index,
                'possible_charge_id',
              ]) || disabled;
            return (
              <Row key={`other_rate_items_${index}`} gutter={5}>
                <Col span={4}>
                  <Form.Item
                    name={['other_rate_items', index, 'rate_id']}
                    noStyle
                  >
                    <Input hidden />
                  </Form.Item>
                  <Form.Item name={['other_rate_items', index, 'type']} noStyle>
                    <Input hidden />
                  </Form.Item>
                  <Form.Item
                    name={['other_rate_items', index, 'possible_charge']}
                    noStyle
                  >
                    <Input hidden />
                  </Form.Item>
                  <Form.Item shouldUpdate noStyle>
                    {({ getFieldValue, setFieldsValue }) => (
                      <Form.Item
                        name={['other_rate_items', index, 'code']}
                        noStyle
                        rules={[{ required: true, message: '' }]}
                      >
                        <BillingCodeSelect
                          disabled={isDisabled}
                          style={{ width: '100%' }}
                          value={getFieldValue([
                            'other_rate_items',
                            index,
                            'code',
                          ])}
                          onSelect={(c) =>
                            setFieldsValue({
                              other_rate_items: getFieldValue(
                                'other_rate_items',
                              ).map((row: any, i: number) =>
                                i == index
                                  ? {
                                      ...row,
                                      code: c?.code,
                                      name: c?.name,
                                    }
                                  : row,
                              ),
                            })
                          }
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item
                    className="mb-sm"
                    name={['other_rate_items', index, 'name']}
                    rules={[{ required: true, message: '' }]}
                  >
                    <Input className="mb0" disabled={isDisabled} />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    className="mb-sm"
                    name={['other_rate_items', index, 'rate']}
                    rules={[{ required: true, message: '' }]}
                    hidden
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    className="mb-sm"
                    name={['other_rate_items', index, 'currency']}
                    rules={[{ required: true, message: '' }]}
                    hidden
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    className="mb-sm"
                    name={['other_rate_items', index, 'display_rate']}
                    rules={[{ required: true, message: '' }]}
                  >
                    <Input
                      type="number"
                      name="rate"
                      value={item.rate}
                      onChange={(e) => handleOtherRateChange(e, index)}
                      disabled={isDisabled}
                      addonAfter={form.getFieldValue([
                        'other_rate_items',
                        index,
                        'currency',
                      ])}
                    />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item
                    className="mb-sm"
                    name={['other_rate_items', index, 'unit']}
                    rules={[{ required: true, message: '' }]}
                  >
                    <Select disabled={isDisabled}>
                      {RATEITEM_UNITS.map((v) => (
                        <Select.Option key={v} value={v}>
                          {' '}
                          {v}{' '}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item
                    className="mb-sm"
                    name={['other_rate_items', index, 'qty']}
                    rules={[{ required: true, message: '' }]}
                  >
                    <Input
                      type="number"
                      value={item.qty}
                      onChange={(e) => {
                        handleOtherQtyChange(e, index);
                      }}
                      disabled={isDisabled}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    className="mb-sm"
                    name={['other_rate_items', index, 'amount']}
                    hidden
                  >
                    <Input type="number" disabled />
                  </Form.Item>
                  <Form.Item className="mb-sm" shouldUpdate noStyle>
                    {({ getFieldValue }) => {
                      return (
                        <Form.Item className="mb-sm">
                          <Input
                            type="number"
                            value={
                              getFieldValue([
                                'other_rate_items',
                                index,
                                'display_rate',
                              ]) *
                              getFieldValue(['other_rate_items', index, 'qty'])
                            }
                            disabled
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={2}>
                  {!isDisabled && (
                    <Button onClick={() => handleRemoveRateItem(index)}>
                      <DeleteFilled />
                    </Button>
                  )}
                </Col>
              </Row>
            );
          })}
      <Divider />
      <Row gutter={5}>
        <Col span={22}>
          <Space
            style={{ width: '100%' }}
            size={'small'}
            align={'end'}
            direction={'vertical'}
          >
            <Title className="mb0" level={5}>
              Regular Charge {regularCharge().toFixed(2)}
            </Title>
            <Title className="mb0" level={5}>
              Other Charge {otherCharge().toFixed(2)}
            </Title>
            <Title className="mb-md" level={5}>
              Total Rate {totalRate().toFixed(2)}
            </Title>
            <Form.Item noStyle name="drop_fee">
              <Title className="mb0" level={5}>
                Drop Fee: {dropFee || '-'}
              </Title>
            </Form.Item>
          </Space>
        </Col>
      </Row>
    </>
  );
};

export const BuyRateForm: React.FC<
  {
    form: FormInstance;
    isNew?: boolean;
    disabled?: boolean;
    id: number;
  } & TModifyProps
> = ({ form, isNew = false, id, disabled = false, banModifyFields = [] }) => {
  const [displayMemo, setDisplayMemo] = useState(
    form.getFieldValue('memo') ? true : false,
  );

  const [initPossibleCharges, setInitPossibleCharges] = useState(false);

  const [currencyRate, setCurrencyRate] = useState<number | undefined>();
  const app = useApp();

  const getCurrencyRate = async () => {
    try {
      const resp = await app.service.get('currencyRate');
      setCurrencyRate(resp.data.rates['CAD']);
    } catch (err: any) {
      message.error(err?.data?.message || err?.data?.error);
    }
  };

  useEffect(() => {
    getCurrencyRate();
  }, []);

  const handleChange = async (event: any) => {
    const { name, value } = event.target as HTMLInputElement;
    await form.setFieldsValue({ [name]: value });
  };

  const handlePreviewMessage = (messageId: string) => {
    const frontappUrl = `https://app.frontapp.com/open/${messageId}`;
    window.open(frontappUrl, '_blank');
  };

  const handleDownloadFrontappMessage = async (messageId: string) => {
    if (!messageId) {
      return false;
    }

    try {
      const resp = await app.service.get(
        `rates/frontapp/download/${messageId}`,
      );
      form.setFieldValue('memo', resp.data);
      setDisplayMemo(true);
    } catch (err: any) {
      message.error(err?.data?.message || err?.data?.error || 'System Error');
    }
  };

  const handleFormValuesChange = (values: any) => {
    const attributeToBillingCodeMap = {
      is_haz: 'HAZCA',
      is_soc: 'SOCRT',
      is_ow: 'OVECH',
      is_reefer: 'REESU',
    };

    Object.keys(attributeToBillingCodeMap).map((k) => {
      if (values[k] !== undefined) {
        if (values[k]) {
          const ps = form.getFieldValue(['vendor', 'possible_charges']);

          if (ps) {
            const charge = ps.find(
              (p: any) => p.code == attributeToBillingCodeMap[k],
            );
            if (charge) {
              form.setFieldsValue({
                other_rate_items: [
                  ...form.getFieldValue('other_rate_items'),
                  {
                    code: charge.code,
                    type: RATEITEM_TO_TYPE_OTHER,
                    name: charge.name,
                    rate: charge.rate,
                    unit: RATEITEM_UNIT_CNTR,
                    qty: 1,
                    amount: charge.rate,
                  },
                ],
              });
            }
          }
        } else {
          form.setFieldsValue({
            other_rate_items: (
              form.getFieldValue('other_rate_items') || []
            ).filter((item) => item.code != attributeToBillingCodeMap[k]),
          });
        }
      }
    });
  };

  useEffect(() => {
    if (!isNew && id && id != COPY) {
      return;
    }
    if (id !== COPY) {
      form.setFieldsValue({
        regular_rate_items: [
          {
            type: RATEITEM_TO_TYPE_BASE_RATE,
            name: 'Base Rate',
            rate: '',
            unit: RATEITEM_UNIT_CNTR,
            currency: USD,
            qty: 1,
          },
          {
            type: RATEITEM_TO_TYPE_FUEL_SURCHARGE,
            name: 'Fuel Surcharge',
            rate: '',
            unit: RATEITEM_UNIT_CNTR,
            currency: USD,
            qty: 1,
          },
          // {
          //   type: RATEITEM_TO_TYPE_CHASSIS_FEE,
          //   name: 'Chassis Fee',
          //   rate: '',
          //   unit: RATEITEM_UNIT_DAY,
          //   qty: 1,
          // },
          // {
          //   type: RATEITEM_TO_TYPE_CHASSIS_SPLIT_FEE,
          //   name: 'Chassis Split Fee',
          //   rate: '',
          //   unit: RATEITEM_UNIT_TIME,
          //   qty: 1,
          // },
        ],
        other_rate_items: [],
      });
    }
  }, []);

  return (
    <div>
      <Form
        form={form}
        disabled={disabled}
        onValuesChange={handleFormValuesChange}
      >
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Row gutter={10}>
          <Col span={12}>
            <Row gutter={5}>
              <Col span={18}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => (
                    <Form.Item
                      name="vendor"
                      label="Vendor"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[{ required: true }]}
                    >
                      <VendorSelect
                        withPossibleCharges
                        style={{ width: '100%' }}
                        disabled={
                          disabled ||
                          (banModifyFields.includes('vendor_id') ? true : false)
                        }
                        selected={getFieldValue('vendor')}
                        onSelect={(id) => {
                          handleChange({
                            target: { name: 'vendor', value: id },
                          });
                          setInitPossibleCharges(true);
                        }}
                      />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="intermodal_region_id"
                  label="Intermodal Region"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: true }]}
                >
                  <IntermodalRegionSelect
                    disabled={
                      disabled ||
                      (banModifyFields.includes('intermodal_region_id')
                        ? true
                        : false)
                    }
                  />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  name="from_facility_type"
                  label="From Facility Type"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: true }]}
                >
                  <FacilityTypeSelect
                    getValueForOption="name"
                    disabled={
                      disabled ||
                      (banModifyFields.includes('from_facility_type')
                        ? true
                        : false)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue, setFieldValue }) => (
                    <Form.Item
                      name="terminal_ids"
                      label="Terminals"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <TerminalsSelect
                        btnSize="normal"
                        // disabled={banModifyFields.includes('terminal_ids') ? true : false}
                        defaultSelected={getFieldValue('terminal_ids')}
                        onChange={(selected) =>
                          setFieldValue('terminal_ids', selected)
                        }
                        intermodal_region_id={getFieldValue(
                          'intermodal_region_id',
                        )}
                      />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => (
                    <Form.Item
                      name="to_city"
                      label="To City"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[{ required: true }]}
                    >
                      <CitySelect
                        disabled={
                          disabled ||
                          (banModifyFields.includes('to_city_id')
                            ? true
                            : false)
                        }
                        selected={getFieldValue('to_city')}
                        onSelect={(id) =>
                          handleChange({
                            target: { name: 'to_city', value: id },
                          })
                        }
                      />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="To Zipcode"
                  name="to_zipcode"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Input
                    placeholder="Enter to zipcode"
                    disabled={
                      disabled ||
                      (banModifyFields.includes('to_zipcode') ? true : false)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="to_facility_type"
                  label="To Facility Type"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: true }]}
                >
                  <FacilityTypeSelect
                    getValueForOption="name"
                    disabled={
                      disabled ||
                      (banModifyFields.includes('to_facility_type')
                        ? true
                        : false)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={18}></Col>
              <Col span={6}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    return (
                      getFieldValue('to_facility_type') == 'FBA Location' && (
                        <Form.Item
                          label="FBA Facility Code"
                          name="fba_facility_code"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          rules={[{ required: true }]}
                        >
                          <Input
                            disabled={
                              disabled ||
                              (banModifyFields.includes('fba_facility_code')
                                ? true
                                : false)
                            }
                            placeholder="Enter to FBA facility code"
                          />
                        </Form.Item>
                      )
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <Form.Item name="regular_rate_items">
                  <RateItems
                    currencyRate={currencyRate}
                    form={form}
                    disabled={
                      disabled ||
                      (banModifyFields.includes('regular_rate_items')
                        ? true
                        : false)
                    }
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item hidden name="other_rate_items">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            {/* <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const vendor = getFieldValue('vendor');
                return (
                  <Form.Item
                    label="Vendor Contact"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}>
                    <div>
                      ATTN: {get(vendor, 'contact')}, Phone:{' '}
                      {get(vendor, 'phone')}, Email: {get(vendor, 'email')}
                    </div>
                    {ratepossibleCharges.length > 0 && (
                      <Collapse accordion>
                        <Collapse.Panel header="Possible Charges" key="1">
                          <Table
                            size="small"
                            pagination={{
                              pageSize: 3,
                            }}
                            bordered={false}
                            columns={[
                              {
                                title: 'Code',
                                key: 'code',
                                dataIndex: 'code',
                              },
                              {
                                title: 'Name',
                                key: 'name',
                                dataIndex: 'name',
                              },
                              {
                                title: 'Rate',
                                key: 'rate',
                                dataIndex: 'rate',
                              },
                            ]}
                            dataSource={ratepossibleCharges}
                          />
                        </Collapse.Panel>
                      </Collapse>
                    )}
                  </Form.Item>
                );
              }}
            </Form.Item> */}
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldValue }) => (
                <Form.Item
                  name="frontapp_message_id"
                  label={
                    <Space>
                      <span>Frontapp Message ID</span>
                      {getFieldValue('frontapp_message_id') && (
                        <>
                          <Tooltip
                            placement="topLeft"
                            title={<>Redirect to the Frontapp to Preview</>}
                            arrowPointAtCenter
                          >
                            <Button
                              disabled={false}
                              type="primary"
                              size="small"
                              ghost
                              onClick={() =>
                                handlePreviewMessage(
                                  getFieldValue('frontapp_message_id'),
                                )
                              }
                            >
                              Preview
                            </Button>
                          </Tooltip>
                          <Button
                            disabled={false}
                            type="primary"
                            size="small"
                            ghost
                            onClick={() =>
                              handleDownloadFrontappMessage(
                                getFieldValue('frontapp_message_id'),
                              )
                            }
                          >
                            Download
                          </Button>
                        </>
                      )}
                    </Space>
                  }
                  rules={[
                    {
                      required: true,
                      message: 'Frontapp Message ID is Required.',
                    },
                  ]}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Input
                    disabled={
                      disabled ||
                      (banModifyFields.includes('frontapp_message_id')
                        ? true
                        : false)
                    }
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldValue }) => (
                <>
                  {displayMemo ? (
                    <>
                      <Form.Item noStyle name="memo">
                        <Input hidden />
                      </Form.Item>
                      <div className="ant-form-item-label">
                        <Space>
                          <span>Memo</span>
                          <Button
                            type="primary"
                            size="small"
                            ghost
                            onClick={() => setDisplayMemo(false)}
                          >
                            Edit
                          </Button>
                        </Space>
                      </div>
                      <Card
                        className="mt-xs mb-xs"
                        style={{
                          maxHeight: '23vh',
                          overflowY: 'scroll',
                        }}
                      >
                        <div
                          style={{ whiteSpace: 'pre-line' }}
                          dangerouslySetInnerHTML={{
                            __html: getFieldValue('memo') || '',
                          }}
                        />
                      </Card>
                    </>
                  ) : (
                    <Form.Item
                      label={
                        <Space>
                          <span>Memo</span>
                          <Button
                            type="primary"
                            size="small"
                            ghost
                            onClick={() => setDisplayMemo(true)}
                          >
                            Display
                          </Button>
                        </Space>
                      }
                      name="memo"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input.TextArea
                        rows={10}
                        placeholder="Enter Memo"
                        disabled={
                          disabled ||
                          (banModifyFields.includes('memo') ? true : false)
                        }
                      />
                    </Form.Item>
                  )}
                </>
              )}
            </Form.Item>
            <Form.Item
              label="Note For Customer"
              name="note"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input.TextArea
                rows={5}
                placeholder="Enter note"
                disabled={
                  disabled || (banModifyFields.includes('note') ? true : false)
                }
              />
            </Form.Item>
            <Row gutter={8}>
              <Col span={6}>
                <Form.Item
                  name="container_sizes"
                  label="CNTR Size"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <ContainerSizeSelect
                    mode="multiple"
                    disabled={
                      disabled ||
                      (banModifyFields.includes('container_sizes')
                        ? true
                        : false)
                    }
                    style={{ width: '100%' }}
                    placeholder="All"
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={18}>
                <Form.Item
                  label="Attributes"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Form.Item name="is_haz" valuePropName="checked" noStyle>
                    <Checkbox>HAZ</Checkbox>
                  </Form.Item>
                  <Form.Item name="is_ow" valuePropName="checked" noStyle>
                    <Checkbox>OW</Checkbox>
                  </Form.Item>
                  <Form.Item name="is_reefer" valuePropName="checked" noStyle>
                    <Checkbox>REEFER</Checkbox>
                  </Form.Item>
                  <Form.Item name="is_soc" valuePropName="checked" noStyle>
                    <Checkbox>SOC</Checkbox>
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={6}>
                <Form.Item
                  label="Expired Date"
                  name="expired_at"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[
                    { required: true, message: 'Expired Date is Required.' },
                  ]}
                >
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => (
                      <>
                        <AntDDatePicker
                          onChange={(date) =>
                            handleChange({
                              target: {
                                name: 'expired_at',
                                value: date
                                  ? date.format('YYYY-MM-DD HH:mm:ss')
                                  : null,
                              },
                            })
                          }
                          value={
                            getFieldValue('expired_at')
                              ? moment(getFieldValue('expired_at'))
                              : null
                          }
                        />
                      </>
                    )}
                  </Form.Item>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Earliest Capacity"
                  name="earliest_capacity_at"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue, setFieldValue }) => {
                      const value = getFieldValue('earliest_capacity_at');
                      return (
                        <AntDDatePicker
                          value={value ? moment(value) : null}
                          onChange={(v) => {
                            setFieldValue(
                              'earliest_capacity_at',
                              v?.format('YYYY-MM-DD HH:mm:ss') || null,
                            );
                          }}
                        />
                      );
                    }}
                  </Form.Item>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => (
                    <Form.Item
                      label="Created Date"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <DatePicker
                        value={
                          getFieldValue('created_at')
                            ? moment(getFieldValue('created_at'))
                            : null
                        }
                        disabled
                      />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  valuePropName="checked"
                  label="Visible"
                  name="visible"
                  initialValue={true}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldValue }) => {
            return (
              <Form.Item
                name="vendor_special_terms"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <SpecialTerms
                  title="Vendor Special Terms"
                  arrayName={['vendor', 'special_terms']}
                  data={getFieldValue(['vendor', 'special_terms']) || []}
                  updateData={(list) => {
                    setFieldValue(['vendor', 'special_terms'], list);
                  }}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
        <Divider />
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldValue }) => {
            return (
              <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                <PossibleCharges
                  title="Vendor Possible Charges"
                  arrayName={['vendor', 'possible_charges']}
                  extra={
                    <Space>
                      <RenderMissingVendorPossibleChargeTags
                        missVendorPossibleCharges={getFieldValue(
                          'miss_vendor_possible_charges',
                        )}
                      />
                    </Space>
                  }
                  intermodal_region_id={getFieldValue([
                    'vendor',
                    'intermodal_region_id',
                  ])}
                  data={getFieldValue(['vendor', 'possible_charges']) || []}
                  updateData={(list) => {
                    setFieldValue(['vendor', 'possible_charges'], list);
                  }}
                  currencyRate={currencyRate}
                  forceInit={initPossibleCharges}
                  setForceInit={(init: boolean) => setInitPossibleCharges(init)}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {() =>
            !isNew && id && id != COPY ? (
              <Documents
                title="Attachments"
                target="rate"
                targetId={id}
                disabled={disabled}
              />
            ) : (
              <DocumentsStoreLocalFormItem form={form} />
            )
          }
        </Form.Item>
      </Form>
    </div>
  );
};
