import { Button, Modal } from 'antd';
import { useApp } from '@/utils/useapp';
import { useEffect, useState } from 'react';
import React from 'react';
import { Documents as LtlShipmentDocument } from '@/pages/truckload/ltl/components/Upload/Documents';
import { Documents as FtlShipmentDocument } from '@/pages/truckload/ftl/components/Upload/Documents';
import {
  TShipmentAction,
  TARGET_TYPE_FTL_SHIPMENT,
  TARGET_TYPE_LTL_SHIPMENT,
} from '../../Interfaces/ActionInterface';

const Upload: React.FC<TShipmentAction> = ({
  task,
  target,
  targetModel,
  onDone,
  onSaveOperation,
}) => {
  const [open, setOpen] = useState(false);
  const [updated, setUpdated] = React.useState(true);

  const handleCancel = () => {
    //   if (updated) {
    //     onClosedWithUpdate && onClosedWithUpdate();
    //   }
    setOpen(false);
  };

  return (
    <>
      <Button
        disabled={task?.disabled}
        size="small"
        onClick={() => setOpen(true)}
      >
        UPLOAD
      </Button>

      {open && (
        <Modal
          width={'80vw'}
          destroyOnClose
          cancelText="Close"
          footer={
            <>
              <Button type="primary" onClick={handleCancel}>
                Close
              </Button>
            </>
          }
          title="Upload"
          open={open}
          onCancel={handleCancel}
        >
          <div style={{ maxHeight: '65vh', overflowY: 'scroll' }}>
            {target == TARGET_TYPE_LTL_SHIPMENT && (
              <LtlShipmentDocument
                targetModel={targetModel}
                onUpdated={onDone}
                title=""
                target="ltl_doc"
                targetId={targetModel.id}
              />
            )}

            {target == TARGET_TYPE_FTL_SHIPMENT && (
              <FtlShipmentDocument
                onUpdated={onDone}
                shipment={targetModel}
                title=""
                target="ftl_doc"
                targetId={targetModel.id}
              />
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export default Upload;
