import React from 'react';

import { isArray, isObject, upperFirst } from 'lodash';

const TimeText: React.FC<{ time: string | { start: string; end: string } }> = ({
  time,
}) => {
  if (!time) {
    return null;
  }

  if (isObject(time)) {
    return <span>{`${time.start} - ${time.end}`}</span>;
  }

  return <span>{upperFirst(time)}</span>;
};

export default TimeText;
