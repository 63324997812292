import request from './request';
import * as store from '@/stores';
import { memo } from 'react';
import { observer } from 'mobx-react';
import { IReactComponent } from 'mobx-react/dist/types/IReactComponent';

export function useApp() {
  return {
    store,
    service: request,
  };
}

export function withApp(component: IReactComponent) {
  return memo(observer(component));
}
