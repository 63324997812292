import React, { useEffect } from 'react';
import { Modal, Button, message, Card, Spin, Divider } from 'antd';
import { useApp } from '@/utils/useapp';
import { saveAs } from 'file-saver';
import { Excels } from '@/components/Upload/Excels';
import { UploadFileItemRender } from './Upload/UploadFileItemRender';

const Import: React.FC<{ onUploaded: () => void }> = ({ onUploaded }) => {
  const app = useApp();

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [enableEmptyUpload, setEnableEmptyUpload] = React.useState(false);
  const [records, setRecords] = React.useState<any[]>([]);
  const [loadingFiles, setLoadingFiles] = React.useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setEnableEmptyUpload(true);
    setIsModalVisible(false);
  };

  const fetchUploadFiles = async () => {
    setLoadingFiles(true);
    try {
      const resp = await app.service.get('orders/bulk/bulkUploadFiles');
      setRecords(resp.data);
    } catch (e: any) {
      message.error('Error');
    } finally {
      setLoadingFiles(false);
    }
  };

  useEffect(() => {
    if (isModalVisible) {
      fetchUploadFiles();
    }
  }, [isModalVisible]);

  const downloadTemplate = async () => {
    try {
      const file = await app.service.get('orders/template/download', {
        responseType: 'blob',
      });
      const filename = 'drayeasy-bulk-upload-order-template.xlsx';
      saveAs(file, filename);
    } catch (e: any) {
      message.error('Error');
    }
  };

  const handleDownloadFile = async (document: any) => {
    try {
      const file = await app.service.get(`documents/${document.id}`, {
        responseType: 'blob',
      });
      saveAs(file, document.name);
    } catch (e: any) {
      message.error('Error');
    }
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Bulk Upload
      </Button>
      <Modal
        width={720}
        title="Bulk Upload"
        open={isModalVisible}
        onOk={downloadTemplate}
        okText="Download Template"
        onCancel={handleCancel}
      >
        <Excels
          disabledFileList={true}
          uploadAction="orders/bulk"
          enableEmptyUpload={enableEmptyUpload}
          onUploaded={onUploaded}
        />

        <Divider />
        <div>
          <h4>Uploaded Files</h4>
          <Spin spinning={loadingFiles}>
            {!loadingFiles && records.length > 0 ? (
              <>
                {records.map((record) => (
                  <UploadFileItemRender
                    key={record.uid}
                    file={record}
                    handleDownload={handleDownloadFile}
                  />
                ))}
              </>
            ) : (
              <>Empty</>
            )}
          </Spin>
        </div>
      </Modal>
    </>
  );
};

export default Import;
