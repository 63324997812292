import { Descriptions, Popover, Select } from 'antd';

import React from 'react';
import { ORDER_SALES_TYPE_MAP } from './data';
import { UserOutlined } from '@ant-design/icons';
import { UserSyncSelect } from '@/components/UserSyncSelect';

const SALES_TYPE_OPTIONS = [
  ...Object.keys(ORDER_SALES_TYPE_MAP).map((t: string) => ({
    value: +t,
    label:
      ORDER_SALES_TYPE_MAP[(t as unknown) as keyof typeof ORDER_SALES_TYPE_MAP],
  })),
];

export const IDUserIconPopover: React.FC<{
  order: any;
  handlePatchUpdate: (id: number, values: any) => void;
}> = ({ order, handlePatchUpdate }) => {
  return (
    <Popover
      trigger="click"
      placement="right"
      content={
        <Descriptions
          title=""
          bordered
          column={7}
          layout="vertical"
          style={{ width: '60vw' }}
        >
          <Descriptions.Item label="Sales Type">
            <Select
              style={{ width: '100%' }}
              value={order.sales_type_id || null}
              options={SALES_TYPE_OPTIONS}
              onChange={(value) =>
                handlePatchUpdate(order.id, { sales_type_id: value })
              }
            />
          </Descriptions.Item>
          <Descriptions.Item label="Operator">
            <UserSyncSelect
              style={{ width: '100%' }}
              type="admin"
              value={order.operator_id}
              onChange={(value) =>
                handlePatchUpdate(order.id, { operator_id: value })
              }
            />
          </Descriptions.Item>
          <Descriptions.Item label="DB">
            <UserSyncSelect
              style={{ width: '100%' }}
              type="admin"
              value={order.business_development_id}
              onChange={(value) =>
                handlePatchUpdate(order.id, { business_development_id: value })
              }
            />
          </Descriptions.Item>
          <Descriptions.Item label="Sales">
            <UserSyncSelect
              style={{ width: '100%' }}
              type="admin"
              value={order.sales_id}
              onChange={(value) =>
                handlePatchUpdate(order.id, { sales_id: value })
              }
            />
          </Descriptions.Item>
          <Descriptions.Item label="D.P.">
            <UserSyncSelect
              style={{ width: '100%' }}
              type="admin"
              value={order.document_person_id}
              onChange={(value) =>
                handlePatchUpdate(order.id, {
                  document_person_id: value,
                })
              }
            />
          </Descriptions.Item>
          <Descriptions.Item label="Sales Support">
            <UserSyncSelect
              style={{ width: '100%' }}
              type="admin"
              value={order.sales_support_id}
              onChange={(value) =>
                handlePatchUpdate(order.id, {
                  sales_support_id: value,
                })
              }
            />
          </Descriptions.Item>
          <Descriptions.Item label="OP Lead">
            <UserSyncSelect
              style={{ width: '100%' }}
              type="admin"
              value={order.operator_lead_id}
              onChange={(value) =>
                handlePatchUpdate(order.id, {
                  operator_lead_id: value,
                })
              }
            />
          </Descriptions.Item>
        </Descriptions>
      }
    >
      <div style={{ color: '#1890ff' }} className="mt-xs cursor-pointer">
        {' '}
        <UserOutlined />
      </div>
    </Popover>
  );
};
