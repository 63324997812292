import React from 'react';
import { Checkbox, Form, FormInstance, Input, Select } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import Editor from '@/components/Editor/Editor';
import { TITLES } from './data';

export const DEPARTMENTS = [
  'Operation',
  'IT',
  'Accounting & Admin',
  'Customer Success',
];

interface Props {
  form: FormInstance;
  onSubmit: () => void;
}

const roles = [
  'super-admin',
  'admin',
  'us-team-member',
  'ph-team-leader',
  'ph-team-member',
  'accounting',
  'controller',
  'sales',
  'it',
];

const roleOptions = roles.map((role) => ({
  label: role,
  value: role,
}));

export const DrawerForm: React.FC<Props> = ({ form, onSubmit }) => {
  return (
    <Form layout="vertical" form={form} onFinish={onSubmit}>
      <Form.Item name="id" noStyle>
        <Input hidden />
      </Form.Item>
      <Form.Item
        label="Username"
        name="username"
        rules={[{ required: true, message: 'Please Input Username' }]}
      >
        <Input placeholder="Enter Username" />
      </Form.Item>
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Please Input Name' }]}
      >
        <Input placeholder="Enter name" />
      </Form.Item>
      <Form.Item label="Title" name="title">
        <Select allowClear>
          {TITLES.map((title: string) => (
            <Select.Option key={title} value={title}>
              {title}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Department" name="department">
        <Select allowClear>
          {DEPARTMENTS.map((department: string) => (
            <Select.Option key={department} value={department}>
              {department}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        rules={[
          { type: 'email', required: true, message: 'Please Input Email' },
        ]}
      >
        <Input placeholder="Enter email" />
      </Form.Item>
      <Form.Item name="password" label="Password">
        <Input.Password
          placeholder="Enter password"
          prefix={<LockOutlined />}
        />
      </Form.Item>
      <Form.Item name="email_signature" label="Email Signature">
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldValue }) => (
            <Editor
              value={getFieldValue('email_signature') || ''}
              onChange={(value) => {
                setFieldValue('email_signature', value);
              }}
            />
          )}
        </Form.Item>
      </Form.Item>
      <Form.Item name="roles" label="Roles">
        <Checkbox.Group options={roleOptions} style={{ display: 'grid' }} />
      </Form.Item>
    </Form>
  );
};
