import { Card, Button, message, Tag, Space, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import type {
  FileStatus,
  ShipmentData,
  RecognitionResult,
  DuplicateBillCheck,
} from '../../interface/types';
import NewBill from '../../../bills/components/NewBill';
import { BILLABLE_TYPE } from '@/pages/accounting/bills/components/data';
import { TLType } from '@/components/constants';
import ShipmentInfo from './ShipmentInfo';
import { prepareInitialBillData } from './utils';
import { useEffect, useState } from 'react';
import { useApp } from '@/utils/useapp';
import UILayoutSearchModal from '@/components/UILayoutSearchModal';
import {
  SearchResultDrayItem,
  SearchResultTLItem,
} from '@/components/UILayoutSearchModal/types';

interface Props {
  result: RecognitionResult | null;
  file: FileStatus | null;
  fileContent?: string;
  shipmentData?: ShipmentData;
  onSaveResult?: (result: RecognitionResult) => Promise<void>;
  onResultChange?: (key: string, value: string | any) => void;
}

const MatchedShipmentCard = ({
  result,
  file,
  fileContent,
  shipmentData,
  onSaveResult,
  onResultChange,
}: Props) => {
  const [
    duplicateCheck,
    setDuplicateCheck,
  ] = useState<DuplicateBillCheck | null>(null);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const app = useApp();

  const getBillableType = (invoiceType?: string): string => {
    switch (invoiceType) {
      case TLType.LTL.toUpperCase():
        return BILLABLE_TYPE.LTL;
      case TLType.FTL.toUpperCase():
        return BILLABLE_TYPE.FTL;
      case 'DRAY':
        return BILLABLE_TYPE.ORDER;
      default:
        return BILLABLE_TYPE.LTL;
    }
  };

  useEffect(() => {
    const checkDuplicateBill = async () => {
      if (result?.invoice_number) {
        try {
          const response = await app.service.get('orderBills', {
            params: {
              invoice_number: result.invoice_number,
              date_field: 'billed_at',
              billable_type: getBillableType(file?.invoiceType),
              page: 1,
              per_page: 5,
            },
          });
          const duplicateBill = response.data?.find(
            (bill: any) => bill.invoice_number === result.invoice_number,
          );
          setDuplicateCheck(
            duplicateBill
              ? {
                  exists: true,
                  billNumber: duplicateBill.invoice_number,
                  billId: duplicateBill.id,
                }
              : null,
          );
        } catch (error) {
          console.error('Failed to check duplicate bill:', error);
        }
      }
    };

    checkDuplicateBill();
  }, [result?.invoice_number, result?.reference_number, file?.invoiceType]);

  const renderNewBillButton = () => {
    return (
      <NewBill
        billableId={parseInt(shipmentData?.id ?? '0')}
        billableType={getBillableType(file?.invoiceType)}
        container={{ number: result?.container_number ?? '' }}
        onSaved={async () => {
          try {
            message.success('Bill created successfully');
            if (result && onSaveResult) {
              onSaveResult(result);
            }
          } catch (error) {
            message.error('Failed to save recognition result');
          }
        }}
        initialValues={
          result && shipmentData
            ? prepareInitialBillData(result, shipmentData, file, fileContent)
            : undefined
        }
        {...(duplicateCheck?.exists
          ? {
              confirmModalProps: {
                title: 'Duplicate Bill Warning',
                content:
                  'Duplicate bill found. Are you sure to continue to add new bill?',
              },
            }
          : {})}
      >
        <Button type="primary" icon={<PlusOutlined />}>
          New Bill
        </Button>
      </NewBill>
    );
  };

  return (
    <>
      <Card
        title={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Space>
              <span>Matched Shipment/Order Data</span>
              {duplicateCheck?.exists && (
                <Tooltip
                  title={`Duplicate bill found: ${duplicateCheck.billNumber}`}
                >
                  <Tag color="error">Duplicate Invoice</Tag>
                </Tooltip>
              )}
            </Space>
            <Space>
              {result && (
                <Button type="primary" onClick={() => setShowSearchModal(true)}>
                  Search Shipment/Order
                </Button>
              )}
              {shipmentData && renderNewBillButton()}
            </Space>
          </div>
        }
        className="section-card"
        style={{ marginTop: '16px' }}
      >
        {shipmentData ? (
          <ShipmentInfo shipmentData={shipmentData} file={file} />
        ) : (
          <div style={{ textAlign: 'center', padding: '24px' }} />
        )}
      </Card>
      <UILayoutSearchModal
        open={showSearchModal}
        onCancel={() => setShowSearchModal(false)}
        afterClick={(item: SearchResultDrayItem | SearchResultTLItem) => {
          if (
            'container_number' in item &&
            'mbl_number' in item &&
            item.container_number !== null
          ) {
            onResultChange?.('container_number', item.container_number ?? '');
          } else if ('uid' in item && item.uid !== null) {
            onResultChange?.('reference_number', item.uid ?? '');
          }
          setShowSearchModal(false);
        }}
      />
    </>
  );
};

export default MatchedShipmentCard;
