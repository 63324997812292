import { TContainer } from '@/types';
import { Space, Tag, TagProps } from 'antd';

const types = {
  is_soc: 'SOC',
  is_dg: 'DG',
  is_overweight: 'OW',
  is_reefer: 'REFER',
} as const;

const ContainerTag = (props: TagProps & { value: string }) => {
  return (
    <Tag color="#0070fa" {...props}>
      {props.value}
    </Tag>
  );
};

const Index = ({ container }: { container: TContainer }) => (
  <Space>
    {Object.keys(types).map((type) => {
      return container[type] ? <ContainerTag value={types[type]} /> : null;
    })}
  </Space>
);

export default Index;
