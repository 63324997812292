import { Popconfirm, Space, Table, TableColumnsType } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useMemo } from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import {
  CNTR_SIZE_MAP,
  UNIT_MAP,
} from '../../../../components/PossibleCharges';

interface TExpandedDataType {
  key: React.Key;
  rowSpan: number;
  statement: string;
  billing_code: string;
  billing_name: string;
  unit: number;
  rate: string;
  probability: string;
  cntr_size: number;
}

export const RateScenairoTable: React.FC<{
  data: any[];
  onEdit?: (id: number) => void;
  onAdd?: () => void;
  onDelete?: (id: number) => void;
}> = ({ data = [], onEdit, onAdd, onDelete }) => {
  const handleAdd = () => {
    if (onAdd) {
      onAdd();
    }
  };

  const expandedRowRender = (data: any) => {
    const rows = [];
    let pre = null;
    for (const item of data.rate_rate_scenario_items) {
      for (const charge of item.possible_charges) {
        const row = {
          key: data.id,
          rowSpan: pre === item.id ? 0 : item.possible_charges.length,
          statement: item.statement.statement,
          billing_code: charge.code,
          billing_name: charge.name,
          unit: charge.unit,
          rate: charge.rate,
          probability: item.probability,
          qty: item.qty,
          cntr_size:
            charge.cntr_size
              ?.map(
                (size: number) =>
                  CNTR_SIZE_MAP[size as unknown as keyof typeof CNTR_SIZE_MAP],
              )
              .join(',') || '',
        };
        pre = item.id;
        rows.push(row);
      }
    }

    const columns: any = [
      {
        title: 'Statement',
        dataIndex: 'statement',
        key: 'statement',
        onCell: (record: TExpandedDataType) => {
          return { rowSpan: record.rowSpan };
        },
      },
      {
        title: 'Billing Code',
        dataIndex: 'billing_code',
        key: 'billing_code',
      },
      {
        title: 'Billing Name',
        dataIndex: 'billing_name',
        key: 'billing_name',
      },
      {
        title: 'Unit',
        dataIndex: 'unit',
        key: 'unit',
        render: (text: string, record: any) => {
          return <>{UNIT_MAP[record.unit as keyof typeof UNIT_MAP]}</>;
        },
      },
      {
        title: 'Rate',
        dataIndex: 'rate',
        key: 'rate',
      },
      {
        title: 'Cntr size',
        dataIndex: 'cntr_size',
        key: 'cntr_size',
      },
      {
        title: 'Probability',
        dataIndex: 'probability',
        key: 'probability',
        render: (text: string, record: any) => {
          return <>{record.probability}%</>;
        },
      },
      {
        title: 'Default Qty',
        key: 'qty',
        dataIndex: 'qty',
        width: 200,
        onCell: (record: TExpandedDataType) => {
          return { rowSpan: record.rowSpan };
        },
      },
    ];

    return (
      <Table bordered columns={columns} dataSource={rows} pagination={false} />
    );
  };

  const handleEdit = (id: number) => {
    if (onEdit) {
      onEdit(id);
    }
  };

  const handleRemove = (index: number) => {
    if (onDelete) {
      onDelete(index);
    }
  };

  const defaultColumns: ColumnsType<any> = [
    {
      title: 'Code',
      key: 'code',
      dataIndex: 'code',
      width: 200,
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      width: 200,
    },
    {
      title: 'Terminal',
      key: 'terminal_ids',
      render: (text, record) => {
        return record.terminals
          ? record.terminals.map((t) => t.name).join(',')
          : 'All';
      },
      width: 200,
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
      width: 200,
      render: (text, record) => {
        return (
          <div className="text-eclipsis-4 new-line">{record.description}</div>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      fixed: 'right',
      render: (text, record) => {
        return (
          <Space>
            <a onClick={() => handleEdit(record.id)}>Edit</a>
            <Popconfirm title="Title" onConfirm={() => handleRemove(record.id)}>
              <a>Delete</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const columns = useMemo(() => {
    const _columns = [...defaultColumns];
    return _columns;
  }, [data]);

  return (
    <div>
      <Space style={{ justifyContent: 'space-between', width: '100%' }}>
        <h3>
          Rate Scenairos <PlusCircleOutlined onClick={handleAdd} />
        </h3>
      </Space>

      <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        expandable={{
          expandedRowRender,
        }}
        pagination={false}
        scroll={{ x: '100%' }}
      />
    </div>
  );
};
