import React from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Divider,
  Drawer,
  message,
  Popconfirm,
  Space,
  TablePaginationConfig,
} from 'antd';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { DrawerForm } from './components/DawerForm';
import { useForm } from 'antd/lib/form/Form';
import { TLocalStatement, TCollection } from '@/types';
import usePagination from '@/components/usePagination';
import AutoResizeTable from '@/components/AutoResizeTable';
import { Filter } from './components/Filter';
import { ToOrderForm } from './components/ToOrderForm';

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [toOrder, setToOrder] = React.useState(false);
  const [preOrder, setPreOrder] = React.useState<number | null>(null);
  const [data, setData] = React.useState<TCollection<TLocalStatement>>();
  const [model, setModel] = React.useState<TLocalStatement>();
  const [filter] = useForm();

  const pagination = usePagination(data);

  const fetchData = React.useCallback(
    async (pagination?: TablePaginationConfig) => {
      setLoading(true);

      try {
        const result = await app.service.get('preOrders', {
          params: {
            ...filter.getFieldsValue(),
            page: pagination?.current || 1,
            per_page: pagination?.pageSize || 20,
          },
        });

        setData(result);
      } catch (e: any) {
        message.error(e.data?.message || e.data?.error);
      }

      setLoading(false);
    },
    [],
  );

  const addNew = () => {
    setModel(undefined);
    setVisible(true);
  };

  const handleEdit = (m: any) => {
    setModel(m);
    setVisible(true);
  };

  const handleDelete = async (id: number) => {
    setLoading(true);
    try {
      await app.service.delete(`preOrders/${id}`);
      fetchData();
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
    setLoading(false);
  };

  const columns: ColumnsType<any> = React.useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 80,
        render: (text, record) => <>{record.id}</>,
      },
      {
        title: 'POD',
        dataIndex: 'port_of_dis_charge',
        key: 'port_of_dis_charge',
        render: (text, record) => <>{record.port_of_dis_charge.name}</>,
        width: 120,
      },
      {
        title: 'POD ETA',
        dataIndex: 'port_of_discharge_eta',
        key: 'port_of_discharge_eta',
        render: (text, record) => <>{record.port_of_discharge_eta}</>,
        width: 120,
      },
      {
        title: 'IR',
        dataIndex: 'final_port',
        key: 'final_port',
        render: (text, record) => <>{record.final_port.name}</>,
        width: 120,
      },
      {
        title: 'POD ETA',
        dataIndex: 'final_port_eta',
        key: 'final_port_eta',
        render: (text, record) => <>{record.final_port_eta}</>,
        width: 120,
      },
      {
        title: 'Customer',
        dataIndex: 'customer',
        key: 'customer',
        render: (text, record) => {
          return <>{record?.customer?.name}</>;
        },
        width: 120,
      },
      {
        title: 'Created At',
        dataIndex: 'created_at',
        key: 'created_at',
        width: 120,
      },
      {
        align: 'center',
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 150,
        render: (text, record) => (
          <Space>
            <a onClick={() => handleEdit(record)}>Edit</a>
            <Divider type="vertical" />
            <Popconfirm
              title="Sure to delete?"
              okText="Confirm"
              cancelText="Cancel"
              onConfirm={() => {
                handleDelete(record.id);
              }}>
              <a>Delete</a>
            </Popconfirm>
            <Divider type="vertical" />
            <Button
              // disabled={record.status != 0}
              onClick={() => {
                setPreOrder(record.id);
                setToOrder(true);
              }}>
              To Order
            </Button>
          </Space>
        ),
      },
    ],
    [],
  );

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleFilterChange = (values: any) => {
    filter.setFieldsValue(values);
  };

  const handleSearch = () => {
    fetchData();
  };

  return (
    <div className={styles.main}>
      <Header
        title="Pre-Order"
        rightElement={
          <div>
            <Button type="primary" onClick={addNew}>
              Create Pre-Order
            </Button>
          </div>
        }></Header>
      <Filter
        loading={loading}
        filter={filter}
        onSearch={handleSearch}
        onChange={handleFilterChange}
      />
      <AutoResizeTable
        loading={loading}
        pagination={pagination}
        size="small"
        rowKey="id"
        columns={columns}
        onChange={fetchData}
        dataSource={data?.data || []}
        sticky
        scroll={{
          x: 'auto',
        }}
      />

      <Drawer
        title=""
        placement="right"
        width="90%"
        onClose={() => setVisible(false)}
        destroyOnClose={true}
        open={visible}>
        <DrawerForm
          model={model}
          onCreated={() => {
            fetchData();
            setVisible(false);
          }}
          onClose={() => setVisible(false)}
        />
      </Drawer>
      <Drawer
        title=""
        placement="right"
        width="90%"
        onClose={() => setToOrder(false)}
        destroyOnClose={true}
        open={toOrder}>
        <ToOrderForm
          id={preOrder}
          onCreated={() => {
            fetchData();
            setToOrder(false);
          }}
          onClose={() => setToOrder(false)}
        />
      </Drawer>
    </div>
  );
};

export default Index;
