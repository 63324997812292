import { Tag } from 'antd';
import { get, has, uniq } from 'lodash';
import React, { useMemo, useState } from 'react';
import {
  BILLING_CODE_REMIND_MAP,
  BILLING_CODE_CHAFE,
  formatChassisDaysRemind,
} from './data';
import { EditableContainerExtraCosts } from '@/pages/dispatchs/components/ExtraCosts';

export const RenderReminds: React.FC<{
  container: any;
  showNumber: boolean;
  charges: any;
  showExtraCost?: boolean;
}> = ({ container, showNumber, charges, showExtraCost = false }) => {
  const [openExtraCost, setOpenExtraCost] = useState(false);

  const fees = charges
    .filter((c) => has(BILLING_CODE_REMIND_MAP, c.code))
    ?.map((c) => get(BILLING_CODE_REMIND_MAP, c.code))
    ?.filter((f) => f);

  const chassisCharge = charges.find((c) => c.code == BILLING_CODE_CHAFE);

  const chassisFee = useMemo(
    () =>
      formatChassisDaysRemind(container.order.mbl_number, chassisCharge?.qty),
    [chassisCharge],
  );

  const handleUpdatedExtraCost = () => {
    console.log('updated extra cost');
  };

  return (
    <div className="mb-md space">
      {showNumber && container.number + ': '}
      {showExtraCost && (
        <EditableContainerExtraCosts
          container={container}
          color="red"
          customizeText={`${openExtraCost ? 'Hide' : 'View'} Extra cost`}
          setOpen={(open: boolean) => setOpenExtraCost(open)}
          onUpdated={handleUpdatedExtraCost}
        />
      )}
      <>
        {chassisFee && <Tag color="red">{chassisFee}</Tag>}
        {fees ? (
          uniq(fees).map((fee, i) => (
            <Tag color="red" key={i}>
              {fee}{' '}
            </Tag>
          ))
        ) : (
          <></>
        )}
      </>
    </div>
  );
};
