import { EditableSelect } from '@/components/Editable';
import { useCallback, useState } from 'react';
import { message, Form, Input, Select, Modal } from 'antd';
import { ShipmentCustomerStatus } from '../../constants';
import { CUSTOMER_STATUS_OPTIONS } from '..';

const requiredRules = [{ required: true }];

const rejectTypeOptions = [
  { label: 'Issue of Order Information', value: 1 },
  { label: 'Carrier Reject', value: 2 },
  { label: 'Other', value: 3 },
];

export interface EditableCustomerStatusSelectProps {
  record: Record<string, any>;
  patchUpdate: (id: number, model: string, data: any) => void;
}

export const EditableCustomerStatusSelect = ({
  record,
  patchUpdate,
}: EditableCustomerStatusSelectProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [form] = Form.useForm();

  const handleConfirm = () => {
    form
      .validateFields()
      .then((values) => {
        setIsModalOpen(false);
        patchUpdate(record.id, 'shipments', {
          customer_status: ShipmentCustomerStatus.REJECT,
          ...values,
        });
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleChange = useCallback(
    (name: string, value: any) => {
      if (record.customer_status == ShipmentCustomerStatus.CANCELED) {
        return message.error('Canceled shipment cannot change status.');
      }

      if (record.customer_status == ShipmentCustomerStatus.REJECT) {
        return message.error('Reject shipment cannot change status.');
      }

      if (
        ![
          ShipmentCustomerStatus.CONFIRMING,
          ShipmentCustomerStatus.PENDING_PICKUP,
        ].includes(record.customer_status) &&
        value === ShipmentCustomerStatus.REJECT
      ) {
        return message.error(
          'This status can only be changed from "Order Reviewing" or "Pending Pickup".',
        );
      }

      if (value === ShipmentCustomerStatus.REJECT) {
        setIsModalOpen(true);
        return;
      }

      patchUpdate(record.id, 'shipments', {
        [name]: value,
      });
    },
    [record, patchUpdate],
  );

  return (
    <>
      <EditableSelect
        name="customer_status"
        value={record.customer_status || 0}
        options={CUSTOMER_STATUS_OPTIONS}
        onChange={handleChange}
      />
      <Modal
        title={`Reject Shipment ${record.uid}`}
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={handleConfirm}
        okText="Save"
        cancelText="Cancel"
        destroyOnClose
      >
        <Form layout="vertical" form={form}>
          <Form.Item
            name="reject_type"
            label="Reject Type"
            rules={requiredRules}
          >
            <Select options={rejectTypeOptions} />
          </Form.Item>
          <Form.Item
            name="reject_reason"
            label="Reject Reason"
            rules={requiredRules}
          >
            <Input.TextArea maxLength={200} showCount />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
