import { useApp } from '@/utils/useapp';
import { Button, Modal } from 'antd';

import { useMemo, useState } from 'react';
import React from 'react';
import { FileOutlined } from '@ant-design/icons';
import { OrderDocuments } from '@/pages/orders/components/Upload/OrderDocuments';

import {
  FileActionTips,
  FileActionTipsProps,
} from '@/pages/dispatchs/components/FileActionTips/index';
import {
  TActionTasksProps,
  ActionTasks,
} from '@/pages/dispatchs/components/ActionTasks';
import { get } from 'lodash';

export const Upload: React.FC<{
  order: any;
  containerId?: number;
  container?: any;
  children?: React.ReactNode;
  onClosedWithUpdate?: () => void;
  onClosed?: () => void;
  fileActionTipsProps?: FileActionTipsProps;
  showFileActionTips?: boolean;
  TActionTasksProps?: TActionTasksProps;
  showActionTasks?: boolean;
}> = ({
  order,
  containerId = 0,
  container,
  children,
  onClosedWithUpdate,
  onClosed,
  fileActionTipsProps,
  showFileActionTips = false,
  TActionTasksProps,
  showActionTasks = false,
}) => {
  const app = useApp();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [containers, setContainers] = React.useState<Array<any>>([]);
  const [updated, setUpdated] = React.useState(true);

  const FileRender: any = children ? children : <FileOutlined />;

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    if (updated) {
      onClosedWithUpdate && onClosedWithUpdate();
    }
    onClosed && onClosed();
    setIsModalVisible(false);
  };

  const fetchContainers = async () => {
    const resp = await app.service.get(`orders/${order.id}/containers`);
    setContainers(resp.data);
  };

  React.useEffect(() => {
    if (isModalVisible && order.id) {
      fetchContainers();
    }
  }, [isModalVisible, order]);

  return (
    <>
      {get(container, 'dispatch_action_v2') ? (
        showActionTasks ? (
          <ActionTasks {...TActionTasksProps}>
            <div onClick={showModal}>{FileRender}</div>
          </ActionTasks>
        ) : (
          <div onClick={showModal}>{FileRender}</div>
        )
      ) : showFileActionTips ? (
        <FileActionTips {...fileActionTipsProps}>
          <div onClick={showModal}>{FileRender}</div>
        </FileActionTips>
      ) : (
        <div onClick={showModal}>{FileRender}</div>
      )}

      {isModalVisible && (
        <Modal
          width={'80vw'}
          destroyOnClose
          cancelText="Close"
          footer={
            <>
              <Button type="primary" onClick={handleCancel}>
                Close
              </Button>
            </>
          }
          title="Upload"
          open={isModalVisible}
          onCancel={handleCancel}>
          <div style={{ maxHeight: '65vh', overflowY: 'scroll' }}>
            <OrderDocuments
              onUpdated={() => setUpdated(true)}
              title=""
              containerId={containerId}
              containers={containers}
              target="order"
              targetId={order.id}
            />
          </div>
        </Modal>
      )}
    </>
  );
};
