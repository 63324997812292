import React from 'react';

export const IPLink: React.FC<{
  ip: string;
}> = ({ ip }) => {
  return (
    <a href={'https://ip-api.com/#' + ip} target="_blank" rel="noreferrer">
      {ip}
    </a>
  );
};
