import { TerminalSelect } from '@/components/TerminalSelect';
import {
  Form,
  Row,
  Col,
  Input,
  DatePicker,
  Divider,
  Space,
  Button,
  message,
  Select,
  Tag,
} from 'antd';
import { FormInstance } from 'antd/lib/form';
import moment from 'moment';
import { Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { VendorSelect } from '@/components/VendorSelect';
import { OceanCarrierSelect } from '@/components/OceanCarrierSelect';
import { IntermodalRegionSelect } from '@/components/IntermodalRegionSelect';
import { useEffect, useState } from 'react';
import { useApp } from '@/utils/useapp';
import { get, sumBy, toString, chain } from 'lodash';
import Search from 'antd/lib/input/Search';
import { EyeOutlined } from '@ant-design/icons';
import Decimal from 'decimal.js';

import ContainerTable, { Weight } from '@/components/ContainerTable';

import { CitySelect } from '@/components/CitySelect';
import { DeliveryOrderFormRemindTag } from './DeliveryOrderFormRemindTag';
import { SocReturnLocationSelect } from '@/components/SocReturnLocationSelect';
import { DeliveryFormItems } from './DeliveryFormItems';
import { RateLink } from '@/components/Rate/RateLink';
import { OPEN_TYPE_BUY_RATE } from '@/components/Rate/OpenBuyRateOrSellRateDrawer';
import { TContainer } from '@/types';
import { LOADING_TYPE_TBD } from '@/components/ContainerTable/components/data';

const PDF_TEMPLATE_DEFAULT = 'DEFAULT';
const PDF_TEMPLATE_SOLAR_CHICAGO = 'SOLAR-CHICAGO';
const PDF_TEMPLATE_MAP = {
  [PDF_TEMPLATE_DEFAULT]: PDF_TEMPLATE_DEFAULT,
  [PDF_TEMPLATE_SOLAR_CHICAGO]: PDF_TEMPLATE_SOLAR_CHICAGO,
};

const { Title } = Typography;

const DEFAULT_BILL_TO =
  'DrayEasy Inc.\r800 N Haven Ave Ste 210\rOntario, CA 91764\r(O) 1-818-826-2850\rPlease send bill to AP@drayeasy.com';
const DEFUALT_INSTRUCTION = `1) PLS CHECK AVAILABILITY & CALL CUSTOMER/TERMINAL TO SET UP APPOINTMENT PRIOR TO PICKUP & DELIVERY.
2) PLS REPORT ANY WAITING HOUR ASAP BEFORE YOUR DRIVER LEAVES THE WHSE.
3) PLS CALL US ASAP IF THERE MAY BE ANY PER DIEM CHARGES. IF WE ARE NOT NOTIFIED, ALL THE PER DIEM IS ON YOUR ACCOUNT.
4) WE ARE NOT PAYING ANY DRIVER UNLOADING CHARGE UNLESS APPROVED BY US IN ADVANCE IN WRITING.
            `;

export const DeliveryOrderForm: React.FC<{
  form: FormInstance;
  orderId: number;
  mblNumber: string;
  containers?: any;
  onSaved?: () => void;
}> = ({ form, orderId, mblNumber, containers, onSaved }) => {
  const [showDetail, setShowDetail] = useState(false);
  const [showReturn, setShowReturn] = useState(
    containers.some((c: any) => c && c.is_soc),
  );
  const [hasSoc, setHasSoc] = useState(false);
  const [loading, setLoading] = useState(false);
  const [copying, setCopying] = useState(false);
  const [isCityMismatch, setIsCityMismatch] = useState(false);
  const [isProfitNegative, setIsProfitNegative] = useState(false);

  const containerIds = containers?.map((c: TContainer) => c.id) || [];

  const app = useApp();

  const fetchAll = async () => {
    setLoading(true);

    if (form.getFieldValue('id')) {
      setLoading(false);
      return;
    }

    try {
      const calls = [];
      if (containerIds && containerIds.length > 0) {
        calls.push(
          app.service.get('containersByIds', { params: { ids: containerIds } }),
        );
      } else {
        calls.push(Promise.resolve({ data: [] }));
      }
      if (orderId) {
        calls.push(app.service.get('orders/' + orderId));
      } else {
        calls.push(Promise.resolve({ data: {} }));
      }
      const [containersResponse, orderResponse] = await Promise.all(calls);

      const _containers = containersResponse.data.length
        ? containersResponse.data
        : orderResponse.data.containers;

      const socReturnLocation = get(_containers, '0.soc_return_location', null);

      if (
        _containers.some(
          (c: any) => c.soc_return_location?.id != socReturnLocation?.id,
        )
      ) {
        message.warning('Container has different soc return location');
      }

      let buyRate = get(orderResponse, 'data.buy_rate');
      if (containerIds && containerIds.length > 0) {
        buyRate = get(_containers, '0.buy_rate')
          ? get(_containers, '0.buy_rate')
          : get(orderResponse, 'data.buy_rate');
      }

      form.setFieldsValue({
        mbl_number: mblNumber,
        port_of_discharge_id: get(orderResponse, 'data.port_of_discharge_id'),
        port_of_discharge_eta: get(orderResponse, 'data.port_of_discharge_eta'),
        terminal_id: get(orderResponse, 'data.terminal_id'),
        terminal: get(orderResponse, 'data.terminal'),
        pickup_address: get(
          orderResponse,
          'data.terminal.delivery_order_address',
        ),
        warehouse_id: get(orderResponse, 'data.warehouse_id'),
        warehouse: get(orderResponse, 'data.warehouse'),
        delivery_address: get(
          orderResponse,
          'data.warehouse.delivery_order_address',
        ),
        final_port_id: get(orderResponse, 'data.final_port_id'),
        final_port_eta: get(orderResponse, 'data.final_port_eta'),
        buy_rate_id: buyRate?.uid,
        buy_rate: buyRate,
        gross_weight: sumBy(_containers, (c: any) =>
          parseFloat(c.weight || 0),
        ).toFixed(2),
        containers: _containers,
        vendor: get(buyRate, 'vendor'),
        return_id: socReturnLocation?.id,
        return: socReturnLocation || null,
        return_address: socReturnLocation?.address || '',
        return_contact: socReturnLocation?.contact || '',
        return_phone: socReturnLocation?.phone || '',
        return_instruction: socReturnLocation?.return_instruction || '',
      });
      updateOceanCarrierByMBL(mblNumber);
    } catch (err: any) {
      message.error(err.data.message || err.data.error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAll();
  }, []);

  const handleBillToCustomer = async () => {
    setLoading(true);
    try {
      const resp = await app.service.get(`orders/${orderId}/customerAddress`);
      form.setFieldsValue({
        billing_to: resp.data.billing_to,
      });
    } catch (err: any) {
      message.error(err.error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddContainer = () => {
    const newContainers = form.getFieldValue('containers');
    if (newContainers) {
      newContainers.push({
        loading_type: LOADING_TYPE_TBD,
      });
      form.setFieldsValue({
        containers: newContainers,
      });
    }
  };

  const handleCopyDo = async (value: string) => {
    setCopying(true);
    try {
      const id = value.replace(/[^\d]/, '');
      const resp = await app.service.get(`deliveryOrders/${id}`);
      message.success('DO copied');
      form.setFieldsValue({
        ...resp.data,
        id: '',
      });
    } catch (err: any) {
      message.error('Not Found');
    } finally {
      setCopying(false);
    }
  };

  const updateOceanCarrierByMBL = async (mbl: string) => {
    const length = mbl.length;

    if (length > 4) {
      const resp = await app.service.get(
        'oceanCarriers/searchByCode?code=' + mbl.substring(0, 4),
      );

      if (resp.data) {
        form.setFieldValue('ocean_carrier_id', resp.data.id);
      }
    }
  };

  const handleMBLOnBlur = (e: any) => {
    const mbl = e.target.value.trim();
    updateOceanCarrierByMBL(mbl);
  };

  const handleChangePDFTemplate = (value: any, option: any) => {
    if (value == PDF_TEMPLATE_DEFAULT) {
      return false;
    }

    const containers = form.getFieldValue('containers');
    form.setFieldValue(
      'containers',
      containers.map((c: any) => ({
        ...c,
        custom_properties: {
          module_type: '',
          pallet_qty: '',
        },
      })),
    );
  };

  const fetchBuyRateAndContainers = async (
    _containers: any,
    buyRateId: string,
  ) => {
    const numbers = chain(_containers || [])
      .map((c) => c?.number)
      .filter((n) => n)
      .uniq()
      .value();
    if (!buyRateId && numbers.length <= 0) {
      setIsCityMismatch(false);
      setIsProfitNegative(false);
      return;
    }

    try {
      const resp = await app.service.get(
        `deliveryOrders/${orderId}/fetchBuyRateAndContainers`,
        {
          params: {
            buy_rate_id: buyRateId,
            container_numbers: numbers,
          },
        },
      );

      const buyRate = get(resp.data, 'buy_rate');
      const containers = get(resp.data, 'containers');

      checkIsWareohuseCityMismatch(containers, buyRate);
      checkIsProfitNegative(containers, buyRate);
    } catch (err: any) {
      message.error('Not Found');
    } finally {
      // setCopying(false);
    }
  };
  const checkIsWareohuseCityMismatch = (_containers: any, buyRate: any) => {
    setIsCityMismatch(false);
    if (!buyRate || !_containers) {
      return;
    }

    if (
      _containers?.find(
        (c: any) =>
          c.final_port_id != buyRate?.intermodal_region_id ||
          c?.warehouse?.city_id != buyRate.to_city_id,
      )
    ) {
      setIsCityMismatch(true);
    }
  };

  const checkIsProfitNegative = (_containers: any, buyRate: any) => {
    setIsProfitNegative(false);
    if (!buyRate || !_containers) {
      return;
    }

    const buyRateTotal = new Decimal(get(buyRate, 'total_rate', 0));

    if (
      _containers?.find((c: any) =>
        buyRateTotal.greaterThan(
          new Decimal(get(c, 'sell_rate.total_rate', 0)),
        ),
      )
    ) {
      setIsProfitNegative(true);
    }
  };

  const handleValuesChange = (changedValues: any, values: any) => {
    if (changedValues.containers) {
      fetchBuyRateAndContainers(values.containers, values.buy_rate_id);

      if (values.containers?.find((c: any) => c && c.is_soc && c)) {
        setShowReturn(true);
        setHasSoc(true);
      } else {
        setHasSoc(false);
      }
    } else if (changedValues.buy_rate_id) {
      fetchBuyRateAndContainers(values.containers, values.buy_rate_id);
    }
    // else if (changedValues.buy_rate_id) {
    //   if (!form.getFieldValue('id') && (/B\d+$/.test(changedValues.buy_rate_id))) {
    //     autoAssignVendor(changedValues.buy_rate_id);
    //   }
    // }
  };

  // const autoAssignVendor = async (buyRateId: any) => {
  //   if (!buyRateId) {
  //     return;
  //   }

  //   try {
  //     const response = await app.service.get(
  //       `deliveryOrders/fetchBuyRate/${buyRateId}`,
  //     );

  //     form.setFieldsValue({
  //       vendor: response.data.vendor,
  //     });
  //   } catch (e: any) {
  //     message.error(e?.data?.message || e?.data?.error);
  //   }
  // };

  const handleAssignVendor = async () => {
    try {
      if (form.getFieldValue('id')) {
        const resp = await app.service.put(
          `deliveryOrders/${form.getFieldValue('id')}/assignVendoByBuyRate`,
          {
            data: {
              buy_rate_id: form.getFieldValue('buy_rate_id'),
            },
          },
        );
        message.success('Success');
        form.setFieldsValue({
          ...resp.data,
        });
      } else {
        const resp = await app.service.post(
          'deliveryOrders/assignVendoByBuyRate',
          {
            data: {
              buy_rate_id: form.getFieldValue('buy_rate_id'),
            },
          },
        );
        message.success('Success');
        form.setFieldValue('vendor', resp.data);
      }
      onSaved && onSaved();
    } catch (err: any) {
      message.error('Not Found');
    } finally {
      setCopying(false);
    }
  };

  useEffect(() => {
    checkIsProfitNegative(containers, form.getFieldValue('buy_rate'));
    checkIsWareohuseCityMismatch(containers, form.getFieldValue('buy_rate'));
  }, [containers]);

  return (
    <Form form={form} layout="vertical" onValuesChange={handleValuesChange}>
      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="buy_rate" hidden />
      <Row gutter={10}>
        <Col span={24}>
          <Search
            className="mb-sm"
            placeholder="Enter DO ID"
            enterButton="Copy"
            loading={copying}
            onSearch={handleCopyDo}
          />
        </Col>
        <Col span={12}>
          <Title level={4}>PDF Setting</Title>
          <Form.Item
            initialValue="DrayEasy Inc."
            label="Title"
            name="title"
            rules={[{ required: true, message: 'Title is required' }]}
          >
            <Input placeholder="Enter title" />
          </Form.Item>
          <Form.Item label="Title Body" name="title_body">
            <TextArea
              rows={4}
              placeholder="Please leave blank if you don't have a specific title body at D/O. It will be generated by system automatically."
            />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                initialValue={PDF_TEMPLATE_DEFAULT}
                label="Template"
                name="pdf_template"
              >
                <Select onChange={handleChangePDFTemplate}>
                  {Object.keys(PDF_TEMPLATE_MAP).map((key, index) => (
                    <Select.Option key={index} value={key}>
                      {
                        PDF_TEMPLATE_MAP[
                          (key as unknown) as keyof typeof PDF_TEMPLATE_MAP
                        ]
                      }
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Form.Item>
          <Divider />
          <Title level={4}>Trucker</Title>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                label={
                  <span>
                    Buy Rate{' '}
                    {getFieldValue('buy_rate_id') && (
                      <Space>
                        <RateLink
                          type={OPEN_TYPE_BUY_RATE}
                          rateUid={form.getFieldValue('buy_rate_id')}
                          hasBanModifyFields={true}
                          label={<EyeOutlined />}
                          showLabel={true}
                        />
                        <Button type="link" onClick={handleAssignVendor}>
                          Assign Vendor By this Buy Rate
                        </Button>
                      </Space>
                    )}
                  </span>
                }
                name="buy_rate_id"
                rules={[{ required: false, message: 'Buy Rate is required' }]}
              >
                <Input />
              </Form.Item>
            )}
          </Form.Item>

          {isCityMismatch && (
            <Tag color="red">
              IR/warehouse city in BR is different with order, please double
              check BR
            </Tag>
          )}
          {isProfitNegative && (
            <Tag color="red">Please check profit with your supervisor</Tag>
          )}

          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                label="Vendor"
                name="vendor"
                shouldUpdate
                rules={[{ required: true, message: 'Vendor is required' }]}
              >
                <VendorSelect
                  selected={getFieldValue('vendor')}
                  onSelect={(vendor) => {
                    setFieldsValue({ vendor });
                  }}
                  editable={true}
                />
              </Form.Item>
            )}
          </Form.Item>
          <Divider />
          <Title level={4}>Pickup</Title>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                label="Terminal"
                name="terminal"
                rules={[{ required: true, message: 'Terminal is required' }]}
              >
                <TerminalSelect
                  searchParams={
                    getFieldValue('final_port_id')
                      ? { intermodal_region_id: getFieldValue('final_port_id') }
                      : {}
                  }
                  selected={getFieldValue('terminal')}
                  onSelect={(terminal: any) => {
                    setFieldsValue({
                      terminal,
                      pickup_address: terminal?.delivery_order_address || '',
                    });
                  }}
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item
            label="Address"
            name="pickup_address"
            rules={[{ required: true, message: 'Address is required' }]}
          >
            <TextArea rows={4} placeholder="Enter pickup address" />
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Ref.No" name="pickup_reference_number">
                <Input placeholder="Reference Number" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <Form.Item label="Pickup Date" name="pickup_at">
                    <div>
                      <DatePicker
                        style={{ width: '100%' }}
                        placeholder="Pickup Date"
                        value={
                          getFieldValue('pickup_at')
                            ? moment(getFieldValue('pickup_at'))
                            : null
                        }
                        onChange={(date, dateString) => {
                          form.setFieldsValue({
                            pickup_at: dateString,
                          });
                        }}
                      />
                    </div>
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <DeliveryFormItems form={form} containers={containers} />
        </Col>
        <Col span={12}>
          <Title level={4}>Carrier</Title>
          <Form.Item
            label="MBL No."
            name="mbl_number"
            rules={[{ required: true, message: 'MBL No. is required' }]}
          >
            <Input placeholder="MBL Number" onBlur={handleMBLOnBlur} />
          </Form.Item>
          <Form.Item
            label="Ocean Carrier"
            name="ocean_carrier_id"
            rules={[{ required: true, message: 'Ocean Carrier is required' }]}
          >
            <OceanCarrierSelect />
          </Form.Item>
          <Form.Item
            initialValue="GENERAL MERCHANDISE"
            label="Commodity"
            name="commodity"
            rules={[{ required: true, message: 'Commodity is required' }]}
          >
            <Input placeholder="Commodity" />
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Port of Discharge"
                name="port_of_discharge_id"
                rules={[
                  { required: true, message: 'Port of Discharge is required' },
                ]}
              >
                <IntermodalRegionSelect />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="ETA"
                name="port_of_discharge_eta"
                required
                rules={[{ required: true, message: 'ETA is required' }]}
              >
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.port_of_discharge_eta !==
                    curValues.port_of_discharge_eta
                  }
                >
                  {({ getFieldValue, setFieldsValue }) => {
                    const value = getFieldValue('port_of_discharge_eta');
                    return (
                      <DatePicker
                        style={{ width: '100%' }}
                        value={value ? moment(value) : null}
                        onChange={(v) =>
                          setFieldsValue({
                            port_of_discharge_eta: v?.format('YYYY-MM-DD'),
                          })
                        }
                      />
                    );
                  }}
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Final Port (Intermodal Region)"
                name="final_port_id"
                rules={[{ required: true, message: 'Final Port is required' }]}
              >
                <IntermodalRegionSelect />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="ETA"
                name="final_port_eta"
                required
                rules={[{ required: true, message: 'ETA is required' }]}
              >
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.final_port_eta !== curValues.final_port_eta
                  }
                >
                  {({ getFieldValue, setFieldsValue }) => {
                    const value = getFieldValue('final_port_eta');
                    return (
                      <DatePicker
                        style={{ width: '100%' }}
                        value={value ? moment(value) : null}
                        onChange={(v) =>
                          setFieldsValue({
                            final_port_eta: v?.format('YYYY-MM-DD'),
                          })
                        }
                      />
                    );
                  }}
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <Form.Item
                    label="Warehouse City"
                    // name="warehouse_city"
                  >
                    <CitySelect
                      disabled
                      selected={
                        getFieldValue('warehouse')
                          ? getFieldValue('warehouse').city
                          : null
                      }
                      onSelect={(city) => {
                        // form.setFieldValue('warehouse_city', city);
                      }}
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="ETA" name="warehouse_city_eta">
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.warehouse_city_eta !==
                    curValues.warehouse_city_eta
                  }
                >
                  {({ getFieldValue, setFieldsValue }) => {
                    const value = getFieldValue('warehouse_city_eta');
                    return (
                      <DatePicker
                        style={{ width: '100%' }}
                        value={value ? moment(value) : null}
                        onChange={(v) =>
                          setFieldsValue({
                            warehouse_city_eta: v?.format('YYYY-MM-DD'),
                          })
                        }
                      />
                    );
                  }}
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                label="Warehouse City Zipcode"
                //name="warehouse_city_zipcode"
              >
                <Input
                  value={get(getFieldValue('warehouse'), 'zipcode')}
                  disabled
                  placeholder="Zipcode"
                />
              </Form.Item>
            )}
          </Form.Item>
          <Title level={4}>
            Shipping Detail{' '}
            <Button
              size="small"
              type="primary"
              ghost
              onClick={() => setShowDetail(!showDetail)}
            >
              Show {!showDetail ? 'More' : 'Less'}
            </Button>
          </Title>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                label="Gross Weight"
                name="gross_weight"
                rules={[
                  {
                    required: true,
                    validator: (_: any, value: number) => {
                      if (value > 0) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Required'));
                    },
                  },
                ]}
              >
                <Weight
                  onChange={(gross_weight: any) => {
                    setFieldsValue({ gross_weight });
                  }}
                  weight={getFieldValue('gross_weight')}
                />
              </Form.Item>
            )}
          </Form.Item>
          {showDetail && (
            <div>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item noStyle shouldUpdate>
                    {() => (
                      <Form.Item
                        label="Port of Loading"
                        name="port_of_loading_id"
                      >
                        <IntermodalRegionSelect />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => (
                      <Form.Item label="ETA" name="port_of_loading_etd">
                        <div>
                          <DatePicker
                            style={{ width: '100%' }}
                            value={
                              getFieldValue('port_of_loading_etd')
                                ? moment(getFieldValue('port_of_loading_etd'))
                                : null
                            }
                            onChange={(date, dateString) => {
                              form.setFieldsValue({
                                port_of_loading_etd: dateString,
                              });
                            }}
                          />
                        </div>
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="AMS BL No" name="ams_bl_number">
                    <Input placeholder="AMS BL Number" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="HBL No." name="hbl_number">
                    <Input placeholder="HBL Number" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Vessel Info." name="vessel">
                <Input placeholder="Vessel Info." />
              </Form.Item>
              <Form.Item label="Total Packages" name="package_count">
                <Input placeholder="Total Packages" />
              </Form.Item>
              <Form.Item label="Measurement" name="measurement">
                <Input placeholder="Total Packages" addonAfter="CBM" />
              </Form.Item>
            </div>
          )}
          <Title level={4}>Billing </Title>
          <Form.Item
            rules={[{ required: true, message: 'Billing to is required' }]}
            label={
              <Space direction="horizontal">
                Billing To
                <Button
                  size="small"
                  type="primary"
                  ghost
                  onClick={() => {
                    form.setFieldsValue({ billing_to: DEFAULT_BILL_TO });
                  }}
                >
                  BILL TO DRAYEASY{' '}
                </Button>
                <Button
                  size="small"
                  type="primary"
                  ghost
                  onClick={handleBillToCustomer}
                >
                  BILL TO CUSTOMER{' '}
                </Button>
              </Space>
            }
            initialValue={DEFAULT_BILL_TO}
            name="billing_to"
          >
            <TextArea rows={4} placeholder="Billing To" />
          </Form.Item>

          <Space>
            <Title level={4}>Remark </Title>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <DeliveryOrderFormRemindTag
                  containers={getFieldValue('containers') || []}
                />
              )}
            </Form.Item>
          </Space>
          <Form.Item name="remark">
            <TextArea rows={4} />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row gutter={16}>
        <Col span={24}>
          <Title level={4}>
            Return{' '}
            {containers.some((c: any) => c && c.is_soc) && (
              <Tag color="processing">Soc</Tag>
            )}
            <Button
              type="primary"
              ghost
              onClick={() => setShowReturn(!showReturn)}
            >
              Show
            </Button>
          </Title>
        </Col>

        {showReturn && (
          <>
            <Col span={12}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <Form.Item
                    label="Return"
                    name="return"
                    rules={[
                      { required: hasSoc, message: 'Return is required' },
                    ]}
                  >
                    <SocReturnLocationSelect
                      editable
                      selected={getFieldValue('return')}
                      onSelect={(port: any) => {
                        if (port) {
                          form.setFieldsValue({
                            return: port,
                            return_phone: port?.phone || '',
                            return_address: port?.address || '',
                            return_contact: port?.contact || '',
                            return_instruction: port?.return_instruction || '',
                          });
                        } else {
                          form.setFieldsValue({
                            return: null,
                          });
                        }
                      }}
                    />
                    {/* <WarehouseSelect
                      selected={getFieldValue('return')}
                      onSelect={(port) => {
                        form.setFieldsValue({ return: port });
                      }}
                    /> */}
                  </Form.Item>
                )}
              </Form.Item>
              <Form.Item
                label="Return Address"
                name="return_address"
                rules={[
                  { required: hasSoc, message: 'Return Address is required' },
                ]}
              >
                <TextArea rows={4} placeholder="Return Address" />
              </Form.Item>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Contact" name="return_contact">
                    <Input placeholder="Return Contact" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Phone" name="return_phone">
                    <Input placeholder="Return Phone" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Ref.No" name="return_reference_number">
                    <Input placeholder="Return Reference Number" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => (
                      <Form.Item label="Date" name="return_at">
                        <div>
                          <DatePicker
                            style={{ width: '100%' }}
                            value={
                              getFieldValue('return_at')
                                ? moment(getFieldValue('return_at'))
                                : null
                            }
                            onChange={(date, dateString) => {
                              form.setFieldsValue({
                                return_at: dateString,
                              });
                            }}
                          />
                        </div>
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Empty Return Instruction"
                name="return_instruction"
              >
                <TextArea rows={4} placeholder="Empty Return Instruction" />
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
      <Divider />
      <Row>
        <Col span={24}>
          <Title level={4}>
            Containers <Button onClick={handleAddContainer}>Add</Button>
          </Title>
          <Form.Item noStyle name="containers">
            <Input hidden />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <ContainerTable
                containers={getFieldValue('containers') || []}
                onChange={(containers) => {
                  setFieldsValue({ containers });
                }}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row gutter={16}>
        <Col span={24}>
          <Title level={4}>Instruction</Title>
          <Form.Item
            label="Instruction"
            initialValue={DEFUALT_INSTRUCTION}
            name="instruction"
          >
            <TextArea rows={4} placeholder="Instruction" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
