import { useMemo } from 'react';
import { Button, Popconfirm, Space, Table, TablePaginationConfig } from 'antd';
import type { TableProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Containers from './ContainersTag';
import type { TSpotRate } from '@/types';
import { useSpotRateFormDrawer } from '@/components/useSpotRateForm';
import AutoResizeTable from '@/components/AutoResizeTable';

interface SpotRateTableProps extends TableProps<TSpotRate[]> {
  loading: boolean;
  pagination: TablePaginationConfig;
  handleRemove: (id: number) => any;
}

const Index = ({
  loading,
  pagination,
  dataSource,
  onChange,
  handleRemove,
}: SpotRateTableProps) => {
  const { SpotRateFormDrawer, openSpotRateFormDrawer } =
    useSpotRateFormDrawer();

  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 80,
        defaultSortOrder: 'descend',
        sorter: (a, b) => Number(a.id) - Number(b.id),
      },
      {
        title: 'Quote Date',
        dataIndex: 'quote_date',
        key: 'quote_date',
        width: 80,
        defaultSortOrder: 'descend',
        sorter: (a, b) =>
          new Date(a.quote_date).getTime() - new Date(b.quote_date).getTime(),
      },
      {
        title: 'Quote Expire Date',
        dataIndex: 'expired_date',
        key: 'expired_date',
        width: 80,
      },
      {
        title: 'Quote Type',
        dataIndex: 'type',
        key: 'type',
        width: 80,
      },
      {
        title: 'Sales Person',
        dataIndex: 'sales',
        key: 'sales',
        width: 80,
      },
      {
        title: 'Customer',
        dataIndex: 'customer_name',
        key: 'customer_name',
        width: 80,
      },
      {
        title: 'POD',
        dataIndex: 'pod_name',
        key: 'pod_name',
        width: 80,
      },
      {
        title: 'DEL(IR)',
        dataIndex: 'del_name',
        key: 'del_name',
        width: 80,
      },
      {
        title: 'F.DEST',
        dataIndex: 'fdest_name',
        key: 'fdest_name',
        width: 80,
      },
      {
        title: 'Containers',
        dataIndex: 'containers',
        key: 'containers',
        width: 80,
        render: (text, record) => (
          <>
            {record.containers.size_type}
            <Containers container={record.containers[0]} />
          </>
        ),
      },
      {
        key: 'action',
        width: 150,
        fixed: true,
        render: (text, record, index) => (
          <Space>
            <Button
              type="link"
              onClick={() => {
                openSpotRateFormDrawer(record.id, true);
              }}>
              edit
            </Button>

            <Popconfirm
              placement="top"
              title={'Are you sure to delete?'}
              onConfirm={() => {
                handleRemove(record.id);
              }}
              okText="Yes"
              cancelText="No">
              <Button type="link">delete</Button>
            </Popconfirm>
          </Space>
        ),
      },
    ],
    [],
  );

  return (
    <>
      <AutoResizeTable
        loading={loading}
        pagination={pagination}
        size="small"
        rowKey="id"
        columns={columns}
        onChange={onChange}
        dataSource={dataSource ?? []}
        sticky
        scroll={{
          x: 'auto',
        }}
      />
      <SpotRateFormDrawer />
    </>
  );
};

export default Index;
