import React from 'react';
import { DatePicker, Space } from 'antd';
import type { Dayjs } from 'dayjs';

import { Moment } from 'moment';
import 'res/css/antDRangePicker.scss';

import { CSSProperties, FC } from 'react';
import {
  RANGE_TYPES_MAP,
  RANGE_TYPES_DEFAULT,
  TRangePickerValue,
} from './data';

const { RangePicker } = DatePicker;

interface IAntDRangePicker {
  rangeType?: string;
  className?: string;
  style?: CSSProperties;
  value?: TRangePickerValue;
  onChange?: (dates: TRangePickerValue) => void;
}

export const AntDRangePicker: FC<IAntDRangePicker> = ({
  rangeType = 'default',
  className,
  style,
  value,
  onChange,
  ...props
}) => {
  const ranges = React.useMemo(() => {
    if (
      !RANGE_TYPES_MAP[rangeType as unknown as keyof typeof RANGE_TYPES_MAP]
    ) {
      return RANGE_TYPES_MAP[RANGE_TYPES_DEFAULT];
    } else {
      return RANGE_TYPES_MAP[
        rangeType as unknown as keyof typeof RANGE_TYPES_MAP
      ];
    }
  }, [rangeType]);

  const handleOnChange = (dates: TRangePickerValue) => {
    onChange && onChange(dates);
  };

  return (
    <>
      <RangePicker
        // className={className}
        style={style}
        {...props}
        value={value}
        onChange={handleOnChange}
        popupClassName="antDRangePicker"
        // dropdownClassName={styles.antdDaterange}
        ranges={ranges}
      />
    </>
  );
};
