import React from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import { Button, Drawer, message, Space, Popconfirm, Table, Tag } from 'antd';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { DrawerForm } from './components/DrawerForm';
import { useForm } from 'antd/lib/form/Form';
import { TPlan, TCollection } from '@/types';
import { get, upperFirst } from 'lodash';

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [title, setTitle] = React.useState('Create Plan');
  const [data, setData] = React.useState<TCollection<TPlan>>();
  const [form] = useForm();

  const fetchData = React.useCallback(async () => {
    setLoading(true);

    try {
      const result = await app.service.get('plans', {
        params: {},
      });

      setData(result);
    } catch (e: any) {
      message.error(e.error || 'System Error');
    }

    setLoading(false);
  }, []);

  const addNew = () => {
    form.resetFields();
    form.setFieldsValue({
      billing_interval: 'monthly',
      enabled: true,
    });
    setTitle('Create Plan');
    setVisible(true);
  };

  const handleSave = async () => {
    const values = await form.validateFields();
    setLoading(true);

    try {
      !values.id
        ? await app.service.post('plans', { data: values })
        : await app.service.put(`plans/${values.id}`, { data: values });
      message.success('Saved');
      fetchData();
      setVisible(false);
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  const handleRemove = async (id: number) => {
    setLoading(true);

    try {
      await app.service.delete(`plans/${id}`);

      message.success('Delete');

      fetchData();
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(true);
  };

  const handleEdit = async (plan: TPlan) => {
    setLoading(true);

    try {
      setTitle('Edit Plan');
      form.resetFields();
      await form.setFieldsValue(plan);
      setVisible(true);
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  const columns: ColumnsType<any> = React.useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 80,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 150,
      },
      {
        title: 'Slug',
        dataIndex: 'slug',
        key: 'slug',
        width: 200,
      },
      {
        title: 'Subscription Name',
        dataIndex: 'stripe_plan',
        key: 'stripe_plan',
        width: 200,
      },
      {
        title: 'Cost',
        dataIndex: 'cost',
        key: 'cost',
        width: 200,
      },
      {
        title: 'Billing Interval',
        dataIndex: 'billing_interval',
        key: 'billing_interval',
        width: 200,
        render: (text, record) => (
          <>{upperFirst(get(record, 'billing_interval', ''))}</>
        ),
      },
      {
        title: 'Enabled',
        dataIndex: 'enabled',
        key: 'enabled',
        width: 200,
        render: (text, record) => {
          const enabled = get(record, 'enabled');
          if (true === enabled) {
            return <Tag color="success">{'YES'}</Tag>;
          } else {
            return <Tag color="red">{'NO'}</Tag>;
          }
        },
      },
      {
        align: 'center',
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 150,
        render: (text, record) => (
          <Space>
            <a onClick={() => handleEdit(record)}>Edit</a>
            <Popconfirm
              placement="left"
              title="Sure to delete?"
              okText="Confirm"
              cancelText="Cancel"
              onConfirm={() => handleRemove(record.id)}>
              <a>Delete</a>
            </Popconfirm>
          </Space>
        ),
      },
    ],
    [],
  );

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.main}>
      <Header
        title="Plans"
        rightElement={
          <div>
            <Button type="primary" onClick={addNew}>
              New Plan
            </Button>
          </div>
        }></Header>

      <Table
        loading={loading}
        pagination={false}
        size="small"
        rowKey="id"
        columns={columns}
        onChange={fetchData}
        dataSource={data?.data || []}
        sticky
        scroll={{
          x: 'auto',
        }}
      />

      <Drawer
        title={title}
        placement="right"
        width="45%"
        onClose={() => setVisible(false)}
        destroyOnClose={true}
        open={visible}
        extra={
          <Space>
            <Button onClick={() => setVisible(false)}>Close</Button>
            <Button type="primary" loading={loading} onClick={handleSave}>
              Save
            </Button>
          </Space>
        }>
        <DrawerForm form={form} onSubmit={handleSave} />
      </Drawer>
    </div>
  );
};

export default Index;
