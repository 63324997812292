import { Form, Row, Col, Input, DatePicker, FormInstance } from 'antd';
import { Typography } from 'antd';
import { WarehouseFormItem } from './WarehouseFormItem';
import { MultipleDeliveriesFormList } from '@/pages/orders/components/DeliveryOrderDrawer/MultipleDeliveriesFormList';
import { useEffect } from 'react';

const { Title } = Typography;

export const DeliveryFormItems: React.FC<{
  form: FormInstance;
  containers?: any;
}> = ({ form, containers }) => {
  const hasMultiple = containers?.find((c) => c.is_multi_deliveries)
    ? true
    : false;

  useEffect(() => {
    if (hasMultiple && containers?.length > 0) {
      if (form.getFieldValue('multiple_deliveries') === undefined) {
        const multipleDeliveries = containers
          .filter((c) => c?.multiple_deliveries?.length > 0)
          .flatMap((c) => c.multiple_deliveries)
          .sort((a, b) => a.serial_number - b.serial_number);
        form.setFieldsValue({ multiple_deliveries: multipleDeliveries });
      }
    }
  }, [hasMultiple, containers]);

  return (
    <>
      <Title level={4}>Delivery</Title>
      {hasMultiple ? (
        <MultipleDeliveriesFormList form={form} />
      ) : (
        <WarehouseFormItem form={form} />
      )}
      {/* <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue, setFieldsValue }) => (
                        <Form.Item
                            label="Warehouse"
                            name="warehouse"
                            rules={[{ required: true, message: 'Warehouse is required' }]}>
                            <WarehouseSelect
                                forbidToModfiySpecifyField={true}
                                selected={getFieldValue('warehouse')}
                                onSelect={(warehouse: any) => {
                                    setFieldsValue({
                                        warehouse,
                                        delivery_address: warehouse?.delivery_order_address || '',
                                    });
                                }}
                                editable={true}
                            />
                        </Form.Item>
                    )}
                </Form.Item>
                <Form.Item
                    label="Address"
                    name="delivery_address"
                    rules={[{ required: true, message: 'Address is required' }]}>
                    <TextArea rows={4} placeholder="Enter delivery address" />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Ref.No" name="delivery_reference_number">
                            <Input placeholder="Reference Number" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) => (
                                <Form.Item label="Pickup Date" name="delivery_at">
                                    <div>
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            placeholder="Date"
                                            value={
                                                getFieldValue('delivery_at')
                                                    ? moment(getFieldValue('delivery_at'))
                                                    : null
                                            }
                                            onChange={(date, dateString) => {
                                                form.setFieldsValue({
                                                    delivery_at: dateString,
                                                });
                                            }}
                                        />
                                    </div>
                                </Form.Item>
                            )}
                        </Form.Item>
                    </Col>
                </Row> */}
    </>
  );
};
