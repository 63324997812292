import { useApp } from '@/utils/useapp';
import { Badge, Button, message, Modal, Popconfirm, Space, Switch } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { BellOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import AutoResizeTable from './AutoResizeTable';
import { ColumnsType } from 'antd/lib/table';
import { history } from 'umi';

const INTERVAL_TIME = 1000 * 60 * 10; // 10min

const REDIRECT_MAP = {
  'App\\Models\\Container': {
    pathname: '/dispatchs',
    search: (id: number | string) => `?containerId=${id}&open=1`,
  },
  'App\\Models\\Order': {
    pathname: '/orders',
  },
  'App\\Models\\OrderBill': {
    pathname: '/accounting/bills',
    search: (id: number | string) => `?billId=${id}&open=1`,
  },
  'App\\Models\\OrderInvoice': {
    pathname: '/accounting/invoices',
    search: (id: number | string) => `?invoiceId=${id}&open=1`,
  },
  'App\\Models\\Vendor': {
    pathname: '/entity/vendors',
    search: (id: number | string) => `?vendorId=${id}&open=1`,
  },
  'App\\Domains\\TL\\Models\\LTLShipment': {
    pathname: '/truckload/ltl/shipments',
    search: (id: number | string) => `?shipmentId=${id}&open=1`,
  },
  'App\\Domains\\FTL\\Models\\FTLShipment': {
    pathname: '/truckload/ftl/shipments',
    search: (id: number | string) => `?shipmentId=${id}&open=1`,
  },
};

const Notification = observer(() => {
  const app = useApp();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [focus, setFocus] = useState(true);
  const [showAll, setShowAll] = useState(false);

  const fetchUnreadCount = async () => {
    try {
      const resp = await app.service.get('notifications/unreadCount');
      app.store.notification.setUnreadCount(resp.data);
      app.store.onlineUsers.setUsers(resp.meta);
    } catch (err: any) {
      message.error(err?.data?.message || '');
    }
  };

  const fetch = async () => {
    setLoading(true);
    try {
      if (showAll) {
        const resp = await app.service.get('notifications');
        app.store.notification.setUnread(resp.data);
        setLoading(false);
      } else {
        const resp = await app.service.get('notifications/unread');
        app.store.notification.setUnread(resp.data);
        setLoading(false);
      }
    } catch (err: any) {
      message.error(err?.data?.message || '');
    }
  };

  // const fetchAll = async () => {
  //   setLoading(true);
  //   try {
  //     const resp = await app.service.get('notifications/index');
  //     app.store.notification.setUnread(resp.data);
  //     setLoading(false);
  //   } catch (err: any) {
  //     message.error(err.data.message);
  //   }
  // };

  const onOpen = async () => {
    setOpen(true);
    // await fetch();
  };

  useEffect(() => {
    if (open) {
      fetch();
    }
  }, [showAll, open]);

  const markAsRead = async (id: number) => {
    try {
      await app.service.post(`notifications/${id}/read`);
      fetch();
      fetchUnreadCount();
    } catch (err: any) {
      message.error(err?.data?.message || '');
    }
  };

  const readMessage = (uid: string, record: any) => {
    // if (record.data.commentable_type == 'App\\Models\\Container') {
    //   setOpen(false);
    //   history.push({
    //     pathname: '/dispatchs',
    //     search: '?containerId=' + record.data.commentable_id + '&open=1',
    //   });
    //   markAsRead(record.id);
    // } else if (record.data.commentable_type == 'App\\Models\\Order') {
    //   app.store.notification.setRead(uid);
    //   setOpen(false);
    //   history.push({
    //     pathname: '/orders',
    //   });
    //   markAsRead(record.id);
    // } else if (record.data.commentable_type == 'App\\Models\\OrderBill') {
    //   app.store.notification.setRead(uid);
    //   setOpen(false);
    //   history.push({
    //     pathname: '/accounting/bills',
    //     search: '?billId=' + record.data.commentable_id + '&open=1',
    //   });
    //   markAsRead(record.id);
    // } else if (record.data.commentable_type == 'App\\Models\\OrderInvoice') {
    //   app.store.notification.setRead(uid);
    //   setOpen(false);
    //   history.push({
    //     pathname: '/accounting/invoices',
    //     search: '?invoiceId=' + record.data.commentable_id + '&open=1',
    //   });
    //   markAsRead(record.id);
    // } else if (record.data.commentable_type == 'App\\Models\\Vendor') {
    //   app.store.notification.setRead(uid);
    //   setOpen(false);
    //   history.push({
    //     pathname: '/entity/vendors',
    //     search: '?vendorId=' + record.data.commentable_id + '&open=1',
    //   });
    //   markAsRead(record.id);
    // } else if (record.data.messageable_type == 'App\\Models\\Order') {
    //   app.store.notification.setRead(uid);
    //   setOpen(false);
    //   history.push({
    //     pathname: '/orders',
    //   });
    //   markAsRead(record.id);
    // }

    const redirect =
      REDIRECT_MAP[
        record.data.messageable_type || record.data.commentable_type
      ];
    if (redirect) {
      app.store.notification.setRead(uid);
      setOpen(false);
      history.push({
        pathname: redirect.pathname,
        search: redirect.search
          ? redirect.search(
              record.data.messageable_id || record.data.commentable_id,
            )
          : '',
      });
      markAsRead(record.id);
    }
  };

  useEffect(() => {
    focus && fetchUnreadCount();
    const interval = setInterval(
      () => focus && fetchUnreadCount(),
      INTERVAL_TIME,
    );

    const doFocus = () => {
      // fetchUnreadCount();
      setFocus(true);
    };

    const undoFocus = () => {
      setFocus(false);
    };

    window.addEventListener('focus', doFocus);
    window.addEventListener('blur', undoFocus);

    return () => {
      clearInterval(interval);
      window.removeEventListener('focus', doFocus);
      window.removeEventListener('blur', undoFocus);
    };
  }, [focus]);

  const markAllAsRead = async () => {
    try {
      await app.service.post('notifications/markAllAsRead');
      fetch();
      fetchUnreadCount();
    } catch (err: any) {
      message.error(err.data.message);
    }
  };

  const target = (data: any) => {
    if (
      data.type == 'App\\Notifications\\AdminCommentMentioned' ||
      data.type == 'App\\Notifications\\AdminCommentMentionedRelated'
    ) {
      return data.data.commentable_id;
    }
    return data.data.messageable_uid;
  };

  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: 'Message Target',
        dataIndex: 'messageable_uid',
        key: 'id',
        sorter: true,
        width: 80,
        fixed: 'left',
        render: (text, record) => target(record),
      },
      {
        title: 'Content',
        dataIndex: 'content',
        key: 'content',
        sorter: true,
        width: 160,
        fixed: 'left',
        render: (text, record) => record.data.body,
      },
      {
        title: 'Created At',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: true,
        width: 160,
        fixed: 'left',
        render: (text, record) => record.created_at,
      },
      {
        align: 'center',
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 120,
        render: (text, record) => (
          <Space split="|">
            <a onClick={() => readMessage(record.data.messageable_uid, record)}>
              Read
            </a>
            {!record.read_at && (
              <a onClick={() => markAsRead(record.id)}>Mark As Read</a>
            )}
          </Space>
        ),
      },
    ],
    [app.store.notification.unread],
  );

  const data = useMemo(() => {
    const ms: any[] = app.store.notification?.unread || [];
    return showAll ? ms : ms.filter((m) => !m.read_at);
  }, [app.store.notification.unread, showAll]);

  return (
    <>
      <Badge size="small" count={app.store.notification.unreadCount}>
        <BellOutlined onClick={onOpen} style={{ color: 'white' }} />
      </Badge>
      <Modal
        title={
          <Space>
            {'Messages'}
            <Switch
              className="ml-lg"
              size="small"
              checked={showAll}
              onChange={() => setShowAll(!showAll)}
            />
            <Popconfirm
              title="Are you sure to mark all as read?"
              onConfirm={markAllAsRead}
            >
              <Button type="link">Mark All as Read</Button>
            </Popconfirm>
            <span> Show All</span>
          </Space>
        }
        centered
        open={open}
        width={1000}
        cancelText={'Close'}
        okText={false}
        onCancel={() => setOpen(false)}
      >
        <AutoResizeTable
          loading={loading}
          pagination={false}
          size="small"
          rowKey={'id'}
          columns={columns}
          dataSource={data}
          sticky
          scroll={{
            x: 'auto',
          }}
        />
      </Modal>
    </>
  );
});

export default Notification;
