import Icon, { HomeOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import React from 'react';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import styles from 'res/css/ui.scss';

const OverweightSvg = () => (
  <svg
    viewBox="64 64 896 896"
    focusable="false"
    data-icon="gateway"
    width="1em"
    height="1em"
    fill="currentColor"
    aria-hidden="true">
    <path d="M416 560h48v192h80V480h-128v80z m505.76 253.712l-116.992-448A104.032 104.032 0 0 0 704.128 288h-66.32A151.04 151.04 0 0 0 656 216C656 132.192 587.808 64 504 64S352 132.192 352 216a151.04 151.04 0 0 0 18.192 72H303.84a104.032 104.032 0 0 0-100.624 77.712l-116.992 448a103.264 103.264 0 0 0 18.304 89.856A103.264 103.264 0 0 0 186.88 944H821.12c32.48 0 62.48-14.72 82.32-40.432a103.28 103.28 0 0 0 18.304-89.856zM504 144c39.696 0 72 32.304 72 72S543.696 288 504 288 432 255.696 432 216 464.304 144 504 144z m336.144 710.672A23.632 23.632 0 0 1 821.12 864H186.88c-10.24 0-16.304-5.84-19.008-9.328a23.6 23.6 0 0 1-4.224-20.736l117.008-448A24 24 0 0 1 303.856 368H704.16a24 24 0 0 1 23.216 17.936l117.008 448a23.6 23.6 0 0 1-4.224 20.736z"></path>
  </svg>
);
const _OverweightIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={OverweightSvg} {...props} />
);

export const OverweightIcon: React.FC = () => (
  <span className={styles.customer_icon}>
    <_OverweightIcon />
  </span>
);
