export const GENERAL_LEDGER_CODE_TYPE_ASSET = 1;
export const GENERAL_LEDGER_CODE_TYPE_LIABILITY = 2;
export const GENERAL_LEDGER_CODE_TYPE_EQUITY = 3;
export const GENERAL_LEDGER_CODE_TYPE_INCOME = 4;
export const GENERAL_LEDGER_CODE_TYPE_COST = 5;
export const GENERAL_LEDGER_CODE_TYPE_EXPENSE = 6;
export const GENERAL_LEDGER_CODE_TYPE_OTHER_INCOME = 7;
export const GENERAL_LEDGER_CODE_TYPE_OTHER_EXPENSE = 8;
export const GENERAL_LEDGER_CODE_TYPE_INCOME_TAX = 9;

export const GENERAL_LEDGER_CODE_TYPES_MAP = {
  [GENERAL_LEDGER_CODE_TYPE_ASSET]: 'ASSET',
  [GENERAL_LEDGER_CODE_TYPE_LIABILITY]: 'LIABILITY',
  [GENERAL_LEDGER_CODE_TYPE_EQUITY]: 'EQUITY',
  [GENERAL_LEDGER_CODE_TYPE_INCOME]: 'INCOME',
  [GENERAL_LEDGER_CODE_TYPE_COST]: 'COST',
  [GENERAL_LEDGER_CODE_TYPE_EXPENSE]: 'EXPENSE',
  [GENERAL_LEDGER_CODE_TYPE_OTHER_INCOME]: 'OTHER_INCOME',
  [GENERAL_LEDGER_CODE_TYPE_OTHER_EXPENSE]: 'OTHER_EXPENSE',
  [GENERAL_LEDGER_CODE_TYPE_INCOME_TAX]: 'INCOME_TAX',
};
