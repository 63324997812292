import React, { useEffect, useMemo, useState } from 'react';
import { useApp } from '@/utils/useapp';
import { message, Table } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { get, truncate } from 'lodash';

export const ExtraCostTable: React.FC<{
  show: boolean;
  containers: any;
}> = ({ show = false, containers }) => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const containerIds = useMemo(() => containers.map((c) => c.id), [containers]);

  const fetchData = React.useCallback(async () => {
    setLoading(true);
    try {
      const result = await app.service.get('extraCosts', {
        params: {
          containerIds: containerIds,
        },
      });

      setData(result.data);
    } catch (e: any) {
      message.error(e.error || 'System Error');
    }

    setLoading(false);
  }, []);

  const columns: ColumnsType<any> = React.useMemo(() => {
    const defaultColumns = [
      // {
      //     title: 'ID',
      //     dataIndex: 'id',
      //     key: 'id',
      //     width: 80,
      // },
      // {
      //   title: 'Type',
      //   dataIndex: 'type_name',
      //   key: 'type_name',
      //   width: 150,
      // },
      {
        title: 'Billing Code',
        dataIndex: ['billing_code', 'name'],
        key: 'billing_code',
        width: 200,
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        width: 150,
      },
      {
        title: 'Qty',
        dataIndex: 'qty',
        key: 'qty',
        width: 100,
      },
      {
        title: 'Amount Total',
        dataIndex: 'amount_total',
        key: 'amount_total',
        width: 150,
      },
      {
        title: 'Start At',
        dataIndex: 'start_at',
        key: 'start_at',
        width: 100,
      },
      {
        title: 'End At',
        dataIndex: 'end_at',
        key: 'end_at',
        width: 100,
      },
      {
        title: 'Reason',
        dataIndex: 'reason',
        key: 'reason',
        width: 200,
        render: (text, record) =>
          truncate(get(record, 'reason', ''), {
            length: 300,
          }),
      },
    ];

    if (containerIds.length > 1) {
      defaultColumns.unshift({
        title: 'CNTR',
        dataIndex: ['container', 'number'],
        key: 'containerNumber',
        width: 150,
      });
    }
    return defaultColumns;
  }, [containerIds]);

  useEffect(() => {
    show && containerIds?.length > 0 && fetchData();
  }, [containerIds, show]);

  return (
    <>
      {show && (
        <Table
          loading={loading}
          pagination={false}
          size="small"
          rowKey="id"
          columns={columns}
          dataSource={data || []}
          sticky
          scroll={{
            x: 400,
            y: 400,
          }}
        />
      )}
    </>
  );
};
