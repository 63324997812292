import React, { useState } from 'react';
import DrawerForm from './components/DrawerForm';

export const useContractRatesDrawer = () => {
  const [vendor, setVendor] = useState<any>(undefined);
  const [visible, setVisible] = useState(false);
  const [isChangeHistory, setIsChangeHistory] = useState(false);

  const closeDrawer = (
    e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
  ) => {
    e.stopPropagation();
    setVisible(false);
    isChangeHistory && window.history.back();
    !isChangeHistory && window.history.pushState(null, '', '/entity/vendors');
    setIsChangeHistory(false);
  };

  const ContractRatesTableDrawer = () =>
    vendor ? (
      <DrawerForm
        vendor_id={vendor.id}
        contractRates={vendor.contract_rates}
        visible={visible}
        intermodal_region_id={vendor.intermodal_region_id}
        onClose={closeDrawer}
      />
    ) : null;

  const openContractRatesTableDrawer = (
    vendor: any,
    enableChangeHistory?: boolean,
  ) => {
    if (enableChangeHistory) {
      // window.history.pushState(null, '', `./vendors/${id}/`);
      setIsChangeHistory(true);
    }
    setVendor(vendor);
    setVisible(true);
  };

  return { ContractRatesTableDrawer, openContractRatesTableDrawer };
};
