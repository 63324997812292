import { useApp } from '@/utils/useapp';
import {
  Popover,
  DatePicker,
  message,
  Button,
  Modal,
  Tooltip,
  Row,
  Input,
  Space,
} from 'antd';
import React, { useState } from 'react';
import { get } from 'lodash';
import { BaseButtonProps } from 'antd/lib/button/button';
import moment from 'moment';
import { auth } from '@/stores';
import { CustomerConfirmModal } from './CustomerConfirmModal';
type TProps = {
  confirmed_at: string;
  cancel_customer_confirmed_reason?: string;
  orderInvoiceId: number;
  onSetLoading: (loading: boolean) => void;
  onSaved: (orderInvoiceId: number) => void;
};

export const CustomerConfirm: React.FC<TProps & BaseButtonProps> = ({
  confirmed_at,
  cancel_customer_confirmed_reason,
  orderInvoiceId,
  onSetLoading,
  onSaved,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const buttonText = confirmed_at
    ? 'Cancel Customer Confirm'
    : 'Customer Confirm';
  const buttonType = confirmed_at ? 'primary' : 'default';
  const tooltipTilteText = confirmed_at
    ? 'Cancel customer confirm the invoice'
    : cancel_customer_confirmed_reason
      ? cancel_customer_confirmed_reason
      : 'Customer confirm the invoice';

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  return (
    <>
      <Tooltip placement="right" title={tooltipTilteText}>
        <Button type={buttonType} onClick={handleOpen}>
          {buttonText}
        </Button>
      </Tooltip>
      {open && (
        <CustomerConfirmModal
          open={open}
          text={buttonText}
          confirmed_at={confirmed_at}
          orderInvoiceId={orderInvoiceId}
          cancel_customer_confirmed_reason={cancel_customer_confirmed_reason}
          onClose={handleClose}
          onOpen={handleOpen}
          onSaved={onSaved}
          onSetLoading={onSetLoading}
        />
      )}
    </>
  );
};
