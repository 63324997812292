export const CONTAINER_EXTRA_COST_TYPE_DEMURRAGE = 1;
export const CONTAINER_EXTRA_COST_TYPE_PERDIEM = 2;
export const CONTAINER_EXTRA_COST_TYPE_YARD_STORAGE = 3;
export const CONTAINER_EXTRA_COST_TYPE_TOLL_FEE = 4;
export const CONTAINER_EXTRA_COST_TYPE_PREPULL = 5;
export const CONTAINER_EXTRA_COST_TYPE_EXAM_STOP = 6;
export const CONTAINER_EXTRA_COST_TYPE_DRY_RUN = 7;
export const CONTAINER_EXTRA_COST_TYPE_WAITING_TIME = 8;
export const CONTAINER_EXTRA_COST_TYPE_CHASSIS_SPLIT = 9;
export const CONTAINER_EXTRA_COST_TYPE_OTHER = 10;
export const CONTAINER_EXTRA_COST_TYPE_OVER_WERIGHT = 11;
export const CONTAINER_EXTRA_COST_TYPE_HANCH = 12;
export const CONTAINER_EXTRA_COST_TYPE_TRI_AXLE = 13;
export const CONTAINER_EXTRA_COST_TYPE_STOP_OFF = 14;
export const CONTAINER_EXTRA_COST_TYPE_HAZMAT_PLACARD_REMOVAL = 15;
export const CONTAINER_EXTRA_COST_TYPE_RUSH_DO_FEE = 16;
export const CONTAINER_EXTRA_COST_TYPE_CANCELLATION_FEE = 17;
export const CONTAINER_EXTRA_COST_TYPE_WEEKEND_SURCHARGE = 18;

export const DISPATCH_STATUS_DO_RECEIVED = 0;
export const DISPATCH_STATUS_DO_SENT = 3;
export const DISPATCH_STATUS_DO_CONFIRMED = 4;
export const DISPATCH_STATUS_PU_RECEIVED = 5;
export const DISPATCH_STATUS_PU_SENT = 7;
export const DISPATCH_STATUS_ALL_RELEASED = 11;
export const DISPATCH_STATUS_TERMINAL_APT_MADE = 9;
export const DISPATCH_STATUS_WHS_APT_MADE = 13;
export const DISPATCH_STATUS_DROPPED = 15;
export const DISPATCH_STATUS_CNTR_EMPTY = 17;
export const DISPATCH_STATUS_DELIVERED = 19;
export const DISPATCH_STATUS_EMPTY_RETURENED = 21;
export const DISPATCH_STATUS_OP_CLOSED = 25;
export const DISPATCH_STATUS_CANCELED = -5;

export const CONTAINER_EXTRA_COST_TYPE_MAP = {
  [CONTAINER_EXTRA_COST_TYPE_OTHER]: 'Other',
  [CONTAINER_EXTRA_COST_TYPE_DEMURRAGE]: 'Demurrage',
  [CONTAINER_EXTRA_COST_TYPE_PERDIEM]: 'PerDiem',
  [CONTAINER_EXTRA_COST_TYPE_YARD_STORAGE]: 'Yard Storage',
  [CONTAINER_EXTRA_COST_TYPE_TOLL_FEE]: 'Toll Fee',
  [CONTAINER_EXTRA_COST_TYPE_PREPULL]: 'PrePull',
  [CONTAINER_EXTRA_COST_TYPE_EXAM_STOP]: 'Exam Stop',
  [CONTAINER_EXTRA_COST_TYPE_DRY_RUN]: 'Dry Run',
  [CONTAINER_EXTRA_COST_TYPE_WAITING_TIME]: 'Waiting Time',
  [CONTAINER_EXTRA_COST_TYPE_CHASSIS_SPLIT]: 'Chassis Split',
  [CONTAINER_EXTRA_COST_TYPE_OVER_WERIGHT]: 'Over Weight',
  [CONTAINER_EXTRA_COST_TYPE_HANCH]: 'Admin Fee',
  [CONTAINER_EXTRA_COST_TYPE_TRI_AXLE]: 'Tri-axle',
  [CONTAINER_EXTRA_COST_TYPE_STOP_OFF]: 'Stop Off',
  [CONTAINER_EXTRA_COST_TYPE_HAZMAT_PLACARD_REMOVAL]: 'Hazmat Placard Removal',
  [CONTAINER_EXTRA_COST_TYPE_RUSH_DO_FEE]: 'Rush DO fee',
  [CONTAINER_EXTRA_COST_TYPE_CANCELLATION_FEE]: 'Cancellation fee',
  [CONTAINER_EXTRA_COST_TYPE_WEEKEND_SURCHARGE]: 'Weekend Surcharge',
};

export const CONTAINER_TROUBLE_STATUES = {
  // freight_hold: 'Freight Hold',
  // customs_hold: 'Customs Hold',
  // customs_exam: 'Customs Exam',
  met_exam: 'Met Exam',
  cet_exam: 'Cet Exam',
  customer_hold: 'Customer Hold',
  closed_area: 'Closed Area',
  demurrage: 'Demurrage',
  customer_canceled_order: 'Customer canceled order',
  x_ray_exam: 'X-Ray Exam',

  // still_on_vessel: 'Still On Vessel',
};

export const CONTAINER_TROUBLE_STATUE_TIPS = {
  customer_hold: 'Customer Request To Hold',
};

export const CONTAINER_HISTORY_TROUBLE_STATUES = {
  history_freight_hold: 'Freight Hold',
  history_customs_hold: 'Customs Hold',
  history_closed_area: 'Closed Area',
  history_still_on_vessel: 'Still On Vessel',
  history_met_exam: 'Met Exam',
  history_cet_exam: 'Cet Exam',
  // history_customs_exam: 'Customs Exam',
  history_customer_hold: 'Customer Hold',
  history_demurrage: 'Demurrage',
  history_customer_canceled_order: 'Customer canceled order',
  history_x_ray_exam: 'X-Ray Exam',
};

const STATUS_ABBREVIATION_ORDER_CONF = 'Order Conf';
const STATUS_ABBREVIATION_PREDELIVERY_APPOINTMENT_SENT = 'PreDel Appt Sent';
const STATUS_ABBREVIATION_PREDELIVERY_APPOINTMENT_CONFIRMED =
  'PreDel Appt Conf';
const STATUS_ABBREVIATION_DO_CONFIRMED = 'DO Conf';
const STATUS_ABBREVIATION_PU_RECEIVED = 'PU# Rcvd';
const STATUS_ABBREVIATION_EXAM_DO_RECEIVED = 'Exam DO Rcvd';
const STATUS_ABBREVIATION_MET_EXAM_DO_CONFIRMED = 'MET Exam DO Conf';
const STATUS_ABBREVIATION_CET_EXAM_SITE_PICKUP_APT_CONFIRMED =
  'CET Exam Site PU Appt Conf';
const STATUS_ABBREVIATION_MET_EXAM_SITE_DELIVERY_APT_CONFIRMED =
  'MET Exam Site Del Appt Conf';
const STATUS_ABBREVIATION_WHS_APT_CONFIRMED = ' WHS Appt Conf';
const STATUS_ABBREVIATION_WHS_APT_MADE_BY_CUSTOMER =
  'WHS Appt Made By Customer';
const STATUS_ABBREVIATION_MET_EXAM_SITE_PICKUP_APT_CONFIRMED =
  'MET Exam Site PU Appt Conf';
const STATUS_ABBREVIATION_WHS_APT_CONFIRME_MET = 'WHS Appt Conf (MET)';

export const DISPATCH_STATUS_OPTIONS = [
  { value: DISPATCH_STATUS_DO_RECEIVED, label: 'DO Recevied' },
  { value: DISPATCH_STATUS_DO_SENT, label: 'DO Sent' },
  {
    value: DISPATCH_STATUS_DO_CONFIRMED,
    label: STATUS_ABBREVIATION_DO_CONFIRMED,
  },
  {
    value: DISPATCH_STATUS_PU_RECEIVED,
    label: STATUS_ABBREVIATION_PU_RECEIVED,
  },
  { value: DISPATCH_STATUS_PU_SENT, label: 'PU# Sent' },
  { value: DISPATCH_STATUS_ALL_RELEASED, label: 'All Release' },
  { value: DISPATCH_STATUS_TERMINAL_APT_MADE, label: 'Terminal Apt Made' },
  { value: DISPATCH_STATUS_WHS_APT_MADE, label: 'WHS Apt Made' },
  { value: DISPATCH_STATUS_DROPPED, label: 'Dropped' },
  { value: DISPATCH_STATUS_CNTR_EMPTY, label: 'CNTR Empty' },
  { value: DISPATCH_STATUS_DELIVERED, label: 'Delivered' },
  { value: DISPATCH_STATUS_EMPTY_RETURENED, label: 'Empty Returned' },
  { value: DISPATCH_STATUS_OP_CLOSED, label: 'OP Closed' },
  { value: DISPATCH_STATUS_CANCELED, label: 'Canceled' },
];

export const DISPATCH_AR_STATUS_NONE = 0;
export const DISPATCH_AR_STATUS_IN_DISPUTE = 3;
export const DISPATCH_AR_STATUS_PARTIAL_ISSUED = 5;
export const DISPATCH_AR_STATUS_ALL_ISSUED = 10;
export const DISPATCH_AR_STATUS_ALL_DEPOSITED = 20;

export const DISPATCH_AR_STATUS_OPTIONS = [
  { value: DISPATCH_AR_STATUS_NONE, label: 'None' },
  { value: DISPATCH_AR_STATUS_IN_DISPUTE, label: 'In Dispute' },
  { value: DISPATCH_AR_STATUS_PARTIAL_ISSUED, label: 'Partial Issued' },
  { value: DISPATCH_AR_STATUS_ALL_ISSUED, label: 'All Issued ' },
  { value: DISPATCH_AR_STATUS_ALL_DEPOSITED, label: 'All Deposited' },
];

export const DISPATCH_AP_STATUS_NONE = 0;
export const DISPATCH_AP_STATUS_IN_DISPUTE = 3;
export const DISPATCH_AP_STATUS_PARTIAL_ISSUED = 5;
export const DISPATCH_AP_STATUS_ALL_ISSUED = 10;
export const DISPATCH_AP_STATUS_ALL_CLEARED = 20;

export const DISPATCH_AP_STATUS_OPTIONS = [
  { value: DISPATCH_AP_STATUS_NONE, label: 'None' },
  { value: DISPATCH_AP_STATUS_IN_DISPUTE, label: 'In Dispute' },
  { value: DISPATCH_AP_STATUS_PARTIAL_ISSUED, label: 'Partial Issued' },
  { value: DISPATCH_AP_STATUS_ALL_ISSUED, label: 'All Issued' },
  { value: DISPATCH_AP_STATUS_ALL_CLEARED, label: 'All Cleared' },
];

export const TAB_MODE_DISPATCH = 0;
export const TAB_MODE_ACCOUNTING_CONTAINER = 1;
export const MODE_VENDOR_MODEL_TAB = 2; // is for vendor model tab
export const TAB_MODE_MY_FOCUS = 5; // is for my focus tab

export const OCEAN_PORT = 1;
export const RAMP_PORT = 2;
export const DESTINATION_TYPE_MAP = {
  [OCEAN_PORT]: 'Ocean Port',
  [RAMP_PORT]: 'Ramp Port',
};
export const DESTINATION_TYPE_OPTIONS = [
  { value: OCEAN_PORT, label: 'Ocean Port' },
  { value: RAMP_PORT, label: 'Ramp Port' },
];

export const APPPOINTMENT_BY_UNKNOW = 0;
export const APPPOINTMENT_BY_NO_APT_NEEDED = 1;
export const APPPOINTMENT_BY_CUSTOMER = 2;
export const APPPOINTMENT_BY_DRAYEASY = 3;

export const APPOINTMENT_BY_TYPE_MAP = {
  [APPPOINTMENT_BY_UNKNOW]: 'Unknown',
  [APPPOINTMENT_BY_NO_APT_NEEDED]: 'No apt needed',
  [APPPOINTMENT_BY_CUSTOMER]: 'Customer',
  [APPPOINTMENT_BY_DRAYEASY]: 'DrayEasy',
};

export const RESERVER_TIME_TYPE_TIME = 'time';
export const RESERVER_TIME_TYPE_RANGE = 'range';
export const RESERVER_TIME_TYPE_ANYTIME = 'anytime';
export const RESERVER_TIME_TYPE_MAP = {
  [RESERVER_TIME_TYPE_TIME]: 'Time',
  [RESERVER_TIME_TYPE_RANGE]: 'Range',
  [RESERVER_TIME_TYPE_ANYTIME]: 'Anytime',
};

export const LIVE_OR_DROP_UNKNOW = 0;
export const LIVE_OR_DROP_LIVE = 1;
export const LIVE_OR_DROP_DROP = 2;
export const LIVE_OR_DROP_MAP = {
  [LIVE_OR_DROP_UNKNOW]: 'None',
  [LIVE_OR_DROP_LIVE]: 'Live',
  [LIVE_OR_DROP_DROP]: 'Drop',
};
export const LIVE_OR_DROP_OPTIONS = [
  { value: LIVE_OR_DROP_UNKNOW, label: 'None' },
  { value: LIVE_OR_DROP_LIVE, label: 'Live' },
  { value: LIVE_OR_DROP_DROP, label: 'Drop' },
];

export const INTERNAL_TASKS_FILE_TASKS_CHECK_IF_TERMINAL_PICKUP_AND_RETURN_LOCATION_IS_THE_SAME =
  'check_if_terminal_pickup_and_return_location_is_the_same';
export const ACTION_NO = 'NO';
export const ACTION_YES = 'YES';
export const ACTIONS_TO_SHOW = [ACTION_YES, ACTION_NO];

export const PROFIT_AMOUNT_FILTER_NEGATIVE = 'Negative';
export const PROFIT_AMOUNT_FILTER_POSITIVE = 'Positive';
export const PROFIT_AMOUNT_FILTERS = [
  PROFIT_AMOUNT_FILTER_NEGATIVE,
  PROFIT_AMOUNT_FILTER_POSITIVE,
];

// const DISPATCH_STATUS_DO_RECEIVED = 0;
export const DISPATCH_STATUS_ORDER_CONFIRMED = 0;
export const DISPATCH_STATUS_PREDELIVERY_APPOINTMENT_SENT = 1;
export const DISPATCH_STATUS_PREDELIVERY_APPOINTMENT_CONFIRMED = 2;
export const DISPATCH_STATUS_EXAM_DO_RECEIVED = 31;
export const DISPATCH_STATUS_MET_EXAM_DO_SENT = 32;
export const DISPATCH_STATUS_MET_EXAM_DO_CONFIRMED = 33;
export const DISPATCH_STATUS_CET_EXAM_FINISHED = 34;
export const DISPATCH_STATUS_AVAILABLE = 35;
export const DISPATCH_STATUS_CET_EXAM_SITE_PICKUP_APT_CONFIRMED = 36;
export const DISPATCH_STATUS_MET_EXAM_SITE_DELIVERY_APT_CONFIRMED = 37;
export const DISPATCH_STATUS_WHS_APT_CONFIRMED = 14;
export const DISPATCH_STATUS_WHS_APT_CONFIRMED_MET = 61;
export const DISPATCH_STATUS_WHS_APT_MADE_BY_CUSTOMER = 55;
export const DISPATCH_STATUS_OUTGATED = 56;
export const DISPATCH_STATUS_PREPULL_TO_YARD = 38;
export const DISPATCH_STATUS_MET_EXAM_SITE_PICKUP_APT_CONFIRMED = 50;
export const DISPATCH_STATUS_CONTAINER_IS_DROPPED_TO_MET_EXAM_SITE = 39;
export const DISPATCH_STATUS_MET_EXAM_FINISHED = 40;
export const DISPATCH_STATUS_DELIVER_TO_DESTINATION = 41;
export const DISPATCH_STATUS_CONTAINER_DROPPED_TO_DESTINATION = 42;
// export const DISPATCH_STATUS_POD_SENT = 43;
export const DISPATCH_STATUS_CNTR_EMPTIED_INFORMED_FROM_WHS = 44;
// export const DISPATCH_STATUS_EMPTY_RETURNED_TO_YARD = 45;
export const DISPATCH_STATUS_EMPTY_RETURNED_TO_TERMINAL_DEPOT = 46;
// export const DISPATCH_ACTION_V2_MAP = {
//   [DISPATCH_STATUS_ORDER_CONFIRMED]: STATUS_ABBREVIATION_ORDER_CONF,
//   [DISPATCH_STATUS_PREDELIVERY_APPOINTMENT_SENT]: STATUS_ABBREVIATION_PREDELIVERY_APPOINTMENT_SENT,
//   [DISPATCH_STATUS_PREDELIVERY_APPOINTMENT_CONFIRMED]:STATUS_ABBREVIATION_PREDELIVERY_APPOINTMENT_CONFIRMED,
//   [DISPATCH_STATUS_DO_SENT]: 'DO SENT',
//   [DISPATCH_STATUS_DO_CONFIRMED]: STATUS_ABBREVIATION_DO_CONFIRMED,
//   [DISPATCH_STATUS_PU_RECEIVED]: STATUS_ABBREVIATION_PU_RECEIVED,
//   [DISPATCH_STATUS_PU_SENT]: 'PU# SENT',
//   [DISPATCH_STATUS_EXAM_DO_RECEIVED]: STATUS_ABBREVIATION_EXAM_DO_RECEIVED,
//   [DISPATCH_STATUS_MET_EXAM_DO_SENT]: 'MET Exam DO sent',
//   [DISPATCH_STATUS_MET_EXAM_DO_CONFIRMED]: 'MET Exam DO confirmed',
//   [DISPATCH_STATUS_CET_EXAM_FINISHED]: 'CET Exam finished',
//   [DISPATCH_STATUS_AVAILABLE]: 'Available',
//   [DISPATCH_STATUS_TERMINAL_APT_MADE]: 'TERMINAL APT MADE',
//   [DISPATCH_STATUS_CET_EXAM_SITE_PICKUP_APT_CONFIRMED]:
//     'CET Exam site pickup Apt confirmed',
//   [DISPATCH_STATUS_MET_EXAM_SITE_DELIVERY_APT_CONFIRMED]:
//     'MET Exam site Delivery Apt confirmed',
//   [DISPATCH_STATUS_WHS_APT_CONFIRMED]: 'WHS Apt Confirmed',
//   [DISPATCH_STATUS_WHS_APT_CONFIRMED_MET]: 'WHS Apt Confirmed (MET)',
//   [DISPATCH_STATUS_WHS_APT_MADE_BY_CUSTOMER]: 'WHS Apt Made by Customer',
//   [DISPATCH_STATUS_OUTGATED]: 'Outgated',
//   [DISPATCH_STATUS_PREPULL_TO_YARD]: 'Prepull to yard',
//   [DISPATCH_STATUS_CONTAINER_IS_DROPPED_TO_MET_EXAM_SITE]:
//     'Container is dropped to MET exam site',
//   [DISPATCH_STATUS_MET_EXAM_FINISHED]: 'MET Exam Finished',
//   [DISPATCH_STATUS_MET_EXAM_SITE_PICKUP_APT_CONFIRMED]:
//     'MET Exam site pickup Apt confirmed',

//   [DISPATCH_STATUS_DELIVER_TO_DESTINATION]: 'Deliver to Destination',
//   [DISPATCH_STATUS_CONTAINER_DROPPED_TO_DESTINATION]:
//     'Container Dropped to Destination',
//   // [DISPATCH_STATUS_POD_SENT]: 'POD Sent',
//   [DISPATCH_STATUS_CNTR_EMPTIED_INFORMED_FROM_WHS]:
//     'CNTR Emptied informed from WHS',
//   // [DISPATCH_STATUS_EMPTY_RETURNED_TO_YARD]: 'Empty returned to yard',
//   [DISPATCH_STATUS_EMPTY_RETURNED_TO_TERMINAL_DEPOT]:
//     'Empty Returned to terminal/depot',

//   [DISPATCH_STATUS_OP_CLOSED]: 'OP CLOSED',
//   [DISPATCH_STATUS_CANCELED]: 'CANCELED',
// };
export const DISPATCH_ACTION_V2_OPTIONS = [
  {
    value: DISPATCH_STATUS_ORDER_CONFIRMED,
    label: STATUS_ABBREVIATION_ORDER_CONF,
  },
  {
    value: DISPATCH_STATUS_PREDELIVERY_APPOINTMENT_SENT,
    label: STATUS_ABBREVIATION_PREDELIVERY_APPOINTMENT_SENT,
  },
  {
    value: DISPATCH_STATUS_PREDELIVERY_APPOINTMENT_CONFIRMED,
    label: STATUS_ABBREVIATION_PREDELIVERY_APPOINTMENT_CONFIRMED,
  },
  { value: DISPATCH_STATUS_DO_SENT, label: 'DO Sent' },
  {
    value: DISPATCH_STATUS_DO_CONFIRMED,
    label: STATUS_ABBREVIATION_DO_CONFIRMED,
  },
  {
    value: DISPATCH_STATUS_PU_RECEIVED,
    label: STATUS_ABBREVIATION_PU_RECEIVED,
  },
  { value: DISPATCH_STATUS_PU_SENT, label: 'PU# Sent' },
  {
    value: DISPATCH_STATUS_EXAM_DO_RECEIVED,
    label: STATUS_ABBREVIATION_EXAM_DO_RECEIVED,
  },
  { value: DISPATCH_STATUS_MET_EXAM_DO_SENT, label: 'MET Exam DO sent' },
  {
    value: DISPATCH_STATUS_MET_EXAM_DO_CONFIRMED,
    label: STATUS_ABBREVIATION_MET_EXAM_DO_CONFIRMED,
  },
  { value: DISPATCH_STATUS_CET_EXAM_FINISHED, label: 'CET Exam finished' },
  { value: DISPATCH_STATUS_AVAILABLE, label: 'Available' },
  { value: DISPATCH_STATUS_TERMINAL_APT_MADE, label: 'TERMINAL APT MADE' },
  {
    value: DISPATCH_STATUS_CET_EXAM_SITE_PICKUP_APT_CONFIRMED,
    label: STATUS_ABBREVIATION_CET_EXAM_SITE_PICKUP_APT_CONFIRMED,
  },
  {
    value: DISPATCH_STATUS_MET_EXAM_SITE_DELIVERY_APT_CONFIRMED,
    label: STATUS_ABBREVIATION_MET_EXAM_SITE_DELIVERY_APT_CONFIRMED,
  },
  {
    value: DISPATCH_STATUS_WHS_APT_CONFIRMED,
    label: STATUS_ABBREVIATION_WHS_APT_CONFIRMED,
  },
  {
    value: DISPATCH_STATUS_WHS_APT_CONFIRMED_MET,
    label: STATUS_ABBREVIATION_WHS_APT_CONFIRME_MET,
  },
  {
    value: DISPATCH_STATUS_WHS_APT_MADE_BY_CUSTOMER,
    label: STATUS_ABBREVIATION_WHS_APT_MADE_BY_CUSTOMER,
  },
  { value: DISPATCH_STATUS_OUTGATED, label: 'Outgated' },
  { value: DISPATCH_STATUS_PREPULL_TO_YARD, label: 'Prepull to yard' },
  {
    value: DISPATCH_STATUS_CONTAINER_IS_DROPPED_TO_MET_EXAM_SITE,
    label: 'Container is dropped to MET exam site',
  },
  { value: DISPATCH_STATUS_MET_EXAM_FINISHED, label: 'MET Exam Finished' },
  {
    value: DISPATCH_STATUS_MET_EXAM_SITE_PICKUP_APT_CONFIRMED,
    label: STATUS_ABBREVIATION_MET_EXAM_SITE_PICKUP_APT_CONFIRMED,
  },
  {
    value: DISPATCH_STATUS_DELIVER_TO_DESTINATION,
    label: 'Deliver to Destination',
  },
  {
    value: DISPATCH_STATUS_CONTAINER_DROPPED_TO_DESTINATION,
    label: 'Container Dropped to Destination',
  },
  // { value: DISPATCH_STATUS_POD_SENT, label: 'POD Sent' },
  {
    value: DISPATCH_STATUS_CNTR_EMPTIED_INFORMED_FROM_WHS,
    label: 'CNTR Emptied informed from WHS',
  },
  // {
  //   value: DISPATCH_STATUS_EMPTY_RETURNED_TO_YARD,
  //   label: 'Empty returned to yard',
  // },
  {
    value: DISPATCH_STATUS_EMPTY_RETURNED_TO_TERMINAL_DEPOT,
    label: 'Empty Returned to terminal/depot',
  },
  { value: DISPATCH_STATUS_OP_CLOSED, label: 'OP CLOSED' },
  { value: DISPATCH_STATUS_CANCELED, label: 'CANCELED' },
];

export const CUSTOMER_STATUS_ORDER_CONFIRMED = 0;
export const CUSTOMER_STATUS_PREDELIVERY_APPOINTMENT_SENT = 1;
export const CUSTOMER_STATUS_PREDELIVERY_APPOINTMENT_CONFIRMED = 2;
export const CUSTOMER_STATUS_EXAM_DO_RECEIVED = 8;
// export const CUSTOMER_STATUS_WHS_APT_CONFIRMED = 14;
export const CUSTOMER_STATUS_WHS_APT_CONFIRMED_MET = 61;
export const CUSTOMER_STATUS_AVAILABLE = 34;
export const CUSTOMER_STATUS_CET_EXAM_SITE_PICKUP_APT_CONFIRMED = 36;
export const CUSTOMER_STATUS_DELIVERY_PLAN_SET = 30;
export const CUSTOMER_STATUS_WHS_APT_MADE_BY_CUSTOMER = 55;
export const CUSTOMER_STATUS_OUTGATED = 56;
export const CUSTOMER_STATUS_PREPULL_TO_YARD = 38;
export const CUSTOMER_STATUS_MET_EXAM_SITE_PICKUP_APT_CONFIRMED = 50;
export const CUSTOMER_STATUS_DELIVER_TO_DESTINATION = 41;
export const CUSTOMER_STATUS_CONTAINER_DROPPED_TO_DESTINATION = 42;
// export const CUSTOMER_STATUS_POD_SENT = 43;
export const CUSTOMER_STATUS_CNTR_EMPTIED_INFORMED_FROM_WHS = 44;
// export const CUSTOMER_STATUS_EMPTY_RETURNED_TO_YARD = 45;
export const CUSTOMER_STATUS_EMPTY_RETURNED_TO_TERMINAL_DEPOT = 46;
export const CUSTOMER_STATUS_COMPLETED = 25;

const CUSTOMER_STATUS_CANCELED = -5;

export const CUSTOMER_STATUS_MAP = {
  [CUSTOMER_STATUS_ORDER_CONFIRMED]: 'Order Confirmed',
  [CUSTOMER_STATUS_PREDELIVERY_APPOINTMENT_SENT]:
    'Predelivery Appointment sent',
  [CUSTOMER_STATUS_PREDELIVERY_APPOINTMENT_CONFIRMED]:
    'Predelivery Appointment confirmed',
  [CUSTOMER_STATUS_EXAM_DO_RECEIVED]: 'Exam DO received',
  [CUSTOMER_STATUS_AVAILABLE]: 'Available',
  [CUSTOMER_STATUS_CET_EXAM_SITE_PICKUP_APT_CONFIRMED]:
    'CET Exam site pickup Apt confirmed',
  [CUSTOMER_STATUS_DELIVERY_PLAN_SET]: 'Delivery plan Set',
  [CUSTOMER_STATUS_WHS_APT_MADE_BY_CUSTOMER]: 'WHS Apt Made by Customer',
  [CUSTOMER_STATUS_OUTGATED]: 'Outgated',
  [CUSTOMER_STATUS_PREPULL_TO_YARD]: 'Prepull to yard',
  [CUSTOMER_STATUS_MET_EXAM_SITE_PICKUP_APT_CONFIRMED]:
    'MET Exam site pickup Apt confirmed',
  // [CUSTOMER_STATUS_WHS_APT_CONFIRMED]: 'WHS Apt Confirmed',
  [CUSTOMER_STATUS_WHS_APT_CONFIRMED_MET]: 'WHS Apt Confirmed(MET)',
  [CUSTOMER_STATUS_DELIVER_TO_DESTINATION]: 'Deliver to Destination',
  [CUSTOMER_STATUS_CONTAINER_DROPPED_TO_DESTINATION]:
    'Container Dropped to Destination',
  // [CUSTOMER_STATUS_POD_SENT]: 'POD Sent',
  [CUSTOMER_STATUS_CNTR_EMPTIED_INFORMED_FROM_WHS]:
    'CNTR Emptied informed from WHS',
  // [CUSTOMER_STATUS_EMPTY_RETURNED_TO_YARD]: 'Empty returned to yard',
  [CUSTOMER_STATUS_EMPTY_RETURNED_TO_TERMINAL_DEPOT]:
    'Empty Returned to terminal/depot',
  [CUSTOMER_STATUS_COMPLETED]: 'Completed',
  [CUSTOMER_STATUS_CANCELED]: 'Canceled',
};

export const CUSTOMER_STATUS_OPTIONS = [
  { value: CUSTOMER_STATUS_ORDER_CONFIRMED, label: 'Order Confirmed' },
  {
    value: CUSTOMER_STATUS_PREDELIVERY_APPOINTMENT_SENT,
    label: 'Predelivery Appointment sent',
  },
  {
    value: CUSTOMER_STATUS_PREDELIVERY_APPOINTMENT_CONFIRMED,
    label: 'Predelivery Appointment confirmed',
  },
  { value: CUSTOMER_STATUS_EXAM_DO_RECEIVED, label: 'Exam DO received' },
  { value: CUSTOMER_STATUS_AVAILABLE, label: 'Available' },
  {
    value: CUSTOMER_STATUS_CET_EXAM_SITE_PICKUP_APT_CONFIRMED,
    label: 'CET Exam site pickup Apt confirmed',
  },
  { value: CUSTOMER_STATUS_DELIVERY_PLAN_SET, label: 'Delivery plan Set' },
  {
    value: CUSTOMER_STATUS_WHS_APT_MADE_BY_CUSTOMER,
    label: 'WHS Apt Made by Customer',
  },
  { value: CUSTOMER_STATUS_OUTGATED, label: 'Outgated' },
  { value: CUSTOMER_STATUS_PREPULL_TO_YARD, label: 'Prepull to yard' },
  {
    value: CUSTOMER_STATUS_MET_EXAM_SITE_PICKUP_APT_CONFIRMED,
    label: 'MET Exam site pickup Apt confirmed',
  },
  // { value: CUSTOMER_STATUS_WHS_APT_CONFIRMED, label: 'WHS Apt Confirmed' },
  {
    value: CUSTOMER_STATUS_WHS_APT_CONFIRMED_MET,
    label: 'WHS Apt Confirmed(MET)',
  },
  {
    value: CUSTOMER_STATUS_DELIVER_TO_DESTINATION,
    label: 'Deliver to Destination',
  },
  {
    value: CUSTOMER_STATUS_CONTAINER_DROPPED_TO_DESTINATION,
    label: 'Container Dropped to Destination',
  },
  // { value: CUSTOMER_STATUS_POD_SENT, label: 'POD Sent' },
  {
    value: CUSTOMER_STATUS_CNTR_EMPTIED_INFORMED_FROM_WHS,
    label: 'CNTR Emptied informed from WHS',
  },
  // {
  //   value: CUSTOMER_STATUS_EMPTY_RETURNED_TO_YARD,
  //   label: 'Empty returned to yard',
  // },
  {
    value: CUSTOMER_STATUS_EMPTY_RETURNED_TO_TERMINAL_DEPOT,
    label: 'Empty Returned to terminal/depot',
  },
  {
    value: CUSTOMER_STATUS_COMPLETED,
    label: 'Completed',
  },
  {
    value: CUSTOMER_STATUS_CANCELED,
    label: 'Canceled',
  },
];

export const WHOSE_TASK_OP_ASSIST = 1;
export const WHOSE_TASK_OP = 2;
export const WHOSE_TASK_CPS = 3;
export const WHOSE_TASK_MAP = {
  [WHOSE_TASK_OP_ASSIST]: 'OA',
  [WHOSE_TASK_OP]: 'OP',
  [WHOSE_TASK_CPS]: 'CPS',
};
