import styles from 'res/css/ui.scss';
import {
  Button,
  Col,
  Form,
  Row,
  Select,
  FormInstance,
  Space,
  Input,
  InputNumber,
} from 'antd';
import { IntermodalRegionSelect } from '@/components/IntermodalRegionSelect';
import { CitySelect } from '@/components/CitySelect';
import React from 'react';
import { FacilityTypeSelect } from '@/components/FacilityTypeSelect';
import { CargoTypeSelect } from '@/components/CargoTypeSelect';

export type TFilter = {
  loading: boolean;
  filter: FormInstance;
  onSearch: () => void;
  onChange?: (values: any) => void;
};

const LATEST_RATE_DATES = [
  { label: 'Recent 1 Month', value: 1 },
  { label: 'Recent 3 Months', value: 3 },
  { label: 'Recent 6 Months', value: 6 },
  { label: 'Recent 9 Months', value: 9 },
  { label: 'Recent 12 Months', value: 12 },
];

export const Filter: React.FC<TFilter> = ({
  loading,
  filter,
  onSearch,
  onChange,
}) => {
  const handleSearch = () => {
    onSearch();
  };

  const handleClearAll = () => {
    filter.resetFields();
  };

  const handleChange = async (event: any) => {
    const { name, value } = event.target as HTMLInputElement;
    await filter.setFieldsValue({ [name]: value });
  };

  return (
    <div className={styles.filter}>
      <Form
        layout="vertical"
        form={filter}
        onFinish={onSearch}
        initialValues={{
          recent_month: 6,
          no_drayeasy_vendor: true,
          distance_start: 0,
          distance_end: 500,
          min_driver_count: 10,
          min_rate_count: 10,
          cargo_type_id: 1,
          mode: 'by_distance',
        }}
        style={{ width: '100%' }}>
        <Row gutter={8}>
          <Col span={3}>
            <Form.Item
              name="intermodal_region_id"
              label="IR"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: 'IR is required.' }]}>
              <IntermodalRegionSelect />
            </Form.Item>
          </Col>

          <Col span={3}>
            <Form.Item label="Date" name="recent_month">
              <Select options={LATEST_RATE_DATES} />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item label="Cargo Type" name="cargo_type_id">
              <CargoTypeSelect />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <Form.Item name="from_facility_type" label="From Facility Type">
                  <FacilityTypeSelect getValueForOption="name" />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <Form.Item name="to_facility_type" label="To Facility Type">
                  <FacilityTypeSelect getValueForOption="name" />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="min_driver_count" label="Min Driver Count">
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="min_rate_count" label="Min Rate Count">
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="distance_start" label="Distance Start">
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="distance_end" label="Distance End">
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="no_drayeasy_vendor" label="No DE Vendor">
              <Select
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="mode" label="Mode">
              <Select
                options={[
                  { label: 'By Distance', value: 'by_distance' },
                  { label: 'By Driving Time', value: 'by_driving_time' },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={2}>
            <Form.Item label="">
              <Space style={{ paddingTop: '30px' }}>
                <Button htmlType="submit" type="primary" loading={loading}>
                  Search
                </Button>
                <Button onClick={handleClearAll}>Clear All</Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
