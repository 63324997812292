import ClickWrapper from '@/components/ClickWrapper';
import React from 'react';
import { InvoiceDrawer } from './InvoiceDrawer';

interface Props {
  id: number;
  containerNumber?: string;
  invoiceableId: number;
  invoiceableType: string;
  children: React.ReactNode;
  onSaved?: () => void;
  onDeleted?: () => void;
}

const EditInvoice: React.FC<Props> = ({
  id,
  containerNumber,
  invoiceableId,
  invoiceableType,
  children,
  onSaved,
  onDeleted,
}) => {
  const [visible, setVisible] = React.useState<boolean>(false);

  const handleDeleted = () => {
    setVisible(false);
    onDeleted && onDeleted();
  };

  return (
    <>
      <ClickWrapper onClick={() => setVisible(!visible)}>
        {children}
      </ClickWrapper>
      {visible && (
        <InvoiceDrawer
          id={id}
          invoiceableId={invoiceableId}
          invoiceableType={invoiceableType}
          containerNumber={containerNumber}
          visible={visible}
          onClose={() => setVisible(false)}
          onSaved={onSaved}
          onDeleted={handleDeleted}
        />
      )}
    </>
  );
};

export default EditInvoice;
