import { TBill } from '@/types';
import {
  BILL_TYPE_ORDER,
  BILL_TYPE_LTL,
  BILL_TYPE_FTL,
} from '@/pages/accounting/bills/components/data';
import { ORDER_STATE_CANCELED } from '@/pages/orders/components/data';
import { CancelBusinessStatuses as LtlCancelBusinessStatuses } from '@/pages/truckload/ltl/constants';
import { ShipmentBusinessStatus as FtlShipmentBusinessStatus } from '@/pages/truckload/ftl/constants';

export default (bills: TBill[]) => {
  if (bills.length === 0) {
    return [];
  }

  return bills.filter((b) => {
    if (b?.billable_type === BILL_TYPE_ORDER) {
      return b?.billable?.state === ORDER_STATE_CANCELED;
    } else if (b?.billable_type === BILL_TYPE_LTL) {
      return LtlCancelBusinessStatuses.includes(b?.billable?.business_status);
    } else if (b?.billable_type === BILL_TYPE_FTL) {
      return b.billable?.business_status === FtlShipmentBusinessStatus.CANCELED;
    }

    return false;
  });
};
