import React, { useEffect, useState } from 'react';
import DrawerForm from './components/DrawerForm';

export const useContractRateDrawer = () => {
  const [data, setData] = useState<any>(undefined);
  const [visible, setVisible] = useState(false);
  const [isChangeHistory, setIsChangeHistory] = useState(false);

  const closeDrawer = (
    e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
  ) => {
    e.stopPropagation();
    setVisible(false);
    isChangeHistory && window.history.back();
    !isChangeHistory && window.history.pushState(null, '', '/entity/vendors');
    setIsChangeHistory(false);
  };

  const ContractRateFormDrawer = () =>
    data ? (
      <DrawerForm
        vendor_id={data.vendor_id}
        contractRate={data}
        intermodal_region_id={data.intermodal_region_id}
        visible={visible}
        onClose={closeDrawer}
        onSave={(d) => {
          setData(d);
          setVisible(false);
        }}
      />
    ) : null;

  const openContractRateFormDrawer = (
    contractRate: any,
    enableChangeHistory?: boolean,
  ) => {
    if (enableChangeHistory) {
      // window.history.pushState(null, '', `./vendors/${id}/`);
      setIsChangeHistory(true);
    }
    setData(contractRate);
    setVisible(true);
  };

  return {
    ContractRateFormDrawer,
    openContractRateFormDrawer,
    contractRate: data,
  };
};
