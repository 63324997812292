export const DOCUMENT_FILE_TYPE_DO = 'DO';
export const DOCUMENT_FILE_TYPE_DG_FORM = 'DG form';
export const DOCUMENT_FILE_TYPE_POD = 'POD';
export const DOCUMENT_FILE_TYPE_PACKING_LIST = 'Packing List';
export const DOCUMENT_FILE_TYPE_MBL = 'MBL';
export const DOCUMENT_FILE_TYPE_TELEX = 'Telex';
export const DOCUMENT_FILE_TYPE_PU = 'PU#';
export const DOCUMENT_FILE_TYPE_EXAM_DO = 'Exam DO';
export const DOCUMENT_FILE_TYPE_MSDS = 'MSDS';
export const DOCUMENT_FILE_TYPE_PAYMENT_PROOF = 'Payment Proof';
export const DOCUMENT_FILE_TYPE_SCALE_TICKET = 'Scale Ticket';
export const DOCUMENT_FILE_TYPE_SOC_RETURN_GUARANTEE_LETTER =
  'SOC Return Guarantee Letter';
export const DOCUMENT_FILE_TYPE_TERMINAL_DETENTION_PROOF =
  'Terminal Detention Proof';
export const DOCUMENT_FILE_TYPE_PROOF_OF_TERMINAL_APT_TIME =
  'Proof of Terminal apt time';
export const DOCUMENT_FILE_TYPE_PROOF_OF_TERMINAL_PICKUP_APT_TIME =
  'Proof of Terminal pickup apt time';
export const DOCUMENT_FILE_TYPE_PROOF_OF_TERMINAL_RETURN_APT_TIME =
  'Proof of Terminal return apt time';
export const DOCUMENT_FILE_TYPE_PROOF_OF_EMPTY_RETURNED =
  'Proof of Empty Returned';
export const DOCUMENT_FILE_TYPE_INGATE_EIR = 'Ingate EIR';
export const DOCUMENT_FILE_TYPE_OUTGATE_EIR = 'Outgate EIR';
// export const DOCUMENT_FILE_TYPE_EQUIPMENT_INTERCHANGE_RECEIPT =
//   'EIR - Equipment Interchange Receipt';
export const DOCUMENT_FILE_TYPE_VENDOR_INVOICE = 'Vendor Invoice';
export const DOCUMENT_FILE_TYPE_ARRIVAL_NOTICE = 'Arrival Notice';
export const DOCUMENT_FILE_TYPE_OTHER = 'Other';

export const DOCUMENT_FILE_TYPES = [
  DOCUMENT_FILE_TYPE_DO,
  DOCUMENT_FILE_TYPE_DG_FORM,
  DOCUMENT_FILE_TYPE_POD,
  DOCUMENT_FILE_TYPE_PACKING_LIST,
  DOCUMENT_FILE_TYPE_MBL,
  DOCUMENT_FILE_TYPE_TELEX,
  DOCUMENT_FILE_TYPE_PU,
  DOCUMENT_FILE_TYPE_EXAM_DO,
  DOCUMENT_FILE_TYPE_MSDS,
  DOCUMENT_FILE_TYPE_PAYMENT_PROOF,
  DOCUMENT_FILE_TYPE_SCALE_TICKET,
  DOCUMENT_FILE_TYPE_SOC_RETURN_GUARANTEE_LETTER,
  // DOCUMENT_FILE_TYPE_EQUIPMENT_INTERCHANGE_RECEIPT,
  DOCUMENT_FILE_TYPE_INGATE_EIR,
  DOCUMENT_FILE_TYPE_OUTGATE_EIR,
  DOCUMENT_FILE_TYPE_TERMINAL_DETENTION_PROOF,
  DOCUMENT_FILE_TYPE_PROOF_OF_TERMINAL_APT_TIME,
  DOCUMENT_FILE_TYPE_PROOF_OF_TERMINAL_PICKUP_APT_TIME,
  DOCUMENT_FILE_TYPE_PROOF_OF_TERMINAL_RETURN_APT_TIME,
  DOCUMENT_FILE_TYPE_PROOF_OF_EMPTY_RETURNED,
  DOCUMENT_FILE_TYPE_VENDOR_INVOICE,
  DOCUMENT_FILE_TYPE_ARRIVAL_NOTICE,
  DOCUMENT_FILE_TYPE_OTHER,
];

export const CUSTOMER_VISIBLE = true;
export const CUSTOMER_INVISIBLE = false;

export const DOCUMENT_FILE_TYPES_DEFAULT_CUSTOMER_VISIBLE_MAP = {
  [DOCUMENT_FILE_TYPE_DO]: CUSTOMER_VISIBLE,
  [DOCUMENT_FILE_TYPE_DG_FORM]: CUSTOMER_VISIBLE,
  [DOCUMENT_FILE_TYPE_POD]: CUSTOMER_VISIBLE,
  [DOCUMENT_FILE_TYPE_PACKING_LIST]: CUSTOMER_VISIBLE,
  [DOCUMENT_FILE_TYPE_MBL]: CUSTOMER_VISIBLE,
  [DOCUMENT_FILE_TYPE_TELEX]: CUSTOMER_VISIBLE,
  [DOCUMENT_FILE_TYPE_PU]: CUSTOMER_VISIBLE,
  [DOCUMENT_FILE_TYPE_EXAM_DO]: CUSTOMER_VISIBLE,
  [DOCUMENT_FILE_TYPE_MSDS]: CUSTOMER_VISIBLE,
  [DOCUMENT_FILE_TYPE_PAYMENT_PROOF]: CUSTOMER_VISIBLE,
  [DOCUMENT_FILE_TYPE_SCALE_TICKET]: CUSTOMER_VISIBLE,
  [DOCUMENT_FILE_TYPE_SOC_RETURN_GUARANTEE_LETTER]: CUSTOMER_VISIBLE,
  // [DOCUMENT_FILE_TYPE_EQUIPMENT_INTERCHANGE_RECEIPT]: CUSTOMER_VISIBLE,
  [DOCUMENT_FILE_TYPE_INGATE_EIR]: CUSTOMER_VISIBLE,
  [DOCUMENT_FILE_TYPE_OUTGATE_EIR]: CUSTOMER_VISIBLE,
  [DOCUMENT_FILE_TYPE_TERMINAL_DETENTION_PROOF]: CUSTOMER_INVISIBLE,
  [DOCUMENT_FILE_TYPE_PROOF_OF_TERMINAL_APT_TIME]: CUSTOMER_VISIBLE,
  [DOCUMENT_FILE_TYPE_PROOF_OF_TERMINAL_PICKUP_APT_TIME]: CUSTOMER_VISIBLE,
  [DOCUMENT_FILE_TYPE_PROOF_OF_TERMINAL_RETURN_APT_TIME]: CUSTOMER_VISIBLE,
  [DOCUMENT_FILE_TYPE_PROOF_OF_EMPTY_RETURNED]: CUSTOMER_VISIBLE,
  [DOCUMENT_FILE_TYPE_VENDOR_INVOICE]: CUSTOMER_INVISIBLE,
  [DOCUMENT_FILE_TYPE_ARRIVAL_NOTICE]: CUSTOMER_VISIBLE,
  [DOCUMENT_FILE_TYPE_OTHER]: CUSTOMER_INVISIBLE,
};
