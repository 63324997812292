import { Button, Modal } from 'antd';
import React, { useCallback, useState } from 'react';
import Map, { ControlPosition, Layer, MapRef, Source } from 'react-map-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { useControl } from 'react-map-gl';
import * as turf from '@turf/turf';
import GeocoderControl from './Geocoder';

import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  position?: ControlPosition;

  onCreate?: (evt: { features: object[] }) => void;
  onUpdate?: (evt: { features: object[] }) => void;
  onDelete?: (evt: { features: object[] }) => void;
};

const ZIPS_TILES = {
  'ac-zips': {
    'source-layer': 'ac-b0n2k5',
    url: 'darsh99137.67h8ttal',
  },
  'nopr-zips': {
    'source-layer': 'nopr-aab0hu',
    url: 'darsh99137.4a95ht04',
  },
  'stuv-zips': {
    'source-layer': 'stuv-bdvekc',
    url: 'darsh99137.4qk2dlxt',
  },
  'klm-zips': {
    'source-layer': 'klm-1cyylf',
    url: 'darsh99137.c10a4dg9',
  },
  'dfghi-zips': {
    'source-layer': 'dfghi-9jmprs',
    url: 'darsh99137.45692mm0',
  },
  'w-zips': {
    'source-layer': 'w-6rljid',
    url: 'darsh99137.4c878s8r',
  },
};

export const DrawControl = (props: DrawControlProps) => {
  useControl<MapboxDraw>(
    () => new MapboxDraw(props),
    ({ map }: { map: MapRef }) => {
      map.on('draw.create', props.onCreate);
      map.on('draw.update', props.onUpdate);
      // map.on('draw.delete', props.onDelete);
    },
    ({ map }: { map: MapRef }) => {
      map.off('draw.create', props.onCreate);
      map.off('draw.update', props.onUpdate);
      // map.off('draw.delete', props.onDelete);
    },
    {
      position: props.position,
    },
  );
  return null;
};

export const MapboxModal: React.FC<{ onSave?: (zips: string) => void }> = ({
  onSave,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [zipcodes, setZipcodes] = useState('');
  const mapRef = React.useRef<MapRef>();

  const showModal = () => {
    setZipcodes('');
    setIsModalOpen(true);
  };

  const handleOk = () => {
    onSave && onSave(zipcodes);
    setIsModalOpen(false);
    setZipcodes('');
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setZipcodes('');
  };

  const onUpdate = useCallback((e) => {
    if (!mapRef || !mapRef.current) {
      return;
    }
    const userPolygon = e.features[0];

    const polygonBoundingBox = turf.bbox(userPolygon);

    const southWest: any = [polygonBoundingBox[0], polygonBoundingBox[1]];
    const northEast: any = [polygonBoundingBox[2], polygonBoundingBox[3]];

    const northEastPointPixel = mapRef.current.project(northEast);
    const southWestPointPixel = mapRef.current.project(southWest);

    const bbox: any = [southWestPointPixel, northEastPointPixel];
    const features = mapRef.current.queryRenderedFeatures(bbox, {
      layers: ['Zip'],
    });
    setZipcodes(features.map((f: any) => f.properties.ZIP5).join(','));
  }, []);

  const zipLayer = {
    id: 'Zip',
    type: 'fill',
    source: 'zips',
    layout: {
      // 'visibility': 'none'
    },
    paint: {
      'fill-outline-color': '#696969',
      'fill-color': {
        property: 'fill',
        // type: 'identity',
        type: 'categorical', // this is essential to match 'fill'
        stops: [
          ['#fbb4ae', '#fbb4ae'],
          ['#b3cde3', '#b3cde3'],
          ['#ccebc5', '#ccebc5'],
          ['#decbe4', '#decbe4'],
          ['#fed9a6', '#fed9a6'],
        ], //'#fbb4ae','#b3cde3','#ccebc5','#decbe4','#fed9a6'                                       //red ,   blue,      green,   purple,   orange
      },
      'fill-opacity': 0.5,
    },
    'source-layer': 'zip5_topo_color-2bf335',
  };

  const toLayerConfig = (key: string) => {
    return {
      id: key + '-label',
      type: 'symbol',
      source: key,
      minzoom: 5,
      layout: {
        'text-field': '{ZCTA5CE10}',
      },
      'source-layer':
        ZIPS_TILES[key as unknown as keyof typeof ZIPS_TILES]['source-layer'],
    };
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Find Zip Codes
      </Button>

      <Modal
        width="80vw"
        destroyOnClose
        centered
        title="Map"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}>
        <div
          style={{
            width: '100%',
            height: '70vh',
          }}>
          <Map
            ref={mapRef}
            initialViewState={{
              longitude: -95.03734351262877,
              latitude: 29.53745189,
              zoom: 5,
            }}
            mapStyle="mapbox://styles/mapbox/streets-v9"
            mapboxAccessToken={MAPBOX_API_KEY}>
            <DrawControl
              position="top-right"
              displayControlsDefault={false}
              controls={{
                polygon: true,
                trash: true,
              }}
              defaultMode="draw_polygon"
              onCreate={onUpdate}
              onUpdate={onUpdate}
            />
            <GeocoderControl
              mapboxAccessToken={MAPBOX_API_KEY}
              position="top-left"
            />
            <Source id="Zip" type="vector" url="mapbox://jn1532.2z2q31r2">
              <Layer {...zipLayer} />
            </Source>
            {Object.keys(ZIPS_TILES).map((key: string) => (
              <Source
                id={key}
                key={'source-' + key}
                type="vector"
                url={`mapbox://${ZIPS_TILES[key]['url']}`}>
                <Layer {...toLayerConfig(key)} />
              </Source>
            ))}
          </Map>
        </div>
        <div>Zipcodes: {zipcodes}</div>
      </Modal>
    </>
  );
};
