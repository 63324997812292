import React, { useState, useEffect } from 'react';
import mapboxgl, { LngLatBoundsLike } from 'mapbox-gl';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

mapboxgl.accessToken = MAPBOX_API_KEY;

const Map: React.FC<{
  source: any[];
  maxBounds?: LngLatBoundsLike | undefined;
}> = ({
  source,
  maxBounds = [
    [-172.298804, 4.84616], // Southwest coordinates
    [-48.90097, 70.52138], // Northeast coordinates
  ],
}) => {
  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const [markers, setMarkers] = useState<any[]>([]);

  useEffect(() => {
    const initializeMap = () => {
      const map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/streets-v11',
        center: {
          lat: 34.0148,
          lng: -118.191,
        }, // Set the initial map center
        zoom: 1, // Set the initial map zoom level,
        maxBounds,
      });

      map.addControl(new mapboxgl.FullscreenControl());

      setMap(map);
    };

    if (!map) {
      initializeMap();
    }

    return () => {
      if (map) {
        map.remove();
      }
    };
  }, [map]);

  const createElementFromHTML = (htmlString: string) => {
    const div = document.createElement('div');
    div.innerHTML = htmlString.trim();

    return div;
  };
  const toMaker = (text: string) => {
    const html = `<div style="max-width: 64px; display: flex; justify-content: center; align-items: center; padding: 3px 5px; background: rgba(12, 37, 86, 0.9); box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25); backdrop-filter: blur(1px); border-radius: 5px;">
    <div style="background: #FFFFFF;border-radius: 50%; display: flex; justify-content: center; align-items: center; padding: 5px;">
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.16602 8.125H9.67622C9.77915 8.12496 9.87993 8.0956 9.96677 8.04035C10.0536 7.98511 10.1229 7.90626 10.1666 7.81305C10.2102 7.71984 10.2264 7.61612 10.2132 7.51405C10.2001 7.41197 10.1581 7.31575 10.0922 7.23667L8.12435 4.875L10.0922 2.51333C10.1581 2.43425 10.2001 2.33803 10.2132 2.23595C10.2264 2.13388 10.2102 2.03016 10.1666 1.93695C10.1229 1.84374 10.0536 1.76489 9.96677 1.70965C9.87993 1.6544 9.77915 1.62504 9.67622 1.625H2.16602V11.375" stroke="#0C2556" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </div>
    <div style="margin-left: 5px; font-style: normal;font-weight: normal;font-size: 14px;line-height: 150%;color: #FFFFFF;">
        ${text}
    </div>
</div>
    `;

    return createElementFromHTML(html) || undefined;
  };

  const createPopup = (marker: any, content: string) => {
    return new mapboxgl.Popup({ closeButton: true, closeOnClick: true })
      .setLngLat(marker.getLngLat())
      .setHTML(content);
  };

  useEffect(() => {
    if (map) {
      markers.forEach((marker) => marker.remove());
      const newMarkers: any[] = [];
      source.forEach((s: any) => {
        const lngLat = {
          lat: s.latitude,
          lng: s.longitude,
        };
        const markerElement: HTMLElement | undefined = toMaker(s.qty);
        const newMarker = new mapboxgl.Marker(markerElement)
          .setPopup(
            new mapboxgl.Popup({ offset: 25 }) // add popups
              .setHTML('<div><strong>' + s.ir + '</strong></div>'),
          )
          .setLngLat(lngLat)
          .addTo(map);

        const popupContent = `<h3>${s.ir}</h3>`;
        const popup = createPopup(newMarker, popupContent);
        markerElement &&
          markerElement.addEventListener('click', () => popup.addTo(map));
        newMarkers.push(newMarker);
      });
      setMarkers(newMarkers);
    }
  }, [map, source]);

  return <div id="map" style={{ height: '320px' }} className="map-container" />;
};

export default Map;
