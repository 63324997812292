import styles from 'res/css/ui.scss';
import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Space,
  Switch,
} from 'antd';
import React from 'react';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { QuoteBusinessStatusLabelMap } from '../constants';
import { handleEnterKeyDown } from '@/utils/handleEnterKeyDown';

export type TFilter = {
  loading: boolean;
  filter: FormInstance;
  onSearch: () => void;
};

export const Filter: React.FC<TFilter> = ({ loading, filter, onSearch }) => {
  const handleReset = () => {
    filter.resetFields();
    onSearch();
  };

  return (
    <div className={styles.filter}>
      <Form
        layout="vertical"
        onKeyDown={() => handleEnterKeyDown(onSearch)}
        form={filter}
        onFinish={onSearch}
      >
        <Row gutter={16}>
          <Col>
            <Form.Item name={'query'} label="Quote#">
              <Input placeholder="Quote#" allowClear />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="user_id" label="Customer">
              <UserSyncSelect style={{ width: '200px' }} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="business_status" label="Status">
              <Select
                placeholder="Status"
                allowClear
                style={{ width: '150px' }}
              >
                {Object.keys(QuoteBusinessStatusLabelMap).map((key) => (
                  <Select.Option key={key} value={key}>
                    {QuoteBusinessStatusLabelMap[key] as string}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label={' '}>
              <Space>
                <Button htmlType="submit" type="primary">
                  Search
                </Button>
                <Button onClick={handleReset}>Clear All</Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
