import { useState, useEffect } from 'react';
import type { ShipmentData, InvoiceType } from '../interface/types';
import { useApp } from '@/utils/useapp';
import { ShipmentBusinessStatus } from '@/pages/truckload/ltl/constants';
import { TLType } from '@/components/constants';
import { ShipmentInterface } from '@/pages/truckload/ltl/shipments/Interfaces/ShipmentInterface';
import { FTLShipmentInterface } from '@/pages/truckload/ftl/shipments/Interfaces/ShipmentInterface';

export function useShipmentData(
  invoiceType?: InvoiceType,
  referenceNumber?: string,
) {
  const [shipmentData, setShipmentData] = useState<ShipmentData | undefined>();
  const app = useApp();

  useEffect(() => {
    setShipmentData(undefined);

    const fetchShipmentData = async () => {
      if (!referenceNumber || !invoiceType) {
        return;
      }

      if (referenceNumber.trim() === '') {
        return;
      }

      if (invoiceType === TLType.LTL.toUpperCase()) {
        app.service
          .get(
            'tl/ltl/shipments?query=' + referenceNumber + '&page=1&per_page=1',
          )
          .then((response) => {
            const data = response.data;
            // data is array of ShipmentData
            if (data.length > 0) {
              const find = data[0];
              let business_status_desc = '';
              if (find.business_status == ShipmentBusinessStatus.CANCELED) {
                business_status_desc = 'Canceled';
              } else if (
                find.business_status == ShipmentBusinessStatus.DELIVERED
              ) {
                business_status_desc = 'Delivered';
              } else if (
                find.business_status == ShipmentBusinessStatus.IN_TRANSIT
              ) {
                business_status_desc = 'In Transit';
              } else if (
                find.business_status == ShipmentBusinessStatus.PENDING_PICKUP
              ) {
                business_status_desc = 'Pending Pickup';
              } else if (
                find.business_status == ShipmentBusinessStatus.CONFIRMING
              ) {
                business_status_desc = 'Order reviewing';
              } else if (
                find.business_status == ShipmentBusinessStatus.REJECT
              ) {
                business_status_desc = 'Reject';
              }
              const ok: ShipmentData = {
                id: find.id,
                shipment_number: find.uid,
                business_status: business_status_desc,
                vendor_id: find.vendor_id,
                vendor_name: find.vendor_name,
                shipment_model: find as ShipmentInterface,
              };
              setShipmentData(ok);
            } else {
              // 如果没有找到数据，清空之前的数据
              setShipmentData(undefined);
            }
          })
          .catch((error) => {
            console.error(error);
            setShipmentData(undefined);
          });
      } else if (invoiceType === TLType.FTL.toUpperCase()) {
        app.service
          .get('ftl/shipments?query=' + referenceNumber + '&page=1&per_page=1')
          .then((response) => {
            const data = response.data;
            if (data.length > 0) {
              const find = data[0];
              let business_status_desc = '';
              if (find.business_status == ShipmentBusinessStatus.CANCELED) {
                business_status_desc = 'Canceled';
              } else if (
                find.business_status == ShipmentBusinessStatus.DELIVERED
              ) {
                business_status_desc = 'Delivered';
              } else if (
                find.business_status == ShipmentBusinessStatus.IN_TRANSIT
              ) {
                business_status_desc = 'In Transit';
              } else if (
                find.business_status == ShipmentBusinessStatus.PENDING_PICKUP
              ) {
                business_status_desc = 'Pending Pickup';
              } else if (
                find.business_status == ShipmentBusinessStatus.CONFIRMING
              ) {
                business_status_desc = 'Order reviewing';
              } else if (
                find.business_status == ShipmentBusinessStatus.REJECT
              ) {
                business_status_desc = 'Reject';
              }
              const ok: ShipmentData = {
                id: find.id,
                shipment_number: find.uid,
                business_status: business_status_desc,
                vendor_id: find.vendor_id,
                vendor_name: find.vendor_name,
                shipment_model: find as FTLShipmentInterface,
              };
              setShipmentData(ok);
            } else {
              setShipmentData(undefined);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        app.service
          .get(
            'orders?search_id=' +
              referenceNumber +
              '&warehouse_city_id=0&page=1&per_page=1',
          )
          .then((response) => {
            if (response.data.length > 0) {
              const find = response.data[0];
              const ok: ShipmentData = {
                id: find.id,
                shipment_number: find.uid,
                business_status: find.state,
                mbl_number: find.mbl_number,
                cntr_number: referenceNumber,
                vendor_id: '',
                vendor_name: '',
              };
              setShipmentData(ok);
            }
          })
          .catch((error) => {
            console.error(error);
            setShipmentData(undefined);
          });
      }
    };

    fetchShipmentData().then((r) => r);
  }, [invoiceType, referenceNumber, app.service]);

  return shipmentData;
}
