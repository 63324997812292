import React from 'react';
import moment from 'moment';

export const OrderIdConfirmedAt: React.FC<{ order: any }> = ({ order }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
      }}>
      <small className="text-gray ">
        {order.confirmed_at ? (
          <>Order Date {moment(order.confirmed_at).format('YYYY-MM-DD')}</>
        ) : (
          <>Created at {moment(order.created_at).format('YYYY-MM-DD')}</>
        )}
      </small>
    </div>
  );
};
