import React, { useState } from 'react';
import { Form, message, Button, Drawer, Space, Tag, Input } from 'antd';
import { ShipmentInterface } from './Interfaces/ShipmentInterface';
import { useApp } from '@/utils/useapp';
import { useForm } from 'antd/lib/form/Form';
import { BulkUpdateFormItem } from '@/components/BulkUpdateFormItem';
import { UserSyncSelect } from '@/components/UserSyncSelect';

interface IBulkUpdate {
  shipments: Array<ShipmentInterface>;
  onClose?: () => void;
  onSaved: () => void;
}
const ShipmentBulkUpdate: React.FC<IBulkUpdate> = ({
  shipments,
  onSaved,
  onClose,
}) => {
  const app = useApp();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = useForm();
  const [names, setNames] = useState<Array<string>>([]);

  const handleSave = async () => {
    const values = await form.validateFields(names);

    setLoading(true);

    try {
      await app.service.put('tl/ltl/shipments/bulkUpdate', {
        data: {
          ids: shipments.map((c: any) => c.id),
          ...values,
        },
      });
      message.success('Saved');
      form.resetFields();
      setNames([]);
      onSaved();
      handleClose();
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  const onClickBulkUpdate = () => {
    if (shipments.length === 0) {
      message.error('No shipments selected');
      return;
    }

    setOpen(true);
  };

  const handleClose = () => {
    form.resetFields();
    setNames([]);
    setOpen(false);
    onClose && onClose();
  };

  const handleListenNamesRequired = (name: string, required: boolean) => {
    const _names = Object.assign([], names);
    if (_names.find((n) => n == name)) {
      if (!required) {
        setNames(_names.filter((n) => n != name));
        form.setFieldValue(name, undefined);
      }
    } else {
      _names.push(name);
      required && setNames(_names);
    }
  };

  return (
    <>
      <Button type="primary" ghost onClick={onClickBulkUpdate}>
        Bulk Update
      </Button>

      {open && (
        <Drawer
          title={'Bulk Update'}
          placement={'right'}
          width={'50%'}
          onClose={handleClose}
          destroyOnClose={true}
          open={open}
          extra={
            <Space>
              <Button onClick={handleClose}>Close</Button>
              <Button type="primary" loading={loading} onClick={handleSave}>
                Save
              </Button>
            </Space>
          }
        >
          Selected:
          <div style={{ marginBottom: '10px' }}>
            {shipments?.map((shipment: any) => (
              <Tag key={shipment.uid} color="processing">
                {shipment.uid} <br />
              </Tag>
            ))}
          </div>
          <Form layout="vertical" form={form} onFinish={handleSave}>
            <Form.Item name="isSync" noStyle>
              <Input hidden></Input>
            </Form.Item>
            <BulkUpdateFormItem
              form={form}
              name={'operator_id'}
              label="Operator"
              toListenNameRequired={handleListenNamesRequired}
              elementControlledByStatus={
                <UserSyncSelect
                  disabled={!names?.includes('operator_id')}
                  type="admin"
                />
              }
            />
            <BulkUpdateFormItem
              form={form}
              name={'sales_id'}
              label="Sales"
              toListenNameRequired={handleListenNamesRequired}
              elementControlledByStatus={
                <UserSyncSelect
                  disabled={!names?.includes('sales_id')}
                  type="admin"
                />
              }
            />
          </Form>
        </Drawer>
      )}
    </>
  );
};

export default ShipmentBulkUpdate;
