import React from 'react';
import { DayFormat } from './DayFormat';
import { HourFormat } from './HourFormat';
import { DAY, HOUR, DAY_TEXT, HOUR_TEXT } from './data';

export const RateDetailOfPossibleCharge: React.FC<{
  possibleCharge: any;
}> = ({ possibleCharge }) => {
  if (possibleCharge.rate_text) {
    return <small>${possibleCharge.rate_text}</small>;
  }

  const needFormat = [DAY, DAY_TEXT, HOUR, HOUR_TEXT];

  if (isNaN(possibleCharge.rate)) {
    return <small>{possibleCharge.rate}</small>;
  }

  return (
    <>
      {(possibleCharge.unit == DAY || possibleCharge.unit == DAY_TEXT) && (
        <DayFormat possibleCharge={possibleCharge} />
      )}
      {(possibleCharge.unit == HOUR || possibleCharge.unit == HOUR_TEXT) && (
        <HourFormat possibleCharge={possibleCharge} />
      )}
      {!needFormat.includes(possibleCharge.unit) && (
        <small>${(+possibleCharge.rate).toFixed(2)}</small>
      )}
    </>
  );
};
