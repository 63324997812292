import { Button } from 'antd';
import { useApp } from '@/utils/useapp';
import { useState } from 'react';
import React from 'react';
import { get, upperFirst } from 'lodash';
import {
  formatActionInfo,
  TCommonProps,
  TItemProps,
  TOnDoneProps,
} from '../..';
import { SendDOEmailModal } from './SendDOEmailModal';
import { TruckingModal } from '@/pages/orders/components/Trucking/TruckingModal';
import {
  TASK_TYPE_FILE,
  TASK_TYPE_VENDOR,
  TASK_TYPE_WAREHOUSE,
} from '@/pages/setting/containerTasks/components/data';

export const SendDOAction: React.FC<
TCommonProps & TItemProps & TOnDoneProps
> = ({ taskType, item, container, onDone }) => {
  const app = useApp();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnSaved = () => {
    onDone && onDone();
    handleClose();
  };

  return (
    <>
      <Button
        // disabled={item.disabled}
        key={item.action}
        size="small"
        onClick={handleOpen}>
        {upperFirst(item.action)}
      </Button>
      {open && container && (
        <TruckingModal
          isModalVisible={open}
          showContainers={true}
          order={get(container, 'order')}
          container={container}
          selectedContainers={[container]}
          onCancel={handleClose}
          sendOptions={{
            taskAction: formatActionInfo(item, item.action),
            container_id: container.id,
          }}
          // refreshOrder={refreshOrder}
          refreshContainer={container}
        />
      )}

      {/* {open && container && (
        <SendDOEmailModal
          open={open}
          admin={app.store.auth.account}
          container={container}
          actionInfo={formatActionInfo(item, item.action)}
          onClose={handleCloseOpen}
          onSent={handleOnSent}
        />
      )} */}
    </>
  );
};
