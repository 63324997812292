import React from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  message,
  Row,
  Space,
  TablePaginationConfig,
} from 'antd';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { DrawerForm } from './components/DrawerForm';
import { useForm } from 'antd/lib/form/Form';
import { TCity, TCollection } from '@/types';
import usePagination from '@/components/usePagination';
import AutoResizeTable from '@/components/AutoResizeTable';

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [data, setData] = React.useState<TCollection<TCity>>();
  const [form] = useForm();
  const [filter] = useForm();

  const pagination = usePagination(data);

  const fetchData = React.useCallback(
    async (pagination?: TablePaginationConfig) => {
      setLoading(true);

      try {
        const result = await app.service.get('questionnaires', {
          params: {
            ...filter.getFieldsValue(),
            page: pagination?.current || 1,
            per_page: pagination?.pageSize || 20,
          },
        });

        setData(result);
      } catch (e: any) {
        message.error(e.error || 'System Error');
      }

      setLoading(false);
    },
    [],
  );

  const addNew = () => {
    form.resetFields();
    setVisible(true);
  };

  const handleSave = async () => {
    const values = await form.validateFields();
    setLoading(true);

    try {
      !values.id
        ? await app.service.post('questionnaires', { data: values })
        : await app.service.put(`questionnaires/${values.id}`, {
          data: values,
        });
      message.success('Saved');

      fetchData();
      setVisible(false);
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  // const handleRemove = async (id: number) => {
  //   setLoading(true);

  //   try {
  //     await app.service.delete(`cities/${id}`);

  //     message.success('Deleted');

  //     fetchData();
  //   } catch (e: any) {
  //     message.error(e.data?.message || e.data?.error);
  //   }

  //   setLoading(false);
  // };

  const handleEdit = async (city: TCity) => {
    setLoading(true);

    try {
      form.resetFields();
      setVisible(true);
      form.setFieldsValue(city);
      // const resp = await app.service.get(`cities/${city.id}`);
      // form.setFieldsValue(resp.data);
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  const columns: ColumnsType<any> = React.useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 80,
        render: (text, record, index) => <>{record.id}</>,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 250,
      },
      {
        title: 'Alias',
        dataIndex: 'alias',
        key: 'alias',
        width: 200,
      },
      {
        align: 'center',
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 150,
        render: (text, record) => (
          <Space>
            <a onClick={() => handleEdit(record)}>Edit</a>
            {/* <Popconfirm
              placement="left"
              title="Sure to delete?"
              okText="Confirm"
              cancelText="Cancel"
              onConfirm={() => handleRemove(record.id)}>
              <a>Delete</a>
            </Popconfirm> */}
          </Space>
        ),
      },
    ],
    [],
  );

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.main}>
      <Header
        title="Questionnaires"
        rightElement={
          <div>
            <Button type="primary" onClick={addNew}>
              New Questionnaire
            </Button>
          </div>
        }></Header>

      <div className={styles.filter}>
        <Form layout="vertical" form={filter} onFinish={fetchData}>
          <Row gutter={16}>
            <Col>
              <Form.Item name="query" label="Query">
                <Input />
              </Form.Item>
            </Col>

            <Col>
              <Form.Item label={' '}>
                <Space>
                  <Button htmlType="submit" type="primary">
                    Search
                  </Button>
                  <Button onClick={() => filter.resetFields()}>
                    Clear All
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <AutoResizeTable
        loading={loading}
        pagination={pagination}
        size="small"
        rowKey="id"
        columns={columns}
        onChange={fetchData}
        dataSource={data?.data || []}
        sticky
        scroll={{
          x: 'auto',
        }}
      />

      <Drawer
        title=""
        placement="right"
        width="80%"
        onClose={() => setVisible(false)}
        destroyOnClose={true}
        open={visible}
        extra={
          <Space>
            <Button onClick={() => setVisible(false)}>Close</Button>
            <Button type="primary" loading={loading} onClick={handleSave}>
              Save
            </Button>
          </Space>
        }>
        <DrawerForm form={form} onSubmit={handleSave} />
      </Drawer>
      {/* <QuestionnarieDrawer /> */}
    </div>
  );
};

export default Index;
