import { useApp } from '@/utils/useapp';
import { message } from 'antd';
import { TTarget } from '../Interfaces/ActionInterface';
import { TaskInterface } from '../Interfaces/TaskInterface';

export type TCheckValid = {
  task_id: number;
  action: string;
  name?: string;
  value?: string | null;
} | null;

const useAction = (props: TTarget) => {
  const app = useApp();

  const fetchData = async (setLoading: (loading: boolean) => void) => {
    if (!props.target || !props.targetModel?.id) {
      return null;
    }

    setLoading(true);
    try {
      const resp = await app.service.get(
        `tasks/${props.target}/${props.targetModel.id}`,
      );
      return resp.data;
    } catch (error: any) {
      message.error(error.data?.message || error.data?.error || 'System Error');
    } finally {
      setLoading(false);
    }
  };

  const saveOperation = async (
    task: TaskInterface,
    action: string,
    update_name: string,
    value?: string | Array<any> | null,
    setLoading?: (loading: boolean) => void,
    afterSave?: () => void,
  ) => {
    if (!props.targetModel?.id || !props.target) {
      return;
    }
    setLoading && setLoading(true);

    try {
      const resp = await app.service.post(
        `tasks/${props.target}/${props.targetModel.id}/operations`,
        {
          data: {
            task_id: task.id,
            action,
            name: update_name,
            value,
          },
        },
      );
      afterSave && afterSave();
      return resp;
    } catch (err: any) {
      return message.error(err.data.error || err.data.message);
    } finally {
      setLoading && setLoading(false);
    }
  };

  const saveOperationData = async (
    task: TaskInterface,
    action: string,
    data: { [key: string]: any },
    setLoading?: (loading: boolean) => void,
    afterSave?: () => void,
  ) => {
    if (!props.targetModel?.id || !props.target) {
      return;
    }
    setLoading && setLoading(true);

    try {
      const resp = await app.service.post(
        `tasks/${props.target}/${props.targetModel.id}/operations`,
        {
          data: {
            task_id: task.id,
            action,
            ...data,
          },
        },
      );
      afterSave && afterSave();
      return resp;
    } catch (err: any) {
      return message.error(err.data.error || err.data.message);
    } finally {
      setLoading && setLoading(false);
    }
  };

  const sendEmail = async (
    config: any,
    setLoading?: (loading: boolean) => void,
    afterSave?: () => void,
  ) => {
    console.log('sendEmail', config);
    if (!props.targetModel?.id || !props.target) {
      return;
    }
    setLoading && setLoading(true);
    try {
      const resp = await app.service.post(
        `tasks/${props.target}/${props.targetModel.id}/sendEmail`,
        config,
      );
      afterSave && afterSave();
      message.success('Sent');
      afterSave && afterSave();
      return resp;
    } catch (err: any) {
      return message.error(err.data.error || err.data.message);
    } finally {
      setLoading && setLoading(false);
    }
  };

  const checkValid = async (data: TCheckValid) => {
    if (!props.targetModel?.id || !props.target) {
      return;
    }

    try {
      const resp = await app.service.get(
        `tasks/${props.target}/${props.targetModel.id}/checkValid`,
        {
          params: { ...data },
        },
      );
      return resp;
    } catch (err: any) {
      message.error(err.data.error || err.data.message);
      return;
    }
  };

  return {
    fetchData,
    checkValid,
    saveOperation,
    saveOperationData,
    sendEmail,
  };
};
export default useAction;
