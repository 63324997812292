import { Button, Popover, Checkbox, Divider, Table, Row, Space } from 'antd';
import React, { useMemo, useState } from 'react';
import { RenderActions } from './RenderActions/index';
import { UnorderedListOutlined } from '@ant-design/icons';
import {
  TASK_TYPES,
  TASK_TYPE_FILE,
  TASK_TYPE_VENDOR,
  TASK_TYPE_WAREHOUSE,
  TASK_TYPE_TODOLIST,
} from '@/pages/setting/containerTasks/components/data';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { RenderToDoListTaskType } from './RenderToDoListTaskType';
import { ColumnsType } from 'antd/es/table';
import { TaskTitlePopover } from './TaskTitlePopover';
import { useApp } from '@/utils/useapp';
import { TContainerProps, TOnDoneProps, TItem } from '.';
import { RenderSla } from './RenderSla';
import { TitleSlaTooltip } from './TitleSlaTooltip';
import { RenderTaskColumn } from './RenderTaskColumn';
import useActionTasks from './useActionTasks';
import {
  WHOSE_TASK_OP_ASSIST,
  WHOSE_TASK_CPS,
  WHOSE_TASK_OP,
  WHOSE_TASK_MAP,
} from '@/pages/dispatchs/components/data';

const CheckboxGroup = Checkbox.Group;

const TodoListCheckboxOptions = [
  {
    label: 'Doc',
    value: TASK_TYPE_FILE,
  },
  {
    label: 'Car',
    value: TASK_TYPE_VENDOR,
  },
  {
    label: 'WHS',
    value: TASK_TYPE_WAREHOUSE,
  },
];

export const TodoTaskList: React.FC<
  {
    color: string;
  } & TContainerProps &
    TOnDoneProps
> = ({ color, onDone, container }) => {
  const app = useApp();
  const whoseTaskCheckBoxOptions = Object.keys(WHOSE_TASK_MAP).map((key) => ({
    label: WHOSE_TASK_MAP[(key as unknown) as keyof typeof WHOSE_TASK_MAP],
    value: +key,
  }));

  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([
    TASK_TYPE_FILE,
    TASK_TYPE_VENDOR,
    TASK_TYPE_WAREHOUSE,
  ]);

  const [whoseTaskcheckedList, setWhoseTaskcheckedList] = useState<
    CheckboxValueType[]
  >([]);

  const [loading, setLoading] = useState(false);
  const [respData, setRespData] = useState();
  const { fetchData, updateContainerTask } = useActionTasks();

  const colors: { [key: string]: string } = useMemo(() => {
    const _colors: { [key: string]: string } = {};

    for (const i in TASK_TYPES) {
      _colors[TASK_TYPES[i]] =
        container?.task_icon_colors &&
        container?.task_icon_colors[TASK_TYPES[i]]
          ? container?.task_icon_colors[TASK_TYPES[i]]
          : 'gray';
    }

    return _colors;
  }, [container]);

  const handleOpenChange = async (open: boolean) => {
    if (!open) {
      return;
    }

    setLoading(true);
    const resp = await fetchData(container.id, TASK_TYPE_TODOLIST, setLoading);
    setRespData(resp);
    setLoading(false);
  };

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
  };

  const handleOnCheck = async (data: { [key: string]: any }) => {
    await updateContainerTask(container.id, data, afterUpdated);
  };

  const afterUpdated = () => {
    handleOpenChange(true);
    onDone && onDone();
  };

  const data: Array<TItem> = useMemo(() => {
    if (checkedList?.length == 0) {
      return [];
    }

    const checkedData = respData?.filter((task) =>
      checkedList.includes(task.task_type),
    );

    if (whoseTaskcheckedList?.length > 0) {
      return checkedData?.filter((t) =>
        whoseTaskcheckedList.includes(t.whose_task),
      );
    }

    return checkedData;
  }, [colors, respData, checkedList, whoseTaskcheckedList]);

  const columns: ColumnsType<any> = [
    {
      title: <TaskTitlePopover />,
      dataIndex: 'task',
      render: (_, record: any) => {
        return <RenderTaskColumn item={record} />;
      },
    },
    {
      title: <TitleSlaTooltip />,
      dataIndex: 'sla',
      width: 180,
      render: (_, record: any) => {
        return (
          <RenderSla
            item={record}
            container={container}
            showReset={true}
            onSaved={afterUpdated}
          />
        );
      },
    },
    {
      title: 'Task Type',
      dataIndex: 'task_type',
      width: 80,
      render: (_, record: any) => {
        return <RenderToDoListTaskType item={record} colors={colors} />;
      },
    },
    {
      title: 'Action',
      width: 350,
      render: (_, record: any, index: number) => {
        return (
          <RenderActions
            taskType={record.taskType}
            item={record}
            container={container}
            onCheck={handleOnCheck}
            onDone={afterUpdated}
          />
        );
      },
    },
  ];

  return (
    <>
      <Popover
        mouseEnterDelay={0.05}
        mouseLeaveDelay={0.05}
        onOpenChange={handleOpenChange}
        placement="left"
        zIndex={1000}
        content={
          <div style={{ maxWidth: 1100, minHeight: 800 }}>
            <CheckboxGroup
              options={TodoListCheckboxOptions}
              value={checkedList}
              onChange={onChange}
            />
            <CheckboxGroup
              options={whoseTaskCheckBoxOptions}
              value={whoseTaskcheckedList}
              onChange={(values: CheckboxValueType[]) => {
                setWhoseTaskcheckedList(values);
              }}
            />
            <Divider />
            <Table
              columns={columns}
              loading={loading}
              dataSource={data || []}
              bordered
              size="small"
              pagination={false}
              scroll={{ x: '100%', y: 700 }}
              // rowKey="task"
            />
          </div>
        }
      >
        <Button type="link" size="small">
          <span className={`text-${color}`}>
            <UnorderedListOutlined />
          </span>
        </Button>
      </Popover>
    </>
  );
};
