import React from 'react';
import { message, UploadFile } from 'antd';
import type { UploadProps } from 'antd';
import { DraggerWithPaste } from './DraggerWithPaste';
import { concat } from 'lodash';

interface IDocumentsStoreLocal {
  files?: any;
  onFilesChange: (files: any) => void;
}
export const DocumentsStoreLocal: React.FC<IDocumentsStoreLocal> = ({
  files = [],
  onFilesChange,
}) => {
  const [newFiles, setNewFiles] = React.useState<File[]>([]);

  const [isLoading, setIsLoading] = React.useState(false);

  const fileList: UploadFile[] = React.useMemo(() => {
    return [
      ...(files || []).map((file: string, index: number) => ({
        uid: file,
        name: file,
        status: 'done',
        url: file,
        index,
      })),
      ...(newFiles || []).map((file: File) => ({
        uid: file.uid,
        name: file.name,
        size: file.size,
        type: file.type,
        originFileObj: file,
      })),
    ];
  }, [files, newFiles]);

  React.useEffect(() => {
    onFilesChange(newFiles);
  }, [newFiles]);

  const handleFilesChange: UploadProps['onChange'] = (info) => {
    if (info.file.status === 'removed') {
      if (info.file.originFileObj) {
        setNewFiles(newFiles.filter((f) => f.name !== info.file.name));
      }
    }
    // add new files
    if (info.file instanceof File) {
      setNewFiles([...newFiles, info.file]);
    }
  };

  const draggerProps: UploadProps = {
    multiple: true,
    beforeUpload: () => false,
    fileList: fileList,
    onChange: handleFilesChange,
  };

  const handlePasteFiles = async (pasteFiles: any) => {
    pasteFiles && setNewFiles(concat(newFiles, pasteFiles));
  };

  return (
    <DraggerWithPaste
      uploadProp={draggerProps}
      fileList={fileList}
      disabled={isLoading}
      handlePasteFiles={handlePasteFiles}
    />
  );
};
