import React from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Dropdown,
  Form,
  Input,
  MenuProps,
  message,
  Popconfirm,
  Popover,
  Row,
  Space,
  Switch,
  Table,
  TablePaginationConfig,
} from 'antd';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { DrawerForm } from './components/DawerForm';
import { useForm } from 'antd/lib/form/Form';
import { TLocalStatement, TCollection } from '@/types';
import usePagination from '@/components/usePagination';
import moment from 'moment';
import AutoResizeTable from '@/components/AutoResizeTable';
import { DownOutlined } from '@ant-design/icons';
import FileSaver from 'file-saver';
import { get } from 'lodash';
import { EmailModal } from './components/EmailModal';
import {
  ACTION_MAP,
  ACTION_VIEW,
  ACTION_VIEW_WITH_ORIGINAL_INVOICES,
  ACTION_DOWNLOAD,
  ACTION_DOWNLOAD_WITH_ORIGINAL_INVOICES,
  ACTION_EXCEL,
  ACTION_SEND_EMIAL,
  ACTION_RELOAD,
  ACTION_REGENERATE,
} from './components/data';
import { ReloadModal } from './components/ReloadModal';
import { UserSyncSelect } from '@/components/UserSyncSelect';

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [showEmailForm, setShowEmailForm] = React.useState(false);
  const [showReloadModal, setShowReloadModal] = React.useState(false);
  const [
    localstatement,
    setLocalstatement,
  ] = React.useState<TLocalStatement | null>(null);

  const [data, setData] = React.useState<TCollection<TLocalStatement>>();
  const [model, setModel] = React.useState<TLocalStatement>();
  const [filter] = useForm();

  const pagination = usePagination(data);

  const fetchData = React.useCallback(
    async (pagination?: TablePaginationConfig) => {
      setLoading(true);

      try {
        const result = await app.service.get('accounting/localStatements', {
          params: {
            ...filter.getFieldsValue(),
            page: pagination?.current || 1,
            per_page: pagination?.pageSize || 20,
          },
        });

        setData(result);
      } catch (e: any) {
        message.error(e.data?.message || e.data?.error);
      }

      setLoading(false);
    },
    [],
  );

  const addNew = () => {
    setModel(undefined);
    setVisible(true);
  };

  const handleAmountConfirm = async (
    row: TLocalStatement,
    checked: boolean,
  ) => {
    try {
      await app.service.patch(
        `accounting/localStatements/${row.id}/amountConfirm`,
        {
          data: { amount_confirmed: checked },
        },
      );
      fetchData();
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
  };

  const handleSendEmail = async (row: TLocalStatement) => {
    setLocalstatement(row);
    setShowEmailForm(true);
  };

  const handleEmailSent = () => {
    setShowEmailForm(false);
  };

  const handleOnCloseEmail = () => {
    setShowEmailForm(false);
  };

  const handleReload = async (record: TLocalStatement) => {
    setShowReloadModal(true);
    setLocalstatement(record);
  };

  const handleOnCloseReload = () => {
    setShowReloadModal(false);
    setLocalstatement(null);
  };

  const handleRegenerate = async (record: TLocalStatement) => {
    if (!window.confirm('Are you sure to regenerate this statement?')) {
      return;
    }
    setLoading(true);
    try {
      const resp = await app.service.put(
        `accounting/localStatements/${record.id}/regenerate`,
        {
          data: {},
          responseType: 'blob',
        },
      );

      const fileURL = URL.createObjectURL(resp);
      window.open(fileURL, '_blank');

      fetchData();
    } catch (e: any) {
      message.error(e.data.message || e.data.error);
    }

    setLoading(false);
  };

  const handleEdit = async (m: TLocalStatement) => {
    setModel(m);
    setVisible(true);
  };

  const getFileName = (record: TLocalStatement, fileExtension: string) => {
    return `Statement_${record.id}_${get(record, 'bill_to.name', '')}_${
      record.period
    }.${fileExtension}`;
  };

  const resolveFetchPDF = (resp: any, record: TLocalStatement) => {
    const file = new Blob([resp], {
      type: 'application/pdf',
    });
    const fileURL = URL.createObjectURL(file);
    //Open the URL on new Window
    const w = window.open(fileURL, '_blank');
    if (!w) {
      throw new Error('Please allow popups for this website');
    }
    w.document.title = getFileName(record, '');
  };

  const viewPDF = async (record: TLocalStatement, params = {}) => {
    const resp = await app.service.get(
      `accounting/localStatements/${record.id}/pdf`,
      {
        params,
        responseType: 'blob',
      },
    );
    resolveFetchPDF(resp, record);
  };

  const downloadPDF = async (
    record: TLocalStatement,
    params = { attach_original_invoices: true, with_stamp: true },
  ) => {
    const resp = await app.service.get(
      `accounting/localStatements/${record.id}/pdf`,
      {
        params,
        responseType: 'blob',
      },
    );
    const file = new Blob([resp], {
      type: 'application/pdf',
    });
    FileSaver.saveAs(file, getFileName(record, 'pdf'));
  };
  const actionHandler: any = {
    [ACTION_VIEW]: () => {
      return;
    },
    [ACTION_VIEW_WITH_ORIGINAL_INVOICES]: () => {
      return;
    },
    [ACTION_DOWNLOAD]: () => {
      return;
    },
    [ACTION_DOWNLOAD_WITH_ORIGINAL_INVOICES]: () => {
      return;
    },
    [ACTION_EXCEL]: async (record: TLocalStatement) => {
      const resp = await app.service.get(
        `accounting/localStatements/${record.id}/excel`,
        { responseType: 'blob' },
      );
      const file = new Blob([resp]);
      FileSaver.saveAs(file, getFileName(record, 'xlsx'));
    },
    [ACTION_SEND_EMIAL]: async (record: TLocalStatement) => {
      handleSendEmail(record);
    },
    [ACTION_RELOAD]: async (record: TLocalStatement) => {
      handleReload(record);
    },
    [ACTION_REGENERATE]: async (record: TLocalStatement) => {
      handleRegenerate(record);
    },
  };

  const handleMenuClick = (e: any, record: TLocalStatement) => {
    actionHandler[e.key](record);
  };

  const handlePdfWithStamp = (record: TLocalStatement, key: string) => {
    if (ACTION_DOWNLOAD == key) {
      downloadPDF(record, {
        attach_original_invoices: false,
        with_stamp: true,
      });
    } else if (ACTION_DOWNLOAD_WITH_ORIGINAL_INVOICES == key) {
      downloadPDF(record, { attach_original_invoices: true, with_stamp: true });
    } else if (ACTION_VIEW == key) {
      viewPDF(record, { attach_original_invoices: false, with_stamp: true });
    } else if (ACTION_VIEW_WITH_ORIGINAL_INVOICES) {
      downloadPDF(record, { attach_original_invoices: true, with_stamp: true });
    }
  };

  const handlePdfWithoutStamp = (record: TLocalStatement, key: string) => {
    if (ACTION_DOWNLOAD == key) {
      downloadPDF(record, {
        attach_original_invoices: false,
        with_stamp: false,
      });
    } else if (ACTION_DOWNLOAD_WITH_ORIGINAL_INVOICES == key) {
      downloadPDF(record, {
        attach_original_invoices: true,
        with_stamp: false,
      });
    } else if (ACTION_VIEW == key) {
      viewPDF(record, { attach_original_invoices: false, with_stamp: false });
    } else if (ACTION_VIEW_WITH_ORIGINAL_INVOICES) {
      downloadPDF(record, {
        attach_original_invoices: true,
        with_stamp: false,
      });
    }
  };

  // const items: MenuProps['items'] = Object.keys(ACTION_MAP).map((key) => {
  //   return {
  //     label: [ACTION_DOWNLOAD, ACTION_DOWNLOAD_WITH_ORIGINAL_INVOICES, ACTION_VIEW, ACTION_VIEW_WITH_ORIGINAL_INVOICES].includes(key) ? <Popover
  //       zIndex={1000}
  //       content={<><Space>
  //         <Button onClick={() => handlePdfWithStamp(record, key)}>With Stamp</Button>
  //         <Button onClick={() => handlePdfWithoutStamp(record, key)}>No Stamp</Button>
  //       </Space></>}
  //       placement="left"
  //     >
  //       <div className="w100">{ACTION_MAP[key as unknown as keyof typeof ACTION_MAP]}</div>
  //     </Popover> : ACTION_MAP[key as unknown as keyof typeof ACTION_MAP],
  //     key: key,
  //   };
  // });

  const columns: ColumnsType<any> = React.useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 80,
        render: (text, record, index) => <>{record.id}</>,
      },
      {
        title: 'Company',
        dataIndex: ['bill_to', 'name'],
        width: 120,
      },
      {
        title: 'Customer',
        dataIndex: ['user', 'name'],
        width: 120,
        render: (text, record) =>
          get(record, 'users', [])
            .map((u: any) => u.name)
            .join(', '),
      },

      {
        title: 'Creator',
        dataIndex: ['operator', 'name'],
        width: 120,
      },
      {
        title: 'Period',
        dataIndex: 'period',
        width: 120,
      },
      {
        title: 'INV Count',
        dataIndex: 'invoices_count',
        width: 120,
      },
      {
        title: 'Invoice Amount',
        dataIndex: 'amount_total',
        width: 120,
      },
      {
        title: 'Paid',
        dataIndex: 'amount_paid',
        width: 120,
      },
      {
        title: 'Balance',
        dataIndex: 'amount_due',
        width: 120,
      },

      {
        title: 'Amt. Confirm',
        dataIndex: 'amount_confirmed',
        width: 120,
        render: (checked: boolean, record: TLocalStatement) => (
          <Switch
            checked={checked}
            onChange={(v) => handleAmountConfirm(record, v)}
          />
        ),
      },
      {
        title: 'Created At',
        dataIndex: 'created_at',
        key: 'created_at',
        width: 120,
      },
      {
        title: 'Update At',
        dataIndex: 'updated_at',
        key: 'updated_at',
        width: 120,
      },
      {
        align: 'center',
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 150,
        render: (text, record) => (
          <Space>
            <Dropdown
              menu={{
                items: Object.keys(ACTION_MAP).map((key) => {
                  return {
                    label: [
                      ACTION_DOWNLOAD,
                      ACTION_DOWNLOAD_WITH_ORIGINAL_INVOICES,
                      ACTION_VIEW,
                      ACTION_VIEW_WITH_ORIGINAL_INVOICES,
                    ].includes(key) ? (
                        <Popover
                          zIndex={1000}
                          content={
                            <>
                              <Space>
                                <Button
                                  onClick={() => handlePdfWithStamp(record, key)}
                                >
                                With Stamp
                                </Button>
                                <Button
                                  onClick={() =>
                                    handlePdfWithoutStamp(record, key)
                                  }
                                >
                                No Stamp
                                </Button>
                              </Space>
                            </>
                          }
                          placement="left"
                        >
                          <div className="w100">
                            {
                              ACTION_MAP[
                                (key as unknown) as keyof typeof ACTION_MAP
                              ]
                            }
                          </div>
                        </Popover>
                      ) : (
                        ACTION_MAP[(key as unknown) as keyof typeof ACTION_MAP]
                      ),
                    key: key,
                  };
                }),
                onClick: (e) => handleMenuClick(e, record),
              }}
            >
              <Button>
                <Space>
                  Action
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </Space>
        ),
      },
    ],
    [],
  );

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.main}>
      <Header
        title="Local Statements"
        rightElement={
          <div>
            <Button type="primary" onClick={addNew}>
              New Local Statements
            </Button>
          </div>
        }
      ></Header>

      <div className={styles.filter}>
        <Form layout="vertical" form={filter} onFinish={fetchData}>
          <div>
            <Row gutter={16}>
              <Col span={4}>
                <Form.Item name="query" label="Query">
                  <Input placeholder="ST#/CNTR#" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="sales_id" label="Sales">
                  <UserSyncSelect type="admin" className="w100" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="sales_support_id" label="Sales Support">
                  <UserSyncSelect type="admin" className="w100" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="start_date" noStyle>
                  <Input hidden />
                </Form.Item>
                <Form.Item name="end_date" noStyle>
                  <Input hidden />
                </Form.Item>
                <Form.Item label="Date" shouldUpdate>
                  {({ getFieldValue, setFieldsValue }) => {
                    const startDate = getFieldValue('start_date');
                    const endDate = getFieldValue('end_date');
                    return (
                      <DatePicker.RangePicker
                        value={
                          startDate && endDate
                            ? [moment(startDate), moment(endDate)]
                            : undefined
                        }
                        onChange={(value) =>
                          setFieldsValue({
                            start_date: value
                              ? value[0]?.format('YYYY-MM-DD')
                              : null,
                            end_date: value
                              ? value[1]?.format('YYYY-MM-DD')
                              : null,
                          })
                        }
                      />
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label={' '}>
                  <Space>
                    <Button htmlType="submit" type="primary">
                      Search
                    </Button>
                    <Button onClick={() => filter.resetFields()}>
                      Clear All
                    </Button>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      </div>

      <AutoResizeTable
        loading={loading}
        pagination={pagination}
        size="small"
        rowKey="id"
        columns={columns}
        onChange={fetchData}
        dataSource={data?.data || []}
        sticky
        scroll={{
          x: 'auto',
        }}
      />

      <Drawer
        title=""
        placement="right"
        width="90%"
        onClose={() => setVisible(false)}
        destroyOnClose={true}
        open={visible}
      >
        <DrawerForm
          model={model}
          onCreated={() => {
            fetchData();
            setVisible(false);
          }}
        />
      </Drawer>

      {showEmailForm && localstatement && (
        <EmailModal
          open={showEmailForm}
          localstatement={localstatement}
          onSaved={handleEmailSent}
          onClose={handleOnCloseEmail}
        />
      )}

      {showReloadModal && localstatement && (
        <ReloadModal
          open={showReloadModal}
          localStatement={localstatement}
          onClose={handleOnCloseReload}
          onDone={fetchData}
        />
      )}
    </div>
  );
};

export default Index;
