import React from 'react';
import { Form } from 'antd';
import { FormInstance } from 'antd/es/form';
import { DocumentsStoreLocal } from './DocumentsStoreLocal';

interface IDocumentsStoreLocalFormItem {
  form: FormInstance;
}
export const DocumentsStoreLocalFormItem: React.FC<
IDocumentsStoreLocalFormItem
> = ({ form }) => {
  const handleFilesChange = async (newFiles: any) => {
    form.setFieldValue('files', newFiles);
  };
  return (
    <>
      <Form.Item name={'files'} hidden noStyle></Form.Item>
      <Form.Item label="Documents">
        <DocumentsStoreLocal onFilesChange={handleFilesChange} />
      </Form.Item>
    </>
  );
};
