import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Space,
  Table,
  Tag,
} from 'antd';
import React, { useState } from 'react';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import AutoResizeTable from '@/components/AutoResizeTable';
import { Filter } from './components/Filter';
import TextArea from 'antd/lib/input/TextArea';
import { useForm } from 'antd/lib/form/Form';
import {
  CNTR_SIZE_MAP,
  DAY,
  HOUR,
  NIGHT,
  UNIT_MAP,
  WEIGHT,
} from '@/components/PossibleCharges';
import { capitalize, isArray } from 'lodash';
import { TCollection } from '@/types';
import usePagination from '@/components/usePagination';
import { EditableText } from '@/components/Editable';

export const BID_PENDDING = 0;
export const BID_ACCEPTED = 1;
export const BID_REJECTED = 2;
export const BID_CANCELED = 3;

export const BID_STATUS_MAP = {
  [BID_PENDDING]: 'Pending',
  [BID_ACCEPTED]: 'Accepted',
  [BID_REJECTED]: 'Rejected',
  [BID_CANCELED]: 'Canceled',
};

const Reject: React.FC<{
  id: number;
  onConfirm: (id: number, reason: string) => void;
}> = ({ id, onConfirm }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = useForm();
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleConfirm = async () => {
    const values = await form.getFieldsValue();
    await onConfirm(id, values.reason);
    handleCancel();
  };

  return (
    <>
      <div className="text-gray" onClick={showModal}>
        <Button size="small" danger ghost>
          Reject
        </Button>
      </div>
      <Modal
        zIndex={9999}
        cancelText="Close"
        footer={
          <>
            <Button type="primary" onClick={handleConfirm}>
              Confirm
            </Button>
          </>
        }
        title="Reason"
        open={isModalVisible}
        onCancel={handleCancel}>
        <Form form={form} layout="vertical">
          <Form.Item
            label=""
            name="reason"
            rules={[{ required: true, message: 'Reason is required' }]}>
            <TextArea rows={6}></TextArea>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

const RangeInput: React.FC<{
  unit: number;
  range: any;
  onChange: (T: any) => void;
  disabled?: boolean;
}> = ({ unit, range = {}, onChange, disabled = false }) => {
  const [_range, setRange] = useState(range);
  const handleChange: React.ChangeEventHandler = (e) => {
    const { name, value }: any = e.target;
    setRange({ ..._range, [name]: value });
  };

  const handleUpdate = () => {
    onChange(_range);
  };

  return (
    <>
      {(unit == DAY || unit == HOUR || unit == NIGHT) && (
        <Input.Group
          style={{
            width: '100%',
          }}
          size="small">
          <Row gutter={8}>
            <Col span={11}>
              <Input
                disabled={disabled}
                size="small"
                value={_range.min || ''}
                name="min"
                addonAfter="Min"
                onChange={handleChange}
                onBlur={handleUpdate}
              />
            </Col>
            <Col span={11}>
              <Input
                disabled={disabled}
                size="small"
                value={_range.free || ''}
                name="free"
                addonAfter="Free"
                onChange={handleChange}
                onBlur={handleUpdate}
              />
            </Col>
          </Row>
        </Input.Group>
      )}
      {unit == WEIGHT && (
        <Input.Group
          style={{
            width: '100%',
          }}
          size="small"
          compact>
          <Input
            style={{ width: 100, textAlign: 'center' }}
            value={_range.min || ''}
            name="min"
            placeholder="Minimum"
            onChange={handleChange}
            onBlur={handleUpdate}
          />
          <Input
            className="site-input-split"
            style={{
              width: 25,
              borderLeft: 0,
              borderRight: 0,
              pointerEvents: 'none',
            }}
            placeholder="~"
            disabled
          />
          <Input
            className="site-input-right"
            style={{
              width: 100,
              textAlign: 'center',
            }}
            name="max"
            value={_range.max || ''}
            placeholder="Maximum"
            onChange={handleChange}
            onBlur={handleUpdate}
          />
        </Input.Group>
      )}
    </>
  );
};

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<any>({});
  const [data, setData] = React.useState<TCollection<any>>();

  const pagination = usePagination(data);

  const handleFilterChange = (values: any) => {
    setFilter(values);
  };

  const fetchData = React.useCallback(
    async (pagination?: TablePaginationConfig) => {
      const _filter = Object.assign({}, filter);
      if (_filter.city) {
        _filter.city_id = _filter.city.id;
      }
      delete _filter.city;
      setLoading(true);
      try {
        const result = await app.service.get('rateBidRequests', {
          params: {
            page: pagination?.current || 1,
            per_page: pagination?.pageSize || 20,
            ..._filter,
          },
        });
        // setPagination({ current: result.meta.current_page, pageSize: result.meta.per_page, total: result.meta.total });
        setData(result);
      } catch (err: any) {
        message.error(err.data?.message || err.data?.error);
      } finally {
        setLoading(false);
      }
      setLoading(false);
    },
    [],
  );

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleAccept = async (id: number) => {
    setLoading(true);
    try {
      await app.service.patch(`rateBidRequests/${id}/accept`);
      fetchData();
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const handleReject = async (id: number, reason: string) => {
    setLoading(true);
    try {
      await app.service.patch(`rateBidRequests/${id}/reject`, {
        data: {
          reason,
        },
      });
      fetchData();
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const handlePatchUpdatePossibleCharge = async (
    id: number,
    possibleCharge: any,
  ) => {
    setLoading(true);

    try {
      const ls = data?.data.find((d) => d.id == id) || null;
      const possible_charges: any[] = ls.possible_charges || [];
      const idx = possible_charges.findIndex((p) => p.id == possibleCharge.id);
      if (idx > -1) {
        possible_charges[idx] = possibleCharge;
        await app.service.patch(`rateBidRequests/${id}/possibleCharges`, {
          data: {
            possible_charges: possible_charges,
          },
        });
        message.success('updated');
        // fetchData();
      }
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePatchUpdate = async (id: number, data: any) => {
    setLoading(true);

    try {
      await app.service.patch(`rateBidRequests/${id}`, {
        data,
      });
      fetchData();
      message.success('updated');
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const formatRange = (range: any) => {
    if (!range) {
      return '';
    }

    const values = Object.entries(range).map(([key, value]) => {
      return `${value} ${capitalize(key)}`;
    });
    return `${values.join(' ')}`;
  };

  const formatRate = (rate: string, range: any) => {
    return `$${rate} ${formatRange(range)}`;
  };

  const expandedRowRender = (data: any) => {
    // return data.possoble_charges;
    const rows = [];
    for (const charge of data.possible_charges) {
      const row = {
        ...charge,
        key: data.id,
      };
      rows.push(row);
    }
    const columns: any = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
      },
      {
        title: 'Unit',
        dataIndex: 'unit',
        key: 'unit',
        render: (text: string, record: any) => {
          return <>{UNIT_MAP[record.unit as keyof typeof UNIT_MAP]}</>;
        },
      },
      {
        title: 'Rate',
        dataIndex: 'rate',
        key: 'rate',
        render: (text: string, record: any) => {
          return formatRate(record.rate, record.range) !=
            formatRate(record.original.rate, record.original.range) ? (
              <Tag color="red">
                {formatRate(record.original.rate, record.original.range)}
              </Tag>
            ) : (
              <>{formatRate(record.original.rate, record.original.range)}</>
            );
        },
      },
      {
        title: 'After',
        dataIndex: 'rate',
        key: 'rate',
        width: '200px',
        render: (text: string, record: any) => {
          // console.log(record.status);
          return (
            <>
              <EditableText
                disabled={data.status != BID_PENDDING}
                type="number"
                name="rate"
                value={record.rate || ''}
                onChange={(name: string, value: any) =>
                  handlePatchUpdatePossibleCharge(data.id, {
                    ...record,
                    [name]: value,
                  })
                }
              />
            </>
          );
        },
      },
      {
        title: 'Range',
        dataIndex: 'range',
        key: 'range',
        width: '300px',
        render: (text: string, record: any) => {
          return (
            <>
              <RangeInput
                disabled={data.status != BID_PENDDING}
                unit={record?.unit || 0}
                range={record?.range || {}}
                onChange={(value) => {
                  handlePatchUpdatePossibleCharge(data.id, {
                    ...record,
                    range: value,
                  });
                }}
              />
            </>
          );
        },
      },
      {
        title: 'Cntr size',
        dataIndex: 'cntr_size',
        key: 'cntr_size',
        render: (text: string, record: any) => {
          return (
            record.cntr_size
              ?.map(
                (size: number) =>
                  CNTR_SIZE_MAP[size as unknown as keyof typeof CNTR_SIZE_MAP],
              )
              .join(',') || ''
          );
        },
      },
    ];

    return (
      <Table bordered columns={columns} dataSource={rows} pagination={false} />
    );
  };

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 50,
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      width: 50,
      render: (text, record) => {
        return <>{record.rate?.uid || '-'}</>;
      },
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      width: 100,
      render: (text, record) => {
        return <>{record.user.name}</>;
      },
    },
    {
      title: 'Base Rate',
      dataIndex: 'old_base_rate',
      key: 'old_base_rate',
      width: 100,
      render: (text: string, record: any) => {
        return record.rate.base_rate != record.base_rate ? (
          <Tag color="red">{record.rate.base_rate}</Tag>
        ) : (
          record.base_rate
        );
      },
    },
    {
      title: 'New Base Rate',
      dataIndex: 'base_rate',
      key: 'base_rate',
      width: 100,
      render: (text: string, record: any) => {
        return (
          <EditableText
            type="number"
            name="base_rate"
            disabled={record.status != BID_PENDDING}
            value={record.base_rate || ''}
            onChange={(name: string, value: any) => {
              handlePatchUpdate(record.id, {
                [name]: value || 0,
              });
            }}
          />
        );
      },
    },
    {
      title: 'Fuel Surcharge',
      dataIndex: 'old_fuel_surcharge',
      key: 'old_fuel_surcharge',
      width: 100,
      render: (text: string, record: any) => {
        return record.rate.fuel_surcharge != record.fuel_surcharge ? (
          <Tag color="red">{record.rate.fuel_surcharge}</Tag>
        ) : (
          record.fuel_surcharge
        );
      },
    },
    {
      title: 'New Fuel Surcharge',
      dataIndex: 'fuel_surcharge',
      key: 'fuel_surcharge',
      width: 100,
      render: (text: string, record: any) => {
        return (
          <EditableText
            type="number"
            name="fuel_surcharge"
            disabled={record.status != BID_PENDDING}
            value={record.fuel_surcharge || ''}
            onChange={(name: string, value: any) => {
              handlePatchUpdate(record.id, {
                [name]: value || 0,
              });
            }}
          />
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (text, record) => {
        return (
          <>
            {
              BID_STATUS_MAP[
                record.status as unknown as keyof typeof BID_STATUS_MAP
              ]
            }
          </>
        );
      },
    },
    {
      title: 'Reject Reason',
      dataIndex: 'rejected_reason',
      key: 'rejected_reason',
      width: 200,
    },
    {
      align: 'center',
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 120,
      render: (text, record) => (
        <Space>
          {record.status == BID_PENDDING && (
            <Button
              type="primary"
              size="small"
              onClick={() => handleAccept(record.id)}>
              Accept
            </Button>
          )}
          <Divider type="vertical" />
          {record.status == BID_PENDDING && (
            <Reject id={record.id} onConfirm={handleReject} />
          )}
        </Space>
      ),
    },
  ];

  return (
    <div className={styles.main}>
      <Header title="Bid Requests" rightElement={<div></div>}></Header>

      <Filter
        loading={loading}
        filter={filter}
        onSearch={fetchData}
        onChange={handleFilterChange}>
        {' '}
      </Filter>
      <div>
        <AutoResizeTable
          pagination={pagination}
          expandable={{
            expandedRowRender,
          }}
          loading={loading}
          onChange={fetchData}
          size="small"
          rowKey="id"
          columns={columns}
          dataSource={data?.data || []}
          scroll={{ x: 1500 }}
          sticky
        />
      </div>
    </div>
  );
};

export default Index;
