const RANGES = [
  { min: 5001, max: 6000, value: 6000 },
  { min: 6001, max: 7000, value: 7000 },
  { min: 7001, max: 8000, value: 8000 },
  { min: 8001, max: 9000, value: 9000 },
  { min: 9001, max: 10000, value: 10000 },
];

const FREE_PREMIUM = 5;
const FREE_PERMIUM_CARGO_VALUE = 5000;
const INSURANCE_DEBUCTIBLE_POLICY__5000_TO_10000_RATE = 0.002;

export default (cargoValue: number): number => {
  const _cargoValue = Math.ceil(cargoValue);

  if (_cargoValue <= FREE_PERMIUM_CARGO_VALUE) {
    return 0;
  }

  const range = RANGES.find(
    (range) => _cargoValue >= range.min && _cargoValue <= range.max,
  );

  if (range) {
    return (
      range.value * INSURANCE_DEBUCTIBLE_POLICY__5000_TO_10000_RATE -
      FREE_PREMIUM
    );
  } else {
    return 0;
  }
};
