export const ACCOUNT_ICON = 'account_icon';
const CREATE_BILL = 'create_bill';
const APPROVE_ALL_BILLS = 'approve_all_bills';
const ALL_INVOICE_SENT = 'all_invoices_sent';
export interface TaskIconColorInterface {
  pickup_warehouse: 'danger' | 'success' | 'primary' | 'gray';
  doc: 'danger' | 'success' | 'primary' | 'gray';
  car: 'danger' | 'success' | 'primary' | 'gray';
  todo_list: 'danger' | 'success' | 'primary' | 'gray';
  receiving_warehouse: 'danger' | 'success' | 'primary' | 'gray';

  [key: string]: 'danger' | 'success' | 'primary' | 'gray';
}

const icon_key_map: { [key: string]: string } = {
  create_bill: 'Create Bill',
  approve_all_bills: 'Approve All Bills',
  all_invoices_sent: 'All Invoices Sent',
};

const ACCOUNT_ICON_TEXT_MAP: { [key: string]: string } = {
  create_bill: 'Create Bill',
  approve_all_bills: 'Approve All Bills',
  all_invoices_sent: 'All Invoices Sent',
};

export function transferTasksIconColor(iconColors: TaskIconColorInterface) {
  type IconKeyType =
    | typeof CREATE_BILL
    | typeof APPROVE_ALL_BILLS
    | typeof ALL_INVOICE_SENT;
  type ColorType = 'danger' | 'success' | 'primary' | 'gray';

  const task = (
    iconKey: IconKeyType,
    color: ColorType,
  ): { task: string; done: boolean } => ({
    task: icon_key_map[iconKey],
    done: color === 'success',
  });

  const tasks = Object.keys(ACCOUNT_ICON_TEXT_MAP).map((iconKey) => {
    let color: ColorType = 'danger';
    if (iconColors && iconColors[iconKey as IconKeyType] === 'success') {
      color = 'success';
    }

    return task(iconKey as IconKeyType, color);
  });

  return tasks;
}
