import { List, Avatar, message, Row } from 'antd';
import { useEffect, useState } from 'react';
import React from 'react';
import { useApp } from '@/utils/useapp';
import { observer } from 'mobx-react';
import { AttributeChangeLogInterface } from '@/Interfaces/AttributeChangeLog/AttributeChangelogInterface';
import { TAttributeChangeTarget } from '@/Interfaces/AttributeChangeLog/AttributeChangeTarget';

const AttributeChangelog: React.FC<
  TAttributeChangeTarget & { visible: boolean }
> = observer(({ targetId, target, attribute, visible = false, ...props }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [logs, setLogs] = useState<AttributeChangeLogInterface[]>([]);

  const app = useApp();

  const fetchLogs = async () => {
    if (!visible) {
      return;
    }

    setLoading(true);
    try {
      const resp = await app.service.get(
        `atttributeChangelogs/${target}/${targetId}`,
        {
          params: {
            attribute: attribute,
          },
        },
      );
      setLogs(resp.data);
    } catch (err: any) {
      message.error(err?.data?.message || err?.data?.error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (visible) {
      fetchLogs();
    }
  }, [visible]);

  return (
    <List loading={loading} itemLayout="horizontal">
      {logs.map((log: AttributeChangeLogInterface) => (
        <List.Item key={log.id}>
          <List.Item.Meta
            avatar={<Avatar>{log.operator?.name.charAt(0) || 'S'} </Avatar>}
            title={
              <>
                <span>{log.operator?.name || 'System'}</span>
              </>
            }
            description={
              <>
                <Row>
                  {log.operator?.name} changes {log.old_value} to{' '}
                  {log.new_value} at {log.created_at}
                </Row>
                <Row>Memo: {log.reason}</Row>
              </>
            }
          />
        </List.Item>
      ))}
    </List>
  );
});

export default AttributeChangelog;
