import { get } from 'lodash';
import { CSSProperties, memo, useCallback, useState } from 'react';
import { Typography, InputProps, Input, Tooltip, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';

const inputStyle: CSSProperties = {
  width: '100%',
};

type EditableCommonInputColumnProps = InputProps & {
  record: any;
  path: string[];
  onSave: (value: string) => void;
};

const EditableCommonInputColumn = memo(
  ({ record, path, onSave, ...props }: EditableCommonInputColumnProps) => {
    const [isEditing, setIsEditing] = useState(false);
    const handleEdit = () => {
      setIsEditing(true);
    };

    const [value, setValue] = useState(get(record, path, undefined));

    const handleChange: InputProps['onChange'] = useCallback((e) => {
      setValue(e.target.value);
    }, []);

    const handleBlur = useCallback(() => {
      onSave(value);
      setIsEditing(false);
    }, [onSave, value]);

    const handlePressEnter = useCallback(() => {
      onSave(value);
      setIsEditing(false);
    }, [onSave, value]);

    if (!isEditing) {
      if (!value) {
        return (
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={handleEdit}
          ></Button>
        );
      }

      return (
        <Tooltip placement="topLeft" title={value}>
          <Typography.Link underline onClick={handleEdit}>
            {value}
          </Typography.Link>
        </Tooltip>
      );
    }

    return (
      <Input
        style={inputStyle}
        autoFocus
        value={value}
        allowClear
        onChange={handleChange}
        onBlur={handleBlur}
        onPressEnter={handlePressEnter}
        {...props}
      />
    );
  },
);

EditableCommonInputColumn.displayName = 'EditableCommonInputColumn';

export default EditableCommonInputColumn;
