import styles from 'res/css/ui.scss';
import { Button, Col, Form, Row, Select, FormInstance, Space } from 'antd';
import { IntermodalRegionSelect } from '@/components/IntermodalRegionSelect';
import { CitySelect } from '@/components/CitySelect';
import React from 'react';
import { FacilityTypeSelect } from '@/components/FacilityTypeSelect';

export type TFilter = {
  loading: boolean;
  filter: FormInstance;
  onSearch: () => void;
  onChange?: (values: any) => void;
};

const LATEST_RATE_DATES = [
  { label: 'Recent 1 Month', value: 1 },
  { label: 'Recent 3 Months', value: 3 },
  { label: 'Recent 6 Months', value: 6 },
  { label: 'Recent 9 Months', value: 9 },
  { label: 'Recent 12 Months', value: 12 },
];

const SOURCE_OPTIONS = [
  { label: 'All', value: 'all' },
  { label: 'Drayeasy', value: 'drayeasy' },
  { label: 'Drayage', value: 'drayage' },
];

export const Filter: React.FC<TFilter> = ({
  loading,
  filter,
  onSearch,
  onChange,
}) => {
  const handleSearch = () => {
    onSearch();
  };

  const handleClearAll = () => {
    filter.resetFields();
    onSearch();
  };

  const handleChange = async (event: any) => {
    const { name, value } = event.target as HTMLInputElement;
    await filter.setFieldsValue({ [name]: value });
  };

  return (
    <div className={styles.filter}>
      <Form
        layout="vertical"
        form={filter}
        onFinish={onSearch}
        initialValues={{
          recent_month: 3,
          no_drayeasy_vendor: true,
          force_scale: false,
        }}
        style={{ width: '100%' }}>
        <Row gutter={8}>
          <Col span={4}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <Form.Item name="source" initialValue={'all'} label="Source">
                  <Select>
                    {SOURCE_OPTIONS.map(
                      (option: { value: any; label: string }) => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ),
                    )}
                  </Select>
                </Form.Item>
              )}
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <Form.Item
                  name="intermodal_region_id"
                  label="Port of discharge / Rail destination"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}>
                  <IntermodalRegionSelect
                    selected={getFieldValue('intermodal_region_id')}
                    onSelect={(id: string) =>
                      handleChange({
                        target: { name: 'intermodal_region_id', value: id },
                      })
                    }
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <Form.Item
                  name="to_city"
                  className="mb0"
                  label="Warehosue City">
                  <CitySelect
                    selected={getFieldValue('to_city')}
                    onSelect={(city) => {
                      filter.setFieldsValue({ to_city: city });
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Date" name="recent_month">
              <Select options={LATEST_RATE_DATES} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="force_scale" label="Scale Dataset">
              <Select
                options={[
                  { label: 'Yes', value: true },
                  { label: 'Auto', value: false },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="no_drayeasy_vendor" label="No DE Vendor">
              <Select
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8} align="middle">
          <Col span={4}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <Form.Item name="from_facility_type" label="From Facility Type">
                  <FacilityTypeSelect getValueForOption="name" />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <Form.Item name="to_facility_type" label="To Facility Type">
                  <FacilityTypeSelect getValueForOption="name" />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="">
              <Space style={{ paddingTop: '30px' }}>
                <Button htmlType="submit" type="primary">
                  Search
                </Button>
                <Button onClick={handleClearAll}>Clear All</Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
