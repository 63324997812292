import React from 'react';
import { useApp } from '@/utils/useapp';
import { Button, Space, Typography, message, Spin, Table } from 'antd';
import { FormInstance } from 'antd/es/form';
import { get } from 'lodash';
import { CopyButton } from '@/components/CopyButton';
import { CNTREasyTracking } from '@/pages/dispatchs/components/CNTREasyTracking';
import { WHSColumn } from '@/pages/dispatchs/components/WHS/WHSColumn';
import { Memo } from '@/pages/dispatchs/components/Memo';
import Online from './Online';
import { MBLLink } from '@/components/MBLLInk';
import type { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { TSorter } from '@/types';
import { RenderSla } from '@/pages/dispatchs/components/ActionTasks/RenderSla';
import { TitleSlaTooltip } from '@/pages/dispatchs/components/ActionTasks/TitleSlaTooltip';
import usePagination from '@/components/usePagination';
import { Overdue } from '@/pages/dispatchs/components/Table/Overdue';
import { TodoTaskList } from '@/pages/dispatchs/components/ActionTasks/TodoTaskList';

type TStatusTable = {
  filter: FormInstance;
  status: string;
  handleCommentSaved: (id: number) => void;
};
export const StatusTable: React.FC<TStatusTable> = ({
  filter,
  status = 'Overdue',
  handleCommentSaved,
}) => {
  const app = useApp();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const pagination = usePagination(data);

  const fetchData = React.useCallback(
    async (pagination?: TablePaginationConfig, sorter?: TSorter) => {
      setLoading(true);

      const _filter = Object.assign({}, filter.getFieldsValue());

      try {
        const resp = await app.service.get('dispatchDashboard/containers', {
          params: {
            status,
            ..._filter,
            page: pagination?.current || 1,
            per_page: pagination?.pageSize || 100,
            ...sorter,
          },
        });

        setData(resp);
      } catch (err: any) {
        message.error(err.data?.message || err.data?.error);
      } finally {
        setLoading(false);
      }
    },
    [status],
  );

  const columns: ColumnsType<any> = React.useMemo(
    () => [
      {
        title: 'CNTR#',
        dataIndex: 'number',
        key: 'number',
        width: 240,
        fixed: 'left',
        render: (text, record) => (
          <Space>
            <CNTREasyTracking
              showAtrribute={false}
              container={record}
              order={record?.order}
            />
            <Overdue container={record} toIgnoreSpecifiedTasks={true} />
            <TodoTaskList
              container={record}
              color={get(record, 'task_icon_colors.todoList', 'gray')}
            />
          </Space>
        ),
      },
      {
        title: 'MBL#',
        dataIndex: 'mbl_number',
        key: 'mbl_number',
        width: 200,
        fixed: 'left',
        render: (text, record) => (
          <Space>
            <MBLLink mblNumber={record.order?.mbl_number || ''} />
            {get(record, 'order.mbl_number', false) && (
              <CopyButton value={get(record, 'order.mbl_number')} />
            )}
          </Space>
        ),
      },
      {
        title: 'Disaptch Status',
        dataIndex: 'dispatch_status_name',
        key: 'dispatch_status_name',
        width: 200,
      },
      {
        title: 'IR ETA',
        dataIndex: 'final_port_eta',
        key: 'final_port_eta',
        width: 100,
      },
      {
        title: 'WHS',
        dataIndex: 'warehouse_id',
        key: 'warehouse_id',
        width: 300,
        render: (text, record) => {
          return (
            <WHSColumn
              container={record}
              onSaved={() => console.log(record.warehouse_id)}
            />
          );
        },
      },
      {
        title: 'SE',
        dataIndex: 'se',
        key: 'se',
        width: 120,
        render: (text, record) => (
          <>
            <Online id={record.order?.sales_id || 0} />
            <Typography.Text className="ml-sm">
              {record.order?.sales?.name || '-'}
            </Typography.Text>
          </>
        ),
      },
      {
        title: 'SS',
        dataIndex: 'sales_support_id',
        key: 'sales_support_id',
        width: 120,
        render: (text, record) => (
          <>
            <Online id={record.order?.sales_support_id || 0} />
            <Typography.Text className="ml-sm">
              {record.order?.sales_support?.name || '-'}
            </Typography.Text>
          </>
        ),
      },
      {
        title: 'OP',
        dataIndex: 'operator_id',
        key: 'operator_id',
        width: 120,
        render: (text, record) => (
          <>
            <Online id={record.order?.operator_id || 0} />
            <Typography.Text className="ml-sm">
              {record.order?.operator?.name || '-'}
            </Typography.Text>
          </>
        ),
      },
      {
        title: 'OP Leader',
        dataIndex: 'opl',
        key: 'opl',
        width: 120,
        render: (text, record) => (
          <>
            <Online id={record.order?.operator_lead_id || 0} />
            <Typography.Text className="ml-sm">
              {record.order?.operator_lead?.name || '-'}
            </Typography.Text>
          </>
        ),
      },
      {
        title: 'Memo',
        dataIndex: 'internal_memo',
        key: 'internal_memo',
        width: 120,
        render: (text, record) => (
          <Button type="link" size="small">
            <Memo
              initialValue={record.internal_memo}
              onSaved={handleCommentSaved}
              container={record}
            />
          </Button>
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    status && fetchData();
  }, [status]);

  if (!status || !data) {
    return <></>;
  }
  // else if (loading) {
  //   return (
  //     <div className="flex h90 justify-center align-center">
  //       <Spin />
  //     </div>
  //   );
  // }

  return (
    <div key={status} className="p-sm" id={status}>
      <h5>{status}</h5>
      <Table
        loading={loading}
        pagination={pagination}
        bordered
        size="small"
        rowKey="id"
        columns={columns}
        // onChange={fetchData}
        onChange={(pagination, _2, sorter: any) => {
          fetchData(pagination, {
            sort_by: sorter?.columnKey || sorter?.field,
            sort_value:
              sorter?.order === 'ascend'
                ? 'asc'
                : sorter?.order === 'descend'
                  ? 'desc'
                  : undefined,
          });
        }}
        dataSource={data?.data || []}
        scroll={{
          x: 1500,
        }}
        sticky
      />
    </div>
  );
};
