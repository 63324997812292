import { cmToIn, DimensionUnit, WeightUnit } from '@/utils/freight';

import { kgToLb } from '@/utils/freight';
import { inToCm, lbToKg } from '@/utils/freight';
import { FormInstance } from 'antd/es/form';

export const DimensionItemExtra = ({
  form,
  name,
  extraKey,
}: {
  form: FormInstance;
  name: number;
  extraKey: string;
}) => {
  const unit =
    form.getFieldValue(['items', name, 'dimension_unit']) ?? DimensionUnit.IN;
  return (
    <>
      {unit === 'cm' && (
        <span>
          {cmToIn(form.getFieldValue(['items', name, extraKey]), true)}
          in
        </span>
      )}
      {unit === 'in' && (
        <span>
          {inToCm(form.getFieldValue(['items', name, extraKey]), true)}
          cm
        </span>
      )}
    </>
  );
};

export const WeightItemExtra = ({
  form,
  name,
  extraKey,
}: {
  form: FormInstance;
  name: number;
  extraKey: string;
}) => {
  const unit =
    form.getFieldValue(['items', name, 'weight_unit']) ?? WeightUnit.LBS;
  return (
    <>
      {unit === 'lbs' && (
        <span>
          {lbToKg(form.getFieldValue(['items', name, extraKey]), true)}
          kg
        </span>
      )}
      {unit === 'kg' && (
        <span>
          {kgToLb(form.getFieldValue(['items', name, extraKey]), true)}
          lbs
        </span>
      )}
    </>
  );
};
