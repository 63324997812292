import { Table, Input, Typography, Button } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import type { RecognitionResult, FileStatus } from '../../interface/types';
import { BillingCodeSelect } from '@/components/BillingCodeSelect';

const { Text } = Typography;

interface Props {
  result: RecognitionResult;
  onResultChange?: (key: string, value: any) => void;
  file: FileStatus | null;
}

const ChargesSection = ({ result, onResultChange, file }: Props) => {
  const handleValueChange = (key: string, value: any) => {
    if (onResultChange) {
      if (key.startsWith('items.')) {
        const [_, index, field] = key.split('.');
        const newItems = [...(result?.items || [])];
        newItems[parseInt(index)] = {
          ...newItems[parseInt(index)],
          [field]: value,
        };

        if (field === 'rate' || field === 'qty') {
          const item = newItems[parseInt(index)];
          const rate = parseFloat(item.rate) || 0;
          const qty = parseFloat(item.qty) || 0;
          newItems[parseInt(index)].amount = (rate * qty).toFixed(2);
        }

        if (field === 'amount') {
          newItems[parseInt(index)].rate = '';
          newItems[parseInt(index)].qty = '';
        }

        const total = newItems.reduce((sum, item) => {
          let itemAmount = 0;
          if (item.rate && item.qty) {
            itemAmount = parseFloat(item.rate) * parseFloat(item.qty);
          } else if (item.amount) {
            itemAmount = parseFloat(item.amount);
          }
          return sum + itemAmount;
        }, 0);

        onResultChange('items', newItems);
        onResultChange('total_amount', total.toFixed(2));
      }
    }
  };

  const handleAddItem = () => {
    if (onResultChange) {
      const newItems = [...(result?.items || [])];
      newItems.push({
        code: '',
        name: '',
        description: '',
        rate: '',
        qty: '',
        amount: '',
      });
      onResultChange('items', newItems);
    }
  };

  const handleDeleteItem = (index: number) => {
    if (onResultChange) {
      const newItems = [...(result?.items || [])];
      newItems.splice(index, 1);

      const total = newItems.reduce((sum, item) => {
        let itemAmount = 0;
        if (item.rate && item.qty) {
          itemAmount = parseFloat(item.rate) * parseFloat(item.qty);
        } else if (item.amount) {
          itemAmount = parseFloat(item.amount);
        }
        return sum + itemAmount;
      }, 0);

      onResultChange('items', newItems);
      onResultChange('total_amount', total.toFixed(2));
    }
  };

  return (
    <div style={{ marginBottom: '8px' }}>
      {result.items && result.items.length > 0 && (
        <>
          <div style={{ marginBottom: '16px' }}>
            <div
              style={{
                marginBottom: '8px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Text strong style={{ fontSize: '14px' }}>
                Amount Total
              </Text>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={handleAddItem}
                style={{ marginLeft: '8px' }}
              >
                Add Charge
              </Button>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                marginBottom: '16px',
              }}
            >
              <Input
                disabled
                value={
                  result.total_amount ||
                  result.items
                    .reduce((sum, item) => {
                      const amount =
                        item.rate && item.qty
                          ? parseFloat(item.rate) * parseFloat(item.qty)
                          : parseFloat(item.amount || '0');
                      return sum + amount;
                    }, 0)
                    .toFixed(2)
                }
                style={{
                  width: '200px',
                  textAlign: 'right',
                  fontSize: '16px',
                  fontWeight: 'bold',
                }}
                prefix="$"
              />
            </div>
            <Text
              strong
              style={{
                fontSize: '16px',
                color: '#262626',
                display: 'block',
              }}
            >
              Charges
            </Text>
          </div>

          <div style={{ overflow: 'auto' }}>
            <Table
              dataSource={result.items}
              columns={[
                {
                  title: 'Code',
                  dataIndex: 'code',
                  width: 120,
                  render: (text, record, index) => (
                    <BillingCodeSelect
                      value={text}
                      style={{
                        width: '100%',
                        minWidth: '120px',
                      }}
                      filterModel={(row, value) => {
                        return (
                          row.category == ((file?.invoiceType || '') as string)
                        );
                      }}
                      onSelect={(c) => {
                        if (c) {
                          const newItems = [...(result?.items || [])];
                          newItems[index] = {
                            ...newItems[index],
                            code: c.code,
                            name: c.name,
                          };
                          onResultChange?.('items', newItems);
                        }
                      }}
                    />
                  ),
                },
                {
                  title: 'Name',
                  dataIndex: 'name',
                  width: 150,
                  render: (text, record, index) => (
                    <Input
                      value={text}
                      onChange={(e) =>
                        handleValueChange(`items.${index}.name`, e.target.value)
                      }
                      bordered={false}
                      style={{
                        fontSize: '14px',
                        fontWeight: 500,
                        padding: '4px 8px',
                        border: '1px solid #d9d9d9',
                        borderRadius: '4px',
                        minWidth: '180px',
                      }}
                    />
                  ),
                },
                {
                  title: 'Rate',
                  dataIndex: 'rate',
                  width: 120,
                  render: (text, record, index) => (
                    <Input
                      value={text}
                      type="number"
                      step="0.01"
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value === '' || /^\d*\.?\d*$/.test(value)) {
                          handleValueChange(`items.${index}.rate`, value);
                        }
                      }}
                      onBlur={(e) => {
                        const value = e.target.value;
                        if (value) {
                          handleValueChange(
                            `items.${index}.rate`,
                            Number(value).toFixed(2),
                          );
                        }
                      }}
                      bordered={false}
                      style={{
                        fontSize: '14px',
                        fontWeight: 500,
                        padding: '4px 8px',
                        border: '1px solid #d9d9d9',
                        borderRadius: '4px',
                        minWidth: '100px',
                      }}
                    />
                  ),
                },
                {
                  title: 'Qty',
                  dataIndex: 'qty',
                  width: 100,
                  render: (text, record, index) => (
                    <Input
                      value={text}
                      type="number"
                      step="0.01"
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value === '' || /^\d*\.?\d*$/.test(value)) {
                          handleValueChange(`items.${index}.qty`, value);
                        }
                      }}
                      onBlur={(e) => {
                        const value = e.target.value;
                        if (value) {
                          handleValueChange(
                            `items.${index}.qty`,
                            Number(value).toFixed(2),
                          );
                        }
                      }}
                      bordered={false}
                      style={{
                        fontSize: '14px',
                        fontWeight: 500,
                        padding: '4px 8px',
                        border: '1px solid #d9d9d9',
                        borderRadius: '4px',
                        minWidth: '80px',
                      }}
                    />
                  ),
                },
                {
                  title: 'Amount',
                  dataIndex: 'amount',
                  width: 120,
                  render: (text, record, index) => {
                    const amount =
                      record.rate && record.qty
                        ? (
                            parseFloat(record.rate) * parseFloat(record.qty)
                          ).toFixed(2)
                        : text || '0.00';

                    return (
                      <Input
                        value={amount}
                        type="number"
                        step="0.01"
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value === '' || /^\d*\.?\d*$/.test(value)) {
                            handleValueChange(`items.${index}.amount`, value);
                          }
                        }}
                        onBlur={(e) => {
                          const value = e.target.value;
                          if (value) {
                            handleValueChange(
                              `items.${index}.amount`,
                              Number(value).toFixed(2),
                            );
                          }
                        }}
                        bordered={false}
                        style={{
                          fontSize: '14px',
                          fontWeight: 500,
                          padding: '4px 8px',
                          border: '1px solid #d9d9d9',
                          borderRadius: '4px',
                          minWidth: '100px',
                        }}
                      />
                    );
                  },
                },
                {
                  title: 'Action',
                  key: 'action',
                  width: 80,
                  render: (_, record, index) => (
                    <Button
                      type="text"
                      danger
                      icon={<DeleteOutlined />}
                      onClick={() => handleDeleteItem(index)}
                    />
                  ),
                },
              ]}
              pagination={false}
              size="small"
              bordered
              style={{
                backgroundColor: '#fff',
                minWidth: '700px',
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ChargesSection;
