import { useApp } from '@/utils/useapp';
import { Space, Popconfirm, Tooltip, message, Tag } from 'antd';
import { ShipmentInterface } from '../Interfaces/ShipmentInterface';
import { LiaCarSideSolid } from 'react-icons/lia';
import { useEffect, useMemo } from 'react';
import { isToday } from '@/utils/isToday';

const CarIcon = () => (
  <LiaCarSideSolid
    style={{ fontSize: '1rem', position: 'relative', top: '3px' }}
  />
);

const ApptTag: React.FC<{
  shipment: ShipmentInterface;
}> = ({ shipment }) => {
  if (!shipment.has_delivery_appointment) {
    return <></>;
  }

  return (
    <Tag
      color="blue"
      style={{
        padding: '1px',
        fontSize: '10px',
        display: 'inline',
        margin: '0px',
      }}
    >
      Appt
    </Tag>
  );
};

export default ApptTag;
