import { Typography, Space, Row } from 'antd';
import React, { useMemo } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { get } from 'lodash';

const { Text, Link } = Typography;
interface IFileItemRender {
  file: any;
  handleDownload: (file: any) => void;
}

export const UploadFileItemRender: React.FC<IFileItemRender> = ({
  file,
  handleDownload,
}) => {
  const customProperties = useMemo(() => get(file, 'custom_properties'), [
    file,
  ]);

  return (
    <>
      <Row className="flex justify-between align-center mt-md mb-md">
        <Space>
          <Link>{file.file_name}</Link>
          <Space>
            {get(customProperties, 'creator_name') && (
              <Text>created by {get(customProperties, 'creator_name')}</Text>
            )}
            <Text> at {file.created_at}</Text>
            <Link onClick={() => handleDownload(file)}>
              <DownloadOutlined />
            </Link>
          </Space>
        </Space>
      </Row>
    </>
  );
};
