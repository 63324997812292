import { Col, Form, Input, Row, Switch } from 'antd';
import React from 'react';
import { FormInstance } from 'antd/es/form';

export const DrawerForm: React.FC<{
  form: FormInstance;
}> = ({ form }) => {
  return (
    <div>
      <Form form={form}>
        <Row gutter={10}>
          <Col span={24}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input.TextArea placeholder="Enter Name" rows={6} />
            </Form.Item>
            <Form.Item
              label="Visible on Email"
              name="visible_on_email"
              valuePropName="checked"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Switch />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
