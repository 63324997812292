import React from 'react';

import { ActOverdue } from './ActOverdue';
import { TaskOverdue } from './TaskOverdue';

export const Overdue: React.FC<{
  container: any;
  toIgnoreSpecifiedTasks?: boolean;
}> = ({ container, toIgnoreSpecifiedTasks = false }) => {
  return (
    <div className="flex">
      {/* <DispatchOverdue container={container} /> */}
      <TaskOverdue
        container={container}
        toIgnoreSpecifiedTasks={toIgnoreSpecifiedTasks}
      />
      <ActOverdue container={container} />
    </div>
  );
};
