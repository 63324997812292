import React, { useState } from 'react';
import DrawerForm from './components/DrawerForm';

export const useSpotRateFormDrawer = () => {
  const [quoteId, setQuoteId] = useState<string>('');
  const [visible, setVisible] = useState(false);
  const [isChangeHistory, setIsChangeHistory] = useState(false);

  const closeDrawer = (
    e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
  ) => {
    e.stopPropagation();
    setVisible(false);
    isChangeHistory && window.history.back();
    setIsChangeHistory(false);
  };

  const SpotRateFormDrawer = () => (
    <DrawerForm quoteId={quoteId} visible={visible} onClose={closeDrawer} />
  );

  const openSpotRateFormDrawer = (
    quoteId: string,
    enableChangeHistory?: boolean,
  ) => {
    if (enableChangeHistory) {
      window.history.pushState(null, '', `/rates/spotRate/${quoteId}`);
      setIsChangeHistory(true);
    }
    setQuoteId(quoteId);
    setVisible(true);
  };

  return { SpotRateFormDrawer, openSpotRateFormDrawer };
};
