import styles from 'res/css/ui.scss';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Switch,
} from 'antd';
import { IntermodalRegionSelect } from '@/components/IntermodalRegionSelect';
import React from 'react';
import { handleEnterKeyDown } from '@/utils/handleEnterKeyDown';
import { VendorDivisionSyncSelect } from '@/components/VendorDivisionSyncSelect';

export type TFilter = {
  loading: boolean;
  filter: any;
  onSearch: () => void;
  onChange: (values: any) => void;
};

export const Filter: React.FC<TFilter> = ({
  loading,
  filter,
  onSearch,
  onChange,
}) => {
  const [isShow, setIsShow] = React.useState(false);
  const handleSearch = () => {
    onSearch();
  };

  const handleFilterChange = (key: string, value: any) => {
    onChange({ ...filter, [key]: value });
  };

  const handleReset = () => {
    onChange({});
  };

  return (
    <div className={styles.filter}>
      <div
        style={{
          width: '100%',
        }}
      >
        <Form
          onKeyDown={handleEnterKeyDown(() => handleSearch())}
          layout="vertical"
        >
          <Row gutter={10}>
            <Col span={3}>
              <Form.Item label="Search Name">
                <Input
                  placeholder="Search"
                  value={filter.name || ''}
                  onChange={(e) => handleFilterChange('name', e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="Search">
                <Input
                  placeholder="ID/MC#/DOT#/SCAC#..."
                  value={filter.query || ''}
                  onChange={(e) => handleFilterChange('query', e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="IR">
                <IntermodalRegionSelect
                  value={filter.intermodal_region_id || ''}
                  onChange={(v) =>
                    handleFilterChange('intermodal_region_id', v)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="Division">
                <VendorDivisionSyncSelect
                  editable={false}
                  selected={filter.vendor_division}
                  onSelect={(vendorDivision: any) =>
                    handleFilterChange('vendor_division', vendorDivision)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Rating">
                <Input.Group compact>
                  <Select
                    defaultValue=""
                    value={filter.has_rating}
                    onChange={(v) => handleFilterChange('has_rating', v)}
                  >
                    <Select.Option value="">All</Select.Option>
                    <Select.Option value="has">Has</Select.Option>
                    <Select.Option value="no">No</Select.Option>
                  </Select>
                  <InputNumber
                    style={{ width: 100, textAlign: 'center' }}
                    min={0}
                    max={5}
                    value={filter.rating_min || ''}
                    onChange={(v) => handleFilterChange('rating_min', v)}
                    placeholder="Minimum"
                  />
                  <Input
                    className="site-input-split"
                    style={{
                      width: 30,
                      borderLeft: 0,
                      borderRight: 0,
                      pointerEvents: 'none',
                    }}
                    placeholder="~"
                    disabled
                  />
                  <InputNumber
                    className="site-input-right"
                    min={0}
                    max={5}
                    value={filter.rating_max || ''}
                    onChange={(v) => handleFilterChange('rating_max', v)}
                    style={{
                      width: 100,
                      textAlign: 'center',
                    }}
                    placeholder="Maximum"
                  />
                </Input.Group>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label=" ">
                <Button
                  onClick={() => {
                    setIsShow((isShow) => !isShow);
                  }}
                >
                  {isShow ? 'Hide Filters' : 'Show All Filters'}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label=" ">
                <Space>
                  <Button
                    type="primary"
                    disabled={loading}
                    onClick={handleSearch}
                  >
                    Search
                  </Button>
                  <Button onClick={handleReset}>Clear All</Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>

          {isShow && (
            <Row gutter={10}>
              <Col span={2}>
                <Form.Item label="Has Own Chassis">
                  <Select
                    placeholder="IR"
                    allowClear
                    showSearch
                    value={filter.has_own_chassis || ''}
                    onChange={(v) => handleFilterChange('has_own_chassis', v)}
                    filterOption={(input, option) =>
                      ((option!.children as unknown) as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    <Select.Option value="">All</Select.Option>
                    <Select.Option value="true">Yes</Select.Option>
                    <Select.Option value="false">No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="Has PC Update">
                  <Select
                    placeholder="IR"
                    allowClear
                    showSearch
                    value={filter.has_possible_charges_update || ''}
                    onChange={(v) =>
                      handleFilterChange('has_possible_charges_update', v)
                    }
                    filterOption={(input, option) =>
                      ((option!.children as unknown) as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    <Select.Option value="">All</Select.Option>
                    <Select.Option value="true">Yes</Select.Option>
                    <Select.Option value="false">No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={2}>
                <Form.Item name="no_driver_count" label="No Driver Count">
                  <Select
                    allowClear
                    value={filter.no_driver_count || ''}
                    onChange={(v) => handleFilterChange('no_driver_count', v)}
                  >
                    <Select.Option value="">All</Select.Option>
                    <Select.Option value="true">Yes</Select.Option>
                    <Select.Option value="false">No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name="authority_verified" label="Authority Verified">
                  <Select
                    allowClear
                    value={filter.authority_verified || ''}
                    onChange={(v) =>
                      handleFilterChange('authority_verified', v)
                    }
                  >
                    <Select.Option value="">All</Select.Option>
                    <Select.Option value="true">Yes</Select.Option>
                    <Select.Option value="false">No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name="insurance_verified" label="Insurance Verified">
                  <Select
                    allowClear
                    value={filter.insurance_verified || ''}
                    onChange={(v) =>
                      handleFilterChange('insurance_verified', v)
                    }
                  >
                    <Select.Option value="">All</Select.Option>
                    <Select.Option value="true">Yes</Select.Option>
                    <Select.Option value="false">No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name="w9_verified" label="W9 Verified">
                  <Select
                    allowClear
                    value={filter.w9_verified || ''}
                    onChange={(v) => handleFilterChange('w9_verified', v)}
                  >
                    <Select.Option value="">All</Select.Option>
                    <Select.Option value="true">Yes</Select.Option>
                    <Select.Option value="false">No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name="constract_verified" label="Constract Verified">
                  <Select
                    allowClear
                    value={filter.constract_verified || ''}
                    onChange={(v) =>
                      handleFilterChange('constract_verified', v)
                    }
                  >
                    <Select.Option value="">All</Select.Option>
                    <Select.Option value="true">Yes</Select.Option>
                    <Select.Option value="false">No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name="favorited" label="Favorited">
                  <Select
                    allowClear
                    value={filter.favorited || ''}
                    onChange={(v) => handleFilterChange('favorited', v)}
                  >
                    <Select.Option value="">All</Select.Option>
                    <Select.Option value="true">Yes</Select.Option>
                    <Select.Option value="false">No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name="blacklisted" label="Blacklisted">
                  <Select
                    allowClear
                    value={filter.blacklisted || ''}
                    onChange={(v) => handleFilterChange('blacklisted', v)}
                  >
                    <Select.Option value="">All</Select.Option>
                    <Select.Option value="true">Yes</Select.Option>
                    <Select.Option value="false">No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name="terms" label="Terms">
                  <InputNumber
                    value={filter.terms || ''}
                    onChange={(v) => handleFilterChange('terms', v)}
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name="sent_do_count" label="DO Sent">
                  <InputNumber
                    value={filter.sent_do_count || ''}
                    onChange={(v) => handleFilterChange('sent_do_count', v)}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Form>
      </div>
    </div>
  );
};
