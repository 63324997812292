import { useApp } from '@/utils/useapp';
import { List, Table, message, Popover, Typography } from 'antd';
import { useEffect, useState } from 'react';
import React from 'react';
import type { ColumnsType } from 'antd/lib/table';
import { InfoCircleOutlined } from '@ant-design/icons';
import { TAdmin } from '@/types';
import { TItemProps } from './';
import { get, has } from 'lodash';
import {
  ACTION_STREET_TURN,
  ACTION_UNDO,
} from '@/pages/setting/containerTasks/components/data';
export const TYPE_DISPATCH = 'dispatch';
export const TYPE_CUSTOMER = 'customer';
export const TYPE_AR = 'ar';

const ACTION_RESET_SLA = 'Reset sla';

type TTaskLog = {
  operator: TAdmin;
  task: string;
  action_at: string;
  action: string;
  value: string;
};

const ACTION_LABELS: { [key: string]: string } = {
  [ACTION_RESET_SLA]: 'update due at',
  [ACTION_STREET_TURN]: 'update street turn at',
};

export const TaskLogs: React.FC<TItemProps> = ({ item }) => {
  const app = useApp();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const handleOpenChange = async (open: boolean) => {
    if (!open) {
      return;
    }

    await fetchData();
  };

  const fetchData = async () => {
    if (item.id) {
      setLoading(true);
      app.service
        .get(`containerTaskLogs/${item.id}}`)
        .then((resp) => {
          setData(resp.data);
        })
        .catch((err: any) => {
          message.error(err.data?.message || err.data?.error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Operator',
      key: 'Operator',
      dataIndex: ['operator', 'name'],
    },
    // {
    //   title: 'Task',
    //   key: 'task',
    //   dataIndex: 'task',
    // },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
    },
    {
      title: 'Action At',
      key: 'action_at',
      dataIndex: 'action_at',
    },
  ];

  return (
    <>
      <Popover
        mouseEnterDelay={0.05}
        mouseLeaveDelay={0.05}
        onOpenChange={handleOpenChange}
        content={
          <div
            style={{
              maxWidth: '60vw',
              maxHeight: '50vh',
              overflowY: 'scroll',
            }}>
            <List
              loading={loading}
              dataSource={data}
              renderItem={(taskLog: TTaskLog) => (
                <List.Item>
                  {get(taskLog, 'operator.name', 'System')}{' '}
                  {taskLog.value && (
                    <span>
                      {ACTION_LABELS[taskLog.action] ?? 'update'} to{' '}
                      <strong>{taskLog.value}</strong>
                    </span>
                  )}
                  {!taskLog.value && <strong>{taskLog.action}</strong>}
                  {` at ${taskLog.action_at}`}
                </List.Item>
              )}
            />
          </div>
        }>
        <div className="text-gray cursor-pointer py-sm">
          <InfoCircleOutlined />
        </div>
      </Popover>
    </>
  );
};
