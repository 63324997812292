import React from 'react';
import { Alert, Button, Space } from 'antd';
import request from 'umi-request';

const NewVersion = () => {
  const [isDiff, setIsDiff] = React.useState(false);
  const [version, setVersion] = React.useState('');
  const [focus, setFocus] = React.useState(true);

  const checkVersion = async () => {
    if (isDiff) {
      return;
    }

    try {
      const data = await request.get(
        `${window.location.origin}/version.json?${Date.now()}`,
      );
      const _isDiff = !!version && data.version !== version;

      setIsDiff(_isDiff);
      setVersion(data.version);
    } catch (e: any) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (IS_PROD) {
      const interval = setInterval(() => {
        focus && checkVersion();
      }, 10 * 60 * 1000); // 10 minutes

      const doFocus = () => {
        checkVersion();
        setFocus(true);
      };

      const undoFocus = () => {
        setFocus(false);
      };

      window.addEventListener('focus', doFocus);
      window.addEventListener('blur', undoFocus);

      return () => {
        clearInterval(interval);
        window.removeEventListener('focus', doFocus);
        window.removeEventListener('blur', undoFocus);
      };
    }
  }, [focus, version]);

  return (
    <>
      {isDiff && (
        <Alert
          showIcon
          message="New Version is available. "
          type="info"
          action={
            <Space>
              <Button
                size="small"
                type="ghost"
                onClick={() => window.location.reload()}>
                Refresh
              </Button>
            </Space>
          }
          closable={false}
        />
      )}
    </>
  );
};

export default NewVersion;
