import { Button, Space, Tag } from 'antd';
import { useState } from 'react';
import { AddressVerifierProps, AddressVerifyStatus } from './types';
import { VerifyModal } from './VerifierModal';
export const AddressVerifier = <T extends object>({
  address,
  addressType,
  accessorials,
  referenceId,
  referenceType,
  tag,
  usedReferences,
}: AddressVerifierProps<T>) => {
  const [status, setStatus] = useState<AddressVerifyStatus | null>(null);
  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    setShowModal(true);
  };

  return (
    <>
      <Space>
        <Button
          disabled={false}
          size="small"
          type="primary"
          onClick={handleClick}
        >
          Address Verify
        </Button>
        {status && (
          <Tag
            color={
              status === AddressVerifyStatus.Pass
                ? 'success'
                : status === AddressVerifyStatus.Fail
                ? 'error'
                : 'warning'
            }
          >
            {AddressVerifyStatus[status]}
          </Tag>
        )}
      </Space>
      <VerifyModal
        status={status}
        setStatus={setStatus}
        showModal={showModal}
        setShowModal={setShowModal}
        address={address}
        addressType={addressType}
        accessorials={accessorials}
        referenceId={referenceId}
        referenceType={referenceType}
        tag={tag}
        usedReferences={usedReferences}
      />
    </>
  );
};
