import { useGetResizeTableHeight } from '@/components/AutoResizeTable/useGetResizeTableHeight';
import { Table, TableProps } from 'antd';
import { useEffect, useState } from 'react';

export interface AutoResizeTableProps<T = any> extends TableProps<T> {
  dataSource?: readonly T[] | undefined;
  [k: string]: any;
}

const AutoResizeTable = (props: AutoResizeTableProps) => {
  const [tableHeight, handleGetTableHeight, id] = useGetResizeTableHeight();
  const [activeRowId, setActiveRowId] = useState(0);

  useEffect(() => {
    handleGetTableHeight();
  }, [props.dataSource, props.summary]);

  return (
    <Table
      rowKey="id"
      rowClassName={(record: any) => [record?.id == activeRowId && 'rowActive']}
      onRow={(record: any, rowIndex: any) => {
        return {
          onClick: (event) => {
            setActiveRowId && setActiveRowId(record?.id || 0);
          },
        };
      }}
      {...props}
      id={id}
      scroll={{ ...props.scroll, y: tableHeight }}
    />
  );
};

export default AutoResizeTable;
