import { useApp } from '@/utils/useapp';
import { Button, Popover, Form, Input, message } from 'antd';
import React, { useState } from 'react';
import { get, has } from 'lodash';
import { useForm } from 'antd/lib/form/Form';

export const SellRateCompleteButton: React.FC<{
  requestedRate: any;
  setLoading: (loading: boolean) => void;
  onCompleted: () => void;
}> = ({ requestedRate, setLoading, onCompleted }) => {
  const app = useApp();
  const [form] = useForm();

  const handleSellRateComplete = async () => {
    setLoading(true);
    try {
      await app.service.post(
        `requestedRates/${requestedRate.id}/sellRateComplete`,
      );
      onCompleted();
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveSellRate = async () => {
    const values = await form.getFieldsValue();
    setLoading(true);
    try {
      await app.service.patch(
        `requestedRates/${requestedRate.id}/updateSellRateId`,
        {
          data: values,
        },
      );
      onCompleted();
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Popover
      placement="top"
      content={
        <div className="text-center">
          <Form
            form={form}
            initialValues={{
              sell_rate_id: get(requestedRate, 'sell_rate.uid'),
            }}
            layout="vertical">
            <Form.Item label="Sell Rate" name="sell_rate_id">
              <Input placeholder="Please Input Sell Rate" />
            </Form.Item>
          </Form>
          <Button className="mt-md" type="primary" onClick={handleSaveSellRate}>
            Save
          </Button>
        </div>
      }
      trigger="hover">
      <Button
        disabled={
          (requestedRate.state !== 'created' &&
            requestedRate.state !== 'rfq sent') ||
          requestedRate.sell_rate_completed_at
        }
        size="small"
        type="default"
        onClick={handleSellRateComplete}>
        SR Complete
      </Button>
    </Popover>
  );
};
