import React from 'react';
import { Space, Typography } from 'antd';
import ColorTags from '../../components/ColorTags';
import { FTLShipmentInterface } from '../Interfaces/ShipmentInterface';
import { CopyButton } from '@/components/CopyButton';
import InsuranceIcon from '@/components/Insurance/InsuranceIcon';
import { useApp } from '@/utils/useapp';

interface Props {
  shipment: FTLShipmentInterface;
  handleShow: (record: any) => void;
  fetchData?: () => void;
}

const FtlShipmentIdColumn: React.FC<Props> = ({
  shipment,
  handleShow,
  fetchData,
}) => {
  const app = useApp();

  const handleVendorShipmentClick = async () => {
    if (!shipment.vendor_id) {
      return;
    }
    const id = shipment.vendor_shipment_id;
    if (!id) {
      return;
    }

    try {
      const resp = await app.service.get(`ftl/vendors/${shipment.vendor_id}`);
      const vendor = resp.data;
      if (!vendor.quick_link) {
        return;
      }

      const url =
        vendor.quick_link.includes('$') && vendor.use_shipment_id
          ? vendor.quick_link.replace('$', id)
          : vendor.quick_link;

      window.open(url, '_blank');
    } catch (e) {
      console.error('Failed to open vendor tracking:', e);
    }
  };

  const handleCarrierShipmentClick = async () => {
    if (!shipment.carrier_id) {
      return;
    }
    const id = shipment.pro_number;
    if (!id) {
      return;
    }

    try {
      const resp = await app.service.get(`ftl/carriers/${shipment.carrier_id}`);
      const carrier = resp.data;
      if (!carrier.quick_link) {
        return;
      }

      const url =
        carrier.quick_link.includes('$') && carrier.use_shipment_id
          ? carrier.quick_link.replace('$', id)
          : carrier.quick_link;

      window.open(url, '_blank');
    } catch (e) {
      console.error('Failed to open carrier tracking:', e);
    }
  };

  return (
    <div>
      <Space direction="vertical" size={4} style={{ lineHeight: '1.2' }}>
        <Space size={4}>
          <a onClick={() => handleShow(shipment)}>{shipment.uid}</a>
          <ColorTags
            tlId={shipment.id}
            tags={shipment.tags || []}
            onApply={() => fetchData && fetchData()}
          />
          <CopyButton value={shipment.uid} />
          {shipment.insurance && (
            <InsuranceIcon insurance={shipment.insurance} />
          )}
        </Space>
        <div>
          <Space size={4}>
            {shipment.vendor_shipment_id ? (
              <>
                <Typography.Link onClick={() => handleVendorShipmentClick()}>
                  {shipment.vendor_shipment_id}
                </Typography.Link>
                <CopyButton value={shipment.vendor_shipment_id} />
              </>
            ) : (
              <>&nbsp;</>
            )}
          </Space>
        </div>
        <div>
          <Space size={4}>
            {shipment.pro_number ? (
              <>
                <Typography.Link onClick={() => handleCarrierShipmentClick()}>
                  {shipment.pro_number}
                </Typography.Link>
                <CopyButton value={shipment.pro_number} />
              </>
            ) : (
              <>&nbsp;</>
            )}
          </Space>
        </div>
      </Space>
    </div>
  );
};

export default FtlShipmentIdColumn;
