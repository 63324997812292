import { Badge, TagProps, Tooltip } from 'antd';

import React, { useEffect, useMemo } from 'react';
import { VendorFormW9Drawer } from '../../vendorFormW9s/components/VendorFormW9Drawer';
import { VendorInsuranceDrawer } from '../../vendorInsurances/components/VendorInsuranceDrawer';
import { VendorContractDrawer } from '../../vendorContracts/components/VendorContractDrawer';

const VerifiedTooltip: React.FC<{
  verified: boolean;
  text: any;
  tooltipText: string;
}> = ({ verified, text, tooltipText }) =>
  verified ? (
    <Tooltip title={`${tooltipText} Verified`}>
      <Badge
        size="small"
        count={text}
        style={{ backgroundColor: '#52c41a', marginRight: '2px' }}
      />
    </Tooltip>
  ) : (
    <Tooltip title={`${tooltipText} Not Verified`}>
      <Badge
        size="small"
        count={text}
        style={{ backgroundColor: '#f5222d', marginRight: '2px' }}
      />
    </Tooltip>
  );
export const VendorVerified: React.FC<
{
  vendor: any;
  disabled?: boolean;
  onSaved: () => void;
} & TagProps
> = ({ vendor, disabled = false, onSaved, ...rest }) => {
  if (!vendor || !vendor.id) {
    return null;
  }

  const vendorInsuranceId = useMemo(
    () => vendor.vendor_insurance_id || 0,
    [vendor],
  );
  const vendorContractId = useMemo(
    () => vendor.vendor_contract_id || 0,
    [vendor],
  );
  const vendorFormW9Id = useMemo(() => vendor.vendor_form_w9_id || 0, [vendor]);

  const [openVendorInsurance, setOpenVendorInsurance] = React.useState(false);
  const [openVendorContract, setOpenVendorContract] = React.useState(false);
  const [openVendorFormW9, setOpenVendorFormW9] = React.useState(false);

  const onClickVendorInsurance = () => {
    if (disabled) {
      return;
    }

    setOpenVendorInsurance(true);
  };

  const onSavedVendorInsurance = () => {
    setOpenVendorInsurance(false);
    onSaved();
  };

  const closeVendorInsurance = () => {
    setOpenVendorInsurance(false);
  };

  const onClickVendorContract = () => {
    if (disabled) {
      return;
    }
    setOpenVendorContract(true);
  };

  const onSavedVendorContract = () => {
    setOpenVendorContract(false);
    onSaved();
  };

  const closedVendorContract = () => {
    setOpenVendorContract(false);
  };

  const onClickVendorFormW9 = () => {
    if (disabled) {
      return;
    }
    setOpenVendorFormW9(true);
  };

  const onSavedVendorFormW9 = () => {
    setOpenVendorFormW9(false);
    onSaved();
  };

  const closedVendorFormW9 = () => {
    setOpenVendorFormW9(false);
  };

  return (
    <>
      <VerifiedTooltip
        verified={vendor.authority_verified}
        text={'A'}
        tooltipText="Authority"
      />
      <span
        className={!disabled ? 'cursor-pointer' : ''}
        onClick={onClickVendorInsurance}>
        <VerifiedTooltip
          verified={vendor.insurance_verified}
          text={'I'}
          tooltipText="Insurance"
        />
      </span>
      <span
        className={!disabled ? 'cursor-pointer' : ''}
        onClick={onClickVendorFormW9}>
        <VerifiedTooltip
          verified={vendor.w9_verified}
          text={'W'}
          tooltipText="Form W9"
        />
      </span>
      <span
        className={!disabled ? 'cursor-pointer' : ''}
        onClick={onClickVendorContract}>
        <VerifiedTooltip
          verified={vendor.constract_verified}
          text={'C'}
          tooltipText="Contract"
        />
      </span>

      {openVendorInsurance && (
        <VendorInsuranceDrawer
          visible={openVendorInsurance}
          id={vendorInsuranceId}
          mc_number={vendor.trucker_mc}
          onClose={closeVendorInsurance}
          onSaved={onSavedVendorInsurance}
        />
      )}

      {openVendorContract && (
        <VendorContractDrawer
          visible={openVendorContract}
          id={vendorContractId}
          mc_number={vendor.trucker_mc}
          onClose={closedVendorContract}
          onSaved={onSavedVendorContract}
        />
      )}

      {openVendorFormW9 && (
        <VendorFormW9Drawer
          visible={openVendorFormW9}
          id={vendorFormW9Id}
          mc_number={vendor.trucker_mc}
          onClose={closedVendorFormW9}
          onSaved={onSavedVendorFormW9}
        />
      )}
    </>
  );
};
