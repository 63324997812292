import { useApp } from '@/utils/useapp';
import { message, Card, Empty, Space, Button } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { BuyRateForm } from '@/pages/rates/buyRates/components/buyRateDrawer/BuyRateForm';
import React, { useEffect, useMemo } from 'react';
import { isBuyRate } from '@/components/Rate/OpenBuyRateOrSellRateDrawer';
import { SnapshotCardContent } from '@/pages/orders/components/SnapshotRate/SnapshotCardContent';
import { RateLink } from '@/components/Rate/RateLink';

interface ISellRateFormCard {
  orderId: number;
  containers: Array<any>;
  containerNumber?: string;
}

export const SellRateFormCard: React.FC<ISellRateFormCard> = ({
  orderId,
  containers,
  containerNumber,
}) => {
  return (
    <>
      {containers.map((container) => {
        return (
          <Card title={`Container ${container.number}`} key={container.id}>
            <Space direction="vertical">
              {container.sell_rate && (
                <RateLink
                  rateUid={container.sell_rate?.rate_uid}
                  type="SELL_RATE"
                />
              )}

              <SnapshotCardContent
                snapshotableId={container.id}
                snapshotableType="container"
                colSpan={24}
              />
            </Space>
          </Card>
        );
      })}
    </>
  );
};
