import { ShipmentOrderStatusLabelMap as LTLStatusLabelMap } from '@/pages/truckload/ltl/constants';
import { ShipmentOrderStatusLabelMap as FTLStatusLabelMap } from '@/pages/truckload/ftl/constants';
import { EProfitType } from './constants';
import { ORDER_STATES } from '@/pages/orders/components/data';

export const toShipmentIdText = (type: EProfitType) => {
  if (type === EProfitType.LTL) {
    return 'LTL#';
  } else if (type === EProfitType.FTL) {
    return 'FTL#';
  }
  return 'MBL#';
};

export const toShipmentId = (type: EProfitType, shipment: any) => {
  if (type === EProfitType.LTL) {
    return shipment.uid;
  } else if (type === EProfitType.FTL) {
    return shipment.uid;
  }
  return shipment.mbl_number;
};

export const toShipmentStatus = (type: EProfitType, shipment: any) => {
  if (type === EProfitType.LTL) {
    return shipment.order_status_name;
  } else if (type === EProfitType.FTL) {
    return shipment.order_status_name;
  }
  return shipment.state;
};

export const toShipmentStatusChangedAt = (type: EProfitType, shipment: any) => {
  if (type === EProfitType.LTL) {
    return shipment.order_status_changed_at;
  } else if (type === EProfitType.FTL) {
    return shipment.order_status_changed_at;
  }
  return shipment.state_changed_at;
};

export const toETA = (type: EProfitType, shipment: any) => {
  if (type === EProfitType.LTL) {
    return shipment.estimated_delivery_date;
  } else if (type === EProfitType.FTL) {
    return shipment.estimated_delivery_date;
  }
  return shipment.port_of_discharge_eta;
};

export const toStatusLabelOptions = (type: EProfitType) => {
  if (type === EProfitType.LTL) {
    return Object.entries(LTLStatusLabelMap).map(([key, value]) => ({
      label: value,
      value: key,
    }));
  }
  if (type === EProfitType.FTL) {
    return Object.entries(FTLStatusLabelMap).map(([key, value]) => ({
      label: value,
      value: key,
    }));
  }

  return ORDER_STATES.map((value) => ({
    label: value,
    value,
  }));
};
