import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Divider,
  Drawer,
  message,
  Popconfirm,
  Space,
  Switch,
  Table,
  Tag,
} from 'antd';
import React, { useMemo, useState } from 'react';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { DrawerForm } from './components/DawerForm';
import { useForm } from 'antd/lib/form/Form';
import AutoResizeTable from '@/components/AutoResizeTable';
import { Filter } from './components/Filter';

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [specialRequirement, setSpecialRequirement] = useState<any>(null);
  const [form] = useForm();
  const [filter] = useForm();

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    form.resetFields();
  };

  const handleNewSR = () => {
    showDrawer();
  };

  const fetchData = async ({ page } = { page: 1 }) => {
    setLoading(true);
    try {
      const result = await app.service.get('specialRequirements', {
        params: {
          ...filter.getFieldsValue(),
        },
      });
      setData(result.data);
    } catch (error: any) {
      message.error(error.data?.message || error.data?.error || 'System Error');
    } finally {
      setLoading(false);
    }
  };

  const isNew = useMemo(() => {
    return !specialRequirement;
  }, [specialRequirement]);

  const handleSave = async () => {
    const data = await form.validateFields();
    setLoading(true);
    const formValue = Object.assign({}, data);

    const request = isNew
      ? app.service.post('specialRequirements', { data: formValue })
      : app.service.put(`specialRequirements/${specialRequirement}`, {
        data: formValue,
      });

    request
      .then((resp) => {
        fetchData();
        setSpecialRequirement(null);
        onClose();
      })
      .catch((err) => {
        message.error(err.data?.message || err.data?.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUpdate = async (id: number, values: any) => {
    setLoading(true);
    try {
      const resp = await app.service.put(`specialRequirements/${id}`, {
        data: values,
      });
      const _data = [...data];

      // replace data in collection with new returned data
      const index = _data.findIndex((item) => item.id === id);
      _data[index] = { ..._data[index], ...resp.data };
      setData(_data);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const handleRemove = (id: number) => {
    setLoading(true);
    app.service
      .delete(`specialRequirements/${id}`)
      .then((resp) => {
        fetchData();
      })
      .catch((err) => {
        message.error(err.data?.message || err.data?.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEdit = (id: number) => {
    const item = data.find((item) => item.id === id);
    if (!item) {
      return;
    }
    setVisible(true);
    setSpecialRequirement(id);
    form.setFieldsValue({ ...item });
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = () => {
    fetchData();
  };

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 80,
      render: (text, record, index) => <>{record.id}</>,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: 'Visible On Email',
      dataIndex: 'visible_on_email',
      key: 'visible_on_email',
      width: 200,
      render: (text, record) => {
        return (
          <Switch
            checked={record.visible_on_email}
            onChange={(v) =>
              handleUpdate(record.id, {
                visible_on_email: v,
              })
            }
          />
        );
      },
    },
    {
      align: 'center',
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 150,
      render: (text, record) => (
        <span>
          <a onClick={() => handleEdit(record.id)}>Edit</a>
          <Divider type="vertical" />
          <Popconfirm
            title="Sure to delete?"
            okText="Confirm"
            cancelText="Cancel"
            onConfirm={() => handleRemove(record.id)}>
            <a>Delete</a>
          </Popconfirm>
        </span>
      ),
    },
  ];

  const editale = useMemo(() => {
    return ['admin', 'super-admin'].some((role: string) =>
      app.store.auth.user?.roles.includes(role),
    );
  }, [app.store.auth.user]);

  return (
    <div className={styles.main}>
      {editale ? (
        <>
          {' '}
          <Header
            title="Special Requirements"
            rightElement={
              <div>
                <Button type="primary" onClick={handleNewSR}>
                  New Special Requirement
                </Button>
              </div>
            }></Header>
          <Filter loading={loading} filter={filter} onSearch={handleSearch}>
            {' '}
          </Filter>
          <div>
            <AutoResizeTable
              pagination={{
                defaultPageSize: 25,
              }}
              loading={loading}
              size="small"
              rowKey="id"
              columns={columns}
              dataSource={[...data]}
              scroll={{ x: 1500 }}
              sticky
            />
          </div>
        </>
      ) : (
        <Header title="Only Admin Can Edit Special Requirements"></Header>
      )}
      <Drawer
        title=""
        placement={'right'}
        width={'40%'}
        onClose={onClose}
        destroyOnClose={true}
        open={visible}
        extra={
          <Space>
            <Button onClick={onClose}>Close</Button>
            <Button type="primary" loading={loading} onClick={handleSave}>
              Save
            </Button>
          </Space>
        }>
        <DrawerForm form={form} />
      </Drawer>
    </div>
  );
};

export default Index;
