export const EXCEL_TYPES =
  '.csv,text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel';

export const IMAGE_TYPES = 'image/tiff,image/jpeg,image/png';

export const WORD_TYPES =
  'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';

export const PDF_TYPES = 'application/pdf';

// export const ACCEPT_FILE_TYPES = 'message/rfc822,text/html,application/pdf,image/tiff,image/jpeg,image/png,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const ACCEPT_FILE_TYPES = `message/rfc822,text/html,${PDF_TYPES},${IMAGE_TYPES},${EXCEL_TYPES},${WORD_TYPES}`;
