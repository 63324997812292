import React from 'react';

import { isArray, upperFirst } from 'lodash';

const TimeText: React.FC<{ time: any }> = ({ time }) => {
  if (!time) {
    return null;
  }

  return <span>{isArray(time) ? time.join(' - ') : upperFirst(time)}</span>;
};

export default TimeText;
