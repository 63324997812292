import { ShipmentInterface } from '@/pages/truckload/ltl/shipments/Interfaces/ShipmentInterface';
import { FTLShipmentInterface } from '@/pages/truckload/ftl/shipments/Interfaces/ShipmentInterface';

export interface BillFromProbablyList {
  type: string;
  id: number;
  name: string;
  terms: string;
  selected: boolean;
  value: any;
}

export type DocumentType =
  | 'invoice'
  | 'mbl'
  | 'delivery order'
  | 'arrival notice'
  | 'unknown';
export type InvoiceType = 'DRAY' | 'LTL' | 'FTL';

export interface RecognitionResult {
  invoice_number: string;
  invoice_date: string;
  due_date: string;
  bill_from: string;
  bill_to: string;
  pickup_from: string;
  delivery_to: string;
  container_number: string;
  mbl_number: string;
  terms: string;
  reference_number: string;
  total_amount?: string;
  items: {
    code: string;
    name: string;
    description: string;
    rate: string;
    qty: string;
    amount: string;
  }[];
  bill_from_probably_list: BillFromProbablyList[];
  status: 'pending' | 'processing' | 'success' | 'error';
  invoice_type: InvoiceType;
  bill_from_type?: string;
  bill_from_name?: any;
  bill_from_id?: number;
}

export interface FileStatus {
  fileId: string;
  fileName: string;
  uploadTime: string;
  status: 'uploaded' | 'processing' | 'completed' | 'error';
  errorMessage?: string;
  result?: RecognitionResult;
  documentType?: DocumentType;
  invoiceType?: InvoiceType;
  billCreated?: boolean;
}

export interface ShipmentData {
  id: string;
  shipment_number: string;
  business_status: string;
  vendor_id: string;
  vendor_name: string;
  shipment_model?: ShipmentInterface | FTLShipmentInterface;
  mbl_number?: string;
  cntr_number?: string;
}

export interface ClassifyResult {
  file_type: DocumentType;
  invoice_type?: InvoiceType;
  original_type?: DocumentType;
  original_text?: string;
}

export enum TransportationType {
  DRAY = 'dray',
  LTL = 'ltl',
  FTL = 'ftl',
}

export enum ShipmentTypeId {
  DRAY = 1,
  LTL = 2,
  FTL = 3,
}

export const SHIPMENT_TYPE_MAP: Record<TransportationType, ShipmentTypeId> = {
  [TransportationType.DRAY]: ShipmentTypeId.DRAY,
  [TransportationType.LTL]: ShipmentTypeId.LTL,
  [TransportationType.FTL]: ShipmentTypeId.FTL,
} as const;

export type ShipmentType = keyof typeof SHIPMENT_TYPE_MAP;

export interface DuplicateBillCheck {
  exists: boolean;
  billId?: number;
  billNumber?: string;
}
