import React from 'react';
import { useApp } from '@/utils/useapp';
import { Space, Typography, message } from 'antd';
import { CompanyDrawer } from '@/pages/setting/companies/components/CompanyDrawer';
import { MemoAction } from '@/components/Memo/MemoAction';
import {
  TARGET_COMPANY,
  MEMO_ATTRIBUTE_COMPANY_ACCOUNTING,
} from '@/components/Memo/data';

type TCompanyLinkProps = {
  name: string;
  id: number;
  accountingMemo: string;
  onClose?: () => void;
  onSaved?: (company: any) => void;
};

export const CompanyLink: React.FC<TCompanyLinkProps> = ({
  name,
  id,
  accountingMemo,
  onClose,
  onSaved,
}) => {
  const app = useApp();

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSaved = (company: any) => {
    setOpen(false);
    onSaved && onSaved(company);
  };

  const handleSaveMemo = async (_id: number) => {
    if (!id || !onSaved) {
      return;
    }

    try {
      const resp = await app.service.get(`companies/${id}`);
      onSaved(resp.data);
    } catch (error: any) {
      message.error(error.data?.message || error.data?.error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    onClose && onClose();
  };

  return (
    <>
      <Space>
        <Typography.Link onClick={handleOpen}>{name}</Typography.Link>
        <MemoAction
          initialValue={accountingMemo}
          targetId={id}
          target={TARGET_COMPANY}
          memoAttribute={MEMO_ATTRIBUTE_COMPANY_ACCOUNTING}
          onSaved={handleSaveMemo}
        />
      </Space>

      {open && (
        <>
          <CompanyDrawer
            open={open}
            id={id}
            onSaved={handleSaved}
            onClose={handleClose}
          />
        </>
      )}
    </>
  );
};
