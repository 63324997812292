import { Button, Checkbox, Col, Divider, Tooltip, Row, Space } from 'antd';
import { useState } from 'react';
import type { MenuProps } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { InfoCircleOutlined } from '@ant-design/icons';

type TItem = {
  name: string;
  key: string;
};

export const ManagerFilterItems: React.FC<{
  items: TItem[];
  checkedItems: string[];
  onChange: (checkedItems: string[]) => void;
}> = ({ items, checkedItems, onChange }) => {
  const [show, setShow] = useState(true);

  const handleCheck = (e: any) => {
    const { name, checked } = e.target;
    if (checked) {
      onChange([...checkedItems, name]);
    } else {
      onChange(checkedItems.filter((item) => item !== name));
    }
  };

  const handleOnChange = (checkedValues: any) => {
    onChange(checkedValues);
  };

  const menu: MenuProps['items'] = items.map((item) => {
    return {
      label: (
        <Space key={item.key}>
          <Checkbox
            name={item.key}
            checked={checkedItems.includes(item.key)}
            onChange={handleCheck}
          />
          <div>{item.name}</div>
        </Space>
      ),
      key: item.key,
    };
  });

  return (
    <div>
      <Space>
        <span> Select displayed filter items </span>
        <Tooltip title={'The selected filter items cannot be undisplayed'}>
          <InfoCircleOutlined />
        </Tooltip>
        <Button type="ghost" size="small" onClick={() => setShow(!show)}>
          {show ? 'Hide' : 'Show'}
        </Button>
      </Space>
      {show && (
        <Checkbox.Group
          value={checkedItems}
          style={{ width: '100%' }}
          onChange={handleOnChange}>
          <Row>
            {items.map((item: any, i) => (
              <Col key={i} span={4}>
                <Checkbox value={item.key}>{item.name}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      )}

      <Divider />
    </div>
  );
};
