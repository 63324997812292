import React, { ReactElement, useRef } from 'react';
import Editor from './Editor/Editor';
import { PresetDataDropDown } from './Editor/PresetDataDropDown';

import { Input, Form, Tooltip, Space, Button, Typography } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useSafeState } from 'ahooks';

interface Props {
  presetDataMap?: any;
  info?: any;
  onChange: (model?: any) => void;
  handlePreview?: () => void;
}

export const EmailTemplateEditor: React.FC<Props> = ({
  presetDataMap,
  info,
  onChange,
  handlePreview,
}) => {
  const [subjectCursor, setSubjectCursor] = useSafeState();

  const handleChange = async (event: any) => {
    onChange(event);
  };

  return (
    <>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue, setFieldValue }) => (
          <>
            <div className="flex justify-between mb-md">
              <div>
                <Space>
                  <label>Subject</label>
                  <PresetDataDropDown
                    presetDataMap={presetDataMap}
                    handleClickPresetData={(e: any) => {
                      const subject = getFieldValue('subject')
                        ? getFieldValue('subject')
                        : '';
                      if (e.key) {
                        handleChange({
                          target: {
                            name: 'subject',
                            value: `${
                              subject.slice(0, subjectCursor) +
                              e.key +
                              subject.slice(subjectCursor)
                            }`,
                          },
                        });
                      }
                    }}
                  />
                </Space>
              </div>
              <div>
                {presetDataMap && handlePreview && (
                  <Typography.Link onClick={handlePreview}>
                    Preview
                    <Tooltip
                      placement="topLeft"
                      title={
                        <>
                          Preview the first truck mail details with available
                          mailboxes
                        </>
                      }
                      arrowPointAtCenter>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </Typography.Link>
                )}
              </div>
            </div>
            <Form.Item
              rules={[{ required: true, message: 'Please Input Subject' }]}
              shouldUpdate>
              <Input
                value={info?.subject || ''}
                placeholder="Input Subject"
                onBlur={(e) => {
                  setSubjectCursor(e.target?.selectionStart || 0);
                }}
                onChange={(e) => {
                  handleChange({
                    target: {
                      name: 'subject',
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Form.Item>
          </>
        )}
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: 'Please Input Body' }]}
        label={
          <>
            <div>Body</div>{' '}
          </>
        }
        shouldUpdate>
        <Editor
          presetDataMap={presetDataMap}
          value={info?.body || ''}
          isShowPresetData={true}
          onChange={(value) =>
            handleChange({
              target: {
                name: 'body',
                value: value,
              },
            })
          }
        />
      </Form.Item>
    </>
  );
};
