import {
  CloseOutlined,
  LoadingOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Empty, Modal } from 'antd';
import { ChangeEvent, useState, CSSProperties, useRef, useEffect } from 'react';
import styles from './styles.scss';
import { useApp } from '@/utils/useapp';
import { useDebounceFn } from 'ahooks';
import { UILayoutSearchModalProps, SearchResult } from './types';
import UILayoutSearchModalResultDraySection from './UILayoutSearchModalResultDraySection';
import UILayoutSearchModalResultTLSection from './UILayoutSearchModalResultTLSection';

const modalStyle: CSSProperties = {
  top: '64px',
  width: '560px',
};

const modalBodyStyle: CSSProperties = {
  padding: '12px',
  backgroundColor: '#f0f2f5',
};

const UILayoutSearchModal = (props: UILayoutSearchModalProps) => {
  const app = useApp();

  const [isLoading, setIsLoading] = useState(false);
  const [resultKeyword, setResultKeyword] = useState('');
  const [result, setResult] = useState<SearchResult>();
  const isResultEmpty =
    Object.entries(result ?? {}).filter(
      ([k, v]) => Array.isArray(v) && v.length !== 0,
    ).length === 0;

  const { run } = useDebounceFn(
    (keyword: string) => {
      if (!keyword) {
        return;
      }
      setIsLoading(true);
      app.service
        .get(`shipments/search?keyword=${keyword}`)
        .then((response) => {
          setResult(response);
          setResultKeyword(keyword);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    { wait: 1000 },
  );

  const inputRef = useRef<HTMLInputElement>(null);
  const [keyword, setKeyword] = useState('');
  const handleChangeKeyword = (e: ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
    run(e.target.value);
  };
  const handleClearKeyword = () => {
    setKeyword('');
    setResult(undefined);
    setResultKeyword('');
  };

  // 打开时聚焦
  useEffect(() => {
    if (props.open) {
      // https://github.com/ant-design/ant-design/issues/8668
      // https://github.com/ant-design/ant-design/issues/41239
      // 用 setTimeout 创建一个宏任务，在动画结束后聚焦
      setTimeout(() => {
        inputRef.current?.focus();
      });
    }
  }, [props.open]);

  return (
    <Modal
      style={modalStyle}
      bodyStyle={modalBodyStyle}
      footer={null}
      closable={false}
      {...props}
    >
      <header className={styles.searchBar}>
        <form className={styles.searchBarForm}>
          <label
            className={styles.searchBarSearchIconLabel}
            htmlFor="search-bar-input"
          >
            {isLoading ? (
              <LoadingOutlined className={styles.searchBarSearchIcon} />
            ) : (
              <SearchOutlined className={styles.searchBarSearchIcon} />
            )}
          </label>
          <input
            id="search-bar-input"
            ref={inputRef}
            className={styles.searchBarInput}
            value={keyword}
            onChange={handleChangeKeyword}
            placeholder="Search ..."
            maxLength={64}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
          />
          {keyword && (
            <button
              type="reset"
              onClick={handleClearKeyword}
              title="Clear the query"
              className={styles.searchBarClearButton}
            >
              <CloseOutlined />
            </button>
          )}
        </form>
      </header>
      <div className={styles.searchBarResultList}>
        {isResultEmpty ? (
          <Empty className={styles.searchBarResultListEmpty}></Empty>
        ) : (
          <>
            <UILayoutSearchModalResultDraySection
              list={result!.dray}
              query={resultKeyword}
              onCancel={props.onCancel}
              afterClick={props.afterClick}
            />
            <UILayoutSearchModalResultTLSection
              list={result!.ltl}
              query={resultKeyword}
              type="ltl"
              onCancel={props.onCancel}
              afterClick={props.afterClick}
            />
            <UILayoutSearchModalResultTLSection
              list={result!.ftl}
              query={resultKeyword}
              type="ftl"
              onCancel={props.onCancel}
              afterClick={props.afterClick}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default UILayoutSearchModal;
