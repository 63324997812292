import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Space, Popover, Card } from 'antd';

type IScenarioCard = {
  scenario: any;
};
export const ScenarioCard: React.FC<IScenarioCard> = ({ scenario }) => {
  return (
    <Card
      title={
        <Space>
          {scenario.name}
          {scenario.description && (
            <Popover
              trigger="click"
              placement="bottom"
              content={scenario.description}>
              <InfoCircleOutlined />
            </Popover>
          )}
        </Space>
      }
      className="price-rate-card">
      <div style={{ height: '90%' }}>
        {scenario?.rates?.map((rate: any, idx: number) => (
          <div key={`rate_scenario-${idx}`} className="flex justify-between">
            <small className="mr20">{rate.name}</small>
            <small>
              {rate.rate}
              {rate.original_rate && rate.rate != rate.original_rate && (
                <>
                  {' '}
                  <span className="text-line-through text-gray">
                    {rate.original_rate}
                  </span>
                </>
              )}
            </small>
          </div>
        ))}

        {scenario?.possible_charges
          // ?.filter((c: any) => !isNaN(c.rate))
          ?.map((possibleCharge: any, idx: number) => (
            <div
              key={`rate_scenario_possible_charge-${idx}`}
              className="flex justify-between">
              <small className="mr20">
                {possibleCharge.name}{' '}
                {possibleCharge.explanation && (
                  <Popover
                    trigger="click"
                    placement="bottom"
                    content={possibleCharge.explanation}>
                    <InfoCircleOutlined />
                  </Popover>
                )}
              </small>
              <small>{possibleCharge.rate_text}</small>
            </div>
          ))}
      </div>
      <div className="flex justify-between mt-lg">
        <strong>Total</strong>
        <strong>${scenario?.total_rate}</strong>
      </div>
    </Card>
  );
};
