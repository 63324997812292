import React from 'react';
import { Modal, Button, message } from 'antd';
import { useApp } from '@/utils/useapp';

import { saveAs } from 'file-saver';
import { Excels } from '@/components/Upload/Excels';

const Import: React.FC<{ onUploaded: () => void }> = ({ onUploaded }) => {
  const app = useApp();

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [enableEmptyUpload, setEnableEmptyUpload] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setEnableEmptyUpload(true);
    setIsModalVisible(false);
  };

  const downloadTemplate = async () => {
    try {
      await app.service.download('sellRates/template/download');
    } catch (e: any) {
      message.error('System Error');
    }
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Bulk Upload
      </Button>
      <Modal
        title="Bulk Upload"
        open={isModalVisible}
        onOk={downloadTemplate}
        okText="Download Template"
        onCancel={handleCancel}>
        <Excels
          uploadAction="sellRates/bulk"
          enableEmptyUpload={enableEmptyUpload}
          onUploaded={onUploaded}
        />
      </Modal>
    </>
  );
};

export default Import;
