import { Tag } from 'antd';
import React from 'react';
import { FORM_TYPE_MAP, FORM_TYPE_W8, FORM_TYPE_W9 } from './data';

const FORM_TYPE_COLOR_MAP = {
  [FORM_TYPE_W8]: 'cyan',
  [FORM_TYPE_W9]: 'blue',
};

export const RenderFormTypeTag: React.FC<{ formType: number | null }> = ({
  formType,
}) => {
  if (formType) {
    return (
      <Tag
        color={
          FORM_TYPE_COLOR_MAP[
            formType as unknown as keyof typeof FORM_TYPE_COLOR_MAP
          ]
        }>
        {FORM_TYPE_MAP[formType as unknown as keyof typeof FORM_TYPE_MAP]}
      </Tag>
    );
  } else {
    return <></>;
  }
};
