import {
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Switch,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';

const AUTORAINGEVENT_POD = 1;
const AUTORAINGEVENT_EIR = 2;
const AUTORAINGEVENT_EMPTY_RETURN = 3;

const AUTORAINGEVENT_MAP = {
  [AUTORAINGEVENT_POD]: 'POD',
  [AUTORAINGEVENT_EIR]: 'EIR',
  [AUTORAINGEVENT_EMPTY_RETURN]: 'Empty Return',
};

export const DrawerForm: React.FC<{
  form: FormInstance<any>;
  onSubmit: (values: any) => void;
}> = ({ form, onSubmit }) => {
  const handleAddOneOption = (idx: number) => {
    const questions = [...(form.getFieldValue('questions') || [])];
    questions[idx].options.push({
      text: '',
      point: 0,
    });
    form.setFieldValue('questions', questions);
  };

  const handleDeleteOneOption = (idx: number, oidx: number) => {
    const questions = [...(form.getFieldValue('questions') || [])];
    questions[idx].options.splice(oidx, 1);
    form.setFieldValue('questions', questions);
  };

  const handleAddNewQuestion = () => {
    const questions = [...(form.getFieldValue('questions') || [])];
    questions.push({
      title: '',
      description: '',
      options: [],
    });
    form.setFieldValue('questions', questions);
  };

  const handleDeleteOneQuestion = (idx: number) => {
    const questions = [...(form.getFieldValue('questions') || [])];
    questions.splice(idx, 1);
    form.setFieldValue('questions', questions);
  };

  return (
    <div>
      <Form form={form} onFinish={onSubmit}>
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Row gutter={10}>
          <Col span={24}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input placeholder="Enter Name" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <TextArea rows={6} placeholder="Enter Description" />
            </Form.Item>
          </Col>

          <h3>
            Questions <PlusCircleOutlined onClick={handleAddNewQuestion} />
            <p>
              <strong className="text-strong">
                If you leave the score field empty, we will consider the option
                as Not Applicable (NA).
              </strong>
            </p>
          </h3>

          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              return (
                <Space
                  className="w100"
                  direction="vertical"
                  split={<Divider />}>
                  {(getFieldValue('questions') || []).map(
                    (question: any, idx: number) => (
                      <div className="w100" key={`question_${idx}`}>
                        <Form.Item hidden name={['questions', idx, 'id']}>
                          <Input />
                        </Form.Item>
                        <Form.Item
                          hidden
                          initialValue={{}}
                          name={['questions', idx, 'custom_properties']}>
                          <Input />
                        </Form.Item>
                        <div className="flex justify-end">
                          {!form.getFieldValue(['questions', idx, 'id']) && (
                            <MinusCircleOutlined
                              className="ml-sm"
                              onClick={() => handleDeleteOneQuestion(idx)}
                            />
                          )}
                        </div>
                        <Row>
                          <Col>
                            <Form.Item
                              label="Enabled"
                              name={['questions', idx, 'enabled']}
                              valuePropName="checked">
                              <Switch size="small" />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Col span={24}>
                          <Form.Item
                            label="Title"
                            name={['questions', idx, 'title']}>
                            <Input
                              size="small"
                              disabled={form.getFieldValue([
                                'questions',
                                idx,
                                'id',
                              ])}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            label="Description"
                            name={['questions', idx, 'description']}>
                            <TextArea
                              rows={2}
                              disabled={form.getFieldValue([
                                'questions',
                                idx,
                                'id',
                              ])}
                              size="small"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Row gutter={1} align="middle">
                            <Col span={22}>
                              <h6>
                                Options
                                {!form.getFieldValue([
                                  'questions',
                                  idx,
                                  'id',
                                ]) && (
                                  <PlusCircleOutlined
                                    onClick={() => handleAddOneOption(idx)}
                                  />
                                )}
                              </h6>
                              <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) => {
                                  const options = getFieldValue([
                                    'questions',
                                    idx,
                                    'options',
                                  ]);
                                  return (
                                    <>
                                      {(options || []).map(
                                        (option: any, i: number) => (
                                          <Row key={`option_id_${i}`}>
                                            <Space align="center">
                                              <Form.Item
                                                className="mb-sm"
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: '',
                                                  },
                                                ]}
                                                name={[
                                                  'questions',
                                                  idx,
                                                  'options',
                                                  i,
                                                  'text',
                                                ]}>
                                                <Input
                                                  size="small"
                                                  placeholder="Enter Option"
                                                  disabled={form.getFieldValue([
                                                    'questions',
                                                    idx,
                                                    'id',
                                                  ])}
                                                />
                                              </Form.Item>
                                              <Form.Item
                                                className="mb-sm"
                                                name={[
                                                  'questions',
                                                  idx,
                                                  'options',
                                                  i,
                                                  'point',
                                                ]}>
                                                <InputNumber
                                                  size="small"
                                                  placeholder="Enter score"
                                                  disabled={form.getFieldValue([
                                                    'questions',
                                                    idx,
                                                    'id',
                                                  ])}
                                                />
                                              </Form.Item>
                                              {!form.getFieldValue([
                                                'questions',
                                                idx,
                                                'id',
                                              ]) && (
                                                <MinusCircleOutlined
                                                  className="ml-sm"
                                                  onClick={() =>
                                                    handleDeleteOneOption(
                                                      idx,
                                                      i,
                                                    )
                                                  }
                                                />
                                              )}
                                            </Space>
                                          </Row>
                                        ),
                                      )}
                                    </>
                                  );
                                }}
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            label="Allow Auto Rating"
                            initialValue={false}
                            name={[
                              'questions',
                              idx,
                              'custom_properties',
                              'allowAutoRating',
                            ]}
                            valuePropName="checked">
                            <Switch size="small" />
                          </Form.Item>
                          {form.getFieldValue([
                            'questions',
                            idx,
                            'custom_properties',
                            'allowAutoRating',
                          ]) && (
                            <Form.Item
                              label="Allow Auto Rating"
                              initialValue={false}
                              name={[
                                'questions',
                                idx,
                                'custom_properties',
                                'allowAutoRatingEvent',
                              ]}
                              valuePropName="checked">
                              <Select>
                                {Object.keys(AUTORAINGEVENT_MAP).map(
                                  (key: string) => {
                                    return (
                                      <Select.Option key={key}>
                                        {
                                          AUTORAINGEVENT_MAP[
                                            key as unknown as keyof typeof AUTORAINGEVENT_MAP
                                          ]
                                        }
                                      </Select.Option>
                                    );
                                  },
                                )}
                              </Select>
                            </Form.Item>
                          )}
                        </Col>
                      </div>
                    ),
                  )}
                </Space>
              );
            }}
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};
