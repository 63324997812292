import { CACHE_CONSIGNEE_KEY } from '@/stores/cache';
import React from 'react';
import SyncCacheModelSelect, {
  filterModelFn,
  SyncCacheModelSelectProps,
} from './SyncCacheModelSelect';

const filterModel: filterModelFn = (model: any, value: any) => {
  if (!model.deleted_at) {
    return true;
  }
  if (model.deleted_at && value == model.id) {
    return true;
  }
  return false;
};

export const ConsigneeSyncSelect: React.FC<SyncCacheModelSelectProps> = ({
  ...props
}) => {
  return (
    <SyncCacheModelSelect
      {...props}
      cacheKey={CACHE_CONSIGNEE_KEY}
      renderOption="name"
      filterModel={filterModel}
    />
  );
};
