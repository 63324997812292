export const MAX_VENDOR_SELECTION = 200;

export const STATUS_SUCCESS = 1;
export const STATUS_FAILED = 0;
export const STATUS_OPEN = 2;

export const STATUS_MAP = {
  [STATUS_SUCCESS]: 'SUCCESS',
  [STATUS_FAILED]: 'FAILED',
  [STATUS_OPEN]: 'OPEN',
};

export const REQUESTED_RATE_STATE_CREATED = 'created';
export const REQUESTED_RATE_STATE_RFQ_SENT = 'rfq sent';
export const REQUESTED_RATE_STATE_REJECTED = 'rejected';
export const REQUESTED_RATE_STATE_COMPLETED = 'completed';

export const REQUESTED_RATE_STATES = [
  REQUESTED_RATE_STATE_CREATED,
  REQUESTED_RATE_STATE_RFQ_SENT,
  REQUESTED_RATE_STATE_REJECTED,
  REQUESTED_RATE_STATE_COMPLETED,
];
