import { Input, InputProps, Select } from 'antd';
import { useMemo, useState } from 'react';
import React from 'react';

const EditableInput: React.FC<{
  type?: string;
  defaultValue: string;
  onChange: (a: string) => void;
  onHide?: () => void;
  autoFocus?: boolean;
  disabled?: boolean;
}> = ({
  defaultValue,
  onChange,
  onHide,
  autoFocus = false,
  type = 'text',
  disabled = false,
  ...props
}) => {
  const [currentValue, setCurrenValue] = useState(defaultValue);

  const handleChange = () => {
    if (currentValue !== defaultValue) {
      onChange(currentValue);
    }
    if (onHide) {
      onHide();
    }
  };
  return (
    <Input
      style={{ minWidth: '150px' }}
      disabled={disabled}
      type={type}
      allowClear
      autoFocus={autoFocus}
      onBlur={() => handleChange()}
      onChange={(e) => setCurrenValue(e.target.value)}
      value={currentValue}
      {...props}
    />
  );
};

export const EditableText: React.FC<{
  type?: string;
  value: string;
  onChange: (name: string, value: string) => void;
  name: string;
  onHide?: () => void;
  autoFocus?: boolean;
  disabled?: boolean;
  addonAfter?: React.ReactNode;
}> = ({
  name,
  value,
  onChange,
  onHide,
  autoFocus,
  type = 'text',
  disabled = false,
  ...props
}) => {
  const handleChange = (value: string) => {
    onChange(name, value);
  };

  return (
    <EditableInput
      disabled={disabled}
      type={type}
      onHide={onHide}
      onChange={handleChange}
      defaultValue={value}
      autoFocus={autoFocus}
      {...props}
    />
  );
};

export const EditableSelect: React.FC<{
  value: number | string;
  options: any[];
  onChange: (s: string, v: number | string) => void;
  name: string;
  showSearch?: boolean;
}> = ({ value, options, onChange, name, showSearch = false }) => {
  const handleChange = (value: number | string) => {
    onChange(name, value);
  };

  const isExist = useMemo(() => {
    return !!options.find((o) => o.value == value);
  }, [options, value]);

  return (
    <>
      <Select
        allowClear
        onChange={(v) => handleChange(v)}
        className="w100"
        showSearch={showSearch}
        filterOption={(input: any, option: any) =>
          (option?.children ?? '').toLowerCase()?.includes(input.toLowerCase())
        }
        value={isExist ? value : ''}>
        {options.map((option, index) => (
          <Select.Option key={index} value={option.value}>
            {option.text || option.label || ' '}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};
