import { Tag } from 'antd';
import moment from 'moment';

const Status: React.FC<{ status: number; expireAt: string }> = ({
  status,
  expireAt,
}) => {
  return (
    <>
      {moment(expireAt).isAfter(moment()) && status === 0 && (
        <Tag color="blue">Draft</Tag>
      )}
      {moment(expireAt).isAfter(moment()) && status === 1 && (
        <Tag color="green">Quoted</Tag>
      )}
      {moment(expireAt).isBefore(moment()) && <Tag color="red">Expired</Tag>}
    </>
  );
};

export default Status;
