import React, { useEffect } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Drawer,
  DrawerProps,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Upload,
  UploadProps,
} from 'antd';
import { useApp } from '@/utils/useapp';
import { TerminalsSelect } from '@/components/TerminalsSelect';
import TextArea from 'antd/lib/input/TextArea';
import {
  CloseOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  CopyOutlined,
} from '@ant-design/icons';
import { FacilityTypeSelect } from '@/components/FacilityTypeSelect';
import { CargoTypeSelect } from '@/components/CargoTypeSelect';
import moment from 'moment';
import { IntermodalRegionSelect } from '@/components/IntermodalRegionSelect';
import { BY_FORMULAR, BY_LOCATION } from '../../data';
import { CSVLink } from 'react-csv';

interface Props {
  vendor_id: number;
  contractRate: any;
  visible: boolean;
  onClose?: DrawerProps['onClose'];
  intermodal_region_id: number;
  onSave?: (d: any) => void;
}

const RateRoute: React.FC<{
  index: number;
  onDelete: (index: number, key: string) => void;
}> = ({ index, onDelete }) => {
  return (
    <>
      <h5>
        Route {index + 1}{' '}
        <MinusCircleOutlined onClick={() => onDelete(index, 'routes')} />
      </h5>
      <Row gutter={6}>
        <Col span={8}>
          <Form.Item
            className="w100"
            label="Start"
            name={['routes', index, 'start']}>
            <InputNumber
              style={{
                width: '100%',
              }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            className="w100"
            label="End"
            name={['routes', index, 'end']}>
            <InputNumber
              style={{
                width: '100%',
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={6}>
        <Col span={8}>
          <Form.Item
            className="w100"
            label="Rate Type"
            name={['routes', index, 'rate_type']}>
            <Select>
              <Select.Option value={0}>Fix</Select.Option>
              <Select.Option value={1}>Linear</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={6}>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            return getFieldValue(['routes', index, 'rate_type']) == 0 ? (
              <Col span={8}>
                <Form.Item
                  className="w100"
                  label="Rate"
                  name={['routes', index, 'rate']}>
                  <InputNumber
                    style={{
                      width: '100%',
                    }}
                  />
                </Form.Item>
              </Col>
            ) : (
              <>
                <Col span={8}>
                  <Form.Item
                    className="w100"
                    label="Coefficient"
                    name={['routes', index, 'coefficient']}>
                    <InputNumber
                      style={{
                        width: '100%',
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <Form.Item
                    label={' '}
                    style={{
                      textAlign: 'center',
                    }}>
                    <CloseOutlined />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label={' '}>
                    <Button className="w100">Miles</Button>
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <Form.Item
                    label={' '}
                    style={{
                      textAlign: 'center',
                    }}>
                    <PlusOutlined />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="w100"
                    label="Intercept"
                    name={['routes', index, 'intercept']}>
                    <InputNumber
                      style={{
                        width: '100%',
                      }}
                    />
                  </Form.Item>
                </Col>
              </>
            );
          }}
        </Form.Item>
      </Row>
      <Divider />
    </>
  );
};

const Index: React.FC<Props> = ({
  vendor_id,
  contractRate,
  visible,
  onClose,
  onSave,
}) => {
  const app = useApp();
  const [form] = Form.useForm();

  useEffect(() => {
    if (contractRate) {
      if (contractRate.type == BY_FORMULAR && !contractRate.routes.length) {
        form.setFieldsValue({
          ...contractRate,
          routes: [
            {
              id: '',
              start: 0,
              end: 30,
              rate_type: 0,
              rate: 500,
            },
          ],
        });
      } else {
        form.setFieldsValue({ ...contractRate });
      }
    }
  }, [contractRate]);

  const handleDelete = (index: number, key: string) => {
    const routes = [...form.getFieldValue(key)];
    if (routes.length <= 1) {
      return;
    }
    routes.splice(index, 1);
    form.setFieldValue(key, routes);
  };

  const handleAddRoute = () => {
    const routes = [...form.getFieldValue('routes')];
    const start = routes.length > 0 ? routes[routes.length - 1].end : 0;
    const end = start + 1;
    routes.push({
      id: '',
      start,
      end,
      rate_type: 0,
      rate: 500,
    });
    form.setFieldValue('routes', routes);
  };

  const handleAddLocation = () => {
    const locations = [...(form.getFieldValue('locations') || [])];
    locations.push({
      id: '',
      city_or_zipcode: '',
      fuel_sucharge: '',
      rate: '',
    });
    form.setFieldValue('locations', locations);
  };

  const handleSave = async (generateRates: boolean) => {
    const values = await form.validateFields();
    try {
      const resp = !contractRate
        ? await app.service.post('contractRates', {
          data: { ...values, vendor_id, generateRates },
        })
        : await app.service.put(`contractRates/${contractRate.id}`, {
          data: { ...values, vendor_id, generateRates },
        });

      onSave && onSave(resp.data);
      message.success(`Saved ${generateRates ? '& Rates generated' : ''}`);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    }
  };

  const uploadProps: UploadProps = {
    accept: '.csv',
    showUploadList: false,
    beforeUpload: (file) => {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const text: string = e.target.result;
        const lines = text.split('\r\n');
        lines.splice(0, 1);
        const locations = [];
        for (const line of lines) {
          const ls = line.split(',');
          // console.log(ls);
          locations.push({
            id: '',
            city_or_zipcode: ls[0],
            fuel_surcharge: ls[2],
            rate: ls[1],
          });
        }

        form.setFieldValue('locations', locations);
      };
      reader.readAsText(file);
      // Prevent upload
      return false;
    },
  };

  const applyFuelSurcharge = () => {
    const locations: any[] = [...(form.getFieldValue('locations') || [])];
    const fuelSurcharge = form.getFieldValue('fuel_surcharge');
    for (let i = 0; i < locations.length; i++) {
      locations[i].fuel_surcharge = fuelSurcharge;
    }
    form.setFieldValue('locations', locations);
  };

  return (
    <Drawer
      title="Contract Rate"
      placement="right"
      width="80%"
      open={visible}
      onClose={onClose}
      extra={
        <Space>
          <Button type="primary" onClick={() => handleSave(false)}>
            Save
          </Button>
          <Button type="primary" onClick={() => handleSave(true)}>
            Save & Generate Rates
          </Button>
        </Space>
      }
      destroyOnClose={true}>
      <Form form={form} layout="vertical">
        <Row gutter={8}>
          <Col span={8}>
            <Form.Item
              label="Contrat Rate Name"
              name="name"
              rules={[{ required: true, message: 'Please input Name' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue, setFieldValue }) => (
                <Form.Item
                  label="Fuel Surcharge"
                  name="fuel_surcharge"
                  rules={[
                    { required: true, message: 'Please input Fuel Surcharge!' },
                  ]}>
                  <Input.Group compact>
                    <Input
                      style={{
                        width: `calc(100% - ${
                          contractRate.type == BY_LOCATION ? 32 : 0
                        }px)`,
                      }}
                      type="number"
                      suffix="%"
                      value={getFieldValue('fuel_surcharge') || ''}
                      onChange={(e) => {
                        setFieldValue('fuel_surcharge', e.target.value);
                      }}
                    />
                    {contractRate.type == BY_LOCATION && (
                      <Tooltip title="Apply">
                        <Button
                          icon={<CopyOutlined />}
                          onClick={applyFuelSurcharge}
                        />
                      </Tooltip>
                    )}
                  </Input.Group>
                </Form.Item>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              required
              name="intermodal_region_id"
              label="Intermodal Region">
              <IntermodalRegionSelect />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue, setFieldValue }) => {
                const terminal_ids = getFieldValue('terminal_ids');
                return terminal_ids ? (
                  <Form.Item label="Pickup Terminal" name="terminal_ids">
                    <>
                      <TerminalsSelect
                        btnStyle={{
                          width: '100%',
                        }}
                        btnSize="middle"
                        defaultSelected={getFieldValue('terminal_ids')}
                        onChange={(selected) =>
                          setFieldValue('terminal_ids', selected)
                        }
                        intermodal_region_id={
                          getFieldValue('intermodal_region_id') || 0
                        }
                      />
                    </>
                  </Form.Item>
                ) : (
                  ''
                );
              }}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="from_facility_type"
              label="From Facility Type"
              rules={[{ required: true }]}>
              <FacilityTypeSelect getValueForOption="name" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="to_facility_type"
              label="To Facility Type"
              rules={[{ required: true }]}>
              <FacilityTypeSelect getValueForOption="name" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="cargo_type_id"
              label="Cargo Type"
              rules={[{ required: true }]}>
              <CargoTypeSelect />
            </Form.Item>
          </Col>
          {contractRate.type == BY_FORMULAR && (
            <Col span={8}>
              <Form.Item
                name="to_zipcodes"
                label="Destination Zipcode"
                rules={[{ required: true, message: 'Please input Zipcodes' }]}>
                <TextArea rows={2} />
              </Form.Item>
            </Col>
          )}

          <Col span={8}>
            <Form.Item name="expired_at" noStyle>
              <Input hidden />
            </Form.Item>
            <Form.Item label="Expired at" shouldUpdate>
              {({ getFieldValue, setFieldValue }) => {
                const time = getFieldValue('expired_at');
                return (
                  <DatePicker
                    className="w100"
                    value={time ? moment(time) : null}
                    onChange={(date, dateString) => {
                      setFieldValue('expired_at', dateString);
                    }}
                  />
                );
              }}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="memo" label="Memo">
              <TextArea rows={2} />
            </Form.Item>
          </Col>
        </Row>
        <Divider />

        {contractRate.type == BY_FORMULAR && (
          <>
            <Space>
              <h4>
                Routes <PlusCircleOutlined onClick={() => handleAddRoute()} />
              </h4>
            </Space>
            <Form.Item name="routes" hidden>
              <Input />
            </Form.Item>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => {
                return (getFieldValue('routes') || []).map(
                  (route: any, index: number) => (
                    <RateRoute
                      key={`route-${index}`}
                      index={index}
                      onDelete={handleDelete}
                    />
                  ),
                );
              }}
            </Form.Item>
          </>
        )}
        {contractRate.type == BY_LOCATION && (
          <>
            <Space>
              <h4>
                Rates <PlusCircleOutlined onClick={() => handleAddLocation()} />
              </h4>
              <Space className="ml-lg">
                <CSVLink
                  filename={'template.csv'}
                  data={[
                    {
                      'City Or Zipcode': '',
                      'Base Rate': '',
                      'Fuel Surcharge': '',
                    },
                  ]}>
                  Download template
                </CSVLink>
                <Upload {...uploadProps}>
                  <Button>Upload Template</Button>
                </Upload>
              </Space>
            </Space>
            <Form.Item name="locations" hidden>
              <Input />
            </Form.Item>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => {
                return (
                  <Table
                    columns={[
                      {
                        title: 'City Or Zipcode',
                        dataIndex: 'city_or_zipcode',
                        key: 'city_or_zipcode',
                        width: 80,
                        render: (text, record, index) => (
                          <Form.Item
                            className="mb0"
                            name={['locations', index, 'city_or_zipcode']}>
                            <Input />
                          </Form.Item>
                        ),
                      },
                      {
                        title: 'Base Rate',
                        dataIndex: 'rate',
                        key: 'rate',
                        width: 80,
                        render: (text, record, index) => (
                          <Form.Item
                            className="mb0"
                            name={['locations', index, 'rate']}>
                            <Input type="number" />
                          </Form.Item>
                        ),
                      },
                      {
                        title: 'Fuel Surcharge',
                        dataIndex: 'fuel_sucharge',
                        key: 'fuel_sucharge',
                        width: 80,
                        render: (text, record, index) => (
                          <Form.Item
                            className="mb0"
                            name={['locations', index, 'fuel_surcharge']}>
                            <Input type="number" suffix="%" />
                          </Form.Item>
                        ),
                      },
                      {
                        title: 'Action',
                        dataIndex: 'action',
                        key: 'action',
                        width: 80,
                        render: (text, record, index) => (
                          <a onClick={() => handleDelete(index, 'locations')}>
                            Delete
                          </a>
                        ),
                      },
                    ]}
                    dataSource={getFieldValue('locations') || []}></Table>
                );
              }}
            </Form.Item>
          </>
        )}
      </Form>
    </Drawer>
  );
};

export default Index;
