import { useApp } from '@/utils/useapp';
import { Space } from 'antd';
import React from 'react';

type Props = {
  userId: number;
};

const UserBalance: React.FC<Props> = ({ userId }) => {
  const app = useApp();
  const [userAccount, setUserAccount] = React.useState<any>({});

  const fetchUserAccount = async () => {
    if (!userId) {
      setUserAccount({});
      return;
    }
    const { data } = await app.service.get(
      `userAccounts/userAccountByUserId/${userId}`,
    );
    setUserAccount(data);
  };

  React.useEffect(() => {
    fetchUserAccount();
  }, [userId]);

  return (
    <>
      {userAccount.id ? (
        <Space>
          <div>Prepaid Balance: {userAccount.prepaid_balance}</div>
          <div>Available Balance: {userAccount.available_balance}</div>
          <div>Credit: {userAccount.credit_amount}</div>
          <div>Order Amount: {userAccount.order_amount}</div>
          <div>Settled Amount: {userAccount.settled_amount}</div>
        </Space>
      ) : null}
    </>
  );
};

export default UserBalance;
