export const TYPE_REGULAR = 'regular';
export const TYPE_TRI_AXLE = 'tri-axle';

export const TYPE_MAP = {
  [TYPE_REGULAR]: 'Regular',
  [TYPE_TRI_AXLE]: 'Tri-axle',
};

export const AGENT_OR_HEADQUARTER_HEADQUARTER = 'Headquarter';
export const AGENT_OR_HEADQUARTER_HEADQUARTER_AGENT = 'Agent';

export const AGENT_OR_HEADQUARTER_MAP = {
  [AGENT_OR_HEADQUARTER_HEADQUARTER]: AGENT_OR_HEADQUARTER_HEADQUARTER,
  [AGENT_OR_HEADQUARTER_HEADQUARTER_AGENT]:
    AGENT_OR_HEADQUARTER_HEADQUARTER_AGENT,
};

export enum MinimunInsuranceAmount {
  GENERAL_LIABILITY = 1000000,
  CARGO_INSURANCE = 100000,
  AUTOMOBILE_INSURANCE = 100000,
}
