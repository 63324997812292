const SERIAL_NUMBER_FIRST = 1;
const SERIAL_NUMBER_SECOND = 2;
const SERIAL_NUMBER_THIRD = 3;
const SERIAL_NUMBER_FOURTH = 4;
const SERIAL_NUMBER_FIFTH = 5;
const SERIAL_NUMBER_SIXTH = 6;
const SERIAL_NUMBER_SEVENTH = 7;
const SERIAL_NUMBER_EIGHTH = 8;
const SERIAL_NUMBER_NINTH = 9;

export const SERIAL_NUMBER_MAP = {
  [SERIAL_NUMBER_FIRST]: '1st stop',
  [SERIAL_NUMBER_SECOND]: '2nd stop',
  [SERIAL_NUMBER_THIRD]: '3rd stop',
  [SERIAL_NUMBER_FOURTH]: '4th stop',
  [SERIAL_NUMBER_FIFTH]: '5th stop',
  [SERIAL_NUMBER_SIXTH]: '6th stop',
  [SERIAL_NUMBER_SEVENTH]: '7th stop',
  [SERIAL_NUMBER_EIGHTH]: '8th stop',
  [SERIAL_NUMBER_NINTH]: '9th stop',
};
