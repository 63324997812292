import { Button, Popover, Space, Typography } from 'antd';

import React from 'react';
import { get, upperFirst, has } from 'lodash';
import {
  ACTION_SEND_POD,
  ACTION_BUY_RATE_LINK,
  ACTION_SELL_RATE_LINK,
  ACTION_EDIT_BUY_RATE_ID,
  ACTION_EDIT_SELL_RATE_ID,
  ACTION_SEND_PU_REQUEST,
  ACTION_VENDOR_RATING,
} from './data';
import { TItemProps, TCommonProps, TOnCheckProps, TOnDoneProps } from '../';
import { GeneralAction } from './GeneralAction';
import { SendPodAction } from './SendPodAction/index';
import { SendPURequest } from './SendPURequest';
import { RateLink } from '@/components/Rate/RateLink';
import {
  OPEN_TYPE_BUY_RATE,
  OPEN_TYPE_SELL_RATE,
} from '@/components/Rate/OpenBuyRateOrSellRateDrawer';
import { EditAction } from './EditAction';
import VendorRating from '../../VendorRating';
import { RatingTag } from '@/components/RatingTag';

export const RenderActions: React.FC<
TCommonProps & TItemProps & TOnCheckProps & TOnDoneProps
> = ({ type, item, container, onDone, onCheck }) => {
  if (!item.actions) {
    return <></>;
  }

  if (item.action_at) {
    return (
      <GeneralAction
        item={{ task: item.task, action: 'UNDO' }}
        onCheck={onCheck}
      />
    );
  }

  return (
    <Space>
      {item.actions.map((action: string) => {
        switch (action) {
          case ACTION_SEND_POD:
            return (
              <SendPodAction
                type={type}
                item={{ task: item.task, action }}
                container={container}
                onDone={onDone}
              />
            );
          case ACTION_SELL_RATE_LINK:
            if (has(container, 'sell_rate.rate_uid')) {
              return (
                <RateLink
                  type={OPEN_TYPE_SELL_RATE}
                  snapshotableId={container.id}
                  snapshotableType="container"
                  rateUid={get(container, 'sell_rate.rate_uid')}
                  hasBanModifyFields={true}
                  onSaved={onDone}
                />
              );
            }
            return <></>;
          case ACTION_EDIT_SELL_RATE_ID:
            return (
              <EditAction
                container={container}
                name="sell_rate_id"
                value={get(container, 'sell_rate.rate_uid')}
                placeholder="Input Sell Rate ID"
                onDone={onDone}
              />
            );
          case ACTION_BUY_RATE_LINK:
            if (has(container, 'buy_rate.uid')) {
              return (
                <RateLink
                  type={OPEN_TYPE_BUY_RATE}
                  rateUid={get(container, 'buy_rate.uid')}
                  hasBanModifyFields={true}
                  onSaved={onDone}
                />
              );
            }
            return <></>;
          case ACTION_EDIT_BUY_RATE_ID:
            return (
              <EditAction
                container={container}
                name="buy_rate_id"
                value={get(container, 'buy_rate.uid')}
                placeholder="Input Buy Rate ID"
                onDone={onDone}
              />
            );
          case ACTION_SEND_PU_REQUEST:
            return (
              <SendPURequest
                type={type}
                item={{ task: item.task, action }}
                container={container}
                onDone={onDone}
              />
            );
          case ACTION_VENDOR_RATING:
            return (
              <>
                {container.vendor ? (
                  <Popover
                    trigger="click"
                    placement="left"
                    destroyTooltipOnHide
                    content={
                      <VendorRating
                        vendor_id={container.vendor?.id || 0}
                        score={container.vendor?.score || 'N/A'}
                        container={container}
                        refreshContainer={() => onDone && onDone()}
                      />
                    }>
                    <RatingTag rating={container.vendor?.score || 0} />
                  </Popover>
                ) : (
                  <Typography.Text type="warning">
                    Vendor is required
                  </Typography.Text>
                )}
              </>
            );
          default:
            return (
              <Button
                key={action}
                size="small"
                disabled={item.editable && !get(container, item.editable)}
                onClick={() => {
                  onCheck({ task: item.task, action: action });
                }}>
                {upperFirst(action)}
              </Button>
            );
        }
      })}
    </Space>
  );
};
