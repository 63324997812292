import { Button, Table, Typography } from 'antd';

import { Space } from 'antd';
import { useEffect, useState } from 'react';
import { Address } from './types';
import { useMemoizedFn } from 'ahooks';
import { ColumnType } from 'antd/es/table';

export interface UsedTableProps<T extends object> {
  address: Address;
  columns: ColumnType<T>[];
  getUsedReferences: (address: Address) => Promise<T[]>;
  directLoad?: boolean;
}

export const UsedTable = <T extends object>({
  address,
  getUsedReferences,
  columns,
  directLoad = false,
}: UsedTableProps<T>) => {
  const [usedShipments, setUsedShipments] = useState<T[] | null>(null);
  const [loading, setLoading] = useState(false);
  const getData = useMemoizedFn(async () => {
    setLoading(true);
    try {
      const resp = await getUsedReferences(address);
      setUsedShipments(resp);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  });
  useEffect(() => {
    if (directLoad) {
      getData();
    }
  }, [directLoad]);
  return (
    <Table<T>
      loading={loading}
      title={() => {
        return (
          <Space align="baseline">
            <Typography.Title level={5}>Used References</Typography.Title>
            <Button type="primary" disabled={loading} onClick={getData}>
              Check
            </Button>
            {usedShipments && (
              <span
                style={{ color: usedShipments.length > 0 ? 'green' : 'red' }}
              >
                {usedShipments.length > 0
                  ? `${usedShipments.length} used`
                  : 'No data'}
              </span>
            )}
          </Space>
        );
      }}
      columns={columns}
      dataSource={usedShipments ?? []}
      pagination={false}
    />
  );
};
