import { Form, Row, Col, Input, Button, Space } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React, { ReactElement } from 'react';
import { chain, get, trim, uniq, difference } from 'lodash';

interface IEmailFormProps {
  form: FormInstance;
  sendToElement?: ReactElement;
  sendToExtra?: ReactElement;
  ccExtra?: ReactElement;
  alertMessage?: ReactElement;
  onChange?: (name: string, value: any) => void;
}

export const SendToListAndCCListFormItems: React.FC<IEmailFormProps> = ({
  form,
  sendToElement,
  sendToExtra,
  ccExtra,
  alertMessage,
}) => {
  const handleAddSendToList = () => {
    const sendToList = form.getFieldValue('sendToList') || [];
    sendToList.push('');
    form.setFieldsValue({ sendToList });
  };

  const handleAddCcList = () => {
    const ccList = form.getFieldValue('ccList') || [];
    ccList.push('');
    setFormEmailsUnique('ccList', ccList);
  };

  const setFormEmailsUnique = (target: string, emails: any) => {
    form.setFieldValue(target, uniq(emails));
  };

  const initForm = () => {
    const values = form.getFieldsValue();

    if (!get(values, 'sendToList', false)) {
      values.sendToList = [''];
    }

    if (!get(values, 'ccList', false)) {
      values.ccList = [''];
    }

    if (difference(values, form.getFieldsValue())) {
      form.setFieldsValue(values);
    }
  };

  React.useEffect(() => {
    initForm();
  }, []);

  return (
    <>
      {alertMessage && (
        <>
          {alertMessage} <br />
        </>
      )}

      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => (
          <Form.Item
            name="sendToList"
            rules={[{ required: true, message: 'required' }]}
            label={
              <Space>
                Send To:
                <Button
                  size="small"
                  type="primary"
                  // className='mr-xs'
                  onClick={handleAddSendToList}>
                  +
                </Button>
                {sendToExtra && sendToExtra}
              </Space>
            }>
            {sendToElement
              ? sendToElement
              : getFieldValue('sendToList')?.map(
                (email: string, index: number) => (
                  <Row key={index} gutter={10}>
                    <Col span={19}>
                      <Form.Item
                        style={{ marginBottom: '10px' }}
                        name={['sendToList', index]}
                        rules={[{ required: true, message: 'required' }]}>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md="auto">
                      <Button
                        danger
                        ghost
                        onClick={() => {
                          const sendToList = getFieldValue('sendToList');
                          sendToList.splice(index, 1);
                          form.setFieldsValue({ sendToList });
                        }}>
                          -
                      </Button>
                    </Col>
                  </Row>
                ),
              )}
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => (
          <Form.Item
            name="ccList"
            rules={[{ required: true, message: 'required' }]}
            label={
              <Space>
                CC:
                <Button
                  size="small"
                  type="primary"
                  // className='mr-xs'
                  onClick={handleAddCcList}>
                  +
                </Button>
                {ccExtra && ccExtra}
              </Space>
            }>
            {getFieldValue('ccList')?.map((email: string, index: number) => (
              <Row key={index} gutter={10}>
                <Col span={19}>
                  <Form.Item
                    style={{ marginBottom: '10px' }}
                    name={['ccList', index]}
                    rules={[{ required: true, message: 'required' }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col md="auto">
                  <Button
                    danger
                    ghost
                    onClick={() => {
                      const ccList = getFieldValue('ccList');
                      ccList.splice(index, 1);
                      form.setFieldsValue({ ccList });
                    }}>
                    -
                  </Button>
                </Col>
              </Row>
            ))}
          </Form.Item>
        )}
      </Form.Item>
    </>
  );
};
