import React from 'react';

export const DayFormat: React.FC<{
  possibleCharge: any;
}> = ({ possibleCharge }) => {
  return (
    <small>
      ${(+possibleCharge.rate).toFixed(2)}/Day{' '}
      {possibleCharge.range?.free
        ? `${possibleCharge.range.free} Day Free`
        : ''}
    </small>
  );
};
