import React from 'react';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { useForm } from 'antd/lib/form/Form';
import { Button, Form, InputNumber, message, Popover } from 'antd';
import { useApp } from '@/utils/useapp';
import {
  SellRateDrawer,
  TFormProps,
} from '@/pages/rates/sellRates/components/sellRateDrawer';

const UpdateForSpecificCustomer: React.FC<{
  baseRate: any;
  tollFee: any;
  intermodalRegionId: any;
  city: any;
}> = ({ baseRate, tollFee, intermodalRegionId, city }) => {
  const [chcekForm] = useForm();
  const [form] = useForm();

  const app = useApp();
  const [rateId, setRateId] = React.useState(null);
  const visible = rateId !== null;
  const [showPopover, setShowPopover] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [initialSellRateFormValues, setInitialSellRateFormValues] =
    React.useState<TFormProps>();

  const doContinue = async (values: any) => {
    setLoading(true);
    const resp = await app.service.get('sellRates', {
      params: {
        intermodal_region_id: intermodalRegionId,
        to_city_id: city.id,
        customer_id: values.customer_id,
      },
    });

    const rates = resp.data;

    if (rates.length > 0) {
      message.warning(`${rates[0].uid} found.`);

      setRateId(rates[0].id);
      setShowPopover(false);
    } else {
      setRateId(0);
      setInitialSellRateFormValues({
        base_rate: values.base_rate,
        toll_fee: values.toll_fee,
        intermodal_region_id: intermodalRegionId,
        to_city: city,
        customer_id: values.customer_id,
        visible: true,
      });
      setShowPopover(false);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    chcekForm.setFieldValue('base_rate', baseRate);
  }, [baseRate]);

  React.useEffect(() => {
    chcekForm.setFieldValue('toll_fee', tollFee);
  }, [tollFee]);

  return (
    <>
      <Popover
        trigger="click"
        open={showPopover}
        onOpenChange={setShowPopover}
        content={
          <div style={{ minWidth: '360px' }}>
            <Form form={chcekForm} layout="vertical" onFinish={doContinue}>
              <Form.Item
                label="Base + FSC"
                name="base_rate"
                rules={[{ required: true, message: 'Rate is Required' }]}>
                <InputNumber />
              </Form.Item>
              <Form.Item label="Toll" name="toll_fee">
                <InputNumber />
              </Form.Item>
              <Form.Item
                label="Customer"
                name="customer_id"
                rules={[{ required: true, message: 'Customer is required' }]}>
                <UserSyncSelect type="user" />
              </Form.Item>
              <Button htmlType="submit" loading={loading}>
                Continue
              </Button>
            </Form>
          </div>
        }>
        <Button>Update rate for specific customer</Button>
      </Popover>

      {visible && (
        <SellRateDrawer
          visible={visible}
          id={rateId}
          initialValues={initialSellRateFormValues}
          onClose={() => setRateId(null)}
        />
      )}
    </>
  );
};

export default UpdateForSpecificCustomer;
