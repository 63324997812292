import { Input, Select } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';

export const SEARCHQUERYTYPES = [
  {
    value: '',
    label: 'Default Search',
    placeholder: 'Search by LTL ID/Pro Number#/Reference#',
  },
  {
    value: 'pu',
    label: 'PU Facility Search',
    placeholder: 'Address, State, zip, contact, phone, email, company name',
  },
  {
    value: 'del',
    label: 'DEL Facility Search',
    placeholder: 'Address, State, zip, contact, phone, email, company name',
  },
];

const HeaderSearch: React.FC<{
  disabled?: boolean;
  onSearch: (value: string, searchQueryType: string) => void;
}> = ({ disabled = false, onSearch }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const [searchQueryType, setSearchQueryType] = useState(
    SEARCHQUERYTYPES[0].value,
  );

  const [searchQueryInput, setSearchQueryInput] = useState('');

  const handleInputChange = useCallback(
    debounce((value) => {
      setSearchQuery(value);
    }, 100),
    [],
  );

  const handleSearch = (value: any) => {
    onSearch(value, searchQueryType);
  };

  return (
    <Input.Group compact>
      <Select
        value={searchQueryType}
        onSelect={(value) => {
          setSearchQueryType(value);
        }}
        style={{ width: '140px' }}
      >
        {SEARCHQUERYTYPES.map((type) => (
          <Select.Option key={type.value} value={type.value}>
            {type.label}
          </Select.Option>
        ))}
      </Select>
      <Input.Search
        style={{ width: '320px' }}
        enterButton="Search"
        placeholder={
          SEARCHQUERYTYPES.find((type) => type.value === searchQueryType)
            ?.placeholder
        }
        allowClear
        disabled={disabled}
        value={searchQueryInput}
        onChange={(e) => {
          setSearchQueryInput(e.target.value);
          handleInputChange(e.target.value);
        }}
        onSearch={handleSearch}
      />
    </Input.Group>
  );
};

export default HeaderSearch;
