import React from 'react';
import { useApp } from '@/utils/useapp';
import { Space, Typography, message } from 'antd';
import { UserDrawer } from '@/pages/setting/users/components/UserDrawer';
import { MemoAction } from '@/components/Memo/MemoAction';
import {
  TARGET_USER,
  MEMO_ATTRIBUTE_USER_ACCOUNTING,
} from '@/components/Memo/data';

export const TYPE_USER = 'user';
export const TYPE_VENDOR = 'vendor';

type TUserLinkinkProps = {
  name: string;
  id: number;
  accountingMemo: string;
  onClose?: () => void;
  onSaved?: (user: any) => void;
};

export const UserLink: React.FC<TUserLinkinkProps> = ({
  name,
  id,
  accountingMemo,
  onClose,
  onSaved,
}) => {
  const app = useApp();

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSaved = (user: any) => {
    setOpen(false);
    onSaved && onSaved(user);
  };

  const handleClose = () => {
    setOpen(false);
    onClose && onClose();
  };

  const handleSavedMemo = async () => {
    if (!id || !onSaved) {
      return;
    }

    try {
      const resp = await app.service.get(`users/${id}`);
      onSaved(resp.data);
    } catch (error: any) {
      message.error(error.data?.message || error.data?.error);
    }
  };
  return (
    <>
      <Space>
        <Typography.Link onClick={handleOpen}>{name}</Typography.Link>
        <MemoAction
          initialValue={accountingMemo}
          targetId={id}
          target={TARGET_USER}
          memoAttribute={MEMO_ATTRIBUTE_USER_ACCOUNTING}
          onSaved={handleSavedMemo}
        />
      </Space>

      {open && (
        <>
          <UserDrawer
            open={open}
            id={id}
            onSaved={handleSaved}
            onClose={handleClose}
          />
        </>
      )}
    </>
  );
};
