import React, { useState } from 'react';
import { DispatchTable } from '@/pages/dispatchs/components/Table/DispatchTable';
import { get, has, uniq, isEqual } from 'lodash';
import { useApp } from '@/utils/useapp';
import { message, Spin, TableProps } from 'antd';
import useDispatch from './useDispatch';
import type { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { TCollection } from '@/types';

interface IDispatchTableDrawer<T = any> extends TableProps<T> {
  orderId: number;
  containers?: Array<any>;
  containerNumbers?: Array<string>;
  refreshContainers?: () => void;
}

export const DispatchTableDrawer: React.FC<IDispatchTableDrawer> = ({
  orderId,
  containers = [],
  containerNumbers = [],
  refreshContainers,
  ...props
}) => {
  const app = useApp();
  const { fetchData } = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const [pagination, setPagination] = useState<TablePaginationConfig>();
  const [data, setData] = React.useState<TCollection<any>>();
  const [dataSource, setDataSource] = React.useState<TCollection<any>>();
  const [defaultColumns, setDefaultColumns] = useState<any>([]);

  const onFilterChange = (resp: any) => {
    const oldNumbers = data ? data.data.map((c: any) => c.number) : [];

    if (isEqual(oldNumbers, uniq(containerNumbers))) {
      return;
    }

    if (containerNumbers?.length > 0) {
      setData({
        ...resp,
        data: resp.data.filter((c: any) =>
          containerNumbers?.includes(c.number),
        ),
      });
    } else {
      setData(resp);
    }
  };

  const _fetchData = async (
    _pagination?: TablePaginationConfig,
    params?: any,
  ) => {
    if (loading) {
      return false;
    }
    setLoading(true);

    const _params = {
      ...params,
      order_id: orderId,
      number: uniq([
        ...(containerNumbers || []),
        ...containers.map((c) => c.number),
      ]),
    };
    const resp = await fetchData(pagination, {
      ..._params,
      page: 1,
      per_page: 1000,
    });

    setDataSource(resp);
    setLoading(false);
  };

  React.useEffect(() => {
    if (dataSource?.data.length > 0) {
      onFilterChange(dataSource);
    }
  }, [containerNumbers, dataSource]);

  React.useEffect(() => {
    if (dataSource?.data.length > 0) {
      return;
    }

    (containers.length > 0 || containerNumbers?.length > 0) &&
      orderId &&
      _fetchData();
  }, [containers, containerNumbers, orderId]);

  React.useEffect(() => {
    if (dataSource) {
      refreshContainers && refreshContainers();
    }
  }, [dataSource]);

  const columns: ColumnsType<any> = React.useMemo(() => {
    const _columns: ColumnsType<any> = defaultColumns?.map((c: any) => ({
      ...c,
      sortOrder: null,
    }));

    return _columns;
  }, [defaultColumns]);

  return (
    <>
      <Spin spinning={loading}>
        <DispatchTable
          data={data}
          {...props}
          columns={columns}
          setDefaultColumns={setDefaultColumns}
          setData={setData}
          fetchData={_fetchData}
          pagination={false}
          disabledResize={true}
          onSetPagination={setPagination}
        />
      </Spin>
    </>
  );
};
