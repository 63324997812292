import React from 'react';
import {
  Input,
  AutoComplete,
  message,
  Form,
  Button,
  Spin,
  Space,
  Typography,
  Tooltip,
} from 'antd';
import { debounce } from 'lodash';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import { Header } from '@/components/CommonHeader';
import { InfoCircleOutlined } from '@ant-design/icons';

interface AddressFormData {
  name: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zipcode: string;
}

const CodeAccuracy = () => {
  return (
    <div>
      Code Accuracy
      <br />
      A1 99.5%
      <br />
      A2 75%
      <br />
      A3 70%
      <br />
      A4 65%
      <br />
      U1 75%
      <br />
      U2 50%
      <br />
      U3 40%
      <br />
      U4 30%
    </div>
  );
};

const generateSessionToken = () => {
  return (
    Math.random().toString(36).substring(2) +
    '-' +
    Date.now().toString(36) +
    '-' +
    Math.random().toString(36).substring(2)
  );
};

const ShiplifyAddressForm = () => {
  const app = useApp();
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = React.useState(false);
  const [suggestionsLoading, setSuggestionsLoading] = React.useState(false);
  const [suggestions, setSuggestions] = React.useState<any[]>([]);
  const [shiplifyResult, setShiplifyResult] = React.useState<any>(null);
  const [isLoadingDetails, setIsLoadingDetails] = React.useState(false);
  const [sessionToken, setSessionToken] = React.useState<string>(() =>
    generateSessionToken(),
  );

  const handleClear = () => {
    setShiplifyResult(null);
    form.resetFields();
    setSessionToken(generateSessionToken());
  };

  // Debounced function to fetch address suggestions
  const fetchSuggestions = debounce(async (query: string) => {
    if (!query) {
      setSuggestions([]);
      handleClear();
      return;
    }

    setSuggestionsLoading(true);
    try {
      const response = await app.service.get('address/googleSuggestions', {
        params: {
          query,
          sessionToken,
        },
      });

      const places = response.data.predictions.map((prediction: any) => ({
        place_id: prediction.place_id,
        description: prediction.description,
      }));
      setSuggestions(places);
    } catch (error: any) {
      message.error(
        error.data?.message ||
          error.data?.error ||
          'Failed to fetch suggestions',
      );
      setSessionToken(generateSessionToken());
    } finally {
      setSuggestionsLoading(false);
    }
  }, 500);

  // Handle address selection
  const handleAddressSelect = async (placeId: string) => {
    try {
      setIsLoadingDetails(true);
      form.setFieldsValue({
        street1: '',
      });
      const response = await app.service.get(
        `address/googlePlaceDetails/${placeId}`,
        {
          params: {
            sessionToken,
          },
        },
      );
      const details = response.data;

      form.setFieldsValue({
        street1: details.street1,
        street2: details.street2 || '',
        city: details.city,
        state: details.state,
        zipcode: details.zipcode,
      });

      setSessionToken(generateSessionToken());
    } catch (error: any) {
      message.error(
        error.data?.message ||
          error.data?.error ||
          'Failed to fetch address details',
      );
      setSessionToken(generateSessionToken());
    } finally {
      setIsLoadingDetails(false);
    }
  };

  const handleSubmit = async (values: AddressFormData) => {
    setSubmitting(true);
    try {
      const response = await app.service.get('address/shiplify', {
        params: values,
      });

      setShiplifyResult(response.data);
    } catch (error: any) {
      message.error(
        error.data?.message || error.data?.error || 'Failed to verify address',
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className={styles.main}>
      <Header title="Shiplify Address" />

      <div
        style={{
          maxWidth: '1080px',
          padding: '24px',
          margin: '0 auto',
        }}
      >
        <Spin spinning={isLoadingDetails}>
          <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <Form.Item
              label="Street Address"
              name="street1"
              rules={[
                { required: true, message: 'Please input street address' },
              ]}
            >
              <AutoComplete
                autoFocus
                allowClear
                options={suggestions.map((s) => ({
                  value: s.place_id,
                  label: s.description,
                }))}
                onSearch={fetchSuggestions}
                onSelect={(value) => handleAddressSelect(value)}
                notFoundContent={
                  suggestionsLoading ? <Spin size="small" /> : null
                }
              />
            </Form.Item>

            <Form.Item label="Street Address 2" name="street2">
              <Input />
            </Form.Item>

            <div style={{ display: 'flex', gap: '8px' }}>
              <Form.Item
                label="City"
                name="city"
                style={{ flex: '1' }}
                rules={[{ required: true, message: 'Please input city' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="State"
                name="state"
                style={{ width: '100px' }}
                rules={[{ required: true, message: 'Please input state' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Postal Code"
                name="zipcode"
                style={{ width: '120px' }}
                rules={[
                  { required: true, message: 'Please input postal code' },
                ]}
              >
                <Input />
              </Form.Item>
            </div>

            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit" loading={submitting}>
                  Submit
                </Button>
                <Button onClick={() => form.resetFields()}>Clear</Button>
              </Space>
            </Form.Item>
          </Form>
        </Spin>

        <div className="mt-lg">
          {shiplifyResult && (
            <div>
              <Space align="center">
                <Typography.Title level={5} style={{ marginBottom: 0 }}>
                  Result{' '}
                  <Tooltip title={CodeAccuracy}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </Typography.Title>
                <Button
                  onClick={() => {
                    const address = `${form.getFieldValue(
                      'street1',
                    )}, ${form.getFieldValue('city')}, ${form.getFieldValue(
                      'state',
                    )} ${form.getFieldValue('zipcode')}`;
                    const encodedAddress = encodeURIComponent(address);
                    window.open(
                      `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`,
                      '_blank',
                    );
                  }}
                  size="small"
                >
                  View in Google Maps
                </Button>
              </Space>

              <div className="mt-sm">
                <Space>
                  <div>Location Type:</div>
                  <div>{shiplifyResult.location_types?.values.join(', ')}</div>
                  <div>{shiplifyResult?.location_types?.confidence}</div>
                </Space>
              </div>

              <div>
                <Space>
                  <div>Dock Access:</div>
                  <div>{shiplifyResult.dock_access?.value}</div>
                  <div>{shiplifyResult.dock_access?.confidence}</div>
                </Space>
              </div>

              <div>
                <Space>
                  <div>Fork Lift:</div>
                  <div>{shiplifyResult.forklift?.value}</div>
                  <div>{shiplifyResult.forklift?.confidence}</div>
                </Space>
              </div>
              <div>
                <Space>
                  <div>Tariff:</div>
                  <div>
                    {[
                      ...new Set([
                        ...(shiplifyResult.tariff_items?.shipper?.codes || []),
                        ...(shiplifyResult.tariff_items?.consignee?.codes ||
                          []),
                      ]),
                    ].join(', ')}
                  </div>
                  <div>{shiplifyResult.tariff_items?.confidence}</div>
                </Space>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShiplifyAddressForm;
