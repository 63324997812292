import { action, computed, observable } from 'mobx';

export class OnlineUserStore {
  @observable private _users: string[] = [];

  @action setUsers(_users: string[]) {
    this._users = _users;
  }

  @computed get users() {
    return this._users;
  }
}

const store = new OnlineUserStore();

export default store;
