import { useApp } from '@/utils/useapp';
import { Button, Input, Modal, Spin, Table, TableColumnsType } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import React from 'react';
import classnames from 'classnames';
import { ButtonType } from 'antd/lib/button';
import usePagination from '@/components/usePagination';
import { TCollection } from '@/types';
import { SorterResult } from 'antd/lib/table/interface';
import { TablePaginationConfig } from 'antd/es/table';

interface TTransactionLog {
  op: string;
  prev_prepaid_amount: number;
  prev_balance_amount: number;
  prev_order_amount: number;
  prev_hold_amount: number;
  prev_settled_amount: number;
  current_prepaid_amount: number;
  current_balance_amount: number;
  current_order_amount: number;
  current_hold_amount: number;
  current_settled_amount: number;
  order_number: string;
  container_number: string;
  ltl_shipment_uid: string;
  ftl_shipment_uid: string;
  prepay_order_uid: string;
}

const renderDiff = (v1: number, v2: number) => {
  const diff = v1 - v2;
  return diff ? (
    <>
      <div>{v1.toFixed(2)}</div>
      <div
        className={classnames(
          'ml-sm',
          diff > 0 ? 'text-success' : 'text-danger',
        )}
      >
        ({diff > 0 ? '+' : '-'}
        {Math.abs(diff).toFixed(2)})
      </div>
    </>
  ) : (
    v1.toFixed(2)
  );
};

export const TransactionLog: React.FC<{
  order_id?: number;
  account_id?: number;
  container_id?: number;
  btnType?: ButtonType;
}> = ({ order_id, account_id, container_id, btnType = 'link' }) => {
  const app = useApp();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<TCollection<TTransactionLog>>({
    data: [],
    meta: {
      total: 0,
      per_page: 100,
      current_page: 1,
      last_page: 1,
    },
  });
  const [search, setSearch] = useState<string | undefined>();
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const pagination = usePagination(data);

  const fetchData = async (pagination?: TablePaginationConfig) => {
    setIsLoading(true);
    const resp = await app.service.get('userAccounts/transactionLogs', {
      params: {
        order_id,
        account_id,
        container_id,
        search,
        page: pagination?.current || 1,
        per_page: pagination?.pageSize || 100,
      },
    });

    setData(resp);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isModalVisible) {
      fetchData();
    }
  }, [isModalVisible]);

  const columns = useMemo(() => {
    const columns: TableColumnsType<TTransactionLog> = [
      {
        title: 'Time',
        dataIndex: 'created_at',
        width: 150,
      },
      {
        title: 'Operator',
        dataIndex: ['operator', 'name'],
        width: 80,
      },
      {
        title: 'OP',
        dataIndex: 'op',
        width: 150,
      },
      {
        title: 'Number#',
        render: (record: TTransactionLog) => {
          if (record.container_number) {
            return record.container_number;
          }
          if (record.ltl_shipment_uid) {
            return record.ltl_shipment_uid;
          }
          if (record.ftl_shipment_uid) {
            return record.ftl_shipment_uid;
          }
          if (record.prepay_order_uid) {
            return record.prepay_order_uid;
          }
          return '-';
        },
        width: 150,
      },
      {
        title: 'Available Balance',
        width: 150,
        render: (record: TTransactionLog) =>
          renderDiff(
            +record.current_balance_amount,
            +record.prev_balance_amount,
          ),
      },
      {
        title: 'Prepaid Amount',
        dataIndex: 'current_prepaid_amount',
        width: 150,
        render: (v, record) => renderDiff(+v, +record.prev_prepaid_amount),
      },
      {
        title: 'Prepaid Balance',
        dataIndex: 'current_prepaid_amount',
        width: 150,
        render: (v, record) =>
          renderDiff(
            record.current_prepaid_amount - record.current_settled_amount,
            record.prev_prepaid_amount - record.prev_settled_amount,
          ),
      },
      {
        title: 'Order Amount',
        dataIndex: 'current_order_amount',
        width: 150,
        render: (v, record) => renderDiff(+v, +record.prev_order_amount),
      },
      {
        title: 'Settled',
        dataIndex: 'current_settled_amount',
        width: 150,
        render: (v, record) => renderDiff(+v, +record.prev_settled_amount),
      },
    ];
    return columns;
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    setSearch(inputValue);
  };

  return (
    <>
      <Button type={btnType} onClick={showModal}>
        Logs
      </Button>
      <Modal
        cancelText="Close"
        footer={false}
        width={1400}
        title="Transation Logs"
        open={isModalVisible}
        onCancel={handleCancel}
      >
        <Input.Search
          onChange={handleChange}
          placeholder="Search"
          allowClear
          onSearch={fetchData}
        />
        <br />
        <br />
        <Spin spinning={isLoading} tip="Loading...">
          <Table
            scroll={{
              y: 700,
            }}
            sticky
            pagination={pagination}
            rowKey="id"
            size="small"
            columns={columns}
            dataSource={data?.data || []}
            onChange={fetchData}
          />
        </Spin>
      </Modal>
    </>
  );
};
