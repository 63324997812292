import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { ReactElement, useMemo } from 'react';
import { IntermodalRegionSelect } from './IntermodalRegionSelect';
import { CitySelect } from './CitySelect';
import { CargoTypeSelect } from './CargoTypeSelect';
import { SearchOutlined, CopyOutlined, WarningFilled } from '@ant-design/icons';
import VirtualTable from './VirtualTable';

type TQuoteRoute = {
  id: string;
  intermodal_region_id: number | string;
  city_id: number | string;
  zipcode: string;
  cargo_type_id: number | string;
  rate_id: number | string;
  base_fsc_markup: number;
  base_fsc_markup_type: number;
  description: string;
  miles: number | string;
  base_fsc: string;
  dup?: boolean;
};

export const QuoteRoutes: React.FC<{
  data: TQuoteRoute[];
  rightHeader?: ReactElement | false;
  updateData: (a: TQuoteRoute[]) => void;
  clickAdd?: () => void;
  handleApplySR: (id: number) => void;
  handleOpenRate: (id: string) => void;
  intermodal_region_id?: number;
  extra: ReactElement;
  arrayName?: string;
}> = ({
  data = [],
  rightHeader = false,
  updateData,
  clickAdd,
  extra,
  arrayName = 'quote_routes',
  handleApplySR,
  handleOpenRate,
}) => {
  const handleAddList = () => {
    if (clickAdd instanceof Function) {
      clickAdd();
    }

    updateData([
      ...data,
      {
        id: '',
        intermodal_region_id: '',
        city_id: '',
        miles: '',
        zipcode: '',
        rate_id: '',
        cargo_type_id: '',
        base_fsc: '',
        base_fsc_markup: 0,
        base_fsc_markup_type: 0,
        description: '',
      },
    ]);
  };
  const handleRemove = (index: number) => {
    const copy = [...data];
    copy.splice(index, 1);
    updateData(copy);
  };

  const defaultColumns: ColumnsType<TQuoteRoute> = [
    {
      title: 'IR',
      key: 'ir',
      width: 200,
      render: (text, record, dataIndex) => {
        return (
          <Row align="middle">
            <Col span={20}>
              <Form.Item
                className="mb0"
                name={[arrayName, dataIndex, 'intermodal_region_id']}
                rules={[
                  { required: true, message: 'please input this field' },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (value == 0) {
                        return Promise.reject('IR not found');
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <IntermodalRegionSelect size="small" />
              </Form.Item>
            </Col>
            <Col span={4}>
              {record.dup && (
                <Tooltip title="Duplicate with above route">
                  <WarningFilled style={{ color: '#f4bb42' }} />
                </Tooltip>
              )}
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'City',
      key: 'city',
      width: 200,
      render: (text, record, dataIndex) => {
        return (
          <>
            <Form.Item hidden name={[arrayName, dataIndex, 'city']}>
              <Input size="small" />
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) =>
                prevValues.city_id !== curValues.city_id
              }
            >
              {({ getFieldValue, getFieldsValue, setFieldsValue }) => (
                <Form.Item
                  className="mb0"
                  name={[arrayName, dataIndex, 'city_id']}
                  rules={[
                    { required: true, message: 'please input this field' },
                    () => ({
                      validator(rule, value) {
                        if (value == 0) {
                          return Promise.reject('City not found');
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <CitySelect
                    size="small"
                    selected={getFieldValue([arrayName, dataIndex, 'city'])}
                    onSelect={(v) => {
                      const fields = getFieldsValue();
                      const routes = fields[arrayName];
                      routes[dataIndex].city_id = v?.id;
                      routes[dataIndex].city = v;
                      setFieldsValue({
                        [arrayName]: routes,
                      });
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </>
        );
      },
    },
    {
      title: 'ZipCode',
      key: 'zipcode',
      width: 200,
      render: (text, record, dataIndex) => {
        return (
          <Form.Item
            className="mb0"
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              prevValues.zipcode !== curValues.zipcode
            }
          >
            {({ getFieldsValue, setFieldsValue }) => (
              <Form.Item
                className="mb0"
                name={[arrayName, dataIndex, 'zipcode']}
                rules={[{ required: true, message: 'please input this field' }]}
              >
                <Input
                  size="small"
                  onChange={(v) => {
                    const { value } = v.target;
                    const fields = getFieldsValue();
                    const routes = fields[arrayName];
                    routes[dataIndex].zipcode = value;
                    setFieldsValue({
                      [arrayName]: routes,
                    });
                  }}
                />
              </Form.Item>
            )}
          </Form.Item>
        );
      },
    },
    {
      title: 'Miles',
      key: 'miles',
      width: 100,
      render: (text, record, dataIndex) => {
        return (
          <Form.Item
            className="mb0"
            noStyle
            name={[arrayName, dataIndex, 'miles']}
          >
            <Input size="small" disabled />
          </Form.Item>
        );
      },
    },
    {
      title: 'Cargo Type',
      key: 'cargo_type_id',
      width: 200,
      render: (text, record, dataIndex) => {
        return (
          <Form.Item
            className="mb0"
            name={[arrayName, dataIndex, 'cargo_type_id']}
            rules={[{ required: true, message: 'please input this field' }]}
          >
            <CargoTypeSelect size="small" />
          </Form.Item>
        );
      },
    },
    {
      title: 'Rate ID',
      key: 'rate_id',
      width: 250,
      render: (text, record, dataIndex) => {
        return (
          <Input.Group>
            <Row align="middle">
              <Col span={4}>
                <Tooltip title="Apply DE SR">
                  <Button
                    size="small"
                    onClick={() => handleApplySR(dataIndex)}
                    icon={<CopyOutlined />}
                  />
                </Tooltip>
              </Col>
              <Col span={16}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.rate_id !== curValues.rate_id
                  }
                >
                  {({ getFieldsValue, setFieldsValue }) => (
                    <Form.Item
                      className="mb0"
                      name={[arrayName, dataIndex, 'rate_id']}
                      rules={[
                        { required: true, message: 'please input this field' },
                      ]}
                    >
                      <Input
                        size="small"
                        onChange={(v) => {
                          const { value } = v.target;
                          const fields = getFieldsValue();
                          const routes = fields[arrayName];
                          routes[dataIndex].rate_id = value;
                          setFieldsValue({
                            [arrayName]: routes,
                          });
                        }}
                      />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
              <Col span={4}>
                <Button
                  size="small"
                  onClick={() => handleOpenRate(record.rate_id)}
                  icon={<SearchOutlined />}
                ></Button>
              </Col>
            </Row>
          </Input.Group>
        );
      },
    },
    {
      title: 'Base+FSC',
      key: 'base_fsc',
      width: 120,
      render: (text, record, dataIndex) => {
        return (
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.base_fsc !== curValues.base_fsc
            }
            noStyle
          >
            {({ setFieldsValue, getFieldsValue, getFieldValue }) => (
              <Form.Item
                className="mb0"
                name={[arrayName, dataIndex, 'base_fsc']}
                // rules={[
                //   { required: true, message: 'please input this field' },
                // ]}
              >
                <Input.Group compact>
                  <Input
                    style={{ width: '50%' }}
                    onChange={(v) => {
                      const { value } = v.target;
                      const fields = getFieldsValue();
                      const routes = fields[arrayName];
                      routes[dataIndex].base_fsc = value;
                      setFieldsValue({
                        [arrayName]: routes,
                      });
                    }}
                    value={getFieldValue([arrayName, dataIndex, 'base_fsc'])}
                    size="small"
                  />
                </Input.Group>
              </Form.Item>
            )}
          </Form.Item>
        );
      },
    },
    {
      title: 'Base+FSC Markup',
      key: 'base_fsc_markup',
      width: 280,
      render: (text, record, dataIndex) => {
        return (
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.base_fsc_markup !== curValues.base_fsc_markup
            }
            noStyle
          >
            {({ setFieldValue, getFieldValue }) => (
              <Form.Item
                className="mb0"
                name={[arrayName, dataIndex, 'base_fsc_markup']}
                rules={[{ required: true, message: 'please input this field' }]}
              >
                <Input.Group compact>
                  <Input
                    style={{ width: '50%' }}
                    onChange={(e) => {
                      setFieldValue(
                        [arrayName, dataIndex, 'base_fsc_markup'],
                        e.target.value,
                      );
                    }}
                    value={getFieldValue([
                      arrayName,
                      dataIndex,
                      'base_fsc_markup',
                    ])}
                    size="small"
                  />
                  <Select
                    size="small"
                    onChange={(v) => {
                      setFieldValue(
                        [arrayName, dataIndex, 'base_fsc_markup_type'],
                        v,
                      );
                    }}
                    defaultValue={0}
                    value={getFieldValue([
                      arrayName,
                      dataIndex,
                      'base_fsc_markup_type',
                    ])}
                  >
                    <Select.Option value={0}>%</Select.Option>
                    <Select.Option value={1}>$</Select.Option>
                  </Select>
                </Input.Group>
              </Form.Item>
            )}
          </Form.Item>
        );
      },
    },
    {
      title: 'Description',
      key: 'description',
      width: 200,
      render: (text, record, dataIndex) => {
        return (
          <Form.Item
            name={[arrayName, dataIndex, 'description']}
            className="mb0"
          >
            <Input size="small" />
          </Form.Item>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      fixed: 'right',
      render: (text, record, dataIndex) => {
        return (
          <>
            <a onClick={() => handleRemove(dataIndex)}>delete</a>
          </>
        );
      },
    },
  ];

  const columns = useMemo(() => {
    const _columns = [...defaultColumns];
    return _columns;
  }, [data]);

  const rows = useMemo(() => {
    const exist: any[] = [];
    return data.map((row) => {
      const key = row.intermodal_region_id + '-' + row.city_id;
      if (row.intermodal_region_id && row.city_id && exist.includes(key)) {
        return {
          ...row,
          dup: true,
        };
      } else {
        exist.push(key);
        return row;
      }
    });
  }, [data]);

  return (
    <div>
      <Space style={{ justifyContent: 'space-between', width: '100%' }}>
        <h3>
          Quote Routes <Button onClick={handleAddList}>Add</Button>{' '}
          {extra ? extra : ''}
        </h3>
        <div>{rightHeader}</div>
      </Space>

      <VirtualTable
        height={900}
        size="small"
        columns={columns}
        dataSource={rows}
        pagination={false}
        scroll={{ x: '100%' }}
      />
    </div>
  );
};
