import { Button, Select, Input } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { PickupNumberAgentsDrawer } from '@/pages/entity/pickupNumberAgents/components/PickupNumberAgentsDrawer';
import { CACHE_PICKUP_NUMBER_AGENT_KEY } from '@/stores/cache';
import { useApp } from '@/utils/useapp';
import { useObserver } from 'mobx-react';
import { isEmpty } from 'lodash';

import { SyncCacheModelSelectProps } from './SyncCacheModelSelect';

export const PICKUP_NUMBER_AGENT_MYSELF_ID = 0;
const PICKUP_NUMBER_AGENT_MYSELF = {
  id: PICKUP_NUMBER_AGENT_MYSELF_ID,
  name: 'Myself',
};

type IChassisTypeSyncSelect = {
  pickupNumberAgentId: any;
  onSelect: (rows: Array<any>) => void;
};

export const PickupNumberAgentSyncSelect: React.FC<
IChassisTypeSyncSelect & SyncCacheModelSelectProps
> = ({ pickupNumberAgentId, onSelect, ...props }) => {
  const app = useApp();

  const [id, setId] = React.useState(0);
  const [visible, setVisible] = React.useState(false);
  const [pickupNumberAgents, setPickupNumberAgents] =
    React.useState<Array<any>>();

  const loading = useMemo(
    () => app.store.cache.loadingMap[CACHE_PICKUP_NUMBER_AGENT_KEY] !== false,
    [app.store.cache],
  );

  const handleSaved = useCallback(
    (data: any) => {
      onSelect(data);
      handleClosed();
      app.store.cache.fetch(CACHE_PICKUP_NUMBER_AGENT_KEY, true);
    },
    [pickupNumberAgentId, pickupNumberAgents],
  );

  const handleClosed = () => {
    setVisible(false);
    setId(0);
  };

  const handleOnChange = (value: any, options: any) => {
    onSelect(pickupNumberAgents?.find((p: any) => value == p.id));
  };

  const observeCache = () => {
    useEffect(() => {
      if (undefined == app.store.cache.data[CACHE_PICKUP_NUMBER_AGENT_KEY]) {
        app.store.cache.fetch(CACHE_PICKUP_NUMBER_AGENT_KEY);
      }

      if (app.store.cache.data[CACHE_PICKUP_NUMBER_AGENT_KEY]) {
        setPickupNumberAgents([
          PICKUP_NUMBER_AGENT_MYSELF,
          ...app.store.cache.data[CACHE_PICKUP_NUMBER_AGENT_KEY],
        ]);
      }
    }, [app.store.cache.data[CACHE_PICKUP_NUMBER_AGENT_KEY]]);
  };

  const _props = useMemo(
    () => ({
      ...props,
      value: pickupNumberAgentId,
    }),
    [props, pickupNumberAgentId, pickupNumberAgents],
  );

  return useObserver(() => {
    observeCache();
    return (
      <>
        <Input.Group compact>
          <Button
            type="ghost"
            onClick={() => {
              setId(pickupNumberAgentId || 0);
              setVisible(true);
            }}
            className="ant-btn-icon-only select-modal-icon">
            <span className="icon-gray">
              {' '}
              <EditOutlined />
            </span>
          </Button>
          <Select
            {..._props}
            optionFilterProp={'name'}
            showSearch
            allowClear
            className="select-minus-edit-w"
            optionLabelProp="name"
            onChange={handleOnChange}
            fieldNames={{
              label: 'name',
              value: 'id',
            }}
            options={pickupNumberAgents}
            dropdownStyle={{ zIndex: 10010 }}></Select>
        </Input.Group>
        {visible && (
          <PickupNumberAgentsDrawer
            visible={visible}
            id={id}
            onClose={handleClosed}
            onSaved={handleSaved}
          />
        )}
      </>
    );
  });
};
