import React, { useMemo } from 'react';
import { VList } from 'virtuallist-antd';
import AutoResizeTable from './AutoResizeTable';

const VirtualTable = ({ height, ...props }: { height?: number } & any) => {
  const vc1 = useMemo(() => {
    return VList({
      height: height || 500,
      vid: 'first',
    });
  }, []);

  return (
    <AutoResizeTable
      scroll={{ y: 300, x: '100vw' }}
      components={vc1}
      {...props}
    />
  );
};

export default VirtualTable;
