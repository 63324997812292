import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Divider,
  Drawer,
  message,
  Popconfirm,
  Space,
  Table,
} from 'antd';
import React, { useMemo, useState } from 'react';
import { Header } from '@/components/CommonHeader';
import { Filter } from './components/Filter';
import type { ColumnsType } from 'antd/lib/table';
import { get } from 'lodash';
import { DrawerForm } from './components/DawerForm';
import { useForm } from 'antd/lib/form/Form';
import AutoResizeTable from '@/components/AutoResizeTable';

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [filter, setFilter] = useState<any>({});
  const [data, setData] = useState<any[]>([]);

  const [oceanCarrier, setOceanCarrier] = useState<any>(null);
  const [form] = useForm();
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    form.resetFields();
  };

  const handleNewVendor = () => {
    showDrawer();
  };

  const handleFilterChange = (values: any) => {
    setFilter(values);
  };

  const fetchData = async () => {
    const _filter = Object.assign({}, filter);
    if (_filter.city) {
      _filter.city_id = _filter.city.id;
    }
    delete _filter.city;

    try {
      const result = await app.service.get('oceanCarriers', {
        params: {
          ..._filter,
        },
      });
      setLoading(result);

      setData(result.data);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const isNew = useMemo(() => {
    return !oceanCarrier;
  }, [oceanCarrier]);

  const handleSave = async () => {
    const data = await form.validateFields();

    setLoading(true);
    const formValue = Object.assign({}, data);

    formValue.city_id = get(formValue, 'city.id', 0);
    formValue.other_codes = get(formValue, 'other_codes', []).filter((v) =>
      v?.trim(),
    );
    delete formValue.city;
    const request = isNew
      ? app.service.post('oceanCarriers', { data: formValue })
      : app.service.put(`oceanCarriers/${oceanCarrier}`, { data: formValue });

    request
      .then((resp) => {
        fetchData();
        setOceanCarrier(null);
        onClose();
      })
      .catch((err) => {
        message.error(err.data?.message || err.data?.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRemove = (id: number) => {
    app.service
      .delete(`oceanCarriers/${id}`)
      .then((resp) => {
        fetchData();
      })
      .catch((err) => {
        message.error(err.data?.message || err.data?.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEdit = (id: number) => {
    app.service
      .get(`oceanCarriers/${id}`)
      .then((resp) => {
        setVisible(true);
        setOceanCarrier(id);
        form.setFieldsValue({ ...resp.data });
      })
      .catch((err) => {
        message.error(err.data?.message || err.data?.error);
      });
  };

  const handleSearch = () => {
    fetchData();
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 80,
      render: (text, record, index) => <>{record.id}</>,
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      width: 200,
    },
    {
      title: 'Other Code',
      dataIndex: 'other_codes',
      key: 'other_codes',
      width: 200,
      render: (text, record, index) => <>{text?.join(', ')}</>,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: 'Billing',
      dataIndex: 'billing',
      key: 'billing',
      width: 200,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      width: 200,
    },
    {
      title: 'Contact',
      dataIndex: 'contact',
      key: 'contact',
      width: 200,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 200,
    },
    {
      align: 'center',
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 150,
      render: (text, record) => (
        <span>
          <a onClick={() => handleEdit(record.id)}>Edit</a>
          <Divider type="vertical" />
          <Popconfirm
            title="Sure to delete?"
            okText="Confirm"
            cancelText="Cancel"
            onConfirm={() => handleRemove(record.id)}>
            <a>Delete</a>
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <div className={styles.main}>
      <Header
        title="OceanCarriers"
        rightElement={
          <div>
            <Button type="primary" onClick={handleNewVendor}>
              New OceanCarrier
            </Button>
          </div>
        }></Header>

      <Filter
        loading={loading}
        filter={filter}
        onSearch={handleSearch}
        onChange={handleFilterChange}>
        {' '}
      </Filter>
      <div>
        <AutoResizeTable
          size="small"
          rowKey="id"
          columns={columns}
          onChange={fetchData}
          dataSource={[...data]}
          scroll={{ x: 1500 }}
          pagination={false}
          sticky
        />
      </div>
      <Drawer
        title=""
        placement={'right'}
        width={'80%'}
        onClose={onClose}
        destroyOnClose={true}
        open={visible}
        extra={
          <Space>
            <Button onClick={onClose}>Close</Button>
            <Button type="primary" loading={loading} onClick={handleSave}>
              Save
            </Button>
          </Space>
        }>
        <DrawerForm form={form} isNew={isNew} updateData={setOceanCarrier} />
      </Drawer>
    </div>
  );
};

export default Index;
