import { useApp } from '@/utils/useapp';
import {
  Drawer,
  Space,
  Button,
  Popconfirm,
  Switch,
  message,
  Form,
  Modal,
  Checkbox,
} from 'antd';
import { FormInstance } from 'antd/es/form';
import { useForm } from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import { get, isEmpty, has } from 'lodash';
import React, { useState } from 'react';
import { EmailForm } from './EmailForm';
import { DeliveryOrderForm } from './DeliveryOrderForm';
import { serialize } from 'object-to-formdata';
import { CancelDOModal } from './CancelDoModal';
import { AddressConfirmModal } from './AddressConfirmModal';
import { number } from 'echarts';

export type TSendOptions = {
  container_id: number;
  taskAction: {
    type: string;
    data: { [key: string]: string };
  };
};

const CancelDO: React.FC<{
  id: number;
  deliveryOrder: any;
  onConfirmed: (id: number) => void;
}> = ({ id, deliveryOrder, onConfirmed }) => {
  const app = useApp();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleConfirmed = async () => {
    await onConfirmed(id);
    handleCancel();
  };

  return (
    <>
      <div className="text-gray" onClick={showModal}>
        <Button danger ghost>
          Cancel DO
        </Button>
      </div>
      <CancelDOModal
        DOId={id}
        deliveryOrder={deliveryOrder}
        admin={app.store.auth.account}
        open={isModalVisible}
        onCancel={handleCancel}
        onConfirmed={handleConfirmed}
      />
    </>
  );
};

export const DeliveryOrderDrawer: React.FC<{
  sendOptions?: TSendOptions | undefined;
  doForm: FormInstance;
  selectedCntrs: any[];
  showDoForm: boolean;
  setShowDoForm: any;
  refreshOrder?: (id: number) => void;
  deliveryOrder: any;
  order?: any;
}> = ({
  sendOptions = {},
  selectedCntrs,
  showDoForm,
  setShowDoForm,
  refreshOrder,
  deliveryOrder,
  order,
  doForm,
}) => {
  const app = useApp();
  order = deliveryOrder?.order || order;

  const [loading, setLoading] = useState(false);
  const [withRateCon, setWithRateCon] = useState(false);
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [address, setAddress] = useState<any>(null);
  const [openAddressConfirm, setOpenAddressConfirm] = useState(false);

  const [emailForm] = useForm();

  const showAsPDF = async () => {
    const id = doForm.getFieldValue('id');
    if (!id) {
      return;
    }
    try {
      const resp = await app.service.get(`deliveryOrders/${id}/pdf`, {
        params: { withRateCon },
        responseType: 'blob',
      });
      const file = new Blob([resp], {
        type: 'application/pdf',
      });
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      const w = window.open(fileURL, '_blank');
      if (!w) {
        throw new Error('Please allow popups for this website');
      }
      w.document.title = 'DO#' + id;
    } catch (err: any) {
      message.error(err.data.message);
    }
  };

  const onClose = () => {
    setShowDoForm(false);
    doForm.resetFields();
  };

  const toRefreshOrder = () => {
    refreshOrder && refreshOrder(order.id);
  };

  const handleCanceledDo = async (id: number) => {
    try {
      if (refreshOrder) {
        refreshOrder(order.id);
      }

      setShowDoForm(false);
    } catch (err: any) {
      message.error(err.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveDo = async () => {
    setLoading(true);
    try {
      const values = await doForm.validateFields();
      const _values = Object.assign({}, values);

      _values.vendor_id = get(_values, 'vendor.id', 0);
      _values.terminal_id = get(_values, 'terminal.id', 0);

      if (!_values.multiple_deliveries) {
        _values.warehouse_id = get(_values, 'warehouse.id', 0);
        delete _values.warehouse;
      } else {
        for (const i in _values.multiple_deliveries) {
          if (_values.multiple_deliveries[i].warehouse) {
            _values.multiple_deliveries[i].warehouse_id =
              _values.multiple_deliveries[i].warehouse.id;

            delete _values.multiple_deliveries[i].warehouse;
          }
        }
      }

      _values.return_id = get(_values, 'return.id', 0);
      // data.warehouse_city_id = get(data, 'warehouse_city.id', 0);
      _values.port_of_loading_id = get(_values, 'port_of_loading.id', 0);
      _values.port_of_discharge_id = _values.port_of_discharge_id || 0;
      _values.final_port_id = _values.final_port_id || 0;

      delete _values.vendor;
      delete _values.terminal;
      delete _values.return;
      _values.warehouse_city && delete _values.warehouse_city;
      _values.warehouse_city_zipcode && delete _values.warehouse_city_zipcode;
      delete _values.port_of_loading;
      delete _values.port_of_discharge;
      delete _values.final_port;

      !_values.id
        ? await app.service.post('deliveryOrders', {
            data: { ..._values, order_id: order.id },
          })
        : await app.service.put(`deliveryOrders/${_values.id}`, {
            data: { ..._values, order_id: order.id },
          });
      message.success('Saved');

      toRefreshOrder();
      setShowDoForm(false);
    } catch (err: any) {
      console.log('erro', err);
      message.error(err.data?.message || err.data?.error || 'Check Your Form');
    } finally {
      setLoading(false);
    }
  };

  const downloadAsPDF = async () => {
    const id = doForm.getFieldValue('id');
    if (!id) {
      return;
    }
    try {
      await app.service.download(`deliveryOrders/${id}/pdf`);
    } catch (err: any) {
      message.error(err.data.message);
    }
  };

  const markAsManualSent = async (mask: boolean) => {
    setLoading(true);
    const id = doForm.getFieldValue('id');
    try {
      const resp = await app.service.put(
        `deliveryOrders/${id}/markAsManualSent`,
        {
          data: { mask },
        },
      );
      doForm.setFieldsValue({
        sent_do_admin_id: resp.data.sent_do_admin_id,
      });
    } catch (err: any) {
      message.error(err.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteDo = async (id: number) => {
    if (!id) {
      return;
    }
    setLoading(true);
    try {
      await app.service.delete(`deliveryOrders/${id}`);
      message.success('Delete Successfully');
      setShowDoForm(false);

      toRefreshOrder();
    } catch (err: any) {
      message.error(err.data.message);
    } finally {
      setLoading(false);
    }
  };
  // end delivery order handlers

  // email handlers
  const onCloseEmailDrawer = () => {
    setShowEmailForm(false);
  };

  const handleSendEmail = async () => {
    setLoading(true);
    try {
      const data = await emailForm.validateFields();
      const config = {
        data: serialize(
          { ...data, ...sendOptions },
          { indices: true, nullsAsUndefineds: true },
        ),
        requestType: 'form',
      };

      await app.service.post(`deliveryOrders/${deliveryOrder.id}/send`, config);
      message.success('Sent');
      setShowEmailForm(false);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseAddressConfirm = () => {
    setAddress(null);
    setOpenAddressConfirm(false);
  };
  const handleAddressUpdated = (address: any) => {
    if (has(address, 'pickup_address')) {
      doForm.setFieldValue('pickup_address', address.pickup_address);
    }

    if (has(address, 'delivery_address')) {
      doForm.setFieldValue('delivery_address', address.delivery_address);
    }

    setAddress(null);
    setOpenAddressConfirm(false);
    toRefreshOrder();
  };

  const handleWithRateCon = () => {
    setWithRateCon(!withRateCon);
  };

  const toSyncAddress = () => {
    return false;
    if (!doForm.getFieldValue('id')) {
      return false;
    }

    const values = doForm.getFieldsValue([
      'terminal',
      'pickup_address',
      'warehouse',
      'delivery_address',
    ]);
    const newAddress: any = {};

    const originalPickupAddress = get(values, 'pickup_address');
    const terminalAddress = get(values, 'terminal.delivery_order_address');

    if (terminalAddress && terminalAddress != originalPickupAddress) {
      newAddress.original_pickup_address = originalPickupAddress;
      newAddress.pickup_address = terminalAddress;
    }

    const originalDeliveryAddress = get(values, 'delivery_address');
    const warehouseAddress = get(values, 'warehouse.delivery_order_address');

    if (warehouseAddress && originalDeliveryAddress != warehouseAddress) {
      newAddress.original_delivery_address = originalDeliveryAddress;
      newAddress.delivery_address = warehouseAddress;
    }

    if (isEmpty(newAddress)) {
      return false;
    }

    setAddress(newAddress);
    setOpenAddressConfirm(true);
  };

  // React.useEffect(() => {
  //   console.log(order);
  // }, []);

  React.useEffect(() => {
    toSyncAddress();
  }, [open]);

  return (
    <>
      <Drawer
        // zIndex={1001}
        title="Send Delivery Order Email"
        placement={'right'}
        width={'90%'}
        onClose={onCloseEmailDrawer}
        destroyOnClose={true}
        open={showEmailForm}
        extra={
          <Space>
            <Button onClick={onCloseEmailDrawer}>Close</Button>
            <Button type="primary" loading={loading} onClick={handleSendEmail}>
              Send
            </Button>
          </Space>
        }
      >
        {showEmailForm && (
          <EmailForm
            form={emailForm}
            selectedCntrs={selectedCntrs}
            deliveryOrderId={deliveryOrder?.id || 0}
            admin={app.store.auth.account}
          />
        )}
      </Drawer>
      <Drawer
        // zIndex={1001}
        title={
          doForm.getFieldValue('id') ? (
            <Space>
              DO {doForm.getFieldValue('id')}
              <Button type="primary" ghost onClick={showAsPDF}>
                View PDF
              </Button>
              <Button type="primary" ghost onClick={downloadAsPDF}>
                Download
              </Button>
              <Button
                type="primary"
                ghost
                onClick={() => setShowEmailForm(true)}
              >
                Send Email
              </Button>
              <CancelDO
                id={doForm.getFieldValue('id')}
                deliveryOrder={deliveryOrder}
                onConfirmed={handleCanceledDo}
              />
              <Popconfirm
                placement="topLeft"
                title="Are you sure?"
                onConfirm={() => handleDeleteDo(doForm.getFieldValue('id'))}
                okText="Yes"
                cancelText="No"
              >
                <Button danger>Delete</Button>
              </Popconfirm>
              <div>
                <Switch
                  checked={!!doForm.getFieldValue('sent_do_admin_id')}
                  onChange={(checked) => markAsManualSent(checked)}
                />
                Mark as manual sent
              </div>
              <div>
                <Switch
                  checked={withRateCon}
                  onChange={() => handleWithRateCon()}
                />
                With RateCon
              </div>
            </Space>
          ) : (
            'New Delivery Order'
          )
        }
        placement={'right'}
        width={'90%'}
        onClose={onClose}
        destroyOnClose={true}
        open={showDoForm}
        extra={
          <Space>
            <Button onClick={onClose}>Close</Button>
            <Button type="primary" loading={loading} onClick={handleSaveDo}>
              Save
            </Button>
          </Space>
        }
      >
        {showDoForm && (
          <DeliveryOrderForm
            form={doForm}
            orderId={order.id}
            mblNumber={order.mbl_number}
            containers={selectedCntrs}
            onSaved={toRefreshOrder}
          />
        )}

        {address && openAddressConfirm && (
          <AddressConfirmModal
            deliveryOrderId={doForm.getFieldValue('id')}
            address={address}
            open={openAddressConfirm}
            onClose={handleCloseAddressConfirm}
            onUpdated={handleAddressUpdated}
          />
        )}
      </Drawer>
    </>
  );
};
