import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Divider,
  Drawer,
  message,
  Popconfirm,
  Space,
  Table,
} from 'antd';
import React, { useMemo, useState } from 'react';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { DrawerForm } from './components/DawerForm';
import { useForm } from 'antd/lib/form/Form';
import { GENERAL_LEDGER_CODE_TYPES_MAP } from './components/data';
import { Filter } from './components/Filter';
import Loading from '@/components/Loading';
import AutoResizeTable from '@/components/AutoResizeTable';

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [filter, setFilter] = useState<any>({});
  const [generalLedgerCode, setGeneralLedgeCode] = useState<any>(null);
  const [form] = useForm();
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    form.resetFields();
  };

  const handleNewVendor = () => {
    showDrawer();
  };

  const fetchData = async ({ page } = { page: 1 }) => {
    const _filter = Object.assign({}, filter);
    setLoading(true);
    try {
      const result = await app.service.get('generalLedgerCodes', {
        params: { ..._filter },
      });
      setData(result.data);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const isNew = useMemo(() => {
    return !generalLedgerCode;
  }, [generalLedgerCode]);

  const handleSave = async () => {
    const data = await form.validateFields();

    setLoading(true);
    const formValue = Object.assign({}, data);

    const request = isNew
      ? app.service.post('generalLedgerCodes', { data: formValue })
      : app.service.put(`generalLedgerCodes/${generalLedgerCode}`, {
        data: formValue,
      });

    request
      .then((resp) => {
        fetchData();
        setGeneralLedgeCode(null);
        onClose();
      })
      .catch((err) => {
        message.error(err.data?.message || err.data?.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearch = async () => {
    fetchData();
  };

  const handleFilterChange = (values: any) => {
    setFilter(values);
  };

  const handleRemove = (id: number) => {
    setLoading(true);

    app.service
      .delete(`generalLedgerCodes/${id}`)
      .then((resp) => {
        fetchData();
      })
      .catch((err) => {
        message.error(err.data?.message || err.data?.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEdit = (id: number) => {
    const item = data.find((item) => item.id === id);
    if (!item) {
      return;
    }
    setVisible(true);
    setGeneralLedgeCode(id);
    form.setFieldsValue({ ...item });
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 80,
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      width: 200,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 200,
      render: (text, record) => (
        <>
          {
            GENERAL_LEDGER_CODE_TYPES_MAP[
              record.type as unknown as keyof typeof GENERAL_LEDGER_CODE_TYPES_MAP
            ]
          }
        </>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 200,
    },
    {
      title: '	Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 200,
    },
    {
      align: 'center',
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 150,
      render: (text, record) => (
        <span>
          <a onClick={() => handleEdit(record.id)}>Edit</a>
          <Divider type="vertical" />
          <Popconfirm
            title="Sure to delete?"
            okText="Confirm"
            cancelText="Cancel"
            onConfirm={() => handleRemove(record.id)}>
            <a>Delete</a>
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <div className={styles.main}>
      <Header
        title="General Ledger Codes"
        rightElement={
          <div>
            <Button type="primary" onClick={handleNewVendor}>
              New General LedgerCodes
            </Button>
          </div>
        }></Header>

      <Filter
        loading={loading}
        filter={filter}
        onSearch={handleSearch}
        onChange={handleFilterChange}>
        {' '}
      </Filter>
      <div>
        <AutoResizeTable
          pagination={{
            defaultPageSize: 20,
          }}
          loading={loading}
          size="small"
          rowKey="id"
          columns={columns}
          dataSource={[...data]}
          scroll={{ x: 1500 }}
          sticky
        />
      </div>
      <Drawer
        title=""
        placement="right"
        width="40%"
        onClose={onClose}
        destroyOnClose={true}
        open={visible}
        extra={
          <Space>
            <Button onClick={onClose}>Close</Button>
            <Button type="primary" loading={loading} onClick={handleSave}>
              Save
            </Button>
          </Space>
        }>
        <DrawerForm form={form} isNew={isNew} updateData={generalLedgerCode} />
      </Drawer>
    </div>
  );
};

export default Index;
