import { Popover, Table } from 'antd';
import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Rate',
    dataIndex: 'rate',
    key: 'rate',
  },
  {
    title: 'Qty',
    dataIndex: 'qty',
    key: 'qty',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
];

const RateItemsQuickViewPopover: React.FC<{ items: any }> = ({ items }) => {
  return (
    <Popover
      placement="top"
      className="ml-sm"
      title="Rate Items Quick View"
      trigger="click"
      content={
        <Table
          size="small"
          columns={columns}
          dataSource={items || []}
          pagination={false}
        />
      }>
      <QuestionCircleOutlined />
    </Popover>
  );
};

export default RateItemsQuickViewPopover;
