import { useApp } from '@/utils/useapp';
import { Form, Input, Modal, Table } from 'antd';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import React from 'react';
import { LogActivitiesTableColumns } from './LogActivitiesTableColumns';
import { get, isBoolean, isNumber, isString } from 'lodash';

export const LogActivities: React.FC<{
  id: number;
  icon?: ReactNode;
  type: string;
}> = ({ id, icon, type }) => {
  const app = useApp();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState<string | undefined>();
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (isModalVisible) {
      app.service.get(`logActivities/${type}/${id}`).then((resp) => {
        setData(resp.data);
      });
    }
  }, [isModalVisible]);

  const filterdData = useMemo(() => {
    if (search) {
      const _data = data.filter((record: any) => {
        const resource = Object.keys(
          get(record, 'properties.attributes', []),
        ).map((column) => ({
          column,
          before: get(record, `properties.old.${column}`, null),
          after: get(record, `properties.attributes.${column}`, null),
        }));

        return resource.some((row: any) => {
          return (
            row.column?.toLowerCase()?.includes(search.toLowerCase()) ||
            row.column
              .replace(/_/g, ' ')
              ?.toLowerCase()
              ?.includes(search.toLowerCase()) ||
            (isString(row.before) && row.before?.includes(search)) ||
            (isString(row.after) && row.after?.includes(search)) ||
            (isNumber(row.after) && +row.after == search) ||
            (isNumber(row.after) && +row.before == search)
          );
        });
      });
      return _data;
    } else {
      return data;
    }
  }, [search, data]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    setSearch(inputValue);
  };

  return (
    <>
      <div onClick={showModal}>{icon ? icon : <a> log </a>}</div>
      <Modal
        cancelText="Close"
        footer={false}
        width={1000}
        // style={{
        //   maxHeight: '700px',
        //   overflowY: 'auto',
        // }}
        title="Log Activities"
        open={isModalVisible}
        onCancel={handleCancel}>
        <Input onChange={handleChange} placeholder="Search" />
        <br />
        <Table
          scroll={{
            y: 700,
          }}
          sticky
          pagination={false}
          rowKey="id"
          size="small"
          columns={LogActivitiesTableColumns}
          dataSource={filterdData}
        />
      </Modal>
    </>
  );
};
