import { Space, Tag, Empty, Typography } from 'antd';
import type { FileStatus, ShipmentData } from '../../interface/types';
import { CopyButton } from '@/components/CopyButton';
import { TLType } from '@/components/constants';
import { useState } from 'react';
import LTLShipmentDrawer from '@/pages/truckload/ltl/components/ShipmentDrawer';
import FTLShipmentDrawer from '@/pages/truckload/ftl/components/ShipmentDrawer';
const { Text } = Typography;

interface Props {
  shipmentData?: ShipmentData;
  file: FileStatus | null;
}

const ShipmentInfo = ({ shipmentData, file }: Props) => {
  const [model, setModel] = useState<any>(null);
  const [shipformVisible, setshipformVisible] = useState(false);

  const handleShow = (m: any) => {
    setModel(m);
    setshipformVisible(true);
  };

  return shipmentData ? (
    <div>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Space>
          <Text strong>Shipment No:</Text>
          {file?.invoiceType === TLType.LTL.toUpperCase() ? (
            <>
              <Space>
                <a onClick={() => handleShow(shipmentData.shipment_model)}>
                  {shipmentData.shipment_model?.uid}
                </a>
                <CopyButton
                  value={shipmentData.shipment_model?.uid as string}
                />
              </Space>
              <LTLShipmentDrawer
                visible={shipformVisible}
                model={{
                  ...model,
                  ...shipmentData.shipment_model,
                }}
                onSaved={(id: number) => {
                  setshipformVisible(false);
                }}
                onClose={() => {
                  setshipformVisible(false);
                }}
              />
            </>
          ) : file?.invoiceType === TLType.FTL.toUpperCase() ? (
            <>
              <Space>
                <a onClick={() => handleShow(shipmentData.shipment_model)}>
                  {shipmentData.shipment_model?.uid}
                </a>
                <CopyButton
                  value={shipmentData.shipment_model?.uid as string}
                />
              </Space>
              <FTLShipmentDrawer
                visible={shipformVisible}
                model={{
                  ...model,
                  ...shipmentData.shipment_model,
                }}
                onSaved={() => {
                  setshipformVisible(false);
                }}
                onClose={() => {
                  setshipformVisible(false);
                }}
              />
            </>
          ) : (
            <Text>{shipmentData.shipment_number}</Text>
          )}
        </Space>
        <Space>
          <Text strong>Status:</Text>
          <Tag
            color={
              shipmentData.business_status === 'Delivered' ? 'green' : 'blue'
            }
          >
            {shipmentData.business_status}
          </Tag>
        </Space>
        {file?.invoiceType === 'DRAY' && (
          <>
            <Space>
              <Text strong>MBL No:</Text>
              <Text>{shipmentData.mbl_number}</Text>
            </Space>
            <Space>
              <Text strong>Container No:</Text>
              <Text>{shipmentData.cntr_number}</Text>
            </Space>
          </>
        )}
      </Space>
    </div>
  ) : (
    <Empty description="No shipment data available" />
  );
};

export default ShipmentInfo;
