import DraggableTabs from '@/components/DraggbleTabs';
import { EditableText } from '@/components/Editable';
import { useApp } from '@/utils/useapp';
import { Button, Dropdown, MenuProps, Space, Tooltip, message } from 'antd';
import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { FaPlus } from 'react-icons/fa';

interface TModelTabsProps {
  activeTab: any;
  onTabChange?: (tab: any) => void;
  tabMode: number;
}

const ModelTabs = (
  { onTabChange, tabMode, activeTab }: TModelTabsProps,
  ref: React.Ref<unknown> | undefined,
) => {
  const [tabs, setTabs] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = React.useState<string[]>([]);
  const [editTab, setEditTab] = useState(0);

  const app = useApp();

  const handleSaveUpdateTab = async (id: number, data: any) => {
    if (!data?.name) {
      return;
    }
    const resp = await app.service.put(`modelTabs/${id}`, {
      data: data,
    });
    setTabs(tabs.map((t) => (t.id == id ? resp.data : t)));
    onTabChange && onTabChange(resp.data);
  };

  const handleAddTab = async () => {
    setLoading(true);
    try {
      const resp = await app.service.post('modelTabs', {
        data: { name: `Tab ${tabs.length}`, mode: tabMode },
      });
      setTabs([...tabs, { ...resp.data, filters: [] }]);
      onTabChange && onTabChange(resp.data);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const updateTab = async (tab: any) => {
    setLoading(true);
    try {
      const resp = await handleSaveUpdateTab(tab.id, {
        ...tab,
        mode: tabMode,
      });

      return resp;
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateTab = async (
    name: string,
    value: string | any[],
    id?: number,
  ) => {
    id = id || editTab;
    const tab = tabs.find((tab) => tab.id === id);
    if (tab) {
      try {
        await handleSaveUpdateTab(id, { ...tab, [name]: value });
      } catch (err: any) {
        message.error(err.data?.message || err.data?.error);
      } finally {
        setEditTab(0);
      }
    }
  };

  const handleTabMoved = (order: any[] = []) => {
    app.service.post('modelTabs/tabsOrder', {
      data: { order, mode: tabMode },
    });
    setOrder(order);
  };

  const _tabs = useMemo(() => {
    if (!order || order.length == 0) {
      return tabs;
    }
    const _order = order.map((i: string) => +i);
    const _tabs = [...tabs].sort((a, b) => {
      const orderA = _order.indexOf(a.id);
      const orderB = _order.indexOf(b.id);

      if (orderA !== -1 && orderB !== -1) {
        return orderA - orderB;
      }
      if (orderA !== -1) {
        return -1;
      }
      if (orderB !== -1) {
        return 1;
      }

      const ia = tabs.indexOf(a);
      const ib = tabs.indexOf(b);

      return ia - ib;
    });
    return _tabs;
  }, [order, tabs]);

  const handleDeleteTab = async (id: number) => {
    if (tabs.length == 1) {
      return;
    }
    setLoading(true);
    try {
      await app.service.delete(`modelTabs/${id}`);
      const newTabs = tabs.filter((t) => t.id != id);
      setTabs(newTabs);
      onTabChange && onTabChange(newTabs[0] || {});
      message.success('Tab has been delete');
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const tabMenu = (id: number): MenuProps['items'] => {
    const items: MenuProps['items'] = [
      {
        label: 'Edit',
        key: 'edit' + id,
        onClick: () => {
          setEditTab(id);
        },
      },
      {
        label: 'Delete',
        key: 'delete' + id,
        onClick: () => {
          handleDeleteTab(id);
        },
      },
    ];

    return items;
  };

  const handleTabChange = (key: string) => {
    onTabChange && onTabChange(tabs.find((t) => t.id == +key) || {});
  };

  const fetchTabs = async () => {
    setLoading(true);
    try {
      const resp = await app.service.get('modelTabs', {
        params: { mode: tabMode },
      });
      setTabs(resp.data.tabs);
      setOrder(resp.data.order);
      const key = resp.data.order[0] || resp.data.tabs[0]?.id;
      // setActiveKey(+key);
      const tab =
        resp.data.tabs.find((t: any) => t.id == +key) || resp.data.tabs[0];
      if (tab) {
        onTabChange && onTabChange(tab);
      } else {
        handleAddTab();
      }
    } catch (err: any) {
      message.error(err);
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTabs();
  }, []);

  useImperativeHandle(ref, () => ({
    updateTab,
  }));

  return (
    <DraggableTabs
      onChange={handleTabChange}
      onTabMoved={handleTabMoved}
      activeKey={'' + (activeTab?.id || '')}
      tabBarExtraContent={
        <Space>
          <Tooltip title="New Tab">
            <Button
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              shape="circle"
              onClick={handleAddTab}>
              <FaPlus size={18} color="#1890ff" />
            </Button>
          </Tooltip>
        </Space>
      }
      items={_tabs.map((tab) => {
        return {
          label:
            editTab !== tab.id ? (
              <Dropdown
                menu={{ items: tabMenu(tab.id) }}
                trigger={['contextMenu']}>
                <div>{tab.name}</div>
              </Dropdown>
            ) : (
              <EditableText
                value={tab.name}
                onChange={handleUpdateTab}
                onHide={() => setEditTab(0)}
                name="name"
                autoFocus
              />
            ),
          key: '' + tab.id,
        };
      })}></DraggableTabs>
  );
};

export default React.forwardRef(ModelTabs);
