import { Button } from 'antd';
import { useApp } from '@/utils/useapp';
import { useMemo, useState } from 'react';
import React from 'react';
import { get, upperFirst } from 'lodash';
import { RescheduledModal } from './RescheduledModal';
import { TAction } from '../../Interfaces/ActionInterface';

export const RescheduledAction: React.FC<TAction> = ({
  target,
  targetModel,
  task,
  onDone,
}) => {
  const app = useApp();

  const [open, setOpen] = useState(false);

  const handleOnOpen = () => {
    setOpen(true);
  };

  const handleCloseOpen = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        disabled={task?.disabled_rescheduled}
        size="small"
        onClick={handleOnOpen}
      >
        Rescheduled {task?.disabled_rescheduled}
      </Button>
      {open && targetModel && (
        <RescheduledModal
          open={open}
          targetModel={targetModel}
          task={task}
          target={target}
          onClose={handleCloseOpen}
          onDone={onDone}
        />
      )}
    </>
  );
};
