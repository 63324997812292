import { THeader } from '@/types.d';
import styles from 'res/css/ui.scss';
import React from 'react';

export const Header: React.FC<THeader> = ({ title, rightElement, style }) => {
  return (
    <div className={styles.header} style={style}>
      <h1>{title}</h1>
      {rightElement && rightElement}
    </div>
  );
};
