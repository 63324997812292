import { EditOutlined } from '@ant-design/icons';
import { Button, DatePickerProps, Typography } from 'antd';
import { DatePicker } from 'antd';
import moment from 'moment';
import { CSSProperties, memo, useCallback, useState } from 'react';

const datePickerStyle: CSSProperties = {
  width: '100%',
};

const EditableCommonDatePicker = memo(
  ({ value, disabled, onChange, ...props }: DatePickerProps) => {
    const [isEditing, setIsEditing] = useState(false);
    const handleEdit = () => {
      setIsEditing(true);
    };

    const _value = value ? moment(value) : undefined;
    const label = _value ? _value.format('YYYY-MM-DD') : null;

    const handleChange: DatePickerProps['onChange'] = useCallback(
      (date, dateString) => {
        onChange?.(date, dateString);
        setIsEditing(false);
      },
      [onChange],
    );

    const handleBlur = useCallback(() => {
      setIsEditing(false);
    }, []);

    // 被禁用，只显示文本
    if (disabled) {
      return <>{label}</>;
    }

    if (!isEditing) {
      if (!value) {
        return (
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={handleEdit}
          ></Button>
        );
      }

      return (
        <Typography.Link underline onClick={handleEdit}>
          {label}
        </Typography.Link>
      );
    }

    return (
      <DatePicker
        disabled={disabled}
        autoFocus
        open
        style={datePickerStyle}
        value={_value}
        onChange={handleChange}
        onBlur={handleBlur}
        {...props}
      />
    );
  },
);

EditableCommonDatePicker.displayName = 'EditableCommonDatePicker';

export default EditableCommonDatePicker;
