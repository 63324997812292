import React from 'react';
import ReactECharts from 'echarts-for-react';
import styles from 'res/css/ui.scss';
import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  List,
  message,
  Row,
  Select,
  Space,
  Tag,
} from 'antd';
import { RateEngineConfigSelect } from '@/components/RateEngineConfigSelect';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { useApp } from '@/utils/useapp';
import { Header } from '@/components/CommonHeader';
import cache, { CACHE_RATE_ENGINE_CONFIG_KEY, useCache } from '@/stores/cache';
import { get } from 'lodash';
import Edit from '../setting/components/Edit';
import AlgoCard, { toLines } from './components/AlgoCard';
import { observer } from 'mobx-react';
import AlgoHistory from './components/AlgoHistory';

const LATEST_RATE_DATES = [
  { label: 'Recent 1 Month', value: 1 },
  { label: 'Recent 3 Months', value: 3 },
  { label: 'Recent 6 Months', value: 6 },
  { label: 'Recent 9 Months', value: 9 },
  { label: 'Recent 12 Months', value: 12 },
  { label: 'All', value: 12 * 5 }, // 5 years data. that's enough.
];

const Index = observer(() => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<any>({});
  const [filter] = useForm();
  const selectedConfigId = useWatch('rate_engein_config_id', filter);

  const configs = useCache(CACHE_RATE_ENGINE_CONFIG_KEY);

  const selectedConfig = React.useMemo(() => {
    if (!selectedConfigId) {
      return null;
    }
    return configs.find((ir) => ir.id == selectedConfigId);
  }, [selectedConfigId, configs]);

  React.useEffect(() => {
    setData({});
  }, [selectedConfigId]);

  const app = useApp();

  const fetchData = React.useCallback(async () => {
    setData({});
    setLoading(true);

    const params = filter.getFieldsValue();

    if (params.dates_exclude) {
      params.dates_exclude = params.dates_exclude.map((d: any) =>
        d.format('YYYY-MM-DD'),
      );
    }

    try {
      const result = await app.service.get('rateEngine/rates', {
        params,
      });
      setData(result);
    } catch (e: any) {
      message.error(e.data.message || e.data.error || 'System Error');
    }

    setLoading(false);
  }, []);

  const chartOption = React.useMemo(() => {
    return {
      toolbox: {
        feature: {
          dataZoom: {},
          dataView: {},
          restore: {},
          saveAsImage: {},
        },
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985',
          },
        },
        // formatter: (params) => {
        //   return `
        //             Tooltip: <br />
        //             ${params[0].seriesName}: ${params[0].value}<br />
        //             ${params[1].seriesName}: ${params[1].value}
        //             `;
        // },
        formatter: (params) => {
          return params
            .map((p) => {
              return `${p.seriesName}: ${p.value[1]}(${p.name})`;
            })
            .join('<br/>');
        },
        // formatter: '{b}: {c}',
        // formatter: '{b0}: {c0}<br />{b1}: {c1}',
      },
      legend: {},
      xAxis: {
        type: 'value',
        name: 'Distance / Miles',
        max: data.maxMiles,
      },
      yAxis: {
        type: 'value',
        name: 'Price / Dollar',
        max: data.maxPrice,
      },
      series: [
        {
          name: 'Valid Rate Data',
          symbolSize: 5,
          data: data.experimentRates,
          type: 'scatter',
        },
        {
          name: 'Current SR',
          symbolSize: 5,
          data: data.sellRates,
          type: 'scatter',
        },
        {
          name: 'Outlier Data',
          symbolSize: 5,
          data: data.outlierRates,
          type: 'scatter',
        },
        ...(selectedConfig && selectedConfig.algo
          ? toLines(selectedConfig.algo.formular)
          : []
        ).map((line: any) => ({
          name: 'Current Algo for this IR',
          type: 'line',
          labelLayout: { dx: -40 },
          data: line,
        })),
        ...(data.initialFormular ? toLines(data.initialFormular) : []).map(
          (line: any) => ({
            name: 'Initial Algo',
            type: 'line',
            labelLayout: { dx: -20 },
            data: line,
          }),
        ),

        ...(data.adjustFormular ? toLines(data.adjustFormular) : []).map(
          (line: any) => ({
            name: 'Adjust Algo',
            type: 'line',
            data: line,
          }),
        ),
      ],
    };
  }, [data]);

  return (
    <div className={styles.main}>
      <Header title="Rates" />
      <div className={styles.filter}>
        <Form
          layout="vertical"
          form={filter}
          initialValues={{ max_miles: 500, max_price: 5000, recent_month: 3 }}
          onFinish={fetchData}>
          <Row gutter={16}>
            <Col>
              <Form.Item
                required
                name="rate_engein_config_id"
                style={{ width: '200px' }}
                label="IR Config (Group)">
                <RateEngineConfigSelect />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="Date" name="recent_month">
                <Select options={LATEST_RATE_DATES} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="Dates Exclude" name="dates_exclude">
                <DatePicker.RangePicker />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item required name="max_miles" label="Max Miles">
                <Input type="number" style={{ width: '200px' }} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item required name="max_price" label="Max Price">
                <Input type="number" style={{ width: '200px' }} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label={' '}>
                <Space>
                  <Button htmlType="submit" type="primary" loading={loading}>
                    Submit
                  </Button>
                  <Button onClick={() => filter.resetFields()}>
                    Clear All
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <div className={styles.filter}>
        <Space>
          {data.experimentRates && data.experimentRates.length > 0 && (
            <Alert
              message={`Valid Rate Data: ${data.experimentRates.length}`}
              type="info"
            />
          )}
          {data.outlierRates && data.outlierRates.length > 0 && (
            <Alert
              message={`Outlier Data: ${data.outlierRates.length}`}
              type="info"
            />
          )}
          {data.experimentRates && data.experimentRates.length === 0 && (
            <Alert message="No Rates Found." type="error" />
          )}
        </Space>
      </div>
      <div className={styles.filter}>
        <Space align="start">
          {!!selectedConfig && (
            <List
              header={
                <Space>
                  <span>{selectedConfig.name}</span>
                  {get(selectedConfig, 'active', false) ? (
                    <Tag color="success">Active</Tag>
                  ) : (
                    <Tag color="error">InActive</Tag>
                  )}
                </Space>
              }
              footer={
                <>
                  <Space>
                    <Edit rateEngineConfigId={selectedConfigId}>
                      <Button size="small">Setting</Button>
                    </Edit>
                    <AlgoHistory
                      rateEngineConfigId={selectedConfigId}
                      enabledAlgoId={selectedConfig?.algo_id}
                    />
                  </Space>
                </>
              }
              bordered
              size="small"
              dataSource={get(selectedConfig, 'ranges') || []}
              renderItem={(item) => (
                <List.Item>
                  {item.start} - {item.end}, Markup: {item.markup}, Min:{' '}
                  {item.min}
                </List.Item>
              )}
            />
          )}
          {selectedConfig && selectedConfig && selectedConfig.algo && (
            <AlgoCard
              name={`Current Algo For this IR: ${selectedConfig.algo.name}`}
              algoFormular={selectedConfig.algo.formular}
              rates={data.experimentRates}
              rateEngineConfigId={selectedConfig.id}
              footer={`Created At ${selectedConfig.algo.created_at}`}
            />
          )}
          {!!data.initialFormular && selectedConfig && (
            <AlgoCard
              name="Initial Algo"
              algoFormular={data.initialFormular}
              rates={data.experimentRates}
              rateEngineConfigId={selectedConfig.id}
              showStatisticalMeasure
            />
          )}
          {!!data.adjustFormular && selectedConfig && (
            <AlgoCard
              name="Adjust Algo"
              algoFormular={data.adjustFormular}
              rates={data.experimentRates}
              rateEngineConfigId={selectedConfig.id}
              showAlgoEnableBtn
              showStatisticalMeasure
            />
          )}
        </Space>
      </div>
      {data.experimentRates && data.experimentRates.length > 0 && (
        <Card className="m-md">
          <ReactECharts option={chartOption} style={{ height: 600 }} />
        </Card>
      )}
    </div>
  );
});

export default Index;
