import { get, has } from 'lodash';

import React, { useEffect, useMemo } from 'react';
import {
  TASK_TYPES,
  TASK_TYPE_FILE,
  TASK_TYPE_VENDOR,
  TASK_TYPE_WAREHOUSE,
} from '@/pages/setting/containerTasks/components/data';

import { TItemProps, TOnCheckProps, TItemActionProps } from '.';

const TASK_TYPES_LABEL_MAP = {
  [TASK_TYPE_FILE]: 'DOC',
  [TASK_TYPE_VENDOR]: 'Car',
  [TASK_TYPE_WAREHOUSE]: 'WHS',
};

export const RenderToDoListTaskType: React.FC<
{
  colors: { [key: string]: string };
} & TItemProps
> = ({ colors, item }) => {
  if (!item) {
    return <></>;
  }

  const color = useMemo(
    () => (has(colors, item.task_type) ? get(colors, item.task_type) : 'gray'),
    [colors],
  );

  return (
    <span className={`text-${color}`}>
      {TASK_TYPES_LABEL_MAP[item.task_type]}
    </span>
  );
};
