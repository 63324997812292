export const AGENT_OR_HEADQUARTER_HEADQUARTER = 'Headquarter';
export const AGENT_OR_HEADQUARTER_HEADQUARTER_AGENT = 'Agent';

export const AGENT_OR_HEADQUARTER_MAP = {
  [AGENT_OR_HEADQUARTER_HEADQUARTER]: AGENT_OR_HEADQUARTER_HEADQUARTER,
  [AGENT_OR_HEADQUARTER_HEADQUARTER_AGENT]:
    AGENT_OR_HEADQUARTER_HEADQUARTER_AGENT,
};

const FEDERAL_TAX_CLASSIFICATION_INDIVIDUAL_SOLE_PROPRIETOR_OR_SINGLE_MEMBER_LLC = 1;
const FEDERAL_TAX_CLASSIFICATION_C_CORPORATION = 2;
const FEDERAL_TAX_CLASSIFICATION_S_CORPORATION = 3;
const FEDERAL_TAX_CLASSIFICATION_PARTNERSHIP = 4;
export const FEDERAL_TAX_CLASSIFICATION_LIMITED_LIABILITY_COMPANY = 5;
const FEDERAL_TAX_CLASSIFICATION_OTHER = 6;

export const FEDERAL_TAX_CLASSIFICATION_MAP = {
  [FEDERAL_TAX_CLASSIFICATION_INDIVIDUAL_SOLE_PROPRIETOR_OR_SINGLE_MEMBER_LLC]:
    'Individual/sole proprietor or single-member LLC',
  [FEDERAL_TAX_CLASSIFICATION_C_CORPORATION]: 'C Corporation',
  [FEDERAL_TAX_CLASSIFICATION_S_CORPORATION]: 'S Corporation',
  [FEDERAL_TAX_CLASSIFICATION_PARTNERSHIP]: 'Partnership',
  [FEDERAL_TAX_CLASSIFICATION_LIMITED_LIABILITY_COMPANY]:
    'Limited liability company',
  [FEDERAL_TAX_CLASSIFICATION_OTHER]: 'Other (see instructions)',
};

export const FORM_TYPE_W9 = 1;
export const FORM_TYPE_W8 = 2;
export const FORM_TYPE_MAP = {
  [FORM_TYPE_W9]: 'W-9',
  [FORM_TYPE_W8]: 'W-8',
};
