import { useApp } from '@/utils/useapp';
import { message, Modal } from 'antd';
import React from 'react';
import type { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import update from 'immutability-helper';
import { convertFilterValuesToQueryParams } from '@/pages/dispatchs/components/Filter';
import moment from 'moment-timezone';

import { TAB_MODE_MY_FOCUS } from '@/pages/dispatchs/components/data';

const useProgressTab = () => {
  const joinParams = (
    progressType: string,
    opdashboard_tab_id: number,
    _filters?: any,
    _dates?: any,
    searchQuery?: string | null,
  ) => {
    if (searchQuery) {
      return {
        query: searchQuery,
      };
    }

    _filters = _filters || [];

    const sort = _filters?.find((f) => f.attribute === 'sort') || null;

    const params = convertFilterValuesToQueryParams(_filters) || {};

    const opdashboardDate =
      (_dates && _dates[progressType]) ||
      moment().tz('America/Los_Angeles').format('YYYY-MM-DD');

    params.opdashboard_date = opdashboardDate;

    progressType && (params.progress_type = progressType);

    params.opdashboard_tab_id = opdashboard_tab_id;

    params.tab_mode = TAB_MODE_MY_FOCUS;

    return params;
  };

  return {
    joinParams,
  };
};

export default useProgressTab;
