import ClickWrapper from '@/components/ClickWrapper';
import React from 'react';
import { BillDrawer } from './BillDrawer';
interface Props {
  id: number;
  billableId: number;
  billableType: string;
  container?: any;
  children: React.ReactNode;
  onSaved?: () => void;
}

const EditBill: React.FC<Props> = ({
  id,
  container,
  billableId,
  billableType,
  children,
  onSaved,
}) => {
  const [visible, setVisible] = React.useState<boolean>(false);

  return (
    <>
      <ClickWrapper onClick={() => setVisible(!visible)}>
        {children}
      </ClickWrapper>
      {visible && (
        <BillDrawer
          id={id}
          billableId={billableId}
          billableType={billableType}
          container={container}
          visible={visible}
          onClose={() => setVisible(false)}
          onSaved={onSaved}
        />
      )}
    </>
  );
};

export default EditBill;
