import React, { ReactElement, useEffect, useState } from 'react';
import { Space, Tooltip, Divider, Button, Popover, message } from 'antd';
import { useApp } from '@/utils/useapp';
import { TAction } from '../Interfaces/ActionInterface';

import useAction, { TCheckValid } from './useAction';
import {
  OnDoneInterface,
  onSaveOperationInterface,
} from '../Interfaces/TaskInterface';
import { set } from 'lodash';

export const ConfirmBeforeUpdate: React.FC<
  {
    toSave: () => void;
    updatingData: TCheckValid;
  } & TAction &
    OnDoneInterface &
    onSaveOperationInterface
> = ({ updatingData, target, targetModel, task, onDone, toSave, ...props }) => {
  const app = useApp();

  const { checkValid } = useAction({ target, targetModel });
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [errors, setErrors] = useState<Array<string>>([]);
  const [msg, setMsg] = useState<string>(' Are your sure to update?');

  const checkValidUpdatingData = async () => {
    if (!targetModel?.id) {
      return;
    }

    const resp = await checkValid(updatingData);

    if (resp && (resp.errors || resp.message)) {
      resp.errors && setErrors(resp.errors);
      message && setMsg(resp.message);
      setIsOpenConfirm(true);
    } else {
      toSave();
    }
  };

  const handleCancel = () => {
    init();
  };

  const init = () => {
    setErrors([]);
    setIsOpenConfirm(false);
  };

  useEffect(() => {
    if (updatingData && Object.keys(updatingData).length > 0) {
      checkValidUpdatingData();
    }
  }, [updatingData]);

  return (
    <>
      {props.children}
      {isOpenConfirm && (
        <Popover
          placement="topRight"
          open={isOpenConfirm}
          content={
            <div style={{ width: '400px' }}>
              {errors.map((e, i) => (
                <div key={i}>{e}</div>
              ))}
              {msg}
              <Divider />
              <div className="flex justify-end">
                <Button onClick={handleCancel} size="small">
                  Cancel
                </Button>
                <Button type="primary" onClick={toSave} size="small">
                  Confirm
                </Button>
              </div>
            </div>
          }
        >
          <></>
        </Popover>
      )}
    </>
  );
};
