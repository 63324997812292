import { Form, Row, Col, Input, Button, Space, message } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React, { ReactElement } from 'react';
import { chain, get, trim, uniq, difference } from 'lodash';
import { Attachment } from './Attachment';

interface IHas {
  from?: boolean;
  attachment?: boolean;
  showSelectAttachments?: boolean;
}

interface IEmailFormProps {
  form: FormInstance;
  has?: IHas;
  sendToElement?: ReactElement;
  sendToExtra?: ReactElement;
  ccExtra?: ReactElement;
  bodyExtra?: ReactElement;
  docs?: any;
  onChange?: (name: string, value: any) => void;
}

export const validateForm = (values: any) => {
  if (!values.subject) {
    message.error('Please Input Subject');
    return false;
  }

  if (values.subject.length > 255) {
    message.error('The subject may not be greater than 255 characters');
    return false;
  }

  if (!values.body) {
    message.error('Please Input Body');
    return false;
  }

  return true;
};

const EmailForm: React.FC<IEmailFormProps> = ({
  form,
  has = {
    from: true,
    attachment: true,
    showSelectAttachments: true,
  },
  sendToElement,
  sendToExtra,
  ccExtra,
  bodyExtra,
  docs = [],
  onChange,
}) => {
  const handleOnChange = async (name: string, value: any) => {
    form.setFieldValue(name, value);
  };

  const handleAddSendToList = () => {
    const sendToList = form.getFieldValue('sendToList') || [];
    sendToList.push('');
    form.setFieldsValue({ sendToList });
  };

  const handleAddCcList = () => {
    const ccList = form.getFieldValue('ccList') || [];
    ccList.push('');
    setFormEmailsUnique('ccList', ccList);
  };

  const setFormEmailsUnique = (target: string, emails: any) => {
    form.setFieldValue(target, uniq(emails));
  };

  const initForm = () => {
    const values = form.getFieldsValue();

    if (!get(values, 'sendToList', false)) {
      values.sendToList = [''];
    }

    if (!get(values, 'ccList', false)) {
      values.ccList = [''];
    }

    if (difference(values, form.getFieldsValue())) {
      form.setFieldsValue(values);
    }
  };

  React.useEffect(() => {
    initForm();
  }, []);

  return (
    <>
      {has.from && (
        <Form.Item label="From">
          <Input.Group size="large" compact>
            <div className="mr-md" style={{ width: '45%' }}>
              <Form.Item name="from" noStyle>
                <Input />
              </Form.Item>
            </div>

            <div style={{ width: '45%' }}>
              <Form.Item name="from_name" noStyle>
                <Input />
              </Form.Item>
            </div>
          </Input.Group>
        </Form.Item>
      )}
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => (
          <Form.Item
            name="sendToList"
            rules={[{ required: true, message: 'required' }]}
            label={
              <Space>
                Send To:
                <Button
                  size="small"
                  type="primary"
                  // className='mr-xs'
                  onClick={handleAddSendToList}>
                  +
                </Button>
                {sendToExtra && sendToExtra}
              </Space>
            }>
            {sendToElement
              ? sendToElement
              : getFieldValue('sendToList')?.map(
                (email: string, index: number) => (
                  <Row key={index} gutter={10}>
                    <Col span={19}>
                      <Form.Item
                        style={{ marginBottom: '10px' }}
                        name={['sendToList', index]}
                        rules={[{ required: true, message: 'required' }]}>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md="auto">
                      <Button
                        danger
                        ghost
                        onClick={() => {
                          const sendToList = getFieldValue('sendToList');
                          sendToList.splice(index, 1);
                          form.setFieldsValue({ sendToList });
                        }}>
                          -
                      </Button>
                    </Col>
                  </Row>
                ),
              )}
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => (
          <Form.Item
            name="ccList"
            rules={[{ required: true, message: 'required' }]}
            label={
              <Space>
                CC:
                <Button
                  size="small"
                  type="primary"
                  // className='mr-xs'
                  onClick={handleAddCcList}>
                  +
                </Button>
                {ccExtra && ccExtra}
              </Space>
            }>
            {getFieldValue('ccList')?.map((email: string, index: number) => (
              <Row key={index} gutter={10}>
                <Col span={19}>
                  <Form.Item
                    style={{ marginBottom: '10px' }}
                    name={['ccList', index]}
                    rules={[{ required: true, message: 'required' }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col md="auto">
                  <Button
                    danger
                    ghost
                    onClick={() => {
                      const ccList = getFieldValue('ccList');
                      ccList.splice(index, 1);
                      form.setFieldsValue({ ccList });
                    }}>
                    -
                  </Button>
                </Col>
              </Row>
            ))}
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item
        name="subject"
        label="Subject"
        rules={[{ required: true, message: 'required' }]}>
        <Input placeholder="Enter subject" />
      </Form.Item>
      <Form.Item
        label={<>Body: {bodyExtra && bodyExtra}</>}
        name="body"
        rules={[{ required: true, message: 'required' }]}>
        <Input.TextArea id="emailBody" rows={12} placeholder="Enter body" />
      </Form.Item>
      {has.attachment && (
        <Form.Item name="selectedDocuments" noStyle>
          <Attachment
            form={form}
            showSelectAttachments={has.showSelectAttachments}
            docs={docs}
            onChange={(fileList) =>
              handleOnChange('selectedDocuments', fileList)
            }
          />
        </Form.Item>
      )}
    </>
  );
};

export default EmailForm;
