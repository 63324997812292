import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import classNames from 'classnames';

export const SOPLink: React.FC<{
  title: string;
  settingContainerTaskId: number;
  color: string;
}> = ({ title, color, settingContainerTaskId }) => {
  return (
    <a
      href={`${BASE_URL}/sop/${settingContainerTaskId}/redirect`}
      target="_blank"
      style={{ color: color ? color : 'inherit', textDecoration: 'underline' }}
      rel="noreferrer">
      {title}
      {/* <QuestionCircleOutlined/> */}
    </a>
  );
};
