import React from 'react';
import { Form, FormInstance, Input, Button } from 'antd';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

interface Props {
  form: FormInstance;
}

export const PickupNumberAgentsForm: React.FC<Props> = ({ form }) => {
  return (
    <Form layout="vertical" form={form}>
      <Form.Item name="id" noStyle>
        <Input hidden />
      </Form.Item>
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Name is required' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        name="user_id"
        label="Customer"
        rules={[{ required: false, message: 'Please Select this user' }]}>
        <UserSyncSelect />
      </Form.Item>
      <Form.Item label="Emails" required>
        <Form.List
          initialValue={form.getFieldValue('emails') || []}
          name="emails">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Form.Item label={''} key={field.key}>
                  <Form.Item
                    wrapperCol={{ xs: 10 }}
                    {...field}
                    noStyle
                    //validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        required: true,
                        message: 'Please input Email',
                      },
                    ]}>
                    <Input
                      minLength={5}
                      placeholder="Email"
                      style={{
                        width: fields.length > 1 ? '95%' : '100%',
                      }}
                    />
                  </Form.Item>
                  {fields.length > 1 ? (
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      style={{ marginLeft: '10px' }}
                      onClick={() => remove(field.name)}
                    />
                  ) : null}
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{
                    width: '100%',
                  }}
                  icon={<PlusOutlined />}>
                  Add
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
    </Form>
  );
};
