import { useApp } from '@/utils/useapp';
import { Table } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { ICashFlow } from '.';
import moment from 'moment-timezone';

const ARSummary: FC<{ cashFlow: ICashFlow }> = ({ cashFlow }) => {
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState<any[]>([]);
  const app = useApp();

  const fetchARSummary = async () => {
    try {
      setLoading(true);
      const res = await app.service.get('risks/arsummary');
      setSummary(res.data);
    } catch (e: any) {
      console.log(e.error || 'network error');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchARSummary();
  }, []);

  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const redirectToInovices = (
    salesId: string,
    type: string,
    state: string | null = null,
  ) => {
    let query = `?salesId=${salesId}`;

    if (type === 'due_61') {
      query +=
        '&dueStartDate=' +
        moment().subtract(365, 'days').format('YYYY-MM-DD') +
        '&dueEndDate=' +
        moment().subtract(61, 'days').format('YYYY-MM-DD');
    } else if (type === 'due_31_60') {
      query +=
        '&dueStartDate=' +
        moment().subtract(60, 'days').format('YYYY-MM-DD') +
        '&dueEndDate=' +
        moment().subtract(31, 'days').format('YYYY-MM-DD');
    } else if (type === 'due_1_30') {
      query +=
        '&dueStartDate=' +
        moment().subtract(30, 'days').format('YYYY-MM-DD') +
        '&dueEndDate=' +
        moment().format('YYYY-MM-DD');
    } else if (type === 'current') {
      query +=
        '&dueStartDate=' +
        moment().add(1, 'days').format('YYYY-MM-DD') +
        '&dueEndDate=' +
        moment().add(365, 'days').format('YYYY-MM-DD');
    }

    if (state) {
      query += `&state=${state}`;
    }
    // history.push('/accounting/invoices' + query);
    window.open('/accounting/invoices' + query, '_blank');
  };

  const columns = useMemo(
    () => [
      {
        title: 'Sales Name',
        dataIndex: 'sales_name',
        key: 'sales_name',
      },
      {
        title: 'Cash Flow Impact',
        dataIndex: 'sales_id',
        key: 'sales_id',
        render: (text: any) => {
          return cashFlow ? USDollar.format(cashFlow[+text] || 0) : '-';
        },
      },
      {
        title: 'Disput Count',
        dataIndex: 'dispute_count',
        key: 'dispute_count',
        render: (text: any, record: any) => {
          return (
            <a
              onClick={() =>
                redirectToInovices(record.sales_id, '', 'dispute')
              }>
              {text}
            </a>
          );
        },
      },
      {
        title: 'Current',
        dataIndex: 'current',
        key: 'current',
        sorter: (a: any, b: any) => a.current - b.current,
        render: (text: any, record: any) => {
          return (
            <a onClick={() => redirectToInovices(record.sales_id, 'current')}>
              {USDollar.format(+text || 0)}
            </a>
          );
        },
      },
      {
        title: 'Over 1-30',
        dataIndex: 'due_1_30',
        key: 'due_1_30',
        sorter: (a: any, b: any) => a.due_1_30 - b.due_1_30,
        render: (text: any, record: any) => {
          return (
            <a onClick={() => redirectToInovices(record.sales_id, 'due_1_30')}>
              {USDollar.format(+text || 0)}
            </a>
          );
        },
      },
      {
        title: 'Over 31-60',
        dataIndex: 'due_31_60',
        key: 'due_31_60',
        sorter: (a: any, b: any) => a.due_31_60 - b.due_31_60,
        render: (text: any, record: any) => {
          return (
            <a onClick={() => redirectToInovices(record.sales_id, 'due_31_60')}>
              {USDollar.format(+text || 0)}
            </a>
          );
        },
      },
      {
        title: 'Over 61',
        dataIndex: 'due_61',
        key: 'due_61',
        sorter: (a: any, b: any) => a.due_61 - b.due_61,
        render: (text: any, record: any) => {
          return (
            <a onClick={() => redirectToInovices(record.sales_id, 'due_61')}>
              {USDollar.format(+text || 0)}
            </a>
          );
        },
      },
      {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        sorter: (a: any, b: any) => a.total - b.total,
        render: (text: any, record: any) => {
          return (
            <a onClick={() => redirectToInovices(record.sales_id, 'total')}>
              {USDollar.format(+text || 0)}
            </a>
          );
        },
      },
    ],
    [cashFlow],
  );

  return (
    <>
      <h2>AR Summary</h2>
      <Table
        size="small"
        pagination={false}
        loading={loading}
        columns={columns}
        dataSource={summary}
      />
    </>
  );
};

export default ARSummary;
