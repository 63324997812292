import { Tag } from 'antd';
import React from 'react';
import {
  WHOSE_TASK_OP_ASSIST,
  WHOSE_TASK_CPS,
  WHOSE_TASK_OP,
  WHOSE_TASK_MAP,
} from '@/pages/dispatchs/components/data';

const WHOSE_TASK_COLOR_MAP = {
  [WHOSE_TASK_OP_ASSIST]: 'volcano',
  [WHOSE_TASK_CPS]: 'cyan',
  [WHOSE_TASK_OP]: 'blue',
};

export const RenderWhoseTaskTag: React.FC<{ whoseTask: number | null }> = ({
  whoseTask,
}) => {
  if (whoseTask) {
    return (
      <Tag
        color={
          WHOSE_TASK_COLOR_MAP[
            whoseTask as unknown as keyof typeof WHOSE_TASK_COLOR_MAP
          ]
        }>
        {WHOSE_TASK_MAP[whoseTask as unknown as keyof typeof WHOSE_TASK_MAP]}
      </Tag>
    );
  } else {
    return <></>;
  }
};
