import { useApp } from '@/utils/useapp';
import { Badge } from 'antd';
import { observer } from 'mobx-react';

const Online: React.FC<{ id: number }> = ({ id }) => {
  const app = useApp();
  return id ? (
    <Badge
      color={
        app.store.onlineUsers.users.includes('user:online:' + id)
          ? 'green'
          : 'red'
      }
    />
  ) : (
    <></>
  );
};

export default observer(Online);
