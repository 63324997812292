import React, { useEffect, useMemo } from 'react';
import {
  Form,
  FormInstance,
  Switch,
  Drawer,
  Space,
  Button,
  message,
} from 'antd';
import { EmailTemplateEditor } from '@/components/EmailTemplateEditor';
import { PRESET_DATA_MAP } from '@/pages/setting/emailTemplate/components/data';
import { useApp } from '@/utils/useapp';
import { get, has } from 'lodash';
interface Props {
  form: FormInstance;
  target: string;
  targetId: number;
  title: string;
  visible: boolean;
  onSaved: () => void;
  setVisible: (value: boolean) => void;
}

export const EmailTemlateDrawer: React.FC<Props> = ({
  target,
  targetId,
  form,
  title,
  visible,
  onSaved,
  setVisible,
}) => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const presetDataMap = useMemo(
    () => has(PRESET_DATA_MAP, target) && get(PRESET_DATA_MAP, target),
    [target],
  );

  const handleChange = (event: any) => {
    const { name, value } = event.target as HTMLInputElement;
    form.setFieldValue([name], value);
  };

  const validateForm = (values: any) => {
    if (!values.subject) {
      message.error('Please Input Subject');
      return false;
    }

    if (values.subject.length > 255) {
      message.error('The subject may not be greater than 255 characters');
      return false;
    }

    if (!values.body) {
      message.error('Please Input Body');
      return false;
    }

    return true;
  };

  const handleSave = async () => {
    const values = await form.validateFields();
    if (false === validateForm(values)) {
      return false;
    }
    setLoading(true);

    try {
      !values.id
        ? await app.service.post(`emailTemplates/${target}/${targetId}`, {
          data: values,
        })
        : await app.service.put(`emailTemplates/${values.id}`, {
          data: values,
        });
      message.success('Saved');
      onSaved();
      setVisible(false);
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  return (
    <Drawer
      title={title}
      placement="right"
      width="45%"
      onClose={() => setVisible(false)}
      destroyOnClose={true}
      open={visible}
      extra={
        <Space>
          <Button onClick={() => setVisible(false)}>Close</Button>
          <Button type="primary" loading={loading} onClick={handleSave}>
            Save
          </Button>
        </Space>
      }>
      <Form layout="vertical" form={form}>
        <Form.Item name="id" hidden noStyle />
        <Form.Item name="subject" hidden noStyle></Form.Item>
        <Form.Item name="body" hidden noStyle></Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <EmailTemplateEditor
              presetDataMap={presetDataMap}
              info={{
                subject: getFieldValue('subject'),
                body: getFieldValue('body'),
              }}
              onChange={handleChange}
            />
          )}
        </Form.Item>
        <Form.Item
          name="enabled"
          label="Enabled"
          initialValue={true}
          valuePropName="checked">
          <Switch />
        </Form.Item>
      </Form>
    </Drawer>
  );
};
