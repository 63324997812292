import { useApp } from '@/utils/useapp';
import {
  Space,
  Popover,
  Tooltip,
  message,
  Button,
  Typography,
  Divider,
} from 'antd';
import { ShipmentInterface } from '../Interfaces/ShipmentInterface';
import { LiaCarSideSolid } from 'react-icons/lia';
import { useEffect, useMemo, useState } from 'react';
import { isToday } from '@/utils/isToday';

const CarIcon = () => (
  <LiaCarSideSolid
    style={{ fontSize: '1rem', position: 'relative', top: '3px' }}
  />
);

const DeliveryCarIcon: React.FC<{
  shipment: ShipmentInterface;
  onSaved: () => void;
}> = ({ shipment, onSaved }) => {
  const app = useApp();
  const [open, setOpen] = useState(false);
  const dateString = useMemo(() => shipment.estimated_delivery_date, [
    shipment,
  ]);

  const _isToday = useMemo(() => {
    return isToday(dateString);
  }, [dateString]);

  if (!_isToday) {
    return <></>;
  }

  const handleConfirm = async (enRoute: boolean) => {
    if (!shipment || !shipment.id) {
      return;
    }

    try {
      await app.service.patch(`tl/ltl/shipments/${shipment.id}`, {
        data: {
          destination_en_route: enRoute,
        },
      });
      message.success('Saved');
      onSaved();
      setOpen(false);
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
  };

  return (
    <Popover
      trigger={['hover']}
      mouseEnterDelay={0.05}
      mouseLeaveDelay={0.05}
      onOpenChange={setOpen}
      open={open}
      title={'Delivery'}
      content={
        <>
          <Typography.Paragraph>
            {shipment.destination_en_route
              ? 'The trucker is not en route.'
              : 'Confirm if the trucker is en route. Click here to confirm.'}
          </Typography.Paragraph>
          <div className="flex justify-end">
            <Button
              size="small"
              onClick={() => setOpen(false)}
              className="mr-sm"
            >
              Cancel
            </Button>
            <Button
              size="small"
              type="primary"
              onClick={() => handleConfirm(!shipment.destination_en_route)}
            >
              OK
            </Button>
          </div>
        </>
      }
    >
      <span
        className={`cursor-pointer ${
          shipment.destination_en_route ? 'text-primary' : 'text-danger'
        }`}
      >
        <CarIcon />
      </span>
    </Popover>
  );
};

export default DeliveryCarIcon;
