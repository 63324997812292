import { TCity } from '@/types';

export enum BusinessWeekDay {
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
  SUNDAY = 'Sunday',
}

export interface TCFSLocation {
  id: number;
  firms_code: string;
  verified: boolean;
  facility_type: number;
  name: string;
  address1: string;
  address2: string;
  city_id: number;
  city: TCity;
  zipcode: string;
  track_link: string;
  business_day_start: BusinessWeekDay;
  business_day_end: BusinessWeekDay;
  business_time_start: string;
  business_time_end: string;
  contact_name: string;
  contact_phone: string;
  contact_ext: string;
  contact_email: string;
  pickup_location_memo: string;
  enabled: boolean;
}
