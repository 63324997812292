import { Button, List, Popover, Space } from 'antd';
import React, { useEffect } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

export type AccountingActionTipsProps = {
  children?: React.ReactElement;
  color?: string | undefined;
  list?: any[];
};

const AccountingActionTips: React.FC<AccountingActionTipsProps> = ({
  children,
  color,
  list,
}) => {
  return (
    <>
      <Popover
        zIndex={100}
        mouseEnterDelay={0.05}
        mouseLeaveDelay={0.05}
        placement="left"
        content={
          <List
            pagination={false}
            bordered
            size="small"
            dataSource={list}
            renderItem={(item) => (
              <List.Item>
                <Space>
                  <div className={item.done ? 'text-success' : 'text-danger'}>
                    {item.done ? (
                      <CheckCircleOutlined />
                    ) : (
                      <CloseCircleOutlined />
                    )}
                  </div>
                  <div>{item.task}</div>
                </Space>
              </List.Item>
            )}
          />
        }
      >
        <Button type="link" size="small">
          <span className={`text-${color}`}>{children}</span>
        </Button>
      </Popover>
    </>
  );
};

export default AccountingActionTips;
