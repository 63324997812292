import styles from 'res/css/ui.scss';
import {
  Button,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Space,
} from 'antd';
import { CitySelect } from '@/components/CitySelect';
import { IntermodalRegionSelect } from '@/components/IntermodalRegionSelect';
import { FacilityTypeSelect } from '@/components/FacilityTypeSelect';
import { CargoTypeSelect } from '@/components/CargoTypeSelect';
import { VendorSelect } from '@/components/VendorSelect';
import moment from 'moment';
import React, { useState } from 'react';
import { useApp } from '@/utils/useapp';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import ContainerSizeSelect from '@/components/ContainerSizeSelect';

export type TFilter = {
  loading: boolean;
  filter: FormInstance;
  onSearch: () => void;
  onChange: (values: any) => void;
};

const { RangePicker } = DatePicker;

export const Filter: React.FC<TFilter> = ({ loading, filter, onSearch }) => {
  const app = useApp();
  const [isShow, setIsShow] = useState(false);
  const handleSearch = () => {
    onSearch();
  };

  const handleFilterChange = (key: string, value: any) => {
    filter.setFieldsValue({ ...filter.getFieldsValue(), [key]: value });
  };

  const handleCreateDateChange = (type: string, value: string[]) => {
    filter.setFieldsValue({
      ...filter,
      [`${type}_at_start_date`]: value[0],
      [`${type}_at_end_date`]: value[1],
    });
  };

  const handleReset = () => {
    filter.resetFields();
    onSearch();
  };

  React.useEffect(() => {
    if (app.store.search.rateId) {
      filter.setFieldsValue({ id: app.store.search.rateId });
    }
  }, [app.store.search.rateId]);

  return (
    <div className={styles.filter}>
      <div
        style={{
          width: '100%',
        }}
      >
        <Form layout="vertical" form={filter} initialValues={{ valid: 'true' }}>
          <Form.Item name="sort_by" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="sort_value" hidden>
            <Input />
          </Form.Item>
          <Row gutter={8}>
            <Col span={2}>
              <Form.Item label="ID#" name={'id'}>
                <Input
                  onChange={(e) => handleFilterChange('id', e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Intermodal Region" name="intermodal_region_id">
                <IntermodalRegionSelect
                  onChange={(v) =>
                    handleFilterChange('intermodal_region_id', v)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <Form.Item label="City" name="to_city">
                    <CitySelect
                      selected={getFieldValue('to_city') || ''}
                      onSelect={(id) => handleFilterChange('to_city', id)}
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <Form.Item label="Vendor" name="vendor">
                    <VendorSelect
                      selected={getFieldValue('vendor') || ''}
                      onSelect={(id) => {
                        handleFilterChange('vendor', id);
                      }}
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="Contract Rate Name" name="contract_rate_name">
                <Input
                  onChange={(e) =>
                    handleFilterChange('contract_rate_name', e.target.value)
                  }
                />
              </Form.Item>
            </Col>

            <Col span={2}>
              <Form.Item label=" ">
                <Button
                  onClick={() => {
                    setIsShow((isShow) => !isShow);
                  }}
                >
                  {isShow ? 'Hide Filters' : 'Show All Filters'}
                </Button>
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label=" ">
                <Space>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={loading}
                    onClick={handleSearch}
                  >
                    Search
                  </Button>

                  <Button onClick={handleReset}>Clear All</Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>

          {isShow && (
            <Row gutter={8}>
              <Col span={3}>
                <Form.Item label="From Facility Type" name="from_facility_type">
                  <FacilityTypeSelect
                    getValueForOption="name"
                    // value={filter.from_facility_type || ''}
                    onChange={(v) =>
                      handleFilterChange('from_facility_type', v)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="CNTR Size" name="container_size">
                  <ContainerSizeSelect style={{ width: '100%' }} allowClear />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="HAZ" name="is_haz">
                  <Select allowClear>
                    <Select.Option value="">All</Select.Option>
                    <Select.Option value="true">Yes</Select.Option>
                    <Select.Option value="false">No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="OW" name="is_ow">
                  <Select allowClear>
                    <Select.Option value="">All</Select.Option>
                    <Select.Option value="true">Yes</Select.Option>
                    <Select.Option value="false">No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="REEFER" name="is_reefer">
                  <Select allowClear>
                    <Select.Option value="">All</Select.Option>
                    <Select.Option value="true">Yes</Select.Option>
                    <Select.Option value="false">No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="SOC" name="is_soc">
                  <Select allowClear>
                    <Select.Option value="">All</Select.Option>
                    <Select.Option value="true">Yes</Select.Option>
                    <Select.Option value="false">No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="Valid" name="valid">
                  <Select
                    allowClear
                    // value={filter.valid || ''}
                    onChange={(v) => handleFilterChange('valid', v)}
                  >
                    <Select.Option value="">All</Select.Option>
                    <Select.Option value="true">Yes</Select.Option>
                    <Select.Option value="false">No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="Visible" name="visible">
                  <Select
                    allowClear
                    // value={filter.visible || ''}
                    onChange={(v) => handleFilterChange('visible', v)}
                  >
                    <Select.Option value="true">Yes</Select.Option>
                    <Select.Option value="false">No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="Favored" name="admin_favored">
                  <Select
                    allowClear
                    // value={filter.admin_favored || ''}
                    onChange={(v) => handleFilterChange('admin_favored', v)}
                  >
                    <Select.Option value="true">Yes</Select.Option>
                    <Select.Option value="false">No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              {/* <Col span={2}>
                <Form.Item
                  label="Different IR"
                  name="vendor_not_in_same_intermodal_region">
                  <Select
                    allowClear
                    // value={filter.vendor_not_in_same_intermodal_region || ''}
                    onChange={(v) =>
                      handleFilterChange(
                        'vendor_not_in_same_intermodal_region',
                        v,
                      )
                    }>
                    <Select.Option value="true">Yes</Select.Option>
                  </Select>
                </Form.Item>
              </Col> */}

              <Col span={4}>
                <Form.Item label="To Zipcode" name="to_zipcode">
                  <Input
                    placeholder="Search"
                    value={filter.getFieldValue('to_zipcode') || ''}
                    onChange={(e) =>
                      handleFilterChange('to_zipcode', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="To Facility Type" name="to_facility_type">
                  <FacilityTypeSelect
                    getValueForOption="name"
                    value={filter.getFieldValue('to_facility_type') || ''}
                    onChange={(v) => handleFilterChange('to_facility_type', v)}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="FBA Code" name="fba_facility_code">
                  <Input
                    placeholder="Enter to FBA facility code"
                    // value={filter.fba_facility_code || ''}
                    onChange={(e) =>
                      handleFilterChange('fba_facility_code', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="customer_id" label="Customer">
                  <UserSyncSelect />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => (
                    <>
                      <Form.Item
                        hidden
                        name="created_at_start_date"
                      ></Form.Item>
                      <Form.Item hidden name="created_at_end_date"></Form.Item>
                      <Form.Item label="Created Date">
                        <RangePicker
                          value={[
                            getFieldValue('created_at_start_date')
                              ? moment(getFieldValue('created_at_start_date'))
                              : null,
                            filter.getFieldValue('created_at_end_date')
                              ? moment(getFieldValue('created_at_end_date'))
                              : null,
                          ]}
                          style={{ width: '100%' }}
                          onChange={(date, dataStrings) => {
                            handleCreateDateChange('created', dataStrings);
                          }}
                        />
                      </Form.Item>
                    </>
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => (
                    <>
                      <Form.Item
                        hidden
                        name="expired_at_start_date"
                      ></Form.Item>
                      <Form.Item hidden name="expired_at_end_date"></Form.Item>
                      <Form.Item label="Expired Date">
                        <RangePicker
                          value={[
                            getFieldValue('expired_at_start_date')
                              ? moment(getFieldValue('expired_at_start_date'))
                              : null,
                            getFieldValue('expired_at_end_date')
                              ? moment(getFieldValue('expired_at_end_date'))
                              : null,
                          ]}
                          style={{ width: '100%' }}
                          onChange={(date, dataStrings) => {
                            handleCreateDateChange('expired', dataStrings);
                          }}
                        />
                      </Form.Item>
                    </>
                  )}
                </Form.Item>
              </Col>
            </Row>
          )}
        </Form>
      </div>
    </div>
  );
};
