import {
  Button,
  Form,
  Input,
  Select,
  Space,
  Switch,
  Table,
  Tooltip,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState, useMemo } from 'react';

type TContact = {
  email: string;
  emailable: boolean;
  request_rate: boolean;
  fax: string;
  id?: number;
  name: string;
  phone: string;
  remark: string;
  role: string;
  type: Array<string>;
  cell: string;
};
export const CONTACT_TYPE_APT = 'APT';
export const CONTACT_TYPE_SALES = 'SALES';
export const CONTACT_TYPE_DISPATCH = 'DISPATCH';
export const CONTACT_TYPE_ACCOUNTING = 'ACCOUNTING';
export const CONTACT_TYPES = [
  CONTACT_TYPE_APT,
  CONTACT_TYPE_SALES,
  CONTACT_TYPE_DISPATCH,
  CONTACT_TYPE_ACCOUNTING,
];

export const ContactList: React.FC<{
  data: TContact[];
  showRequestRate?: boolean;
  updateData: (a: TContact[]) => void;
}> = ({ data = [], showRequestRate = false, updateData }) => {
  const handleAddList = () => {
    updateData([
      ...data,
      {
        email: '',
        emailable: false,
        request_rate: false,
        fax: '',
        name: '',
        phone: '',
        remark: '',
        role: '',
        type: [],
        cell: '',
      },
    ]);
  };

  const handleSelectCode = (index: number, value: string) => {
    const copy = [...data];
    copy[index].type = value;
    updateData(copy);
  };

  const handlePropertyChange = (index: number, e: any) => {
    const { name, value } = e.target as HTMLInputElement;
    const copy: TContact[] = [...data];
    copy[index][name as keyof TContact] = value;
    updateData(copy);
  };

  const handleRemove = (index: number) => {
    const copy = [...data];
    copy.splice(index, 1);
    updateData(copy);
  };

  const columns: ColumnsType<TContact> = [
    {
      title: 'ID',
      key: 'id',
      width: 100,
      render: (text: any, record: TContact, dataIndex: number) => {
        return <>{record.id}</>;
      },
    },
    {
      title: 'Type',
      key: 'type',
      width: 180,
      render: (text: any, record: TContact, dataIndex: number) => {
        return (
          <>
            <Select
              style={{ width: '100%' }}
              maxTagCount={'responsive'}
              value={record.type || []}
              mode="multiple"
              onChange={(code) => {
                handleSelectCode(dataIndex, code);
              }}>
              {CONTACT_TYPES.map((c) => (
                <Select.Option key={c} value={c}>
                  {c}
                </Select.Option>
              ))}
            </Select>
          </>
        );
      },
    },
    // {
    //   title: 'Emailable',
    //   key: 'emailable',
    //   width: 100,
    //   render: (text: any, record: TContact, dataIndex: number) => {
    //     return (
    //       <>
    //         <Form.Item
    //           className="mb0"
    //           name={['contacts', dataIndex, 'emailable']}
    //           rules={[{ required: true }]}>
    //           <Switch
    //             checked={record.emailable}
    //             onChange={(v) =>
    //               handlePropertyChange(dataIndex, {
    //                 target: {
    //                   name: 'emailable',
    //                   value: v,
    //                 },
    //               })
    //             }
    //           />
    //         </Form.Item>
    //       </>
    //     );
    //   },
    // },
    {
      title: 'Name',
      key: 'name',
      width: 200,
      render: (text: any, record: TContact, dataIndex: number) => {
        return (
          <>
            <Form.Item
              className="mb0"
              name={['contacts', dataIndex, 'name']}
              rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </>
        );
      },
    },
    {
      title: 'Role',
      key: 'role',
      width: 200,
      render: (text: any, record: TContact, dataIndex: number) => {
        return (
          <>
            <Form.Item className="mb0" name={['contacts', dataIndex, 'role']}>
              <Input />
            </Form.Item>
          </>
        );
      },
    },
    {
      title: 'Phone',
      key: 'phone',
      width: 200,
      render: (text: any, record: TContact, dataIndex: number) => {
        return (
          <>
            <Form.Item className="mb0" name={['contacts', dataIndex, 'phone']}>
              <Input />
            </Form.Item>
          </>
        );
      },
    },
    {
      title: 'Email',
      key: 'email',
      width: 200,
      render: (text: any, record: TContact, dataIndex: number) => {
        return (
          <>
            <Form.Item className="mb0" name={['contacts', dataIndex, 'email']}>
              <Input />
            </Form.Item>
          </>
        );
      },
    },
    // {
    //   title: 'Cell',
    //   key: 'cell',
    //   width: 200,
    //   render: (text: any, record: TContact, dataIndex: number) => {
    //     return (
    //       <>
    //         <Form.Item className="mb0" name={['contacts', dataIndex, 'cell']}>
    //           <Input />
    //         </Form.Item>
    //       </>
    //     );
    //   },
    // },
    {
      title: 'Fax',
      key: 'fax',
      width: 200,
      render: (text: any, record: TContact, dataIndex: number) => {
        return (
          <>
            <Form.Item className="mb0" name={['contacts', dataIndex, 'fax']}>
              <Input />
            </Form.Item>
          </>
        );
      },
    },

    {
      title: 'Remark',
      key: 'remark',
      width: 200,
      render: (text: any, record: TContact, dataIndex: number) => {
        return (
          <>
            <Form.Item className="mb0" name={['contacts', dataIndex, 'remark']}>
              <Input />
            </Form.Item>
          </>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      fixed: 'right',
      render: (text: any, record: TContact, dataIndex: number) => {
        return (
          <>
            <a onClick={() => handleRemove(dataIndex)}>delete</a>
          </>
        );
      },
    },
  ];
  // const columns = useMemo(() => {
  //   const _columns = [...defaultColumns];
  //   if (showRequestRate) {
  //     _columns.splice(3, 0, {
  //       title: (
  //         <Space>
  //           <span>Request Rate</span>
  //           <Tooltip
  //             placement="topLeft"
  //             title={
  //               <div>
  //                 Will send request rate email to this contact when this switch
  //                 is on.
  //               </div>
  //             }
  //             arrowPointAtCenter>
  //             <QuestionCircleOutlined />
  //           </Tooltip>{' '}
  //         </Space>
  //       ),
  //       key: 'request_rate',
  //       width: 100,
  //       render: (text: any, record: TContact, dataIndex: number) => {
  //         return (
  //           <>
  //             <Form.Item
  //               className="mb0"
  //               valuePropName="checked"
  //               name={['contacts', dataIndex, 'request_rate']}
  //               rules={[{ required: true }]}>
  //               <Switch />
  //             </Form.Item>
  //           </>
  //         );
  //       },
  //     });
  //   }
  //   return _columns;
  // }, [data, showRequestRate]);

  return (
    <div>
      <h3>
        Contacts <Button onClick={handleAddList}>Add</Button>
      </h3>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ x: '100%' }}
      />
    </div>
  );
};
