import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Col,
  Dropdown,
  Empty,
  message,
  Row,
  Input,
  Space,
  Spin,
  Tooltip,
  Select,
} from 'antd';
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import type { MenuProps } from 'antd';
import {
  convertFilterValuesToQueryParams,
  Filter,
  toFilterValue,
} from './components/Filter';
import { TCollection } from '@/types';
import FileSaver from 'file-saver';

import { EditableText } from '@/components/Editable';
import update from 'immutability-helper';
import { ManageColumns } from './components/ManageColumns';
import { FaPlus } from 'react-icons/fa';
import { HiRefresh } from 'react-icons/hi';

import {
  TAB_MODE_ACCOUNTING_CONTAINER,
  TAB_MODE_DISPATCH,
} from './components/data';

import { reduce } from 'lodash';

import { DrawerFormBulkUpdate } from './components/DrawerFormBulkUpdate';
import { AccountingModeBulkUpdate } from './components/AccountingModeBulkUpdate';
import { DispatchTable } from './components/Table/DispatchTable';
import useDispatch from './components/Table/useDispatch';
import { useHistory, useLocation } from 'umi';
import DraggableTabs from '@/components/DraggbleTabs';
import ColorTagSelect from '@/components/ColorTagSelect';
import { CACHE_CONTAINER_COLOR_TAG_KEY } from '@/stores/cache';
import { SellRateBulkUpdate } from './components/SellRateBulkUpdate';
import { debounce } from 'lodash';
import { SellRateBulkUpdateWithExcel } from './components/SellRateBulkUpdateWithExcel';

export type TDispatchTab = {
  admin_id: number;
  columns: null;
  created_at: string;
  filters: any[];
  id: number;
  name: string;
  updated_at: string;
};

const SEARCH_QUERY_TYPES = [
  {
    value: '',
    label: 'Default Search',
  },
  {
    value: 'multi_cntr',
    label: 'Multi Search',
  },
];

const SEARCH_QUERY_TYPE_TOKEN_SEPARATORS = [',', ' ', '\r\n', '\n'];

const SEARCH_QUERY_TYPE_MAX_COUNT = 100;

const handleSearchMultipleQueriesSelectMaxTagPlaceholderTooltipOverlayStyle: CSSProperties = {
  pointerEvents: 'none',
};
const handleSearchMultipleQueriesSelectMaxTagPlaceholderTooltipTitle = (
  omittedValues: any,
) => omittedValues.map(({ label }: { label: string }) => label).join(', ');

const handleSearchMultipleQueriesSelectMaxTagPlaceholder = (
  omittedValues: any,
) => (
  <Tooltip
    overlayStyle={
      handleSearchMultipleQueriesSelectMaxTagPlaceholderTooltipOverlayStyle
    }
    title={handleSearchMultipleQueriesSelectMaxTagPlaceholderTooltipTitle(
      omittedValues,
    )}
  >
    <span>+{omittedValues.length} ...</span>
  </Tooltip>
);

const Index: React.FC<{
  accountingMode?: boolean;
  mode?: string;
}> = ({ accountingMode = false }) => {
  const app = useApp();
  useEffect(() => {
    app.store.cache.fetch(CACHE_CONTAINER_COLOR_TAG_KEY, true);
  }, []);

  const searchRef = useRef<string>('');

  const { fetchData, sortOrder, ...props } = useDispatch();

  const [loading, setLoading] = React.useState(false);
  const [tabs, setTabs] = React.useState<TDispatchTab[]>([]);
  const [order, setOrder] = React.useState<string[]>([]);
  const [data, setData] = React.useState<TCollection<any>>();
  const [filterValues, setFilterValues] = React.useState<any[] | null>(null);
  const [editTab, setEditTab] = React.useState<number>(0);
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const multipleQueriesRef = useRef('');
  const [searchQueryType, setSearchQueryType] = useState(
    SEARCH_QUERY_TYPES[0].value,
  );
  const [activeKey, setActiveKey] = useState<number>(0);
  const [openBulkUpdate, setOpenBulkUpdate] = useState(false);
  const [
    openAccountingModeBulkUpdate,
    setOpenAccountingModeBulkUpdate,
  ] = useState(false);
  const [defaultColumns, setDefaultColumns] = useState<any>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [pagination, setPagination] = useState<TablePaginationConfig>();
  const [tabCounts, setTabCounts] = useState<any>({});
  const location = useLocation();
  const history = useHistory();

  const queryParams = history?.location?.query || {};

  const headerTitle = accountingMode ? 'Accounting Container' : 'Dispatch';
  const tabMode = accountingMode
    ? TAB_MODE_ACCOUNTING_CONTAINER
    : TAB_MODE_DISPATCH;

  const handleTabChange = (key: string) => {
    setSearchQuery(searchRef.current || '');
    multipleQueriesRef.current = '';
    setActiveKey(+key);
    setSelectedRows([]);
    if (pagination) {
      pagination.current = 1;
    }
  };

  const fetchTabs = async () => {
    setLoading(true);
    try {
      const resp = await app.service.get('dispatchTabs', {
        params: { mode: tabMode },
      });
      setTabs(resp.data.tabs);
      setOrder(resp.data.order);
      handleTabChange(resp.data.order[0] || resp.data.tabs[0].id);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTabCounts = async () => {
    try {
      const resp = await app.service.get('dispatchTabs/tabCounts', {
        params: { mode: tabMode },
      });
      setTabCounts(resp);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    }
  };

  const _fetchData = React.useCallback(
    async (
      pagination?: TablePaginationConfig,
      _filters?: any,
      isEmptyQuery?: boolean,
      query?: string,
    ) => {
      const sortFilters = [
        (_filters || filterValues || []).find(
          (f: any) => f.attribute === 'sort',
        ) ?? {},
      ];

      const shouldSetQuery =
        searchQueryType === '' && (query || searchQuery) && !isEmptyQuery;
      const shouldSetMultipleQueries =
        searchQueryType === 'multi_cntr' && multipleQueriesRef.current;

      let paramsWithoutPagination: Record<string, any> = {};
      if (searchQueryType === 'multi_cntr' && shouldSetMultipleQueries) {
        // Use multiple queries and sort filters
        paramsWithoutPagination = {
          multiple_queries: multipleQueriesRef.current,
          ...convertFilterValuesToQueryParams(sortFilters),
        };
      } else if (searchQueryType === '' && shouldSetQuery) {
        // Use passed query and sort filters
        paramsWithoutPagination = {
          query: query || searchQuery,
          ...convertFilterValuesToQueryParams(sortFilters),
        };
      } else {
        // Use passed filters directly
        paramsWithoutPagination = {
          ...convertFilterValuesToQueryParams(_filters),
        };
      }

      const resp = await fetchData(pagination, {
        ...paramsWithoutPagination,
        page: pagination?.current || 1,
        per_page: pagination?.pageSize || 20,
      });
      setData(resp);
    },
    [activeKey, filterValues, searchQuery, searchQueryType],
  );

  useEffect(() => {
    const queryString = location.search;
    if (queryString) {
      setSearchQuery('');
      multipleQueriesRef.current = '';
      const params = new URLSearchParams(queryString);
      const containerId: any = params.get('containerId') || 0;
      const externalLink: any =
        params.get('link_warehouse_id') || params.get('link_vendor_id') || 0;
      const search: string = params.get('search') || '';
      if (search) {
        setSearchQueryType(SEARCH_QUERY_TYPES[0].value);
        setSearchQuery(search);
        multipleQueriesRef.current = '';
        searchRef.current = search;
        params.delete('search');
        const newLocationSearch = params.toString();
        // 更新 url 并跳过后续逻辑
        // 另外两个 useEffect 包含异步操作（请求等）
        // 如果不使用 ref 来保留状态并处理，会被 handleTabChange 覆盖状态
        return history.replace(
          location.pathname +
            (newLocationSearch ? `?${newLocationSearch}` : ''),
        );
      }
      if (!externalLink) {
        _fetchData(
          pagination,
          [
            {
              attribute: 'id',
              text: 'CNTR ID: ' + containerId,
              value: containerId,
            },
          ],
          true,
        );
      }
    } else if (searchRef.current) {
      const search = searchRef.current;
      setSearchQuery(search);
      _fetchData(pagination, undefined, !search, search);
    }
  }, [location.search]);

  const columns: ColumnsType<any> = React.useMemo(() => {
    const sort = filterValues?.find((f) => f.attribute === 'sort') || null;
    const _columns: ColumnsType<any> = defaultColumns?.map((c: any) => ({
      ...c,
      sortOrder: sortOrder(sort, c.key),
    }));

    const tab = tabs.find((t) => t.id === activeKey);
    const _defaultColumns = _columns?.map((c) => c.key);
    return _columns.filter((c) =>
      (tab?.columns || _defaultColumns)?.includes(c.key),
    );
  }, [filterValues, tabs, activeKey, pagination, defaultColumns]);
  // const location = useLocation();

  const fetchDataForOpDashboard = () => {
    const filterParams = app.store.opDashboard.getFilterParams();

    const _filter = [];

    filterParams.container_ids &&
      _filter.push({
        attribute: 'id',
        text: 'Container ID: ' + filterParams.container_ids.join(', '),
        value: filterParams.container_ids,
      });

    if (
      (filterParams.container_ids && filterParams.progress_key) ||
      filterParams.task_warning_type
    ) {
      filterParams.opdashboard_tab_id &&
        _filter.push({
          attribute: 'opdashboard_tab_id',
          text: 'Tab ID: ' + filterParams.opdashboard_tab_id,
          value: filterParams.opdashboard_tab_id,
        });
    }

    filterParams.task_warning_type &&
      _filter.push({
        attribute: 'task_warning_type',
        text: 'Task Warning Type: ' + filterParams.task_warning_type,
        value: filterParams.task_warning_type,
      });

    filterParams.date &&
      _filter.push({
        attribute: 'opdashboard_date',
        text: 'Date: ' + filterParams.date,
        value: filterParams.date,
      });

    filterParams.progress_key &&
      _filter.push({
        attribute: 'progress_type',
        text: 'Progress: ' + filterParams.progress_key,
        value: filterParams.progress_key,
      });

    app.store.opDashboard.setCurrentDate('');
    app.store.opDashboard.setCurrentProgressKey('');
    app.store.opDashboard.setContainerIds([]);
    app.store.opDashboard.setOpdashboardTabId(0);
    app.store.opDashboard.setTaskWarningType(undefined);

    _fetchData({ current: 1 }, _filter);

    setFilterValues(_filter);
    return;
  };

  React.useEffect(() => {
    if (
      app.store.opDashboard.containerIds.length > 0 ||
      app.store.opDashboard.taskWarningType
    ) {
      return fetchDataForOpDashboard();
    }

    const queryString = location.search;
    if (queryString) {
      const params = new URLSearchParams(queryString);
      const externalLinkWarehouseId: any = params.get('link_warehouse_id') || 0;
      const externalLinkTruckerId: any = params.get('link_vendor_id') || 0;
      if (!externalLinkWarehouseId && !externalLinkTruckerId) {
        fetchTabs();
        return;
      }
      const _filter = [];
      if (externalLinkWarehouseId) {
        _filter.push({
          attribute: 'warehouse_id',
          text: 'Warehouse ID: ' + externalLinkWarehouseId,
          value: externalLinkWarehouseId,
        });
      }

      if (externalLinkTruckerId) {
        _filter.push({
          attribute: 'vendor_id',
          text: 'Vendor ID: ' + externalLinkTruckerId,
          value: externalLinkTruckerId,
        });
      }

      _fetchData(pagination, _filter);

      setFilterValues(_filter);
    } else {
      fetchTabs();
    }
  }, []);

  const handleAddTab = async () => {
    setLoading(true);
    try {
      const reps = await app.service.post('dispatchTabs', {
        data: { name: `Tab ${tabs.length}`, mode: tabMode },
      });
      setTabs([...tabs, { ...reps.data, filters: [] }]);
      setActiveKey(reps.data.id);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activeKey) {
      const filters = tabs.find((tab) => tab.id === activeKey)?.filters || [];
      if (location.search) {
        const params = new URLSearchParams(location.search);
        const open: any = params.get('open');
        if (!open) {
          history.replace(location.pathname);
        }
        setFilterValues([]);
      } else if (!location.search) {
        // 最终处理搜索参数
        const search = searchRef.current;
        history.replace(location.pathname);
        setFilterValues(filters);
        _fetchData(pagination, filters, !search, search);
        searchRef.current = '';
      }
    }
  }, [activeKey]);

  const toFilterSortValue = (
    label: string,
    sortBy: string,
    sortValue: string,
  ) => {
    const flag = sortValue.toUpperCase();
    return toFilterValue(`${label} ${flag}`, 'sort', `${sortBy}|${sortValue}`);
  };

  const handleSaveUpdateTab = async (id: number, data: any) => {
    if (!data?.name) {
      return;
    }
    const resp = await app.service.put(`dispatchTabs/${id}`, {
      data: data,
    });
    setTabs(tabs.map((t) => (t.id == id ? resp.data : t)));
    setFilterValues(resp.data.filters);
  };

  const updateTab = React.useCallback(
    async (filters: any, pagination: any, isEmptyQuery?: boolean) => {
      setLoading(true);
      const tab = tabs.find((tab) => tab.id === activeKey);
      try {
        await handleSaveUpdateTab(activeKey, {
          ...tab,
          filters,
          mode: tabMode,
        });

        _fetchData(pagination, filters, isEmptyQuery);
      } catch (err: any) {
        message.error(err.data?.message || err.data?.error);
      } finally {
        setLoading(false);
      }
    },
    [filterValues],
  );

  const handleSort = React.useCallback(
    (pagination: any, sortBy: string, sortValue: string) => {
      if (!sortValue) {
        handleRemoveSortInFilterValues(sortBy);
        _fetchData(pagination, filterValues, searchQuery ? false : true);
        return;
      }

      const label: any = columns.find((c) => c.key == sortBy);
      if (!label) {
        handleRemoveSortInFilterValues(sortBy);
        _fetchData(pagination, filterValues, searchQuery ? false : true);
        return;
      }

      const filterSortValue = toFilterSortValue(label.title, sortBy, sortValue);
      if (!filterValues) {
        _fetchData(pagination, filterValues, searchQuery ? false : true);
        return;
      }

      const index = filterValues.findIndex((fv) => fv.attribute === 'sort');
      if (index !== -1) {
        updateTab(
          update(filterValues, { [index]: { $set: filterSortValue } }),
          pagination,
          searchQuery ? false : true,
        );
      } else {
        updateTab(
          [...filterValues, filterSortValue],
          pagination,
          searchQuery ? false : true,
        );
      }
    },
    [filterValues, searchQuery],
  );

  const handleRemoveSortInFilterValues = (sortBy: string) => {
    if (!filterValues) {
      return;
    }

    const index = filterValues.findIndex((fv) => fv.attribute === 'sort');
    if (-1 === index) {
      return;
    }
    const sortIndex = filterValues[index].value.indexOf(`${sortBy}|`);
    if (-1 === sortIndex) {
      return;
    }
    handleRemoveFilter(index);
  };

  const handleRemoveAllFilters = () => {
    setFilterValues([]);
    updateTab([], pagination);
  };

  const handleRemoveFilter = React.useCallback(
    (index: number) => {
      updateTab(update(filterValues, { $splice: [[index, 1]] }), pagination);
    },
    [filterValues, pagination],
  );

  const handleDeleteTab = async (id: number) => {
    if (tabs.length == 1) {
      return;
    }

    setLoading(true);
    try {
      await app.service.delete(`dispatchTabs/${id}`);
      setTabs(tabs.filter((t) => t.id != id));
      message.success('Tab has been delete');
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateTab = async (
    name: string,
    value: string | any[],
    id?: number,
  ) => {
    id = id || editTab;
    const tab = tabs.find((tab) => tab.id === id);
    if (tab) {
      try {
        await handleSaveUpdateTab(id, { ...tab, [name]: value, mode: tabMode });
      } catch (err: any) {
        message.error(err.data?.message || err.data?.error);
      } finally {
        setEditTab(0);
      }
    }
  };

  const handleSearch = (value?: any) => {
    _fetchData(
      {
        current: 1,
        pageSize: pagination?.pageSize || 20,
      },
      filterValues,
      value ? false : true,
    );
  };

  const handleSelectSearchQueryType = useCallback((value: string) => {
    setSearchQueryType(value);
    setSearchQuery('');
    multipleQueriesRef.current = '';
  }, []);
  const handleSearchMultipleQueriesSelectChange = useCallback(
    (value: string[]) => {
      // clear multiple queries
      if (value.length === 0) {
        multipleQueriesRef.current = '';
        handleSearch();
        return;
      }
      // max count message
      if (value.length >= SEARCH_QUERY_TYPE_MAX_COUNT) {
        message.warning(
          `You have reached the maximum limit of ${SEARCH_QUERY_TYPE_MAX_COUNT} CNTRs, and only the first ${SEARCH_QUERY_TYPE_MAX_COUNT} are being used.`,
        );
      }
      // deduplicate & limit count
      multipleQueriesRef.current = [...new Set(value)]
        .slice(0, SEARCH_QUERY_TYPE_MAX_COUNT)
        .join(',');
    },
    [handleSearch],
  );
  const handleSearchMultipleQueriesSelectInputKeydown = (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (e.key === 'Enter') {
      // 如果输入框有值，需要更新 tags，不需要发起搜索
      if (e.currentTarget.value) {
        return;
      }
      // 如果输入框没有值，发起搜索
      handleSearch();
    }
  };

  const handleSaveBulkUpdate = async () => {
    _fetchData(pagination);
    handelCloseBulkUpdate();
  };

  const handleBulkUpdate = () => {
    if (selectedRowKeys?.length <= 0) {
      message.error('Please select data to update');
      return false;
    }
    if (accountingMode) {
      setOpenAccountingModeBulkUpdate(true);
    } else {
      setOpenBulkUpdate(true);
    }
  };

  const handelCloseBulkUpdate = () => {
    setSelectedRowKeys([]);
    if (accountingMode) {
      setOpenAccountingModeBulkUpdate(false);
    } else {
      setOpenBulkUpdate(false);
    }
  };

  const handleExport = async () => {
    if (selectedRowKeys?.length <= 0 && !app.store.auth.superAdmin) {
      message.error('Please select data to Export');
      return false;
    }

    const ids = selectedRowKeys;

    setLoading(true);
    try {
      await app.service.download('dispatchTabs/excel', {
        params: {
          ids,
          accountingMode,
          ...convertFilterValuesToQueryParams(filterValues || []),
        },
      });
    } catch (err: any) {
      message.error(err?.data?.message || 'System Error');
    } finally {
      setLoading(false);
    }
  };

  const checkedColumns = useMemo(() => {
    const tab = tabs.find((tab) => tab.id === activeKey);
    return tab?.columns || defaultColumns?.map((c: any) => c.key);
  }, [activeKey, tabs]);

  // const checkedFilterItems = useMemo(() => {
  //   const tab = tabs.find((tab) => tab.id === activeKey);
  //   return tab?.displayed_filter_items || [];
  // }, [activeKey, tabs]);

  const tabMenu = (id: number): MenuProps['items'] => {
    const items: MenuProps['items'] = [
      {
        label: 'Edit',
        key: 'edit' + id,
        onClick: () => {
          setEditTab(id);
        },
      },
      {
        label: 'Delete',
        key: 'delete' + id,
        onClick: () => {
          handleDeleteTab(id);
        },
      },
    ];

    return items;
  };

  const handleTabMoved = (order: any[] = []) => {
    app.service.post('dispatchTabs/tabsOrder', {
      data: { order, mode: tabMode },
    });
    setOrder(order);
  };

  const _tabs = useMemo(() => {
    if (!order || order.length == 0) {
      return tabs;
    }
    const _order = order.map((i: string) => +i);
    const _tabs = [...tabs].sort((a, b) => {
      const orderA = _order.indexOf(a.id);
      const orderB = _order.indexOf(b.id);

      if (orderA !== -1 && orderB !== -1) {
        return orderA - orderB;
      }
      if (orderA !== -1) {
        return -1;
      }
      if (orderB !== -1) {
        return 1;
      }

      const ia = tabs.indexOf(a);
      const ib = tabs.indexOf(b);

      return ia - ib;
    });
    return _tabs;
  }, [order, tabs]);

  const handleShowTabCount = async () => {
    try {
      const resp = await app.service.get('dispatchTabs/tabCounts', {
        params: { mode: tabMode },
      });

      setTabCounts(resp);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    }
  };

  return (
    <>
      <div className={styles.main}>
        <Header
          title={headerTitle}
          rightElement={
            <Space.Compact>
              <Select
                disabled={loading}
                options={SEARCH_QUERY_TYPES}
                value={searchQueryType}
                onSelect={handleSelectSearchQueryType}
                style={{ width: '140px' }}
              ></Select>
              {searchQueryType === '' ? (
                <Input.Search
                  style={{ width: '600px' }}
                  placeholder="Search by MBL#, CNTR#, #REF, #DO, #Order..."
                  allowClear
                  disabled={loading}
                  enterButton="Search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onSearch={handleSearch}
                />
              ) : (
                <>
                  <Select
                    disabled={loading}
                    mode="tags"
                    style={{ width: '525px' }}
                    onChange={handleSearchMultipleQueriesSelectChange}
                    tokenSeparators={SEARCH_QUERY_TYPE_TOKEN_SEPARATORS}
                    maxTagCount="responsive"
                    open={false}
                    notFoundContent={null}
                    allowClear
                    onInputKeyDown={
                      handleSearchMultipleQueriesSelectInputKeydown
                    }
                    maxTagPlaceholder={
                      handleSearchMultipleQueriesSelectMaxTagPlaceholder
                    }
                    suffixIcon={null}
                    placeholder="Multiple CNTRs should be separated by commas or placed on separate lines"
                  ></Select>
                  <Button
                    disabled={loading}
                    type="primary"
                    onClick={() => handleSearch(searchQuery)}
                  >
                    Search
                  </Button>
                </>
              )}
            </Space.Compact>
          }
        />
        <Spin spinning={loading || props.loading}>
          <div className="pl-sm pr-sm">
            {/* <DraggableTabs tabBarExtraContent={
              <Button type="link" onClick={handleAddTab}>
                New Tab
              </Button>
            }>
              {tabs.map((tab: TDispatchTab) => {
                <TabPane tab={editTab !== tab.id ? (
                  <Dropdown
                    menu={{ items: tabMenu(tab.id) }}
                    trigger={['contextMenu']}>
                    <div>{tab.name}</div>
                  </Dropdown>
                ) : (
                  <EditableText
                    value={tab.name}
                    onChange={handleUpdateTab}
                    onHide={() => setEditTab(0)}
                    name="name"
                    autoFocus
                  />
                )} key={tab.id}>{tab.name}</TabPane>;

              })}
            </DraggableTabs> */}
            <DraggableTabs
              onChange={handleTabChange}
              onTabMoved={handleTabMoved}
              activeKey={'' + activeKey}
              tabBarExtraContent={
                <Space>
                  <Tooltip title="Update Counts">
                    <Button
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      onClick={handleShowTabCount}
                      shape="circle"
                    >
                      <HiRefresh size={18} color="#1890ff" />
                    </Button>
                  </Tooltip>
                  <Tooltip title=" New Tab">
                    <Button
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      shape="circle"
                      onClick={handleAddTab}
                    >
                      <FaPlus size={18} color="#1890ff" />
                    </Button>
                  </Tooltip>
                </Space>
              }
              items={_tabs.map((tab: TDispatchTab) => {
                return {
                  label:
                    editTab !== tab.id ? (
                      <Dropdown
                        menu={{ items: tabMenu(tab.id) }}
                        trigger={['contextMenu']}
                      >
                        <div>
                          {tab.name}
                          {tabCounts[tab.id] == undefined ? (
                            ''
                          ) : (
                            <span
                              style={{
                                background:
                                  activeKey == tab.id ? '#F0F9FF' : '#F0F0F0',
                                borderRadius: '100px',
                                color:
                                  activeKey == tab.id ? '#2F6EC0' : '#8C8C8C',
                                padding: '0px 8px',
                                fontWeight: '400',
                              }}
                            >
                              {tabCounts[tab.id]}
                            </span>
                          )}
                        </div>
                      </Dropdown>
                    ) : (
                      <EditableText
                        value={tab.name}
                        onChange={handleUpdateTab}
                        onHide={() => setEditTab(0)}
                        name="name"
                        autoFocus
                      />
                    ),
                  key: '' + tab.id,
                };
              })}
            ></DraggableTabs>
          </div>
          {_tabs.length > 0 && (
            <Row>
              <Col span={16}>
                <Filter
                  show={isShowFilter}
                  values={filterValues || []}
                  accountingMode={accountingMode}
                  onChange={(v: any) => {
                    setSearchQuery('');
                    updateTab(
                      v,
                      { current: 1, pageSize: pagination?.pageSize || 20 },
                      true,
                    );
                  }}
                  removeAll={handleRemoveAllFilters}
                  removeOne={handleRemoveFilter}
                  activeFilters={
                    tabs.find((tab) => tab.id === activeKey)?.filters || []
                  }
                  hideFilter={() => setIsShowFilter(false)}
                  loading={loading}
                />
              </Col>
              <Col
                span={8}
                style={{
                  marginTop: isShowFilter ? '48px' : '0',
                  marginBottom: isShowFilter ? '0' : '20px',
                }}
              >
                <Space>
                  <Button
                    onClick={() => {
                      setIsShowFilter((isShowFilter) => !isShowFilter);
                    }}
                  >
                    {isShowFilter ? 'Hide Filters' : 'Show Filters'}
                  </Button>
                  <Dropdown
                    menu={{
                      items: [
                        {
                          key: '1',
                          label: (
                            <div
                              className="cursor-pointer"
                              onClick={handleBulkUpdate}
                            >
                              Update Others
                            </div>
                          ),
                        },
                        {
                          key: '2',
                          label: (
                            <SellRateBulkUpdate
                              containers={selectedRows}
                              onSaved={() => _fetchData(pagination)}
                            />
                          ),
                        },
                        {
                          key: '3',
                          label: (
                            <SellRateBulkUpdateWithExcel
                              containers={selectedRows}
                              onSaved={() => _fetchData(pagination)}
                            />
                          ),
                        },
                      ],
                    }}
                  >
                    <Button type="primary" ghost>
                      Bulk Update
                    </Button>
                  </Dropdown>
                  <Button type="primary" ghost onClick={handleExport}>
                    Export
                  </Button>
                  <ColorTagSelect
                    selectedRows={selectedRows}
                    onApply={(rows) => {
                      setSelectedRows(rows);
                      _fetchData(pagination);
                    }}
                  />
                  <div>
                    <ManageColumns
                      items={defaultColumns?.map((c: any) => {
                        return (
                          {
                            key: c.key,
                            name:
                              c.title instanceof Function ? c.label : c.title,
                          } || []
                        );
                      })}
                      checkedColumns={checkedColumns}
                      onChange={(list) =>
                        handleUpdateTab('columns', list, activeKey)
                      }
                    />
                  </div>
                </Space>
              </Col>
            </Row>
          )}

          <div>
            <DispatchTable
              accountingMode={accountingMode}
              tab={activeKey}
              data={data}
              columns={columns}
              setData={setData}
              fetchData={_fetchData}
              handleSort={handleSort}
              setDefaultColumns={setDefaultColumns}
              onSetPagination={setPagination}
              onClickPagination={(pagination) => {
                _fetchData(
                  pagination,
                  filterValues,
                  searchQuery ? false : true,
                );
              }}
              onSetSelected={(
                selectedRowKeys: React.Key[],
                selectedRows: any[],
              ) => {
                setSelectedRowKeys(selectedRowKeys);
                setSelectedRows(selectedRows);
              }}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                      activeKey === 0
                        ? 'Please add new tab to view data.'
                        : 'No Data'
                    }
                  />
                ),
              }}
            />
          </div>
        </Spin>
      </div>

      {openBulkUpdate && selectedRows.length > 0 && (
        <DrawerFormBulkUpdate
          containers={selectedRows}
          open={openBulkUpdate}
          onClose={handelCloseBulkUpdate}
          onSubmit={handleSaveBulkUpdate}
        />
      )}

      {openAccountingModeBulkUpdate && selectedRows.length > 0 && (
        <AccountingModeBulkUpdate
          containers={selectedRows}
          open={openAccountingModeBulkUpdate}
          onClose={handelCloseBulkUpdate}
          onSubmit={handleSaveBulkUpdate}
        />
      )}
    </>
  );
};

export default Index;
