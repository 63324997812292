import { CSSProperties, memo, useCallback, useState } from 'react';
import { get } from 'lodash';
import { TerminalRedirectLink } from '@/components/TerminalRedirectLink';
import { Button, Typography, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { TerminalSelect } from '@/components/TerminalSelect';
import { SyncCacheModelSelectProps } from '@/components/SyncCacheModelSelect';

const containerStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
};

const buttonStyle: CSSProperties = {
  flex: 'none',
};

const truncateStyle: CSSProperties = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

interface EditableTerminalSelectColumnProps {
  record: any;
  path: string[];
  onSelect: (model: any) => void;
}

const EditableTerminalSelectColumn = memo(
  ({ record, path, onSelect }: EditableTerminalSelectColumnProps) => {
    const [isEditing, setIsEditing] = useState(false);
    const handleEdit = () => {
      setIsEditing(true);
    };

    const value = get(record, path, undefined);
    const label = value ? value.firms + ' | ' + value.name : null;

    const handleSelect: SyncCacheModelSelectProps['onSelect'] = useCallback(
      (model: any) => {
        onSelect?.(model);
        setIsEditing(false);
      },
      [onSelect],
    );

    const handleBlur = useCallback(() => {
      setIsEditing(false);
    }, []);

    if (!isEditing) {
      return (
        <div style={containerStyle}>
          <Button
            style={buttonStyle}
            disabled={!value?.id}
            type="link"
            className="ant-btn-icon-only select-modal-icon"
          >
            <TerminalRedirectLink id={value?.id || 0} />
          </Button>
          {value ? (
            <Tooltip placement="topLeft" title={label}>
              <span style={truncateStyle}>
                <Typography.Link underline onClick={handleEdit}>
                  <strong>{label}</strong>
                </Typography.Link>
              </span>
            </Tooltip>
          ) : (
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={handleEdit}
            ></Button>
          )}
        </div>
      );
    }

    return (
      <TerminalSelect
        open
        autoFocus
        selected={value}
        redirectable
        onSelect={handleSelect}
        onBlur={handleBlur}
      />
    );
  },
);

EditableTerminalSelectColumn.displayName = 'EditableTerminalSelectColumn';

export default EditableTerminalSelectColumn;
