import { Button, Form, List, Modal, Tooltip, Avatar, message } from 'antd';
import { CACHE_ADMIN_KEY } from '@/stores/cache';
import { useEffect, useMemo, useState } from 'react';
import React from 'react';
import { AlignLeftOutlined } from '@ant-design/icons';
import { sortBy, truncate } from 'lodash';
import { useApp } from '@/utils/useapp';
import { observer } from 'mobx-react';
import { EditMentionsInput } from './EditMentionsInput';
import { MemoList } from './MemoList';
import { appendMemoAttributeToData } from './data';
import WithMentionHighlighter from '../WithMentionHighlighter';

type TargetType = {
  target: string;
  targetId: number;
  memoAttribute?: string;
  options?: Record<string, any>;
  onSaved?: (targetId: number) => void;
};

type CustomSaveAndFetchType = {
  toSave?: (comment: string) => void;
  fetch?: (setComment: (comment: string) => void) => void;
};

export const MemoAction: React.FC<
{
  initialValue: string;
} & TargetType &
CustomSaveAndFetchType
> = observer(({ initialValue, targetId, ...props }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [comments, setComments] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');

  const isModelValid = targetId && props.target;

  const app = useApp();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSave = async () => {
    if (props.toSave) {
      return props.toSave(comment);
    }

    if (!isModelValid) {
      return;
    }

    setLoading(true);
    try {
      const resp = await app.service.post(
        `adminComments/${props.target}/${targetId}`,
        {
          data: appendMemoAttributeToData({ comment }, props?.memoAttribute),
        },
      );
      props.onSaved && props.onSaved(targetId);
      setComment('');
      const data = resp.data;
      setComments([{ ...data, pin: 0 }, ...comments]);
    } catch (err: any) {
      message.error(err?.data?.message || err?.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const fetchComments = async () => {
    if (props.fetch) {
      return props.fetch(setComment);
    }
    if (!isModelValid) {
      return;
    }

    setLoading(true);
    try {
      const resp = await app.service.get(
        `adminComments/${props.target}/${targetId}`,
      );
      setComments(resp.data);
    } catch (err: any) {
      message.error(err?.data?.message || err?.data?.error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isModalVisible) {
      fetchComments();
    }
  }, [isModalVisible]);

  const rows = app.store.cache.data[CACHE_ADMIN_KEY];

  if (rows === undefined) {
    app.store.cache.fetch(CACHE_ADMIN_KEY);
  }

  return (
    <>
      {initialValue ? (
        <Tooltip
          placement="left"
          title={
            <WithMentionHighlighter
              content={truncate(initialValue, { length: 100 })}
              highlighterColor={'#ffffff'}
            />
          }>
          <div className="text-primary" onClick={showModal}>
            <AlignLeftOutlined />
          </div>
        </Tooltip>
      ) : (
        <div className="text-gray" onClick={showModal}>
          <AlignLeftOutlined />
        </div>
      )}
      {isModalVisible && (
        <Modal
          cancelText="Close"
          footer={false}
          title="Memo"
          width={1000}
          open={isModalVisible}
          onCancel={handleCancel}>
          <Form layout="vertical" onFinish={() => handleSave()}>
            <Form.Item label="">
              <EditMentionsInput
                comment={comment}
                setComment={(comment) => setComment(comment)}
              />
            </Form.Item>
            <Button disabled={loading} type="primary" htmlType="submit">
              Save
            </Button>
          </Form>
          <MemoList targetId={targetId} {...props} />
        </Modal>
      )}
    </>
  );
});
