import {
  Button,
  DatePicker,
  Form,
  FormInstance,
  Input,
  message,
  Modal,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { PossibleCharges } from '@/components/PossibleCharges';
import { useApp } from '@/utils/useapp';
import moment from 'moment';
import { TPossibleCharge } from '@/types';
import { VendorSelect } from '@/components/VendorSelect';

const CopyModal: React.FC<{
  onSelect: any;
}> = ({ onSelect }) => {
  const app = useApp();
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState<any | null>(null);

  const showModal = () => {
    setShow(true);
  };

  const handleCancel = () => {
    setShow(false);
  };

  const handleSelect = (vendor: any) => {
    setSelected(vendor);
  };

  const handleCopy = async () => {
    if (selected) {
      try {
        const resp = await app.service.get(`vendors/${selected.id}`);
        onSelect(resp.data.possible_charges);
        setShow(false);
      } catch (e: any) {
        message.error('Failed to copy');
      }
    }
  };
  return (
    <>
      <Button type="primary" onClick={showModal}>
        Copy Possible Charges
      </Button>
      <Modal
        zIndex={1005}
        title="Copy Possible Charges"
        open={show}
        onOk={() => handleCopy()}
        okText={'Copy'}
        onCancel={handleCancel}>
        <VendorSelect
          className="w100"
          selected={selected}
          onSelect={handleSelect}
        />
      </Modal>
    </>
  );
};

interface IPossibleChargesTab {
  form: FormInstance;
  handleChange: (event: any) => void;
}

export const PossibleChargesTab: React.FC<IPossibleChargesTab> = ({
  form,
  handleChange,
}) => {
  const [currencyRate, setCurrencyRate] = useState<number | undefined>();
  const app = useApp();

  const getCurrencyRate = async () => {
    try {
      const resp = await app.service.get('currencyRate');
      setCurrencyRate(resp.data.rates['CAD']);
    } catch (err: any) {
      message.error(err?.data?.message || err?.data?.error);
    }
  };

  useEffect(() => {
    getCurrencyRate();
  }, []);

  const handleCopyPossibleCharges = (possibleCharges: TPossibleCharge[]) => {
    form.setFieldValue(
      'possible_charges',
      possibleCharges.map(({ id, ...p }) => p),
    );
  };

  const handleClickAdd = () => {
    if (!form.getFieldValue('update_possible_charges_at')) {
      form.setFieldsValue({
        update_possible_charges_at: moment().format('YYYY-MM-DD'),
      });
    }
  };

  return (
    <>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => (
          <Form.Item
            name="possible_charges"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}>
            <PossibleCharges
              intermodal_region_id={getFieldValue('intermodal_region_id')}
              rightHeader={
                <>
                  <Form.Item name="update_possible_charges_at" noStyle>
                    <Input hidden />
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue, setFieldsValue }) => {
                      const update_possible_charges_at = getFieldValue(
                        'update_possible_charges_at',
                      );
                      return (
                        <DatePicker
                          style={{ width: '100%' }}
                          value={
                            update_possible_charges_at
                              ? moment(update_possible_charges_at)
                              : null
                          }
                          onChange={(value) =>
                            setFieldsValue({
                              update_possible_charges_at: value
                                ? value?.format('YYYY-MM-DD')
                                : null,
                            })
                          }
                        />
                      );
                    }}
                  </Form.Item>
                </>
              }
              extra={<CopyModal onSelect={handleCopyPossibleCharges} />}
              clickAdd={handleClickAdd}
              data={getFieldValue('possible_charges') || []}
              updateData={(list) => {
                handleChange({
                  target: { name: 'possible_charges', value: list },
                });
              }}
              currencyRate={currencyRate}
            />
          </Form.Item>
        )}
      </Form.Item>
    </>
  );
};
