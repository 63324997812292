import React from 'react';

const ClickWrapper: React.FC<{
  children: React.ReactNode;
  onClick: () => void;
}> = ({ children, onClick }) => {
  return (
    <>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          onClick: onClick,
        });
      })}
    </>
  );
};

export default ClickWrapper;
