import styles from 'res/css/editor.scss';

export const EditorToolBar = {
  options: [
    'inline',
    'blockType',
    'fontSize',
    'list',
    'textAlign',
    'history',
    'colorPicker',
  ],
  // blockType: {
  //   inDropdown: false,
  //   options: [
  //     'Normal',
  //     'H1',
  //     'H2',
  //     'H3',
  //     'H4',
  //     'H5',
  //     'H6',
  //     'Blockquote',
  //     'Code',
  //   ],
  // },
  // colorPicker: {
  //   inDropdown: false,
  // },
  // fontSize: {
  //   inDropdown: false,
  //   options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
  // },
  // textAlign: {
  //   inDropdown: false,
  // },
  // list: {
  //   inDropdown: false,
  //   indent: { className: styles.indent },
  //   outdent: { className: styles.outdent },
  // },
};
