import React, { useEffect } from 'react';

import { Space, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';

export const PresetDataDropDown: React.FC<{
  presetDataMap?: any;
  handleClickPresetData: (e: any) => void;
}> = ({ presetDataMap, handleClickPresetData }) => {
  const handleMenuClick: MenuProps['onClick'] = (e) => {
    handleClickPresetData(e);
  };

  const items: MenuProps['items'] = React.useMemo(() => {
    if (!presetDataMap) {
      return [];
    }

    return [
      ...Object.keys(presetDataMap).map((key: string) => ({
        key: key,
        label: presetDataMap[key as unknown as keyof typeof presetDataMap],
      })),
    ];
  }, [presetDataMap]);

  if (!presetDataMap || typeof presetDataMap !== 'object') {
    return <></>;
  } else {
    return (
      <div style={{ display: 'inline' }}>
        <Dropdown
          menu={{
            items,
            onClick: handleMenuClick,
          }}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              Preset Data
              <DownOutlined />
            </Space>
          </a>
        </Dropdown>
      </div>
    );
  }
};
