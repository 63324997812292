import { Form, Col, Row } from 'antd';
import { FormInstance } from 'antd/es/form';
import { get } from 'lodash';
import React from 'react';
import { ContactList } from '@/components/ContactList';

interface IContactsTab {
  id: number | null;
  form: FormInstance;
  handleChange: (event: any) => void;
}

export const ContactsTab: React.FC<IContactsTab> = ({
  id,
  form,
  handleChange,
}) => {
  return (
    <>
      {id && (
        <Row gutter={10}>
          <Col span={24}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <Form.Item
                  name="contacts"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}>
                  <ContactList
                    data={getFieldValue('contacts') || []}
                    showRequestRate={true}
                    updateData={(list) =>
                      handleChange({
                        target: { name: 'contacts', value: list },
                      })
                    }
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
        </Row>
      )}
    </>
  );
};
