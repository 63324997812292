import React, { useEffect, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button, Form, Select, Modal, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useApp } from '@/utils/useapp';
import SyncCacheModelSelect from './SyncCacheModelSelect';
import { CACHE_RATE_STATEMENTS_KEY } from '@/stores/cache';

const { Option } = Select;

const STATEMENT_TYPE_GENERAL = 0;
const STATEMENT_TYPE_OVERWEIGHT = 1;
const STATEMENT_TYPE_HAZMAT = 2;
const STATEMENT_TYPE_REEFER = 3;

const STATEMENT_TYPE_MAP = {
  [STATEMENT_TYPE_GENERAL]: 'General',
  [STATEMENT_TYPE_OVERWEIGHT]: 'Overweight',
  [STATEMENT_TYPE_HAZMAT]: 'Hazmat',
  [STATEMENT_TYPE_REEFER]: 'Reefer',
};

export const RateScenarioStatementForm: React.FC<{
  id: number | null;
  onSave?: (data?: any) => void;
  onClose?: () => void;
  visible: boolean;
}> = ({ id, onSave, onClose, visible }) => {
  const app = useApp();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const fetchData = async (id: number) => {
    setLoading(true);
    try {
      const resp = await app.service.get(`rateScenarioStatements/${id}`);
      form.setFieldsValue({ ...resp.data });
    } catch (err: any) {
      message.error(err.data.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!id) {
      return;
    }
    fetchData(id);
  }, [id]);

  const handleOk = async () => {
    const data = await form.validateFields();
    setLoading(true);
    const formValues = Object.assign({}, data);
    try {
      const resp = !id
        ? await app.service.post('rateScenarioStatements', { data: formValues })
        : await app.service.put(`rateScenarioStatements/${id}`, {
          data: formValues,
        });
      onSave && onSave(resp.data);
      onClose && onClose();
      form.resetFields();
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      title="New Statement"
      open={visible}
      onCancel={onClose}
      okText="Create"
      cancelText="Cancel"
      onOk={handleOk}>
      <Form
        layout="vertical"
        disabled={loading}
        form={form}
        name="control-hooks"
        onFinish={onSave}>
        <Form.Item name="type" label="Type" rules={[{ required: true }]}>
          <Select placeholder="Select Type" allowClear>
            {Object.keys(STATEMENT_TYPE_MAP).map((key) => (
              <Option key={key} value={+key}>
                {
                  STATEMENT_TYPE_MAP[
                    key as unknown as keyof typeof STATEMENT_TYPE_MAP
                  ]
                }
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="statement"
          label="Statement"
          rules={[{ required: true }]}>
          <TextArea rows={6} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export const RateScenarioStatementSelect: React.FC<{
  selected: any;
  onSelect: (id: string) => void;
  className?: string;
  editable?: boolean;
}> = ({ selected, onSelect, editable, ...props }) => {
  const app = useApp();
  const [id, setId] = React.useState(null);
  const [visible, setVisible] = React.useState(false);
  const handleSave = (data: any) => {
    onSelect(data.id);
    setVisible(false);
    setId(null);
    app.store.cache.fetch(CACHE_RATE_STATEMENTS_KEY, true);
  };

  const handleClose = () => {
    setId(null);
    setVisible(false);
  };

  return (
    <>
      <div className="flex">
        {editable && (
          <Button
            onClick={() => {
              // console.log(selected);
              setId(selected);
              setVisible(true);
            }}
            className="ant-btn-icon-only">
            <EditOutlined
              style={{
                color: '#00000073',
              }}
            />
          </Button>
        )}
        <SyncCacheModelSelect
          {...props}
          renderOption={(option: any) => {
            return (
              option.statement +
              ' | ' +
              [
                STATEMENT_TYPE_MAP[
                  option.type as keyof typeof STATEMENT_TYPE_MAP
                ],
              ]
            );
          }}
          cacheKey={CACHE_RATE_STATEMENTS_KEY}
        />
      </div>
      {/* <ModelSelect
          {...props}
          selected={selected}
          onSelect={onSelect}
          dropdownStyle={{ zIndex: 10010 }}
          renderOption={(option: any) => {
            return (
              option.statement +
              ' | ' +
              [
                STATEMENT_TYPE_MAP[
                  option.type as keyof typeof STATEMENT_TYPE_MAP
                ],
              ]
            );
          }}
          model="rateScenarioStatements"
        />
      </div> */}

      <RateScenarioStatementForm
        id={id}
        onSave={handleSave}
        onClose={handleClose}
        visible={visible}
      />
    </>
  );
};
