import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  List,
  Row,
  Select,
  Space,
  TimePicker,
  Typography,
  message,
} from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import React, { FC, useEffect } from 'react';
import { CitySelect } from '@/components/CitySelect';
import { ContactList } from '@/components/ContactList';
import { useWatch } from 'antd/es/form/Form';
import { has, isArray, isString } from 'lodash';
import styles from 'res/css/ui.scss';
import type { FormInstance } from 'antd/es/form';

import { UserSyncSelect } from '@/components/UserSyncSelect';
import { TIMEZONE, WEEKDAYS } from '../data';
import moment from 'moment';
import { Documents } from '@/components/Upload/Documents';
import { SpecialRequirements } from './SpecialRequirements';
import AddressAutoFill from '@/components/AddressAutoFill';
import { EnvironmentOutlined } from '@ant-design/icons';
import { useApp } from '@/utils/useapp';

const DO_ADDRESS_RELATED_FIELDS = [
  'name',
  'address',
  'city',
  'zipcode',
  'city_id',
  'country',
  // 'contacts',
];

const UNKNOWN = 0;
const NO_APT_NEEDED = 1;
const CUSTOMER = 2;
const DRAYEASY = 3;
const TRUCKER = 4;

interface IWarehouseForm {
  id: number | null;
  form: FormInstance;
  disabled?: boolean;
  verifyMessage: any;
  forbidToModfiySpecifyField?: boolean;
  handleVerify: () => void;
  handleSyncShiplify: () => void;
}

const Shiplify: FC<{
  shiplify: any;
  onSync: () => void;
  disabled: boolean;
}> = ({ shiplify, onSync, disabled = false }) => {
  const dockAccess = shiplify?.data?.dock_access;
  const forklift = shiplify?.data?.forklift;
  const locationTypes = shiplify?.data?.location_types;

  return (
    <>
      <Row>
        <Col span={8}>
          <List
            header={<strong>Location Types</strong>}
            dataSource={locationTypes?.values || []}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          ></List>
        </Col>
        <Col span={8}>
          <List
            header={<strong>Dock Access</strong>}
            dataSource={[dockAccess?.value || 'unknown']}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          ></List>
        </Col>
        <Col span={8}>
          <List
            header={<strong>Forklift</strong>}
            dataSource={[forklift?.value || 'unknown']}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          ></List>
        </Col>
      </Row>
    </>
  );
};

export const WarehouseForm: React.FC<IWarehouseForm> = ({
  id,
  form,
  verifyMessage,
  disabled = false,
  forbidToModfiySpecifyField = false,
  handleVerify,
}) => {
  const [residentialWarning, setResidentialWarning] = React.useState(false);

  const address = useWatch('address', form);
  const is_residential = useWatch('is_residential', form);
  const zipcode = useWatch('zipcode', form);
  const country = useWatch('country', form);
  const city_id = useWatch('city_id', form);

  const [syncing, setSyncing] = React.useState(false);

  const handleAppointmentBy = (value: number) => {
    if (value == NO_APT_NEEDED || value == CUSTOMER || value == TRUCKER) {
      form.setFieldsValue({ is_no_pre_del_needed: true });
    } else {
      form.setFieldsValue({ is_no_pre_del_needed: false });
    }
  };

  React.useEffect(() => {
    if (residentialWarning) {
      setResidentialWarning(false);
    }
  }, [address, is_residential, zipcode, country, city_id]);

  const toAddress = (_address: any) => {
    const { name, address, city, country, zipcode } = _address;

    const doAddress = `
${name || ''}
${address}
${city?.name || ''}, ${city?.state || ''}, ${country || ''} ${zipcode || ''}
`;

    return doAddress;
  };

  const handleAutoChangeDOAddress = () => {
    const doAddress = toAddress(form.getFieldsValue(DO_ADDRESS_RELATED_FIELDS));

    form.setFieldValue('delivery_order_address', doAddress.trim());
  };

  const handleValuesChange = (changedValues: any) => {
    for (const i in DO_ADDRESS_RELATED_FIELDS) {
      if (has(changedValues, DO_ADDRESS_RELATED_FIELDS[i])) {
        handleAutoChangeDOAddress();

        return;
      }
    }
  };

  const handleChange = async (event: any) => {
    const { name, value } = event.target as HTMLInputElement;
    await form.setFieldsValue({ [name]: value });

    if (DO_ADDRESS_RELATED_FIELDS.indexOf(name) !== -1) {
      handleAutoChangeDOAddress();
    }
  };

  const handleSyncShiplify = async () => {
    try {
      setSyncing(true);
      const { data } = await useApp().service.post(
        `warehouses/syncShiplify/${id}`,
      );
      form.setFieldsValue({ shiplify: data.shiplify });
    } catch (error) {
      message.error('Failed to sync shiplify');
    } finally {
      setSyncing(false);
    }
  };

  const googleAddress = React.useMemo(() => {
    return `${form.getFieldValue('address') || ''} ${
      form.getFieldValue('city')?.name || ''
    } ${form.getFieldValue('country') || ''} ${
      form.getFieldValue('zipcode') || ''
    }`;
  }, [address, zipcode, country, city_id]);

  return (
    <>
      <Form form={form} disabled={disabled} onValuesChange={handleValuesChange}>
        <Row gutter={10}>
          <Col span={12}>
            <h3>Basic Info:</h3>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input
                disabled={forbidToModfiySpecifyField}
                placeholder="Enter Name"
              />
            </Form.Item>
            <Form.Item
              label="Company"
              name="company"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                allowClear
                onChange={(value = '') => {
                  form.setFieldsValue({ company: value });
                }}
              >
                <Select.Option value={''}> </Select.Option>
                <Select.Option value={'Amazon'}>Amazon</Select.Option>
                <Select.Option value={'Walmart'}>Walmart</Select.Option>
                <Select.Option value={'Wayfair'}>Wayfair</Select.Option>
                <Select.Option value={'Menards'}>Menards</Select.Option>
                <Select.Option value={'Goodcang'}>Goodcang</Select.Option>
                <Select.Option value={'GIGA 大健云仓'}>
                  GIGA 大健云仓
                </Select.Option>
                <Select.Option value={'WUYOUDA 无忧达仓'}>
                  WUYOUDA 无忧达仓
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Code"
              name="code"
              rules={[{ required: false }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input
                disabled={forbidToModfiySpecifyField}
                placeholder="Enter Code"
              />
            </Form.Item>
            <Form.Item
              label="Related Customer"
              name="user_id"
              rules={[
                { required: false, message: 'Related Customer is required' },
              ]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <UserSyncSelect disabled={forbidToModfiySpecifyField} />
            </Form.Item>

            <h3>Address Info:</h3>
            {/* <Form.Item noStyle hidden name="address" rules={[{ required: true }]}></Form.Item> */}
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item
                  name="address"
                  rules={[{ required: true }]}
                  label={
                    <Space>
                      <Typography.Text>Address</Typography.Text>
                      {id ? (
                        verifyMessage ? (
                          <span className={styles.table_td_weak}>
                            {verifyMessage}
                          </span>
                        ) : (
                          <Button
                            type={'default'}
                            icon={<ClockCircleOutlined />}
                            disabled={forbidToModfiySpecifyField}
                            size={'small'}
                            onClick={handleVerify}
                          >
                            Verify
                          </Button>
                        )
                      ) : (
                        <></>
                      )}
                      <a
                        className="text-gray"
                        target="_blank"
                        rel="noreferrer"
                        href={`http://maps.google.com/?q=${googleAddress}`}
                      >
                        <EnvironmentOutlined />
                      </a>
                    </Space>
                  }
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <AddressAutoFill
                    disabled={forbidToModfiySpecifyField}
                    value={getFieldValue('address')}
                    onSelect={(_data) => {
                      setFieldsValue({
                        ..._data,
                        delivery_order_address: toAddress({
                          ...form.getFieldsValue(),
                          ..._data,
                        }),
                      });

                      if (_data.classification == 0) {
                        setResidentialWarning(true);
                      }
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Space align="start">
              <Form.Item name="is_residential" valuePropName="checked">
                <Checkbox>Residential</Checkbox>
              </Form.Item>
              {residentialWarning && (
                <small className="text-danger">
                  The classification of selected address is unknown, You can
                  change it to the residential manually!
                </small>
              )}
            </Space>
            <Form.Item name="is_special" valuePropName="checked">
              <Checkbox>
                Special (Please upload SOP in attachments if need)
              </Checkbox>
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <Form.Item
                  name="city"
                  label="City"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: true }]}
                >
                  <CitySelect
                    disabled={forbidToModfiySpecifyField}
                    selected={getFieldValue('city')}
                    onSelect={(id) =>
                      handleChange({ target: { name: 'city', value: id } })
                    }
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item
              label="Zipcode"
              name="zipcode"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input
                disabled={forbidToModfiySpecifyField}
                placeholder="Enter zip code"
              />
            </Form.Item>
            <Form.Item
              label="Country"
              name="country"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select disabled={forbidToModfiySpecifyField}>
                <Select.Option value={'USA'}>United States</Select.Option>
                <Select.Option value={'CAN'}>Canada</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item hidden name="working_hour_from" />
            <Form.Item hidden name="working_hour_to" />
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label="Working/Receiving hours"
              shouldUpdate
            >
              {({ getFieldValue, setFieldValue }) => (
                <Input.Group compact>
                  <Form.Item>
                    <TimePicker
                      allowClear
                      format="HH:mm"
                      showNow={false}
                      value={
                        getFieldValue('working_hour_from')
                          ? moment(getFieldValue('working_hour_from'), 'HH:mm')
                          : null
                      }
                      onSelect={(value) => {
                        const timeString = moment(value).format('HH:mm');
                        setFieldValue('working_hour_from', timeString);
                      }}
                      onChange={(value) => {
                        setFieldValue('working_hour_from', value);
                      }}
                    />
                  </Form.Item>
                  <Input
                    className="site-input-split"
                    style={{
                      width: 30,
                      borderLeft: 0,
                      borderRight: 0,
                      pointerEvents: 'none',
                    }}
                    placeholder="~"
                    disabled
                  />
                  <Form.Item>
                    <TimePicker
                      allowClear
                      format="HH:mm"
                      showNow={false}
                      value={
                        getFieldValue('working_hour_to')
                          ? moment(getFieldValue('working_hour_to'), 'HH:mm')
                          : null
                      }
                      onSelect={(value) => {
                        const timeString = moment(value).format('HH:mm');
                        setFieldValue('working_hour_to', timeString);
                      }}
                      onChange={(value) => {
                        setFieldValue('working_hour_to', value);
                      }}
                    />
                  </Form.Item>
                </Input.Group>
              )}
            </Form.Item>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label="Working Days"
              shouldUpdate
            >
              {() => (
                <Input.Group compact>
                  <Form.Item name="working_day_from" initialValue={''}>
                    <Select
                      allowClear
                      placeholder="Select a Day"
                      style={{
                        width: 150,
                      }}
                    >
                      <Select.Option value="">{''}</Select.Option>
                      {WEEKDAYS.map((day, index) => (
                        <Select.Option key={index} value={index}>
                          {day}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Input
                    className="site-input-split"
                    style={{
                      width: 30,
                      borderLeft: 0,
                      borderRight: 0,
                      pointerEvents: 'none',
                    }}
                    placeholder="~"
                    disabled
                  />
                  <Form.Item name="working_day_to" initialValue={''}>
                    <Select
                      allowClear
                      placeholder="Select a day"
                      style={{
                        width: 150,
                      }}
                    >
                      <Select.Option value="">{''}</Select.Option>
                      {WEEKDAYS.map((day, index) => (
                        <Select.Option key={index} value={index}>
                          {day}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Input.Group>
              )}
            </Form.Item>
            <Form.Item
              label="First come first serve"
              name="first_come_first_serve"
              rules={[{ required: false }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input
                // disabled={forbidToModfiySpecifyField}
                placeholder="Enter first come first serve"
              />
            </Form.Item>
            <Form.Item
              label="Unload Time"
              name="unload_time"
              rules={[{ required: false }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input
                style={{ width: '100%' }}
                // disabled={forbidToModfiySpecifyField}
                placeholder="Enter unload time"
              />
            </Form.Item>
            <Form.Item
              label="Special Request"
              name="special_request"
              rules={[{ required: false }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input.TextArea
                rows={2}
                // disabled={forbidToModfiySpecifyField}
                placeholder="Enter special request"
              />
            </Form.Item>

            <Form.Item name="time_zone" label="Time zone">
              <Select
                allowClear
                placeholder="Select timezome"
                style={{
                  width: 150,
                }}
              >
                <Select.Option value="">{''}</Select.Option>
                {TIMEZONE.map((zone) => (
                  <Select.Option key={zone} value={zone}>
                    {zone}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              shouldUpdate
              label="D/O Address"
              name="delivery_order_address"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input.TextArea
                rows={5}
                disabled={forbidToModfiySpecifyField}
                autoSize={{ minRows: 5, maxRows: 10 }}
                placeholder="Enter Delivery Order Address"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <h3>Delivery Requirement:</h3>
            <Form.Item
              label="Live or Drop"
              name="live_or_drop"
              initialValue={0}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                style={{
                  width: '100%',
                }}
              >
                <Select.Option value={0}> </Select.Option>
                <Select.Option value={1}>Live</Select.Option>
                <Select.Option value={2}>Drop</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Who Make Appointment"
              name="appointment_by"
              rules={[{ required: true }]}
              initialValue={0}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                onChange={(value) => handleAppointmentBy(value)}
                style={{
                  width: '100%',
                }}
              >
                <Select.Option value={UNKNOWN}>Unknown</Select.Option>
                <Select.Option value={NO_APT_NEEDED}>
                  No apt needed
                </Select.Option>
                <Select.Option value={CUSTOMER}>Customer</Select.Option>
                <Select.Option value={DRAYEASY}>DrayEasy</Select.Option>
                <Select.Option value={TRUCKER}>Trucker</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="No pre del needed"
              name="is_no_pre_del_needed"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>

            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldValue }) => (
                <Form.Item
                  label="Special Requirements"
                  name="special_requirements"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <SpecialRequirements
                    id={id || 0}
                    specialRequirements={
                      getFieldValue('special_requirements') || []
                    }
                    onChange={(specialRequirements) => {
                      setFieldValue(
                        'special_requirements',
                        specialRequirements,
                      );
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Col>
              <h3>
                Shiplify{' '}
                <Button
                  type="link"
                  disabled={syncing}
                  onClick={handleSyncShiplify}
                >
                  Sync
                </Button>
              </h3>
              {form.getFieldValue('shiplify') ? (
                <Shiplify
                  disabled={syncing}
                  onSync={() => handleSyncShiplify()}
                  shiplify={form.getFieldValue('shiplify')}
                />
              ) : (
                <>
                  <Typography.Text type="danger">
                    No shiplify data
                  </Typography.Text>
                </>
              )}
            </Col>
            <Col>
              {id && (
                <Documents
                  title="Attachments"
                  target="warehouse"
                  disabled={disabled}
                  targetId={id}
                />
              )}
            </Col>

            <Form.Item
              shouldUpdate
              label="Internal Memo"
              name="internal_memo"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input.TextArea
                rows={5}
                autoSize={{ minRows: 5, maxRows: 10 }}
                placeholder="Enter Internal Memo"
              />
            </Form.Item>
            <Form.Item
              shouldUpdate
              label="Customer Memo"
              name="customer_memo"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input.TextArea
                rows={5}
                autoSize={{ minRows: 5, maxRows: 10 }}
                placeholder="Enter Customer Memo"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <Form.Item
                  initialValue={[
                    {
                      email: '',
                      emailable: false,
                      request_rate: false,
                      fax: '',
                      name: '',
                      phone: '',
                      remark: '',
                      role: '',
                      type: '',
                      cell: '',
                    },
                  ]}
                  name="contacts"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <ContactList
                    data={getFieldValue('contacts') || []}
                    updateData={(list) =>
                      handleChange({
                        target: { name: 'contacts', value: list },
                      })
                    }
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
