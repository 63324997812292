import { Drawer } from 'antd';
import { FC } from 'react';
import QuestionnarieForm from './QuestionnarieForm';

const QuestionnarieDrawer: FC<{
  onSumbit: (values: any, id?: number) => void;
  onClose?: () => void;
  questionnarieId: number;
  openResponseId?: number;
  autoRating?: any;
  openByVendorContainer?: any;
}> = ({
  onSumbit,
  questionnarieId,
  onClose,
  openResponseId,
  autoRating,
  openByVendorContainer,
}) => {
  return (
    <>
      <Drawer
        title=""
        placement="right"
        width="80%"
        zIndex={10009}
        onClose={() => {
          onClose && onClose();
        }}
        destroyOnClose={true}
        open={!!questionnarieId}>
        <QuestionnarieForm
          questionnarieId={questionnarieId}
          onSumbit={onSumbit}
          openResponseId={openResponseId}
          autoRating={autoRating}
          openByVendorContainer={openByVendorContainer}
        />
      </Drawer>
    </>
  );
};

export default QuestionnarieDrawer;
