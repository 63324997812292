import { useApp } from '@/utils/useapp';
import { Tabs, Card, Space, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React from 'react';
import { BuyRateForm } from '@/pages/rates/buyRates/components/buyRateDrawer/BuyRateForm';
import { get } from 'lodash';
import { RateLink } from '@/components/Rate/RateLink';

interface IBuyRateTabs {
  orderId: number;
  buyRates: any;
}

export const BuyRateTabs: React.FC<IBuyRateTabs> = ({
  orderId,
  buyRates,
  ...props
}) => {
  const app = useApp();

  const [form] = useForm();
  const [loading, setLoading] = React.useState(false);

  const handleChangeTab = async (key: string) => {
    const buyRate = buyRates.find((br: any) => br.id == Number(key));
    form.setFieldsValue(buyRate);
  };

  const items = React.useMemo(
    () =>
      buyRates.map((buyRate: any) => {
        form.setFieldsValue(buyRate);
        return {
          label: `${buyRate.uid}`,
          key: '' + `${buyRate.id}`,
          children: (
            <Card>
              <Row>
                <Space>
                  Buy Rate
                  <RateLink
                    rateUid={get(buyRate, 'uid')}
                    hasBanModifyFields={true}
                  />
                </Space>
              </Row>
              <BuyRateForm
                form={form}
                isNew={false}
                id={buyRate.id}
                disabled={true}
              />
            </Card>
          ),
        };
      }),
    [buyRates],
  );

  return (
    <>
      <Tabs type="card" items={items} onChange={handleChangeTab} />
    </>
  );
};
