import { ShipmentInterface as LtlShipmentInterface } from '@/pages/truckload/ltl/shipments/Interfaces/ShipmentInterface';
import { FTLShipmentInterface } from '@/pages/truckload/ftl/shipments/Interfaces/ShipmentInterface';
import { ShipmentInterface as CFSShipmentInterface } from '@/pages/truckload/cfs/shipments/Interfaces/ShipmentInterface';
import { LTLCarrierInterface } from '@/pages/truckload/ltl/carriers/Interfaces/LTLCarrierInterface';
import { CFSCarrierInterface } from '@/pages/truckload/cfs/carriers/Interfaces/CFSCarrierInterface';

export enum ResponseTarget {
  Container = 'container',
  LtlShipment = 'ltl_shipment',
  FtlShipment = 'ftl_shipment',
  CfsShipment = 'cfs_shipment',
  LtlCarrier = 'ltl_carrier',
  CfsCarrier = 'cfs_carrier',
}

export const LTLCarrierTargetClass = 'App\\Domains\\TL\\Models\\LTLCarrier';
export const LTLShipmentTargetClass = 'App\\Domains\\TL\\Models\\LTLShipment';
export const FTLShipmentTargetClass = 'App\\Domains\\FTL\\Models\\FTLShipment';
export const CFSShipmentTargetClass = 'App\\Domains\\CFS\\Models\\CFSShipment';
export const CFSCarrierTargetClass = 'App\\Domains\\CFS\\Models\\CFSCarrier';
export const RESPONSIBLE_TARGET_CLASS = {
  [ResponseTarget.LtlCarrier]: LTLCarrierTargetClass,
  [ResponseTarget.LtlShipment]: LTLShipmentTargetClass,
  [ResponseTarget.FtlShipment]: FTLShipmentTargetClass,
  [ResponseTarget.CfsShipment]: CFSShipmentTargetClass,
  [ResponseTarget.CfsCarrier]: CFSCarrierTargetClass,
};

export const getTarget = (className: string) => {
  return Object.keys(RESPONSIBLE_TARGET_CLASS).find(
    (key) =>
      RESPONSIBLE_TARGET_CLASS[key as keyof typeof RESPONSIBLE_TARGET_CLASS] ==
      className,
  );
};

export type ResponseType = {
  responsibleTarget?:
    | ResponseTarget.Container
    | ResponseTarget.LtlShipment
    | ResponseTarget.FtlShipment
    | ResponseTarget.CfsShipment
    | ResponseTarget.LtlCarrier
    | ResponseTarget.CfsCarrier;
  responsible?:
    | LtlShipmentInterface
    | FTLShipmentInterface
    | CFSShipmentInterface
    | LTLCarrierInterface
    | CFSCarrierInterface;
};
