import { Button } from 'antd';
import { useApp } from '@/utils/useapp';
import { useState } from 'react';
import React from 'react';
import { get, upperFirst } from 'lodash';
import { formatActionInfo, TCommonProps, TItemProps, TOnDoneProps } from '..';
import { TruckingModal } from '@/pages/orders/components/Trucking/TruckingModal';

export const ConfirmDoAction: React.FC<
TCommonProps & TItemProps & TOnDoneProps
> = ({ taskType, item, container, onDone }) => {
  const app = useApp();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnSaved = () => {
    onDone && onDone();
    handleClose();
  };

  return (
    <>
      <Button
        // disabled={item.disabled}
        key={item.action}
        size="small"
        onClick={handleOpen}>
        {upperFirst(item.action)}
      </Button>
      {open && container && (
        <TruckingModal
          isModalVisible={open}
          showContainers={false}
          order={get(container, 'order')}
          container={container}
          selectedContainers={[container]}
          onCancel={handleClose}
          sendOptions={{
            taskAction: formatActionInfo(item, item.action),
            container_id: container.id,
          }}
          // refreshOrder={refreshOrder}
          refreshContainer={container}
        />
      )}
    </>
  );
};
