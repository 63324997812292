import { Button, Modal, Space } from 'antd';
import React, { useMemo } from 'react';

import { FormInstance } from 'antd/es/form';
import { update } from 'lodash';

interface IIsSyncCreditOrInsuranceModal {
  open: boolean;
  form: FormInstance;
  vendorCredit: any;
  vendorInsurance: any;
  vendorFormW9: any;
  vendorContract: any;
  onClose: () => void;
}

export const IsSyncCreditOrInsuranceModal: React.FC<
IIsSyncCreditOrInsuranceModal
> = ({
  open,
  form,
  vendorCredit,
  vendorInsurance,
  vendorFormW9,
  vendorContract,
  onClose,
}) => {
  const updateText = useMemo(() => {
    const text = [];
    if (vendorCredit) {text.push('Credit');}
    if (vendorInsurance) {text.push('Insurance');}
    if (vendorFormW9) {text.push('Form W9');}
    if (vendorContract) {text.push('Contract');}
    return text.join(', ') || '';
  }, [vendorCredit, vendorInsurance, vendorFormW9, vendorContract]);

  const thereText = useMemo(() => {
    const text = [];
    if (vendorCredit) {text.push('Credit');}
    if (vendorInsurance) {text.push('Insurance');}
    if (vendorFormW9) {text.push('Form W9');}
    if (vendorContract) {text.push('Contract');}

    if (text.length > 1) {
      return ' are ' + text.join(', ');
    } else {
      return ' is ' + text.join(', ');
    }
  }, [vendorCredit, vendorInsurance, vendorFormW9, vendorContract]);

  const handleConfirm = () => {
    let update = {};

    if (vendorCredit) {
      update = {
        ...update,
        established_credit: true,
        vendor_credit: vendorCredit,
        vendor_credit_id: vendorCredit.id,
        terms: vendorCredit.terms,
      };
    }

    if (vendorInsurance) {
      update = {
        ...update,
        has_insurance: true,
        vendor_insurance: vendorInsurance,
        vendor_insurance_id: vendorInsurance.id,
      };
    }

    if (vendorFormW9) {
      update = {
        ...update,
        vendor_form_w9: vendorFormW9,
        vendor_form_w9_id: vendorFormW9.id,
      };
    }

    if (vendorContract) {
      update = {
        ...update,
        vendor_contract: vendorContract,
        vendor_contract_id: vendorContract.id,
      };
    }

    form.setFieldsValue({ ...update });

    onClose();
  };

  return (
    <Modal
      title=""
      destroyOnClose
      cancelText="Close"
      footer={
        <Space>
          <Button type="primary" onClick={handleConfirm}>
            Confirm
          </Button>
          <Button onClick={onClose}>cancel</Button>
        </Space>
      }
      open={open}
      onCancel={onClose}>
      <p>
        When the Trucker MC is {form.getFieldValue('trucker_mc')}, there{' '}
        {thereText} with the same mc number.
      </p>
      <p>
        The {updateText} will be updated when you click the {'\'Confirm\''}{' '}
        button.
      </p>
    </Modal>
  );
};
