import styles from './styles.scss';
import { SearchResultDrayItem } from './types';
import { EnterOutlined, InfoCircleOutlined } from '@ant-design/icons';
import highlightWords from 'highlight-words';
import { history } from 'umi';

const fields = [
  {
    key: 'container_number',
    label: 'CNTR#',
  },
  {
    key: 'mbl_number',
    label: 'MBL#',
  },
] as const;

const UILayoutSearchModalResultDraySection = ({
  list,
  query,
  onCancel,
  afterClick,
}: {
  list: SearchResultDrayItem[];
  query: string;
  onCancel: () => void;
  afterClick?: (item: SearchResultDrayItem) => void;
}) => {
  const handleClick = (event: React.MouseEvent<HTMLUListElement>) => {
    event.stopPropagation();
    let target = event.target as HTMLElement;
    while (target.tagName !== 'LI' && target.parentElement) {
      target = target.parentElement;
    }
    if (!target || target.tagName !== 'LI') {
      return;
    }
    const id = target.dataset.id;
    if (!id) {
      return;
    }
    const listItem = list.find((item) => item.id.toString() === id);
    if (!listItem) {
      return;
    }

    if (afterClick) {
      afterClick(listItem);
    } else {
      history.push(
        `/dispatchs?search=${
          listItem.container_number || listItem.mbl_number || ''
        }`,
      );
    }
    onCancel();
  };

  if (list.length === 0) {
    return null;
  }

  return (
    <section className={styles.searchBarResultListSection}>
      <div className={styles.searchBarResultListSectionTitle}>Dray</div>
      <ul
        role="listbox"
        className={styles.searchBarResultListSectionContent}
        onClick={handleClick}
      >
        {list.map((listItem) => (
          <li
            role="option"
            className={styles.searchBarResultListSectionContentItem}
            key={listItem.id}
            data-id={listItem.id}
          >
            <InfoCircleOutlined
              className={styles.searchBarResultListSectionContentItemPrefix}
            />
            <div
              className={styles.searchBarResultListSectionContentItemContent}
            >
              {fields.map((field) =>
                listItem[field.key] ? (
                  <div
                    className={
                      styles.searchBarResultListSectionContentItemContentItem
                    }
                    key={field.key}
                  >
                    {field.label}:{' '}
                    {highlightWords({
                      text: listItem[field.key],
                      query,
                    }).map(({ text, match, key }) =>
                      match ? (
                        <span
                          className={
                            styles.searchBarResultListSectionContentItemContentItemHighlight
                          }
                          key={key}
                        >
                          {text}
                        </span>
                      ) : (
                        <span key={key}>{text}</span>
                      ),
                    )}
                  </div>
                ) : null,
              )}
            </div>
            <EnterOutlined
              className={styles.searchBarResultListSectionContentItemSuffix}
            />
          </li>
        ))}
      </ul>
    </section>
  );
};

export default UILayoutSearchModalResultDraySection;
