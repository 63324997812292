import { Button } from 'antd';
import { useApp } from '@/utils/useapp';
import { useState } from 'react';
import React from 'react';
import { get, upperFirst } from 'lodash';
import { RescheduledModal } from './RescheduledModal';

import {
  formatActionInfo,
  TCommonProps,
  TItemProps,
  TOnDoneProps,
} from '../..';

export const RescheduledAction: React.FC<
TCommonProps & TItemProps & TOnDoneProps
> = ({ item, container, onDone }) => {
  const app = useApp();

  const [open, setOpen] = useState(false);

  const handleOnOpen = () => {
    setOpen(true);
  };

  const handleCloseOpen = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        disabled={item.disabled}
        key={item.action}
        size="small"
        onClick={handleOnOpen}>
        {upperFirst(item.action)}
      </Button>
      {open && container && (
        <RescheduledModal
          open={open}
          admin={app.store.auth.account}
          container={container}
          item={item}
          actionInfo={formatActionInfo(item, item.action)}
          onClose={handleCloseOpen}
          onDone={onDone}
        />
      )}
    </>
  );
};
