import {
  Button,
  Divider,
  Modal,
  Space,
  Spin,
  Tabs,
  Empty,
  message,
} from 'antd';
import React, { useEffect, useMemo } from 'react';
import { CreditCardOutlined } from '@ant-design/icons';
import { InvoiceTable } from './InvoiceTable';
import { BillTable } from './BillTable';
import { reduce, uniq } from 'lodash';
import { useApp } from '@/utils/useapp';
import { TBill, TContainer } from '@/types';
import AccountingActionTips, {
  AccountingActionTipsProps,
} from '@/pages/dispatchs/components/AccountingActionTips';
import Decimal from 'decimal.js';
import { BILL_TYPE_ORDER } from '@/pages/accounting/bills/components/data';
import { INVOICE_TYPE_ORDER } from '@/pages/accounting/invoices/components/data';

const ALL_TAB_KEY = '0';
export const ALL_NUMBER = 'All CNTR';
const ALL_CONTAINER = {
  id: ALL_TAB_KEY,
  number: ALL_NUMBER,
};

export const filterRows = (rows: any[], cntrNumber?: string) => {
  if (cntrNumber) {
    return rows.filter((row) => {
      return (
        (row.charges || []).findIndex(
          (charge: any) => charge.containerNumber == cntrNumber,
        ) !== -1
      );
    });
  }

  return rows;
};

export const AccountingModal: React.FC<{
  orderId: number;
  containerNumber?: string;
  onChanged?: () => void;
  accountingActionTipsProps?: AccountingActionTipsProps;
  showAccountingTips?: boolean;
}> = ({
  orderId,
  containerNumber,
  onChanged,
  accountingActionTipsProps,
  showAccountingTips = false,
}) => {
  const app = useApp();

  const [open, setOpen] = React.useState<boolean>(false);

  const [invoicesTotal, setInvoicesTotal] = React.useState(0);
  const [billsTotal, setBillsTotal] = React.useState(0);
  const [containers, setContainers] = React.useState<Array<any>>([]);
  const [activeKey, setActiveKey] = React.useState(ALL_TAB_KEY);
  const [activeContainer, setActiveContainer] = React.useState(ALL_CONTAINER);
  const [loading, setLoading] = React.useState(false);

  const [allInvoices, setAllInvoices] = React.useState<Array<any>>([]);
  const [allBills, setAllBills] = React.useState<TBill[]>([]);
  const [invoicesSelected, setInvoiceSelected] = React.useState<any>({});

  const formatTabKey = (key: string | number) => String(key);

  const fetchContainers = async () => {
    const resp = await app.service.get(`orders/${orderId}/containers`);

    setContainers([...resp.data, ALL_CONTAINER]);

    if (containerNumber) {
      const container = resp.data.find((c: any) => c.number == containerNumber);

      if (container) {
        setActiveKey(formatTabKey(container.id));
        setActiveContainer(container);
      }
    }
  };

  const fetchInvoicesData = async () => {
    setLoading(true);
    try {
      const response = await app.service.get('orderInvoices', {
        params: {
          invoiceable_id: orderId,
          invoiceable_type: INVOICE_TYPE_ORDER,
          per_page: 1000,
        },
      });

      setAllInvoices(response.data);
    } catch (err: any) {
      message.error(err.data.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchBillsData = async () => {
    setLoading(true);
    try {
      const response = await app.service.get('orderBills', {
        params: {
          billable_id: orderId,
          billable_type: BILL_TYPE_ORDER,
          per_page: 1000,
        },
      });

      setAllBills(response.data);
    } catch (err: any) {
      message.error(err.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSavedBill = () => {
    fetchBillsData();
    onChanged && onChanged();
  };

  const handleSavedInvoice = () => {
    fetchInvoicesData();
    onChanged && onChanged();
  };

  const handleSettled = () => {
    fetchInvoicesData();
  };

  const invoices = React.useMemo(() => {
    if (allInvoices?.length == 0) {
      return [];
    }

    if (!activeContainer || activeContainer.number == ALL_NUMBER) {
      return allInvoices;
    }

    return filterRows(allInvoices, activeContainer.number);
  }, [allInvoices, activeContainer]);

  const activeSelectedInvoices = React.useMemo(() => {
    return reduce(
      Object.keys(invoicesSelected).map((key) =>
        reduce(
          invoicesSelected[key],
          (result, invoiceId) => {
            return result.concat([
              allInvoices.find((invoice) => invoice.id == invoiceId),
            ]);
          },
          [] as any[],
        ),
      ),
      (data, invoicesData) => data.concat(invoicesData),
      [] as any[],
    );
  }, [invoicesSelected, allInvoices]);

  const bills = React.useMemo(() => {
    if (allBills.length == 0) {
      return [];
    }

    if (!activeContainer || activeContainer.number == ALL_NUMBER) {
      return allBills;
    }

    return filterRows(allBills, activeContainer.number);
  }, [allBills, activeContainer]);

  const handleChangeTab = async (key: string) => {
    setActiveKey(formatTabKey(key));
    setActiveContainer(containers.find((c: any) => c.id == Number(key)));
  };

  const total = useMemo(() => {
    return new Decimal(invoicesTotal).minus(new Decimal(billsTotal));
  }, [invoicesTotal, billsTotal]);

  const items = React.useMemo(
    () =>
      containers.map((container: any) => {
        return {
          label: `${container.number}`,
          key: formatTabKey(container.id),
          children: (
            <>
              <InvoiceTable
                container={activeContainer}
                orderId={orderId}
                invoices={invoices}
                rowSelection={{
                  onSelect: (a: any, b: any, selectedRows: any) => {
                    setInvoiceSelected({
                      ...invoicesSelected,
                      [activeContainer.id]: selectedRows.map((r: any) => r.id),
                    });
                  },
                  onSelectAll: (a: any, selectedRows: any) => {
                    setInvoiceSelected({
                      ...invoicesSelected,
                      [activeContainer.id]: selectedRows.map((r: any) => r.id),
                    });
                  },
                }}
                activeSelectedInvoices={activeSelectedInvoices}
                onSaved={handleSavedInvoice}
                onDeleted={handleSavedInvoice}
                onSettled={handleSettled}
                setInvoiceTotal={(total: number) => setInvoicesTotal(total)}
              />
              <Divider />
              <BillTable
                bills={bills}
                onSaved={handleSavedBill}
                orderId={orderId}
                container={activeContainer}
                setBillsTotal={(total: number) => setBillsTotal(total)}
              />
            </>
          ),
        };
      }),
    [containers, activeContainer, invoices, bills, activeSelectedInvoices],
  );

  React.useEffect(() => {
    if (open && orderId) {
      fetchContainers();
      fetchBillsData();
      fetchInvoicesData();
    }
  }, [open, orderId]);

  return (
    <>
      {showAccountingTips ? (
        <AccountingActionTips {...accountingActionTipsProps}>
          <div onClick={() => setOpen(!open)}>
            <CreditCardOutlined />
          </div>
        </AccountingActionTips>
      ) : (
        <div onClick={() => setOpen(!open)}>
          <CreditCardOutlined />
        </div>
      )}
      {open && (
        <>
          <Modal
            title="Accounting"
            open={open}
            onCancel={() => setOpen(!open)}
            width="80vw"
            footer={
              <Space className="w100" direction="vertical" align="end">
                <h3>Total {total.toFixed(2)}</h3>
              </Space>
            }
          >
            <Spin spinning={loading}>
              {containers.length > 0 ? (
                <Tabs
                  type="card"
                  activeKey={activeKey}
                  items={items}
                  onChange={handleChangeTab}
                />
              ) : (
                <Empty />
              )}
            </Spin>
          </Modal>
        </>
      )}
    </>
  );
};
