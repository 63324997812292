import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Drawer,
  message,
  Space,
  Popconfirm,
  Table,
  Form,
  Row,
} from 'antd';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { useForm } from 'antd/lib/form/Form';
import { TCollection } from '@/types';
import { get, truncate } from 'lodash';
import { TContainer } from '@/types';
import { ExtraCostForm } from './ExtraCostForm';
import { serialize } from 'object-to-formdata';
import { EnterOutlined } from '@ant-design/icons';

type TExtraCost = {
  id: number;
  type_name: string;
  amount: number;
  start_at: string;
  end_at: string;
  reason: string;
  front_link: string;
};

interface IExtraCost {
  container: TContainer;
  onUpdated: (id: number, data: any[]) => void;
}
const ExtraCostTable: React.FC<IExtraCost> = ({ container, onUpdated }) => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [title, setTitle] = React.useState('Create Extra Cost');
  const [data, setData] = useState();
  const [costs, setCosts] = useState(container.extra_costs || []);

  const containerId = useMemo(() => container.id, [container]);

  const [form] = useForm();

  const fetchData = React.useCallback(async () => {
    if (!containerId) {
      return false;
    }

    setLoading(true);
    try {
      const result = await app.service.get(
        `containers/${containerId}/extraCosts`,
        {
          params: {},
        },
      );

      setData(result.data);
    } catch (e: any) {
      message.error(e.error || 'System Error');
    }

    setLoading(false);
  }, []);

  const handleAdd = () => {
    form.setFieldsValue({
      container_id: containerId,
    });

    setVisible(true);
  };

  const handleEdit = async (extraCost: TExtraCost) => {
    setTitle('Edit Extra Cost');
    form.resetFields();
    form.setFieldsValue({
      ...extraCost,
      containerId: containerId,
    });
    setVisible(true);
  };

  const handleDelete = async (record: any) => {
    if (!record.id) {
      return false;
    }
    setLoading(true);

    try {
      await app.service.delete(
        `containers/${containerId}/extraCosts/${record.id}`,
      );

      message.success('Deleted');

      onUpdated(containerId || 0, []);
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  const handleSave = async () => {
    const values = await form.validateFields();

    if (values.billing_code) {
      values.billing_code_id = values.billing_code?.id;
      delete values.billing_code;
    } else {
      return message.error('Billing Code is required');
    }

    setLoading(true);

    const config = {
      data: serialize(
        {
          ...values,
        },
        { indices: true, nullsAsUndefineds: true },
      ),
      requestType: 'form',
    };

    try {
      const resp = !values.id
        ? await app.service.post(`containers/${containerId}/extraCosts`, config)
        : await app.service.put(
            `containers/${containerId}/extraCosts/${values.id}`,
            {
              data: values,
            },
          );
      message.success('Saved');

      form.resetFields();
      onUpdated(containerId || 0, []);
      handleClose();
    } catch (err: any) {
      if (err.errorFields) {
        message.error(err.errorFields.map((e: any) => e.errors[0]).join(','));
      } else {
        message.error(err.data?.message || err.data?.error);
      }
    } finally {
      setLoading(false);
    }

    setLoading(false);
  };

  const handleClose = async () => {
    setVisible(false);
    form.resetFields();
  };

  useEffect(() => {
    fetchData();
  }, [container]);

  const columns: ColumnsType<any> = React.useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 80,
      },
      // {
      //   title: 'Billing Code',
      //   dataIndex: 'billing_code',
      //   key: 'billing_code',
      //   width: 200,
      // },
      {
        title: 'Billing Code',
        dataIndex: ['billing_code', 'name'],
        key: 'billing_code',
        width: 200,
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        width: 200,
      },
      {
        title: 'Qty',
        dataIndex: 'qty',
        key: 'qty',
        width: 200,
      },
      {
        title: 'Amount Total',
        dataIndex: 'amount_total',
        key: 'amount_total',
        width: 200,
      },
      {
        title: 'Start At',
        dataIndex: 'start_at',
        key: 'start_at',
        width: 200,
      },
      {
        title: 'End At',
        dataIndex: 'end_at',
        key: 'end_at',
        width: 200,
      },
      {
        title: 'Reason',
        dataIndex: 'reason',
        key: 'reason',
        width: 200,
        render: (text, record) =>
          truncate(get(record, 'reason', ''), {
            length: 300,
          }),
      },
      {
        title: 'Front Link',
        dataIndex: 'front_link',
        key: 'front_link',
        width: 200,
        render: (text, record) =>
          text && (
            <a target="_blank" rel="noreferrer" href={text}>
              <EnterOutlined />
            </a>
          ),
      },
      {
        title: 'Creator',
        dataIndex: ['creator', 'name'],
        key: 'creator_name',
        width: 200,
      },
      {
        align: 'center',
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 150,
        render: (text, record) => (
          <Space>
            <a onClick={() => handleEdit(record)}>Edit</a>
            <Popconfirm
              placement="left"
              title="Sure to delete?"
              okText="Confirm"
              cancelText="Cancel"
              onConfirm={() => handleDelete(record)}
            >
              <a>Delete</a>
            </Popconfirm>
          </Space>
        ),
      },
    ],
    [],
  );

  return (
    <div className="mb-lg">
      <Row className="mb-md">
        <Button type="primary" onClick={handleAdd}>
          New Extra Cost
        </Button>
      </Row>
      <Table
        loading={loading}
        pagination={false}
        size="small"
        rowKey="id"
        columns={columns}
        dataSource={data || []}
        sticky
        scroll={{
          x: 'auto',
        }}
      />

      <Drawer
        title={''}
        placement="right"
        width={'40%'}
        onClose={handleClose}
        destroyOnClose={true}
        open={visible}
        extra={
          <Space>
            <Button onClick={handleClose}>Close</Button>
            <Button type="primary" loading={loading} onClick={handleSave}>
              Save
            </Button>
          </Space>
        }
      >
        <ExtraCostForm container={container} form={form} disabled={loading} />
      </Drawer>
    </div>
  );
};

export default ExtraCostTable;
