import React, { useEffect } from 'react';

import {
  Typography,
  Space,
  Button,
  Form,
  Divider,
  Input,
  FormInstance,
} from 'antd';
import { useApp } from '@/utils/useapp';
import { get } from 'lodash';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { WarehouseFormItem } from '@/pages/orders/components/DeliveryOrderDrawer/WarehouseFormItem';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { SERIAL_NUMBER_MAP } from '../../../dispatchs/components/WHS/data';

export const MultipleDeliveriesFormList: React.FC<{
  form: FormInstance;
}> = ({ form }) => {
  return (
    <>
      <Form.List name="multiple_deliveries">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => {
              const serialNumber = Number(index) + 1;
              return (
                <Form.Item key={field.key} {...fields}>
                  <Space>
                    <Typography.Title level={4}>
                      {
                        SERIAL_NUMBER_MAP[
                          serialNumber as unknown as keyof typeof SERIAL_NUMBER_MAP
                        ]
                      }
                    </Typography.Title>

                    {index != 0 && (
                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                    )}
                  </Space>
                  <WarehouseFormItem
                    form={form}
                    names={{
                      warehouse: [
                        'multiple_deliveries',
                        field.name,
                        'warehouse',
                      ],
                      delivery_address: [
                        'multiple_deliveries',
                        field.name,
                        'delivery_address',
                      ],
                      delivery_reference_number: [
                        field.name,
                        'delivery_reference_number',
                      ],
                      delivery_at: [
                        'multiple_deliveries',
                        field.name,
                        'delivery_at',
                      ],
                    }}
                  />

                  <Divider />
                </Form.Item>
              );
            })}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}>
                Add Delivery
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
};
