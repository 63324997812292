import actionUtils from '@/components/Tasks/Interfaces/ActionInterface';
export interface TaskOperationLogInterface {
  id: number;
  action: string;
  value: string;
  action_at: string;
}

export const ACTION_LABELS: { [key: string]: string } = {
  // [ACTION_RESET_SLA]: 'update due at',
  // [ACTION_STREET_TURN]: 'update street turn at',
  [actionUtils.INPUT_BR]: 'Input BR',
  [actionUtils.INPUT_VID]: 'Input VID',
  [actionUtils.EDIT_BR]: 'Edit BR',
  [actionUtils.EDIT_SR]: 'Edit SR',
};
