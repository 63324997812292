import ClickWrapper from '@/components/ClickWrapper';
import React from 'react';
import { BillDrawer } from './BillDrawer';
import { Modal } from 'antd';

interface Props {
  billableId: number;
  billableType: string;
  container?: any;
  children: React.ReactNode;
  onSaved?: () => void;
  confirmModalProps?: {
    title?: string;
    content?: string;
    okText?: string;
    cancelText?: string;
  };
  initialValues?: {
    invoice_number?: string;
    invoice_date?: string;
    due_date?: string;
    bill_from?: string;
    items?: Array<{
      name: string;
      description?: string;
      rate: number;
      quantity: number;
      amount: number;
    }>;
    new_files?: Array<{
      name: string;
      content: string | null;
      type: string;
    }>;
  };
}

const NewBill: React.FC<Props> = ({
  billableId,
  billableType,
  container,
  children,
  onSaved,
  confirmModalProps,
  initialValues,
}) => {
  const [visible, setVisible] = React.useState<boolean>(false);

  const handleClick = () => {
    if (!confirmModalProps) {
      setVisible(true);
      return;
    }

    Modal.confirm({
      title: confirmModalProps?.title || 'Create Bill Confirmation',
      content:
        confirmModalProps?.content || 'Are you sure to create a new bill?',
      okText: confirmModalProps?.okText || 'OK',
      cancelText: confirmModalProps?.cancelText || 'Cancel',
      onOk: () => {
        setVisible(true);
      },
    });
  };

  return (
    <>
      <ClickWrapper onClick={handleClick}>{children}</ClickWrapper>
      {visible && (
        <BillDrawer
          id={0}
          billableId={billableId}
          billableType={billableType}
          container={container}
          visible={visible}
          onClose={() => setVisible(false)}
          onSaved={onSaved}
          initialValues={initialValues}
        />
      )}
    </>
  );
};

export default NewBill;
