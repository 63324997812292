import { FC, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  TimePicker,
  Typography,
  message,
} from 'antd';
import { useApp } from '@/utils/useapp';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { ShipmentParams } from './QuoteForm';
import calcPremium from '@/utils/calcPremium';
import {
  // calcFreightClass,
  destinationAccesorials,
  fetchItemFreightClass,
  freightClasses,
  packageingTypes,
  pickupAccesorials,
  QuoteItem,
} from '@/utils/freight';
import { debounce, intersection } from 'lodash';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { FormInstance } from 'antd/es/form';
import moment from 'moment';
import TopCard from './TopCard';
import {
  LTLAddressType,
  LTLAddressTypeLabelMap,
  ShipmentBusinessStatus,
} from '../constants';
import { TLVendorSelect } from '@/components/TLVendorSelect';
import { TLType } from '@/components/constants';
import { TCity } from '@/types';
import { AIfillBtn } from '@/components/AIFillButton';
import { Address, ReferenceType } from '@/components/AddressVerify/types';
import { UsedAddressShipment } from '@/components/AddressVerify/types';
import { AddressVerifier } from '@/components/AddressVerify';
import { ShipmentBusinessStatusTag } from './ShipmentBusinessStatusTag';
import { LTLModelSelect } from './LTLModelSelect';
import { useFetchDistance } from '../../hooks/useFetchDistance';
import { DimensionItemExtra, WeightItemExtra } from './ItemExtra';
import ShipmentItemsAggregator from './ShipmentItemsAggregator';

interface Props {
  params?: ShipmentParams | null;
  model?: any;
  form?: FormInstance | null;
  loading?: boolean;
  setLoading?: (loading: boolean) => void;
}

interface Vendor {
  id: number;
  name: string;
}

const ShipmentForm: FC<Props> = ({ params, model, loading, form = null }) => {
  const rate = params?.rate;
  const quoteFields = params?.quoteFields;

  const disabled = useMemo(
    () =>
      loading ||
      model?.business_status >= ShipmentBusinessStatus.PENDING_PICKUP,
    [loading, model],
  );

  const manualMode = !params;
  if (!form) {
    [form] = useForm();
  }

  const [, setRenderTrigger] = useState(0); // 添加一个状态来触发渲染

  const forceUpdate = () => {
    setRenderTrigger((prev) => prev + 1); // 更新状态以强制重新渲染
  };

  const app = useApp();

  const isCustomerDisabled = useMemo(() => {
    if (!model) {
      return false;
    }

    if (app.store.auth.superAdminBen) {
      return (
        !manualMode ||
        model?.business_status >= ShipmentBusinessStatus.PENDING_PICKUP
      );
    }

    return true;
  }, [app.store.auth, model, manualMode]);

  useFetchDistance(form);

  async function getUsedAddressShipments(
    address: Address,
  ): Promise<UsedAddressShipment[]> {
    const resp = await app.service.post('tl/tools/used-address-shipments', {
      data: {
        addressLine1: address.addressLine1,
        addressLine2: address.addressLine2,
        cityId: address.cityId,
        zipcode: address.zipcode,
        currentShipmentId: model?.id,
      },
    });
    return resp.data;
  }

  const [pickupCityList, setPickupCityList] = useState<TCity[]>([]);

  const [, setVendors] = useState<Vendor[]>([]);
  const [destinationCityList, setDestinationCityList] = useState<TCity[]>([]);
  const pickupZipcode = useWatch('pickup_zipcode', form);
  const destinationZipcode = useWatch('destination_zipcode', form);

  const [addressTypes] = useState(
    Object.entries(LTLAddressTypeLabelMap).map(
      ([value, label]: [string, string]) => ({
        label,
        value,
      }),
    ),
  );
  const [pickupAdditions] = useState(
    pickupAccesorials.map((item) => ({
      value: item.key,
      label: item.name,
    })),
  );
  const [destinationAdditions] = useState(
    destinationAccesorials.map((item) => ({
      value: item.key,
      label: item.name,
    })),
  );
  const [packageTypes] = useState(
    packageingTypes.map((item) => ({
      value: item,
      label: item,
    })),
  );

  const [classes] = useState(
    freightClasses.map((item) => ({
      value: item.classCode,
      label: item.classCode,
    })),
  );

  const debouncePickupCityFetcher = useMemo(() => {
    const fetchPickupCityList = async (pickup_zipcode: string) => {
      try {
        const resp = await app.service.get(
          `tl/tools/citylist/${pickup_zipcode}`,
        );
        setPickupCityList(resp.data);
      } catch (e: any) {
        message.error(e.data?.message || e.data?.error);
      }
    };
    return debounce(fetchPickupCityList, 300);
  }, []);

  const debounceDestinationCityFetcher = useMemo(() => {
    const fetchDestinationCityList = async (destination_zipcode: string) => {
      try {
        const resp = await app.service.get(
          `tl/tools/citylist/${destination_zipcode}`,
        );
        setDestinationCityList(resp.data);
      } catch (e: any) {
        message.error(e.data?.message || e.data?.error);
      }
    };
    return debounce(fetchDestinationCityList, 300);
  }, []);

  const fetchVendors = async () => {
    try {
      const resp = await app.service.get('tl/ltl/vendors');
      setVendors(resp.data);
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
  };

  const handleClassSuggest = useMemo(
    () =>
      debounce((key: number, item: QuoteItem) => {
        if (!item) {
          return;
        }
        const judge = [
          item.dimension_unit,
          item.weight_unit,
          item.total_weight,
          item.width,
          item.height,
          item.length,
          item.units,
        ].every((element) => Boolean(element));
        if (!judge) {
          return;
        }
        // const freightClass = calcFreightClass(
        //   item.total_weight,
        //   item.length,
        //   item.width,
        //   item.height,
        //   item.units,
        //   item.weight_unit,
        //   item.dimension_unit,
        // );
        // form.setFieldValue(['items', key, 'class'], freightClass);
        fetchItemFreightClass(item)
          .then((response) => {
            form.setFieldValue(
              ['items', key, 'class'],
              response.class?.toString() ?? '',
            );
          })
          .catch((error) => {
            console.error(error);
            message.error(error);
          });
      }, 500),
    [form],
  );

  const calcuTotalSellRate = (
    sellRate: number | null,
    premium: number | null,
  ) => {
    const totalSellRate =
      (sellRate ? Number(sellRate) : 0) + (premium ? Number(premium) : 0);
    form.setFieldValue('total_sell_rate', totalSellRate);
  };

  const onFormValueChange = (changedValues: any, allValues: any) => {
    if (manualMode && changedValues.pickup_zipcode) {
      debouncePickupCityFetcher(changedValues.pickup_zipcode);
    }

    if (manualMode && changedValues.destination_zipcode) {
      debounceDestinationCityFetcher(changedValues.destination_zipcode);
    }

    if (changedValues.items) {
      forceUpdate();
    }

    if (changedValues.items && changedValues.items.length > 0) {
      Object.keys(changedValues.items).forEach((key) => {
        if (
          key &&
          changedValues.items[key] &&
          intersection(Object.keys(changedValues.items[key]), [
            'units',
            'pieces',
            'length',
            'width',
            'height',
            'total_weight',
            'weight_unit',
            'dimension_unit',
          ]).length
        ) {
          handleClassSuggest(parseInt(key, 10), allValues.items[key]);
        }
      });
    }
  };

  useEffect(() => {
    if (pickupZipcode) {
      debouncePickupCityFetcher(pickupZipcode);
    }
  }, [pickupZipcode]);

  useEffect(() => {
    if (destinationZipcode) {
      debounceDestinationCityFetcher(destinationZipcode);
    }
  }, [destinationZipcode]);
  useEffect(() => {
    fetchVendors();
  }, []);

  useEffect(() => {
    if (manualMode) {
      form.setFieldsValue({
        ...quoteFields,
      });
    } else {
      form.setFieldsValue({
        ...quoteFields,
        quote_rate: rate?.quote_rate,
        original_pickup_date: quoteFields.pickup_date,
      });
    }
  }, [quoteFields, rate]);

  const usedReferences = {
    columns: [
      { title: 'Shipment#', dataIndex: 'order_number' },
      { title: 'Vendor', dataIndex: 'vendor_name' },
      { title: 'Carrier', dataIndex: 'carrier_name' },
      {
        title: 'Location Memo',
        key: 'location_memo',
        render: (text: string, record: UsedAddressShipment) => {
          return (
            <Space>
              {record.address_match_type === 'pickup' ? (
                <span>{record.pickup_location_memo}</span>
              ) : record.address_match_type === 'destination' ? (
                <span>{record.destination_location_memo}</span>
              ) : (
                <></>
              )}
            </Space>
          );
        },
      },
      {
        title: 'Status',
        dataIndex: 'business_status',
        render: (text: number) => {
          return (
            <ShipmentBusinessStatusTag
              business_status={text as ShipmentBusinessStatus}
            />
          );
        },
      },
      {
        title: 'Delivery Date',
        dataIndex: 'delivery_date',
      },
      {
        title: 'BOL',
        key: 'bol_url',
        dataIndex: 'bol_url',
        render: (text: string) => (
          <Space>
            <a href={text}>Download</a>
          </Space>
        ),
      },
    ],
    getUsedReferences: getUsedAddressShipments,
  };

  return (
    <>
      <Form
        layout="vertical"
        disabled={disabled}
        onValuesChange={onFormValueChange}
        form={form}
      >
        {rate && <TopCard rate={rate} />}
        <Space direction="vertical">
          {manualMode ? (
            <AIfillBtn
              form={form}
              setPickupCityList={setPickupCityList}
              setDestinationCityList={setDestinationCityList}
              formType="shipment"
            />
          ) : (
            <AIfillBtn
              form={form}
              fields={[
                'pickup_company_name',
                'pickup_contact_name',
                'pickup_contact_email',
                'pickup_contact_phone',
                'pickup_address1',
                'pickup_address2',
                'pickup_special_request',
                'pickup_open_time',
                'pickup_close_time',
                'destination_company_name',
                'destination_contact_name',
                'destination_contact_email',
                'destination_contact_phone',
                'destination_address1',
                'destination_address2',
                'destination_special_request',
                'destination_open_time',
                'destination_close_time',
                'pickup_number',
                'dropoff_number',
                'remarks',
              ]}
              setPickupCityList={setPickupCityList}
              setDestinationCityList={setDestinationCityList}
              formType="shipment"
            />
          )}
          <Row gutter={48}>
            <Col span={12}>
              <Card
                title={
                  <Space>
                    <span style={{ fontWeight: 'bold' }}>PICKUP</span>
                    {form.getFieldValue('pickup_address1') &&
                      form.getFieldValue('pickup_city_id') &&
                      form.getFieldValue('pickup_zipcode') && (
                        <AddressVerifier<UsedAddressShipment>
                          address={{
                            addressLine1: form.getFieldValue('pickup_address1'),
                            addressLine2: form.getFieldValue('pickup_address2'),
                            cityId: form.getFieldValue('pickup_city_id'),
                            zipcode: form.getFieldValue('pickup_zipcode'),
                          }}
                          addressType={form.getFieldValue(
                            'pickup_address_type',
                          )}
                          accessorials={form.getFieldValue(
                            'pickup_accessorials',
                          )}
                          referenceId={model?.id}
                          referenceType={ReferenceType.LTLShipment}
                          tag="pickup"
                          usedReferences={usedReferences}
                        />
                      )}
                  </Space>
                }
                size="small"
                bordered={true}
                style={{
                  borderColor: '#d2ebff',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                }}
                headStyle={{
                  backgroundColor: '#d2ebff',
                  fontWeight: 'bold',
                }}
              >
                <Row gutter={32}>
                  <Col span={8}>
                    <Form.Item
                      name="pickup_company_name"
                      label="Facility Name"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Enter facility name." />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Space direction="horizontal">
                      <Form.Item
                        name="pickup_open_time"
                        label="Open Time"
                        initialValue={moment('08:00', 'HH:mm')}
                        rules={[{ required: true }]}
                      >
                        <TimePicker
                          format={'HH:mm'}
                          suffixIcon={null}
                          placeholder={'Pickup open time'}
                          minuteStep={15}
                          style={{
                            width: 100,
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name="pickup_close_time"
                        label="Close Time"
                        initialValue={moment('21:00', 'HH:mm')}
                        rules={[{ required: true }]}
                      >
                        <TimePicker
                          format={'HH:mm'}
                          suffixIcon={null}
                          placeholder={'Pickup close time'}
                          minuteStep={15}
                          style={{
                            width: 100,
                          }}
                        />
                      </Form.Item>
                    </Space>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="pickup_contact_name"
                      label="Contact name"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Enter name." />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="pickup_contact_email"
                      label="Contact email"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Enter email." />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Space.Compact>
                      <Form.Item
                        name="pickup_contact_phone"
                        label="Contact phone"
                        rules={[{ required: true }]}
                      >
                        <Input placeholder="Enter phone." />
                      </Form.Item>
                      <Form.Item
                        label=" "
                        name="pickup_contact_phone_ext"
                        rules={[{ required: false }]}
                      >
                        <Input placeholder="Ext." />
                      </Form.Item>
                    </Space.Compact>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Zipcode"
                      name="pickup_zipcode"
                      rules={[{ required: true }]}
                    >
                      <Input
                        disabled={
                          !manualMode ||
                          model?.business_status >=
                            ShipmentBusinessStatus.PENDING_PICKUP
                        }
                        placeholder="Enter zipcode."
                      />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item
                      label="City"
                      name="pickup_city_id"
                      rules={[{ required: true }]}
                    >
                      <Select
                        allowClear
                        placeholder="Please select city"
                        options={pickupCityList.map((item) => ({
                          value: item.id,
                          label: `${item.name}, ${item.state}`,
                        }))}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={16}>
                    <Form.Item
                      name="pickup_address1"
                      label="Address line1"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Enter address line1." />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="pickup_address2" label="Address line2">
                      <Input placeholder="Enter address line2." />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      name="pickup_special_request"
                      label="Special requirement"
                    >
                      <Input placeholder="Enter special requirement." />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="pickup_address_type"
                      label="Address Type"
                      initialValue={LTLAddressType.BUSINESS}
                    >
                      <Select
                        disabled={
                          !manualMode ||
                          model?.business_status >=
                            ShipmentBusinessStatus.PENDING_PICKUP
                        }
                        placeholder="Please select address type"
                        options={addressTypes}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item
                      name="pickup_accessorials"
                      label="Accessorial services"
                    >
                      <Select
                        disabled={
                          !manualMode ||
                          model?.business_status >=
                            ShipmentBusinessStatus.PENDING_PICKUP
                        }
                        mode="multiple"
                        allowClear
                        placeholder="Please select accessorial services"
                        options={pickupAdditions}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={12}>
              <Card
                title={
                  <Space>
                    <span style={{ fontWeight: 'bold' }}>DESTINATION</span>
                    {form.getFieldValue('destination_address1') &&
                      form.getFieldValue('destination_city_id') &&
                      form.getFieldValue('destination_zipcode') && (
                        <AddressVerifier<UsedAddressShipment>
                          address={{
                            addressLine1: form.getFieldValue(
                              'destination_address1',
                            ),
                            addressLine2: form.getFieldValue(
                              'destination_address2',
                            ),
                            cityId: form.getFieldValue('destination_city_id'),
                            zipcode: form.getFieldValue('destination_zipcode'),
                          }}
                          addressType={form.getFieldValue(
                            'destination_address_type',
                          )}
                          accessorials={form.getFieldValue(
                            'destination_accessorials',
                          )}
                          referenceId={model?.id}
                          referenceType={ReferenceType.LTLShipment}
                          tag="destination"
                          usedReferences={usedReferences}
                        />
                      )}
                  </Space>
                }
                size="small"
                style={{
                  borderColor: '#daf8f6',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                }}
                headStyle={{ backgroundColor: '#daf8f6', fontWeight: 'bold' }}
              >
                <Row gutter={32}>
                  <Col span={8}>
                    <Form.Item
                      name="destination_company_name"
                      label="Facility name"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Enter facility name." />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Space direction="horizontal">
                      <Form.Item
                        name="destination_open_time"
                        label="Open Time"
                        initialValue={moment('08:00', 'HH:mm')}
                        rules={[{ required: true }]}
                      >
                        <TimePicker
                          format={'HH:mm'}
                          suffixIcon={null}
                          placeholder={'Delivery open time'}
                          minuteStep={15}
                          style={{
                            width: 100,
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name="destination_close_time"
                        label="Close Time"
                        initialValue={moment('21:00', 'HH:mm')}
                        rules={[{ required: true }]}
                      >
                        <TimePicker
                          format={'HH:mm'}
                          suffixIcon={null}
                          placeholder={'Delivery close time'}
                          minuteStep={15}
                          style={{
                            width: 100,
                          }}
                        />
                      </Form.Item>
                    </Space>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="destination_contact_name"
                      label="Contact name"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Enter name." />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="destination_contact_email"
                      label="Contact email"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Enter email." />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Space.Compact>
                      <Form.Item
                        name="destination_contact_phone"
                        label="Contact phone"
                        rules={[{ required: true }]}
                      >
                        <Input placeholder="Enter phone." />
                      </Form.Item>
                      <Form.Item
                        label=" "
                        name="destination_contact_phone_ext"
                        rules={[{ required: false }]}
                      >
                        <Input placeholder="Ext." />
                      </Form.Item>
                    </Space.Compact>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Zip"
                      name="destination_zipcode"
                      rules={[{ required: true }]}
                    >
                      <Input
                        disabled={
                          !manualMode ||
                          model?.business_status >=
                            ShipmentBusinessStatus.PENDING_PICKUP
                        }
                        placeholder="Enter zipcode."
                      />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item
                      label="City"
                      name="destination_city_id"
                      rules={[{ required: true }]}
                    >
                      <Select
                        allowClear
                        placeholder="Please select city"
                        options={destinationCityList.map((item) => ({
                          value: item.id,
                          label: `${item.name}, ${item.state}`,
                        }))}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={16}>
                    <Form.Item
                      name="destination_address1"
                      label="Address line1"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Enter address line1." />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="destination_address2"
                      label="Address line2"
                    >
                      <Input placeholder="Enter address line2." />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      name="destination_special_request"
                      label="Special requirement"
                    >
                      <Input placeholder="Enter special requirement." />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="destination_address_type"
                      label="Address Type"
                      initialValue={LTLAddressType.BUSINESS}
                    >
                      <Select
                        disabled={
                          !manualMode ||
                          model?.business_status >=
                            ShipmentBusinessStatus.PENDING_PICKUP
                        }
                        placeholder="Please select address type"
                        options={addressTypes}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item
                      name="destination_accessorials"
                      label="Accessorial services"
                    >
                      <Select
                        disabled={
                          !manualMode ||
                          model?.business_status >=
                            ShipmentBusinessStatus.PENDING_PICKUP
                        }
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Please select accessorial services"
                        options={destinationAdditions}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={11}>
              <Form.Item
                name="pickup_location_memo"
                label="Pickup Location Memo"
              >
                <Input.TextArea
                  rows={3}
                  placeholder="Enter pickup location memo."
                />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                name="destination_location_memo"
                label="Delivery Location Memo"
              >
                <Input.TextArea
                  rows={3}
                  placeholder="Enter delivery location memo."
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Space direction="horizontal" size={16}>
                <Form.Item
                  label="Original Pickup Date"
                  name="original_pickup_date"
                  rules={[{ required: true }]}
                  getValueFromEvent={(onChange) =>
                    onChange ? moment(onChange).format('YYYY-MM-DD') : null
                  }
                  getValueProps={(v) => ({ value: v ? moment(v) : null })}
                >
                  <DatePicker
                    disabled={!manualMode || Boolean(model)}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                {manualMode && (
                  <Form.Item
                    label="Original Delivery Date"
                    name="original_delivery_date"
                    getValueFromEvent={(onChange) =>
                      onChange ? moment(onChange).format('YYYY-MM-DD') : null
                    }
                    getValueProps={(v) => ({ value: v ? moment(v) : null })}
                    rules={[
                      { required: manualMode },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const pickupDate = getFieldValue(
                            'original_pickup_date',
                          );
                          if (
                            value &&
                            pickupDate &&
                            moment(value).isBefore(moment(pickupDate))
                          ) {
                            return Promise.reject(
                              new Error(
                                'Delivery date must be after or equal to pickup date',
                              ),
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <DatePicker
                      disabled={!manualMode || Boolean(model)}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                )}
                <Form.Item
                  name="user_id"
                  label="Customer"
                  rules={[{ required: true }]}
                >
                  <UserSyncSelect
                    // disabled={
                    //   !manualMode ||
                    //   model?.business_status >=
                    //     ShipmentBusinessStatus.PENDING_PICKUP
                    // }
                    disabled={isCustomerDisabled}
                    style={{ width: 160 }}
                  />
                </Form.Item>
                <Form.Item name="operator_id" label="Operator">
                  <UserSyncSelect
                    style={{ width: 160 }}
                    type="admin"
                    allowClear
                  />
                </Form.Item>
                <Form.Item name="sales_id" label="Sales">
                  <UserSyncSelect
                    style={{ width: 160 }}
                    type="admin"
                    allowClear
                  />
                </Form.Item>
                <Form.Item name="sales_support_id" label="Sales Support">
                  <UserSyncSelect
                    style={{ width: 160 }}
                    type="admin"
                    allowClear
                  />
                </Form.Item>
                <Form.Item name="quote_rate" label="Quote rate">
                  <InputNumber
                    disabled={
                      !manualMode ||
                      model?.business_status >=
                        ShipmentBusinessStatus.PENDING_PICKUP
                    }
                  />
                </Form.Item>

                {manualMode ? (
                  <>
                    <Form.Item name="vendor_rate" label="Buy rate">
                      <InputNumber min={0} max={99999.99} />
                    </Form.Item>
                    <Form.Item
                      name="sell_rate"
                      label="Sell rate"
                      rules={[{ required: true }]}
                    >
                      <InputNumber
                        min={0}
                        onChange={(val: number | null) => {
                          calcuTotalSellRate(
                            val,
                            form.getFieldValue('premium'),
                          );
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="premium"
                      label="Premium"
                      rules={[{ required: false }]}
                    >
                      <InputNumber disabled min={0} />
                    </Form.Item>
                    <Form.Item
                      name="total_sell_rate"
                      label="Total SR"
                      rules={[{ required: true }]}
                    >
                      <InputNumber disabled min={0} />
                    </Form.Item>
                  </>
                ) : (
                  <> </>
                )}
              </Space>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Space>
                {manualMode ? (
                  <>
                    <Form.Item
                      name="vendor_id"
                      label="Vendor"
                      rules={[{ required: true }]}
                    >
                      <TLVendorSelect
                        tlType={TLType.LTL}
                        style={{ minWidth: '200px' }}
                        selected={form.getFieldValue('vendor_id')}
                        onSelect={(vendor: any) =>
                          form.setFieldValue('vendor_id', vendor?.id || null)
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      name="carrier_id"
                      label="Carrier"
                      rules={[{ required: true }]}
                    >
                      <LTLModelSelect identify="carrier" />
                    </Form.Item>
                    <Form.Item
                      name="vendor_shipment_id"
                      label="Vendor Shipment ID"
                    >
                      <Input placeholder="Enter vendor shipment id." />
                    </Form.Item>
                    <Form.Item name="quote_transit_days" label="Transit Days">
                      <InputNumber min={0} max={100} addonAfter="days" />
                    </Form.Item>
                  </>
                ) : (
                  <></>
                )}
                <Form.Item label="Distance" name="distance">
                  <InputNumber
                    disabled
                    style={{
                      width: '100%',
                    }}
                    addonAfter="miles"
                  />
                </Form.Item>
              </Space>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={3}>
              {/* <Space direction="horizontal" size={16}> */}
              <Form.Item
                label="Customer Reference Number"
                name="customer_reference_number"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="Pickup Number" name="pickup_number">
                <Input />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="Dropoff Number" name="dropoff_number">
                <Input />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item
                name="is_insurance_entrusted"
                hidden
                noStyle
              ></Form.Item>
              <Form.Item name="cargo_value" hidden noStyle></Form.Item>
              <Form.Item shouldUpdate>
                {({ getFieldValue }) => (
                  <Form.Item
                    label={'Insurance'}
                    rules={[{ required: true, message: 'Required.' }]}
                  >
                    <Radio.Group
                      value={getFieldValue('is_insurance_entrusted')}
                      onChange={(event: RadioChangeEvent) => {
                        const isEntrusted = event.target.value;

                        if (isEntrusted) {
                          form.setFieldsValue({
                            is_insurance_entrusted: true,
                          });
                        } else {
                          form.setFieldsValue({
                            is_insurance_entrusted: false,
                            cargo_value: 0,
                            premium: 0,
                          });
                        }
                      }}
                    >
                      <Radio value={true}>
                        Purchase insurance by DrayEasy{' '}
                        <InputNumber
                          style={{ width: '8rem' }}
                          min={1}
                          size="small"
                          max={10000}
                          step={1}
                          parser={(value) =>
                            value ? value.replace(/\.\d*$/, '') : ''
                          }
                          value={getFieldValue('cargo_value')}
                          placeholder="Cargo Value: max 10000usd"
                          disabled={
                            disabled || !getFieldValue('is_insurance_entrusted')
                          }
                          onChange={(val: number | null) => {
                            const premium = val ? calcPremium(val) : 0;
                            form.setFieldsValue({
                              cargo_value: val,
                              premium,
                            });
                            calcuTotalSellRate(
                              form.getFieldValue('sell_rate'),
                              premium,
                            );
                          }}
                        />
                        <Typography.Text type="secondary">
                          {' '}
                          Premium:{getFieldValue('premium')}
                        </Typography.Text>
                      </Radio>
                      <Radio value={false}>Basic Carrier Insurance </Radio>
                    </Radio.Group>
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            {/* </Space> */}
          </Row>
          <Row>
            <Col span={20}>
              <Form.Item label="Remarks" name="remarks">
                <Input.TextArea showCount maxLength={500} rows={4} />
              </Form.Item>
            </Col>
          </Row>
        </Space>
        <Form.List name="items">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Row
                  key={key}
                  justify="start"
                  gutter={[16, 16]}
                  align="stretch"
                >
                  <Col>
                    <Form.Item
                      {...restField}
                      name={[name, 'units']}
                      initialValue={1}
                      label={name > 0 ? '' : 'Units'}
                    >
                      <InputNumber
                        min={1}
                        placeholder="Units"
                        disabled={
                          !manualMode ||
                          model?.business_status >=
                            ShipmentBusinessStatus.PENDING_PICKUP
                        }
                        style={{
                          width: 120,
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      {...restField}
                      name={[name, 'package_type']}
                      label={name > 0 ? '' : 'Package Type'}
                      initialValue={'Pallet'}
                      rules={[{ required: true }]}
                    >
                      <Select
                        placeholder="Select package type."
                        options={packageTypes}
                        disabled={
                          !manualMode ||
                          model?.business_status >=
                            ShipmentBusinessStatus.PENDING_PICKUP
                        }
                        style={{
                          width: 100,
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      {...restField}
                      name={[name, 'pieces']}
                      initialValue={1}
                      label={name > 0 ? '' : 'Pieces'}
                    >
                      <InputNumber
                        min={1}
                        placeholder="Pieces"
                        disabled={
                          !manualMode ||
                          model?.business_status >=
                            ShipmentBusinessStatus.PENDING_PICKUP
                        }
                        style={{
                          width: 120,
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item label={name > 0 ? '' : 'Dimensions'}>
                      <Space.Compact>
                        <Form.Item
                          {...restField}
                          name={[name, 'length']}
                          initialValue={1}
                          rules={[
                            { required: true, message: 'Missing length' },
                          ]}
                          extra={
                            <DimensionItemExtra
                              form={form}
                              name={name}
                              extraKey={'length'}
                            />
                          }
                        >
                          <InputNumber
                            min={1}
                            placeholder="Length"
                            disabled={
                              !manualMode ||
                              model?.business_status >=
                                ShipmentBusinessStatus.PENDING_PICKUP
                            }
                            style={{
                              width: 120,
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'width']}
                          initialValue={1}
                          rules={[{ required: true, message: 'Missing width' }]}
                          extra={
                            <DimensionItemExtra
                              form={form}
                              name={name}
                              extraKey={'width'}
                            />
                          }
                        >
                          <InputNumber
                            min={1}
                            placeholder="Width"
                            disabled={
                              !manualMode ||
                              model?.business_status >=
                                ShipmentBusinessStatus.PENDING_PICKUP
                            }
                            style={{
                              width: 120,
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'height']}
                          initialValue={1}
                          rules={[
                            { required: true, message: 'Missing height' },
                          ]}
                          extra={
                            <DimensionItemExtra
                              form={form}
                              name={name}
                              extraKey={'height'}
                            />
                          }
                        >
                          <InputNumber
                            min={1}
                            placeholder="Height"
                            disabled={
                              !manualMode ||
                              model?.business_status >=
                                ShipmentBusinessStatus.PENDING_PICKUP
                            }
                            style={{
                              width: 190,
                            }}
                            addonAfter={
                              <Form.Item
                                {...restField}
                                name={[name, 'dimension_unit']}
                                initialValue={'in'}
                                noStyle
                                rules={[{ required: true }]}
                              >
                                <Select
                                  style={{
                                    width: 70,
                                  }}
                                  options={[
                                    {
                                      value: 'in',
                                      label: 'in',
                                    },
                                    {
                                      value: 'cm',
                                      label: 'cm',
                                    },
                                  ]}
                                  disabled={
                                    !manualMode ||
                                    model?.business_status >=
                                      ShipmentBusinessStatus.PENDING_PICKUP
                                  }
                                />
                              </Form.Item>
                            }
                          />
                        </Form.Item>
                      </Space.Compact>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      {...restField}
                      name={[name, 'total_weight']}
                      initialValue={1}
                      label={name > 0 ? '' : 'Total weight'}
                      rules={[
                        { required: true, message: 'Missing total weight' },
                      ]}
                      extra={
                        <WeightItemExtra
                          form={form}
                          name={name}
                          extraKey={'total_weight'}
                        />
                      }
                    >
                      <InputNumber
                        min={1}
                        placeholder="Total weight"
                        style={{
                          width: 200,
                        }}
                        disabled={
                          !manualMode ||
                          model?.business_status >=
                            ShipmentBusinessStatus.PENDING_PICKUP
                        }
                        addonAfter={
                          <Form.Item
                            {...restField}
                            name={[name, 'weight_unit']}
                            initialValue={'lbs'}
                            rules={[{ required: true }]}
                            noStyle
                          >
                            <Select
                              style={{
                                width: 70,
                              }}
                              options={[
                                {
                                  value: 'kg',
                                  label: 'kg',
                                },
                                {
                                  value: 'lbs',
                                  label: 'lbs',
                                },
                              ]}
                              disabled={
                                !manualMode ||
                                model?.business_status >=
                                  ShipmentBusinessStatus.PENDING_PICKUP
                              }
                            />
                          </Form.Item>
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      {...restField}
                      name={[name, 'class']}
                      initialValue={'50'}
                      label={name > 0 ? '' : 'Class'}
                      rules={[{ required: true }]}
                      style={{
                        width: 120,
                      }}
                    >
                      <Select
                        placeholder="Select class."
                        options={classes}
                        disabled={
                          !manualMode ||
                          model?.business_status >=
                            ShipmentBusinessStatus.PENDING_PICKUP
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      {...restField}
                      name={[name, 'description']}
                      label={name > 0 ? '' : 'Description'}
                      rules={[{ required: true }]}
                      style={{
                        width: 250,
                      }}
                    >
                      <Input
                        placeholder="Enter description."
                        disabled={
                          !manualMode ||
                          model?.business_status >=
                            ShipmentBusinessStatus.PENDING_PICKUP
                        }
                      />
                    </Form.Item>
                  </Col>
                  {manualMode && (
                    <Col>
                      <Form.Item label={name > 0 ? '' : ' '}>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              ))}
              <ShipmentItemsAggregator items={form.getFieldValue('items')} />
              <Divider />
              {manualMode && (
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add item
                  </Button>
                </Form.Item>
              )}
            </>
          )}
        </Form.List>
        <Divider />
      </Form>
    </>
  );
};

export default ShipmentForm;
