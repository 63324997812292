import React from 'react';
import { Space, Tooltip } from 'antd';
import { LuTimerReset } from 'react-icons/lu';

import { TItemProps } from '.';
import { ResetSlaModal } from './ResetSlaModal';

export const RenderSla: React.FC<
{
  container?: any;
  showReset?: boolean;
  onSaved?: () => void;
} & TItemProps
> = ({ container, showReset = false, item, onSaved }) => {
  if (!item.sla_text) {
    return <></>;
  }

  return (
    <Space>
      <Tooltip
        title={
          <>
            <div>Due At: {item.due_at}</div>
            {item.finished_at && <div>Finished At: {item.finished_at}</div>}
          </>
        }>
        <span className={!item.is_done && item.is_overdue ? 'text-danger' : ''}>
          ({item.sla_text})
        </span>
      </Tooltip>

      {showReset && (
        <ResetSlaModal item={item} container={container} onSaved={onSaved} />
      )}
    </Space>
  );
};
