import React, { useState } from 'react';
import {
  Form,
  Select,
  message,
  InputNumber,
  Drawer,
  Space,
  Button,
  Input,
} from 'antd';

import { useApp } from '@/utils/useapp';
import { useForm } from 'antd/lib/form/Form';
import { AntDDatePicker, presetDates } from 'antd-datepicker';
import moment from 'moment';
import { FacilityTypeSelect } from '@/components/FacilityTypeSelect';
import { CargoTypeSelect } from '@/components/CargoTypeSelect';
import { BulkUpdateFormItem } from '@/components/BulkUpdateFormItem';

interface Props {
  ids: any;
  visible: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

type saveDataItem = {
  id: number;
  expired_at?: string;
  visible?: boolean;
  from_facility_type?: string;
  to_facility_type?: string;
  cargo_type_id?: number;
  note?: string;
};

export const DrawerFormBulkUpdate: React.FC<Props> = ({
  ids = [],
  visible = false,
  onClose,
  onSubmit,
}) => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [form] = useForm();
  const [names, setNames] = useState<Array<string>>([]);

  const handleSave = async () => {
    const values = await form.validateFields(names);
    const data: any = [];

    ids.map((id: any) => {
      const item = {
        id: id,
        ...values,
      };

      data.push(item);
    });

    setLoading(true);

    try {
      await app.service.put('sellRates/bulkUpdate', { data: data });
      message.success('Saved');
      form.resetFields();
      onSubmit();
    } catch (e: any) {
      message.error(e?.data?.message || e?.data?.error || 'Network Error');
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    form.resetFields();
    setNames([]);
    onClose();
  };

  const handleChange = async (event: any) => {
    const { name, value } = event.target as HTMLInputElement;
    await form.setFieldsValue({ [name]: value });
  };

  const handleListenNamesRequired = (name: string, required: boolean) => {
    const _names = Object.assign([], names);
    if (_names.find((n) => n == name)) {
      !required && setNames(_names.filter((n) => n != name));
    } else {
      _names.push(name);
      required && setNames(_names);
    }
  };

  return (
    <Drawer
      title={'Bulk Update'}
      placement={'right'}
      width={'50%'}
      onClose={handleClose}
      destroyOnClose={true}
      open={visible}
      extra={
        <Space>
          <Button onClick={handleClose}>Close</Button>
          <Button type="primary" loading={loading} onClick={handleSave}>
            Save
          </Button>
        </Space>
      }>
      <Form layout="vertical" form={form} onFinish={handleSave}>
        <BulkUpdateFormItem
          form={form}
          name="from_facility_type"
          label="From Facility Type"
          toListenNameRequired={handleListenNamesRequired}
          elementControlledByStatus={
            <FacilityTypeSelect
              disabled={names?.includes('from_facility_type') ? false : true}
              getValueForOption="name"
            />
          }
        />
        <BulkUpdateFormItem
          form={form}
          name="to_facility_type"
          label="To Facility Type"
          toListenNameRequired={handleListenNamesRequired}
          elementControlledByStatus={
            <FacilityTypeSelect
              disabled={names?.includes('to_facility_type') ? false : true}
              getValueForOption="name"
            />
          }
        />

        {/* <BulkUpdateFormItem
          form={form}
          name="cargo_type_id"
          label="Cargo Type"
          toListenNameRequired={handleListenNamesRequired}
          elementControlledByStatus={
            <CargoTypeSelect
              disabled={names?.includes('cargo_type_id') ? false : true}
            />
          }
        /> */}
        <BulkUpdateFormItem
          form={form}
          name="base_rate"
          label="Base Rate"
          toListenNameRequired={handleListenNamesRequired}
          elementControlledByStatus={
            <InputNumber
              style={{ width: '100%' }}
              disabled={names?.includes('base_rate') ? false : true}
            />
          }
        />
        <BulkUpdateFormItem
          form={form}
          name="expired_at"
          label="Expired Date"
          toListenNameRequired={handleListenNamesRequired}
          elementControlledByStatus={
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <>
                  <Form.Item
                    name={'expired_at'}
                    label=""
                    noStyle
                    rules={[
                      {
                        // required: getFieldValue('expired_at') ? true : false,
                        message: 'Expired Date is required',
                      },
                    ]}>
                    <Input hidden />
                  </Form.Item>
                  <Form.Item label="" noStyle>
                    <AntDDatePicker
                      disabled={names?.includes('expired_at') ? false : true}
                      presetDates={presetDates}
                      style={{ width: '100%' }}
                      value={
                        getFieldValue('expired_at')
                          ? moment(getFieldValue('expired_at'))
                          : null
                      }
                      onChange={(v) =>
                        handleChange({
                          target: {
                            name: 'expired_at',
                            value: v?.format('YYYY-MM-DD'),
                          },
                        })
                      }
                    />
                  </Form.Item>
                </>
              )}
            </Form.Item>
          }
        />
        <BulkUpdateFormItem
          form={form}
          name="visible"
          label="Visible"
          toListenNameRequired={handleListenNamesRequired}
          elementControlledByStatus={
            <Select
              disabled={names?.includes('visible') ? false : true}
              onChange={(visible) =>
                handleChange({
                  target: {
                    name: 'visible',
                    value: visible,
                  },
                })
              }>
              <Select.Option value={true}>Visible</Select.Option>
              <Select.Option value={false}>InVisble</Select.Option>
            </Select>
          }
        />
        <BulkUpdateFormItem
          form={form}
          name="note"
          label="Note For Customer"
          toListenNameRequired={handleListenNamesRequired}
          elementControlledByStatus={
            <Input.TextArea
              disabled={names?.includes('note') ? false : true}
              rows={5}
              placeholder="Enter note"
            />
          }
        />
        <BulkUpdateFormItem
          form={form}
          name="locked"
          label="Locked"
          toListenNameRequired={handleListenNamesRequired}
          elementControlledByStatus={
            <Select
              disabled={names?.includes('visible') ? false : true}
              onChange={(visible) =>
                handleChange({
                  target: {
                    name: 'visible',
                    value: visible,
                  },
                })
              }>
              <Select.Option value={true}>locked</Select.Option>
              <Select.Option value={false}>unlocked</Select.Option>
            </Select>
          }
        />
      </Form>
    </Drawer>
  );
};
