import { CACHE_COMMODITY_KEY } from '@/stores/cache';
import React from 'react';
import SyncCacheModelSelect, {
  SyncCacheModelSelectProps,
} from './SyncCacheModelSelect';
import { OptionProps } from 'antd/lib/select';

export const CommoditiesSyncSelect: React.FC<
SyncCacheModelSelectProps & {
  selected?: any;
  onSelect?: (value: number, option: OptionProps) => void;
  className?: string;
}
> = ({ selected, onSelect, ...props }) => {
  return (
    <SyncCacheModelSelect
      {...props}
      // onSelect={onSelect}
      onChange={onSelect}
      cacheKey={CACHE_COMMODITY_KEY}
      // mode='tags'
    />
  );
};
