import { CACHE_INTERMODALREGION_OCEAN_SELECT_KEY } from '@/stores/cache';
import React from 'react';
import SyncCacheModelSelect, {
  SyncCacheModelSelectProps,
} from './SyncCacheModelSelect';

export const IntermodalRegionOceanSelect: React.FC<
SyncCacheModelSelectProps
> = ({ ...props }) => {
  return (
    <SyncCacheModelSelect
      {...props}
      cacheKey={CACHE_INTERMODALREGION_OCEAN_SELECT_KEY}
    />
  );
};
