import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { useApp } from '@/utils/useapp';
import { message, Spin } from 'antd';
import { TRateHeat } from '@/types';

const defaultProps = {
  center: {
    lat: 39.8283,
    lng: -98.5795,
  },
  zoom: 5,
  height: '840px',
};

const Index = () => {
  const app = useApp();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<TRateHeat[]>([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await app.service.get('statistics/ratesHeatMapData');
      setData(res.data);
    } catch (e: any) {
      message.error(e.error || 'network error');
    }
    setLoading(false);
  };

  const getHeatMapData = () => {
    return {
      positions: data,
      options: {
        radius: 20,
        opacity: 0.6,
      },
    };
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: MIX_GOOGLE_MAP_API_KEY ?? '',
          region: 'us',
          language: 'en',
        }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        heatmapLibrary={true}
        heatmap={getHeatMapData()}
        // onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
      >
        {loading ? <Spin /> : null}
      </GoogleMapReact>
    </div>
  );
};

export default Index;
