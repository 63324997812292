import React from 'react';

import {
  InputNumber,
  Select,
  Popover,
  Typography,
  Space,
  message,
  Descriptions,
  Spin,
} from 'antd';
import { useApp } from '@/utils/useapp';
import { get } from 'lodash';
import { EyeOutlined } from '@ant-design/icons';
import {
  CHASSIS_OWNER_MAP,
  CHASSIS_OWNER_NO_IDEA,
} from '@/pages/orders/components/data';
import {
  CHASSIS_TYPE_MAP,
  CHASSIS_TYPE_NO_IDEA,
} from '@/pages/containers/components/data';

const RELEASE_TYPES_MAP = {
  freight_released_at: 'Freight Release',
  customs_released_at: 'Customs Release',
  terminal_released_at: 'Terminal Release',
};

export const Chassis: React.FC<{
  container: any;
  refreshContainer: (id: number) => void;
}> = ({ container, refreshContainer }) => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);

  const handlePatchUpdate = async (id: number, values: any) => {
    setLoading(true);
    try {
      await app.service.patch(`containers/${id}`, { data: values });
      refreshContainer(id);
    } catch (error: any) {
      message.error(error.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Space>
      <Popover
        trigger="click"
        placement="right"
        content={
          <Spin spinning={loading}>
            <Descriptions className="mt-md" layout="vertical" bordered>
              <Descriptions.Item label="Chassis Owner">
                <Select
                  style={{ width: '10rem' }}
                  value={get(container, 'chassis_owner')}
                  onChange={(v) =>
                    handlePatchUpdate(container.id, { chassis_owner: v })
                  }>
                  {Object.keys(CHASSIS_OWNER_MAP).map((key: string) => {
                    return (
                      <Select.Option key={key} value={Number(key)}>
                        {
                          CHASSIS_OWNER_MAP[
                            key as unknown as keyof typeof CHASSIS_OWNER_MAP
                          ]
                        }
                      </Select.Option>
                    );
                  })}
                </Select>
              </Descriptions.Item>
              <Descriptions.Item label="Chassis Type">
                <Select
                  style={{ width: '10rem' }}
                  value={get(container, 'chassis_type')}
                  onChange={(v) => {
                    handlePatchUpdate(container.id, { chassis_type: v });
                  }}>
                  {Object.keys(CHASSIS_TYPE_MAP).map((key: string) => {
                    return (
                      <Select.Option key={key} value={Number(key)}>
                        {
                          CHASSIS_TYPE_MAP[
                            key as unknown as keyof typeof CHASSIS_TYPE_MAP
                          ]
                        }
                      </Select.Option>
                    );
                  })}
                </Select>
              </Descriptions.Item>
              <Descriptions.Item label="Chassis Fee">
                <InputNumber
                  style={{ width: '10rem' }}
                  value={get(container, 'chassis_fee')}
                  onChange={(v) => {
                    handlePatchUpdate(container.id, { chassis_fee: v });
                  }}
                />
              </Descriptions.Item>
            </Descriptions>
          </Spin>
        }>
        <Typography.Link>
          {get(container, 'chassis_business_days') ?? '-'} /{' '}
          {get(container, 'chassis_usage') ?? '-'}
        </Typography.Link>
      </Popover>
    </Space>
  );
};
