import React, { useEffect, useMemo, useState } from 'react';
import {
  Switch,
  Form,
  Input,
  Select,
  InputNumber,
  Typography,
  Row,
  Col,
  Space,
  Button,
} from 'antd';
import { get, reduce } from 'lodash';
import { CitySelect } from '@/components/CitySelect';

interface Props {
  loading: boolean;
  rates: any;
  intermodalRegion: any;
  requestWarehouses: any;
  filter: any;
  isRemind: boolean;
  clearAll: () => void;
  setShowAllFilter: (show: boolean) => void;
  onChange: (values: any) => void;
  handleSearch: () => void;
}

const FormLayout = {
  // labelCol: { span: 6 },
  // wrapperCol: { span: 18 },
};
export const SearchVendors: React.FC<Props> = ({
  rates,
  loading,
  intermodalRegion,
  requestWarehouses,
  filter,
  isRemind = false,
  clearAll,
  setShowAllFilter,
  onChange,
  handleSearch,
}) => {
  const [isShow, setIsShow] = useState(false);

  const handleFilterChange = (key: string, value: any) => {
    onChange({ ...filter, [key]: value });
  };

  const handleClearAll = () => {
    clearAll();
    //filter.resetFields();

    // onSearch();
  };

  const handleShowAllFilter = (show: boolean) => {
    setIsShow(show);
    setShowAllFilter(show);
  };

  React.useEffect(() => {
    if (filter || isRemind) {
      return;
    }

    onChange({
      to_city_ids: requestWarehouses.map((w) => w.to_city_id),
      name: '',
      contact_count: '',
      rating: '',
      driver_count: 1,
      city: '',
      enabled: 'true',
      has_rate_count: '',
      has_valid_rate: 'false',
      blacklisted: 'false',
      contactable: 'true',
      overweight_ability: '',
      hazmat_ability: '',
      fba_delivery: '',
      residential_ability: '',
      reefer_ability: '',
      out_of_gauge_ability: '',
      open_top_ability: '',
      flat_rack_ability: '',
      menards_approval_ability: '',
      transloading_ability: '',
      boned_trucker_ability: '',
      has_drayeasy: 'false',
    });
  }, []);

  return (
    <>
      <Form layout={'vertical'}>
        <Form.Item hidden name="to_city_ids" />
        <Row gutter={10}>
          <Col span={3}>
            <Form.Item {...FormLayout} label="Intermodal Region">
              <Typography.Text> {intermodalRegion?.name}</Typography.Text>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item {...FormLayout} label="Warehouse City">
              <Typography.Text>
                {requestWarehouses
                  ?.map((w: any) => {
                    return w.name + ' ' + w.zipcode;
                  })
                  .join(' / ')}
              </Typography.Text>
            </Form.Item>
          </Col>
          {!isRemind && (
            <>
              <Col span={4}>
                <Form.Item {...FormLayout} label="Name">
                  <Input
                    placeholder="Name"
                    value={filter?.name || ''}
                    onChange={(e) => handleFilterChange('name', e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item {...FormLayout} label="Driver Count">
                  <InputNumber
                    style={{ width: '100%' }}
                    value={filter?.driver_count}
                    onChange={(v) => handleFilterChange('driver_count', v)}
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item {...FormLayout} label="Has Rate Input">
                  <Select
                    allowClear
                    placeholder="All"
                    showSearch
                    value={filter?.has_rate_count}
                    onChange={(v) => handleFilterChange('has_rate_count', v)}
                  >
                    <Select.Option value="">All</Select.Option>
                    <Select.Option value="true">Yes</Select.Option>
                    <Select.Option value="false">No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item {...FormLayout} label="Has Valid Rate">
                  <Select
                    allowClear
                    placeholder="All"
                    showSearch
                    value={filter?.has_valid_rate}
                    onChange={(v) => handleFilterChange('has_valid_rate', v)}
                  >
                    <Select.Option value="true">Yes</Select.Option>
                    <Select.Option value="false">No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="&nbsp;">
                  <Space>
                    <Button onClick={() => handleShowAllFilter(!isShow)}>
                      {isShow ? 'Hide Filters' : 'Show All Filters'}
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={loading}
                      onClick={handleSearch}
                    >
                      Search
                    </Button>
                    <Button onClick={handleClearAll}>Clear All</Button>
                  </Space>
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
        {!isRemind && isShow && (
          <Row gutter={10}>
            <Col span={4}>
              <Form.Item {...FormLayout} label="Contact Count">
                <InputNumber
                  style={{ width: '100%' }}
                  value={filter?.contact_count}
                  onChange={(v) => handleFilterChange('contact_count', v)}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item {...FormLayout} label="Rating">
                <InputNumber
                  style={{ width: '100%' }}
                  value={filter?.rating}
                  onChange={(v) => handleFilterChange('rating', v)}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item {...FormLayout} label="City">
                <CitySelect
                  selected={filter?.city}
                  onSelect={(city) => handleFilterChange('city', city)}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item {...FormLayout} label="Enabled">
                <Select
                  allowClear
                  placeholder="All"
                  showSearch
                  value={filter?.enabled}
                  onChange={(v) => handleFilterChange('enabled', v)}
                >
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item {...FormLayout} label="Blacklisted">
                <Select
                  allowClear
                  placeholder="All"
                  showSearch
                  value={filter?.blacklisted}
                  onChange={(v) => handleFilterChange('blacklisted', v)}
                >
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item {...FormLayout} label="Has Own Chassis">
                <Select
                  allowClear
                  placeholder="All"
                  showSearch
                  value={filter?.has_own_chassis}
                  onChange={(v) => handleFilterChange('has_own_chassis', v)}
                >
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            {/* <Col span={4}>
              <Form.Item {...FormLayout} label="Favorited">
                <Select
                  allowClear
                  placeholder="All"
                  showSearch
                  value={filter?.favorited}
                  onChange={(v) => handleFilterChange('favorited', v)}>
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              </Form.Item>
            </Col> */}
            <Col span={4}>
              <Form.Item {...FormLayout} label="Contactable">
                <Select
                  allowClear
                  placeholder="All"
                  showSearch
                  value={filter?.contactable}
                  onChange={(v) => handleFilterChange('contactable', v)}
                >
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item {...FormLayout} label="Overweight Ability">
                <Select
                  allowClear
                  placeholder="All"
                  showSearch
                  value={filter?.overweight_ability}
                  onChange={(v) => handleFilterChange('overweight_ability', v)}
                >
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item {...FormLayout} label="Hazmat Ability">
                <Select
                  allowClear
                  placeholder="All"
                  showSearch
                  value={filter?.hazmat_ability}
                  onChange={(v) => handleFilterChange('hazmat_ability', v)}
                >
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item {...FormLayout} label="FBA Delivery">
                <Select
                  allowClear
                  placeholder="All"
                  showSearch
                  value={filter?.fba_delivery}
                  onChange={(v) => handleFilterChange('fba_delivery', v)}
                >
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item {...FormLayout} label="Residential Ability">
                <Select
                  allowClear
                  placeholder="All"
                  showSearch
                  value={filter?.residential_ability}
                  onChange={(v) => handleFilterChange('residential_ability', v)}
                >
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item {...FormLayout} label="Reefer Ability">
                <Select
                  allowClear
                  placeholder="All"
                  showSearch
                  value={filter?.reefer_ability}
                  onChange={(v) => handleFilterChange('reefer_ability', v)}
                >
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item {...FormLayout} label="Liquor Alcohol Ability">
                <Select
                  allowClear
                  placeholder="All"
                  showSearch
                  value={filter?.liquor_alcohol_ability}
                  onChange={(v) =>
                    handleFilterChange('liquor_alcohol_ability', v)
                  }
                >
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item {...FormLayout} label="Household Goods Ability">
                <Select
                  allowClear
                  placeholder="All"
                  showSearch
                  value={filter?.household_goods_ability}
                  onChange={(v) =>
                    handleFilterChange('household_goods_ability', v)
                  }
                >
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item {...FormLayout} label="Tsa Ability">
                <Select
                  allowClear
                  placeholder="All"
                  showSearch
                  value={filter?.tsa_ability}
                  onChange={(v) => handleFilterChange('tsa_ability', v)}
                >
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item {...FormLayout} label="Chains Ability">
                <Select
                  allowClear
                  placeholder="All"
                  showSearch
                  value={filter?.chains_ability}
                  onChange={(v) => handleFilterChange('chains_ability', v)}
                >
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item {...FormLayout} label="Tank Endorsed Drivers Ability">
                <Select
                  allowClear
                  placeholder="All"
                  showSearch
                  value={filter?.tank_endorsed_drivers_ability}
                  onChange={(v) =>
                    handleFilterChange('tank_endorsed_drivers_ability', v)
                  }
                >
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item {...FormLayout} label="Iso Tank Drayage Ability">
                <Select
                  allowClear
                  placeholder="All"
                  showSearch
                  value={filter?.iso_tank_drayage_ability}
                  onChange={(v) =>
                    handleFilterChange('iso_tank_drayage_ability', v)
                  }
                >
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item {...FormLayout} label="Day Cab Ability">
                <Select
                  allowClear
                  placeholder="All"
                  showSearch
                  value={filter?.day_cab_ability}
                  onChange={(v) => handleFilterChange('day_cab_ability', v)}
                >
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item {...FormLayout} label="OOG Ability">
                <Select
                  allowClear
                  placeholder="All"
                  showSearch
                  value={filter?.out_of_gauge_ability}
                  onChange={(v) =>
                    handleFilterChange('out_of_gauge_ability', v)
                  }
                >
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item {...FormLayout} label="OpenTop Ability">
                <Select
                  allowClear
                  placeholder="All"
                  showSearch
                  value={filter?.open_top_ability}
                  onChange={(v) => handleFilterChange('open_top_ability', v)}
                >
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item {...FormLayout} label="FlatRack Ability">
                <Select
                  allowClear
                  placeholder="All"
                  showSearch
                  value={filter?.flat_rack_ability}
                  onChange={(v) => handleFilterChange('flat_rack_ability', v)}
                >
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item {...FormLayout} label="Menards Approval Ability">
                <Select
                  allowClear
                  placeholder="All"
                  showSearch
                  value={filter?.menards_approval_ability}
                  onChange={(v) =>
                    handleFilterChange('menards_approval_ability', v)
                  }
                >
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item {...FormLayout} label="Transloading Ability">
                <Select
                  allowClear
                  placeholder="All"
                  showSearch
                  value={filter?.transloading_ability}
                  onChange={(v) =>
                    handleFilterChange('transloading_ability', v)
                  }
                >
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item {...FormLayout} label="Bonded Trucker Ability">
                <Select
                  allowClear
                  placeholder="All"
                  showSearch
                  value={filter?.boned_trucker_ability}
                  onChange={(v) =>
                    handleFilterChange('boned_trucker_ability', v)
                  }
                >
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item {...FormLayout} label="Drayeasy">
                <Select
                  allowClear
                  placeholder="All"
                  showSearch
                  value={filter?.has_drayeasy}
                  onChange={(v) => handleFilterChange('has_drayeasy', v)}
                >
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </>
  );
};
