import React, { useEffect } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { get, truncate } from 'lodash';
import { Tooltip, Space, Popover, Divider, Typography, Tag } from 'antd';
import { WarehouseDrawer } from '@/pages/entity/warehouses/components/WarehouseDrawer';
import { EnvironmentOutlined, SendOutlined } from '@ant-design/icons';
import { MultipleDeliveries } from './MultipleDeliveries';
import Logo from '@/components/Logo';

const WHS: React.FC<{
  container: any;
  warehouse: any;
  onSaved: () => void;
}> = ({ container, warehouse, onSaved }) => {
  const [open, setOpen] = React.useState<boolean>(false);

  const handleSaved = (data: any) => {
    setOpen(false);
    onSaved();
  };

  const handleClosed = () => {
    setOpen(false);
  };

  const googleAddress = React.useMemo(() => {
    return `?q=${encodeURIComponent(warehouse?.address) || ''} ${
      warehouse?.city?.name || ''
    } ${warehouse?.city?.state || ''} ${warehouse?.country || ''} ${
      warehouse?.zipcode || ''
    }`;
  }, [warehouse]);

  const googleTransit = React.useMemo(() => {
    if (!container) {
      return '';
    }

    return container?.terminal
      ? `maps/dir/${encodeURIComponent(container?.terminal?.street)},+${
        container?.terminal?.city?.name
      },+${container?.terminal?.city?.state}/${
        encodeURIComponent(warehouse?.address) || ''
      }+${warehouse?.city?.name || ''},+${warehouse?.city?.state}+${
        warehouse?.country || ''
      }`
      : '';
  }, [container, warehouse]);

  return (
    <>
      <Space>
        {warehouse && (
          <span
            className="text-gray cursor-pointer"
            onClick={() => setOpen(!open)}>
            <InfoCircleOutlined />
          </span>
        )}
        {get(warehouse, 'is_pre_del_appt_sent') && (
          <Tooltip
            className="text-primary"
            title={`Pre Alert at ${get(
              warehouse,
              'pre_del_appt_sent_at',
            )} by ${get(warehouse, 'pre_del_appt_sender.name')}`}>
            <SendOutlined />
          </Tooltip>
        )}
        {warehouse && (
          <Popover
            placement="top"
            content={
              <Space split={<Divider />}>
                <a
                  type="link"
                  target="_blank"
                  rel="noreferrer"
                  href={`http://maps.google.com/${googleAddress}`}>
                  Address
                </a>
                {container.terminal && (
                  <a
                    type="link"
                    target="_blank"
                    rel="noreferrer"
                    href={`http://maps.google.com/${googleTransit}`}>
                    Transit
                  </a>
                )}
              </Space>
            }
            trigger="click">
            <a className="text-gray cursor-pointer" rel="noreferrer">
              <Tooltip
                title={
                  container.sell_rate?.rate?.distance
                    ? `${container.sell_rate?.rate?.distance} miles`
                    : ''
                }>
                <EnvironmentOutlined />
              </Tooltip>
            </a>
          </Popover>
        )}
        {warehouse && (
          <Tooltip
            title={
              <>
                <strong>{warehouse.code}</strong>
                <br />
                <span>{warehouse.name}</span>
                <br />
                <small>{warehouse.address}</small>
              </>
            }>
            {warehouse.is_special && <Tag color="red">S</Tag>}
            {truncate(get(warehouse, 'name', ''), {
              length: 22,
            })}
          </Tooltip>
        )}
        {warehouse?.company && <Logo name={warehouse?.company || ''} />}

        <Tooltip
          title={
            <>
              <strong>{'Total count of delivery to this warehouse'}</strong>
            </>
          }>
          <a
            type="link"
            target="_blank"
            rel="noreferrer"
            className="text-gray"
            href={`${window.location.origin}/dispatchs?link_warehouse_id=${warehouse?.id}`}>
            ({warehouse?.usage_count})
          </a>
        </Tooltip>
      </Space>
      {open && warehouse.id && (
        <WarehouseDrawer
          id={warehouse.id}
          container={container}
          // disabled={true}
          forbidToModfiySpecifyField={true}
          onSaved={handleSaved}
          onSent={onSaved}
          onClosed={handleClosed}
          visible={open}
        />
      )}
    </>
  );
};

export const WHSColumn: React.FC<{
  container: any;
  onSaved: () => void;
}> = ({ container, onSaved }) => {
  if (container.is_multi_deliveries) {
    return (
      <Space>
        <MultipleDeliveries container={container} onSaved={onSaved} />
        <div>
          {(container?.multiple_deliveries || [])?.map((delivery, i) => {
            return (
              <div key={i}>
                <WHS
                  warehouse={delivery.warehouse}
                  container={container}
                  onSaved={onSaved}
                />
              </div>
            );
          })}
        </div>
      </Space>
    );
  } else {
    return (
      <>
        <WHS
          container={container}
          warehouse={container.warehouse}
          onSaved={onSaved}
        />
      </>
    );
  }
};
