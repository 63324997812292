import { Tag } from 'antd';
import { get } from 'lodash';
import React, { useMemo, useEffect } from 'react';

interface IDeliveryOrderFormRowTag {
  containers: any;
}

export const RenderTag: React.FC<{
  container: any;
  showNumber: boolean;
}> = ({ container, showNumber }) => {
  const hasDGFormNotUploaded = useMemo(
    () =>
      container.is_dg &&
      (get(container, 'internal_tasks.file_tasks.dg_form', false)
        ? false
        : true),
    [container],
  );

  return (
    <div className="pb-xs">
      {showNumber && container.number + ': '}
      {hasDGFormNotUploaded && (
        <Tag color="red">DG form is not yet uploaded in the system </Tag>
      )}
    </div>
  );
};

export const DeliveryOrderFormRemindTag: React.FC<IDeliveryOrderFormRowTag> = ({
  containers,
}) => {
  const showNumber = useMemo(
    () => (containers?.length > 1 ? true : false),
    [containers],
  );

  return (
    <>
      {containers?.map((c: any, index: any) => (
        <RenderTag key={index} container={c} showNumber={showNumber} />
      ))}
    </>
  );
};
