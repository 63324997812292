import { Drawer, Descriptions, Space, Button } from 'antd';
import { useState } from 'react';

export const PreviewEmailContentDrawer: React.FC<{
  disabled: boolean;
  open: boolean;
  content: any;
  onClose: () => void;
  onConfirmSend: (data) => void;
}> = ({ disabled, open, content, onClose, onConfirmSend }) => {
  return (
    <Drawer
      width={'50%'}
      title="Preview Email"
      placement="right"
      onClose={onClose}
      extra={
        <Space>
          <Button onClick={onClose}>Close</Button>
          <Button
            disabled={disabled}
            type="primary"
            onClick={() => onConfirmSend(content)}>
            Confirm Send
          </Button>
        </Space>
      }
      open={open}>
      <Descriptions
        title=""
        bordered
        column={1}
        layout="vertical"
        style={{ width: '60vw' }}>
        <Descriptions.Item label="Subject">
          <div dangerouslySetInnerHTML={{ __html: content?.subject }} />
        </Descriptions.Item>
        <Descriptions.Item label="Body">
          <div dangerouslySetInnerHTML={{ __html: content?.body }} />
        </Descriptions.Item>
      </Descriptions>
    </Drawer>
  );
};
