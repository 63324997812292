import { BUYRATE_UID_PREFIX } from '@/pages/rates/buyRates/components/buyRateDrawer';
import { SELLRATE_UID_PREFIX } from '@/pages/rates/sellRates/components/sellRateDrawer';

export const isStringOfNumbers = (str: string) => {
  return /^\d+$/.test(str);
};

export const isBuyRate = (rateId: string) => {
  return new RegExp('^' + BUYRATE_UID_PREFIX + '\\d+').test(rateId);
};
export const isSellRate = (rateId: string) => {
  return new RegExp('^' + SELLRATE_UID_PREFIX + '\\d+').test(rateId);
};
