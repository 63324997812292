import { CACHE_ADMIN_KEY, CACHE_USER_SELECT_KEY } from '@/stores/cache';
import { Divider, Radio, Space } from 'antd';
import React, { useState } from 'react';
import SyncCacheModelSelect, {
  filterModelFn,
  SyncCacheModelSelectProps,
} from './SyncCacheModelSelect';

const filterModel: filterModelFn = (model: any, value: any) => {
  if (!model.deleted_at) {
    return true;
  }
  if (model.deleted_at && value == model.id) {
    return true;
  }
  return false;
};

// const ALL = 0;
// const ENABLED = 1;
// const DISABLED = -1;

export const UserSyncSelect: React.FC<
  SyncCacheModelSelectProps & {
    type?: 'user' | 'admin';
  }
> = ({
  statusTypes = {
    ALL: 0,
    ENABLED: 1,
    DISABLED: -1,
  },
  ...props
}) => {
  const { ALL, ENABLED, DISABLED } = statusTypes;
  const [selected, setSelected] = useState(ENABLED);

  return (
    <SyncCacheModelSelect
      {...props}
      cacheKey={
        props.type === 'admin' ? CACHE_ADMIN_KEY : CACHE_USER_SELECT_KEY
      }
      renderOption={
        props.type === 'admin'
          ? 'display_name'
          : (row) => `${row.company_code || ''}(${row.name})`
      }
      disabledFunc={(row: any) => {
        return selected == ALL
          ? false
          : selected == ENABLED
          ? row.status == DISABLED
          : row.status != DISABLED;
      }}
      classNameFunc={(row: any) => {
        return row.status == DISABLED ? 'disabled-item' : '';
      }}
      selected={selected}
      filterModel={filterModel}
      trySearchWhenNotFound={true}
      dropdownMatchSelectWidth={false}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <div className="flex justify-center">
            <Radio.Group
              value={selected}
              onChange={(e) => setSelected(e.target.value)}
              size="small"
            >
              <Radio.Button value={ALL}>All</Radio.Button>
              <Radio.Button value={ENABLED}>Enabled</Radio.Button>
              <Radio.Button value={DISABLED}>Disabled</Radio.Button>
            </Radio.Group>
          </div>
        </>
      )}
    />
  );
};
