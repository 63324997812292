import React from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  message,
  Space,
  Tag,
  TablePaginationConfig,
  Form,
  Row,
  Col,
  Input,
  Select,
  Switch,
  Popconfirm,
} from 'antd';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { TYPES } from './components/UserDrawer/UserForm';
import { UserDrawer } from './components/UserDrawer';
import { useForm } from 'antd/lib/form/Form';
import { TUser, TCollection } from '@/types';
import { get } from 'lodash';
import { Link } from 'umi';
import { IPLink } from '@/components/IPLInk';
import { ModalNewApiToken } from './components/ModalNewApiToken';
import AutoResizeTable from '@/components/AutoResizeTable';
import usePagination from '@/components/usePagination';
import { CompanySyncSelect } from '@/components/CompanySyncSelect';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { MemoAction } from '@/components/Memo/MemoAction';
import {
  TARGET_USER,
  MEMO_ATTRIBUTE_USER_ACCOUNTING,
} from '@/components/Memo/data';
import { TIER_MAP } from '@/pages/entity/intermodalRegions/components/data';
import { handleEnterKeyDown } from '@/utils/handleEnterKeyDown';

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [data, setData] = React.useState<TCollection<TUser>>();

  const [currentUser, setCurrentUser] = React.useState<TUser | undefined>();

  const [openNewToken, setOpenNewToken] = React.useState(false);

  const pagination = usePagination(data);

  const [filter] = useForm();

  const fetchData = React.useCallback(
    async (_pagination?: TablePaginationConfig) => {
      setLoading(true);
      try {
        const result = await app.service.get('users', {
          params: {
            ...filter.getFieldsValue(),
            page: _pagination?.current || pagination?.current || 1,
            per_page: _pagination?.pageSize || pagination?.pageSize || 20,
          },
        });

        setData(result);
      } catch (e: any) {
        message.error(e.error || 'System Error');
      }

      setLoading(false);
    },
    [pagination],
  );

  const addNew = () => {
    setVisible(true);
  };

  const handleEdit = async (user: TUser) => {
    setCurrentUser(user);
    setVisible(true);
  };

  const refreshUser = React.useCallback(
    async (id: number) => {
      if (!data) {
        return;
      }
      const _user = await app.service.get(`users/${id}`);
      const collection = data.data.map((item) => {
        if (item.id === _user.data.id) {
          return _user.data;
        }
        return item;
      });
      setCurrentUser(_user.data);
      setData({ ...data, data: collection });
    },
    [data, setData, setCurrentUser],
  );

  const handleNewApiToken = (user: TUser) => {
    setCurrentUser(user);
    setOpenNewToken(true);
  };

  const handleCloseNewApiToken = () => {
    setCurrentUser(undefined);
    setOpenNewToken(false);
  };

  const handleSavedUser = (user: any) => {
    fetchData();
    handleCloseUserDrawer();
  };

  const handleCloseUserDrawer = () => {
    setVisible(false);
    setCurrentUser(undefined);
  };

  const handleUpdateEnabledEstimateInvoice = async (
    id: number,
    values: any,
  ) => {
    setLoading(true);
    try {
      await app.service.patch(`users/${id}/updateEnabledEstimateInvoice`, {
        data: values,
      });
      fetchData();
    } catch (error: any) {
      message.error(error.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleVerify = async (user: TUser) => {
    setLoading(true);
    try {
      await app.service.patch(`users/${user.id}/verify`);
      fetchData();
    } catch (error: any) {
      message.error(error.data.message);
    } finally {
      setLoading(false);
    }
  };

  const columns: ColumnsType<any> = React.useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 80,
        fixed: 'left',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 100,
        fixed: 'left',
      },
      {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        width: 150,
        fixed: 'left',
        render: (text, record) => {
          return record.company ? record.company.name : '-';
        },
      },
      {
        title: 'Tier',
        dataIndex: ['company', 'tier_rate'],
        key: 'tier_rate',
        width: 100,
        render: (value, record) => {
          return <>{TIER_MAP[(value as unknown) as keyof typeof TIER_MAP]}</>;
        },
      },
      {
        title: 'Bill To',
        dataIndex: 'bill_to',
        key: 'bill_to',
        width: 150,
        render: (text, record) => {
          return record.bill_to ? record.bill_to.code : '-';
        },
      },
      {
        title: 'Agent',
        dataIndex: 'agent',
        key: 'agent',
        width: 100,
        render: (text, record) => {
          return record.agent ? record.agent.name : '-';
        },
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: 100,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 100,
        render: (text, record) => {
          const status = get(record, 'status', false);
          const status_name = get(record, 'status_name', false);
          if (status === false) {
            return <></>;
          }
          if (status === 0) {
            return <Tag color="default">{status_name}</Tag>;
          }
          if (status === 1) {
            return <Tag color={'processing'}>{status_name}</Tag>;
          }
          if (status === 2) {
            return <Tag color="success">{status_name}</Tag>;
          }
          if (status === -1) {
            return <Tag color="red">{status_name}</Tag>;
          }
          return <></>;
        },
      },
      {
        title: 'Types',
        dataIndex: 'types',
        key: 'types',
        width: 100,
        render: (text, record) => {
          const type = get(record, 'type', false);
          if (type === false) {
            return <></>;
          }
          if (type === 0) {
            return <Tag color="default">{'Normal'}</Tag>;
          }
          if (type === 1) {
            return <Tag color={'processing'}>{'Insider'}</Tag>;
          }
          if (type === 2) {
            return <Tag color="success">{'Dedicated'}</Tag>;
          }
          return <></>;
        },
      },
      {
        title: 'Trial Ends At',
        dataIndex: 'trial_ends_at',
        key: 'trial_ends_at',
        width: 150,
      },
      {
        title: 'Rate Limit',
        dataIndex: 'rate_search_limit',
        key: 'rate_search_limit',
        width: 100,
      },
      {
        title: 'BD',
        dataIndex: ['business_development', 'name'],
        key: 'business_development_id',
        width: 120,
      },
      {
        title: 'Drayage Sales',
        dataIndex: ['sales', 'name'],
        key: 'sales_id',
        width: 120,
      },
      {
        title: 'LtL Sales',
        dataIndex: ['ltl_sales', 'name'],
        key: 'ltl_sales_id',
        width: 120,
      },
      {
        title: 'FtL Sales',
        dataIndex: ['ftl_sales', 'name'],
        key: 'ftl_sales_id',
        width: 120,
      },

      {
        title: 'Last Login Time',
        dataIndex: 'last_login_log',
        key: 'last_login_log',
        width: 150,
        render: (text, record) => {
          const last_login_log = get(record, 'last_login_log', false);
          if (false === last_login_log) {
            return <></>;
          }
          const created_at = get(last_login_log, 'created_at', false);
          if (false === created_at) {
            return <></>;
          }
          return (
            <Link
              to={
                '/statistics/authlogs?operator_id=' +
                last_login_log.authlogable_id
              }
            >
              {created_at}
            </Link>
          );
        },
      },
      {
        title: 'Last Login IP',
        dataIndex: 'last_login_log',
        key: 'last_login_log',
        width: 100,
        render: (text, record) => {
          const last_login_log = get(record, 'last_login_log', false);
          if (false === last_login_log) {
            return <></>;
          }
          const ip = get(last_login_log, 'ip', false);
          if (false === ip) {
            return <></>;
          }
          return <IPLink ip={last_login_log.ip} />;
        },
      },
      {
        title: 'Enabled Estimate Invoice',
        dataIndex: 'enabled_estimate_invoice',
        key: 'enabled_estimate_invoice',
        width: 150,
        render: (_, record) => (
          <Switch
            checked={record.enabled_estimate_invoice}
            onChange={(checked: boolean) =>
              handleUpdateEnabledEstimateInvoice(record.id, {
                enabled_estimate_invoice: checked,
              })
            }
          />
        ),
      },
      {
        title: 'Multi Devices Login',
        dataIndex: 'is_multi_devices_login',
        key: 'is_multi_devices_login',
        width: 160,
        render: (text, record) => {
          return record.is_multi_devices_login ? (
            <Tag color="success">Yes</Tag>
          ) : (
            <Tag color="red">No</Tag>
          );
        },
      },
      {
        title: 'API Status',
        dataIndex: 'apiStatus',
        key: 'apiStatus',
        width: 100,
        render: (text, record) =>
          get(record, 'apiStatus', false) ? (
            <Tag color="success">{'Enabled'}</Tag>
          ) : (
            <Tag color="red">{'Disabled'}</Tag>
          ),
        onCell: (record, rowIndex) => {
          return {
            onClick: () => handleNewApiToken(record),
          };
        },
      },
      {
        title: 'Act Memo',
        dataIndex: 'accounting_memo',
        key: 'accounting_memo',
        width: 100,
        render: (text, record) => (
          <MemoAction
            initialValue={record.accounting_memo}
            targetId={record.id}
            target={TARGET_USER}
            memoAttribute={MEMO_ATTRIBUTE_USER_ACCOUNTING}
            onSaved={(id: number) => {
              refreshUser(id);
            }}
          />
        ),
      },
      {
        align: 'center',
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 150,
        render: (text, record) => (
          <Space>
            <a onClick={() => handleEdit(record)}>Edit</a>
            {record.status == 4 && (
              <Popconfirm
                title={'Are you sure?'}
                onConfirm={() => handleVerify(record)}
              >
                <a>Verify</a>
              </Popconfirm>
            )}
          </Space>
        ),
      },
    ],
    [refreshUser],
  );

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.main}>
      <Header
        title="Users"
        rightElement={
          <div>
            <Button type="primary" onClick={addNew}>
              New User
            </Button>
          </div>
        }
      ></Header>
      <div
        style={{
          width: '100%',
          padding: '10px',
        }}
      >
        <Form
          layout="vertical"
          form={filter}
          onFinish={fetchData}
          onKeyDown={handleEnterKeyDown(() => fetchData())}
        >
          <Row gutter={16}>
            <Col span={2}>
              <Form.Item name="name" label="Name">
                <Input />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue, setFieldsValue }) => (
                  <Form.Item label="Company" name="company_id">
                    <CompanySyncSelect
                      selected={getFieldValue('company_id')}
                      onSelect={(company_id: any) => {
                        setFieldsValue({
                          company_id,
                        });
                      }}
                      editable={false}
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue, setFieldsValue }) => (
                  <Form.Item label="Bill To" name="bill_to_id">
                    <CompanySyncSelect
                      selected={getFieldValue('bill_to_id')}
                      onSelect={(bill_to_id: any) => {
                        setFieldsValue({
                          bill_to_id,
                        });
                      }}
                      editable={false}
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name="email" label="Email">
                <Input />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name="status" label="Status">
                <Select>
                  <Select.Option value={''}>ALL</Select.Option>
                  <Select.Option value={0}>PENDING</Select.Option>
                  <Select.Option value={1}>ACTIVE</Select.Option>
                  <Select.Option value={2}>ENABLED</Select.Option>
                  <Select.Option value={-1}>DISABLED</Select.Option>
                  <Select.Option value={4}>VERIFYING</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item label="Type" name="type">
                <Select>
                  <Select.Option value={''}>ALL</Select.Option>
                  {TYPES.map((type) => (
                    <Select.Option key={type.value} value={type.value}>
                      {type.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item label="BD" name="business_development_id">
                <UserSyncSelect type="admin" />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item label="Drayage Sales" name="sales_id">
                <UserSyncSelect type="admin" />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item label="LTL Sales" name="ltl_sales_id">
                <UserSyncSelect type="admin" />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item label="FTL Sales" name="ftl_sales_id">
                <UserSyncSelect type="admin" />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item label="Agent" name="agent_id">
                <UserSyncSelect type="user" />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item label={' '}>
                <Space>
                  <Button htmlType="submit" size="small" type="primary">
                    Search
                  </Button>
                  <Button
                    size="small"
                    onClick={() => {
                      filter.resetFields();
                      fetchData();
                    }}
                  >
                    Clear All
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <AutoResizeTable
        loading={loading}
        pagination={pagination}
        size="small"
        rowKey="id"
        columns={columns}
        onChange={fetchData}
        dataSource={data?.data || []}
        sticky
        scroll={{
          x: 1500,
        }}
      />

      {visible && (
        <UserDrawer
          id={currentUser?.id}
          open={visible}
          onClose={handleCloseUserDrawer}
          onSaved={handleSavedUser}
        />
      )}

      <ModalNewApiToken
        open={openNewToken}
        user={currentUser}
        refreshUser={refreshUser}
        onClose={handleCloseNewApiToken}
      ></ModalNewApiToken>
    </div>
  );
};

export default Index;
