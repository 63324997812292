import crypto from 'crypto';

export const generateAuthData = (content: string) => {
  const timestamp = Math.floor(Date.now() / 1000).toString();
  const message = `${content}${timestamp}`;
  const signature = crypto
    .createHmac('sha256', DRAYAI_AUTH_SECRET_KEY)
    .update(message)
    .digest('hex');
  return { timestamp, signature };
};
