import { STATUS_IN_DISPUTE } from '@/pages/accounting/bills/components/data';
import { useApp } from '@/utils/useapp';
import { Table } from 'antd';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';

const redirectTobills = (
  billTo: string,
  type: string | null,
  state: number | null = null,
) => {
  let query = `?billTo=${billTo}`;
  if (type === 'due_31') {
    query +=
      '&dueStartDate=' +
      moment().subtract(365, 'days').format('YYYY-MM-DD') +
      '&dueEndDate=' +
      moment().subtract(31, 'days').format('YYYY-MM-DD');
  } else if (type === 'due_1_30') {
    query +=
      '&dueStartDate=' +
      moment().subtract(30, 'days').format('YYYY-MM-DD') +
      '&dueEndDate=' +
      moment().format('YYYY-MM-DD');
  } else if (type === 'due_31_60') {
    query +=
      '&dueStartDate=' +
      moment().subtract(60, 'days').format('YYYY-MM-DD') +
      '&dueEndDate=' +
      moment().subtract(31, 'days').format('YYYY-MM-DD');
  } else if (type === 'current') {
    query +=
      '&dueStartDate=' +
      moment().add(1, 'days').format('YYYY-MM-DD') +
      '&dueEndDate=' +
      moment().add(365, 'days').format('YYYY-MM-DD');
  }

  if (state) {
    query += `&state=${state}`;
  }

  window.open('/accounting/bills' + query, '_blank');
};

const APSummary = () => {
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState<any[]>([]);
  const app = useApp();

  const fetchAPSummary = async () => {
    try {
      setLoading(true);
      const res = await app.service.get('risks/apsummary');
      setSummary(res.data);
    } catch (e: any) {
      console.log(e.error || 'network error');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAPSummary();
  }, []);

  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const columns = [
    {
      title: 'Vendor',
      dataIndex: 'vendor_name',
      key: 'vendor_name',
    },
    {
      title: 'Disput Count',
      dataIndex: 'dispute_count',
      key: 'dispute_count',
      render: (text: any, record: any) => {
        return (
          <a
            onClick={() =>
              redirectTobills(record.vendor_name, null, STATUS_IN_DISPUTE)
            }>
            {text}
          </a>
        );
      },
    },
    {
      title: 'Current',
      dataIndex: 'current',
      key: 'current',
      sorter: (a: any, b: any) => a.current - b.current,
      render: (text: any, record: any) => {
        return (
          <a onClick={() => redirectTobills(record.vendor_name, 'current')}>
            {USDollar.format(+text || 0)}
          </a>
        );
      },
    },
    {
      title: 'Over 1-30',
      dataIndex: 'due_1_30',
      key: 'due_1_30',
      sorter: (a: any, b: any) => a.due_1_30 - b.due_1_30,
      render: (text: any, record: any) => {
        return (
          <a onClick={() => redirectTobills(record.vendor_name, 'due_1_30')}>
            {USDollar.format(+text || 0)}
          </a>
        );
      },
    },
    {
      title: 'Over 31-60',
      dataIndex: 'due_31_60',
      key: 'due_31_60',
      sorter: (a: any, b: any) => a.due_31_60 - b.due_31_60,
      render: (text: any, record: any) => {
        return (
          <a onClick={() => redirectTobills(record.vendor_name, 'due_31_60')}>
            {USDollar.format(+text || 0)}
          </a>
        );
      },
    },
    {
      title: 'Over 61',
      dataIndex: 'due_61',
      key: 'due_61',
      sorter: (a: any, b: any) => a.due_61 - b.due_61,
      render: (text: any, record: any) => {
        return (
          <a onClick={() => redirectTobills(record.vendor_name, 'due_61')}>
            {USDollar.format(+text || 0)}
          </a>
        );
      },
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      sorter: (a: any, b: any) => a.total - b.total,
      render: (text: any, record: any) => {
        return (
          <a onClick={() => redirectTobills(record.vendor_name, 'total')}>
            {USDollar.format(+text || 0)}
          </a>
        );
      },
    },
  ];

  return (
    <>
      <h2>AP Summary</h2>
      <Table
        size="small"
        pagination={false}
        loading={loading}
        columns={columns}
        dataSource={summary}
      />
    </>
  );
};

export default APSummary;
