import { Space, Tag, Tooltip } from 'antd';
import React from 'react';
import { TItemProps } from '..';
import { TEXT_COLOR_MAP } from '@/pages/setting/containerTasks/components/data';
import { ACTIONS_TO_SHOW } from '../../data';
import { RenderIcon } from './RenderIcon';
import { TaskLogs } from '../TaskLogs';
import { RatingTag } from '@/components/RatingTag';
import { ACTION_VENDOR_RATING } from '../../FileActionTips/RenderActions/data';
import { RenderWhoseTaskTag } from './RenderWhoseTaskTag';
import { SOPLink } from '@/components/SOPLink';
import {
  WHOSE_TASK_OP_ASSIST,
  WHOSE_TASK_CPS,
  WHOSE_TASK_OP,
  WHOSE_TASK_MAP,
} from '@/pages/dispatchs/components/data';

const ACTIONS_TO_SHOW_COLOR_MAP = {
  YES: 'green',
  NO: 'red',
};

const WHOSE_TASK_COLOR_MAP = {
  [WHOSE_TASK_OP_ASSIST]: 'volcano',
  [WHOSE_TASK_CPS]: 'cyan',
  [WHOSE_TASK_OP]: 'blue',
};

export const RenderTaskColumn: React.FC<TItemProps & { showLog: boolean }> = ({
  item,
  container,
  showLog = true,
}) => {
  return (
    <Space size={'small'}>
      <RenderIcon item={item} container={container} />
      <div>
        <RenderWhoseTaskTag whoseTask={item.whose_task} />
        {/* <Tooltip title={item.sop}>
          <span className='mr-xs'>
            <SOPLink title={item.sop} settingContainerTaskId={item.setting_container_task_id} />
          </span>
        </Tooltip> */}
        <span
          className={[
            item.text_color
              ? `text-${
                TEXT_COLOR_MAP[
                  item.text_color as unknown as keyof typeof TEXT_COLOR_MAP
                ]
              }`
              : '',
            item.is_filter_status ? 'text-line-through' : '',
          ].join(' ')}>
          {item.sop ? (
            <SOPLink
              title={item.task}
              settingContainerTaskId={item.setting_container_task_id}
              color={
                item.text_color
                  ? `text-${
                    TEXT_COLOR_MAP[
                      item.text_color as unknown as keyof typeof TEXT_COLOR_MAP
                    ]
                  }`
                  : ''
              }
            />
          ) : (
            item.task
          )}

          {/* {item.task} */}
        </span>
      </div>

      {ACTIONS_TO_SHOW.includes(item.action) && (
        <Tag
          color={
            ACTIONS_TO_SHOW_COLOR_MAP[
              item.action as unknown as keyof typeof ACTIONS_TO_SHOW_COLOR_MAP
            ]
          }>
          {item.action}
        </Tag>
      )}
      {container && container.vendor && item.task == ACTION_VENDOR_RATING && (
        <RatingTag rating={container.vendor?.score || null} />
      )}
      {showLog && (
        <>
          {!!item.action_by && (
            <small className="text-gray">{item.action_by}</small>
          )}
          {!!item.action_by && (
            <small className="text-gray">{item.action_at}</small>
          )}

          {item.id && <TaskLogs item={item} container={container} />}
        </>
      )}
    </Space>
  );
};
