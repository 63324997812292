import React from 'react';
import { ModelSelect } from './ModelSelect';
import { WarehouseDrawer } from '@/pages/entity/warehouses/components/WarehouseDrawer';
import {
  EditOutlined,
  HomeOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { Button, Tooltip } from 'antd';

export const WarehouseSelect: React.FC<{
  size?: string;
  selected: any;
  forbidToModfiySpecifyField?: boolean;
  onSelect: (id: string) => void;
  renderOption?: (warehouse: any) => React.ReactNode;
  className?: string;
  editable?: boolean;
  multiple?: boolean;
  mode?: string;
  style?: any;
  maxTagCount?: string;
  placeholder?: string;
}> = ({
  selected,
  forbidToModfiySpecifyField = false,
  onSelect,
  renderOption,
  editable,
  ...props
}) => {
  const [id, setId] = React.useState(null);
  const [visible, setVisible] = React.useState(false);
  const handleSaved = (data: any) => {
    onSelect(data);
    setVisible(false);
    setId(null);
  };

  const handleClosed = () => {
    setId(null);
    setVisible(false);
  };

  return (
    <>
      <div className="flex">
        {editable && (
          <Button
            onClick={() => {
              setId(selected?.id);
              setVisible(true);
            }}
            className="ant-btn-icon-only select-modal-icon">
            {selected ? (
              <EditOutlined
                style={{
                  color: '#00000073',
                }}
              />
            ) : (
              <PlusCircleOutlined
                style={{
                  color: '#00000073',
                }}
              />
            )}
          </Button>
        )}
        <ModelSelect
          {...props}
          className={
            editable
              ? 'select-modal-with-icon-width'
              : 'select-modal-without-icon-width'
          }
          selected={selected}
          onSelect={onSelect}
          renderOption={(warehouse: any) => {
            if (renderOption) {
              return renderOption(warehouse);
            }

            return (
              <>
                <strong>{warehouse.code}</strong>
                <br />
                <span>{warehouse.name}</span>
                <br />
                <small>{warehouse.address}</small>
              </>
            );
          }}
          model="warehouses"
        />
        {selected && selected.is_residential && (
          <Tooltip title={'Residential'}>
            <Button className="ant-btn-icon-only select-modal-icon">
              <HomeOutlined
                style={{
                  color: '#00000073',
                }}
              />
            </Button>
          </Tooltip>
        )}
      </div>
      <WarehouseDrawer
        id={id}
        forbidToModfiySpecifyField={id ? forbidToModfiySpecifyField : false}
        onSaved={handleSaved}
        onClosed={handleClosed}
        visible={visible}
      />
    </>
  );
};
