import React, { useEffect, useMemo } from 'react';
import { useApp } from '@/utils/useapp';
import { Popover, Spin, Button, PopoverProps, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { AmountChangeForm } from './AmountChangeForm';
import { TAttributeChangeTarget } from '@/Interfaces/AttributeChangeLog/AttributeChangeTarget';
import AttributeChangelog from './AttributeChangelog';
import { InfoCircleOutlined } from '@ant-design/icons';

type TAttributeChangelogInfoIcon = {
  modified?: boolean;
} & TAttributeChangeTarget &
  PopoverProps;

const AttributeChangelogInfoIcon: React.FC<TAttributeChangelogInfoIcon> = ({
  target,
  targetId,
  attribute,
  modified,
  ...props
}) => {
  if (!modified) {
    return null;
  }

  const [open, setOpen] = React.useState(false);

  return (
    <Popover
      title={'Change Log'}
      trigger={props.trigger ? props.trigger : 'hover'}
      placement={props.placement ? props.placement : 'right'}
      onOpenChange={(open: boolean) => {
        setOpen(open);
      }}
      open={open}
      content={
        <div style={{ width: '20vw', maxHeight: '55vh', overflowY: 'auto' }}>
          <AttributeChangelog
            target={target}
            targetId={targetId}
            attribute={attribute}
            visible={open}
          />
        </div>
      }
    >
      <InfoCircleOutlined />
    </Popover>
  );
};

export default AttributeChangelogInfoIcon;
