import React from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Col,
  Form,
  message,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tabs,
} from 'antd';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment';
import AutoResizeTable from '@/components/AutoResizeTable';
import { CSVLink } from 'react-csv';
import { AntDRangePicker } from '@/components/AntDRangePicker';
import { RANGE_TYPES_PAST_1_2_3_4_6_12_MONTHS } from '@/components/AntDRangePicker/data';
import numeral from 'numeral';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { useHistory } from 'umi';

const Index = () => {
  const app = useApp();

  const history = useHistory();

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<any[]>([]);
  const [selected, setSelected] = React.useState<any[]>([]);
  const [filter] = useForm();
  const [exportingData, setExportingData] = React.useState<any[]>([]);

  const handleSave = async () => {
    const values = await filter.validateFields();
    setLoading(true);

    try {
      const result = await app.service.post('accountings/irProfitReport', {
        data: values,
      });
      const rows = result;
      setData(rows);
      setSelected([]);
      setExportingData(toExportData(rows));
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  const footer = React.useCallback(() => {
    let totalInvoiceAmount = 0,
      totalPaidAmount = 0,
      totalDueAmount = 0,
      totalProfit = 0,
      containerCount = 0;

    selected.forEach((row) => {
      totalInvoiceAmount += numeral(row.total_invoice_amount).value();
      totalPaidAmount += numeral(row.paid_amount).value();
      totalDueAmount += numeral(row.due_amount).value();
      totalProfit += numeral(row.profit).value();
      containerCount += row.container_count;
    });
    return (
      selected &&
      selected.length > 0 && (
        <Table.Summary fixed>
          <Table.Summary.Row>
            <Table.Summary.Cell index={0} colSpan={2}>
              Total Selected {selected.length}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2}>{containerCount}</Table.Summary.Cell>
            <Table.Summary.Cell index={3}>
              {numeral(totalInvoiceAmount).format('0,0.00')}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={4}>
              {numeral(totalPaidAmount).format('0,0.00')}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={5}>
              {numeral(totalDueAmount).format('0,0.00')}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={6}>
              {numeral(totalProfit).format('0,0.00')}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={7} colSpan={10}></Table.Summary.Cell>
          </Table.Summary.Row>
        </Table.Summary>
      )
    );
  }, [selected]);

  const toExportData = (data) => {
    // to csv data
    const rows = data.map((item) => {
      return [
        item.ir,
        item.container_count,
        item.total_invoice_amount,
        item.paid_amount,
        item.due_amount,
        item.profit,
      ];
    });

    // add header
    rows.unshift([
      'IR',
      'CNTR Count',
      'Total Invoice Amount',
      'Paid Amount',
      'Due Amount',
      'Profit',
    ]);

    return rows;
  };

  const columns: ColumnsType<any> = React.useMemo(
    () => [
      {
        title: 'IR',
        dataIndex: 'ir_name',
        key: 'ir_id',
        width: 200,
        sorter: (a, b) => a.ir_id - b.ir_id,
      },

      {
        title: 'CNTR Count',
        dataIndex: 'container_count',
        key: 'container_count',
        width: 100,
        sorter: (a, b) => a.container_count - b.container_count,
      },
      {
        title: 'Total Invoice Amount',
        dataIndex: 'total_invoice_amount',
        key: 'total_invoice_amount',
        width: 200,
        sorter: (a, b) =>
          parseFloat(a.total_invoice_amount.replace(/,/g, '')) -
          parseFloat(b.total_invoice_amount.replace(/,/g, '')),
      },
      {
        title: 'Paid Amount',
        dataIndex: 'paid_amount',
        key: 'paid_amount',
        width: 200,
        sorter: (a, b) =>
          parseFloat(a.paid_amount.replace(/,/g, '')) -
          parseFloat(b.paid_amount.replace(/,/g, '')),
      },
      {
        title: 'Due Amount',
        dataIndex: 'due_amount',
        key: 'due_amount',
        width: 200,
        sorter: (a, b) =>
          parseFloat(a.due_amount.replace(/,/g, '')) -
          parseFloat(b.due_amount.replace(/,/g, '')),
      },
      {
        title: 'Profit',
        dataIndex: 'profit',
        key: 'profit',
        width: 200,
        sorter: (a, b) =>
          parseFloat(a.profit.replace(/,/g, '')) -
          parseFloat(b.profit.replace(/,/g, '')),
      },
      {
        title: 'Magin',
        dataIndex: 'margin',
        key: 'margin',
        width: 200,
        render: (text, record) => {
          return `${record.margin}%`;
        },
        sorter: (a, b) =>
          parseFloat(a.margin.replace(/,/g, '')) -
          parseFloat(b.margin.replace(/,/g, '')),
      },
    ],
    [],
  );

  return (
    <div className={styles.main}>
      <div className={styles.filter}>
        <Radio.Group value="ir">
          <Radio.Button
            value="customer"
            onClick={() =>
              history.push('/accounting/profit-report/customer-profit-report')
            }>
            Customer Profit Report
          </Radio.Button>
          <Radio.Button value="ir">IR Profit Report</Radio.Button>
        </Radio.Group>
      </div>
      <div className={styles.filter}>
        <Form
          className="w100"
          layout="vertical"
          form={filter}
          onFinish={handleSave}>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item hidden name="start_date"></Form.Item>
              <Form.Item hidden name="end_date"></Form.Item>
              <Form.Item label="Order Created Date" shouldUpdate required>
                {({ getFieldValue, setFieldsValue }) => {
                  const startDate = getFieldValue('created_at_start_date');
                  const endDate = getFieldValue('created_at_end_date');
                  return (
                    <AntDRangePicker
                      style={{ width: '100%' }}
                      rangeType={RANGE_TYPES_PAST_1_2_3_4_6_12_MONTHS}
                      value={
                        startDate && endDate
                          ? [moment(startDate), moment(endDate)]
                          : undefined
                      }
                      onChange={(value: any) => {
                        setFieldsValue({
                          start_date: value[0].format('YYYY-MM-DD'),
                          end_date: value[1].format('YYYY-MM-DD'),
                        });
                      }}
                    />
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="Sales" name="sales_id">
                <UserSyncSelect type="admin" allowClear />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="Customer" name="user_id">
                <UserSyncSelect type="user" allowClear />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="Only Closed Order" name="only_closed_order">
                <Select allowClear>
                  <Select.Option value="1">Yes</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={3}>
              <Form.Item label={' '}>
                <Space>
                  <Button htmlType="submit" type="primary">
                    Submit
                  </Button>
                  <CSVLink data={exportingData} filename="agingreport.csv">
                    <Button>Export</Button>
                  </CSVLink>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <AutoResizeTable
        loading={loading}
        pagination={false}
        size="small"
        columns={columns}
        dataSource={data ? data : []}
        rowKey="ir_id"
        rowSelection={{
          selectedRowKeys: selected.map((row) => row.ir_id),
          onChange: (_: any, selectedRows: any[]) => setSelected(selectedRows),
        }}
        summary={footer}
        sticky
        scroll={{
          x: 'auto',
        }}
      />
    </div>
  );
};

export default Index;
