import { useApp } from '@/utils/useapp';
import { Drawer, Space, Button, message, Tag, Popover, Divider } from 'antd';
import { FormInstance } from 'antd/es/form';
import { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { BuyRateForm } from './BuyRateForm';
import { TModifyProps } from '@/components/Rate/data';
import { InAccurateReportModal } from './InAccurateReportModal';
import { TAdmin } from '@/types';
import { serialize } from 'object-to-formdata';

export const BUYRATE_UID_PREFIX = 'B';

interface IBuyRateDrawer {
  id: number;
  open: boolean;
  isNew: boolean;
  copyRecord?: any;
  onClose: () => void;
  onSaved?: () => void;
}

interface IInaccuratReported {
  inaccurate_reported_at: string;
  inaccurate_reason: string;
  inaccurate_reported_by: TAdmin;
  inaccurate_solved_at: string;
  inaccurate_solved_by: TAdmin;
}

export const BuyRateDrawer: React.FC<IBuyRateDrawer & TModifyProps> = ({
  // form,
  id,
  open,
  isNew,
  disabled = false,
  banModifyFields = [],
  copyRecord,
  onClose,
  onSaved,
}) => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [form] = useForm();
  const [
    inaccuratReported,
    setInAccurateReported,
  ] = React.useState<IInaccuratReported | null>(null);
  const buyRateId = useMemo(() => {
    if (!copyRecord) {
      return id;
    } else {
      return 0;
    }
  }, [id, copyRecord]);

  const fetchData = async () => {
    if (!buyRateId) {
      return;
    }

    try {
      const response = await app.service.get(`rates/${buyRateId}`);
      form.setFieldsValue(response.data);
      const rate = response.data;
      setInAccurateReported({
        inaccurate_reported_at: rate.inaccurate_reported_at,
        inaccurate_reason: rate.inaccurate_reason,
        inaccurate_reported_by: rate.inaccurate_reported_by,
        inaccurate_solved_at: rate.inaccurate_solved_at,
        inaccurate_solved_by: rate.inaccurate_solved_by,
      });
    } catch (e: any) {
      message.error(e?.data?.message || e?.data?.error);
    }
  };

  const handleSave = async () => {
    const data = await form.validateFields();

    setLoading(true);
    const formValue = Object.assign({}, data);

    formValue.to_city_id = get(formValue, 'to_city.id', 0);
    formValue.vendor_id = get(formValue, 'vendor.id', 0);

    formValue.vendor_possible_charges = get(
      formValue,
      'vendor.possible_charges',
    );

    formValue.vendor_special_terms = get(formValue, 'vendor.special_terms');

    delete formValue.vendor;
    delete formValue.to_city;

    // toformdata
    const config = {
      data: serialize(
        {
          ...formValue,
        },
        { indices: true, nullsAsUndefineds: true },
      ),
      requestType: 'form',
    };

    const request = isNew
      ? app.service.post('rates', config)
      : app.service.put(`rates/${id}`, { data: formValue });

    request
      .then(() => {
        form.resetFields();
        onSaved && onSaved();
      })
      .catch((err) => {
        message.error(err.data?.message || err.data?.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOnClose = () => {
    onClose();
    form.resetFields();
  };

  const handleInaccurate = async (values: { reason: string }) => {
    try {
      const response = await app.service.put(`rates/${id}/inaccurate`, {
        data: values,
      });
      const rate = response.data;
      setInAccurateReported({
        inaccurate_reported_at: rate.inaccurate_reported_at,
        inaccurate_reason: rate.inaccurate_reason,
        inaccurate_reported_by: rate.inaccurate_reported_by,
        inaccurate_solved_at: rate.inaccurate_solved_at,
        inaccurate_solved_by: rate.inaccurate_solved_by,
      });
    } catch (e: any) {
      message.error(e?.data?.message || e?.data?.error);
    }
  };

  const handleSolveInaccurate = async () => {
    try {
      const response = await app.service.put(`rates/${id}/solveInaccurate`);
      const rate = response.data;
      setInAccurateReported({
        inaccurate_reported_at: rate.inaccurate_reported_at,
        inaccurate_reason: rate.inaccurate_reason,
        inaccurate_reported_by: rate.inaccurate_reported_by,
        inaccurate_solved_at: rate.inaccurate_solved_at,
        inaccurate_solved_by: rate.inaccurate_solved_by,
      });
    } catch (e: any) {
      message.error(e?.data?.message || e?.data?.error);
    }
  };

  useEffect(() => {
    if (buyRateId) {
      fetchData();
    } else {
      form.setFieldsValue({
        cargo_type_id: 1,
        visible: true,
      });
    }
  }, [buyRateId]);

  useEffect(() => {
    copyRecord && form.setFieldsValue({ ...copyRecord });
  }, [copyRecord]);

  return (
    <>
      <Drawer
        title=""
        placement={'right'}
        width={'90%'}
        onClose={handleOnClose}
        destroyOnClose={true}
        open={open}
        extra={
          <Space>
            {!isNew && inaccuratReported?.inaccurate_solved_at && (
              <Tag color="green">
                Inaccurat Solved At {inaccuratReported?.inaccurate_solved_at} By{' '}
                {inaccuratReported?.inaccurate_solved_by?.name}
              </Tag>
            )}
            {!isNew && !inaccuratReported?.inaccurate_reported_at && (
              <InAccurateReportModal onSave={handleInaccurate}>
                Inaccurate
              </InAccurateReportModal>
            )}
            {!isNew && inaccuratReported?.inaccurate_reported_at && (
              <Popover
                content={
                  <>
                    <>{inaccuratReported?.inaccurate_reason || ''}</>
                    <Divider />
                    <Button
                      size="small"
                      type="primary"
                      onClick={handleSolveInaccurate}
                    >
                      Solve
                    </Button>
                  </>
                }
              >
                <Tag color="red">
                  Inaccurat Reported At{' '}
                  {inaccuratReported?.inaccurate_reported_at} By{' '}
                  {inaccuratReported?.inaccurate_reported_by?.name}
                </Tag>
              </Popover>
            )}
            {/* <Button onClick={handleOnClose}>Close</Button> */}
            <Button type="primary" loading={loading} onClick={handleSave}>
              Save
            </Button>
          </Space>
        }
      >
        <BuyRateForm
          form={form}
          isNew={isNew}
          id={id}
          disabled={disabled}
          banModifyFields={banModifyFields}
        />
      </Drawer>
    </>
  );
};
