import { Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import formatNumber from '@/utils/formatNumber';
import {
  InsuranceInterface,
  INSURANCE_STATUS_INSURED,
  INSURANCE_STATUS_PENDING,
} from './Interfaces/InsuranceInterface';

const InsuranceDetail: React.FC<{ insurance: InsuranceInterface }> = ({
  insurance,
}) => (
  <>
    <p>Policy Number: {insurance.policy_number}</p>
    <p>Status: {insurance.status}</p>
    <p>Premium: ${formatNumber(insurance.premium_in_usd)}</p>
  </>
);
const InsuranceIcon: React.FC<{
  insurance: InsuranceInterface;
}> = ({ insurance }) => {
  if (insurance.status == INSURANCE_STATUS_PENDING) {
    return (
      <Popover
        placement="right"
        content={<InsuranceDetail insurance={insurance} />}
        title="Insurance"
      >
        <span className="text-warning">
          <InfoCircleOutlined />
        </span>
      </Popover>
    );
  }
  if (insurance.status == INSURANCE_STATUS_INSURED) {
    return (
      <Popover
        placement="right"
        content={<InsuranceDetail insurance={insurance} />}
        title="Insurance"
      >
        <span className="text-success">
          <InfoCircleOutlined />
        </span>
      </Popover>
    );
  }

  return <></>;
};

export default InsuranceIcon;
