import React from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { TaskOperationInterface } from '../../Interfaces/TaskOperationInterface';

export const ActionIcon: React.FC<{
  operation: TaskOperationInterface | null;
}> = ({ operation }) => {
  if (operation?.is_done) {
    if (operation?.action === 'NO') {
      return (
        <span className="text-gray">
          <CloseCircleOutlined />
        </span>
      );
    }

    if (operation?.action == 'NA') {
      return (
        <span className="text-gray">
          <CheckCircleOutlined />
        </span>
      );
    }

    return (
      <span className="text-success">
        <CheckCircleOutlined />
      </span>
    );
  }

  return (
    <span className="text-danger">
      <CloseCircleOutlined />
    </span>
  );
};
