import {
  Avatar,
  Button,
  Divider,
  Form,
  List,
  message,
  Modal,
  Popconfirm,
  Space,
} from 'antd';

import { useEffect, useState } from 'react';
import React from 'react';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import {
  MessageOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { useApp } from '@/utils/useapp';
import style from 'res/css/ui.scss';

export const Message: React.FC<{
  open?: boolean;
  order: any;
  onSave?: (a: any) => void;
}> = ({ open = false, order, onSave }) => {
  const app = useApp();
  const [isModalVisible, setIsModalVisible] = useState(open);
  const [messages, setMessages] = useState<any[]>([]);
  const [form] = useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (
      app.store.notification.view &&
      order.uid == app.store.notification.view
    ) {
      setIsModalVisible(true);
    }
  }, [app.store.notification.view]);

  const loadMessages = async () => {
    setLoading(true);

    try {
      const resp = await app.service.get(`orders/${order.id}/messages`);
      setMessages(resp.data);
    } catch (e: any) {
      message.error(e.data.message);
    } finally {
      setLoading(false);
    }
  };

  const sendMessage = async (values: any) => {
    setLoading(true);
    try {
      const resp = await app.service.post(`orders/${order.id}/messages`, {
        data: { body: values.body },
      });
      form.resetFields();
      const ms = [...messages];
      ms.push(resp.data);
      setMessages(ms);
    } catch (e: any) {
      message.error(e.data.message);
    } finally {
      setLoading(false);
    }
  };

  const deleteMessage = async (id: any) => {
    setLoading(true);
    try {
      await app.service.delete(`orders/${order.id}/messages/${id}`);
      loadMessages();
    } catch (e: any) {
      message.error(e.data.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isModalVisible) {
      loadMessages();
      app.store.notification.setRead(null);
    }
  }, [isModalVisible]);

  const SenderMessage = ({ message }) => {
    return (
      <div className="flex justify-end w100">
        <Space align="start">
          <Space align="center">
            <div>
              <div>
                {' '}
                {message.messager.name}{' '}
                <span className={style.message_timestamp}>
                  {message.created_at}
                </span>
              </div>
              <div className="flex justify-end"> {message.body}</div>
            </div>
            <div>
              <Avatar style={{ backgroundColor: '#87d068', color: '#ffffff' }}>
                {message.messager.name}
              </Avatar>
              <Popconfirm
                title="Are you sure？"
                onConfirm={() => deleteMessage(message.id)}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}>
                <DeleteOutlined className="ml-sm" />
              </Popconfirm>
            </div>
          </Space>
        </Space>
      </div>
    );
  };

  const ReciverMessage = ({ message }) => {
    return (
      <Space>
        <Space align="center">
          <div>
            <Avatar style={{ backgroundColor: '#4285f4', color: '#ffffff' }}>
              {message.messager.name}
            </Avatar>
          </div>
          <div>
            <div>
              {' '}
              {message.messager.name}{' '}
              <span className={style.message_timestamp}>
                {message.created_at}
              </span>
            </div>
            <div> {message.body}</div>
          </div>
        </Space>
      </Space>
    );
  };
  return (
    <>
      <div className="text-gray" onClick={showModal}>
        <MessageOutlined
          className={order.messages.length > 0 ? 'text-primary' : ''}
        />
      </div>
      <Modal
        cancelText="Close"
        title="Message"
        footer={false}
        open={isModalVisible}
        onCancel={handleCancel}>
        {isModalVisible && (
          <>
            <Form onFinish={sendMessage} form={form} layout="vertical">
              <Form.Item
                label=""
                name="body"
                rules={[{ required: true, message: 'Message is required' }]}>
                <TextArea disabled={loading} rows={4}></TextArea>
              </Form.Item>
              <Button disabled={loading} htmlType="submit" type="primary">
                Send
              </Button>
            </Form>
            <Divider />
            {messages.map((m: any) => (
              <List.Item key={m.id}>
                {m.messager_type == 'App\\Models\\Admin' &&
                m.messager_id == app.store.auth.user?.id ? (
                    <SenderMessage message={m} />
                  ) : (
                    <ReciverMessage message={m} />
                  )}
              </List.Item>
            ))}
          </>
        )}
      </Modal>
    </>
  );
};
