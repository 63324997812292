import { action, computed, observable } from 'mobx';

export class NotificationStore {
  @observable _unreadCount = 0;

  @observable _unread: any[] = [];

  @observable _read: string | null = null;

  @action setUnreadCount(unreadCount: number) {
    this._unreadCount = unreadCount;
  }

  @action setUnread(unread: any[]) {
    this._unread = unread;
  }

  @action setRead(uid: string | null) {
    this._read = uid;
  }

  @computed get unreadCount() {
    return this._unreadCount;
  }

  @computed get unread() {
    return this._unread;
  }

  @computed get view() {
    return this._read;
  }
}

const search = new NotificationStore();

export default search;
