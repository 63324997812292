import { useApp } from '@/utils/useapp';
import { Form, Input, Button, Select, Tabs, Spin } from 'antd';
import { FormInstance } from 'antd/es/form';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'antd/es/form/Form';
import moment from 'moment';
const STATUSES = {
  '-1': 'DISABLED',
  '0': 'PENDING',
  '2': 'ENABLED',
};

interface IPortalSetupTab {
  form: FormInstance;
  handleChange: (event: any) => void;
}

export const PortalSetupTab: React.FC<IPortalSetupTab> = ({
  form,
  handleChange,
}) => {
  const [show, setShow] = React.useState(false);

  return (
    <>
      <h3>Portal Setup:</h3>
      <h3>
        Vendor Account{' '}
        <Button onClick={() => setShow(!show)}>
          {' '}
          {show ? 'Hide' : 'Show'}
        </Button>
      </h3>

      {show && (
        <>
          {' '}
          <hr />
          <div style={{ padding: '10px' }}>
            <Form.Item
              label="Account Email"
              name="account_email"
              rules={[{ type: 'email' }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input placeholder="Enter Account Email" />
            </Form.Item>
            <Form.Item
              label="Account Status"
              name="account_status"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Select
                onChange={(v) =>
                  handleChange({
                    target: { name: 'account_status', value: v },
                  })
                }>
                {Object.keys(STATUSES).map((key) => (
                  <Select.Option key={key} value={+key}>
                    {STATUSES[key as keyof typeof STATUSES]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Account Password"
              name="account_password"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input type="password" placeholder="Enter Account Password" />
            </Form.Item>
            <Form.Item
              label="Account Password Confirmation"
              name="account_password_confirmation"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input
                type="password"
                placeholder="Enter Account Password Confirmation"
              />
            </Form.Item>
          </div>
        </>
      )}
    </>
  );
};
