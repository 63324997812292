import styles from 'res/css/ui.scss';
import {
  Button,
  Col,
  Form,
  Row,
  Select,
  FormInstance,
  Space,
  Input,
} from 'antd';
import React from 'react';

export type TFilter = {
  loading: boolean;
  filter: FormInstance;
  onSearch: () => void;
};

export const Filter: React.FC<TFilter> = ({ loading, filter, onSearch }) => {
  const handleClearAll = () => {
    filter.resetFields();
    onSearch();
  };
  return (
    <div className={styles.filter}>
      <Form
        layout="vertical"
        form={filter}
        onFinish={onSearch}
        initialValues={{
          recent_month: 3,
          no_drayeasy_vendor: true,
          force_scale: false,
        }}
        style={{ width: '100%' }}>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              name="subject"
              label="Subject"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label={' '}>
              <Space>
                <Button htmlType="submit" type="primary" loading={loading}>
                  Search
                </Button>
                <Button onClick={handleClearAll} disabled={loading}>
                  Clear All
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
