import React from 'react';
import { Tabs, Tooltip } from 'antd';
import { capitalize } from 'lodash';
import { InfoCircleOutlined } from '@ant-design/icons';

export const NavTabs: React.FC<{
  items: any;
  activeKey: string;
  onChange: (key: string) => void;
  showIcon?: boolean;
  descriptionMap?: { [key: string]: string };
}> = ({
  items,
  activeKey,
  onChange,
  showIcon = false,
  descriptionMap = {},
}) => {
  return (
    <Tabs
      className="pl-sm pr-sm"
      activeKey={activeKey}
      onChange={onChange}
      items={Object.keys(items).map((key) => {
        return {
          label: (
            <div className="pl-sm">
              {showIcon && (
                <Tooltip title={descriptionMap[key]}>
                  <InfoCircleOutlined className="mr-sm" />
                </Tooltip>
              )}
              {`${capitalize(key == '' ? 'All' : key.replaceAll('_', ' '))}`}{' '}
              <span
                style={{
                  background: activeKey == key ? '#F0F9FF' : '#F0F0F0',
                  borderRadius: '100px',
                  color: activeKey == key ? '#2F6EC0' : '#8C8C8C',
                  padding: '0px 8px',
                  fontWeight: '400',
                }}>
                {items[key]}
              </span>
            </div>
          ),
          key: key,
        };
      })}></Tabs>
  );
};
