import { Button, DatePicker, List, Popover, Space, Table } from 'antd';
import React from 'react';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { get } from 'lodash';
import moment from 'moment';

export type CheckActionsProps = {
  children?: React.ReactElement;
  color: string;
  list: any[];
  container?: any;
  onCheck: (_: {
    task: string;
    action: 'DONE' | 'NA' | 'UNDO' | 'INPUT';
    value?: any;
  }) => void;
};

const CheckActions: React.FC<CheckActionsProps> = ({
  children,
  color,
  list,
  onCheck,
  container,
}) => {
  const columns: ColumnsType<any> = [
    {
      title: 'Task',
      dataIndex: 'task',
      render: (_, record: any) => {
        let icon = null;

        if (record.action_at) {
          if (record.action === 'DONE') {
            icon = (
              <span className="text-success">
                <CheckCircleOutlined />
              </span>
            );
          } else if (record.action === 'NA') {
            icon = (
              <span className="text-gray">
                <MinusCircleOutlined />
              </span>
            );
          }
        } else {
          icon = (
            <span className="text-danger">
              <CloseCircleOutlined />
            </span>
          );
        }

        return (
          <Space>
            {icon}
            <div>
              <Space>
                <span>{record.task}</span>
                {!!record.action_by && (
                  <small className="text-gray">{record.action_by}</small>
                )}
                {!!record.action_by && (
                  <small className="text-gray">{record.action_at}</small>
                )}
              </Space>
            </div>
          </Space>
        );
      },
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render: (_, record: any, index: number) => {
        if (index >= 1 && !list[index - 1]['action_at']) {
          return null;
        }

        if (record.date) {
          return record.date;
        }

        if (record.editable) {
          const dateValue = get(container, record.editable);
          if (record.action_at) {
            return dateValue || <div className="text-center">-</div>;
          }
          return (
            <DatePicker
              placement={index > list.length / 2 ? 'topRight' : 'bottomRight'}
              getPopupContainer={(trigger: any) => trigger.parentElement}
              size="small"
              value={dateValue ? moment(dateValue) : null}
              onChange={(value) =>
                onCheck({
                  task: record.task,
                  action: 'INPUT',
                  value: value ? value.format('YYYY-MM-DD') : null,
                })
              }
            />
          );
        }
        return <div className="text-center">-</div>;
      },
    },
    {
      title: 'Action',
      render: (_, record: any, index: number) => {
        if (index >= 1 && !list[index - 1]['action_at']) {
          return null;
        }

        if (record.action_at) {
          return (
            <Button
              size="small"
              onClick={() => onCheck({ task: record.task, action: 'UNDO' })}>
              Undo
            </Button>
          );
        }
        return (
          <Space>
            <Button
              size="small"
              disabled={record.editable && !get(container, record.editable)}
              onClick={() => onCheck({ task: record.task, action: 'DONE' })}>
              Done
            </Button>
            <Button
              size="small"
              onClick={() => onCheck({ task: record.task, action: 'NA' })}>
              NA
            </Button>
          </Space>
        );
      },
    },
  ];
  return (
    <>
      <Popover
        zIndex={100}
        placement="left"
        mouseEnterDelay={0.05}
        mouseLeaveDelay={0.05}
        destroyTooltipOnHide={true}
        content={
          <Table
            columns={columns}
            dataSource={list}
            bordered
            size="small"
            pagination={false}
            rowKey="task"
          />
        }>
        <Button type="link" size="small">
          <span className={`text-${color}`}>{children}</span>
        </Button>
      </Popover>
    </>
  );
};

export default CheckActions;
