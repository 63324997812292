import styles from 'res/css/ui.scss';
import { useApp } from '@/utils/useapp';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Tag,
  message,
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { groupBy, mapValues, uniq } from 'lodash';
import update from 'immutability-helper';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { useForm } from 'antd/lib/form/Form';
import Slider from '@/components/Slider';
import { ManagerFilterItems } from './ManagerFilterItems';
import dataKeys from './data';
import { BUSINESS_STATUS_OPTIONS } from '../../shipments';
import { TLVendorSelect } from '@/components/TLVendorSelect';
import { TLType } from '@/components/constants';
import {
  ApStatusLabelMap,
  ArStatusLabelMap,
  generateOptions,
  RebillStatusLabelMap,
  ShipmentOrderStatusLabelMap,
} from '../../constants';
import { CFSModelSelect } from '../CFSModelSelect';

const OPTION_ATTRIBUTES: any[] = [];

const SINGLE_UPDATE_ATTRIBUTE: any[] = ['task_overdue'];

type TFilter = {
  values: any[];
  allowAttributes?: string[];
  removeAll: () => void;
  removeOne: (item: any) => void;
  onChange: (t: any) => void;
  activeFilters: any[];
  show: boolean;
  loading: boolean;
  hideFilter: () => void;
};

export const convertFilterValuesToQueryParams = (values: any[]) => {
  if (values?.find((v) => v.meta)) {
    values = [
      ...values,
      ...(Object.entries(values?.find((v) => v.meta)?.meta)?.map((v) => ({
        attribute: v[0],
        value: v[1],
      })) || []),
    ];
  }
  return mapValues(groupBy(values, 'attribute'), (items) => {
    return items.length > 1 ? items.map((i) => i.value) : items[0].value;
  });
};

export const toFilterValue = (
  text: string,
  attribute: string,
  value: string[] | number[] | boolean | string,
) => {
  return {
    text,
    attribute,
    value,
  };
};

const BINARY_OPTIONS = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const Filter: React.FC<TFilter> = ({
  allowAttributes = [],
  onChange,
  removeAll,
  removeOne,
  activeFilters,
  show,
  hideFilter,
  loading,
}) => {
  const app = useApp();

  const [checkedFilterItems, setCheckedFilterItem] = useState<Array<string>>(
    [],
  );
  const [newValues, setNewValues] = useState<Array<any>>([]);
  const [settingContainerTasks, setSettingContainerTasks] = useState<
    Array<any>
  >([]);

  const [localActiveFilters, setLocalActiveFilters] = useState<Array<any>>([]);

  useEffect(() => {
    if (show) {
      setLocalActiveFilters(activeFilters);
    }
  }, [show, activeFilters]);

  const [filter] = useForm();

  useEffect(() => {
    const _meta: any = activeFilters?.find((n) => n.meta);

    if (show) {
      // const _meta: any = activeFilters.find((n) => n.meta);
      if (_meta?.meta) {
        filter.setFieldsValue(_meta?.meta);
      } else {
        filter.resetFields(RESET_FIELDS);
      }

      if (!settingContainerTasks) {
        fetchSettingContainerTasks();
      }
    } else {
      filter.setFieldsValue(_meta?.meta);
    }
  }, [show, activeFilters]);

  const removeLocalFilters = (index: number) => {
    const copy = [...localActiveFilters];

    copy.splice(index, 1);

    setLocalActiveFilters([...copy]);
  };

  const fetchSettingContainerTasks = async () => {
    try {
      const resp = await app.service.get('dispatch/settingContainerTasks/');
      setSettingContainerTasks(resp.data);
    } catch (err: any) {
      message.error(err?.data?.message || 'An error occurred');
    }
  };

  useEffect(() => {
    fetchSettingContainerTasks();
  }, []);

  const handleMultipleSelectChange = (
    values: any,
    options: any,
    attribute: string,
    textPrefix?: string,
  ) => {
    if (options) {
      const newFilterValues = newValues.filter(
        (v) => v.attribute !== attribute || values?.includes(v.value),
      );

      options.map((o: any) => {
        const index = newFilterValues.findIndex(
          (v) => v.attribute === attribute && v.value == o.value,
        );

        const text = textPrefix ? `${textPrefix}: ${o.label}` : `${o.label}`;

        const value = toFilterValue(text, attribute, o.value);

        if (index !== -1) {
          update(newFilterValues, { [index]: { $set: value } });
        } else {
          newFilterValues.push(value);
        }
      });
      setNewValues(newFilterValues);
    }
  };

  const handleInputChange = (
    value: any,
    attribute: string,
    textPrefix: string,
  ) => {
    handleChange(
      {
        target: {
          value,
          attribute,
        },
      },
      {
        textPrefix,
      },
    );
  };

  const handleBinaryChange = (v: any, key: string, title: string) => {
    const index = newValues.findIndex((v) => v.attribute === key);
    const value = toFilterValue(
      (title || key) + (v === true ? ' Yes' : ' No'),
      key,
      v === true,
    );

    if (index !== -1) {
      setNewValues(update(newValues, { [index]: { $set: value } }));
    } else {
      setNewValues([...newValues, value]);
    }
  };

  const handleStatusChange = (options: Array<any>, key: string) => {
    if (options.length > 0) {
      handleMultipleSelectChange(
        options.map((o) => o.id),
        options,
        key,
        key.split('_').join(' ').toUpperCase(),
      );
    }
  };

  const defaultRows = [
    {
      title: 'Operator',
      item: (
        <Form.Item className="mb0" name={dataKeys.OPERATOR}>
          <UserSyncSelect
            addNullOption
            nullOptionValue={0}
            size="small"
            value={undefined}
            mode="multiple"
            style={{ width: '100%' }}
            maxTagCount="responsive"
            type="admin"
            allowClear
            onChange={(value: any, option: any) =>
              handleMultipleSelectChange(value, option, dataKeys.OPERATOR, 'OP')
            }
          />
        </Form.Item>
      ),
      enableIsNot: true,
      key: dataKeys.OPERATOR,
    },
    {
      title: 'Customer',
      item: (
        <Form.Item name={dataKeys.CUSTOMER} className="mb0">
          <UserSyncSelect
            size="small"
            allowClear
            mode="multiple"
            style={{ width: '100%' }}
            maxTagCount="responsive"
            onChange={(value: any, option: any) =>
              handleMultipleSelectChange(
                value,
                option,
                dataKeys.CUSTOMER,
                'Customer',
              )
            }
          />
        </Form.Item>
      ),
      enableIsNot: true,
      key: dataKeys.CUSTOMER,
    },
    {
      title: 'BD',
      item: (
        <Form.Item className="mb0" name={dataKeys.BUSINESS_DEVELOPMENT}>
          <UserSyncSelect
            size="small"
            value={undefined}
            mode="multiple"
            style={{ width: '100%' }}
            maxTagCount="responsive"
            type="admin"
            allowClear
            onChange={(value: any, option: any) =>
              handleMultipleSelectChange(
                value,
                option,
                dataKeys.BUSINESS_DEVELOPMENT,
                'BD',
              )
            }
          />
        </Form.Item>
      ),
      enableIsNot: true,
      key: dataKeys.BUSINESS_DEVELOPMENT,
    },
    {
      title: 'Sales',
      item: (
        <Form.Item className="mb0" name={dataKeys.SALES}>
          <UserSyncSelect
            size="small"
            value={undefined}
            mode="multiple"
            style={{ width: '100%' }}
            maxTagCount="responsive"
            type="admin"
            allowClear
            onChange={(value: any, option: any) =>
              handleMultipleSelectChange(value, option, dataKeys.SALES, 'Sales')
            }
          />
        </Form.Item>
      ),
      enableIsNot: true,
      key: dataKeys.SALES,
    },
    {
      title: 'Sales Support',
      item: (
        <Form.Item className="mb0" name={dataKeys.SALES_SUPPORT}>
          <UserSyncSelect
            size="small"
            value={undefined}
            mode="multiple"
            style={{ width: '100%' }}
            maxTagCount="responsive"
            type="admin"
            allowClear
            onChange={(value: any, option: any) =>
              handleMultipleSelectChange(
                value,
                option,
                dataKeys.SALES_SUPPORT,
                'Sales Support',
              )
            }
          />
        </Form.Item>
      ),
      enableIsNot: true,
      key: dataKeys.SALES_SUPPORT,
    },
    {
      title: 'Dispatch Status',
      item: (
        <Form.Item className="mb0" name={dataKeys.BUSINESS_STATUS}>
          <Select
            size="small"
            allowClear
            mode="multiple"
            value={undefined}
            style={{ width: '100%' }}
            filterOption={(input, option) =>
              ((option!.label as unknown) as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            maxTagCount="responsive"
            onChange={(value: any, option: any) =>
              handleMultipleSelectChange(
                value,
                option,
                dataKeys.BUSINESS_STATUS,
              )
            }
            options={BUSINESS_STATUS_OPTIONS}
          />
        </Form.Item>
      ),
      enableIsNot: true,
      key: dataKeys.BUSINESS_STATUS,
    },
    {
      title: 'Vendor',
      item: (
        <Form.Item className="mb0" name={dataKeys.VENDOR}>
          <TLVendorSelect
            tlType={TLType.CFS}
            style={{ width: '100%' }}
            onSelect={(vendor: any) => {
              handleMultipleSelectChange(
                vendor?.id,
                [
                  {
                    label: vendor?.name,
                    value: vendor?.id,
                  },
                ],
                dataKeys.VENDOR,
                'Vendor',
              );
            }}
          />
        </Form.Item>
      ),
      enableIsNot: true,
      key: dataKeys.VENDOR,
    },
    {
      title: 'Carrier',
      item: (
        <Form.Item className="mb0" name="carrier">
          <CFSModelSelect
            identify="carrier"
            mode="multiple"
            value={undefined}
            onChange={(value: any, option: any) =>
              handleMultipleSelectChange(
                value,
                option,
                dataKeys.CARRIER,
                'Carrier',
              )
            }
          />
        </Form.Item>
      ),
      enableIsNot: true,
      key: dataKeys.CARRIER,
    },
    {
      title: 'Order Status',
      item: (
        <Form.Item name={'order_status'} className="mb0">
          <Select
            mode="multiple"
            size="small"
            options={generateOptions(ShipmentOrderStatusLabelMap)}
            onChange={(order_status: any, options: any) => {
              filter.setFieldsValue({ order_status: options });
              handleStatusChange(options, 'order_status');
            }}
          ></Select>
        </Form.Item>
      ),
      enableIsNot: true,
      key: 'order_status',
    },
    {
      title: 'AR Status',
      item: (
        <Form.Item noStyle shouldUpdate>
          {() => (
            <Form.Item name={'ar_status'} className="mb0">
              <Select
                mode="multiple"
                size="small"
                options={generateOptions(ArStatusLabelMap)}
                onChange={(ar_status: any, options: any) => {
                  filter.setFieldsValue({ ar_status: options });
                  handleStatusChange(options, 'ar_status');
                }}
              ></Select>
            </Form.Item>
          )}
        </Form.Item>
      ),
      enableIsNot: true,
      key: 'ar_status',
    },
    {
      title: 'AP Status',
      item: (
        <Form.Item noStyle shouldUpdate>
          {() => (
            <Form.Item name={'ap_status'} className="mb0">
              <Select
                mode="multiple"
                size="small"
                options={generateOptions(ApStatusLabelMap)}
                onChange={(ap_status: any, options: any) => {
                  filter.setFieldsValue({ ap_status: options });
                  handleStatusChange(options, 'ap_status');
                }}
              ></Select>
            </Form.Item>
          )}
        </Form.Item>
      ),
      enableIsNot: true,
      key: 'ap_status',
    },

    {
      title: 'Rebill Status',
      item: (
        <Form.Item noStyle shouldUpdate>
          {() => (
            <Form.Item name={'rebill_status'} className="mb0">
              <Select
                mode="multiple"
                size="small"
                options={generateOptions(RebillStatusLabelMap)}
                onChange={(ap_status: any, options: any) => {
                  filter.setFieldsValue({ rebill_status: options });
                  handleStatusChange(options, 'rebill_status');
                }}
              ></Select>
            </Form.Item>
          )}
        </Form.Item>
      ),
      enableIsNot: true,
      key: 'rebill_status',
    },
    {
      title: 'Agent',
      item: (
        <Form.Item className="mb0" name={dataKeys.AGENT}>
          <UserSyncSelect
            size="small"
            value={undefined}
            mode="multiple"
            style={{ width: '100%' }}
            maxTagCount="responsive"
            type="user"
            allowClear
            onChange={(value: any, option: any) =>
              handleMultipleSelectChange(value, option, dataKeys.AGENT, 'Agent')
            }
          />
        </Form.Item>
      ),
      enableIsNot: true,
      key: dataKeys.AGENT,
    },
  ];

  const rows = useMemo(() => {
    if (allowAttributes.length == 0) {
      return defaultRows.filter(
        (item) => !OPTION_ATTRIBUTES.includes(item.key),
      );
    }

    return defaultRows.filter((item) => allowAttributes.includes(item.key));
  }, [defaultRows, allowAttributes]);

  useEffect(() => {
    if (!show) {
      return;
    }

    if (
      (!checkedFilterItems || checkedFilterItems.length == 0) &&
      localActiveFilters.length > 0
    ) {
      setCheckedFilterItem(localActiveFilters.map((f) => f.attribute));
    }
  }, [checkedFilterItems, localActiveFilters]);

  useEffect(() => {
    setNewValues([]);
    setCheckedFilterItem([]);
  }, [show]);

  const handleChangeFilterItems = (checked: string[]) => {
    setCheckedFilterItem(
      Object.assign(
        uniq([
          ...checked,
          ...localActiveFilters.map((f) => f.attribute),
          ...newValues.map((n) => n.attribute),
        ]),
      ),
    );
  };

  const RESET_FIELDS = [
    ...rows.map((item) => item.key),
    ...['warehouse', 'vendor'],
  ];

  const handleChange = async (event: any, options: any = {}) => {
    const { value, attribute } = event.target;
    const { preferText, textPrefix } = options;

    const index = newValues.findIndex((v) => v.attribute === attribute);

    const text = preferText
      ? preferText
      : textPrefix
      ? `${textPrefix}: ${value}`
      : `${value}`;

    const _value = toFilterValue(text, attribute, value);
    if (index !== -1) {
      value && setNewValues(update(newValues, { [index]: { $set: _value } }));
      !value && setNewValues(update(newValues, { $splice: [[index, 1]] }));
    } else {
      setNewValues([...newValues, _value]);
    }
  };

  const handleApply = async () => {
    const filterNewValues = newValues.filter(
      (n) =>
        !localActiveFilters.find(
          (a) => a.attribute == n.attribute && a.value == n.value,
        ),
    );

    const filterActives = localActiveFilters.filter((a) => {
      if (
        SINGLE_UPDATE_ATTRIBUTE.includes(a.attribute) &&
        filterNewValues.find((n) => a.attribute == n.attribute)
      ) {
        return false;
      }

      return a;
    });
    const _meta = localActiveFilters.findIndex((n) => n.meta);
    const meta: any = {};
    const _filter = filter.getFieldsValue();
    for (const key in _filter) {
      if (key.startsWith('is_') && !RESET_FIELDS.includes(key)) {
        meta[key] = _filter[key];
      }
    }

    if (_meta > -1) {
      filterActives[_meta] = {
        meta,
      };
    } else {
      filterNewValues.push({
        meta,
      });
    }

    onChange([...filterActives, ...filterNewValues]);
    filter.resetFields(RESET_FIELDS);
    setNewValues([]);
  };

  const handleReset = () => {
    filter.resetFields(RESET_FIELDS);
    setNewValues([]);
  };

  const FieldTag = ({ field }: { field: string }) =>
    useMemo(() => {
      return (
        <div style={{ width: 'flex' }}>
          {localActiveFilters
            // .filter((item) => item.attribute == field)
            .map((item: any, index) => {
              return item.attribute == field ? (
                <Tag
                  className="ml-sm"
                  closable
                  onClose={() => {
                    removeLocalFilters(index);
                  }}
                  color={
                    filter.getFieldValue(`is_${item.attribute}`)
                      ? 'processing'
                      : ''
                  }
                  key={`tag-${item.text}-${index}`}
                >
                  {item.text}
                </Tag>
              ) : (
                ''
              );
            })}
        </div>
      );
    }, [activeFilters, filter]);

  return (
    <div className={styles.filter}>
      {show && (
        <Modal
          open={show}
          onCancel={() => hideFilter()}
          footer={false}
          width={1200}
        >
          <Form form={filter} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <div style={{ maxHeight: '70vh', overflowY: 'scroll' }}>
              <Row>
                <ManagerFilterItems
                  checkedItems={checkedFilterItems}
                  items={rows.map((item: any) => ({
                    key: item.key,
                    name: item.title,
                  }))}
                  onChange={handleChangeFilterItems}
                />
              </Row>
              <table>
                <tbody>
                  {rows
                    .filter((item) => checkedFilterItems.includes(item.key))
                    .map((item: any) => (
                      <tr key={item.key}>
                        <td width="200">{item.title}</td>
                        <td width="250">
                          {item.isBinary ? (
                            <Form.Item className="mb0" name={item.key}>
                              <Select
                                size="small"
                                allowClear
                                value={undefined}
                                style={{ width: '100%' }}
                                maxTagCount="responsive"
                                onChange={(v) =>
                                  handleBinaryChange(v, item.key, item.title)
                                }
                                options={BINARY_OPTIONS}
                              />
                            </Form.Item>
                          ) : (
                            item.item
                          )}
                        </td>
                        <td width="80">
                          {item.enableIsNot ? (
                            <Form.Item
                              className="mb0"
                              initialValue={1}
                              name={`is_${item.key}`}
                            >
                              <Select size="small">
                                <Select.Option value={1}>Is</Select.Option>
                                <Select.Option value={0}>Is Not</Select.Option>
                              </Select>
                            </Form.Item>
                          ) : (
                            <></>
                          )}
                        </td>
                        <td width="800">
                          <FieldTag field={item.key} />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            <Row>
              <Col span={4}>
                <Form.Item label="&nbsp;" shouldUpdate>
                  <Space>
                    <Button
                      disabled={
                        (newValues.length > 0 ||
                        filter.isFieldsTouched([], true)
                          ? false
                          : true) || loading
                      }
                      type="primary"
                      onClick={handleApply}
                    >
                      Apply
                    </Button>
                    <Button onClick={handleReset} disabled={loading}>
                      Reset
                    </Button>
                    {activeFilters.length > 0 && (
                      <Button onClick={removeAll} disabled={loading}>
                        Clear All
                      </Button>
                    )}
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
      {(show || activeFilters.length > 0) && (
        <Row>
          <Col span={1}>
            <div>Filters:</div>
          </Col>
          <Col span={19}>
            <Slider>
              {activeFilters
                // .filter((item) => !item.meta)
                ?.map((item: any, index) =>
                  !item.meta ? (
                    <li key={`tag-${item.text}-${index}`}>
                      <Tag
                        closable
                        onClose={() => {
                          removeOne(index);
                        }}
                        color={
                          filter.getFieldValue(`is_${item.attribute}`)
                            ? 'processing'
                            : ''
                        }
                      >
                        {item.text}
                      </Tag>
                    </li>
                  ) : (
                    ''
                  ),
                )}
            </Slider>
          </Col>
          <Col span={4}>
            <div>
              {activeFilters.length > 0 && (
                <Button size="small" onClick={removeAll}>
                  Clear All
                </Button>
              )}
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};
