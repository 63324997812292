import styles from 'res/css/ui.scss';
import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Space,
} from 'antd';
import { CitySelect } from '@/components/CitySelect';
import { IntermodalRegionSelect } from '@/components/IntermodalRegionSelect';
import { FacilityTypeSelect } from '@/components/FacilityTypeSelect';
import { VendorSelect } from '@/components/VendorSelect';
import { Moment } from 'moment';
import React, { useState } from 'react';
import { useApp } from '@/utils/useapp';
import ContainerSizeSelect from '@/components/ContainerSizeSelect';
import { AntDRangePicker } from '@/components/AntDRangePicker';
import {
  PAST_3_MONTHS,
  RANGE_TYPES_PAST_1_2_3_4_6_12_MONTHS,
} from '@/components/AntDRangePicker/data';

export type TFilter = {
  loading: boolean;
  filter: FormInstance;
  onSearch: () => void;
  onChange: (values: any) => void;
  onCreateSellRate: () => void;
};

export const Filter: React.FC<TFilter> = ({
  loading,
  filter,
  onSearch,
  onChange,
  onCreateSellRate,
}) => {
  const app = useApp();
  const [isShow, setIsShow] = useState(false);
  const [create, setCreate] = useState<[Moment, Moment]>();
  const [expire, setExpire] = useState<[Moment, Moment]>();

  const handleSearch = () => {
    onSearch();
  };

  const handleFilterChange = (key: string, value: any) => {
    filter.setFieldsValue({ ...filter.getFieldsValue(), [key]: value });
  };

  const handleCreateOrExpiredDateChange = (
    type: string,
    value: Moment[] | null,
  ) => {
    filter.setFieldsValue({
      ...filter,
      [`${type}_at_start_date`]: value ? value[0].format() : '',
      [`${type}_at_end_date`]: value ? value[1].format() : '',
    });
  };

  const handleReset = () => {
    filter.resetFields();
    onSearch();
  };
  const handleCreateSellRate = () => {
    onCreateSellRate();
  };

  React.useEffect(() => {
    // handleCreateOrExpiredDateChange('created', create);
    filter.setFieldsValue({
      ...filter,
      past_month: 3,
    });
    handleCreateOrExpiredDateChange('expired', expire);
  }, []);

  React.useEffect(() => {
    app.store.search.rateId &&
      filter.setFieldsValue({ id: app.store.search.rateId });

    app.store.search.intermodalRegionId &&
      filter.setFieldsValue({
        intermodal_region_id: app.store.search.intermodalRegionId,
      });

    app.store.search.toCity &&
      filter.setFieldsValue({ to_city: app.store.search.toCity });
  }, [
    app.store.search.rateId,
    app.store.search.intermodalRegionId,
    app.store.search.toCity,
  ]);

  return (
    <div className={styles.filter}>
      <div
        style={{
          width: '100%',
        }}>
        <Form layout="vertical" form={filter} initialValues={{ valid: 'true' }}>
          <Form.Item name="sort_by" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="sort_value" hidden>
            <Input />
          </Form.Item>
          <Row gutter={8}>
            <Col span={4}>
              <Form.Item label="ID#" name={'id'}>
                <Input
                  onChange={(e) => handleFilterChange('id', e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Intermodal Region" name="intermodal_region_id">
                <IntermodalRegionSelect
                  onChange={(v) =>
                    handleFilterChange('intermodal_region_id', v)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <Form.Item label="City" name="to_city">
                    <CitySelect
                      selected={getFieldValue('to_city') || ''}
                      onSelect={(id) => handleFilterChange('to_city', id)}
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="Create Date" name="past_month">
                <Select
                  defaultValue={3}
                  options={[
                    { label: 'All', value: 0 },
                    { label: 'Past 1 Month', value: 1 },
                    { label: 'Past 3 Months', value: 3 },
                    { label: 'Past 6 Months', value: 6 },
                    { label: 'Past 12 Months', value: 12 },
                  ]}
                  onChange={(v) => handleFilterChange('past_month', v)}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label=" ">
                <Button
                  onClick={() => {
                    setIsShow((isShow) => !isShow);
                  }}>
                  {isShow ? 'Hide Filters' : 'Show All Filters'}
                </Button>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label=" ">
                <Space>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={loading}
                    onClick={handleSearch}>
                    Search
                  </Button>

                  <Button onClick={handleReset}>Clear All</Button>
                  <Button type="primary" onClick={handleCreateSellRate}>
                    Create Sell Rate
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>

          {isShow && (
            <Row gutter={8}>
              <Col span={3}>
                <Form.Item label="From Facility Type" name="from_facility_type">
                  <FacilityTypeSelect
                    getValueForOption="name"
                    // value={filter.from_facility_type || ''}
                    onChange={(v) =>
                      handleFilterChange('from_facility_type', v)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="CNTR Size" name="container_size">
                  <ContainerSizeSelect style={{ width: '100%' }} allowClear />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="HAZ" name="is_haz">
                  <Select allowClear>
                    <Select.Option value="">All</Select.Option>
                    <Select.Option value="true">Yes</Select.Option>
                    <Select.Option value="false">No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="OW" name="is_ow">
                  <Select allowClear>
                    <Select.Option value="">All</Select.Option>
                    <Select.Option value="true">Yes</Select.Option>
                    <Select.Option value="false">No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="REEFER" name="is_reefer">
                  <Select allowClear>
                    <Select.Option value="">All</Select.Option>
                    <Select.Option value="true">Yes</Select.Option>
                    <Select.Option value="false">No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="SOC" name="is_soc">
                  <Select allowClear>
                    <Select.Option value="">All</Select.Option>
                    <Select.Option value="true">Yes</Select.Option>
                    <Select.Option value="false">No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="Residential" name="is_residential">
                  <Select allowClear>
                    <Select.Option value="">All</Select.Option>
                    <Select.Option value="true">Yes</Select.Option>
                    <Select.Option value="false">No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="Valid" name="valid">
                  <Select
                    allowClear
                    // value={filter.valid || ''}
                    onChange={(v) => handleFilterChange('valid', v)}>
                    <Select.Option value="">All</Select.Option>
                    <Select.Option value="true">Yes</Select.Option>
                    <Select.Option value="false">No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="Visible" name="visible">
                  <Select
                    allowClear
                    // value={filter.visible || ''}
                    onChange={(v) => handleFilterChange('visible', v)}>
                    <Select.Option value="true">Yes</Select.Option>
                    <Select.Option value="false">No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="Favored" name="admin_favored">
                  <Select
                    allowClear
                    // value={filter.admin_favored || ''}
                    onChange={(v) => handleFilterChange('admin_favored', v)}>
                    <Select.Option value="true">Yes</Select.Option>
                    <Select.Option value="false">No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              {/* <Col span={3}>
                <Form.Item
                  label="Different IR"
                  name="vendor_not_in_same_intermodal_region">
                  <Select
                    allowClear
                    // value={filter.vendor_not_in_same_intermodal_region || ''}
                    onChange={(v) =>
                      handleFilterChange(
                        'vendor_not_in_same_intermodal_region',
                        v,
                      )
                    }>
                    <Select.Option value="true">Yes</Select.Option>
                  </Select>
                </Form.Item>
              </Col> */}

              <Col span={3}>
                <Form.Item label="To Zipcode" name="to_zipcode">
                  <Input
                    placeholder="Search"
                    value={filter.getFieldValue('to_zipcode') || ''}
                    onChange={(e) =>
                      handleFilterChange('to_zipcode', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="To Facility Type" name="to_facility_type">
                  <FacilityTypeSelect
                    getValueForOption="name"
                    value={filter.getFieldValue('to_facility_type') || ''}
                    onChange={(v) => handleFilterChange('to_facility_type', v)}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="FBA Code" name="fba_facility_code">
                  <Input
                    placeholder="Enter to FBA facility code"
                    // value={filter.fba_facility_code || ''}
                    onChange={(e) =>
                      handleFilterChange('fba_facility_code', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => (
                    <Form.Item label="Vendor" name="vendor">
                      <VendorSelect
                        selected={getFieldValue('vendor') || ''}
                        onSelect={(id) => handleFilterChange('vendor', id)}
                      />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => (
                    <>
                      <Form.Item
                        hidden
                        initialValue={create}
                        name="created_at_start_date"></Form.Item>
                      <Form.Item hidden name="created_at_end_date"></Form.Item>
                      <Form.Item label="Created Date">
                        <AntDRangePicker
                          style={{ width: '100%' }}
                          rangeType={RANGE_TYPES_PAST_1_2_3_4_6_12_MONTHS}
                          value={create}
                          onChange={(value: [Moment, Moment]) => {
                            setCreate(value);
                            handleCreateOrExpiredDateChange('created', value);
                          }}
                        />
                      </Form.Item>
                    </>
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => (
                    <>
                      <Form.Item
                        hidden
                        name="expired_at_start_date"></Form.Item>
                      <Form.Item hidden name="expired_at_end_date"></Form.Item>
                      <Form.Item label="Expired Date">
                        <AntDRangePicker
                          style={{ width: '100%' }}
                          value={expire}
                          rangeType={RANGE_TYPES_PAST_1_2_3_4_6_12_MONTHS}
                          onChange={(value: [Moment, Moment]) => {
                            setExpire(value);
                            handleCreateOrExpiredDateChange('expired', value);
                          }}
                        />
                      </Form.Item>
                    </>
                  )}
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  initialValue={'false'}
                  name="exclude_drayeasy"
                  label="Exclude Drayeasy">
                  <Select allowClear placeholder="All">
                    <Select.Option value={'true'}>Yes</Select.Option>
                    <Select.Option value={'false'}>No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label="Inaccurate" name="inaccurate">
                  <Select
                    allowClear
                    onChange={(v) => handleFilterChange('inaccurate', v)}>
                    <Select.Option value="true">Yes</Select.Option>
                    <Select.Option value="false">No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
        </Form>
      </div>
    </div>
  );
};
