export const PROGRESS_TYPE_ALL = 'all';
export const PROGRESS_TYPE_PICKUP = 'pickup';
export const PROGRESS_TYPE_DELIVERY = 'delivered';
export const PROGRESS_TYPE_AVAILABLE = 'available';
export const PROGRESS_TYPE_PENDING_EMPTY = 'pending_empty';
export const PROGRESS_TYPE_PENDING_RETURN = 'pending_return';
export const PROGRESS_TYPE_ARRIVING = 'arriving';
export const PROGRESS_TYPE_NEW_ORDER = 'new_order';
export const PROGRESS_TYPE_IN_TRANSIT = 'in_transit';
export const PROGRESS_TYPE_WEEKEND_HOLIDAY = 'weekend_holiday';

export const PROGRESS_TYPE_MAP = {
  [PROGRESS_TYPE_ALL]: 'All',
  [PROGRESS_TYPE_PICKUP]: 'Pickup',
  [PROGRESS_TYPE_DELIVERY]: 'Delivery',
  [PROGRESS_TYPE_AVAILABLE]: 'Available',
  [PROGRESS_TYPE_PENDING_EMPTY]: 'Pending Empty',
  [PROGRESS_TYPE_PENDING_RETURN]: 'Pending Return',
  [PROGRESS_TYPE_ARRIVING]: 'Arriving',
  [PROGRESS_TYPE_NEW_ORDER]: 'New Order',
  [PROGRESS_TYPE_IN_TRANSIT]: 'In Transit',
  [PROGRESS_TYPE_WEEKEND_HOLIDAY]: 'Weekend & Holiday',
};

export const PROGRESS_DESCRIPTION = {
  [PROGRESS_TYPE_ALL]: 'All',
  [PROGRESS_TYPE_PICKUP]: '',
  [PROGRESS_TYPE_DELIVERY]: '',
  [PROGRESS_TYPE_AVAILABLE]:
    'Container is available, and pending for securing pickup and delivery appt',
  [PROGRESS_TYPE_PENDING_EMPTY]:
    'Conatiner has been dropped, and pending emptied',
  [PROGRESS_TYPE_PENDING_RETURN]:
    'Container has been emptied but pending empty returned',
  [PROGRESS_TYPE_ARRIVING]:
    'container is arriving soon, and OP needs to verify release and ground status',
  [PROGRESS_TYPE_NEW_ORDER]: 'New order has been created at today',
  [PROGRESS_TYPE_IN_TRANSIT]:
    'Container is not arriving yet, and OP needs to finish the preparation',
  [PROGRESS_TYPE_WEEKEND_HOLIDAY]: 'Weekend & Holiday',
};
