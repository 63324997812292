import { EditOutlined } from '@ant-design/icons';
import { get } from 'lodash';
import { Button, Tooltip, Typography } from 'antd';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { CSSProperties, memo, useCallback, useMemo, useState } from 'react';
import { SyncCacheModelSelectProps } from '@/components/SyncCacheModelSelect';
import EditableCustomerSelectColumnMemo from './memo';

const containerStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
};

const truncateStyle: CSSProperties = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

const selectStyle: CSSProperties = {
  flex: 1,
  minWidth: 0,
};

const EditableCustomerSelectColumn = memo(
  ({
    data,
    record,
    path,
    onSelect,
    refresh,
  }: {
    data: any[];
    record: any;
    path: string[];
    onSelect: SyncCacheModelSelectProps['onSelect'];
    refresh?: () => void;
  }) => {
    const [isEditing, setIsEditing] = useState(false);
    const handleEdit = useCallback(() => {
      setIsEditing(true);
    }, []);

    const value = get(record, path, undefined);

    const label = useMemo(() => {
      const user = data?.find((user) => user.id === value);
      if (!user) {
        return '';
      }
      return `${user.company_code}(${user.name})`;
    }, [data, value]);

    const handleSelect: SyncCacheModelSelectProps['onSelect'] = useCallback(
      (model) => {
        onSelect?.(model);
        setIsEditing(false);
      },
      [onSelect],
    );

    const handleBlur = useCallback(() => {
      setIsEditing(false);
    }, []);

    const MainComponent = !isEditing ? (
      label ? (
        <Tooltip placement="left" title={label}>
          <span style={truncateStyle}>
            <Typography.Link underline onClick={handleEdit}>
              {label}
            </Typography.Link>
          </span>
        </Tooltip>
      ) : (
        <Button
          type="link"
          icon={<EditOutlined />}
          onClick={handleEdit}
        ></Button>
      )
    ) : (
      <UserSyncSelect
        style={selectStyle}
        value={record.user_id || ''}
        autoFocus
        open
        onSelect={handleSelect}
        onBlur={handleBlur}
      />
    );

    return (
      <div style={containerStyle}>
        {MainComponent}
        <EditableCustomerSelectColumnMemo record={record} refresh={refresh} />
      </div>
    );
  },
);

EditableCustomerSelectColumn.displayName = 'EditableCustomerSelectColumn';

export default EditableCustomerSelectColumn;
