import { Button, Space, DatePicker } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { get, upperFirst, has, isArray } from 'lodash';

import actionUtils, {
  TFtlShipmentAction,
  TARGET_TYPE_FTL_SHIPMENT,
} from '@/components/Tasks/Interfaces/ActionInterface';

import FtlShipmentSendPod from './FtlShipmentSendPod';
import FtlShipmentSendBOL from './FtlShipmentSendBOL';
import { EditAction } from './EditAction';
import { ConfirmBeforeUpdate } from './ConfirmBeforeUpdate';
import moment from 'moment';
import { RescheduledAction } from './RescheduledAction';
import Upload from './Upload';
import FtlShipmentSendConfirmation from './FtlShipmentSendConfirmation';
import InputAvailableTime from './InputAvailableTime';
import { ShipmentInterface } from '@/pages/truckload/ltl/shipments/Interfaces/ShipmentInterface';

const FtlShipmentTaskActions: React.FC<TFtlShipmentAction> = ({
  task,
  targetModel: ftlShipment,
  onDone,
  onSaveOperation,
}) => {
  const [updatingData, setUpdatingData] = React.useState<any>({});
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleOnSaveOperation = async (
    action: string,
    update_name: string,
    value?: string | null,
  ) => {
    await onSaveOperation(
      task,
      action,
      update_name,
      value,
      (loading: boolean) => setLoading(loading),
      onDone,
    );
  };

  useEffect(() => {
    setLoading(false);
  }, [loading]);

  const operation = useMemo(() => task?.operation, [task]);

  const _actions = useMemo(() => {
    if (!operation?.is_done) {
      return task?.actions;
    }

    const hasReschedule = task?.actions.some(
      (actionItem) => actionItem.action == actionUtils.RESCHEDULE,
    );
    const newActions = task?.actions.filter((actionItem) =>
      actionUtils.ALWAY_SHOW_ACTIONS.includes(actionItem.action),
    );

    if (
      !hasReschedule &&
      !newActions.find((action) => action.action === actionUtils.UNDO)
    ) {
      newActions.push({ action: actionUtils.UNDO });
    }

    return newActions;
  }, [task]);

  return (
    <>
      <Space>
        {_actions?.map((actionRecord: any) => {
          const { action, update_name, avaliable_time_name } = actionRecord;

          const actionDisabled =
            task?.disabled ||
            (action == actionUtils.UNDO ? false : task.disabled);
          let value;

          if (isArray(update_name)) {
            if (has(update_name, 'time')) {
              value = get(ftlShipment, update_name[get(update_name, 'time')]);
            }
          } else {
            value = get(ftlShipment, update_name);
          }

          switch (action) {
            case actionUtils.INPUT:
            case actionUtils.INPUT_SR: {
              return (
                <EditAction
                  task={task}
                  targetModel={ftlShipment}
                  target={TARGET_TYPE_FTL_SHIPMENT}
                  action={action}
                  name={update_name}
                  value={value}
                  onDone={onDone}
                  onSaveOperation={onSaveOperation}
                />
              );
            }

            case actionUtils.INPUT_DATE:
              return (
                <ConfirmBeforeUpdate
                  target={TARGET_TYPE_FTL_SHIPMENT}
                  targetModel={ftlShipment}
                  task={task}
                  onDone={onDone}
                  toSave={() =>
                    handleOnSaveOperation(
                      action,
                      update_name,
                      updatingData.value,
                    )
                  }
                  updatingData={updatingData}
                >
                  <DatePicker
                    disabled={task?.disabled}
                    getPopupContainer={(trigger: any) => trigger.parentElement}
                    size="small"
                    value={value ? moment(value) : null}
                    onChange={(value) => {
                      setUpdatingData({
                        action,
                        name: update_name,
                        value: value ? value.format('YYYY-MM-DD') : null,
                      });
                    }}
                  />
                </ConfirmBeforeUpdate>
              );
            case actionUtils.INPUT_AVAILABLE_TIME:
              return (
                <InputAvailableTime
                  action={action}
                  task={task}
                  name={update_name}
                  availableTimeName={avaliable_time_name}
                  target={TARGET_TYPE_FTL_SHIPMENT}
                  targetModel={ftlShipment}
                  disabled={task?.disabled}
                  onDone={onDone}
                  onSaveOperation={onSaveOperation}
                />
              );
            case actionUtils.RESCHEDULE:
              return (
                <RescheduledAction
                  target={TARGET_TYPE_FTL_SHIPMENT}
                  targetModel={ftlShipment}
                  task={task}
                  onSaveOperation={onSaveOperation}
                  onDone={onDone}
                />
              );

            case actionUtils.UPLOAD:
              return (
                <Upload
                  task={task}
                  target={TARGET_TYPE_FTL_SHIPMENT}
                  targetModel={ftlShipment}
                  onDone={onDone}
                  onSaveOperation={onSaveOperation}
                />
              );
            case actionUtils.SEND_POD:
              return (
                <FtlShipmentSendPod
                  task={task}
                  targetModel={ftlShipment}
                  target={TARGET_TYPE_FTL_SHIPMENT}
                  onDone={onDone}
                  onSaveOperation={onSaveOperation}
                />
              );
            case actionUtils.SEND_BOL:
              return (
                <FtlShipmentSendBOL
                  task={task}
                  targetModel={ftlShipment}
                  target={TARGET_TYPE_FTL_SHIPMENT}
                  onDone={onDone}
                  onSaveOperation={onSaveOperation}
                />
              );
            case actionUtils.SEND_CONFIRMATION:
              return (
                <FtlShipmentSendConfirmation
                  task={task}
                  targetModel={ftlShipment}
                  target={TARGET_TYPE_FTL_SHIPMENT}
                  onDone={onDone}
                  onSaveOperation={onSaveOperation} // 添加这一行
                />
              );
            default: {
              return (
                <Button
                  key={action}
                  size="small"
                  disabled={task.disabled || actionDisabled}
                  onClick={() => {
                    handleOnSaveOperation(action, update_name);
                  }}
                >
                  {upperFirst(action)}
                </Button>
              );
            }
          }
        })}
      </Space>
    </>
  );
};

export default FtlShipmentTaskActions;
