import React from 'react';
import { Form, FormInstance, Input, InputNumber, Select, Button } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

interface Props {
  form: FormInstance;
  onSubmit: () => void;
}

export const DrawerForm: React.FC<Props> = ({ form, onSubmit }) => {
  return (
    <Form layout="vertical" form={form} onFinish={onSubmit}>
      <Form.Item name="id" noStyle>
        <Input hidden />
      </Form.Item>
      <Form.Item label="Name" name="name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Alias" name="alias">
        <Input />
      </Form.Item>
      <Form.Item label="State" name="state" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Country" name="country" rules={[{ required: true }]}>
        <Select>
          <Select.Option value="USA">USA</Select.Option>
          <Select.Option value="CANADA">CANADA</Select.Option>
          <Select.Option value="HONG KONG">HONG KONG</Select.Option>
          <Select.Option value="CHINA">CHINA</Select.Option>
          <Select.Option value="Mexico">Mexico</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label="UNLOCODE" name="unlocode">
        <Input />
      </Form.Item>
      <Form.Item label="Zipcode" required>
        <Form.List
          initialValue={form.getFieldValue('zipcodes') || [{ zipcode: '' }]}
          name="zipcodes">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Form.Item label={''} key={field.key}>
                  <Form.Item name={[field.name, 'id']} noStyle>
                    <Input type={'hidden'} />
                  </Form.Item>
                  <Form.Item
                    wrapperCol={{ xs: 10 }}
                    name={[field.name, 'zipcode']}
                    noStyle
                    //validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        required: true,
                        message: 'Please input Zipcode',
                      },
                    ]}>
                    <Input
                      minLength={5}
                      placeholder="Zipcode"
                      style={{
                        width: fields.length > 1 ? '95%' : '100%',
                      }}
                    />
                  </Form.Item>
                  {fields.length > 1 ? (
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      style={{ marginLeft: '10px' }}
                      onClick={() => remove(field.name)}
                    />
                  ) : null}
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{
                    width: '100%',
                  }}
                  icon={<PlusOutlined />}>
                  Add zipcode
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>

      <Form.Item label="Latitude" name="latitude" rules={[{ required: true }]}>
        <InputNumber style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        label="Longitude"
        name="longitude"
        rules={[{ required: true }]}>
        <InputNumber style={{ width: '100%' }} />
      </Form.Item>
    </Form>
  );
};
