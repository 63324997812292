import React, { useState } from 'react';
import DrawerForm from './components/DrawerForm';

export const useVendorRateDrawer = () => {
  const [id, setId] = useState<number | undefined>(undefined);
  const [visible, setVisible] = useState(false);
  const [isChangeHistory, setIsChangeHistory] = useState(false);

  const closeDrawer = (
    e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
  ) => {
    e.stopPropagation();
    setVisible(false);
    isChangeHistory && window.history.back();
    !isChangeHistory && window.history.pushState(null, '', 'entity/vendors');
    setIsChangeHistory(false);
  };

  const VendorRateFormDrawer = () =>
    id ? <DrawerForm id={id} visible={visible} onClose={closeDrawer} /> : null;

  const openVendorRateFormDrawer = (
    id: number,
    enableChangeHistory?: boolean,
  ) => {
    if (enableChangeHistory) {
      window.history.pushState(null, '', `./vendors/${id}/`);
      setIsChangeHistory(true);
    }
    setId(id);
    setVisible(true);
  };

  return { VendorRateFormDrawer, openVendorRateFormDrawer };
};
