import { kgToLb, lbToKg, QuoteItem, WeightUnit } from '@/utils/freight';
import { Col, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import _ from 'lodash';

export interface ShipmentItemsAggregatorProps {
  items: QuoteItem[];
}

const ShipmentItemsAggregator = ({ items }: ShipmentItemsAggregatorProps) => {
  const totalUnits = _.sum(items?.map((item: QuoteItem) => item?.units));

  const totalImperialWeight = _.sum(
    items?.map((item: QuoteItem) => {
      if (item?.weight_unit === WeightUnit.KG) {
        return kgToLb(item?.total_weight);
      }
      return item?.total_weight;
    }),
  );
  const totalMetricWeight = lbToKg(totalImperialWeight, true);

  const totalCartonQty = _.sum(items?.map((item: QuoteItem) => item?.pieces));

  return (
    <>
      <Row justify="start" gutter={16} align="middle">
        <Col>
          <Text>Total Units {totalUnits}</Text>
        </Col>
        <Col>
          <Text>Total Pieces {totalCartonQty}</Text>
        </Col>
        <Col>
          <Text>
            Weight {totalImperialWeight?.toFixed(2) ?? '-'} lbs /{' '}
            {totalMetricWeight?.toFixed(2) ?? '-'} kg
          </Text>
        </Col>
      </Row>
    </>
  );
};

export default ShipmentItemsAggregator;
