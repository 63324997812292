import { Space, Typography } from 'antd';
import ColorTags from '../../components/ColorTags';
import { ShipmentInterface } from '../Interfaces/ShipmentInterface';
import { CopyButton } from '@/components/CopyButton';
import InsuranceIcon from '@/components/Insurance/InsuranceIcon';
// import { TARGET_TYPE_CFS_SHIPMENT } from '@/components/Tasks/Interfaces/ActionInterface';
// import TaskOverdue from '@/components/Tasks/Render/TaskOverdue';
import TroubleStatusIcon from '@/components/TroubleStatuses/TroubleStatusIcon';
import { useApp } from '@/utils/useapp';
import { copyToClipboard } from '@/utils/clipboard';

const ShipmentIdColumn: React.FC<{
  shipment: ShipmentInterface;
  handleShow: (record: any) => void;
  fetchData?: () => void;
}> = ({ shipment, handleShow, fetchData }) => {
  const app = useApp();

  const handleVendorShipmentClick = async () => {
    if (!shipment.vendor_id) {
      return;
    }

    const id = shipment.vendor_shipment_id;
    if (!id) {
      return;
    }

    copyToClipboard(id, 'Failed to copy vendor shipment id:');

    try {
      const resp = await app.service.get(`cfs/vendors/${shipment.vendor_id}`);
      const vendor = resp.data;
      if (!vendor.quick_link) {
        return;
      }

      const url =
        vendor.quick_link.includes('$') && vendor.use_shipment_id
          ? vendor.quick_link.replace('$', id)
          : vendor.quick_link;

      window.open(url, '_blank');
    } catch (e) {
      console.error('Failed to open vendor tracking:', e);
    }
  };

  const handleCarrierShipmentClick = async () => {
    if (!shipment.carrier_id) {
      return;
    }
    const id = shipment.pro_number;
    if (!id) {
      return;
    }

    copyToClipboard(id, 'Failed to copy pro number:');

    try {
      const resp = await app.service.get(`cfs/carriers/${shipment.carrier_id}`);
      const carrier = resp.data;
      if (!carrier.quick_link) {
        return;
      }

      const url =
        carrier.quick_link.includes('$') && carrier.use_shipment_id
          ? carrier.quick_link.replace('$', id)
          : carrier.quick_link;

      window.open(url, '_blank');
    } catch (e) {
      console.error('Failed to open carrier tracking:', e);
    }
  };

  return (
    <div>
      <Space wrap>
        <a onClick={() => handleShow(shipment)}>{shipment.uid}</a>
        {<CopyButton value={shipment.uid} />}
        {/* <TaskOverdue target={TARGET_TYPE_CFS_SHIPMENT} targetModel={shipment} /> */}
        <ColorTags
          tlId={shipment.id}
          tags={shipment.tags || []}
          onApply={() => fetchData && fetchData()}
        />
        <TroubleStatusIcon targetModel={shipment} />
      </Space>
      <div>
        <Space>
          {shipment.insurance && (
            <InsuranceIcon insurance={shipment.insurance} />
          )}
          {shipment.vendor_shipment_id && (
            <>
              <Typography.Link onClick={() => handleVendorShipmentClick()}>
                {shipment.vendor_shipment_id}
              </Typography.Link>
              {<CopyButton value={shipment.vendor_shipment_id} />}
            </>
          )}
        </Space>
      </div>
      <div>
        <Space>
          {shipment.pro_number && (
            <>
              <Typography.Link onClick={() => handleCarrierShipmentClick()}>
                {shipment.pro_number}
              </Typography.Link>
              {<CopyButton value={shipment.pro_number} />}
            </>
          )}
        </Space>
      </div>
    </div>
  );
};

export default ShipmentIdColumn;
