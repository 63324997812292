import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Popconfirm,
  Popover,
  Select,
  Space,
  Table,
  Tooltip,
  message,
} from 'antd';
import {
  TransactionOutlined,
  EyeOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { WarningOutlined } from '@ant-design/icons';

import { ColumnsType } from 'antd/es/table';
import { ContainerTypeSelect } from '@/components/ContainerTypeSelect';
import { Weight } from './Weight';
import { sumBy, has, get, set } from 'lodash';
import { validateContainerNumber } from '../index';
import { DGAttribute } from './Attribute/DG';
import { TContainer } from '@/types';
import { TagBadge } from '@/pages/orders/components/OrderIdColumn';
import { SOCForm } from './SOCForm';
import { LOADING_TYPE, STAY_IN_WAREHOUSE, TBD } from './data';
import { useApp } from '@/utils/useapp';

const DiffTooltip = ({ isDiffWhs, isDiffIR }: any) => {
  if (!isDiffWhs && !isDiffIR) {
    return <></>;
  }

  const tooltipTitle = useMemo(() => {
    if (isDiffIR && isDiffWhs) {
      return 'The city and intermodal region of SR and the container are different. Please double check.';
    } else if (isDiffIR) {
      return 'The intermodal region of SR and the container are different. Please double check.';
    } else {
      return 'The city of SR and the container are different. Please double check.';
    }
  }, [isDiffIR, isDiffWhs]);

  return (
    <>
      {(isDiffWhs || isDiffIR) && (
        <Tooltip title={`${tooltipTitle}`}>
          <span className="text-danger ml-xs">
            <WarningOutlined />
          </span>
        </Tooltip>
      )}
    </>
  );
};

export const Attributes: React.FC<{ container?: any; showNone?: boolean }> = ({
  container,
  showNone = true,
}) => {
  const list = [];
  let soc, dg, ow, reefer, md, bd, tl, ot, urgent, diversion, oog;

  const c = container || {};

  if (c.is_soc) {
    soc = true;
  }
  if (c.is_dg) {
    dg = true;
  }
  if (c.is_overweight) {
    ow = true;
  }
  if (c.is_reefer) {
    reefer = true;
  }

  if (c.is_multi_deliveries) {
    md = true;
  }

  if (c.is_bonded) {
    bd = true;
  }

  if (c.is_diversion) {
    diversion = true;
  }

  if (c.urgent) {
    urgent = true;
  }

  if (c.is_arrival_on_time) {
    ot = true;
  }

  if (c.transload) {
    tl = true;
  }

  if (c.is_out_of_gauge) {
    oog = true;
  }

  urgent && list.push(<TagBadge key="ug" text="Urgent" />);
  soc && list.push(<TagBadge key="soc" text="SOC" />);
  dg && list.push(<TagBadge key="dg" text="DG" />);
  ow && list.push(<TagBadge key="ow" text="OW" />);
  bd && list.push(<TagBadge key="bd" text="BD" />);
  reefer && list.push(<TagBadge key="reefer" text="REFFER" />);
  diversion && list.push(<TagBadge key="diversion" text="Diversion" />);
  ot && list.push(<TagBadge key="ot" text="On Time" />);
  md && list.push(<TagBadge key="md" text="MD" />);
  tl && list.push(<TagBadge key="td" text="TL" />);
  oog && list.push(<TagBadge key="oog" text="OOG" />);
  //TODO
  // exam &&
  //   list.push(
  //     <Tag key="exam" color="processing">
  //       EXAM
  //     </Tag>,
  //   );

  return (
    <Space
      direction="horizontal"
      size="small"
      style={{
        gap: '4px !important',
      }}
      wrap
    >
      {list.length == 0 && showNone ? (
        <TagBadge key="td" text="Add Attributes" />
      ) : (
        list
      )}
    </Space>
  );
};

export const ContainerTable: React.FC<{
  containers: any[];
  onChange?: (value: any) => void;
  onSaved?: (containers: any) => void;
  matchCurrentRate: (
    id: number,
    rate_id: string,
    index: number,
  ) => Promise<void>;
  handleOpenSellRate: (container: TContainer) => void;
  handleOpenBuyRate: (buyRateId: string) => void;
  containerRemoveableOnEdit?: boolean;
}> = ({
  containers,
  onChange,
  matchCurrentRate,
  handleOpenSellRate,
  handleOpenBuyRate,
  containerRemoveableOnEdit = true,
}) => {
  const [commodities, setCommodities] = useState<any[]>([]);
  const app = useApp();
  useEffect(() => {
    (async () => {
      try {
        const { data } = await app.service.get('commodities');
        setCommodities(data);
      } catch (err: any) {
        message.error(err.data?.message || err.data?.error);
      }
    })();
  }, []);

  const handleRemove = (index: number) => {
    const newContainers = [...containers];
    newContainers.splice(index, 1);
    if (onChange) {
      onChange(newContainers);
    }
  };

  const toBuyRate = (record: any) => {
    const irId = record.final_port_id || 0;
    const toCityId = record.warehouse?.city_id || 0;

    if (!toCityId || !irId) {
      return message.warning('Missing city or intermodalReigon');
    }

    window.open(
      `/rates/buyRates?ir=${irId}&toCity=${toCityId}&cntrId=${record.id}`,
      '_blank',
    );
  };

  const RenderSoc = ({
    getFieldValue,
    setFieldValue,
    index,
  }: // open,
  {
    getFieldValue: any;
    setFieldValue: any;
    index: number;
  }) => {
    const namePath = ['containers', index, 'is_soc'];
    const isSelected = getFieldValue(namePath);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
      setIsModalOpen(true);
    };

    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const onClick = () => {
      if (namePath[2] == 'is_soc') {
        showModal();
        return;
      }

      // if (namePath[2] == 'is_soc' && getFieldValue(namePath) == true) {
      //   setFieldValue([namePath[0], namePath[1], 'soc_return_location_id'], 0);
      // }

      setFieldValue(namePath, !isSelected);
    };

    const onSelectSOCLocation = (depot: any, v: string | null) => {
      if (depot) {
        setFieldValue(
          [namePath[0], namePath[1], 'soc_return_location_id'],
          depot.id,
        );
        setFieldValue([namePath[0], namePath[1], 'soc_return_location'], depot);
        setFieldValue(namePath, true);
      } else if (v == STAY_IN_WAREHOUSE) {
        setFieldValue([namePath[0], namePath[1], 'soc_return_location_id'], -1);
        setFieldValue([namePath[0], namePath[1], 'soc_return_location'], null);
        setFieldValue(namePath, true);
      } else if (v == TBD) {
        setFieldValue([namePath[0], namePath[1], 'soc_return_location_id'], 0);
        setFieldValue([namePath[0], namePath[1], 'soc_return_location'], null);
        setFieldValue(namePath, true);
      } else {
        setFieldValue(
          [namePath[0], namePath[1], 'soc_return_location_id'],
          null,
        );
        setFieldValue([namePath[0], namePath[1], 'soc_return_location'], null);
        setFieldValue(namePath, false);
      }
      // setIsModalOpen(false);
    };

    return (
      <>
        <Form.Item
          className="mb0"
          label="SOC"
          name={['containers', index, 'is_soc']}
          valuePropName="checked"
        >
          <>
            <Checkbox
              onClick={onClick}
              checked={getFieldValue(['containers', index, 'is_soc'])}
            />
            <Modal
              title={'SOC Return Location'}
              open={isModalOpen}
              // onOk={handleOk}
              onCancel={handleCancel}
              footer={null}
            >
              <SOCForm
                onSelectSOCLocation={onSelectSOCLocation}
                selected={getFieldValue([
                  'containers',
                  index,
                  'soc_return_location_id',
                ])}
                socReturnLocation={getFieldValue([
                  'containers',
                  index,
                  'soc_return_location',
                ])}
              />
            </Modal>
          </>
        </Form.Item>
        <Form.Item
          hidden
          name={['containers', index, 'soc_return_location_id']}
        >
          <Input />
        </Form.Item>
      </>
    );
  };

  const defaultColumns: ColumnsType<any> = useMemo(
    () => [
      {
        title: 'No.',
        key: 'number',
        dataIndex: 'number',
        width: 120,
        fixed: true,
        render: (text, record, index) => (
          <Form.Item
            className="mb0"
            name={['containers', index, 'number']}
            rules={[
              {
                required: true,
                message: 'required',
              },
              () => ({
                validator(_, value) {
                  if (!value || validateContainerNumber(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'Must be a unique alpha-numeric combination of seven numbers and four letters.',
                    ),
                  );
                },
              }),
            ]}
          >
            <Input size="small" />
          </Form.Item>
        ),
      },
      {
        title: 'Type',
        key: 'type',
        dataIndex: 'type',
        width: 80,
        render: (text, record, index) => (
          <Form.Item
            className="mb0"
            name={['containers', index, 'type']}
            rules={[{ required: true, message: 'required' }]}
          >
            <ContainerTypeSelect size="small" getValueForOption="code" />
          </Form.Item>
        ),
      },
      {
        title: 'Seal No.',
        key: 'seal_number',
        dataIndex: 'seal_number',
        width: 120,
        render: (text, record, index) => (
          <Form.Item
            className="mb0"
            name={['containers', index, 'seal_number']}
          >
            <Input size="small" />
          </Form.Item>
        ),
      },
      {
        title: 'Package',
        key: 'package',
        dataIndex: 'package',
        width: 100,
        render: (text, record, index) => (
          <Form.Item
            className="mb0"
            name={['containers', index, 'package']}
            rules={[{ required: true, message: 'required' }]}
          >
            <Input size="small" />
          </Form.Item>
        ),
      },
      {
        title: 'Weight',
        key: 'weight',
        dataIndex: 'weight',
        width: 220,
        render: (text, record, index) => (
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldValue }) => (
              <Form.Item
                className="mb0"
                name={['containers', index, 'weight']}
                rules={[
                  {
                    validator: (_: any, value: number) => {
                      if (value > 0) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Required'));
                    },
                  },
                ]}
              >
                <Weight
                  size="small"
                  weight={getFieldValue(['containers', index, 'weight'])}
                  onChange={(value) => {
                    setFieldValue(['containers', index, 'weight'], value);
                    setFieldValue(
                      'gross_weight',
                      (
                        sumBy(
                          getFieldValue('containers').filter(
                            (v: any, i: any) => i !== index,
                          ),
                          (c: any) => parseFloat(c.weight || 0),
                        ) + +value
                      ).toFixed(2),
                    );
                  }}
                />
              </Form.Item>
            )}
          </Form.Item>
        ),
      },
      {
        title: 'Sell Rate',
        key: 'sell_rate',
        dataIndex: 'sell_rate_id',
        width: 150,
        render: (text, record, index) => {
          let isDifferentWhs = false;
          let isDiffIR = false;

          if (record.sell_rate) {
            const sellRateCityId = get(record, 'sell_rate.to_city_id');
            const sellRateIRId = get(record, 'sell_rate.intermodal_region_id');
            const finalPortId = record.final_port_id;

            isDiffIR = sellRateIRId != finalPortId ? true : false;

            if (record.is_multi_deliveries) {
              const warehouses = record.multiple_deliveries?.map(
                (delivery) => delivery?.warehouse,
              );
              isDifferentWhs =
                sellRateCityId &&
                (warehouses?.length == 0 ||
                  !warehouses?.find(
                    (warehouse: any) => warehouse.city_id == sellRateCityId,
                  ))
                  ? true
                  : false;
            } else {
              const whsCityId = get(record, 'warehouse.city_id');
              isDifferentWhs = sellRateCityId != whsCityId ? true : false;
            }
          }

          return (
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldValue }) => (
                <Form.Item
                  className="mb0"
                  name={['containers', index, 'sell_rate_id']}
                >
                  <Input.Group compact>
                    <Input
                      size="small"
                      style={{ width: 'calc(100% - 80px)' }}
                      onChange={(e) =>
                        setFieldValue(
                          ['containers', index, 'sell_rate_id'],
                          e.target.value,
                        )
                      }
                      value={getFieldValue([
                        'containers',
                        index,
                        'sell_rate_id',
                      ])}
                    />
                    <Tooltip title="Match Current Rate">
                      <Popconfirm
                        placement="topLeft"
                        title={'Are you sure?'}
                        onConfirm={() =>
                          matchCurrentRate(
                            record.id,
                            getFieldValue([
                              'containers',
                              index,
                              'sell_rate_id',
                            ]),
                            index,
                          )
                        }
                        okText="Confirm"
                        cancelText="No"
                      >
                        <Button size="small" icon={<TransactionOutlined />} />
                      </Popconfirm>
                    </Tooltip>
                    <Tooltip title="View Rate">
                      <Button
                        size="small"
                        onClick={() =>
                          handleOpenSellRate(
                            getFieldValue(['containers', index]),
                          )
                        }
                        icon={<EyeOutlined />}
                      />
                    </Tooltip>
                    <DiffTooltip
                      isDiffWhs={isDifferentWhs}
                      isDiffIR={isDiffIR}
                    />
                  </Input.Group>
                </Form.Item>
              )}
            </Form.Item>
          );
        },
      },
      {
        title: 'Buy Rate',
        key: 'buy_rate',
        dataIndex: 'buy_rate_id',
        width: 120,
        render: (text, record) => (
          <Input.Group compact>
            <Input
              size="small"
              style={{ width: 'calc(100% - 80px)' }}
              disabled
              value={record.buy_rate?.uid || ''}
            />
            <Tooltip title="View Rate">
              <Button
                size="small"
                onClick={() => handleOpenBuyRate(record.buy_rate?.uid)}
                icon={<EyeOutlined />}
              />
            </Tooltip>
            <Tooltip title="Search Route">
              <Button
                // disabled={!record.buy_rate?.uid}
                size="small"
                onClick={() => toBuyRate(record)}
                icon={<SearchOutlined />}
              />
            </Tooltip>
          </Input.Group>
        ),
      },
      // {
      //   title: 'Pickup No.',
      //   key: 'pickup_number',
      //   dataIndex: 'pickup_number',
      //   width: 120,
      //   render: (text, record, index) => (
      //     <Form.Item
      //       className="mb0"
      //       name={['containers', index, 'pickup_number']}>
      //       <Input size="small" />
      //     </Form.Item>
      //   ),
      // },
      // {
      //   title: 'L.F.D',
      //   key: 'lfd',
      //   dataIndex: 'lfd',
      //   width: 120,
      //   render: (text, record, index) => (
      //     <Form.Item noStyle shouldUpdate>
      //       {({ getFieldValue, setFieldsValue }) => (
      //         <Form.Item className="mb0" name={['containers', index, 'lfd']}>
      //           <div>
      //             <DatePicker
      //               size="small"
      //               value={
      //                 getFieldValue(['containers', index, 'lfd'])
      //                   ? moment(getFieldValue(['containers', index, 'lfd']))
      //                   : null
      //               }
      //               onChange={(date, dateString) => {
      //                 const newContainers = [...getFieldValue('containers')];
      //                 newContainers[index].lfd = dateString;
      //                 setFieldsValue({
      //                   containers: newContainers,
      //                 });
      //               }}
      //             />
      //           </div>
      //         </Form.Item>
      //       )}
      //     </Form.Item>
      //   ),
      // },
      {
        title: 'REF#',
        key: 'ref',
        dataIndex: 'ref',
        width: 120,
        render: (text, record, index) => (
          <Form.Item
            className="mb0"
            name={['containers', index, 'delivery_reference']}
          >
            <Input size="small" />
          </Form.Item>
        ),
      },
      {
        title: 'Commodity',
        key: 'commodity',
        dataIndex: 'commodity',
        width: 120,
        render: (text, record, index) => {
          return (
            <>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue, setFieldValue }) => {
                  return (
                    <Form.Item
                      className="mb0"
                      name={['containers', index, 'commodity_values']}
                      rules={[
                        {
                          message:
                            'Name Length must be less than 60 characters',
                          validator: (_, value: string[]) => {
                            return value.some((v) => {
                              return v.length > 60;
                            })
                              ? Promise.reject(
                                  'Length must be less than 60 characters',
                                )
                              : Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Select
                        size="small"
                        mode="tags"
                        // defaultValue={record.commodity_values || []}
                        style={{ width: '100%' }}
                        placeholder="Add commodity"
                        options={commodities?.map((c) => ({
                          label: c.name,
                          value: c.name,
                        }))}
                        onChange={(value) => {
                          setFieldValue(
                            ['containers', index, 'commodity'],
                            value,
                          );
                          setFieldValue(
                            ['containers', index, 'commodity'],
                            value,
                          );
                        }}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </>
          );
        },
      },
      {
        title: 'Loading Type',
        key: 'loading_type',
        dataIndex: 'loading_type',
        width: 120,
        render: (text, record, index) => (
          <Form.Item
            className="mb0"
            name={['containers', index, 'loading_type']}
          >
            <Select
              size="small"
              value={record.loading_type?.name || ''}
              options={Object.entries(LOADING_TYPE).map(([key, value]) => ({
                label: value,
                value: +key,
              }))}
            />
          </Form.Item>
        ),
      },
      {
        title: 'Attributes',
        key: 'checkboxes',
        dataIndex: 'checkboxes',
        align: 'center',
        width: 180,
        render: (text, record, index) => (
          <Popover
            placement="top"
            content={
              <Space direction="horizontal">
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue, setFieldsValue }) => (
                    <Space direction="vertical">
                      <Form.Item
                        className="mb0"
                        label="Urgent"
                        name={['containers', index, 'urgent']}
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>

                      <Form.Item
                        className="mb0"
                        label="DG"
                        name={['containers', index, 'is_dg']}
                        valuePropName="checked"
                      >
                        <DGAttribute
                          container={getFieldValue(['containers', index])}
                          onChange={(container: any) => {
                            const newContainers = [
                              ...getFieldValue('containers'),
                            ];
                            newContainers[index] = container;
                            setFieldsValue({
                              containers: newContainers,
                            });
                          }}
                        />
                      </Form.Item>
                      <Form.Item shouldUpdate noStyle>
                        {({ setFieldValue, getFieldValue }) => {
                          return (
                            <RenderSoc
                              setFieldValue={setFieldValue}
                              getFieldValue={getFieldValue}
                              index={index}
                            />
                          );
                        }}
                      </Form.Item>
                      <Form.Item
                        className="mb0"
                        label="OW"
                        name={['containers', index, 'is_overweight']}
                        valuePropName="checked"
                      >
                        <Checkbox disabled />
                      </Form.Item>
                      <Form.Item
                        className="mb0"
                        label="REEFER"
                        name={['containers', index, 'is_reefer']}
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                      <Form.Item
                        className="mb0"
                        label="Bonded"
                        name={['containers', index, 'is_bonded']}
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                      <Form.Item
                        className="mb0"
                        label="On time"
                        name={['containers', index, 'is_arrival_on_time']}
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                      <Form.Item
                        className="mb0"
                        label="Diversion"
                        name={['containers', index, 'is_diversion']}
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                      <Form.Item
                        className="mb0"
                        label="Multiple Deliveries"
                        name={['containers', index, 'is_multi_deliveries']}
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                      <Form.Item
                        className="mb0"
                        label="Transload"
                        name={['containers', index, 'transload']}
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                      <Form.Item
                        className="mb0"
                        label="OOG"
                        name={['containers', index, 'is_out_of_gauge']}
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                    </Space>
                  )}
                </Form.Item>
              </Space>
            }
          >
            <div>
              <Attributes container={record} />
            </div>
          </Popover>
        ),
      },
      {
        title: 'Action',
        key: 'action',
        dataIndex: 'action',
        width: 80,
        render: (text, record: any, index) => (
          <>
            {(!record.id || containerRemoveableOnEdit) && (
              <Button
                size="small"
                onClick={() => handleRemove(index)}
                danger
                ghost
              >
                Remove
              </Button>
            )}
          </>
        ),
      },
    ],
    [commodities],
  );

  const columns: ColumnsType<any> = React.useMemo(() => {
    const _columns: ColumnsType<any> = defaultColumns;

    const refIndex = _columns.findIndex((_c) => _c.key == 'ref');

    if (containers?.find((c) => has(c, 'custom_properties.module_type'))) {
      _columns.splice(refIndex + 1, 0, {
        title: 'Module Type',
        key: 'module_type',
        dataIndex: 'module_type',
        width: 120,
        render: (text, record, index) => (
          <Form.Item
            className="mb0"
            label=""
            name={['containers', index, 'custom_properties', 'module_type']}
          >
            <Input size="small" />
          </Form.Item>
        ),
      });
    }

    if (containers?.find((c) => has(c, 'custom_properties.pallet_qty'))) {
      const moduleTypeIndex = _columns.findIndex(
        (_c) => _c.key == 'module_type',
      );

      _columns.splice((moduleTypeIndex || refIndex) + 1, 0, {
        title: 'Pallet Qty',
        key: 'pallet_qty',
        dataIndex: 'pallet_qty',
        width: 80,
        render: (text, record, index) => (
          <Form.Item
            className="mb0"
            label=""
            name={['containers', index, 'custom_properties', 'pallet_qty']}
          >
            <Input type="number" size="small" />
          </Form.Item>
        ),
      });
    }

    return _columns;
  }, [containers]);

  return (
    <>
      <Table
        size="small"
        columns={columns}
        dataSource={containers}
        pagination={false}
        scroll={{ x: 2000, y: 400 }}
      />
    </>
  );
};
