import React, { useMemo } from 'react';
import { RenderSpecificReminds } from './RenderSpecificReminds';
import { RenderReminds } from './RenderReminds';
import { chain, reduce } from 'lodash';

interface TProps {
  containers: any;
  charges: any;
  reminds?: { [key: string]: Array<string> } | Array<string>;
  showExtraCost?: boolean;
  onUpdated?: () => void;
}

export const InvoiceReminds: React.FC<TProps> = ({
  containers,
  charges,
  reminds,
  showExtraCost = false,
  onUpdated,
}) => {
  const containersInCharges = useMemo(() => {
    const containerNumbers = chain(charges)
      .reduce(
        (result, charge) =>
          charge.containerNumber && result?.concat(charge?.containerNumber),
        [],
      )
      .uniq()
      .value();

    return reduce(
      containerNumbers,
      (result, number) => {
        const container = containers.find((c: any) => c.number == number);
        if (container) {
          result.push(container);
        }
        return result;
      },
      [],
    );
  }, [containers, charges]);

  const showNumber = useMemo(
    () => (containersInCharges.length > 1 ? true : false),
    [containersInCharges],
  );

  return (
    <>
      {containersInCharges?.map((c) => {
        if (reminds[c?.number]) {
          return (
            <RenderSpecificReminds
              container={c}
              showNumber={showNumber}
              reminds={reminds[c?.number]}
            />
          );
        } else {
          return (
            <RenderReminds
              container={c}
              showNumber={showNumber}
              showExtraCost={showExtraCost}
              charges={charges.filter(
                (charge) => charge.containerNumber == c?.number,
              )}
            />
          );
        }
      })}
    </>
  );
};
