import React, { useState, useEffect } from 'react';
import { Button, Form, Input, message, Modal, Popconfirm, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useApp } from '@/utils/useapp';
import { SnashotRateModal } from './SnapshotRate/SnashotRateModal';
import {
  BuyRateDrawer,
  BUYRATE_UID_PREFIX,
} from '@/pages/rates/buyRates/components/buyRateDrawer';
import { isBuyRate, isSellRate } from './utils';

export const SyncRateModal: React.FC<{
  order: any;
  open: boolean;
  onCancel: () => void;
  updateOrder: (order: any) => void;
}> = ({ order, open, onCancel, updateOrder }) => {
  const app = useApp();

  const [openSnapshotRate, setOpenSnapshot] = useState<boolean>(false);
  const [openBuyRate, setOpenBuyRate] = useState<boolean>(false);
  const [buyRateId, setBuyRateId] = React.useState(0);
  const [shadow, setShadow] = React.useState(null);
  const [form] = useForm();

  const [loading, setLoading] = useState<boolean>(false);

  const handleSyncRate = async (id: number, rate_id: number) => {
    setLoading(true);
    try {
      const resp = await app.service.put(`orders/${id}/syncRate`, {
        data: {
          sell_rate_id: rate_id,
        },
      });
      setShadow(resp.data);
      updateOrder(id);
    } catch (err: any) {
      message.error(err?.data?.message ?? 'error');
    } finally {
      setLoading(false);
    }
  };

  // const isBuyRate = (rateId: string) => {
  //   return new RegExp('^' + BUYRATE_UID_PREFIX + '\\d+').test(rateId);
  // };
  // const isSellRate = (rateId: string) => {
  //   return new RegExp('^' + SELLRATE_UID_PREFIX + '\\d+').test(rateId);
  // };

  const handleCloseBuyRate = () => {
    setOpenBuyRate(false);
  };

  const handleSaveBuyRate = () => {
    handleCloseBuyRate();
  };

  const handleOpenRate = (rateId: string) => {
    if (isBuyRate(rateId)) {
      const _rateId = Number(rateId.replace(BUYRATE_UID_PREFIX, ''));
      setOpenBuyRate(true);
      setBuyRateId(_rateId);
    } else if (isSellRate(rateId)) {
      setOpenSnapshot(true);
    }
  };

  const handleSave = async () => {
    const data = await form.validateFields();
    setLoading(true);
    const formValue = Object.assign({}, data);
    try {
      const resp = await app.service.patch(`orders/${order.id}/updateRates`, {
        data: formValue,
      });
      updateOrder(order.id);
      setShadow(resp.data);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open && order) {
      form.setFieldsValue({
        id: order.id,
        sell_rate_id: order.sell_rate_id,
        buy_rate_id: order.buy_rate_id,
      });
      setShadow(order);
    } else {
      form.resetFields();
    }
  }, [order, open]);

  return (
    <>
      {open && (
        <Modal title="Sync Rate" open={open} onCancel={onCancel} footer={false}>
          <Form form={form} onFinish={handleSave} layout="vertical">
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <Form.Item
                  label={
                    <Space>
                      Sell Rate ID
                      {getFieldValue('sell_rate_id') && getFieldValue('id') && (
                        <>
                          <Button
                            type="link"
                            size="small"
                            ghost
                            disabled={loading}
                            onClick={() => setOpenSnapshot(true)}>
                            View Rate
                          </Button>
                        </>
                      )}
                      {getFieldValue('sell_rate_id') && getFieldValue('id') && (
                        <Popconfirm
                          title={'Confirm'}
                          onConfirm={() =>
                            handleSyncRate(
                              getFieldValue('id'),
                              getFieldValue('sell_rate_id'),
                            )
                          }>
                          <Button
                            disabled={loading}
                            type="link"
                            size="small"
                            ghost>
                            Sync Rate
                          </Button>
                        </Popconfirm>
                      )}
                    </Space>
                  }
                  name="sell_rate_id"
                  rules={[
                    { required: false, message: 'Sell Rate ID is required' },
                  ]}>
                  <Input placeholder="Enter sell rate id" />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <Form.Item
                  label={
                    <Space>
                      Buy Rate ID
                      {getFieldValue('buy_rate_id') && (
                        <>
                          <Button
                            type="link"
                            size="small"
                            ghost
                            disabled={loading}
                            onClick={() =>
                              handleOpenRate(getFieldValue('buy_rate_id'))
                            }>
                            View Rate
                          </Button>
                        </>
                      )}
                    </Space>
                  }
                  name="buy_rate_id">
                  <Input placeholder="Enter buy rate id" />
                </Form.Item>
              )}
            </Form.Item>
            <Button type="primary" htmlType="submit" disabled={loading}>
              Save
            </Button>
          </Form>
        </Modal>
      )}

      {openSnapshotRate && shadow && (
        <SnashotRateModal
          snapshotableId={order.id}
          snapshotableType="order"
          open={openSnapshotRate}
          onClose={() => {
            setOpenSnapshot(false);
          }}
        />
      )}

      {openBuyRate && buyRateId && (
        <BuyRateDrawer
          isNew={false}
          id={buyRateId}
          open={openBuyRate}
          onSaved={handleSaveBuyRate}
          onClose={handleCloseBuyRate}
        />
      )}
    </>
  );
};
