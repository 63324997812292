import React from 'react';
import { Calendar, Button, Row, Col, Space } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import moment from 'moment';

export const CalendarHeader = ({ value, onChange, onDateRangeChange }) => {
  const handleDateRangeChange = (date) => {
    const startOfMonth = date.clone().startOf('month').startOf('week');
    const endOfMonth = date.clone().endOf('month').endOf('week');
    onDateRangeChange({
      start: startOfMonth.format('YYYY-MM-DD'),
      end: endOfMonth.format('YYYY-MM-DD'),
    });
  };

  const goToToday = () => {
    const now = moment();
    onChange(now);
    handleDateRangeChange(now);
  };

  const handlePreviousMonth = () => {
    const newValue = value.clone().subtract(1, 'month');
    onChange(newValue);
    handleDateRangeChange(newValue);
  };

  const handleNextMonth = () => {
    const newValue = value.clone().add(1, 'month');
    onChange(newValue);
    handleDateRangeChange(newValue);
  };

  React.useEffect(() => {
    handleDateRangeChange(value);
  }, [value]);

  return (
    <div style={{ padding: 10 }}>
      <Row justify="space-between" align="middle">
        <Col>
          <Space>
            <LeftOutlined onClick={handlePreviousMonth} />
            <div style={{ fontSize: 16 }}>{value.format('YYYY-MM')}</div>
            <RightOutlined onClick={handleNextMonth} />
            <Button size="small" type="link" onClick={goToToday}>
              Today
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  );
};
