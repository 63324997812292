import React, { useEffect, useMemo } from 'react';

import {
  Form,
  Popover,
  Typography,
  Space,
  message,
  TimePicker,
  Radio,
  Spin,
  Button,
  PopoverProps,
} from 'antd';
import { useApp } from '@/utils/useapp';
import moment, { Moment } from 'moment';
import { AVAILABLE_TIME_TYPE_MAP, AvailableTimeType } from './constant';
import { AvaliableTimeValueInterface } from './AvaliableTimeValueInterface';
import { useForm, useWatch } from 'antd/lib/form/Form';
import DefaultIcon from './DefaultIcon';
import TimeDetail from './TimeDetail';
import TimeText from './TimeText';
import { time } from 'echarts';

const TIME_FORMATE = 'HH:mm';

type TAvailableTime = {
  name: string;
  value: AvaliableTimeValueInterface;
  toSave: (value: AvaliableTimeValueInterface) => void;
  disabled?: boolean;
  isShowDetail?: boolean;
  saveText?: string;
} & PopoverProps;

const AvailableTime: React.FC<TAvailableTime> = ({
  name,
  value,
  disabled = false,
  isShowDetail = false,
  saveText = 'Save',
  toSave,
  ...props
}) => {
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const [form] = useForm();

  const isAnyTimeType = (type: string) => type == AvailableTimeType.ANYTIME;

  const handleChangeType = (e: any) => {
    const type = e.target.value;
    const value = isAnyTimeType(type) ? AvailableTimeType.ANYTIME : null;

    form.setFieldsValue({
      type,
      value,
    });
  };

  const momentTime = (value?: Moment) =>
    value ? moment(value, TIME_FORMATE) : null;

  const handleSave = async () => {
    const values = form.getFieldsValue();
    await toSave(values);
    setOpen(false);
  };

  return (
    <Space>
      {disabled ? (
        <TimeText time={value?.value} />
      ) : (
        <Popover
          trigger={props.trigger ? props.trigger : 'click'}
          placement={props.placement ? props.placement : 'top'}
          onOpenChange={(open: boolean) => {
            setOpen(open);
          }}
          open={open}
          content={
            <div style={{ width: '14vw' }}>
              <Spin spinning={loading}>
                <Form
                  layout="vertical"
                  form={form}
                  initialValues={{
                    type: value?.type || AvailableTimeType.SPECIFIC_TIME,
                    value: value?.value || null,
                  }}
                >
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue, setFieldsValue }) => (
                      <Form.Item
                        name="type"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Radio.Group onChange={handleChangeType}>
                          {Object.keys(AVAILABLE_TIME_TYPE_MAP).map(
                            (key, index) => (
                              <Radio key={+index} value={key}>
                                {
                                  AVAILABLE_TIME_TYPE_MAP[
                                    (key as unknown) as keyof typeof AVAILABLE_TIME_TYPE_MAP
                                  ]
                                }
                              </Radio>
                            ),
                          )}
                        </Radio.Group>
                      </Form.Item>
                    )}
                  </Form.Item>
                  <Form.Item name="value" required noStyle></Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue, setFieldValue }) => {
                      if (getFieldValue('type') == AvailableTimeType.ANYTIME) {
                        return <></>;
                      }
                      return (
                        <>
                          <Form.Item
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            {getFieldValue('type') ==
                              AvailableTimeType.SPECIFIC_TIME && (
                              <>
                                <TimePicker
                                  allowClear
                                  format={TIME_FORMATE}
                                  showNow={true}
                                  style={{ width: '100%' }}
                                  value={
                                    getFieldValue('value')
                                      ? momentTime(getFieldValue('value'))
                                      : momentTime()
                                  }
                                  onChange={(value: any) => {
                                    if (!value) {
                                      setFieldValue('value', null);
                                      return;
                                    }

                                    setFieldValue(
                                      'value',
                                      moment(value).format(TIME_FORMATE),
                                    );
                                  }}
                                />
                              </>
                            )}
                            {getFieldValue('type') ==
                              AvailableTimeType.RANGE && (
                              <>
                                <TimePicker.RangePicker
                                  allowClear
                                  style={{ width: '100%' }}
                                  value={
                                    getFieldValue('value')
                                      ? [
                                          momentTime(
                                            getFieldValue('value')['start'],
                                          ),
                                          momentTime(
                                            getFieldValue('value')['end'],
                                          ),
                                        ]
                                      : [momentTime(), momentTime()]
                                  }
                                  onChange={(value: any) => {
                                    if (!value) {
                                      setFieldValue('value', null);
                                      return;
                                    }
                                    const startTime = moment(value[0]);
                                    const endTime = moment(value[1]);

                                    if (endTime.isBefore(startTime)) {
                                      setFieldValue('value', {
                                        start: startTime.format(TIME_FORMATE),
                                        end: startTime.format(TIME_FORMATE),
                                      });
                                    } else {
                                      setFieldValue('value', {
                                        start: startTime.format(TIME_FORMATE),
                                        end: endTime.format(TIME_FORMATE),
                                      });
                                    }
                                  }}
                                  format={TIME_FORMATE}
                                />
                              </>
                            )}
                          </Form.Item>
                        </>
                      );
                    }}
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    <div className="flex justify-end">
                      <Button
                        type="primary"
                        loading={loading}
                        onClick={handleSave}
                      >
                        {' '}
                        {saveText}
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </Spin>
            </div>
          }
        >
          <Typography.Link>
            {isShowDetail && <TimeDetail time={value?.value} />}
            {!isShowDetail && (
              <DefaultIcon className={value?.value ? '' : 'text-gray'} />
            )}
          </Typography.Link>
        </Popover>
      )}
    </Space>
  );
};

export default AvailableTime;
