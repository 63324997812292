import React, { useEffect, useMemo, useState } from 'react';
import { useApp } from '@/utils/useapp';
import { Drawer, Button, Space, message, Select, Tag, Alert } from 'antd';
import { get, has, trim, uniq, upperCase, trimEnd } from 'lodash';
import { useForm } from 'antd/es/form/Form';
import { BELONG_MODEL_WAREHOUSE } from '@/pages/setting/emailTemplate/components/data';
import { SendEmailWithTemplates } from '@/components/EmailForm/SendWithTemplate';
import { SendEmailUsingEditor } from '@/components/EmailForm/SendEmailUsingEditor';
import { TEMPLATE_TYPE_SPECIFICTABS } from '@/components/EmailForm/SendWithTemplate/data';
import moment from 'moment';
import { LIVE_OR_DROP_MAP } from '@/pages/dispatchs/components/data';
import { PresetDataComponent } from './PresetDataComponent';

export const SendDelPptDrawer: React.FC<{
  isPreDelAppt: boolean;
  warehouse: any;
  container: any;
  open: boolean;
  onClose: () => void;
  onSent: () => void;
  id?: any;
}> = ({ isPreDelAppt, open, warehouse, container, onClose, onSent, id }) => {
  LIVE_OR_DROP_MAP[0] = 'Live';
  const app = useApp();
  const admin = app.store.auth.account;

  const [loading, setLoading] = useState(true);
  const [form] = useForm();
  const [docs, setDocs] = useState<
    {
      id: number;
      file_name: string;
      custom_properties: {
        document_type: string;
      };
    }[]
  >([]);
  const [sending, setSending] = useState(false);
  const sendURI = useMemo(() => `warehouses/${warehouse.id}/sendDelApt`, [
    warehouse,
  ]);

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handleSent = () => {
    setSending(false);
    onSent();
  };

  const fetchDocuments = async () => {
    // http://drayeasy.local/api/admin/documents/order/10562?containerIds%5B0%5D=13851
    if (!container || !container.order_id) {
      return;
    }

    try {
      const resp = await app.service.get(
        `documents/order/${container.order_id}`,
        {
          params: { containerIds: [container.id] },
        },
      );
      setDocs(resp.data);
    } catch (e: any) {
      message.error(e.data.message);
    }
  };
  const initForm = async () => {
    const sendToList: string[] = [];
    let ccList: string[] = [admin?.email];
    warehouse?.contacts.map((c: any) => {
      if (!c.email) {
        return;
      }
      sendToList.push(c.email);
    });
    if (container) {
      const contactEmails = get(container, 'order.contact_emails', []);
      const operatorEmail = get(container, 'order.operator.email');
      const salesEmail = get(container, 'order.sales.email');
      const salesSupportEmail = get(container, 'order.sales_support.email');
      !sendToList.includes(operatorEmail) && ccList.push(operatorEmail);
      !sendToList.includes(salesEmail) && ccList.push(salesEmail);
      !sendToList.includes(salesSupportEmail) && ccList.push(salesSupportEmail);

      contactEmails?.length > 0 && (ccList = ccList.concat(contactEmails));
    }

    form.setFieldsValue({
      sendToList: uniq(sendToList),
      ccList: uniq(ccList).filter((c) => c),
      subject: toSubject(container ? [container] : []),
      body: toBody(container ? [container] : []),
      isPreDelAppt,
    });
  };

  const toSubject = (containers: any) => {
    if (!isPreDelAppt) {
      return '';
    }
    let subject = 'PRE-DEL APPT';

    const mblNumber = containers[0]?.order?.mbl_number || '';
    const customerReferenceNumber =
      containers[0]?.order?.customer_reference_number || '';

    const containerNumbers = containers.map((c: any) => c.number);
    // join container numbers with comma
    // if container numbers are more than 5 then show first 5 container numbers and add +{remaining count} more
    const containerNumbersString =
      containerNumbers.length > 5
        ? `${containerNumbers.slice(0, 5).join(', ')} +${
            containerNumbers.length - 5
          } more`
        : containerNumbers.join(', ');

    const finalPortNameArray = uniq(
      containers?.map((c) => get(c, 'final_port.name'))?.filter((c) => c),
    );

    const finalPortName =
      finalPortNameArray.length > 1
        ? `[${finalPortNameArray.join(', ')}]`
        : finalPortNameArray[0] || '-';

    const cityName = warehouse?.city?.full_name || '';
    const zipcode = warehouse?.zipcode || '';
    // eta is the earliest final_port_eta of all containers
    const eta = moment
      .min(containers.map((c: any) => moment(c.final_port_eta)))
      .format('MM/DD');

    subject += ` for MBL#: ${mblNumber}${
      customerReferenceNumber ? ' REF#: ' + customerReferenceNumber : ''
    } CNTR#: ${containerNumbersString} / ${finalPortName} - ${cityName}${
      zipcode ? ' ' + zipcode : ''
    } / ETA: ${eta}`;

    // if (containers?.length == 1) {
    //   subject += ` for ${containers[0].number} / ${mblNumber
    //     } / ${get(containers[0], 'final_port.name') ?? '-'}`;
    // } else if (containers?.length > 1) {
    //   const finalPortNames = uniq(
    //     containers?.map((c) => get(c, 'final_port.name'))?.filter((c) => c),
    //   );
    //   subject += ` for ${mblNumber} / ${finalPortNames.length > 1
    //       ? `[${finalPortNames.join(', ')}]`
    //       : finalPortNames[0]
    //     } `;
    // }

    // subject += ` - ${warehouse?.city?.full_name}`;
    // warehouse.zipcode && (subject += ` ${warehouse?.zipcode}`);

    // if (containers?.length == 1) {
    //   subject += ` / ETA: ${moment(containers[0].final_port_eta).format(
    //     'MM/DD',
    //   )}`;
    // }

    return subject;
  };

  const toBody = (containers: any) => {
    if (!isPreDelAppt) {
      return '';
    }

    const mblNumber = containers[0]?.order?.mbl_number || '';
    const customerReferenceNumber =
      containers[0]?.order?.customer_reference_number || '';

    const body = `<p>Hi Team,</p><div>This is to inform you that below shipment will be delivered to your warehouse soon. Please find the container information below</div>
    <p>MBL#: ${mblNumber} ${
      customerReferenceNumber ? `, REF#: ${customerReferenceNumber}` : ''
    }</p>
${initFormCntrInfo(containers)}
${
  warehouse?.pre_del_appt_sent_at
    ? '<p>Below is the information you have confirmed from the previous shipment.</p>'
    : '<p>We kindly request confirmation of the following details pertaining to your warehouse:</p>'
}
<ul>
<li>Facility Name: <strong>${warehouse?.name || ''}</strong></li>
<li>Facility Address: <strong>${warehouse?.address || ''} ${
      warehouse?.city?.name || ''
    }, ${warehouse?.city?.state || ''} ${warehouse?.zipcode || ''}, ${
      warehouse?.country || ''
    }</strong></li>
<li>Receiving Date & Hours: <strong>${warehouse.working_hour_from || ''} - ${
      warehouse.working_hour_to || ''
    }</strong></li>
<li>${
      warehouse.first_come_first_serve
        ? 'First Come First Serve: ' + warehouse.first_come_first_serve
        : 'Is it first come, first serve?'
    }</li>
<li>${
      warehouse.unload_time
        ? 'Uoload Time: ' + warehouse.unload_time + 'h'
        : 'How long will it take to unload a container?'
    }</li>
${
  warehouse.is_residential
    ? '<li>Please advise if you have the unloading tools to unload this container</li>'
    : ''
}
<li>${
      warehouse.special_request
        ? 'Special request: ' + warehouse.special_request.replace(/\n/g, '<br>')
        : 'Any special request?'
    }
<br /><strong>${
      warehouse.special_requirements
        ?.filter((s: any) => s.visible_on_email)
        .map((s: any) => s.name)
        .join('<br />') || ''
    }</strong>
</li>
</ul>
<p>We will confirm delivery schedule once container is available.</p>
<br/>
<br/>
<p>Please note: Although we consistently endeavor to ensure on time delivery, unforeseen circumstances may occasionally lead to delays or the inability to meet scheduled appointments. In these situations, we are unable to cover the costs related to any delays or missed deliveries unless approved by the shipper.</p>
`;
    return trim(body);
  };

  const initFormCntrInfo = (containers: any) => {
    if (containers?.length < 1) {
      return '';
    }

    let info = '';
    const containersInfo = containers.map((c: any) =>
      trim(
        `<p><strong> CNTR#:${c.number},  ${
          has(LIVE_OR_DROP_MAP, Number(c.live_or_drop))
            ? `${
                LIVE_OR_DROP_MAP[
                  (c.live_or_drop as unknown) as keyof typeof LIVE_OR_DROP_MAP
                ]
              } Unloading, `
            : 'Live Unloading, '
        }QTY: ${c.package || 'NA'} </strong></p>`,
      ),
    );

    info += containersInfo.join('');

    return info;
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    if (open) {
      initForm();
      fetchDocuments();
    }
  }, [open, container, isPreDelAppt]);

  return (
    <>
      <Drawer
        title={isPreDelAppt ? ' Send PRE-DEL APPT' : 'Send DEL-APT'}
        extra={
          <Space>
            <Button onClick={handleClose}>Close</Button>
            <Button
              disabled={loading || sending}
              type="primary"
              onClick={() => setSending(true)}
            >
              Send
            </Button>
          </Space>
        }
        placement={'right'}
        width={isPreDelAppt ? '60%' : '90%'}
        onClose={handleClose}
        destroyOnClose={true}
        open={open}
      >
        {isPreDelAppt ? (
          <SendEmailUsingEditor
            alertMessage={
              warehouse?.is_no_pre_del_needed ? (
                <>
                  <Alert message="No Pre Del Needed" type="warning" />
                </>
              ) : (
                <></>
              )
            }
            target={BELONG_MODEL_WAREHOUSE}
            targetId={warehouse.id}
            options={{
              isPreDelAppt: true,
              container_id: container?.id,
            }}
            docs={docs}
            sending={sending}
            onSending={(sending: boolean) => setSending(sending)}
            form={form}
            onCancelSend={() => setSending(false)}
            sendURI={sendURI}
            presetDataComponent={({
              handleClickPresetData,
            }: {
              handleClickPresetData: (e: any) => void;
            }) =>
              container && (
                <PresetDataComponent
                  container={container}
                  setSelectedContainers={(containers: any) => {
                    form.setFieldsValue({
                      subject: toSubject(containers),
                      body: toBody(containers),
                    });
                  }}
                />
              )
            }
            onSent={handleSent}
          />
        ) : (
          <SendEmailWithTemplates
            alertMessage={
              warehouse?.is_no_pre_del_needed ? (
                <>
                  <Alert message="No Pre Del Needed" type="warning" />
                </>
              ) : (
                <></>
              )
            }
            target={BELONG_MODEL_WAREHOUSE}
            type={TEMPLATE_TYPE_SPECIFICTABS}
            options={{
              isPreDelAppt: false,
            }}
            targetId={warehouse.id}
            sending={sending}
            onSending={(sending: boolean) => setSending(sending)}
            form={form}
            onCancelSend={() => setSending(false)}
            sendURI={sendURI}
            onSent={handleSent}
          />
        )}
      </Drawer>
    </>
  );
};
