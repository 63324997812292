import { BillingCodeSelect } from '@/components/BillingCodeSelect';
import {
  Button,
  Form,
  FormInstance,
  FormListFieldData,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Table,
  TableProps,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { PlusOutlined } from '@ant-design/icons';
import { getSum } from '@/utils/getSum';
import './index.less';

interface RatesTableProps extends TableProps<FormListFieldData> {
  loading: boolean;
  form: FormInstance;
  add: () => void;
  remove: (id: number | number[]) => void;
}

const Index = ({
  loading,
  dataSource,
  form,
  add,
  remove,
  ...rest
}: RatesTableProps) => {
  const generateRateColumn = ({
    dataSource,
    add,
    remove,
  }: {
    dataSource: any;
    add: () => void;
    remove: (index: number | number[]) => void;
  }): ColumnsType<FormListFieldData> => {
    return [
      {
        title: '',
        width: 100,
        children: [
          {
            title: 'Billing Code',
            dataIndex: 'code',
            width: 100,
            render: (text, record, index) => (
              <Form.Item noStyle shouldUpdate>
                {() => {
                  return (
                    <Form.Item name={[index, 'code']}>
                      <BillingCodeSelect
                        renderOption="code"
                        onSelect={(value) => {
                          form.setFieldValue(
                            ['rates', index, 'code'],
                            value.code,
                          );
                          form.setFieldValue(
                            ['rates', index, 'name'],
                            value.name,
                          );
                        }}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            ),
          },
        ],
      },
      {
        title: '',
        width: 200,
        children: [
          {
            title: 'Billing Name',
            dataIndex: 'name',
            width: 200,
            render: (text, record, index) => (
              <Form.Item noStyle shouldUpdate>
                {({ setFieldValue }) => (
                  <Form.Item>
                    <Input
                      value={form.getFieldValue(['rates', index, 'name'])}
                      onChange={(v) =>
                        setFieldValue(['rates', index, 'name'], v)
                      }
                    />
                  </Form.Item>
                )}
              </Form.Item>
            ),
          },
        ],
      },
      {
        title: '',
        width: 100,
        children: [
          {
            title: 'Unit',
            dataIndex: 'unit',
            width: 100,
            render: (text, record, index) => (
              <Form.Item noStyle shouldUpdate>
                {() => (
                  <Form.Item>
                    <Select
                      style={{ width: '100%' }}
                      value={form.getFieldValue(['rates', index, 'unit'])}
                      onSelect={(v: string) =>
                        form.setFieldValue(['rates', index, 'unit'], v)
                      }>
                      {['', 'CNTR', 'DAY', 'TIME'].map((item) => (
                        <Select.Option key={item} value={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Form.Item>
            ),
          },
        ],
      },
      {
        title: 'Buying Rate',
        width: 300,
        children: [
          {
            title: 'Rate',
            width: 100,
            render: (text, record, index) => (
              <Form.Item noStyle shouldUpdate>
                {() => {
                  return (
                    <Form.Item initialValue={0}>
                      <InputNumber
                        value={Number(
                          form.getFieldValue(['rates', index, 'buy_rate']),
                        )}
                        onChange={(v) => {
                          form.setFieldValue(
                            ['rates', index, 'buy_rate'],
                            Number(v),
                          );
                        }}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            ),
          },
          {
            title: 'Volume',
            width: 100,
            render: (text, record, index) => (
              <Form.Item noStyle shouldUpdate>
                {() => (
                  <Form.Item
                    {...dataSource[index]}
                    name={[dataSource[index].name, 'buy_volume']}
                    initialValue={0}>
                    <InputNumber />
                  </Form.Item>
                )}
              </Form.Item>
            ),
          },
          {
            title: 'Amount',
            width: 100,
            render: (text, record, index) => (
              <Form.Item noStyle shouldUpdate>
                {() => {
                  const result = Number(
                    form.getFieldValue(['rates', index, 'buy_rate']) *
                      Number(
                        form.getFieldValue(['rates', index, 'buy_volume']),
                      ),
                  );
                  return <span>{Number.isNaN(result) ? 0 : result}</span>;
                }}
              </Form.Item>
            ),
          },
        ],
      },
      {
        title: 'Selling Rate',
        width: 300,
        children: [
          {
            title: 'Rate',
            width: 100,
            // dataIndex: 'sell_rate',
            render: (text, record, index) => (
              <Form.Item noStyle shouldUpdate>
                {({ setFieldValue }) => (
                  <Form.Item initialValue={0}>
                    <InputNumber
                      value={Number(
                        form.getFieldValue(['rates', index, 'sell_rate']),
                      )}
                      onChange={(v) =>
                        setFieldValue(['rates', index, 'sell_rate'], Number(v))
                      }
                    />
                  </Form.Item>
                )}
              </Form.Item>
            ),
          },
          {
            title: 'Volume',
            width: 100,
            render: (text, record, index) => (
              <Form.Item noStyle shouldUpdate>
                {({ setFieldValue }) => (
                  <Form.Item initialValue={0}>
                    <InputNumber
                      value={Number(
                        form.getFieldValue(['rates', index, 'sell_volume']),
                      )}
                      onChange={(v) =>
                        setFieldValue(
                          ['rates', index, 'sell_volume'],
                          Number(v),
                        )
                      }
                    />
                  </Form.Item>
                )}
              </Form.Item>
            ),
          },
          {
            title: 'Amount',
            width: 100,
            render: (text, record, index) => (
              <Form.Item noStyle shouldUpdate>
                {() => {
                  const result =
                    Number(form.getFieldValue(['rates', index, 'sell_rate'])) *
                    Number(form.getFieldValue(['rates', index, 'sell_volume']));
                  return <span>{Number.isNaN(result) ? 0 : result}</span>;
                }}
              </Form.Item>
            ),
          },
        ],
      },
      {
        title: '',
        width: 100,
        children: [
          {
            title: 'profit',
            width: 100,
            render: (text, record, index) => {
              return (
                <Form.Item noStyle shouldUpdate>
                  {() => {
                    const result =
                      Number(
                        form.getFieldValue(['rates', index, 'sell_rate']),
                      ) *
                        Number(
                          form.getFieldValue(['rates', index, 'sell_volume']),
                        ) -
                      Number(form.getFieldValue(['rates', index, 'buy_rate'])) *
                        Number(
                          form.getFieldValue(['rates', index, 'buy_volume']),
                        );

                    return <span>{Number.isNaN(result) ? 0 : result}</span>;
                  }}
                </Form.Item>
              );
            },
          },
        ],
      },
      {
        title: (
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => add()}
          />
        ),
        width: 100,
        render: (text, record, index) => (
          <Popconfirm
            placement="top"
            title={'Are you sure to delete?'}
            onConfirm={() => remove(index)}
            okText="Yes"
            cancelText="No">
            <Button type="link">delete</Button>
          </Popconfirm>
        ),
      },
    ];
  };

  return (
    <Table
      {...rest}
      title={() => 'Rates'}
      className="spot-rates-drawer-table"
      loading={loading}
      pagination={false}
      size="small"
      columns={generateRateColumn({ add, remove, dataSource })}
      dataSource={dataSource}
      scroll={{
        x: 'auto',
        y: 400,
      }}
      summary={() => {
        return (
          <Table.Summary.Row>
            <Table.Summary.Cell index={0} colSpan={5}></Table.Summary.Cell>
            <Table.Summary.Cell index={5}>
              <Form.Item shouldUpdate noStyle>
                {() => {
                  const values = form.getFieldValue('rates');
                  const sum = getSum(
                    values?.map(
                      (item: any) =>
                        Number(item?.buy_rate) * Number(item?.buy_volume),
                    ),
                  );
                  return <>{Number.isNaN(sum) ? 0 : sum}</>;
                }}
              </Form.Item>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={6} colSpan={2}></Table.Summary.Cell>
            <Table.Summary.Cell index={8}>
              <Form.Item shouldUpdate noStyle>
                {() => {
                  const values = form.getFieldValue('rates');
                  const sum = getSum(
                    values?.map(
                      (item: any) =>
                        Number(item?.sell_rate) * Number(item?.sell_volume),
                    ),
                  );
                  return <>{Number.isNaN(sum) ? 0 : sum}</>;
                }}
              </Form.Item>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={9}>
              <Form.Item shouldUpdate noStyle>
                {() => {
                  const values = form.getFieldValue('rates');
                  const sum = getSum(
                    values?.map(
                      (item: any) =>
                        Number(item?.sell_rate) * Number(item?.sell_volume) -
                        Number(item?.buy_rate) * Number(item?.buy_volume),
                    ),
                  );

                  return <>{Number.isNaN(sum) ? 0 : sum}</>;
                }}
              </Form.Item>
            </Table.Summary.Cell>
          </Table.Summary.Row>
        );
      }}
    />
  );
};

export default Index;
