import { Tag } from 'antd';
import { ShipmentBusinessStatus } from '../constants';

export type ShipmentBusinessStatusTagProps = {
  business_status: ShipmentBusinessStatus;
};

export const ShipmentBusinessStatusTag = ({
  business_status,
}: ShipmentBusinessStatusTagProps) => {
  if (business_status === ShipmentBusinessStatus.DRAFT) {
    return <Tag color="default">DRAFT</Tag>;
  } else if (business_status === ShipmentBusinessStatus.CONFIRMING) {
    return <Tag color="yellow">Order reviewing</Tag>;
  } else if (business_status === ShipmentBusinessStatus.PENDING_PICKUP) {
    return <Tag color="blue">PENDING PICKUP</Tag>;
  } else if (business_status === ShipmentBusinessStatus.IN_TRANSIT) {
    return <Tag color="blue">IN TRANSIT</Tag>;
  } else if (business_status === ShipmentBusinessStatus.DELIVERED) {
    return <Tag color="green">DELIVERED</Tag>;
  } else if (business_status === ShipmentBusinessStatus.CANCELED) {
    return <Tag color="gray">CANCELED</Tag>;
  } else if (business_status === ShipmentBusinessStatus.OP_CLOSE) {
    return <Tag color="gray">OP CLOSED</Tag>;
  } else if (business_status === ShipmentBusinessStatus.REJECT) {
    return <Tag color="red">REJECT</Tag>;
  } else {
    return <Tag color="gray">UNKNOWN</Tag>;
  }
};
