import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { v4 as uuidv4 } from 'uuid';

function getAbsoluteHeight(el: HTMLElement | undefined): number {
  if (!el) {
    return 0;
  }
  const styles = window.getComputedStyle(el);
  const margin =
    parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom']);

  return Math.ceil(el.offsetHeight + margin);
}

export function useGetResizeTableHeight() {
  //设置撑高表格外部包裹元素的高度
  const [tableHeight, setTableHeight] = useState(500);
  const [id] = useState(uuidv4());

  useEffect(() => {
    handleGetTableHeight();
    //窗口resize防抖
    const debounced = debounce(handleGetTableHeight, 200);
    window.addEventListener('resize', debounced);
    //卸载组件时解除对resize的监听
    return () => window.removeEventListener('resize', debounced);
  }, []);

  const handleGetTableHeight = useCallback(() => {
    setTimeout(() => {
      const table = document.getElementById(id);
      const tableTop = table?.getBoundingClientRect().top ?? 0;
      const headerHeight = getAbsoluteHeight(
        table?.getElementsByClassName('ant-table-header')?.[0] as HTMLElement,
      );
      const paginationHeight = getAbsoluteHeight(
        table?.parentElement?.getElementsByClassName(
          'ant-table-pagination',
        )?.[0] as HTMLElement,
      );
      const summaryHeight = getAbsoluteHeight(
        table?.parentElement?.getElementsByClassName(
          'ant-table-summary',
        )?.[0] as HTMLElement,
      );
      const height =
        document.body.clientHeight -
        tableTop -
        headerHeight -
        paginationHeight -
        summaryHeight -
        24; //61-表头高度，56-分页组件高度，6-微调高度
      setTableHeight(height);
    });
  }, []);

  return [tableHeight, handleGetTableHeight, id] as const;
}
