import { Form, InputNumber, Radio } from 'antd';
import { FormInstance } from 'antd/es/form';
import React from 'react';
import { VendorSelect } from '@/components/VendorSelect';
import { IntermodalRegionSelect } from '@/components/IntermodalRegionSelect';
import { DROPZONE_TYPE_DRAYEASY, DROPZONE_TYPE_MAP } from '../constants';
import { CitySelect } from '@/components/CitySelect';

type TDropZoneForm = {
  form: FormInstance;
};

export const DropZoneForm: React.FC<TDropZoneForm> = ({ form }) => {
  const handleChange = async (event: any) => {
    const { name, value } = event.target as HTMLInputElement;
    await form.setFieldsValue({ [name]: value });
  };

  return (
    <div>
      <Form
        form={form}
        initialValues={{ type: DROPZONE_TYPE_DRAYEASY }}
        layout="vertical">
        <Form.Item name="type" label="Type" rules={[{ required: true }]}>
          <Radio.Group
            options={Object.keys(DROPZONE_TYPE_MAP).map((k) => ({
              label: DROPZONE_TYPE_MAP[k],
              value: Number(k),
            }))}
          />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            if (getFieldValue('type') == DROPZONE_TYPE_DRAYEASY) {
              return null;
            }

            return (
              <Form.Item
                name="vendor"
                label="Vendor"
                rules={[{ required: true }]}>
                <VendorSelect
                  withPossibleCharges
                  style={{ width: '100%' }}
                  selected={getFieldValue('vendor')}
                  onSelect={(id) => {
                    handleChange({
                      target: { name: 'vendor', value: id },
                    });
                  }}
                />
              </Form.Item>
            );
          }}
        </Form.Item>

        <Form.Item
          name="intermodal_region_id"
          label="IR"
          rules={[{ required: true }]}>
          <IntermodalRegionSelect />
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <Form.Item name="city" label="City" rules={[{ required: true }]}>
              <CitySelect
                selected={getFieldValue('city')}
                onSelect={(id) =>
                  handleChange({ target: { name: 'city', value: id } })
                }
              />
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item name="rate" label="Rate" rules={[{ required: true }]}>
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </div>
  );
};
