import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Col,
  Divider,
  Drawer,
  Empty,
  Input,
  message,
  Popconfirm,
  Row,
  Space,
  Tag,
} from 'antd';
import React, { useMemo, useState } from 'react';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { get } from 'lodash';
import { DrawerForm } from './components/DawerForm';
import { useForm } from 'antd/lib/form/Form';
import AutoResizeTable from '@/components/AutoResizeTable';
import { LogActivities } from '@/components/LogActivities';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  TIER_0,
  TIER_1,
  TIER_2,
  TIER_3,
  TIER_4,
  TIER_MAP,
} from './components/data';

const RenderTierRate: React.FC<{ labelKey: any; discount: any }> = ({
  labelKey,
  discount,
}) => {
  return (
    <Tag color="blue">
      {TIER_MAP[labelKey as unknown as keyof typeof TIER_MAP]}: {discount}%
    </Tag>
  );
};

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [search, setSearch] = useState<string>('');
  const [intermodalRegion, setIntermodalRegion] = useState<any>(null);
  const [form] = useForm();
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    setIntermodalRegion(null);
    form.resetFields();
  };

  const handleNew = () => {
    form.setFieldsValue({
      possible_charges: [],
    });
    showDrawer();
  };

  const fetchData = async () => {
    setLoading(true);

    try {
      const result = await app.service.get('intermodalRegions');
      setData(result.data);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const isNew = useMemo(() => {
    return !intermodalRegion;
  }, [intermodalRegion]);

  const handleSave = async () => {
    const data = await form.validateFields();

    setLoading(true);
    const formValue = Object.assign({}, data);

    formValue.city_id = get(formValue, 'city.id', 0);
    delete formValue.city;

    const request = isNew
      ? app.service.post('intermodalRegions', { data: formValue })
      : app.service.put(`intermodalRegions/${intermodalRegion}`, {
        data: formValue,
      });

    request
      .then(() => {
        fetchData();
        onClose();
        setIntermodalRegion(null);
        form.resetFields();
      })
      .catch((err) => {
        message.error(err.data?.message || err.data?.error);
        setLoading(false);
      });
  };

  const handleRemove = (id: number) => {
    setLoading(true);
    app.service
      .delete(`intermodalRegions/${id}`)
      .then(() => {
        fetchData();
      })
      .catch((err) => {
        message.error(err.data?.message || err.data?.error);
        setLoading(false);
      });
  };

  const handleEdit = (id: number) => {
    const item = data.find((item) => item.id === id);
    if (!item) {
      return;
    }

    setVisible(true);
    setIntermodalRegion(id);
    form.setFieldsValue({ ...item });
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      width: 80,
      render: (text, record) => <>{record.id}</>,
    },
    {
      title: 'Name',
      sorter: (a, b) => (a.name > b.name ? 1 : 0),
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      sorter: (a, b) => a.active - b.active,
      filters: [
        { text: 'Active', value: true },
        { text: 'Inactive', value: false },
      ],
      onFilter: (value, record) => record.active == value,
      width: 120,
      render: (value, record) => {
        return (
          <Tag color={record.active ? 'green' : 'red'}>
            {record.active ? 'Active' : 'Inactive'}
          </Tag>
        );
      },
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      width: 200,
      render: (value, record) => {
        return <>{record.city?.full_name || ''}</>;
      },
    },
    {
      title: 'Coordinates',
      dataIndex: 'coordinates',
      key: 'coordinates',
      width: 200,
      render: (_, record) => {
        if (record.latitude && record.longitude) {
          const c = `${record.latitude},${record.longitude}`;
          return (
            <a
              href={`http://maps.google.co.uk/maps?q=${c}`}
              target="_blank"
              rel="noreferrer">
              {c}
            </a>
          );
        } else {
          return '-';
        }
      },
    },
    {
      title: 'Tier Rates',
      dataIndex: 'tier_rate',
      key: 'tier_rate',
      width: 420,
      render: (value, record) => {
        const tierRate = record.tier_rate;
        if (tierRate) {
          return (
            <>
              <RenderTierRate labelKey={TIER_0} discount={tierRate.tier_zero} />
              <RenderTierRate labelKey={TIER_1} discount={tierRate.tier_one} />
              <RenderTierRate labelKey={TIER_2} discount={tierRate.tier_two} />
              <RenderTierRate
                labelKey={TIER_3}
                discount={tierRate.tier_three}
              />
              <RenderTierRate labelKey={TIER_4} discount={tierRate.tier_four} />
            </>
          );
        }
      },
    },
    {
      title: 'Memo',
      dataIndex: 'memo',
      key: 'memo',
      width: 200,
    },

    {
      align: 'center',
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 150,
      render: (text, record) => (
        <span>
          <a onClick={() => handleEdit(record.id)}>Edit</a>
          <Divider type="vertical" />
          <Popconfirm
            title="Sure to delete?"
            okText="Confirm"
            cancelText="Cancel"
            onConfirm={() => handleRemove(record.id)}>
            <a>Delete</a>
          </Popconfirm>
          <Button type="link" size="small">
            <LogActivities
              id={record.id}
              icon={
                <div className="text-gray">
                  <InfoCircleOutlined />
                </div>
              }
              type="intermodalRegions"
            />
          </Button>
        </span>
      ),
    },
  ];

  const display = useMemo(() => {
    return [...data].filter((i) =>
      i.name.toLowerCase().includes(search.toLowerCase()),
    );
  }, [search, data]);

  return (
    <div className={styles.main}>
      <Header
        title="Intermodal Regions"
        rightElement={
          <div>
            <Button type="primary" onClick={handleNew}>
              New Intermodal Region
            </Button>
          </div>
        }></Header>

      <div className={styles.filter}>
        <div
          style={{
            width: '60%',
          }}>
          <Row>
            <Col>
              <Input
                allowClear
                placeholder="Search by Name"
                onChange={(e) => setSearch(e.target.value)}
              />
            </Col>
          </Row>
        </div>
      </div>
      <div>
        <AutoResizeTable
          pagination={false}
          loading={loading}
          size="small"
          rowKey="id"
          columns={columns}
          dataSource={display}
          scroll={{ x: 1500 }}
          sticky
        />
      </div>
      <Drawer
        title=""
        placement={'right'}
        width={'80%'}
        onClose={onClose}
        destroyOnClose={true}
        open={visible}
        extra={
          <Space>
            <Button onClick={onClose}>Close</Button>
            <Button type="primary" loading={loading} onClick={handleSave}>
              Save
            </Button>
          </Space>
        }>
        <DrawerForm
          form={form}
          intermodalRegion={intermodalRegion}
          updateData={setIntermodalRegion}
          onRefresh={fetchData}
        />
      </Drawer>
    </div>
  );
};

export default Index;
