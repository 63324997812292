import { useApp } from '@/utils/useapp';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import React from 'react';
import { TrackingInterface } from '../types';
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment';

export const TrackTable: React.FC<{
  oredrId: number;
  icon?: ReactNode;
  type: string;
}> = ({ oredrId, icon, type }) => {
  const app = useApp();
  const [form] = useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const resp = await app.service.get(`${type}/shipments/${oredrId}/tracks`);
      setData(resp.data);
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
    setLoading(false);
  };

  const handleAdd = async (values: any) => {
    setLoading(true);
    try {
      await app.service.post(`${type}/shipments/${oredrId}/tracks`, {
        data: values,
      });
      fetchData();
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
    setLoading(false);
  };

  const handleDelete = async (id: number) => {
    setLoading(true);
    try {
      await app.service.delete(`${type}/tracks/${id}`);
      fetchData();
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
    setLoading(false);
  };

  const columns: ColumnsType<TrackingInterface> = useMemo(() => {
    return [
      {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
      },
      {
        title: 'Date',
        dataIndex: 'timestamp',
        key: 'timestamp',
      },
      {
        title: 'Description',
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: 'Creator',
        dataIndex: 'creator',
        key: 'creator',
      },
      {
        title: 'Updated Time',
        dataIndex: 'updated_at',
        key: 'updated_at',
      },
      {
        align: 'center',
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 150,
        render: (text, record) => (
          <Popconfirm
            title="Sure to delete?"
            okText="Confirm"
            cancelText="Cancel"
            onConfirm={() => {
              handleDelete(record.id);
            }}
          >
            <a>Delete</a>
          </Popconfirm>
        ),
      },
    ];
  }, []);

  useEffect(() => {
    if (isModalVisible) {
      fetchData();
    }
  }, [isModalVisible]);

  return (
    <>
      <div onClick={showModal}>{icon ? icon : <a> T </a>}</div>
      <Modal
        cancelText="Close"
        footer={false}
        width={1000}
        title="Tracking Information"
        open={isModalVisible}
        onCancel={handleCancel}
      >
        <Space direction="vertical">
          <Form onFinish={handleAdd} initialValues={{}} form={form}>
            <Row gutter={8}>
              <Col span={10}>
                <Form.Item name="location">
                  <Input placeholder="Location" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="timestamp"
                  rules={[{ required: true }]}
                  getValueFromEvent={(onChange) =>
                    onChange
                      ? moment(onChange).format('YYYY-MM-DD HH:mm')
                      : null
                  }
                  getValueProps={(v) => ({ value: v ? moment(v) : null })}
                >
                  <DatePicker
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    allowClear={false}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="status" rules={[{ required: true }]}>
                  <Select
                    placeholder="Select status"
                    style={{ width: '100%' }}
                    options={[
                      { value: 'Shipment Booked', label: 'Shipment Booked' },
                      { value: 'Assigning Driver', label: 'Assigning Driver' },
                      { value: 'Driver Assigned', label: 'Driver Assigned' },
                      { value: 'Pending Pickup', label: 'Pending Pickup' },
                      {
                        value: 'Enroute to pickup',
                        label: 'Enroute to pickup',
                      },
                      {
                        value: 'Arrived at pickup',
                        label: 'Arrived at pickup',
                      },
                      { value: 'Picked up', label: 'Picked up' },
                      { value: 'In Transit', label: 'In Transit' },
                      {
                        value: 'At Delivery Terminal',
                        label: 'At Delivery Terminal',
                      },
                      { value: 'Out for Delivery', label: 'Out for Delivery' },
                      {
                        value: 'Arrived at delivery',
                        label: 'Arrived at delivery',
                      },
                      { value: 'Delivered', label: 'Delivered' },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Button type="primary" htmlType="submit">
                Add
              </Button>
            </Row>
          </Form>
          <Table
            scroll={{
              y: 700,
            }}
            loading={loading}
            sticky
            pagination={false}
            rowKey="id"
            size="small"
            columns={columns}
            dataSource={data}
          />
        </Space>
      </Modal>
    </>
  );
};
