import React, { useMemo } from 'react';
import { useApp } from '@/utils/useapp';
import { observer } from 'mobx-react';
import {
  WiFog,
  WiRain,
  WiSnow,
  WiSnowflakeCold,
  WiRainMix,
  WiSnowWind,
  WiStormShowers,
} from 'react-icons/wi';
import { Tooltip } from 'antd';
import moment from 'moment';

const FOG = 45;
const HEAVYRAIN = 65;
const HEAVYSNOWFALL = 75;
const SNOWGRAINS = 77;
const HEAVYRAINSHOWERS = 82;
const HEAVYSNOWSHOWERS = 86;
const THUNDERSTORMHEAVY = 99;

// weather text map
const WEATHER_TEXT = {
  [FOG]: 'Fog',
  [HEAVYRAIN]: 'Heavy Rain',
  [HEAVYSNOWFALL]: 'Heavy Snowfall',
  [SNOWGRAINS]: 'Snow Grains',
  [HEAVYRAINSHOWERS]: 'Heavy Rain Showers',
  [HEAVYSNOWSHOWERS]: 'Heavy Snow Showers',
  [THUNDERSTORMHEAVY]: 'Thunderstorm Heavy',
};

const ICON_SIZE = 18;

const WeatherIcon: React.FC<{ code: number; time: string }> = ({
  code,
  time,
}) => {
  const color = moment(time).isBefore(moment()) ? '#1890ff' : '#aaa';
  switch (code) {
    case FOG:
      return <WiFog size={ICON_SIZE} color={color} />;
    case HEAVYRAIN:
      return <WiRain size={ICON_SIZE} color={color} />;
    case HEAVYSNOWFALL:
      return <WiSnow size={ICON_SIZE} color={color} />;
    case SNOWGRAINS:
      return <WiSnowflakeCold size={ICON_SIZE} color={color} />;
    case HEAVYRAINSHOWERS:
      return <WiRainMix size={ICON_SIZE} color={color} />;
    case HEAVYSNOWSHOWERS:
      return <WiSnowWind size={ICON_SIZE} color={color} />;
    case THUNDERSTORMHEAVY:
      return <WiStormShowers size={ICON_SIZE} color={color} />;
    default:
      return <></>;
  }
};

const Weather: React.FC<{
  city_id: number;
  time: string;
  text?: string;
}> = ({ city_id, time, text }) => {
  const app = useApp();

  const weather = useMemo(() => {
    if (!city_id) {
      return;
    }

    return app.store.bufferCache.getWeahter(city_id, time);
  }, [app.store.bufferCache.data]);

  return (
    <>
      {weather ? (
        <Tooltip
          title={`${weather.time} ${
            WEATHER_TEXT[
              weather.weather_code as unknown as keyof typeof WEATHER_TEXT
            ]
          } ${text || ''}`}>
          <div className="flex flex-col items-center">
            <WeatherIcon code={weather.weather_code} time={weather.time} />
          </div>
        </Tooltip>
      ) : (
        ''
      )}
    </>
  );
};

export default observer(Weather);
