import { Button } from 'antd';
import { useApp } from '@/utils/useapp';
import { useEffect, useState } from 'react';
import React from 'react';
import { get, upperFirst } from 'lodash';
import { formatActionInfo, TCommonProps, TItemProps, TOnDoneProps } from '../';
import { SendDelPptDrawer } from '@/pages/entity/warehouses/components/WarehouseDrawer/SendDelPptDrawer';

export const SendPreDelApptAction: React.FC<
TCommonProps & TItemProps & TOnDoneProps
> = ({ taskType, item, container, onDone }) => {
  const app = useApp();

  const [open, setOpen] = useState(false);

  const handleOnOpen = () => {
    setOpen(true);
  };

  const handleCloseOpen = () => {
    setOpen(false);
  };

  const handleOnSent = () => {
    onDone && onDone();
    handleCloseOpen();
  };

  return (
    <>
      <Button
        disabled={item.disabled}
        key={item.action}
        size="small"
        onClick={handleOnOpen}>
        {upperFirst(item.action)}
      </Button>
      {open && container && (
        <SendDelPptDrawer
          open={open}
          isPreDelAppt={true}
          warehouse={container.warehouse}
          container={container}
          onClose={handleCloseOpen}
          onSent={handleOnSent}
        />
      )}
    </>
  );
};
