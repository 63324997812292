import styles from 'res/css/ui.scss';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
} from 'antd';
import React, { useState } from 'react';
import { IntermodalRegionSelect } from '@/components/IntermodalRegionSelect';
import { FormInstance } from 'antd/es/form';
import { TPerdiem, TStatesCount } from '..';
import { capitalize } from 'lodash';
import moment from 'moment';
import { CitySelect } from '@/components/CitySelect';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { ConsigneeSyncSelect } from '@/components/ConsigneeSyncSelect';
import { AntDRangePicker } from '@/components/AntDRangePicker';
import {
  PAST_3_MONTHS,
  RANGE_TYPES_PAST_1_2_3_4_6_12_MONTHS,
} from '@/components/AntDRangePicker/data';
import { Moment } from 'moment';
import { CompanySyncSelect } from '@/components/CompanySyncSelect';

export type TFilter = {
  loading: boolean;
  filter: FormInstance;
  statesCount: TStatesCount;
  perdiem: TPerdiem;
  onSearch: () => void;
};

export const Filter: React.FC<TFilter> = ({
  loading,
  filter,
  statesCount,
  perdiem,
  onSearch,
}) => {
  const [isShow, setIsShow] = useState(false);
  const [create, setCreate] = useState<[Moment, Moment]>();

  const handleSearch = () => {
    onSearch();
  };

  const handleClearAll = () => {
    filter.resetFields();
    onSearch();
  };

  const handleDateChange = (value: Moment[] | null) => {
    filter.setFieldsValue({
      ...filter,
      ['created_at_start_date']: value ? value[0].format() : '',
      ['created_at_end_date']: value ? value[1].format() : '',
    });
  };

  return (
    <div className={styles.filter}>
      <div
        style={{
          width: '100%',
        }}>
        <Form
          form={filter}
          disabled={loading}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}>
          <Form.Item hidden name="sort_by">
            <Input />
          </Form.Item>
          <Form.Item hidden name="sort_value">
            <Input />
          </Form.Item>
          <Row gutter={10}>
            <Col span={3}>
              <Form.Item label="Search ID#" name="search_id">
                <Input placeholder="Enter MBL#/Container#/DO#" />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item name="intermodal_region_id" className="mb0" label="IR">
                <IntermodalRegionSelect />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item label="Status" name="state" shouldUpdate>
                <Select
                  style={{
                    width: '100%',
                  }}
                  onChange={(value) => {
                    filter.setFieldsValue({ state: value });
                  }}>
                  {Object.keys(statesCount).map((state: string) => {
                    return (
                      <Select.Option key={state} value={state}>
                        {capitalize(state)}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="Customer" name="user_id">
                <UserSyncSelect allowClear />
              </Form.Item>
            </Col>

            <Col span={2}>
              <Form.Item label="Operator" name="operator_id">
                <UserSyncSelect type="admin" allowClear />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item label="Sales" name="sales_id">
                <UserSyncSelect type="admin" allowClear />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item label="Sales Support" name="sales_support_id">
                <UserSyncSelect type="admin" allowClear />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="Verify" name="open_order_verified">
                <Select allowClear>
                  <Select.Option value="true">Yes</Select.Option>
                  <Select.Option value="false">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="&nbsp;">
                <Space>
                  <Button
                    onClick={() => {
                      setIsShow((isShow) => !isShow);
                    }}>
                    {isShow ? 'Hide Filters' : 'Show All Filters'}
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={loading}
                    onClick={handleSearch}>
                    Search
                  </Button>
                  <Button onClick={handleClearAll}>Clear All</Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
          {isShow && (
            <>
              <Row gutter={10}>
                <Col span={3}>
                  <Form.Item
                    label="Customer Ref."
                    name="customer_reference_number">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item
                    label="Perdiem Type"
                    name="perdiem_type"
                    shouldUpdate>
                    <Select
                      style={{
                        width: '100%',
                      }}
                      onChange={(value) => {
                        filter.setFieldsValue({ perdiem_type: value });
                      }}>
                      {Object.keys(perdiem).map((state: string) => {
                        return (
                          <Select.Option key={state} value={state}>
                            {state == '' ? 'All' : `${state}`}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => (
                      <Form.Item
                        name="warehouse_city"
                        className="mb0"
                        label="Warehosue City">
                        <CitySelect
                          selected={getFieldValue('warehouse_city')}
                          onSelect={(id) => {
                            filter.setFieldsValue({ warehouse_city: id });
                          }}
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="port_of_discharge_eta_start_date" noStyle>
                    <></>
                  </Form.Item>
                  <Form.Item name="port_of_discharge_eta_end_date" noStyle>
                    <></>
                  </Form.Item>
                  <Form.Item label="POD ETA" shouldUpdate>
                    {({ getFieldValue, setFieldsValue }) => {
                      const startDate = getFieldValue(
                        'port_of_discharge_eta_start_date',
                      );
                      const endDate = getFieldValue(
                        'port_of_discharge_eta_end_date',
                      );

                      return (
                        <DatePicker.RangePicker
                          value={
                            startDate && endDate
                              ? [moment(startDate), moment(endDate)]
                              : undefined
                          }
                          onChange={(value) =>
                            setFieldsValue({
                              port_of_discharge_eta_start_date: value
                                ? value[0]?.format('YYYY-MM-DD')
                                : null,
                              port_of_discharge_eta_end_date: value
                                ? value[1]?.format('YYYY-MM-DD')
                                : null,
                            })
                          }
                        />
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="final_port_eta_start_date" noStyle>
                    <></>
                  </Form.Item>
                  <Form.Item name="final_port_eta_end_date" noStyle>
                    <></>
                  </Form.Item>
                  <Form.Item label="IR ETA" shouldUpdate>
                    {({ getFieldValue, setFieldsValue }) => {
                      const startDate = getFieldValue(
                        'final_port_eta_start_date',
                      );
                      const endDate = getFieldValue('final_port_eta_end_date');
                      return (
                        <DatePicker.RangePicker
                          value={
                            startDate && endDate
                              ? [moment(startDate), moment(endDate)]
                              : undefined
                          }
                          onChange={(value) =>
                            setFieldsValue({
                              final_port_eta_start_date: value
                                ? value[0]?.format('YYYY-MM-DD')
                                : null,
                              final_port_eta_end_date: value
                                ? value[1]?.format('YYYY-MM-DD')
                                : null,
                            })
                          }
                        />
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Vendor" name="vendor_name">
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={3}>
                  <Form.Item label="Terminal Firm Code" name="terminal_firm">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item label="SSL SCAC Code" name="ocean_carrier_code">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item label="Consignee" name="consignee_id">
                    <ConsigneeSyncSelect />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item label="Company" name="company_id">
                    <CompanySyncSelect allowClear />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    hidden
                    initialValue={create}
                    name="created_at_start_date"></Form.Item>
                  <Form.Item hidden name="created_at_end_date"></Form.Item>
                  <Form.Item label="Created Date" shouldUpdate>
                    {({ getFieldValue, setFieldsValue }) => {
                      const startDate = getFieldValue('created_at_start_date');
                      const endDate = getFieldValue('created_at_end_date');
                      return (
                        <AntDRangePicker
                          style={{ width: '100%' }}
                          rangeType={RANGE_TYPES_PAST_1_2_3_4_6_12_MONTHS}
                          value={
                            startDate && endDate
                              ? [moment(startDate), moment(endDate)]
                              : undefined
                          }
                          onChange={(value: [Moment, Moment]) => {
                            setCreate(value);
                            handleDateChange(value);
                          }}
                        />
                      );
                    }}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={10}>
                <Col span={20}>
                  <Space direction="horizontal">
                    <Form.Item
                      labelCol={{ span: 20 }}
                      wrapperCol={{ span: 12 }}
                      label="Without Invoices"
                      name="without_invoices"
                      valuePropName="checked">
                      <Checkbox />
                    </Form.Item>
                    <Form.Item
                      labelCol={{ span: 20 }}
                      wrapperCol={{ span: 5 }}
                      label="Without Bills"
                      name="without_bills"
                      valuePropName="checked">
                      <Checkbox />
                    </Form.Item>
                    <Form.Item
                      labelCol={{ span: 20 }}
                      wrapperCol={{ span: 5 }}
                      label="Duplicate Containers"
                      name="duplicate_containers"
                      valuePropName="checked">
                      <Checkbox />
                    </Form.Item>
                    <Form.Item
                      labelCol={{ span: 20 }}
                      wrapperCol={{ span: 5 }}
                      label="With Invoices"
                      name="with_invoices"
                      valuePropName="checked">
                      <Checkbox />
                    </Form.Item>
                    <Form.Item
                      labelCol={{ span: 20 }}
                      wrapperCol={{ span: 5 }}
                      label="With Bills"
                      name="with_bills"
                      valuePropName="checked">
                      <Checkbox />
                    </Form.Item>
                  </Space>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </div>
    </div>
  );
};
