import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { get, truncate } from 'lodash';
import { Tooltip, Typography } from 'antd';
import { OpenBuyRateOrSellRateDrawer } from '@/components/Rate/OpenBuyRateOrSellRateDrawer';

export const BuyRateColumn: React.FC<{
  container: any;
  refreshContainer: (id: number) => void;
}> = ({ container, refreshContainer }) => {
  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <>
      <Typography.Link onClick={() => setOpen(true)}>
        {get(container, 'buy_rate.uid')}
      </Typography.Link>

      <OpenBuyRateOrSellRateDrawer
        open={open}
        rateUid={get(container, 'buy_rate.uid')}
        onClose={() => setOpen(false)}
      />
    </>
  );
};
