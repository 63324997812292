import { useApp } from '@/utils/useapp';
import {
  Space,
  Button,
  message,
  Drawer,
  Switch,
  Input,
  Tooltip,
  Divider,
} from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { Header } from '@/components/CommonHeader';
import styles from 'res/css/ui.scss';
import { TCollection } from '@/types';
import AutoResizeTable from '@/components/AutoResizeTable';
import CarrierForm from './components/CarrierForm';
import { FTLCarrierInterface } from './Interfaces/FTLCarrierInterface';
import { AlignLeftOutlined } from '@ant-design/icons';
import {
  DisputeMemo,
  DISPUTE_TYPE_FTL_CARRIERS,
} from '@/components/DisputeMemo';
import { LogActivities } from '@/components/LogActivities';

const Index: React.FC = () => {
  const app = useApp();
  const [data, setData] = useState<TCollection<FTLCarrierInterface>>();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState<FTLCarrierInterface>();
  const [openId, setOpenId] = useState<number>(0);

  const fetchData = async (
    pagination?: TablePaginationConfig,
    query?: string,
  ) => {
    setLoading(true);
    try {
      const resp = await app.service.get('ftl/carriers', {
        params: {
          query,
          all: true,
        },
      });
      setData(resp);
      setLoading(false);
    } catch (err: any) {
      message.error(err?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    fetchData(pagination);
  };

  const handleNewCarrier = () => {
    setModel(undefined);
    setDrawerVisible(true);
  };

  const handleEdit = (m: any) => {
    setModel(m);
    setDrawerVisible(true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSwitch = async (checked: boolean, id: number) => {
    await app.service.put(`ftl/carriers/${id}`, {
      data: { enabled: checked },
    });
    fetchData();
  };

  const handleSearch = (value: string) => {
    fetchData(undefined, value);
  };

  const columns: ColumnsType<FTLCarrierInterface> = [
    {
      title: 'Carrier#',
      dataIndex: 'id',
      sorter: true,
      width: 150,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: 300,
    },
    {
      title: 'Creator',
      dataIndex: 'creator_name',
      width: 150,
    },
    {
      title: 'Status',
      dataIndex: 'enabled',
      key: 'enabled',
      width: 150,
      render: (text, record) => {
        const isSuperAdmin = app.store.auth.hasRole('super-admin');
        return (
          <Switch
            checked={record.enabled}
            onChange={(checked) => handleSwitch(checked, record.id)}
            disabled={!isSuperAdmin}
          />
        );
      },
    },
    {
      title: 'Primary Scac Code',
      dataIndex: 'scac_code',
      width: 150,
    },
    {
      title: 'Sub Scac Codes',
      dataIndex: 'sub_scac_codes',
      key: 'sub_scac_codes',
      width: 150,
      render: (text, record) => {
        return record.sub_scac_codes?.join(', ') ?? '-';
      },
    },
    {
      title: 'Dot Number',
      dataIndex: 'dot_number',
      width: 180,
    },
    {
      title: 'Mc Number',
      dataIndex: 'mc_number',
      width: 180,
    },
    {
      align: 'center',
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 150,
      render: (text, record) => (
        <Space>
          <DisputeMemo
            title="Carrier Memo"
            onSaved={() => fetchData && fetchData()}
            model={record}
            icon={
              <Tooltip placement="left" title={record.internal_memo || ''}>
                {
                  <span
                    className={`ant-btn-sm ${
                      record.internal_memo ? 'text-primary' : 'text-gray'
                    }`}
                  >
                    <AlignLeftOutlined />
                  </span>
                }
              </Tooltip>
            }
            type={DISPUTE_TYPE_FTL_CARRIERS}
            withStatus={false}
            open={openId == record.id}
          />
          <Divider type="vertical" />
          <LogActivities id={record.id} type="ftlCarrier" icon={<a>log</a>} />
          <Divider type="vertical" />
          <a onClick={() => handleEdit(record)}>Edit</a>
        </Space>
      ),
    },
  ];

  return (
    <div className={styles.main}>
      <Header
        title="FTL Carrier"
        rightElement={
          <Space align="end" direction="horizontal">
            <Input.Search
              style={{ width: '320px' }}
              placeholder="Search by name"
              allowClear
              disabled={loading}
              onSearch={(value: string) => handleSearch(value)}
            />
            <Button type="primary" onClick={handleNewCarrier}>
              New FTL Carrier
            </Button>
          </Space>
        }
      />

      <AutoResizeTable
        loading={loading}
        size="small"
        rowKey="id"
        columns={columns}
        onChange={handleTableChange}
        dataSource={data?.data || []}
        sticky
        pagination={{
          pageSize: 50,
          showSizeChanger: false,
        }}
        scroll={{
          x: 'auto',
        }}
      />
      <Drawer
        title=""
        placement="right"
        width="90%"
        onClose={() => {
          fetchData();
          setDrawerVisible(false);
        }}
        destroyOnClose={true}
        open={drawerVisible}
      >
        <CarrierForm
          model={model}
          onCreated={() => {
            fetchData();
            setDrawerVisible(false);
          }}
          onClose={() => setDrawerVisible(false)}
        />
      </Drawer>
    </div>
  );
};

export default Index;
