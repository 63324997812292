import { TRole, TUser } from '@/types';
import { useApp } from '@/utils/useapp';
import {
  Button,
  Divider,
  Drawer,
  message,
  Popconfirm,
  Space,
  TableColumnsType,
} from 'antd';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styles from 'res/css/ui.scss';
import RoleDetail from './components/RoleDetail';
import AutoResizeTable from '@/components/AutoResizeTable';
import { PlusOutlined } from '@ant-design/icons';
import { Header } from '@/components/CommonHeader';
import RoleDetailView from './components/RoleDetailView';

const UserRole: FC = (props) => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Array<TRole>>([]);
  const [editRole, setEditRole] = useState<TRole>();
  const [openView, setOpenView] = useState<boolean>(false);
  const [currentRole, setCurrentRole] = useState<TRole>();

  const [openUsersDrawer, setOpenUsersDrawer] = useState<boolean>(false);
  const [users, setUsers] = useState<TUser[]>([]);

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      const result = await app.service.get('roles');
      setData(result.data);
    } catch (e: any) {
      message.error(e.data.message);
    }
    setLoading(false);
  }, []);

  const deleteRole = useCallback(async (role: TRole) => {
    try {
      await app.service.delete(`roles/${role.id}`);
      getData();
    } catch (e: any) {
      message.error(e.data.message);
    }
  }, []);

  const onOPenView = (role: TRole) => {
    setCurrentRole(role);
    setOpenView(true);
  };

  const onCloseView = () => {
    setCurrentRole(undefined);
    setOpenView(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const showUsers = (users: TUser[]) => {
    setUsers(users);
    setOpenUsersDrawer(true);
  };

  const columns = useMemo(() => {
    const columns: TableColumnsType<TRole> = [
      {
        title: 'Name',
        dataIndex: 'name',
      },
      {
        title: 'User Count',
        dataIndex: 'users_count',
        render: (text, record) => {
          return (
            <>
              {record?.users?.length}{' '}
              {record?.users?.length > 0 ? (
                <Button type="link" onClick={() => showUsers(record?.users)}>
                  Show
                </Button>
              ) : (
                ''
              )}
            </>
          );
        },
      },
      {
        title: 'Action',
        width: 200,
        render: (role: TRole) => {
          return (
            <Space split={<Divider type="vertical" />}>
              <Button type="link" size="small" onClick={() => onOPenView(role)}>
                View
              </Button>
              {/* <Button
                type="link"
                size="small"
                onClick={() => setEditRole(role)}>
                Edit
              </Button>
              <Popconfirm
                title="Are you sure？"
                onConfirm={() => deleteRole(role)}>
                <Button size="small" type="link">
                  Remove
                </Button>
              </Popconfirm> */}
            </Space>
          );
        },
      },
    ];
    return columns;
  }, []);

  return (
    <div className={styles.main}>
      <Header
        title="Role permissions"
        rightElement={
          <Space>
            {/* <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setEditRole({} as TRole)}>
              Add
            </Button> */}
          </Space>
        }
      ></Header>

      <AutoResizeTable
        size="small"
        rowKey="id"
        columns={columns}
        loading={loading}
        dataSource={data}
      />

      {/* {editRole && (
        <RoleDetail
          role={editRole}
          onClose={(reload) => {
            setEditRole(undefined);
            if (reload) {
              getData();
            }
          }}
        />
      )} */}

      {openView && (
        <RoleDetailView
          open={openView}
          role={currentRole}
          onClose={onCloseView}
        />
      )}

      {openUsersDrawer && (
        <Drawer
          open={openUsersDrawer}
          onClose={() => {
            setOpenUsersDrawer(false);
          }}
        >
          <AutoResizeTable
            loading={loading}
            pagination={false}
            size="small"
            rowKey="id"
            columns={[
              {
                title: 'Name',
                dataIndex: 'name',
              },
            ]}
            dataSource={users || []}
            sticky
            scroll={{
              x: 'auto',
            }}
          />
        </Drawer>
      )}
    </div>
  );
};

export default UserRole;
