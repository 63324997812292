import { Tabs } from 'antd';
import CashFlow from './CashFlow';
import ARSummary from './ARSummary';
import APSummary from './APSummary';
import CustomerSummary from './CustomerSummary';
import { useApp } from '@/utils/useapp';
import { useEffect, useState } from 'react';

export interface ICashFlow {
  name: string;
  total_profit: number;
  avg_aging: number;
  container_count: number;
}

const Financial = () => {
  const [loading, setLoading] = useState(false);
  const [cashFlow, setCashFlow] = useState<ICashFlow[]>([]);
  const [cashFlowRaw, setCashFlowRaw] = useState<any>([]);

  const app = useApp();
  const fetchCashFlow = async () => {
    try {
      setLoading(true);
      const res = await app.service.get('risks/cashflow');

      setCashFlow(res.data.slice(0, 3));

      setCashFlowRaw(res.data.slice(3));
    } catch (e: any) {
      console.log(e.error || 'network error');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCashFlow();
  }, []);

  const items = [
    {
      key: '1',
      label: 'Cash Flow',
      children: (
        <>
          <CashFlow cashFlow={cashFlow} loading={loading} />
          <ARSummary cashFlow={cashFlowRaw[0]} />
          <APSummary />
        </>
      ),
    },
    {
      key: '2',
      label: 'Customer Summary',
      children: <CustomerSummary />,
    },
  ];

  return (
    <div>
      <Tabs defaultActiveKey="1" type="card" items={items} />
    </div>
  );
};

export default Financial;
