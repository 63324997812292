import { useApp } from '@/utils/useapp';
import { Space } from 'antd';
import { ShipmentInterface } from '../Interfaces/ShipmentInterface';
import { useState } from 'react';
import QuoteDetailDrawer from '@/pages/truckload/ltl/quotes/QuoteDetailDrawer';

const FromType: React.FC<{
  shipment: ShipmentInterface;
  onSaved: () => void;
}> = ({ shipment, onSaved }) => {
  const [open, setOpen] = useState(false);

  const handleSaved = () => {
    onSaved();
    setOpen(false);
  };
  return (
    <>
      <Space direction="vertical" align="center">
        <div style={{ width: '100%' }}>{shipment.from_type}</div>
        <a onClick={() => setOpen(true)}>{shipment.quote_number}</a>
      </Space>
      {shipment.quote_id && open && (
        <QuoteDetailDrawer
          open={open}
          id={shipment.quote_id}
          onClose={() => setOpen(false)}
          onSaved={handleSaved}
        />
      )}
    </>
  );
};

export default FromType;
