import React from 'react';
import {
  Form,
  Select,
  Card,
  Row,
  Col,
  Checkbox,
  message,
  Tooltip,
  Button,
  Space,
  Drawer,
} from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useApp } from '@/utils/useapp';
import type { TAdmin } from '@/types';
import type {
  Permission,
  Role,
  PermissionResponse,
  RolePermissionsResult,
} from './data';

export const PermissionsRolesForm: React.FC<{
  admin: TAdmin | null;
  visible: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}> = ({ admin, visible, onClose, onSuccess }) => {
  const app = useApp();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [
    permissionData,
    setPermissionData,
  ] = React.useState<PermissionResponse | null>(null);
  const [rolePermissions, setRolePermissions] = React.useState<Set<string>>(
    new Set(),
  );
  const [selectedPermissionIds, setSelectedPermissionIds] = React.useState<
    Set<number>
  >(new Set());

  const getPermissionsByRoles = React.useCallback(
    (roleNames: string[]): RolePermissionsResult => {
      if (!permissionData) {
        return {
          permissionNames: [],
          permissionIds: [],
        };
      }

      // 通过角色名找到对应的角色ID和权限
      const selectedRoles = permissionData.allRoles.filter((role) =>
        roleNames.includes(role.name),
      );
      const permissions = new Set<string>();
      const permissionIds = new Set<number>();

      selectedRoles.forEach((role) => {
        if (role.permissions) {
          role.permissions.forEach((permission) => {
            permissions.add(permission.name);
            permissionIds.add(permission.id);
          });
        }
      });

      setRolePermissions(permissions);
      return {
        permissionNames: Array.from(permissions),
        permissionIds: Array.from(permissionIds),
      };
    },
    [permissionData],
  );

  const handleRoleChange = (roleNames: string[]) => {
    if (!permissionData) {
      return;
    }

    const { permissionNames, permissionIds } = getPermissionsByRoles(roleNames);

    // 获取当前所有权限
    const currentPermissions = form.getFieldValue('permissions') || [];

    // 找出不是来自角色的额外权限
    const extraPermissions = currentPermissions.filter(
      (p: string) => !rolePermissions.has(p),
    );

    // 获取额外权限的 ID
    const permissionMap = new Map(
      permissionData.allPermission.map((p) => [p.name, p.id]),
    );
    const extraPermissionIds = extraPermissions
      .map((name: string) => permissionMap.get(name))
      .filter(Boolean) as number[];

    // 更新选中的权限ID
    setSelectedPermissionIds(
      new Set([...permissionIds, ...extraPermissionIds]),
    );

    // 合并角色权限和额外权限
    form.setFieldsValue({
      permissions: [...permissionNames, ...extraPermissions],
    });
  };

  const handlePermissionChange = (checkedValues: CheckboxValueType[]) => {
    if (!permissionData) {
      return;
    }

    // 确保角色带来的权限不会被取消
    const newPermissions = Array.from(
      new Set([...Array.from(rolePermissions), ...checkedValues]),
    );

    // 获取所有权限的映射关系
    const permissionMap = new Map(
      permissionData.allPermission.map((p) => [p.name, p.id]),
    );

    // 获取选中权限对应的ID
    const newPermissionIds = newPermissions
      .map((name) => permissionMap.get(name as string))
      .filter(Boolean) as number[];

    setSelectedPermissionIds(new Set(newPermissionIds));

    form.setFieldsValue({
      permissions: newPermissions,
    });
  };

  const fetchPermissionData = React.useCallback(async () => {
    if (!admin?.id) {
      return;
    }

    setLoading(true);
    try {
      const response = await app.service.get(`admins/${admin.id}/permissions`);
      const { data } = response;
      if (data && data.allPermission && data.allRoles) {
        setPermissionData(data);

        // 获取角色权限
        const roleNames = data.userRole.map((role: Role) => role.name);
        const rolePermissionSet = new Set<string>();
        const rolePermissionIds = new Set<number>();

        // 收集所有角色的权限
        data.userRole.forEach((role: Role) => {
          if (role.permissions) {
            role.permissions.forEach((permission: Permission) => {
              rolePermissionSet.add(permission.name);
              rolePermissionIds.add(permission.id);
            });
          }
        });

        // 设置角色权限状态
        setRolePermissions(rolePermissionSet);

        // 设置初始选中的权限ID
        const initialPermissionIds = new Set(
          data.userPermission.map((p: Permission) => p.id),
        );
        setSelectedPermissionIds(initialPermissionIds as Set<number>);

        // 设置表单值
        form.setFieldsValue({
          roles: roleNames,
          permissions: data.userPermission.map((p: Permission) => p.name),
        });
      }
    } catch (e: any) {
      console.log(e);
      message.error(
        e.data?.message || e.data?.error || 'Failed to fetch permissions',
      );
    } finally {
      setLoading(false);
    }
  }, [admin?.id]);

  React.useEffect(() => {
    fetchPermissionData();
  }, [fetchPermissionData]);

  const handleSubmit = async (values: {
    roles: number[];
    permissions: number[];
  }) => {
    if (!admin?.id) {
      return;
    }

    setLoading(true);
    try {
      await app.service.put(`admins/${admin.id}/permissions`, {
        data: {
          role_ids: values.roles,
          permission_ids: values.permissions,
        },
      });
      message.success('Permissions updated successfully');
      onClose();
      onSuccess?.();
    } catch (e: any) {
      message.error(
        e.data?.message || e.data?.error || 'Failed to update permissions',
      );
    } finally {
      setLoading(false);
    }
  };

  const handleFinish = () => {
    const values = form.getFieldsValue();

    // 获取选中的角色 ID
    const selectedRoleIds =
      permissionData?.allRoles
        .filter((role) => values.roles.includes(role.name))
        .map((role) => role.id) || [];

    // 获取角色带来的权限 ID
    const rolePermissionIds = new Set<number>();
    permissionData?.allRoles
      .filter((role) => values.roles.includes(role.name))
      .forEach((role) => {
        role.permissions?.forEach((permission) => {
          rolePermissionIds.add(permission.id);
        });
      });

    // 获取额外的权限 ID（当前选中的权限中，不是来自角色的权限）
    const extraPermissionIds = Array.from(selectedPermissionIds).filter(
      (id) => !rolePermissionIds.has(id),
    );

    handleSubmit({
      roles: selectedRoleIds,
      permissions: extraPermissionIds,
    });
  };

  if (
    !permissionData ||
    !permissionData.allRoles ||
    !permissionData.allPermission
  ) {
    return null;
  }

  return (
    <Drawer
      title={`Permissions & Roles - ${admin?.name || ''}`}
      placement="right"
      width="60%"
      onClose={onClose}
      destroyOnClose={true}
      open={visible}
      extra={
        <Space>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="primary" loading={loading} onClick={handleFinish}>
            Save
          </Button>
        </Space>
      }
    >
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Card title="Roles" bordered={false} style={{ marginBottom: 24 }}>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name="roles"
                label="Roles"
                rules={[
                  {
                    required: true,
                    message: 'Please select at least one role',
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  placeholder="Please select roles"
                  options={(permissionData.allRoles || []).map((role) => ({
                    label:
                      role.name.charAt(0).toUpperCase() +
                      role.name.slice(1).replace(/-/g, ' '),
                    value: role.name,
                  }))}
                  onChange={handleRoleChange}
                  optionFilterProp="label"
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>

        <Card title="Permissions" bordered={false}>
          <Form.Item name="permissions">
            <Checkbox.Group
              style={{ width: '100%' }}
              onChange={handlePermissionChange}
            >
              <Row gutter={[16, 8]}>
                {permissionData.allPermission.map((permission) => {
                  const isRolePermission = rolePermissions.has(permission.name);
                  return (
                    <Col span={8} key={permission.id}>
                      <Tooltip
                        title={
                          isRolePermission
                            ? 'This permission is granted by selected roles'
                            : ''
                        }
                      >
                        <Checkbox
                          value={permission.name}
                          disabled={isRolePermission}
                          style={{ opacity: isRolePermission ? 0.7 : 1 }}
                        >
                          {permission.name
                            .split('.')
                            .map(
                              (part) =>
                                part.charAt(0).toUpperCase() + part.slice(1),
                            )
                            .join(' ')}
                        </Checkbox>
                      </Tooltip>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>
      </Form>
    </Drawer>
  );
};
