import Icon, { HomeOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import React from 'react';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import styles from 'res/css/ui.scss';
import { FaRegSnowflake } from 'react-icons/fa';
const ReeferSvg = () => (
  <svg
    viewBox="64 64 896 896"
    focusable="false"
    data-icon="gateway"
    width="1em"
    height="1em"
    fill="currentColor"
    aria-hidden="true">
    <path d="M925.802667 564.266667A74.453333 74.453333 0 0 1 949.333333 618.666667v93.546666a160.021333 160.021333 0 0 1-142.165333 159.018667A117.333333 117.333333 0 0 1 693.333333 960a117.333333 117.333333 0 0 1-112.917333-85.333333h-158.165333a117.333333 117.333333 0 0 1-112.917334 85.333333 117.333333 117.333333 0 0 1-112.917333-85.333333H117.333333a32 32 0 0 1 0-64h79.082667a117.333333 117.333333 0 0 1 112.917333-85.333334 117.333333 117.333333 0 0 1 112.917334 85.333334h158.165333a117.333333 117.333333 0 0 1 112.917333-85.333334 117.333333 117.333333 0 0 1 111.786667 81.578667 96.021333 96.021333 0 0 0 80.213333-94.72V618.666667a10.666667 10.666667 0 0 0-10.666666-10.666667H576a74.666667 74.666667 0 0 1-74.666667-74.666667V170.666667a10.666667 10.666667 0 0 0-10.666666-10.666667h-117.333334a32 32 0 0 1 0-64H490.666667A74.666667 74.666667 0 0 1 565.333333 170.666667v10.666666h105.856a117.333333 117.333333 0 0 1 106.154667 67.370667l147.626667 313.6c0.298667 0.64 0.576 1.28 0.832 1.962667z m-80.213334-20.266667l-126.144-268.053333a53.333333 53.333333 0 0 0-48.256-30.613334H565.333333V533.333333a10.666667 10.666667 0 0 0 10.666667 10.666667h269.610667z m-539.498666-149.333333H384a32 32 0 0 1 0 64h-77.909333l38.954666 67.477333a32 32 0 0 1-55.424 32L250.666667 490.666667l-38.954667 67.477333a32 32 0 0 1-55.424-32L195.242667 458.666667H117.333333a32 32 0 0 1 0-64h77.909334l-38.954667-67.477334a32 32 0 0 1 55.424-32L250.666667 362.666667l38.954666-67.477334a32 32 0 0 1 55.424 32L306.090667 394.666667zM362.666667 842.666667c0-29.482667-23.850667-53.333333-53.333334-53.333334a53.290667 53.290667 0 0 0-53.333333 53.333334c0 29.482667 23.850667 53.333333 53.333333 53.333333s53.333333-23.850667 53.333334-53.333333z m384 0c0-29.482667-23.850667-53.333333-53.333334-53.333334a53.290667 53.290667 0 0 0-53.333333 53.333334c0 29.482667 23.850667 53.333333 53.333333 53.333333s53.333333-23.850667 53.333334-53.333333z"></path>
  </svg>
);
const _ReeferIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ReeferSvg} {...props} />
);

export const ReeferIcon: React.FC = () => (
  <span className={styles.customer_icon}>
    <FaRegSnowflake />
    {/* <_ReeferIcon /> */}
  </span>
);
