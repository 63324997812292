import { Input, Form, Select, Switch } from 'antd';
import { FormInstance } from 'antd/es/form';
import { get, reduce, isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { Weight } from '@/components/ContainerTable';
import { IAttribute } from '.';
import { CNTR_SIZE_MAP } from '@/components/PossibleCharges';
import { AbilitySwitchDisabled } from './AbilitySwitchDisabled';

export const OverweightAbility: React.FC<IAttribute> = ({ form }) => {
  return (
    <>
      <Form.Item
        label="Overweight Ability"
        name="overweight_ability"
        valuePropName="checked"
        shouldUpdate>
        {/* <Switch /> */}
        <AbilitySwitchDisabled
          checked={form.getFieldValue('overweight_ability')}
        />
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue, setFieldValue }) =>
          getFieldValue('overweight_ability') && (
            <>
              <Form.Item
                label="Overweight Container Size"
                name="overweight_cntr_size"
                rules={
                  [
                    // {
                    //   required: true,
                    //   message: 'Please Select Overweight Container Size',
                    // },
                  ]
                }
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}>
                <Select
                  mode="multiple"
                  value={
                    isEmpty(getFieldValue('overweight_cntr_size'))
                      ? getFieldValue('overweight_cntr_size')
                      : []
                  }>
                  {Object.keys(CNTR_SIZE_MAP).map((key) => (
                    <Select.Option key={+key} value={+key}>
                      {
                        CNTR_SIZE_MAP[
                          key as unknown as keyof typeof CNTR_SIZE_MAP
                        ]
                      }
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Overweight Range"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}>
                <Input.Group compact>
                  <Form.Item
                    name={'overweight_range_from'}
                    rules={[
                      {
                        required: false,
                        message: 'Overweight Range is Reuired',
                      },
                    ]}
                    noStyle>
                    <Weight
                      weight={getFieldValue('overweight_range_from')}
                      onChange={(value) => {
                        setFieldValue('overweight_range_from', value);
                      }}
                    />
                  </Form.Item>
                  <Input
                    className="site-input-split"
                    style={{
                      width: 30,
                      marginTop: 10,
                      marginBottom: 10,
                      borderLeft: 0,
                      borderRight: 0,
                      pointerEvents: 'none',
                    }}
                    placeholder="~"
                    disabled
                  />
                  <Form.Item name={'overweight_range_to'} noStyle>
                    <Weight
                      weight={getFieldValue('overweight_range_to')}
                      onChange={(value) => {
                        setFieldValue('overweight_range_to', value);
                      }}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </>
          )
        }
      </Form.Item>
    </>
  );
};
