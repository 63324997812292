import { useState } from 'react';
import { Popover } from 'antd';
import { RatingTag } from '@/components/RatingTag';
import { ModelTarget, ModelType } from '../Interfaces/ModelType';
import { ResponseType } from '../Interfaces/RepsonseType';
import { ShipmentInterface as LTLShipmentInterface } from '@/pages/truckload/ltl/shipments/Interfaces/ShipmentInterface';
import { FTLShipmentInterface } from '@/pages/truckload/ftl/shipments/Interfaces/ShipmentInterface';
import { useMemo } from 'react';
import RatingTable from './RatingTable';

const RatingTagPopover: React.FC<
  ModelType &
    ResponseType & {
      onSaved?: () => void;
    }
> = ({
  modelTarget,
  modelId,
  model,
  responsible,
  responsibleTarget,
  onSaved,
}) => {
  const [open, setOpen] = useState(false);
  if (!modelId) {
    return <></>;
  }

  const onOpenChange = (open: boolean) => {
    if (open) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  return (
    <Popover
      mouseEnterDelay={0.05}
      mouseLeaveDelay={0.05}
      trigger="click"
      onOpenChange={onOpenChange}
      placement="right"
      zIndex={1000}
      destroyTooltipOnHide
      content={
        <RatingTable
          modelId={modelId}
          model={model}
          open={open}
          modelTarget={modelTarget}
          responsible={responsible}
          responsibleTarget={responsibleTarget}
          score={model?.score || 'N/A'}
          onSaved={onSaved}
        />
      }
    >
      <RatingTag rating={model?.score || 0} />
    </Popover>
  );
};

export default RatingTagPopover;
