import { Col, Form, Input, InputNumber, Row, Select } from 'antd';
import React from 'react';

export const DrawerForm: React.FC<any> = ({ form }) => {
  return (
    <div>
      <Form form={form}>
        <Row gutter={10}>
          <Col span={24}>
            <Form.Item
              label="Code"
              name="code"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input placeholder="Enter Code" />
            </Form.Item>
            <Form.Item
              label="ISO CODE"
              name="iso_code"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input placeholder="Enter ISO CODE" />
            </Form.Item>
            <Form.Item
              label="Ams Code"
              name="ams_code"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input placeholder="Enter Ams Code" />
            </Form.Item>
            <Form.Item
              label="Size"
              name="size"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input placeholder="Enter Size" />
            </Form.Item>
            <Form.Item
              label="Length"
              name="length"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <InputNumber
                style={{ width: '100%' }}
                placeholder="Enter Length"
              />
            </Form.Item>
            <Form.Item
              label="Width"
              name="width"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <InputNumber
                style={{ width: '100%' }}
                placeholder="Enter Width"
              />
            </Form.Item>
            <Form.Item
              label="Type"
              name="type"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input placeholder="Enter Type" />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input placeholder="Enter Description" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
