import React from 'react';
import { message, Button, Modal } from 'antd';
import { useApp } from '@/utils/useapp';
import { get } from 'lodash';
import { TBill } from '@/types';
import { STATE_OPEN } from './data';
import getCanceledBillables from '@/utils/getCanceledBillables';

const BulkApprove: React.FC<{ bills: TBill[]; onSaved: () => void }> = ({
  bills,
  onSaved,
}) => {
  const app = useApp();

  const handleBulkApprove = async () => {
    try {
      await app.service.post('orderBills/bulk/approve', {
        data: {
          ids: bills.map((b) => b.id),
        },
      });
      message.success('Approved');
      onSaved();
    } catch (e: any) {
      message.error(
        get(e, 'data.message') || get(e, 'data.error') || 'System Error',
      );
    }
  };

  const toBulkApprove = async () => {
    if (bills.length === 0) {
      message.error('No bills selected');
      return;
    }

    if (bills.find((b) => b.state != STATE_OPEN)) {
      message.error('Can\'t not approve when bill is not open');
      return;
    }

    const canceledBillables = getCanceledBillables(bills);
    if (canceledBillables?.length > 0) {
      const cancelOrderUid = canceledBillables?.map((b) => b.uid);
      Modal.confirm({
        title: 'Warning',
        content: `Include cancel order, Bill might be wrong. Please check with OP. Cancel Order#: ${cancelOrderUid.join(
          ', ',
        )}`,
        onOk: handleBulkApprove,
        okText: 'Continue',
        cancelText: 'Cancel',
        maskClosable: true,
      });
    } else {
      handleBulkApprove();
    }
  };

  return (
    <>
      <Button type="primary" onClick={toBulkApprove}>
        Bulk Approve
      </Button>
    </>
  );
};

export default BulkApprove;
