import React, { useEffect } from 'react';
import {
  SELLRATE_UID_PREFIX,
  SellRateDrawer,
} from '@/pages/rates/sellRates/components/sellRateDrawer';
import {
  BUYRATE_UID_PREFIX,
  BuyRateDrawer,
} from '@/pages/rates/buyRates/components/buyRateDrawer';
import { SnashotRateModal } from '@/pages/orders/components/SnapshotRate/SnashotRateModal';
import { TOrder } from '@/types';
import { THasBanModifyFields } from './data';

export const OPEN_TYPE_BUY_RATE = 'BUY_RATE';
export const OPEN_TYPE_SELL_RATE = 'SELL_RATE';

export const isStringOfNumbers = (str: string) => {
  return /^\d+$/.test(str);
};

export const isBuyRate = (rateId: string) => {
  return new RegExp('^' + BUYRATE_UID_PREFIX + '\\d+').test(rateId);
};

export const isSellRate = (rateId: string) => {
  return new RegExp('^' + SELLRATE_UID_PREFIX + '\\d+').test(rateId);
};

export type TOrderProps = {
  order?: TOrder;
};

export type TContainerProps = {
  container?: any;
};

export type TSnapshotProps = {
  snapshotableId?: number;
  snapshotableType?: string;
};

export type TOpenTypeProps = {
  type?: 'BUY_RATE' | 'SELL_RATE';
};

type TOpenBuyRateOrSellRateDrawer = {
  rateUid: string | number;
  open: boolean;
  onSaved?: () => void;
  onClose: () => void;
};
export const OpenBuyRateOrSellRateDrawer: React.FC<
TOpenBuyRateOrSellRateDrawer &
TOpenTypeProps &
TSnapshotProps &
THasBanModifyFields
> = ({
  type = OPEN_TYPE_BUY_RATE,
  open,
  rateUid,
  snapshotableId,
  snapshotableType,
  hasBanModifyFields = false,
  onSaved,
  onClose,
}) => {
  const [openBuyRate, setOpenBuyRate] = React.useState(false);
  const [buyRateId, setBuyRateId] = React.useState(0);

  const [openSellRate, setOpenSellRate] = React.useState(false);
  const [sellRateId, setSellRateId] = React.useState(0);
  const banModifyFields = React.useMemo(() => {
    if (type === OPEN_TYPE_BUY_RATE) {
      return [
        'vendor_id',
        'intermodal_region_id',
        'from_facility_type',
        'to_city_id',
        'to_zipcode',
        'terminal_ids',
        'to_facility_type',
        'fba_facility_code',
        'regular_rate_items',
        'frontapp_message_id',
        'memo',
        'note',
      ];
    } else {
      return [
        'customer_id',
        'intermodal_region_id',
        'from_facility_type',
        'to_city_id',
        'to_zipcode',
        'terminal_ids',
        'to_facility_type',
        'fba_facility_code',
        'base_rate',
        'fuel_surcharge',
        'fuel_surcharge_percentage',
        'toll_fee',
        'total_rate',
        'memo',
        'note',
        'container_sizes',
      ];
    }
  }, [type]);

  const [isSnapshotRateModalVisible, setIsSnapshotRateModalVisible] =
    React.useState(false);

  const handleOpenRate = () => {
    if (isStringOfNumbers(rateUid)) {
      if (type === OPEN_TYPE_BUY_RATE) {
        setOpenBuyRate(true);
        setBuyRateId(Number(rateUid));
      } else {
        if (snapshotableId && snapshotableType) {
          handleOpenSnatshotRate();
        } else {
          handleOpenSellRate(rateUid);
        }
      }
    } else if (isBuyRate(rateUid)) {
      handleOpenBuyRate();
    } else if (isSellRate(rateUid)) {
      if (snapshotableId && snapshotableType) {
        handleOpenSnatshotRate();
      } else {
        handleOpenSellRate();
      }
    }
  };

  const handleOpenBuyRate = () => {
    const _rateId = Number(rateUid.replace(BUYRATE_UID_PREFIX, ''));
    setOpenBuyRate(true);
    setBuyRateId(_rateId);
  };

  const handleCloseBuyRate = () => {
    setOpenBuyRate(false);
    setBuyRateId(0);
    onClose();
  };

  const handleSavedBuyRate = () => {
    handleCloseBuyRate();
    onSaved && onSaved();
  };

  const handleOpenSellRate = () => {
    const _rateId = Number(rateUid.replace(SELLRATE_UID_PREFIX, ''));
    setSellRateId(_rateId);
    setOpenSellRate(true);
  };
  const handleCloseSellRate = () => {
    setOpenSellRate(false);
    setSellRateId(0);
    onClose();
  };

  const handleSavedSellRate = () => {
    handleCloseSellRate();
    onSaved && onSaved();
  };

  const handleOpenSnatshotRate = () => {
    setIsSnapshotRateModalVisible(true);
  };
  const handleCloseSnatshotRateModal = () => {
    setIsSnapshotRateModalVisible(false);
    onClose();
  };

  useEffect(() => {
    open && rateUid && handleOpenRate();
  }, [open, rateUid]);

  return (
    <>
      {openBuyRate && buyRateId && (
        <BuyRateDrawer
          isNew={false}
          disabled={hasBanModifyFields}
          id={buyRateId}
          open={openBuyRate}
          onSaved={handleSavedBuyRate}
          onClose={handleCloseBuyRate}
        />
      )}

      {openSellRate && sellRateId && (
        <SellRateDrawer
          visible={openSellRate}
          disabled={hasBanModifyFields}
          id={sellRateId}
          onSaved={handleSavedSellRate}
          onClose={handleCloseSellRate}
        />
      )}

      {isSnapshotRateModalVisible && snapshotableId && snapshotableType && (
        <SnashotRateModal
          rateUid={rateUid}
          snapshotableId={snapshotableId}
          snapshotableType={snapshotableType}
          hasBanModifyFields={hasBanModifyFields}
          open={isSnapshotRateModalVisible}
          onClose={handleCloseSnatshotRateModal}
        />
      )}
    </>
  );
};
