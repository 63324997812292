import { Col, Form, Input, Row, Select, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import React from 'react';
import { GeneralLedgerCodeSelect } from '@/components/GeneralLedgerCodeSelect';
import { UNIT_MAP as POSSIBLECHARNGE_UNIT_MAP } from '@/components/PossibleCharges';
import { FormInstance } from 'antd/es/form';
import { BILLING_CODE_GATEGORIES } from './constans';

const DefinitionTab: React.FC<{
  form: FormInstance;
  type: string;
}> = ({ form, type }) => (
  <>
    <Form.Item
      name={[type, 'definition']}
      label={'Definition'}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Input.TextArea
        value={form.getFieldValue([type, 'definition'])}
        rows={4}
        placeholder="Enter definition"
      />
    </Form.Item>
    <Form.Item
      name={[type, 'when_will_it_happen']}
      label={'Trigger When will it happen'}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Input.TextArea
        value={form.getFieldValue([type, 'when_will_it_happen'])}
        rows={4}
        placeholder="Enter when will it happen"
      />
    </Form.Item>
  </>
);

export const DrawerForm: React.FC<{
  form: FormInstance;
}> = ({ form }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'English',
      children: <DefinitionTab form={form} type={'en_definition'} />,
    },
    {
      key: '2',
      label: 'Chinese',
      children: <DefinitionTab form={form} type={'cn_definition'} />,
    },
  ];

  return (
    <div>
      <Form form={form}>
        <Row gutter={10}>
          <Col span={24}>
            <Form.Item
              label="Code"
              name="code"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Enter Code" />
            </Form.Item>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Enter Name" />
            </Form.Item>
            <Form.Item
              label="Category"
              name="category"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                options={BILLING_CODE_GATEGORIES.map((row) => ({
                  label: row,
                  value: row,
                  id: row,
                }))}
              />
            </Form.Item>
            <Form.Item
              label="Chinese Name"
              name="cn_name"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Enter Chinese Name" />
            </Form.Item>
            <Form.Item
              name="revenue_general_ledger_code_id"
              label="Revenue GL Code"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true }]}
            >
              <GeneralLedgerCodeSelect />
            </Form.Item>
            <Form.Item
              name="cost_general_ledger_code_id"
              label="Cost GL Code"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true }]}
            >
              <GeneralLedgerCodeSelect />
            </Form.Item>
            <Form.Item
              name="unit"
              label="Unit"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true }]}
            >
              <Select>
                {Object.keys(POSSIBLECHARNGE_UNIT_MAP).map((key) => (
                  <Select.Option key={+key} value={+key}>
                    {
                      POSSIBLECHARNGE_UNIT_MAP[
                        (key as unknown) as keyof typeof POSSIBLECHARNGE_UNIT_MAP
                      ]
                    }
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Tabs defaultActiveKey="1" items={items} />
            {/* <Form.Item
              name="definition"
              label="Definition"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input.TextArea
                value={form.getFieldValue('definition')}
                rows={4}
                placeholder="Enter definition"
              />
            </Form.Item> */}
          </Col>
        </Row>
      </Form>
    </div>
  );
};
