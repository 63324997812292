import {
  LoadingOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { Tag, Tooltip } from 'antd';
import type { FileStatus } from '../interface/types';

export const FileStatusIcon = ({
  status,
}: {
  status: FileStatus['status'];
}) => {
  switch (status) {
    case 'processing':
      return <LoadingOutlined style={{ color: '#1890ff' }} />;
    case 'completed':
      return <CheckCircleOutlined style={{ color: '#52c41a' }} />;
    case 'error':
      return <CloseCircleOutlined style={{ color: '#ff4d4f' }} />;
    default:
      return null;
  }
};

export const FileStatusText = ({
  status,
  documentType,
  errorMessage,
}: {
  status: FileStatus['status'];
  documentType?: string;
  errorMessage?: string;
}) => {
  if (status === 'processing') {
    return (
      <Tag color="processing">
        {documentType === 'invoice'
          ? 'Recognizing Content...'
          : 'Identifying File Type...'}
      </Tag>
    );
  }

  if (status === 'completed') {
    return <Tag color="success">Completed</Tag>;
  }

  if (status === 'error') {
    return (
      <Tooltip title={errorMessage}>
        <Tag color="error" style={{ cursor: 'help' }}>
          Failed
        </Tag>
      </Tooltip>
    );
  }

  return null;
};
