import {
  Card,
  Upload,
  Space,
  List,
  Typography,
  Tag,
  Tooltip,
  Select,
} from 'antd';
import { InboxOutlined, DeleteOutlined } from '@ant-design/icons';
import type { FileStatus, DocumentType, InvoiceType } from '../interface/types';
import { FileStatusIcon, FileStatusText } from './FileStatus';

interface Props {
  fileStatuses: FileStatus[];
  selectedFileId: string | null;
  onUpload: (file: File) => void;
  onFileSelect: (fileId: string) => void;
  onFileDelete?: (fileId: string) => void;
  onInvoiceTypeChange?: (fileId: string, type: InvoiceType) => void;
}

const DocumentUpload = ({
  fileStatuses,
  selectedFileId,
  onUpload,
  onFileSelect,
  onFileDelete,
  onInvoiceTypeChange,
}: Props) => {
  const beforeUpload = (file: File) => {
    onUpload(file);
    return false;
  };

  const getDocumentTypeTag = (
    type?: DocumentType,
    invoiceType?: InvoiceType,
    fileId?: string,
  ) => {
    const colorMap: Record<DocumentType, string> = {
      invoice: 'blue',
      mbl: 'green',
      'delivery order': 'red',
      'arrival notice': 'yellow',
      unknown: 'default',
    };

    const invoiceColorMap: Record<InvoiceType, string> = {
      DRAY: 'purple',
      LTL: 'orange',
      FTL: 'cyan',
    };

    return (
      <Space>
        {type && (
          <Tag color={colorMap[type]} style={{ marginLeft: 8 }}>
            {type.toUpperCase()}
          </Tag>
        )}
        {type === 'invoice' &&
          (invoiceType ? (
            <Tag color={invoiceColorMap[invoiceType]}>{invoiceType}</Tag>
          ) : (
            <Select
              size="small"
              style={{ width: 120 }}
              placeholder="Select type"
              onClick={(e) => e.stopPropagation()}
              onChange={(value) => {
                if (fileId && onInvoiceTypeChange) {
                  onInvoiceTypeChange(fileId, value as InvoiceType);
                }
              }}
            >
              <Select.Option value="DRAY">DRAY</Select.Option>
              <Select.Option value="LTL">LTL</Select.Option>
              <Select.Option value="FTL">FTL</Select.Option>
            </Select>
          ))}
      </Space>
    );
  };

  const renderFileStatus = (file: FileStatus) => {
    if (file.billCreated) {
      return (
        <>
          <Tag color="success">Completed</Tag>
          <Tag color="geekblue">Bill Created</Tag>
        </>
      );
    }
    return (
      <FileStatusText
        status={file.status}
        documentType={file.documentType}
        errorMessage={file.errorMessage}
      />
    );
  };

  return (
    <div className="document-upload-container">
      <Card
        title={
          <Space>
            Document Import
            <Tag color="warning">Experimental</Tag>
          </Space>
        }
        className="section-card"
        bordered
      >
        <Upload.Dragger
          multiple={true}
          showUploadList={false}
          beforeUpload={beforeUpload}
          accept=".pdf,.jpg,.jpeg,.png"
          className="upload-area"
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag files to this area to upload
          </p>
          <p className="ant-upload-hint">Support for PDF and image files</p>
        </Upload.Dragger>
      </Card>

      <Card
        className="file-list-card"
        size="small"
        title="Uploaded Files"
        bordered={false}
      >
        <List
          dataSource={fileStatuses}
          renderItem={(file) => (
            <List.Item
              key={file.fileId}
              className={`file-list-item ${
                selectedFileId === file.fileId ? 'selected' : ''
              }`}
              onClick={() => onFileSelect(file.fileId)}
              actions={[
                <DeleteOutlined
                  key="delete"
                  className="delete-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    onFileDelete?.(file.fileId);
                  }}
                />,
              ]}
              style={{
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                margin: '8px 0',
              }}
            >
              <Space direction="vertical" style={{ width: '100%' }}>
                <Space>
                  <FileStatusIcon status={file.status} />
                  <Tooltip title={file.fileName}>
                    <Typography.Text strong>
                      {file.fileName.length > 10
                        ? `${file.fileName.slice(0, 12)}...`
                        : file.fileName}
                    </Typography.Text>
                  </Tooltip>
                  {getDocumentTypeTag(
                    file.documentType,
                    file.invoiceType,
                    file.fileId,
                  )}
                </Space>
                <Space className="file-info">
                  <Typography.Text
                    type="secondary"
                    style={{ fontSize: '12px' }}
                  >
                    {file.uploadTime}
                  </Typography.Text>
                  {renderFileStatus(file)}
                </Space>
              </Space>
            </List.Item>
          )}
        />
      </Card>
    </div>
  );
};

export default DocumentUpload;
