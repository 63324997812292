import React from 'react';
import { Form, FormInstance, Switch, Input, Select } from 'antd';
import { KEY_EXPIRED_RATE_INVISIBLE } from './data';
interface Props {
  form: FormInstance;
  onSubmit: () => void;
}

export const CardForm: React.FC<Props> = ({ form, onSubmit }) => {
  const onChange = () => {
    onSubmit();
  };

  return (
    <Form form={form} onFinish={onSubmit} layout="vertical">
      <Form.Item
        label="Make the expired rate invisible on user portal"
        name="expired_rate_invisible"
        valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item
        label="Company Name"
        name={['company', 'name']}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}>
        <Input placeholder="Enter Name" />
      </Form.Item>
      <Form.Item
        label="Company Address"
        name={['company', 'address']}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}>
        <Input.TextArea rows={6} placeholder="Enter Company Address" />
      </Form.Item>
      <Form.Item
        label="Company Phone"
        name={['company', 'phone']}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}>
        <Input placeholder="Enter Company Phone" />
      </Form.Item>
      <Form.Item
        label="Company Fax"
        name={['company', 'fax']}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}>
        <Input placeholder="Enter Company fax" />
      </Form.Item>
      <Form.Item
        label="Invoice Note"
        name={['company', 'invoice_note']}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}>
        <Input.TextArea rows={3} placeholder="Enter Invoice Note" />
      </Form.Item>
    </Form>
  );
};
