export const HAZMAT_CLASS_CLASS_1_EXPLOSIVES = 10;
export const HAZMAT_CLASS_DIVISION_1_1_SUBSTANCES_AND_ARTICLES_WHICH_HAVE_A_MASS_EXPLOSION_HAZARD = 11;
export const HAZMAT_CLASS_DIVISION_1_2_SUBSTANCES_AND_ARTICLES_WHICH_HAVE_A_PROJECTION_HAZARD_BUT_NOT_A_MASS_EXPLOSION_HAZARD = 12;
export const HAZMAT_CLASS_DIVISION_1_3_SUBSTANCES_AND_ARTICLES_WHICH_HAVE_A_FIRE_HAZARD_AND_EITHER_A_MINOR_BLAST_HAZARD_OR_A_MINOR_PROJECTION_HAZARD_OR_BOTH_BUT_NOT_A_MASS_EXPLOSION_HAZARD = 13;
export const HAZMAT_CLASS_DIVISION_1_4_SUBSTANCES_AND_ARTICLES_WHICH_PRESENT_NO_SIGNIFICANT_HAZARD = 14;
export const HAZMAT_CLASS_DIVISION_1_5_VERY_INSENSITIVE_SUBSTANCES_WHICH_HAVE_A_MASS_EXPLOSION_HAZARD = 15;
export const HAZMAT_CLASS_DIVISION_1_6_EXTREMELY_INSENSITIVE_ARTICLES_WHICH_DO_NOT_HAVE_A_MASS_EXPLOSION_HAZARD = 16;
export const HAZMAT_CLASS_CLASS_2_GASES = 20;
export const HAZMAT_CLASS_DIVISION_2_1_FLAMMABLE_GASES = 21;
export const HAZMAT_CLASS_DIVISION_2_2_NON_FLAMMABLE_NON_TOXIC_GASES = 22;
export const HAZMAT_CLASS_DIVISION_2_3_TOXIC_GASES = 23;
export const HAZMAT_CLASS_CLASS_3_FLAMMABLE_LIQUIDS = 30;
export const HAZMAT_CLASS_CLASS_4_FLAMMABLE_SOLIDS = 40;
export const HAZMAT_CLASS_DIVISION_4_1_FLAMMABLE_SOLIDS = 41;
export const HAZMAT_CLASS_DIVISION_4_2_SPONTANEOUSLY_COMBUSTIBLE_MATERIALS = 42;
export const HAZMAT_CLASS_DIVISION_4_3_DANGEROUS_WHEN_WET_MATERIALS = 43;
export const HAZMAT_CLASS_CLASS_5_OXIDIZERS_AND_ORGANIC_PEROXIDES = 50;
export const HAZMAT_CLASS_DIVISION_5_1_OXIDIZERS = 51;
export const HAZMAT_CLASS_DIVISION_5_2_ORGANIC_PEROXIDES = 52;
export const HAZMAT_CLASS_CLASS_6_TOXIC_AND_INFECTIOUS_SUBSTANCES = 60;
export const HAZMAT_CLASS_DIVISION_6_1_TOXIC_SUBSTANCES = 61;
export const HAZMAT_CLASS_DIVISION_6_2_INFECTIOUS_SUBSTANCES = 62;
export const HAZMAT_CLASS_CLASS_7_RADIOACTIVE_MATERIALS = 70;
export const HAZMAT_CLASS_CLASS_8_CORROSIVE_SUBSTANCES = 80;
export const HAZMAT_CLASS_CLASS_9_MISCELLANEOUS_DANGEROUS_GOODS = 90;

export const HAZMAT_CLASS_MAP = {
  [HAZMAT_CLASS_CLASS_1_EXPLOSIVES]: 'Class 1: Explosives',
  [HAZMAT_CLASS_DIVISION_1_1_SUBSTANCES_AND_ARTICLES_WHICH_HAVE_A_MASS_EXPLOSION_HAZARD]:
    'Division 1.1: Substances and articles which have a mass explosion hazard',
  [HAZMAT_CLASS_DIVISION_1_2_SUBSTANCES_AND_ARTICLES_WHICH_HAVE_A_PROJECTION_HAZARD_BUT_NOT_A_MASS_EXPLOSION_HAZARD]:
    'Division 1.2: Substances and articles which have a projection hazard but not a mass explosion hazard',
  [HAZMAT_CLASS_DIVISION_1_3_SUBSTANCES_AND_ARTICLES_WHICH_HAVE_A_FIRE_HAZARD_AND_EITHER_A_MINOR_BLAST_HAZARD_OR_A_MINOR_PROJECTION_HAZARD_OR_BOTH_BUT_NOT_A_MASS_EXPLOSION_HAZARD]:
    'Division 1.3: Substances and articles which have a fire hazard and either a minor blast hazard or a minor projection hazard or both, but not a mass explosion hazard',
  [HAZMAT_CLASS_DIVISION_1_4_SUBSTANCES_AND_ARTICLES_WHICH_PRESENT_NO_SIGNIFICANT_HAZARD]:
    'Division 1.4: Substances and articles which present no significant hazard',
  [HAZMAT_CLASS_DIVISION_1_5_VERY_INSENSITIVE_SUBSTANCES_WHICH_HAVE_A_MASS_EXPLOSION_HAZARD]:
    'Division 1.5: Very insensitive substances which have a mass explosion hazard',
  [HAZMAT_CLASS_DIVISION_1_6_EXTREMELY_INSENSITIVE_ARTICLES_WHICH_DO_NOT_HAVE_A_MASS_EXPLOSION_HAZARD]:
    'Division 1.6: Extremely insensitive articles which do not have a mass explosion hazard',
  [HAZMAT_CLASS_CLASS_2_GASES]: 'Class 2: Gases',
  [HAZMAT_CLASS_DIVISION_2_1_FLAMMABLE_GASES]: 'Division 2.1: Flammable gases',
  [HAZMAT_CLASS_DIVISION_2_2_NON_FLAMMABLE_NON_TOXIC_GASES]:
    'Division 2.2: Non-flammable, non-toxic gases',
  [HAZMAT_CLASS_DIVISION_2_3_TOXIC_GASES]: 'Division 2.3: Toxic gases',
  [HAZMAT_CLASS_CLASS_3_FLAMMABLE_LIQUIDS]: 'Class 3: Flammable liquids',
  [HAZMAT_CLASS_CLASS_4_FLAMMABLE_SOLIDS]: 'Class 4: Flammable solids',
  [HAZMAT_CLASS_DIVISION_4_1_FLAMMABLE_SOLIDS]:
    'Division 4.1: Flammable solids',
  [HAZMAT_CLASS_DIVISION_4_2_SPONTANEOUSLY_COMBUSTIBLE_MATERIALS]:
    'Division 4.2: Spontaneously combustible materials',
  [HAZMAT_CLASS_DIVISION_4_3_DANGEROUS_WHEN_WET_MATERIALS]:
    'Division 4.3: Dangerous when wet materials',
  [HAZMAT_CLASS_CLASS_5_OXIDIZERS_AND_ORGANIC_PEROXIDES]:
    'Class 5: Oxidizers and organic peroxides',
  [HAZMAT_CLASS_DIVISION_5_1_OXIDIZERS]: 'Division 5.1: Oxidizers',
  [HAZMAT_CLASS_DIVISION_5_2_ORGANIC_PEROXIDES]:
    'Division 5.2: Organic peroxides',
  [HAZMAT_CLASS_CLASS_6_TOXIC_AND_INFECTIOUS_SUBSTANCES]:
    'Class 6: Toxic and infectious substances',
  [HAZMAT_CLASS_DIVISION_6_1_TOXIC_SUBSTANCES]:
    'Division 6.1: Toxic substances',
  [HAZMAT_CLASS_DIVISION_6_2_INFECTIOUS_SUBSTANCES]:
    'Division 6.2: Infectious substances',
  [HAZMAT_CLASS_CLASS_7_RADIOACTIVE_MATERIALS]:
    'Class 7: Radioactive materials',
  [HAZMAT_CLASS_CLASS_8_CORROSIVE_SUBSTANCES]: 'Class 8: Corrosive substances',
  [HAZMAT_CLASS_CLASS_9_MISCELLANEOUS_DANGEROUS_GOODS]:
    'Class 9: Miscellaneous dangerous goods',
};
