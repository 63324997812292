import React from 'react';

import { isArray, isObject, upperFirst } from 'lodash';
import DefaultIcon from './DefaultIcon';

const TimeDetail: React.FC<{
  time: string | { start: string; end: string };
}> = ({ time }) => {
  if (!time) {
    return <DefaultIcon />;
  }

  if (isObject(time)) {
    return <span className="text-link">{`${time.start} - ${time.end}`}</span>;
  }

  return <span className="text-link">{upperFirst(time)}</span>;
};

export default TimeDetail;
