import { Button, Col, Row, Space } from 'antd';
import update from 'immutability-helper';

import React, { useEffect, useState } from 'react';
import { Filter as LTLShipmentFilter } from '@/pages/truckload/ltl/components/Filter';
import dataKeys from '@/pages/truckload/ltl/components/Filter/data';
import { TabFilterType } from '@/Interfaces/TabFilterType';
import { omit, values } from 'lodash';

export type TFilter = {
  loading: boolean;
  initShow: boolean;
  activeFilters: any[] | [];
  onApply: (values: any) => void;
  setInitShow: (initShow: boolean) => void;
  onSearch: () => void;
};

const Filter: React.FC<TFilter> = ({
  loading,
  initShow = false,
  activeFilters,
  onApply,
  setInitShow,
  onSearch,
}) => {
  const [show, setShow] = useState(false);
  const [isShowFilter, setIsShowFilter] = useState(false);

  const [filters, setFilters] = React.useState<any[] | null>(null);

  const handleChangeFilters = async (filters: TabFilterType[]) => {
    setFilters(filters);
    onApply(filters);
  };

  return (
    <Row>
      <Col span={20}>
        <LTLShipmentFilter
          show={isShowFilter}
          values={filters || []}
          onChange={(v: any) => {
            handleChangeFilters(v);
          }}
          allowAttributes={Object.values(dataKeys).filter(
            (item) => item != dataKeys.BUSINESS_STATUS,
          )}
          removeAll={() => handleChangeFilters([])}
          removeOne={(index: number) =>
            handleChangeFilters(
              update(activeFilters, { $splice: [[index, 1]] }),
            )
          }
          activeFilters={activeFilters || []}
          hideFilter={() => setIsShowFilter(false)}
          loading={loading}
        />
      </Col>
      <Col
        span={4}
        style={{
          marginTop: isShowFilter ? '48px' : '0',
          marginBottom: isShowFilter ? '0' : '20px',
        }}
      >
        <Space>
          <Button
            size="small"
            onClick={() => {
              setIsShowFilter((isShowFilter) => !isShowFilter);
            }}
          >
            {isShowFilter ? 'Hide Filters' : 'Show Filters'}
          </Button>
          <Button size="small" type="primary" onClick={onSearch}>
            Refresh
          </Button>
        </Space>
      </Col>
    </Row>
  );
};

export default Filter;
