export const charCompare = (a: string, b: string) => {
  const aArr = a.split('');
  const bArr = b.split('');

  let i = 0;
  while (i < aArr.length && i < bArr.length) {
    if (aArr[i] > bArr[i]) {
      return 1;
    } else if (aArr[i] < bArr[i]) {
      return -1;
    }
    i++;
  }

  if (i === bArr.length) {
    return -1;
  }
  return 1;
};
