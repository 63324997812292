import { Card, Empty, Space, Tag } from 'antd';
import type { FileStatus, ShipmentData } from '../../interface/types';
import type { RecognitionResult } from '../../interface/types';
import BasicInformation from './BasicInformation';
import ChargesSection from './ChargesSection';
import MatchedShipmentCard from './MatchedShipmentCard';

interface Props {
  result: RecognitionResult | null;
  onResultChange?: (key: string, value: any) => void;
  file: FileStatus | null;
  shipmentData?: ShipmentData;
  fileContent?: string;
  onSaveResult?: (result: RecognitionResult) => Promise<void>;
}

const RecognitionResults = ({
  result,
  onResultChange,
  file,
  shipmentData,
  fileContent,
  onSaveResult,
}: Props) => {
  const getDocumentTypeTag = (type?: string, invoiceType?: string) => {
    const colorMap: Record<string, string> = {
      invoice: 'blue',
      mbl: 'green',
      unknown: 'default',
    };

    const invoiceColorMap: Record<string, string> = {
      DRAY: 'purple',
      LTL: 'orange',
      FTL: 'cyan',
    };

    return (
      <Space>
        {type && (
          <Tag color={colorMap[type]}>{type.toString().toUpperCase()}</Tag>
        )}
        {type === 'invoice' && invoiceType && (
          <Tag color={invoiceColorMap[invoiceType]}>{invoiceType}</Tag>
        )}
      </Space>
    );
  };

  return (
    <div className="recognition-results-container">
      <Card
        title={
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <span>Recognition Results</span>
            {file && getDocumentTypeTag(file.documentType, file.invoiceType)}
          </div>
        }
        className="section-card"
      >
        {result ? (
          <div style={{ padding: '0px', margin: '0px' }}>
            <BasicInformation
              result={result}
              onResultChange={onResultChange}
              file={file}
            />
            <ChargesSection
              result={result}
              onResultChange={onResultChange}
              file={file}
            />
          </div>
        ) : (
          <Empty description="No recognition results" />
        )}
      </Card>

      <MatchedShipmentCard
        result={result}
        file={file}
        fileContent={fileContent}
        shipmentData={shipmentData}
        onResultChange={onResultChange}
        onSaveResult={onSaveResult}
      />
    </div>
  );
};

export default RecognitionResults;
