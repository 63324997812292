import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Col,
  Divider,
  Form,
  message,
  Row,
  Space,
  Table,
  Tabs,
} from 'antd';
import React, { FC, useMemo, useState } from 'react';
import { Header } from '@/components/CommonHeader';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import TabPane from 'antd/lib/tabs/TabPane';
import { useHistory } from 'umi';
import moment from 'moment-timezone';
import {
  STATUS_IN_DISPUTE,
  STATUS_CHARGES_VALID,
  STATUS_REQUESTED,
  STATUS_ACCEPTED,
  STATUS_INVOICE_RECEIVED,
} from '../accounting/bills/components/data';

const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const AP = 'ap';
const AR = 'ar';

const redirectToInovices = (
  userId: string,
  salesId: string,
  type: string,
  state: string | null = null,
) => {
  let query = `?salesId=${salesId}`;

  if (userId) {
    query += `&userId=${userId}`;
  }

  if (type === 'due_61') {
    query +=
      '&dueStartDate=' +
      moment().subtract(365, 'days').format('YYYY-MM-DD') +
      '&dueEndDate=' +
      moment().subtract(61, 'days').format('YYYY-MM-DD');
  } else if (type === 'due_31_60') {
    query +=
      '&dueStartDate=' +
      moment().subtract(60, 'days').format('YYYY-MM-DD') +
      '&dueEndDate=' +
      moment().subtract(31, 'days').format('YYYY-MM-DD');
  } else if (type === 'due_1_30') {
    query +=
      '&dueStartDate=' +
      moment().subtract(30, 'days').format('YYYY-MM-DD') +
      '&dueEndDate=' +
      moment().format('YYYY-MM-DD');
  } else if (type === 'current') {
    query +=
      '&dueStartDate=' +
      moment().add(1, 'days').format('YYYY-MM-DD') +
      '&dueEndDate=' +
      moment().add(365, 'days').format('YYYY-MM-DD');
  }

  if (state) {
    query += `&state=${state}`;
  }
  // history.push('/accounting/invoices' + query);
  window.open('/accounting/invoices' + query, '_blank');
};

const redirectTobills = (
  billTo: string,
  type: string,
  salesId: string,
  state: number | null = null,
) => {
  let query = `?salesId=${salesId}`;

  if (billTo) {
    query += `&billTo=${billTo}`;
  }
  if (type === 'due_31') {
    query +=
      '&dueStartDate=' +
      moment().subtract(365, 'days').format('YYYY-MM-DD') +
      '&dueEndDate=' +
      moment().subtract(31, 'days').format('YYYY-MM-DD');
  } else if (type === 'due_1_30') {
    query +=
      '&dueStartDate=' +
      moment().subtract(30, 'days').format('YYYY-MM-DD') +
      '&dueEndDate=' +
      moment().format('YYYY-MM-DD');
  } else if (type === 'before_due_5') {
    query +=
      '&dueStartDate=' +
      moment().format('YYYY-MM-DD') +
      '&dueEndDate=' +
      moment().add(5, 'days').format('YYYY-MM-DD');
  } else if (type === 'current') {
    query +=
      '&dueStartDate=' +
      moment().add(1, 'days').format('YYYY-MM-DD') +
      '&dueEndDate=' +
      moment().add(365, 'days').format('YYYY-MM-DD');
  }

  if (state) {
    query += `&state=${state}`;
  }
  // history.push('/accounting/bills' + query);
  window.open('/accounting/bills' + query, '_blank');
};

const DisputeTable: FC<{
  data: any;
  type: string;
  loading: boolean;
  saleId: string;
}> = ({ data, type, loading, saleId }) => {
  const columns = useMemo(() => {
    if (type == AR) {
      return [
        {
          title: 'Cutsomer',
          dataIndex: 'customer',
          key: 'customer',
        },
        {
          title: 'Dispute#',
          dataIndex: 'dispute_count',
          key: 'dispute_count',
          render: (text: any, record: any) => {
            return (
              <a
                onClick={() =>
                  redirectToInovices(record.customer_id, saleId, '', 'dispute')
                }>
                {text}
              </a>
            );
          },
        },
        {
          title: 'Dispute Amount',
          dataIndex: 'dispute_amount',
          key: 'dispute_amount',
          render: (text: string, record: any) => {
            return (
              <a
                onClick={() =>
                  redirectToInovices(record.customer_id, saleId, '', 'dispute')
                }>
                {USDollar.format(+text)}
              </a>
            );
          },
        },
      ];
    } else if (type == AP) {
      return [
        {
          title: 'Vendor',
          dataIndex: 'vendor',
          key: 'vendor',
        },
        {
          title: 'Container Count',
          dataIndex: 'dispute_count',
          key: 'dispute_count',
          // render: (text: any, record: any) => {
          //   return <a onClick={() =>
          //     redirectTobills(record.vendor, '', saleId)
          //   }>
          //     {text}
          //   </a>;
          // }
        },
        {
          title: 'In Dispute',
          dataIndex: 'in_dispute',
          key: 'in_dispute',
          render: (text: string, record: any) => {
            return (
              <a
                onClick={() =>
                  redirectTobills(record.vendor, '', saleId, STATUS_IN_DISPUTE)
                }>
                {text}
              </a>
            );
          },
        },
        {
          title: 'Dispute Requested',
          dataIndex: 'dispute_requestd',
          key: 'dispute_requestd',
          render: (text: string, record: any) => {
            return (
              <a
                onClick={() =>
                  redirectTobills(record.vendor, '', saleId, STATUS_REQUESTED)
                }>
                {text}
              </a>
            );
          },
        },
        {
          title: 'Charges Valid',
          dataIndex: 'charges_valid',
          key: 'charges_valid',
          render: (text: string, record: any) => {
            return (
              <a
                onClick={() =>
                  redirectTobills(
                    record.vendor,
                    '',
                    saleId,
                    STATUS_CHARGES_VALID,
                  )
                }>
                {text}
              </a>
            );
          },
        },
        {
          title: 'Dispute Accepted',
          dataIndex: 'dispute_accepted',
          key: 'dispute_accepted',
          render: (text: string, record: any) => {
            return (
              <a
                onClick={() =>
                  redirectTobills(record.vendor, '', saleId, STATUS_ACCEPTED)
                }>
                {text}
              </a>
            );
          },
        },
        {
          title: 'Invoice Recevied',
          dataIndex: 'invoice_received',
          key: 'invoice_received',
          render: (text: string, record: any) => {
            return (
              <a
                onClick={() =>
                  redirectTobills(
                    record.vendor,
                    '',
                    saleId,
                    STATUS_INVOICE_RECEIVED,
                  )
                }>
                {text}
              </a>
            );
          },
        },
      ];
    }
  }, [data, type]);

  return (
    <Space direction="vertical">
      <strong>
        Status OP closed but not all issued :{' '}
        {data?.opClosedAndNotAllIssuedCount || 0}
      </strong>
      <strong>Non dispute: {data?.nonDisputeCount || 0}</strong>
      <strong>- dispute per customer: </strong>
      <Table
        loading={loading}
        pagination={false}
        size="small"
        columns={columns}
        dataSource={data?.data || []}
      />
    </Space>
  );
};

const OverdueTable: FC<{ data: any; loading: boolean }> = ({
  data,
  loading,
}) => {
  const columns = [
    {
      title: 'Cutsomer Name',
      dataIndex: 'user_name',
      key: 'user_name',
      render: (text: string, record: any) => {
        return (
          <>
            {text} {text == 'Total' ? '' : `(${record.company || ''})`}{' '}
          </>
        );
      },
    },
    {
      title: 'Terms',
      dataIndex: 'terms',
      key: 'terms',
    },

    {
      title: 'Over 60',
      children: [
        {
          title: 'Total',
          dataIndex: 'due_61',
          render: (text: string, record: any) => {
            return (
              <a
                onClick={() =>
                  redirectToInovices(
                    record.user_name == 'Total' ? null : record.user_id,
                    record.sales_id,
                    'due_61',
                  )
                }>
                {USDollar.format(+text)}
              </a>
            );
          },
        },
        {
          title: 'Sales Not Confirm',
          dataIndex: 'due_61_sales_not_confirmed',
          key: 'due_61_sales_not_confirmed',
          render: (text: string, record: any) => {
            return (
              <a
                onClick={() =>
                  redirectToInovices(
                    record.user_name == 'Total' ? null : record.user_id,
                    record.sales_id,
                    'due_61',
                    'sales_not_confirmed',
                  )
                }>
                {USDollar.format(+text)}
              </a>
            );
          },
        },
        {
          title: 'Customer Not Confirm',
          dataIndex: 'due_61_customer_not_confirmed',
          key: 'due_61_customer_not_confirmed',
          render: (text: string, record: any) => {
            return (
              <a
                onClick={() =>
                  redirectToInovices(
                    record.user_name == 'Total' ? null : record.user_id,
                    record.sales_id,
                    'due_61',
                    'customer_not_confirmed',
                  )
                }>
                {USDollar.format(+text)}
              </a>
            );
          },
        },
        {
          title: 'Dispute',
          dataIndex: 'due_61_dispute',
          key: 'due_61_dispute',
          render: (text: string, record: any) => {
            return (
              <a
                onClick={() =>
                  redirectToInovices(
                    record.user_name == 'Total' ? null : record.user_id,
                    record.sales_id,
                    'due_61',
                    'dispute',
                  )
                }>
                {USDollar.format(+text)}
              </a>
            );
          },
        },
      ],
    },
    {
      title: 'Over 31-60',
      children: [
        {
          title: 'Total',
          key: 'due_31_60',
          dataIndex: 'due_31_60',
          render: (text: string, record: any) => {
            return (
              <a
                onClick={() =>
                  redirectToInovices(
                    record.user_name == 'Total' ? null : record.user_id,
                    record.sales_id,
                    'due_31_60',
                  )
                }>
                {USDollar.format(+text)}
              </a>
            );
          },
        },
        {
          title: 'Sales Not Confirm',
          dataIndex: 'due_31_60_sales_not_confirmed',
          key: 'due_31_60_sales_not_confirmed',
          render: (text: string, record: any) => {
            return (
              <a
                onClick={() =>
                  redirectToInovices(
                    record.user_name == 'Total' ? null : record.user_id,
                    record.sales_id,
                    'due_31_60',
                    'sales_not_confirmed',
                  )
                }>
                {USDollar.format(+text)}
              </a>
            );
          },
        },
        {
          title: 'Customer Not Confirm',
          dataIndex: 'due_31_60_customer_not_confirmed',
          key: 'due_31_60_customer_not_confirmed',
          render: (text: string, record: any) => {
            return (
              <a
                onClick={() =>
                  redirectToInovices(
                    record.user_name == 'Total' ? null : record.user_id,
                    record.sales_id,
                    'due_31_60',
                    'customer_not_confirmed',
                  )
                }>
                {USDollar.format(+text)}
              </a>
            );
          },
        },
        {
          title: 'Dispute',
          dataIndex: 'due_31_60_dispute',
          key: 'due_31_60_dispute',
          render: (text: string, record: any) => {
            return (
              <a
                onClick={() =>
                  redirectToInovices(
                    record.user_name == 'Total' ? null : record.user_id,
                    record.sales_id,
                    'due_31_60',
                    'dispute',
                  )
                }>
                {USDollar.format(+text)}
              </a>
            );
          },
        },
      ],
    },
    {
      title: 'Over 1-30',
      children: [
        {
          title: 'Total',
          dataIndex: 'due_1_30',
          key: 'due_1_30',
          render: (text: string, record: any) => {
            return (
              <a
                onClick={() =>
                  redirectToInovices(
                    record.user_name == 'Total' ? null : record.user_id,
                    record.sales_id,
                    'due_1_30',
                  )
                }>
                {USDollar.format(+text)}
              </a>
            );
          },
        },
        {
          title: 'Sales Not Confirm',
          dataIndex: 'due_1_30_sales_not_confirmed',
          key: 'due_1_30_sales_not_confirmed',
          render: (text: string, record: any) => {
            return (
              <a
                onClick={() =>
                  redirectToInovices(
                    record.user_name == 'Total' ? null : record.user_id,
                    record.sales_id,
                    'due_1_30',
                    'sales_not_confirmed',
                  )
                }>
                {USDollar.format(+text)}
              </a>
            );
          },
        },
        {
          title: 'Customer Not Confirm',
          dataIndex: 'due_1_30_customer_not_confirmed',
          key: 'due_1_30_customer_not_confirmed',
          render: (text: string, record: any) => {
            return (
              <a
                onClick={() =>
                  redirectToInovices(
                    record.user_name == 'Total' ? null : record.user_id,
                    record.sales_id,
                    'due_1_30',
                    'customer_not_confirmed',
                  )
                }>
                {USDollar.format(+text)}
              </a>
            );
          },
        },
        {
          title: 'Dispute',
          dataIndex: 'due_1_30_dispute',
          key: 'due_1_30_dispute',
          render: (text: string, record: any) => {
            return (
              <a
                onClick={() =>
                  redirectToInovices(
                    record.user_name == 'Total' ? null : record.user_id,
                    record.sales_id,
                    'due_1_30',
                    'dispute',
                  )
                }>
                {USDollar.format(+text)}
              </a>
            );
          },
        },
      ],
    },
    {
      title: 'Current',
      children: [
        {
          title: 'Total',
          dataIndex: 'current',
          key: 'current',
          render: (text: string, record: any) => {
            return (
              <a
                onClick={() =>
                  redirectToInovices(
                    record.user_name == 'Total' ? null : record.user_id,
                    record.sales_id,
                    'current',
                  )
                }>
                {USDollar.format(+text)}
              </a>
            );
          },
        },
        {
          title: 'Sales Not Confirm',
          dataIndex: 'current_sales_not_confirmed',
          key: 'current_sales_not_confirmed',
          render: (text: string, record: any) => {
            return (
              <a
                onClick={() =>
                  redirectToInovices(
                    record.user_name == 'Total' ? null : record.user_id,
                    record.sales_id,
                    'current',
                    'sales_not_confirmed',
                  )
                }>
                {USDollar.format(+text)}
              </a>
            );
          },
        },
        {
          title: 'Customer Not Confirm',
          dataIndex: 'current_customer_not_confirmed',
          key: 'current_customer_not_confirmed',
          render: (text: string, record: any) => {
            return (
              <a
                onClick={() =>
                  redirectToInovices(
                    record.user_name == 'Total' ? null : record.user_id,
                    record.sales_id,
                    'current',
                    'customer_not_confirmed',
                  )
                }>
                {USDollar.format(+text)}
              </a>
            );
          },
        },
        {
          title: 'Dispute',
          dataIndex: 'current_dispute',
          key: 'current_dispute',
          render: (text: string, record: any) => {
            return (
              <a
                onClick={() =>
                  redirectToInovices(
                    record.user_name == 'Total' ? null : record.user_id,
                    record.sales_id,
                    'current',
                    'dispute',
                  )
                }>
                {USDollar.format(+text)}
              </a>
            );
          },
        },
      ],
    },
  ];

  return (
    <Space direction="vertical">
      <strong>AR Overdue {data?.arOverdue || 0}</strong>
      <strong>- aging report</strong>
      <Table
        loading={loading}
        pagination={false}
        size="small"
        columns={columns}
        dataSource={data?.data || []}
      />
    </Space>
  );
};

const PendingApprovalTable: FC<{ data: any; loading: boolean }> = ({
  data,
  loading,
}) => {
  const columns = [
    {
      title: 'Vendor Name',
      dataIndex: 'vendor_name',
      key: 'vendor_name',
    },
    {
      title: 'Terms',
      dataIndex: 'terms',
      key: 'terms',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (text: string, record: any) => {
        return (
          <a
            onClick={() =>
              redirectTobills(record.vendor_name, 'total', record.sales_id)
            }>
            {USDollar.format(+text)}
          </a>
        );
      },
    },
    {
      title: 'Over 31+',
      key: 'due_31',
      render(text: string, record: any) {
        return (
          <a
            onClick={() =>
              redirectTobills(record.vendor_name, 'due_31', record.sales_id)
            }>
            {USDollar.format(record.due_31_60 + record.due_61)}
          </a>
        );
      },
    },
    {
      title: 'Over 1-30',
      dataIndex: 'due_1_30',
      key: 'due_1_30',
      render: (text: string, record: any) => {
        return (
          <a
            onClick={() =>
              redirectTobills(record.vendor_name, 'due_1_30', record.sales_id)
            }>
            {USDollar.format(+text)}
          </a>
        );
      },
    },
    {
      title: '5 days Before Overdue',
      dataIndex: 'before_due_5',
      key: 'before_due_5',
      render: (text: string, record: any) => {
        return (
          <a
            onClick={() =>
              redirectTobills(
                record.vendor_name,
                'before_due_5',
                record.sales_id,
              )
            }>
            {USDollar.format(record.before_due_5)}
            {record.before_due_5_dispute > 0
              ? `(${USDollar.format(record.before_due_5_dispute)})`
              : 0}
          </a>
        );
      },
    },
    {
      title: 'Current',
      dataIndex: 'current',
      key: 'current',
      render: (text: string, record: any) => {
        return (
          <a
            onClick={() =>
              redirectTobills(record.vendor_name, 'current', record.sales_id)
            }>
            {USDollar.format(+text)}
          </a>
        );
      },
    },
  ];

  return (
    <Space direction="vertical">
      <strong>Pending AP Approval {data?.pendingApApproval || 0}</strong>
      <strong>
        - aging report (Only Pending Approve Amount){' '}
        {data?.agingReport ? USDollar.format(data?.agingReport) : 0}
      </strong>
      <Table
        loading={loading}
        pagination={false}
        size="small"
        columns={columns}
        dataSource={data?.data || []}
      />
    </Space>
  );
};

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [arData, setArData] = useState(null);
  const [apData, setApData] = useState(null);
  const [arOverdue, setArOverdue] = useState([]);
  const [pendingApprovalData, setPendingApprovalData] = useState(null);

  const [filter] = useForm();
  const admin = useWatch('admin_id', filter);

  const fetchArData = React.useCallback(async () => {
    setLoading(true);

    const _filter = Object.assign({}, filter.getFieldsValue());

    try {
      const resp = await app.service.get('csdashboard/dispute', {
        params: {
          ..._filter,
          model: AR,
        },
      });

      setArData(resp.data || []);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchAROverdue = React.useCallback(async () => {
    setLoading(true);
    const _filter = Object.assign({}, filter.getFieldsValue());
    try {
      const resp = await app.service.get('csdashboard/arOverdue', {
        params: {
          ..._filter,
        },
      });
      setArOverdue(resp || []);
      // setArData(resp.data || []);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchApDate = React.useCallback(async () => {
    setLoading(true);
    const _filter = Object.assign({}, filter.getFieldsValue());
    try {
      const resp = await app.service.get('csdashboard/dispute', {
        params: {
          ..._filter,
          model: AP,
        },
      });

      setApData(resp.data || []);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchPendingApApproval = React.useCallback(async () => {
    setLoading(true);
    const _filter = Object.assign({}, filter.getFieldsValue());
    try {
      const resp = await app.service.get('csdashboard/pendingApApproval', {
        params: {
          ..._filter,
          model: AP,
        },
      });

      setPendingApprovalData(resp);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  }, []);

  const search = () => {
    fetchArData();
    fetchAROverdue();
    fetchApDate();
    fetchPendingApApproval();
  };

  return (
    <div className={styles.main}>
      <Header title="CS DashBoard" />
      <div
        style={{
          padding: '0px 10px',
        }}>
        <Form layout="vertical" form={filter} onFinish={search}>
          <Row gutter={16}>
            <Col span={4}>
              <Form.Item shouldUpdate noStyle>
                {() => (
                  <Form.Item
                    name="admin_id"
                    label="Admin"
                    rules={[
                      {
                        required: true,
                        message: 'Please select admin',
                      },
                    ]}>
                    <UserSyncSelect type="admin" />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label={' '}>
                <Button htmlType="submit" type="primary">
                  Refresh
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <div>
          <Tabs defaultActiveKey="1">
            <TabPane tab="AR" key="1">
              <DisputeTable
                loading={loading}
                saleId={admin}
                data={arData || []}
                type={AR}
              />
              <Divider />
              <OverdueTable loading={loading} data={arOverdue} />
            </TabPane>
            <TabPane tab="AP" key="2">
              <DisputeTable
                loading={loading}
                saleId={admin}
                data={apData || []}
                type={AP}
              />
              <Divider />
              <PendingApprovalTable
                loading={loading}
                data={pendingApprovalData}
              />
            </TabPane>
          </Tabs>

          {/* <h4>AR </h4>
          <DisputeTable data={arData || []} type={AR} />
          <OverdueTable data={arOverdue} />
          <h4>AP </h4>
          <DisputeTable data={apData || []} type={AP} />
          <PendingApprovalTable data={pendingApprovalData} /> */}
        </div>
      </div>
    </div>
  );
};

export default Index;
