import { ColumnType } from 'antd/es/table';

export enum AddressVerifyStatus {
  Pass = 1,
  Fail = 2,
  Hold = 3,
}

export type Address = {
  addressLine1: string;
  addressLine2: string;
  cityId: number;
  zipcode: string;
};

export interface ShiplifyLocationType {
  values: string[];
  confidence: string;
}

export interface ShiplifyAttribute {
  value: string;
  confidence: string;
}

export enum ReferenceType {
  LTLShipment = 'App\\Domains\\TL\\Models\\LTLShipment',
  FTLShipment = 'App\\Domains\\FTL\\Models\\FTLShipment',
  CFSShipment = 'App\\Domains\\CFS\\Models\\CFSShipment',
}

export interface AddressVerifierProps<T extends object> {
  address: Address;
  addressType: string;
  accessorials: string[];
  referenceId: number;
  referenceType: ReferenceType;
  tag: string;
  usedReferences: {
    columns: ColumnType<T>[];
    getUsedReferences: (address: Address) => Promise<T[]>;
  };
}

export interface ShiplifyVerification {
  location_types: ShiplifyLocationType;
  dock_access: ShiplifyAttribute;
  forklift: ShiplifyAttribute;
}

export type GooleVerificationLevel =
  | 'CONFIRMED'
  | 'UNCONFIRMED_BUT_PLAUSIBLE'
  | 'UNCONFIRMED_AND_SUSPICIOUS'
  | null;

export interface GoogleResult {
  result: {
    address: {
      addressComponents: {
        componentName: {
          text: string;
          languageCode?: string;
        };
        componentType: string;
        confirmationLevel: GooleVerificationLevel;
      }[];
    };
    uspsData: {
      dpvConfirmation: 'N' | 'D' | 'S' | 'Y';
    };
  };
}

export interface GoogleVerificationItem {
  value: string;
  level: GooleVerificationLevel;
}

export interface GoogleAddressComponents {
  [key: string]: GoogleVerificationItem;
}

export interface GoogleVerification {
  addressComponents: GoogleAddressComponents;
  dpvConfirmation: 'N' | 'D' | 'S' | 'Y' | null;
}

export interface AddressVerifyData {
  data: {
    status: AddressVerifyStatus;
    google_result: GoogleResult;
    shiplify_result: ShiplifyVerification;
    address_line1: string;
    address_line2: string;
    city_id: number;
    zipcode: string;
  };
}

export type VerifyModalProps<T extends object> = {
  status: AddressVerifyStatus | null;
  setStatus: (status: AddressVerifyStatus) => void;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
} & AddressVerifierProps<T>;

export interface UsedAddressShipment {
  order_number: string;
  vendor_name: string;
  carrier_name: string;
  pickup_location_memo: string;
  destination_location_memo: string;
  business_status: string;
  delivery_date: string;
  bol_url: string;
  address_match_type: 'pickup' | 'destination';
}
