import Icon, { HomeOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import React from 'react';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import styles from 'res/css/ui.scss';

const PriceSvg = () => (
  <svg
    viewBox="64 64 896 896"
    focusable="false"
    data-icon="gateway"
    width="1em"
    height="1em"
    fill="currentColor"
    aria-hidden="true">
    <path
      d="M951.5 117.9c0-25-20.4-45.4-45.4-45.4L577.4 72c-13.7 0-25.8 6.2-34.1 15.8-0.6 0.5-1.3 0.9-1.9 1.5L85.2 545.5c-17.7 17.7-17.6 46.4 0.1 64.1l329.1 329.1c17.7 17.7 46.4 17.8 64.1 0.1l456.2-456.2c0.6-0.6 1-1.3 1.5-1.9 9.6-8.3 15.8-20.4 15.8-34.1l-0.5-328.7z m-41.4 333.2L453.6 907.6c-2.4 2.4-9.9 4.2-14 0L116.5 584.5c-3.9-3.9-4.1-10 0-14L573 114h337.1v337.1z m-98.4-112.4c34.9-34.9 34.9-91.5 0-126.4-34.9-34.9-91.5-34.9-126.4 0-34.9 34.9-34.9 91.5 0 126.4 34.9 34.9 91.5 34.9 126.4 0z m-98.3-98.3c19.4-19.4 50.8-19.4 70.2 0 19.4 19.4 19.4 50.8 0 70.2-19.4 19.4-50.8 19.4-70.2 0-19.4-19.4-19.4-50.8 0-70.2zM544.8 423c-30.1-30.1-75-37.4-112.4 0-37.4 37.4-25.3 87.1-7 105.3l63.2 63.2c17.4 17.4 12.6 43.6 0 56.2-12.6 12.6-42.4 13.8-56.2 0L327.1 542.4 299 570.5l63.2 63.2-49.2 49.2 28.1 28.1 49.2-49.2 21.1 21.1c18.7 18.7 74 24.4 105.3-7 31.4-31.4 35.5-76.8 14-98.3l-70.2-70.2c-14.5-14.5-16.1-40.1 0-56.2s42.4-13.8 63.2 7l98.3 98.3 28.1-28.1-63.2-63.2 49.2-49.2-28.1-28.1-49.1 49.1c-5.3-5.3-10.1-10.1-14.1-14z"
      fill="#1F1402"
      p-id="3604"></path>
  </svg>
);
const _PriceIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={PriceSvg} {...props} />
);

export const PriceIcon: React.FC = () => (
  <span className={styles.customer_icon}>
    <_PriceIcon />
  </span>
);
