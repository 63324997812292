export const RESCHEDULED_BY_WAREHOUSE = 1;
export const RESCHEDULED_BY_CUSTOMER = 2;
export const RESCHEDULED_BY_DRAYEASY = 3;
export const RESCHEDULED_BY_TRUCKER = 4;
export const RESCHEDULED_BY_TERMINAL = 5;

export const RESCHEDULED_BY_MAP = {
  [RESCHEDULED_BY_WAREHOUSE]: 'Warehouse',
  [RESCHEDULED_BY_TRUCKER]: 'Trucker',
  [RESCHEDULED_BY_DRAYEASY]: 'Drayeasy',
  [RESCHEDULED_BY_TERMINAL]: 'Terminal',
  [RESCHEDULED_BY_CUSTOMER]: 'Customer',
};
