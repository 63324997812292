import { Header } from '@/components/CommonHeader';
import { Tabs, TabsProps } from 'antd';
import styles from 'res/css/ui.scss';
import Cyber from './components/Cyber';
import Insurance from './components/Insurance';
import Financial from './components/financial';

const Index = () => {
  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Financial',
      children: <Financial />,
    },
    {
      key: '2',
      label: 'Cyber',
      children: <Cyber />,
    },
    {
      key: '3',
      label: 'Insurance and Compliance',
      children: <Insurance />,
    },
  ];

  return (
    <div className={styles.dashboard}>
      <Header title="Risks"></Header>
      <div
        style={{
          padding: '0px 20px',
        }}>
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </div>
    </div>
  );
};

export default Index;
