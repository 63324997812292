import {
  FileStatus,
  RecognitionResult,
  ShipmentData,
} from '../../interface/types';
import Decimal from 'decimal.js';

const BILL_TO_MAP: Record<string, string> = {
  ORDER: 'App\\Models\\Vendor',
  LTL: 'App\\Domains\\TL\\Models\\LTLVendor',
  FTL: 'App\\Domains\\FTL\\Models\\FTLVendor',
};

export const prepareInitialBillData = (
  result: RecognitionResult,
  shipmentData: ShipmentData,
  file: FileStatus | null,
  fileContent: string | undefined,
) => {
  const {
    invoice_date,
    invoice_number,
    due_date,
    terms,
    items,
    container_number,
  } = result;

  let saveData: any = {
    ...(invoice_date && { billed_at: invoice_date }),
    ...(invoice_number && { invoice_number }),
    ...(due_date && { due_at: due_date }),
    ...(terms && { terms }),
    ...(items && {
      charges: items.map((item) => ({
        ...(container_number && { containerNumber: container_number }),
        code: item.code,
        name: item.name || item.description,
        rate: item.rate,
        qty: item.qty,
      })),
    }),
    ...(shipmentData.vendor_id && {
      bill_to_id: shipmentData.vendor_id,
      bill_to_type: BILL_TO_MAP[result.invoice_type],
    }),
  };

  if (items) {
    const totalAmount = items
      .reduce(
        (acc, charge) =>
          acc.plus(
            new Decimal(charge.rate || 0).times(new Decimal(charge.qty || 0)),
          ),
        new Decimal(0),
      )
      .toNumber();
    const amountPaid = 0;
    const amountDue = new Decimal(totalAmount).minus(amountPaid).toNumber();
    saveData = {
      ...saveData,
      amount_total: totalAmount.toFixed(2),
      amount_due: amountDue.toFixed(2),
      amount_paid: amountPaid.toFixed(2),
    };
  }

  // 添加文件相关信息
  if (file?.fileId && fileContent) {
    const fileData = {
      name: file.fileName,
      content: fileContent,
      type: file.fileName.split('.').pop()?.toLowerCase() || '',
    };
    saveData.new_files = [fileData];
  }

  // 加入 dray 相关逻辑
  saveData.billable_id = shipmentData.id;
  saveData.container_number = container_number;
  if (result.bill_from_id) {
    saveData.bill_to_id = result.bill_from_id;
  }
  if (result.bill_from_type) {
    saveData.bill_to_type = result.bill_from_type;
  }
  if (result.bill_from_name) {
    saveData.bill_to = result.bill_from_name;
  }

  return saveData;
};
