import {
  Button,
  Form,
  List,
  Modal,
  Tooltip,
  Avatar,
  message,
  Space,
  Divider,
  Badge,
} from 'antd';
import { CACHE_ADMIN_KEY } from '@/stores/cache';
import { useCallback, useEffect, useMemo, useState } from 'react';
import React from 'react';
import { AlignLeftOutlined } from '@ant-design/icons';
import { sortBy, truncate } from 'lodash';
import { useApp } from '@/utils/useapp';
import { observer } from 'mobx-react';
import { TAdmin, TContainer } from '@/types';
import { MentionsInput, Mention } from 'react-mentions';
import reactStringReplace from 'react-string-replace';
import { EditOutlined, PushpinFilled } from '@ant-design/icons';
import { appendMemoAttributeToData } from '@/components/Memo/data';
import { MEMO_ATTRIBUTE_CONTAINER_INTERNAL } from '@/components/Memo/data';
import { TItemProps } from './ActionTasks';
import {
  RenderTaskShortCut,
  SHORTCUT_DISPATCH_MEMO,
} from './ActionTasks/RenderTaskShortCut';
import WithMentionHighlighter from '@/components/WithMentionHighlighter';

const style = {
  control: {
    backgroundColor: '#fff',
    fontSize: 16,
    // fontWeight: 'normal',
  },

  '&multiLine': {
    control: {
      // fontFamily: 'monospace',
      minHeight: 60,
    },
    highlighter: {
      padding: 9,
      border: '1px solid transparent',
    },
    input: {
      padding: 9,
      border: '1px solid silver',
    },
  },

  '&singleLine': {
    display: 'inline-block',
    width: 180,

    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },
    input: {
      padding: 1,
      border: '2px inset',
    },
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 16,
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
};

// const WithMentionHighlighter = ({
//   content,
//   highlighterColor = '#000005',
// }: {
//   content: string;
//   highlighterColor?: string;
// }) => {
//   const regexp = /@\[(?<name>[\w\s.]+)\]\(\d+\)/gim;
//   return (
//     <span style={{ color: highlighterColor, whiteSpace: 'break-spaces' }}>
//       {reactStringReplace(content, regexp, (match, i) => (
//         <strong
//           key={i}
//           style={{ backgroundColor: '#1dcae02e', color: highlighterColor }}>
//           @{match}
//         </strong>
//       ))}
//     </span>
//   );
// };

const EditableItem = ({
  item,
  admins = [],
  pinConment,
}: {
  item: any;
  admins: TAdmin[];
  pinConment: (item: any, pin: boolean) => void;
}) => {
  const [edit, setEdit] = useState(false);
  const [comment, setComment] = useState<string>(item.comment);
  const [loading, setLoading] = useState(false);
  const app = useApp();

  const handleSave = async () => {
    try {
      const resp = await app.service.put(`adminComments/${item.id}`, {
        data: appendMemoAttributeToData(
          {
            comment,
          },
          MEMO_ATTRIBUTE_CONTAINER_INTERNAL,
        ),
      });
      setEdit(false);
      setComment(resp.data.comment);
    } catch (err: any) {
      message.error(err?.data?.message || err?.data?.error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {!edit ? (
        <div className="flex justify-between">
          <WithMentionHighlighter
            content={comment}
            highlighterColor={'#000000'}
          />
          <Space split={<Divider type="vertical" />}>
            {app.store.auth?.user?.id == item.admin_id && (
              <EditOutlined
                className="text-primary"
                onClick={() => setEdit(true)}
              />
            )}
            <a onClick={() => pinConment(item, true)}>Pin</a>
          </Space>
        </div>
      ) : (
        <>
          <MentionsInput
            defaultValue={''}
            value={comment}
            onChange={(e) => setComment(e.target.value || '')}
            placeholder="Mention any admin by typing `@` followed by at least one char"
            required
            style={style}>
            <Mention
              displayTransform={(id, display) => `@${display}`}
              trigger="@"
              data={(admins || []).map((admin) => ({
                id: admin.id,
                display: admin.name,
              }))}
              style={{ backgroundColor: '#1dcae02e', color: '#024d65' }}
            />
          </MentionsInput>
          <br />
          <Space>
            <Button
              disabled={loading}
              type="primary"
              onClick={() => handleSave()}>
              Save
            </Button>
            <Button disabled={loading} onClick={() => setEdit(false)}>
              Cancel
            </Button>
          </Space>
        </>
      )}
    </>
  );
};

export const Memo: React.FC<{
  onSaved?: (data: any) => void;
  initialValue: string;
  container: TContainer;
  open?: boolean;
}> = observer(({ onSaved, initialValue, container, open = false }) => {
  const [isModalVisible, setIsModalVisible] = useState(open);
  const [comments, setComments] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');

  const app = useApp();

  useEffect(() => {
    if (open) {
      setIsModalVisible(true);
      markAsRead();
    }
  }, [open]);
  // const [form] = useForm();
  // const location = useLocation();

  const markAsRead = useCallback(async () => {
    const ids = app.store.bufferCache.getUnreadCountByContainerId(
      container?.id || 0,
    )?.m_ids;
    if (ids?.length) {
      await app.service.post('notifications/markAsReadByIds', {
        data: { ids },
      });
      const resp = await app.service.get('notifications/unreadCount');
      app.store.notification.setUnreadCount(resp.data);
      app.store.bufferCache.markAsRead(container?.id || 0);
    }
  }, [app.store.bufferCache.data]);

  const showModal = async () => {
    setIsModalVisible(true);
    markAsRead();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const resp = await app.service.post(
        `containers/${container.id}/adminComments`,
        {
          data: { comment },
        },
      );
      onSaved && onSaved(container.id);
      setComment('');
      const data = resp.data;
      setComments([{ ...data, pin: 0 }, ...comments]);
    } catch (err: any) {
      message.error(err?.data?.message || err?.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const fetchComments = async () => {
    setLoading(true);
    try {
      const resp = await app.service.get(
        `containers/${container.id}/adminComments`,
      );
      setComments(resp.data);
    } catch (err: any) {
      message.error(err?.data?.message || err?.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const pinConment = async (item: any, pin: boolean) => {
    setLoading(true);
    try {
      await app.service.put(`adminComments/${item.id}`, {
        data: appendMemoAttributeToData(
          {
            pin,
            container_id: container.id,
          },
          MEMO_ATTRIBUTE_CONTAINER_INTERNAL,
        ),
      });
      fetchComments();
    } catch (err: any) {
      message.error(err?.data?.message || err?.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const _comments = useMemo(() => {
    return sortBy(comments, ['pin', 'pin_at']).reverse();
    return comments;
  }, [comments]);

  useEffect(() => {
    if (isModalVisible && container) {
      fetchComments();
    }
  }, [isModalVisible]);

  const rows = app.store.cache.data[CACHE_ADMIN_KEY];

  if (rows === undefined) {
    app.store.cache.fetch(CACHE_ADMIN_KEY);
  }

  const count = useMemo(() => {
    return app.store.bufferCache.getUnreadCountByContainerId(
      container?.id || 0,
    );
  }, [app.store.bufferCache.data]);

  return (
    <>
      {initialValue ? (
        <Tooltip
          placement="left"
          title={
            <WithMentionHighlighter
              content={truncate(initialValue, { length: 100 })}
              highlighterColor={'#ffffff'}
            />
          }>
          <Badge size="small" count={count?.count || 0}>
            <div className="text-primary" onClick={showModal}>
              <AlignLeftOutlined />
            </div>
          </Badge>
        </Tooltip>
      ) : (
        <div className="text-gray" onClick={showModal}>
          <AlignLeftOutlined />
        </div>
      )}
      {isModalVisible && (
        <Modal
          cancelText="Close"
          footer={false}
          title="Memo"
          width={1000}
          open={isModalVisible}
          onCancel={handleCancel}>
          <Form layout="vertical" onFinish={handleSave}>
            <Form.Item label="">
              <MentionsInput
                defaultValue={''}
                value={comment}
                onChange={(e) => setComment(e.target.value || '')}
                placeholder="Mention any admin by typing `@` followed by at least one char"
                required
                style={style}>
                <Mention
                  displayTransform={(id, display) => `@${display}`}
                  trigger="@"
                  data={(rows || []).map((admin) => ({
                    id: admin.id,
                    display: admin.name,
                  }))}
                  style={{ backgroundColor: '#1dcae02e', color: '#024d65' }}
                />
              </MentionsInput>
            </Form.Item>
            <div className="flex justify-between">
              <Button disabled={loading} type="primary" htmlType="submit">
                Save
              </Button>
              <RenderTaskShortCut
                shortCutType={SHORTCUT_DISPATCH_MEMO}
                container={container}
                onDone={() => onSaved && onSaved(container.id)}
              />
            </div>
          </Form>
          <List
            loading={loading}
            className="demo-loadmore-list"
            itemLayout="horizontal">
            {_comments.map((item) => (
              <List.Item
                key={item.id}
                // actions={[]}
              >
                <List.Item.Meta
                  avatar={
                    <Avatar>
                      {item.admin?.display_name.charAt(0) || 'S'}{' '}
                    </Avatar>
                  }
                  title={
                    <>
                      <span>{item.admin?.display_name || 'System'}</span> @
                      <span className="text-gray">{item.created_at} </span>
                      {!!item.pin && (
                        <PushpinFilled
                          style={{ color: item.pin ? 'orange' : 'black' }}
                          onClick={() => pinConment(item, false)}
                        />
                      )}
                    </>
                  }
                  description={
                    <EditableItem
                      item={item}
                      admins={rows}
                      pinConment={pinConment}
                    />
                  }
                />
              </List.Item>
            ))}
          </List>
        </Modal>
      )}
    </>
  );
});
