import React from 'react';
import { Form, FormInstance, Select, Switch, Tag } from 'antd';

import type { CustomTagProps } from 'rc-select/lib/BaseSelect';

interface Props {
  form: FormInstance;
  oceanCarriesCodes: any;
  onSubmit: () => void;
}

const CONTAINER_TYPES = [
  { label: 'Reefer', value: 'is_reefer' },
  { label: 'DG', value: 'is_dg' },
  { label: 'SOC', value: 'is_soc' },
  { label: 'OverWeight', value: 'is_overweight' },
  // { label: 'Inside Exam', value: 'inside_terminal_exam_site_at' },
  // { label: 'Outside Exam', value: 'outside_terminal_exam_site_at' },
];

export const CardForm: React.FC<Props> = ({
  form,
  onSubmit,
  oceanCarriesCodes,
}) => {
  const getEventEle = (event: any) => {
    const { name, value } = event.target as HTMLInputElement;
    return { name, value };
  };

  const handleChange = async (event: any, affected_event: any) => {
    const { name, value } = getEventEle(event);
    const affected_ele = getEventEle(affected_event);
    await form.setFieldsValue({
      [name]: value,
      [affected_ele.name]: affected_ele.value,
    });
  };

  const tagRender = (props: CustomTagProps) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color="processing"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}>
        {label}
      </Tag>
    );
  };

  return (
    <Form form={form} onFinish={onSubmit}>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => (
          <Form.Item
            name="all_ocean_carrier_codes"
            label="Carriers (SCAC)"
            valuePropName="checked">
            <Switch
              checked={getFieldValue('all_ocean_carrier_codes')}
              onChange={(checked) =>
                handleChange(
                  {
                    target: {
                      name: 'all_ocean_carrier_codes',
                      value: checked,
                    },
                  },
                  {
                    target: {
                      name: 'ocean_carrier_codes',
                      value: checked
                        ? []
                        : getFieldValue('ocean_carrier_codes'),
                    },
                  },
                )
              }
            />
            <div
              className="ant-col ant-form-item-label"
              style={{ marginLeft: '10px' }}>
              {' '}
              ALL
            </div>
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => (
          <Form.Item name="ocean_carrier_codes" noStyle>
            <Select
              mode="multiple"
              showArrow
              key={'value'}
              value={getFieldValue('ocean_carrier_codes')}
              allowClear
              tagRender={tagRender}
              onChange={(value) =>
                handleChange(
                  {
                    target: {
                      name: 'ocean_carrier_codes',
                      value: value,
                    },
                  },
                  {
                    target: {
                      name: 'all_ocean_carrier_codes',
                      value:
                        value && value.length > 0
                          ? false
                          : getFieldValue('all_ocean_carrier_codes'),
                    },
                  },
                )
              }
              style={{ width: '100%' }}>
              {oceanCarriesCodes?.map((code: any) => (
                <Select.Option value={code.value} key={code.value}>
                  {code.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => (
          <Form.Item
            name="all_container_types"
            label="Container Type"
            valuePropName="checked">
            <Switch
              checked={getFieldValue('all_container_types')}
              onChange={(checked) =>
                handleChange(
                  {
                    target: {
                      name: 'all_container_types',
                      value: checked,
                    },
                  },
                  {
                    target: {
                      name: 'container_types',
                      value: checked ? [] : getFieldValue('container_types'),
                    },
                  },
                )
              }
            />
            <div
              className="ant-col ant-form-item-label"
              style={{ marginLeft: '10px' }}>
              {' '}
              ALL
            </div>
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => (
          <Form.Item name="container_types" noStyle>
            <Select
              mode="multiple"
              showArrow
              allowClear
              key={'value'}
              value={getFieldValue('container_types')}
              tagRender={tagRender}
              onChange={(value) =>
                handleChange(
                  {
                    target: {
                      name: 'container_types',
                      value: value,
                    },
                  },
                  {
                    target: {
                      name: 'all_container_types',
                      value:
                        value && value.length > 0
                          ? false
                          : getFieldValue('all_container_types'),
                    },
                  },
                )
              }
              style={{ width: '100%' }}>
              {CONTAINER_TYPES?.map((option: any) => (
                <Select.Option value={option.value} key={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Form.Item>
    </Form>
  );
};
