export default (number: number | string, fractionDigits?: number) => {
  if (!number) {
    return '';
  }

  const _number = Number(number);

  if (Number.isInteger(_number)) {
    return _number.toString();
  } else if (fractionDigits) {
    return _number.toFixed(fractionDigits);
  } else {
    return _number;
  }
};
