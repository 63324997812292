export enum OPdashboardTabModelType {
  Container = 'container',
  FtlShipment = 'ftl_shipment',
  LtlShipment = 'ltl_shipment',
}

export type OPdashboardTabType = {
  id: number;
  name: string;
  model_type: OPdashboardTabModelType;
};
