import styles from 'res/css/ui.scss';
import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Space,
  DatePicker,
} from 'antd';
import { Moment } from 'moment';
import React, { useEffect, useMemo } from 'react';
import { useApp } from '@/utils/useapp';
import { BILL_STATES } from './constants';
import { STATUS_MAP } from './data';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import moment from 'moment';
import { BILLABLE_TYPE } from './data';
import { orderStatesLabelMap } from '@/pages/orders/components/data';
import { ShipmentBusinessStatusLabelMap as LtlShipmentBusinessStatusLabelMap } from '@/pages/truckload/ltl/constants';
import { ShipmentBusinessStatusLabelMap as FtlShipmentBusinessStatusLabelMap } from '@/pages/truckload/ftl/constants';

export type TFilter = {
  loading: boolean;
  filter: FormInstance;
  billableType?: BILLABLE_TYPE;
  onSearch: () => void;
};

export const Filter: React.FC<TFilter> = ({
  loading,
  filter,
  billableType,
  onSearch,
}) => {
  const [showAllFilters, setShowAllFilters] = React.useState(false);
  const statusLabelMap = useMemo(() => {
    if (billableType === BILLABLE_TYPE.LTL) {
      return LtlShipmentBusinessStatusLabelMap;
    } else if (billableType === BILLABLE_TYPE.FTL) {
      return FtlShipmentBusinessStatusLabelMap;
    } else if (billableType === BILLABLE_TYPE.ORDER) {
      return orderStatesLabelMap;
    }
    // todo: update this to include all billable types
    return {
      ...orderStatesLabelMap,
      ...LtlShipmentBusinessStatusLabelMap,
      ...FtlShipmentBusinessStatusLabelMap,
    };
  }, [billableType]);

  return (
    <div className={styles.filter}>
      <Form
        layout="vertical"
        form={filter}
        onFinish={onSearch}
        initialValues={{ date_field: 'billed_at' }}
        style={{ width: '100%' }}
      >
        <Row gutter={8}>
          <Col md={2}>
            <Form.Item name="id" label="Bill#">
              <Input />
            </Form.Item>
          </Col>
          <Col md={2}>
            <Form.Item name="invoice_number" label="INV#">
              <Input />
            </Form.Item>
          </Col>
          {billableType &&
            [
              BILLABLE_TYPE.ORDER,
              BILLABLE_TYPE.LTL,
              BILLABLE_TYPE.FTL,
            ].includes(billableType) && (
              <Col md={2}>
                <Form.Item name="order_dispatch" label="Order Dispatch">
                  <Select allowClear>
                    {Object.keys(statusLabelMap).map((key: any) => (
                      <Select.Option key={key} value={key}>
                        {
                          statusLabelMap[
                            (key as unknown) as keyof typeof statusLabelMap
                          ]
                        }
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}

          <Col md={2}>
            <Form.Item name="bill_to" label="Bill From">
              <Input />
            </Form.Item>
          </Col>
          <Col md={2}>
            <Form.Item name="number" label="CNTR#/LTL#/FTL#">
              <Input />
            </Form.Item>
          </Col>
          <Col md={2}>
            <Form.Item name="is_approved" label="Approved">
              <Select allowClear>
                <Select.Option value="true">Yes</Select.Option>
                <Select.Option value="false">No</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col md={2}>
            <Form.Item name="dispute" label="Dispute">
              <Select allowClear>
                <Select.Option key={0} value={0}>
                  No Dispute
                </Select.Option>
                {Object.keys(STATUS_MAP).map((key: any) => (
                  <Select.Option key={key} value={key}>
                    {STATUS_MAP[(key as unknown) as keyof typeof STATUS_MAP]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={2}>
            <Form.Item name="state" label="Status">
              <Select
                allowClear
                options={BILL_STATES.map((s) => ({ value: s, text: s }))}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item label="Related Person" name="releated_person_id">
              <UserSyncSelect type="admin" allowClear />
            </Form.Item>
          </Col>
          {/* <Col span={2}>
              <Form.Item label="Operator" name="operator_id">
                <UserSyncSelect type="admin" allowClear />
              </Form.Item>
            </Col> */}
          <Col>
            <Form.Item label={' '}>
              <Space>
                <Button htmlType="submit" type="primary">
                  Search
                </Button>
                <Button onClick={() => filter.resetFields()}>Clear All</Button>
                <Button onClick={() => setShowAllFilters(!showAllFilters)}>
                  {showAllFilters ? 'Hide Filters' : 'Show All Filter'}
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>

        <Row
          style={{
            height: showAllFilters ? '' : '0px',
            width: showAllFilters ? '100%' : '0px',
          }}
          gutter={8}
        >
          <Col
            style={{
              visibility: showAllFilters ? 'visible' : 'hidden',
            }}
            md={8}
          >
            <Form.Item name="start_date" noStyle>
              <Input hidden />
            </Form.Item>
            <Form.Item name="end_date" noStyle>
              <Input hidden />
            </Form.Item>
            <Form.Item name="date_field" noStyle>
              <Input hidden />
            </Form.Item>
            <Form.Item label="Date" shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => {
                const startDate = getFieldValue('start_date');
                const endDate = getFieldValue('end_date');
                return (
                  <Input.Group compact>
                    <Select
                      value={getFieldValue('date_field')}
                      onChange={(value) =>
                        setFieldsValue({ date_field: value })
                      }
                      style={{ width: '30%' }}
                    >
                      <Select.Option value="billed_at">Bill Date</Select.Option>
                      <Select.Option value="created_at">
                        Create Date
                      </Select.Option>
                      <Select.Option value="due_at">Due Date</Select.Option>
                      <Select.Option value="cleared_at">
                        Cleared Date
                      </Select.Option>
                    </Select>
                    <DatePicker.RangePicker
                      style={{ width: '70%' }}
                      value={
                        startDate && endDate
                          ? [moment(startDate), moment(endDate)]
                          : undefined
                      }
                      onChange={(value) =>
                        setFieldsValue({
                          start_date: value
                            ? value[0]?.format('YYYY-MM-DD')
                            : null,
                          end_date: value
                            ? value[1]?.format('YYYY-MM-DD')
                            : null,
                        })
                      }
                    />
                  </Input.Group>
                );
              }}
            </Form.Item>
          </Col>
          <Col
            style={{
              visibility: showAllFilters ? 'visible' : 'hidden',
            }}
            span={3}
          >
            <Form.Item label="Has Due Amount" name="has_due_amount">
              <Select allowClear>
                <Select.Option value="true">Has Due Amount</Select.Option>
                <Select.Option value="false">No Due Amount</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col
            style={{
              visibility: showAllFilters ? 'visible' : 'hidden',
            }}
            span={3}
          >
            <Form.Item label="Need Chassis Dispute" name="need_chassis_dispute">
              <Select allowClear>
                <Select.Option value="true">Yes</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
