const LTL_INS_ID = 202;
const LTL_TRUCH_ID = 182;
const FTL_INS_ID = 144;
const FTL_TRUCH_ID = 124;

export default {
  LTL_INS_ID,
  LTL_TRUCH_ID,
  FTL_INS_ID,
  FTL_TRUCH_ID,
} as const;
