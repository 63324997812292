import React, {
  ReactElement,
  ReactHTMLElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { EmailTemplateEditor } from '@/components/EmailTemplateEditor';
import { useApp } from '@/utils/useapp';
import { FormInstance, Form, message, Select } from 'antd';
import { get, has } from 'lodash';
import { PRESET_DATA_MAP } from '@/pages/setting/emailTemplate/components/data';
import { PreviewEmailContentDrawer } from './PreviewEmailContentDrawer';
import { SendToListAndCCListFormItems } from './SendToListAndCCListFormItems';
import { validateForm } from './index';

interface Props {
  target: string;
  targetId: number;
  form: FormInstance;
  sending: boolean;
  hasSendToList?: boolean;
  sendURI: string;
  options?: { [key: string]: any };
  docs?: any[];
  previewURI?: string;
  presetDataComponent?: React.ReactNode;
  onSending: (sending: boolean) => void;
  onCancelSend: () => void;
  onSent: () => void;
  alertMessage?: ReactElement;
}
const gridStyle: React.CSSProperties = {
  width: '50%',
  height: '90%',
  overflowY: 'scroll',
};

export const SendEmailUsingEditor: React.FC<Props> = ({
  target,
  targetId,
  form,
  sending,
  hasSendToList = true,
  sendURI,
  previewURI,
  presetDataComponent,
  options = {},
  docs = [],
  onSending,
  onCancelSend,
  onSent,
  alertMessage,
}) => {
  const app = useApp();
  const [loading, setLoading] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [previewContent, setPreviewContent] = useState(null);
  const presetDataMap = useMemo(
    () =>
      !presetDataComponent
        ? has(PRESET_DATA_MAP, target) && get(PRESET_DATA_MAP, target)
        : false,
    [target],
  );

  const handleChange = (event: any) => {
    const { name, value } = event.target as HTMLInputElement;
    form.setFieldValue([name], value);
  };

  const handlePreview = async () => {
    if (!previewURI) {
      return;
    }

    const data = await form.getFieldsValue();

    setLoading(true);
    try {
      const resp = await app.service.post(`${previewURI}`, {
        data,
      });

      setPreviewContent(resp.data);
      setOpenPreview(true);
    } catch (err: any) {
      if (err.data) {
        message.error(get(err, 'data.message'));
      } else {
        message.error(err.message || 'Network ERROR');
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePreSend = async () => {
    const data = await form.validateFields();

    if (false === validateForm(data)) {
      onCancelSend();
      return false;
    }

    if (previewURI && presetDataMap) {
      handlePreview();
    } else {
      handleSend();
    }
  };

  const handleSend = async () => {
    if (!sendURI) {
      return;
    }

    const data = await form.validateFields();
    setLoading(true);
    try {
      await app.service.post(`${sendURI}`, {
        data: {
          ...options,
          ...data,
        },
      });
      message.success('Sent');
      onSent();
    } catch (err: any) {
      message.error(err.data.message || err.data.error);
    } finally {
      onSending(false);
      setLoading(false);
    }
  };

  const onClosePreviewContent = () => {
    setOpenPreview(false);
    setPreviewContent(null);
    onCancelSend();
  };

  useEffect(() => {
    sending && handlePreSend();
  }, [sending]);

  const _EmailTemplateEditorProps = useMemo(() => {
    let _props: any = {
      presetDataMap: presetDataComponent ? presetDataComponent : presetDataMap,
    };

    if (typeof _props.presetDataMap === 'object') {
      _props = {
        ..._props,
        handlePreview,
      };
    }

    return _props;
  }, [presetDataComponent, presetDataMap]);

  return (
    <>
      <Form layout="vertical" form={form}>
        {hasSendToList && (
          <SendToListAndCCListFormItems
            form={form}
            alertMessage={alertMessage}
          />
        )}
        <Form.Item name="subject" hidden noStyle shouldUpdate></Form.Item>
        <Form.Item name="body" hidden noStyle shouldUpdate></Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <EmailTemplateEditor
              {..._EmailTemplateEditorProps}
              info={{
                subject: getFieldValue('subject'),
                body: getFieldValue('body'),
              }}
              onChange={handleChange}
            />
          )}
        </Form.Item>
        {docs && (
          <Form.Item label="Attachments" name="attachments" initialValue={[]}>
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              defaultValue={[]}
              optionLabelProp="label">
              {docs.map((doc) => {
                return (
                  <Select.Option
                    key={doc.id}
                    value={doc.id}
                    label={`${doc.file_name} (${doc.custom_properties.document_type})`}>
                    <div className="demo-option-label-item">
                      {doc.file_name} ({doc.custom_properties.document_type})
                    </div>
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        )}
      </Form>

      <PreviewEmailContentDrawer
        open={openPreview}
        onClose={onClosePreviewContent}
        onConfirmSend={handleSend}
        disabled={loading}
        content={previewContent}
      />
    </>
  );
};
