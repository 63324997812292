import { Tooltip, Typography } from 'antd';
import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';

export const TitleSlaTooltip: React.FC<{ showSortDesc?: boolean }> = ({
  showSortDesc = false,
}) => {
  return (
    <Tooltip
      title={
        <>
          <div>Remaing Hours - Spent Hours / Reference Hours</div>
          {showSortDesc && (
            <div>
              <br />
              <span>
                {' '}
                If sorting is clicked, sla is sorted in ascending or descending
                order based on the ratio of spent hours to reference hours{' '}
              </span>
            </div>
          )}
        </>
      }>
      SLA <InfoCircleOutlined />
    </Tooltip>
  );
};
