import { Button, Modal } from 'antd';
import { FC } from 'react';
import { TPrepaidOrder } from '..';

interface IRejectButtonProps {
  order: TPrepaidOrder;
  onSubmit: () => void;
}

const RejectButton: FC<IRejectButtonProps> = ({ order, onSubmit }) => {
  return (
    <Button
      size="small"
      type="link"
      danger
      onClick={() => {
        Modal.confirm({
          title: 'Reject',
          content: (
            <>
              <div> {order.account.user.name}</div>
              <div className="mt-sm text-danger">
                If the confirmation is not approved, the recharge will enter the
                pending review failed state.
              </div>
            </>
          ),
          onOk: () => onSubmit(),
        });
      }}
    >
      Reject
    </Button>
  );
};

export default RejectButton;
