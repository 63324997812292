import React from 'react';
import { Space, Typography, Tooltip } from 'antd';
import { TerminalRedirectLink } from '@/components/TerminalRedirectLink';
import { CopyButton } from '@/components/CopyButton';
import { VendorVerified } from '@/pages/entity/vendors/components/VendorVerified';
import { OnTheWayTruckIcon } from '@/components/OnTheWayTruckIcon';
import { RAMP_PORT } from './data';
import { CONTAINER_TROUBLE_STATUES } from '@/pages/dispatchs/components/data';
import { WarningOutlined } from '@ant-design/icons';
import CntrAttributeBadge from '@/components/CntrAttributeBadge';

export const CNTREasyTracking: React.FC<{
  order?: any;
  showAtrribute?: boolean;
  container: any;
  onSaved?: () => void;
}> = ({ showAtrribute = true, order, container, onSaved }) => {
  if (!container) {
    return null;
  }

  const terminalId = container.terminal_id;
  const vendor = container.vendor;
  const cntr = container.number;
  const ramp = container.destination_type == RAMP_PORT;

  const troubles = React.useMemo(() => {
    const list =
      container && container.trouble_status
        ? Object.keys(CONTAINER_TROUBLE_STATUES)
          .filter(
            (field: string) =>
              container.trouble_status && container.trouble_status[field],
          )
          .map((t: string) => CONTAINER_TROUBLE_STATUES[t])
        : [];

    if (container && container.is_diversion) {
      list.push('Diversion');
    }

    return list;
  }, [container]);

  return (
    <Space>
      {terminalId ? (
        <TerminalRedirectLink
          id={terminalId}
          label={cntr}
          style={{ color: ramp ? 'orange' : '#1890ff' }}
        />
      ) : (
        <Typography.Text style={{ color: ramp ? 'orange' : '#1890ff' }}>
          {cntr}
        </Typography.Text>
      )}
      {cntr && <CopyButton value={cntr} />}
      {/* <OnTheWayTruckIcon container={container} onUpdated={onSaved} /> */}
      {troubles && troubles.length > 0 && (
        <Tooltip title={troubles.join(',')}>
          <span className="text-danger">
            <WarningOutlined />
          </span>
        </Tooltip>
      )}
      {showAtrribute && (
        <CntrAttributeBadge
          order_is_prepaid={order?.is_prepaid}
          container={container}
        />
      )}
      {/* <div>
        {vendor && (
          <VendorVerified
            vendor={vendor}
            onSaved={() => onSaved && onSaved()}
          />
        )}
      </div> */}
    </Space>
  );
};
