import { useCallback, useMemo, useState } from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { Filter } from './components/Filter';
import ReactECharts from 'echarts-for-react';
import { message, Modal, Popover, Spin, Table } from 'antd';
import { VendorCell } from '@/components/VendorCell';
import { VendorDrawer } from '@/pages/entity/vendors/components/VendorDrawer';
import VendorRating from '@/pages/dispatchs/components/VendorRating';
import { RatingTag } from '@/components/RatingTag';

const Index = () => {
  const app = useApp();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [filter] = useForm();
  const [selected, setSelected] = useState<any>();
  const [showModal, setShowModal] = useState(false);
  const [vendor, setVendor] = useState<any>(null);
  const [visible, setVisible] = useState(false);

  const mode = useWatch('mode', filter);

  const fetchData = useCallback(async () => {
    const data = await filter.validateFields();

    setLoading(true);

    try {
      const result = await app.service.get('rateEngine/indexTool', {
        params: {
          ...data,
        },
      });

      setData(result.data);
    } catch (e: any) {
      message.error(e.data.message || e.data.error || 'System Error');
    }

    setLoading(false);
  }, []);

  const handleShowModal = (s) => {
    setShowModal(true);
    setTimeout(() => setSelected(s), 500);
  };

  const handleHideModal = () => {
    setShowModal(false);
    setSelected(null);
  };

  const handleEdit = (id: number) => {
    setVisible(true);
    setVendor(id);
  };

  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: 'Vendor',
        dataIndex: 'vendor',
        render: (text, record: any) => (
          <VendorCell
            vendor={record.vendor}
            showVendorDetail={() => handleEdit(record.vendor.id)}
          />
        ),
      },
      {
        title: 'Rating',
        dataIndex: 'rating',
        key: 'rating',
        // width: 120,
        render: (text, record) => {
          console.log('record', record);
          return (
            <Popover
              trigger="click"
              destroyTooltipOnHide
              content={
                <VendorRating
                  vendor_id={record.vendor?.id || 0}
                  score={record.vendor?.score || 'N/A'}
                />
              }
            >
              <RatingTag rating={record.vendor?.score} />
            </Popover>
          );
        },
      },
      {
        title: 'Driver Count',
        dataIndex: 'driverCount',
        width: 160,
        sorter: (a, b) => a.driverCount - b.driverCount,
      },
      {
        title: 'Rate Count',
        dataIndex: 'rateCount',
        width: 160,
        sorter: (a, b) => a.rateCount - b.rateCount,
      },
      {
        title: 'Residual Standard Deviation',
        dataIndex: 'residualStandardDeviation',
        width: 160,
        sorter: (a, b) =>
          a.residualStandardDeviation - b.residualStandardDeviation,
      },
      {
        title: 'R Squared',
        dataIndex: 'rSquared',
        width: 160,
        sorter: (a, b) => a.rSquared - b.rSquared,
      },
      {
        title: 'Coefficient',
        dataIndex: 'coefficient',
        width: 160,
        sorter: (a, b) => a.coefficient - b.coefficient,
      },
      {
        title: 'Intercept',
        dataIndex: 'intercept',
        width: 160,
        sorter: (a, b) => a.intercept - b.intercept,
      },
      {
        title: 'Chart',
        dataIndex: 'chart',
        width: 160,
        render: (_, record: any) => {
          return (
            <>
              <a onClick={() => handleShowModal(record)}>View</a>
            </>
          );
        },
      },

      // {
      //   title: 'From',
      //   dataIndex: ['ir_latitude', 'ir_longitude'],
      //   key: 'ir_longitude',
      //   width: 200,
      //   render: (text, record: any) => (
      //     <>
      //       {get(record, 'ir_latitude', '')},{get(record, 'ir_longitude', '')}
      //     </>
      //   ),
      // },
      // {
      //   title: 'To',
      //   dataIndex: ['city_latitude', 'city_longitude'],
      //   key: 'city_longitude',
      //   width: 200,
      //   render: (text, record: any) => (
      //     <>
      //       {get(record, 'city_latitude', '')},
      //       {get(record, 'city_longitude', '')}
      //     </>
      //   ),
      // },
      // {
      //   title: 'Distance',
      //   dataIndex: 'distance',
      //   key: 'distance',
      //   width: 200,
      //   render: (text, record: any) =>
      //     get(record, 'distance', '') && get(record, 'distance', '') + ' miles',
      // },
      // {
      //   title: 'Count',
      //   dataIndex: 'count',
      //   key: 'count',
      //   width: 200,
      // },
      // {
      //   title: 'Lowest Rate(Base + Fuel)',
      //   dataIndex: 'lowest_rate',
      //   key: 'lowest_rate',
      //   width: 200,
      // },
      // {
      //   title: 'Median Rate(Base + Fuel)',
      //   dataIndex: 'midpoint_rate',
      //   key: 'midpoint_rate',
      //   width: 200,
      // },
      // {
      //   title: 'Highest Rate(Base + Fuel)',
      //   dataIndex: 'highest_rate',
      //   key: 'highest_rate',
      //   width: 200,
      // },
      // {
      //   title: 'Average Rate(Base + Fuel)',
      //   dataIndex: 'average_rate',
      //   key: 'average_rate',
      //   width: 200,
      // },
    ],
    [],
  );

  const chartOption = useMemo(() => {
    const maxX = selected ? Math.max(...selected.rows.map((row) => row[0])) : 0;

    return {
      xAxis: {
        type: 'value',
        name:
          mode === 'by_driving_time'
            ? 'Minutes / Driving Time'
            : 'Distance / Miles',
        // nameLocation: 'center',
      },
      yAxis: {
        type: 'value',
        name: 'Dollar / Price',
        // nameLocation: 'center',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985',
          },
        },
        // formatter:
        //   mode === 'by_driving_time'
        //     ? 'Minutes, Rate: {c}'
        //     : 'Miles, Rate: {c}',
      },
      series: selected
        ? [
          {
            symbolSize: 5,
            data: selected.rows,
            type: 'scatter',
            markLine: {
              lineStyle: {
                type: 'solid',
                color: '#45f248',
              },
              tooltip: {
                formatter: selected.expression,
              },
              data: [
                [
                  {
                    name: selected.expression,
                    coord: [0, parseFloat(selected.intercept)],
                  },
                  {
                    coord: [
                      maxX,
                      parseFloat(maxX) * parseFloat(selected.coefficient) +
                          parseFloat(selected.intercept),
                    ],
                  },
                ],
              ],
            },
          },
        ]
        : [],
    };
  }, [selected, mode]);

  const handleSearch = () => {
    fetchData();
  };

  const handelCloseDrawer = () => {
    setVisible(false);
    setVendor(null);
  };

  return (
    <div className={styles.main}>
      <Header title="Index Tool"></Header>
      <Filter loading={loading} filter={filter} onSearch={handleSearch}>
        {' '}
      </Filter>

      <Table
        loading={loading}
        pagination={false}
        size="small"
        rowKey="vendor"
        columns={columns}
        onChange={fetchData}
        dataSource={data}
        sticky
        scroll={{
          x: 'auto',
        }}
      />
      <Modal
        title="Chart"
        open={!!showModal}
        onCancel={() => handleHideModal()}
        onOk={() => handleHideModal()}
        width={1000}
      >
        <div>
          {selected && selected.rows && selected.rows.length > 0 ? (
            <ReactECharts option={chartOption} style={{ height: 600 }} />
          ) : (
            <Spin />
          )}
        </div>
      </Modal>
      <VendorDrawer id={vendor} onClose={handelCloseDrawer} visible={visible} />
    </div>
  );
};

export default Index;
