import { Space, DatePicker } from 'antd';
import { useApp } from '@/utils/useapp';
import { isArray, has, get, isObject } from 'lodash';

import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { RerserveTime, FIELD as rerseveTimeField } from '../../RerserveTime';

import { TItemProps, TOnDoneProps, TContainerProps } from '..';
import { useForm } from 'antd/lib/form/Form';

export const DateTimeRangeAction: React.FC<
{
  disabled: boolean;
  name: string | { time: string; range: string };
  onUpdating: (data: {
    is_reserve_time: boolean;
    name: string;
    value: string | null;
  }) => void;
} & TItemProps &
TOnDoneProps &
TContainerProps
> = ({ disabled = false, name, item, container, onDone, onUpdating }) => {
  const timeName = useMemo(
    () => (isObject(name) ? get(name, 'time') : name),
    [name],
  );

  const rangeName = useMemo(
    () => (isObject(name) ? get(name, 'range') : name),
    [name],
  );

  const isAppendToDefaultRerseve = useMemo(
    () => (isObject(name) ? false : true),
    [name],
  );

  const value = useMemo(() => get(container, timeName), [container, timeName]);

  return (
    <>
      <Space>
        <DatePicker
          disabled={disabled}
          style={{ width: '100%' }}
          size="small"
          value={value ? moment(value) : null}
          onChange={(date, dateString) =>
            onUpdating({
              is_reserve_time: false,
              name: timeName,
              value: dateString || null,
            })
          }
        />

        <RerserveTime
          disabled={disabled}
          // trigger={'hover'}
          name={rangeName}
          placement={'top'}
          record={container}
          is_append_to_default={isAppendToDefaultRerseve}
          is_show_time_detail={true}
          customUpdate={(value) => {
            onUpdating({
              is_reserve_time: isAppendToDefaultRerseve,
              name: rangeName,
              value: value,
            });
          }}
          refreshContainer={() => onDone}
        />
      </Space>
    </>
  );
};
