import React from 'react';
import { EProfitType } from './constants';
import { AccountingModal as DrayAccountingModal } from '@/pages/orders/components/AccountingModal';
import { AccountingModal as LTLAccountingModal } from '@/pages/truckload/ltl/components/AccountingModal';
import { AccountingModal as FTLAccountingModal } from '@/pages/truckload/ftl/components/AccountingModal';

interface AccountingModalProps {
  type: EProfitType;
  modelId: number;
}

const AccountingModal: React.FC<AccountingModalProps> = ({ type, modelId }) => {
  if (type === EProfitType.LTL) {
    return <LTLAccountingModal ltlShipmentId={modelId} />;
  }

  if (type === EProfitType.FTL) {
    return <FTLAccountingModal ftlShipmentId={modelId} />;
  }

  return <DrayAccountingModal orderId={modelId} />;
};

export default AccountingModal;
