import React, { FC, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useApp } from '@/utils/useapp';

export const Statement: React.FC<{
  container: any;
}> = observer(({ container }) => {
  if (!container || !container.id) {
    return null;
  }

  const app = useApp();

  const statements = useMemo(
    () =>
      app.store.cntrStatementCache.getStatements(container.id)?.statements ??
      null,
    [container, app.store.cntrStatementCache.data],
  );

  useEffect(() => {
    container &&
      container.number &&
      app.store.cntrStatementCache.debounceFetch(container.id);
  }, [container]);

  return <>{statements?.map((s) => s.id).join(', ')}</>;
});
