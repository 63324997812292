import { TAdditionalCharges, TContainer, TSpotRate, TUser } from '@/types';
import moment from 'moment';

const defaultAdditionalCharges: TAdditionalCharges = [
  {
    code: '',
    name: '',
    rate: '',
    explanation: '',
  },
];

export const defaultContainer: TContainer = {
  size_type: '',
  qty: '',
  is_dg: false,
  is_overweight: false,
  is_reefer: false,
  is_soc: false,
};

export const defaultRateData = {
  code: '',
  name: '',
  buy_rate: '0',
  buy_volume: 0,
  sell_rate: '0',
  sell_volume: '0',
};

export const defaultData: Partial<TSpotRate> = {
  additional_charges: [...defaultAdditionalCharges],
  // carrier: '',
  carrier_code: '',
  carrier_name: '',
  containers: [{ ...defaultContainer }],
  customer_name: '',
  // del_id:
  del_name: '',
  expired_date: moment(new Date()).format('YYYY-MM-DD'),
  external_remark: '',
  // fdest_id: 0,
  // fdest_name: '',
  fdest_zipcode: '',
  // id:
  internal_remark: '',
  name: '',
  // pod_id: 1,
  pod_name: '',
  quote_date: moment(new Date()).format('YYYY-MM-DD'),
  // rate_id:
  rates: [{ ...defaultRateData }],
  // sales_id:
  status: 0,
  total_buy_rate: '0',
  total_sell_rate: '0',
  type: '',
  user: {} as TUser,
  // user_id: 0,
};
