import { useApp } from '@/utils/useapp';
import { Col, message, Radio } from 'antd';
import { Row } from 'antd';
import { Card } from 'antd';
import { FC, useEffect, useState } from 'react';

export interface AmountData {
  all: {
    value: number;
    today: number;
  };
  admin: {
    value: number;
    today: number;
  };
  client: {
    value: number;
    today: number;
  };
}

interface AmountProps {
  initialQuoteAmount: AmountData;
  initialShipmentAmount: AmountData;
}

export const Amount: FC<AmountProps> = ({
  initialQuoteAmount,
  initialShipmentAmount,
}: AmountProps) => {
  const [quoteLoading, setQuoteLoading] = useState(false);
  const [shipmentLoading, setShipmentLoading] = useState(false);
  const app = useApp();

  const timeRangeOptions = [
    { label: '7 Days', value: '7' },
    { label: '14 days', value: '14' },
    { label: '30 days', value: '30' },
    { label: 'ALL', value: 'all' },
  ];
  const [quoteTimeRange, setQuoteTimeRange] = useState('all');
  const [shipmentTimeRange, setShipmentTimeRange] = useState('all');

  const [quoteAmount, setQuoteAmount] = useState<AmountData>(
    initialQuoteAmount,
  );
  const [shipmentAmount, setShipmentAmount] = useState<AmountData>(
    initialShipmentAmount,
  );

  useEffect(() => {
    setQuoteAmount(initialQuoteAmount);
  }, [initialQuoteAmount]);

  useEffect(() => {
    setShipmentAmount(initialShipmentAmount);
  }, [initialShipmentAmount]);

  const fetchQuoteAmountData = async (range: string) => {
    setQuoteLoading(true);
    try {
      const resp = await app.service.get('ftl/dashboard/quote-amount', {
        params: {
          range,
        },
      });
      setQuoteAmount(resp.data);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setQuoteLoading(false);
    }
  };

  const fetchShipmentAmountData = async (range: string) => {
    setShipmentLoading(true);
    try {
      const resp = await app.service.get('ftl/dashboard/shipment-amount', {
        params: {
          range,
        },
      });
      setShipmentAmount(resp.data);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setShipmentLoading(false);
    }
  };

  return (
    <Row gutter={8}>
      <Col span={12}>
        <Card
          loading={quoteLoading}
          size="small"
          title="Quote"
          headStyle={{ fontWeight: 'bold' }}
          extra={
            <Radio.Group
              options={timeRangeOptions}
              optionType="button"
              buttonStyle="solid"
              defaultValue="all"
              value={quoteTimeRange}
              onChange={(e) => {
                setQuoteTimeRange(e.target.value);
                fetchQuoteAmountData(e.target.value);
              }}
            />
          }
        >
          <Row gutter={8}>
            <Col span={8}>
              <Card size="small">
                <p>Total</p>
                <h2>
                  {quoteAmount.all.value}{' '}
                  <span style={{ color: 'green' }}>
                    +{quoteAmount.all.today}
                  </span>
                </h2>
              </Card>
            </Col>
            <Col span={8}>
              <Card size="small">
                <p>By Client</p>
                <h2>
                  {quoteAmount.client.value}{' '}
                  <span style={{ color: 'green' }}>
                    +{quoteAmount.client.today}
                  </span>
                </h2>
              </Card>
            </Col>
            <Col span={8}>
              <Card size="small">
                <p>By DE</p>
                <h2>
                  {quoteAmount.admin.value}{' '}
                  <span style={{ color: 'green' }}>
                    +{quoteAmount.admin.today}
                  </span>
                </h2>
              </Card>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={12}>
        <Card
          loading={shipmentLoading}
          size="small"
          title="Shipment"
          headStyle={{ fontWeight: 'bold' }}
          extra={
            <Radio.Group
              options={timeRangeOptions}
              optionType="button"
              buttonStyle="solid"
              defaultValue="all"
              value={shipmentTimeRange}
              onChange={(e) => {
                setShipmentTimeRange(e.target.value);
                fetchShipmentAmountData(e.target.value);
              }}
            />
          }
        >
          <Row gutter={8}>
            <Col span={8}>
              <Card size="small">
                <p>Total</p>
                <h2>
                  {shipmentAmount.all.value}{' '}
                  <span style={{ color: 'green' }}>
                    +{shipmentAmount.all.today}
                  </span>
                </h2>
              </Card>
            </Col>
            <Col span={8}>
              <Card size="small">
                <p>By Client</p>
                <h2>
                  {shipmentAmount.client.value}{' '}
                  <span style={{ color: 'green' }}>
                    +{shipmentAmount.client.today}
                  </span>
                </h2>
              </Card>
            </Col>
            <Col span={8}>
              <Card size="small">
                <p>By DE</p>
                <h2>
                  {shipmentAmount.admin.value}{' '}
                  <span style={{ color: 'green' }}>
                    +{shipmentAmount.admin.today}
                  </span>
                </h2>
              </Card>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
