import { Input, Form, Select, Checkbox, Switch, Tooltip } from 'antd';
import { FormInstance } from 'antd/es/form';
import { get, reduce, uniq } from 'lodash';
import React from 'react';
import { HAZMAT_OPTIONS_MAP } from '../../data';
import { IAttribute } from '.';
import { AbilitySwitchDisabled } from './AbilitySwitchDisabled';

export const HazmatAbility: React.FC<IAttribute> = ({ form }) => {
  const handleHazmatAbilityChange = (event: any) => {
    const checked = event.target.checked;
    if (!checked) {
      form.setFieldsValue({
        hazmat_range: [],
      });
    }
  };

  return (
    <>
      <Form.Item
        name="hazmat_ability"
        valuePropName="checked"
        label="Hazmat Ability"
        shouldUpdate>
        <AbilitySwitchDisabled checked={form.getFieldValue('hazmat_ability')} />
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue, setFieldValue }) =>
          getFieldValue('hazmat_ability') && (
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="hazmat_range"
              label="Hazmat Range">
              <Select mode="multiple" style={{ width: '100%' }} maxTagCount={2}>
                {Object.keys(HAZMAT_OPTIONS_MAP).map((key: string) => {
                  return (
                    <Select.Option key={key} value={key}>
                      {
                        HAZMAT_OPTIONS_MAP[
                          key as unknown as keyof typeof HAZMAT_OPTIONS_MAP
                        ]
                      }
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          )
        }
      </Form.Item>
    </>
  );
};
