import { InboxOutlined } from '@ant-design/icons';
import { message, Spin, UploadProps } from 'antd';
import { Upload } from 'antd';
import React, { useEffect } from 'react';
import { checkFileSize, FILE_MAX_SIZE } from './Validate';

const { Dragger } = Upload;

export const DraggerWithPaste: React.FC<{
  title?: string;
  disabled?: boolean;
  uploadProp: UploadProps;
  acceptFileTypes?: string;
  handlePasteFiles: (pasteFiles: any) => void;
}> = ({
  title = null,
  disabled = false,
  uploadProp,
  acceptFileTypes = null,
  handlePasteFiles,
  ...props
}) => {
  const handlePaste = async (e: any) => {
    if (disabled) {
      return false;
    }

    const items = e.clipboardData.items;
    const files = [];

    for (const item of items) {
      if (item.kind !== 'file') {
        continue;
      }

      if (acceptFileTypes && -1 === acceptFileTypes.indexOf(item.type)) {
        continue;
      }

      const pasteFile = item.getAsFile();

      const sizeOk = checkFileSize(pasteFile);

      if (!sizeOk) {
        message.error(`File must smaller than ${FILE_MAX_SIZE}MB!`);

        return false;
      }

      files.push(pasteFile);
    }

    if (files.length == 0) {
      message.error('No valid file to upload');
      return false;
    }

    if (!uploadProp.multiple && files.length > 1) {
      message.error('Only one file can be pasted at a time');
      return false;
    }

    files.length > 0 && (await handlePasteFiles(files));
  };

  return (
    <>
      {title && <h3>{title}</h3>}
      <div>
        <div
          className={`upload-paste ${disabled ? 'disable-cursor' : ''}`}
          onPaste={handlePaste}
        >
          Click and Ctrl V to Paste
        </div>
        <div className="upload-drag-with-paste">
          <Dragger disabled={disabled} {...uploadProp} {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from
              uploading company data or other band files
            </p>
          </Dragger>
        </div>
      </div>
    </>
  );
};
