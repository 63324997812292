import React from 'react';
import copy from 'copy-to-clipboard';
import { Typography, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

const { Link } = Typography;

interface ICopyMultipleButton {
  onClick: () => void;
}

export const toCopy = (
  value: string,
  successMsg = 'Copied',
  failedMsg = 'Copy Failed',
) => {
  if (copy(value)) {
    message.success(successMsg);
  } else {
    message.error(failedMsg);
  }
};

export const CopyMultipleButton: React.FC<ICopyMultipleButton> = ({
  onClick,
}) => {
  return (
    <Link onClick={onClick}>
      <CopyOutlined />
    </Link>
  );
};
