import React from 'react';
import { ModelSelect } from './ModelSelect';
import { TLType } from './constants';
import SyncCacheModelSelect from './SyncCacheModelSelect';
import {
  CACHE_CFS_VENDOR_KEY,
  CACHE_FTL_VENDOR_KEY,
  CACHE_LTL_VENDOR_KEY,
} from '@/stores/cache';

const CACHE_KEY_MAP = {
  [TLType.LTL]: CACHE_LTL_VENDOR_KEY,
  [TLType.FTL]: CACHE_FTL_VENDOR_KEY,
  [TLType.CFS]: CACHE_CFS_VENDOR_KEY,
};

export const TLVendorSelect: React.FC<{
  selected?: any;
  onSelect: (option: any) => void;
  renderOption?: (warehouse: any) => React.ReactNode;
  className?: string;
  editable?: boolean;
  multiple?: boolean;
  style?: any;
  placeholder?: string;
  tlType?: TLType;
}> = ({ selected, tlType = TLType.LTL, onSelect, editable, ...props }) => {
  return (
    <>
      <SyncCacheModelSelect
        {...props}
        className={
          editable
            ? 'select-modal-with-icon-width'
            : 'select-modal-without-icon-width'
        }
        selected={selected}
        onSelect={onSelect}
        cacheKey={CACHE_KEY_MAP[tlType] ?? ''}
      />
    </>
  );
};
