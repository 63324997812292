import React, { useEffect } from 'react';
import {
  Form,
  FormInstance,
  Input,
  InputNumber,
  Select,
  Button,
  Row,
  Col,
} from 'antd';
import { AntDDatePicker } from 'antd-datepicker';
import { Documents } from '@/components/Upload/Documents';
import moment from 'moment';
import { MinimunInsuranceAmount, AGENT_OR_HEADQUARTER_MAP } from '../data';
import { DocumentsStoreLocalFormItem } from '@/components/Upload/DocumentsStoreLocalFormItem';
import numeral from 'numeral';
import Decimal from 'decimal.js';

interface Props {
  form: FormInstance;
}

export const VendorInsuranceForm: React.FC<Props> = ({ form }) => {
  const handleChange = async (event: any) => {
    const { name, value } = event.target as HTMLInputElement;
    await form.setFieldsValue({ [name]: value });
  };

  const validateAmount = (
    rule: any,
    value: any,
    minValue: number,
    callback: any,
  ) => {
    console.log('validateAmount', rule, value, minValue, callback);
    if (!value) {
      callback('Amount is required');
    }

    if (new Decimal(value).lessThan(new Decimal(minValue))) {
      callback(
        'The amount is less than the required amount of ' +
          numeral(minValue).format('0,0.00'),
      );
    }

    callback();
  };

  return (
    <Form layout="vertical" form={form}>
      <Form.Item name="id" noStyle>
        <Input hidden />
      </Form.Item>
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Name is required' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="MC#"
        name="mc_number"
        rules={[{ required: true, message: 'MC# is required' }]}>
        <Input />
      </Form.Item>
      <Row>
        <Col span={12}>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => (
              <Form.Item
                label="General Liability Amount"
                name="general_liability_amount"
                validateStatus={
                  getFieldValue('general_liability_amount') <
                  MinimunInsuranceAmount.GENERAL_LIABILITY
                    ? 'error'
                    : 'success'
                }
                rules={[
                  {
                    required: true,
                    validator(rule, value, callback) {
                      return validateAmount(
                        rule,
                        value,
                        MinimunInsuranceAmount.GENERAL_LIABILITY,
                        callback,
                      );
                    },
                  },
                ]}>
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Expired At"
            name="general_liability_amount_expired_at"
            rules={[{ required: true }]}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <>
                  <AntDDatePicker
                    style={{ width: '100%' }}
                    onChange={(date) =>
                      handleChange({
                        target: {
                          name: 'general_liability_amount_expired_at',
                          value: date
                            ? date.format('YYYY-MM-DD HH:mm:ss')
                            : null,
                        },
                      })
                    }
                    value={
                      getFieldValue('general_liability_amount_expired_at')
                        ? moment(
                            getFieldValue(
                              'general_liability_amount_expired_at',
                            ),
                          )
                        : null
                    }
                  />
                </>
              )}
            </Form.Item>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => (
              <>
                <Form.Item
                  label="Cargo Insurance Amount"
                  name="cargo_insurance_amount"
                  validateStatus={
                    getFieldValue('cargo_insurance_amount') <
                    MinimunInsuranceAmount.CARGO_INSURANCE
                      ? 'error'
                      : 'success'
                  }
                  rules={[
                    {
                      required: true,
                      validator(rule, value, callback) {
                        return validateAmount(
                          rule,
                          value,
                          MinimunInsuranceAmount.CARGO_INSURANCE,
                          callback,
                        );
                      },
                    },
                  ]}>
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
              </>
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Expired At"
            name="cargo_insurance_amount_expired_at"
            rules={[{ required: true }]}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <>
                  <AntDDatePicker
                    style={{ width: '100%' }}
                    onChange={(date) =>
                      handleChange({
                        target: {
                          name: 'cargo_insurance_amount_expired_at',
                          value: date
                            ? date.format('YYYY-MM-DD HH:mm:ss')
                            : null,
                        },
                      })
                    }
                    value={
                      getFieldValue('cargo_insurance_amount_expired_at')
                        ? moment(
                            getFieldValue('cargo_insurance_amount_expired_at'),
                          )
                        : null
                    }
                  />
                </>
              )}
            </Form.Item>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => (
              <>
                <Form.Item
                  label="Automobile Insurance Amount"
                  name="automobile_insurance_amount"
                  validateStatus={
                    getFieldValue('automobile_insurance_amount') <
                    MinimunInsuranceAmount.AUTOMOBILE_INSURANCE
                      ? 'error'
                      : 'success'
                  }
                  rules={[
                    {
                      required: true,
                      validator(rule, value, callback) {
                        return validateAmount(
                          rule,
                          value,
                          MinimunInsuranceAmount.AUTOMOBILE_INSURANCE,
                          callback,
                        );
                      },
                    },
                  ]}>
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
              </>
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Expired At"
            name="automobile_insurance_amount_expired_at"
            rules={[{ required: true }]}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <>
                  <AntDDatePicker
                    style={{ width: '100%' }}
                    onChange={(date) =>
                      handleChange({
                        target: {
                          name: 'automobile_insurance_amount_expired_at',
                          value: date
                            ? date.format('YYYY-MM-DD HH:mm:ss')
                            : null,
                        },
                      })
                    }
                    value={
                      getFieldValue('automobile_insurance_amount_expired_at')
                        ? moment(
                            getFieldValue(
                              'automobile_insurance_amount_expired_at',
                            ),
                          )
                        : null
                    }
                  />
                </>
              )}
            </Form.Item>
          </Form.Item>
        </Col>
      </Row>
      {/* 
      <Form.Item
        label="Expired At"
        name="expired_at"
        rules={[{ required: true }]}>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <>
              <AntDDatePicker
                style={{ width: '100%' }}
                onChange={(date) =>
                  handleChange({
                    target: {
                      name: 'expired_at',
                      value: date ? date.format('YYYY-MM-DD HH:mm:ss') : null,
                    },
                  })
                }
                value={
                  getFieldValue('expired_at')
                    ? moment(getFieldValue('expired_at'))
                    : null
                }
              />
            </>
          )}
        </Form.Item>
      </Form.Item> */}
      <Form.Item
        label="Agent or Headquarter"
        name="agent_or_headquarter"
        rules={[{ required: true }]}>
        <Select>
          {Object.keys(AGENT_OR_HEADQUARTER_MAP).map((key) => (
            <Select.Option key={key} value={key}>
              {
                AGENT_OR_HEADQUARTER_MAP[
                  key as unknown as keyof typeof AGENT_OR_HEADQUARTER_MAP
                ]
              }
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Memo" name="memo" rules={[{ required: false }]}>
        <Input.TextArea rows={4} />
      </Form.Item>

      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) =>
          getFieldValue('id') ? (
            <Documents
              title="Documents"
              target="vendor_insurance"
              targetId={getFieldValue('id')}
            />
          ) : (
            <DocumentsStoreLocalFormItem form={form} />
          )
        }
      </Form.Item>
    </Form>
  );
};
