import { useState } from 'react';
import type { RecognitionResult, InvoiceType } from '../interface/types';
import { useApp } from '@/utils/useapp';
import { useDocumentClassify } from './useDocumentClassify';
import { generateAuthData } from '@/utils/drayaiAuth';
import {
  SHIPMENT_TYPE_MAP,
  TransportationType,
  ShipmentTypeId,
} from '../interface/types';

export function useRecognition() {
  const [recognitionResults, setRecognitionResults] = useState<
    Record<string, RecognitionResult>
  >({});
  const app = useApp();
  const { getBase64 } = useDocumentClassify();

  const startRecognition = async (
    file: File,
    fileId: string,
    invoiceType: InvoiceType,
    originalText?: string,
  ) => {
    try {
      const base64Content = await getBase64(file, false);
      const result: RecognitionResult = await app.service.api(
        `${DRAYAI_BASE_URL}/ai/invoice/extract`,
        {
          method: 'post',
          timeout: 600000,
          data: {
            file: originalText
              ? undefined
              : {
                  filename: file.name,
                  content: base64Content,
                },
            type: invoiceType,
            original_text: originalText,
            ...generateAuthData(''),
          },
        },
      );

      result.status = 'success';
      result.invoice_type = invoiceType;

      try {
        if (result.bill_from && result.invoice_type) {
          const transportType = result.invoice_type.toLowerCase() as TransportationType;
          const shipmentTypeInt = SHIPMENT_TYPE_MAP[transportType];
          const association = await app.service.get(
            'billingCode/ai/association',
            {
              params: {
                shipment_type: shipmentTypeInt,
                bill_from_name: result.bill_from,
              },
            },
          );
          if (association?.data?.length > 0) {
            for (const item of association.data) {
              if (item.bill_from_id) {
                result.bill_from_id = item.bill_from_id;
                result.bill_from_type = item.bill_from_type;
                if (transportType === TransportationType.DRAY) {
                  if (item.bill_from_type === 'App\\Models\\Vendor') {
                    const res = await app.service.get(
                      `vendors/${item.bill_from_id}`,
                    );
                    result.bill_from_name = res.data;
                  } else if (item.bill_from_type === 'App\\Models\\Terminal') {
                    const res = await app.service.get('terminals', {
                      params: {
                        id: item.bill_from_id,
                      },
                    });
                    if (res?.data?.length > 0) {
                      result.bill_from_name = res.data[0];
                    }
                  }
                }
              }
              if (item.billing_description) {
                for (let index = 0; index < result.items.length; index++) {
                  const i = result.items[index];
                  if (i.description === item.billing_description) {
                    // 直接在原位置更新数据
                    result.items[index] = {
                      ...i, // 保留原有的其他属性
                      code: item.billing_code,
                      name: item.billing_name,
                    };
                    break;
                  }
                }
              }
            }
          }
        }
      } catch (error) {
        console.error('Bill association failed:', error);
      }

      try {
        const billCodeList = {
          invoice_type: invoiceType.toLowerCase(),
          items: [],
          ...generateAuthData(''),
        } as { invoice_type: InvoiceType; items: { description: string }[] };
        result.items.forEach((item: { description: string; code: string }) => {
          if (item.description && item.code == '') {
            billCodeList.items.push({
              description: item.description,
            });
          }
        });
        billCodeList.items = billCodeList.items.filter(
          (item) => item.description !== '',
        );
        if (billCodeList.items.length > 0) {
          const billCodeFill = await app.service.api(
            `${DRAYAI_BASE_URL}/ai/bill/code/classify`,
            {
              method: 'post',
              data: billCodeList,
            },
          );
          if (billCodeFill.items && Array.isArray(billCodeFill.items)) {
            // match billCodeFill.items with result.items with description
            result.items.forEach((item) => {
              const match = billCodeFill.items.find(
                (billCode: {
                  description: string;
                  code: string;
                  name: string;
                }) => billCode.description === item.description,
              );
              if (match) {
                item.code = match.code;
                item.name = match.name;
              }
            });
          }
        }
      } catch (error) {
        console.error('Bill code classification failed:', error);
      }

      setRecognitionResults((prev) => ({
        ...prev,
        [fileId]: result,
      }));

      return result;
    } catch (error) {
      console.error('Recognition failed:', error);
      throw error;
    }
  };

  const updateRecognitionResult = (
    fileId: string,
    key: string,
    value: string | any[] | null,
  ) => {
    setRecognitionResults((prev) => {
      if (!prev[fileId]) {
        return prev;
      }

      const updatedResult = { ...prev[fileId] };
      if (value === null) {
        delete updatedResult[key as keyof RecognitionResult];
      } else {
        (updatedResult[key as keyof RecognitionResult] as any) = value;
      }

      return {
        ...prev,
        [fileId]: updatedResult,
      };
    });
  };

  const saveRecognitionResult = async (result: RecognitionResult) => {
    try {
      if (
        result.invoice_type === TransportationType.DRAY.toUpperCase() &&
        result.bill_from_type &&
        result.bill_from_id
      ) {
        await app.service.post('billingCode/ai/association', {
          data: {
            shipment_type: ShipmentTypeId.DRAY,
            bill_from_name: result.bill_from,
            bill_from_type: result.bill_from_type,
            bill_from_id: result.bill_from_id,
          },
        });
      }

      for (const item of result.items) {
        if (item.description && item.code && item.name) {
          const transportType = result.invoice_type.toLowerCase() as TransportationType;
          const shipmentTypeInt = SHIPMENT_TYPE_MAP[transportType];

          if (shipmentTypeInt) {
            await app.service.post('billingCode/ai/association', {
              data: {
                shipment_type: shipmentTypeInt,
                bill_from_name: result.bill_from,
                billing_description: item.description,
                billing_code: item.code,
                billing_name: item.name,
              },
            });
          }
        }
      }
    } catch (error) {
      console.error('Failed to save recognition result:', error);
      throw error;
    }
  };

  return {
    recognitionResults,
    startRecognition,
    updateRecognitionResult,
    saveRecognitionResult,
  };
}
