import React, { useState } from 'react';
import AutoResizeTable from '@/components/AutoResizeTable';
import { EditableSelect } from '@/components/Editable';
import { LogActivities } from '@/components/LogActivities';
import { TARGET_TYPE_LTL_SHIPMENT } from '@/components/Tasks/Interfaces/ActionInterface';
import { TodoList } from '@/components/Tasks/Render/TodoList';
import { InfoCircleOutlined, NodeIndexOutlined } from '@ant-design/icons';
import { Space, message, Tooltip, Tag, Button } from 'antd';
import { Upload } from '../../components/Upload';
import { ColumnsType, TablePaginationConfig, TableProps } from 'antd/lib/table';
import { get, truncate } from 'lodash';
import { BUSINESS_STATUS_OPTIONS, CUSTOMER_STATUS_OPTIONS } from '..';
import { AccountingModal } from '../../components/AccountingModal';
import { CopyMultipleButton, toCopy } from '@/components/CopyMultipleButton';
import {
  ShipmentBusinessStatus,
  generateOptions,
  ArStatusLabelMap,
  ApStatusLabelMap,
  RebillStatusLabelMap,
  ShipmentStatus,
  ShipmentCustomerStatus,
} from '../../constants';
import { ShipmentInterface } from '../Interfaces/ShipmentInterface';
import { useEffect, useMemo } from 'react';
import { TrackTable } from '../../components/TrackTable';
import ShipmentIdColumn from './ShipmentIdColumn';
import cache, { CACHE_ADMIN_KEY, CACHE_USER_SELECT_KEY } from '@/stores/cache';
import {
  ACCOUNT_ICON,
  transferTasksIconColor,
} from '@/components/Tasks/Interfaces/TaskIconColorInterface';
import LocationCell from '@/pages/truckload/components/LocationCell';
import { TARGET_LTL as TroubleStatusTarget } from '@/components/TroubleStatuses/data';
import TroubleStatusesPopover from '@/components/TroubleStatuses/TroubleStatusesPopover';
import ShipmentPronumberColumn from './ShipmentPronumberColumn';
import VerificationStatus from './ShipmentVerifyColumn';
import RatingTagPopover from '@/components/Rating/RatingTagPopover';
import { ModelTarget } from '@/components/Rating/Interfaces/ModelType';
import { ResponseTarget } from '@/components/Rating/Interfaces/RepsonseType';
import LocationCellExtra from '@/pages/truckload/components/LocationCellExtra';
import EditableUserSelect from '@/components/EditableUserSelect';
import EditableCommonDatePicker from '@/components/EditableCommonDatePicker';
import TimeText from '@/components/AvailableTime/TimeText';
import AttributeChangelogInfoIcon from '@/components/AttributeChange/AttributeChangelogInfoIcon';
import { AttributeChangeTarget } from '@/Interfaces/AttributeChangeLog/AttributeChangeTarget';
import ApptTag from './ApptTag';
import FromType from './FromType';
import CarrierQuickLink from '@/components/CarrierQuickLink';
import { ShipmentDisputeMemo } from '@/components/Memo/ShipmentDisputeMemo';
import { EditableBusinessStatusSelect } from './EditableBusinessStatusSelect';
import { EditableCustomerStatusSelect } from './EditableCustomerStatusSelect';

export interface ShipmentsTableProps {
  tabId: number;
  pagination?: false | TablePaginationConfig | undefined;
  columns?: ColumnsType<ShipmentInterface>;
  data: any;
  handleTableChange: (pagination: any, filters: any, sorter: any) => void;
  handleShow: (record: any) => void;
  handleShowQuote?: (id: number) => void;
  handlePatchUpdate: (id: number, model: string, data: any) => void;
  fetchData: (pagination?: any, filters?: any, sorter?: any) => void;
  refreshLtlShipment: (id: number) => void;
  openId?: number;
  setDefaultColumns?: (columns: ColumnsType<ShipmentInterface>) => void;
  onSetSelected?: (selectedRowKeys: any[], selectedRows: any[]) => void;

  loading?: boolean;
  onVerificationChange: (
    shipmentId: number,
    key: string,
    checked: boolean,
  ) => void;
}

const tableComponents: TableProps<any>['components'] = {
  body: {
    // onMouseEnter 和 onMouseLeave 在数据量多时严重阻塞表格单元格渲染，严重影响性能
    cell: ({ onMouseEnter, onMouseLeave, ...props }) => <td {...props} />,
  },
};

const OP_USER_SELECT_PATH = ['operator_id'];
const AGENT_USER_SELECT_PATH = ['agent_id'];
const BD_USER_SELECT_PATH = ['business_development_id'];
const SALES_USER_SELECT_PATH = ['sales_id'];
const SALES_SUPPORT_USER_SELECT_PATH = ['sales_support_id'];

const ShipmentsTable: React.FC<ShipmentsTableProps> = ({
  tabId,
  pagination = false,
  columns,
  data,
  handleTableChange,
  handleShow,
  handleShowQuote,
  handlePatchUpdate,
  fetchData,
  refreshLtlShipment,
  setDefaultColumns,
  onSetSelected,
  openId = 0,
  loading,
  onVerificationChange,
}: ShipmentsTableProps) => {
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
      onSetSelected && onSetSelected(selectedRowKeys, selectedRows);
    },
    onSelectMultiple: (_selected: any, selectedRows: any, _changeRows: any) => {
      const selectedRowKeys = selectedRows.map((s: any) => s.id);
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
      onSetSelected && onSetSelected(selectedRowKeys, selectedRows);
    },
  };

  useEffect(() => {
    setSelectedRowKeys([]);
    setSelectedRows([]);
    onSetSelected && onSetSelected([], []);
  }, [tabId]);

  const handleCopyShipmentID = React.useCallback(() => {
    if (!data) {
      return false;
    }

    let uids = [];
    let msg = '';

    if (selectedRowKeys?.length <= 0) {
      uids = data.data.map((c: any) => c.uid);
      msg = 'Copy All ID';
    } else {
      const shipments = data.data.filter((d: any) =>
        selectedRowKeys.includes(d.id),
      );

      uids = shipments.map((c: any) => c.uid);
      msg = `Copy  Selected ${uids.length} ID`;
    }

    toCopy(uids.join('\r\n'), `${msg}`, `${msg} Failed`);
  }, [selectedRowKeys, data]);

  const handleCopyVendorIDs = React.useCallback(() => {
    if (!data) {
      return false;
    }

    let vids = [];
    let msg = '';

    if (selectedRowKeys?.length <= 0) {
      vids = data.data.map((c: any) => c.vendor_shipment_id);

      msg = 'Copy All VID';
    } else {
      const shipments = data.data.filter((d: any) =>
        selectedRowKeys.includes(d.id),
      );

      vids = shipments.map((c: any) => c.vendor_shipment_id);
      msg = `Copy  Selected ${vids.length} VID`;
    }

    toCopy(vids.join('\r\n'), `${msg}`, `${msg} Failed`);
  }, [selectedRowKeys, data]);

  const handleCopyProNumbers = React.useCallback(() => {
    if (!data) {
      return false;
    }

    let proNumbers = [];
    let msg = '';

    if (selectedRowKeys?.length <= 0) {
      proNumbers = data.data.map((c: any) => c.pro_number);

      msg = 'Copy All Pro#';
    } else {
      const shipments = data.data.filter((d: any) =>
        selectedRowKeys.includes(d.id),
      );

      proNumbers = shipments.map((c: any) => c.pro_number);
      msg = `Copy Selected ${proNumbers.length} Pro#`;
    }

    toCopy(proNumbers.join('\r\n'), `${msg}`, `${msg} Failed`);
  }, [selectedRowKeys, data]);

  const adminUsers = useMemo(() => cache.data[CACHE_ADMIN_KEY], [
    cache.data[CACHE_ADMIN_KEY],
  ]);
  useEffect(() => {
    if (!adminUsers || adminUsers.length === 0) {
      cache.fetch(CACHE_ADMIN_KEY);
    }
  }, [adminUsers]);

  const users = useMemo(() => cache.data[CACHE_USER_SELECT_KEY], [
    cache.data[CACHE_USER_SELECT_KEY],
  ]);
  useEffect(() => {
    if (!users || users.length === 0) {
      cache.fetch(CACHE_USER_SELECT_KEY);
    }
  }, [users]);

  const defaultColumns: ColumnsType<ShipmentInterface> = useMemo(
    () => [
      {
        title: () => (
          <>
            <Space>
              ID
              <CopyMultipleButton
                onClick={handleCopyShipmentID}
              ></CopyMultipleButton>
            </Space>{' '}
            <Space>
              VID
              <CopyMultipleButton
                onClick={handleCopyVendorIDs}
              ></CopyMultipleButton>
            </Space>{' '}
            <Space>
              Pro#
              <CopyMultipleButton
                onClick={handleCopyProNumbers}
              ></CopyMultipleButton>
            </Space>
          </>
        ),
        dataIndex: 'uid',
        key: 'id',
        fixed: 'left',
        width: 250,
        sorter: false,
        render: (text, record) => {
          return (
            <Space>
              <ShipmentIdColumn
                shipment={record}
                handleShow={handleShow}
                fetchData={fetchData}
              />
            </Space>
          );
        },
      },
      {
        title: 'Dispatch Status',
        dataIndex: 'business_status',
        key: 'business_status',
        width: 200,
        fixed: 'left',
        align: 'center',
        render: (text, record) => (
          <EditableBusinessStatusSelect
            record={record}
            patchUpdate={handlePatchUpdate}
          />
        ),
      },
      {
        title: 'Customer Status',
        dataIndex: 'customer_status',
        key: 'customer_status',
        width: 200,
        align: 'center',
        render: (text, record) => (
          <EditableCustomerStatusSelect
            record={record}
            patchUpdate={handlePatchUpdate}
          />
        ),
      },
      {
        title: 'Verify',
        key: 'verification',
        width: 100,
        render: (_, record) => {
          if (!onVerificationChange) {
            console.warn(
              'onVerificationChange is missing in Verify column render',
            );
          }

          return (
            <VerificationStatus
              shipmentId={record.id}
              verifications={record.shipment_verifications}
              opAllVerifiedAt={record.op_all_verified_at}
              onVerificationChange={onVerificationChange}
            />
          );
        },
      },
      {
        title: 'OP',
        dataIndex: 'operator_id',
        key: 'operator_id',
        width: 150,
        ellipsis: {
          showTitle: false,
        },
        render: (value, record) => (
          <EditableUserSelect
            data={adminUsers}
            record={record}
            path={OP_USER_SELECT_PATH}
            onSelect={(user: any) =>
              handlePatchUpdate(record.id, 'shipments', {
                operator_id: user?.id || 0,
              })
            }
          />
        ),
      },
      {
        title: 'Agent',
        dataIndex: 'agent_id',
        key: 'agent_id',
        width: 150,
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => (
          <EditableUserSelect
            data={users}
            record={record}
            type="user"
            path={AGENT_USER_SELECT_PATH}
            onSelect={(user: any) =>
              handlePatchUpdate(record.id, 'shipments', {
                agent_id: user?.id || 0,
              })
            }
          />
        ),
      },
      {
        title: 'BD',
        dataIndex: 'business_development_id',
        key: 'business_development_id',
        width: 150,
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => (
          <EditableUserSelect
            data={adminUsers}
            record={record}
            path={BD_USER_SELECT_PATH}
            onSelect={(user: any) =>
              handlePatchUpdate(record.id, 'shipments', {
                business_development_id: user?.id || 0,
              })
            }
          />
        ),
      },
      {
        title: 'Sales',
        dataIndex: 'sales_id',
        key: 'sales_id',
        width: 150,
        ellipsis: {
          showTitle: false,
        },
        render: (value, record) => (
          <EditableUserSelect
            data={adminUsers}
            record={record}
            path={SALES_USER_SELECT_PATH}
            onSelect={(user: any) =>
              handlePatchUpdate(record.id, 'shipments', {
                sales_id: user?.id || 0,
              })
            }
          />
        ),
      },
      {
        title: 'Sales Support',
        dataIndex: 'sales_support_id',
        key: 'sales_support_id',
        width: 150,
        ellipsis: {
          showTitle: false,
        },
        render: (value, record) => (
          <EditableUserSelect
            data={adminUsers}
            record={record}
            path={SALES_SUPPORT_USER_SELECT_PATH}
            onSelect={(user: any) =>
              handlePatchUpdate(record.id, 'shipments', {
                sales_support_id: user?.id || 0,
              })
            }
          />
        ),
      },
      {
        title: 'Order Status',
        dataIndex: 'order_status_name',
        key: 'order_status',
        width: 120,
      },
      {
        title: 'AR Status',
        dataIndex: 'ar_status',
        key: 'ar_status',
        width: 120,
        render: (text: string, record: any) => (
          <EditableSelect
            name="ar_status"
            value={record.ar_status}
            options={generateOptions(ArStatusLabelMap)}
            onChange={(name: string, value: any) => {
              const callback = () =>
                handlePatchUpdate(record.id, 'shipments', {
                  [name]: value,
                });
              callback();
            }}
          />
        ),
      },
      {
        title: 'AP Status',
        dataIndex: 'ap_status',
        key: 'ap_status',
        width: 120,
        render: (text: string, record: any) => (
          <EditableSelect
            name="ap_status"
            value={record.ap_status}
            options={generateOptions(ApStatusLabelMap)}
            onChange={(name: string, value: any) => {
              const callback = () =>
                handlePatchUpdate(record.id, 'shipments', {
                  [name]: value,
                });
              callback();
            }}
          />
        ),
      },
      {
        title: 'Rebill Status',
        dataIndex: 'rebill_status',
        key: 'rebill_status',
        width: 120,
        render: (text: string, record: any) => (
          <EditableSelect
            name="rebill_status"
            value={record.rebill_status}
            options={generateOptions(RebillStatusLabelMap)}
            onChange={(name: string, value: any) => {
              const callback = () =>
                handlePatchUpdate(record.id, 'shipments', {
                  [name]: value ? value : null,
                });
              callback();
            }}
          />
        ),
      },
      {
        title: 'Customer Ref#',
        dataIndex: 'customer_reference_number',
        key: 'customer_reference_number',
        width: 160,
        ellipsis: {
          showTitle: false,
        },
        render: (customer_reference_number) => (
          <Tooltip placement="topLeft" title={customer_reference_number}>
            {customer_reference_number}
          </Tooltip>
        ),
      },
      {
        title: 'From Type',
        dataIndex: 'from_type',
        key: 'from_type',
        width: 120,
        render: (text, record) => (
          <FromType shipment={record} onSaved={() => fetchData()} />
        ),
        // render: (text, record) => (
        //   <Space direction="vertical" align="center">
        //     <div style={{ width: '100%' }}>{text}</div>
        //     <a
        //       onClick={() =>
        //         handleShowQuote && handleShowQuote(record.quote_id)
        //       }
        //     >
        //       {record.quote_number}
        //     </a>
        //   </Space>
        // ),
      },
      {
        title: 'Customer',
        dataIndex: 'customer',
        key: 'customer',
        width: 160,
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => (
          <Tooltip
            placement="topLeft"
            title={`${record.company_code} (${record.user_name})`}
          >
            {`${record.company_code}(${record.user_name})`}
          </Tooltip>
        ),
      },
      {
        title: 'Quote Rate',
        dataIndex: 'quote_rate',
        key: 'quote_rate',
        width: 100,
      },
      {
        title: 'Buy Rate',
        dataIndex: 'vendor_rate',
        key: 'vendor_rate',
        width: 100,
        render: (text, record: ShipmentInterface) => (
          <Space>
            <div>{text}</div>
            <AttributeChangelogInfoIcon
              target={AttributeChangeTarget.LTL_SHIPMENT}
              targetId={record.id}
              attribute="vendor_rate"
              modified={record.vendor_rate_modified}
            />
          </Space>
        ),
      },
      {
        title: 'Sell Rate',
        dataIndex: 'sell_rate',
        key: 'sell_rate',
        width: 100,
        render: (text, record: ShipmentInterface) => (
          <Space>
            <div>{text}</div>
            <AttributeChangelogInfoIcon
              target={AttributeChangeTarget.LTL_SHIPMENT}
              targetId={record.id}
              attribute="sell_rate"
              modified={record.sell_rate_modified}
            />
          </Space>
        ),
      },
      {
        title: 'Cargo Value',
        dataIndex: 'cargo_value',
        key: 'cargo_value',
        width: 100,
      },
      {
        title: 'Premium',
        dataIndex: 'premium',
        key: 'premium',
        width: 100,
      },
      {
        title: 'Total SR',
        dataIndex: 'total_sell_rate',
        key: 'total_sell_rate',
        width: 100,
      },
      {
        title: 'Est Margin',
        dataIndex: 'margin_rate',
        key: 'margin_rate',
        width: 150,
        render: (text, record) => {
          return (
            record.margin_rate.toFixed(2) +
            '/' +
            record.margin_percent.toFixed(2) +
            '%'
          );
        },
      },
      {
        title: 'AR',
        dataIndex: 'invoice_amount',
        key: 'invoice_amount',
        sorter: true,
        width: 100,
      },
      {
        title: 'AP',
        dataIndex: 'bill_amount',
        key: 'bill_amount',
        sorter: true,
        width: 100,
      },
      {
        title: 'Act Profit',
        dataIndex: 'profit_amount_total',
        key: 'profit_amount_total',
        sorter: true,
        width: 100,
      },
      {
        title: 'Vendor',
        dataIndex: 'vendor_name',
        key: 'vendor_name',
        width: 150,
      },
      {
        title: 'Carrier',
        dataIndex: 'vendor_carrier_name',
        key: 'vendor_carrier_name',
        sorter: true,
        width: 250,
        render: (text, record) => {
          return (
            <>
              {text}
              {record.carrier_id && (
                <RatingTagPopover
                  modelId={record.carrier_id}
                  modelTarget={ModelTarget.LtlCarrier}
                  model={record.carrier}
                  responsible={record}
                  responsibleTarget={ResponseTarget.LtlShipment}
                  onSaved={() => refreshLtlShipment(record.id)}
                />
              )}
            </>
          );
        },
      },
      {
        title: 'Pro Number',
        dataIndex: 'pro_number',
        key: 'pro_number',
        width: 180,
        render: (text, record) => (
          <ShipmentPronumberColumn
            shipment={record}
            handleEdit={handlePatchUpdate}
          />
        ),
      },
      {
        title: 'Vendor PU#',
        dataIndex: 'vendor_pu',
        key: 'vendor_pu',
        width: 150,
        render: (text, record) => (
          <CarrierQuickLink
            carrierId={record.carrier_id || 0}
            number={record.vendor_pu || ''}
          />
        ),
      },
      {
        title: 'Est Pickup Date',
        dataIndex: 'estimated_pickup_date',
        key: 'estimated_pickup_date',
        width: 150,
        sorter: true,
        render: (value, record) => (
          <div>
            <div>{value}</div>
            <div>
              <TimeText
                time={record.estimated_pickup_date_available_time?.value}
              />
            </div>
          </div>
          // <EditableCommonDatePicker
          //   value={value}
          //   onChange={(date, dateString) =>
          //     handlePatchUpdate(record.id, 'shipments', {
          //       estimated_pickup_date: dateString || null,
          //     })
          //   }
          // />
        ),
      },
      {
        title: 'Est Delivery Date',
        dataIndex: 'estimated_delivery_date',
        key: 'estimated_delivery_date',
        width: 150,
        sorter: true,
        render: (value, record) => (
          <div>
            <div>{value}</div>
            <div>
              <TimeText
                time={record.estimated_delivery_date_available_time?.value}
              />
              {record.has_delivery_appointment &&
                record.confirmed_delivery_date && <ApptTag shipment={record} />}
            </div>
          </div>
          // <EditableCommonDatePicker
          //   value={value}
          //   onChange={(date, dateString) =>
          //     handlePatchUpdate(record.id, 'shipments', {
          //       estimated_delivery_date: dateString || null,
          //     })
          //   }
          // />
        ),
      },
      {
        title: 'Ready',
        dataIndex: 'ready_to_pickup_at',
        key: 'ready_to_pickup_at',
        width: 150,
      },
      {
        title: 'Act Pickup Date',
        dataIndex: 'actual_pickup_date',
        key: 'actual_pickup_date',
        width: 150,
        sorter: true,
        render: (value, record) => (
          <EditableCommonDatePicker
            value={value}
            onChange={(date, dateString) =>
              handlePatchUpdate(record.id, 'shipments', {
                actual_pickup_date: dateString || null,
              })
            }
          />
        ),
      },
      {
        title: 'Act Delivery Date',
        dataIndex: 'actual_delivery_date',
        key: 'actual_delivery_date',
        width: 150,
        sorter: true,
        render: (value, record) => (
          <EditableCommonDatePicker
            value={value}
            onChange={(date, dateString) =>
              handlePatchUpdate(record.id, 'shipments', {
                actual_delivery_date: dateString || null,
              })
            }
          />
        ),
      },
      {
        title: 'Pickup Location',
        dataIndex: 'pickup_address1',
        key: 'pickup_address1',
        sorter: true,
        width: 400,
        render: (text, record) => {
          return (
            <LocationCell
              location={`${record.pickup_address1}
          ${record.pickup_address2} ${record.pickup_city},
          ${record.pickup_state} ${record.pickup_zipcode} ${record.pickup_country}`}
              contact={`${record.pickup_company_name} ${record.pickup_contact_name} ${record.pickup_contact_phone} ${record.pickup_contact_email}`}
              extraComponent={
                <LocationCellExtra
                  address={{
                    addressLine1: record.pickup_address1,
                    addressLine2: record.pickup_address2,
                    cityId: record.pickup_city_id,
                    zipcode: record.pickup_zipcode,
                  }}
                  transitInfo={{
                    transitDays: record.quote_transit_days,
                    distance: record.distance,
                  }}
                  currentShipmentId={record.id}
                />
              }
            ></LocationCell>
          );
        },
      },
      {
        title: 'Delivery Location',
        dataIndex: 'destination_address1',
        key: 'destination_address1',
        width: 320,
        sorter: true,
        render: (text, record) => {
          return (
            <LocationCell
              location={`${record.destination_address1}
          ${record.destination_address2} ${record.destination_city},
          ${record.destination_state} ${record.destination_zipcode} ${record.destination_country}`}
              contact={`${record.destination_company_name} ${record.destination_contact_name} ${record.destination_contact_phone} ${record.destination_contact_email}`}
              extraComponent={
                <LocationCellExtra
                  address={{
                    addressLine1: record.destination_address1,
                    addressLine2: record.destination_address2,
                    cityId: record.destination_city_id,
                    zipcode: record.destination_zipcode,
                  }}
                  currentShipmentId={record.id}
                />
              }
            ></LocationCell>
          );
        },
      },
      {
        title: 'API Stage',
        dataIndex: 'status',
        key: 'status',
        width: 120,
        render: (text, record) => {
          switch (record.status) {
            case ShipmentStatus.PROCESSING:
              return <Tag color="default">PROCESSING</Tag>;
            case ShipmentStatus.WAIT_REQUOTE:
              return <Tag color="yellow">WAIT_REQUOTE</Tag>;
            case ShipmentStatus.WAIT_CONFIM:
              return <Tag color="yellow">WAIT_CONFIM</Tag>;
            case ShipmentStatus.FAILED:
              return (
                <Tooltip title={record.vendor_errors}>
                  <Tag color="red">FAILED</Tag>
                </Tooltip>
              );
            case ShipmentStatus.SUCCESS:
              return <Tag color="blue">SUCCESS</Tag>;
            case ShipmentStatus.SYNCED:
              return <Tag color="green">SYNCED</Tag>;
            case ShipmentStatus.SYNC_FAILED:
              return <Tag color="gray">SYNC FAILED</Tag>;
            default:
              return <Tag color="default">Unknown</Tag>;
          }
        },
      },
      {
        title: 'Trouble Status',
        dataIndex: 'trouble_status',
        key: 'trouble_status',
        align: 'center',
        width: 150,
        render: (text, record) => {
          return (
            <TroubleStatusesPopover
              target={TroubleStatusTarget}
              targetModel={record}
              onUpdated={(id, troubleStatus) => {
                refreshLtlShipment(id);
              }}
            />
          );
        },
      },
      {
        title: 'Order Created Date',
        dataIndex: 'create_date',
        key: 'create_date',
        align: 'center',
        width: 150,
        sorter: true,
      },
      {
        align: 'center',
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 250,
        render: (text, record) => (
          <Space>
            <Upload shipment={record} />
            <TodoList
              target={TARGET_TYPE_LTL_SHIPMENT}
              targetModel={record}
              iconColor={get(record, 'task_icon_colors.todoList', 'gray')}
              onDone={() => refreshLtlShipment(record.id)}
            />
            <Button type="link" size="small">
              <ShipmentDisputeMemo
                onSaved={() => fetchData && fetchData()}
                model={record}
                initialValue={record.internal_memo}
                open={openId == record.id}
                type="ltl"
              />
            </Button>
            <Button type="link" size="small">
              <div className="text-gray">
                <AccountingModal
                  ltlShipmentId={record.id}
                  showAccountingTips
                  accountingActionTipsProps={{
                    color: get(
                      record,
                      'task_icon_colors.' + ACCOUNT_ICON,
                      'gray',
                    ),
                    list: transferTasksIconColor(record.task_icon_colors),
                  }}
                  onChanged={() => refreshLtlShipment(record.id)}
                />
              </div>
            </Button>

            <Button type="link" size="small">
              <LogActivities
                id={record.id}
                icon={
                  <div className="text-gray">
                    <InfoCircleOutlined />
                  </div>
                }
                type="ltlShipments"
              />
            </Button>
            <Button type="link" size="small">
              <TrackTable
                oredrId={record.id}
                icon={
                  record.tracking_is_expired ? (
                    <div className="text-danger">
                      <NodeIndexOutlined />
                    </div>
                  ) : record?.tracking_histories?.length === 0 ? (
                    <div className="text-gray">
                      <NodeIndexOutlined />
                    </div>
                  ) : (
                    <div className="text-primary">
                      <NodeIndexOutlined />
                    </div>
                  )
                }
                type="tl/ltl"
              />
            </Button>
          </Space>
        ),
      },
    ],
    [
      openId,
      selectedRowKeys,
      selectedRows,
      refreshLtlShipment,
      fetchData,
      handlePatchUpdate,
      onVerificationChange,
    ],
  );

  useEffect(() => {
    setDefaultColumns && setDefaultColumns(defaultColumns);
  }, [defaultColumns]);

  return (
    <AutoResizeTable
      loading={loading}
      pagination={pagination}
      size="small"
      rowSelection={{
        type: 'checkbox',
        ...rowSelection,
      }}
      columns={columns || defaultColumns}
      onChange={handleTableChange}
      dataSource={data?.data || []}
      sticky
      bordered
      scroll={{
        x: 1500,
      }}
      components={tableComponents}
    />
  );
};

export default ShipmentsTable;
