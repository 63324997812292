import React, { useEffect, useMemo, useState } from 'react';
import {
  Space,
  Spin,
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  Popover,
  message,
  Tag,
} from 'antd';
import { LuTimerReset } from 'react-icons/lu';
import { useForm } from 'antd/lib/form/Form';
import { DocumentsStoreLocal } from '@/components/Upload/DocumentsStoreLocal';
import { useApp } from '@/utils/useapp';
import {
  TARGET_CONTAINER_TASK,
  MEMO_ATTRIBUTE_CONTAINER_TASK_RESET_SLA_MEMO,
} from '@/components/Memo/data';
import { TItemProps } from '.';
import { EditMentionsInput } from '@/components/Memo/EditMentionsInput';
import { MemoList } from '@/components/Memo/MemoList';
import { serialize } from 'object-to-formdata';
import { Documents } from '@/components/Upload/Documents';

export const ResetSlaModal: React.FC<
{
  container?: any;
  onSaved?: () => void;
} & TItemProps
> = ({ container, item, onSaved }) => {
  if (!item.show_reset_sla) {
    return <></>;
  }

  const disabled = useMemo(() => item.is_done, [item]);

  const app = useApp();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [form] = useForm();

  const handleSaved = () => {
    setOpen(false);
    form.resetFields();
    onSaved && onSaved();
  };
  const handleClose = () => {
    setLoading(false);
    setOpen(false);
    form.resetFields();
  };

  const handleSave = async () => {
    if (!item.id) {
      return false;
    }

    const values = await form.validateFields();

    setLoading(true);

    const config = {
      data: serialize(values, { indices: true, nullsAsUndefineds: true }),
      requestType: 'form',
    };

    config.data.set('_method', 'put');

    try {
      await app.service.post(`containerTasks/${item.id}/resetSla`, config);

      message.success('Reset Success');

      handleSaved();
    } catch (e: any) {
      message.error(e.data.message || e.data.error);
    } finally {
      setLoading(false);
    }
  };

  // const fetchDocuments = async () => {
  //     app.service
  //         .get(`documents/container_task_reset_sla/${item.id}`)
  //         .then((resp) => {
  //             form.setFieldValue('files', resp.data.map((document: any) => ({
  //                 uid: document.id,
  //                 name: document.file_name,
  //                 status: 'done',
  //                 url: `${API_URL}/documents/${document.id}/url`,
  //             })));
  //         })
  //         .catch((err) => {
  //             message.error(err.data?.message || err.data?.error);
  //         })
  // };

  // const handleFilesChange = async (newFiles: any) => {
  //     form.setFieldValue('files', newFiles);
  // };

  const handleSaveMemo = (memo: any) => {
    onSaved && onSaved();
  };

  useEffect(() => {
    if (open) {
      form.setFieldsValue({
        add_hours: 1,
        id: item.id,
        reset_sla_memo: item.reset_sla_memo ? item.reset_sla_memo : '',
      });
    }
  }, [open, item]);

  return (
    <>
      <span className="text-pointer" onClick={() => setOpen(true)}>
        <LuTimerReset />
      </span>
      {open && (
        <Modal
          title={'Reset Sla'}
          open={open}
          onCancel={handleClose}
          footer={
            <Space>
              <Button disabled={disabled} type="primary" onClick={handleSave}>
                Save
              </Button>
              <Button onClick={handleClose}>Cancel</Button>
            </Space>
          }>
          <Spin spinning={loading}>
            <Form form={form} disabled={disabled} layout="vertical">
              <Form.Item name="id" hidden>
                <Input />
              </Form.Item>
              <Form.Item>
                <Tag color="processing">
                  {item.task} <br />
                  {container.order.uid} - {container?.number}
                </Tag>
              </Form.Item>
              <Form.Item name="add_hours" label="Add Hours" required>
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue, setFieldValue }) => (
                  <>
                    <Form.Item name={'reset_sla_memo'} label="Memo">
                      <Input.TextArea
                        placeholder="Please input memo"
                        rows={4}
                      />
                      {/* <EditMentionsInput
                        disabled={disabled}
                        placeholder='Please input memo'
                        comment={getFieldValue('reset_sla_memo')}
                        setComment={(comment: any) =>
                          setFieldValue('reset_sla_memo', comment)
                        }
                      /> */}
                    </Form.Item>
                    {/* {item.id && (
                      <MemoList
                        disabled={disabled}
                        targetId={item.id}
                        target={TARGET_CONTAINER_TASK}
                        memoAttribute={
                          MEMO_ATTRIBUTE_CONTAINER_TASK_RESET_SLA_MEMO
                        }
                        onSaved={handleSaveMemo}
                      />
                    )} */}
                  </>
                )}
              </Form.Item>
              <Form.Item noStyle shouldUpdate>
                {item.id && (
                  <Documents
                    disabled={disabled}
                    title="Documents"
                    target="container_task_reset_sla"
                    targetId={item.id}
                  />
                )}
                {/* <Form.Item name={'files'} hidden noStyle></Form.Item> */}
                {/* {({ getFieldValue, setFieldValue }) => (<>
                                <Form.Item label="Documents">
                                    <DocumentsStoreLocal files={getFieldValue('files')} onFilesChange={handleFilesChange} />
                                </Form.Item>
                            </>)} */}
              </Form.Item>
            </Form>
          </Spin>
        </Modal>
      )}
    </>
  );
};
