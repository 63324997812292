import React, { useEffect, useMemo } from 'react';
import { ModelSelect } from './ModelSelect';
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Select, SelectProps, Space, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { DrawerForm as SOCLocationForm } from '@/pages/entity/socReturnLocation/components/DawerForm';
import { useApp } from '@/utils/useapp';
import { ISOCReutrnLocation } from './ContainerTable/components/SOCForm';

const SocReturanLocationDrawer: React.FC<{
  id: any;
  onSaved: (id: string) => void;
  onClosed?: (data: any) => void;
  visible?: boolean;
}> = ({ id, onSaved, onClosed, visible }) => {
  const [form] = useForm();
  const [loading, setLoading] = React.useState<boolean>(false);
  const app = useApp();

  const fetchData = async (id: number) => {
    setLoading(true);
    try {
      const resp = await app.service.get('socReturnLocations/' + id);
      form.setFieldsValue({ ...resp.data });
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    const data = await form.validateFields();
    setLoading(true);
    const formValue = Object.assign({}, data);
    try {
      const resp = !id
        ? await app.service.post('socReturnLocations', { data: formValue })
        : await app.service.put('socReturnLocations/' + id, {
          data: formValue,
        });
      form.setFieldsValue({ ...resp.data });
      onSaved(resp.data);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    id && fetchData(id);
  }, [id]);

  return (
    <Drawer
      placement={'left'}
      open={visible}
      onClose={onClosed}
      extra={
        <Space>
          <Button onClick={onClosed}>Close</Button>
          <Button
            // disabled={disabled}
            type="primary"
            loading={loading}
            onClick={handleSave}>
            Save
          </Button>
        </Space>
      }>
      <SOCLocationForm form={form} />
    </Drawer>
  );
};

export const SocReturnLocationSelect: React.FC<{
  selected: any;
  forbidToModfiySpecifyField?: boolean;
  onSelect: (deport: any) => void;
  onClear?: () => void;
  className?: string;
  editable?: boolean;
}> &
SelectProps = ({ selected, onSelect, editable, ...props }) => {
  const [id, setId] = React.useState(null);
  const [visible, setVisible] = React.useState(false);
  const [options, setOptions] = React.useState<any[]>([]);

  const fetchData = async () => {
    const app = useApp();
    const resp = await app.service.get('socReturnLocations');
    setOptions(resp.data);
  };

  const handleSaved = (data: any) => {
    fetchData();
    setVisible(false);
    setId(null);
  };

  const handleClosed = () => {
    setId(null);
    setVisible(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filterOption = (
    input: string,
    option?: { label: string; value: string },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  const _options = useMemo(
    () =>
      options.map((c) => ({
        label: c.name + ' - ' + c.address,
        value: c.id,
      })),
    [options],
  );

  return (
    <>
      <div className="flex">
        {editable && (
          <Button
            onClick={() => {
              setId(selected?.id);
              setVisible(true);
            }}
            className="ant-btn-icon-only select-modal-icon">
            {selected ? (
              <EditOutlined
                style={{
                  color: '#00000073',
                }}
              />
            ) : (
              <PlusCircleOutlined
                style={{
                  color: '#00000073',
                }}
              />
            )}
          </Button>
        )}
        <Select
          {...props}
          className={
            editable
              ? 'select-modal-with-icon-width'
              : 'select-modal-without-icon-width'
          }
          showSearch
          allowClear
          filterOption={filterOption}
          style={{ width: 265 }}
          value={selected?.id || ''}
          options={_options}
          onSelect={(v: number) => {
            onSelect(options.find((o) => o.id == v));
          }}>
          {/* {options.map((v: any) => (
            <Select.Option key={v?.id || ''} value={v?.id || ''}>
              {renderOption ? renderOption(v) : <strong>{v.name}</strong>}
            </Select.Option>
          ))} */}
        </Select>
      </div>
      <SocReturanLocationDrawer
        id={id}
        onSaved={handleSaved}
        onClosed={handleClosed}
        visible={visible}
      />
    </>
  );
};
