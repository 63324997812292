import React, { useState, useEffect, useMemo } from 'react';
import {
  Form,
  Input,
  Select,
  Radio,
  TimePicker,
  Button,
  Row,
  Col,
  message,
  Typography,
} from 'antd';
import { CitySelect } from '@/components/CitySelect';
import { useApp } from '@/utils/useapp';
import { BusinessWeekDay, TCFSLocation } from '../interface';
import { CFSFacilityType, CFSFacilityTypeLabelMap } from '../../constants';
import moment from 'moment-timezone';
const { Title } = Typography;

const CSFForm = ({
  cfsId,
  initialData,
  onSuccess,
}: {
  cfsId: number | undefined;
  initialData: TCFSLocation | null;
  onSuccess: () => void;
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const app = useApp();

  const isUpdateMode = useMemo(() => Boolean(cfsId), [cfsId]);

  useEffect(() => {
    if (isUpdateMode && initialData) {
      const formattedData = {
        ...initialData,
        business_time_start: initialData.business_time_start
          ? moment(initialData.business_time_start, 'HH:mm')
          : null,
        business_time_end: initialData.business_time_end
          ? moment(initialData.business_time_end, 'HH:mm')
          : null,
        verified: initialData.verified ? 1 : 0,
      };
      form.setFieldsValue(formattedData);
    }
  }, [initialData, form, isUpdateMode]);

  const createCFS = async (values: any) => {
    await app.service.post('cfs/cfsLocations', {
      data: values,
    });
    onSuccess?.();
  };

  const updateCFS = async (cfsId: number, values: any) => {
    await app.service.put(`cfs/cfsLocations/${cfsId}`, {
      data: values,
    });
    onSuccess?.();
  };

  const onFinish = async (values: any) => {
    try {
      setLoading(true);

      const formattedValues = {
        ...values,
        city_id: values?.city?.id ?? null,
        business_time_start: values.business_time_start?.format('HH:mm'),
        business_time_end: values.business_time_end?.format('HH:mm'),
      };

      delete formattedValues.city;

      if (isUpdateMode && cfsId) {
        await updateCFS(cfsId, formattedValues);
        message.success('CFS updated successfully');
      } else {
        await createCFS(formattedValues);
        message.success('CFS created successfully');
      }

      onSuccess?.();
    } catch (error) {
      message.error('Failed to save CFS');
      console.error('Failed to save CFS:', error);
    } finally {
      setLoading(false);
    }
  };

  const isNew = useMemo(() => !cfsId, [cfsId]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      initialValues={{
        verified: 0,
        facility_type: CFSFacilityType.BONDED_WAREHOUSE,
      }}
    >
      <Row gutter={16}>
        <Col span={18}>
          <Form.Item label="FirmsCode" name="firms_code" required>
            <Input disabled={!isNew} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Verified" name="verified">
            <Select>
              <Select.Option value={1}>Yes</Select.Option>
              <Select.Option value={0}>No</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label="Facility Type" name="facility_type" required>
        <Radio.Group>
          <Radio value={CFSFacilityType.BONDED_WAREHOUSE}>
            {CFSFacilityTypeLabelMap[CFSFacilityType.BONDED_WAREHOUSE]}
          </Radio>
          <Radio value={CFSFacilityType.INSPECT_WAREHOUSE}>
            {CFSFacilityTypeLabelMap[CFSFacilityType.INSPECT_WAREHOUSE]}
          </Radio>
          <Radio value={CFSFacilityType.CUSTOMS_CONTAINER_STATION}>
            {CFSFacilityTypeLabelMap[CFSFacilityType.CUSTOMS_CONTAINER_STATION]}
          </Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label="Name" name="name" required>
        <Input />
      </Form.Item>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Address1" name="address1" required>
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Address2" name="address2">
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={18}>
          <Form.Item noStyle shouldUpdate>
            {() => (
              <Form.Item label="City" name="city" required>
                <CitySelect
                  selected={form.getFieldValue('city')}
                  onSelect={(v) => {
                    form.setFieldValue('city', v);
                  }}
                />
              </Form.Item>
            )}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Zipcode" name="zipcode" required>
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label="Track Link" name="track_link">
        <Input />
      </Form.Item>

      <Title level={5}>Business Information</Title>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Business Days">
            <Input.Group compact>
              <Form.Item name="business_day_start" noStyle>
                <Select style={{ width: '50%' }}>
                  {Object.values(BusinessWeekDay).map((day) => (
                    <Select.Option key={day} value={day}>
                      {day}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="business_day_end" noStyle>
                <Select style={{ width: '50%' }}>
                  {Object.values(BusinessWeekDay).map((day) => (
                    <Select.Option key={day} value={day}>
                      {day}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Business Hours">
            <Input.Group compact>
              <Form.Item name="business_time_start" noStyle>
                <TimePicker
                  format="HH:mm"
                  minuteStep={15}
                  style={{ width: '50%' }}
                />
              </Form.Item>
              <Form.Item
                name="business_time_end"
                rules={[
                  {
                    validator: (_, value) =>
                      form.getFieldValue('business_time_start') &&
                      form.getFieldValue('business_time_end')
                        ? value >
                          moment(form.getFieldValue('business_time_start'))
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error(
                                'End time must be greater than start time',
                              ),
                            )
                        : Promise.resolve(),
                  },
                ]}
                noStyle
              >
                <TimePicker
                  format="HH:mm"
                  minuteStep={15}
                  style={{ width: '50%' }}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Col>
      </Row>

      <Title level={5}>Contact Information</Title>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item label="Name" name={'contact_name'}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item label="Phone" style={{ marginBottom: 0 }}>
            <Input.Group compact>
              <Form.Item name={'contact_phone'} noStyle>
                <Input style={{ width: '70%' }} />
              </Form.Item>
              <Form.Item name={'contact_ext'} noStyle>
                <Input style={{ width: '30%' }} placeholder="Ext" />
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Email"
            rules={[
              {
                type: 'email',
                message: 'Please enter a valid email address',
              },
            ]}
            name={'contact_email'}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label="Pickup Location Memo" name="pickup_location_memo">
        <Input.TextArea rows={4} />
      </Form.Item>

      <Form.Item>
        <Row justify="end" gutter={8}>
          <Col>
            <Button
              onClick={() => {
                form.resetFields();
              }}
            >
              Close
            </Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit" loading={loading}>
              {isUpdateMode ? 'Update' : 'Save'}
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

export default CSFForm;
