import React, { useMemo } from 'react';
import { Space, Tooltip } from 'antd';
import { TaskInterface } from '../Interfaces/TaskInterface';
import { TAction, TTarget } from '../Interfaces/ActionInterface';
import { TaskOperationInterface } from '../Interfaces/TaskOperationInterface';

const TaskSla: React.FC<{
  operation: TaskOperationInterface;
}> = ({ operation }) => {
  if (!operation?.sla_text || !operation?.started_at || !operation?.due_at) {
    return <></>;
  }

  return (
    <Space>
      <Tooltip
        title={
          <>
            {/* <div>Started At: {operation.started_at}</div> */}

            <div>Due At: {operation.due_at}</div>
            {operation.finished_at && (
              <div>Finished At: {operation.finished_at}</div>
            )}
          </>
        }
      >
        <span
          className={
            !operation.is_done && operation.is_past_due ? 'text-danger' : ''
          }
        >
          ({operation.sla_text})
        </span>
      </Tooltip>
    </Space>
  );
};

export default TaskSla;
