import { Button, Form, Select, Space, Typography } from 'antd';
import { FC, useEffect, useState } from 'react';
import { SocReturnLocationSelect } from '@/components/SocReturnLocationSelect';
import { CloseOutlined } from '@ant-design/icons';
import { TBD, STAY_IN_WAREHOUSE, RETURN_TO_DEPORT } from './data';
const { Text } = Typography;
export interface ISOCReutrnLocation {
  id: number;
  name: string;
  address: string;
  contact: string;
  email: string;
  phone: string;
  return_instruction: string;
}

export const SOCForm: FC<{
  onSelectSOCLocation: (v: any, selected: string | null) => void;
  selected: number;
  socReturnLocation: any;
}> = ({ onSelectSOCLocation, selected, socReturnLocation }) => {
  const [_selected, setSelected] = useState<string>();
  const [deport, setDepot] = useState<ISOCReutrnLocation | null>();
  const options = [TBD, STAY_IN_WAREHOUSE, RETURN_TO_DEPORT];

  const handleSelect = (v: any) => {
    setSelected(v);
    if (v == STAY_IN_WAREHOUSE) {
      onSelectSOCLocation(null, v);
    }

    if (v == TBD) {
      onSelectSOCLocation(null, v);
    }
  };

  const handleRemoveSoc = () => {
    setSelected('');
    setDepot(null);
    onSelectSOCLocation(null, null);
  };

  useEffect(() => {
    if (selected == -1) {
      setSelected(STAY_IN_WAREHOUSE);
    } else if (selected === 0) {
      setSelected(TBD);
    } else if (selected > 0) {
      setSelected(RETURN_TO_DEPORT);
    }
    if (socReturnLocation) {
      setDepot(socReturnLocation);
    }
  }, [selected, socReturnLocation]);

  return (
    <Space direction="vertical">
      <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
        <Form.Item label="Type">
          <Space.Compact>
            <Select
              style={{ width: 300 }}
              allowClear
              value={_selected}
              onSelect={handleSelect}
              onClear={() => {
                handleSelect(null);
                setDepot(null);
                onSelectSOCLocation(null, null);
              }}
              options={options.map((o) => ({ label: o, value: o }))}
            />
            <Button onClick={handleRemoveSoc}>
              <CloseOutlined />
            </Button>
          </Space.Compact>
        </Form.Item>
        {_selected == RETURN_TO_DEPORT && (
          <>
            <Form.Item label="Location">
              <Space.Compact>
                <SocReturnLocationSelect
                  editable
                  selected={deport}
                  onSelect={(deport: ISOCReutrnLocation) => {
                    setDepot(deport);
                    onSelectSOCLocation(deport, _selected);
                  }}
                  onClear={() => {
                    setDepot(null);
                    onSelectSOCLocation(null, _selected);
                  }}
                />
              </Space.Compact>
            </Form.Item>
            {deport && (
              <Form.Item label="Address">
                <Text> {deport.address}</Text>
              </Form.Item>
            )}
          </>
        )}
      </Form>
    </Space>
  );
};
