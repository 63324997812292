import { useApp } from '@/utils/useapp';
import { Button, Modal, Popover, Table } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { get, isObject } from 'lodash';
import { useEffect, useState } from 'react';
import ReactJson from 'react-json-view';

const Identifier = ({ activity, ...props }: any) => {
  const { subject_type, subject } = activity;
  if (subject_type === 'App\\Models\\MBL') {
    return get(subject, 'mbl_number', 'NA');
  }
  if (subject_type === 'App\\Models\\HBL') {
    return get(subject, 'hbl_number', 'NA');
  }
  if (subject_type === 'App\\Models\\Container') {
    return get(subject, 'number', 'NA');
  }
  return get(subject, 'id', 'NA');
};

const DiffText = ({ value, ...props }: any) => {
  if (isObject(value)) {
    return (
      <>
        <ReactJson
          name={false}
          src={value}
          collapsed
          indentWidth={2}
          onEdit={false}
          onAdd={false}
          onDelete={false}
          enableClipboard={false}
        />
      </>
    );
  }

  return <>{value + ''}</>;
};

const AdminPopover = ({ id }: any) => {
  const app = useApp();
  const [data, setData] = useState<any>({});

  const getAdmin = () => {
    app.service.get(`admins/${id}`).then((resp) => {
      setData(resp.data);
    });
  };

  useEffect(() => {
    if (id) {
      getAdmin();
    }
  }, [id]);

  return <>{id == 0 ? 'System' : data?.name || ''}</>;
};

const DiffTextCell = ({ value, record }: any) => {
  if (
    record.column == 'admin_id' ||
    record.column == 'operator_id' ||
    record.column == 'sales_id' ||
    record.column == 'sales_support_id' ||
    record.column == 'operator_assistant_id'
  ) {
    return (
      <Popover
        trigger="click"
        placement="bottom"
        content={<AdminPopover id={value} />}>
        <a>
          <DiffText value={value} />
        </a>
      </Popover>
    );
  }

  return <DiffText value={value} />;
};

export const LogActivitiesTableColumns: ColumnsType<any> = [
  {
    title: 'Operator',
    key: 'name',
    dataIndex: 'name',
    render: (text, record) => <>{get(record, 'causer.name', 'System')}</>,
  },
  {
    title: 'Subject',
    key: 'sub',
    dataIndex: 'sub',
    render: (text, record) => (
      <>{record.subject_type.replace('App\\Models\\', '')}</>
    ),
  },
  {
    title: '#',
    key: 'no',
    dataIndex: 'no',
    render: (text, record) => (
      <>
        <Identifier activity={record} />
      </>
    ),
  },
  {
    title: 'Description',
    key: 'description',
    dataIndex: 'description',
    render: (text, record) => <>{record.description}</>,
  },
  {
    title: 'Time',
    key: 'time',
    dataIndex: 'time',
    render: (text, record) => <>{record.created_at}</>,
  },
  {
    title: '',
    key: 'detail',
    dataIndex: 'detail',
    render: (text, record) => {
      const resource = Object.keys(
        get(record, 'properties.attributes', []),
      ).map((column) => ({
        column,
        before: get(record, `properties.old.${column}`, null),
        after: get(record, `properties.attributes.${column}`, null),
      }));
      return (
        <>
          <Popover
            trigger="click"
            placement="bottom"
            content={
              <Table
                style={{
                  width: '800px',
                }}
                scroll={{
                  x: true,
                  y: 300,
                }}
                size="small"
                pagination={false}
                rowKey={(record) => record.column}
                columns={[
                  {
                    title: 'Column',
                    dataIndex: 'column',
                    key: 'column',
                    width: 200,
                  },
                  {
                    title: 'Before',
                    dataIndex: 'before',
                    key: 'before',
                    width: 400,
                    render: (t, r) => (
                      <DiffTextCell value={r.before} record={r} />
                    ),
                  },
                  {
                    title: 'After',
                    dataIndex: 'after',
                    key: 'after',
                    width: 400,
                    render: (t, r) => (
                      <DiffTextCell value={r.after} record={r} />
                    ),
                  },
                ]}
                dataSource={resource}
              />
            }>
            <Button type="link">Detail</Button>
          </Popover>
        </>
      );
    },
  },
];
