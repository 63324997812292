import { useApp } from '@/utils/useapp';
import {
  message,
  Button,
  Modal,
  Card,
  Row,
  Col,
  Space,
  Form,
  Checkbox,
  Spin,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import Search from 'antd/lib/input/Search';
import React, { useEffect, useMemo } from 'react';

export const ALL = 1;
export const ALL_PORTS = 2;
export const ALL_RAILS = 3;
export const ALL_RAILS_PORTS = 4;
export const NONE = 0;
export const CUSTOMIZED = 13;

export const GROUP_SELECT_MAP = {
  [ALL]: 'All',
  [ALL_PORTS]: 'All Ports',
  [ALL_RAILS]: 'All Rails',
  [ALL_RAILS_PORTS]: 'All Ports & Rails',
  [NONE]: 'None',
};

export const TAG_MAP = {
  [ALL]: 'All',
  [ALL_PORTS]: 'All Ports',
  [ALL_RAILS]: 'All Rails',
  [ALL_RAILS_PORTS]: 'All Ports & Rails',
  [NONE]: 'None',
  [CUSTOMIZED]: 'Customized',
};

export const TERMINAL_TYPES = {
  [ALL_PORTS]: 'OCEAN PORTS',
  [ALL_RAILS]: 'RAILROAD TERMINALS',
};

export const TerminalsSelect: React.FC<{
  intermodal_region_id: number | null;
  defaultSelected?: number[] | null;

  options?: any[];
  onChange?: (T: number[] | null) => void;
  onTagChange?: (T: number) => void;
  btnStyle?: any;
  btnSize?: SizeType;
}> = ({
  intermodal_region_id,
  defaultSelected,
  onChange,
  options,
  btnStyle = {},
  btnSize = 'middle',
}) => {
  const app = useApp();
  const [visible, setVisible] = React.useState<boolean>(false);
  const [terminals, setTerminals] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [selected, setSelected] = React.useState<number[] | null | undefined>(
    defaultSelected,
  );
  const [search, setSearch] = React.useState<string>('');

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    if (defaultSelected) {
      setSelected(defaultSelected);
    } else {
      setSelected(terminals ? terminals.map((t) => t.id) : []);
    }
    setVisible(false);
  };

  useEffect(() => {
    if (defaultSelected) {
      setSelected(defaultSelected);
    } else {
      setSelected(null);
    }
  }, [defaultSelected, terminals]);

  const fetchTerminals = async () => {
    setLoading(true);
    try {
      const resp = await app.service.get('terminals', {
        params: {
          intermodal_region_id,
          active: true,
        },
      });
      setLoading(false);
      const _terminals = resp.data;
      setTerminals(_terminals);
    } catch (err: any) {
      message.error(err.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCheck = (id: number) => {
    const _selected = selected ? [...selected] : terminals.map((t) => t.id);
    const index = _selected.indexOf(id);

    if (index > -1) {
      _selected.splice(index, 1);
    } else {
      _selected.push(id);
    }

    setSelected(_selected);
  };

  const handleGroupSelect = (key: string) => {
    const type = Number(key);
    switch (type) {
      case ALL:
        setSelected(null);
        break;
      case ALL_PORTS:
        setSelected(
          terminals
            .filter((t) => t.type == TERMINAL_TYPES[ALL_PORTS])
            .map((t) => t.id),
        );
        break;
      case ALL_RAILS:
        setSelected(
          terminals
            .filter((t) => t.type == TERMINAL_TYPES[ALL_RAILS])
            .map((t) => t.id),
        );
        break;
      case ALL_RAILS_PORTS:
        setSelected(
          terminals
            .filter(
              (t) =>
                t.type == TERMINAL_TYPES[ALL_RAILS] ||
                t.type == TERMINAL_TYPES[ALL_PORTS],
            )
            .map((t) => t.id),
        );
        break;
      case NONE:
        setSelected([]);
        break;
      default:
        break;
    }
  };

  // useEffect(() => {
  //   if (onChange && !loading) {
  //     onChange(selected);
  //   }
  // }, [selected]);

  // useEffect(() => {
  //   if (onTagChange && !loading) {
  //     onTagChange(tag);
  //   }
  // }, [tag]);

  const handleOk = () => {
    if (onChange && !loading) {
      onChange(selected);
    }

    setVisible(false);
  };

  const display = useMemo(() => {
    return terminals.filter(
      (t) =>
        t.name?.toLowerCase().includes(search.toLowerCase()) ||
        t.delivery_order_address?.toLowerCase().includes(search.toLowerCase()),
    );
  }, [search, terminals]);

  useEffect(() => {
    if (!intermodal_region_id) {
      return;
    }
    if (options) {
      setTerminals([...options]);
    } else {
      fetchTerminals();
    }
  }, [intermodal_region_id, options]);

  const text = useMemo(() => {
    if (!selected) {
      return TAG_MAP[ALL];
    }
    const terminal = terminals.find((t) => t.id == selected[0]);
    if (selected.length > 0 && terminal) {
      return (
        terminals.find((t) => t.id == selected[0])?.name +
        ` ${selected.length > 1 ? '...+' + (selected.length - 1) : ''}`
      );
    } else {
      return 'None';
    }
  }, [defaultSelected, terminals]);
  return (
    <>
      <Button
        size={btnSize || 'small'}
        style={btnStyle}
        type="primary"
        onClick={showModal}>
        {text}
      </Button>
      <Modal
        zIndex={1006}
        width={'80wv'}
        open={visible}
        title="Terminals Select"
        onOk={handleOk}
        onCancel={handleCancel}>
        <Card
          title={
            <Row>
              <Col lg={6}>
                <h3>Filter by Pickup Terminal(s)</h3>
              </Col>
              <Col lg={10}>
                <div className="p-sm">Select:</div>
                <Space split={'|'}>
                  {Object.keys(GROUP_SELECT_MAP).map((key: string) => (
                    <Button
                      key={key}
                      type="link"
                      onClick={() => handleGroupSelect(key)}>
                      {
                        GROUP_SELECT_MAP[
                          key as unknown as keyof typeof GROUP_SELECT_MAP
                        ]
                      }
                    </Button>
                  ))}
                </Space>
              </Col>
              <Col lg={8}>
                <Form.Item
                  className="mb0"
                  label="Find Terminal"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}>
                  <Search
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          }
          key="1">
          {!loading ? (
            <Row>
              {display.length > 0 ? (
                display.map((terminal: any) => (
                  <Col key={terminal.id} lg={8}>
                    <Space align="start">
                      <Checkbox
                        checked={!selected || selected?.includes(terminal.id)}
                        onChange={() => handleCheck(terminal.id)}
                      />
                      <div>
                        <strong>
                          {terminal.firms} | {terminal.name}
                        </strong>
                        <div>
                          {terminal.street} {terminal.city?.full_name}
                        </div>
                      </div>
                    </Space>
                  </Col>
                ))
              ) : (
                <p>No Terminals Found</p>
              )}
            </Row>
          ) : (
            <Spin />
          )}
        </Card>
      </Modal>
    </>
  );
};
