import React, { useEffect, useState } from 'react';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { Editor as ReactDraftEditor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorToolBar } from './EditorToolbar';
import { SelectPresetDataVariables } from './SelectPresetDataVariables';
import { uniqueId } from 'lodash';

import styles from 'res/css/editor.scss';

const calcState = (value: any) => {
  if (value) {
    const blocksFromHTML = convertFromHTML(value);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
    return EditorState.createWithContent(state);
  }

  return EditorState.createEmpty();
};

const Editor: React.FC<{
  presetDataMap?: any;

  value: string;
  isShowPresetData?: boolean;
  onChange: (value: string | null) => void;
}> = ({ presetDataMap, value, isShowPresetData = false, onChange }) => {
  const [editorState, setEditorState] = useState(() => calcState(value));
  const [toolbarCustomButtons, setToolbarCustomButtons] = useState([]);
  const [loading, setLoading] = useState(true);
  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState);
    // onChange(convertToHTML(editorState.getCurrentContent()));
  };

  const handleOnBlur = (event: any) => {
    event.preventDefault();
    onChange(convertToHTML(editorState.getCurrentContent()));
  };

  const handleOnFocus = (event: any) => {
    event.preventDefault();
    EditorState.moveFocusToEnd(editorState);
  };

  const updateEditorState = (state: any) => {
    // setEditorState(EditorState.moveFocusToEnd(state));
    setEditorState(state);
  };

  useEffect(() => {
    updateEditorState(calcState(value));
  }, [value]);

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    if (isShowPresetData) {
      setToolbarCustomButtons([
        ...toolbarCustomButtons,
        <SelectPresetDataVariables
          key={'' + uniqueId()}
          editorState={editorState}
          presetDataMap={presetDataMap}
          onChange={onEditorStateChange}
        />,
      ]);
    }
  }, [isShowPresetData]);

  return (
    <div className="text-editor">
      <div>
        {!loading && (
          <ReactDraftEditor
            editorState={editorState}
            wrapperClassName={styles.editorWrapper}
            toolbarClassName="toolbarClassName"
            editorClassName={styles.editorArea}
            onEditorStateChange={onEditorStateChange}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            toolbar={{
              ...EditorToolBar,
            }}
            onToolbarFocus={(e) => console.log(e)}
            toolbarCustomButtons={toolbarCustomButtons}
          />
        )}
      </div>
    </div>
  );
};

export default Editor;
