import { useApp } from '@/utils/useapp';
import { Modal, message, Spin, Popconfirm, Button, Image } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useRef, useState } from 'react';
import 'tui-image-editor/dist/tui-image-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import ImageEditor from '@toast-ui/react-image-editor';
import moment from 'moment';
import mime from 'mime';

export interface IImage {
  id: number;
  url: string;
  name: string;
  fileName: string;
}

interface IPODImageCutModal {
  image: IImage;
  open: boolean;
  disabled?: boolean;
  onSave: (isOverwrite: boolean, image: File) => void;
  onClose: () => void;
}

export const PODImageCutModal: React.FC<IPODImageCutModal> = ({
  image,
  open,
  disabled = false,
  onSave,
  onClose,
}) => {
  const app = useApp();
  const imageRef = useRef();
  const [imageUrl, setImageUrl] = useState('');

  const handleOnClose = () => {
    onClose();
  };

  const getImage = async () => {
    try {
      const imageBlob = await app.service.get(`documents/${image.id}`, {
        responseType: 'blob',
      });

      const url = URL.createObjectURL(imageBlob);
      setImageUrl(url);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    }
  };

  const handleOnSave = (isOverwrite: boolean) => {
    const imageDataURL = imageRef.current.getInstance().toDataURL();

    const generateFileName = (): string => {
      if (isOverwrite) {
        return image.name;
      }

      return (
        image.name + '_' + moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      );
    };

    onSave(isOverwrite, dataURLtoFile(imageDataURL, generateFileName()));
  };

  const initImageEditor = () => {
    if (imageRef) {
      const editorInstance = imageRef.current.getInstance();
      imageRef.current
        .getRootElement()
        .getElementsByClassName('tie-btn-hand')[0]
        .remove();
      imageRef.current
        .getRootElement()
        .getElementsByClassName('tie-btn-deleteAll')[0]
        .remove();
      imageRef.current
        .getRootElement()
        .getElementsByClassName('tie-btn-delete')[0]
        .remove();
    }
  };

  const dataURLtoFile = (dataURL: any, fileName: string): File => {
    let byteString;

    if (dataURL.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURL.split(',')[1]);
    } else {
      byteString = decodeURIComponent(dataURL.split(',')[1]);
    }

    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    fileName += '.' + mime.getExtension(mimeString);

    return new File([ia], fileName, { type: mimeString });
  };

  React.useEffect(() => {
    if (image.id) {
      getImage();
    }
  }, [image]);

  React.useEffect(() => {
    if (imageUrl) {
      initImageEditor();
    }
  }, [imageUrl]);

  const THEME = {
    'common.bi.image': '', // 左上角logo图片
    'common.bisize.width': '0px',
    'common.bisize.height': '0px',
    'common.border': '1px solid #333',

    'loadButton.display': 'none',
    'downloadButton.display': 'none',

    'colorpicker.button.border': '#ffffff',
    'colorpicker.title.color': '#ffffff',
  };

  return (
    <>
      <Modal
        zIndex={1000}
        title="Image Cut"
        width={'80%'}
        onCancel={handleOnClose}
        destroyOnClose={true}
        open={open}
        footer={
          <>
            <Popconfirm
              placement="top"
              title={
                'Please choose whether to overwrite original image or create a new image'
              }
              onConfirm={() => handleOnSave(false)}
              onCancel={() => handleOnSave(true)}
              okText="Create new"
              disabled={disabled}
              cancelText="Overwrite"
            >
              <Button type="primary" disabled={disabled}>
                Save
              </Button>
            </Popconfirm>
          </>
        }
      >
        <div className="justify-center">
          {imageUrl && (
            <ImageEditor
              ref={imageRef}
              includeUI={{
                loadImage: {
                  path: imageUrl,
                  name: image.name,
                },
                menu: [
                  'resize',
                  'crop',
                  'flip',
                  'rotate',
                  'draw',
                  'shape',
                  'icon',
                  'text',
                  'mask',
                  'filter',
                ],
                initMenu: 'shape',
                uiSize: {
                  width: '100%',
                  height: '700px',
                },
                menuBarPosition: 'bottom',
                theme: THEME,
              }}
              cssMaxHeight={700}
              cssMaxWidth={700}
              selectionStyle={{
                cornerSize: 20,
                rotatingPointOffset: 70,
              }}
              usageStatistics={false}
            />
          )}
        </div>
      </Modal>
    </>
  );
};
