import { TAdmin } from '@/types';
import {
  Button,
  Col,
  DatePicker,
  Form,
  List,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Typography,
  message,
} from 'antd';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import Online from './Online';
import { useApp } from '@/utils/useapp';
import moment from 'moment';
import { AntDRangePicker } from '@/components/AntDRangePicker';
import { useForm } from 'antd/lib/form/Form';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { DEPARTMENTS } from '@/pages/setting/admin/components/DrawerForm';
import { TITLES } from '@/pages/setting/admin/components/data';

const ColumnList: React.FC<{
  items: TAdmin[];
  title: string;
  itemsPerColumn?: number;
}> = ({ title, items, itemsPerColumn = 6 }) => {
  const numColumns = Math.ceil(items.length / itemsPerColumn);

  const columns = Array.from({ length: numColumns }, (_, index) => {
    const startIndex: number = index * itemsPerColumn;
    const endIndex: number = startIndex + itemsPerColumn;
    return items.slice(startIndex, endIndex);
  });

  return (
    <div className="p-sm">
      <h4>{title}</h4>
      <Space className="mx-sm" direction="horizontal" align="start">
        {columns.map((columnItems, columnIndex) => (
          <List
            key={columnIndex}
            dataSource={columnItems}
            renderItem={(item: TAdmin) => (
              <List.Item>
                <Online id={item.id}></Online>{' '}
                <Typography.Text mark></Typography.Text> {item.name}{' '}
                {item.title ? `(${item.title})` : ''}
              </List.Item>
            )}
          />
        ))}
      </Space>
    </div>
  );
};

const DutyLog: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [filter] = useForm();

  const app = useApp();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSearch = async () => {
    try {
      setIsLoading(true);

      const resp = await app.service.get('dutyLogs', {
        params: {
          ...filter.getFieldsValue(),
        },
      });
      setData(resp.data);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {
        <>
          <Button type="primary" onClick={showModal}>
            Duty Report
          </Button>
          <Modal
            destroyOnClose
            width={1000}
            title="Duty Report"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={false}>
            <Form
              layout="vertical"
              onFinish={handleSearch}
              form={filter}
              initialValues={{
                from: moment().subtract(7, 'days').format('YYYY-MM-DD'),
                to: moment().add(7, 'days').format('YYYY-MM-DD'),
              }}>
              <Form.Item hidden name="from"></Form.Item>
              <Form.Item hidden name="to"></Form.Item>
              <Row>
                <Col span={8}>
                  <Form.Item label="Date" shouldUpdate>
                    {({ getFieldValue, setFieldsValue }) => {
                      const startDate = getFieldValue('from');
                      const endDate = getFieldValue('to');
                      return (
                        <AntDRangePicker
                          style={{ width: '100%' }}
                          value={
                            startDate && endDate
                              ? [moment(startDate), moment(endDate)]
                              : undefined
                          }
                          onChange={(value) =>
                            setFieldsValue({
                              from: value
                                ? value[0]?.format('YYYY-MM-DD')
                                : null,
                              to: value ? value[1]?.format('YYYY-MM-DD') : null,
                            })
                          }
                        />
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Operator" name="admin_id">
                    <UserSyncSelect
                      mode="multiple"
                      type="admin"
                      allowClear
                      maxTagCount={'responsive'}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Title" name="title">
                    <Select
                      mode="multiple"
                      options={TITLES.map((d) => ({ label: d, value: d }))}
                      allowClear
                      maxTagCount={'responsive'}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Department" name="department">
                    <Select
                      mode="multiple"
                      options={DEPARTMENTS.map((d) => ({ label: d, value: d }))}
                      allowClear
                      maxTagCount={'responsive'}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label={' '}>
                    <Button
                      htmlType="submit"
                      loading={isLoading}
                      type="primary">
                      Search
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Table
              pagination={false}
              loading={isLoading}
              scroll={{
                x: true,
                y: 800,
              }}
              columns={[
                {
                  title: 'Name',
                  key: 'admin_name',
                  dataIndex: 'admin_name',
                  width: 200,
                },
                {
                  title: 'Average online',
                  key: 'avg',
                  dataIndex: 'avg',
                  width: 200,
                  sorter: (a: any, b: any) => a.avg - b.avg,
                },
                {
                  title: 'Total online',
                  key: 'total',
                  dataIndex: 'total',
                  width: 200,
                  sorter: (a: any, b: any) => a.total - b.total,
                },
              ]}
              dataSource={data}
              size="small"
            />
          </Modal>
        </>
      }
    </>
  );
};

const Teams: React.FC<{ admins: TAdmin[]; loading: boolean }> = observer(
  ({ admins, loading }) => {
    const operation = useMemo(() => {
      return admins.filter((a: TAdmin) => a.department == 'Operation');
    }, [admins]);

    const customerSuccess = useMemo(() => {
      return admins.filter((a: TAdmin) => a.department == 'Customer Success');
    }, [admins]);

    return (
      <>
        {loading ? (
          <div className="flex h100 justify-center align-center">
            {' '}
            <Spin />
          </div>
        ) : (
          <>
            <Space align="start">
              <ColumnList items={operation} title={'Operation'} />
              <ColumnList items={customerSuccess} title={'Customer Success'} />
            </Space>
            <DutyLog />
          </>
        )}
      </>
    );
  },
);

export default Teams;
