import { CACHE_CONTAINER_COLOR_TAG_KEY } from '@/stores/cache';
import { useApp } from '@/utils/useapp';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { TagFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { ContainerColorTag } from '@/components/ColorTagSelect';
import { TContainer } from '@/types';

const ColorTags: React.FC<{
  tags: number[];
  containerId: number;
  onApply: (values: TContainer) => void;
}> = observer(({ tags, containerId, onApply }) => {
  const app = useApp();

  const colorTags = useMemo(
    () => app.store.cache.get(CACHE_CONTAINER_COLOR_TAG_KEY) || [],
    [app.store.cache.data],
  );

  return (
    <ContainerColorTag
      selected={tags}
      containerId={containerId}
      onApply={onApply}
    >
      <div
        style={{
          cursor: 'pointer',
        }}
      >
        {tags.length == 0 && (
          <Tooltip title={'Add Tags'}>
            <TagFilled style={{ color: '#E6E6E6' || '', marginLeft: '1px' }} />
          </Tooltip>
        )}
        {tags.map((tag) => {
          const _tag = colorTags.find((t) => t.id == tag);
          if (!_tag) {
            return <></>;
          }
          return (
            <Tooltip key={tag} title={_tag?.label || ''}>
              <TagFilled
                style={{ color: _tag?.color || '', marginLeft: '1px' }}
              />
            </Tooltip>
          );
        })}
      </div>
    </ContainerColorTag>
  );
});

export default ColorTags;
