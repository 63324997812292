import React, { useMemo } from 'react';

import { Popover, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { get } from 'lodash';

export const Contacts: React.FC<{
  vendor: any;
}> = ({ vendor }) => {
  const firstContactText = useMemo(() => {
    if (vendor?.contacts?.length <= 0) {
      return '';
    }

    const { name, phone, email } = vendor.contacts[0];

    let text = '';

    name && (text += name);

    name && (phone || email) && (text += ', ');

    phone && (text += phone);

    name && phone && email && (text += ', ');

    email && (text += email);

    return text;
  }, [vendor]);

  const columns: ColumnsType<any> = [
    {
      title: 'Name',
      key: 'name',
      width: 80,
      render: (text: any, record: any) => get(record, 'name'),
    },
    {
      title: 'Type',
      key: 'type',
      width: 80,
      render: (text: any, record: any) => get(record, 'type', []).join(', '),
    },
    {
      title: 'Role',
      key: 'role',
      width: 80,
      render: (text: any, record: any) => get(record, 'role'),
    },
    {
      title: 'Phone',
      key: 'phone',
      width: 80,
      render: (text: any, record: any) => get(record, 'phone'),
    },
    {
      title: 'email',
      key: 'email',
      width: 80,
      render: (text: any, record: any) => get(record, 'email'),
    },
  ];

  return (
    <>
      {vendor?.contacts?.length > 0 && (
        <Popover
          trigger="hover"
          placement="right"
          content={
            <>
              <Table
                columns={columns}
                dataSource={vendor?.contacts}
                pagination={false}
                scroll={{ x: '100%' }}
              />
            </>
          }>
          {firstContactText}
        </Popover>
      )}
    </>
  );
};
