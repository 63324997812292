import { AntDRangePicker } from '@/components/AntDRangePicker';
import Map from '@/components/Map';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { useApp } from '@/utils/useapp';
import { Button, Col, Form, Row, Table } from 'antd';
import moment from 'moment-timezone';
import { useCallback, useEffect, useMemo, useState } from 'react';

const Cyber = () => {
  const app = useApp();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [ipLocation, setIpLocation] = useState<any[]>([]);
  const [filter] = Form.useForm();

  const getCyberRisk = useCallback(async () => {
    try {
      setLoading(true);
      const res = await app.service.get('risks/cyber', {
        params: {
          ...filter.getFieldsValue(),
        },
      });
      setData(res.data);
    } catch (e: any) {
      console.log(e.error || 'network error');
    }
    setLoading(false);
  }, [filter]);

  const getIpLocation = async () => {
    try {
      setLoading(true);
      const res = await app.service.post('risks/ipToCoords', {
        data: {
          query: [
            ...data
              .map((item) => {
                return {
                  query: item.ip,
                };
              })
              .filter((item) => item.query),
          ],
        },
      });

      setIpLocation(
        res.data
          .filter((item: any) => item.status !== 'fail')
          .map((item: any) => {
            return {
              ip: item.query,
              location: `${item.city}, ${item.region}, ${item.country}`,
              coord: { lat: item.lat, lon: item.lon },
            };
          }),
      );
    } catch (e: any) {
      console.log(e.error || 'network error');
    }
  };

  const columns = useMemo(() => {
    return [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render(text: string, record: any) {
          return record.authlogable?.name || '';
        },
      },
      {
        title: 'Last Time Login IP',
        dataIndex: 'ip',
        key: 'ip',
      },
      {
        title: 'IP Location',
        dataIndex: 'ip_location',
        key: 'ip_location',
        render: (text: string, record: any) => (
          <div>
            {ipLocation.map((item) => {
              return item.ip === record.ip ? item.location : '';
            })}
          </div>
        ),
      },
      {
        title: 'Last 30 Day IP Changed',
        dataIndex: 'count',
        key: 'count',
      },
      {
        title: '2FA Status',
        dataIndex: '2fa',
        key: '2fa',
        render(text: string, record: any) {
          return record.authlogable?.two_fa_active ? 'Active' : 'Inactive';
        },
      },
    ];
  }, [data, ipLocation]);

  useEffect(() => {
    getCyberRisk();
  }, []);

  useEffect(() => {
    if (data.length) {
      getIpLocation();
    }
  }, [data]);

  return (
    <div>
      <h1>Login Security</h1>

      <Form
        form={filter}
        layout="vertical"
        initialValues={{
          start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
          end_date: moment().format('YYYY-MM-DD'),
        }}>
        <Row gutter={16}>
          <Col span={9}>
            <Form.Item name="start_date" hidden />
            <Form.Item name="end_date" hidden />
            <Form.Item label="Range" shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => {
                const startDate = getFieldValue('start_date');
                const endDate = getFieldValue('end_date');
                return (
                  <AntDRangePicker
                    style={{ width: '100%' }}
                    value={
                      startDate && endDate
                        ? [moment(startDate), moment(endDate)]
                        : undefined
                    }
                    onChange={(value) =>
                      setFieldsValue({
                        start_date: value
                          ? value[0]?.format('YYYY-MM-DD')
                          : null,
                        end_date: value ? value[1]?.format('YYYY-MM-DD') : null,
                      })
                    }
                  />
                );
              }}
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item label="Admin ID" name="admin_id">
              <UserSyncSelect type="admin" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label=" ">
              <Button type="primary" onClick={() => getCyberRisk()}>
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Table
        size="small"
        dataSource={data}
        loading={loading}
        // pagination={{ pageSize: 5 }}
        rowKey="id"
        columns={columns}></Table>
      <Map
        height="400px"
        source={data
          .filter((item) => item.ip)
          .map((item) => {
            const location = ipLocation.find((ip) => ip.ip === item.ip);
            return {
              latitude: location?.coord.lat || 0,
              longitude: location?.coord.lon || 0,
              content: `${item.authlogable?.name} - ${item.ip} \n
          ${location?.location}
          `,
              title: item.authlogable?.name,
            };
          })}
      />
    </div>
  );
};

export default Cyber;
