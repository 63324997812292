import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { get, truncate } from 'lodash';
import { Tooltip, Space, message, Modal, Button, Form, Input } from 'antd';
import { DrawerForm as WarehouseForm } from '@/pages/entity/warehouses/components/DrawerForm';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { useApp } from '@/utils/useapp';
import { useForm } from 'antd/lib/form/Form';
import { TUser } from '@/types';

export const UserMemoModal: React.FC<{
  open: boolean;
  user: TUser;
  onSaved: () => void;
  onClose: () => void;
}> = ({ open, user, onSaved, onClose }) => {
  const app = useApp();
  const [loading, setLoading] = React.useState(false);

  const [form] = useForm();

  const handleSave = async () => {
    const values = await form.validateFields();

    setLoading(true);

    try {
      await app.service.patch(`users/${user.id}/updateMemo`, { data: values });
      message.success('Saved');
      onSaved();
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };
  return (
    <Modal
      cancelText="Close"
      footer={
        <>
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
        </>
      }
      title="Memo"
      open={open}
      onCancel={handleCancel}>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          memo: user.memo,
        }}>
        <Form.Item name="memo" label="Memo">
          <Input.TextArea
            value={form.getFieldValue('memo')}
            rows={4}
            placeholder="Enter memo"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
