import React from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Divider,
  Drawer,
  message,
  Space,
  TablePaginationConfig,
} from 'antd';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { DrawerForm } from './components/DawerForm';
import { useForm } from 'antd/lib/form/Form';
import { TLocalStatement, TCollection } from '@/types';
import usePagination from '@/components/usePagination';
import AutoResizeTable from '@/components/AutoResizeTable';
import Status from './components/Status';
import { Filter } from './components/Filter';

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [data, setData] = React.useState<TCollection<TLocalStatement>>();
  const [model, setModel] = React.useState<TLocalStatement>();
  const [filter] = useForm();

  const pagination = usePagination(data);

  const fetchData = React.useCallback(
    async (pagination?: TablePaginationConfig) => {
      setLoading(true);

      try {
        const result = await app.service.get('quotes', {
          params: {
            ...filter.getFieldsValue(),
            page: pagination?.current || 1,
            per_page: pagination?.pageSize || 20,
          },
        });

        setData(result);
      } catch (e: any) {
        message.error(e.data?.message || e.data?.error);
      }

      setLoading(false);
    },
    [],
  );

  const addNew = () => {
    setModel(undefined);
    setVisible(true);
  };

  const handleEdit = (m: any) => {
    setModel(m);
    setVisible(true);
  };

  const downloadExcel = async (id: number) => {
    try {
      const file = await app.service.get(`quotes/${id}/excel`, {
        responseType: 'blob',
      });
      const filename = `Quote_${id}.xlsx`;
      saveAs(file, filename);
    } catch (e: any) {
      message.error('Error');
    }
  };

  const columns: ColumnsType<any> = React.useMemo(
    () => [
      {
        title: 'Bulk Quote ID',
        dataIndex: 'id',
        key: 'id',
        width: 80,
        render: (text, record) => <>BQ{record.id}</>,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text, record) => (
          <>
            <Status status={record.status} expireAt={record.expiration_date} />
          </>
        ),
        width: 120,
      },
      {
        title: 'Customer',
        dataIndex: 'customer',
        key: 'customer',
        render: (text, record) => {
          return <>{record.customer.name}</>;
        },
        width: 120,
      },
      {
        title: 'Quote Date',
        dataIndex: 'quote_date',
        width: 120,
      },
      {
        title: 'Expiration Date',
        dataIndex: 'expiration_date',
        width: 120,
      },
      {
        title: 'Route Count',
        dataIndex: 'route_count',
        render: (text, record) => {
          return <>{record.quote_routes?.length || 0}</>;
        },
        width: 120,
      },
      {
        align: 'center',
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 150,
        render: (text, record) => (
          <Space>
            <a onClick={() => handleEdit(record)}>Edit</a>
            <Divider type="vertical" />
            <a onClick={() => downloadExcel(record.id)}>Download Excel</a>
          </Space>
        ),
      },
    ],
    [],
  );

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleFilterChange = (values: any) => {
    filter.setFieldsValue(values);
  };

  const handleSearch = () => {
    fetchData();
  };

  return (
    <div className={styles.main}>
      <Header
        title="Bulk Quotes"
        rightElement={
          <div>
            <Button type="primary" onClick={addNew}>
              Create New Bulk Quote
            </Button>
          </div>
        }></Header>
      <Filter
        loading={loading}
        filter={filter}
        onSearch={handleSearch}
        onChange={handleFilterChange}
      />
      <AutoResizeTable
        loading={loading}
        pagination={pagination}
        size="small"
        rowKey="id"
        columns={columns}
        onChange={fetchData}
        dataSource={data?.data || []}
        sticky
        scroll={{
          x: 'auto',
        }}
      />

      <Drawer
        title=""
        placement="right"
        width="90%"
        onClose={() => setVisible(false)}
        destroyOnClose={true}
        open={visible}>
        <DrawerForm
          model={model}
          onCreated={() => {
            fetchData();
            message.success('saved');
            // setVisible(false);
          }}
          onClose={() => setVisible(false)}
        />
      </Drawer>
    </div>
  );
};

export default Index;
