import { Button, Modal, Space, message, Form, Input } from 'antd';
import React, { useMemo } from 'react';

import { useApp } from '@/utils/useapp';

import { get, has } from 'lodash';
import { useForm } from 'antd/lib/form/Form';
interface IAfterUploadedModal {
  open: boolean;
  fileId: any;
  handleUpdate: (fileId: any, values: any) => void;
  onClose: () => void;
}

export const CommentModal: React.FC<IAfterUploadedModal> = ({
  open,
  fileId,
  handleUpdate,
  onClose,
}) => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [form] = useForm();

  const handleOnClose = () => {
    setLoading(false);
    form.resetFields();
    onClose();
  };

  const handleSave = async () => {
    const data = await form.validateFields();
    await handleUpdate(fileId, data);
    onClose();
  };

  return (
    <Modal
      title=""
      destroyOnClose
      cancelText="Close"
      footer={
        <Space>
          <Button type="primary" disabled={loading} onClick={handleSave}>
            Save
          </Button>
        </Space>
      }
      open={open}
      onCancel={handleOnClose}>
      <Form form={form} layout="vertical">
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="comment" label="Comment">
          <Input.TextArea rows={6} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
