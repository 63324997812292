import { useState } from 'react';
import React from 'react';
import { CarOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/lib/table';
import { useApp } from '@/utils/useapp';
import { TruckingModal } from './TruckingModal';
import CheckActions, {
  CheckActionsProps,
} from '@/pages/dispatchs/components/CheckActions';
import {
  TActionTasksProps,
  ActionTasks,
} from '@/pages/dispatchs/components/ActionTasks';
import { get } from 'lodash';

export const Trucking: React.FC<{
  showContainers?: boolean;
  order: any;
  container?: any;
  selectedContainers?: any[];

  //handleContainerChange: (id: number, value: any) => void;
  //handleDoChange: (id: number, value: any) => void;
  refreshOrder?: (id: number) => void;
  refreshContainer?: (id: number) => void;
  checkActionTipsProps?: CheckActionsProps;
  showCheckActionTips?: boolean;
  TActionTasksProps?: TActionTasksProps;
  showActionTasks?: boolean;
}> = ({
  showContainers = true,
  selectedContainers,
  order,
  container,
  refreshOrder,
  refreshContainer,
  checkActionTipsProps,
  showCheckActionTips = false,
  TActionTasksProps,
  showActionTasks,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      {get(container, 'dispatch_action_v2') ? (
        showActionTasks ? (
          <ActionTasks {...TActionTasksProps}>
            <div onClick={showModal}>
              <CarOutlined />
            </div>
          </ActionTasks>
        ) : (
          <div onClick={showModal}>
            <CarOutlined />
          </div>
        )
      ) : showCheckActionTips ? (
        <CheckActions {...checkActionTipsProps}>
          <div onClick={showModal}>
            <CarOutlined />
          </div>
        </CheckActions>
      ) : (
        <div onClick={showModal}>
          <CarOutlined />
        </div>
      )}

      {isModalVisible && (
        <TruckingModal
          isModalVisible={isModalVisible}
          showContainers={showContainers}
          order={order}
          container={container}
          selectedContainers={selectedContainers}
          onCancel={handleCancel}
          refreshOrder={refreshOrder}
          refreshContainer={refreshContainer}
        />
      )}
    </>
  );
};
