import React, { useEffect, useState, useMemo } from 'react';
import { PriceIcon } from '@/components/icons';
import { get } from 'lodash';
import { SnashotRateModal } from './SnashotRateModal';

export const SnapshotRate: React.FC<{
  order: any;
}> = ({ order }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="text-gray" onClick={showModal}>
        <PriceIcon />
      </div>

      {isModalVisible && (
        <SnashotRateModal
          snapshotableId={get(order, 'id')}
          snapshotableType="Order"
          rateUid={get(order, 'sell_rate.rate_uid')}
          hasBanModifyFields={true}
          open={isModalVisible}
          onClose={handleCancel}
        />
      )}
    </>
  );
};
