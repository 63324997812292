import React from 'react';
import { EnterOutlined } from '@ant-design/icons';
import { Typography } from 'antd';

export const TerminalRedirectLink: React.FC<{
  id: number;
  label?: string;
  style?: any;
}> = ({ id, label, style }) => {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={`${BASE_URL}/terminals/${id}/redirect`}>
      {label ? (
        <Typography.Text style={style}>{label}</Typography.Text>
      ) : (
        <EnterOutlined />
      )}
    </a>
  );
};
