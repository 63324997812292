import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Col,
  Input,
  message,
  Modal,
  Popconfirm,
  Popover,
  Row,
  Space,
  Switch,
  Tag,
  Tooltip,
} from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { get, set } from 'lodash';
import TextArea from 'antd/lib/input/TextArea';
import { RatingTag } from '@/components/RatingTag';
import AutoResizeTable from '@/components/AutoResizeTable';
import { LogActivities } from '@/components/LogActivities';
import { useVendorRateDrawer } from '../vendors/components/useVendorRateDrawer';
import { useLocation, useParams } from 'umi';
import { useContractRatesDrawer } from '../vendors/components/contractRates';
import usePagination from '@/components/usePagination';
import { TCollection } from '@/types';
import { VendorCell } from '@/components/VendorCell';
import ModelTabs from './components/ModelTabs';
import { Filter } from './components/Filter/index';
import { ManageColumns } from '@/pages/dispatchs/components/ManageColumns';
import { groupBy, mapValues, uniq } from 'lodash';
import { DISPUTE_TYPE_VENDOR, DisputeMemo } from '@/components/DisputeMemo';
import {
  EditOutlined,
  ExclamationCircleOutlined,
  FileTextOutlined,
  DeleteOutlined,
  AlignLeftOutlined,
} from '@ant-design/icons';
import VendorRating from '@/pages/dispatchs/components/VendorRating';
import { FINANCIAL_STATUS_MAP } from '../vendors/components/data';
import { VendorDrawer } from '../vendors/components/VendorDrawer';
import { Header } from '../vendors/components/Header';
import { Contacts } from '../vendors/components/Contacts';

export const convertFilterValuesToQueryParams = (values: any[]) => {
  if (values?.find((v) => v.meta)) {
    values = [
      ...values,
      ...(Object.entries(values?.find((v) => v.meta)?.meta)?.map((v) => ({
        attribute: v[0],
        value: v[1],
      })) || []),
    ];
  }
  return mapValues(groupBy(values, 'attribute'), (items) => {
    return items.length > 1 ? items.map((i) => i.value) : items[0].value;
  });
};

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  // const [filter, setFilter] = useState<any>({});
  const [data, setData] = useState<TCollection<any>>();
  const [tab, setTab] = useState<any>({});
  const [blacklistedVender, setBlacklistedVender] = useState<any>(null);
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [openId, setOpenId] = React.useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  // const [filterValues, setFilterValues] = React.useState<any[] | null>(null);

  // const [defaultColumns, setDefaultColumns] = useState<any>([]);

  const pagination = usePagination(data);
  const location = useLocation();

  const [vendor, setVendor] = useState<any>(null);
  const { VendorRateFormDrawer, openVendorRateFormDrawer } =
    useVendorRateDrawer();

  const { ContractRatesTableDrawer, openContractRatesTableDrawer } =
    useContractRatesDrawer();
  const params: any = useParams();

  const showDrawer = () => {
    setVisible(true);
  };

  const handleNewVendor = () => {
    setVendor(null);
    showDrawer();
  };

  // const handleFilterChange = (values: any) => {
  //   setFilter(values);
  // };

  const handleReplace = async (vendor: any) => {
    if (!data) {
      return;
    }
    const collection = data.data.map((item) => {
      if (item.id === vendor.id) {
        return vendor;
      }
      return item;
    });
    setData({ ...data, data: collection });
  };

  const handlePatchUpdate = useCallback(
    async (id: number, value: any) => {
      setLoading(true);
      try {
        const resp = await app.service.patch(`vendors/${id}`, { data: value });
        const vendor = resp.data;
        handleReplace(vendor);
      } catch (error) {
        message.error('Error updating vendor');
      } finally {
        setLoading(false);
      }
    },
    [data],
  );

  const handelCloseDrawer = () => {
    setVisible(false);
    setVendor(null);
  };

  const handleSetEnabled = useCallback(
    async (id: string, enabled: boolean) => {
      setLoading(true);
      try {
        await app.service.put(`vendors/${id}/setEnabled`, {
          data: { enabled },
        });
        const vendor = data?.data.find((r) => r.id == id);
        vendor.enabled = enabled;
        handleReplace(vendor);
      } catch (error) {
        message.error('Error updating vendor');
      } finally {
        setLoading(false);
      }
    },
    [data],
  );

  const handleSetBlacklisted = useCallback(
    async (id: string, blacklisted: boolean) => {
      if (!blacklisted) {
        setLoading(true);
        try {
          const resp = await app.service.put(`vendors/${id}/setBlacklisted`, {
            data: { blacklisted },
          });
          const vendor = resp.data;
          handleReplace(vendor);
        } catch (error) {
          message.error('Error updating vendor');
        }
      } else {
        setBlacklistedVender({
          id,
          reason: '',
        });
      }
    },
    [data],
  );

  const handleSetPromo = useCallback(
    async (id: string, promo: boolean) => {
      setLoading(true);
      try {
        const resp = await app.service.put(`vendors/${id}/setPromo`, {
          data: { promo },
        });
        const vendor = resp.data;
        handleReplace(vendor);
      } catch (error) {
        message.error('Error updating vendor');
      } finally {
        setLoading(false);
      }
    },
    [data],
  );

  const handleConfirmSetBlacklisted = useCallback(async () => {
    setLoading(true);
    try {
      const resp = await app.service.put(
        `vendors/${blacklistedVender.id}/setBlacklisted`,
        { data: { blacklisted: true, reason: blacklistedVender.reason } },
      );
      const vendor = resp.data;
      handleReplace(vendor);
      setBlacklistedVender(null);
    } catch (error) {
      message.error('Error updating vendor');
    } finally {
      setLoading(false);
    }
  }, [blacklistedVender]);

  const handleSetFavorited = useCallback(
    async (id: string, favorited: boolean) => {
      setLoading(true);
      try {
        const resp = await app.service.put(`vendors/${id}/setFavorited`, {
          data: { favorited },
        });
        const vendor = resp.data;
        handleReplace(vendor);
      } catch (error) {
        message.error('Error updating vendor');
      } finally {
        setLoading(false);
      }
    },
    [data],
  );

  const fetchData = async (pagination?: TablePaginationConfig, _: any = {}) => {
    const _filters = tab?.filters || [];
    const params = convertFilterValuesToQueryParams(_filters);

    if (searchQuery) {
      params.search = searchQuery;
    }

    setLoading(true);
    try {
      const resp = await app.service.get('vendors', {
        params: {
          ...params,
          ..._,
          page: pagination?.current || 1,
          per_page: pagination?.pageSize || 20,
        },
      });
      setData(resp);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const handleRemove = (id: number) => {
    app.service
      .delete(`vendors/${id}`)
      .then(() => {
        fetchData();
      })
      .catch((err) => {
        message.error(err.data?.message || err.data?.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEdit = (id: number) => {
    setVisible(true);
    setVendor(id);
  };

  const handleSearch = (value: string) => {
    fetchData(pagination, { search: value });
  };

  const handleSave = () => {
    fetchData();
    setVendor(null);
  };

  useEffect(() => {
    if (params.id) {
      openVendorRateFormDrawer(params.id);
    }
  }, []);

  // const _fetchData = useCallback(() => {
  //   if (tab && !location.search) {
  //     fetchData();
  //   } else if (location.search) {
  //     const queryString = location.search;
  //     if (queryString) {
  //       const params = new URLSearchParams(queryString);
  //       const vendorId: any = params.get('vendorId') || 0;
  //       const open: any = params.get('open') || 0;
  //       if (open) {
  //         setOpenId(vendorId);
  //       }
  //       setTab({ ...tab, filters: [{ attribute: 'id', value: vendorId }] });
  //     }
  //   }
  // }, [tab, location.search]);

  useEffect(() => {
    if (tab && !location.search) {
      fetchData();
    }
  }, [tab]);

  useEffect(() => {
    if (location.search) {
      const queryString = location.search;
      if (queryString) {
        const params = new URLSearchParams(queryString);
        const vendorId: any = params.get('vendorId') || 0;
        const open: any = params.get('open') || 0;
        if (open) {
          setOpenId(vendorId);
        }
        setTab(null);
        fetchData(pagination, { id: vendorId });
        // fetchData();
        // remove query params
        const url = location.pathname;
        window.history.replaceState({}, '', url);
      }
    }
  }, [location.search]);

  const defaultColumns: ColumnsType<any> = [
    {
      title: 'ID',
      key: 'id',
      width: 80,
      render: (text, record) => <>{record.id}</>,
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor',
      key: 'vendor',
      width: 300,
      render: (text, record) => (
        <VendorCell
          vendor={record}
          showVendorDetail={handleEdit}
          onSaved={fetchData}
        />
      ),
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
      width: 120,
      render: (text, record) => {
        return (
          <Popover
            trigger="click"
            destroyTooltipOnHide
            content={
              <VendorRating
                vendor_id={record.id || 0}
                score={record.score || 'N/A'}
                // container={container}
                // refreshContainer={() => refreshContainer(container.id)}
              />
            }>
            <RatingTag rating={record?.score} />
          </Popover>
        );
      },
    },
    {
      title: 'Info',
      dataIndex: 'info',
      key: 'info',
      width: 230,
      render: (text, record) => (
        <div>
          {record?.phone ? <div>Phone - {record.phone}</div> : ''}
          {record?.contact ? <div>Contact - {record.contact}</div> : ''}
          {record?.email ? <div>Email - {record.email}</div> : ''}
        </div>
      ),
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      width: 160,
      render: (text, record) => {
        return <>{get(record, 'city.full_name', '')}</>;
      },
    },
    {
      title: 'FBA Delivery',
      dataIndex: 'fba_delivery',
      key: 'fba_delivery',
      width: 100,
      render: (text, record) => {
        return (
          <>
            {record.fba_delivery ? (
              <Tag color="green">Yes</Tag>
            ) : (
              <Tag color="red">No</Tag>
            )}
          </>
        );
      },
    },
    {
      title: 'Contacts',
      dataIndex: 'contacts',
      key: 'contacts',
      width: 200,
      render: (text, record) => <Contacts vendor={record} />,
    },
    // {
    //   title: 'Phone',
    //   dataIndex: 'phone',
    //   key: 'phone',
    //   width: 160,
    // },
    // {
    //   title: 'Contact',
    //   dataIndex: 'contact',
    //   key: 'contact',
    //   width: 100,
    // },
    // {
    //   title: 'Email',
    //   dataIndex: 'email',
    //   key: 'email',
    //   width: 100,
    // },
    {
      title: 'Terms',
      dataIndex: 'terms',
      key: 'terms',
      width: 60,
      render: (text, record) => {
        return <>{record.terms || 0}</>;
      },
    },
    {
      title: 'Financial Status',
      dataIndex: 'financial_status',
      key: 'financial_status',
      width: 100,
      render: (text, record) => {
        return <>{FINANCIAL_STATUS_MAP[record.financial_status] || ''}</>;
      },
    },
    {
      title: 'IR',
      dataIndex: 'intermodal_region_id',
      key: 'intermodal_region_id',
      width: 150,
      render: (text, record) => {
        return <>{record.intermodal_region?.name || ''}</>;
      },
    },
    {
      title: 'MC#',
      dataIndex: 'trucker_mc',
      key: 'trucker_mc',
      width: 100,
    },
    {
      title: 'DOT#',
      dataIndex: 'us_dot',
      key: 'us_dot',
      width: 100,
    },
    {
      title: 'SCAC#',
      dataIndex: 'scac_code',
      key: 'scac_code',
      width: 100,
    },
    {
      title: 'Own Chassis',
      dataIndex: 'has_own_chassis',
      key: 'has_own_chassis',
      width: 100,
      render: (text, record) => {
        return (
          <>
            <Switch
              checked={record.has_own_chassis}
              onClick={(v) =>
                handlePatchUpdate(record.id, { has_own_chassis: v })
              }
            />
          </>
        );
      },
    },
    {
      title: 'Enabled',
      dataIndex: 'enabled',
      key: 'enabled',
      width: 100,
      render: (text, record) => {
        return (
          <>
            <Switch
              checked={record.enabled}
              onClick={(v) => handleSetEnabled(record.id, v)}
            />
          </>
        );
      },
    },
    {
      title: 'Favorited',
      dataIndex: 'favorited',
      key: 'favorited',
      width: 100,
      render: (text, record) => {
        return (
          <>
            <Switch
              checked={record.favorited}
              onClick={(v) => handleSetFavorited(record.id, v)}
            />
          </>
        );
      },
    },
    {
      title: 'Blacklisted',
      dataIndex: 'blacklisted',
      key: 'blacklisted',
      width: 100,
      render: (text, record) => {
        return (
          <>
            <Switch
              checked={record.blacklisted}
              onClick={(v) => handleSetBlacklisted(record.id, v)}
            />
          </>
        );
      },
    },
    {
      title: 'Promo',
      dataIndex: 'promo',
      key: 'promo',
      width: 100,
      render: (text, record) => {
        return (
          <>
            <Switch
              checked={record.promo}
              onClick={(v) => handleSetPromo(record.id, v)}
            />
          </>
        );
      },
    },
    {
      title: 'Overweight Ability',
      dataIndex: 'overweight_ability',
      key: 'overweight_ability',
      width: 100,
      render: (text, record) => {
        return (
          <>
            <Switch
              checked={record.promo}
              onClick={(v) =>
                handlePatchUpdate(record.id, { overweight_ability: v })
              }
            />
          </>
        );
      },
    },
    // residential_ability
    {
      title: 'Residential Ability',
      dataIndex: 'residential_ability',
      key: 'residential_ability',
      width: 100,
      render: (text, record) => {
        return (
          <>
            <Switch
              checked={record.promo}
              onClick={(v) =>
                handlePatchUpdate(record.id, { residential_ability: v })
              }
            />
          </>
        );
      },
    },
    // reefer_ability
    {
      title: 'Reefer Ability',
      dataIndex: 'reefer_ability',
      key: 'reefer_ability',
      width: 100,
      render: (text, record) => {
        return (
          <>
            <Switch
              checked={record.promo}
              onClick={(v) =>
                handlePatchUpdate(record.id, { reefer_ability: v })
              }
            />
          </>
        );
      },
    },
    // out_of_gauge_ability
    {
      title: 'Out of Gauge Ability',
      dataIndex: 'out_of_gauge_ability',
      key: 'out_of_gauge_ability',
      width: 100,
      render: (text, record) => {
        return (
          <>
            <Switch
              checked={record.promo}
              onClick={(v) =>
                handlePatchUpdate(record.id, { out_of_gauge_ability: v })
              }
            />
          </>
        );
      },
    },
    // open_top_ability
    {
      title: 'Open Top Ability',
      dataIndex: 'open_top_ability',
      key: 'open_top_ability',
      width: 100,
      render: (text, record) => {
        return (
          <>
            <Switch
              checked={record.promo}
              onClick={(v) =>
                handlePatchUpdate(record.id, { open_top_ability: v })
              }
            />
          </>
        );
      },
    },
    // flat_rack_ability
    {
      title: 'Flat Rack Ability',
      dataIndex: 'flat_rack_ability',
      key: 'flat_rack_ability',
      width: 100,
      render: (text, record) => {
        return (
          <>
            <Switch
              checked={record.promo}
              onClick={(v) =>
                handlePatchUpdate(record.id, { flat_rack_ability: v })
              }
            />
          </>
        );
      },
    },
    // menards_approval_ability
    {
      title: 'Menards Approval Ability',
      dataIndex: 'menards_approval_ability',
      key: 'menards_approval_ability',
      width: 100,
      render: (text, record) => {
        return (
          <>
            <Switch
              checked={record.promo}
              onClick={(v) =>
                handlePatchUpdate(record.id, { menards_approval_ability: v })
              }
            />
          </>
        );
      },
    },
    // transloading_ability
    {
      title: 'Transloading Ability',
      dataIndex: 'transloading_ability',
      key: 'transloading_ability',
      width: 100,
      render: (text, record) => {
        return (
          <>
            <Switch
              checked={record.promo}
              onClick={(v) =>
                handlePatchUpdate(record.id, { transloading_ability: v })
              }
            />
          </>
        );
      },
    },
    // boned_trucker_ability
    {
      title: 'Bonded Trucker Ability',
      dataIndex: 'bonded_trucker_ability',
      key: 'bonded_trucker_ability',
      width: 100,
      render: (text, record) => {
        return (
          <>
            <Switch
              checked={record.promo}
              onClick={(v) =>
                handlePatchUpdate(record.id, { bonded_trucker_ability: v })
              }
            />
          </>
        );
      },
    },
    // gate_door_with_locked
    {
      title: 'Gate Door with Locked',
      dataIndex: 'gate_door_with_locked',
      key: 'gate_door_with_locked',
      width: 100,
      render: (text, record) => {
        return (
          <>
            <Switch
              checked={record.promo}
              onClick={(v) =>
                handlePatchUpdate(record.id, { gate_door_with_locked: v })
              }
            />
          </>
        );
      },
    },
    // surveillance_cameras
    {
      title: 'Surveillance Cameras',
      dataIndex: 'surveillance_cameras',
      key: 'surveillance_cameras',
      width: 100,
      render: (text, record) => {
        return (
          <>
            <Switch
              checked={record.promo}
              onClick={(v) =>
                handlePatchUpdate(record.id, { surveillance_cameras: v })
              }
            />
          </>
        );
      },
    },
    // locked_with_pinlock
    {
      title: 'Locked with Pinlock',
      dataIndex: 'locked_with_pinlock',
      key: 'locked_with_pinlock',
      width: 100,
      render: (text, record) => {
        return (
          <>
            <Switch
              checked={record.promo}
              onClick={(v) =>
                handlePatchUpdate(record.id, { locked_with_pinlock: v })
              }
            />
          </>
        );
      },
    },
    // live_security_guards
    {
      title: 'Live Security Guards',
      dataIndex: 'live_security_guards',
      key: 'live_security_guards',
      width: 100,
      render: (text, record) => {
        return (
          <>
            <Switch
              checked={record.promo}
              onClick={(v) =>
                handlePatchUpdate(record.id, { live_security_guards: v })
              }
            />
          </>
        );
      },
    },
    // security_alarm_systems
    {
      title: 'Security Alarm Systems',
      dataIndex: 'security_alarm_systems',
      key: 'security_alarm_systems',
      width: 100,
      render: (text, record) => {
        return (
          <>
            <Switch
              checked={record.promo}
              onClick={(v) =>
                handlePatchUpdate(record.id, { security_alarm_systems: v })
              }
            />
          </>
        );
      },
    },
    // has_container_yard
    {
      title: 'Has Container Yard',
      dataIndex: 'has_container_yard',
      key: 'has_container_yard',
      width: 100,
      render: (text, record) => {
        return (
          <>
            <Switch
              checked={record.promo}
              onClick={(v) =>
                handlePatchUpdate(record.id, { has_container_yard: v })
              }
            />
          </>
        );
      },
    },
    {
      title: 'Update PC Date',
      dataIndex: 'update_possible_charges_at',
      key: 'update_possible_charges_at',
      width: 100,
    },
    {
      title: 'CreatedAt',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 100,
    },
    {
      align: 'center',
      title: 'Actions',
      key: 'action',
      fixed: 'right',
      width: 220,
      render: (text, record) => (
        <Space>
          <div onClick={() => handleEdit(record.id)}>
            <Tooltip title="Edit Vendor">
              <Button type="link" size="small">
                {' '}
                <EditOutlined />
              </Button>
            </Tooltip>
          </div>
          <div onClick={() => openContractRatesTableDrawer(record)}>
            <Tooltip title="Contract Rates">
              <Button type="link" size="small">
                <FileTextOutlined />
              </Button>
            </Tooltip>
          </div>

          <LogActivities
            id={record.id}
            type={'vendors'}
            icon={
              <Tooltip title="Logs">
                <Button type="link" size="small">
                  <ExclamationCircleOutlined />
                </Button>
              </Tooltip>
            }
          />

          {/* <Divider type="vertical" /> */}
          <Tooltip title="Delete">
            <Popconfirm
              title="Sure to delete?"
              okText="Confirm"
              cancelText="Cancel"
              onConfirm={() => handleRemove(record.id)}>
              <Button type="link" size="small">
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Tooltip>

          <DisputeMemo
            title="Memo"
            onSaved={() => fetchData()}
            model={record}
            icon={
              <Tooltip placement="left" title={record.internal_memo || ''}>
                {!record.internal_memo && (
                  <div className="ant-btn-sm text-gray">
                    <AlignLeftOutlined />
                  </div>
                )}
                {record.internal_memo && (
                  <div className="ant-btn-sm text-primary">
                    <AlignLeftOutlined />
                  </div>
                )}
              </Tooltip>
            }
            type={DISPUTE_TYPE_VENDOR}
            withStatus={false}
            open={openId == record.id}
          />
        </Space>
      ),
    },
  ];

  const columns: ColumnsType<any> = React.useMemo(() => {
    // const sort = filterValues?.find((f) => f.attribute === 'sort') || null;
    const _columns: ColumnsType<any> = defaultColumns?.map((c: any) => ({
      ...c,
      // sortOrder: sortOrder(sort, c.key),
    }));

    // const tab = tabs.find((t) => t.id === activeKey);
    const _defaultColumns = _columns?.map((c) => c.key);
    return _columns.filter((c) =>
      (tab?.columns || _defaultColumns)?.includes(c.key),
    );
  }, [tab, pagination, defaultColumns]);

  const checkedColumns = useMemo(() => {
    return tab?.columns || defaultColumns?.map((c: any) => c.key);
  }, [tab]);

  const tabRef = React.useRef<any>(null);

  const handleFilterChange = async (values: any) => {
    tabRef?.current?.updateTab({ ...tab, filters: values });
  };

  const handleRemoveFilter = React.useCallback(
    (index: number) => {
      const filters = tab.filters.filter((_: any, i: number) => i !== index);
      tabRef.current.updateTab({ ...tab, filters: filters });
    },
    [pagination],
  );

  return (
    <div className={styles.main}>
      <Header
        title={
          <Button type="primary" onClick={handleNewVendor}>
            New Vendor
          </Button>
        }
        rightElement={
          <Space>
            <Input.Search
              style={{ width: 400 }}
              placeholder="Search by Vendor Name, Trucker mc, Doc number."
              allowClear
              enterButton="Search"
              disabled={loading}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onSearch={(value) => handleSearch(value)}
            />
          </Space>
        }></Header>
      <ModelTabs
        ref={tabRef}
        activeTab={tab}
        onTabChange={(tab) => {
          setTab(tab);
        }}
        tabMode={2}
      />
      <Row>
        <Col span={16}>
          <Filter
            show={isShowFilter}
            values={tab?.filters || []}
            onChange={(v) => handleFilterChange(v)}
            removeAll={() => handleFilterChange([])}
            removeOne={(index: number) => handleRemoveFilter(index)}
            activeFilters={tab?.filters || []}
            hideFilter={() => setIsShowFilter(false)}
            loading={loading}
          />
        </Col>
        <Col
          span={8}
          style={{
            marginTop: isShowFilter ? '48px' : '0',
            marginBottom: isShowFilter ? '0' : '20px',
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
            }}>
            <Button
              onClick={() => {
                setIsShowFilter((isShowFilter) => !isShowFilter);
              }}>
              {isShowFilter ? 'Hide Filters' : 'Show Filters'}
            </Button>

            <div style={{ marginLeft: '10px' }}>
              <ManageColumns
                items={
                  defaultColumns.map((c: any) => {
                    return {
                      key: c.key,
                      name: c.title instanceof Function ? c.label : c.title,
                    };
                  }) || []
                }
                checkedColumns={checkedColumns}
                onChange={(list) => {
                  setTab({ ...tab, columns: list });
                  tabRef?.current?.updateTab({ ...tab, columns: list });
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
      {/* <Filter
        loading={loading}
        filter={filter}
        onSearch={handleSearch}
        onChange={handleFilterChange}>
        {' '}
      </Filter> */}
      <div>
        <AutoResizeTable
          loading={loading}
          pagination={pagination}
          size="small"
          rowKey="id"
          columns={columns}
          onChange={(pagination) => {
            fetchData(pagination);
          }}
          dataSource={data?.data}
          scroll={{ x: 1500 }}
          sticky
        />
      </div>
      <VendorDrawer
        id={vendor}
        onSaved={handleSave}
        onClose={handelCloseDrawer}
        visible={visible}
      />
      <Modal
        title="Reason"
        open={!!blacklistedVender}
        onOk={handleConfirmSetBlacklisted}
        okText="Comfirm"
        onCancel={() => {
          setBlacklistedVender(null);
        }}
        cancelText="Cancel">
        <TextArea
          rows={4}
          value={blacklistedVender?.reason || ''}
          onChange={(e) =>
            setBlacklistedVender({
              ...blacklistedVender,
              reason: e.target.value,
            })
          }
        />
      </Modal>
      <ContractRatesTableDrawer />
      <VendorRateFormDrawer />
    </div>
  );
};

export default Index;
