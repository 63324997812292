import { useApp } from '@/utils/useapp';
import { DatePicker, message, Tooltip, Space } from 'antd';
import React, { useState } from 'react';
import { get } from 'lodash';
import { BaseButtonProps } from 'antd/lib/button/button';
import moment from 'moment';
import { auth } from '@/stores';
import { CustomerConfirmModal } from './InvoiceDrawer/CustomerConfirmModal';
import { CloseCircleOutlined } from '@ant-design/icons';

type TProps = {
  confirmed_at: string;
  cancel_customer_confirmed_reason?: string;
  orderInvoiceId: number;
  onSaved: (orderInvoiceId: number) => void;
};

export const CustomerConfirmColumn: React.FC<TProps & BaseButtonProps> = ({
  confirmed_at,
  cancel_customer_confirmed_reason,
  orderInvoiceId,
  onSaved,
  ...props
}) => {
  const app = useApp();

  const [open, setOpen] = useState(false);
  const buttonText = confirmed_at
    ? 'Cancel Customer Confirm'
    : 'Customer Confirm';
  const buttonType = confirmed_at ? 'primary' : 'default';
  const tooltipTilteText = confirmed_at
    ? 'Cancel customer confirm the invoice'
    : cancel_customer_confirmed_reason
      ? cancel_customer_confirmed_reason
      : 'Customer confirm the invoice';

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const handleConfirm = async (dateString: string) => {
    if (!orderInvoiceId || !dateString) {
      return false;
    }

    try {
      await app.service.patch(`orderInvoices/${orderInvoiceId}/confirm`, {
        data: {
          confirmed_at: dateString,
        },
      });
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
    onSaved(orderInvoiceId);
  };

  return (
    <>
      <Space className="flex">
        <DatePicker
          size="small"
          allowClear={false}
          value={confirmed_at ? moment(confirmed_at) : null}
          onChange={(date, dateString) => handleConfirm(dateString)}
          disabled={!auth.hasRole(['accounting', 'controller'])}
        />
        {confirmed_at && (
          <Tooltip placement="right" title={tooltipTilteText}>
            <span className="text-gray cursor-pointer" onClick={handleOpen}>
              <CloseCircleOutlined />
            </span>
          </Tooltip>
        )}
      </Space>

      {open && (
        <CustomerConfirmModal
          open={open}
          text={'Cancel customer confirm the invoice'}
          confirmed_at={confirmed_at}
          orderInvoiceId={orderInvoiceId}
          cancel_customer_confirmed_reason={cancel_customer_confirmed_reason}
          onClose={handleClose}
          onOpen={handleOpen}
          onSaved={onSaved}
        />
      )}
    </>
  );
};
