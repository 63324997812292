import { Col, Form, Input, Row, Button } from 'antd';
import React, { useEffect } from 'react';
import { ContactList } from '@/components/ContactList';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

export const DrawerForm: React.FC<any> = ({
  form,
  isNew = false,
  updateData,
}) => {
  const handleChange = async (event: any) => {
    const { name, value } = event.target as HTMLInputElement;
    await form.setFieldsValue({ [name]: value });
  };

  return (
    <div>
      <Form form={form}>
        <Row gutter={10}>
          <Col span={24}>
            <Form.Item
              label="Code"
              name="code"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input placeholder="Enter Code" />
            </Form.Item>
            <Form.Item
              label="Other Codes"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Form.List name="other_codes">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => (
                      <Form.Item label={''} key={field.key}>
                        <Form.Item
                          wrapperCol={{ xs: 10 }}
                          {...field}
                          noStyle
                          //validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: false,
                              message: 'Please input other code',
                            },
                          ]}>
                          <Input
                            minLength={5}
                            placeholder="Other code"
                            style={{
                              width: fields.length > 1 ? '95%' : '100%',
                            }}
                          />
                        </Form.Item>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            style={{ marginLeft: '10px' }}
                            onClick={() => remove(field.name)}
                          />
                        ) : null}
                      </Form.Item>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        style={{
                          width: '100%',
                        }}
                        icon={<PlusOutlined />}>
                        Add
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form.Item>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input placeholder="Enter Name" />
            </Form.Item>
            <Form.Item
              label="Billing"
              name="billing"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input.TextArea rows={5} placeholder="Enter Address" />
            </Form.Item>
            <Form.Item
              label="Phone"
              name="phone"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input placeholder="Enter Phone" />
            </Form.Item>
            <Form.Item
              label="Contact"
              name="contact"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input placeholder="Enter Contact" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ type: 'email' }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input placeholder="Enter Email" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <Form.Item
                  name="contacts"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}>
                  <ContactList
                    data={getFieldValue('contacts') || []}
                    updateData={(list) =>
                      handleChange({
                        target: { name: 'contacts', value: list },
                      })
                    }
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
