import React, { useCallback } from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Table,
} from 'antd';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment';
import { get } from 'lodash';
import AutoResizeTable from '@/components/AutoResizeTable';
import { CompanySyncSelect } from '@/components/CompanySyncSelect';
import { NameLink, TYPE_VENDOR } from './NameLink';
import { UserLink } from './UserLink';
import { CompanyLink } from './CompanyLink';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { CSVLink } from 'react-csv';

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<any>();
  const [summary, setSummary] = React.useState<any>();
  const [filter] = useForm();
  const [exportingData, setExportingData] = React.useState<any[]>([]);

  const handleSave = async () => {
    const values = await filter.validateFields();
    setLoading(true);

    try {
      const result = await app.service.post('accountings/agingReport', {
        data: values,
      });
      const rows = get(result, 'rows', []);
      const summary = get(result, 'summary', {});
      setData(rows);
      setSummary(summary);
      setExportingData(toExportData(rows));
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  const refreshName = useCallback(
    (index: number, name: string) => {
      if (!data) {
        return;
      }

      const collection = data.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            name: name,
          };
        }
        return item;
      });

      setData(collection);
    },
    [data, setData],
  );

  const refreshUser = useCallback(
    (index: number, user: any) => {
      if (!data) {
        return;
      }

      const collection = data.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            name: user.name,
            user_accounting_memo: user.accounting_memo,
          };
        }
        return item;
      });

      setData(collection);
    },
    [data, setData],
  );

  const refreshCompany = useCallback(
    (index: number, company: any) => {
      if (!data) {
        return;
      }

      const collection = data.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            company: company.name,
            company_accounting_memo: company.accounting_memo,
          };
        }
        return item;
      });

      setData(collection);
    },
    [data, setData],
  );

  const toExportData = (data) => {
    // to csv data
    const rows = data.map((item) => {
      return [
        item.name,
        item.company,
        item.has_contract ? 'Yes' : 'No',
        item.agent_name,
        item.business_development_name,
        item.sales_name,
        item.sales_support_name,
        item.terms,
        item.total,
        item.current,
        item.due_1_30,
        item.due_31_60,
        item.due_61,
      ];
    });

    // add header
    rows.unshift([
      'Name',
      'Company',
      'Contract',
      'Agent',
      'BD',
      'Sales',
      'Sales Support',
      'Terms',
      'Total',
      'Current',
      'Over 1 - 30 Days',
      'Over 31 - 60 Days',
      'Over 60 Days',
    ]);

    return rows;
  };

  const columns: ColumnsType<any> = React.useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 200,
        render: (text, record, index) => {
          if (get(record, 'user_id')) {
            return (
              <UserLink
                name={get(record, 'name')}
                id={get(record, 'user_id')}
                accountingMemo={get(record, 'user_accounting_memo', '')}
                onSaved={(user: any) => refreshUser(index, user)}
              />
            );
          }

          if (get(record, 'vendor_id')) {
            return (
              <NameLink
                type={TYPE_VENDOR}
                name={get(record, 'name')}
                id={get(record, 'vendor_id')}
                onSaved={(name: string) => refreshName(index, name)}
              />
            );
          }

          return text;
        },
      },
      {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        width: 200,
        render: (text, record, index) => {
          if (get(record, 'company_id')) {
            return (
              <CompanyLink
                name={get(record, 'company')}
                id={get(record, 'company_id')}
                accountingMemo={get(record, 'company_accounting_memo', '')}
                onSaved={(company: any) => refreshCompany(index, company)}
              />
            );
          }

          return text;
        },
      },
      {
        title: 'Contract',
        dataIndex: 'has_contract',
        key: 'has_contract',
        width: 200,
        render: (v) => {
          if (v) {
            return 'Yes';
          }
          return 'No';
        },
      },
      {
        title: 'Agent',
        dataIndex: 'agent_name',
        key: 'agent_name',
        width: 100,
      },
      {
        title: 'BD',
        dataIndex: 'business_development_name',
        key: 'business_development_name',
        width: 100,
      },
      {
        title: 'Sales',
        dataIndex: 'sales_name',
        key: 'sales_name',
        width: 100,
        sorter: (a, b) => a.sales_id - b.sales_id,
      },
      {
        title: 'Sales Support',
        dataIndex: 'sales_support_name',
        key: 'sales_support_name',
        width: 150,
      },
      {
        title: 'Terms',
        dataIndex: 'terms',
        key: 'terms',
        width: 200,
      },
      {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        width: 200,
        sorter: (a, b) =>
          parseFloat(a.total.replace(/,/g, '')) -
          parseFloat(b.total.replace(/,/g, '')),
      },
      {
        title: 'Current',
        dataIndex: 'current',
        key: 'current',
        width: 200,
        sorter: (a, b) =>
          parseFloat(a.current.replace(/,/g, '')) -
          parseFloat(b.current.replace(/,/g, '')),
      },
      {
        title: 'Over 1 - 30 Days',
        dataIndex: 'due_1_30',
        key: 'due_1_30',
        width: 200,
        sorter: (a, b) =>
          parseFloat(a.due_1_30.replace(/,/g, '')) -
          parseFloat(b.due_1_30.replace(/,/g, '')),
      },
      {
        title: 'Over 31 - 60 Days',
        dataIndex: 'due_31_60',
        key: 'due_31_60',
        width: 200,
        sorter: (a, b) =>
          parseFloat(a.due_31_60.replace(/,/g, '')) -
          parseFloat(b.due_31_60.replace(/,/g, '')),
      },
      {
        title: 'Over 60 Days',
        dataIndex: 'due_61',
        key: 'due_61',
        width: 200,
        sorter: (a, b) =>
          parseFloat(a.due_61.replace(/,/g, '')) -
          parseFloat(b.due_61.replace(/,/g, '')),
      },
    ],
    [refreshUser, refreshCompany],
  );

  return (
    <div className={styles.main}>
      <Header title="Aging Report"></Header>
      <div className={styles.filter}>
        <Form
          className="w100"
          layout="vertical"
          form={filter}
          onFinish={handleSave}
        >
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item label="Date as of">
                <Space>
                  <Form.Item noStyle name="date" initialValue={'invoice_date'}>
                    <Select style={{ width: '10em' }}>
                      <Select.Option value="post_date">
                        {'Post Date'}
                      </Select.Option>
                      <Select.Option value="invoice_date">
                        {'Inovice Date'}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="end_date"
                    initialValue={moment().format('YYYY-MM-DD')}
                    noStyle
                  >
                    <Input hidden />
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue, setFieldsValue }) => {
                      const end_date = getFieldValue('end_date');
                      return (
                        <DatePicker
                          value={end_date ? moment(end_date) : null}
                          onChange={(value) =>
                            setFieldsValue({
                              end_date: value
                                ? value?.format('YYYY-MM-DD')
                                : null,
                            })
                          }
                          style={{ width: '100%' }}
                        />
                      );
                    }}
                  </Form.Item>
                </Space>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Company" name="company_id">
                <CompanySyncSelect />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Sales" name="sales_id">
                <UserSyncSelect
                  type="admin"
                  addNullOption
                  nullOptionValue={-1}
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Sales Support" name="sales_support_id">
                <UserSyncSelect
                  type="admin"
                  addNullOption
                  nullOptionValue={-1}
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Agent" name="agent_id">
                <UserSyncSelect
                  type="user"
                  addNullOption
                  nullOptionValue={-1}
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                label="Business Development"
                name="business_development_id"
              >
                <UserSyncSelect
                  type="admin"
                  addNullOption
                  nullOptionValue={-1}
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Space align="end">
                <Form.Item
                  label="&nbsp;"
                  name="invoiceable"
                  initialValue={true}
                  valuePropName="checked"
                >
                  <Checkbox>Invoice</Checkbox>
                </Form.Item>
                <Form.Item
                  label="&nbsp;"
                  name="billable"
                  initialValue={true}
                  valuePropName="checked"
                >
                  <Checkbox>Bill</Checkbox>
                </Form.Item>
              </Space>
            </Col>
            {/* </Col> */}
            <Col span={3}>
              <Form.Item label={' '}>
                <Space>
                  <Button htmlType="submit" type="primary">
                    Submit
                  </Button>
                  <CSVLink data={exportingData} filename="agingreport.csv">
                    <Button>Export</Button>
                  </CSVLink>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <AutoResizeTable
        loading={loading}
        pagination={false}
        size="small"
        rowKey={moment().format('Y-m-d H:i:s') + Math.random()}
        columns={columns}
        dataSource={data ? data : []}
        summary={() =>
          summary ? (
            <Table.Summary fixed>
              <Table.Summary.Row className={styles.table_summary_row}>
                <Table.Summary.Cell index={0} colSpan={8} align={'right'}>
                  Total{' '}
                  {data && data.length > 0
                    ? data.length + ' Records'
                    : '0 Record'}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  {summary.total}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  {summary.current}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                  {summary.due_1_30}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                  {summary.due_31_60}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5}>
                  {summary.due_61}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          ) : (
            <></>
          )
        }
        sticky
        scroll={{
          x: 'auto',
        }}
      />
    </div>
  );
};

export default Index;
