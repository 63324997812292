import request from '@/utils/request';
import { action, observable } from 'mobx';
import _ from 'lodash';

export class Cache {
  @observable data: { [containerId: number]: any[] | any } = {};

  @observable buffers: Array<number> = [];

  _debounceFetch = _.debounce((...args) => {
    return request.post('containers/getStatements', ...args).then((resp) => {
      this.buffers = [];
      if (!this.data) {
        this.data = {};
      }
      const _data = { ...this.data };
      for (const item of resp.data) {
        _data[item.id] = item;
      }

      this.data = _data;
    });
  }, 500);

  @action debounceFetch(containerId: number) {
    if (this.buffers) {
      this.buffers.push(containerId);
    }

    return this._debounceFetch({
      data: { ids: _.uniq(this.buffers) },
    });
  }

  @action getStatements(containerId: number) {
    return this.data[containerId] ?? null;
  }
}

const cntrStatementCache = new Cache();

export default cntrStatementCache;
