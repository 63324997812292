import {
  Button,
  Space,
  Modal,
  Typography,
  Popconfirm,
  message,
  Row,
  Switch,
} from 'antd';

import { useEffect, useState } from 'react';
import React from 'react';
import { TUser } from '@/types';
import { useApp } from '@/utils/useapp';
import { get } from 'lodash';

export const ModalNewApiToken: React.FC<{
  open: boolean;
  user: TUser | undefined;
  onClose: () => void;
  refreshUser: (id: number) => void;
}> = ({ open, user, onClose, refreshUser }) => {
  const app = useApp();

  const [showApiToken, setShowApiToken] = useState(false);
  const [apiToken, setApiToken] = useState<any>(null);
  const [loading, setLoading] = React.useState(false);

  const handleCancel = () => {
    setApiToken(null);
    setShowApiToken(false);
    onClose();
  };

  const handleDeleteToken = async () => {
    const userId = get(user, 'id', 0);
    if (!userId) {
      return;
    }

    setLoading(true);

    try {
      await app.service.patch(`users/${userId}/deleteToken`, {});

      setShowApiToken(true);
      setApiToken('None');

      message.success('Saved');
      refreshUser(userId);
    } catch (e: any) {
      message.error(e.data.error || 'System Error');
    }

    setLoading(false);
  };

  const handleRegenerateToken = async () => {
    const userId = get(user, 'id', 0);
    if (!userId) {
      return;
    }

    setLoading(true);

    try {
      const resp = await app.service.patch(
        `users/${userId}/regenerateToken`,
        {},
      );

      setShowApiToken(true);
      setApiToken(get(resp, 'apiToken', null));

      message.success('Saved');
      refreshUser(userId);
    } catch (e: any) {
      message.error(e.data.error || 'System Error');
    }

    setLoading(false);
  };

  const handleApiEnable = async (data: any) => {
    const userId = get(user, 'id', 0);
    if (!userId) {
      return;
    }

    setLoading(true);

    try {
      await app.service.patch(`users/${userId}/handleApiEnable`, {
        data,
      });
      message.success('Updated');
      refreshUser(userId);
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      cancelText="Close"
      style={{ top: '35%' }}
      width="40%"
      title="Api Token"
      footer={false}
      open={open}
      onCancel={handleCancel}>
      <div>
        <Row className="mb-md">
          <Space>
            <Typography.Text>API Token:</Typography.Text>
            <Typography.Text>
              {showApiToken
                ? apiToken
                : get(user, 'has_token', false)
                  ? '*****'
                  : 'None'}
            </Typography.Text>
          </Space>
        </Row>
        <Row className="mb-md">
          <span> V1 Enable &nbsp;</span>
          <Switch
            disabled={loading}
            checked={get(user, 'api_v1_enabled', false)}
            onChange={(checked: boolean) => {
              handleApiEnable({ api_v1_enabled: checked });
            }}
          />
        </Row>
        <Row className="mb-md">
          <span> V2 Enable &nbsp;</span>
          <Switch
            disabled={loading}
            checked={get(user, 'api_v2_enabled', false)}
            onChange={(checked: boolean) => {
              handleApiEnable({ api_v2_enabled: checked });
            }}
          />
        </Row>
        <Row className="mb-md">
          <span> V3 Enable &nbsp;</span>
          <Switch
            disabled={loading}
            checked={get(user, 'api_v3_enabled', false)}
            onChange={(checked: boolean) => {
              handleApiEnable({ api_v3_enabled: checked });
            }}
          />
        </Row>
        <Row>
          <Space>
            <Button type="primary" onClick={handleRegenerateToken}>
              Regenerate token
            </Button>
            {get(user, 'has_token', false) && (
              <Popconfirm
                placement="top"
                title={'Are you sure to delete?'}
                onConfirm={handleDeleteToken}
                okText="Yes"
                cancelText="No">
                <Button>Delete token</Button>
              </Popconfirm>
            )}
          </Space>
        </Row>
      </div>
    </Modal>
  );
};
