import { useApp } from '@/utils/useapp';
import { AutoComplete, Input, message, Select, Spin } from 'antd';
import { capitalize, debounce } from 'lodash';
import React, { FC, useCallback } from 'react';
const RESIDENTIAL = 2;
const AddressAutoFill: FC<{
  value: string;
  disabled: boolean;
  onSelect: (data: any) => void;
}> = ({ value = '', onSelect, disabled = false }) => {
  const app = useApp();
  const [options, setOptions] = React.useState<any[]>([]);
  const [data, setData] = React.useState<any[]>([]);
  // const [value, setValue] = React.useState<string>(value);
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleSearch = async (query: string) => {
    setLoading(true);
    if (query.length === 0) {
      return;
    }
    try {
      const resp = await app.service.get('address/suggestions', {
        params: {
          query,
        },
      });

      // const options = resp.features.map((place: any) => {
      //   const context = place.context;
      //   return {
      //     full_address: place.place_name || '',
      //     country:
      //       context.find((c: any) => c.id.includes('country'))?.short_code ||
      //       '',
      //     region: context.find((c: any) => c.id.includes('region'))?.text || '',
      //     place: context.find((c: any) => c.id.includes('place'))?.text || '',
      //     postcode:
      //       context.find((c: any) => c.id.includes('postcode'))?.text || '',
      //     address: `${place.address ? place.address + ' ' : ''}${place.text}`,
      //   };
      // });
      const options = resp.data;
      setData(options);
      setOptions(
        options.map((o: any, idx: number) => ({
          value: idx,
          label: o.full_address,
        })),
      );
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };
  const doSearch = useCallback(debounce(handleSearch, 500), []);

  const handleSelect = async (idx: any) => {
    const _data = data[idx];

    if (_data && onSelect) {
      onSelect({
        city: _data.city,
        city_id: _data.city?.id || '',
        country: _data.country,
        zipcode: _data.postcode,
        address: _data.address,
      });
    }
  };

  const handleChange = (v: string) => {
    onSelect &&
      onSelect({
        address: v,
      });
  };

  return (
    <AutoComplete
      disabled={disabled}
      options={options}
      onChange={handleChange}
      value={value}
      onSelect={handleSelect}
      onSearch={doSearch}
      placeholder="input here"
    />
  );
};

export default AddressAutoFill;
