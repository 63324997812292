import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { Modal, Typography } from 'antd';

const { Paragraph } = Typography;

const CSMemo = ({
  container,
  onUpdated,
}: {
  container: any;
  onUpdated: (id: string, value: string) => void;
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [memo, setMemo] = useState(container.cs_memo || '');

  useEffect(() => {
    setMemo(container.cs_memo || '');
  }, [container.cs_memo, isModalVisible]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    onUpdated(container.id, memo);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setMemo(container.csMemo || '');
    setIsModalVisible(false);
  };

  return (
    <>
      <Paragraph
        ellipsis={{ rows: 2 }}
        style={{ cursor: 'pointer', margin: 0 }}
        onClick={showModal}
      >
        {container.cs_memo || '-'}
      </Paragraph>

      <Modal
        title="Edit CS Memo"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <TextArea
          value={memo}
          onChange={(e) => setMemo(e.target.value)}
          rows={4}
          placeholder="Enter CS memo"
        />
      </Modal>
    </>
  );
};

export default CSMemo;
