import React, { useState, useMemo } from 'react';

import { Form, Select, message, Drawer, Space, Button, Input, Tag } from 'antd';

import { useApp } from '@/utils/useapp';
import { useForm } from 'antd/lib/form/Form';

import { BulkUpdateFormItem } from '@/components/BulkUpdateFormItem';

import { chain, uniq } from 'lodash';
import {
  DISPATCH_AR_STATUS_OPTIONS,
  DISPATCH_AP_STATUS_OPTIONS,
  DISPATCH_AR_STATUS_ALL_ISSUED,
} from './data';
import useDispatch from './Table/useDispatch';

interface Props {
  containers: any;
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}
const CONTAINER_KEY = 'CONTAINER_';
const ORDER_KEY = 'ORDER_';

export const AccountingModeBulkUpdate: React.FC<Props> = ({
  containers = [],
  open = false,
  onClose,
  onSubmit,
}) => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [form] = useForm();
  const [names, setNames] = useState<Array<string>>([]);
  const orderIds = useMemo(
    () => uniq(containers.map((c: any) => c.order_id)),
    [containers],
  );

  const { ...dispatch } = useDispatch();

  const handleSave = async () => {
    const values = await form.validateFields(names);

    const containerValues: any = chain(
      names.filter((n) => -1 !== n.indexOf(CONTAINER_KEY)),
    )
      .reduce(
        (result, n) => ({
          ...result,
          [n.replace(CONTAINER_KEY, '')]: values[n],
        }),
        {},
      )
      .value();

    const orderValues: any = chain(
      names.filter((n) => -1 !== n.indexOf(ORDER_KEY)),
    )
      .reduce(
        (result, n) => ({
          ...result,
          [n.replace(ORDER_KEY, '')]: values[n],
        }),
        {},
      )
      .value();

    setLoading(true);

    try {
      await app.service.put('dispatch/bulk', {
        data: {
          orders:
            Object.keys(orderValues).length > 0
              ? orderIds.map((id: any) => ({
                id,
                ...orderValues,
              }))
              : [],
          containers:
            Object.keys(containerValues).length > 0
              ? containers.map((c: any) => ({
                id: c.id,
                ...containerValues,
              }))
              : [],
        },
      });
      message.success('Saved');
      form.resetFields();
      setNames([]);
      onSubmit();
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  const handleClose = () => {
    form.resetFields();
    setNames([]);
    onClose();
  };

  const handleChange = async (event: any) => {
    const { name, value } = event.target as HTMLInputElement;
    await form.setFieldsValue({ [name]: value });
  };

  const handleListenNamesRequired = (name: string, required: boolean) => {
    const _names = Object.assign([], names);
    if (_names.find((n) => n == name)) {
      !required && setNames(_names.filter((n) => n != name));
    } else {
      _names.push(name);
      required && setNames(_names);
    }
  };

  return (
    <>
      <Drawer
        title={'Bulk Update'}
        placement={'right'}
        width={'50%'}
        onClose={handleClose}
        destroyOnClose={true}
        open={open}
        extra={
          <Space>
            <Button onClick={handleClose}>Close</Button>
            <Button type="primary" loading={loading} onClick={handleSave}>
              Save
            </Button>
          </Space>
        }>
        Selected:
        <div style={{ marginBottom: '10px' }}>
          {containers?.map((c: any) => (
            <Tag key={c.order.uid + '-' + c.number} color="processing">
              {c.order.uid} <br />
              {c.number}
            </Tag>
          ))}
        </div>
        <Form layout="vertical" form={form} onFinish={handleSave}>
          <Form.Item name="isSync" noStyle>
            <Input hidden></Input>
          </Form.Item>

          <BulkUpdateFormItem
            form={form}
            name={`${CONTAINER_KEY}ar_status`}
            label="AR Status"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue, setFieldValue }) => (
                  <Select
                    disabled={
                      names?.includes(`${CONTAINER_KEY}ar_status`)
                        ? false
                        : true
                    }
                    value={getFieldValue(`${CONTAINER_KEY}ar_status`)}
                    onChange={(val: any) => {
                      const callback = () => {
                        handleChange({
                          target: {
                            name: `${CONTAINER_KEY}ar_status`,
                            value: val,
                          },
                        });
                      };

                      if (val === DISPATCH_AR_STATUS_ALL_ISSUED) {
                        dispatch.checkIfArStatusToBeAllIssueWithMessage(
                          containers.map((c) => c.id),
                          callback,
                          () =>
                            handleChange({
                              target: {
                                name: `${CONTAINER_KEY}ar_status`,
                                value: null,
                              },
                            }),
                        );
                      } else {
                        callback();
                      }
                    }}>
                    {DISPATCH_AR_STATUS_OPTIONS.map((o) => (
                      <Select.Option key={o.value} value={o.value}>
                        {o.label}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            }
          />

          <BulkUpdateFormItem
            form={form}
            name={`${CONTAINER_KEY}ap_status`}
            label="AP Status"
            toListenNameRequired={handleListenNamesRequired}
            elementControlledByStatus={
              <Select
                disabled={
                  names?.includes(`${CONTAINER_KEY}ap_status`) ? false : true
                }
                onChange={(val) => {
                  const callback = () =>
                    handleChange({
                      target: {
                        name: `${CONTAINER_KEY}ap_status`,
                        value: val,
                      },
                    });

                  if (val === DISPATCH_AR_STATUS_ALL_ISSUED) {
                    dispatch.checkIfApStatusToBeAllIssueWithMessage(
                      containers.map((c) => c.id),
                      callback,
                      () =>
                        handleChange({
                          target: {
                            name: `${CONTAINER_KEY}ap_status`,
                            value: null,
                          },
                        }),
                    );
                  } else {
                    callback();
                  }
                }}>
                {DISPATCH_AP_STATUS_OPTIONS.map((o) => (
                  <Select.Option key={o.value} value={o.value}>
                    {o.label}
                  </Select.Option>
                ))}
              </Select>
            }
          />
        </Form>
      </Drawer>
    </>
  );
};
