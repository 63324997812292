import React from 'react';

import { isArray, upperFirst } from 'lodash';
import DefaultIcon from './DefaultIcon';

const TimeDetail: React.FC<{ time: any }> = ({ time }) => {
  if (!time) {
    return <DefaultIcon />;
  }

  return (
    <span className="text-link">
      {isArray(time) ? time.join(' - ') : upperFirst(time)}
    </span>
  );
};

export default TimeDetail;
