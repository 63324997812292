import {
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
} from 'antd';
import React from 'react';
import { FormInstance } from 'antd/lib/form';
import { VendorSelect } from '@/components/VendorSelect';

const columns: string[] = [];
const A = 65;
const Z = 91;
for (let i = A; i < Z; i++) {
  columns.push(String.fromCharCode(i));
}

export const DrawerForm: React.FC<{
  form: FormInstance<any>;
}> = ({ form }) => {
  const handleChange = async (event: any) => {
    const { name, value } = event.target as HTMLInputElement;
    await form.setFieldsValue({ [name]: value });
  };

  return (
    <div>
      <Form form={form}>
        <Row gutter={10}>
          <Col span={24}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input placeholder="Enter Name" />
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <Form.Item
                  name="vendor"
                  label="Vendor"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: true }]}>
                  <VendorSelect
                    selected={getFieldValue('vendor')}
                    onSelect={(vendor) =>
                      handleChange({
                        target: { name: 'vendor', value: vendor },
                      })
                    }
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item
              label="Sheet Id"
              name="sheet_id"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input placeholder="Enter Name" />
            </Form.Item>
            <Form.Item
              label="Tab"
              name="tab"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input placeholder="Tab Name" />
            </Form.Item>
            <Form.Item
              label="Skip Lines"
              name="skip_lines"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <InputNumber placeholder="How many lines to skip" />
            </Form.Item>
            <Form.Item
              label="Enabled"
              name="enabled"
              initialValue={false}
              valuePropName="checked"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Switch />
            </Form.Item>
            <Form.Item name="columns_map" hidden>
              <Input></Input>
            </Form.Item>
            <Divider />

            {/* <Form.Item label="Columns Map" shouldUpdate labelCol={{ span: 24 }}>
              {() => {
                // POD ETA:  C, container.port_of_discharge_eta,
                // Final Port ETA:  C, container.final_port_eta,
                // CNTR#:  F, container.number,
                // CNTR Size:  G, container.type,
                // MBL:  H, order.mbl_number,
                // Terminal Pickup At:  M, container.actual_terminal_pickuped_at,
                // Delivered At:  O, container.actual_delivered_at,
                // Empty At:  H, container.actual_empty_at,
                // Empty Return At:  H, container.actual_empty_returned_at,
                const layout = {
                  labelCol: { span: 5 },
                  style: { maxWidth: 600 },
                };
                return (
                  <>
                    <Form.Item
                      {...layout}
                      name={['columns_map', 'port_of_discharge_eta']}
                      initialValue={'C'}
                      label="POD ETA">
                      <Select showSearch>
                        {columns.map((c) => (
                          <Select.Option key={c} value={c}>
                            {c}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...layout}
                      name={['columns_map', 'final_port_eta']}
                      initialValue={'C'}
                      label="Final Port ETA">
                      <Select showSearch>
                        {columns.map((c) => (
                          <Select.Option key={c} value={c}>
                            {c}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...layout}
                      name={['columns_map', 'number']}
                      initialValue={'F'}
                      label="CNTR#">
                      <Select showSearch>
                        {columns.map((c) => (
                          <Select.Option key={c} value={c}>
                            {c}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...layout}
                      name={['columns_map', 'type']}
                      initialValue={'G'}
                      label="CNTR Size">
                      <Select showSearch>
                        {columns.map((c) => (
                          <Select.Option key={c} value={c}>
                            {c}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...layout}
                      name={['columns_map', 'mbl_number']}
                      initialValue={'H'}
                      label="MBL">
                      <Select showSearch>
                        {columns.map((c) => (
                          <Select.Option key={c} value={c}>
                            {c}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...layout}
                      name={['columns_map', 'actual_terminal_pickuped_at']}
                      initialValue={'M'}
                      label="Terminal Pickup At">
                      <Select showSearch>
                        {columns.map((c) => (
                          <Select.Option key={c} value={c}>
                            {c}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...layout}
                      name={['columns_map', 'actual_delivered_at']}
                      initialValue={'O'}
                      label="Delivered At">
                      <Select showSearch>
                        {columns.map((c) => (
                          <Select.Option key={c} value={c}>
                            {c}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...layout}
                      name={['columns_map', 'actual_empty_at']}
                      initialValue={'Q'}
                      label="Empty At">
                      <Select showSearch>
                        {columns.map((c) => (
                          <Select.Option key={c} value={c}>
                            {c}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...layout}
                      name={['columns_map', 'actual_empty_returned_at']}
                      initialValue={'S'}
                      label="Empty Return At">
                      <Select showSearch>
                        {columns.map((c) => (
                          <Select.Option key={c} value={c}>
                            {c}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...layout}
                      name={['columns_map', 'delivery_address']}
                      initialValue={'W'}
                      label="Delivery Address">
                      <Select showSearch>
                        {columns.map((c) => (
                          <Select.Option key={c} value={c}>
                            {c}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </>
                );
              }}
            </Form.Item> */}
          </Col>
        </Row>
      </Form>
    </div>
  );
};
