export interface InsuranceInterface {
  id: number;
  provider: string;
  cargo_value: number;
  premium: number;
  premium_in_usd: number;
  insuranced_at: string;
  status: string;
  policy_number: string;
  currency: string;
}

export const INSURANCE_STATUS_PENDING = 'PENDDING';
export const INSURANCE_STATUS_INSURED = 'INSURED';
