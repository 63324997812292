import React from 'react';
import { Popover, Tabs, Tooltip, DatePicker } from 'antd';
import { isNumber } from 'lodash';
import { InfoCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import {
  PROGRESS_DESCRIPTION,
  PROGRESS_TYPE_PICKUP,
  PROGRESS_TYPE_DELIVERY,
  PROGRESS_TYPE_MAP,
} from './data';

export const SUB_TAB_FIXED = 'fixed';
export const SUB_TAB_PROGESS = 'progress';

export const setTabItem = (progressType: string, count: number | null) => {
  const description =
    PROGRESS_DESCRIPTION[
      (progressType as unknown) as keyof typeof PROGRESS_DESCRIPTION
    ];

  return {
    name:
      PROGRESS_TYPE_MAP[
        (progressType as unknown) as keyof typeof PROGRESS_TYPE_MAP
      ],
    count,
    key: progressType,
    showCount: true,
    type: SUB_TAB_PROGESS,
    showDate: [PROGRESS_TYPE_PICKUP, PROGRESS_TYPE_DELIVERY].includes(
      progressType,
    ),
    showDescription: isNumber(count),
    description,
  };
};

const RenderCount: React.FC<{
  isActive: boolean;
  count: number | { actual_total: number; est_total: number };
}> = ({ isActive, count }) => {
  return (
    <span
      style={{
        borderRadius: '100px',
        background: isActive ? '#F0F9FF' : '#F0F0F0',
        marginLeft: '2px',
      }}
    >
      {typeof count === 'number' ? (
        count
      ) : (
        <>
          {`${count?.actual_total} / ${count?.est_total}`}{' '}
          <Tooltip title={'ACT / EST'}>
            <InfoCircleOutlined />
          </Tooltip>
        </>
      )}
    </span>
  );
};

export interface INavTabItem {
  name: string;
  key: string | number;
  type: 'normal' | 'server';
  showCount?: boolean;
  count?:
  | number
  | {
    actual_total: number;
    est_total: number;
  };
  showDescription: boolean;
  description?: string;
  showDate: boolean;
}

export const NavTabs: React.FC<{
  items: Array<INavTabItem>;
  activeKey: string;
  onChange: (key: string) => void;
  onChangeDate: (date: string) => void;
}> = ({ items, activeKey, onChange, onChangeDate }) => {
  return (
    <Tabs
      className="pl-sm pr-sm"
      activeKey={activeKey}
      type="card"
      onChange={onChange}
      items={items.map((tab: INavTabItem) => {
        const isActive = activeKey == tab.key;
        return {
          label: (
            <div className="pl-sm">
              {tab.showDescription && (
                <Tooltip title={tab.description} style={{ marginRight: '2px' }}>
                  <InfoCircleOutlined size={'small'} />
                </Tooltip>
              )}
              <span
                style={{
                  color: isActive ? '#2F6EC0' : '#8C8C8C',
                  fontWeight: '400',
                }}
              >
                {tab.name} {tab.showCount && <RenderCount count={tab.count} />}
              </span>

              {tab.showDate && (
                <Popover
                  placement="bottom"
                  className="ml-xs"
                  content={
                    <DatePicker
                      getPopupContainer={(trigger: any) =>
                        trigger.parentElement
                      }
                      size="middle"
                      showToday
                      clearIcon={null}
                      // value={value ? moment.tz(value, 'America/Los_Angeles') : null}
                      format="YYYY-MM-DD"
                      defaultValue={moment.tz('America/Los_Angeles')}
                      onChange={(value) => {
                        onChangeDate(value?.format('YYYY-MM-DD') || '');
                      }}
                    />
                  }
                >
                  <ClockCircleOutlined />
                </Popover>
              )}
            </div>
          ),
          key: tab.key,
        };
      })}
    ></Tabs>
  );
};
