import { Space, Button, message, Table, Tooltip, Tag } from 'antd';

import React, { useMemo } from 'react';
import NewInvoice from '@/pages/accounting/invoices/components/NewInvoice';
import { TInvoice } from '@/types';
import type { ColumnsType } from 'antd/es/table';
import EditInvoice from '@/pages/accounting/invoices/components/EditInvoice';
import { uniq, truncate, get, sumBy } from 'lodash';
import {
  INVOICE_TYPE_MAP,
  STATUS_MAP,
} from '@/pages/accounting/invoices/components/data';
import { INVOICE_TYPE_FTL } from '@/pages/accounting/invoices/components/data';
import SendInvoiceDropdown from '@/components/SendInvoiceDropdown';

interface IInvoicesTab {
  id: number;
  invoices: TInvoice[];
  container?: any;
  activeSelectedInvoices?: any;
  rowSelection: any;
  setInvoiceTotal: (total: number) => void;
  onSaved: () => void;
  onDeleted: () => void;
  onSettled?: () => void;
}

export const InvoiceTable: React.FC<IInvoicesTab> = ({
  id,
  invoices,
  container,
  activeSelectedInvoices,
  rowSelection,
  setInvoiceTotal,
  onSaved,
  onDeleted,
  onSettled,
  ...props
}) => {
  const invoicesTotal = React.useMemo(() => {
    const total = invoices?.reduce(
      (accumulator: number, invoice: TInvoice) =>
        accumulator + +invoice.amount_total,
      0,
    );
    setInvoiceTotal(total);

    return total;
  }, [invoices]);

  const columns: ColumnsType<TInvoice> = React.useMemo(() => {
    return [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 100,
        render: (value, record: any, index) => (
          <EditInvoice
            id={record.id}
            invoiceableId={record.invoiceable_id}
            invoiceableType={record.invoiceable_type}
            onSaved={onSaved}
            onDeleted={onDeleted}
          >
            <Space>
              {record.dispute_at ? (
                <Tooltip
                  placement="topLeft"
                  title={<>In Dispute</>}
                  arrowPointAtCenter
                >
                  <a className="text-danger">{record.id}</a>
                </Tooltip>
              ) : (
                <a>{record.id}</a>
              )}
            </Space>
          </EditInvoice>
        ),
      },
      {
        title: 'Customer',
        dataIndex: ['user', 'name'],
        ellipsis: {
          showTitle: false,
        },
        width: 200,
        render: (text, record) => (
          <Tooltip placement="topLeft" title={text}>
            {truncate(text, { length: 20 })}{' '}
            {record.user?.bill_to ? `(${record.user.bill_to?.name})` : ''}
          </Tooltip>
        ),
      },
      {
        title: 'Pro#',
        dataIndex: 'pro_number',
        key: 'pro_number',
        width: 200,
      },
      {
        title: 'Status',
        dataIndex: 'state',
        key: 'state',
        width: 100,
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        width: 100,
        render: (text) => (
          <>
            {
              INVOICE_TYPE_MAP[
                (text as unknown) as keyof typeof INVOICE_TYPE_MAP
              ]
            }
          </>
        ),
      },
      {
        title: 'Dispute Status',
        key: 'dispute_status',
        width: 130,
        render: (record: TInvoice) =>
          record?.dispute_status ? (
            <Tag color="error">
              {
                STATUS_MAP[
                  (record.dispute_status as unknown) as keyof typeof STATUS_MAP
                ]
              }
            </Tag>
          ) : (
            ''
          ),
      },
      {
        title: 'AmountTotal',
        dataIndex: 'amount_total',
        width: 180,
        key: 'amount_total',
      },
      {
        title: 'AmountDue',
        dataIndex: 'amount_due',
        width: 180,
        key: 'amount_due',
      },
      {
        title: 'AmountPaid',
        dataIndex: 'amount_paid',
        width: 180,
        key: 'amount_paid',
      },
      {
        title: 'Invoice At',
        dataIndex: 'invoiced_at',
        width: 150,
        key: 'invoiced_at',
      },
      {
        title: 'Due At',
        dataIndex: 'due_at',
        width: 150,
        key: 'due_at',
      },

      {
        title: 'Sent At',
        dataIndex: 'sent_email',
        width: 240,
        key: 'sent_at',
        render: (text, record) => {
          if (record.sent_email) {
            return (
              <>
                <div>{get(record, 'sent_email.created_at')}</div>
                <div>{get(record, 'sent_email.sent_by.name')}</div>
              </>
            );
          } else if (record.sent_at || record.sent_by) {
            return (
              <>
                <div>{record.sent_at}</div>
                <div>{get(record, 'sent_by.name')}</div>
              </>
            );
          }
        },
      },
      {
        title: 'Paid At',
        dataIndex: 'paid_at',
        width: 150,
        key: 'paid_at',
      },
      {
        title: 'Deposited At',
        dataIndex: 'deposited_at',
        width: 150,
        key: 'deposited_at',
      },
    ];
  }, []);

  return (
    <>
      <>
        <Space align="center">
          <h3>Invoices</h3>
          <div>
            <Space>
              <NewInvoice
                invoiceableId={id}
                invoiceableType={INVOICE_TYPE_FTL}
                onSaved={onSaved}
              >
                <Button>New Invoice</Button>
              </NewInvoice>
              <SendInvoiceDropdown
                invoiceableId={id}
                invoiceableType={INVOICE_TYPE_FTL}
                invoices={activeSelectedInvoices}
                onSaved={onSaved}
              />
            </Space>
          </div>
        </Space>

        <Table
          size="small"
          pagination={false}
          rowKey="id"
          scroll={{
            x: 1500,
            y: 200,
          }}
          columns={columns}
          rowSelection={rowSelection}
          dataSource={invoices}
          footer={() => (
            <Space className="w100" direction="vertical" align="end">
              <h4>Invoices total {invoicesTotal.toFixed(2)}</h4>
            </Space>
          )}
        />
      </>
    </>
  );
};
