import { useApp } from '@/utils/useapp';
import { message, Form, Modal, Space, Button, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';
import EmailForm from '@/components/EmailForm';
import { serialize } from 'object-to-formdata';
import { get, uniq } from 'lodash';
import actionUtil, {
  TSendEmailModal,
  DOC_TARGET_MAP,
  TAction,
} from '../../Interfaces/ActionInterface';
import useAction from '../useAction';

const SendEmailModal: React.FC<TSendEmailModal & TAction> = ({
  task,
  admin,
  targetModel,
  target,
  open,
  onDone,
  onClose,
}) => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [emailForm] = useForm();
  const [docs, setDocs] = useState<any[]>([]);
  const { sendEmail } = useAction({ target, targetModel });

  const handleOnClose = () => {
    emailForm.resetFields();
    onClose();
  };

  const handleSendEmail = async () => {
    try {
      const data = await emailForm.validateFields();

      const config = {
        data: serialize(
          {
            ...data,
            task_id: task.id,
            action: actionUtil.SEND_DEL_VERIFICATION,
          },
          { indices: true, nullsAsUndefineds: true },
        ),
        requestType: 'form',
      };

      await sendEmail(config, setLoading, onDone);

      emailForm.resetFields();
    } catch (err: any) {
      err.data && message.error(err.data.message || err.data.error);
    }
  };

  // const fetchDocuments = async () => {
  //   if (!targetModel?.id || !target || !DOC_TARGET_MAP[target]) {
  //     return;
  //   }

  //   const docTarget = DOC_TARGET_MAP[target];

  //   try {
  //     const docs = await app.service.get(
  //       `documents/${docTarget}/${targetModel.id}`,
  //     );

  //     const resData = [...docs.data];

  //     setDocs(
  //       resData.map((d: any) => {
  //         return {
  //           label: `[${get(d, 'custom_properties.document_type', 'No Type')}]${
  //             d.file_name
  //           }`,
  //           value: d.id,
  //         };
  //       }),
  //     );
  //   } catch (err: any) {
  //     setDocs([]);
  //   }
  // };

  const initForm = () => {
    const sendToList = [targetModel.destination_contact_email];
    const salesEmail = get(targetModel, 'sales.email', '');
    const ssEmail = get(targetModel, 'sales_support.email', '');
    const operatorEmail = get(targetModel, 'operator.email', '');
    const operatorName = get(targetModel, 'operator.name', '');
    const description = targetModel.items[0].description;
    const accessorials =
      targetModel.destination_accessorials?.length > 0
        ? targetModel.destination_accessorials.join(', ')
        : '';

    const uid = get(targetModel, 'uid', '');
    const cityState = `${targetModel.destination_city}, ${targetModel.destination_state}`;

    let address = `${targetModel.destination_address1}`;
    if (targetModel.destination_address2) {
      address += ` ${targetModel.destination_address2}`;
    }
    address += ` ${cityState} ${targetModel.destination_zipcode}`;

    //DEL Verification For +$ DE ID + $Vendor ID + DEL city, state
    let subject = `DEL Verification For ${uid}`;
    targetModel.vendor_shipment_id &&
      (subject += ` - ${targetModel.vendor_shipment_id}`);
    cityState && (subject += ` - ${cityState}`);

    emailForm.setFieldsValue({
      sendToList: sendToList?.length > 0 ? sendToList : [''],
      ccList: uniq([admin?.email, salesEmail, ssEmail, operatorEmail]).filter(
        (i) => i,
      ),
      subject,
      body: `
Hi ${targetModel.destination_contact_name}, This is ${operatorName} from Drayeasy. Your vendor booked a shipment with us. Details:
• Ref#: ${targetModel.uid}
• Facility: ${targetModel.destination_company_name}
• Facility Type: ${targetModel.destination_address_type}
• Facility Address: ${address}
• Contact Person: ${targetModel.destination_contact_name}
• Contact Number: ${targetModel.destination_contact_phone}
• Cargo: ${description}
• Pallets: ${targetModel.total_units}
• Total Weight: ${targetModel.total_weight} LBS
• Accessorials: ${accessorials}
Can you confirm if all the information is correct or if additional accessorials are needed?`,
    });
  };

  useEffect(() => {
    open && initForm();
  }, [open, targetModel]);

  // React.useEffect(() => {
  //   open && target && targetModel?.id && fetchDocuments();
  // }, [open, targetModel, target]);

  return (
    <>
      <Modal
        title="LTL - Send DEL Verification"
        width={'75%'}
        onCancel={handleOnClose}
        destroyOnClose={true}
        open={open}
        footer={
          <>
            <Space>
              <Button disabled={loading} onClick={handleOnClose}>
                Cancel
              </Button>
              <Button
                type="primary"
                disabled={loading}
                onClick={handleSendEmail}
              >
                Send
              </Button>
            </Space>
          </>
        }
      >
        <div style={{ maxHeight: '70vh', overflowY: 'scroll' }}>
          <Spin spinning={loading}>
            <Form form={emailForm} layout="vertical">
              <EmailForm
                form={emailForm}
                docs={docs}
                has={{
                  from: false,
                  attachment: true,
                  showSelectAttachments: false,
                }}
              />
            </Form>
          </Spin>
        </div>
      </Modal>
    </>
  );
};

export default SendEmailModal;
