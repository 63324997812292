import React from 'react';
import { Form, FormInstance, Input, InputNumber, Select } from 'antd';
import { LockOutlined } from '@ant-design/icons';

interface Props {
  form: FormInstance;
  onSubmit: () => void;
}

export const DrawerForm: React.FC<Props> = ({ form, onSubmit }) => {
  return (
    <Form layout="vertical" form={form} onFinish={onSubmit}>
      <Form.Item name="id" noStyle>
        <Input hidden />
      </Form.Item>
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Please Input Name' }]}>
        <Input placeholder="Enter name" />
      </Form.Item>
      <Form.Item
        label="Company"
        name="company"
        rules={[{ required: true, message: 'Please Input Company' }]}>
        <Input placeholder="Enter company" />
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        rules={[
          { type: 'email', required: true, message: 'Please Input Email' },
        ]}>
        <Input placeholder="Enter email" />
      </Form.Item>
      <Form.Item
        label="Status"
        name="status"
        rules={[{ required: true, message: 'Please Select Status' }]}>
        <Select value={form.getFieldValue('status') || 0}>
          <Select.Option value={0}>PENDING</Select.Option>
          <Select.Option value={2}>ENABLED</Select.Option>
          <Select.Option value={-1}>DISABLED</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="password" label="Password">
        <Input.Password
          placeholder="Enter password"
          prefix={<LockOutlined />}
        />
      </Form.Item>
      <Form.Item name="password_confirmation" label="Password Confirmation">
        <Input.Password
          placeholder="Enter password confirmation"
          prefix={<LockOutlined />}
        />
      </Form.Item>
      <Form.Item name="billing_address" label="Billing Address">
        <Input.TextArea
          value={form.getFieldValue('billing_address')}
          rows={4}
          placeholder="Enter billing address"
        />
      </Form.Item>
    </Form>
  );
};
