import React, { useState, useEffect } from 'react';
import mapboxgl, { LngLatBoundsLike } from 'mapbox-gl';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

mapboxgl.accessToken = MAPBOX_API_KEY;

const Map: React.FC<{
  source: any[];
  maxBounds?: LngLatBoundsLike | undefined;
  height: string;
}> = ({ source, maxBounds, height = '320px' }) => {
  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const [markers, setMarkers] = useState<any[]>([]);

  useEffect(() => {
    const initializeMap = () => {
      const map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/streets-v11',
        center: {
          lat: 34.0148,
          lng: -118.191,
        }, // Set the initial map center
        zoom: 1, // Set the initial map zoom level,
        maxBounds,
      });

      map.addControl(new mapboxgl.FullscreenControl());

      setMap(map);
    };

    if (!map) {
      initializeMap();
    }

    return () => {
      if (map) {
        map.remove();
      }
    };
  }, [map]);

  const createElementFromHTML = (htmlString: string) => {
    const div = document.createElement('div');
    div.innerHTML = htmlString.trim();

    return div;
  };
  const toMaker = (text: string) => {
    const html = `<div style="max-width: 64px; display: flex; justify-content: center; align-items: center; padding: 3px 5px; background: rgba(12, 37, 86, 0.9); box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25); backdrop-filter: blur(1px); border-radius: 5px;">
    <div style="margin-left: 5px; font-style: normal;font-weight: normal;font-size: 14px;line-height: 150%;color: #FFFFFF;">
        ${text}
    </div>
</div>
    `;

    return createElementFromHTML(html) || undefined;
  };

  const createPopup = (marker: any, content: string) => {
    return new mapboxgl.Popup({ closeButton: true, closeOnClick: true })
      .setLngLat(marker.getLngLat())
      .setHTML(content);
  };

  useEffect(() => {
    if (map) {
      markers.forEach((marker) => marker.remove());
      const newMarkers: any[] = [];
      source.forEach((s: any) => {
        const lngLat = {
          lat: s.latitude,
          lng: s.longitude,
        };
        const markerElement: HTMLElement | undefined = toMaker(s.title);
        const newMarker = new mapboxgl.Marker(markerElement)
          .setPopup(
            new mapboxgl.Popup({ offset: 25 }) // add popups
              .setHTML('<div><strong>' + s.content + '</strong></div>'),
          )
          .setLngLat(lngLat)
          .addTo(map);

        const popupContent = `<h3>${s.content}</h3>`;
        const popup = createPopup(newMarker, popupContent);
        markerElement &&
          markerElement.addEventListener('click', () => popup.addTo(map));
        newMarkers.push(newMarker);
      });
      setMarkers(newMarkers);
    }
  }, [map, source]);

  return <div id="map" style={{ height: height }} className="map-container" />;
};

export default Map;
