import styles from 'res/css/ui.scss';
import {
  Button,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Space,
  message,
} from 'antd';
import update from 'immutability-helper';

import React, { useEffect, useState } from 'react';
import { useApp } from '@/utils/useapp';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { TITLES } from '@/pages/setting/admin/components/data';
import { Filter as DispatchFilter } from '@/pages/dispatchs/components/Filter';
import dataKeys from '@/pages/dispatchs/components/Filter/data';

export type TFilter = {
  loading: boolean;
  initShow: boolean;
  activeFilters: any[] | [];
  onApply: (values: any) => void;
  setInitShow: (initShow: boolean) => void;
  onSearch: () => void;
};

export const Filter: React.FC<TFilter> = ({
  loading,
  initShow = false,
  activeFilters,
  onApply,
  setInitShow,
  onSearch,
}) => {
  const [show, setShow] = useState(false);
  const [isShowFilter, setIsShowFilter] = useState(false);

  const [dispatchFilters, setDispatchFilters] = React.useState<any[] | null>(
    null,
  );

  const handleChangeDispatchFilters = async (filters: any) => {
    setDispatchFilters(filters);
    onApply(filters);
  };

  useEffect(() => {
    if (initShow) {
      // setShow(true);
      // setInitShow(false);
    }
  }, [initShow]);

  return (
    <Row>
      <Col span={20}>
        <DispatchFilter
          show={isShowFilter}
          values={dispatchFilters || []}
          accountingMode={false}
          onChange={(v: any) => {
            handleChangeDispatchFilters(v);
          }}
          removeAll={() => handleChangeDispatchFilters([])}
          removeOne={(index: number) =>
            handleChangeDispatchFilters(
              update(activeFilters, { $splice: [[index, 1]] }),
            )
          }
          activeFilters={activeFilters || []}
          allowAttributes={Object.values(dataKeys)}
          hideFilter={() => setIsShowFilter(false)}
          loading={loading}
        />
      </Col>
      <Col
        span={4}
        style={{
          marginTop: isShowFilter ? '48px' : '0',
          marginBottom: isShowFilter ? '0' : '20px',
        }}
      >
        <Space>
          <Button
            size="small"
            onClick={() => {
              setIsShowFilter((isShowFilter) => !isShowFilter);
            }}
          >
            {isShowFilter ? 'Hide Filters' : 'Show Filters'}
          </Button>
          <Button size="small" type="primary" onClick={onSearch}>
            Refresh
          </Button>
        </Space>
      </Col>
    </Row>
  );
};
