import { Typography } from 'antd';
import { useApp } from '@/utils/useapp';
import { copyToClipboard } from '@/utils/clipboard';
import { CopyButton } from '@/components/CopyButton';

interface CarrierQuickLinkProps {
  carrierId?: number;
  number?: string;
  children?: React.ReactNode;
}

const CarrierQuickLink: React.FC<CarrierQuickLinkProps> = ({
  carrierId,
  number,
  children,
}) => {
  const app = useApp();

  const handleClick = async () => {
    if (!carrierId || !number) {
      return;
    }

    copyToClipboard(number, 'Failed to copy number');

    try {
      const resp = await app.service.get(`tl/ltl/carriers/${carrierId}`);
      const carrier = resp.data;
      if (!carrier.quick_link) {
        return;
      }

      const url =
        carrier.quick_link.includes('$') && number
          ? carrier.quick_link.replace('$', number)
          : carrier.quick_link;

      window.open(url, '_blank');
    } catch (e) {
      console.error('Failed to open carrier quick link:', e);
    }
  };

  return (
    <div>
      <Typography.Link onClick={handleClick}>
        {children || number}
      </Typography.Link>
      {number && <CopyButton value={number} />}
    </div>
  );
};

export default CarrierQuickLink;
