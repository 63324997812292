export const RESCHEDULED_DUE_TO_WAREHOUSE = 1;
export const RESCHEDULED_DUE_TO_CUSTOMER = 2;
export const RESCHEDULED_DUE_TO_DRAYEASY = 3;
export const RESCHEDULED_DUE_TO_TRUCKER = 4;
export const RESCHEDULED_DUE_TO_TERMINAL = 5;

export const RESCHEDULED_DUE_TO_MAP = {
  [RESCHEDULED_DUE_TO_WAREHOUSE]: 'Warehouse',
  [RESCHEDULED_DUE_TO_TRUCKER]: 'Trucker',
  [RESCHEDULED_DUE_TO_DRAYEASY]: 'Drayeasy',
  [RESCHEDULED_DUE_TO_TERMINAL]: 'Terminal',
  [RESCHEDULED_DUE_TO_CUSTOMER]: 'Customer',
};
