import { useApp } from '@/utils/useapp';
import { Drawer, Space, Button, message, Typography, Modal } from 'antd';
import { FormInstance } from 'antd/es/form';
import _, { get, isNumber } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { OrderForm } from './OrderForm';
import { MblNumberDuplicateModal } from './MblNumberDuplicateModal';
import { ApplyToContainersModal } from './ApplyToContainersModal';
import {
  OpenBuyRateOrSellRateDrawer,
  OPEN_TYPE_BUY_RATE,
  OPEN_TYPE_SELL_RATE,
} from '@/components/Rate/OpenBuyRateOrSellRateDrawer';
import { TContainer } from '@/types';

const COPY_ATTRIBUTES = [
  'user_id',
  'contact_emails',
  'warehouse',
  'delivery_address',
  'final_port_id',
  'port_of_discharge_id',
  'destination_type',
];

export class ServiceWrapper {
  _url: string;
  _data: any;
  _proxy: any;
  _extra: any;
  _validator: (() => void) | undefined;

  constructor(url: string, data: object, proxy: any) {
    this._url = url;
    this._data = data;
    this._proxy = proxy;
  }

  set extra(obj: any) {
    this._extra = obj;
  }

  set validator(validator: () => void) {
    this._validator = validator;
  }

  run() {
    if (!this._data || !this._url || !this._proxy) {
      return;
    }

    this._validator && this._validator();

    return this._proxy(this._url, {
      data: {
        ...this._data,
        ...this._extra,
      },
    });
  }
}

export const OrderDrawer: React.FC<{
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isCopy?: boolean;
  orderId: number;
  refreshOrder?: any;
  handelAdded?: () => void;
}> = ({
  visible,
  setVisible,
  orderId,
  refreshOrder,
  handelAdded,
  isCopy = false,
}) => {
  const app = useApp();
  const [form] = useForm();

  const [loading, setLoading] = React.useState(false);
  const [applying, setApplying] = React.useState(false);
  const [showDuplicate, setShowDuplicate] = React.useState(false);
  const [order, setOrder] = React.useState();

  const [showApplyToContainer, setShowApplyToContainer] = useState(false);
  const [service, setService] = useState<ServiceWrapper | null>(null);

  const [openRate, setOpenRate] = React.useState(false);
  // const [rateId, setRateId] = React.useState(0);
  const [container, setContainer] = React.useState<TContainer | null>(null);
  const [openType, setOpenType] = React.useState<
    'BUY_RATE' | 'SELL_RATE' | undefined
  >(OPEN_TYPE_BUY_RATE);

  const onClose = () => {
    setVisible(false);
    form.resetFields();
  };
  const fetchOrder = async () => {
    if (!orderId) {
      return;
    }

    setLoading(true);
    try {
      const resp = await app.service.get(`orders/${orderId}`);
      const _order = resp.data;
      _order.containers = _order.containers.map((c: any) => {
        const rate_uid = c.sell_rate?.rate_uid;
        // delete c.sell_rate;
        return {
          ...c,
          sell_rate_id: rate_uid,
        };
      });

      if (isCopy) {
        console.log({ ..._.pick(_order, COPY_ATTRIBUTES) });

        form.setFieldsValue({
          ..._.pick(_order, COPY_ATTRIBUTES),
        });
        return;
      }

      setOrder(_order.data);
      form.setFieldsValue({ ..._order });
    } catch (err: any) {
      message.error(err?.data?.error ?? 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (force = false) => {
    const data = await form.validateFields();

    setLoading(true);
    const formValue = Object.assign({}, data);
    formValue.consignee_id = get(formValue, 'consignee.id', 0);
    formValue.terminal_id = get(formValue, 'terminal.id', 0);
    formValue.warehouse_id = get(formValue, 'warehouse.id', 0);

    delete formValue.consignee;
    delete formValue.terminal;
    delete formValue.warehouse;

    if (force) {
      formValue.force = true;
    }

    // for (const key in formValue) {
    //   console.log("formValue", key,formValue[key])
    //   if (!formValue[key]) {
    //     delete formValue[key];
    //   }
    // }

    try {
      !data.id
        ? await app.service.post('orders', { data: formValue })
        : await app.service.put(`orders/${orderId}`, { data: formValue });

      message.success('Saved');

      if (refreshOrder && data.id) {
        refreshOrder(data.id);
      } else if (undefined !== handelAdded) {
        handelAdded();
      }

      onClose();
    } catch (error: any) {
      const emsg = error.data.message || error.data.error;
      if (emsg == 'MBL_NUMBER_DUPLICATE') {
        // setShowForceSaveModal(true);
        forceSaveModal(data.mbl_number);
        return;
      }

      message.error(emsg);
    } finally {
      setLoading(false);
    }
  };

  const forceSaveModal = (mblNumber: string) => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: 'Duplicated MBL Number ' + mblNumber,
      content: 'Are you sure to proceeded?',
      onOk() {
        handleSave(true);
      },
      okText: 'Continue',
      onCancel() {
        Modal.destroyAll();
      },
    });
  };

  const onSaving = () => {
    handleSave();
    // const mblNumber = form.getFieldValue('mbl_number');
    // try {
    //   const resp = await app.service.get('orders/checkMblNumberDuplicate', {
    //     params: {
    //       id: form.getFieldValue('id'),
    //       mbl_number: mblNumber,
    //     },
    //   });
    //   if (resp.isDuplicate) {
    //     setShowDuplicate(true);
    //   } else {
    //     handleSave();
    //   }
    // } catch (err: any) {
    //   message.error(err.data?.message || err.data?.error);
    // }
  };

  const handleCloseDuplicate = () => {
    setShowDuplicate(false);
  };

  const handleContinue = () => {
    setShowDuplicate(false);
    handleSave();
  };

  // const onAppliedToAllContainers = () => {
  //   const id = form.getFieldValue('id');
  //   if (refreshOrder && id) {
  //     refreshOrder(id);
  //   }
  // };

  // const handleOpenBuyRate = (rateId: string) => {
  //   setOpenRate(true);
  //   setRateId(rateId);
  //   setOpenType(OPEN_TYPE_BUY_RATE);
  // };

  const handleOpenSellRate = (container: TContainer) => {
    setOpenRate(true);
    setContainer(container);
    setOpenType(OPEN_TYPE_SELL_RATE);
  };

  const handleCloseRate = () => {
    setOpenType(OPEN_TYPE_BUY_RATE);
    setContainer(null);
    setOpenRate(false);
  };

  const handleOnApplyToContainers = (service: ServiceWrapper) => {
    setShowApplyToContainer(true);
    setService(service);
  };
  const handleCloseApplyToContainers = () => {
    setShowApplyToContainer(false);
  };
  const handleOnAppliedToContainers = async (ids: number[]) => {
    setApplying(true);
    if (service && ids.length > 0) {
      service.extra = { containerIds: ids };
      try {
        await service.run();
        message.success('Saved');
        const id = form.getFieldValue('id');
        if (refreshOrder && id) {
          refreshOrder(id);
        }
        fetchOrder();
        setShowApplyToContainer(false);
      } catch (error: any) {
        message.error(error.data.message || error.data.error);
      } finally {
        setApplying(false);
      }
    }
  };

  React.useEffect(() => {
    orderId && visible && fetchOrder();
  }, [orderId, visible]);

  const handleUpdateOrder = (order: any) => {
    setOrder(order);
  };

  return (
    <>
      <Drawer
        title=""
        placement={'right'}
        width="95%"
        onClose={onClose}
        destroyOnClose={true}
        open={visible}
        extra={
          <Space>
            <Button onClick={onClose}>Close</Button>
            <Button type="primary" disabled={loading} onClick={onSaving}>
              Save
            </Button>
          </Space>
        }
      >
        <OrderForm
          form={form}
          handleOpenSellRate={handleOpenSellRate}
          onApplyToContainers={handleOnApplyToContainers}
          updateOrder={handleUpdateOrder}
        />
      </Drawer>
      {showDuplicate && (
        <MblNumberDuplicateModal
          open={showDuplicate}
          handleContinue={handleContinue}
          onClose={handleCloseDuplicate}
        />
      )}

      {openRate && (
        <OpenBuyRateOrSellRateDrawer
          open={openRate}
          type={openType}
          rateUid={get(container, 'sell_rate_id')}
          snapshotableId={container.id}
          snapshotableType="container"
          hasBanModifyFields={true}
          onClose={handleCloseRate}
        />
      )}

      {showApplyToContainer && orderId && (
        <ApplyToContainersModal
          open={showApplyToContainer}
          applying={applying}
          containers={form
            .getFieldValue('containers')
            ?.filter((c: any) => c.id)}
          onAppliedToContainers={handleOnAppliedToContainers}
          onClose={handleCloseApplyToContainers}
        />
      )}
    </>
  );
};
