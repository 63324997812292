import React from 'react';
import { DatePicker, Form, FormInstance, Input, Switch } from 'antd';

import moment from 'moment';

interface Props {
  form: FormInstance;
  onSubmit: () => void;
}

export const CardForm: React.FC<Props> = ({ form, onSubmit }) => {
  return (
    <Form layout={'vertical'} form={form} onFinish={onSubmit}>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue, setFieldsValue }) => (
          <Form.Item
            name="blocked"
            label="Block Option"
            initialValue={false}
            valuePropName="checked">
            <Switch
              checked={getFieldValue('blocked')}
              onChange={(checked) =>
                checked === false
                  ? setFieldsValue({
                    blocked: checked,
                    time: null,
                  })
                  : setFieldsValue({
                    blocked: checked,
                  })
              }
            />
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item name="time" noStyle>
        <Input hidden />
      </Form.Item>
      <Form.Item label="Block All Data Until" shouldUpdate>
        {({ getFieldValue, setFieldsValue }) => {
          const time = getFieldValue('time');
          console;
          return (
            <DatePicker
              value={time ? moment(time) : null}
              onChange={(value) =>
                setFieldsValue({
                  time: value ? value?.format('YYYY-MM-DD') : null,
                })
              }
              style={{ width: '100%' }}
            />
          );
        }}
      </Form.Item>
    </Form>
  );
};
