import React, { FC, useEffect, useState } from 'react';
import { get } from 'lodash';
import { MinusCircleOutlined } from '@ant-design/icons';
import {
  CONTAINER_HISTORY_TROUBLE_STATUES,
  CONTAINER_TROUBLE_STATUES,
} from './data';
import { Checkbox, message, Popover, Space } from 'antd';
import { useApp } from '@/utils/useapp';
import moment from 'moment-timezone';

const TroubleStatusesHistory: FC<{ troubleStatusHistory: any[] }> = ({
  troubleStatusHistory,
}) => {
  return (
    <>
      {troubleStatusHistory.map((history, index) => (
        <div key={index}>
          {moment(history.created_at).format('YYYY-MM-DD HH:mm:ss') +
            ' ' +
            CONTAINER_HISTORY_TROUBLE_STATUES[
              (`history_${history.status_key}` as unknown) as keyof typeof CONTAINER_HISTORY_TROUBLE_STATUES
            ] +
            ' was ' +
            history?.action_name +
            ' by ' +
            history?.operator?.name}
        </div>
      ))}
    </>
  );
};

const TroubleStatuses: FC<{
  container: any;
  onChange: (e: any) => void;
  onContainerChange: (e: any) => void;
  onRemoveHistory: (id: number, data: any) => void;
  disabled?: boolean;
}> = ({
  container,
  onChange,
  onRemoveHistory,
  disabled = false,
  onContainerChange,
}) => {
  const [troubleStatusHistory, setTroubleStatusHistory] = useState<any[]>([]);
  const handleGetTroubleStatusHistory = async () => {
    try {
      const resp = await useApp().service.get(
        `containers/${container.id}/troubleStatusHistory`,
      );
      setTroubleStatusHistory(resp.data);
      // onUpdated(container.id, resp.data);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    }
  };

  useEffect(() => {
    handleGetTroubleStatusHistory();
  }, [container]);
  return (
    <>
      <div>
        <b>Current</b>
      </div>
      {Object.keys(CONTAINER_TROUBLE_STATUES).map((field, index) => (
        <div key={index}>
          {!get(container, 'trouble_status.history_' + field) && (
            <div>
              <Checkbox
                id={`trouble-status-default-container-attributes-${field}`}
                checked={!!get(container, 'trouble_status.' + field)}
                name={field}
                onChange={onChange}
                disabled={disabled}
              >
                {
                  CONTAINER_TROUBLE_STATUES[
                    (field as unknown) as keyof typeof CONTAINER_TROUBLE_STATUES
                  ]
                }
              </Checkbox>
            </div>
          )}
        </div>
      ))}
      <div>
        <Checkbox
          checked={!!get(container, 'is_diversion')}
          name="is_diversion"
          onChange={onContainerChange}
          disabled={disabled}
        >
          Diversion
        </Checkbox>
      </div>
      <hr />
      <div>
        <b>History</b>
      </div>
      <TroubleStatusesHistory troubleStatusHistory={troubleStatusHistory} />
      {/* {Object.keys(CONTAINER_HISTORY_TROUBLE_STATUES).map((field, index) => (
        <div key={index}>
          {!!get(container, 'trouble_status.' + field) && (
            <Space>
              <span>
                {
                  CONTAINER_HISTORY_TROUBLE_STATUES[
                    field as unknown as keyof typeof CONTAINER_HISTORY_TROUBLE_STATUES
                  ]
                }
              </span>
              {!!onRemoveHistory && (
                <MinusCircleOutlined
                  onClick={() => {
                    onRemoveHistory(container.id, field);
                  }}
                />
              )}
            </Space>
          )}
        </div>
      ))} */}
    </>
  );
};

const TroubleStatusesPopover: FC<{
  container: any;
  onUpdated: (id: number, data: any) => void;
}> = ({ container, onUpdated }) => {
  const app = useApp();
  const [open, setOpen] = useState(false);
  const handleChange = async (e: any) => {
    const { name, type, value, checked } = e.target;
    try {
      const resp = await app.service.patch(
        `containers/${container.id}/updateTroubleStatus`,
        {
          data: { field: name, value: type === 'checkbox' ? checked : value },
        },
      );
      onUpdated(container.id, resp.data);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    }
  };

  const handleContainerChange = async (e: any) => {
    const { name, type, value, checked } = e.target;
    try {
      const resp = await app.service.patch(`containers/${container.id}`, {
        data: { [name]: type === 'checkbox' ? checked : value },
      });
      onUpdated(container.id, resp.data);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    }
  };

  const handleRemoveHistory = async (id: number, field: string) => {
    try {
      const resp = await app.service.patch(
        `containers/${id}/removeHistoryTroubleStatus`,
        {
          data: { field },
        },
      );
      onUpdated(id, resp.data);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    }
  };

  const troubles = React.useMemo(
    () =>
      Object.keys(CONTAINER_TROUBLE_STATUES).filter(
        (field: string) =>
          container.trouble_status && container.trouble_status[field],
      ),
    [container.trouble_status],
  );
  const firstTroubleName = React.useMemo(
    () =>
      troubles.length > 0
        ? CONTAINER_TROUBLE_STATUES[
            (troubles[0] as unknown) as keyof typeof CONTAINER_TROUBLE_STATUES
          ]
        : null,
    [troubles],
  );
  const troubleLength = React.useMemo(() => troubles.length, [troubles]);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <Popover
      content={
        <TroubleStatuses
          container={container}
          onChange={handleChange}
          onContainerChange={handleContainerChange}
          onRemoveHistory={handleRemoveHistory}
        />
      }
      title="Trouble Status"
      trigger="click"
      placement="left"
      visible={open}
      onOpenChange={handleOpenChange}
    >
      <div className="cursor-pointer">
        {troubleLength > 0 ? (
          <>
            {firstTroubleName}
            {troubleLength > 1 && `+${troubleLength - 1}`}
          </>
        ) : (
          '-'
        )}
      </div>
    </Popover>
  );
};

export default TroubleStatusesPopover;
