import React from 'react';
import { ModelSelect } from './ModelSelect';
import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
// import { DrawerForm as VendorForm } from '@/pages/entity/vendors/components/DrawerForm';
import { VendorDrawer } from '@/pages/entity/vendors/components/VendorDrawer';
import { VendorVerified } from '@/pages/entity/vendors/components/VendorVerified';

export const VendorSelect: React.FC<{
  size?: string;
  selected: any;
  onSelect: (id: string) => void;
  className?: string;
  editable?: boolean;
  multiple?: boolean;
  withPossibleCharges?: boolean;
  style?: React.CSSProperties;
  mode?: string;
  maxTagCount?: string;
  placeholder?: string;
}> = ({
  selected,
  onSelect,
  editable = false,
  multiple = false,
  withPossibleCharges = false,
  style,
  ...props
}) => {
  const [id, setId] = React.useState(null);
  const [visible, setVisible] = React.useState(false);
  const handleSave = (data: any) => {
    onSelect(data);
    setVisible(false);
    setId(null);
  };

  const handleClose = () => {
    setId(null);
    setVisible(false);
  };

  const modelSelectContent = (
    <ModelSelect
      {...props}
      // style={{ minWidth: '15.6rem', ...style }}
      style={style}
      searchParams={
        withPossibleCharges ? { with: ['possibleCharges', 'specialTerms'] } : {}
      }
      className={
        editable
          ? 'select-modal-with-icon-width'
          : 'select-modal-without-icon-width'
      }
      selected={selected}
      onSelect={onSelect}
      multiple={multiple}
      dropdownStyle={{ minWidth: '45vw', maxWidth: '55vw' }}
      renderOption={(vendor: any) => {
        return (
          <>
            {vendor.blacklisted && (
              <strong className="text-danger">[Blacklisted]</strong>
            )}
            <VendorVerified
              disabled={true}
              vendor={vendor}
              onSaved={() => handleSave(vendor)}
            />
            {/* {vendor.verified ? (
              <strong className="text-success">[Verified]</strong>
            ) : (
              <strong className="text-danger">[Not Verified]</strong>
            )} */}
            {vendor.name}
          </>
        );
      }}
      model="vendors"
    />
  );

  return (
    <>
      {editable ? (
        <div className="flex">
          <Button
            onClick={() => {
              setId(selected?.id);
              setVisible(true);
            }}
            className="ant-btn-icon-only select-modal-icon">
            <EditOutlined
              style={{
                color: '#00000073',
              }}
            />
          </Button>
          {modelSelectContent}
        </div>
      ) : (
        modelSelectContent
      )}

      <VendorDrawer
        id={id}
        onSaved={handleSave}
        onClose={handleClose}
        visible={visible}
      />
    </>
  );
};
