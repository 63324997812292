import React from 'react';

export const HourFormat: React.FC<{
  possibleCharge: any;
}> = ({ possibleCharge }) => {
  return (
    <small>
      ${(+possibleCharge.rate).toFixed(2)}/HR{' '}
      {possibleCharge.range?.free ? `${possibleCharge.range.free} HR Free` : ''}
    </small>
  );
};
