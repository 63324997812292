import { Col, Input, Row, Form } from 'antd';

import React, { useEffect, useState } from 'react';

const RATIO = 0.45359237;

export const calculateLBSByKG = (value: any) => {
  return formatValue(value / RATIO);
};

export const calculateKGByLBS = (value: any) => {
  return formatValue(value * RATIO);
};

const formatValue = (value: any) => {
  return parseFloat(value).toFixed(2);
};

export const Weight: React.FC<{
  weight: number;
  size?: string;
  onChange?: (value: number | string) => void;
}> = ({ weight, onChange, size = 'normal' }) => {
  const [weightKg, setWeightKg] = useState<number | string>(weight);
  const [weightLbs, setWeightLbs] = useState<number | string>(
    weight ? calculateLBSByKG(weight) : '',
  );

  useEffect(() => {
    if (weight == weightKg) {
      return;
    } else {
      setWeightKg(weight);
      setWeightLbs(weight ? calculateLBSByKG(weight) : '');
    }
  }, [weight, weightKg]);

  const handleOnChangeKG = (e: any) => {
    const lbs = calculateLBSByKG(e.target.value);
    setWeightKg(e.target.value);
    setWeightLbs(lbs);
    if (onChange) {
      onChange(e.target.value);
    }
  };

  const handleOnChangeLBS = (e: any) => {
    const kg = calculateKGByLBS(e.target.value);
    setWeightKg(kg);
    setWeightLbs(e.target.value);
    if (onChange) {
      onChange(kg);
    }
  };

  return (
    <Input.Group compact size={size}>
      <Input
        type="number"
        style={{ width: '50%' }}
        value={weightKg}
        onChange={handleOnChangeKG}
        addonAfter="KG"
      />
      <Input
        type="number"
        style={{ width: '50%' }}
        value={weightLbs}
        onChange={handleOnChangeLBS}
        addonAfter="LBS"
      />
    </Input.Group>
  );
};
