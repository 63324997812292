import SyncCacheModelSelect, {
  SyncCacheModelSelectProps,
} from '@/components/SyncCacheModelSelect';
import { CACHE_INTERMODALREGION_OCEAN_SELECT_KEY } from '@/stores/cache';
import { CSSProperties, memo, useCallback, useMemo, useState } from 'react';
import { get } from 'lodash';
import { EditOutlined } from '@ant-design/icons';
import { Button, Tooltip, Typography } from 'antd';

const selectStyle: CSSProperties = {
  width: '100%',
};

type EditableIntermodalRegionOceanSelectColumnProps = SyncCacheModelSelectProps & {
  data: any[];
  record: any;
  path: string[];
};

const EditableIntermodalRegionOceanSelectColumn = memo(
  ({
    style,
    data,
    record,
    path,
    onSelect,
    onBlur,
    ...props
  }: EditableIntermodalRegionOceanSelectColumnProps) => {
    const [isEditing, setIsEditing] = useState(false);
    const handleEdit = () => {
      setIsEditing(true);
    };

    const value = get(record, path, undefined);
    const name = data?.find((item) => item.id === value)?.name;

    const _style = useMemo(() => ({ ...selectStyle, ...style }), [style]);

    const handleSelect: SyncCacheModelSelectProps['onSelect'] = useCallback(
      (model) => {
        onSelect?.(model);
        setIsEditing(false);
      },
      [onSelect],
    );

    const handleBlur: SyncCacheModelSelectProps['onBlur'] = useCallback(
      (event) => {
        onBlur?.(event);
        setIsEditing(false);
      },
      [onBlur],
    );

    if (!isEditing) {
      if (!name) {
        return (
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={handleEdit}
          ></Button>
        );
      }

      return (
        <Tooltip placement="topLeft" title={name}>
          <Typography.Link underline onClick={handleEdit}>
            {name}
          </Typography.Link>
        </Tooltip>
      );
    }

    return (
      <SyncCacheModelSelect
        dropdownMatchSelectWidth={false}
        {...props}
        style={_style}
        autoFocus
        open
        onSelect={handleSelect}
        onBlur={handleBlur}
        value={value}
        cacheKey={CACHE_INTERMODALREGION_OCEAN_SELECT_KEY}
      />
    );
  },
);

EditableIntermodalRegionOceanSelectColumn.displayName =
  'EditableIntermodalRegionOceanSelectColumn';

export default EditableIntermodalRegionOceanSelectColumn;
