import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Space,
  Spin,
  Table,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { TSorter } from '@/types';
import { NavTabs } from '@/components/NavTabs';
import moment from 'moment';
import Map from './components/Map';
import Teams from './components/Team';
import { StatusTable } from './components/StatusTable';
import { Filter } from './components/Filter';
import OverdueReport from './components/OverdueReport';

const DISPATCH_STATUS_OVERDUE = 'Overdue';

const DispatchStatus: React.FC<{ data: any[] }> = ({ data }) => {
  return (
    <>
      <Table
        size="small"
        pagination={false}
        columns={[
          {
            title: 'Status',
            dataIndex: 'dispatch_status_name',
            key: 'dispatch_status_name',
          },
          {
            title: 'Time',
            dataIndex: 'created_at',
            key: 'created_at',
          },
        ]}
        dataSource={data}
      />
    </>
  );
};

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [teamLoading, setTeamLoading] = useState(false);
  const [data, setData] = useState<any>({});
  const [admins, setAdmins] = useState<any[]>([]);

  const [activeKey, setActiveKey] = useState('');
  const [statesCount, setStatesCount] = useState<any>({});
  const [filter] = useForm();
  const [irAnalysis, setIrAnalysis] = useState([]);

  // const status = useWatch('dispatch_status', filter);

  const fetchData = React.useCallback(
    async (pagination?: TablePaginationConfig, sorter: TSorter = {}) => {
      setLoading(true);

      const _filter = Object.assign({}, filter.getFieldsValue());

      try {
        const resp = await app.service.get('dispatchDashboard', {
          params: {
            ..._filter,
            ...sorter,
          },
        });

        setStatesCount(resp.data);
        setActiveKey(resp.data[0]);
      } catch (err: any) {
        message.error(err.data?.message || err.data?.error);
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const fetchTeam = async () => {
    setTeamLoading(true);
    try {
      const resp = await app.service.get('dispatchDashboard/teams');
      setAdmins(resp.data);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setTeamLoading(false);
    }
  };

  const fetchIrAnalysis = useCallback(async () => {
    try {
      const _filter = Object.assign({}, filter.getFieldsValue());
      const resp = await app.service.get('dispatchDashboard/irAnalysis', {
        params: {
          ..._filter,
          status:
            filter.getFieldValue('dispatch_status') || DISPATCH_STATUS_OVERDUE,
        },
      });

      setIrAnalysis(resp.data);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    }
  }, [activeKey]);

  const handleCommentSaved = async (id: number) => {
    fetchIrAnalysis();
  };

  useEffect(() => {
    fetchTeam();
    fetchData();
  }, []);

  useEffect(() => {
    fetchIrAnalysis();
  }, [activeKey]);

  const handleTabChange = (key: string) => {
    filter.setFieldsValue({
      dispatch_status: key,
    });
    setActiveKey(key);
    // document
    //   .getElementById(key)
    //   ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const search = () => {
    fetchData();
    fetchIrAnalysis();
  };

  return (
    <div className={styles.main}>
      <Header title="Dispatch DashBoard" />
      {/* <div className={styles.filter}>
        <Form
          initialValues={{
            start_ir_eta: moment().subtract(10, 'days').format('YYYY-MM-DD'),
            end_ir_eta: moment().add(10, 'days').format('YYYY-MM-DD'),
          }}
          layout="vertical"
          form={filter}>

          <Row gutter={16}>

          </Row>
        </Form>
      </div> */}

      <div className="flex">
        <Filter filter={filter} loading={loading} onSearch={search} />
        <div style={{ marginTop: '2.4rem', marginLeft: '4.5rem' }}>
          <OverdueReport filter={filter} />
        </div>
      </div>

      <NavTabs
        items={statesCount}
        activeKey={activeKey}
        onChange={handleTabChange}
      />

      <div
        style={{
          height: '80%',
          overflow: 'scroll',
        }}>
        <Row
          style={{
            // height: '30%',
            margin: '0px',
          }}
          gutter={12}>
          <Col span={8}>
            <Map source={irAnalysis || []} />
          </Col>
          <Col span={16}>
            <Teams admins={admins} loading={teamLoading} />
          </Col>
        </Row>

        {loading && (
          <div className="flex h100 justify-center align-center">
            <Spin />
          </div>
        )}

        {!loading && (
          <StatusTable
            filter={filter}
            status={
              filter.getFieldValue('dispatch_status') || DISPATCH_STATUS_OVERDUE
            }
            handleCommentSaved={handleCommentSaved}
          />
        )}
      </div>
    </div>
  );
};

export default Index;
