import { Space, Tag } from 'antd';
import React, { useMemo } from 'react';
import actionUtil, {
  TTarget,
  TARGET_TYPE_FTL_SHIPMENT,
} from '../../Interfaces/ActionInterface';
import { ActionIcon } from './ActionIcon';
import OperationLogs from '../OperationLogs';
import { WhoseTaskTag } from './WhoseTaskTag';

const DefaultTaskColumn: React.FC<TTarget & { showLog: boolean }> = ({
  task,
  targetModel: ftlShipment,
  showLog = true,
}) => {
  const operation = useMemo(() => task?.operation, [task]);

  return (
    <Space size={'small'}>
      <ActionIcon operation={operation} />
      <div>
        <WhoseTaskTag whoseTask={task?.whose_task} />
        <span
          className={[
            task?.sequential_execution ? 'text-primary' : '',
            task?.is_filter_status ? 'text-line-through' : '',
          ].join(' ')}
        >
          {/* {task?.sop ? (
            // <SOPLink
            //   title={task.name}
            //   // settingContainerTaskId={task.setting_container_task_id}
            //   color={
            //     task.text_color
            //       ? `text-${
            //         TEXT_COLOR_MAP[
            //           task.text_color as unknown as keyof typeof TEXT_COLOR_MAP
            //         ]
            //       }`
            //       : ''
            //   }
            // />
            <></>
          ) : (
            task?.name
          )} */}

          {task?.name}
        </span>
      </div>

      {operation && operation.action in actionUtil.ACTION_COLORS && (
        <Tag
          color={
            actionUtil.ACTION_COLORS[
              operation.action as keyof typeof actionUtil.ACTION_COLORS
            ]
          }
        >
          {operation.action}
        </Tag>
      )}

      {showLog && (
        <>
          {!!operation?.action_by && (
            <small className="text-gray">{operation.action_by.name}</small>
          )}
          {!!operation?.action_by && (
            <small className="text-gray">{operation.action_at}</small>
          )}

          {operation?.id && (
            <OperationLogs
              target={TARGET_TYPE_FTL_SHIPMENT}
              targetModel={ftlShipment}
              operationId={operation?.id}
            />
          )}
        </>
      )}
    </Space>
  );
};

export default DefaultTaskColumn;
