import { CitySelect } from '@/components/CitySelect';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Space,
  Switch,
} from 'antd';
import React, { useState } from 'react';
import { PossibleCharges } from '@/components/PossibleCharges';
import { RateScenairoTable } from '@/pages/rates/rateSenarios/components/RateScenairoTable';
import { DrawerForm as RateScenairoForm } from '@/pages/rates/rateSenarios/components/DrawerForm';
import { FormInstance } from 'antd/lib/form';
import { useApp } from '@/utils/useapp';
import { IntermodalRegionSelect } from '@/components/IntermodalRegionSelect';
import { TPossibleCharge } from '@/types';
import { cache } from '@/stores';
import { CACHE_INTERMODALREGION_KEY } from '@/stores/cache';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { TIER_MAP } from './data';
import { TierRatesFormItems } from './TierRatesFormItems';

const CopyModal: React.FC<{
  onSelect: any;
}> = ({ onSelect }) => {
  const app = useApp();
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState<number | null>(null);

  const showModal = () => {
    setShow(true);
  };

  const handleCancel = () => {
    setShow(false);
  };

  const handleSelect = (id: number) => {
    setSelected(id);
  };

  const handleCopy = async () => {
    if (selected) {
      try {
        const resp = await app.service.get(`intermodalRegions/${selected}`);
        onSelect(
          resp.data.possible_charges.map((possibleChange) => ({
            ...possibleChange,
            display_rate: possibleChange.rate,
          })),
        );
        setShow(false);
      } catch (e: any) {
        message.error('Failed to copy');
      }
    }
  };
  return (
    <>
      <Button type="primary" onClick={showModal}>
        Copy Possible Charges
      </Button>
      <Modal
        title="Copy Possible Charges"
        // width={'80vw'}
        open={show}
        onOk={() => handleCopy()}
        okText={'Copy'}
        onCancel={handleCancel}>
        <IntermodalRegionSelect
          className="w100"
          value={selected}
          onChange={handleSelect}
        />
      </Modal>
    </>
  );
};
export const DrawerForm: React.FC<{
  form: FormInstance<any>;
  intermodalRegion: number;
  updateData: any;
  onRefresh: any;
}> = ({ form, intermodalRegion, onRefresh }) => {
  const handleChange = async (event: any) => {
    const { name, value } = event.target as HTMLInputElement;
    await form.setFieldsValue({ [name]: value });
  };

  const [showRateScenarioForm, setShowRateScenarioForm] =
    useState<boolean>(false);
  const [rateScenarioId, setRateScenarioId] = useState<number>(0);
  const app = useApp();

  const hideRateScenario = () => {
    setShowRateScenarioForm(false);
    setRateScenarioId(0);
  };

  const handleSave = (data: any) => {
    const scenarios = [...form.getFieldValue('rate_scenarios')];
    const index = scenarios.indexOf(
      scenarios.find((scenario: any) => scenario.id === data.id),
    );
    if (index > -1) {
      scenarios[index] = data;
    } else {
      scenarios.push(data);
    }
    form.setFieldValue('rate_scenarios', scenarios);
    hideRateScenario();
    cache.fetch(CACHE_INTERMODALREGION_KEY, true);
    onRefresh && onRefresh();
  };

  const handleDelete = async (id: number) => {
    try {
      await app.service.delete(`rateScenarios/${id}`);
      const scenarios = [...form.getFieldValue('rate_scenarios')];
      const index = scenarios.indexOf(
        scenarios.find((scenario: any) => scenario.id === id),
      );
      scenarios.splice(index, 1);
      form.setFieldValue('rate_scenarios', scenarios);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    }

    onRefresh && onRefresh();
  };

  const handleCopyPossibleCharges = (possibleCharges: TPossibleCharge[]) => {
    form.setFieldValue(
      'possible_charges',
      possibleCharges.map(({ id, ...p }) => p),
    );
  };

  return (
    <div>
      <Form form={form}>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input placeholder="Enter Name" />
            </Form.Item>
            <Form.Item
              label="Active"
              name="active"
              valuePropName="checked"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Switch />
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <Form.Item
                  name="city"
                  label="City"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: true }]}>
                  <CitySelect
                    selected={getFieldValue('city')}
                    onSelect={(id) =>
                      handleChange({ target: { name: 'city', value: id } })
                    }
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item
              label="Latitude"
              name="latitude"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input placeholder="Enter Latitude" />
            </Form.Item>
            <Form.Item
              label="Longitude"
              name="longitude"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input placeholder="Enter Longitude" />
            </Form.Item>
            <TierRatesFormItems form={form} />
          </Col>
          <Col span={12}>
            <Form.Item
              label="Special notice for customer"
              name="special_notice"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input.TextArea rows={5} placeholder="Enter Special notice" />
            </Form.Item>
            <Form.Item
              label="Special notice for customer (Chinese)"
              name="special_notice_cn"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input.TextArea rows={5} placeholder="Enter Special notice" />
            </Form.Item>
            <Form.Item
              label="Memo"
              name="memo"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input.TextArea rows={5} placeholder="Enter Memo" />
            </Form.Item>
            <Form.Item
              label="Ocean Port"
              name="has_ocean_port"
              valuePropName="checked">
              <Checkbox />
            </Form.Item>
            <Form.Item
              label="Ramp Port"
              name="has_ramp_port"
              valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <Form.Item
                  name="possible_charges"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}>
                  <PossibleCharges
                    // rules
                    intermodal_region_id={intermodalRegion}
                    data={getFieldValue('possible_charges') || []}
                    updateData={(list) =>
                      handleChange({
                        target: { name: 'possible_charges', value: list },
                      })
                    }
                    extra={<CopyModal onSelect={handleCopyPossibleCharges} />}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
        </Row>
        {!!intermodalRegion && (
          <Row>
            <Col span={24}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <Form.Item
                    name="rate_scenarios"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}>
                    <RateScenairoTable
                      data={getFieldValue('rate_scenarios') || []}
                      onAdd={() => setShowRateScenarioForm(true)}
                      onEdit={(id) => {
                        setRateScenarioId(id);
                        setShowRateScenarioForm(true);
                      }}
                      onDelete={handleDelete}
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
      <RateScenairoForm
        id={rateScenarioId}
        intermodalRegionId={intermodalRegion}
        visible={showRateScenarioForm}
        onClose={hideRateScenario}
        onSave={handleSave}
      />
    </div>
  );
};
