import React from 'react';
import {
  Checkbox,
  DatePicker,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Select,
} from 'antd';
import { GeneralLedgerCodeSelect } from '@/components/GeneralLedgerCodeSelect';
import moment from 'moment';

interface Props {
  form: FormInstance;
  onSubmit: () => void;
}

const TYPE_OPTIONS = [
  { value: '', label: '' },
  { value: 1, label: 'SAVING' },
  { value: 3, label: 'CHECKING' },
  { value: 5, label: 'CREDIT CARD' },
  { value: 10, label: 'OTHER' },
];

const CURRENCY_OPTIONS = [
  { value: 'USD', label: 'USD' },
  { value: 'CNY', label: 'CNY' },
];

export const DrawerForm: React.FC<Props> = ({ form, onSubmit }) => {
  const handleChange = async (event: any) => {
    const { name, value } = event.target as HTMLInputElement;
    await form.setFieldsValue({ [name]: value });
  };

  return (
    <Form layout="vertical" form={form} onFinish={onSubmit}>
      <Form.Item name="id">
        <Input disabled />
      </Form.Item>
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Please Input name' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="Type"
        name="type"
        rules={[{ required: true, message: 'Please Select type' }]}>
        <Select>
          {TYPE_OPTIONS.map((option) => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="general_ledger_code_id"
        label="GL Code"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        rules={[{ required: true, message: 'Please Select GL Code' }]}>
        <GeneralLedgerCodeSelect />
      </Form.Item>
      <Form.Item
        label="Currency"
        name="currency"
        rules={[{ required: true, message: 'Please Select currency' }]}>
        <Select>
          {CURRENCY_OPTIONS.map((option) => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Initial Balance"
        name="initial_balance"
        rules={[{ required: true, message: 'Please Select initial balance' }]}>
        <InputNumber style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item name="last_time_balance_changed" noStyle>
        <Input hidden />
      </Form.Item>
      <Form.Item label="Last Time Balance Chang" shouldUpdate>
        {({ getFieldValue }) => {
          const last_time_balance_changed = getFieldValue(
            'last_time_balance_changed',
          );
          return (
            <DatePicker
              disabled
              style={{ width: '100%' }}
              value={
                last_time_balance_changed
                  ? moment(last_time_balance_changed)
                  : null
              }
            />
          );
        }}
      </Form.Item>
      <Form.Item label="Exchange Rate Cost" name="exchange_rate_cost">
        <InputNumber style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        label="Is Prepaid Account"
        name="is_prepaid_account"
        valuePropName="checked">
        <Checkbox></Checkbox>
      </Form.Item>
      <Form.Item name="memo" label="Memo">
        <Input.TextArea
          value={form.getFieldValue('memo')}
          rows={4}
          placeholder="Enter memo"
        />
      </Form.Item>
    </Form>
  );
};
