import { CitySelect } from '@/components/CitySelect';
import { IntermodalRegionSelect } from '@/components/IntermodalRegionSelect';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Space,
} from 'antd';
import React from 'react';
import { REQUESTED_RATE_STATES } from './data';

type Props = {
  filter: FormInstance;
  onSubmit: () => void;
  loading: boolean;
};

const STATE_OPTIONS = REQUESTED_RATE_STATES.map((m) => ({
  value: m,
  label: m,
}));

const Filter: React.FC<Props> = ({ filter, onSubmit, loading }) => {
  return (
    <div style={{ width: '100%' }}>
      <Form layout="vertical" form={filter} onFinish={onSubmit}>
        <Row gutter={8}>
          <Col span={4}>
            <Form.Item name="id" label="ID">
              <Input placeholder="ID" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="user_id" label="Customer">
              <UserSyncSelect />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="state" label="Status">
              <Select options={STATE_OPTIONS} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="intermodal_region_id" label="IR">
              <IntermodalRegionSelect />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => (
                <Form.Item name="to_city_id" label="City">
                  <CitySelect
                    selected={getFieldValue('to_city')}
                    onSelect={(city) => {
                      filter.setFieldsValue({
                        to_city: city,
                        to_city_id: city?.id,
                      });
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label={' '}>
              <Space>
                <Button type="primary" loading={loading} htmlType="submit">
                  Search
                </Button>
                <Button onClick={() => filter.resetFields()}>Clear All</Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Filter;
