import reactStringReplace from 'react-string-replace';

const LinkMap = {
  live_or_drop: {
    link: 'https://ue2fl6z2dmj.larksuite.com/wiki/R1WiwVm2mivPQkkiHfXuyg0fsdh',
    text: 'SOP for changing live to drop during the Operations',
  },
  warehouse_id: {
    link: 'https://ue2fl6z2dmj.larksuite.com/wiki/YBLRwzRXui5kbNk9Zvyu544pstc',
    text: 'Delivery address is changed during the operation ',
  },
};

const WithMentionHighlighter = ({
  content,
  highlighterColor = '#000005',
}: {
  content: string;
  highlighterColor?: string;
}) => {
  const regexp = /@\[(?<name>[\w\s.]+)\]\(\d+\)/gim;
  let node = reactStringReplace(content, regexp, (match, i) => (
    <strong
      key={i}
      style={{ backgroundColor: '#1dcae02e', color: highlighterColor }}
    >
      @{match}
    </strong>
  ));

  node = reactStringReplace(
    node,
    /link:\/\/<(?<name>[\w\s.]+)>/g,
    (match, i) => {
      return (
        <a
          key={i}
          target="_blank"
          href={LinkMap[match]?.link || match}
          rel="noreferrer"
        >
          {LinkMap[match]?.text}
        </a>
      );
    },
  );

  node = reactStringReplace(
    node,
    /##\[(?<name>[\w\s.,)(]+)\]##/gim,
    (match, i) => {
      return (
        <strong key={i} style={{ color: highlighterColor }}>
          ({match})
        </strong>
      );
    },
  );

  return (
    <span style={{ color: highlighterColor, whiteSpace: 'break-spaces' }}>
      {node}
    </span>
  );
};

export default WithMentionHighlighter;
