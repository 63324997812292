import React from 'react';
import { ModelSelect } from '@/components/ModelSelect';

export const CFSLocationSelect: React.FC<{
  selected: any;
  onSelect: (model: any) => void;
  className?: string;
  dropdownStyle?: any;
}> = ({ selected, onSelect, ...props }) => {
  return (
    <ModelSelect
      {...props}
      style={{ width: 200 }}
      selected={selected}
      onSelect={onSelect}
      isPathParams={false}
      notFoundContent={<>No matches found</>}
      renderOption={(option: any) => {
        return (
          <>
            <strong>
              {option.name ? `${option.name}(${option.firms_code})` : ''}
            </strong>
            <br />
            <small className="text-gray">
              {option.alias ? `Alias: ${option.alias}` : ''}
            </small>
          </>
        );
      }}
      model="cfs/cfsLocations"
    />
  );
};
