import { Button, Modal, Space, Row } from 'antd';
import React from 'react';

interface IMblNumberDuplicateModal {
  open: boolean;
  onClose: () => void;
  handleContinue: () => void;
}

export const MblNumberDuplicateModal: React.FC<IMblNumberDuplicateModal> = ({
  open,
  onClose,
  handleContinue,
}) => {
  return (
    <Modal
      title=""
      destroyOnClose
      cancelText="Close"
      footer={
        <Space>
          <Button onClick={onClose}>Close</Button>
          <Button type="primary" onClick={handleContinue}>
            Continue
          </Button>
        </Space>
      }
      open={open}
      onCancel={onClose}>
      <Row>This MBL# has been taken. Are you sure to continue?</Row>
    </Modal>
  );
};
