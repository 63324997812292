import React from 'react';
import { Typography } from 'antd';
import {
  OpenBuyRateOrSellRateDrawer,
  TSnapshotProps,
  TContainerProps,
  TOpenTypeProps,
} from './OpenBuyRateOrSellRateDrawer';
import { THasBanModifyFields } from './data';

type TRateLinkProps = {
  rateUid: string;
  label?: any;
  showLabel?: boolean;
  onSaved?: () => void;
};

export const RateLink: React.FC<
TRateLinkProps & TOpenTypeProps & TSnapshotProps & THasBanModifyFields
> = ({
  rateUid,
  label = 'View Rate',
  showLabel = false,
  type,
  snapshotableId,
  snapshotableType,
  hasBanModifyFields = false,
  onSaved,
}) => {
  const [openRate, setOpenRate] = React.useState(false);

  const handleOpenRate = () => {
    setOpenRate(true);
  };

  return (
    <>
      <Typography.Link onClick={handleOpenRate}>
        {showLabel ? label : rateUid}
      </Typography.Link>

      {openRate && rateUid && (
        <OpenBuyRateOrSellRateDrawer
          open={openRate}
          rateUid={rateUid}
          snapshotableId={snapshotableId}
          snapshotableType={snapshotableType}
          hasBanModifyFields={hasBanModifyFields}
          type={type}
          onSaved={() => onSaved && onSaved()}
          onClose={() => setOpenRate(false)}
        />
      )}
    </>
  );
};
