import { Button } from 'antd';
import { useApp } from '@/utils/useapp';
import { upperFirst } from 'lodash';

import React, { useState } from 'react';
import { DeliveryOrderDrawer } from '@/pages/orders/components/DeliveryOrderDrawer';

import {
  TItemProps,
  TOnCheckProps,
  TItemActionProps,
  TOnDoneProps,
  TContainerProps,
} from '..';
import { useForm } from 'antd/lib/form/Form';

export const CreateDOAction: React.FC<
TItemProps & TOnDoneProps & TContainerProps
> = ({ item, container, onDone }) => {
  const app = useApp();
  const [open, setOpen] = useState(false);
  const [doForm] = useForm();

  return (
    <>
      <Button size="small" onClick={() => setOpen(!open)}>
        CREATE
      </Button>
      {open && (
        <DeliveryOrderDrawer
          doForm={doForm}
          showDoForm={open}
          setShowDoForm={setOpen}
          selectedCntrs={[container]}
          refreshOrder={onDone}
          deliveryOrder={null}
          order={container?.order}
        />
      )}
    </>
  );
};
