import {
  Button,
  Checkbox,
  Space,
  Form,
  Row,
  Select,
  Popover,
  message,
} from 'antd';

import moment from 'moment';
import React, { useEffect, useState, useMemo } from 'react';

import { useApp } from '@/utils/useapp';
import { WarningOutlined } from '@ant-design/icons';
import { HAZMAT_CLASS_MAP } from './data';

const { Option } = Select;

const DGIcon: React.FC<{ active: boolean }> = ({ active }) => {
  return (
    <span className={active ? 'text-primary' : 'text-gray'}>
      <WarningOutlined />
    </span>
  );
};

type TProps = {
  container: any;
  onChange: (container: any) => void;
};
export const DGAttribute: React.FC<TProps> = ({ container, onChange }) => {
  const app = useApp();

  const active = useMemo(() => container?.is_dg, [container]);
  const [loading, setLoading] = useState(false);

  const handleOnChangeClass = (value: any) => {
    onChange({
      ...container,
      hazmat_classes: value,
    });
  };

  const handleActive = (e: any) => {
    onChange({
      ...container,
      is_dg: e.target.checked,
    });
  };

  return (
    <>
      <Popover
        trigger="click"
        placement="right"
        content={
          <div className="p-md" style={{ width: '25vw' }}>
            <Row>
              <Select
                mode="multiple"
                onChange={handleOnChangeClass}
                placeholder="Select Class"
                maxTagCount={5}
                value={container?.hazmat_classes || []}
                allowClear>
                {Object.keys(HAZMAT_CLASS_MAP).map((key) => (
                  <Option key={key} value={+key}>
                    {
                      HAZMAT_CLASS_MAP[
                        key as unknown as keyof typeof HAZMAT_CLASS_MAP
                      ]
                    }
                  </Option>
                ))}
              </Select>
            </Row>
            <Row className="mt-md">
              <Space>
                <Checkbox checked={container.is_dg} onChange={handleActive} />{' '}
                Active
              </Space>
            </Row>
          </div>
        }>
        <DGIcon active={active} />{' '}
      </Popover>
    </>
  );
};
