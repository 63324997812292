import { CACHE_COMPANY_KEY } from '@/stores/cache';
import { Button } from 'antd';
import React from 'react';
import SyncCacheModelSelect, {
  SyncCacheModelSelectProps,
} from './SyncCacheModelSelect';
import { EditOutlined } from '@ant-design/icons';
import { CompanyDrawer } from '@/pages/setting/companies/components/CompanyDrawer';

export const CompanySyncSelect: React.FC<
  SyncCacheModelSelectProps & {
    selected?: any;
    onSelect?: (id: string) => void;
    className?: string;
    editable?: boolean;
  }
> = ({ selected, onSelect, editable, ...props }) => {
  const [id, setId] = React.useState(null);
  const [visible, setVisible] = React.useState(false);
  const handleSave = (data: any) => {
    onSelect && onSelect(data?.id);
    setVisible(false);
    setId(null);
  };

  const handleClose = () => {
    setId(null);
    setVisible(false);
  };
  return (
    <>
      <div className="flex">
        {editable && (
          <Button
            onClick={() => {
              setId(selected);
              setVisible(true);
            }}
            className="ant-btn-icon-only select-modal-icon"
          >
            <EditOutlined
              style={{
                color: '#00000073',
              }}
            />
          </Button>
        )}
        <SyncCacheModelSelect
          {...props}
          dropdownMatchSelectWidth={false}
          trySearchWhenNotFound={true}
          onSelect={onSelect}
          cacheKey={CACHE_COMPANY_KEY}
          renderOption={(row) => `[${row.code}]${row.name}`}
        />
      </div>
      <CompanyDrawer
        id={id}
        onSaved={handleSave}
        onClose={handleClose}
        open={visible}
      />
    </>
  );
};
