import React from 'react';
import { Form, FormInstance, Input, Select, Typography } from 'antd';
import {
  AGENT_OR_HEADQUARTER_MAP,
  AGENT_OR_HEADQUARTER_HEADQUARTER_AGENT,
} from '../data';
import { Documents } from '@/components/Upload/Documents';
import { AntDDatePicker } from 'antd-datepicker';
import { DocumentsStoreLocalFormItem } from '@/components/Upload/DocumentsStoreLocalFormItem';
import moment from 'moment';

interface Props {
  form: FormInstance;
}

export const VendorContractForm: React.FC<Props> = ({ form }) => {
  const handleChange = async (event: any) => {
    const { name, value } = event.target as HTMLInputElement;
    await form.setFieldsValue({ [name]: value });
  };

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={{
        agent_or_headquarter: AGENT_OR_HEADQUARTER_HEADQUARTER_AGENT,
      }}>
      <Form.Item name="id" noStyle>
        <Input hidden />
      </Form.Item>
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Name is required' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="MC#"
        name="mc_number"
        rules={[{ required: true, message: 'MC# is required' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="Agent or Headquarter"
        name="agent_or_headquarter"
        rules={[{ required: true }]}>
        <Select>
          {Object.keys(AGENT_OR_HEADQUARTER_MAP).map((key) => (
            <Select.Option key={key} value={key}>
              {
                AGENT_OR_HEADQUARTER_MAP[
                  key as unknown as keyof typeof AGENT_OR_HEADQUARTER_MAP
                ]
              }
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Expired At"
        name="expired_at"
        rules={[{ required: true }]}>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <>
              <AntDDatePicker
                style={{ width: '100%' }}
                onChange={(date) =>
                  handleChange({
                    target: {
                      name: 'expired_at',
                      value: date ? date.format('YYYY-MM-DD HH:mm:ss') : null,
                    },
                  })
                }
                value={
                  getFieldValue('expired_at')
                    ? moment(getFieldValue('expired_at'))
                    : null
                }
              />
            </>
          )}
        </Form.Item>
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) =>
          getFieldValue('id') ? (
            <Documents
              title="Documents"
              target="vendor_contract"
              targetId={getFieldValue('id')}
            />
          ) : (
            <DocumentsStoreLocalFormItem form={form} />
          )
        }
      </Form.Item>
    </Form>
  );
};
