import styles from 'res/css/ui.scss';
import { Button, Col, Form, Input, Row, Select, Space } from 'antd';
import React from 'react';
import { FormInstance } from 'antd/es/form';
import { BILLING_CODE_GATEGORIES } from './constans';

export type TFilter = {
  loading: boolean;
  filter: FormInstance;
  onSearch: () => void;
};

export const Filter: React.FC<TFilter> = ({ loading, filter, onSearch }) => {
  const handleSearch = () => {
    onSearch();
  };

  const handleReset = () => {
    filter.resetFields();
    onSearch();
  };

  return (
    <div className={styles.filter}>
      <div
        style={{
          width: '60%',
        }}
      >
        <Form form={filter}>
          <Row gutter={10}>
            <Col span={6}>
              <Form.Item
                labelCol={{ span: 24 }}
                label="Code"
                name="code"
                wrapperCol={{ span: 24 }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                labelCol={{ span: 24 }}
                label="Name"
                name="name"
                wrapperCol={{ span: 24 }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                labelCol={{ span: 24 }}
                label="Category"
                name="category"
                wrapperCol={{ span: 24 }}
              >
                <Select
                  allowClear
                  options={BILLING_CODE_GATEGORIES.map((row) => ({
                    label: row,
                    value: row,
                    id: row,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="&nbsp;"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Space>
                  <Button
                    type="primary"
                    disabled={loading}
                    onClick={handleSearch}
                  >
                    Search
                  </Button>
                  <Button onClick={handleReset}>Clear All</Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};
