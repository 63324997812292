import { Button, Drawer } from 'antd';
import QuoteForm from '../components/QuoteForm';
import { LTLQuoteInterface } from '@/Interfaces/LTLQuoteInterface';
import { useEffect, useState } from 'react';
import { useApp } from '@/utils/useapp';

const Index: React.FC<{
  open: boolean;
  id?: number;
  onClose: () => void;
  onSaved: () => void;
}> = ({ open, id, onClose, onSaved }) => {
  const app = useApp();
  const [quoteModel, setQuoteModel] = useState<LTLQuoteInterface | undefined>(
    undefined,
  );

  const fetchQuote = async (id: number) => {
    const resp = await app.service.get(`tl/ltl/quotes/${id}`);
    setQuoteModel(resp.data);
  };

  useEffect(() => {
    if (id && open) {
      fetchQuote(id);
    }
  }, [id, open]);

  return (
    <Drawer
      title={`Quote Detail#${quoteModel?.uid}`}
      placement="right"
      width="90%"
      destroyOnClose={true}
      onClose={onClose}
      open={open}
      extra={<Button onClick={onClose}>Close</Button>}
    >
      {quoteModel && open && (
        <QuoteForm
          model={quoteModel}
          isCopy={false}
          onCreated={onSaved}
          onClose={onClose}
        />
      )}
    </Drawer>
  );
};

export default Index;
