import { CACHE_ACTIVE_TERMINAL_KEY } from '@/stores/cache';
import React from 'react';
import SyncCacheModelSelect, {
  SyncCacheModelSelectProps,
} from './SyncCacheModelSelect';

export const ActiveTerminalSyncSelect: React.FC<
SyncCacheModelSelectProps & {
  selected?: any;
  onSelect?: (id: string) => void;
  className?: string;
  intermodalRegionId?: number;
}
> = ({ selected, onSelect, intermodalRegionId, ...props }) => {
  return (
    <SyncCacheModelSelect
      {...props}
      onSelect={onSelect}
      cacheKey={CACHE_ACTIVE_TERMINAL_KEY}
      renderOption={(row) => `[${row.firms}]${row.name}`}
      filterModel={(model: any, value: any) => {
        if (intermodalRegionId) {
          return intermodalRegionId == model.intermodal_region_id;
        } else {
          return true;
        }
      }}
    />
  );
};
