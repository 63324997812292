export const BY_LOCATION = 1;
export const BY_FORMULAR = 0;

export const CONTRACT_RATE_TYPE_MAP = {
  [BY_LOCATION]: 'Location',
  [BY_FORMULAR]: 'Formular',
};

export const WEEKDAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
export const TIMEZONE = ['AST', 'EST', 'CST', 'MST', 'PST', 'AKST', 'HST'];

export const ALL = 0;
export const EXPLOSIVE = 1;
export const EXPLOSIVE_1_1 = 2;
export const EXPLOSIVE_1_2 = 3;
export const EXPLOSIVE_1_3 = 4;
export const EXPLOSIVE_1_4 = 5;
export const EXPLOSIVE_1_5 = 6;
export const EXPLOSIVE_1_6 = 7;
export const FLAMMABLE_GAS = 8;
export const NON_FLAMMABLE_GAS = 9;
export const POISON_GAS = 10;
export const FLAMMABLE_LIQUID = 11;
export const FLAMMABLE_SOLID = 12;
export const SPONTANEOUSLY_COMBUSTIBLE = 13;
export const DANGEROUS_WHEN_WET = 14;
export const OXIDIZER = 15;
export const ORGANIC_PEROXIDE = 16;
export const POISON_INHALATION_HAZARD = 17;
export const POISON = 18;
export const INFECTIOUS_SUBSTANCE = 18;
export const RADIOACTIVE = 19;
export const CORROSIVE = 20;
export const ClASS_9 = 21;

export const HAZMAT_OPTIONS_MAP = {
  [ALL]: 'All',
  [EXPLOSIVE]: '1 - Explosive',
  [EXPLOSIVE_1_1]: '1.1 - Explosive 1.1',
  [EXPLOSIVE_1_2]: '1.2 - Explosive 1.2',
  [EXPLOSIVE_1_3]: '1.3 - Explosive 1.3',
  [EXPLOSIVE_1_4]: '1.4 - Explosive 1.4',
  [EXPLOSIVE_1_5]: '1.5 - Explosive 1.5',
  [EXPLOSIVE_1_6]: '1.6 - Explosive 1.6',
  [FLAMMABLE_GAS]: '2.1 - Flammable Gas',
  [NON_FLAMMABLE_GAS]: '2.2 - Non - Flammable Gas',
  [POISON_GAS]: '2.3 - Poison Gas',
  [FLAMMABLE_LIQUID]: '  3 - Flammable Liquid',
  [FLAMMABLE_SOLID]: '4.1 - Flammable Solid',
  [SPONTANEOUSLY_COMBUSTIBLE]: '4.2 - Spontaneously Combustible',
  [DANGEROUS_WHEN_WET]: '4.3 - Dangerous When Wet',
  [OXIDIZER]: '5.1 - Oxidizer',
  [ORGANIC_PEROXIDE]: '5.2 - Organic Peroxide',
  [POISON_INHALATION_HAZARD]:
    '6.1(inhalation hazard, Zone A or B) - Poison Inhalation Hazard',
  [POISON]: '6.1(other than inhalation hazard, Zone A or B) - Poison',
  [INFECTIOUS_SUBSTANCE]: '6.2 - Infectious substance',
  [RADIOACTIVE]: '7 - Radioactive',
  [CORROSIVE]: '8 - Corrosive',
  [ClASS_9]: '9 - Class 9',
};

export const OVERWEIGHT_CODE = 'OVECH';
export const HAZMAT_CODE = 'HAZCA';
export const REEFER_CODE = 'REESU';
export const RESIDENTIAL_CODE = 'RESDE';
export const FBA_DELIVERY_CODE = 'FBAFEE';

export const SAFETY_RATING_TYPE_SATISFACTORY = 'Satisfactory';
export const SAFETY_RATING_TYPE_CONDITIONAL = 'Conditional';
export const SAFETY_RATING_TYPE_UNSATISFACTORY = 'Unsatisfactory';
export const SAFETY_RATING_TYPE_NONE = 'None';
export const SAFETY_RATING_TYPE_MAP = {
  [SAFETY_RATING_TYPE_SATISFACTORY]: SAFETY_RATING_TYPE_SATISFACTORY,
  [SAFETY_RATING_TYPE_CONDITIONAL]: SAFETY_RATING_TYPE_CONDITIONAL,
  [SAFETY_RATING_TYPE_UNSATISFACTORY]: SAFETY_RATING_TYPE_UNSATISFACTORY,
  [SAFETY_RATING_TYPE_NONE]: SAFETY_RATING_TYPE_NONE,
};

export const FINANCIAL_STATUS_SUPERIOR_A_WITH_TWO_PLUS = 'A++';
export const FINANCIAL_STATUS_SUPERIOR_A_WITH_ONE_PLUS = 'A+';
export const FINANCIAL_STATUS_EXCELLENT_A = 'A';
export const FINANCIAL_STATUS_EXCELLENT_A_WITH_MINUS = 'A-';
export const FINANCIAL_STATUS_GOOD_B_WITH_TWO_PLUS = 'B++';
export const FINANCIAL_STATUS_GOOD_B_WITH_ONE_PLUS = 'B+';
export const FINANCIAL_STATUS_FAIR_B = 'B';
export const FINANCIAL_STATUS_FAIR_B_WITH_MINUS = 'B-';
export const FINANCIAL_STATUS_MARGINAL_C_WITH_TWO_PLUS = 'C++';
export const FINANCIAL_STATUS_MARGINAL_C_WITH_ONE_PLUS = 'C+';
export const FINANCIAL_STATUS_WEAK_C = 'C';
export const FINANCIAL_STATUS_WEAK_C_WITH_MINUS = 'C-';
export const FINANCIAL_STATUS_POOR_D = 'D';
export const FINANCIAL_STATUS_UNDER_REGULATORY_SUPERVISION_E = 'E';
export const FINANCIAL_STATUS_IN_LIQUIDATION_F = 'F';
export const FINANCIAL_STATUS_MAP = {
  [FINANCIAL_STATUS_SUPERIOR_A_WITH_TWO_PLUS]:
    FINANCIAL_STATUS_SUPERIOR_A_WITH_TWO_PLUS,
  [FINANCIAL_STATUS_SUPERIOR_A_WITH_ONE_PLUS]:
    FINANCIAL_STATUS_SUPERIOR_A_WITH_ONE_PLUS,
  [FINANCIAL_STATUS_EXCELLENT_A]: FINANCIAL_STATUS_EXCELLENT_A,
  [FINANCIAL_STATUS_EXCELLENT_A_WITH_MINUS]:
    FINANCIAL_STATUS_EXCELLENT_A_WITH_MINUS,
  [FINANCIAL_STATUS_GOOD_B_WITH_TWO_PLUS]:
    FINANCIAL_STATUS_GOOD_B_WITH_TWO_PLUS,
  [FINANCIAL_STATUS_GOOD_B_WITH_ONE_PLUS]:
    FINANCIAL_STATUS_GOOD_B_WITH_ONE_PLUS,
  [FINANCIAL_STATUS_FAIR_B]: FINANCIAL_STATUS_FAIR_B,
  [FINANCIAL_STATUS_FAIR_B_WITH_MINUS]: FINANCIAL_STATUS_FAIR_B_WITH_MINUS,
  [FINANCIAL_STATUS_MARGINAL_C_WITH_ONE_PLUS]:
    FINANCIAL_STATUS_MARGINAL_C_WITH_ONE_PLUS,
  [FINANCIAL_STATUS_WEAK_C]: FINANCIAL_STATUS_WEAK_C,
  [FINANCIAL_STATUS_WEAK_C_WITH_MINUS]: FINANCIAL_STATUS_WEAK_C_WITH_MINUS,
  [FINANCIAL_STATUS_POOR_D]: FINANCIAL_STATUS_POOR_D,
  [FINANCIAL_STATUS_UNDER_REGULATORY_SUPERVISION_E]:
    FINANCIAL_STATUS_UNDER_REGULATORY_SUPERVISION_E,
  [FINANCIAL_STATUS_IN_LIQUIDATION_F]: FINANCIAL_STATUS_IN_LIQUIDATION_F,
};
