import { useApp } from '@/utils/useapp';
import { message, Form, Modal, Space, Button, Spin, FormInstance } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import EmailForm from '@/components/EmailForm';
import { serialize } from 'object-to-formdata';
import { get, chain, reduce, uniq, trim } from 'lodash';
import { TInvoice, TUser } from '@/types';
import { CONTACT_TYPE_ACCOUNTING } from '@/components/ContactList';
import moment from 'moment';

interface IOrderEmaiForm {
  orderId: number;
  invoices: TInvoice[];
  container?: any;
  emailForm: FormInstance;
}
const OrderEmaiForm: React.FC<IOrderEmaiForm> = ({
  orderId,
  invoices,
  container,
  emailForm,
}) => {
  const app = useApp();

  const [loading, setLoading] = useState(false);

  const [docs, setDocs] = useState<any[]>([]);

  const [order, setOrder] = useState();

  const containerNumbers = React.useMemo(
    () =>
      chain(invoices)
        .map((invoice) => get(invoice, 'charges'))
        .reduce(
          (result, charges) =>
            reduce(
              charges,
              (result, charge) => {
                if (!result.includes(charge.containerNumber)) {
                  return [...result, charge.containerNumber];
                }
                return result;
              },
              result,
            ),
          [],
        )
        .value()
        .join(','),
    [invoices],
  );

  const fetchOrder = async () => {
    setLoading(true);
    try {
      const _order = await app.service.get(`orders/${orderId}`);
      setOrder(_order.data);
    } catch (err: any) {
      message.error(err?.data?.error ?? 'error');
    } finally {
      setLoading(false);
    }
  };

  const fetchOrderDocuments = async () => {
    if (!orderId) {
      return;
    }
    try {
      const resData = await app.service.get(
        `orders/${orderId}/getEmailDocuments`,
        {
          params: {
            containerNumbers: containerNumbers || undefined,
          },
        },
      );

      setDocs(
        resData.data.map((d: any) => {
          return {
            label: `[${get(d, 'custom_properties.document_type', 'No Type')}]${
              d.file_name
            }`,
            value: d.id,
          };
        }),
      );
    } catch (err: any) {
      setDocs([]);
    }
  };

  const toDrayeasyBody = () => {
    return trim(`
Hi,

Please find attached invoice. Appreciate the opportunity for us to serve you.

DrayEasy, Inc.
800 N Haven Ave #210,
Ontario, CA 91764
Phone: 1-818-826-2850
E-mail: ar@drayeasy.com
          `);
  };

  // invoice for customer /reference/ MBL/CNTR NO./xx POD/Warehouse zipcode/ETA
  const toSubject = () => {
    const user = get(order, 'user');
    const referenceNumber = get(order, 'customer_reference_number');
    const mbl = get(order, 'mbl_number');
    const finalPortName = get(
      container,
      'final_port.name',
      get(order, 'final_port.name'),
    );
    const warehouse = get(container, 'warehouse', get(order, 'warehouse'));
    const finalPortEta = get(
      container,
      'final_port_eta',
      get(order, 'final_port_eta'),
    );

    const containerNumbers = chain(invoices)
      .reduce(
        (result, invoice) =>
          result.concat(
            invoice?.charges?.map((charge) => charge.containerNumber) || [],
          ),
        [],
      )
      .uniq()
      .join(', ');

    let subjectInfo = 'Invoice for ';

    const subjectArr = [];

    get(user, 'name') && subjectArr.push(get(user, 'name'));

    referenceNumber && subjectArr.push(referenceNumber);

    subjectArr.push(mbl);
    subjectArr.push(containerNumbers);

    finalPortName && subjectArr.push(finalPortName);

    if (warehouse) {
      let warehouseInfo = '';
      warehouseInfo;
      get(warehouse, 'city.full_name') &&
        (warehouseInfo += `${warehouse?.city?.full_name}`);
      warehouse?.zipcode && (warehouseInfo += ` ${warehouse.zipcode}`);
      warehouseInfo && subjectArr.push(warehouseInfo);
    }

    finalPortEta && subjectArr.push(moment(finalPortEta).format('Y-MM-DD'));

    return (subjectInfo += uniq(subjectArr).join(' / '));
  };

  const initForm = () => {
    const cc = get(order, 'user.email', '');
    const salesEmail = get(order, 'sales.email', '');
    const salesSupportEmail = get(order, 'sales_support.email', '');

    const contacts = get(invoices[0], 'user.bill_to.contacts', []).filter(
      (c: any) => {
        if (c.type instanceof Array) {
          return c.type.includes(CONTACT_TYPE_ACCOUNTING);
        }

        return false;
      },
    );

    const sendToList = contacts.map((c: any) => c.email);

    const _lowerSendToList = sendToList.map((send) => send.toLowerCase());

    const ccList = uniq([cc, salesEmail, salesSupportEmail]).filter(
      (cc) => cc && !_lowerSendToList.includes(cc.toLowerCase()),
    );

    emailForm.setFieldsValue({
      sendToList: sendToList.length > 0 ? sendToList : [''],
      ccList: ccList,
      subject: toSubject(),
      body: toDrayeasyBody(),
      addText: false,
    });
  };

  React.useEffect(() => {
    if (order && invoices) {
      initForm();
    }
  }, [order, invoices]);

  React.useEffect(() => {
    if (orderId) {
      fetchOrderDocuments();
      fetchOrder();
    }
  }, [orderId, invoices]);

  return (
    <EmailForm
      form={emailForm}
      docs={docs}
      has={{
        from: false,
        attachment: true,
        showSelectAttachments: true,
      }}
    />
  );
};

export default OrderEmaiForm;
