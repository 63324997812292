import React, { useMemo, useState } from 'react';
import { useApp } from '@/utils/useapp';

import { Popover, message, List, Space } from 'antd';
import { TbClockExclamation } from 'react-icons/tb';
import { RenderWhoseTaskTag } from '../../ActionTasks/RenderTaskColumn/RenderWhoseTaskTag';
import { RenderSla } from '../../ActionTasks/RenderSla';
import { TitleSlaTooltip } from '../../ActionTasks/TitleSlaTooltip';

export const TaskOverdue: React.FC<{
  container: any;
  toIgnoreSpecifiedTasks?: boolean;
}> = ({ container, toIgnoreSpecifiedTasks = false }) => {
  const app = useApp();

  const isOverdue = useMemo(
    () =>
      toIgnoreSpecifiedTasks
        ? !!container.is_task_ignore_specified_tasks_overdue
        : !!container.is_task_overdue,
    [toIgnoreSpecifiedTasks],
  );

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const handleOpenChange = async (open: boolean) => {
    if (!open) {
      return;
    }

    if (!container.id) {
      return null;
    }

    setLoading(true);
    try {
      const resp = await app.service.get(
        `containers/${container.id}/getOverdueTasks`,
        {
          params: {
            is_ignored: toIgnoreSpecifiedTasks,
          },
        },
      );
      setData(resp.data);
    } catch (error: any) {
      message.error(error.data?.message || error.data?.error || 'System Error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex">
      {isOverdue && (
        <Popover
          zIndex={100}
          title={
            <>
              Task Overdue (<TitleSlaTooltip />)
            </>
          }
          mouseEnterDelay={0.1}
          mouseLeaveDelay={0.1}
          placement="right"
          onOpenChange={handleOpenChange}
          content={
            <List
              loading={loading}
              dataSource={data}
              renderItem={(row: {
                task: string;
                whose_task: number | null;
                sla_text?: string;
                due_at?: string;
                finished_at?: string;
              }) => (
                <List.Item>
                  <Space size="small">
                    <RenderWhoseTaskTag whoseTask={row.whose_task} />
                    {row.task}
                    <RenderSla item={row} />
                  </Space>
                </List.Item>
              )}
            />
          }>
          <div className="text-danger flex justify-center align-center">
            <TbClockExclamation />
          </div>
        </Popover>
      )}
    </div>
  );
};
