import React, {
  ReactElement,
  ReactHTMLElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { EmailTemplateEditor } from '@/components/EmailTemplateEditor';
import { useApp } from '@/utils/useapp';
import {
  Card,
  FormInstance,
  Button,
  Radio,
  Form,
  message,
  Space,
  RadioChangeEvent,
  Tooltip,
} from 'antd';
import { get, truncate, has } from 'lodash';
import { TEmailTemplate, TCollection } from '@/types';
import { EditOutlined } from '@ant-design/icons';

interface INormalList {
  form: FormInstance;
  templateData: TCollection<TEmailTemplate> | undefined;
  handleCreateTemplate: () => void;
  handleEditTemplate: (template: TEmailTemplate) => void;
  onChange: (template: TEmailTemplate | undefined) => void;
}

export const NormalList: React.FC<INormalList> = ({
  form,
  templateData,
  handleCreateTemplate,
  handleEditTemplate,
  onChange,
}) => {
  const handleOnChange = (e: RadioChangeEvent) => {
    onChange(
      templateData?.data?.find(
        (template: TEmailTemplate) => template.id === e.target.value,
      ),
    );
  };

  const handleEdit = (e: any, template: TEmailTemplate) => {
    e.preventDefault();
    e.stopPropagation();

    if (!template.id) {
      return false;
    }

    handleEditTemplate(template);
  };

  return (
    <>
      <div
        className="ant-col ant-form-item-label"
        style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>Choose Template</div>
        <div>
          {' '}
          <Button
            size="small"
            type="primary"
            shape="round"
            onClick={handleCreateTemplate}>
            Create Template
          </Button>
        </div>
      </div>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => (
          <Form.Item name="email_template_id" noStyle label="">
            <Radio.Group onChange={handleOnChange} className="template">
              <Space direction="vertical">
                {templateData &&
                  templateData?.data?.map((template, index) => (
                    <Radio
                      key={index}
                      value={template.id}
                      style={{
                        width: '100%',
                        borderBottom: '1px solid rgb(242 242 242)',
                        paddingBottom: '10px',
                      }}>
                      <div
                        className="flex justify-between"
                        style={{ minWidth: '40vw' }}>
                        <Space>
                          <h4>
                            {truncate(get(template, 'subject', ''), {
                              length: 50,
                            })}
                          </h4>
                          <Button
                            type="link"
                            onClick={(e) => handleEdit(e, template)}>
                            <EditOutlined />
                          </Button>
                        </Space>
                      </div>
                      <div>
                        {truncate(get(template, 'body_str', ''), {
                          length: 300,
                        })}
                      </div>
                    </Radio>
                  ))}
              </Space>
            </Radio.Group>
          </Form.Item>
        )}
      </Form.Item>
    </>
  );
};
