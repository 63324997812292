import React, { FC } from 'react';
import { TroubleStatusHisToryInterface } from './Interfaces/TroubleStatusHistoryInterface';
import { StatusLabelMap } from './data';
import moment from 'moment';

const TroubleStatusesHistory: FC<{
  histories: TroubleStatusHisToryInterface[];
}> = ({ histories }) => {
  return (
    <>
      {histories?.map((history, index) => (
        <div key={index}>
          {moment(history.created_at).format('YYYY-MM-DD HH:mm:ss') +
            ' ' +
            StatusLabelMap[
              (`${history.status}` as unknown) as keyof typeof StatusLabelMap
            ] +
            ' was ' +
            history?.action_name +
            ' by ' +
            history?.operator?.name}
        </div>
      ))}
    </>
  );
};

export default TroubleStatusesHistory;
