import React from 'react';
import {
  Form,
  FormInstance,
  Input,
  Select,
  Typography,
  Space,
  Radio,
  Row,
  Col,
  Divider,
} from 'antd';
import {
  AGENT_OR_HEADQUARTER_HEADQUARTER_AGENT,
  FEDERAL_TAX_CLASSIFICATION_MAP,
  FEDERAL_TAX_CLASSIFICATION_LIMITED_LIABILITY_COMPANY,
  AGENT_OR_HEADQUARTER_MAP,
  FORM_TYPE_MAP,
  FORM_TYPE_W9,
} from '../data';
import { Documents } from '@/components/Upload/Documents';
import { CitySelect } from '@/components/CitySelect';
import type { RadioChangeEvent } from 'antd';
import { DocumentsStoreLocalFormItem } from '@/components/Upload/DocumentsStoreLocalFormItem';
import { useWatch } from 'antd/lib/form/Form';

interface Props {
  form: FormInstance;
}

export const VendorFormW9: React.FC<Props> = ({ form }) => {
  const isW9 = useWatch('form_type', form) === FORM_TYPE_W9 ? true : false;

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={{
        agent_or_headquarter: AGENT_OR_HEADQUARTER_HEADQUARTER_AGENT,
        form_type: FORM_TYPE_W9,
      }}>
      <Form.Item name="id" noStyle>
        <Input hidden />
      </Form.Item>
      <Form.Item
        label="Form type"
        name={'form_type'}
        rules={[{ required: true, message: 'Please select form type' }]}>
        <Select>
          {Object.keys(FORM_TYPE_MAP).map((key) => (
            <Select.Option key={+key} value={+key}>
              {FORM_TYPE_MAP[key as unknown as keyof typeof FORM_TYPE_MAP]}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label={
          <Space>
            1. Name
            <Typography.Text type="secondary">
              (as shown on your income tax return).
            </Typography.Text>
          </Space>
        }
        name="name"
        rules={[{ required: true, message: 'Name is required' }]}>
        <Input />
      </Form.Item>
      {isW9 && (
        <>
          <Divider />
          <Form.Item
            label="2. Business name/disregarded entity name, if different from above"
            name="business_name_or_disregarded_entity_name"
            rules={[{ required: false }]}>
            <Input />
          </Form.Item>
          <Divider />
          <Row>
            <Col span={12}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <Form.Item
                    label="3. Check appropriate box for federal tax classification;"
                    name="federal_tax"
                    rules={[{ required: isW9 }]}>
                    <Radio.Group value={form.getFieldValue('federal_tax')}>
                      <Space direction="vertical">
                        {Object.keys(FEDERAL_TAX_CLASSIFICATION_MAP).map(
                          (key: string) => {
                            return (
                              <Radio key={key} value={+key}>
                                {
                                  FEDERAL_TAX_CLASSIFICATION_MAP[
                                    key as unknown as keyof typeof FEDERAL_TAX_CLASSIFICATION_MAP
                                  ]
                                }

                                {(key as unknown as keyof typeof FEDERAL_TAX_CLASSIFICATION_MAP) ==
                                  FEDERAL_TAX_CLASSIFICATION_LIMITED_LIABILITY_COMPANY &&
                                  form.getFieldValue('federal_tax') == key && (
                                  <>
                                    <Form.Item
                                      label="Enter the tax classification (C=C corporation, S=S corporation, P=Partnership) ▶"
                                      name="tax_classification"
                                      rules={[{ required: true }]}>
                                      <Input />
                                    </Form.Item>
                                    <Typography.Paragraph>
                                      <strong> Note: </strong>Check the
                                        appropriate box in the line above for
                                        the tax classification of the
                                        single-member owner. Do not check LLC if
                                        the LLC is classified as a single-member
                                        LLC that is disregarded from the owner
                                        unless the owner of the LLC is another
                                        LLC that is not disregarded from the
                                        owner for U.S. federal tax purposes.
                                        Otherwise, a single-member LLC that is
                                        disregarded from the owner should check
                                        the appropriate box for the tax
                                        classification of its owner.
                                    </Typography.Paragraph>
                                  </>
                                )}
                              </Radio>
                            );
                          },
                        )}
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <h4>
                <Space>
                  4. Exemptions
                  <Typography.Text type="secondary">
                    (codes apply only to certain entities, not individuals; see
                    instructions on page 3).
                  </Typography.Text>
                </Space>
              </h4>
              <Form.Item
                label="Exempt payee code (if any)"
                name="exempt_payee_code"
                rules={[{ required: false }]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="Exemption from FATCA reporting code (if any)"
                name="exemption_from_fatca_reporting_code"
                rules={[{ required: false }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={12}>
              <Form.Item
                label={
                  <Space>
                    5. Address
                    <Typography.Text type="secondary">
                      (number, street, and apt. or suite no. See instructions.)
                    </Typography.Text>
                  </Space>
                }
                name="address"
                rules={[{ required: isW9, message: 'Address is required' }]}>
                <Input />
              </Form.Item>

              <Form.Item noStyle shouldUpdate>
                {() => (
                  <Form.Item
                    label="6. City, state, and ZIP code"
                    required={isW9}>
                    <Form.Item name="city_state_zipcode">
                      <Input placeholder="City, state, and ZIP code" />
                    </Form.Item>
                  </Form.Item>
                )}
              </Form.Item>
              <Form.Item
                label="7. List account number(s) here (optional)"
                name="list_account_number"
                rules={[{ required: false }]}>
                <Input />
              </Form.Item>
            </Col>
            <Divider type="vertical" />
            <Col span={11}>
              <Form.Item
                label="Requester’s name and address (optional)"
                name="requester_name_and_address"
                rules={[{ required: false }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={1}>
              <h3>PartI</h3>
            </Col>
            <Col>
              <h4> Taxpayer Identification Number (TIN)</h4>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Typography.Paragraph>
                Enter your TIN in the appropriate box. The TIN provided must
                match the name given on line 1 to avoid backup withholding. For
                individuals, this is generally your social security number
                (SSN). However, for a resident alien, sole proprietor, or
                disregarded entity, see the instructions for Part I, later. For
                other entities, it is your employer identification number (EIN).
                If you do not have a number, see How to get a TIN, later.
                <br />
                <strong>Note:</strong> If the account is in more than one name,
                see the instructions for line 1. Also see What Name and Number
                To Give the Requester for guidelines on whose number to enter.
              </Typography.Paragraph>
            </Col>
            <Divider type="vertical" />
            <Col span={11}>
              <Form.Item
                label="Social security number"
                name="social_security_number"
                rules={[{ required: false }]}>
                <Input />
              </Form.Item>
              <Typography.Title level={4}>or</Typography.Title>
              <Form.Item
                label="Employer identification number"
                name="employer_identification_number"
                rules={[{ required: false }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
        </>
      )}
      <Form.Item
        label="Agent or Headquarter"
        name="agent_or_headquarter"
        rules={[{ required: isW9 }]}>
        <Select>
          {Object.keys(AGENT_OR_HEADQUARTER_MAP).map((key) => (
            <Select.Option key={key} value={key}>
              {
                AGENT_OR_HEADQUARTER_MAP[
                  key as unknown as keyof typeof AGENT_OR_HEADQUARTER_MAP
                ]
              }
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="MC#"
        name="mc_number"
        rules={[{ required: isW9, message: 'MC# is required' }]}>
        <Input />
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) =>
          getFieldValue('id') ? (
            <Documents
              title="Documents"
              target="vendor_formW9"
              targetId={getFieldValue('id')}
            />
          ) : (
            <DocumentsStoreLocalFormItem form={form} />
          )
        }
      </Form.Item>
    </Form>
  );
};
