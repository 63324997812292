import React from 'react';

import { Tooltip } from 'antd';
import { TbClockDollar } from 'react-icons/tb';

export const ActOverdue: React.FC<{
  container: any;
}> = ({ container }) =>
  !!container.is_act_overdue && (
    <Tooltip title="Act Overdue">
      <div className="text-danger flex justify-center align-center">
        <TbClockDollar />
      </div>
    </Tooltip>
  );
