export const WEEKDAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
export const TIMEZONE = ['AST', 'EST', 'CST', 'MST', 'PST', 'AKST', 'HST'];

export const COUNTRY_MAP = {
  USA: 'United States',
  CAN: 'Canada',
};
