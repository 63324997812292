import React from 'react';
import { Form, FormInstance, Input, InputNumber, Select, Button } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

interface Props {
  form: FormInstance;
}

export const VendorDivisionForm: React.FC<Props> = ({ form }) => {
  return (
    <Form layout="vertical" form={form}>
      <Form.Item name="id" noStyle>
        <Input hidden />
      </Form.Item>
      <Form.Item label="Name" name="name" rules={[{ required: true }]}>
        <Input
          onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
        />
      </Form.Item>
    </Form>
  );
};
