import Select, { DefaultOptionType, SelectProps } from 'antd/lib/select';
import React from 'react';

export const CNTR_SIZE_20 = 20;
export const CNTR_SIZE_40 = 40;
export const CNTR_SIZE_45 = 45;
const CNTR_SIZE_OPTIONS: DefaultOptionType[] = [
  {
    value: 20,
    label: '20',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 45,
    label: '45',
  },
];

const ContainerSizeSelect: React.FC<SelectProps> = (props) => {
  return <Select options={CNTR_SIZE_OPTIONS} allowClear {...props} />;
};

export default ContainerSizeSelect;
