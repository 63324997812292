import {
  FormInstance,
  Row,
  Col,
  Form,
  Switch,
  Input,
  Checkbox,
  TimePicker,
  Select,
} from 'antd';
import { WarningFilled } from '@ant-design/icons';
import { HasChassis } from './HasChassis';
import { OverweightAbility } from './OverweightAbility';
import { HazmatAbility } from './HazmatAbility';
import { HasContainerYard } from './HasContainerYard';
// import { Insurance } from './Insurance';
import moment from 'moment';
import { TIMEZONE, WEEKDAYS } from '../../data';
import { VendorInsuranceSyncSelect } from '@/components/VendorInsuranceSyncSelect';
import { VendorFormW9SyncSelect } from '@/components/VendorFormW9SyncSelect';
import { VendorContractSyncSelect } from '@/components/VendorContractSyncSelect';
import { AbilitySwitchDisabled } from './AbilitySwitchDisabled';
import { SelectedMcWarning } from '../SelectedMcWarning';
import React from 'react';
import { useWatch } from 'antd/lib/form/Form';
export interface IAttribute {
  form: FormInstance;
  onVerifiedUpdated: () => void;
}

export const AttributesFormItems: React.FC<IAttribute> = ({
  form,
  onVerifiedUpdated,
}) => {
  const truckerMc = useWatch('trucker_mc', form);
  const insurance = useWatch('vendor_insurance', form);
  const formW9 = useWatch('vendor_form_w9', form);
  const contract = useWatch('vendor_contract', form);

  return (
    <>
      <h3>Attributes</h3>
      <Row gutter={10}>
        <Col span={8}>
          {/* <Insurance form={form} /> */}
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                name="vendor_insurance"
                label="Insurance"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  { required: false, message: 'Please Select Insurance' },
                ]}
              >
                <VendorInsuranceSyncSelect
                  vendorInsuranceId={getFieldValue('vendor_insurance')?.id}
                  mc_number={getFieldValue('trucker_mc')}
                  onSaved={onVerifiedUpdated}
                  onSelect={(vendorInsurance: any) => {
                    form.setFieldValue('vendor_insurance', vendorInsurance);
                  }}
                />
                <SelectedMcWarning
                  selectedMc={insurance?.mc_number}
                  truckerMc={truckerMc}
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                name="vendor_form_w9"
                label="Form W9"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: false, message: 'Please Select Form W9' }]}
              >
                <VendorFormW9SyncSelect
                  formW9VendorId={getFieldValue('vendor_form_w9')?.id}
                  onSaved={onVerifiedUpdated}
                  mc_number={getFieldValue('trucker_mc')}
                  onSelect={(vendorFormW9: any) => {
                    form.setFieldValue('vendor_form_w9', vendorFormW9);
                  }}
                />
                <SelectedMcWarning
                  selectedMc={formW9?.mc_number}
                  truckerMc={truckerMc}
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                name="vendor_contract"
                label="Contract"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: false, message: 'Please Select Contract' }]}
              >
                <VendorContractSyncSelect
                  contractVendorId={getFieldValue('vendor_contract')?.id}
                  onSaved={onVerifiedUpdated}
                  mc_number={getFieldValue('trucker_mc')}
                  onSelect={(vendorContract: any) => {
                    form.setFieldValue('vendor_contract', vendorContract);
                  }}
                />
                <SelectedMcWarning
                  selectedMc={contract?.mc_number}
                  truckerMc={truckerMc}
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item
            label="Driver Count"
            name="driver_count"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input type={'number'} placeholder="Enter Driver Count" />
          </Form.Item>
          <HasChassis form={form} />
          <HasContainerYard form={form} />
        </Col>
        <Col span={8}>
          <Row gutter={10}>
            <Col span={12}>
              <OverweightAbility form={form} />
              <HazmatAbility form={form} />
              <Form.Item
                label="FBA Delivery"
                name="fba_delivery"
                valuePropName="checked"
                shouldUpdate
              >
                <Switch />
              </Form.Item>{' '}
              <Form.Item
                label="Residential Ability"
                name="residential_ability"
                valuePropName="checked"
                shouldUpdate
              >
                {/* <Switch /> */}
                <AbilitySwitchDisabled
                  checked={form.getFieldValue('residential_ability')}
                />
              </Form.Item>{' '}
              <Form.Item
                label="Reefer Ability"
                name="reefer_ability"
                valuePropName="checked"
              >
                {/* <Switch /> */}
                <AbilitySwitchDisabled
                  checked={form.getFieldValue('reefer_ability')}
                />
              </Form.Item>{' '}
              <Form.Item
                label="Liquor Alcohol Ability"
                name="liquor_alcohol_ability"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>{' '}
              <Form.Item
                label="Household Goods Ability"
                name="household_goods_ability"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>{' '}
              <Form.Item
                label="Tsa Ability"
                name="tsa_ability"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>{' '}
              <Form.Item
                label="Chains Ability"
                name="chains_ability"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>{' '}
              <Form.Item
                label="Tank Endorsed Drivers Ability"
                name="tank_endorsed_drivers_ability"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>{' '}
              <Form.Item
                label="Iso Tank Drayage Ability"
                name="iso_tank_drayage_ability"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>{' '}
              <Form.Item
                label="Day Cab Ability"
                name="day_cab_ability"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>{' '}
            </Col>
            <Col span={12}>
              <Form.Item
                label="OOG Ability"
                name="out_of_gauge_ability"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>{' '}
              <Form.Item
                label="OpenTop Ability"
                name="open_top_ability"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>{' '}
              <Form.Item
                label="FlatRack Ability"
                name="flat_rack_ability"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>{' '}
              <Form.Item
                label="Menards Approval Ability"
                name="menards_approval_ability"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>{' '}
              <Form.Item
                label="Transloading Ability"
                name="transloading_ability"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>{' '}
              <Form.Item
                label="Bonded Trucker Ability"
                name="boned_trucker_ability"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>{' '}
              <Form.Item
                label="Gate Door with Locked"
                name="gate_door_with_locked"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>{' '}
              <Form.Item
                label="Surveillance Cameras"
                name="surveillance_cameras"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>{' '}
              <Form.Item
                label="Locked with Pinlock"
                name="locked_with_pinlock"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>{' '}
              <Form.Item
                label="Live Security Guards"
                name="live_security_guards"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>{' '}
              <Form.Item
                label="Security Alarm Systems"
                name="security_alarm_systems"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>{' '}
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Form.Item hidden name="working_hour_from" />
          <Form.Item hidden name="working_hour_to" />
          <Form.Item
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            label="Working hours"
            shouldUpdate
          >
            {({ getFieldValue, setFieldValue }) => (
              <Input.Group compact>
                <Form.Item>
                  <TimePicker
                    format="HH:mm"
                    showNow={false}
                    value={moment(
                      getFieldValue('working_hour_from') || '00:00',
                      'HH:mm',
                    )}
                    onSelect={(value) => {
                      const timeString = moment(value).format('HH:mm');
                      setFieldValue('working_hour_from', timeString);
                    }}
                  />
                </Form.Item>
                <Input
                  className="site-input-split"
                  style={{
                    width: 30,
                    borderLeft: 0,
                    borderRight: 0,
                    pointerEvents: 'none',
                  }}
                  placeholder="~"
                  disabled
                />
                <Form.Item>
                  <TimePicker
                    format="HH:mm"
                    showNow={false}
                    value={moment(
                      getFieldValue('working_hour_to') || '00:00',
                      'HH:mm',
                    )}
                    onSelect={(value) => {
                      const timeString = moment(value).format('HH:mm');
                      setFieldValue('working_hour_to', timeString);
                    }}
                  />
                </Form.Item>
              </Input.Group>
            )}
          </Form.Item>
          <Form.Item
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            label="Working Days"
            shouldUpdate
          >
            {() => (
              <Input.Group compact>
                <Form.Item name="working_day_from" initialValue={1}>
                  <Select
                    placeholder="Select a timezome"
                    style={{
                      width: 150,
                    }}
                  >
                    {WEEKDAYS.map((day, index) => (
                      <Select.Option key={index} value={index}>
                        {day}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Input
                  className="site-input-split"
                  style={{
                    width: 30,
                    borderLeft: 0,
                    borderRight: 0,
                    pointerEvents: 'none',
                  }}
                  placeholder="~"
                  disabled
                />
                <Form.Item name="working_day_to" initialValue={6}>
                  <Select
                    placeholder="Select a Day"
                    style={{
                      width: 150,
                    }}
                  >
                    {WEEKDAYS.map((day, index) => (
                      <Select.Option key={index} value={index}>
                        {day}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Input.Group>
            )}
          </Form.Item>
          <Form.Item
            name="time_zone"
            label="Time zone"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Select placeholder="Select timezone">
              <Select.Option>{''}</Select.Option>
              {TIMEZONE.map((zone) => (
                <Select.Option key={zone} value={zone}>
                  {zone}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
