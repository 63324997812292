import { Button, Modal } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { forEach, get, set } from 'lodash';
import { useMemo, useState } from 'react';
import React from 'react';

export const CompareRates: React.FC<{ rates: any[] }> = ({ rates }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const columns: ColumnsType<any> = useMemo(() => {
    const arr: any[] = [];
    arr.push({
      title: 'Rate Comparison',
      dataIndex: 'name',
      key: 'name',
    });
    rates.forEach((rate) => {
      arr.push({
        title: rate.id,
        dataIndex: rate.id,
        key: rate.id,
      });
    });
    return arr;
  }, [rates]);

  const data = useMemo(() => {
    const items = {};
    const ids: number[] = [];
    const footers: string[] = [];
    forEach(rates, (rate, i) => {
      let total = 0;
      ids.push(rate.id);
      forEach(
        [...rate.regular_rate_items, ...rate.other_rate_items],
        (rateItem) => {
          total += parseFloat(rateItem['amount'] || 0);
          set(
            items,
            `${rateItem['name']}.[${i}]`,
            '$' +
              parseFloat(rateItem['amount'] || 0).toFixed(2) +
              ' / ' +
              rateItem['unit'],
          );
        },
      );
      footers.push('$' + total.toFixed(2));
    });

    const arr: any[] = Object.keys(items).map((key: string) => {
      const obj: any = {
        name: key,
      };

      forEach(get(items, key, []), (item: any, i: number) => {
        obj[ids[i]] = item;
      });

      return obj;
    });
    const _totals: any = {
      name: '',
    };
    forEach(footers, (item: any, i: number) => {
      _totals[ids[i]] = item;
    });
    arr.push(_totals);

    return arr;
  }, [rates]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button disabled={rates.length < 2} onClick={showModal}>
        Compare Rates
      </Button>
      <Modal
        cancelText="Close"
        footer={false}
        width={1000}
        title="Compare Rates"
        open={isModalVisible}
        onCancel={handleCancel}>
        <Table size="small" columns={columns} dataSource={data} />
      </Modal>
    </>
  );
};
