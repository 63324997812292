import { Space, Tooltip } from 'antd';

const LocationCell = ({
  location,
  contact = '',
  extraComponent,
}: {
  location: string;
  contact?: string;
  extraComponent?: React.ReactNode;
}) => {
  return (
    <Space size={16}>
      {extraComponent ?? <></>}
      <Tooltip
        overlayInnerStyle={{ width: '450px' }}
        title={location + '\n' + contact}
      >
        <div
          style={{
            fontSize: '12px',
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
          }}
        >
          <div>{location}</div>
          <div>{contact}</div>
        </div>
      </Tooltip>
    </Space>
  );
};

export default LocationCell;
