import { CACHE_GENERAL_LEDGER_CODE_KEY } from '@/stores/cache';
import { TGeneralLedgerCode } from '@/types';
import React from 'react';
import SyncCacheModelSelect, {
  SyncCacheModelSelectProps,
} from './SyncCacheModelSelect';

export const GeneralLedgerCodeSelect: React.FC<SyncCacheModelSelectProps> = ({
  ...props
}) => {
  return (
    <SyncCacheModelSelect
      {...props}
      cacheKey={CACHE_GENERAL_LEDGER_CODE_KEY}
      renderOption={(row: TGeneralLedgerCode) => [row.code, row.name].join('|')}
    />
  );
};
