import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Col,
  Tabs,
  Form,
  Input,
  Row,
  Dropdown,
  Table,
  Button,
  Card,
  Spin,
  Empty,
  message,
} from 'antd';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Header } from '@/components/CommonHeader';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { Task } from './components/Task';
import { TaskWarning } from './components/TaskWarning';
import type { MenuProps } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import Calendar from './components/Calendar';
import { PROGRESS_TYPE_MAP } from './components/ProgressTabs/data';
import { OPdashboardTabModelType } from '@/Interfaces/OPdashboardTab/OPdashboardTabType';
import { EditableText } from '@/components/Editable';
import { Filter } from './components/Filter';
import { ProgressDetail } from './components/ProgressTabs';
import {
  INavTabItem,
  NavTabs,
  setTabItem,
  SUB_TAB_PROGESS,
  SUB_TAB_FIXED,
} from './components/ProgressTabs/NavTabs';
import useProgressTab from './components/ProgressTabs/useProgressTab';
import { set } from 'lodash';

const SUB_TAB_TASK = '1';
const SUB_TAB_CALENDAR = '2';

const DEFAULT_SUB_TAB_TASK = {
  name: 'Task',
  key: SUB_TAB_TASK,
  type: SUB_TAB_FIXED,
  showDescription: false,
  showDate: false,
};
const DEFAULT_SUB_TAB_CALENDAR = {
  name: 'Calendar',
  key: SUB_TAB_CALENDAR,
  type: SUB_TAB_FIXED,
  showDescription: false,
  showDate: false,
};
const DEFAULT_SUB_TABS = [DEFAULT_SUB_TAB_TASK, DEFAULT_SUB_TAB_CALENDAR];

export interface SubTabInterface {
  activeOpdashboardTab: TOpdashboardTab;
  refresh: boolean;
  setRefresh: (refresh: boolean) => void;
  goToDispatch: (container_ids: Array<number>) => void;
}

type CustomProperties = {
  [key: string]: {
    filters: any[];
    columns: any[];
  };
};

export type TOpdashboardTab = {
  admin_id: number;
  created_at: string;
  admin_ids: any[];
  admins: Array<{
    id: number;
    name: string;
  }>;
  title: string;
  filters: any[];
  custom_properties: CustomProperties;
  id: number;
  name: string;
  updated_at: string;
};

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const { joinParams } = useProgressTab();

  const [selectedDates, setSelectedDates] = useState<Record<number, string>>();
  const [searchQuery, setSearchQuery] = useState(null);
  const [enableSearch, setEnableSearch] = useState(false);
  const [refreshSearch, setRefreshSearch] = useState(false);

  const [progressTabCounts, setProgressTabCounts] = React.useState<
    Record<
      string,
      | number
      | {
          actual: number;
          estimated: number;
        }
    >
  >({});

  const subTabs = useMemo(() => {
    const _progressTabs = Object.keys(PROGRESS_TYPE_MAP).map((progressType) => {
      const count =
        progressTabCounts && progressTabCounts[progressType]
          ? progressTabCounts[progressType]
          : 0;
      return setTabItem(progressType, count);
    });

    return Object.assign([], [...DEFAULT_SUB_TABS, ..._progressTabs]);
  }, [progressTabCounts]);

  const [opdashboardTabs, setOpdashboardTabs] = React.useState<
    TOpdashboardTab[]
  >([]);

  const [initShowFilter, setInitShowFilter] = useState<boolean>(false);

  const [
    activeOpdashboardTab,
    setActiveOpdashboardTab,
  ] = useState<TOpdashboardTab>();

  const [editTab, setEditTab] = React.useState<number>(0);

  const [activeSubTab, setActiveSubTab] = useState<any>(DEFAULT_SUB_TAB_TASK);

  const [refresh, setRefresh] = useState(false);

  const [filter] = useForm();

  const goToDispatch = async (container_ids: Array<number>) => {
    app.store.opDashboard.setContainerIds(container_ids);
    app.store.opDashboard.setOpdashboardTabId(activeOpdashboardTab?.id);
    window.open('/dispatchs', '_blank');
  };

  const goToTaskWarningDispatch = async (warningType: string) => {
    app.store.opDashboard.setTaskWarningType(warningType);
    app.store.opDashboard.setOpdashboardTabId(activeOpdashboardTab?.id);
    window.open('/dispatchs', '_blank');
  };

  const fetchTabs = async () => {
    setLoading(true);
    try {
      const resp = await app.service.get(
        `opdashboardTabs/${OPdashboardTabModelType.Container}`,
      );
      if (resp.data?.tabs.length > 0) {
        setOpdashboardTabs(resp.data.tabs || []);
        setActiveOpdashboardTab(resp.data.tabs[0]);
      }
      setInitShowFilter(true);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddTab = async () => {
    setLoading(true);
    try {
      const reps = await app.service.post(
        `opdashboardTabs/${OPdashboardTabModelType.Container}`,
        {
          data: { name: `Tab ${opdashboardTabs.length}` },
        },
      );
      setOpdashboardTabs([...opdashboardTabs, { ...reps.data, filters: [] }]);

      setInitShowFilter(true);

      setActiveOpdashboardTab(reps.data);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteTab = async (id: number) => {
    if (opdashboardTabs.length == 1) {
      return;
    }

    setLoading(true);
    try {
      await app.service.delete(`opdashboardTabs/${id}`);
      setOpdashboardTabs(opdashboardTabs.filter((t) => t.id != id));

      message.success('Tab has been delete');
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const tabMenu = (id: number): MenuProps['items'] => {
    const items: MenuProps['items'] = [
      {
        label: 'Edit',
        key: 'edit' + id,
        onClick: () => {
          setEditTab(id);
        },
      },
      {
        label: 'Delete',
        key: 'delete' + id,
        onClick: () => {
          handleDeleteTab(id);
        },
      },
    ];

    return items;
  };

  const handleChangeCustomTab = async (key: string) => {
    const activeTab = opdashboardTabs.find((tab) => tab.id == Number(key));

    if (!activeTab) {
      return;
    }

    setActiveSubTab(DEFAULT_SUB_TAB_TASK);
    setActiveOpdashboardTab(activeTab);

    setInitShowFilter(true);
  };

  const handleUpdateTab = async (
    name: string,
    value: string | any[] | Record<string, any>,
    id?: number,
  ) => {
    id = id || editTab;

    const tab = opdashboardTabs.find((tab) => tab.id === id);
    if (tab) {
      try {
        if (!name) {
          return;
        }

        const resp = await app.service.put(`opdashboardTabs/${id}`, {
          data: { ...tab, [name]: value },
        });

        setOpdashboardTabs(
          opdashboardTabs.map((t) => (t.id == id ? resp.data : t)),
        );

        setActiveOpdashboardTab(resp.data);
      } catch (err: any) {
        message.error(err.data?.message || err.data?.error);
      } finally {
        setEditTab(0);
      }
    }
  };

  const handleApply = async (values: any) => {
    if (!activeOpdashboardTab) {
      return;
    }

    const tab = await handleUpdateTab(
      'filters',
      values,
      activeOpdashboardTab.id,
    );

    fetchProgressTabCounts(
      joinParams(activeSubTab?.key, activeOpdashboardTab?.id),
    );

    setRefresh(true);
  };

  const fetchProgressTabCounts = async (params: any = {}) => {
    if (activeOpdashboardTab?.id == undefined) {
      return;
    }

    try {
      const resp = await app.service.get('dispatchTabs/opdashboardTabCounts', {
        params,
      });

      setProgressTabCounts(resp);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    }
  };

  const refreshProgressTabCount = async (
    progressType: string,
    params: any = {},
  ) => {
    if (!progressType) {
      return;
    }

    try {
      const resp = await app.service.get(
        `dispatchTabs/opdashboardTabCounts/${progressType}`,
        {
          params,
        },
      );

      setProgressTabCounts({
        ...progressTabCounts,
        [progressType]: resp.data,
      });

      // setRefresh(true);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    }
  };

  const handleChangeDate = (date: string) => {
    const _dates = {
      ...selectedDates,
      [activeSubTab.key]: date,
    };

    setSelectedDates(_dates);

    refreshProgressTabCount(
      activeSubTab.key,
      joinParams(activeSubTab.key, activeOpdashboardTab?.id, null, _dates),
    );
  };

  const handleChangeSubTab = (key: any) => {
    const tab = subTabs.find((tab) => tab.key == key);
    setActiveSubTab(tab);

    refreshProgressTabCount(
      tab.key,
      joinParams(tab.key, activeOpdashboardTab?.id, null, selectedDates),
    );
  };

  const updateCustomProperties = (properties: any) => {
    if (!activeOpdashboardTab?.id) {
      return;
    }

    const _properties = activeOpdashboardTab.custom_properties || {};

    _properties[activeSubTab.key] = {
      ..._properties[activeSubTab.key],
      ...properties,
    };

    handleUpdateTab('custom_properties', _properties, activeOpdashboardTab?.id);
  };

  useEffect(() => {
    fetchTabs();
  }, []);

  useEffect(() => {
    fetchProgressTabCounts(
      joinParams(
        activeSubTab?.key,
        activeOpdashboardTab?.id,
        null,
        selectedDates,
      ),
    );
  }, [activeOpdashboardTab?.id]);

  const search = () => {
    setRefresh(true);
    fetchProgressTabCounts(
      joinParams(
        activeSubTab.key,
        activeOpdashboardTab?.id,
        null,
        selectedDates,
      ),
    );
  };

  return (
    <div className={styles.main}>
      <Header
        title="Dispatch - New"
        rightElement={
          <Input.Search
            style={{ width: '40%' }}
            placeholder="Search by MBL#, CNTR#, #REF, #DO, #Order..."
            allowClear
            disabled={loading}
            enterButton="Search"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              if (!e.target.value) {
                setEnableSearch(false);
                setRefreshSearch(false);
              }
            }}
            onSearch={() => {
              setEnableSearch(true);
              setRefreshSearch(true);
            }}
          />
        }
      />

      {enableSearch ? (
        <ProgressDetail
          searchQuery={searchQuery}
          progressType={'searchQuery'}
          activeOpdashboardTab={activeOpdashboardTab}
          activeSubTab={activeSubTab}
          refresh={refreshSearch}
          setRefresh={setRefreshSearch}
          updateCustomProperties={updateCustomProperties}
          selectedDate={
            (selectedDates && selectedDates[activeSubTab?.key]) || null
          }
          goToDispatch={goToDispatch}
        />
      ) : (
        <div
          style={{
            padding: '0px 10px',
          }}
        >
          <Tabs
            key={'tabs'}
            activeKey={activeOpdashboardTab?.id.toString()}
            onChange={handleChangeCustomTab}
            tabBarExtraContent={
              <Button type="link" onClick={handleAddTab}>
                New Tab
              </Button>
            }
          >
            {opdashboardTabs?.length > 0 &&
              opdashboardTabs?.map((tab: TOpdashboardTab) => (
                <TabPane
                  tab={
                    editTab !== tab.id ? (
                      <Dropdown
                        menu={{ items: tabMenu(tab.id) }}
                        trigger={['contextMenu']}
                      >
                        <div>{tab.name}</div>
                      </Dropdown>
                    ) : (
                      <EditableText
                        value={tab.name}
                        onChange={handleUpdateTab}
                        onHide={() => setEditTab(0)}
                        name="name"
                        autoFocus
                      />
                    )
                  }
                  key={tab.id}
                ></TabPane>
              ))}
          </Tabs>

          <Spin spinning={loading}>
            {opdashboardTabs?.length > 0 ? (
              <>
                <div className="mb-md">
                  <Filter
                    loading={loading}
                    initShow={initShowFilter}
                    setInitShow={setInitShowFilter}
                    activeFilters={activeOpdashboardTab?.filters || []}
                    onSearch={search}
                    onApply={handleApply}
                  />
                </div>

                <div>
                  <NavTabs
                    items={subTabs}
                    activeKey={activeSubTab?.key}
                    onChange={handleChangeSubTab}
                    onChangeDate={handleChangeDate}
                  />

                  {activeSubTab?.type == SUB_TAB_FIXED &&
                    activeSubTab?.key == SUB_TAB_TASK && (
                      <Row>
                        <Col span={8}>
                          <Task
                            isTaskTab={activeSubTab?.key == SUB_TAB_TASK}
                            activeOpdashboardTab={activeOpdashboardTab}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            goToDispatch={goToDispatch}
                          />
                        </Col>
                        <Col span={7} className="ml-lg">
                          <TaskWarning
                            isTaskTab={activeSubTab?.key == SUB_TAB_TASK}
                            activeOpdashboardTab={activeOpdashboardTab}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            goToDispatch={goToDispatch}
                            goToTaskWarningDispatch={goToTaskWarningDispatch}
                          />
                        </Col>
                        {/* todo container issue */}
                        <Col span={7} className="ml-lg"></Col>
                      </Row>
                    )}
                  {activeSubTab?.type == SUB_TAB_FIXED &&
                    activeSubTab?.key == SUB_TAB_CALENDAR && (
                      <Calendar
                        isCalendarTab={activeSubTab.key == SUB_TAB_CALENDAR}
                        activeOpdashboardTab={activeOpdashboardTab}
                        refresh={refresh}
                        setRefresh={setRefresh}
                        goToDispatch={goToDispatch}
                      />
                    )}
                  {activeSubTab?.type == SUB_TAB_PROGESS && (
                    <ProgressDetail
                      activeOpdashboardTab={activeOpdashboardTab}
                      activeSubTab={activeSubTab}
                      updateCustomProperties={updateCustomProperties}
                      selectedDate={
                        (selectedDates && selectedDates[activeSubTab?.key]) ||
                        null
                      }
                      refresh={refresh}
                      setRefresh={setRefresh}
                      goToDispatch={goToDispatch}
                    />
                  )}
                </div>
              </>
            ) : (
              <Empty
                description={
                  'Click New Tab to start your OP Dashboard journey.'
                }
              ></Empty>
            )}
          </Spin>
        </div>
      )}
    </div>
  );
};

export default Index;
