import React from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Drawer,
  message,
  Space,
  Table,
  TablePaginationConfig,
  Tag,
} from 'antd';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { DrawerForm } from './components/DrawerForm';
import { useForm } from 'antd/lib/form/Form';
import { TConsignee, TCollection } from '@/types';
import { get } from 'lodash';

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [title, setTitle] = React.useState('Create Consignee');
  const [data, setData] = React.useState<TCollection<TConsignee>>();
  const [form] = useForm();

  const fetchData = React.useCallback(
    async (pagination?: TablePaginationConfig) => {
      setLoading(true);

      try {
        const result = await app.service.get('consignees', {
          params: {
            page: pagination?.current || 1,
            per_page: pagination?.pageSize || 20,
          },
        });

        setData(result);
      } catch (e: any) {
        message.error(e.error || 'System Error');
      }

      setLoading(false);
    },
    [],
  );

  const addNew = () => {
    form.resetFields();
    form.setFieldsValue({
      status: 0,
    });
    setTitle('Create Consignee');
    setVisible(true);
  };

  const handleSave = async () => {
    const values = await form.validateFields();
    setLoading(true);

    try {
      !values.id
        ? await app.service.post('consignees', { data: values })
        : await app.service.put(`consignees/${values.id}`, { data: values });
      message.success('Saved');
      fetchData();
      setVisible(false);
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  const handleEdit = async (consignee: TConsignee) => {
    setLoading(true);

    try {
      setTitle('Edit Consignee');
      form.resetFields();
      setVisible(true);
      form.setFieldsValue(consignee);
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  const columns: ColumnsType<any> = React.useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 80,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 200,
      },
      {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        width: 300,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: 200,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 200,
        render: (text, record) => {
          const status = get(record, 'status', false);
          const status_name = get(record, 'status_name', false);
          if (status === false) {
            return <></>;
          }
          if (status === 0) {
            return <Tag color="default">{status_name}</Tag>;
          }
          if (status === 2) {
            return <Tag color="success">{status_name}</Tag>;
          }
          if (status === -1) {
            return <Tag color="red">{status_name}</Tag>;
          }
          return <></>;
        },
      },
      {
        align: 'center',
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 150,
        render: (text, record) => (
          <Space>
            <a onClick={() => handleEdit(record)}>Edit</a>
          </Space>
        ),
      },
    ],
    [],
  );

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.main}>
      <Header
        title="Consignees"
        rightElement={
          <div>
            <Button type="primary" onClick={addNew}>
              New Consignee
            </Button>
          </div>
        }></Header>

      <Table
        loading={loading}
        pagination={false}
        size="small"
        rowKey="id"
        columns={columns}
        onChange={fetchData}
        dataSource={data?.data || []}
        sticky
        scroll={{
          x: 'auto',
        }}
      />

      <Drawer
        title={title}
        placement="right"
        width="40%"
        onClose={() => setVisible(false)}
        destroyOnClose={true}
        open={visible}
        extra={
          <Space>
            <Button onClick={() => setVisible(false)}>Close</Button>
            <Button type="primary" loading={loading} onClick={handleSave}>
              Save
            </Button>
          </Space>
        }>
        <DrawerForm form={form} onSubmit={handleSave} />
      </Drawer>
    </div>
  );
};

export default Index;
