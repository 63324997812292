import React, { useCallback, useState } from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  message,
  Popconfirm,
  Space,
  Table,
  TablePaginationConfig,
  Tag,
  Tooltip,
} from 'antd';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { useForm } from 'antd/lib/form/Form';
import { TBill, TCollection } from '@/types';
import usePagination from '@/components/usePagination';
import Import from './components/Import';
import { SorterResult } from 'antd/lib/table/interface';
import ReceivePayment from './components/MakePayment';
import Pierpass from './components/Pierpass';
import CTF from './components/CTF';
import EditBill from './components/EditBill';
import BulkApprove from './components/BulkApprove';
import AutoChassisDispute from './components/AutoChassisDispute';
import AutoResizeTable from '@/components/AutoResizeTable';
import _, { truncate, upperCase, upperFirst } from 'lodash';
import { LogActivities } from '@/components/LogActivities';
import { DISPUTE_TYPE_BILL, DisputeMemo } from '@/components/DisputeMemo';
import { BILL_TYPE_FTL, STATUS_MAP } from './components/data';
import { useLocation } from 'umi';
import { ShipmentBusinessStatusLabelMap as LtlShipmentBusinessStatusLabelMap } from '@/pages/truckload/ltl/constants';
import { ShipmentBusinessStatusLabelMap as FtlShipmentBusinessStatusLabelMap } from '@/pages/truckload/ftl/constants';

import {
  BILL_TYPE_LTL,
  BILL_TYPE_ORDER,
  BILLABLE_TYPE,
} from './components/data';
import { Filter } from './components/Filter';

// const INDISPUTE = 1;
// const CLOSEDDISPUTE = 2;
// const NODISPUTE = 3;

const Index: React.FC<{ billableType?: BILLABLE_TYPE }> = ({
  billableType,
}) => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [selected, setSelected] = React.useState<Array<TBill>>([]);
  const [data, setData] = React.useState<TCollection<TBill>>();
  const [filter] = useForm();
  const [sorter, setSorted] = useState<SorterResult<TBill> | undefined>();
  const [showAllFilters, setShowAllFilters] = React.useState(false);
  const [openId, setOpenId] = React.useState(0);
  const pagination = usePagination(data);

  const rowSelection = {
    selected,
    renderCell: (checked, record, index, originNode) => {
      if (!record.is_approved) {
        return (
          <Tooltip
            placement="topLeft"
            title={'Only approved bill can be paied'}
          >
            {originNode}
          </Tooltip>
        );
      }
      return originNode;
    },
    onChange: (_: React.Key[], selectedRows: TBill[]) => {
      setSelected(selectedRows);
    },
  };

  const fetchData = React.useCallback(
    async (
      pagination?: TablePaginationConfig,
      _: any = {},
      _sorter?: SorterResult<TBill>,
    ) => {
      setLoading(true);
      setSorted(_sorter);

      try {
        const result = await app.service.get('orderBills', {
          params: {
            ...filter.getFieldsValue(),
            ..._,
            // set billabletype if it set
            ...(billableType ? { billable_type: billableType } : {}),
            page: pagination?.current || 1,
            per_page: pagination?.pageSize || 20,
            sort_by: _sorter?.columnKey || _sorter?.field,
            sort_value:
              _sorter?.order === 'ascend'
                ? 'asc'
                : _sorter?.order === 'descend'
                ? 'desc'
                : undefined,
          },
        });

        setData(result);
      } catch (e: any) {
        message.error(e.data?.message || e.data?.error || 'System Error');
      }

      setLoading(false);
    },
    [sorter, setSorted],
  );

  const refreshData = useCallback(async () => {
    await fetchData(pagination, null, sorter);
  }, [pagination.current, sorter]);

  const handleRemove = async (id: number) => {
    setLoading(true);

    try {
      await app.service.delete(`orderBills/${id}`);

      message.success('Deleted');

      // fetchData();
      refreshData();
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  const handleExport = async () => {
    setLoading(true);

    try {
      await app.service.download('orderBills/export', {
        params: {
          ...(billableType ? { billable_type: billableType } : {}),
          ...filter.getFieldsValue(),
        },
      });
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
    setLoading(false);
  };

  const columns: ColumnsType<any> = React.useMemo(
    () => [
      {
        title: '#',
        dataIndex: 'uid',
        key: 'id',
        sorter: true,
        width: 80,
        fixed: 'left',
        render: (text, record) => (
          <EditBill
            billableId={record.billable_id}
            billableType={record.billable_type}
            id={record.id}
            onSaved={() => refreshData()}
          >
            <a>{text}</a>
          </EditBill>
        ),
      },
      {
        title: 'Order#/LTL#/FTL#',
        dataIndex: ['order', 'uid'],
        key: 'order_id',
        sorter: true,
        width: 160,
        fixed: 'left',
        render: (text, record) => {
          return record?.billable?.uid || '';
        },
      },
      {
        title: 'CNTR#/Shipment#',
        dataIndex: 'container_numbers',
        key: 'container_numbers',
        // render: (text) => (text ? text.join(',') : '-'),
        sorter: true,
        width: 150,
        fixed: 'left',
        render: (text, record) => {
          if (record.billable_type == BILL_TYPE_ORDER) {
            return (
              <>
                {record.container_numbers
                  ? record.container_numbers.join(',')
                  : '-'}
              </>
            );
          }
          if (record.billable_type == BILL_TYPE_LTL) {
            return record.billable?.pro_number || '';
          }
        },
      },
      {
        title: 'INV#',
        dataIndex: 'invoice_number',
        key: 'invoice_number',
        sorter: true,
        width: 160,
        fixed: 'left',
      },
      {
        title: 'Order Dispatch',
        dataIndex: ['billable', 'dispatch_status'],
        key: 'order_status_name',
        width: 160,
        render: (text, record) => {
          if (record.billable_type == BILL_TYPE_ORDER) {
            return upperFirst(record.billable?.state);
          } else if (record.billable_type == BILL_TYPE_LTL) {
            return (
              <>
                {
                  LtlShipmentBusinessStatusLabelMap[
                    record.billable?.business_status
                  ]
                }
              </>
            );
          } else if (record.billable_type == BILL_TYPE_FTL) {
            return (
              <>
                {
                  FtlShipmentBusinessStatusLabelMap[
                    record.billable?.business_status
                  ]
                }
              </>
            );
          }
        },
      },
      {
        title: 'Sales Support',
        dataIndex: ['order', 'sales_support', 'name'],
        ellipsis: {
          showTitle: false,
        },
        width: 150,
      },
      {
        title: 'Customer',
        dataIndex: ['order', 'user', 'name'],
        ellipsis: {
          showTitle: false,
        },
        width: 200,
        render: (text, record) => (
          <Tooltip placement="topLeft" title={text}>
            {truncate(text, { length: 20 })}{' '}
            {record.order?.user?.name
              ? `(${record.order?.user?.bill_to?.name})`
              : ''}
          </Tooltip>
        ),
      },
      {
        title: 'Approve',
        key: 'is_approved',
        width: 100,
        render: (record: TBill) =>
          record.is_approved ? (
            <Tag color="processing">Approved</Tag>
          ) : (
            <Tag color="default">Unapproved</Tag>
          ),
      },
      {
        title: 'Dispute',
        key: 'dispute_at',
        width: 130,
        render: (record: TBill) => {
          return (
            <>
              <DisputeMemo
                type={DISPUTE_TYPE_BILL}
                model={record}
                onSaved={() => refreshData()}
                icon={
                  record?.dispute_status ? (
                    <Tag color="error">
                      {
                        STATUS_MAP[
                          (record.dispute_status as unknown) as keyof typeof STATUS_MAP
                        ]
                      }
                    </Tag>
                  ) : (
                    <></>
                  )
                }
                open={openId == record.id}
              />
            </>
          );
        },
      },
      {
        title: 'Sales',
        dataIndex: ['billable', 'sales', 'name'],
        key: 'sales_name',
        sorter: true,
        width: 100,
      },
      {
        title: 'OP',
        dataIndex: ['billable', 'operator', 'name'],
        key: 'OP',
        sorter: true,
        width: 100,
      },
      {
        title: 'Bill From',
        dataIndex: ['bill_to', 'name'],
        width: 150,
        ellipsis: {
          showTitle: false,
        },
        render: (address) => (
          <Tooltip placement="topLeft" title={address}>
            {address}
          </Tooltip>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'state',
        width: 100,
      },
      {
        title: 'Amount Total',
        dataIndex: 'amount_total',
        key: 'amount_total',
        sorter: true,
        width: 100,
      },
      {
        title: 'Amount Due',
        dataIndex: 'amount_due',
        key: 'amount_due',
        sorter: true,
        width: 100,
      },
      {
        title: 'Amount Paid',
        dataIndex: 'amount_paid',
        key: 'amount_paid',
        sorter: true,
        width: 100,
      },
      {
        title: 'Billed At',
        dataIndex: 'billed_at',
        key: 'billed_at',
        sorter: true,
        width: 120,
      },
      {
        title: 'Due At',
        dataIndex: 'due_at',
        key: 'due_at',
        sorter: true,
        width: 120,
      },
      {
        title: 'Paid At',
        dataIndex: 'paid_at',
        key: 'paid_at',
        sorter: true,
        width: 120,
      },
      {
        title: 'Cleared At',
        dataIndex: 'cleared_at',
        key: 'cleared_at',
        sorter: true,
        width: 120,
      },
      {
        align: 'center',
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 150,
        render: (text, record) => (
          <Space>
            <EditBill
              billableId={record.billable_id}
              billableType={record.billable_type}
              id={record.id}
              onSaved={() => refreshData()}
            >
              <a>Edit</a>
            </EditBill>
            <Popconfirm
              placement="left"
              title="Sure to delete?"
              okText="Confirm"
              cancelText="Cancel"
              onConfirm={() => handleRemove(record.id)}
            >
              <a>Delete</a>
            </Popconfirm>
            <LogActivities
              id={record.id}
              icon={<a> Log</a>}
              type="orderBills"
            />
          </Space>
        ),
      },
    ],
    [sorter, setSorted, openId, pagination],
  );

  const footer = React.useCallback(() => {
    let amount_total = 0,
      amount_due = 0,
      amount_paid = 0;

    selected.forEach((row) => {
      amount_due += parseFloat((row.amount_due as any) || 0);
      amount_total += parseFloat((row.amount_total as any) || 0);
      amount_paid += parseFloat((row.amount_paid as any) || 0);
    });
    return (
      selected &&
      selected.length > 0 && (
        <Table.Summary fixed>
          <Table.Summary.Row>
            <Table.Summary.Cell index={0} colSpan={13}>
              Total Selected {selected.length}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1}>
              {amount_total.toFixed(2)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2}>
              {amount_due.toFixed(2)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={3}>
              {amount_paid.toFixed(2)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={4} colSpan={10}></Table.Summary.Cell>
          </Table.Summary.Row>
        </Table.Summary>
      )
    );
  }, [selected]);

  const location = useLocation();

  React.useEffect(() => {
    // fetchData();
    const queryString = location.search;
    if (queryString) {
      const params = new URLSearchParams(queryString);
      const _params: any = {};
      const billId: any = params.get('billId') || 0;
      const salesId: any = params.get('salesId') || 0;
      const billTo = params.get('billTo') || 0;
      const due_start_date = params.get('dueStartDate') || '';
      const due_end_date = params.get('dueEndDate') || '';
      const state = params.get('state') || '';

      if (billId) {
        _params.id = billId;
      }

      if (billTo && billTo != 'Total') {
        _params.bill_to = billTo;
      }

      if (due_start_date) {
        _params.date_field = 'due_at';
        _params.start_date = due_start_date;
      }

      if (due_end_date) {
        _params.date_field = 'due_at';
        _params.end_date = due_end_date;
      }

      if (salesId) {
        _params.has_due_amount = 'true';
        _params.releated_person_id = +salesId;
      }

      if (state) {
        _params.dispute = state;
      }

      filter.setFieldsValue(_params);
      fetchData(pagination, _params);
      setOpenId(billId);
    } else {
      fetchData();
    }
  }, [location.search]);

  return (
    <div className={styles.main}>
      <Header
        title="Bills"
        rightElement={
          <Space>
            <BulkApprove bills={selected} onSaved={refreshData} />

            <Button type="primary" onClick={handleExport} disabled={loading}>
              Export
            </Button>
            <ReceivePayment onSaved={refreshData} bills={selected} />
            <Import onUploaded={refreshData} />
            <Pierpass onUploaded={refreshData} />
            <CTF onUploaded={refreshData} />
            <AutoChassisDispute onSaved={refreshData} bills={selected} />
          </Space>
        }
      ></Header>
      <Filter
        filter={filter}
        billableType={billableType}
        onSearch={() => fetchData()}
        loading={loading}
      />

      <AutoResizeTable
        loading={loading}
        pagination={pagination}
        size="small"
        rowKey="id"
        columns={columns}
        onChange={fetchData}
        dataSource={data?.data || []}
        sticky
        rowSelection={rowSelection}
        summary={footer}
      />
    </div>
  );
};

export default Index;
