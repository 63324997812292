import React from 'react';
import {
  TTarget,
  TARGET_TYPE_LTL_SHIPMENT,
  TARGET_TYPE_FTL_SHIPMENT,
} from '../Interfaces/ActionInterface';
import LtlShipmentTaskActions from '../Actions/ltlShipment';
import FtlShipmentTaskActions from '../Actions/ftlShipment';
import {
  OnDoneInterface,
  onSaveOperationInterface,
} from '../Interfaces/TaskInterface';

function withConditionalRendering(WrappedComponentLTL, WrappedComponentFTL) {
  return function ConditionalRenderer(
    props: TTarget & OnDoneInterface & onSaveOperationInterface,
  ) {
    switch (props.target) {
      case TARGET_TYPE_LTL_SHIPMENT:
        return <WrappedComponentLTL {...props} />;
      case TARGET_TYPE_FTL_SHIPMENT:
        return <WrappedComponentFTL {...props} />;
      default:
        return null;
    }
  };
}

const ConditionalShipmentTaskActions = withConditionalRendering(
  LtlShipmentTaskActions,
  FtlShipmentTaskActions,
);

const ActionColumn: React.FC<TTarget> = (props) => {
  return <ConditionalShipmentTaskActions {...props} />;
};

export default ActionColumn;
