const CUSTOMER = 'user_id';
const OPERATOR = 'operator_id';
const PROCUREMENT = 'procurement_id';
const SALES = 'sales_id';
const SALES_SUPPORT = 'sales_support_id';
const BUSINESS_STATUS = 'business_status';
const VENDOR = 'vendor_id';
const CARRIER = 'carrier';
const BUSINESS_DEVELOPMENT = 'business_development_id';
const AGENT = 'agent_id';
const TASK_DONE = 'task_done_id';
const TAGS = 'tags';
export default {
  CUSTOMER,
  OPERATOR,
  PROCUREMENT,
  AGENT,
  SALES,
  BUSINESS_DEVELOPMENT,
  SALES_SUPPORT,
  BUSINESS_STATUS,
  VENDOR,
  CARRIER,
  TASK_DONE,
  TAGS,
} as const;
