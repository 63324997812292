import React from 'react';
import {
  TTarget,
  TARGET_TYPE_LTL_SHIPMENT,
  TShipmentAction,
} from '../../Interfaces/ActionInterface';
import LtlShipmentTaskColumn from './LtlShipmentTaskColumn';
import DefaultTaskColumn from './DefaultTaskColumn';

function withDynamicRendering(
  getComponent: (props: TTarget) => React.ElementType,
  FallbackComponent: React.ElementType,
) {
  return function DynamicRender(
    props: TTarget & TShipmentAction & { children?: React.ReactNode },
  ) {
    const { children, ...rest } = props;
    if (children) {
      return <>{children}</>;
    }

    const ComponentToRender = getComponent(rest) || FallbackComponent;

    return <ComponentToRender {...rest} />;
  };
}

const ShipmentTaskColumn: React.FC<TTarget> = (props) => {
  const determineComponent = (props: TTarget) => {
    switch (props.target) {
      case TARGET_TYPE_LTL_SHIPMENT:
        return LtlShipmentTaskColumn;
      default:
        return null;
    }
  };

  const DynamicShipmenTaskColumn = withDynamicRendering(
    determineComponent,
    DefaultTaskColumn,
  );

  return <DynamicShipmenTaskColumn {...props} />;
};

export default ShipmentTaskColumn;
