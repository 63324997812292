export enum AvailableTimeType {
  SPECIFIC_TIME = 'specific_time',
  RANGE = 'range',
  ANYTIME = 'anytime',
}

export const AVAILABLE_TIME_TYPE_MAP = {
  [AvailableTimeType.SPECIFIC_TIME]: 'Time',
  [AvailableTimeType.RANGE]: 'Range',
  [AvailableTimeType.ANYTIME]: 'Anytime',
};
