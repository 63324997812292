import { Button } from 'antd';
import React, { useEffect } from 'react';
import { ModelSelect } from './ModelSelect';
import { TerminalRedirectLink } from './TerminalRedirectLink';

export const TerminalSelect: React.FC<{
  open?: boolean;
  autoFocus?: boolean;
  size?: string;
  style?: any;
  disabled?: boolean;
  selected: any;
  redirectable?: boolean;
  placeholder?: string;
  searchParams?: any;
  onSelect: (id: string) => void;
  onBlur?: () => void;
  className?: string;
  renderOption?: (v: any) => void;
}> = ({
  selected,
  redirectable = false,
  searchParams,
  onSelect,
  disabled = false,
  renderOption = (v: any) => {
    return (
      <>
        <strong>
          {v.firms} | {v.name}
        </strong>
        <br />
        <small>{v.type}</small>
        <br />
        {v.street && (
          <>
            <span>{v.street}</span>
            <br />
          </>
        )}
        <span>
          {v?.city?.full_name} {v.zipcode}
        </span>
      </>
    );
  },
  ...props
}) => {
  const modelSelectContent = (
    <ModelSelect
      disabled={disabled}
      className={
        redirectable
          ? 'select-modal-with-icon-width'
          : 'select-modal-without-icon-width'
      }
      {...props}
      searchParams={searchParams}
      selected={selected}
      onSelect={onSelect}
      renderOption={renderOption}
      model="terminals"
    />
  );
  return (
    <>
      {redirectable ? (
        <div className="flex">
          <Button
            disabled={selected && selected.id ? false : true}
            type="link"
            className="ant-btn-icon-only select-modal-icon"
          >
            <TerminalRedirectLink id={selected?.id || 0} />
          </Button>
          {modelSelectContent}
        </div>
      ) : (
        modelSelectContent
      )}
    </>
  );
};
