import React from 'react';
import { Popover, Tabs, Tooltip, DatePicker } from 'antd';
import { InfoCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import { OPdashboardSubTabInterface } from '../../Interfaces/OPdashboardTab/OPdashboardSubTabInterface';

const RenderCount: React.FC<{
  isActive: boolean;
  count: OPdashboardSubTabInterface['count'];
}> = ({ isActive, count }) => {
  return (
    <span
      style={{
        borderRadius: '100px',
        background: isActive ? '#F0F9FF' : '#F0F0F0',
        marginLeft: '2px',
      }}
    >
      {typeof count === 'number' ? (
        count
      ) : (
        <>
          {`${count?.actual_total} / ${count?.est_total}`}{' '}
          <Tooltip title={'ACT / EST'}>
            <InfoCircleOutlined />
          </Tooltip>
        </>
      )}
    </span>
  );
};

export interface INavTabItem extends OPdashboardSubTabInterface {
  showDate: boolean;
  showCount: boolean;
}

export const NavTabs: React.FC<{
  items: Array<INavTabItem>;
  activeKey: string;
  onChange: (key: string) => void;
  onChangeDate: (date: string) => void;
}> = ({ items, activeKey, onChange, onChangeDate }) => {
  return (
    <Tabs
      className="pl-sm pr-sm"
      activeKey={activeKey}
      type="card"
      onChange={onChange}
      items={items.map((tab: INavTabItem) => {
        const isActive = activeKey == tab.id;
        return {
          label: (
            <div className="pl-sm">
              {tab.description && (
                <Tooltip title={tab.description} style={{ marginRight: '2px' }}>
                  <InfoCircleOutlined size={'small'} />
                </Tooltip>
              )}
              <span
                style={{
                  color: isActive ? '#2F6EC0' : '#8C8C8C',
                  fontWeight: '400',
                }}
              >
                {tab.name}{' '}
                {tab.showCount && (
                  <RenderCount count={tab.count} isActive={isActive} />
                )}
              </span>

              {tab.showDate && (
                <Popover
                  placement="bottom"
                  className="ml-xs"
                  content={
                    <DatePicker
                      getPopupContainer={(trigger: any) =>
                        trigger.parentElement
                      }
                      size="middle"
                      showToday
                      clearIcon={null}
                      // value={value ? moment.tz(value, 'America/Los_Angeles') : null}
                      format="YYYY-MM-DD"
                      defaultValue={moment.tz('America/Los_Angeles')}
                      onChange={(value) => {
                        onChangeDate(value?.format('YYYY-MM-DD') || '');
                      }}
                    />
                  }
                >
                  <ClockCircleOutlined />
                </Popover>
              )}
            </div>
          ),
          key: tab.id,
        };
      })}
    ></Tabs>
  );
};
