import React, { useMemo } from 'react';
import { Popover } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

import { TargetModelInterface } from './Interfaces/TargetInterface';
import { StatusLabelMap } from './data';

interface TroubleStatusIconProps {
  targetModel: Partial<TargetModelInterface>;
}

const TroubleStatusIcon: React.FC<TroubleStatusIconProps> = ({
  targetModel,
}) => {
  if (
    !targetModel.trouble_statuses ||
    targetModel.trouble_statuses.length === 0
  ) {
    return <></>;
  }

  const troubleStatus: string[] = useMemo(() => {
    return targetModel.trouble_statuses
      ? targetModel.trouble_statuses
          .filter((tr) => tr.occurred)
          .map((tr) => tr.status)
      : [];
  }, [targetModel.trouble_statuses]);

  if (troubleStatus.length === 0) {
    return <></>;
  }

  return (
    <Popover
      placement="right"
      content={
        <>
          {troubleStatus.map((status, index) => (
            <div key={index}>
              <strong></strong>
              {
                StatusLabelMap[
                  (status as unknown) as keyof typeof StatusLabelMap
                ]
              }
            </div>
          ))}
        </>
      }
      title="Trouble Status"
    >
      <span className="text-danger">
        <WarningOutlined />
      </span>
    </Popover>
  );
};

export default TroubleStatusIcon;
