import { Input, Form, Select, Checkbox, Switch } from 'antd';
import React from 'react';
import { IAttribute } from '.';

export const HasContainerYard: React.FC<IAttribute> = ({ form }) => {
  return (
    <>
      <Form.Item
        label="Has Container Yard"
        name="has_container_yard"
        valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue, setFieldValue }) =>
          getFieldValue('has_container_yard') && (
            <>
              <Form.Item
                label="Yard Count"
                name="yard_count"
                rules={[{ required: true, message: 'Yard Count is required' }]}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}>
                <Input type={'number'} placeholder="Enter Yard count" />
              </Form.Item>
              <Form.Item
                label="Total Yard Size"
                name="total_yard_size"
                rules={[
                  { required: true, message: 'Total Yard Size is required' },
                ]}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}>
                <Input type={'number'} placeholder="Enter Total Yard Size" />
              </Form.Item>
            </>
          )
        }
      </Form.Item>
    </>
  );
};
