import { Row } from 'antd';
import React, { useEffect } from 'react';

export const SelectedMcWarning: React.FC<{
  selectedMc: string | undefined;
  truckerMc: string;
}> = ({ selectedMc, truckerMc }) => {
  useEffect(() => {
    console.log(selectedMc, truckerMc, selectedMc != truckerMc);
  }, [selectedMc, truckerMc]);
  return (
    <>
      {selectedMc && truckerMc && selectedMc != truckerMc && (
        <Row gutter={10} className="text-danger">
          The MC of selected does not match vendor’s MC. Please double check.˝
        </Row>
      )}
    </>
  );
};
