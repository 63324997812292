import { Button, Input, message, Modal, Popconfirm, Select, Tag } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useApp } from '@/utils/useapp';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

export const SpecialRequirements: React.FC<{
  id: number;
  specialRequirements: { name: string }[];
  onChange: (v: any) => void;
}> = ({ id, specialRequirements, onChange }) => {
  const [visible, setVisible] = useState(false);
  const [newSR, setNewSR] = useState('');
  const [options, setOptions] = useState<any[]>([]);
  const [edit, setEdit] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const app = useApp();

  const handleSave = async () => {
    try {
      const resp = await app.service.post('specialRequirements', {
        data: {
          name: newSR,
        },
      });
      setNewSR('');
      setOptions([...options, resp.data]);
      setVisible(false);
      message.success('saved');
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
  };

  const loadOptions = async () => {
    try {
      const resp = await app.service.get('specialRequirements');

      setOptions(resp.data);
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
  };

  useEffect(() => {
    loadOptions();
  }, []);

  const selected = useMemo(() => {
    return specialRequirements.map((s: any) => {
      console.log(s);
      return {
        label: s.name,
        value: s.id,
        id: s.id,
      };
    });
  }, [specialRequirements]);

  const handleSelect = (v: any) => {
    onChange([...specialRequirements, options.find((o: any) => o.id === v)]);
  };

  const handlDeSelect = (v: any) => {
    onChange([...specialRequirements].filter((s: any) => s.id !== v));
  };

  const handleDeleteOption = async (id: number, index: number) => {
    try {
      await app.service.delete(`specialRequirements/${id}`);
      const copy = [...options];
      copy.splice(index, 1);
      setOptions(copy);
      handlDeSelect(id);
      message.success('removed');
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
  };

  const handleUpdate = async () => {
    if (!edit) {
      return;
    }
    try {
      await app.service.put(`specialRequirements/${edit.id}`, {
        data: { name: edit.name },
      });
      await loadOptions();
      setEdit(null);
      message.success('updated');
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
  };

  const tagRender = (props: any) => {
    const { label, closable, onClose } = props;
    return (
      <Tag closable={closable} onClose={onClose}>
        {label}
      </Tag>
    );
  };

  return (
    <>
      <div className="flex">
        {/* <Button
          onClick={() => {
            setVisible(true);
          }}
          className="ant-btn-icon-only select-modal-icon">
          <EditOutlined
            style={{
              color: '#00000073',
            }}
          />
        </Button> */}
        <Select
          value={selected}
          tagRender={tagRender}
          onSelect={handleSelect}
          onDeselect={handlDeSelect}
          mode="multiple"
          style={{
            width: '100%',
          }}
          filterOption={(input, option) => {
            if (option?.children?.length) {
              return option?.children
                ?.toLowerCase()
                .includes(input.toLowerCase());
            }
            return false;
          }}>
          {options.map((option: any, index: number) => (
            <Select.Option key={option.id} value={option.id}>
              {option.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <Modal
        zIndex={1009}
        title="Add new Special Requirement"
        onOk={handleSave}
        onCancel={() => setVisible(false)}
        open={visible}>
        <Input
          name="name"
          value={newSR}
          onChange={(e) => {
            setNewSR(e.target.value);
          }}
        />
      </Modal>
      <Modal
        zIndex={1009}
        title="Edit Special Requirement"
        onOk={handleUpdate}
        onCancel={() => setEdit(null)}
        open={!!edit}>
        <Input
          name="name"
          value={edit?.name || ''}
          onChange={(e) => {
            if (edit) {
              setEdit({ ...edit, name: e.target.value });
            }
          }}
        />
      </Modal>
    </>
  );
};
