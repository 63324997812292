import { Tooltip, TooltipProps } from 'antd';
import React from 'react';

interface OverflowTextProps
  extends React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
  > {
  row?: number;
  enableTooltip?: boolean;
  tooltipConfig?: TooltipProps;
}

const getStyle = (row: number) => {
  if (row < 1) {
    return {};
  }
  const baseStyle = {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };
  let otherStyle = {};
  if (row === 1) {
    otherStyle = {
      whiteSpace: 'nowrap',
    };
  }
  if (row > 1) {
    otherStyle = {
      display: '-webkit-box',
      WebkitLineClamp: row,
      WebkitBoxOrient: 'vertical',
    };
  }
  return {
    ...baseStyle,
    ...otherStyle,
  };
};

const Index = ({
  row,
  children,
  style,
  enableTooltip = true,
  tooltipConfig = {},
  ...res
}: OverflowTextProps) => {
  return (
    <div
      {...res}
      style={{
        ...style,
        ...getStyle(row ?? 1),
      }}>
      {enableTooltip ? (
        <Tooltip {...tooltipConfig} title={children}>
          {children}
        </Tooltip>
      ) : (
        { children }
      )}
    </div>
  );
};

export default Index;
