import { useApp } from '@/utils/useapp';
import { List, message, Popover } from 'antd';
import { useState } from 'react';
import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { TTarget } from '../Interfaces/ActionInterface';
import {
  TaskOperationLogInterface,
  ACTION_LABELS,
} from '../Interfaces/TaskOperationLogInterface';
import { get } from 'lodash';

const OperationLogs: React.FC<TTarget & { operationId: string }> = ({
  target,
  targetModel,
  operationId,
}) => {
  const app = useApp();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const handleOpenChange = async (open: boolean) => {
    if (!open) {
      return;
    }

    await fetchData();
  };

  const fetchData = async () => {
    if (!target || !targetModel?.id || !operationId) {
      return;
    }

    setLoading(true);
    app.service
      .get(`tasks/${target}/${targetModel.id}/${operationId}`)
      .then((resp) => {
        setData(resp.data);
      })
      .catch((err: any) => {
        message.error(err.data?.message || err.data?.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Popover
        mouseEnterDelay={0.05}
        mouseLeaveDelay={0.05}
        onOpenChange={handleOpenChange}
        content={
          <div
            style={{
              maxWidth: '60vw',
              maxHeight: '50vh',
              overflowY: 'scroll',
            }}
          >
            <List
              loading={loading}
              dataSource={data}
              renderItem={(log: TaskOperationLogInterface) => (
                <List.Item>
                  {get(log, 'operator.name', 'System')}{' '}
                  {log.value && (
                    <span>
                      {ACTION_LABELS[log.action] ?? 'update'} to{' '}
                      <strong>{log.value}</strong>
                    </span>
                  )}
                  {!log.value && <strong>{log.action}</strong>}
                  {` at ${log.action_at}`}
                </List.Item>
              )}
            />
          </div>
        }
      >
        <div className="text-gray cursor-pointer py-sm">
          <InfoCircleOutlined />
        </div>
      </Popover>
    </>
  );
};

export default OperationLogs;
