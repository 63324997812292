import { Col, Form, Input, InputNumber, Row, Select } from 'antd';
import React, { useEffect } from 'react';
import { GENERAL_LEDGER_CODE_TYPES_MAP } from './data';

export const DrawerForm: React.FC<any> = ({ form }) => {
  const handleChange = async (event: any) => {
    const { name, value } = event.target as HTMLInputElement;
    await form.setFieldsValue({ [name]: value });
  };

  return (
    <div>
      <Form form={form}>
        <Row gutter={10}>
          <Col span={24}>
            <Form.Item
              label="Code"
              name="code"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input placeholder="Enter Code" />
            </Form.Item>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input placeholder="Enter name" />
            </Form.Item>
            <Form.Item
              label="Type"
              name="type"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Select
                onChange={(value) =>
                  handleChange({
                    target: {
                      name: 'type',
                      value: value,
                    },
                  })
                }>
                {Object.keys(GENERAL_LEDGER_CODE_TYPES_MAP).map((key) => (
                  <Select.Option key={key} value={+key}>
                    {
                      GENERAL_LEDGER_CODE_TYPES_MAP[
                        key as unknown as keyof typeof GENERAL_LEDGER_CODE_TYPES_MAP
                      ]
                    }
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input placeholder="Enter Description" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
