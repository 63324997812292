import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { useApp } from '@/utils/useapp';
import type { Moment } from 'moment';
import moment from 'moment-timezone';
import { DateRender } from './DateRender';
import styles from 'res/css/calendar.scss';
import { SubTabInterface } from '../..';
import Calendar from '@/components/Calendar';
export interface GetCalendarItem {
  date: string; // "2024-01-28"
  schedule_terminal_pickup_total: number; // 0
  actual_terminal_pickup_total: number; // 0
  scheduled_delivery_total: number; // 0
  actual_delivery_total: number; // 0
  actual_empty_returned_total: number; // 0
  container_ids: number[]; // [ 1, 2, 3 ]
}

const _Calendar: React.FC<
  {
    isCalendarTab: boolean;
  } & SubTabInterface
> = ({
  activeOpdashboardTab,
  isCalendarTab = false,
  refresh = true,
  setRefresh,
  goToDispatch,
}) => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);

  const [dateRange, setDateRange] = useState({
    start: moment().startOf('month').startOf('week').format('YYYY-MM-DD'),
    end: moment().endOf('month').endOf('week').format('YYYY-MM-DD'),
  });

  const fetchData = async () => {
    if (!activeOpdashboardTab?.id || loading) {
      return;
    }

    setData([]);

    setLoading(true);

    try {
      const resp = await app.service.get(
        `opdashboard/calendar/${activeOpdashboardTab.id}`,
        {
          params: {
            date_range: dateRange,
            opdashboard_tab_id: activeOpdashboardTab.id,
          },
        },
      );

      setData(resp.data || []);

      refresh && setRefresh(false);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activeOpdashboardTab?.id && isCalendarTab && !refresh) {
      fetchData();
    }
  }, [isCalendarTab, activeOpdashboardTab]);

  useEffect(() => {
    if (activeOpdashboardTab?.id && isCalendarTab && refresh) {
      fetchData();
    }
  }, [refresh]);

  const dateCellRender = (value: Moment) => {
    const today = moment().tz('America/Los_Angeles');
    const valueInLA = moment(value).tz('America/Los_Angeles');
    const valueString = valueInLA.format('DD');
    const isToday = valueInLA.isSame(today, 'day');

    const record = data?.find(
      (row) => row.date == valueInLA.format('YYYY-MM-DD'),
    );

    return (
      <div className={`${styles.customDateCell}`}>
        <div onClick={() => console.log('')}>
          <div className={styles.dateRow}>
            <div className={`${isToday ? styles.customToday : styles.date}`}>
              {' '}
              {valueString}
            </div>
          </div>
          <DateRender
            currentData={record}
            today={today}
            goToDispatch={goToDispatch}
            isPre={valueInLA.isBefore(today, 'day')}
            isToday={isToday}
            isFuture={valueInLA.isAfter(today, 'day')}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    fetchData();
  }, [dateRange]);

  return (
    <Calendar
      loading={loading}
      dateCellRender={dateCellRender}
      updateDataRange={setDateRange}
    />
  );
};

export default _Calendar;
