import React, { useEffect, useMemo, useState } from 'react';
import {
  Divider,
  Switch,
  Drawer,
  DrawerProps,
  message,
  Space,
  Table,
  Dropdown,
  Menu,
  MenuProps,
  Popconfirm,
} from 'antd';
import { useApp } from '@/utils/useapp';
import { ColumnsType } from 'antd/es/table';
import { CONTRACT_RATE_TYPE_MAP } from '../../data';
import { useContractRateDrawer } from '../../contractRate';

interface Props {
  vendor_id: number;
  contractRates: any[];
  visible: boolean;
  intermodal_region_id: number;
  onClose?: DrawerProps['onClose'];
}

const Index: React.FC<Props> = ({
  vendor_id,
  contractRates,
  visible,
  intermodal_region_id,
  onClose,
}) => {
  const [loading, setLoading] = useState(false);
  const app = useApp();
  const [data, setData] = useState<any[]>([]);

  const { ContractRateFormDrawer, openContractRateFormDrawer, contractRate } =
    useContractRateDrawer();

  const fetchContractRates = async () => {
    setLoading(true);
    try {
      const resp = await app.service.get(`vendors/${vendor_id}`);
      const contractRates = resp.data.contract_rates;
      setData(contractRates);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContractRates();
  }, [contractRates]);

  useEffect(() => {
    if (!contractRate) {
      return;
    }
    const rows = [...data];
    const idx = rows.findIndex((r) => r.id == contractRate.id);
    rows[idx] = contractRate;
    setData(rows);
  }, [contractRate]);

  const handleDelete = async (id: number) => {
    try {
      await app.service.delete(`contractRates/${id}`);
      const rows = [...data];
      const index = rows.findIndex((r) => r.id == id);
      rows.splice(index, 1);
      setData(rows);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    }
  };

  const handleAdd = async (type: number) => {
    try {
      const resp = await app.service.post('contractRates', {
        data: {
          type,
          vendor_id,
          terminal_ids: [],
          intermodal_region_id,
        },
      });
      const rows = [...data];
      rows.push(resp.data);
      setData(rows);
      message.success('Added');
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    }
  };

  const handleEnable = async (id: number, enabled: boolean) => {
    try {
      const resp = await app.service.patch(`contractRates/${id}`, {
        data: {
          enabled,
        },
      });
      const rows = [...data];
      const index = rows.findIndex((r) => r.id == id);
      rows[index] = resp.data;
      setData(rows);
      message.success('Updated');
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    }
  };

  const handleEdit = (record: any) => {
    openContractRateFormDrawer && openContractRateFormDrawer(record);
  };

  const handleGenerateRate = async (id: number) => {
    try {
      await app.service.post(`contractRates/${id}/generateRates`);
      message.success('Generated');
    } catch (err: any) {
      // console.log(err.data);
      message.error(err.data?.message || err.data?.error);
    }
  };

  const defaultColumns: ColumnsType<any> = [
    {
      title: 'Enable',
      dataIndex: 'enabled',
      key: 'enabled',
      width: 80,
      render: (text, record) => (
        <Switch
          checked={record.enabled}
          onChange={() => handleEnable(record.id, !record.enabled)}
        />
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 80,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 80,
      render: (text, record) => (
        <>
          {
            CONTRACT_RATE_TYPE_MAP[
              record.type as unknown as keyof typeof CONTRACT_RATE_TYPE_MAP
            ]
          }{' '}
        </>
      ),
    },
    {
      title: 'Expired At',
      dataIndex: 'expired_at',
      key: 'expired_at',
      width: 80,
    },
    {
      title: 'Action',
      dataIndex: 'expired_at',
      key: 'expired_at',
      width: 80,
      render: (text, record) => (
        <Space split={'|'}>
          <a onClick={() => handleGenerateRate(record.id)}>Generate Rates</a>
          <a onClick={() => handleEdit(record)}>Edit</a>
          <Popconfirm
            placement="left"
            title="Sure to delete?"
            okText="Confirm"
            cancelText="Cancel"
            onConfirm={() => handleDelete(record.id)}>
            <a>Delete</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const columns: ColumnsType<any> = useMemo(() => {
    return defaultColumns;
  }, [data]);

  const onClick: MenuProps['onClick'] = ({ key }) => {
    handleAdd(+key);
  };

  const menu = (
    <Menu
      onClick={onClick}
      items={[
        {
          label: 'Location',
          key: '1',
        },
        {
          label: 'Formular',
          key: '0',
        },
      ]}
    />
  );

  return (
    <>
      <Drawer
        title="Contract Rates"
        placement="right"
        width="80%"
        open={visible}
        onClose={onClose}
        destroyOnClose={true}>
        <Table
          loading={loading}
          pagination={false}
          rowKey="id"
          columns={columns}
          dataSource={data}
        />
        <Divider />
        <Dropdown overlay={menu}>
          <a onClick={(e) => e.preventDefault()}>Add</a>
        </Dropdown>
      </Drawer>
      <ContractRateFormDrawer />
    </>
  );
};

export default Index;
