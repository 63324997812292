import { THeader } from '@/types.d';
import styles from 'res/css/ui.scss';
import React from 'react';

export const Header: React.FC<THeader> = ({ title, rightElement }) => {
  return (
    <div className={styles.header}>
      <h4>{title}</h4>
      {rightElement && rightElement}
    </div>
  );
};
