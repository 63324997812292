import { Button } from 'antd';
import { useApp } from '@/utils/useapp';
import { useState } from 'react';
import React from 'react';
import { get, upperFirst } from 'lodash';
import { SendPodConfirmEmailModal } from './SendPodConfirmEmailModal';
import {
  formatActionInfo,
  TCommonProps,
  TItemProps,
  TOnDoneProps,
} from '../..';

export const SendPodAction: React.FC<
TCommonProps & TItemProps & TOnDoneProps
> = ({ type, item, container, onDone }) => {
  const app = useApp();

  const [open, setOpen] = useState(false);

  const handleOnOpen = () => {
    setOpen(true);
  };

  const handleCloseOpen = () => {
    setOpen(false);
  };

  const handleOnSent = () => {
    onDone && onDone();
    handleCloseOpen();
  };

  return (
    <>
      <Button
        key={item.action}
        size="small"
        disabled={item.editable && !get(container, item.editable)}
        onClick={handleOnOpen}>
        {upperFirst(item.action)}
      </Button>
      {open && container && (
        <SendPodConfirmEmailModal
          open={open}
          admin={app.store.auth.account}
          containerId={container.id}
          actionInfo={formatActionInfo(type, item.task, item.action)}
          onClose={handleCloseOpen}
          onSent={handleOnSent}
        />
      )}
    </>
  );
};
