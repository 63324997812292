import { get } from 'lodash';
import { CSSProperties, memo, useCallback, useState } from 'react';
import { Typography, SelectProps, Select } from 'antd';

const selectStyle: CSSProperties = {
  width: '100%',
};

type EditableCommonSelectColumnProps = SelectProps & {
  record: any;
  path: string[];
  options: Required<SelectProps['options']>;
};

const EditableCommonSelectColumn = memo(
  ({
    record,
    path,
    options,
    onSelect,
    ...props
  }: EditableCommonSelectColumnProps) => {
    const [isEditing, setIsEditing] = useState(false);
    const handleEdit = () => {
      setIsEditing(true);
    };

    const value = get(record, path, undefined);
    const label = options?.find((option) => option.value === value)?.label;

    const handleSelect: SelectProps['onSelect'] = useCallback(
      (value, option) => {
        onSelect?.(value, option);
        setIsEditing(false);
      },
      [onSelect],
    );

    const handleBlur = useCallback(() => {
      setIsEditing(false);
    }, []);

    if (!isEditing) {
      return (
        <Typography.Link underline onClick={handleEdit}>
          {label}
        </Typography.Link>
      );
    }

    return (
      <Select
        style={selectStyle}
        open
        autoFocus
        value={value}
        options={options}
        onSelect={handleSelect}
        onBlur={handleBlur}
        {...props}
      />
    );
  },
);

EditableCommonSelectColumn.displayName = 'EditableCommonSelectColumn';

export default EditableCommonSelectColumn;
