import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Divider,
  Drawer,
  message,
  Modal,
  Popconfirm,
  Radio,
  RadioChangeEvent,
  Rate,
  Space,
  Switch,
  Table,
  Tag,
} from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { Header } from '@/components/CommonHeader';
import { Filter } from './components/Filter';
import type { ColumnsType } from 'antd/lib/table';
import { get } from 'lodash';
import { DrawerForm } from './components/DawerForm';
import { useForm } from 'antd/lib/form/Form';
import AutoResizeTable from '@/components/AutoResizeTable';

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [filter, setFilter] = useState<any>({});
  const [data, setData] = useState<any[]>([]);
  const [port, setPort] = useState<any>(null);
  const [form] = useForm();
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    form.resetFields();
  };

  const handleNewVendor = () => {
    showDrawer();
  };

  const handleFilterChange = (values: any) => {
    setFilter(values);
  };

  const fetchData = async ({ page } = { page: 1 }) => {
    const _filter = Object.assign({}, filter);
    if (_filter.city) {
      _filter.city_id = _filter.city.id;
    }
    delete _filter.city;
    setLoading(true);
    try {
      const result = await app.service.get('ports', { params: { ..._filter } });
      // setPagination({ current: result.meta.current_page, pageSize: result.meta.per_page, total: result.meta.total });
      setData(result.data);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const isNew = useMemo(() => {
    return !port;
  }, [port]);

  const handleEdit = (id: number) => {
    const item = data.find((item) => item.id === id);
    if (!item) {
      return;
    }
    setVisible(true);
    setPort(id);
    form.setFieldsValue({ ...item });
  };

  const handleSearch = async () => {
    if (filter.name) {
      setLoading(true);
      try {
        const result = await app.service.get('ports/search', {
          params: { ...filter },
        });
        setData(result.data);
      } catch (err: any) {
        message.error(err.data?.message || err.data?.error);
      } finally {
        setLoading(false);
      }
    } else {
      fetchData();
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleSave = async () => {
    const data = await form.validateFields();

    setLoading(true);
    const formValue = Object.assign({}, data);

    formValue.city_id = get(formValue, 'city.id', 0);
    delete formValue.city;
    const request = isNew
      ? app.service.post('ports', { data: formValue })
      : app.service.put(`ports/${port}`, { data: formValue });

    request
      .then((resp) => {
        handleSearch();
        setPort(null);
        onClose();
      })
      .catch((err) => {
        message.error(err.data?.message || err.data?.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRemove = (id: number) => {
    setLoading(true);

    app.service
      .delete(`ports/${id}`)
      .then((resp) => {
        handleSearch();
      })
      .catch((err) => {
        message.error(err.data?.message || err.data?.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 80,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 200,
    },
    {
      align: 'center',
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 150,
      render: (text, record) => (
        <span>
          <a onClick={() => handleEdit(record.id)}>Edit</a>
          <Divider type="vertical" />
          <Popconfirm
            title="Sure to delete?"
            okText="Confirm"
            cancelText="Cancel"
            onConfirm={() => handleRemove(record.id)}>
            <a>Delete</a>
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <div className={styles.main}>
      <Header
        title="Ports"
        rightElement={
          <div>
            <Button type="primary" onClick={handleNewVendor}>
              New Port
            </Button>
          </div>
        }></Header>

      <Filter
        loading={loading}
        filter={filter}
        onSearch={handleSearch}
        onChange={handleFilterChange}>
        {' '}
      </Filter>
      <div>
        <AutoResizeTable
          pagination={{
            defaultPageSize: 20,
          }}
          loading={loading}
          size="small"
          rowKey="id"
          columns={columns}
          dataSource={[...data]}
          scroll={{ x: 1500 }}
          sticky
        />
      </div>
      <Drawer
        title=""
        placement={'right'}
        width={'40%'}
        onClose={onClose}
        destroyOnClose={true}
        open={visible}
        extra={
          <Space>
            <Button onClick={onClose}>Close</Button>
            <Button type="primary" loading={loading} onClick={handleSave}>
              Save
            </Button>
          </Space>
        }>
        <DrawerForm form={form} isNew={isNew} updateData={setPort} />
      </Drawer>
    </div>
  );
};

export default Index;
