import React from 'react';
import type { MenuProps } from 'antd';

import { EditorState, Modifier } from 'draft-js';
import { PresetDataDropDown } from './PresetDataDropDown';

export const SelectPresetDataVariables: React.FC<{
  editorState: any;
  presetDataMap?: React.ReactNode | any;
  onChange: (value: any) => void;
}> = ({ editorState, presetDataMap, onChange }) => {
  const handleClickPresetData = (e: any) => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      e.key,
      editorState.getCurrentInlineStyle(),
    );

    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  };

  if (!presetDataMap) {
    return <></>;
  } else if (typeof presetDataMap === 'object') {
    return (
      <PresetDataDropDown
        presetDataMap={presetDataMap}
        handleClickPresetData={handleClickPresetData}
      />
    );
  } else {
    const CustomizeComponent = presetDataMap as React.ElementType;

    return <CustomizeComponent handleClickPresetData={handleClickPresetData} />;
  }
};
