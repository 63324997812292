import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Divider,
  Drawer,
  message,
  Popconfirm,
  Space,
  Switch,
} from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { Header } from './components/Header';
import { Filter } from './components/Filter';
import type { ColumnsType } from 'antd/lib/table';
import { get, truncate } from 'lodash';
import { DrawerFormBulkUpdate } from './components/DrawerFormBulkUpdate';

import { useForm, useWatch } from 'antd/lib/form/Form';
import {} from '@ant-design/icons';
import AutoResizeTable from '@/components/AutoResizeTable';
import Import from './components/Import';
import OverflowText from '@/components/OverflowText';
import { SellRateDrawer } from './components/sellRateDrawer';
import { LogActivities } from '@/components/LogActivities';
export const COPY = -1;

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  //const [filter, setFilter] = useState<any>({});

  const [visibleBulkUpdate, setVisibleBulkUpdate] = useState(false);

  const [data, setData] = useState<any[]>([]);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 12,
    total: 0,
  });
  const [form] = useForm();
  const [filter] = useForm();

  const filterSortBy = useWatch('sort_by', filter);
  const filterSortValue = useWatch('sort_value', filter);
  const [copyRecord, setCopyRecord] = useState(null);
  const [rate, setRate] = useState<any>(null);

  const rateId = useWatch('id', form);
  const isNew = !rateId;

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    setRate(null);
    setCopyRecord(null);
    form.resetFields();
  };

  const handleNew = () => {
    form.setFieldsValue({
      cargo_type_id: 1,
      visible: true,
    });
    showDrawer();
  };

  const handleSaveBulkUpdate = async () => {
    fetchData({ page: 1 });
    handelCloseBulkUpdate();
  };
  const handleBulkUpdate = () => {
    if (selectedRowKeys.length <= 0) {
      message.error('Please select data to update');
      return false;
    }
    setVisibleBulkUpdate(true);
  };

  const handelCloseBulkUpdate = () => {
    setSelectedRowKeys([]);
    setVisibleBulkUpdate(false);
  };

  const handleFilterChange = (values: any) => {
    //setFilter(values);
    filter.setFieldsValue(values);
  };

  const fetchData = async ({ page = 1, sortBy = '', sortValue = '' } = {}) => {
    const values = filter.getFieldsValue();
    const _filter = Object.assign({}, { ...values });
    _filter.to_city_id = get(_filter, 'to_city.id', 0);
    _filter.vendor_id = get(_filter, 'vendor.id', null);

    if (sortBy) {
      _filter.sort_by = sortBy;
    }

    if (sortValue) {
      _filter.sort_value = sortValue;
    }

    delete _filter.to_city;
    delete _filter.vendor;

    filter.setFieldsValue({ ...values, ..._filter });
    setLoading(true);
    try {
      const result = await app.service.get('sellRates', {
        params: {
          ..._filter,
          per_page: pagination.pageSize,
          page: page || pagination.current,
        },
      });
      setPagination({
        current: result.meta.current_page,
        pageSize: result.meta.per_page,
        total: result.meta.total,
      });
      setData(result.data);

      if (app.store.search.rateId) {
        app.store.search.setRateId(null);
      }
    } catch (error) {
      message.error('Error');
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    fetchData();
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    if (app.store.search.rateId) {
      fetchData();
    }
  }, [app.store.search.rateId]);

  const handleSaved = (response?: any) => {
    fetchData({ page: pagination.current });
  };
  // const handleSave = async () => {
  //   const data = await form.validateFields();

  //   setLoading(true);
  //   const formValue = Object.assign({}, data);

  //   formValue.to_city_id = get(formValue, 'to_city.id', 0);

  //   delete formValue.vendor;
  //   delete formValue.to_city;

  //   const request = isNew
  //     ? app.service.post('sellRates', { data: formValue })
  //     : app.service.put(`sellRates/${rateId}`, { data: formValue });

  //   request
  //     .then((response) => {
  //       fetchData({ page: pagination.current });
  //       form.setFieldsValue(response.data.data);
  //       message.success('Saved');
  //     })
  //     .catch((err) => {
  //       message.error(err.data?.message || err.data?.error);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  const handleRemove = (id: number) => {
    app.service
      .delete(`sellRates/${id}`)
      .then(() => {
        fetchData({ page: pagination.current });
      })
      .catch((err) => {
        message.error(err.data?.message || err.data?.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEdit = (id: number) => {
    const item = data.find((item) => item.id === id);
    if (!item) {
      return;
    }
    setVisible(true);
    setRate(id);
  };

  const handleCopy = (record: any) => {
    setCopyRecord(record);
    setRate(COPY);
    setVisible(true);
  };

  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  // rowSelection object indicates the need for row selection
  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
  };

  const handleReplace = (item: any) => {
    const _data = [...data].map((d) => {
      return d.id == item.id ? item : d;
    });
    setData(_data);
  };

  const handleSetValid = useCallback(
    async (id: string, valid: boolean) => {
      setLoading(true);
      try {
        await app.service.put(`sellRates/${id}/setValid`, {
          data: { valid },
        });
        const _data = data.find((r) => r.id == id);
        _data.valid = valid;
        handleReplace(_data);
      } catch (error) {
        message.error('Error');
      } finally {
        setLoading(false);
      }
    },
    [data],
  );

  const handleSetVisibility = useCallback(
    async (id: string, visible: boolean) => {
      setLoading(true);
      try {
        await app.service.put(`sellRates/${id}/setVisibility`, {
          data: { visible },
        });
        const _data = data.find((r) => r.id == id);
        _data.visible = visible;
        handleReplace(_data);
      } catch (error) {
        message.error('Error');
      } finally {
        setLoading(false);
      }
    },
    [data],
  );

  const handleSetLocked = useCallback(
    async (id: string, locked: boolean) => {
      setLoading(true);
      try {
        await app.service.put(`sellRates/${id}/setLocked`, {
          data: { locked },
        });
        const _data = data.find((r) => r.id == id);
        _data.locked = locked;
        handleReplace(_data);
      } catch (error) {
        message.error('Error');
      } finally {
        setLoading(false);
      }
    },
    [data],
  );

  const columns: ColumnsType<any> = React.useMemo(() => {
    const toSortOrder = (field: string): string | null => {
      if (field === filterSortBy) {
        if (filterSortValue === 'desc') {
          return 'descend';
        }
        if (filterSortValue === 'asc') {
          return 'ascend';
        }
      }
      return null;
    };

    return [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 80,
        sorter: true,
        sortOrder: toSortOrder('id'),
        render: (text, record) => record.uid,
      },
      {
        title: 'Vendor',
        dataIndex: 'vendor_id',
        key: 'vendor_id',
        width: 180,
        sorter: true,
        sortOrder: toSortOrder('vendor_id'),
        render: (text, record) => {
          return record.vendor_id === null ? (
            'DrayEasy'
          ) : (
            <OverflowText>{record.vendor?.name}</OverflowText>
          );
        },
      },
      {
        title: 'Customer',
        dataIndex: 'customer_id',
        key: 'customer_id',
        width: 180,
        sorter: true,
        sortOrder: toSortOrder('customer_id'),
        render: (text, record) => {
          let name = '-';
          if (record.customer_id) {
            if (record.customer) {
              name = `${record.customer.name}(${
                record.customer.company?.code || ''
              })`;
            }
          } else {
            name = 'All';
          }

          return (
            <div>
              <OverflowText>{name}</OverflowText>
              {!!record.contract_rate_name && (
                <div>Contract: {record.contract_rate_name}</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Base',
        dataIndex: 'base_rate',
        key: 'base_rate',
        width: 100,
        sorter: true,
        sortOrder: toSortOrder('base_rate'),
      },
      {
        title: 'FSC',
        dataIndex: 'fuel_surcharge',
        key: 'fuel_surcharge',
        width: 100,
        sorter: true,
        sortOrder: toSortOrder('fuel_surcharge'),
      },
      {
        title: 'Toll',
        dataIndex: 'toll_fee',
        key: 'toll_fee',
        width: 100,
        sorter: true,
        sortOrder: toSortOrder('toll_fee'),
      },
      {
        title: 'Total',
        dataIndex: 'total_rate',
        key: 'total_rate',
        width: 100,
        sorter: true,
        sortOrder: toSortOrder('total_rate'),
      },
      {
        title: 'CNTR Size',
        dataIndex: 'container_sizes',
        key: 'container_sizes',
        width: 100,
        render: (text, record) =>
          record.container_sizes && record.container_sizes.length
            ? record.container_sizes.join(',')
            : 'All',
      },
      {
        title: 'Attributes',
        dataIndex: 'cargo_type',
        key: 'cargo_type',
        width: 120,
        render: (text, record) =>
          ['is_haz', 'is_ow', 'is_reefer', 'is_soc']
            .map((attribute) => {
              return record[attribute]
                ? attribute.replace('is_', '').toUpperCase()
                : '';
            })
            .filter((a) => a)
            .join(',') || '-',
      },
      {
        title: 'Terminal',
        dataIndex: 'terminal_ids',
        key: 'terminal_ids',
        width: 160,
        render: (text, record) =>
          record.terminals && record.terminals.length
            ? record.terminals.map((i) => i.name).join(',')
            : 'All',
      },
      {
        title: 'From',
        dataIndex: 'from',
        key: 'from',
        width: 220,
        render: (text, record) => (
          <>
            <div>{get(record, 'intermodal_region.name', '')}</div>
            <div>{record.from_facility_type}</div>
          </>
        ),
      },
      {
        title: 'To',
        dataIndex: 'to',
        key: 'to',
        width: 200,
        render: (text, record) => (
          <>
            <div>{get(record, 'to_city.full_name', '')}</div>
            <div>
              {record.to_zipcode} {record.to_facility_type}
            </div>
          </>
        ),
      },
      {
        title: 'Valid',
        dataIndex: 'valid',
        key: 'valid',
        width: 100,
        render: (text, record) => {
          return (
            <>
              <Switch
                checked={record.valid}
                onClick={(v) => handleSetValid(record.id, v)}
              />
            </>
          );
        },
      },
      {
        title: 'Visible',
        dataIndex: 'visible',
        key: 'visible',
        width: 100,
        render: (text, record) => {
          return (
            <>
              <Switch
                checked={record.visible}
                onClick={(v) => handleSetVisibility(record.id, v)}
              />
            </>
          );
        },
      },
      {
        title: 'Locked',
        dataIndex: 'locked',
        key: 'locked',
        width: 100,
        render: (text, record) => {
          return (
            <>
              <Switch
                checked={record.locked}
                onClick={(v) => handleSetLocked(record.id, v)}
              />
            </>
          );
        },
      },
      {
        title: 'Created',
        dataIndex: 'created_at',
        key: 'created_at',
        width: 100,
      },
      {
        title: 'Expired',
        dataIndex: 'expired_at',
        key: 'expired_at',
        width: 100,
      },
      {
        align: 'center',
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 250,
        render: (text, record) => (
          <Space split={<Divider type="vertical" />}>
            <a onClick={() => handleEdit(record.id)}>Edit</a>

            <Popconfirm
              title="Sure to delete?"
              okText="Confirm"
              cancelText="Cancel"
              onConfirm={() => handleRemove(record.id)}
            >
              <a>Delete</a>
            </Popconfirm>

            <a onClick={() => handleCopy(record)}>Copy</a>

            <LogActivities id={record.id} type={'sellRates'} />
          </Space>
        ),
      },
    ];
  }, [data, filterSortBy, filterSortValue]);

  return (
    <div className={styles.main}>
      <Header
        title="Sell Rates"
        rightElement={
          <Space>
            <Import onUploaded={fetchData} />

            <Button type="primary" onClick={handleNew}>
              New Rate
            </Button>
            <Button onClick={handleBulkUpdate}>Bulk Update</Button>
          </Space>
        }
      ></Header>

      <Filter
        loading={loading}
        filter={filter}
        onSearch={handleSearch}
        onChange={handleFilterChange}
      ></Filter>
      <div>
        <AutoResizeTable
          loading={loading}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          pagination={pagination}
          size="small"
          rowKey="id"
          columns={columns}
          onChange={(pagination, filters, sorter: any) => {
            let sortBy = sorter.field;
            let sortValue = null;

            if (null === sorter.order) {
              sortBy = null;
            } else {
              sortValue = sorter.order === 'ascend' ? 'asc' : 'desc';
            }

            fetchData({
              page: pagination.current || 1,
              sortBy,
              sortValue,
            });
          }}
          dataSource={[...data]}
          scroll={{ x: 1500 }}
          sticky
        />
      </div>

      <SellRateDrawer
        visible={visible}
        id={rate}
        copyRecord={copyRecord}
        onSaved={handleSaved}
        onClose={onClose}
      />

      <DrawerFormBulkUpdate
        ids={selectedRowKeys}
        visible={visibleBulkUpdate}
        onClose={handelCloseBulkUpdate}
        onSubmit={handleSaveBulkUpdate}
      />
    </div>
  );
};

export default Index;
