import { Tag, TagProps } from 'antd';
import React from 'react';

export const RatingTag: React.FC<{ rating: number | null } & TagProps> = ({
  rating,
  ...rest
}) => {
  // const rating = get(record, 'vendor.rating', 0);
  let color = '';
  if (!rating) {
    color = 'default';
  } else if (rating < 3) {
    color = 'error';
  } else if (rating < 4) {
    color = 'warning';
  } else {
    color = 'success';
  }

  return (
    <Tag
      {...rest}
      style={{ ...rest.style, cursor: rest.onClick ? 'pointer' : 'unset' }}
      color={color}
    >
      {rating === null || rating === undefined ? 'NA' : rating.toFixed(2)}
    </Tag>
  );
};
