import { useApp } from '@/utils/useapp';
import { message, Form, Modal, Space, Button, Spin, Checkbox } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import EmailForm from '@/components/EmailForm';
import { serialize } from 'object-to-formdata';
import {
  get,
  chain,
  reduce,
  uniq,
  trim,
  truncate,
  flatMap,
  mergeWith,
  merge,
} from 'lodash';
import { TLocalStatement } from '@/types';
import { CONTACT_TYPE_ACCOUNTING } from '@/components/ContactList';
import FormItem from 'antd/es/form/FormItem';

interface IEmailModal {
  localstatement: TLocalStatement;
  open: boolean;
  onSaved: () => void;
  onClose: () => void;
}
export const EmailModal: React.FC<IEmailModal> = ({
  localstatement,
  open,
  onSaved,
  onClose,
}) => {
  const app = useApp();
  const admin = app.store.auth.account;

  const [loading, setLoading] = useState(false);
  const [emailForm] = useForm();

  const handleOnClose = () => {
    emailForm.resetFields();
    onClose();
  };

  const handleSendEmail = async () => {
    setLoading(true);
    try {
      const data = await emailForm.validateFields();

      const config = {
        data: serialize(data, { indices: true, nullsAsUndefineds: true }),
        requestType: 'form',
      };

      await app.service.post(
        `accounting/localStatements/${localstatement.id}/sendLocalStatement`,
        config,
      );

      message.success('Sent');

      emailForm.resetFields();

      onSaved();
    } catch (err: any) {
      err.data && message.error(err.data.error || err.data.message);
    } finally {
      setLoading(false);
    }
  };

  const toDrayeasyBody = () => {
    return trim(`
Hi Accounting,



Please find attached statement. Thanks for your business and appreciate if you can make the payment on time.

Thanks.
          `);
  };

  const initForm = () => {
    const contacts = mergeWith(
      [],
      [
        ...get(localstatement, 'bill_to.contacts', []),
        ...flatMap(get(localstatement, 'users', []), 'bill_to.contacts'),
      ],
    ).filter((c: any) => {
      if (c?.type instanceof Array) {
        return c?.type.includes(CONTACT_TYPE_ACCOUNTING);
      }

      return false;
    });

    const sendToList = contacts.map((c: any) => c.email);

    let subject = `Statement#${localstatement.id}`;
    get(localstatement, 'bill_to.name') &&
      (subject += ` ${get(localstatement, 'bill_to.name')}`);
    get(localstatement, 'users', []) &&
      (subject += `(${get(localstatement, 'users')
        .map((u: any) => u.name)
        .join(', ')})`);
    subject += ` ${localstatement.period}`;

    emailForm.setFieldsValue({
      sendToList: sendToList.length > 0 ? uniq(sendToList) : [''],
      ccList: [admin?.email],
      subject: truncate(subject, { length: 100 }),
      body: toDrayeasyBody(),
      addText: false,
    });
  };

  React.useEffect(() => {
    open && initForm();
  }, [open]);

  return (
    <>
      <Modal
        zIndex={1001}
        title="Send Invoice Email"
        width={'60%'}
        onCancel={handleOnClose}
        destroyOnClose={true}
        open={open}
        footer={
          <>
            <Space>
              <Button disabled={loading} onClick={handleOnClose}>
                Cancel
              </Button>
              <Button
                type="primary"
                disabled={loading}
                onClick={handleSendEmail}
              >
                Send
              </Button>
            </Space>
          </>
        }
      >
        <div style={{ maxHeight: '70vh', overflowY: 'scroll' }}>
          <Spin spinning={loading}>
            <Form
              form={emailForm}
              layout="vertical"
              initialValues={{
                attach_original_invoices: true,
              }}
            >
              <EmailForm
                form={emailForm}
                has={{
                  from: false,
                  attachment: true,
                  showSelectAttachments: true,
                }}
              />
              <Form.Item
                labelAlign="right"
                valuePropName="checked"
                label="Attach Original Invoice"
                name="attach_original_invoices"
              >
                <Checkbox />
              </Form.Item>
            </Form>
          </Spin>
        </div>
      </Modal>
    </>
  );
};
