import { useApp } from '@/utils/useapp';
import { List, Button, Card, Row, Tooltip, message, Space } from 'antd';
import React, { useEffect } from 'react';
import { useObserver } from 'mobx-react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { SubTabInterface } from '..';
import { OPdashboardTaskInterface } from '@/Interfaces/OPdashboardTab/OPdashboardTaskInterface';
export const Task: React.FC<
  {
    isTaskTab: boolean;
  } & SubTabInterface
> = ({
  opDashboardTab,
  isTaskTab = false,
  refresh = true,
  setRefresh,
  goToOperationTable,
}) => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<OPdashboardTaskInterface[]>([]);

  const fetchData = async () => {
    if (!opDashboardTab?.id || loading) {
      return;
    }

    setData([]);

    setLoading(true);

    try {
      const resp = await app.service.get(
        `tl/opdashboard/${opDashboardTab.id}/task`,
      );

      setData(resp.data || []);
      refresh && setRefresh(false);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const goOperationTableDetail = (operationIds: number[]) => {
    if (!operationIds || operationIds.length === 0) {
      return;
    }

    goToOperationTable(operationIds);
  };

  useEffect(() => {
    if (opDashboardTab?.id && isTaskTab) {
      fetchData();
    }
  }, [isTaskTab, opDashboardTab]);

  useEffect(() => {
    if (opDashboardTab?.id && isTaskTab && refresh) {
      fetchData();
    }
  }, [refresh]);

  return useObserver(() => {
    return (
      <Card
        title={
          <Space>
            Task
            <Tooltip
              title={
                <>
                  <div>
                    <div>
                      Red: The number of LTL shipments that are overdue.
                    </div>
                    <div>
                      Blue: The number of LTL shipments that are intended to be
                      dispatched.
                    </div>
                  </div>
                </>
              }
            >
              <InfoCircleOutlined />
            </Tooltip>
          </Space>
        }
        loading={loading}
        bodyStyle={{ padding: '16px' }}
        style={{ height: '60vh', overflow: 'scroll' }}
      >
        {data?.length > 0 &&
          data.map((row: OPdashboardTaskInterface) => (
            <>
              <div
                className="flex justify-between pb-xs pt-xs"
                style={{ borderBottom: '1px solid #f0f0f0' }}
              >
                <div>{row.task}</div>
                <div
                  style={{
                    border: '1px solid rgb(214, 228, 255)',
                    // backgroundColor: 'rgb(240, 245, 255)',
                    color: 'rgb(0, 122, 204)',
                    padding: '4px 8px',
                    borderRadius: '10px',
                    height: '26px',
                    // width: '80px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{ width: '20px' }}
                    onClick={() =>
                      goOperationTableDetail(row.overdue_operationable_ids)
                    }
                    className={`flex justify-end mr-sm ${
                      row.overdue_count > 0
                        ? 'text-danger text-pointer'
                        : 'text-primary'
                    }`}
                  >
                    {row.overdue_count}
                  </div>
                  /
                  <div
                    style={{ width: '20px' }}
                    onClick={() =>
                      goOperationTableDetail(row.intend_operationable_ids)
                    }
                    className={`flex text-primary ml-sm ${
                      row.intend_count > 0 ? 'text-pointer' : ''
                    }`}
                  >
                    {row.intend_count}
                  </div>
                </div>
              </div>
            </>
          ))}
      </Card>
    );
  });
};
