import { Input, Form, Switch } from 'antd';
import { FormInstance } from 'antd/es/form';
import { get, reduce, uniq } from 'lodash';
import React from 'react';
import { ChassisTypeSyncSelect } from '@/components/ChassisTypeSyncSelect';
import { CNTR_SIZE_MAP } from '@/components/PossibleCharges';
import { IAttribute } from '.';

export const HasChassis: React.FC<IAttribute> = ({ form }) => {
  const handleSelectChassisType = (chassisTypes: Array<any>) => {
    const cntrSizes = reduce(
      chassisTypes,
      (result, chassisType) => {
        return result.concat(get(chassisType, 'cntr_size', []));
      },
      [],
    );

    form.setFieldsValue({
      chassis_type_ids: chassisTypes.map((c) => c.id),
      chassis_description: uniq(cntrSizes)
        .map((c) => CNTR_SIZE_MAP[c])
        .join(' , '),
    });
  };

  return (
    <>
      <Form.Item
        label="Has Own Chassis"
        name="has_own_chassis"
        valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue, setFieldValue }) =>
          getFieldValue('has_own_chassis') && (
            <>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue, setFieldValue }) => (
                  <Form.Item
                    label="Chassis Type"
                    name="chassis_type_ids"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}>
                    <ChassisTypeSyncSelect
                      maxTagCount={'responsive'}
                      selected={getFieldValue('chassis_type_ids')}
                      onSelect={handleSelectChassisType}
                    />
                  </Form.Item>
                )}
              </Form.Item>
              <Form.Item
                label="Chassis Description"
                name="chassis_description"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}>
                <Input.TextArea
                  rows={3}
                  placeholder="Enter Chassis Description"
                />
              </Form.Item>
            </>
          )
        }
      </Form.Item>
    </>
  );
};
