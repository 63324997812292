import { useApp } from '@/utils/useapp';
import {
  Button,
  Input,
  message,
  Popover,
  Row,
  Tooltip,
  Modal,
  Popconfirm,
} from 'antd';
import { FormInstance } from 'antd/es/form';
import { useWatch } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { TBill } from '@/types';
import { get } from 'lodash';
import { BaseButtonProps } from 'antd/lib/button/button';
import getCanceledBillables from '@/utils/getCanceledBillables';

interface IApproveButton {
  form: FormInstance;
  bill: TBill;
  onSetLoading: (loading: boolean) => void;
  onApprovedOrUnApproved: (billId: number) => void;
}

export const ApproveButton: React.FC<IApproveButton & BaseButtonProps> = ({
  form,
  bill,
  onSetLoading,
  onApprovedOrUnApproved,
  ...props
}) => {
  const app = useApp();
  const [loading, setLoading] = useState(false);
  const [unapprovedReason, setUpapprovedReason] = useState(
    form.getFieldValue('unapproved_reason') ?? '',
  );

  const isApproved = useWatch('is_approved', form);

  const toSetLoading = (loading: boolean) => {
    setLoading(loading);
    onSetLoading && onSetLoading(loading);
  };

  const isCancelOrder = () => {
    const canceledBillables = getCanceledBillables([bill]);

    if (canceledBillables?.length > 0) {
      return true;
    }

    return false;
  };

  const handleApprove = async () => {
    toSetLoading(true);
    if (!bill || !bill.id) {
      return false;
    }

    const action = form.getFieldValue('is_approved') ? 'unapprove' : 'approve';

    try {
      await app.service.put(`orderBills/${bill.id}/${action}`);
      message.success('Saved');
      onApprovedOrUnApproved(bill.id);
    } catch (e: any) {
      message.error(
        get(e, 'data.message') || get(e, 'data.error') || 'System Error',
      );
    }

    toSetLoading(false);
  };

  const toApprove = () => {
    if (!bill || !bill.billable_type || !bill.billable_id) {
      return false;
    }

    if (isCancelOrder()) {
      Modal.confirm({
        title: 'Warning',
        content:
          'This is cancel order, Vendor might send the wrong invoice, please confirm',
        onOk: handleApprove,
        okText: 'Continue',
        cancelText: 'Cancel',
        maskClosable: true,
      });
    } else {
      handleApprove();
    }
  };

  const handleUnApproved = async () => {
    if (!unapprovedReason) {
      return message.error('Please input unapproved reason');
    }

    toSetLoading(true);
    if (!bill || !bill.id) {
      return false;
    }

    try {
      await app.service.put(`orderBills/${bill.id}/unapprove`, {
        data: {
          unapproved_reason: unapprovedReason,
        },
      });
      message.success('Saved');
      onApprovedOrUnApproved(bill.id);
    } catch (e: any) {
      message.error(
        get(e, 'data.message') || get(e, 'data.error') || 'System Error',
      );
    }

    toSetLoading(false);
  };

  if (isApproved) {
    return (
      <>
        <Popover
          trigger={['hover']}
          placement="bottom"
          content={
            <>
              <div className="ant-col ant-form-item-label mb-xs">
                Unapprove Reason
              </div>
              <Row className="mb-md" style={{ width: '50vh' }}>
                <Input.TextArea
                  onChange={(e) => setUpapprovedReason(e.target.value)}
                  rows={3}
                  placeholder="Input unapproved reason"
                />
              </Row>
              <Row justify={'end'}>
                <Button
                  type="primary"
                  disabled={loading}
                  onClick={handleUnApproved}
                  {...props}
                >
                  Unapprove
                </Button>
              </Row>
            </>
          }
        >
          <Button type="primary" disabled={loading}>
            Unapprove
          </Button>
        </Popover>
      </>
    );
  } else {
    if (unapprovedReason) {
      return (
        <Tooltip
          placement="top"
          title={
            <div style={{ whiteSpace: 'pre-line' }}>
              <div>Unapprove reason</div>
              {unapprovedReason}
            </div>
          }
        >
          <Popconfirm
            title={
              form.getFieldValue('dispute_status') &&
              form.getFieldValue('dispute_status') != 6
                ? 'The bill is in dispute. Are you sure to approve?'
                : 'Are you sure?'
            }
            onConfirm={toApprove}
          >
            <Button type="primary" disabled={loading} {...props}>
              Approve
            </Button>
          </Popconfirm>
        </Tooltip>
      );
    }
    return (
      <Popconfirm
        title={
          form.getFieldValue('dispute_status') &&
          form.getFieldValue('dispute_status') != 6
            ? 'The bill is in dispute. Are you sure to approve?'
            : 'Are you sure?'
        }
        onConfirm={toApprove}
      >
        <Button type="primary" disabled={loading} {...props}>
          Approve
        </Button>
      </Popconfirm>
    );
  }
};
