import { Button, Select, Input } from 'antd';
import React, { useEffect } from 'react';
import { ModelSelect } from '@/components/ModelSelect';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { CACHE_VENDOR_FORM_W9_KEY } from '@/stores/cache';
import { VendorFormW9Drawer } from '@/pages/entity/vendorFormW9s/components/VendorFormW9Drawer';
import { useApp } from '@/utils/useapp';
import { get } from 'lodash';

import SyncCacheModelSelect, {
  SyncCacheModelSelectProps,
} from './SyncCacheModelSelect';
import { FORM_TYPE_MAP } from '@/pages/entity/vendorFormW9s/components/data';

type TProps = {
  // selected: any;
  formW9VendorId: any;

  onSelect: (model: any) => any;
  onSaved?: () => void;
};

export const VendorFormW9SyncSelect: React.FC<
TProps & SyncCacheModelSelectProps
> = ({
  // selected,
  formW9VendorId,
  onSelect,
  onSaved,
  ...props
}) => {
  const app = useApp();

  const [id, setId] = React.useState(0);
  const [visible, setVisible] = React.useState(false);

  const handleSaved = (data: any) => {
    onSelect(data);
    handleClosed();
    app.store.cache.fetch(CACHE_VENDOR_FORM_W9_KEY, true);
    onSaved && onSaved();
  };

  const handleClosed = () => {
    setVisible(false);
    setId(0);
  };

  return (
    <>
      <Input.Group compact>
        <Button
          type="ghost"
          onClick={() => {
            setId(formW9VendorId || 0);
            setVisible(true);
          }}
          className="ant-btn-icon-only select-modal-icon">
          <span className="icon-gray">
            {' '}
            {formW9VendorId ? <EditOutlined /> : <PlusOutlined />}
          </span>
        </Button>
        <SyncCacheModelSelect
          {...props}
          placeholder="Search Form W9"
          onSelect={onSelect}
          value={formW9VendorId || 0}
          className="select-minus-edit-w"
          cacheKey={CACHE_VENDOR_FORM_W9_KEY}
          filterOption={(input, option: any) => {
            const optionLabel = get(option, 'label.props.children')
              ?.join('')
              ?.toLowerCase();
            return optionLabel.includes(input.toLowerCase());
          }}
          renderOption={(formW9: any) => {
            return (
              <>
                <strong>[{formW9.mc_number}]</strong> [{' '}
                {
                  FORM_TYPE_MAP[
                    formW9.form_type as unknown as keyof typeof FORM_TYPE_MAP
                  ]
                }
                ] {formW9.name}
              </>
            );
          }}
        />
      </Input.Group>
      {visible && (
        <VendorFormW9Drawer
          visible={visible}
          id={id}
          mc_number={props?.mc_number}
          onClose={handleClosed}
          onSaved={handleSaved}
        />
      )}
    </>
  );
};
