import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Row,
  Space,
} from 'antd';
import moment from 'moment';
import { useApp } from '@/utils/useapp';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import TextArea from 'antd/lib/input/TextArea';
import { QuoteRoutes } from '@/components/QuoteRoutes';
import Status from './Status';
import Upload from './Upload';
import {
  BuyRateDrawer,
  BUYRATE_UID_PREFIX,
} from '../../buyRates/components/buyRateDrawer';
import {
  SellRateDrawer,
  SELLRATE_UID_PREFIX,
} from '../../sellRates/components/sellRateDrawer';

interface Props {
  model?: any;
  onCreated: () => void;
  onClose: () => void;
}

interface AutoSave {
  saveAt: Date;
  data: any[];
}

export const DrawerForm: React.FC<Props> = ({ model, onCreated, onClose }) => {
  const app = useApp();
  const [loading, setLoading] = React.useState(false);

  const [form] = useForm();

  const [openBuyRate, setOpenBuyRate] = React.useState(false);
  const [buyRateId, setBuyRateId] = React.useState(0);
  const [buyRateForm] = useForm();
  const [openSellRate, setOpenSellRate] = React.useState(false);
  const [sellRateId, setSellRateId] = React.useState(0);
  const [sellRateForm] = useForm();
  const [autoSave, setAutoSave] = useState<AutoSave | null>(null);

  const id = model?.id;
  const key = `quote_routes_${id ? id : 'new'}`;
  const quoteRoutes = Form.useWatch('quote_routes', form);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (!quoteRoutes || quoteRoutes.length == 0) {
      return;
    }

    const item = {
      id: key,
      saveAt: new Date(),
      data: quoteRoutes || [],
    };
    localStorage.setItem(key, JSON.stringify(item));
    setAutoSave(item);
  }, [quoteRoutes, id]);

  useEffect(() => {
    const routes = localStorage.getItem(key);
    if (routes) {
      setAutoSave(JSON.parse(routes));
    }
  }, []);

  const handleLoadFromAutoSave = () => {
    const routes = localStorage.getItem(key);
    if (routes) {
      form.setFieldValue('quote_routes', JSON.parse(routes).data);
    }
  };

  const fetchQuote = async () => {
    setLoading(true);
    try {
      const resp = await app.service.get(`quotes/${id}`);
      form.setFieldsValue({ ...resp.data });
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  const handelSave = async (values: any) => {
    setLoading(true);
    try {
      id
        ? await app.service.put(`quotes/${id}`, {
          data: values,
        })
        : await app.service.post('quotes', {
          data: values,
        });
      onCreated && onCreated();
      localStorage.removeItem(key);
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  const handleQuoteSend = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      const resp = await app.service.patch(`quotes/${id}/quoteSend`);
      form.setFieldsValue({ ...resp.data });
      onCreated && onCreated();
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  React.useEffect(() => {
    form.resetFields();
    if (id) {
      form.setFieldsValue(model.filter || {});
      fetchQuote();
    }
  }, [id]);

  const handleUploaded = (data: any[]) => {
    form.setFieldValue('quote_routes', data);
    form.validateFields();
  };

  const handleCreateOrUpdateSellRates = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      await app.service.post(`quotes/${id}/createOrUpdateSellRates`);
      message.success('Created');
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  const handleApplySR = async (index: number) => {
    const values = form.getFieldsValue();
    const route = values.quote_routes[index];
    try {
      const response = await app.service.get('sellRates/search/IrCity', {
        params: {
          intermodal_region_id: route.intermodal_region_id,
          to_city_id: route.city_id,
          customer_id: values.user_id,
        },
      });
      if (response.data && response.data.length != 0) {
        const rate = response.data;
        form.setFieldValue(['quote_routes', index], {
          ...route,
          miles: rate.distance,
          rate_id: rate.uid,
          base_fsc: +(rate.base_rate || 0) + +(rate.fuel_surcharge || 0),
        });
      } else {
        message.warning('No rate is found!');
      }
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
  };

  const handleApplySRAll = async () => {
    const values = form.getFieldsValue();

    try {
      const response = await app.service.post('sellRates/search/IrsCities', {
        data: {
          searches: values.quote_routes.map((route: any) => ({
            intermodal_region_id: route.intermodal_region_id,
            to_city_id: route.city_id,
            customer_id: values.user_id,
          })),
        },
      });
      if (response.data) {
        const rates = response.data;
        let quote_routes = values.quote_routes;
        quote_routes = quote_routes.map((route: any) => {
          let rate = rates.find((r: any) => {
            return (
              r.intermodal_region_id == route.intermodal_region_id &&
              r.to_city_id == route.city_id &&
              r.customer_id == values.user_id
            );
          });
          if (!rate) {
            rate = rates.find((r: any) => {
              return (
                r.intermodal_region_id == route.intermodal_region_id &&
                r.to_city_id == route.city_id
              );
            });
          }
          return rate
            ? {
              ...route,
              miles: rate.distance,
              rate_id: rate.uid,
              base_fsc: +(rate.base_rate || 0) + +(rate.fuel_surcharge || 0),
            }
            : route;
        });

        form.setFieldValue('quote_routes', quote_routes);
      } else {
        message.warning('No rate is found!');
      }
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
  };

  const isBuyRate = (rateId: string) => {
    return new RegExp('^' + BUYRATE_UID_PREFIX + '\\d+').test(rateId);
  };
  const isSellRate = (rateId: string) => {
    return new RegExp('^' + SELLRATE_UID_PREFIX + '\\d+').test(rateId);
  };

  const fetchBuyRate = async (rateId: number) => {
    try {
      const response = await app.service.get(`rates/${rateId}`);
      buyRateForm.setFieldsValue(response.data);
      setOpenBuyRate(true);
      setBuyRateId(rateId);
    } catch (e: any) {
      message.error(e?.data?.message || e?.data?.error);
    }
  };

  const fetchSellRate = async (rateId: number) => {
    try {
      const response = await app.service.get(`sellRates/${rateId}`);
      sellRateForm.setFieldsValue(response.data);

      setOpenSellRate(true);
      setSellRateId(rateId);
    } catch (e: any) {
      message.error(e?.data?.message || e?.data?.error);
    }
  };

  const handleOpenRate = (rateId: string) => {
    if (isBuyRate(rateId)) {
      const _rateId = Number(rateId.replace(BUYRATE_UID_PREFIX, ''));
      fetchBuyRate(_rateId);
    } else if (isSellRate(rateId)) {
      const _rateId = Number(rateId.replace(SELLRATE_UID_PREFIX, ''));
      fetchSellRate(_rateId);
    }
  };

  const handleCloseBuyRate = () => {
    setOpenBuyRate(false);
    setBuyRateId(0);
  };

  const handleCloseSellRate = () => {
    setOpenSellRate(false);
    setSellRateId(0);
  };

  const handleSaveBuyRate = () => {
    handleCloseBuyRate();
  };

  const handleSaveSellRate = () => {
    handleCloseSellRate();
  };

  return (
    <>
      <Form
        disabled={loading}
        onFinish={handelSave}
        layout="horizontal"
        form={form}
        initialValues={{
          field: 'invoiced_at',
          period_type: 'as_of',
          unpaid: '1',
          as_of_at: moment().format('YYYY-MM-DD'),
        }}>
        <Row gutter={24}>
          <Form.Item name="id" noStyle>
            <Input hidden />
          </Form.Item>
          <Col md={8}>
            <Form.Item
              name="user_id"
              label="Customer"
              rules={[{ required: true, message: 'Customer is required' }]}>
              <UserSyncSelect />
            </Form.Item>
            <Form.Item
              label="Quote Date"
              name="quote_date"
              required
              rules={[{ required: true, message: 'Quote Date is required' }]}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.quote_date !== curValues.quote_date
                }>
                {({ getFieldValue, setFieldsValue }) => {
                  const value = getFieldValue('quote_date');
                  return (
                    <DatePicker
                      style={{ width: '100%' }}
                      value={value ? moment(value) : null}
                      onChange={(v) =>
                        setFieldsValue({
                          quote_date: v?.format('YYYY-MM-DD'),
                          expiration_date: v
                            ?.add(30, 'days')
                            .format('YYYY-MM-DD'),
                        })
                      }
                    />
                  );
                }}
              </Form.Item>
            </Form.Item>
            <Form.Item
              label="Expiration Date"
              name="expiration_date"
              required
              rules={[
                { required: true, message: 'Expiration Date is required' },
              ]}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.expiration_date !== curValues.expiration_date
                }>
                {({ getFieldValue, setFieldsValue }) => {
                  const value = getFieldValue('expiration_date');
                  return (
                    <DatePicker
                      style={{ width: '100%' }}
                      value={value ? moment(value) : null}
                      onChange={(v) =>
                        setFieldsValue({
                          expiration_date: v?.format('YYYY-MM-DD'),
                        })
                      }
                    />
                  );
                }}
              </Form.Item>
            </Form.Item>
            <Form.Item name="status" initialValue={0} noStyle shouldUpdate>
              <div>
                Status:{' '}
                <Status
                  status={form.getFieldValue('status') || 0}
                  expireAt={form.getFieldValue('expiration_date')}
                />
              </div>
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label="Quote Remark" name="remark">
              <TextArea rows={6} />
            </Form.Item>
          </Col>
          <Col md={4}>
            <Space direction="vertical">
              <Button type="primary" htmlType="submit" block>
                Save
              </Button>
              <Button block onClick={() => onClose()}>
                Cancel
              </Button>

              <Button
                type="primary"
                htmlType="button"
                disabled={!id}
                onClick={(e) => handleQuoteSend(e)}
                block>
                Quote Send
              </Button>
              {id && (
                <Button
                  type="primary"
                  htmlType="button"
                  disabled={!id}
                  onClick={handleCreateOrUpdateSellRates}
                  loading={loading}
                  block>
                  Create Or Update Sell Rates
                </Button>
              )}
            </Space>
          </Col>
        </Row>
        <Divider />

        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldValue }) => (
            <Form.Item
              name="quote_routes"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <QuoteRoutes
                handleApplySR={handleApplySR}
                data={getFieldValue('quote_routes') || []}
                arrayName="quote_routes"
                updateData={(list) => {
                  setFieldValue('quote_routes', list);
                }}
                extra={
                  <>
                    <Upload onUploaded={handleUploaded} />
                    <Button className="ml-sm" onClick={handleApplySRAll}>
                      Apply DreayEasy SR to All
                    </Button>
                    {autoSave && (
                      <Button
                        className="ml-sm"
                        onClick={handleLoadFromAutoSave}>
                        Load From AutoSave (
                        {moment(autoSave.saveAt).format('Y-MM-DD HH:mm:ss')})
                      </Button>
                    )}
                  </>
                }
                handleOpenRate={handleOpenRate}
              />
            </Form.Item>
          )}
        </Form.Item>
      </Form>
      {openBuyRate && buyRateId && (
        <BuyRateDrawer
          isNew={false}
          id={buyRateId}
          open={openBuyRate}
          onSaved={handleSaveBuyRate}
          onClose={handleCloseBuyRate}
        />
      )}
      {openSellRate && sellRateId && (
        <SellRateDrawer
          id={sellRateId}
          visible={openSellRate}
          onSaved={handleSaveSellRate}
          onClose={handleCloseSellRate}
        />
      )}
    </>
  );
};
