import React from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Space,
  Table,
} from 'antd';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment';
import AutoResizeTable from '@/components/AutoResizeTable';

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<any[]>();
  const [filter] = useForm();

  const fetchData = React.useCallback(async () => {
    const params = filter.getFieldsValue();
    if (undefined === params.start) {
      params.start = '';
    }
    if (undefined === params.end) {
      params.end = '';
    }
    setLoading(true);
    try {
      const result = await app.service.get('accountings/reports', {
        params: {
          ...params,
        },
      });
      setData([result.data]);
    } catch (e: any) {
      message.error(e.error || 'System Error');
    }

    setLoading(false);
  }, []);

  const columns: ColumnsType<any> = React.useMemo(
    () => [
      {
        title: 'Ar',
        dataIndex: 'ar',
        key: 'ar',
        width: 200,
      },
      {
        title: 'Ap',
        dataIndex: 'ap',
        key: 'ap',
        width: 200,
      },
      {
        title: 'Income',
        dataIndex: 'income',
        key: 'income',
        width: 200,
      },
      {
        title: 'Cost',
        dataIndex: 'cost',
        key: 'cost',
        width: 200,
      },
      {
        title: 'Balance',
        dataIndex: 'balance',
        key: 'balance',
        width: 200,
      },
    ],
    [],
  );

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.main}>
      <Header title="Report"></Header>

      <div className={styles.filter}>
        <Form layout="vertical" form={filter} onFinish={fetchData}>
          <Row gutter={16}>
            <Col>
              <Form.Item name="start" noStyle>
                <Input hidden />
              </Form.Item>
              <Form.Item name="end" noStyle>
                <Input hidden />
              </Form.Item>
              <Form.Item label="Date" shouldUpdate>
                {({ getFieldValue, setFieldsValue }) => {
                  const start = getFieldValue('start');
                  const end = getFieldValue('end');
                  return (
                    <DatePicker.RangePicker
                      value={
                        start && end ? [moment(start), moment(end)] : undefined
                      }
                      onChange={(value) =>
                        setFieldsValue({
                          start: value ? value[0]?.format('YYYY-MM-DD') : null,
                          end: value ? value[1]?.format('YYYY-MM-DD') : null,
                        })
                      }
                    />
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label={' '}>
                <Space>
                  <Button htmlType="submit" type="primary">
                    Run
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <AutoResizeTable
        loading={loading}
        pagination={false}
        size="small"
        rowKey={(record) => record.ar + Math.random()}
        columns={columns}
        dataSource={data || []}
        sticky
        scroll={{
          x: 'auto',
        }}
      />
    </div>
  );
};

export default Index;
