import { FC, ReactElement, useEffect, useState } from 'react';
import { useApp } from './useapp';
import React from 'react';
import { useHistory } from 'umi';
import { observer } from 'mobx-react';
import { Spin } from 'antd';

const Auth: FC = ({ ...props }) => {
  const app = useApp();
  const history = useHistory();
  // states
  const [loading, setLoading] = useState(true);

  const checkUser = async () => {
    if (!app.store.auth.token) {
      return history.push('/user/login');
    }

    setLoading(true);

    if (app.store.auth.token && !app.store.auth.account) {
      try {
        const resp = await app.service.get('admin');
        app.store.auth.setAccount(resp.data);
      } catch (e) {
        app.store.auth.logout();
        return history.push('/user/login');
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    checkUser();
  }, [app.store.auth.account]);

  if (!loading) {
    return props.children as ReactElement;
  } else {
    return (
      <div
        style={{
          height: '100vh',
          width: '100%',
          textAlign: 'center',
          lineHeight: '100vh',
        }}>
        <Spin tip="Loading..." />
      </div>
    );
  }
};

export default observer(Auth);
