import { Button, Select, Input } from 'antd';
import React, { useEffect } from 'react';
import { ModelSelect } from '@/components/ModelSelect';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { CACHE_VENDOR_INSURANCE_KEY } from '@/stores/cache';
import { VendorInsuranceDrawer } from '@/pages/entity/vendorInsurances/components/VendorInsuranceDrawer';
import { useApp } from '@/utils/useapp';
import { get } from 'lodash';

import SyncCacheModelSelect, {
  SyncCacheModelSelectProps,
} from './SyncCacheModelSelect';

type IVendorInsuranceSyncSelect = {
  // selected: any;
  vendorInsuranceId: any;
  onSelect: (model: any) => any;
  onSaved?: () => void;
};

export const VendorInsuranceSyncSelect: React.FC<
IVendorInsuranceSyncSelect & SyncCacheModelSelectProps
> = ({
  // selected,
  vendorInsuranceId,
  onSelect,
  onSaved,
  ...props
}) => {
  const app = useApp();

  const [id, setId] = React.useState(0);
  const [visible, setVisible] = React.useState(false);
  const handleSaved = (data: any) => {
    onSelect(data);
    handleClosed();
    app.store.cache.fetch(CACHE_VENDOR_INSURANCE_KEY, true);
    onSaved && onSaved();
  };

  const handleClosed = () => {
    setVisible(false);
    setId(0);
  };

  return (
    <>
      <Input.Group compact>
        <Button
          type="ghost"
          onClick={() => {
            setId(vendorInsuranceId || 0);
            setVisible(true);
          }}
          className="ant-btn-icon-only select-modal-icon">
          <span className="icon-gray">
            {' '}
            {vendorInsuranceId ? <EditOutlined /> : <PlusOutlined />}
          </span>
        </Button>
        <SyncCacheModelSelect
          {...props}
          placeholder="Search Insurance"
          onSelect={onSelect}
          value={vendorInsuranceId || 0}
          className="select-minus-edit-w"
          cacheKey={CACHE_VENDOR_INSURANCE_KEY}
          filterOption={(input, option: any) => {
            const optionLabel = get(option, 'label.props.children')
              ?.join('')
              ?.toLowerCase();
            return optionLabel.includes(input.toLowerCase());
          }}
          renderOption={(insurance: any) => {
            return (
              <>
                {insurance.expired && (
                  <strong className="text-danger">[Expired]</strong>
                )}
                [{insurance.mc_number}] {insurance.name}
              </>
            );
          }}
        />
      </Input.Group>
      {visible && (
        <VendorInsuranceDrawer
          visible={visible}
          id={id}
          mc_number={props?.mc_number}
          onClose={handleClosed}
          onSaved={handleSaved}
        />
      )}
    </>
  );
};
