import React from 'react';
import { Typography } from 'antd';

import { ClockCircleOutlined } from '@ant-design/icons';

const DefaultIcon: React.FC<{ className?: string }> = ({
  className = 'text-gray',
}) => (
  <span className={`${className}`}>
    <ClockCircleOutlined />
  </span>
);

export default DefaultIcon;
