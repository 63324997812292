import { action, computed, observable } from 'mobx';
import { TCity } from '@/types.d';

export class SearchStore {
  @observable _rateId: number | string | null = null;

  @observable _intermodalRegionId: number | null = null;

  @observable _toCity: TCity | null = null;

  @action setRateId(rateId: number | string | null) {
    this._rateId = rateId;
  }

  @action setIntermodalRegionId(intermodalRegionId: number | null) {
    this._intermodalRegionId = intermodalRegionId;
  }

  @action setToCity(toCity: TCity | null) {
    this._toCity = toCity;
  }

  @computed get rateId() {
    return this._rateId;
  }

  @computed get intermodalRegionId() {
    return this._intermodalRegionId;
  }

  @computed get toCity() {
    return this._toCity;
  }
}

const search = new SearchStore();

export default search;
