import React from 'react';
import { Form, FormInstance, Input, Select, Typography } from 'antd';
import { CREDIT_OPTION_MAP, CREDIT_OPTION_AGENT } from '../data';
import { Documents } from '@/components/Upload/Documents';
import { DocumentsStoreLocalFormItem } from '@/components/Upload/DocumentsStoreLocalFormItem';

interface Props {
  form: FormInstance;
}

export const VendorCreditsForm: React.FC<Props> = ({ form }) => {
  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={{ credit_option: CREDIT_OPTION_AGENT }}>
      <Form.Item name="id" noStyle>
        <Input hidden />
      </Form.Item>
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Name is required' }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Code" name="code" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="MC#"
        name="mc_number"
        rules={[{ required: true, message: 'MC# is required' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="Credit Option"
        name="credit_option"
        rules={[{ required: true }]}>
        <Select>
          {Object.keys(CREDIT_OPTION_MAP).map((key) => (
            <Select.Option key={key} value={key}>
              {
                CREDIT_OPTION_MAP[
                  key as unknown as keyof typeof CREDIT_OPTION_MAP
                ]
              }
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue, setFieldValue }) => (
          <>
            <Form.Item label="Terms" name="terms" rules={[{ required: true }]}>
              <Input
                type="number"
                value={getFieldValue('terms')}
                onChange={(e) => setFieldValue('terms', e.target.value)}
              />
              <Typography.Text type="secondary">
                Automatically synchronize terms for vendors using this Credit.
              </Typography.Text>
            </Form.Item>
          </>
        )}
      </Form.Item>

      <Form.Item label="Memo" name="memo" rules={[{ required: false }]}>
        <Input.TextArea rows={4} />
      </Form.Item>

      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) =>
          getFieldValue('id') ? (
            <Documents
              title="Documents"
              target="vendor_credit"
              targetId={getFieldValue('id')}
            />
          ) : (
            <DocumentsStoreLocalFormItem form={form} />
          )
        }
      </Form.Item>
    </Form>
  );
};
