import { Tag } from 'antd';
import React from 'react';
import whoseTaskUtil from '../../Interfaces/WhoseTask';

export const WhoseTaskTag: React.FC<{ whoseTask: number | null }> = ({
  whoseTask,
}) => {
  if (whoseTask) {
    return (
      <Tag
        // style={{padding: '2px', fontSize: '8px', lineHeight: '16px'}}
        color={
          whoseTaskUtil.WHOSE_TASK_COLOR_MAP[
            (whoseTask as unknown) as keyof typeof whoseTaskUtil.WHOSE_TASK_COLOR_MAP
          ]
        }
      >
        {
          whoseTaskUtil.WHOSE_TASK_MAP[
            (whoseTask as unknown) as keyof typeof whoseTaskUtil.WHOSE_TASK_MAP
          ]
        }
      </Tag>
    );
  } else {
    return <></>;
  }
};
