import request from '@/utils/request';
import auth from './authStore';
import { action, computed, observable, runInAction } from 'mobx';
import _ from 'lodash';

interface cacheable {
  uri: string;
  options?: { [key: string]: any };
}

export const CACHE_INTERMODALREGION_KEY = 'intermodal_region';
export const CACHE_INTERMODALREGION_SELECT_KEY = 'intermodal_region_select';
export const CACHE_INTERMODALREGION_OCEAN_SELECT_KEY =
  'intermodal_region_ocean';
export const CACHE_BANK_ACCOUNT_KEY = 'bank_account';
export const CACHE_BILLING_CODE_KEY = 'billing_cpde';
export const CACHE_CARGO_TYPE_KEY = 'cargo_type';
export const CACHE_CONTAINER_TYPE_KEY = 'container_type';
export const CACHE_FACILITY_TYPE_KEY = 'facility_type';
export const CACHE_GENERAL_LEDGER_CODE_KEY = 'gerneral_ledger_code';
export const CACHE_OCEAN_CARRIER_KEY = 'ocean_carrier';
export const CACHE_PORT_KEY = 'port';
export const CACHE_USER_KEY = 'user';
export const CACHE_USER_SELECT_KEY = 'user_select';
export const CACHE_ADMIN_KEY = 'admin';
export const CACHE_AGENT_KEY = 'agent';
export const CACHE_RATE_STATEMENTS_KEY = 'rate_statement';
export const CACHE_COMPANY_KEY = 'company';
export const CACHE_CONSIGNEE_KEY = 'consignee';
export const CACHE_VENDOR_DIVISION_KEY = 'vendor_division';
export const CACHE_VENDOR_CREDIT_KEY = 'vendor_credit';
export const CACHE_VENDOR_INSURANCE_KEY = 'vendor_insurance';
export const CACHE_VENDOR_FORM_W9_KEY = 'vendor_form_w9';
export const CACHE_VENDOR_CONTRACT_KEY = 'vendor_contract';

export const CACHE_CHASSIS_TYPE_KEY = 'chassis_type';
export const CACHE_PICKUP_NUMBER_AGENT_KEY = 'pickupNumberAgent';
export const CACHE_ACTIVE_TERMINAL_KEY = 'active_terminal';
export const CACHE_RATE_ENGINE_CONFIG_KEY = 'rate_engine_config';
export const CACHE_CONTAINER_COLOR_TAG_KEY = 'color_tags_containers';
export const CACHE_LTL_COLOR_TAG_KEY = 'color_tags_ltls';
export const CACHE_FTL_COLOR_TAG_KEY = 'color_tags_ftls';
export const CACHE_CFS_COLOR_TAG_KEY = 'color_tags_cfs';
export const CACHE_LTL_VENDOR_KEY = 'ltl_vendor';
export const CACHE_FTL_VENDOR_KEY = 'ftl_vendor';
export const CACHE_CFS_VENDOR_KEY = 'cfs_vendor';

export const CACHE_COMMODITY_KEY = 'commodity';

const CACHEABLE: { [key: string]: cacheable } = {
  [CACHE_INTERMODALREGION_SELECT_KEY]: {
    uri: 'intermodalRegions/options',
  },
  [CACHE_INTERMODALREGION_KEY]: {
    uri: 'intermodalRegions',
  },
  [CACHE_INTERMODALREGION_OCEAN_SELECT_KEY]: {
    uri: 'intermodalRegions/options?ocean=1',
  },
  [CACHE_BANK_ACCOUNT_KEY]: {
    uri: 'bankAccounts',
  },
  [CACHE_BILLING_CODE_KEY]: {
    uri: 'billingCodes',
  },
  [CACHE_CARGO_TYPE_KEY]: {
    uri: 'cargoTypes',
  },
  [CACHE_CONTAINER_TYPE_KEY]: {
    uri: 'containerTypes',
  },
  [CACHE_FACILITY_TYPE_KEY]: {
    uri: 'facilityTypes',
  },
  [CACHE_OCEAN_CARRIER_KEY]: {
    uri: 'oceanCarriers',
  },
  [CACHE_PORT_KEY]: {
    uri: 'ports',
  },
  [CACHE_USER_KEY]: {
    uri: 'users/all?sort_by=_company&sort_value=asc',
  },
  [CACHE_USER_SELECT_KEY]: {
    uri: 'users/allAsOption',
  },
  [CACHE_RATE_STATEMENTS_KEY]: {
    uri: 'rateScenarioStatements',
  },
  [CACHE_ADMIN_KEY]: {
    uri: 'admins/asOptions',
  },
  [CACHE_GENERAL_LEDGER_CODE_KEY]: {
    uri: 'generalLedgerCodes',
  },
  [CACHE_COMPANY_KEY]: {
    uri: 'companies',
  },
  [CACHE_CONSIGNEE_KEY]: {
    uri:
      'consignees?sort_by=name&sort_value=asc&sort_status=asc&withTrashed=true',
  },
  [CACHE_VENDOR_DIVISION_KEY]: {
    uri: 'vendorDivisions/all',
  },
  [CACHE_VENDOR_CREDIT_KEY]: {
    uri: 'vendor_credits/all',
  },
  [CACHE_VENDOR_INSURANCE_KEY]: {
    uri: 'vendor_insurances/all',
  },
  [CACHE_VENDOR_FORM_W9_KEY]: {
    uri: 'vendorFormW9s/all',
  },
  [CACHE_VENDOR_CONTRACT_KEY]: {
    uri: 'vendorContracts/all',
  },
  [CACHE_CHASSIS_TYPE_KEY]: {
    uri: 'chassis_types/all',
  },
  [CACHE_PICKUP_NUMBER_AGENT_KEY]: {
    uri: 'pickupNumberAgents/all',
  },
  [CACHE_ACTIVE_TERMINAL_KEY]: {
    uri: 'terminals?active=1&per_page=10000',
  },
  [CACHE_RATE_ENGINE_CONFIG_KEY]: {
    uri: 'rateEngineConfigs',
  },
  [CACHE_CONTAINER_COLOR_TAG_KEY]: {
    uri: 'colorTags/containers',
  },
  [CACHE_LTL_COLOR_TAG_KEY]: {
    uri: 'colorTags/ltls',
  },
  [CACHE_FTL_COLOR_TAG_KEY]: {
    uri: 'colorTags/ftls',
  },
  [CACHE_CFS_COLOR_TAG_KEY]: {
    uri: 'colorTags/cfs',
  },
  [CACHE_COMMODITY_KEY]: {
    uri: 'commodities',
  },
  [CACHE_LTL_VENDOR_KEY]: {
    uri: 'tl/ltl/vendors',
  },
  [CACHE_FTL_VENDOR_KEY]: {
    uri: 'ftl/vendors',
  },
  [CACHE_CFS_VENDOR_KEY]: {
    uri: 'cfs/vendors',
  },
};

type Key = keyof typeof CACHEABLE;

export class Cache {
  @observable data: { [key: Key]: any[] } = {};
  @observable loadingMap: { [key: Key]: boolean } = {};

  // @observable buffers: any = {};

  @action async fetch(key: Key, refresh?: boolean, params = {}) {
    if (!auth.loggedIn) {
      return;
    }

    if (this.loadingMap[key] && !refresh) {
      return;
    }

    if (!CACHEABLE[key]) {
      throw new Error(`Cant find the ${key} in CACHEABLE`);
    }

    this.setLoading(key);

    try {
      const resp = await request.get(
        CACHEABLE[key].uri,
        CACHEABLE[key].options,
      );

      runInAction(() => {
        this.data[key] = resp.data || [];
      });
    } catch (e: any) {
      console.log(e);
    }

    this.setUnLoading(key);
  }

  @action setLoading(key: Key) {
    this.loadingMap[key] = true;
  }

  @action setUnLoading(key: Key) {
    this.loadingMap[key] = false;
  }

  @action get(key: Key) {
    return this.data[key];
  }
}

const cache = new Cache();

export const useCache = (key: Key, defaultValue = []) => {
  if (cache.data[key]) {
    return cache.data[key];
  } else {
    cache.fetch(key);
    return defaultValue;
  }
};

export const useAsyncCache = async (key: Key) => {
  if (!cache.data[key]) {
    await cache.fetch(key);
  }

  return cache.data[key];
};

export default cache;
