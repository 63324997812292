import { Card, Descriptions, Space } from 'antd';
import { Rate } from './QuoteForm';
import moment from 'moment-timezone';

export interface TopCardProps {
  rate: Rate;
}

const TopCard = ({ rate }: TopCardProps) => {
  return (
    <Card>
      <Space direction="vertical">
        <Space direction="horizontal">
          <Descriptions
            labelStyle={{ fontWeight: 'bold', fontSize: '16px' }}
            contentStyle={{ fontWeight: 'bold', fontSize: '16px' }}
            size="small"
            column={4}
          >
            <Descriptions.Item label={'Source'}>
              {rate?.carrier_name} | {rate?.carrier_code}
            </Descriptions.Item>
            <Descriptions.Item label={'Quote Rate'}>
              {rate?.quote_rate}
            </Descriptions.Item>
            <Descriptions.Item label={'Final Sell Rate'}>
              {rate?.rate}
            </Descriptions.Item>
          </Descriptions>
        </Space>
        <Space direction="horizontal">
          <Descriptions
            labelStyle={{ fontWeight: 'bold', fontSize: '16px' }}
            contentStyle={{ fontWeight: 'bold', fontSize: '16px' }}
            size="small"
            column={4}
          >
            <Descriptions.Item label={'Pickup Date'}>
              {moment(rate?.estimated_pickup_date).format('MM/DD/YYYY')}
            </Descriptions.Item>
            <Descriptions.Item label={'Est. Delivery'}>
              {moment(rate?.estimated_delivery_date).format('MM/DD/YYYY')}
            </Descriptions.Item>
            <Descriptions.Item label={'Transit days'}>
              {rate?.transit_days}
            </Descriptions.Item>
            <Descriptions.Item label={'Expiration date'}>
              {moment(rate?.expiration_date).format('MM/DD/YYYY')}
            </Descriptions.Item>
          </Descriptions>
        </Space>
      </Space>
    </Card>
  );
};

export default TopCard;
