import { Button, Space, DatePicker } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import { get, upperFirst, has, isArray, flatMap, debounce } from 'lodash';
import { AttributeChangeTarget } from '@/Interfaces/AttributeChangeLog/AttributeChangeTarget';
import actionUtils, {
  TLtlShipmentAction,
  TARGET_TYPE_LTL_SHIPMENT,
} from '@/components/Tasks/Interfaces/ActionInterface';
import { ShipmentBusinessStatus } from '@/pages/truckload/ltl/constants';
import LtlShipmentSendPod from './LtlShipmentSendPod';
import LtlShipmentSendBOL from './LtlShipmentSendBOL';
import LtlSendPUVerification from './LtlSendPUVerification';
import LtlSendDELVerification from './LtlSendDELVerification';

import { EditAction } from './EditAction';
import InputButton from './InputButton';
import LtlRatingButton from './LtlRatingButton';
import useAction, { TCheckValid } from './useAction';
import { ConfirmBeforeUpdate } from './ConfirmBeforeUpdate';
import moment from 'moment';
import { RescheduledAction } from './RescheduledAction';
import Upload from './Upload';
import { EditLink } from './EditLink';
import EditRate from './EditRate';
import _ from 'lodash';
import InputAvailableTime from './InputAvailableTime';
import { ShipmentInterface } from '@/pages/truckload/ltl/shipments/Interfaces/ShipmentInterface';
import LtlShipmentCreateBOL from './LtlShipmentCreateBOL';

const PLACEHOLDER_MAP: Record<string, string> = {
  [actionUtils.INPUT_BR]: 'Input BR',
  [actionUtils.INPUT_VID]: 'Input VID',
  [actionUtils.INPUT_SR]: 'Input SR',
  [actionUtils.INPUT_PRO_NUMBER]: 'Input Pro Number',
};

const BUTTON_ELEMENT_MAP: Record<string, React.ReactNode> = {
  [actionUtils.INPUT_BR]: <Button type="link">Input BR</Button>,
  [actionUtils.INPUT_VID]: <Button type="link">Input VID</Button>,
};

const LtlShipmentTaskActions: React.FC<TLtlShipmentAction> = ({
  task,
  targetModel: ltlShipment,
  onDone,
  onSaveOperation,
}) => {
  const { saveOperationData } = useAction({
    target: TARGET_TYPE_LTL_SHIPMENT,
    targetModel: ltlShipment,
  });

  const [updatingData, setUpdatingData] = React.useState<{
    [key: string]: TCheckValid;
  }>({});

  const [loading, setLoading] = React.useState<boolean>(false);

  const handleOnSaveOperation = async (
    action: string,
    update_name: string,
    value?: string | null,
  ) => {
    await onSaveOperation(
      task,
      action,
      update_name,
      value,
      (loading: boolean) => setLoading(loading),
      onDone,
    );
  };

  const toSaveConfirmOperation = async (
    action: string,
    update_name: string,
    value?: string | null,
  ) => {
    await onSaveOperation(
      task,
      action,
      update_name,
      value,
      (loading: boolean) => setLoading(loading),
      onDoneConfirmOperation,
    );
  };

  const onDoneConfirmOperation = () => {
    setUpdatingData({});
    onDone(task, ltlShipment as ShipmentInterface);
  };

  useEffect(() => {
    setLoading(false);
  }, [loading]);
  const operation = useMemo(() => task?.operation, [task]);

  const _actions = useMemo(() => {
    if (!operation?.is_done) {
      return task?.actions;
    }

    const taskActions = task?.actions ? flatMap(task.actions, 'action') : [];

    const newActions = task?.actions.filter((actionItem) =>
      actionUtils.ALWAY_SHOW_ACTIONS.includes(actionItem.action),
    );

    if (
      !actionUtils.FORBIDDEN_UNDO_TASKIDS.includes(task.id) ||
      (![ShipmentBusinessStatus.CANCELED].includes(
        ltlShipment.business_status,
      ) &&
        !actionUtils.FORBIDDEN_UNDO_ACTIONS.some((action) =>
          taskActions.includes(action),
        ))
    ) {
      newActions.push({ action: actionUtils.UNDO, update_name: null });
    }

    return newActions;
  }, [task]);

  return (
    <>
      <Space>
        {_actions?.map((actionRecord: any) => {
          const { action, update_name, avaliable_time_name } = actionRecord;

          const actionDisabled =
            task?.disabled ||
            (action == actionUtils.UNDO ? false : task.disabled);
          let value;

          if (isArray(update_name)) {
            if (has(update_name, 'time')) {
              value = get(ltlShipment, update_name[get(update_name, 'time')]);
            }
          } else {
            value = get(ltlShipment, update_name);
          }
          // use for the confirm before update
          const toSetUpdateData = (value: string | null) =>
            setUpdatingData((prev) => {
              const prevTask = prev && prev[task.id] ? prev[task.id] : {};

              const currentTaskAction = {
                task_id: task.id,
                action,
                name: update_name,
                value: value,
              };

              return {
                ...prev,
                [task.id]: {
                  ...prevTask,
                  [action]: currentTaskAction,
                },
              };
            });

          switch (action) {
            case actionUtils.INPUT_PRO_NUMBER:
            case actionUtils.INPUT_VENDOR_PU:
              return (
                <InputButton
                  task={task}
                  disabled={task?.disabled || !task.is_can_edit}
                  targetModel={ltlShipment}
                  target={TARGET_TYPE_LTL_SHIPMENT}
                  action={action}
                  name={update_name}
                  value={value}
                  placeholder={PLACEHOLDER_MAP[action] ?? 'Input'}
                  onDone={onDone}
                  onSaveOperation={onSaveOperation}
                />
              );
            case actionUtils.INPUT:
              return (
                <InputButton
                  task={task}
                  targetModel={ltlShipment}
                  target={TARGET_TYPE_LTL_SHIPMENT}
                  action={action}
                  name={update_name}
                  value={value}
                  placeholder="Input"
                  onDone={onDone}
                  onSaveOperation={onSaveOperation}
                />
              );
            case actionUtils.INPUT_BR:
            case actionUtils.INPUT_VID:
            case actionUtils.INPUT_SR: {
              return (
                <EditAction
                  task={task}
                  targetModel={ltlShipment}
                  target={TARGET_TYPE_LTL_SHIPMENT}
                  action={action}
                  name={update_name}
                  value={value}
                  onDone={onDone}
                  onSaveOperation={onSaveOperation}
                  placeholder={PLACEHOLDER_MAP[action] ?? 'Input'}
                  ButtonElement={BUTTON_ELEMENT_MAP[action] ?? undefined}
                />
              );
            }
            case actionUtils.EDIT_SR:
            case actionUtils.EDIT_BR:
              return (
                <EditRate
                  target={TARGET_TYPE_LTL_SHIPMENT}
                  targetModel={ltlShipment}
                  task={task}
                  action={action}
                  name={update_name}
                  onDone={onDone}
                />
              );

            case actionUtils.INPUT_DATE:
              return (
                <ConfirmBeforeUpdate
                  target={TARGET_TYPE_LTL_SHIPMENT}
                  targetModel={ltlShipment}
                  key={task.id + '_' + action}
                  task={task}
                  toSave={() => {
                    const value =
                      updatingData[task.id] && updatingData[task.id][action]
                        ? updatingData[task.id][action].value
                        : null;
                    toSaveConfirmOperation(action, update_name, value);
                  }}
                  updatingData={
                    updatingData &&
                    updatingData[task.id] &&
                    updatingData[task.id][action]
                      ? updatingData[task.id][action]
                      : null
                  }
                >
                  <DatePicker
                    disabled={task?.disabled || !task.is_can_edit}
                    getPopupContainer={(trigger: any) => trigger.parentElement}
                    size="small"
                    value={value ? moment(value) : null}
                    onChange={(value) => {
                      toSetUpdateData(
                        value ? value.format('YYYY-MM-DD') : null,
                      );
                    }}
                  />
                </ConfirmBeforeUpdate>
              );
            case actionUtils.INPUT_AVAILABLE_TIME:
              return (
                <InputAvailableTime
                  action={action}
                  task={task}
                  name={update_name}
                  availableTimeName={avaliable_time_name}
                  target={TARGET_TYPE_LTL_SHIPMENT}
                  targetModel={ltlShipment}
                  disabled={task?.disabled || !task.is_can_edit}
                  onDone={onDone}
                  onSaveOperation={onSaveOperation}
                />
              );
            case actionUtils.RESCHEDULE:
              return (
                <RescheduledAction
                  target={TARGET_TYPE_LTL_SHIPMENT}
                  targetModel={ltlShipment}
                  task={task}
                  onSaveOperation={onSaveOperation}
                  onDone={onDone}
                />
              );

            case actionUtils.UPLOAD:
              return (
                <Upload
                  task={task}
                  target={TARGET_TYPE_LTL_SHIPMENT}
                  targetModel={ltlShipment}
                  onDone={onDone}
                  onSaveOperation={onSaveOperation}
                />
              );
            case actionUtils.SEND_PU_VERIFICATION:
              return (
                <LtlSendPUVerification
                  task={task}
                  targetModel={ltlShipment}
                  target={TARGET_TYPE_LTL_SHIPMENT}
                  onDone={onDone}
                />
              );
            case actionUtils.SEND_DEL_VERIFICATION:
              return (
                <LtlSendDELVerification
                  task={task}
                  targetModel={ltlShipment}
                  target={TARGET_TYPE_LTL_SHIPMENT}
                  onDone={onDone}
                />
              );
            case actionUtils.SEND_POD:
              return (
                <LtlShipmentSendPod
                  task={task}
                  targetModel={ltlShipment}
                  target={TARGET_TYPE_LTL_SHIPMENT}
                  onDone={onDone}
                  onSaveOperation={onSaveOperation}
                />
              );
            case actionUtils.SEND_BOL:
              return (
                <LtlShipmentSendBOL
                  task={task}
                  targetModel={ltlShipment}
                  target={TARGET_TYPE_LTL_SHIPMENT}
                  onDone={onDone}
                  onSaveOperation={onSaveOperation}
                />
              );
            case actionUtils.CREAT_BOL:
              return (
                <LtlShipmentCreateBOL
                  task={task}
                  targetModel={ltlShipment}
                  target={TARGET_TYPE_LTL_SHIPMENT}
                  onDone={onDone}
                  onSaveOperation={onSaveOperation}
                />
              );
            case actionUtils.RATING:
              return (
                <LtlRatingButton
                  task={task}
                  targetModel={ltlShipment}
                  target={TARGET_TYPE_LTL_SHIPMENT}
                  onDone={onDone}
                  onSaveOperation={onSaveOperation}
                />
              );

            case actionUtils.INPUT_CACENL_LINK:
              return (
                <ConfirmBeforeUpdate
                  key={task.id + '_' + action}
                  target={TARGET_TYPE_LTL_SHIPMENT}
                  targetModel={ltlShipment}
                  task={task}
                  toSave={() => {
                    const value =
                      updatingData[task.id] && updatingData[task.id][action]
                        ? updatingData[task.id][action].value
                        : null;
                    toSaveConfirmOperation(action, update_name, value);
                  }}
                  updatingData={
                    updatingData &&
                    updatingData[task.id] &&
                    updatingData[task.id][action]
                      ? updatingData[task.id][action]
                      : null
                  }
                >
                  <EditLink
                    task={task}
                    targetModel={ltlShipment}
                    target={TARGET_TYPE_LTL_SHIPMENT}
                    action={action}
                    name={update_name}
                    value={value}
                    placeholder="Enter Cancel Link"
                    onChange={(value) => toSetUpdateData(value)}
                  />
                </ConfirmBeforeUpdate>
              );
            default: {
              if (
                actionUtils.CANCEL_TASKIDS.includes(task.id) &&
                action != actionUtils.UNDO
              ) {
                return (
                  <ConfirmBeforeUpdate
                    key={task.id + '_' + action}
                    target={TARGET_TYPE_LTL_SHIPMENT}
                    targetModel={ltlShipment}
                    task={task}
                    toSave={() => toSaveConfirmOperation(action, update_name)}
                    updatingData={
                      updatingData &&
                      updatingData[task.id] &&
                      updatingData[task.id][action]
                        ? updatingData[task.id][action]
                        : null
                    }
                  >
                    <Button
                      key={action}
                      size="small"
                      disabled={task.disabled || actionDisabled}
                      onClick={(e: any) => toSetUpdateData(null)}
                    >
                      {upperFirst(action)}
                    </Button>
                  </ConfirmBeforeUpdate>
                );
              } else {
                return (
                  <Button
                    key={action}
                    size="small"
                    disabled={task.disabled || actionDisabled}
                    onClick={() => {
                      handleOnSaveOperation(action, update_name);
                    }}
                  >
                    {upperFirst(action)}
                  </Button>
                );
              }
            }
          }
        })}
      </Space>
    </>
  );
};

export default LtlShipmentTaskActions;
