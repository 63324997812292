import { useApp } from '@/utils/useapp';
import { Drawer, Space, Button, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect } from 'react';
import { get } from 'lodash';
import { HolidayForm } from './HolidayForm';

interface IHolidaysDrawer {
  id: number;
  copyRecord: any;
  visible: boolean;
  onClose: () => void;
  onSaved?: (data: any) => void;
}

export const HolidaysDrawer: React.FC<IHolidaysDrawer> = ({
  id,
  visible,
  copyRecord,
  onClose,
  onSaved,
}) => {
  const app = useApp();
  const [loading, setLoading] = React.useState(false);
  const [form] = useForm();

  const fetchData = async (id: number) => {
    setLoading(true);
    try {
      const resp = await app.service.get(`holidays/${id}`);
      form.setFieldsValue({
        ...resp.data,
      });
    } catch (err: any) {
      message.error(err.data.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!id) {
      return;
    }

    fetchData(id);
  }, [id]);

  const handleSave = async () => {
    const values = await form.validateFields();
    setLoading(true);

    try {
      const resp = !values.id
        ? await app.service.post('holidays', { data: values })
        : await app.service.put(`holidays/${values.id}`, {
          data: values,
        });
      message.success('Saved');
      onSaved && onSaved(resp.data);
      handleClose();
    } catch (e: any) {
      message.error(
        get(e, 'data.message', false) || get(e, 'data.error', 'Network Error'),
      );
    }

    setLoading(false);
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    copyRecord && form.setFieldsValue({ ...copyRecord });
  }, [copyRecord]);

  return (
    <Drawer
      title={form.getFieldValue('uid')}
      width={'65%'}
      onClose={handleClose}
      destroyOnClose={true}
      open={visible}
      extra={
        <Space>
          <Button onClick={handleClose}>Close</Button>
          <Button type="primary" loading={loading} onClick={handleSave}>
            Save
          </Button>
        </Space>
      }>
      <HolidayForm form={form} />
    </Drawer>
  );
};
