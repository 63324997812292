import React, { useState } from 'react';
import { Calendar, Spin } from 'antd';
import { CalendarHeader } from './CalendarHeader';
import type { Moment } from 'moment';
import moment from 'moment-timezone';

const _Calendar: React.FC<{
  loading?: boolean;
  updateDataRange: (range: { start: string; end: string }) => void;
  dateCellRender: (value: Moment) => React.ReactNode;
}> = ({ loading = false, updateDataRange, dateCellRender }) => {
  const [dateRange, setDateRange] = useState({
    start: moment().startOf('month').startOf('week').format('YYYY-MM-DD'),
    end: moment().endOf('month').endOf('week').format('YYYY-MM-DD'),
  });

  const handleDateRangeChange = (range: { start: string; end: string }) => {
    if (range.start == dateRange.start && range.end == dateRange.end) {
      return;
    }
    setDateRange(range);
    updateDataRange(range);
  };

  return (
    <Spin spinning={loading}>
      <div style={{ maxHeight: '60vh', overflow: 'auto' }}>
        <Calendar
          dateFullCellRender={dateCellRender}
          headerRender={(props) => (
            <CalendarHeader
              {...props}
              onDateRangeChange={handleDateRangeChange}
            />
          )}
        />
      </div>
    </Spin>
  );
};

export default _Calendar;
