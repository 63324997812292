import { Space, Form, Input, Button, Tooltip } from 'antd';
import React from 'react';
import {
  MinusCircleOutlined,
  PlusOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { FormInstance } from 'antd/es/form';

export const AdditionalNotificationEmails: React.FC<{
  form: FormInstance;
}> = ({ form }) => {
  return (
    <Form.Item
      label={
        <Space>
          Additional notification emails
          <Tooltip
            title={
              <>
                <div>
                  1. The order confirmation email will cc these email addresses
                  as well.
                </div>
              </>
            }>
            <InfoCircleOutlined />
          </Tooltip>
        </Space>
      }>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue, setFieldsValue }) => (
          <Form.List
            // initialValue={getFieldValue('additional_notification_emails') || []} //TEST
            name="additional_notification_emails">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <Form.Item label={''} key={field.key}>
                    <Form.Item
                      wrapperCol={{ xs: 10 }}
                      {...field}
                      noStyle
                      rules={[
                        {
                          required: false,
                          message: 'Please input Email',
                        },
                      ]}>
                      <Input
                        minLength={5}
                        placeholder="Email"
                        style={{
                          width: fields.length > 1 ? '95%' : '100%',
                        }}
                      />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        style={{ marginLeft: '10px' }}
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{
                      width: '100%',
                    }}
                    icon={<PlusOutlined />}>
                    Add
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        )}
      </Form.Item>
    </Form.Item>
  );
};
