import ClickWrapper from '@/components/ClickWrapper';
import React from 'react';
import { InvoiceDrawer } from './InvoiceDrawer';

interface Props {
  invoiceableId: number;
  invoiceableType: string;
  containerNumber?: string;
  children: React.ReactNode;
  onSaved?: () => void;
}

const NewInvoice: React.FC<Props> = ({
  invoiceableId,
  invoiceableType,
  containerNumber,
  children,
  onSaved,
}) => {
  const [visible, setVisible] = React.useState<boolean>(false);

  return (
    <>
      <ClickWrapper onClick={() => setVisible(!visible)}>
        {children}
      </ClickWrapper>
      {visible && (
        <InvoiceDrawer
          id={0}
          containerNumber={containerNumber}
          invoiceableId={invoiceableId}
          invoiceableType={invoiceableType}
          visible={visible}
          onClose={() => setVisible(false)}
          onSaved={onSaved}
        />
      )}
    </>
  );
};

export default NewInvoice;
