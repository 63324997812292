import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Divider,
  Drawer,
  message,
  Popconfirm,
  Space,
  Switch,
  Table,
  Tag,
} from 'antd';
import React, { useMemo, useState } from 'react';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { get } from 'lodash';
import { DrawerForm } from './components/DawerForm';
import { useForm } from 'antd/lib/form/Form';
import AutoResizeTable from '@/components/AutoResizeTable';
import { UNIT_MAP as POSSIBLECHARNGE_UNIT_MAP } from '@/components/PossibleCharges';
import { Filter } from './components/Filter';

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 12,
    total: 0,
  });
  const [billingCode, setBillingCode] = useState<any>(null);
  const [form] = useForm();
  const [filter] = useForm();

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    form.resetFields();
  };

  const handleNewVendor = () => {
    showDrawer();
  };

  const fetchData = async ({ page } = { page: 1 }) => {
    setLoading(true);
    try {
      const result = await app.service.get('billingCodes', {
        params: {
          ...filter.getFieldsValue(),
          relations: ['revenueGeneralLedgerCode', 'costGeneralLedgerCode'],
        },
      });
      setData(result.data);
    } catch (error) {
      message.error(error.data?.message || error.data?.error || 'System Error');
    } finally {
      setLoading(false);
    }
  };

  const isNew = useMemo(() => {
    return !billingCode;
  }, [billingCode]);

  const handleSave = async () => {
    const data = await form.validateFields();
    setLoading(true);
    const formValue = Object.assign({}, data);

    const request = isNew
      ? app.service.post('billingCodes', { data: formValue })
      : app.service.put(`billingCodes/${billingCode}`, { data: formValue });

    request
      .then((resp) => {
        fetchData({ page: pagination.current });
        setBillingCode(null);
        onClose();
      })
      .catch((err) => {
        message.error(err.data?.message || err.data?.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRemove = (id: number) => {
    setLoading(true);
    app.service
      .delete(`billingCodes/${id}`)
      .then((resp) => {
        fetchData({ page: pagination.current });
      })
      .catch((err) => {
        message.error(err.data?.message || err.data?.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEdit = (id: number) => {
    const item = data.find((item) => item.id === id);
    if (!item) {
      return;
    }
    setVisible(true);
    setBillingCode(id);
    form.setFieldsValue({ ...item });
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = () => {
    fetchData();
  };

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 80,
      render: (text, record, index) => <>{record.id}</>,
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      width: 200,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit',
      width: 200,
      render: (text, record) =>
        get(POSSIBLECHARNGE_UNIT_MAP, get(record, 'unit', null), null),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      width: 120,
    },
    {
      title: 'Revenue',
      key: 'revenue',
      width: 200,
      children: [
        {
          title: 'Code',
          dataIndex: 'code',
          key: 'code',
          width: 200,
          render: (text, record) =>
            get(record, 'revenue_general_ledger_code.code'),
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          width: 200,
          render: (text, record) =>
            get(record, 'revenue_general_ledger_code.name'),
        },
      ],
    },
    {
      title: 'Cost',
      key: 'cost',
      width: 200,
      children: [
        {
          title: 'Code',
          dataIndex: 'code',
          key: 'code',
          width: 200,
          render: (text, record) =>
            get(record, 'cost_general_ledger_code.code'),
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          width: 200,
          render: (text, record) =>
            get(record, 'cost_general_ledger_code.name'),
        },
      ],
    },
    {
      align: 'center',
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 150,
      render: (text, record) => (
        <span>
          <a onClick={() => handleEdit(record.id)}>Edit</a>
          <Divider type="vertical" />
          <Popconfirm
            title="Sure to delete?"
            okText="Confirm"
            cancelText="Cancel"
            onConfirm={() => handleRemove(record.id)}
          >
            <a>Delete</a>
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <div className={styles.main}>
      <Header
        title="Billing Codes"
        rightElement={
          <div>
            <Button type="primary" onClick={handleNewVendor}>
              New Billing Code
            </Button>
          </div>
        }
      ></Header>

      <Filter loading={loading} filter={filter} onSearch={handleSearch}>
        {' '}
      </Filter>
      <div>
        <AutoResizeTable
          pagination={{
            defaultPageSize: 20,
          }}
          loading={loading}
          size="small"
          rowKey="id"
          columns={columns}
          dataSource={[...data]}
          scroll={{ x: 1500 }}
          sticky
        />
      </div>
      <Drawer
        title=""
        placement={'right'}
        width={'40%'}
        onClose={onClose}
        destroyOnClose={true}
        open={visible}
        extra={
          <Space>
            <Button onClick={onClose}>Close</Button>
            <Button type="primary" loading={loading} onClick={handleSave}>
              Save
            </Button>
          </Space>
        }
      >
        <DrawerForm form={form} isNew={isNew} updateData={setBillingCode} />
      </Drawer>
    </div>
  );
};

export default Index;
