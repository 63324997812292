import {
  Typography,
  Select,
  Space,
  Tooltip,
  Row,
  Popconfirm,
  message,
  Tag,
} from 'antd';
import React, { useMemo, useState } from 'react';
import {
  DOUCMENT_FILE_TYPE_INSURANCE_CERTIFICATE,
  DOCUMENT_FILE_TYPE_POD,
  DOCUMENT_FILE_TYPE_OTHER,
} from './data';
import { IMAGE_TYPES } from '@/components/Upload/data';
import {
  DownloadOutlined,
  DeleteOutlined,
  UserOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  ScissorOutlined,
} from '@ant-design/icons';
import { get, has } from 'lodash';
import { SelectDocumentType } from './SelectDocumentType';
import { CommentModal } from './CommentModal';
import { CopyOutlined } from '@ant-design/icons';

const { Text, Link } = Typography;
interface IFileItemRender {
  file: any;
  // containers: any;
  handleUpdate: (fileId: any, values: any) => void;
  handlePreview: (file: any) => void;
  handleDownload: (file: any) => void;
  handleRemove: (file: any) => void;
  onOpenCutImage: (file: any) => void;
  handleCopyToAdminUploadDocument: (fileId: number) => void;
}

export const FileItemRender: React.FC<IFileItemRender> = ({
  file,
  // containers,
  handleUpdate,
  handlePreview,
  handleDownload,
  handleRemove,
  onOpenCutImage,
  handleCopyToAdminUploadDocument,
}) => {
  const [showComment, setShowComment] = useState(false);

  const customProperties = useMemo(() => get(file, 'custom_properties'), [
    file,
  ]);

  const isCustomerUpload = useMemo(
    () => get(customProperties, 'is_customer_upload'),
    [customProperties],
  );

  const customerVisible = useMemo(
    () => get(customProperties, 'customer_visible'),
    [customProperties],
  );

  const isInusranceDoc = useMemo(() => {
    const documentType = get(customProperties, 'document_type');
    return documentType === DOUCMENT_FILE_TYPE_INSURANCE_CERTIFICATE;
  }, [customProperties]);

  const containerIds = useMemo(
    () => get(customProperties, 'container_ids', [])?.map((cid) => Number(cid)),
    [customProperties],
  );

  const isPODImage = useMemo(
    () =>
      -1 !== IMAGE_TYPES.indexOf(get(file, 'mime_type')) &&
      get(customProperties, 'document_type') == DOCUMENT_FILE_TYPE_POD,
    [customProperties],
  );

  const comment = useMemo(() => get(customProperties, 'comment'), [
    customProperties,
  ]);

  const handleDocumentTypeChange = async (values: any) => {
    try {
      await handleUpdate(file.id, values);
      if (
        values.document_type &&
        DOCUMENT_FILE_TYPE_OTHER == values.document_type
      ) {
        setShowComment(true);
      }
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    }
  };

  const onCloseComment = () => {
    setShowComment(false);
  };

  return (
    <>
      <Row className="flex justify-between align-center mt-md mb-md">
        <div>
          <Space>
            {!isCustomerUpload && (
              <>
                {comment ? (
                  <Tooltip title={comment}>
                    <SelectDocumentType
                      disabled={isInusranceDoc}
                      value={get(customProperties, 'document_type')}
                      style={{ width: '12vw' }}
                      size="small"
                      onChange={handleDocumentTypeChange}
                    />
                  </Tooltip>
                ) : (
                  <SelectDocumentType
                    disabled={isInusranceDoc}
                    value={get(customProperties, 'document_type')}
                    style={{ width: '12vw' }}
                    size="small"
                    onChange={handleDocumentTypeChange}
                  />
                )}
              </>
            )}
            {isCustomerUpload && (
              <>
                <Tooltip
                  title={
                    <div>
                      <span>Customer Upload</span>
                    </div>
                  }
                >
                  <UserOutlined />
                </Tooltip>
              </>
            )}
            <Link onClick={() => handlePreview(file)}>{file.file_name}</Link>
            {isCustomerUpload && (
              <>
                {get(customProperties, 'document_type') && (
                  <small className="text-gray">
                    ({get(customProperties, 'document_type')})
                  </small>
                )}

                <Tooltip
                  placement="topLeft"
                  title={<>Copy to Admin Upload Document</>}
                  arrowPointAtCenter
                >
                  <Typography.Link
                    onClick={() => handleCopyToAdminUploadDocument(file.id)}
                  >
                    <CopyOutlined />
                  </Typography.Link>
                </Tooltip>
              </>
            )}
          </Space>
        </div>
        <div>
          <Space>
            {get(customProperties, 'creator_name') && (
              <Text>created by {get(customProperties, 'creator_name')}</Text>
            )}
            <Text> at {file.created_at}</Text>
            {isPODImage && (
              <Tooltip title="Only image can be edited">
                <Link onClick={() => onOpenCutImage(file)}>
                  <ScissorOutlined />
                </Link>
              </Tooltip>
            )}
            <Link
              disabled={isCustomerUpload}
              onClick={() => {
                handleUpdate(file.id, { customer_visible: !customerVisible });
              }}
            >
              <Tooltip
                title={
                  customerVisible || isCustomerUpload ? (
                    <span>Customer Visible</span>
                  ) : (
                    <span>Customer Invisible </span>
                  )
                }
              >
                {customerVisible || isCustomerUpload ? (
                  <EyeOutlined />
                ) : (
                  <EyeInvisibleOutlined />
                )}
              </Tooltip>
            </Link>
            <Link onClick={() => handleDownload(file)}>
              <DownloadOutlined />
            </Link>
            {!isCustomerUpload && !isInusranceDoc && (
              <Popconfirm
                placement="top"
                title={'Are you sure to delete?'}
                onConfirm={() => handleRemove(file)}
                okText="Yes"
                cancelText="No"
              >
                <Link>
                  <DeleteOutlined />
                </Link>
              </Popconfirm>
            )}
          </Space>
        </div>
      </Row>
      {showComment && (
        <CommentModal
          open={showComment}
          fileId={file.id}
          handleUpdate={handleUpdate}
          onClose={onCloseComment}
        />
      )}
    </>
  );
};
