import { message, Form, Modal, Space, Button, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';

import { useApp } from '@/utils/useapp';
import EmailForm from '@/components/EmailForm';
import { serialize } from 'object-to-formdata';
import { concat, get, uniq } from 'lodash';
import { EMAIL_TL } from '@/pages/setting/configurations/components/data';
import useAction from '../useAction';
import actionUtil, {
  TFtlShipmentAction,
  TSendEmailModal,
  DOC_TARGET_MAP,
} from '../../Interfaces/ActionInterface';

const SendPodEmailModal: React.FC<TSendEmailModal & TFtlShipmentAction> = ({
  task,
  admin,
  targetModel: ftlShipment,
  target,
  open,
  onDone,
  onClose,
}) => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [emailForm] = useForm();
  const [docs, setDocs] = useState<any[]>([]);
  const { sendEmail } = useAction({ target, targetModel: ftlShipment });

  const handleOnClose = () => {
    emailForm.resetFields();
    onClose();
  };

  const handleSendEmail = async () => {
    setLoading(true);
    try {
      const data = await emailForm.validateFields();

      const config = {
        data: serialize(
          {
            ...data,
            task_id: task.id,
            action: actionUtil.SEND_POD,
          },
          { indices: true, nullsAsUndefineds: true },
        ),
        requestType: 'form',
      };

      await sendEmail(config, setLoading, onDone);

      emailForm.resetFields();
    } catch (err: any) {
      err.data && message.error(err.data.message || err.data.error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDocuments = async () => {
    if (!ftlShipment?.id || !target || !DOC_TARGET_MAP[target]) {
      return;
    }

    const docTarget = DOC_TARGET_MAP[target];

    try {
      const docs = await app.service.get(
        `documents/${docTarget}/${ftlShipment.id}`,
      );

      const resData = [...docs.data];

      setDocs(
        resData.map((d: any) => {
          return {
            label: `[${get(d, 'custom_properties.document_type', 'No Type')}]${
              d.file_name
            }`,
            value: d.id,
          };
        }),
      );
    } catch (err: any) {
      setDocs([]);
    }
  };

  const bodyString = () => {
    let shipmentId = ftlShipment?.uid;
    ftlShipment?.customer_reference_number &&
      (shipmentId += ` - ${ftlShipment?.customer_reference_number}`);
    const actPickupDate = get(ftlShipment, 'actual_pickup_date');
    const actDeliveryDate = get(ftlShipment, 'actual_delivery_date');
    const username = get(ftlShipment, 'user.name', '');

    return actDeliveryDate && actPickupDate
      ? `
Dear ${username},

I hope this email finds you well.

We are pleased to inform you that the Proof of Delivery (POD) for your recent shipment ${shipmentId} is now available. Please find the POD document attached to this email for your records.

The shipment was picked up on ${actPickupDate} and delivered on ${actDeliveryDate}. If you have any questions or require further information regarding this delivery, feel free to reach out. We are happy to assist you with any concerns.

Thank you for your continued trust in our services. We look forward to working with you again.`
      : `
Dear ${username},

I hope this email finds you well.

We are pleased to inform you that the Proof of Delivery (POD) for your recent shipment ${shipmentId} is now available. Please find the POD document attached to this email for your records.

If you have any questions or require further information regarding this delivery, feel free to reach out. We are happy to assist you with any concerns.

Thank you for your continued trust in our services. We look forward to working with you again.`;
  };
  const initForm = () => {
    let sendToList: string[] = [];
    sendToList.push(get(ftlShipment, 'user.email', ''));

    sendToList = concat(
      sendToList,
      get(ftlShipment, 'user.additional_notification_emails', []),
    );

    const salesEmail = get(ftlShipment, 'sales.email', '');
    const salesSupportEmail = get(ftlShipment, 'sales_support.email', '');
    const useSE = get(ftlShipment, 'user.ftl_sales.email', '');
    const userSS = get(ftlShipment, 'user.ftl_sales_support.email', '');

    const opEmail = get(ftlShipment, 'operator.email', '');
    let subject = `POD Document for ${ftlShipment?.uid}`;
    ftlShipment?.customer_reference_number &&
      (subject += ` - ${ftlShipment?.customer_reference_number}`);

    emailForm.setFieldsValue({
      sendToList: sendToList?.length > 0 ? uniq(sendToList) : [''],
      ccList: uniq(
        [
          admin?.email,
          salesEmail,
          salesSupportEmail,
          useSE,
          userSS,
          opEmail,
          EMAIL_TL,
        ].filter((email) => !sendToList.includes(email)),
      ).filter((i) => i),
      subject: subject,
      body: bodyString(),
    });
  };

  useEffect(() => {
    open && initForm();
  }, [open, ftlShipment]);

  React.useEffect(() => {
    open && target && ftlShipment?.id && fetchDocuments();
  }, [open, ftlShipment, target]);

  return (
    <>
      <Modal
        title="FTL - Send POD to customer"
        width={'75%'}
        onCancel={handleOnClose}
        destroyOnClose={true}
        open={open}
        footer={
          <>
            <Space>
              <Button disabled={loading} onClick={handleOnClose}>
                Cancel
              </Button>
              <Button
                type="primary"
                disabled={loading}
                onClick={handleSendEmail}
              >
                Send
              </Button>
            </Space>
          </>
        }
      >
        <div style={{ maxHeight: '65vh', overflowY: 'scroll' }}>
          <Spin spinning={loading}>
            <Form form={emailForm} layout="vertical">
              <EmailForm
                form={emailForm}
                docs={docs}
                has={{
                  from: false,
                  attachment: true,
                  showSelectAttachments: true,
                }}
              />
            </Form>
          </Spin>
        </div>
      </Modal>
    </>
  );
};

export default SendPodEmailModal;
