import React from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { TItemProps } from './';

export const RenderIcon: React.FC<TItemProps> = ({ item }) => {
  if (item.done || item.action_at) {
    if (item.action === 'NO') {
      return (
        <span className="text-gray">
          <CloseCircleOutlined />
        </span>
      );
    }

    return (
      <span className="text-success">
        <CheckCircleOutlined />
      </span>
    );
  }

  return (
    <span className="text-danger">
      <CloseCircleOutlined />
    </span>
  );
};
