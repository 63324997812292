import styles from 'res/css/ui.scss';
import { Button, Col, Form, Space, Row, Select } from 'antd';
import React from 'react';
import { handleEnterKeyDown } from '@/utils/handleEnterKeyDown';
import { FormInstance } from 'antd/es/form';
import { AntDRangePicker } from '@/components/AntDRangePicker';
import moment from 'moment';

export type TFilter = {
  loading: boolean;
  filter: FormInstance;
  onSearch: () => void;
};

export const Filter: React.FC<TFilter> = ({ loading, filter, onSearch }) => {
  const handleSearch = () => {
    onSearch();
  };

  const handleReset = () => {
    filter.resetFields();
  };

  return (
    <div className={styles.filter}>
      <Form
        form={filter}
        layout="vertical"
        initialValues={{
          // start_ir_eta: moment().subtract(7, 'days').format('YYYY-MM-DD'),
          // end_ir_eta: moment().add(7, 'days').format('YYYY-MM-DD'),
          project_code: 0,
        }}
        onKeyDown={handleEnterKeyDown(() => handleSearch())}>
        <Row gutter={16}>
          <Col span={9}>
            <Form.Item name="start_pod_eta" hidden />
            <Form.Item name="end_pod_eta" hidden />
            <Form.Item name="start_ir_eta" hidden />
            <Form.Item name="end_ir_eta" hidden />
            <Form.Item label="POD ETA" shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => {
                const startDate = getFieldValue('start_pod_eta');
                const endDate = getFieldValue('end_pod_eta');
                return (
                  <AntDRangePicker
                    style={{ width: '100%' }}
                    value={
                      startDate && endDate
                        ? [moment(startDate), moment(endDate)]
                        : undefined
                    }
                    onChange={(value) =>
                      setFieldsValue({
                        start_pod_eta: value
                          ? value[0]?.format('YYYY-MM-DD')
                          : null,
                        end_pod_eta: value
                          ? value[1]?.format('YYYY-MM-DD')
                          : null,
                      })
                    }
                  />
                );
              }}
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item label="IR ETA" shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => {
                const startDate = getFieldValue('start_ir_eta');
                const endDate = getFieldValue('end_ir_eta');
                return (
                  <AntDRangePicker
                    style={{ width: '100%' }}
                    value={
                      startDate && endDate
                        ? [moment(startDate), moment(endDate)]
                        : undefined
                    }
                    onChange={(value) =>
                      setFieldsValue({
                        start_ir_eta: value
                          ? value[0]?.format('YYYY-MM-DD')
                          : null,
                        end_ir_eta: value
                          ? value[1]?.format('YYYY-MM-DD')
                          : null,
                      })
                    }
                  />
                );
              }}
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label="Project Code" name="project_code">
              <Select allowClear>
                {/* <Select.Option value={null}>All</Select.Option> */}
                <Select.Option value={1}>Yes</Select.Option>
                <Select.Option value={0}>No</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={3} style={{ marginTop: '30px' }}>
            <Space>
              <Button type="primary" disabled={loading} onClick={handleSearch}>
                Search
              </Button>
              <Button onClick={handleReset}>Clear All</Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
