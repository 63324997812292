import { Col, Form, Input, Row } from 'antd';
import React from 'react';
import { FormInstance } from 'antd/es/form';
import TextArea from 'antd/lib/input/TextArea';

export const DrawerForm: React.FC<{
  form: FormInstance;
}> = ({ form }) => {
  return (
    <div>
      <Form form={form}>
        <Row gutter={10}>
          <Col span={24}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input placeholder="Enter Name" />
            </Form.Item>
            <Form.Item
              label="Address"
              name="address"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <TextArea placeholder="Enter Address" />
            </Form.Item>
            <Form.Item
              label="Contact"
              name="contact"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input placeholder="Enter Contact Person" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input placeholder="Enter email" />
            </Form.Item>
            <Form.Item
              label="Phone"
              name="phone"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input placeholder="Enter phone" />
            </Form.Item>
            <Form.Item
              label="Return instruction"
              name="return_instruction"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <TextArea placeholder="Enter return instruction" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
