import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Space,
  Table,
  TablePaginationConfig,
  Tag,
  Typography,
} from 'antd';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { useForm } from 'antd/lib/form/Form';
import { TAuthLog, TCollection } from '@/types';
import usePagination from '@/components/usePagination';
import { IPLink } from '@/components/IPLInk';
import { get } from 'lodash';
import AutoResizeTable from '@/components/AutoResizeTable';
import OverflowText from '@/components/OverflowText';
import { UserSyncSelect } from '@/components/UserSyncSelect';

const Index: React.FC<Record<string, any>> = (props) => {
  const app = useApp();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<TCollection<TAuthLog>>();
  const [filter] = useForm();

  const pagination = usePagination(data);

  const fetchData = useCallback(async (pagination?: TablePaginationConfig) => {
    setLoading(true);

    try {
      const result = await app.service.get('analysis/authlogs', {
        params: {
          ...filter.getFieldsValue(),
          page: pagination?.current || 1,
          per_page: pagination?.pageSize || 20,
        },
      });

      setData(result);
    } catch (e: any) {
      message.error(e.error || 'System Error');
    }

    setLoading(false);
  }, []);

  const handleClearAll = async () => {
    filter.resetFields();
    await fetchData();
  };

  const initQuerySearch = useCallback(async () => {
    const { query } = props.location;
    const operator_id = get(query, 'operator_id', false);
    if (false !== operator_id) {
      filter.setFieldsValue({
        user: {
          id: Number(operator_id),
        },
        user_id: Number(operator_id),
      });
    }
  }, []);

  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: 'Operator',
        dataIndex: ['authlogable', 'name'],
        key: 'authlogable',
        width: 50,
      },
      {
        title: 'Type',
        dataIndex: 'type_name',
        key: 'type_name',
        width: 50,
        render: (text, record, index) => <Tag color="#108ee9">{text}</Tag>,
      },
      {
        title: 'IP',
        dataIndex: 'ip',
        key: 'ip',
        width: 200,
        render: (text, record) => <IPLink ip={record.ip} />,
      },
      {
        title: 'UA',
        dataIndex: 'user_agent',
        key: 'user_agent',
        width: 200,
        render: (text) => <OverflowText>{text}</OverflowText>,
      },
      {
        title: 'Time',
        dataIndex: 'updated_at',
        key: 'updated_at',
        width: 200,
      },
    ],
    [],
  );

  useEffect(() => {
    initQuerySearch();
    fetchData();
  }, []);

  return (
    <div className={styles.main}>
      <Header title="User Access Logs"></Header>

      <div className={styles.filter}>
        <Form
          layout="vertical"
          form={filter}
          onFinish={fetchData}
          style={{ width: '100%' }}>
          <Row gutter={8}>
            <Col flex="1 1 auto">
              <Form.Item name="user_id" label="User" shouldUpdate>
                <UserSyncSelect type="user" />
              </Form.Item>
            </Col>
            <Col flex="1 1 auto">
              <Form.Item name="admin_id" label="Admin">
                <UserSyncSelect type="admin" />
              </Form.Item>
            </Col>
            <Col flex={'none'}>
              <Form.Item label={' '}>
                <Space>
                  <Button type="primary" htmlType="submit">
                    Search
                  </Button>
                  <Button onClick={handleClearAll}>Clear All</Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <AutoResizeTable
        loading={loading}
        pagination={pagination}
        size="small"
        rowKey="id"
        columns={columns}
        onChange={fetchData}
        dataSource={data?.data || []}
        sticky
        scroll={{
          x: 'auto',
        }}
      />
    </div>
  );
};

export default Index;
