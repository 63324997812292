import React from 'react';
import { Form, FormInstance, Input, Button, DatePicker } from 'antd';
import moment from 'moment';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

interface Props {
  form: FormInstance;
}

export const HolidayForm: React.FC<Props> = ({ form }) => {
  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={{
        name: 'USA',
        year: moment().format('YYYY'),
      }}>
      <Form.Item name="id" noStyle>
        <Input hidden />
      </Form.Item>
      <Form.Item label="Name" name="name" rules={[{ required: false }]}>
        <Input />
      </Form.Item>
      <Form.Item name="year" hidden noStyle />
      <Form.Item label="Year" shouldUpdate>
        {({ getFieldValue, setFieldsValue }) => {
          const year = getFieldValue('year');
          return (
            <DatePicker
              style={{ width: '100%' }}
              picker="year"
              format="YYYY"
              value={year ? moment(year, 'YYYY') : moment()} // set value to current year when year is null
              onChange={(value) => {
                setFieldsValue({
                  year: value ? value.format('YYYY') : null,
                });
              }}
            />
          );
        }}
      </Form.Item>
      <Form.Item label="Dates">
        <Form.List name="dates">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => {
                return (
                  <Form.Item
                    {...field}
                    key={field.key}
                    rules={[
                      {
                        required: true,
                        message: 'Please input date',
                      },
                    ]}>
                    <DatePicker
                      style={{ width: fields.length > 1 ? '95%' : '100%' }}
                      format="MM-DD"
                      value={
                        form.getFieldValue('dates')[index]
                          ? moment(form.getFieldValue('dates')[index], 'MM-DD')
                          : null
                      }
                      onChange={(value) => {
                        const dates = form.getFieldValue('dates') || [];
                        dates[index] = value ? value.format('MM-DD') : null;
                        form.setFieldsValue({ dates });
                      }}
                    />
                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        style={{ marginLeft: '10px' }}
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                );
              })}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{
                    width: '100%',
                  }}
                  icon={<PlusOutlined />}>
                  Add
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
    </Form>
  );
};
