import React, { useEffect, useMemo } from 'react';
import { useApp } from '@/utils/useapp';
import { Popover, Spin, Button, PopoverProps, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { AmountChangeForm } from './AmountChangeForm';
import { TAttributeChangeTarget } from '@/Interfaces/AttributeChangeLog/AttributeChangeTarget';

type TAmountChangePopover = {
  title?: string;
  buttonText?: string;
  disabled?: boolean;
  onSave?: (values: { value: number; reason: string }) => void;
  onSaved?: () => void;
} & TAttributeChangeTarget &
  PopoverProps;

const AmountChangePopover: React.FC<TAmountChangePopover> = ({
  target,
  targetId,
  attribute,
  title = 'Amount Change',
  buttonText,
  disabled = false,
  onSave,
  onSaved,
  ...props
}) => {
  const app = useApp();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const [form] = useForm();

  const handleSave = async (values: any) => {
    setLoading(true);
    try {
      await app.service.post(`atttributeChangelogs/${target}/${targetId}`, {
        attribute,
        value: values[attribute],
      });
      onSaved?.();
    } catch (error) {
      message.error(error?.data?.message || error?.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const toSave = async (values: any) => {
    if (onSave) {
      await onSave({
        value: values[attribute],
        reason: values.reason,
      });
    } else {
      await handleSave(values);
    }

    form.resetFields();
    setOpen(false);
  };

  return (
    <Popover
      title={title}
      trigger={props.trigger ? props.trigger : 'click'}
      placement={props.placement ? props.placement : 'top'}
      onOpenChange={(open: boolean) => {
        setOpen(open);
      }}
      open={open}
      content={
        <div style={{ width: '14vw' }}>
          <Spin spinning={loading}>
            <AmountChangeForm
              attribute={attribute}
              form={form}
              toSave={toSave}
            />
          </Spin>
        </div>
      }
    >
      <Button type="default" size="small">
        {buttonText}
      </Button>
    </Popover>
  );
};

export default AmountChangePopover;
