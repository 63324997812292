import { Button } from 'antd';
import { useApp } from '@/utils/useapp';
import { upperFirst } from 'lodash';

import React from 'react';

import { TItemProps, TOnCheckProps } from '..';

type GeneralActionProps = TItemProps & TOnCheckProps;

export const GeneralAction: React.FC<GeneralActionProps> = ({
  item,
  onCheck,
}) => {
  const app = useApp();
  return (
    <Button
      size="small"
      onClick={() => onCheck({ task: item.task, action: item.action })}>
      {upperFirst(item.action)}
    </Button>
  );
};
