import { Button, Modal, Space, Table } from 'antd';
import React, { useState } from 'react';

const CurrencyView: React.FC<{ data: any[] }> = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        View USD
      </Button>
      <Modal open={isModalOpen} onCancel={handleCancel}>
        <Table
          size="small"
          pagination={false}
          columns={[
            {
              title: 'Name',
              dataIndex: 'name',
              key: 'name',
            },
            {
              title: 'Rate in USD',
              dataIndex: 'rate',
              key: 'rate',
              render: (text, row, index) => (+row.rate)?.toFixed(2) || '',
            },
          ]}
          // add total for the rate to the second column of the table aligning to the right
          footer={() => {
            return (
              <Space align="end">
                Total:{' '}
                {data.reduce((acc, curr) => acc + +curr.rate, 0).toFixed(2)}
              </Space>
            );
          }}
          dataSource={data}></Table>
      </Modal>
    </>
  );
};

export default CurrencyView;
