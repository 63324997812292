import request from '@/utils/request';
import { action, observable, runInAction } from 'mobx';
import _ from 'lodash';

interface cacheable {
  uri: string;
  options?: { [key: string]: any };
  getKey: (item: any) => string;
  unique?: (buffers: any[]) => any[];
}

export const CACHE_WEATHER = 'weather';
export const CACHE_CNTR_STATUS = 'containerStatus';
export const CACHE_CNTR_COUNT_BY_WAREHOUSE = 'containerCounts';
export const CACHE_CNTR_MEMO_UNREAD_COUNT = 'unreadByContainerId';

const CACHEABLE: { [key: string]: cacheable } = {
  [CACHE_WEATHER]: {
    uri: 'weatherAlerts',
    getKey: (item: any) => `${item.city_id}-${item.time}`,
    unique: (buffers: any[]) =>
      _.uniqBy(
        buffers.filter((item: any) => item.time),
        (el: any) => `${el.city_id}${el.time}`,
      ),
  },
  [CACHE_CNTR_STATUS]: {
    uri: 'containers/getStatusByNumbers',
    getKey: (item: any) => item.number,
    unique: (buffers: any[]) => _.uniq(buffers),
  },
  [CACHE_CNTR_COUNT_BY_WAREHOUSE]: {
    uri: 'warehouses/containerCounts',
    getKey: (item: any) => item.warehouse_id,
    unique: (buffers: any[]) => _.uniq(buffers),
  },
  [CACHE_CNTR_MEMO_UNREAD_COUNT]: {
    uri: 'notifications/unreadByContainerId',
    getKey: (item: any) => item.id,
    unique: (buffers: any[]) => _.uniq(buffers),
  },
};

type Key = keyof typeof CACHEABLE;

export class Cache {
  @observable data: { [key: Key]: any[] | any } = {};
  // @observable loadingMap: { [key: Key]: boolean } = {};

  @observable buffers: any = {};

  _debounceFetch = _.debounce(
    (url, key, ...args) =>
      request.post(url, ...args).then((resp) => {
        this.buffers[key] = [];
        if (!this.data[key]) {
          this.data[key] = {};
        }
        const _data = { ...this.data };
        for (const item of resp.data) {
          _data[key][CACHEABLE[key].getKey(item)] = item;
        }
        this.data = _data;
      }),
    500,
  );

  _debounceFetchWeather = _.debounce(
    (url, key, ...args) =>
      request.post(url, ...args).then((resp) => {
        this.buffers[key] = [];
        if (!this.data[key]) {
          this.data[key] = {};
        }
        const _data = { ...this.data };
        for (const item of resp.data) {
          _data[key][CACHEABLE[key].getKey(item)] = item;
        }
        this.data = _data;
      }),
    500,
  );

  @action debounceFetch(key: string, item: any) {
    if (!CACHEABLE[key]) {
      return;
    }
    if (this.buffers[key]) {
      this.buffers[key].push(item);
    } else {
      this.buffers[key] = [item];
    }
    // unique by combination of time and city_id
    const bufferedItems =
      CACHEABLE[key] && CACHEABLE[key].unique
        ? CACHEABLE[key].unique([...this.buffers[key]])
        : this.buffers[key];

    return this._debounceFetch(CACHEABLE[key].uri, key, {
      data: { params: bufferedItems },
    });
  }

  @action debounceFetchWeather(key: string, item: any) {
    if (!CACHEABLE[key]) {
      return;
    }
    if (this.buffers[key]) {
      this.buffers[key].push(item);
    } else {
      this.buffers[key] = [item];
    }
    // unique by combination of time and city_id
    const bufferedItems =
      CACHEABLE[key] && CACHEABLE[key].unique
        ? CACHEABLE[key].unique([...this.buffers[key]])
        : this.buffers[key];

    return this._debounceFetchWeather(CACHEABLE[key].uri, key, {
      data: { params: bufferedItems },
    });
  }

  // @action setLoading(key: Key) {
  //   this.loadingMap[key] = true;
  // }

  // @action setUnLoading(key: Key) {
  //   this.loadingMap[key] = false;
  // }

  @action get(key: Key) {
    return this.data[key];
  }

  @action getWeahter(city_id: number, time: string) {
    return this.data[CACHE_WEATHER]
      ? this.data[CACHE_WEATHER][`${city_id}-${time}`]
      : null;
  }

  @action getCntrStatus(numher: string) {
    return this.data[CACHE_CNTR_STATUS]
      ? this.data[CACHE_CNTR_STATUS][numher]
      : null;
  }

  @action getCntrCount(warehouse_id: number) {
    return this.data[CACHE_CNTR_COUNT_BY_WAREHOUSE]
      ? this.data[CACHE_CNTR_COUNT_BY_WAREHOUSE][warehouse_id]
      : null;
  }

  @action getUnreadCountByContainerId(id: number) {
    return this.data[CACHE_CNTR_MEMO_UNREAD_COUNT]
      ? this.data[CACHE_CNTR_MEMO_UNREAD_COUNT][id]
      : null;
  }

  @action markAsRead(id: number) {
    const _data = { ...this.data };
    if (_data[CACHE_CNTR_MEMO_UNREAD_COUNT][id]) {
      _data[CACHE_CNTR_MEMO_UNREAD_COUNT][id] = {};
    }
    this.data = _data;
  }
}

const cache = new Cache();

export default cache;
