import React from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import { Button, message, Card } from 'antd';
import { Header } from '@/components/CommonHeader';
import { CardForm } from './components/CardForm';
import { useForm } from 'antd/lib/form/Form';

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [oceanCarriesCodes, setOceanCarriersCodes] = React.useState();
  const [form] = useForm();

  const fetchData = React.useCallback(async () => {
    setLoading(true);

    try {
      const result = await app.service.get('tracking/smartTrackingSetting', {
        params: {},
      });
      await form.setFieldsValue(result.data);
    } catch (e: any) {
      message.error(e.error || 'System Error');
    }

    setLoading(false);
  }, []);

  const fetchOceanCarries = React.useCallback(async () => {
    try {
      const result = await app.service.get('oceanCarriers', {
        params: {
          as_options: true,
          with_trashed: true,
        },
      });
      let codes: any = [];
      if (result && result.data) {
        result.data.map((item: any) => {
          codes.push({
            label: item.code,
            value: item.code,
          });
        });
      }

      if (!codes) {
        codes = [];
      }
      await setOceanCarriersCodes(codes);
    } catch (e: any) {
      message.error(e.error || 'System Error');
    }
  }, []);

  const handleSave = async () => {
    const values = await form.validateFields();
    values.operator_ids = [];
    values.all_operator_ids = true;
    values.to_drayeasy = false;
    setLoading(true);

    try {
      await app.service.put('tracking/smartTrackingSetting', {
        data: values,
      });
      message.success('Saved');
      fetchData();
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
    fetchOceanCarries();
  }, []);

  return (
    <div className={styles.main}>
      <Header title="Smart Tracking Setting"></Header>
      <Card loading={loading} style={{ width: '98%', marginLeft: '20px' }}>
        <CardForm
          key="card_form"
          form={form}
          oceanCarriesCodes={oceanCarriesCodes}
          onSubmit={handleSave}
        />
        <div style={{ position: 'relative', top: '10px' }}>
          <Button type="primary" loading={loading} onClick={handleSave}>
            Save
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default Index;
