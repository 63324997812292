import styles from 'res/css/ui.scss';
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
  Space,
} from 'antd';
import React, { useCallback, useEffect } from 'react';
import { FormInstance } from 'antd/es/form';
import { TStatesCount } from '..';
import moment from 'moment';
import { WarehouseSelect } from '@/components/WarehouseSelect';
import { ConsigneeSyncSelect } from '@/components/ConsigneeSyncSelect';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { VendorSelect } from '@/components/VendorSelect';
import { TerminalSelect } from '@/components/TerminalSelect';
import { TYPES as TERMINAL_TYPES } from '@/pages/entity/terminals';
import { Checkbox } from 'antd';
import { BIG_FIVE } from '../data';
import { OCEAN_PORT, RAMP_PORT } from '@/pages/dispatchs/components/data';

// CN RAIL: CN Rail

// BNSF: BNSF Rail

// CPR: CP Rail

// UP RAIL: UP Rail

// NS RAIL (NORFOLK SOUTHERN RAIL): NS Rail

// CSX: CSX Rail

const RAIL_COMPANY = [
  {
    key: 'CN RAIL',
    value: 'CN Rail',
  },
  {
    key: 'BNSF',
    value: 'BNSF Rail',
  },
  {
    key: 'CPR',
    value: 'CP Rail',
  },
  {
    key: 'UP RAIL',
    value: 'UP Rail',
  },
  {
    key: 'NS RAIL',
    value: 'NS Rail',
  },
  {
    key: 'CSX',
    value: 'CSX Rail',
  },
  {
    key: 'CP Rail',
    value: 'CP Rail',
  },
  {
    key: 'KCS Rail',
    value: 'KCS Rail',
  },
];

export type TFilter = {
  loading: boolean;
  filter: FormInstance;
  statesCount: TStatesCount;
  onSearch: () => void;
  terminals?: any[];
  podTerminals?: any[];
  scacCodes?: any[];
  fetchRelatedTerminals?: (s: string, key: string) => void;
  fetchTerminals?: (key: string) => void;
  handleBulkUpdate: () => void;
};

export const Filter: React.FC<TFilter> = ({
  loading,
  filter,
  onSearch,
  terminals,
  podTerminals,
  scacCodes,
  fetchRelatedTerminals,
  fetchTerminals,
  handleBulkUpdate,
}) => {
  const [isShow, setIsShow] = React.useState(false);
  const [scacCodeMutilple, setScacCodeMutilple] = React.useState(false);
  const handleSearch = () => {
    onSearch();
  };

  const handleClearAll = () => {
    filter.resetFields();
    onSearch();
  };

  const terminalWatch = Form.useWatch('terminal', filter);
  const scacCodeWatch = Form.useWatch('scac_code', filter);

  const onRelatedTerminalsChange = useCallback(
    (id: number) => {
      const t = terminals?.find((t) => t.id == id);
      filter.setFieldsValue({
        terminal: t,
        terminal_id: t?.id,
        terminal_type: t?.type,
      });
      onSearch();
    },
    [terminalWatch, onSearch],
  );

  const onPodTerminalsChange = useCallback(
    (id: number) => {
      const t = terminals?.find((t) => t.id == id);
      filter.setFieldsValue({
        pod_terminal: t,
        pod_terminal_id: t?.id,
        pod_terminal_type: t?.type,
      });
      onSearch();
    },
    [terminalWatch, onSearch],
  );

  useEffect(() => {
    if (scacCodeMutilple) {
      if (fetchRelatedTerminals && filter.getFieldValue('scac_code')) {
        fetchRelatedTerminals(filter.getFieldValue('scac_code'), 'terminal_id');
        fetchRelatedTerminals(
          filter.getFieldValue('scac_code'),
          'pod_terminal_id',
        );
      }
    } else {
      if (fetchTerminals) {
        fetchTerminals('terminal_id');
        fetchTerminals('pod_terminal_id');
      }
    }
  }, [scacCodeMutilple]);

  const onRelatedScacCodeChange = useCallback(
    (code: string) => {
      filter.setFieldsValue({
        scac_code: code,
      });
      if (scacCodeMutilple && fetchRelatedTerminals) {
        fetchRelatedTerminals(code, 'terminal_id');
        fetchRelatedTerminals(code, 'pod_terminal_id');
      } else {
        if (fetchTerminals) {
          fetchTerminals('terminal_id');
          fetchTerminals('pod_terminal_id');
        }
      }
      onSearch();
    },
    [scacCodeWatch, onSearch, scacCodeMutilple],
  );

  return (
    <div className={styles.filter}>
      <div
        style={{
          width: '100%',
        }}
      >
        <Form form={filter} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
          <Form.Item hidden name="sort_by">
            <Input />
          </Form.Item>
          <Form.Item hidden name="sort_value">
            <Input />
          </Form.Item>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item name="search_id" label="Search ID#" className="mb0">
                <Input placeholder="Enter Container#/MBL#/D/O#/Order#" />
              </Form.Item>
            </Col>
            {/* <Col span={4}>
              <Form.Item label="Container#" className="mb0" name="number">
                <Input placeholder="Enter container#" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="MBL" className="mb0" name="mbl_number">
                <Input placeholder="Enter MBL number" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="D/O#" className="mb0" name="delivery_order_id">
                <Input placeholder="Enter D/O ID" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Order#" className="mb0" name="order_id">
                <Input placeholder="Enter order ID" />
              </Form.Item>
            </Col> */}
            {/* <Col span={4}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <Form.Item name="vendor" className="mb0" label="Vendor">
                    <VendorSelect
                      selected={getFieldValue('vendor')}
                      onSelect={(vendor) => {
                        filter.setFieldsValue({ vendor });
                      }}
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Operator"
                className="mb0"
                name="operator_id"
                shouldUpdate>
                <UserSyncSelect
                  style={{
                    width: '100%',
                  }}
                  type="admin"
                />
              </Form.Item>
            </Col> */}
            <Col span={4}>
              <Form.Item shouldUpdate noStyle>
                {() => (
                  <Form.Item
                    label={
                      <>
                        Related SSL{' '}
                        <Checkbox
                          className="ml-md"
                          checked={scacCodeMutilple}
                          onChange={() => {
                            setScacCodeMutilple(!scacCodeMutilple);
                          }}
                        >
                          <small>Multiple</small>{' '}
                        </Checkbox>
                      </>
                    }
                    name="scac_code"
                  >
                    <Select
                      onChange={onRelatedScacCodeChange}
                      mode={scacCodeMutilple ? 'multiple' : undefined}
                      allowClear
                    >
                      {scacCodes?.map((t) => (
                        <Select.Option
                          key={t}
                          value={t}
                          style={{ color: BIG_FIVE.includes(t) ? 'red' : '' }}
                        >
                          {t}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item shouldUpdate noStyle>
                {() => (
                  <Form.Item label="IR Terminals" name="terminal_id">
                    <Select onChange={onRelatedTerminalsChange} allowClear>
                      {terminals?.map((t) => (
                        <Select.Option key={t.id} value={t.id}>
                          {t.firms}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item shouldUpdate noStyle>
                {() => (
                  <Form.Item label="POD Terminals" name="pod_terminal_id">
                    <Select onChange={onPodTerminalsChange} allowClear>
                      {podTerminals?.map((t) => (
                        <Select.Option key={t.id} value={t.id}>
                          {t.firms}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item
                label="Destination type"
                className="mb0"
                name="destination_type"
              >
                <Select allowClear>
                  {/* <Select.Option value="">All</Select.Option> */}
                  <Select.Option value={OCEAN_PORT}>
                    {'Ocean Port'}
                  </Select.Option>
                  <Select.Option value={RAMP_PORT}>{'Ramp Port'}</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item
                label="Rail Company"
                className="mb0"
                name="rail_company"
              >
                <Select allowClear>
                  {RAIL_COMPANY.map((t) => (
                    <Select.Option key={t.key} value={t.value}>
                      {t.value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item className="mb0" label="&nbsp;">
                <Space>
                  <Button
                    onClick={() => {
                      setIsShow((isShow) => !isShow);
                    }}
                  >
                    {isShow ? 'Hide Filters' : 'Show All Filters'}
                  </Button>

                  <Button onClick={handleBulkUpdate} type="primary" ghost>
                    Bulk Update
                  </Button>

                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={loading}
                    onClick={handleSearch}
                  >
                    Search
                  </Button>

                  <Button onClick={handleClearAll}>Clear All</Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
          {isShow && (
            <Row>
              <Col span={4}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => (
                    <Form.Item
                      name="warehouse"
                      className="mb0"
                      label="Warehouse Name"
                    >
                      <WarehouseSelect
                        selected={getFieldValue('warehouse')}
                        onSelect={(warehouse) => {
                          filter.setFieldsValue({ warehouse });
                        }}
                      />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="consignee_id"
                  className="mb0"
                  label="Consignee"
                >
                  <ConsigneeSyncSelect />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="user_id" className="mb0" label="User">
                  <UserSyncSelect allowClear />
                </Form.Item>
              </Col>

              <Col span={4}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => (
                    <Form.Item name="terminal" className="mb0" label="Terminal">
                      <TerminalSelect
                        selected={getFieldValue('terminal')}
                        onSelect={(terminal) => {
                          filter.setFieldsValue({ terminal });
                        }}
                      />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  className="mb0"
                  name="terminal_type"
                  label="Terminal Type"
                >
                  <Select>
                    {TERMINAL_TYPES.map((t) => (
                      <Select.Option key={t} value={t}>
                        {t}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="port_of_discharge_eta_start_date" noStyle>
                  <></>
                </Form.Item>
                <Form.Item name="port_of_discharge_eta_end_date" noStyle>
                  <></>
                </Form.Item>
                <Form.Item className="mb0" label="POD ETA" shouldUpdate>
                  {({ getFieldValue, setFieldsValue }) => {
                    const startDate = getFieldValue(
                      'port_of_discharge_eta_start_date',
                    );
                    const endDate = getFieldValue(
                      'port_of_discharge_eta_end_date',
                    );
                    return (
                      <DatePicker.RangePicker
                        value={
                          startDate && endDate
                            ? [moment(startDate), moment(endDate)]
                            : undefined
                        }
                        onChange={(value) =>
                          setFieldsValue({
                            port_of_discharge_eta_start_date: value
                              ? value[0]?.format('YYYY-MM-DD')
                              : null,
                            port_of_discharge_eta_end_date: value
                              ? value[1]?.format('YYYY-MM-DD')
                              : null,
                          })
                        }
                      />
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="final_port_eta_start_date" noStyle>
                  <></>
                </Form.Item>
                <Form.Item name="final_port_eta_end_date" noStyle>
                  <></>
                </Form.Item>
                <Form.Item className="mb0" label="F.ETA" shouldUpdate>
                  {({ getFieldValue, setFieldsValue }) => {
                    const startDate = getFieldValue(
                      'final_port_eta_start_date',
                    );
                    const endDate = getFieldValue('final_port_eta_end_date');
                    return (
                      <DatePicker.RangePicker
                        value={
                          startDate && endDate
                            ? [moment(startDate), moment(endDate)]
                            : undefined
                        }
                        onChange={(value) =>
                          setFieldsValue({
                            final_port_eta_start_date: value
                              ? value[0]?.format('YYYY-MM-DD')
                              : null,
                            final_port_eta_end_date: value
                              ? value[1]?.format('YYYY-MM-DD')
                              : null,
                          })
                        }
                      />
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row gutter={16}></Row>
        </Form>
      </div>
    </div>
  );
};
