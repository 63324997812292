import { TPossibleCharge } from '@/types';
import { useApp } from '@/utils/useapp';
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Space,
  Tooltip,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useMemo, useState } from 'react';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import Table, { ColumnsType } from 'antd/lib/table';
import { RateScenarioStatementSelect } from '@/components/RateScenarioStatementSelect';
import { CNTR_SIZE_MAP } from '@/components/PossibleCharges';
import { MapboxModal } from './MapBoxModal';
import { TerminalsSelect } from '@/components/TerminalsSelect';

export type TCharge = {
  statement_id: number | null;
  qty: number | null;
  probability: number | null;
  possible_charges: TPossibleCharge[];
};

export type TRateScenario = {
  id: number;
  code: string;
  name: string;
  description: string;
};

const ChargeItems: React.FC<{
  items: TCharge[];
  options: TPossibleCharge[];
  handleDeleteCharge?: (T: number) => void;
  handleDeleteOnePossibleCharge?: (T: number, E: number) => void;
  handleAddOnePossibleCharge: (T: number) => void;
}> = ({
  items,
  options,
  handleDeleteCharge,
  handleDeleteOnePossibleCharge,
  handleAddOnePossibleCharge,
}) => {
  const defaultColumns: ColumnsType<TCharge> = [
    {
      title: 'Statement',
      dataIndex: 'statement',
      key: 'statement',
      width: 100,
      render: (text, record, index) => {
        return (
          <>
            <Form.Item name={['items', index, 'id']} hidden>
              <Input />
            </Form.Item>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue, setFieldValue }) => {
                return (
                  <Form.Item
                    name={['items', index, 'statement_id']}
                    className="mb0"
                    rules={[{ required: true, message: '' }]}>
                    <RateScenarioStatementSelect
                      selected={getFieldValue(['items', index, 'statement_id'])}
                      onSelect={(statement: any) => {
                        setFieldValue(
                          ['items', index, 'statement_id'],
                          statement,
                        );
                      }}
                      editable
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </>
        );
      },
    },
    {
      title: 'Probability',
      dataIndex: 'probability',
      key: 'probability',
      width: 100,
      render: (text, record, index) => {
        return (
          <Form.Item
            name={['items', index, 'probability']}
            className="mb0"
            rules={[
              {
                required: true,
                type: 'number',
                max: 100,
                min: 0,
                message: 'Max 100 Min 0',
              },
            ]}>
            <InputNumber max={100} />
          </Form.Item>
        );
      },
    },
    {
      title: 'Possible Charges',
      dataIndex: 'possible_charges',
      key: 'possible_charges',
      width: 300,
      render: (text, record, index) => {
        return (
          <Row gutter={1} align="middle">
            <Col span={2}>
              <PlusCircleOutlined
                onClick={() => handleAddOnePossibleCharge(index)}
              />
            </Col>
            <Col span={22}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue, setFieldValue }) => {
                  const possible_charges = getFieldValue([
                    'items',
                    index,
                    'possible_charges',
                  ]);
                  return (
                    <>
                      {(possible_charges || []).map(
                        (possible_charge: any, i: number) => (
                          <Form.Item
                            key={`possible_charge_id_${i}`}
                            className="mb-sm"
                            rules={[{ required: true, message: '' }]}>
                            <Select
                              allowClear
                              showSearch
                              value={possible_charge.id}
                              style={{ width: '90%' }}
                              filterOption={(input, option) => {
                                if (option?.children?.length) {
                                  return option?.children
                                    ?.map((e) => {
                                      return typeof e === 'string'
                                        ? e
                                        : e.props.children || '';
                                    })
                                    .join('')
                                    .toLowerCase()
                                    .includes(input.toLowerCase());
                                }
                                return false;
                              }}
                              onSelect={(v: number) => {
                                setFieldValue(
                                  ['items', index, 'possible_charges', i, 'id'],
                                  v,
                                );
                              }}
                              placeholder="Please select">
                              {options.map((option: any) => (
                                <Select.Option
                                  key={option.id}
                                  value={option.id}>
                                  <strong>{option.code}</strong> <br />
                                  <span className="text-gray">
                                    {option.name}{' '}
                                  </span>
                                  <br />
                                  <span className="text-gray">
                                    {option.cntr_size
                                      ?.map(
                                        (size: number) =>
                                          CNTR_SIZE_MAP[
                                            size as unknown as keyof typeof CNTR_SIZE_MAP
                                          ],
                                      )
                                      .join(',') || ''}
                                  </span>
                                </Select.Option>
                              ))}
                            </Select>
                            <MinusCircleOutlined
                              className="ml-sm"
                              onClick={() =>
                                handleDeleteOnePossibleCharge &&
                                handleDeleteOnePossibleCharge(index, i)
                              }
                            />
                          </Form.Item>
                        ),
                      )}
                    </>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Default Qty',
      dataIndex: 'qty',
      key: 'qty',
      width: 100,
      render: (text, record, index) => {
        return (
          <>
            <Form.Item hidden name={['items', index, 'possible_charges']}>
              <Input />
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldValue }) => {
                const possible_charges = getFieldValue([
                  'items',
                  index,
                  'possible_charges',
                ]);
                return (
                  <>
                    {possible_charges.map((possible_charge: any, i: number) => (
                      <Form.Item
                        key={`possible_charge_qty_${i}`}
                        className="mb-sm"
                        rules={[{ required: true, message: '' }]}>
                        <InputNumber
                          value={possible_charge.qty}
                          onChange={(v) => {
                            setFieldValue(
                              ['items', index, 'possible_charges', i, 'qty'],
                              v,
                            );
                          }}
                        />
                      </Form.Item>
                    ))}
                  </>
                );
              }}
            </Form.Item>
          </>
        );
      },
    },
  ];

  const columns: ColumnsType<TCharge> = useMemo(() => {
    const _columns = [...defaultColumns];
    if (handleDeleteCharge) {
      _columns.push({
        title: '',
        dataIndex: 'action',
        key: 'action',
        width: 50,
        render: (text, record, index) => {
          return (
            <MinusCircleOutlined onClick={() => handleDeleteCharge(index)} />
          );
        },
      });
    }
    return _columns;
  }, []);

  return (
    <Table
      pagination={false}
      rowKey="uid"
      columns={columns}
      dataSource={items}
    />
  );
};

const CopyModal: React.FC<{
  onSelect: any;
}> = ({ onSelect }) => {
  const app = useApp();
  const [show, setShow] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [search, setSearch] = useState<string>('');
  const fetchScenarios = async () => {
    try {
      const resp = await app.service.get('rateScenarios');
      setData(resp.data);
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
  };

  const showModal = () => {
    setShow(true);
  };

  const handleCancel = () => {
    setShow(false);
  };

  const handleSelect = (record: any) => {
    setShow(false);
    onSelect(record);
  };

  useEffect(() => {
    if (show) {
      fetchScenarios();
    }
  }, [show]);

  const columns: ColumnsType<any> = useMemo(() => {
    return [
      {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
        width: 150,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 150,
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        render: (text, record) => {
          return (
            <Tooltip placement="rightTop" title={record.description}>
              <div className="text-eclipsis-4 new-line">
                {record.description}
              </div>
            </Tooltip>
          );
        },
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        width: 100,
        render: (text, record) => {
          return <Button onClick={() => handleSelect(record)}>Copy</Button>;
        },
      },
    ];
  }, []);

  const display = useMemo(() => {
    return [...data].filter(
      (i) =>
        i.name.toLowerCase().includes(search.toLowerCase()) ||
        i.code.toLowerCase().includes(search.toLowerCase()),
    );
  }, [search, data]);

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Copy
      </Button>
      <Modal
        title="Copy Scenario"
        width={'80vw'}
        open={show}
        footer={false}
        onCancel={handleCancel}>
        <Input
          className="mb-sm"
          placeholder="Search By Name or Code"
          allowClear
          onChange={(e) => setSearch(e.target.value)}
        />
        <Table
          columns={columns}
          dataSource={display}
          scroll={{ x: '100%' }}
          pagination={{
            pageSize: 8,
          }}
        />
      </Modal>
    </>
  );
};

export const DrawerForm: React.FC<{
  id: number;
  intermodalRegionId: number;
  visible: boolean;
  onClose: () => void;
  onSave: (data: any, id?: number) => void;
}> = ({ id, intermodalRegionId, visible, onClose, onSave }) => {
  const [form] = Form.useForm();
  const app = useApp();
  const [possibleCharges, setPossibleCharges] = useState<TPossibleCharge[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchRateScenario = async (id: number) => {
    setLoading(true);
    try {
      const resp = await app.service.get(`rateScenarios/${id}`);
      const scenario = {
        ...resp.data,
        items: resp.data.rate_rate_scenario_items.map((item: any) => ({
          ...item,
          probability: +item.probability,
          possible_charges: item.possible_charges.map(
            (possible_charge: any) => ({
              id: possible_charge.id,
              qty: possible_charge.pivot.qty,
            }),
          ),
        })),
      };
      form.setFieldsValue({ ...scenario });
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id || id !== 0) {
      fetchRateScenario(id);
    }
  }, [id]);

  const fetchPossisbleCharges = async () => {
    if (!intermodalRegionId) {
      return;
    }
    setLoading(true);
    try {
      const result = await app.service.get(
        `intermodalRegions/${intermodalRegionId}`,
      );
      setPossibleCharges(result.data?.possible_charges || []);
    } catch (err: any) {
      message.error('Failed to fetch PossibleCharges');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPossisbleCharges();
  }, [intermodalRegionId]);

  const handleSave = async () => {
    const data = await form.validateFields();
    const values = { ...data, intermodal_region_id: intermodalRegionId };
    values.items = values.items?.map((item: any) => {
      const possible_charges: { [key: string]: any } = {};
      item.possible_charges.forEach((possible_charge: any) => {
        if (possible_charge.id) {
          possible_charges[possible_charge.id] = {
            qty: possible_charge.qty,
          };
        }
      }) || [];
      return {
        ...item,
        possible_charges,
      };
    });
    setLoading(true);

    try {
      const resp = data.id
        ? await app.service.put(`rateScenarios/${data.id}`, { data: values })
        : await app.service.post('rateScenarios', { data: values });
      form.resetFields();
      message.success('saved');
      onSave(resp.data);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddNewCharge = () => {
    const charges = [...(form.getFieldValue('items') || [])];
    charges.push({
      statement_id: null,
      probability: null,
      possible_charges: [],
    });
    form.setFieldValue('items', charges);
  };

  const handleDeleteCharge = (index: number) => {
    const charges = [...(form.getFieldValue('items') || [])];
    charges.splice(index, 1);
    form.setFieldValue('items', charges);
  };

  const handleDeleteOnePossibleCharge = (iidx: number, pidx: number) => {
    const charges = [...(form.getFieldValue('items') || [])];
    charges[iidx].possible_charges.splice(pidx, 1);
    form.setFieldValue('items', charges);
  };

  const handleAddOnePossibleCharge = (iidx: number) => {
    const charges = [...(form.getFieldValue('items') || [])];
    charges[iidx].possible_charges.push({
      id: '',
      qty: 1,
    });
    form.setFieldValue('items', charges);
  };

  const handleClose = () => {
    form.resetFields();
    if (onClose) {
      onClose();
    }
  };

  const removeUnknownPossibleCharge = (possible_charges: TPossibleCharge[]) => {
    const _possibleCharges: any[] = [];
    possible_charges.forEach((p: any) => {
      const possibleCharge = possibleCharges.find(
        (_p) =>
          p.code == _p.code &&
          p.cntr_size.sort().join() == _p.cntr_size.sort().join(),
      );
      if (possibleCharge) {
        _possibleCharges.push({ ...possibleCharge, qty: p.pivot?.qty || 0 });
      }
    });
    return _possibleCharges;
  };

  const handleCopy = (scenario: any) => {
    const items = scenario.rate_rate_scenario_items.map((item: TCharge) => {
      return {
        ...item,
        id: '',
        probability: item.probability ? +item.probability : '',
        possible_charges: removeUnknownPossibleCharge(item.possible_charges),
      };
    });
    scenario.items = items;

    const _scenario = { id, ...scenario };

    form.setFieldsValue({ ..._scenario, id: form.getFieldValue('id') });

    message.success('copied');
  };

  return (
    <>
      <Drawer
        title="Rate Scenario"
        placement={'right'}
        width={'80%'}
        onClose={handleClose}
        destroyOnClose={true}
        open={visible}
        extra={
          <Space>
            <CopyModal onSelect={handleCopy} />
            <Button onClick={handleClose}>Close</Button>
            <Button type="primary" onClick={handleSave}>
              Save
            </Button>
          </Space>
        }>
        <Form disabled={loading} layout="vertical" form={form}>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item label="Code" name="code" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Description"
                name="description"
                rules={[{ required: true }]}>
                <TextArea rows={6} />
              </Form.Item>
            </Col>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldValue }) => (
                <>
                  <Form.Item
                    className="mb0"
                    label="Terminals"
                    name={'terminal_ids'}>
                    <TerminalsSelect
                      defaultSelected={getFieldValue('terminal_ids')}
                      onChange={(selected) =>
                        setFieldValue('terminal_ids', selected)
                      }
                      // options={terminalsSelectOptions}
                      intermodal_region_id={intermodalRegionId}
                    />
                  </Form.Item>
                </>
              )}
            </Form.Item>
            <Col>
              <Form.Item
                label="Is All Zipcodes"
                name="is_all_zipcodes"
                initialValue={true}
                rules={[{ required: true }]}>
                <Select>
                  <Select.Option value={true}>Yes</Select.Option>
                  <Select.Option value={false}>No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue, setFieldValue }) => {
                  return !getFieldValue('is_all_zipcodes') ? (
                    <Form.Item
                      label={
                        <Space>
                          Specific Zipcodes{' '}
                          <MapboxModal
                            onSave={(zips) =>
                              setFieldValue('specific_zipcodes', zips)
                            }
                          />
                        </Space>
                      }
                      name="specific_zipcodes">
                      <TextArea rows={3} />
                    </Form.Item>
                  ) : (
                    ''
                  );
                }}
              </Form.Item>
            </Col>
            <Divider />
          </Row>
          <Row>
            <h3>
              Charges <PlusCircleOutlined onClick={handleAddNewCharge} />
            </h3>
            <Col span={24}>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  return (
                    <ChargeItems
                      items={getFieldValue('items') || []}
                      options={possibleCharges}
                      handleDeleteCharge={handleDeleteCharge}
                      handleDeleteOnePossibleCharge={
                        handleDeleteOnePossibleCharge
                      }
                      handleAddOnePossibleCharge={handleAddOnePossibleCharge}
                    />
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};
