import React from 'react';
import { Tooltip } from 'antd';
import type { TAdmin } from '@/types';
import { PermissionsRolesForm } from './PermissionsRolesForm';

interface PermissionsRolesButtonProps {
  admin: TAdmin;
  onSuccess?: () => void;
}

export const PermissionsRolesButton: React.FC<PermissionsRolesButtonProps> = ({
  admin,
  onSuccess,
}) => {
  const [visible, setVisible] = React.useState(false);

  return (
    <>
      <Tooltip title="Permissions & Roles">
        <a onClick={() => setVisible(true)}>P&R</a>
      </Tooltip>

      <PermissionsRolesForm
        admin={admin}
        visible={visible}
        onClose={() => setVisible(false)}
        onSuccess={onSuccess}
      />
    </>
  );
};
