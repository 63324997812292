import { CACHE_BANK_ACCOUNT_KEY } from '@/stores/cache';
import React from 'react';
import SyncCacheModelSelect, {
  SyncCacheModelSelectProps,
} from './SyncCacheModelSelect';

export const BankAccountSelect: React.FC<SyncCacheModelSelectProps> = ({
  ...props
}) => {
  return <SyncCacheModelSelect {...props} cacheKey={CACHE_BANK_ACCOUNT_KEY} />;
};
