const CUSTOMER = 'user_id';
const OPERATOR = 'operator_id';
const SALES = 'sales_id';
const SALES_SUPPORT = 'sales_support_id';
const BUSINESS_STATUS = 'business_status';
const VENDOR = 'vendor_id';
const CARRIER = 'carrier';
const BUSINESS_DEVELOPMENT = 'business_development_id';
const BOOKING_ORDER_VERIFIED = 'booking_order_verified';
const PRO_NUMBER_VERIFIED = 'pro_number_verified';
const OP_CLOSED_VERIFIED = 'op_closed_verified';
const AGENT = 'agent_id';
const TASK_DONE = 'task_done_id';
const TITLE = 'title';
const ORDER_STATUS = 'order_status';
const AR_STATUS = 'ar_status';
const AP_STATUS = 'ap_status';
const REBILL_STATUS = 'rebill_status';
const TAGS = 'tags';

export default {
  CUSTOMER,
  OPERATOR,
  AGENT,
  BUSINESS_DEVELOPMENT,
  SALES,
  SALES_SUPPORT,
  BUSINESS_STATUS,
  VENDOR,
  CARRIER,
  BOOKING_ORDER_VERIFIED,
  PRO_NUMBER_VERIFIED,
  OP_CLOSED_VERIFIED,
  TASK_DONE,
  TITLE,
  ORDER_STATUS,
  AR_STATUS,
  AP_STATUS,
  REBILL_STATUS,
  TAGS,
} as const;
