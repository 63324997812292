import { Card, Row, Col, message } from 'antd';
import React from 'react';
import { get } from 'lodash';
import { PossibleCharges } from './PossibleCharge';
import { ScenarioCard } from './ScenarioCard';
import { useApp } from '@/utils/useapp';

export const SnapshotCardContent: React.FC<{
  snapshotableId: number;
  snapshotableType: string;
  colSpan?: number;
}> = ({ snapshotableId, snapshotableType, colSpan = 8 }) => {
  const app = useApp();

  const [scenarios, setScenarios] = React.useState([]);
  const [possibleCharges, setPossibleCharges] = React.useState([]);

  const fetchData = async () => {
    let resp;
    try {
      resp = await app.service.get('orders/formatSnapshot', {
        params: {
          snapshotable_id: snapshotableId,
          snapshotable_type: snapshotableType,
        },
      });
    } catch (err: any) {
      message.error(err?.data?.message ?? err?.data?.error);
    }

    setScenarios(get(resp, 'data.scenarios', []));
    setPossibleCharges(get(resp, 'data.possible_charges', []));
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Row gutter={16}>
        <Col span={colSpan}>
          {possibleCharges.length > 0 && (
            <Card title="Possible Charges" className="price-rate-card">
              <PossibleCharges
                possibleCharges={possibleCharges}></PossibleCharges>
            </Card>
          )}
        </Col>
        {scenarios?.map((scenario: any, i: number) => (
          <Col span={colSpan} key={i}>
            <ScenarioCard scenario={scenario} />
          </Col>
        ))}
      </Row>
    </>
  );
};
