export const BELONG_MODEL_WAREHOUSE = 'warehouse';
export const BELONG_MODEL_REQUESTED_RATE = 'requestedRate';

export const VENDOR_NAME = '{{Vendor.name}}';
export const VENDOR_FIRST_CONTACT_NAME = '{{Vendor.FirstContact.Name}}';
export const REQUEST_IR = '{{Request.IR}}';
export const REQUEST_WAREHOUSE_CITY = '{{Request.WarehouseCity}}';
export const REQUEST_WAREHOUSE_ZIPCODE = '{{Request.warehousezipcode}}';
export const REQUEST_MEMO = '{{Request.memo}}';
export const MY_SIGNATURE = '{{Admin.Signature}}';

export const PRESET_DATA_MAP = {
  [BELONG_MODEL_REQUESTED_RATE]: {
    [VENDOR_NAME]: 'Vendor Name',
    [VENDOR_FIRST_CONTACT_NAME]: 'Vendor First Contact Name',
    [REQUEST_IR]: 'Request IR',
    [REQUEST_WAREHOUSE_CITY]: 'Request Warehouse City',
    [REQUEST_WAREHOUSE_ZIPCODE]: 'Request Warehouse Zipcode',
    [REQUEST_MEMO]: 'Request Memo',
    [MY_SIGNATURE]: 'My Signature',
  },
};

export const BELONGABLE_TYPE_MAP = {
  'App\\Models\\RequestedRate': BELONG_MODEL_REQUESTED_RATE,
  'App\\Models\\Warehouse': BELONG_MODEL_WAREHOUSE,
};
