import styles from 'res/css/ui.scss';
import { Button, Col, Form, Input, Row, Select, Space, Switch } from 'antd';
import { IntermodalRegionSelect } from '@/components/IntermodalRegionSelect';
import { CitySelect } from '@/components/CitySelect';
import { TYPES, FACILITY_TYPES } from './DawerForm';
import React from 'react';
import { handleEnterKeyDown } from '@/utils/handleEnterKeyDown';

export type TFilter = {
  loading: boolean;
  filter: any;
  onSearch: () => void;
  onChange: (values: any) => void;
};

export const Filter: React.FC<TFilter> = ({
  loading,
  filter,
  onSearch,
  onChange,
}) => {
  const handleSearch = () => {
    onSearch();
  };

  const handleFilterChange = (key: string, value: any) => {
    onChange({ ...filter, [key]: value });
  };

  const handleReset = () => {
    onChange({});
  };

  return (
    <div className={styles.filter}>
      <Form onKeyDown={handleEnterKeyDown(() => handleSearch())}>
        <Row gutter={8}>
          <Col span={3}>
            <Form.Item
              className="mb0"
              labelCol={{ span: 24 }}
              label="Firm Code"
              wrapperCol={{ span: 24 }}>
              <Input
                value={filter.firm || ''}
                onChange={(e) => handleFilterChange('firm', e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              className="mb0"
              labelCol={{ span: 24 }}
              label="Name"
              wrapperCol={{ span: 24 }}>
              <Input
                value={filter.name || ''}
                onChange={(e) => handleFilterChange('name', e.target.value)}
              />
            </Form.Item>
          </Col>

          <Col span={3}>
            <Form.Item
              className="mb0"
              labelCol={{ span: 24 }}
              label="Intermodal Region"
              wrapperCol={{ span: 24 }}>
              <IntermodalRegionSelect
                onSelect={(id) => handleFilterChange('intermodal_region', id)}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              className="mb0"
              labelCol={{ span: 24 }}
              label="City"
              wrapperCol={{ span: 24 }}>
              <CitySelect
                selected={filter.city}
                onSelect={(city) => handleFilterChange('city', city)}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              className="mb0"
              labelCol={{ span: 24 }}
              label="Type"
              wrapperCol={{ span: 24 }}>
              <Select
                onChange={(value) => handleFilterChange('type', value)}
                allowClear>
                {TYPES.map((type) => (
                  <Select.Option key={type} value={type}>
                    {type}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item
              className="mb0"
              labelCol={{ span: 24 }}
              label="Active"
              wrapperCol={{ span: 24 }}>
              <Select
                onChange={(value) => handleFilterChange('active', value)}
                allowClear>
                <Select.Option value="true">Yes</Select.Option>
                <Select.Option value="false">No</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              className="mb0"
              labelCol={{ span: 24 }}
              label="Facility Type"
              wrapperCol={{ span: 24 }}>
              <Select
                onChange={(value) => handleFilterChange('facility_type', value)}
                allowClear>
                {FACILITY_TYPES.map((type) => (
                  <Select.Option key={type} value={type}>
                    {type}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              className="mb0"
              labelCol={{ span: 24 }}
              label="&nbsp;"
              wrapperCol={{ span: 24 }}>
              <Space>
                <Button
                  type="primary"
                  disabled={loading}
                  onClick={handleSearch}>
                  Search
                </Button>
                <Button onClick={handleReset}>Clear All</Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
