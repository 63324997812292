import React, { useState } from 'react';
import { TContainerProps, TOnDoneProps } from '..';
import { EditOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  Row,
  message,
  Space,
  Popover,
  Divider,
} from 'antd';
import { useApp } from '@/utils/useapp';

type TEditPopoverProps = {
  name: string;
  value: any;
  placeholder: string;
};

export const EditAction: React.FC<
TEditPopoverProps & TContainerProps & TOnDoneProps
> = ({ name, value, placeholder, container, onDone }) => {
  const app = useApp();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    setLoading(true);
    const values = await form.validateFields();
    try {
      await app.service.patch(`containers/${container.id}`, { data: values });

      message.success('Saved');
      onDone && onDone();
    } catch (err: any) {
      err.data && message.error(err.data.message || err.data.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Popover
        trigger="click"
        placement="right"
        content={
          <>
            <Form
              layout="vertical"
              form={form}
              initialValues={{ [name]: value }}>
              <Form.Item name={name}>
                <Input placeholder={placeholder} allowClear />
              </Form.Item>
            </Form>
            <Divider />
            <Row>
              <Space>
                <Button type="primary" disabled={loading} onClick={handleSave}>
                  Save
                </Button>
              </Space>
            </Row>
          </>
        }>
        <Button type="link">
          <EditOutlined />
        </Button>
      </Popover>
    </>
  );
};
