import { CACHE_RATE_ENGINE_CONFIG_KEY } from '@/stores/cache';
import React from 'react';
import SyncCacheModelSelect, {
  SyncCacheModelSelectProps,
} from './SyncCacheModelSelect';

export const RateEngineConfigSelect: React.FC<SyncCacheModelSelectProps> = ({
  ...props
}) => {
  return (
    <SyncCacheModelSelect
      {...props}
      filterOption={(input, option: any) => {
        const formatText = (text: string) =>
          text.toUpperCase().replace(/[.\s]/gi, '');
        return formatText(option.label).indexOf(formatText(input)) !== -1;
      }}
      cacheKey={CACHE_RATE_ENGINE_CONFIG_KEY}
    />
  );
};
