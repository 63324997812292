import { useState, useEffect } from 'react';
import { Row, Col, message } from 'antd';
import type { UploadFile } from 'antd/es/upload/interface';
import DocumentUpload from './components/DocumentUpload';
import FilePreview from './components/FilePreview';
import RecognitionResults from './components/recognition-results';
import { useRecognition } from '@/pages/accounting/bill-ai-import/hooks/useRecognition';
import { FileStatus, InvoiceType, RecognitionResult } from './interface/types';
import './styles.css';
import { useDocumentClassify } from './hooks/useDocumentClassify';
import { useShipmentData } from './hooks/useShipmentData';
import { TLType } from '@/components/constants';

interface FileInfo {
  url: string;
  type: string;
  content?: string;
}

const Index = () => {
  const [, setFileList] = useState<UploadFile[]>([]);
  const [selectedFileId, setSelectedFileId] = useState<string | null>(null);
  const [fileStatuses, setFileStatuses] = useState<FileStatus[]>([]);
  const [previewFiles, setPreviewFiles] = useState<Record<string, FileInfo>>(
    {},
  );
  const [processingCount, setProcessingCount] = useState(0);
  const MAX_CONCURRENT_PROCESSING = 3; // 最大并发处理数

  const { classifyDocument } = useDocumentClassify();
  const {
    recognitionResults,
    startRecognition,
    updateRecognitionResult,
    saveRecognitionResult,
  } = useRecognition();

  const selectedFile: FileStatus | null = selectedFileId
    ? fileStatuses.find((f) => f.fileId === selectedFileId) || null
    : null;

  const referenceNumber =
    selectedFileId && recognitionResults[selectedFileId]
      ? selectedFile?.invoiceType === TLType.LTL.toUpperCase() ||
        selectedFile?.invoiceType === TLType.FTL.toUpperCase()
        ? recognitionResults[selectedFileId].reference_number
        : recognitionResults[selectedFileId].container_number
      : undefined;

  const shipmentData = useShipmentData(
    selectedFile?.invoiceType,
    referenceNumber,
  );

  const handleUpload = async (file: File) => {
    // 如果当前正在处理的文件数量达到最大值,则将文件添加到队列但暂不处理
    const shouldProcess = processingCount < MAX_CONCURRENT_PROCESSING;

    const fileId = Date.now().toString();
    const previewUrl = URL.createObjectURL(file);

    // 读取文件内容
    const fileContent = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(file);
    });

    setPreviewFiles((prev) => ({
      ...prev,
      [fileId]: {
        url: previewUrl,
        type: file.type,
        content: fileContent as string,
      },
    }));

    const newFileStatus: FileStatus = {
      fileId,
      fileName: file.name,
      uploadTime: new Date().toLocaleString(),
      status: shouldProcess ? 'processing' : 'uploaded',
    };

    setFileStatuses((prev) => [...prev, newFileStatus]);
    setSelectedFileId(fileId);

    if (shouldProcess) {
      await processFile(file, fileId);
    }

    return false;
  };

  const processFile = async (file: File, fileId: string) => {
    setProcessingCount((count) => count + 1);

    try {
      const {
        file_type: documentType,
        invoice_type: invoiceType,
        original_text: originalText,
      } = await classifyDocument(file);

      setFileStatuses((prev) =>
        prev.map((fs) =>
          fs.fileId === fileId
            ? {
                ...fs,
                documentType,
                invoiceType,
                status:
                  documentType === 'invoice' && !invoiceType
                    ? 'uploaded'
                    : 'processing',
              }
            : fs,
        ),
      );

      if (documentType === 'invoice' && invoiceType) {
        const result = await startRecognition(
          file,
          fileId,
          invoiceType,
          originalText,
        );
        setFileStatuses((prev) =>
          prev.map((fs) =>
            fs.fileId === fileId
              ? {
                  ...fs,
                  status: 'completed',
                  result: result,
                }
              : fs,
          ),
        );
      }
    } catch (error) {
      setFileStatuses((prev) =>
        prev.map((fs) =>
          fs.fileId === fileId
            ? {
                ...fs,
                status: 'error',
                errorMessage: (error as Error)?.message ?? 'Unknown error',
              }
            : fs,
        ),
      );
    } finally {
      setProcessingCount((count) => count - 1);

      // 检查是否有等待处理的文件
      const waitingFile = fileStatuses.find(
        (fs) => fs.status === 'uploaded' && !fs.documentType,
      );
      if (waitingFile) {
        const file = previewFiles[waitingFile.fileId];
        if (file?.content) {
          const blobData = file.content.split(',')[1];
          const byteCharacters = atob(blobData);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: file.type });
          const newFile = new File([blob], waitingFile.fileName, {
            type: file.type,
          });
          processFile(newFile, waitingFile.fileId);
        }
      }
    }
  };

  const handleFileDelete = (fileId: string) => {
    setPreviewFiles((prev) => {
      const newFiles = { ...prev };
      URL.revokeObjectURL(newFiles[fileId].url);
      delete newFiles[fileId];
      return newFiles;
    });

    setFileStatuses((prev) => prev.filter((fs) => fs.fileId !== fileId));

    if (selectedFileId === fileId) {
      const remainingFiles = fileStatuses.filter((fs) => fs.fileId !== fileId);
      setSelectedFileId(
        remainingFiles.length > 0 ? remainingFiles[0].fileId : null,
      );
    }

    setFileList((prev) => prev.filter((file) => file.uid !== fileId));
  };

  const handleResultChange = (
    fileId: string,
    key: string,
    value: string | any[] | null,
  ) => {
    updateRecognitionResult(fileId, key, value);
  };

  const updateFileStatus = (fileId: string) => {
    setFileStatuses((prev) =>
      prev.map((fs) =>
        fs.fileId === fileId
          ? {
              ...fs,
              status: 'completed',
              billCreated: true,
            }
          : fs,
      ),
    );
  };

  const handleInvoiceTypeChange = async (fileId: string, type: InvoiceType) => {
    setFileStatuses((prev) =>
      prev.map((fs) =>
        fs.fileId === fileId
          ? {
              ...fs,
              invoiceType: type,
              status: 'processing',
            }
          : fs,
      ),
    );

    if (fileId === selectedFileId) {
      const file = previewFiles[fileId];
      if (file?.content) {
        try {
          const blobData = file.content.split(',')[1];
          const byteCharacters = atob(blobData);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: file.type });
          const newFile = new File(
            [blob],
            fileStatuses.find((f) => f.fileId === fileId)?.fileName || 'file',
            { type: file.type },
          );

          const result = await startRecognition(newFile, fileId, type);

          setFileStatuses((prev) =>
            prev.map((fs) =>
              fs.fileId === fileId
                ? {
                    ...fs,
                    status: 'completed',
                    result: result,
                  }
                : fs,
            ),
          );
        } catch (error) {
          setFileStatuses((prev) =>
            prev.map((fs) =>
              fs.fileId === fileId
                ? {
                    ...fs,
                    status: 'error',
                    errorMessage:
                      (error as Error)?.message ?? 'Recognition failed',
                  }
                : fs,
            ),
          );
        }
      }
    }
  };

  const handleSaveResult = async (result: RecognitionResult) => {
    if (selectedFileId) {
      updateFileStatus(selectedFileId);

      try {
        saveRecognitionResult(result);
      } catch (error) {
        message.error('Failed to save recognition result');
      }
    }
  };

  useEffect(() => {
    return () => {
      Object.values(previewFiles).forEach((file) => {
        URL.revokeObjectURL(file.url);
      });
    };
  }, []);

  return (
    <div className="bill-import-container">
      <Row gutter={12}>
        <Col span={5}>
          <DocumentUpload
            fileStatuses={fileStatuses}
            selectedFileId={selectedFileId}
            onUpload={handleUpload}
            onFileSelect={setSelectedFileId}
            onFileDelete={handleFileDelete}
            onInvoiceTypeChange={handleInvoiceTypeChange}
          />
        </Col>
        <Col span={11}>
          <FilePreview
            file={
              selectedFileId
                ? fileStatuses.find((f) => f.fileId === selectedFileId) || null
                : null
            }
            previewUrl={
              selectedFileId ? previewFiles[selectedFileId]?.url : undefined
            }
          />
        </Col>
        <Col span={8}>
          <RecognitionResults
            result={selectedFileId ? recognitionResults[selectedFileId] : null}
            onResultChange={(key, value) =>
              selectedFileId && handleResultChange(selectedFileId, key, value)
            }
            file={selectedFile}
            shipmentData={shipmentData}
            fileContent={
              selectedFileId ? previewFiles[selectedFileId]?.content : undefined
            }
            onSaveResult={handleSaveResult}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Index;
