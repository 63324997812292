import { Button, Select, Input } from 'antd';
import React, { useEffect } from 'react';
import { ModelSelect } from '@/components/ModelSelect';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { VendorDivisionDrawer } from '@/pages/entity/vendorDivisions/components/VendorDivisionDrawer';
import { CACHE_VENDOR_DIVISION_KEY } from '@/stores/cache';
import { useApp } from '@/utils/useapp';

import SyncCacheModelSelect, {
  SyncCacheModelSelectProps,
} from './SyncCacheModelSelect';

type IVendorDivisionSyncSelect = {
  selected: any;
  editable?: boolean;
  onSelect: (id: string) => void;
};

export const VendorDivisionSyncSelect: React.FC<
IVendorDivisionSyncSelect & SyncCacheModelSelectProps
> = ({ selected, editable = true, onSelect, ...props }) => {
  const app = useApp();

  const [id, setId] = React.useState(0);
  const [visible, setVisible] = React.useState(false);
  const handleSaved = (data: any) => {
    onSelect(data);
    handleClosed();
    app.store.cache.fetch(CACHE_VENDOR_DIVISION_KEY, true);
  };

  const handleClosed = () => {
    setVisible(false);
    setId(0);
  };

  return (
    <>
      <Input.Group compact>
        {editable && (
          <>
            <Button
              type="ghost"
              onClick={() => {
                setId(selected?.id || 0);
                setVisible(true);
              }}
              className="ant-btn-icon-only select-modal-icon">
              <span className="icon-gray">
                {' '}
                <EditOutlined />
              </span>
            </Button>
          </>
        )}

        <SyncCacheModelSelect
          {...props}
          placeholder="Search Division"
          onSelect={onSelect}
          value={selected?.id || 0}
          className="select-minus-edit-w"
          cacheKey={CACHE_VENDOR_DIVISION_KEY}
        />
      </Input.Group>
      {visible && (
        <VendorDivisionDrawer
          visible={visible}
          id={id}
          onClose={handleClosed}
          onSaved={handleSaved}
        />
      )}
    </>
  );
};
