import { CSSProperties, useCallback, useState, memo } from 'react';
import { Button, Tooltip, Typography } from 'antd';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { SyncCacheModelSelectProps } from '@/components/SyncCacheModelSelect';
import { get } from 'lodash';
import { EditOutlined } from '@ant-design/icons';

const selectStyle: CSSProperties = {
  width: '100%',
};

const EditableUserSelect = memo(
  ({
    data,
    record,
    path,
    onSelect,
    type = 'admin',
  }: {
    data: any[];
    record: any;
    path: string[];
    onSelect: SyncCacheModelSelectProps['onSelect'];
    type?: 'admin' | 'user';
  }) => {
    const [isEditing, setIsEditing] = useState(false);
    const handleEdit = () => {
      setIsEditing(true);
    };

    const value = get(record, path, undefined);

    const user = data?.find((user) => user.id === value);
    const name =
      user?.display_name ||
      (user?.name ? `${user.company_code}(${user.name})` : '');

    const handleSelect: SyncCacheModelSelectProps['onSelect'] = useCallback(
      (model: any) => {
        onSelect?.(model);
        setIsEditing(false);
      },
      [onSelect],
    );

    const handleBlur = useCallback(() => {
      setIsEditing(false);
    }, []);

    if (!isEditing) {
      if (!name) {
        return (
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={handleEdit}
          ></Button>
        );
      }

      return (
        <Tooltip placement="topLeft" title={name}>
          <Typography.Link underline onClick={handleEdit}>
            {name}
          </Typography.Link>
        </Tooltip>
      );
    }

    return (
      <UserSyncSelect
        style={selectStyle}
        type={type}
        open
        autoFocus
        value={value}
        allowClear
        onSelect={handleSelect}
        onBlur={handleBlur}
      />
    );
  },
);

EditableUserSelect.displayName = 'EditableUserSelect';

export default EditableUserSelect;
