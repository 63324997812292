const OP_ASSIST = 1;
const OP = 2;
const CPS = 3;
const SALES_SUPPORT = 4;
const WHOSE_TASK_MAP = {
  [OP_ASSIST]: 'OP Assist',
  [OP]: 'OP',
  [CPS]: 'CPS',
  [SALES_SUPPORT]: 'Sales Support',
};

const WHOSE_TASK_COLOR_MAP = {
  [OP_ASSIST]: 'volcano',
  [CPS]: 'cyan',
  [OP]: 'blue',
  [SALES_SUPPORT]: 'purple',
};

const getOptions = () => {
  const setOption = (label, value) => ({ label, value });

  return Object.keys(WHOSE_TASK_MAP).map((key) =>
    setOption(
      WHOSE_TASK_MAP[(key as unknown) as keyof typeof WHOSE_TASK_MAP],
      +key,
    ),
  );
};

export default {
  OP_ASSIST,
  OP,
  CPS,
  SALES_SUPPORT,
  WHOSE_TASK_MAP,
  WHOSE_TASK_COLOR_MAP,
  getOptions,
} as const;
