import { Space, DatePicker } from 'antd';
import { useApp } from '@/utils/useapp';
import { isArray, has, get, isObject } from 'lodash';

import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { TLtlShipmentAction } from '../Interfaces/ActionInterface';
import AvailableTime from '@/components/AvailableTime';
import { AvaliableTimeValueInterface } from '@/components/AvailableTime/AvaliableTimeValueInterface';
import useAction from './useAction';

const InputAvailableTime: React.FC<
  TLtlShipmentAction & {
    action: string;
    name: string;
    availableTimeName: string;
    disabled: boolean;
  }
> = ({
  action,
  target,
  targetModel,
  task,
  name,
  availableTimeName,
  disabled = false,
  onSaveOperation,
  onDone,
}) => {
  const [loading, setLoading] = useState(false);

  const dateTimeValue = useMemo(() => get(targetModel, name), [
    targetModel,
    name,
  ]);

  const availableTimeValue: AvaliableTimeValueInterface = useMemo(
    () => get(targetModel, availableTimeName),
    [targetModel, availableTimeName],
  );

  const toSave = async (name: string, value: any) => {
    await onSaveOperation(
      task,
      action,
      name,
      value,
      (loading: boolean) => setLoading(loading),
      onDone,
    );
  };
  return (
    <>
      <Space>
        <DatePicker
          disabled={disabled || loading}
          style={{ width: '100%' }}
          size="small"
          value={dateTimeValue ? moment(dateTimeValue) : null}
          onChange={(date, dateString) => {
            toSave(name, dateString || null);
          }}
        />

        <AvailableTime
          name={availableTimeName}
          value={availableTimeValue}
          toSave={(_value: AvaliableTimeValueInterface) => {
            toSave(availableTimeName, _value);
          }}
          disabled={disabled || loading}
          isShowDetail={true}
        />
      </Space>
    </>
  );
};

export default InputAvailableTime;
