import { Select } from 'antd';
import React, { useMemo } from 'react';
interface ISelectContainers {
  containers: any;
  onChange: (values: any) => void;
}

export const SelectContainers: React.FC<ISelectContainers> = ({
  containers,
  onChange,
  ...props
}) => {
  const options = useMemo(() => {
    const _containers = Object.assign([], containers);
    if (_containers.length > 0) {
      _containers.unshift({
        id: 'all',
        number: 'Select All',
      });
    }

    return _containers;
  }, [containers]);

  const handleOnChangeContainers = (values: any) => {
    if (values.includes('all')) {
      onChange(containers.map((c: any) => c.id));
    } else {
      onChange(values);
    }
  };

  return (
    <Select
      {...props}
      placeholder="Select Containers"
      mode="multiple"
      onChange={handleOnChangeContainers}
      showSearch
      filterOption={(input: any, option: any) =>
        (option?.children ?? '').toLowerCase()?.includes(input.toLowerCase())
      }
      maxTagCount={'responsive'}>
      {options?.map((container: any, index: any) => (
        <Select.Option key={+index} value={container.id}>
          {container.number}
        </Select.Option>
      ))}
    </Select>
  );
};
