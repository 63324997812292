import { Button, Select, Input } from 'antd';
import React, { useEffect } from 'react';
import { ModelSelect } from '@/components/ModelSelect';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { CACHE_VENDOR_CREDIT_KEY } from '@/stores/cache';
import { VendorCreditsDrawer } from '@/pages/entity/vendorCredits/components/VendorCreditsDrawer';
import { useApp } from '@/utils/useapp';
import { get } from 'lodash';
import SyncCacheModelSelect, {
  SyncCacheModelSelectProps,
} from './SyncCacheModelSelect';
import { SelectValue } from 'antd/lib/select';

type IVendorCreditSyncSelect = {
  // selected: any;
  vendorCreditId: any;
  onSelect: (model: any) => any;
};

export const VendorCreditSyncSelect: React.FC<
IVendorCreditSyncSelect & SyncCacheModelSelectProps
> = ({
  // selected,
  vendorCreditId,
  onSelect,
  ...props
}) => {
  const app = useApp();

  const [id, setId] = React.useState(0);
  const [visible, setVisible] = React.useState(false);
  const handleSaved = (data: any) => {
    onSelect(data);
    handleClosed();
    app.store.cache.fetch(CACHE_VENDOR_CREDIT_KEY, true);
  };

  const handleClosed = () => {
    setVisible(false);
    setId(0);
  };

  return (
    <>
      <Input.Group compact>
        <Button
          type="ghost"
          onClick={() => {
            setId(vendorCreditId || 0);
            setVisible(true);
          }}
          className="ant-btn-icon-only select-modal-icon">
          <span className="icon-gray">
            {' '}
            {vendorCreditId ? <EditOutlined /> : <PlusOutlined />}
          </span>
        </Button>
        <SyncCacheModelSelect
          {...props}
          placeholder="Search Credit"
          onSelect={onSelect}
          value={vendorCreditId || 0}
          className="select-minus-edit-w"
          cacheKey={CACHE_VENDOR_CREDIT_KEY}
          filterOption={(input, option: any) => {
            const optionLabel = get(option, 'label.props.children')
              ?.join('')
              ?.toLowerCase();
            return optionLabel.includes(input.toLowerCase());
          }}
          renderOption={(credit: any) => {
            return (
              <>
                [{credit.mc_number}] {credit.name}
              </>
            );
          }}
        />
      </Input.Group>
      {visible && (
        <VendorCreditsDrawer
          visible={visible}
          id={id}
          mc_number={props?.mc_number}
          onClose={handleClosed}
          onSaved={handleSaved}
        />
      )}
    </>
  );
};
