import { CACHE_BILLING_CODE_KEY } from '@/stores/cache';
import { TBillingCode } from '@/types';
import React from 'react';
import { UNIT_MAP as POSSIBLECHARNGE_UNIT_MAP } from '@/components/PossibleCharges';
import SyncCacheModelSelect, {
  SyncCacheModelSelectProps,
} from './SyncCacheModelSelect';

export const BillingCodeSelect: React.FC<SyncCacheModelSelectProps> = ({
  ...props
}) => {
  return (
    <SyncCacheModelSelect
      {...props}
      dropdownMatchSelectWidth={false}
      cacheKey={CACHE_BILLING_CODE_KEY}
      getValueForOption="code"
      optionLabelProp=""
      append={['name', 'unit']}
      filterOption={(input, option: any) => {
        if (option?.value) {
          const inputText = input.toLowerCase().trim();
          return (
            option?.value.toLowerCase().includes(inputText) ||
            option?.name?.toLowerCase().includes(inputText)
          );
        }

        return false;
      }}
      renderOption={(row: TBillingCode) => (
        <>
          <div>
            <strong>{row.code}</strong>
          </div>
          {!props.codeOnly && <div>{row.name}</div>}
        </>
      )}
    />
  );
};
