import { Segmented } from 'antd';
import { LTLAddressTypeLabelMap } from '../constants';

interface AddressTypeRadioProps {
  value?: string;
  disabled?: boolean;
  onChange?: (value: string | number) => void;
}

export const AddressTypeRadio = ({
  value,
  disabled = false,
  onChange,
}: AddressTypeRadioProps) => {
  return (
    <Segmented
      disabled={disabled}
      block
      options={Object.entries(LTLAddressTypeLabelMap).map(
        ([value, label]: [string, string]) => ({
          label,
          value,
        }),
      )}
      value={value}
      onChange={onChange}
    />
  );
};
