import { SearchOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import { CSSProperties, useState } from 'react';
import UILayoutSearchModal from './UILayoutSearchModal';

const iconStyle: CSSProperties = {
  color: 'white',
};

const UILayoutSearchBadge = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <>
      <Badge size="small">
        <SearchOutlined style={iconStyle} onClick={handleOpenModal} />
      </Badge>
      <UILayoutSearchModal open={isModalOpen} onCancel={handleCloseModal} />
    </>
  );
};

export default UILayoutSearchBadge;
