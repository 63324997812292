import { Button, FormInstance, Image, Input, message, Modal } from 'antd';

import { useBoolean, useMemoizedFn, useSafeState } from 'ahooks';

import Svg from '@/assets/image/shining-10px.svg';
import { useApp } from '@/utils/useapp';
import { pick } from 'lodash';
import {
  // genItemsWithClass,
  fetchItemFreightClass,
  freightClassRequiredFields,
} from '@/utils/freight';
import { generateAuthData } from '@/utils/drayaiAuth';
import moment from 'moment';
import { TCity } from '@/types';

const AUTOFILL_PLACEHOLDER_TEXT =
  '103 pieces 1000lbs 3 pallets 40x48x48 inches\nPickup Address: 8950 SORENSEN AVE, SANTA FE SPRINGS CA 90670\nDelivery Address: 1619 Diamond Springs Rd - Suite F Virginia Beach, VA 23455';

export interface AIfillBtnProps {
  form: FormInstance;
  fields?: string[];
  setItems?: (items: any) => void;
  setPickupCityList: (data: TCity[]) => void;
  setDestinationCityList: (data: TCity[]) => void;
  formType: 'quote' | 'shipment';
}

export const AIfillBtn = ({
  form,
  fields,
  setItems,
  setPickupCityList,
  setDestinationCityList,
  formType,
}: AIfillBtnProps) => {
  const [content, setContent] = useSafeState('');
  const app = useApp();

  const [
    openingModal,
    { setTrue: openModal, setFalse: closeModal },
  ] = useBoolean(false);

  const [loading, { setTrue: startLoading, setFalse: endLoading }] = useBoolean(
    false,
  );

  const handleContentKeydown = (e: any) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      if (!content) {
        setContent(AUTOFILL_PLACEHOLDER_TEXT);
      }
    }
  };

  const handleAIAutofill = useMemoizedFn(async () => {
    try {
      startLoading();

      const auofill = await app.service.api(
        `${DRAYAI_BASE_URL}/${
          formType === 'quote'
            ? 'autofill_with_auth_verify'
            : 'autofill_for_shipment_with_auth_verify'
        }`,
        {
          method: 'post',
          data: { content, ...generateAuthData(content) },
        },
      );
      let pickupCityAddress = '';
      if (auofill.pickup_city_name) {
        pickupCityAddress = auofill.pickup_city_name;
        if (auofill.pickup_state_name) {
          pickupCityAddress += `, ${auofill.pickup_state_name}`;
        }
        if (auofill.pickup_zipcode) {
          pickupCityAddress += ` ${auofill.pickup_zipcode}`;
        }
      }
      const pickupCities = await app.service.get('cities', {
        params: {
          query: pickupCityAddress,
        },
      });

      let destinationAddress = '';
      if (auofill.destination_city_name) {
        destinationAddress = auofill.destination_city_name;
        if (auofill.destination_state_name) {
          destinationAddress += `, ${auofill.destination_state_name}`;
        }
        if (auofill.destination_zipcode) {
          destinationAddress += ` ${auofill.destination_zipcode}`;
        }
      }
      const destinationCities = await app.service.get('cities', {
        params: {
          query: destinationAddress,
        },
      });
      // const items = genItemsWithClass(auofill.items);
      const responses = await Promise.all(
        auofill.items.map((item) => {
          if (freightClassRequiredFields.every((field) => !!item[field])) {
            return fetchItemFreightClass(item);
          }
          return Promise.resolve({ class: undefined });
        }),
      ).catch((error) => {
        console.error(error);
        throw new Error('Failed to fetch freight class');
      });
      const items = auofill.items.map((item: any, index: number) => ({
        ...item,
        class: responses[index].class?.toString(),
      }));
      setItems && setItems(items);
      setPickupCityList(pickupCities.data);
      setDestinationCityList(destinationCities.data);

      const windowTimeData =
        formType === 'quote'
          ? {}
          : {
              pickup_open_time: moment(
                auofill.pickup_open_time || '09:00',
                'HH:mm',
              ),
              pickup_close_time: moment(
                auofill.pickup_close_time || '21:00',
                'HH:mm',
              ),
              destination_open_time: moment(
                auofill.destination_open_time || '09:00',
                'HH:mm',
              ),
              destination_close_time: moment(
                auofill.destination_close_time || '21:00',
                'HH:mm',
              ),
            };

      if (fields) {
        form.setFieldsValue({
          ...pick({ ...auofill, ...windowTimeData }, fields),
        });
        if (fields.includes('pickup_city_id')) {
          form.setFieldsValue({
            pickup_city_id: pickupCities.data.length
              ? pickupCities.data[0].id
              : null,
          });
        }
        if (fields.includes('destination_city_id')) {
          form.setFieldsValue({
            destination_city_id: destinationCities.data.length
              ? destinationCities.data[0].id
              : null,
          });
        }
      } else {
        form.setFieldsValue({
          ...auofill,
          items,
          ...windowTimeData,
          pickup_city_id: pickupCities.data.length
            ? pickupCities.data[0].id
            : null,
          destination_city_id: destinationCities.data.length
            ? destinationCities.data[0].id
            : null,
        });
      }
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      endLoading();
      closeModal();
    }
  });

  return (
    <>
      <Button
        style={{
          backgroundColor: '#6B33FE',
          border: 'unset',
          color: 'white',
        }}
        icon={<Image src={Svg} />}
        onClick={() => {
          openModal();
        }}
      >
        AI Autofill
      </Button>
      {openingModal && (
        <Modal
          open={openingModal}
          title={'Autofill Information'}
          okText={'Confirm'}
          cancelText={'Cancel'}
          closable
          confirmLoading={loading}
          onCancel={() => closeModal()}
          onOk={() => handleAIAutofill()}
        >
          <Input.TextArea
            rows={16}
            placeholder={AUTOFILL_PLACEHOLDER_TEXT}
            value={content}
            onKeyDown={handleContentKeydown}
            onChange={(e) => setContent(e.target.value)}
          />
        </Modal>
      )}
    </>
  );
};
