import { CACHE_CONTAINER_TYPE_KEY } from '@/stores/cache';
import React from 'react';
import SyncCacheModelSelect, {
  SyncCacheModelSelectProps,
} from './SyncCacheModelSelect';

export const ContainerTypeSelect: React.FC<SyncCacheModelSelectProps> = ({
  ...props
}) => {
  return (
    <SyncCacheModelSelect {...props} cacheKey={CACHE_CONTAINER_TYPE_KEY} />
  );
};
