import { useApp } from '@/utils/useapp';
import { message, Form, Modal, Space, Button, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import EmailForm from '@/components/EmailForm';
import { serialize } from 'object-to-formdata';
import { each, get, uniq } from 'lodash';
import moment from 'moment';

export type TActionInfo = {
  type: string;
  data: { [key: string]: string };
};

type TProps = {
  containerId: number;
  open: boolean;
  admin: any;
  actionInfo: TActionInfo;
  onSent: () => void;
  onClose: () => void;
};
export const SendPURequestEmailModal: React.FC<TProps> = ({
  containerId,
  open,
  admin,
  actionInfo,
  onSent,
  onClose,
}) => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [emailForm] = useForm();
  // const [docs, setDocs] = useState<any[]>([]);
  const [container, setContainers] = useState<any>();

  const fetchContainer = async () => {
    if (!containerId) {
      return false;
    }

    setLoading(true);
    try {
      const resp = await app.service.get(`dispatch/${containerId}`);
      setContainers(resp.data);
      // fetchOrderDocuments(resp.data);
    } catch (err: any) {
      message.error(err?.data?.error ?? 'error');
    } finally {
      setLoading(false);
    }
  };

  // const fetchOrderDocuments = async (container: any) => {
  //   if (!container) {
  //     return;
  //   }

  //   try {
  //     const respData = await app.service.get(
  //       `documents/order/${container.order_id}`,
  //       {
  //         params: {
  //           containerIds: container.id,
  //         },
  //       },
  //     );
  //     emailForm.setFieldsValue({
  //       documents: respData.data
  //         .filter(
  //           (d) =>
  //             get(d, 'custom_properties.document_type') ==
  //             DOCUMENT_FILE_TYPE_POD,
  //         )
  //         ?.map((d) => d.id),
  //     });

  //     setDocs(
  //       respData.data.map((d: any) => {
  //         return {
  //           label: `[${get(d, 'custom_properties.document_type', 'No Type')}]${
  //             d.file_name
  //           }`,
  //           value: d.id,
  //         };
  //       }),
  //     );
  //   } catch (err: any) {
  //     setDocs([]);
  //   }
  // };

  const handleOnClose = () => {
    emailForm.resetFields();
    onClose();
  };

  const handleSendEmail = async () => {
    setLoading(true);
    try {
      const data = await emailForm.validateFields();

      const config = {
        data: serialize(
          { ...data, taskAction: actionInfo },
          { indices: true, nullsAsUndefineds: true },
        ),
        requestType: 'form',
      };

      await app.service.post(
        `containers/${container.id}/sendEmailFromContainerTask`,
        config,
      );

      message.success('Sent');

      emailForm.resetFields();

      onSent();
    } catch (err: any) {
      err.data && message.error(err.data.message || err.data.error);
    } finally {
      setLoading(false);
    }
  };

  const initForm = () => {
    const from = get(container, 'order.operator.email');
    const fromName = get(container, 'order.operator.name');

    const customerEmail = get(container, 'user.email', '');
    const salesEmail = get(container, 'order.sales.email', '');
    const salesSupportEmail = get(container, 'order.sales_support.email', '');
    const contactEmails = get(container, 'order.contact_emails', []);

    const pickupNumberAgent = get(container, 'order.pickup_number_agent');

    const sendToList = pickupNumberAgent?.emails ?? [];
    sendToList.push(customerEmail);

    const warehouseCity = get(container, 'warehouse.city.full_name');
    const zipcode = get(container, 'warehouse.zipcode', '');
    let warehouseInfo = '';
    warehouseCity && (warehouseInfo += `${warehouseCity}`);
    zipcode && (warehouseInfo += ` ${zipcode}`);
    warehouseInfo += '';

    const eta = moment(container.port_of_discharge_eta).format('MM/DD');

    const sendTo = uniq([...sendToList, ...(contactEmails || [])]);

    emailForm.setFieldsValue({
      from: from,
      from_name: fromName,
      sendToList: sendTo.length > 0 ? sendTo : [''],
      ccList: uniq([
        // customerEmail,
        salesEmail,
        salesSupportEmail,
      ]).filter((i) => i),
      subject: `PU# Request For MBL ${container.order.mbl_number}/ CTNR ${container.number} ${container.final_port.name} - ${warehouseCity} / ETA ${eta}`,
      body: `
Hi ${pickupNumberAgent?.name || ''},

This is ${
  admin.name
} from DrayEasy Inc who handles the pickup & delivery for subject shipment. ETA is ${eta}. Please kindly provide <strong>PU#</strong> as soon as it is available. Thank you.

CTNR: ${container.number}

ETA: ${eta}
      `,
    });
  };

  React.useEffect(() => {
    if (open && container) {
      initForm();
    }
  }, [open, container]);

  React.useEffect(() => {
    if (open && containerId) {
      fetchContainer();
    }
  }, [containerId, open]);

  return (
    <>
      <Modal
        title="Send PU# Request Email"
        width={'70%'}
        onCancel={handleOnClose}
        destroyOnClose={true}
        open={open}
        footer={
          <>
            <Space>
              <Button disabled={loading} onClick={handleOnClose}>
                Cancel
              </Button>
              <Button
                type="primary"
                disabled={loading}
                onClick={handleSendEmail}
              >
                Send
              </Button>
            </Space>
          </>
        }
      >
        <div style={{ maxHeight: '65vh', overflowY: 'scroll' }}>
          <Spin spinning={loading}>
            <Form form={emailForm} layout="vertical">
              <EmailForm
                form={emailForm}
                // docs={docs}
                has={{
                  from: true,
                  attachment: false,
                  showSelectAttachments: false,
                }}
              />
            </Form>
          </Spin>
        </div>
      </Modal>
    </>
  );
};
