import React, { useState } from 'react';
import { Table, TablePaginationConfig, message, Tag, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { useApp } from '@/utils/useapp';
import usePagination from '@/components/usePagination';
import { get, truncate } from 'lodash';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { STATUS_MAP, STATUS_FAILED, STATUS_SUCCESS, STATUS_OPEN } from './data';
interface Props {
  record: any;
}

export const DrawerSendDetail: React.FC<Props> = ({ record }) => {
  const app = useApp();

  const [data, setData] = React.useState();
  const [loading, setLoading] = useState(false);
  const pagination = usePagination(data);

  const fetchData = React.useCallback(
    async (pagination?: TablePaginationConfig) => {
      setLoading(true);

      try {
        const result = await app.service.get(
          `autoSendActionEmails/${record.id}`,
          {
            params: {
              page: pagination?.current || 1,
              per_page: pagination?.pageSize || 20,
            },
          },
        );

        setData(result);
      } catch (e: any) {
        message.error(e.error || 'System Error');
      }

      setLoading(false);
    },
    [],
  );

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 80,
    },
    {
      title: 'Vendor Name',
      dataIndex: 'vendor_name',
      key: 'vendor_name',
      width: 200,
      render: (text, record) => get(record.vendor, 'name', ''),
    },
    {
      title: 'Rate Received',
      dataIndex: 'rate_received',
      key: 'rate_received',
      width: 200,
      render: (text, record) => {
        if (get(record, 'rate_received')) {
          return <Tag color="success">YES</Tag>;
        } else {
          return <Tag color="red">NO</Tag>;
        }
      },
    },
    {
      title: 'Send Admin',
      dataIndex: 'send_admin_name',
      key: 'send_admin_name',
      width: 100,
      render: (text, record) => get(record.sent_admin, 'name', ''),
    },

    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      width: 200,
      render: (text, record) =>
        truncate(get(record, 'subject', ''), {
          length: 100,
        }),
    },
    {
      title: 'Body',
      dataIndex: 'body',
      key: 'body',
      width: 200,
      render: (text, record) =>
        truncate(get(record, 'body_str', ''), {
          length: 50,
        }),
    },
    {
      title: 'Succeed',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (text, record) => {
        const status = get(record, 'status');
        if (STATUS_OPEN == status) {
          return (
            <Tooltip
              placement="topLeft"
              title={'This will be sent at ' + get(record, 'delay_at')}>
              <Tag icon={<ClockCircleOutlined />} color="default">
                {' '}
                {STATUS_MAP[STATUS_OPEN]}
              </Tag>
            </Tooltip>
          );
        } else if (STATUS_SUCCESS == status) {
          return <Tag color="success">{STATUS_MAP[STATUS_SUCCESS]}</Tag>;
        } else if (STATUS_FAILED == status) {
          return <Tag color="red">{STATUS_MAP[STATUS_FAILED]}</Tag>;
        }
      },
    },
    {
      title: 'Failed Reason',
      dataIndex: 'reason',
      key: 'reason',
      width: 200,
      render: (text, record) =>
        truncate(get(record, 'reason', ''), {
          length: 150,
        }),
    },
    {
      title: 'Email To',
      dataIndex: 'email_to',
      key: 'email_to',
      width: 150,
    },
    {
      title: 'Email CC',
      dataIndex: 'email_cc',
      key: 'email_cc',
      width: 150,
    },
    {
      title: 'Send At',
      dataIndex: 'sent_at',
      key: 'sent_at',
      width: 150,
    },
  ];

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <Table
      loading={loading}
      pagination={pagination}
      size="small"
      rowKey="id"
      columns={columns}
      onChange={fetchData}
      dataSource={data?.data || []}
      sticky
      scroll={{
        x: 'auto',
      }}
    />
  );
};
