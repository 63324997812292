import styles from 'res/css/ui.scss';
import { Button, Col, Form, Input, Row } from 'antd';
import React from 'react';
import { FormInstance } from 'antd/es/form';

export type TFilter = {
  loading: boolean;
  filter: FormInstance;
  onSearch: () => void;
};

export const Filter: React.FC<TFilter> = ({ loading, filter, onSearch }) => {
  const handleSearch = () => {
    onSearch();
  };

  const handleReset = () => {
    filter.resetFields();
    onSearch();
  };

  return (
    <div className={styles.filter}>
      <div
        style={{
          width: '60%',
        }}>
        <Form form={filter}>
          <Row gutter={10}>
            <Col span={8}>
              <Form.Item
                className="mb0"
                labelCol={{ span: 24 }}
                label="Name"
                name="name"
                wrapperCol={{ span: 24 }}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <div>
        <Row gutter={10}>
          <Col span={12}>
            <Button type="primary" disabled={loading} onClick={handleSearch}>
              Search
            </Button>
          </Col>
          <Col span={12}>
            <Button onClick={handleReset}>Clear All</Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};
