import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Modal,
  Popover,
  Space,
  message,
} from 'antd';
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { TagFilled } from '@ant-design/icons';
import { useApp } from '@/utils/useapp';
import _ from 'lodash';
import {
  CACHE_CONTAINER_COLOR_TAG_KEY,
  CACHE_FTL_COLOR_TAG_KEY,
  CACHE_LTL_COLOR_TAG_KEY,
} from '@/stores/cache';
import { observer } from 'mobx-react';
import { useForm } from 'antd/lib/form/Form';
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { TContainer } from '@/types';
import { TLType } from './constants';

interface Option {
  label: string;
  color: string;
  id: number;
}

const TagSelect: React.FC<{
  options: Option[];
  onChange: (values: any) => void;
  selected: number[];
  onEdit?: (values: any) => void;
  loading?: boolean;
}> = ({ options, onChange, selected, onEdit, loading = false }) => {
  return (
    <Checkbox.Group
      style={{ width: '100%' }}
      defaultValue={selected}
      onChange={onChange}
    >
      <Space
        direction="vertical"
        style={{
          width: '100%',
        }}
      >
        {options.map((option: any) => {
          return (
            <div
              key={option.id}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <span>
                <TagFilled style={{ color: option.color }} /> {option.label}
                {onEdit && (
                  <EditOutlined
                    style={{ color: 'grey', marginLeft: '10px' }}
                    onClick={() => {
                      onEdit && onEdit(option);
                    }}
                  />
                )}
              </span>
              <Checkbox disabled={loading} value={option.id}></Checkbox>
            </div>
          );
        })}
      </Space>
    </Checkbox.Group>
  );
};

const AddTagModal: React.FC<{
  onSave: (values: any) => void;
  data: any;
  model: string;
}> = ({ onSave, data, model }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const app = useApp();
  const [form] = useForm();

  useEffect(() => {
    if (data) {
      form.setFieldsValue({ ...data, model });
      setIsModalOpen(true);
    }
  }, [data]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const onFinish = async (values: any) => {
    try {
      if (values.id) {
        await app.service.put(`colorTags/${values.id}`, { data: values });
      } else {
        await app.service.post('colorTags', { data: values });
      }
      onSave && onSave(values);
      form.resetFields();
      handleCancel();
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    }
  };

  return (
    <>
      <PlusCircleOutlined onClick={showModal} />

      <Modal
        footer={false}
        title="Tag"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} onFinish={onFinish} autoComplete="off">
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>
          <Form.Item hidden name="model" initialValue={model}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Label"
            name="label"
            rules={[{ required: true, message: 'Please input Label!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue, setFieldValue }) => (
              <Form.Item
                label="Color"
                name="color"
                rules={[{ required: true, message: 'Please pick a color!' }]}
              >
                <input
                  type="color"
                  value={getFieldValue('color')}
                  onChange={(e) => setFieldValue('color', e.target.value)}
                />
              </Form.Item>
            )}
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export const ContainerColorTag: FC<
  {
    selected: number[];
    containerId: number;
    onApply: (values: TContainer) => void;
  } & ReactNode
> = ({ selected, containerId, onApply, children }) => {
  const [show, setShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const app = useApp();

  const options = useMemo(() => {
    return app.store.cache.get(CACHE_CONTAINER_COLOR_TAG_KEY) || [];
  }, [app.store.cache.data, show]);

  const onChange = async (checkedValues: number[]) => {
    setLoading(true);
    try {
      const resp = await app.service.put('containers/bulk', {
        data: [
          {
            id: containerId,
            tags: checkedValues,
          },
        ],
      });
      onApply && onApply(resp.data[0]);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Popover
      placement="left"
      trigger={'click'}
      open={show}
      onOpenChange={(o) => setShow(o)}
      content={
        <div
          style={{
            minWidth: '300px',
          }}
        >
          {show && (
            <TagSelect
              options={options}
              selected={selected}
              onChange={onChange}
              loading={loading}
            />
          )}
        </div>
      }
    >
      {children}
    </Popover>
  );
};

export const TLColorTag: FC<
  {
    selected: number[];
    tlId: number;
    onApply: (values: TContainer) => void;
    tlType: TLType;
  } & ReactNode
> = ({ selected, tlType = TLType.LTL, tlId, onApply, children }) => {
  const [show, setShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const app = useApp();

  const options = useMemo(() => {
    return (
      app.store.cache.get(
        tlType === TLType.LTL
          ? CACHE_LTL_COLOR_TAG_KEY
          : CACHE_FTL_COLOR_TAG_KEY,
      ) || []
    );
  }, [app.store.cache.data, show]);

  const onChange = async (checkedValues: number[]) => {
    setLoading(true);
    const url =
      tlType === TLType.LTL
        ? `tl/ltl/shipments/${tlId}`
        : `ftl/shipments/${tlId}`;
    try {
      const resp = await app.service.patch(url, {
        data: { tags: checkedValues },
      });

      onApply && onApply(resp.data);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Popover
      placement="left"
      trigger={'click'}
      open={show}
      onOpenChange={(o) => setShow(o)}
      content={
        <div
          style={{
            minWidth: '300px',
          }}
        >
          {show && (
            <TagSelect
              options={options}
              selected={selected}
              onChange={onChange}
              loading={loading}
            />
          )}
        </div>
      }
    >
      {children}
    </Popover>
  );
};

const ColorTagSelect = ({
  selectedRows = [],
  onApply,
  model = 'App\\Models\\Container',
}: {
  selectedRows?: number[];
  onApply?: (values: number[]) => void;
  model: string;
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [selected, setSelected] = useState<number[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [edit, setEdit] = useState(null);
  const app = useApp();

  function onChange(checkedValues: number[]) {
    setSelected(checkedValues);
  }

  const key = useMemo(() => {
    if (model === 'App\\Models\\Container') {
      return CACHE_CONTAINER_COLOR_TAG_KEY;
    }

    if (model === 'App\\Domains\\TL\\Models\\LTLShipment') {
      return CACHE_LTL_COLOR_TAG_KEY;
    }

    if (model === 'App\\Domains\\FTL\\Models\\FTLShipment') {
      return CACHE_FTL_COLOR_TAG_KEY;
    }

    return '';
  }, [app.store.cache.data, show, refresh]);

  const fetchOptions = async () => {
    await app.store.cache.fetch(key, true);
    setRefresh(!refresh);
  };

  const defaultValues = useMemo(() => {
    return _.uniq(
      _.flatten(
        selectedRows.filter((c: any) => c.tags).map((c: any) => c.tags),
      ),
    );
  }, [selectedRows]);

  const handleApply = async () => {
    try {
      if (selectedRows.length === 0) {
        message.warning('No Container selected');
        return;
      }
      const values = selectedRows.map((c: any) => {
        return { id: c.id, tags: selected };
      });
      const resp = await app.service.put('containers/bulk', { data: values });
      onApply && onApply(resp.data);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    }
  };

  const options = useMemo(() => {
    return app.store.cache.get(key) || [];
  }, [app.store.cache.data, show, refresh, key]);

  const handleSave = () => {
    setEdit(null);
    fetchOptions();
  };

  return (
    <>
      <Popover
        placement="left"
        trigger={'click'}
        open={show}
        onOpenChange={(o) => setShow(o)}
        content={
          <div
            style={{
              minWidth: '300px',
            }}
          >
            <div className="flex justify-end">
              {' '}
              <AddTagModal onSave={handleSave} data={edit} model={model} />
            </div>
            <Divider />
            {show && (
              <TagSelect
                options={options}
                selected={defaultValues}
                onChange={onChange}
                onEdit={(values) => setEdit(values)}
              />
            )}
            <Divider />
            {onApply && (
              <Button onClick={() => handleApply()} size="small">
                Apply
              </Button>
            )}
          </div>
        }
      >
        <Button type="primary">Tags</Button>
      </Popover>
    </>
  );
};

export default observer(ColorTagSelect);
