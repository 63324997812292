import type { TabsProps } from 'antd';
import { Tabs } from 'antd';
import React, { useMemo, useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const type = 'DraggableTabNode';
interface DraggableTabPaneProps extends React.HTMLAttributes<HTMLDivElement> {
  index: React.Key;
  moveNode: (dragIndex: React.Key, hoverIndex: React.Key) => void;
}

const DraggableTabNode = ({
  index,
  children,
  moveNode,
}: DraggableTabPaneProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: 'dropping',
      };
    },
    drop: (item: { index: React.Key }) => {
      moveNode(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <div
      ref={ref}
      style={{ marginRight: 5 }}
      className={isOver ? dropClassName : ''}>
      {children}
    </div>
  );
};

interface IDraggableTabsProps {
  onTabMoved?: (order: number[]) => void;
}

const DraggableTabs: React.FC<TabsProps & IDraggableTabsProps> = (props) => {
  const { items = [], onTabMoved } = props;
  const moveTabNode = (dragKey: React.Key, hoverKey: React.Key) => {
    const newOrder: any[] = [];

    items.forEach((item) => {
      if (item.key && newOrder.indexOf(item.key) === -1) {
        newOrder.push(item.key);
      }
    });

    // console.log(dragKey, hoverKey);

    const dragIndex = newOrder.indexOf(dragKey);
    const hoverIndex = newOrder.indexOf(hoverKey);

    newOrder.splice(dragIndex, 1);
    newOrder.splice(hoverIndex, 0, dragKey);

    onTabMoved && onTabMoved(newOrder);
  };

  const renderTabBar: TabsProps['renderTabBar'] = (
    tabBarProps,
    DefaultTabBar,
  ) => (
    <DefaultTabBar {...tabBarProps}>
      {(node) => (
        <DraggableTabNode
          key={node.key}
          index={node.key!}
          moveNode={moveTabNode}>
          {node}
        </DraggableTabNode>
      )}
    </DefaultTabBar>
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <Tabs
        type="card"
        size="small"
        renderTabBar={renderTabBar}
        {...props}
        items={items}
      />
    </DndProvider>
  );
};

export default DraggableTabs;
