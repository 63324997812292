import { Form, Select, message, Input, Divider, Space, Button } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React from 'react';
import { useApp } from '@/utils/useapp';
import { UploadProps } from 'antd';
import { DraggerWithPaste } from '@/components/Upload/DraggerWithPaste';
import { ACCEPT_FILE_TYPES } from '@/components/Upload/data';
import { checkFileSize, FILE_MAX_SIZE } from '@/components/Upload/Validate';
import { RcFile } from 'antd/lib/upload';
import { EyeOutlined } from '@ant-design/icons';

interface IAttachmentProps {
  form: FormInstance;
  showSelectAttachments?: boolean;
  docs?: any;
  onChange: (fileList: any) => void;
}

export const Attachment: React.FC<IAttachmentProps> = ({
  form,
  showSelectAttachments = false,
  docs = [],
  onChange,
}) => {
  const app = useApp();

  const [fileList, setFileList] = React.useState<File[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleOnChange = async (fileList: any) => {
    onChange(fileList);
  };

  const beforeUpload = (file: RcFile) => {
    if (isLoading) {
      return false;
    }

    const sizeOk = checkFileSize(file);

    if (!sizeOk) {
      message.error(`File must smaller than ${FILE_MAX_SIZE}MB!`);
    }
    return sizeOk;
  };

  const props: UploadProps = {
    multiple: true,
    beforeUpload: beforeUpload,
    customRequest: async (options: any) => {
      options.file.status = 'done';
      setFileList([...fileList, options.file]);
    },
    onRemove: async (file) => {
      setFileList(fileList?.filter((item: any) => item.uid !== file.uid));
    },
  };

  const handlePasteFiles = async (pasteFiles: any) => {
    setFileList([
      ...fileList,
      ...pasteFiles.map((f: any, index: number) => {
        f.status = 'done';
        f.uid = Date.now() + '_' + index;
        return f;
      }),
    ]);
  };

  const handlePreviewDoc = async (doc: any, e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const resp = await app.service.get(`documents/${doc.value}/url`);
    const url = resp;
    const w = window.open(url, '_blank');
  };

  React.useEffect(() => {
    form.setFieldValue('customDocuments', fileList);
  }, [fileList]);

  return (
    <>
      <Form.Item
        label="Attachment"
        name="documents"
        noStyle={!showSelectAttachments}>
        {showSelectAttachments && (
          <Select
            placeholder="Select attachments"
            mode="multiple"
            optionLabelProp="label"
            onChange={(v: any) => handleOnChange(v)}>
            {docs?.map((doc: any) => (
              <Select.Option key={doc.id} value={doc.value} label={doc.label}>
                <div className="flex justify-between align-center">
                  <div>{doc.label}</div>
                  <div>
                    <Button
                      type="link"
                      onClick={(e) => handlePreviewDoc(doc, e)}>
                      {/* <EyeOutlined /> */}
                      Preview
                    </Button>
                  </div>
                </div>
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item name="customDocuments" noStyle>
        <Input hidden />
      </Form.Item>
      <Form.Item label={!showSelectAttachments ? 'Attachment' : ''}>
        <DraggerWithPaste
          title={''}
          acceptFileTypes={ACCEPT_FILE_TYPES}
          uploadProp={props}
          disabled={isLoading}
          fileList={fileList}
          handlePasteFiles={handlePasteFiles}
        />
      </Form.Item>
    </>
  );
};
