import { OPdashboardTabInterface } from '@/Interfaces/OPdashboardTab/OpdashboardTabInterface';
import { useApp } from '@/utils/useapp';
import { message } from 'antd';

const useOpdashboardTab = () => {
  const app = useApp();

  const updateOpdashboardTab = async (
    name: string,
    value: string | any[] | Record<string, any>,
    tab: OPdashboardTabInterface | undefined,
  ) => {
    if (!tab || !name) {
      return;
    }

    try {
      const resp = await app.service.put(`opdashboardTabs/${tab.id}`, {
        data: { ...tab, [name]: value },
      });

      return resp.data;
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    }
  };
  return {
    updateOpdashboardTab,
  };
};
export default useOpdashboardTab;
