import { Table, Space } from 'antd';
import React, { useMemo } from 'react';
import { ColumnsType } from 'antd/es/table';
import { OnDoneInterface, TaskInterface } from '../Interfaces/TaskInterface';
import {
  TARGET_TYPE_LTL_SHIPMENT,
  TTarget,
} from '../Interfaces/ActionInterface';
import ActionColumn from './ActionColumn';
import TaskTitilePopover from './TaskTitilePopover';
import TaskColumn from './TaskColumn';
import TitleSlaTooltip from './TitleSlaTooltip';
import TaskSla from './TaskSla';
import TaskTypeColumn from './TaskTypeColumn';
import useAction from '../Actions/useAction';
import taskTypeUtil from '../Interfaces/TaskType';

export const TodoTable: React.FC<
  {
    data: Array<TaskInterface>;
    loading: boolean;
  } & TTarget &
    OnDoneInterface
> = ({ data, loading, target, targetModel, onDone }) => {
  const { saveOperation } = useAction({ target, targetModel });

  const colors: { [key: string]: string } = useMemo(() => {
    const _colors: { [key: string]: string } = {};

    const taskTypes = taskTypeUtil.getCheckedTaskTypeList(target);

    for (const i in taskTypes) {
      _colors[taskTypes[i]] =
        targetModel?.task_icon_colors &&
        targetModel?.task_icon_colors[taskTypes[i]]
          ? targetModel?.task_icon_colors[taskTypes[i]]
          : 'gray';
    }

    return _colors;
  }, [targetModel]);

  const toSaveOperation = async (
    task: TaskInterface,
    action: string,
    update_name: string,
    value?: string | null,
    setLoading?: (loading: boolean) => void,
    afterSave?: () => void,
  ) => {
    saveOperation(task, action, update_name, value, setLoading, afterSave);
  };

  const columns: ColumnsType<TaskInterface> = useMemo(() => {
    const baseColumns: ColumnsType<TaskInterface> = [
      {
        title: <TaskTitilePopover target={target} targetModel={targetModel} />,
        dataIndex: 'task',
        render: (_, record: TaskInterface) => {
          return (
            <Space>
              <TaskColumn
                target={target}
                targetModel={targetModel}
                task={record}
              />
            </Space>
          );
        },
      },
      {
        title: 'Task Type',
        dataIndex: 'task_type',
        width: 180,
        render: (_, record: TaskInterface) => {
          return (
            <TaskTypeColumn
              target={target}
              targetModel={targetModel}
              task={record}
              colors={colors}
              onDone={onDone}
            />
          );
        },
      },
      {
        title: 'Action',
        width: 350,
        render: (_, record: TaskInterface, index: number) => {
          return (
            <ActionColumn
              target={target}
              targetModel={targetModel}
              onDone={onDone}
              task={record}
              onSaveOperation={toSaveOperation}
            />
          );
        },
      },
    ];

    if (target === TARGET_TYPE_LTL_SHIPMENT) {
      baseColumns.splice(1, 0, {
        title: <TitleSlaTooltip />,
        dataIndex: 'sla',
        width: 180,
        render: (_, record: TaskInterface) => {
          return <TaskSla operation={record.operation} />;
        },
      });
    }

    return baseColumns;
  }, [data, target, targetModel]);

  return (
    <>
      <Table
        columns={columns}
        loading={loading}
        dataSource={data || []}
        bordered
        size="small"
        pagination={false}
        scroll={{ x: '100%', y: 800 }}
        // rowKey="task"
      />
    </>
  );
};
