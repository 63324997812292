import { useApp } from '@/utils/useapp';
import { Header } from '@/components/CommonHeader';
import { useEffect, useState } from 'react';
import styles from 'res/css/ui.scss';

const Index = () => {
  const app = useApp();
  const [data, setData] = useState('');
  const [time, setTime] = useState(0);

  const getData = async () => {
    const resp = await app.service.get('setting/phpinfo');

    setData(resp.data);
    setTime(resp.time);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={styles.main}>
      <Header title="PHP INFO" />
      <div className="mt-sm">Time: {time}</div>
      <div dangerouslySetInnerHTML={{ __html: data }} />
    </div>
  );
};

export default Index;
