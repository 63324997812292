import React, { useCallback } from 'react';
import { Popover, Switch, Space } from 'antd';
import { CheckCircleFilled, CloseCircleOutlined } from '@ant-design/icons';
import { ShipmentVerification } from '../Interfaces/ShipmentVerificationInterface';

interface VerificationStatusProps {
  shipmentId: number;
  verifications: ShipmentVerification[];
  opAllVerifiedAt: string | null;
  onVerificationChange: (
    shipmentId: number,
    key: string,
    checked: boolean,
  ) => void;
}

const VerificationStatus: React.FC<VerificationStatusProps> = ({
  shipmentId,
  verifications,
  opAllVerifiedAt,
  onVerificationChange,
}) => {
  const handleChange = useCallback(
    (verification: ShipmentVerification, checked: boolean) => {
      if (typeof onVerificationChange !== 'function') {
        console.error(
          'onVerificationChange is not a function:',
          onVerificationChange,
        );
        return;
      }

      onVerificationChange(shipmentId, verification.key, checked);
    },
    [onVerificationChange, shipmentId],
  );

  const content = (
    <div style={{ padding: '4px 0' }}>
      <Space direction="vertical" size="middle">
        {verifications.map((verification) => (
          <div
            key={verification.key}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              minWidth: '200px',
            }}
          >
            <span>{verification.label}:</span>
            <Switch
              size="small"
              checked={verification.verified}
              onClick={(checked, event) => {
                event.stopPropagation();
              }}
              onChange={(checked) => handleChange(verification, checked)}
            />
          </div>
        ))}
      </Space>
      {opAllVerifiedAt && (
        <div
          style={{
            color: '#8c8c8c',
            fontSize: '12px',
            borderTop: '1px solid #f0f0f0',
            marginTop: '8px',
            paddingTop: '8px',
          }}
        >
          Verified at: {new Date(opAllVerifiedAt).toLocaleString()}
        </div>
      )}
    </div>
  );

  const allVerified = verifications.every((v) => v.verified);

  return (
    <Popover
      content={content}
      trigger="hover"
      placement="bottom"
      overlayStyle={{ minWidth: '240px' }}
      destroyTooltipOnHide={false}
    >
      <div
        style={{
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '4px',
          padding: '4px 8px',
          cursor: 'pointer',
          backgroundColor: 'transparent',
          transition: 'background-color 0.3s',
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.backgroundColor = '#f5f5f5';
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.backgroundColor = 'transparent';
        }}
      >
        {allVerified ? (
          <CheckCircleFilled style={{ fontSize: '18px', color: '#52c41a' }} />
        ) : (
          <CloseCircleOutlined style={{ fontSize: '18px', color: '#8c8c8c' }} />
        )}
      </div>
    </Popover>
  );
};

export default VerificationStatus;
