import { CACHE_FTL_COLOR_TAG_KEY } from '@/stores/cache';
import { useApp } from '@/utils/useapp';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { TagFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { TLColorTag } from '@/components/ColorTagSelect';
import { TLType } from '@/components/constants';

const ColorTags: React.FC<{
  tags: number[];
  tlId: number;
  onApply: (values: any) => void;
}> = observer(({ tags, tlId, onApply }) => {
  const app = useApp();

  const colorTags = useMemo(
    () => app.store.cache.get(CACHE_FTL_COLOR_TAG_KEY) || [],
    [app.store.cache.data],
  );

  return (
    <TLColorTag
      selected={tags}
      tlType={TLType.FTL}
      tlId={tlId}
      onApply={onApply}
    >
      <div
        style={{
          cursor: 'pointer',
        }}
      >
        {tags.length == 0 && (
          <Tooltip title={'Add Tags'}>
            <TagFilled style={{ color: '#E6E6E6', marginLeft: '1px' }} />
          </Tooltip>
        )}
        {tags.map((tag) => {
          const _tag = colorTags.find((t) => t.id == tag);
          if (!_tag) {
            return <></>;
          }
          return (
            <Tooltip key={tag} title={_tag?.label || ''}>
              <TagFilled
                style={{ color: _tag?.color || '', marginLeft: '1px' }}
              />
            </Tooltip>
          );
        })}
      </div>
    </TLColorTag>
  );
});

export default ColorTags;
