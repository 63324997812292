import { Popover } from 'antd';
import React, { useEffect, useState } from 'react';
import { RateDetailOfPossibleCharge } from './RateDetailOfPoossibleCharge';
import { InfoCircleOutlined } from '@ant-design/icons';

export const PossibleCharges: React.FC<{
  possibleCharges: any;
}> = ({ possibleCharges }) => {
  return possibleCharges?.map((possibleCharge: any) => {
    return (
      <div
        key={`possibleCharge-${possibleCharge.id}`}
        className="flex justify-between">
        <small>
          {possibleCharge.name}{' '}
          {possibleCharge.explanation && (
            <Popover
              trigger="click"
              placement="bottom"
              content={possibleCharge.explanation}>
              <InfoCircleOutlined />
            </Popover>
          )}
        </small>
        <RateDetailOfPossibleCharge possibleCharge={possibleCharge} />
      </div>
    );
  });
};
