import AutoResizeTable from '@/components/AutoResizeTable';
import { Button, Form, Space, Table, TablePaginationConfig } from 'antd';
import {
  ColumnsType,
  FilterValue,
  SorterResult,
} from 'antd/lib/table/interface';
import { Rate } from './QuoteForm';
import { Key, useState } from 'react';
import { EditableText } from '@/components/Editable';
import { useForm } from 'antd/lib/form/Form';

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue | null>;
}

export interface RateTableProps {
  rates: Rate[];
  selectedRates: Key[];
  disableSelect: boolean;
  setSelectedRates: (rates: Key[]) => void;
  handleSelect: (rate: Rate) => void;
  handleUpdateRate: (rateId: number, newRate: number) => void;
}

export const RateTable = ({
  rates,
  selectedRates,
  disableSelect,
  setSelectedRates,
  handleSelect,
  handleUpdateRate,
}: RateTableProps) => {
  const [tableParams, setTableParams] = useState<TableParams>({});
  const [form] = useForm();

  const columns: ColumnsType<Rate> = [
    {
      title: 'Source',
      dataIndex: ['vendor', 'name'],
      width: 120,
    },
    {
      title: 'Carrier',
      dataIndex: 'carrier_name',
      width: 120,
    },
    {
      title: 'Transit days',
      dataIndex: 'transit_days',
      defaultSortOrder: 'ascend',
      width: 120,
    },
    {
      title: 'Est Delivery Date',
      dataIndex: 'estimated_delivery_date',
      width: 120,
    },
    {
      title: 'Price',
      dataIndex: 'quote_rate',
      width: 120,
    },
    {
      title: 'Recommend Rate',
      dataIndex: 'recommend_rate',
      width: 120,
    },
    {
      title: 'Final Shipping Rate',
      dataIndex: 'shipping_rate',
      width: 120,
      sorter: true,
      render: (text, record) => {
        return (
          <Form.Item
            noStyle
            name={['data', record.id, 'shipping_rate']}
            initialValue={record.shipping_rate}
          >
            <EditableText
              type="number"
              name="shipping_rate"
              value={record.shipping_rate.toString()}
              onChange={(name: string, value: any) => {
                form.setFieldValue(['data', record.id, 'shipping_rate'], value);
                handleUpdateRate(record.id, parseFloat(value));
              }}
            />
          </Form.Item>
        );
      },
    },
    {
      title: 'Margin %',
      width: 120,
      render: (text, record) => {
        const shippingRate = form.getFieldValue([
          'data',
          record.id,
          'shipping_rate',
        ]);
        return (
          <div>
            {(
              ((shippingRate - record.quote_rate) / record.quote_rate) *
              100
            ).toFixed(2)}
          </div>
        );
      },
    },
    {
      title: 'Margin $',
      width: 120,
      render: (text, record) => {
        const shippingRate = form.getFieldValue([
          'data',
          record.id,
          'shipping_rate',
        ]);
        return <div>{(shippingRate - record.quote_rate).toFixed(2)}</div>;
      },
    },
    {
      title: 'Final Rate',
      dataIndex: 'rate',
      width: 120,
    },
    {
      align: 'center',
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 150,
      render: (text, record) => (
        <Space>
          <Button
            disabled={disableSelect}
            size="small"
            onClick={() => handleSelect(record)}
          >
            Select
          </Button>
        </Space>
      ),
    },
  ];

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Rate> | SorterResult<Rate>[],
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const handleTop = (topN: number) => {
    setSelectedRates(rates.slice(0, topN).map((rate) => rate.id));
  };

  return (
    <Space direction="vertical">
      <Space>
        <Button type="primary" onClick={() => handleTop(5)}>
          Top 5
        </Button>
        <Button type="primary" onClick={() => handleTop(10)}>
          Top 10
        </Button>
        <Button type="primary" onClick={() => handleTop(15)}>
          Top 15
        </Button>
        <Button type="primary" onClick={() => handleTop(20)}>
          Top 20
        </Button>
      </Space>
      <Form form={form} component={false}>
        <Table
          size="small"
          tableLayout="auto"
          pagination={false}
          rowSelection={{
            selectedRowKeys: selectedRates,
            onChange: (selectedRowKeys) => setSelectedRates(selectedRowKeys),
          }}
          rowKey="id"
          columns={columns}
          onChange={handleTableChange}
          dataSource={rates || []}
          sticky
          scroll={{
            x: 'auto',
          }}
        />
      </Form>
    </Space>
  );
};
