import { Button, Modal, Table, message, Space, Typography } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useApp } from '@/utils/useapp';
import moment from 'moment';
import type { ColumnsType } from 'antd/lib/table';
import { useForm } from 'antd/lib/form/Form';
import { get } from 'lodash';
import { CopyButton } from '@/components/CopyButton';
import { Overdue } from '@/pages/dispatchs/components/Table/Overdue';
import { CNTREasyTracking } from '@/pages/dispatchs/components/CNTREasyTracking';
import { WHSColumn } from '@/pages/dispatchs/components/WHS/WHSColumn';
import { Memo } from '@/pages/dispatchs/components/Memo';
import Online from '../Online';
import { MBLLink } from '@/components/MBLLInk';
import { TitleSlaTooltip } from '@/pages/dispatchs/components/ActionTasks/TitleSlaTooltip';
import { RenderSla } from '@/pages/dispatchs/components/ActionTasks/RenderSla';
import { RenderWhoseTaskTag } from '@/pages/dispatchs/components/ActionTasks/RenderTaskColumn/RenderWhoseTaskTag';
import {
  SorterResult,
  TablePaginationConfig,
  FilterValue,
} from 'antd/lib/table/interface';

export const OverdueDataList: React.FC<{
  data: any;
  loading: boolean;
  onExport: (selectedRowKeys: any) => void;
  onMemoSaved: (id: number) => void;
  onReset: () => void;
  onChange: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[],
    extra: any,
  ) => void;
}> = ({ data, loading, onExport, onMemoSaved, onReset, onChange }) => {
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
    onSelectMultiple: (_selected: any, selectedRows: any, _changeRows: any) => {
      const selectedRowKeys = selectedRows.map((s: any) => s.task_id);
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
  };

  const handleExport = async () => {
    // if (!selectedRowKeys?.length) {
    //   message.error('No data to export');
    //   return;
    // }

    onExport(selectedRowKeys);
  };

  const handleReset = () => {
    onReset();
  };

  const columns: ColumnsType<any> = React.useMemo(
    () => [
      {
        title: 'CNTR#',
        dataIndex: 'number',
        key: 'number',
        width: 200,
        fixed: 'left',
        sorter: (a, b) => a.number.localeCompare(b.number),
        render: (text, record) => (
          <Space>
            <CNTREasyTracking container={record} showAtrribute={false} />
          </Space>
        ),
      },
      {
        title: 'MBL#',
        dataIndex: 'mbl_number',
        key: 'mbl_number',
        width: 200,
        fixed: 'left',
        sorter: (a, b) =>
          get(a, 'order.mbl_number').localeCompare(get(b, 'order.mbl_number')),
        render: (text, record) => (
          <Space>
            <MBLLink mblNumber={record.order?.mbl_number || ''} />
            {get(record, 'order.mbl_number', false) && (
              <CopyButton value={get(record, 'order.mbl_number')} />
            )}
          </Space>
        ),
      },
      {
        title: 'Overdue Task',
        dataIndex: 'task',
        key: 'task',
        width: 200,
        sorter: (a, b) => a.whose_task - b.whose_task,
        render: (text, record) => (
          <Space>
            <RenderWhoseTaskTag whoseTask={record.whose_task} />
            {text}
          </Space>
        ),
      },
      {
        title: <TitleSlaTooltip />,
        dataIndex: 'sla',
        key: 'sla',
        width: 200,
        // sorter: true,
        sorter: (a, b) => a.emergency_level - b.emergency_level,
        render: (text, record) => <RenderSla item={record} />,
      },
      {
        title: 'Disaptch Status',
        dataIndex: 'dispatch_status_name',
        key: 'dispatch_status_name',
        sorter: (a, b) => a.dispatch_status - b.dispatch_status,
        width: 200,
      },
      {
        title: 'IR ETA',
        dataIndex: 'final_port_eta',
        key: 'final_port_eta',
        // sorter: true,
        sorter: (a, b) => {
          const dateA = new Date(a.final_port_eta);
          const dateB = new Date(b.final_port_eta);
          return dateA.getTime() - dateB.getTime();
        },
        width: 100,
      },
      {
        title: 'WHS',
        dataIndex: 'warehouse_id',
        key: 'warehouse_id',
        width: 300,
        sorter: (a, b) =>
          get(a, 'warehouse.name')?.localeCompare(get(b, 'warehouse.name')),
        render: (text, record) => {
          return (
            <WHSColumn
              container={record}
              onSaved={() => console.log(record.warehouse_id)}
            />
          );
        },
      },
      {
        title: 'SE',
        dataIndex: 'se',
        key: 'se',
        width: 120,
        sorter: (a, b) =>
          get(a, 'order.sales.name')?.localeCompare(get(b, 'order.sales.name')),
        render: (text, record) => (
          <>
            <Online id={record.order?.sales_id || 0} />
            <Typography.Text className="ml-sm">
              {record.order?.sales?.name || '-'}
            </Typography.Text>
          </>
        ),
      },
      {
        title: 'SS',
        dataIndex: 'sales_support_id',
        key: 'sales_support_id',
        width: 120,
        sorter: (a, b) =>
          get(a, 'order.sales_support.name')?.localeCompare(
            get(b, 'order.sales_support.name'),
          ),
        render: (text, record) => (
          <>
            <Online id={record.order?.sales_support_id || 0} />
            <Typography.Text className="ml-sm">
              {record.order?.sales_support?.name || '-'}
            </Typography.Text>
          </>
        ),
      },
      {
        title: 'OP',
        dataIndex: 'operator_id',
        key: 'operator_id',
        width: 120,
        sorter: (a, b) =>
          get(a, 'order.operator.name')?.localeCompare(
            get(b, 'order.operator.name'),
          ),
        render: (text, record) => (
          <>
            <Online id={record.order?.operator_id || 0} />
            <Typography.Text className="ml-sm">
              {record.order?.operator?.name || '-'}
            </Typography.Text>
          </>
        ),
      },
      {
        title: 'OA',
        dataIndex: 'operator_assistant_id',
        key: 'operator_assistant_id',
        width: 120,
        sorter: (a, b) =>
          get(a, 'order.operator_assistant.name')?.localeCompare(
            get(b, 'order.operator_assistant.name'),
          ),
        render: (text, record) => (
          <>
            <Online id={record.order?.operator_assistant_id || 0} />
            <Typography.Text className="ml-sm">
              {record.order?.operator_assistant?.name || '-'}
            </Typography.Text>
          </>
        ),
      },
      {
        title: 'Memo',
        dataIndex: 'internal_memo',
        key: 'internal_memo',
        width: 120,
        render: (text, record) => (
          <Button type="link" size="small">
            <Memo
              initialValue={record.internal_memo}
              onSaved={onMemoSaved}
              container={record}
            />
          </Button>
        ),
      },
    ],
    [],
  );

  return (
    <>
      <Table
        pagination={{
          showTotal: (total: number) => `Total: ${data?.length}`,
          total: data?.length,
          pageSize: 100,
          position: ['bottomCenter'],
        }}
        loading={loading}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        rowKey="task_id"
        // onChange={onChange}
        title={() => (
          <Space>
            <h4>Overdue List</h4>
            <Button type="primary" size="small" onClick={handleReset}>
              Reset
            </Button>
            <Button type="primary" size="small" onClick={handleExport}>
              Export
            </Button>
          </Space>
        )}
        scroll={{
          x: 800,
          y: 400,
        }}
        columns={columns}
        dataSource={data}
        size="small"
      />
    </>
  );
};
