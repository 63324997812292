import { Button, Typography } from 'antd';
import { CopyButton } from '@/components/CopyButton';
import { useState } from 'react';
import { EditableText } from '@/components/Editable';
import { EditOutlined } from '@ant-design/icons';
import React from 'react';

const ShipmentUpdateableColumn: React.FC<{
  shipmentId: number;
  fieldName: string;
  fieldValue: any;
  handleEdit: (id: number, type: string, data: any) => void;
}> = ({ shipmentId, fieldName, fieldValue, handleEdit }) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <div>
      {isEditing ? (
        <EditableText
          type="string"
          name={fieldName}
          value={fieldValue}
          onChange={(name: string, value: any) => {
            handleEdit(shipmentId, 'shipments', {
              [fieldName]: value,
            });
            setIsEditing(false);
          }}
          onHide={() => setIsEditing(false)}
        />
      ) : (
        <>
          <Typography.Text>{fieldValue}</Typography.Text>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => setIsEditing(true)}
          />
          {fieldValue && <CopyButton value={fieldValue} />}
        </>
      )}
    </div>
  );
};

export default ShipmentUpdateableColumn;
