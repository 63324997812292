import { useApp } from '@/utils/useapp';
import { message, Form, Modal, Space, Button, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';
import EmailForm from '@/components/EmailForm';
import { serialize } from 'object-to-formdata';
import { get, uniq } from 'lodash';
import actionUtil, {
  TFtlShipmentAction,
  TSendEmailModal,
  DOC_TARGET_MAP,
} from '../../Interfaces/ActionInterface';
import useAction from '../useAction';

const SendFtlShipmentEmailModal: React.FC<
  TSendEmailModal & TFtlShipmentAction
> = ({ task, admin, targetModel, target, open, onDone, onClose }) => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [emailForm] = useForm();
  const [docs, setDocs] = useState<any[]>([]);
  const { sendEmail } = useAction({ target, targetModel });

  const handleOnClose = () => {
    emailForm.resetFields();
    onClose();
  };

  const handleSendEmail = async () => {
    try {
      const data = await emailForm.validateFields();

      const config = {
        data: serialize(
          {
            ...data,
            task_id: task.id,
            action: actionUtil.SEND_CONFIRMATION,
          },
          { indices: true, nullsAsUndefineds: true },
        ),
        requestType: 'form',
      };

      await sendEmail(config, setLoading, onDone);

      emailForm.resetFields();
    } catch (err: any) {
      err.data && message.error(err.data.message || err.data.error);
    }
  };

  const fetchDocuments = async () => {
    if (!targetModel?.id || !target || !DOC_TARGET_MAP[target]) {
      return;
    }

    const docTarget = DOC_TARGET_MAP[target];

    try {
      const docs = await app.service.get(
        `documents/${docTarget}/${targetModel.id}`,
      );

      const resData = [...docs.data];

      setDocs(
        resData.map((d: any) => {
          return {
            label: `[${get(d, 'custom_properties.document_type', 'No Type')}]${
              d.file_name
            }`,
            value: d.id,
          };
        }),
      );
    } catch (err: any) {
      setDocs([]);
    }
  };

  const initForm = () => {
    const sendToList = [''];

    const uid = get(targetModel, 'uid', '');
    const customerRef = get(targetModel, 'customer_reference_number', '');

    let subject = `Rate Confirmation for ${uid}`;
    customerRef && (subject += ` - ${customerRef}`);

    let shipmentInfo = uid;
    customerRef && (shipmentInfo += ` - ${customerRef}`);

    emailForm.setFieldsValue({
      sendToList,
      ccList: [],
      subject,
      body: `Hi Team,

Please find the attached Rate Confirmation for shipment [${shipmentInfo}] at your earliest convenience and kindly review the attachment to confirm its accuracy. If you have any questions or require further assistance, feel free to reach out to us.`,
    });
  };

  useEffect(() => {
    open && initForm();
  }, [open, targetModel]);

  React.useEffect(() => {
    open && target && targetModel?.id && fetchDocuments();
  }, [open, targetModel, target]);

  return (
    <>
      <Modal
        title="FTL - Send Rate Confirmation"
        width={'75%'}
        onCancel={handleOnClose}
        destroyOnClose={true}
        open={open}
        footer={
          <>
            <Space>
              <Button disabled={loading} onClick={handleOnClose}>
                Cancel
              </Button>
              <Button
                type="primary"
                disabled={loading}
                onClick={handleSendEmail}
              >
                Send
              </Button>
            </Space>
          </>
        }
      >
        <div style={{ maxHeight: '70vh', overflowY: 'scroll' }}>
          <Spin spinning={loading}>
            <Form form={emailForm} layout="vertical">
              <EmailForm
                form={emailForm}
                docs={docs}
                has={{
                  from: false,
                  attachment: true,
                  showSelectAttachments: true,
                }}
              />
            </Form>
          </Spin>
        </div>
      </Modal>
    </>
  );
};

export default SendFtlShipmentEmailModal;
