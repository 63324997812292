import { useApp } from '@/utils/useapp';
import { Button, Form, Modal } from 'antd';
import { FormInstance } from 'antd/es/form';
import { useWatch } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { BaseButtonProps } from 'antd/lib/button/button';
import { auth } from '@/stores';
import { InfoCircleOutlined } from '@ant-design/icons';
import { TInvoiceCharge } from '@/types';
import { Documents } from '@/components/Upload/Documents';

type TProps = {
  form: FormInstance;
  disabled: boolean;

  row: TInvoiceCharge;
  containerId: number;
  index: number;
};

export const ChargeExplanation: React.FC<TProps & BaseButtonProps> = ({
  form,
  disabled = false,
  row,
  index,
  containerId,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button type="text" size="small" onClick={() => setOpen(!open)}>
        <Form.Item
          name={['charges', index, 'has_explanation']}
          initialValue={false}
          noStyle
        />
        <span
          className={
            row.has_explanation || row.reason ? 'text-primary' : 'text-gray'
          }>
          <InfoCircleOutlined />
        </span>
      </Button>
      {
        <Modal
          title="Explanation"
          open={open}
          onCancel={() => setOpen(false)}
          footer={
            <Button type="default" onClick={() => setOpen(false)}>
              Close
            </Button>
          }>
          <Form.Item
            label="Reason"
            name={['charges', index, 'reason']}
            rules={[{ required: false }]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}>
            <TextArea disabled={disabled} rows={3} placeholder="Enter Reason" />
          </Form.Item>

          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) =>
              getFieldValue('id') && (
                <Documents
                  disabled={disabled}
                  title="Documents"
                  target="order_invoice"
                  options={{
                    billing_code: row.code,
                    container_id: containerId,
                  }}
                  targetId={getFieldValue('id')}
                />
              )
            }
          </Form.Item>
        </Modal>
      }
    </>
  );
};
