import {
  DimensionUnit,
  // imperialFreightClass,
  fetchItemsFreightClass,
  freightClassRequiredFields,
  kgToLb,
  lbToKg,
  QuoteItem,
  WeightUnit,
  ft3ToM3,
  in3ToFt3,
  cmToIn,
} from '@/utils/freight';
import { LoadingOutlined } from '@ant-design/icons';
import { useDebounceEffect } from 'ahooks';
import { Col, message, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import _ from 'lodash';
import { useState } from 'react';

export interface AggregatorProps {
  items: QuoteItem[];
}

const Aggregator = ({ items }: AggregatorProps) => {
  const totalUnits = _.sum(items?.map((item: QuoteItem) => item?.units));

  const totalImperialWeight = _.sum(
    items?.map((item: QuoteItem) => {
      if (item?.weight_unit === WeightUnit.KG) {
        return kgToLb(item?.total_weight, true);
      }
      return item?.total_weight;
    }),
  );
  const totalMetricWeight = lbToKg(totalImperialWeight, true);

  const totalImperialVolume = _.sum(
    items?.map((item: QuoteItem) => {
      if (item?.dimension_unit === DimensionUnit.CM) {
        const volume =
          cmToIn(item?.length, true) *
          cmToIn(item?.width, true) *
          cmToIn(item?.height, true) *
          item?.units;
        return in3ToFt3(volume);
      }
      return in3ToFt3(item?.length * item?.width * item?.height * item?.units);
    }),
  );
  const totalMetricVolume = ft3ToM3(totalImperialVolume);

  const totalCartonQty = _.sum(items?.map((item: QuoteItem) => item?.pieces));

  const totalDensity =
    totalImperialVolume === 0 ? 0 : totalImperialWeight / totalImperialVolume;

  // const classId = imperialFreightClass(
  //   totalImperialWeight,
  //   totalImperialVolume,
  // );
  const [isLoadingClassId, setIsLoadingClassId] = useState(false);
  const [classId, setClassId] = useState();
  useDebounceEffect(
    () => {
      if (
        items?.length > 0 &&
        items.every((item) =>
          freightClassRequiredFields.every((field) => !!item[field]),
        )
      ) {
        setIsLoadingClassId(true);
        fetchItemsFreightClass(items)
          .then((response) => {
            setClassId(response.class?.toString());
          })
          .catch((error) => {
            console.error(error);
            message.error('Failed to fetch freight class');
          })
          .finally(() => {
            setIsLoadingClassId(false);
          });
      } else {
        setClassId(undefined);
      }
    },
    [items],
    { wait: 500 },
  );

  return (
    <>
      <Row>
        <Col span={8}>
          <Text>Handling Unit Count {totalUnits}</Text>
        </Col>
        <Col span={8}>
          <Text>
            Weight {totalImperialWeight?.toFixed(2) ?? '-'} lbs /{' '}
            {totalMetricWeight?.toFixed(2) ?? '-'} kg
          </Text>
        </Col>
        <Col span={8}>
          <Text>
            Volume {totalImperialVolume?.toFixed(2) ?? '-'} ft<sup>3</sup> /{' '}
            {totalMetricVolume?.toFixed(2) ?? '-'} m<sup>3</sup>
          </Text>
        </Col>

        <Col span={8}>
          <Text>Total carton Qty {totalCartonQty ?? '-'}</Text>
        </Col>
        <Col span={8}>Density {totalDensity?.toFixed(2) ?? '-'} PCF</Col>
        <Col span={8}>
          Class ID {classId ?? '-'}
          {isLoadingClassId && <LoadingOutlined style={{ marginLeft: 8 }} />}
        </Col>
      </Row>
    </>
  );
};

export default Aggregator;
