import { useApp } from '@/utils/useapp';
import { Col, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Header } from '@/components/CommonHeader';
import { useForm } from 'antd/lib/form/Form';
import { Task } from './components/Task';
import styles from 'res/css/ui.scss';

import Calendar from './components/Calendar';
import {
  SubTabs as SubTabKeys,
  SubTabShowCount,
  SubTabShowDate,
  HasTableSubTabs,
} from './components/ShipmentSubTab/data';
import { OPdashboardTabModelType } from '@/Interfaces/OPdashboardTab/OPdashboardTabType';
import { OPdashboardTabInterface } from '@/Interfaces/OPdashboardTab/OPdashboardTabInterface';
import useOpdashboardTab from '@/components/OpdashboardTab/useOpdashboardTab';
import { NavTabs } from '@/components/OpdashboardTab/NavTabs';
import LTLHeaderSearch, {
  SEARCHQUERYTYPES,
} from '@/pages/truckload/ltl/shipments/HeaderSearch';
import ShipmentSubTab from './components/ShipmentSubTab';
import OpdashboardTab from '@/components/OpdashboardTab';
import { OperationType } from '@/stores/opDashboardStore';
import { OPdashboardSubTabInterface } from '@/Interfaces/OPdashboardTab/OPdashboardSubTabInterface';

export interface SubTabInterface {
  opDashboardTab: OPdashboardTabInterface;
  refresh: boolean;
  setRefresh: (refresh: boolean) => void;
  goToOperationTable: (operation_ids: Array<number>) => void;
}

const Index = () => {
  const app = useApp();
  const { updateOpdashboardTab } = useOpdashboardTab();
  const [loading, setLoading] = useState(false);

  const [selectedDates, setSelectedDates] = useState<Record<string, string>>();
  const [searchQuery, setSearchQuery] = useState();
  const [searchQueryType, setSearchQueryType] = useState(
    SEARCHQUERYTYPES[0].value,
  );
  const [enableSearch, setEnableSearch] = useState(false);
  const [refreshSearch, setRefreshSearch] = useState(false);
  const [closeRefresh, setCloseRefresh] = useState(false);

  const [subTabs, setSubTabs] = useState<OPdashboardSubTabInterface[]>([]);

  const [progressTabCounts, setProgressTabCounts] = React.useState<
    Record<
      string,
      | number
      | {
          actual: number;
          estimated: number;
        }
    >
  >({});

  const [
    activeOpdashboardTab,
    setActiveOpdashboardTab,
  ] = useState<OPdashboardTabInterface>();

  const [
    activeSubTab,
    setActiveSubTab,
  ] = useState<OPdashboardSubTabInterface>();

  const [refresh, setRefresh] = useState(false);

  const goToOperationTable = async (operation_ids: Array<number>) => {
    app.store.opDashboard.setOperationIds(OperationType.LTL, operation_ids);
    window.open('/truckload/ltl/shipments', '_blank');
  };

  const handleChangeSubTab = (key: any) => {
    const tab = subTabs.find((tab) => tab.id == key);
    setActiveSubTab(tab);
  };

  const updateCustomProperties = async (properties: any) => {
    if (!activeOpdashboardTab?.id) {
      return;
    }

    const _properties = activeOpdashboardTab.custom_properties || {};

    _properties[activeSubTab?.id] = {
      ..._properties[activeSubTab?.id],
      ...properties,
    };

    const newOpdashboardTab = await updateOpdashboardTab(
      'custom_properties',
      _properties,
      activeOpdashboardTab,
    );

    setActiveOpdashboardTab(newOpdashboardTab);
  };

  useEffect(() => {
    fetchSubTabs(activeOpdashboardTab);
  }, [activeOpdashboardTab?.id]);

  const fetchSubTabs = async (
    opdashboardTab: OPdashboardTabInterface,
    refresh = false,
  ) => {
    if (!opdashboardTab?.id) {
      return;
    }

    const resp = await app.service.get(
      `tl/opdashboard/${opdashboardTab?.id}/subTabs`,
    );
    setSubTabs(resp.data);

    if (!refresh) {
      setActiveSubTab(resp.data[0]);
    }
  };

  const refreshSubTabCount = async (
    subTab: OPdashboardSubTabInterface,
    selectedDate?: string,
  ) => {
    if (!activeOpdashboardTab?.id || !subTab?.id) {
      return;
    }

    const resp = await app.service.get(
      `tl/opdashboard/${activeOpdashboardTab?.id}/${subTab?.id}/count`,
      {
        params: {
          opdashboard_date: selectedDate,
        },
      },
    );

    const collection = subTabs.map((item) => {
      if (item.id == subTab?.id) {
        return {
          ...item,
          count: resp.data,
        };
      }
      return item;
    });
    setSubTabs(collection);
  };

  const handleChangeDate = (date: string) => {
    const _dates = {
      ...selectedDates,
      [activeSubTab?.id]: date,
    };

    setSelectedDates(_dates);

    refreshSubTabCount(activeSubTab, date);
  };

  const handleChangeTab = (tab: OPdashboardTabInterface) => {
    setActiveOpdashboardTab(tab);
    fetchSubTabs(tab);
  };

  const toRefresh = () => {
    setRefresh(true);
    fetchSubTabs(activeOpdashboardTab, true);
  };

  const handleSearch = (value: string, queryType: string) => {
    console.log(value, queryType);
    setSearchQuery(value);

    setSearchQueryType(queryType);
    if (value) {
      setEnableSearch(true);
    } else {
      setEnableSearch(false);
    }

    setRefreshSearch(true);
  };

  return (
    <div className={styles.main}>
      <Header
        title="OP Dashboard"
        rightElement={
          <div style={{ width: '30%' }}>
            <LTLHeaderSearch disabled={loading} onSearch={handleSearch} />
          </div>

          // <Input.Search
          //   style={{ width: '40%' }}
          //   placeholder="Search by LS#..."
          //   allowClear
          //   disabled={loading}
          //   enterButton="Search"
          //   value={searchQuery}
          //   onChange={(e) => {
          //     setSearchQuery(e.target.value);
          //     if (!e.target.value) {
          //       setEnableSearch(false);
          //       setRefreshSearch(false);
          //     }
          //   }}
          //   onSearch={() => {
          //     setEnableSearch(true);
          //     setRefreshSearch(true);
          //   }}
          // />
        }
      />
      {enableSearch ? (
        <ShipmentSubTab
          key={activeSubTab?.id}
          opDashboardTab={activeOpdashboardTab}
          subTab={activeSubTab}
          searchQuery={searchQuery}
          searchQueryType={searchQueryType}
          updateCustomProperties={updateCustomProperties}
          selectedDate={
            (selectedDates && selectedDates[activeSubTab?.id]) || null
          }
          refresh={refresh}
          setRefresh={setRefresh}
          goToOperationTable={goToOperationTable}
        />
      ) : (
        <div
          style={{
            padding: '0px 10px',
          }}
        >
          <OpdashboardTab
            modelType={OPdashboardTabModelType.LtlShipment}
            defaultActiveTab={activeOpdashboardTab}
            toSetActiveTab={handleChangeTab}
            closeRefresh={closeRefresh}
            toRefresh={toRefresh}
            SubTabContent={
              <>
                <div>
                  <NavTabs
                    items={subTabs.map(
                      (subTab: OPdashboardSubTabInterface) => ({
                        ...subTab,
                        showCount: SubTabShowCount[
                          subTab.id as keyof typeof SubTabShowCount
                        ]
                          ? true
                          : false,
                        showDate: SubTabShowDate[
                          subTab.id as keyof typeof SubTabShowDate
                        ]
                          ? true
                          : false,
                      }),
                    )}
                    activeKey={activeSubTab?.id}
                    onChange={handleChangeSubTab}
                    onChangeDate={handleChangeDate}
                  />

                  {activeSubTab?.id == SubTabKeys.Task && (
                    <Row>
                      <Col span={8}>
                        <Task
                          isTaskTab={activeSubTab?.id == SubTabKeys.Task}
                          opDashboardTab={activeOpdashboardTab}
                          refresh={refresh}
                          setRefresh={setRefresh}
                          goToOperationTable={goToOperationTable}
                        />
                      </Col>
                      <Col span={7} className="ml-lg"></Col>
                      <Col span={7} className="ml-lg"></Col>
                    </Row>
                  )}
                  {activeSubTab?.id == SubTabKeys.Calendar && (
                    <Calendar
                      isCalendarTab={activeSubTab.id == SubTabKeys.Calendar}
                      opDashboardTab={activeOpdashboardTab}
                      refresh={refresh}
                      setRefresh={setRefresh}
                      goToOperationTable={goToOperationTable}
                    />
                  )}
                  {HasTableSubTabs.includes(activeSubTab?.id as SubTabKeys) && (
                    <ShipmentSubTab
                      key={activeSubTab?.id}
                      opDashboardTab={activeOpdashboardTab}
                      subTab={activeSubTab}
                      updateCustomProperties={updateCustomProperties}
                      selectedDate={
                        (selectedDates && selectedDates[activeSubTab?.id]) ||
                        null
                      }
                      refresh={refresh}
                      setRefresh={setRefresh}
                      goToOperationTable={goToOperationTable}
                    />
                  )}
                </div>
              </>
            }
          />
        </div>
      )}
    </div>
  );
};

export default Index;
