import { useApp } from '@/utils/useapp';
import { List, Space, Tag } from 'antd';
import { sumBy } from 'lodash';
import React, { ReactElement } from 'react';
import EnableAlgo from './EnableAlgo';

type TwoNumberArray = [number, number];
export type AlgoFormular = AlgoValue[];

interface AlgoValue {
  range: TwoNumberArray;
  intercept: number;
  coefficient: number;
}

interface AlgoCardPros {
  rateEngineConfigId: number;
  name: string;
  algoFormular: AlgoFormular;
  algoId?: number;
  footer?: string | ReactElement;
  rates?: Rate[];
  isActive?: boolean;
  showAlgoEnableBtn?: boolean;
  showStatisticalMeasure?: boolean;
}

interface Rate {
  name: string;
  value: TwoNumberArray;
}

export const toLines = (algo: AlgoFormular): any[] => {
  const lines: any[] = [];
  algo.forEach((al: AlgoValue) => {
    lines.push([
      [parseInt(al.range[0]), parseInt(toY(al.range[0], algo))],
      [parseInt(al.range[1]), parseInt(toY(al.range[1], algo))],
    ]);
  });

  return lines;
};

const formatAlgo = (algoValue: AlgoValue): string => {
  return `Y = ${algoValue.coefficient}X + ${algoValue.intercept} (${algoValue.range[0]} < X <= ${algoValue.range[1]})`;
};

const toY = (x: number, algo: AlgoFormular) => {
  const al = algo.find((al: AlgoValue) => {
    if (x === 0) {
      return al.range[0] === 0;
    }
    return al.range[0] < x && x <= al.range[1];
  });

  if (al) {
    return al.intercept + al.coefficient * parseFloat(x);
  } else {
    return NaN; // out of range
  }
};

const toRSquared = (algo: AlgoFormular, rates: Rate[]) => {
  if (!rates || !rates.length) {
    return '-';
  }

  let ssr = 0; // sum squared regression (SSR)
  let sst = 0; // total sum of squares (SST)

  const averageY =
    sumBy(rates, (rate: Rate) => parseFloat(rate.value[1])) / rates.length;

  rates.forEach((rate: Rate) => {
    ssr += Math.pow(toY(rate.value[0], algo) - rate.value[1], 2);
    sst += Math.pow(rate.value[1] - averageY, 2);
  });

  return (1 - ssr / sst).toFixed(2);
};

const toResidualStandarDeviation = (algo: Algo, rates: Rate[]) => {
  if (!rates || !rates.length) {
    return '-';
  }

  let residual = 0;

  rates.forEach((rate: Rate) => {
    residual += Math.pow(toY(rate.value[0], algo) - rate.value[1], 2);
  });

  return Math.sqrt(residual / rates.length).toFixed(2);
};

const AlgoCard: React.FC<AlgoCardPros> = ({
  rateEngineConfigId,
  algoId,
  algoFormular,
  name,
  rates,
  footer = false,
  isActive = false,
  showAlgoEnableBtn = false,
  showStatisticalMeasure = false,
}) => {
  const rSquare = React.useMemo(() => {
    if (showStatisticalMeasure) {
      return toRSquared(algoFormular, rates);
    } else {
      return '-';
    }
  }, [algoFormular, rates, showStatisticalMeasure]);

  const residualStandardDeviation = React.useMemo(() => {
    if (showStatisticalMeasure) {
      return toResidualStandarDeviation(algoFormular, rates);
    } else {
      return '-';
    }
  }, [algoFormular, rates]);

  return (
    <>
      <List
        header={
          <Space>
            <div>{name}</div>
            {isActive && <Tag color="processing">Enabled</Tag>}
            {!isActive && showAlgoEnableBtn && (
              <EnableAlgo
                algoId={algoId}
                rateEngineConfigId={rateEngineConfigId}
                algoFormular={algoFormular}
              />
            )}
          </Space>
        }
        footer={
          showStatisticalMeasure
            ? `R Squared: ${rSquare}, RMSD: ${residualStandardDeviation}`
            : footer
        }
        bordered
        size="small"
        dataSource={algoFormular}
        renderItem={(item: AlgoValue) => (
          <List.Item>{formatAlgo(item)}</List.Item>
        )}
      />
    </>
  );
};

export default AlgoCard;
