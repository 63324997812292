import { Typography, Space, Tooltip, Badge, message, Popover } from 'antd';
import React, { useMemo, useState } from 'react';
import OverflowText from '@/components/OverflowText';
import { red } from '@ant-design/colors';

import {
  TrademarkOutlined,
  StopOutlined,
  TeamOutlined,
  SendOutlined,
  GatewayOutlined,
  AuditOutlined,
  AmazonOutlined,
  WarningOutlined,
  BoldOutlined,
} from '@ant-design/icons';
import { get, has } from 'lodash';
import {
  OverweightIcon,
  ReeferIcon,
  ResidentialIcon,
  HazmatIcon,
} from '@/components/icons';
import { VendorVerified } from '@/pages/entity/vendors/components/VendorVerified';
import { TbHexagonLetterB } from 'react-icons/tb';
import VendorRating from '@/pages/dispatchs/components/VendorRating';
import { RatingTag } from './RatingTag';
import { TContainer } from '@/types';

interface IVendor {
  vendor: any;
  container?: TContainer;
  onSaved?: () => void;
  showVendorDetail?: (id: number) => void;
  score?: boolean;
}

export const VendorCell: React.FC<IVendor> = ({
  vendor,
  container,
  onSaved,
  showVendorDetail,
  score = false,
}) => {
  const isBlackList = get(vendor, 'blacklisted', false);
  const hasOwnChassis = get(vendor, 'has_own_chassis', false);
  const isEstablishedCredit = get(vendor, 'vendor_credit_id', false)
    ? true
    : false;

  const hasOverweightAbility = get(vendor, 'overweight_ability', false);

  const hasHazmatAbility = get(vendor, 'hazmat_ability', false);
  const hasFbaDelivery = get(vendor, 'fba_delivery', false);
  const hasResidentialAbility = get(vendor, 'residential_ability', false);
  const hasReeferAbility = get(vendor, 'reefer_ability', false);

  if (!vendor) {
    return null;
  }

  const toBuyRate = (vendor: any) => {
    window.open(`/rates/buyRates?vendorId=${vendor.id}`, '_blank');
  };

  return (
    <div>
      <div className="flex">
        {vendor.is_broker && (
          <Tooltip title="Broker">
            <div
              style={{
                fontWeight: 'bold',
                fontSize: '16px',
                position: 'relative',
                top: '-1px',
              }}
            >
              <TbHexagonLetterB />
            </div>
          </Tooltip>
        )}
        <OverflowText
          style={isBlackList ? { textDecoration: 'line-through' } : {}}
        >
          {vendor.name && showVendorDetail && (
            <Typography.Link
              onClick={() => showVendorDetail && showVendorDetail(vendor.id)}
            >
              {get(vendor, 'name')}
            </Typography.Link>
          )}
          {vendor.name && !showVendorDetail && <>{get(vendor, 'name')}</>}
        </OverflowText>
        {score && (
          <div>
            <Popover
              trigger="click"
              destroyTooltipOnHide
              content={
                <VendorRating
                  vendor_id={vendor?.id || 0}
                  score={vendor?.score || 'N/A'}
                  container={container}
                  refreshContainer={() => onSaved && onSaved()}
                />
              }
            >
              <RatingTag rating={container?.vendor?.score || 0} />
            </Popover>
          </div>
        )}
      </div>
      <div className="mt-sm">
        <Space>
          <VendorVerified
            vendor={vendor}
            onSaved={() => onSaved && onSaved()}
          />
          {isBlackList && (
            <Tooltip
              title={`Reason: ${vendor?.blacklisted_reason}. Blacklisted  by ${vendor?.blacklisted_by} at ${vendor?.blacklisted_at}`}
            >
              <StopOutlined style={{ color: red.primary }} />
            </Tooltip>
          )}
          {!!get(vendor, 'sent_do_count') && (
            <div className={get(vendor, 'sent_do_count') && 'mr-md'}>
              <Tooltip title="DO Sent">
                <a
                  type="link"
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray"
                  href={`${window.location.origin}/dispatchs?link_vendor_id=${vendor?.id}`}
                >
                  <Badge
                    color="blue"
                    overflowCount={999}
                    count={get(vendor, 'sent_do_count', '')}
                    size="small"
                    offset={[5, -2]}
                  >
                    <SendOutlined />
                  </Badge>
                </a>
              </Tooltip>
            </div>
          )}
          <div className={get(vendor, 'driver_count') && 'mr-md'}>
            <Tooltip title="Driver Count">
              <Badge
                color="blue"
                overflowCount={999}
                count={get(vendor, 'driver_count', '')}
                size="small"
                offset={[5, -2]}
              >
                <TeamOutlined />
              </Badge>
            </Tooltip>
          </div>

          {hasOwnChassis && (
            <Tooltip title={get(vendor, 'chassis_description', '')}>
              <GatewayOutlined />
            </Tooltip>
          )}

          {isEstablishedCredit && (
            <Tooltip title="Established Credit">
              <AuditOutlined />
            </Tooltip>
          )}
          {hasOverweightAbility && (
            <Tooltip title="Overweight Ability">
              <span>
                <OverweightIcon />
              </span>
            </Tooltip>
          )}
          {hasHazmatAbility && (
            <Tooltip title="Hazmat Ability">
              <WarningOutlined />
            </Tooltip>
          )}
          {hasFbaDelivery && (
            <Tooltip title="FBA Delivery">
              <AmazonOutlined />
            </Tooltip>
          )}
          {hasResidentialAbility && (
            <Tooltip title="Residential Ability">
              <span>
                <ResidentialIcon />
              </span>
            </Tooltip>
          )}
          {hasReeferAbility && (
            <Tooltip title="Reefer Ability">
              <span>
                <ReeferIcon />
              </span>
            </Tooltip>
          )}
          <Tooltip title="Rate Count">
            <div
              onClick={() => toBuyRate(vendor)}
              className={get(vendor, 'rates_count') && 'mr-md'}
            >
              <Badge
                color="blue"
                overflowCount={999}
                count={get(vendor, 'rates_count', '')}
                size="small"
                offset={[5, -2]}
              >
                <TrademarkOutlined />
              </Badge>
            </div>
          </Tooltip>
        </Space>
      </div>
      {/* {isBlackList && hasOwnChassis && (
              <Space size={'small'} wrap>
                {isBlackList && <Tag color="red">Blacklisted</Tag>}
                {hasOwnChassis ? (
                  <Tag color="green">
                    Own Chassis
                    <Tooltip
                      placement="top"
                      title={get(record, 'vendor.chassis_description', '')}>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </Tag>
                ) : (
                  <></>
                )}
              </Space>
            )} */}
    </div>
  );
};
