import styles from 'res/css/ui.scss';
import {
  Button,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Space,
} from 'antd';
import { CitySelect } from '@/components/CitySelect';
import { IntermodalRegionSelect } from '@/components/IntermodalRegionSelect';
import { FacilityTypeSelect } from '@/components/FacilityTypeSelect';
import { VendorSelect } from '@/components/VendorSelect';
import moment from 'moment';
import React, { useState } from 'react';
import { useApp } from '@/utils/useapp';
import ContainerSizeSelect from '@/components/ContainerSizeSelect';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { QUOTE_STATUS_MAP } from './data';

export type TFilter = {
  loading: boolean;
  filter: FormInstance;
  onSearch: () => void;
  onChange: (values: any) => void;
};
export const Filter: React.FC<TFilter> = ({ loading, filter, onSearch }) => {
  const app = useApp();
  const handleSearch = () => {
    onSearch();
  };

  const handleFilterChange = (key: string, value: any) => {
    filter.setFieldsValue({ ...filter.getFieldsValue(), [key]: value });
  };

  const handleReset = () => {
    filter.resetFields();
    onSearch();
  };

  React.useEffect(() => {
    if (app.store.search.rateId) {
      filter.setFieldsValue({ id: app.store.search.rateId });
    }
  }, [app.store.search.rateId]);

  return (
    <div className={styles.filter}>
      <div
        style={{
          width: '100%',
        }}>
        <Form layout="vertical" form={filter} initialValues={{ valid: 'true' }}>
          <Row gutter={8}>
            <Col span={4}>
              <Form.Item label="ID#" name={'id'}>
                <Input
                  onChange={(e) => handleFilterChange('id', e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Status" name={'status'}>
                <Select>
                  {Object.entries(QUOTE_STATUS_MAP).map(
                    ([key, value]: [string, string]) => {
                      return (
                        <Select.Option key={key} value={key}>
                          {value}
                        </Select.Option>
                      );
                    },
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="user_id" label="Customer">
                <UserSyncSelect />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label=" ">
                <Space>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={loading}
                    onClick={handleSearch}>
                    Search
                  </Button>

                  <Button onClick={handleReset}>Clear All</Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};
