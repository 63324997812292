import { TAccount, TContainer } from '@/types';
import { useApp } from '@/utils/useapp';
import {
  Form,
  Row,
  Col,
  Input,
  Checkbox,
  Card,
  Select,
  message,
  Button,
  Space,
  Divider,
} from 'antd';
import { FormInstance } from 'antd/lib/form';
import { chain, get, trim, has, uniq, upperCase, includes, join } from 'lodash';
import { SaveOutlined } from '@ant-design/icons';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CONTACT_TYPES, CONTACT_TYPE_DISPATCH } from '@/components/ContactList';
import { BuyRateForm } from '@/pages/rates/buyRates/components/buyRateDrawer/BuyRateForm';

import { useForm } from 'antd/lib/form/Form';
import { default as OrderEmailForm } from '@/components/EmailForm';
import { DOCUMENT_FILE_TYPE_DG_FORM } from '@/pages/orders/components/Upload/data';
import { AppendText } from '@/components/AppendText';
import {
  LIVE_OR_DROP_MAP,
  LIVE_OR_DROP_DROP,
  LIVE_OR_DROP_LIVE,
  LIVE_OR_DROP_UNKNOW,
} from '@/pages/dispatchs/components/data';
import { isInIgnoreScacCodes } from '@/utils/oceanCarrier';

const ORDER_User_2_EMAIL = 'import@ouragl.com';
const Evergreen = 'EGLV';
const MSC = 'MEDU';

const CONTAINER_ATTRIBUTE_MAP = {
  is_dg: 'DG',
  is_soc: 'SOC',
  is_reefer: 'REEFER',
  is_multi_deliveries: 'Multiple Deliveries',
  transload: 'Transload',
};
type TContactEmailFormList = {
  contactableType: string;
  contactableId: number;
  emailable?: boolean;
  emails: string[];
  onChange: (emails: string[]) => void;
  role?: string;
};

type TContactForm = {
  contactableType: string;
  contactableId: number;
  onSaved: (data: any) => void;
  onHide: () => void;
  defaultEmail: string;
  defaultType: Array<string>;
};
const ContactForm: React.FC<TContactForm> = ({
  contactableType,
  contactableId,
  onSaved,
  onHide,
  defaultEmail,
  defaultType,
}) => {
  const [values, setValues] = useState({
    type: defaultType,
    name: '',
    email: defaultEmail,
  });

  const app = useApp();
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e: any) => {
    const { value, name, type, checked } = e.target;

    setValues({ ...values, [name]: type === 'checkbox' ? checked : value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const data = {
      ...values,
      contactable_type: contactableType,
      contactable_id: contactableId,
      emailable: true,
    };

    try {
      const resp = await app.service.post('contacts', { data });
      onSaved(resp.data);
      onHide();
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card title="New Contact">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item label="Type">
            <Select
              placeholder="Select a type"
              style={{ width: '100%' }}
              maxTagCount={'responsive'}
              mode="multiple"
              value={values.type || []}
              onChange={(value) =>
                handleInputChange({
                  target: {
                    value,
                    name: 'type',
                  },
                })
              }
            >
              {CONTACT_TYPES.map((type: string) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Name">
            <Input
              name="name"
              value={values.name || ''}
              onChange={handleInputChange}
              required
            />
          </Form.Item>
          <Form.Item label="Email">
            <Input
              name="email"
              type="email"
              value={values.email || ''}
              onChange={handleInputChange}
              required
            />
          </Form.Item>
          <Space>
            <Button type="primary" loading={loading} onClick={handleSubmit}>
              <SaveOutlined /> Save
            </Button>
            <Button onClick={onHide}>Close</Button>
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

const ContactEmailFormList: React.FC<TContactEmailFormList> = ({
  contactableType,
  contactableId,
  emailable = true,
  role,
  emails = [],
  socInstruction = '',
  onChange,
}) => {
  const app = useApp();

  const [contacts, setContacts] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [newEmailIndex, setNewEmailIndex] = useState<number>(-1);

  const fetchContacts = async () => {
    if (!contactableId) {
      return;
    }

    const query = {
      contactable_id: contactableId,
      contactable_type: contactableType,
      type: CONTACT_TYPE_DISPATCH,
      role,
    };

    setLoading(true);
    try {
      const resp = await app.service.get('contacts', { params: query });
      setContacts(resp.data);
      onChange(resp.data.map((c: any) => c.email));
    } catch (err: any) {
      message.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    contactableId && fetchContacts();
  }, [contactableId]);

  const handleRemove = (index: number) => {
    const newEmails = [...emails];
    newEmails.splice(index, 1);
    onChange(newEmails);
  };

  const handleChange = (value: string, index: number) => {
    const newEmails = [...emails];
    newEmails[index] = value;
    onChange(newEmails);
  };

  const handleShowForm = (index: number) => {
    setNewEmailIndex(index);
    setShowForm(true);
  };

  const handleHideForm = () => {
    setShowForm(false);
    setNewEmailIndex(-1);
  };

  const handleAddedContact = (contact: any) => {
    setContacts([...contacts, contact]);
    const newEmails = [...emails];
    newEmails[newEmailIndex] = contact.email;
    handleHideForm();
  };

  const emailExists = (email: string) => {
    return email && contacts.filter((c: any) => c.email === email).length > 0;
  };

  return (
    <>
      {emails.map((email, index) => (
        <Row className="mb-sm" key={index} gutter={4}>
          <Col span={19}>
            <Input.Group compact>
              <Input
                value={email}
                onChange={(e) => handleChange(e.target.value, index)}
                style={{ width: 'calc(100% - 50px)' }}
              />
              <Button
                onClick={() => handleShowForm(index)}
                disabled={!!emailExists(email)}
                icon={<SaveOutlined />}
              />
            </Input.Group>
          </Col>
          <Col md="auto">
            <Button danger ghost onClick={() => handleRemove(index)}>
              -
            </Button>
          </Col>
        </Row>
      ))}
      {showForm && (
        <ContactForm
          defaultEmail={emails[newEmailIndex]}
          defaultType={[CONTACT_TYPE_DISPATCH]}
          contactableType={contactableType}
          contactableId={contactableId}
          onSaved={handleAddedContact}
          onHide={handleHideForm}
        />
      )}
    </>
  );
};

export const EmailForm: React.FC<{
  form: FormInstance;
  order: any;
  deliveryOrderId: number;
  selectedCntrs?: any;
  admin: TAccount | null;
  socInstruction?: string;
}> = ({ form, order, deliveryOrderId, selectedCntrs, admin }) => {
  const app = useApp();
  const [docs, setDocs] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [rateForm] = useForm();
  const [deliveryOrder, setDeliveryOrder] = useState();

  const rateId = get(deliveryOrder, 'order.sell_rate.rate_id');

  const containers = get(deliveryOrder, 'containers', []);

  const fetchDeliveryOrder = async () => {
    if (!deliveryOrderId) {
      return;
    }
    setLoading(true);
    try {
      const resp = await app.service.get(`deliveryOrders/${deliveryOrderId}`);
      setDeliveryOrder(resp.data);
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setLoading(true);
    }
  };

  const fetchOrderDocuments = async () => {
    const orderId = get(deliveryOrder, 'order_id');
    if (!orderId) {
      return;
    }
    try {
      const docs = await app.service.get(
        `orders/${orderId}/getEmailDocuments`,
        {
          params: {
            containerNumbers:
              selectedCntrs?.map((c: any) => c.number).join(',') ?? undefined,
          },
        },
      );
      const resData = [...docs.data];

      const hasDG = containers?.find((c) => c.is_dg) ? true : false;

      if (hasDG) {
        const docIds: any = [];
        for (const i in containers) {
          if (!containers[i].is_dg) {
            continue;
          }

          resData.map((d) => {
            if (
              get(d, 'custom_properties.document_type') !=
              DOCUMENT_FILE_TYPE_DG_FORM
            ) {
              return false;
            }

            const contaienrIds = get(
              d,
              'custom_properties.container_ids',
              [],
            )?.map((id: any) => (id ? Number(id) : id));
            if (
              contaienrIds instanceof Array &&
              contaienrIds.includes(containers[i].id)
            ) {
              docIds.push(d.id);
            }
          });
        }

        if (docIds.length == 0) {
          message.error('DG form is not yet uploaded in the system');
        }

        form.setFieldsValue({
          documents: docIds,
        });
      }

      setDocs(
        resData.map((d: any) => {
          return {
            label: `[${get(d, 'custom_properties.document_type', 'No Type')}]${
              d.file_name
            }`,
            value: d.id,
          };
        }),
      );
    } catch (err: any) {
      setDocs([]);
    }
  };

  const fetchDocuments = async (rateId: number) => {
    if (!rateId) {
      return;
    }

    try {
      const resp = await app.service.get(`documents/rate/${rateId}`);
      setDocs(
        resp.data.map((d: any) => {
          return {
            label: `[${get(d, 'custom_properties.document_type', 'No Type')}]${
              d.file_name
            }`,
            value: d.id,
          };
        }),
      );
    } catch (err: any) {
      setDocs([]);
    }
  };

  const fetchRate = async (rateId: number) => {
    if (!rateId) {
      // message.error('No Sell Rate Input for this order');
      return;
    }
    try {
      const resp = await app.service.get(`rates/${rateId}`);
      rateForm.setFieldsValue({
        ...resp.data,
      });
    } catch (err: any) {
      message.error(err.data.message);
    }
  };

  const toDrayeasyBody = () => {
    console.log(get(deliveryOrder, 'order.mbl_number'));
    const defaultSignature = `
DrayEasy, Inc.
800 N Haven Ave #210,
Ontario, CA 91764
Phone: 1-818-826-2850
E-mail: op@drayeasy.com`;

    const signature = admin?.email_signature || defaultSignature;

    const mbl: string = get(deliveryOrder, 'order.mbl_number');

    return trim(`
Dear Dispatch,

Please find the attached D/O and confirm received, thanks.
${
  mbl?.startsWith('MATS')
    ? '\nPlease use port chassis to pickup this container since the container is on wheel after it is unloaded from the vessel.\n'
    : ''
}
Please do not contact warehouse and DrayEasy will contact the warehouse and send over the delivery appointment.

${
  containers.length > 0 &&
  containers.some((c: TContainer) => c.live_or_drop == LIVE_OR_DROP_LIVE)
    ? 'If the detention happens, the warehouse checkin and checkout time have to be in the signed POD.'
    : ''
}

MBL#: ${mbl}
${initFormCntrInfo()}

${
  containers.some((c: TContainer) => c.is_soc)
    ? `- Please be advised this is SOC container, please help <strong style="color:red;">confirm</strong> the below information.
- SOC Container Empty return Depot: ${deliveryOrder?.return_address || ''}
- RETURNING TO AN INCORRECT DEPOT MAY RESULT TO LOST OF EQUIPEMENT. PLEASE BE ADVISED YOUR COMPANY WILL BE REPONSSIBLE FOR TAKING CARE OF THE CHARGES CAUSED BY RETURNING THIS CONTAINER TO THE DIFFERENT DEPOT
- PLSEASE CONFIRM WITH SOC RETURN DEPOT PRIOR TO DELIVERY, ALL EXPENSES INCURRED WITHOUT PRIOR CONFIRMING WITH DEPOT WILL BE YOUR OWN EXPENSE
- ATTENTION: Steamship lines may give instructions for empty returns on SOC containers. Please ignore/ disregard ANY instructions other than ours.
- SOC Return instruction: ${
  deliveryOrder?.return_instruction ||
        'SOC return location is still pending, we will send you SOC return location ASAP once its confirmed'
}`
    : ''
}


Upon confirming this order, you acknowledge and agree to the following terms:

1. You accept responsibility for any damage to or theft of the cargo and the equipment that may occur during transit.
2. Inform us promptly of any extra fees incurred and retain the receipts. DrayEasy will cover only the fees confirmed by us.
3. Do not arrange delivery appointments directly with the warehouse or consignee unless instructed by us. DrayEasy will coordinate the delivery appointments.
4. In the event of detention, ensure that the warehouse check-in and checkout times are documented in the signed Proof of Delivery (POD).
5. Your driver must carefully inspect the condition of the container. If any damage is found, please notify us immediately and stop picking up the container until further instructions are provided.
6. POD will need to be provided as soon as the container is delivered.


${signature || ''}
`);
  };

  const toAglBody = () => {
    const mbl: string = get(deliveryOrder, 'order.mbl_number');

    return trim(`
Dear Dispatch,

RE: MBL#: ${mbl}
${initFormCntrInfo()}

Please see the attached D/O and confirm received, thanks.

${
  mbl?.startsWith('MATS')
    ? 'Please use port chassis to pickup this container since the container is on wheel after it is unloaded from the vessel.'
    : ''
}

Please do not contact warehouse and DrayEasy will contact the warehouse and send over the delivery appointment.

${
  containers.length > 0 &&
  containers.some((c: TContainer) => c.live_or_drop == LIVE_OR_DROP_LIVE)
    ? 'If the detention happens, the warehouse checkin and checkout time have to be in the signed POD.'
    : ''
}

${
  containers.some((c: TContainer) => c.is_soc)
    ? `- Please be advised this is SOC container, please help <strong style="color:red;">confirm</strong> the below information.
- SOC Container Empty return Depot: ${deliveryOrder.return_address || ''}
- RETURNING TO AN INCORRECT DEPOT MAY RESULT TO LOST OF EQUIPEMENT. PLEASE BE ADVISED YOUR COMPANY WILL BE REPONSSIBLE FOR TAKING CARE OF THE CHARGES CAUSED BY RETURNING THIS CONTAINER TO THE DIFFERENT DEPOT
- PLSEASE CONFIRM WITH SOC RETURN DEPOT PRIOR TO DELIVERY, ALL EXPENSES INCURRED WITHOUT PRIOR CONFIRMING WITH DEPOT WILL BE YOUR OWN EXPENSE
- ATTENTION: Steamship lines may give instructions for empty returns on SOC containers. Please ignore/ disregard ANY instructions other than ours.
- SOC Return instruction: ${
  deliveryOrder?.return_instruction ||
        'SOC return location is still pending, we will send you SOC return location ASAP once its confirmed'
}`
    : ''
}

American General Logistics, Inc.
800 N Haven Ave #210,
Ontario, CA 91764
(O) (626) 430-6566
(F) 1-888-722-9217
E-mail: import@ouragl.com

*American General Logistics, Inc will not be responsible for any detention, demurrage, per diem,
storage etc. causing by terminal operation, warehouse operation due to the spread of COVID-19.
          `);
  };

  const initFormCntrInfo = () => {
    let info = '';
    for (const i in containers) {
      info += `CNTR#: ${containers[i].number}  ${
        has(LIVE_OR_DROP_MAP, Number(containers[i].live_or_drop))
          ? upperCase(
            LIVE_OR_DROP_MAP[
              (containers[i]
                .live_or_drop as unknown) as keyof typeof LIVE_OR_DROP_MAP
            ],
          )
          : ''
      }  ${
        containers[i].is_dg ? '(This container includes Hazmat Cargos.)' : ''
      }
`;
    }

    const mbl = get(deliveryOrder, 'order.mbl_number');
    if (isInIgnoreScacCodes(mbl)) {
      info += `
Please kindly noted chassis fee is prepaid to SSL already. but you can decide to use your own chassis.`;
    }

    return info;
  };

  const buyRateInfo = useMemo(() => {
    const buyRate = get(deliveryOrder, 'buy_rate');
    if (!buyRate) {
      return '';
    }

    //     const possibleCharges = get(buyRate, 'vendor.possible_charges', []);

    //     let possibleChargeText = '';
    //     if (possibleCharges.length > 0) {
    //       possibleChargeText = `Possible Charges:
    // `;
    //       for (const i in possibleCharges) {
    //         possibleChargeText += `${possibleCharges[i].name}: ${possibleCharges[i].rate}
    // `;
    //       }
    //     }

    return `
The quote we have received from you for the lane is:

Base Rate: ${buyRate.base_rate}
Fuel Surcharge: ${buyRate.fuel_surcharge}`;
  }, [deliveryOrder]);

  const onClickDrayeasyBody = () => {
    form.setFieldValue('body', toDrayeasyBody());
  };
  const onClilckAGLBody = () => {
    form.setFieldValue('body', toAglBody());
  };

  const initForm = () => {
    const containers = chain(get(deliveryOrder, 'containers', []))
      .map((container) => container.number)
      .value()
      .join(',');
    const orderId = get(deliveryOrder, 'order.uid');
    const ir = get(deliveryOrder, 'final_port.name');
    const warehouseCity = get(deliveryOrder, 'warehouse.city.full_name');
    const zipcode = get(deliveryOrder, 'warehouse.zipcode', '');
    const mbl = get(deliveryOrder, 'mbl_number');

    const eta =
      get(deliveryOrder, 'final_port_eta') ||
      get(deliveryOrder, 'port_of_discharge_eta');

    const getCCList = (): Array<string> => {
      const ccList = [];

      if (get(deliveryOrder, 'order.user_id', 0) == 2) {
        ccList.push(ORDER_User_2_EMAIL);
      }

      admin?.email && ccList.push(admin.email);

      has(deliveryOrder, 'order.sales.email') &&
        ccList.push(get(deliveryOrder, 'order.sales.email'));

      has(deliveryOrder, 'order.sales_support.email') &&
        ccList.push(get(deliveryOrder, 'order.sales_support.email'));

      has(deliveryOrder, 'order.operator.email') &&
        ccList.push(get(deliveryOrder, 'order.operator.email'));

      has(deliveryOrder, 'order.operator_lead.email') &&
        ccList.push(get(deliveryOrder, 'order.operator_lead.email'));

      return uniq(ccList);
    };

    let containerAttributeSubject: any[] = [];
    for (const key in CONTAINER_ATTRIBUTE_MAP) {
      if (get(deliveryOrder, 'containers', [])?.find((c) => get(c, key))) {
        containerAttributeSubject.push(
          '[' +
            CONTAINER_ATTRIBUTE_MAP[
              (key as unknown) as keyof typeof CONTAINER_ATTRIBUTE_MAP
            ] +
            ']',
        );
      }
    }

    containerAttributeSubject = uniq(containerAttributeSubject)
      .filter((attr) => attr)
      .join(' ');

    let pathInfoSubject = '';
    ir && (pathInfoSubject += ` ${ir}`);
    warehouseCity && (pathInfoSubject += ` to ${warehouseCity}`);
    zipcode && (pathInfoSubject += ` ${zipcode}`);

    form.setFieldsValue({
      from: admin?.email,
      from_name: admin?.name,
      sendToList: [''],
      ccList: getCCList(),
      subject: trim(
        `${containerAttributeSubject} ${orderId} / ${containers} / ${pathInfoSubject} / ETA ${eta}`,
      ),
      body: toDrayeasyBody(),
      addText: true,
    });
  };

  useEffect(() => {
    if (!deliveryOrder) {
      return;
    }
    const rateId = get(deliveryOrder, 'order.sell_rate.rate_id');
    initForm();
    fetchDocuments(rateId);
    fetchRate(rateId);
    fetchOrderDocuments();
  }, [deliveryOrder]);

  useEffect(() => deliveryOrderId && fetchDeliveryOrder(), [deliveryOrderId]);

  const handleSendToListChange = (emails: string[]) => {
    form.setFieldsValue({
      sendToList: emails,
    });
  };

  const handleCcCustomer = () => {
    const emails = [];
    const deliveryOrder = form.getFieldValue('deliveryOrder');
    if (deliveryOrder && deliveryOrder.order) {
      deliveryOrder.order.contact_email &&
        emails.push(deliveryOrder.order.contact_email);
      if (deliveryOrder.order.user) {
        deliveryOrder.order.user.email &&
          emails.push(deliveryOrder.order.user.email);
      }
    }

    const ccList = form.getFieldValue('ccList');
    ccList.push(...emails);
    setFormEmailsUnique('ccList', ccList);
  };

  const setFormEmailsUnique = (target: string, emails: any) => {
    form.setFieldValue(target, uniq(emails));
  };

  return (
    <Form form={form} layout="vertical">
      <Row gutter={24}>
        <Col span={rateId ? 8 : 24}>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <OrderEmailForm
                form={form}
                docs={docs}
                sendToElement={
                  <ContactEmailFormList
                    contactableType="App\Models\Vendor"
                    contactableId={deliveryOrder?.vendor_id || 0}
                    emails={getFieldValue('sendToList') || []}
                    onChange={handleSendToListChange}
                    socInstruction={deliveryOrder?.return_reason || 0}
                  />
                }
                // sendToExtra={
                //   <Button
                //     size="small"
                //     type="primary"
                //     onClick={handleAddSendToList}>
                //     +
                //   </Button>
                // }
                ccExtra={
                  <Space>
                    {/* <Button
                      size="small"
                      type="primary"
                      onClick={handleAddCcList}>
                      +
                    </Button> */}
                    {/* <Button
                      size="small"
                      type="primary"
                      ghost
                      onClick={handleCcCustomer}>
                      CC Customer
                    </Button> */}
                  </Space>
                }
                bodyExtra={
                  <Space>
                    <Button
                      size="small"
                      type="link"
                      onClick={onClickDrayeasyBody}
                    >
                      Drayeasy Body
                    </Button>
                    <Button size="small" type="link" onClick={onClilckAGLBody}>
                      AGL Body
                    </Button>
                    <AppendText
                      dropdownLabel="Append Buy Rate"
                      appendText={buyRateInfo}
                      elementObj={document.getElementById('emailBody')}
                      setText={(text: string) =>
                        form.setFieldValue('body', text)
                      }
                    />
                  </Space>
                }
              />
            )}
          </Form.Item>
          <Form.Item
            labelAlign="right"
            valuePropName="checked"
            label="Attach Rate Memo"
            name="addText"
          >
            <Checkbox />
          </Form.Item>
        </Col>

        {rateId && (
          <Col span={16}>
            <h4>Internal Info</h4>
            <BuyRateForm form={rateForm} disabled id={rateId} />
          </Col>
        )}
      </Row>
    </Form>
  );
};
