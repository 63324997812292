import { CitySelect } from '@/components/CitySelect';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  Typography,
} from 'antd';
import React, { useState } from 'react';
import { PossibleCharges } from '@/components/PossibleCharges';
import { RateScenairoTable } from '@/pages/rates/rateSenarios/components/RateScenairoTable';
import { DrawerForm as RateScenairoForm } from '@/pages/rates/rateSenarios/components/DrawerForm';
import { FormInstance } from 'antd/lib/form';
import { useApp } from '@/utils/useapp';
import { IntermodalRegionSelect } from '@/components/IntermodalRegionSelect';
import { TPossibleCharge } from '@/types';
import { cache } from '@/stores';
import { CACHE_INTERMODALREGION_KEY } from '@/stores/cache';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { TIER_MAP, TIER_0, TIER_1, TIER_2, TIER_3, TIER_4 } from './data';

const RenderFormItems: React.FC<{
  name: string;
  labelKey: number;
}> = ({ name, labelKey }) => (
  <Col span={4} className="mr-lg">
    <Form.Item
      name={['tier_rate', name]}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      label={TIER_MAP[labelKey as unknown as keyof typeof TIER_MAP]}
      rules={[
        {
          required: false,
          message: 'Please input discount',
        },
      ]}>
      <InputNumber
        style={{ width: '100%' }}
        defaultValue={0}
        min={0}
        max={100}
        addonAfter="%"
      />
    </Form.Item>
  </Col>
);

export const TierRatesFormItems: React.FC<{
  form: FormInstance<any>;
}> = ({ form }) => {
  return (
    <div>
      <Typography.Title level={5}>Tier Rates</Typography.Title>
      <Row>
        <Form.Item name={['tier_rate', 'id']} noStyle hidden />
        <RenderFormItems name={'tier_zero'} labelKey={TIER_0} />
        <RenderFormItems name={'tier_one'} labelKey={TIER_1} />
        <RenderFormItems name={'tier_two'} labelKey={TIER_2} />
        <RenderFormItems name={'tier_three'} labelKey={TIER_3} />
        <RenderFormItems name={'tier_four'} labelKey={TIER_4} />
      </Row>
    </div>
  );
};
