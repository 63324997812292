import { action, computed, observable } from 'mobx';

export enum OperationType {
  LTL = 'ltl',
  FTL = 'ftl',
}

export class OpDashboardStore {
  @action setContainerIds(containerIds: Array<number>) {
    localStorage.setItem(
      'opdashboard_container_ids',
      JSON.stringify(containerIds),
    );
  }

  @action setOperationIds(operationType: string, operationIds: Array<number>) {
    localStorage.setItem(
      `opdashboard_${operationType}_ids`,
      JSON.stringify(operationIds),
    );
  }

  @action setOpdashboardTabId(opdashboardTabId: number) {
    localStorage.setItem('opdashboard_tab_id', opdashboardTabId.toString());
  }

  @action setTaskWarningType(taskWarningType: string | undefined) {
    if (!taskWarningType) {
      localStorage.removeItem('task_warning_type');
    } else {
      localStorage.setItem('task_warning_type', taskWarningType);
    }
  }

  @action setCurrentDate(currentDate: string) {
    localStorage.setItem('opdashboard_current_date', currentDate);
  }

  @action setCurrentProgressKey(progressKey: string) {
    localStorage.setItem('opdashboard_current_progress_key', progressKey);
  }

  @action setSubTabId(operationType: string, subTabId: number | string) {
    localStorage.setItem(
      `opdashboard_${operationType}_sub_tab_id`,
      subTabId.toString(),
    );
  }

  @action getFilterParams(): {
    container_ids: Array<number>;
    opdashboard_tab_id: number;
    task_warning_type: string;
    date: string;
    progress_key: string;
  } {
    return {
      container_ids: this.containerIds,
      date: this.currentDate,
      opdashboard_tab_id: parseInt(
        localStorage.getItem('opdashboard_tab_id') as string,
      ),
      task_warning_type: localStorage.getItem('task_warning_type') || '',
      progress_key: this.currentProgressKey,
    };
  }

  @action getFilterParamsForOperation(operationType: string) {
    return {
      ids: this.getOperationIds(operationType),
      sub_tab_id: this.getSubTabId(operationType),
      date: this.currentDate,
      opdashboard_tab_id: parseInt(
        localStorage.getItem('opdashboard_tab_id') as string,
      ),
    };
  }

  @action reset() {
    localStorage.removeItem('opdashboard_container_ids');
    localStorage.removeItem('opdashboard_current_date');
    localStorage.removeItem('opdashboard_current_progress_key');
    localStorage.removeItem('opdashboard_tab_id');
    localStorage.removeItem('task_warning_type');
  }

  @action resetOperation(operationType: string) {
    localStorage.removeItem('opdashboard_current_date');
    localStorage.removeItem(`opdashboard_${operationType}_ids`);
    localStorage.removeItem(`opdashboard_${operationType}_sub_tab_id`);
    localStorage.removeItem(`opdashboard_${operationType}_tab_id`);
  }

  @computed get containerIds() {
    return localStorage.getItem('opdashboard_container_ids')
      ? JSON.parse(localStorage.getItem('opdashboard_container_ids') as string)
      : [];
  }

  @action getOperationIds(operationType: string) {
    return localStorage.getItem(`opdashboard_${operationType}_ids`)
      ? JSON.parse(
          localStorage.getItem(`opdashboard_${operationType}_ids`) as string,
        )
      : [];
  }

  @action getSubTabId(operationType: string) {
    return (
      localStorage.getItem(`opdashboard_${operationType}_sub_tab_id`) ||
      undefined
    );
  }

  @computed get opdashboardTabId() {
    return localStorage.getItem('opdashboard_tab_id') || undefined;
  }

  @computed get taskWarningType() {
    return localStorage.getItem('task_warning_type') || undefined;
  }

  @computed get currentDate() {
    return localStorage.getItem('opdashboard_current_date') || '';
  }

  @computed get currentProgressKey() {
    return localStorage.getItem('opdashboard_current_progress_key') || '';
  }
}

const opDashboardStore = new OpDashboardStore();

export default opDashboardStore;
