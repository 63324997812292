import { useApp } from '@/utils/useapp';
import { Typography, Select, Input, Form, Row, Col } from 'antd';
import { FormInstance } from 'antd/es/form';
import React from 'react';
import { FINANCIAL_STATUS_MAP } from '../data';
import { VendorCreditSyncSelect } from '@/components/VendorCreditSyncSelect';
import { SelectedMcWarning } from './SelectedMcWarning';
import { useWatch } from 'antd/lib/form/Form';
interface IAccountingTab {
  form: FormInstance;
  handleChange: (event: any) => void;
}

export const AccountingTab: React.FC<IAccountingTab> = ({
  form,
  handleChange,
}) => {
  const vendorCredit = useWatch('vendor_credit', form);
  const truckerMc = useWatch('trucker_mc', form);

  return (
    <>
      <Row gutter={10}>
        <Col span={24}>
          <h3>Accounting:</h3>
          {/* <Form.Item name="established_credit" valuePropName="checked">
                  <Checkbox>Established Credit</Checkbox>
                </Form.Item> */}
          <Form.Item
            label="Currency"
            name="currency"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}>
            <Select
              style={{ width: '100%' }}
              allowClear
              placeholder="Select Currency">
              <Select.Option value={null}>{''}</Select.Option>
              <Select.Option value={'USD'}>USD</Select.Option>
              <Select.Option value={'CAD'}>CAD</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="financial_status"
            label="Financial Status">
            <Select placeholder="Please Select Financial Status">
              {Object.keys(FINANCIAL_STATUS_MAP).map((key: string) => {
                return (
                  <Select.Option key={key} value={key}>
                    {
                      FINANCIAL_STATUS_MAP[
                        key as unknown as keyof typeof FINANCIAL_STATUS_MAP
                      ]
                    }
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          {/* <Form.Item
            label="Established Credit"
            name="established_credit"
            valuePropName="checked">
            <Switch />
          </Form.Item> */}
          <Form.Item shouldUpdate noStyle>
            {
              ({ getFieldValue, setFieldsValue }) => (
                // getFieldValue('established_credit') && (
                <>
                  <Form.Item
                    label="Credit"
                    name="vendor_credit"
                    rules={[
                      { required: false, message: 'Please Select Credit' },
                    ]}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}>
                    <VendorCreditSyncSelect
                      vendorCreditId={getFieldValue('vendor_credit')?.id}
                      mc_number={getFieldValue('trucker_mc')}
                      onSelect={(vendorCredit: any) => {
                        setFieldsValue({
                          vendor_credit: vendorCredit,
                          terms: vendorCredit?.terms,
                        });
                      }}
                    />
                    <SelectedMcWarning
                      selectedMc={vendorCredit?.mc_number}
                      truckerMc={truckerMc}
                    />
                  </Form.Item>
                </>
              )
              // )
            }
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue, setFieldValue }) => (
              <Form.Item
                label="Terms"
                name="terms"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}>
                <Input
                  value={getFieldValue('terms')}
                  onChange={(e) => {
                    setFieldValue('terms', e.target.value);
                  }}
                  disabled={getFieldValue('vendor_credit_id')}
                  type={'number'}
                  min="0"
                  max="5"
                />
                {getFieldValue('vendor_credit_id') > 0 && (
                  <Typography.Text type="secondary">
                    Please setup the terms in Credit.
                  </Typography.Text>
                )}
              </Form.Item>
            )}
          </Form.Item>

          <Form.Item
            label="Billing Address"
            name="billing_address"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}>
            <Input.TextArea rows={6} placeholder="Enter Billing Address" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
