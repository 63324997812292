import React from 'react';
import { TablePaginationConfig } from 'antd';
import { TCollection } from '@/types';

const usePagination = (data: TCollection | undefined) => {
  const pagination = React.useMemo<TablePaginationConfig>(() => {
    return {
      showTotal: (total: number) => `Total: ${total}`,
      showSizeChanger: true,
      total: data?.meta.total,
      pageSize: data?.meta.per_page,
      current: data?.meta.current_page,
      position: ['bottomCenter'],
      pageSizeOptions: [10, 20, 50, 100, 200, 500],
    };
  }, [data]);

  return pagination;
};

export default usePagination;
