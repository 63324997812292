import { Button, Typography } from 'antd';
import { ShipmentInterface } from '../Interfaces/ShipmentInterface';
import { CopyButton } from '@/components/CopyButton';
import { useApp } from '@/utils/useapp';
import { useState } from 'react';
import { EditableText } from '@/components/Editable';
import { EditOutlined } from '@ant-design/icons';
import { copyToClipboard } from '@/utils/clipboard';

const ShipmentPronumberColumn: React.FC<{
  shipment: ShipmentInterface;
  handleEdit: (id: number, type: string, data: any) => void;
}> = ({ shipment, handleEdit }) => {
  const app = useApp();
  const [isEditing, setIsEditing] = useState(false);

  const handleClick = async () => {
    if (!shipment.carrier_id || !shipment.pro_number) {
      return;
    }

    copyToClipboard(shipment.pro_number, 'Failed to copy pro number:');

    try {
      const resp = await app.service.get(
        `tl/ltl/carriers/${shipment.carrier_id}`,
      );
      const carrier = resp.data;
      if (!carrier.quick_link) {
        return;
      }

      const url =
        carrier.quick_link.includes('$') && shipment.pro_number
          ? carrier.quick_link.replace('$', shipment.pro_number)
          : carrier.quick_link;

      window.open(url, '_blank');
    } catch (e) {
      console.error('Failed to open pro number:', e);
    }
  };
  return (
    <div>
      {/* {isEditing ? (
        <EditableText
          autoFocus
          type="string"
          name="pro_number"
          value={shipment.pro_number}
          onChange={(name: string, value: any) => {
            handleEdit(shipment.id, 'shipments', {
              pro_number: value,
            });
            setIsEditing(false);
          }}
          onHide={() => setIsEditing(false)}
        />
      ) : (
        <> */}
      {/* <Typography.Link onClick={handleClick}>
            {shipment.pro_number}
          </Typography.Link> */}
      <Typography.Text>{shipment.pro_number}</Typography.Text>
      {/* <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => setIsEditing(true)}
          /> */}
      {shipment.pro_number && <CopyButton value={shipment.pro_number} />}
      {/* </>
      )} */}
    </div>
  );
};

export default ShipmentPronumberColumn;
