import { Form, Row, Col, Input, DatePicker, FormInstance } from 'antd';
// import { FormInstance } from 'antd/lib/form';
import moment from 'moment';
import { Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { WarehouseSelect } from '@/components/WarehouseSelect';
import { useState } from 'react';

type TNames = {
  warehouse: any;
  delivery_address: any;
  delivery_at: any;
  delivery_reference_number: any;
};

export const WarehouseFormItem: React.FC<{
  form: FormInstance;
  names?: TNames;
}> = ({
  form,
  names = {
    warehouse: 'warehouse',
    delivery_address: 'delivery_address',
    delivery_at: 'delivery_at',
    delivery_reference_number: 'delivery_reference_number',
  },
}) => {
  return (
    <>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue, setFieldsValue }) => (
          <Form.Item
            label="Warehouse"
            name={names.warehouse}
            rules={[
              {
                required: true,
                message: 'Warehouse is required',
                validator: (_, value) => {
                  if (getFieldValue(names.warehouse)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject('Warehouse is required');
                  }
                },
              },
            ]}>
            <WarehouseSelect
              forbidToModfiySpecifyField={true}
              selected={getFieldValue(names.warehouse)}
              onSelect={(warehouse: any) => {
                form.setFieldValue(names.warehouse, warehouse);
                form.setFieldValue(
                  names.delivery_address,
                  warehouse?.delivery_order_address || '',
                );
              }}
              editable={true}
            />
          </Form.Item>
        )}
      </Form.Item>

      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue, setFieldsValue }) => (
          <>
            <Form.Item name={names.delivery_address} noStyle hidden />
            <Form.Item
              label="Address"
              rules={[{ required: true, message: 'Address is required' }]}>
              <TextArea
                value={getFieldValue(names.delivery_address)}
                onChange={(e) => console.log('e', e)}
                rows={4}
                placeholder="Enter delivery address"
              />
            </Form.Item>
          </>
        )}
      </Form.Item>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Ref.No" name={names.delivery_reference_number}>
            <Input placeholder="Reference Number" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <>
                <Form.Item name={names.delivery_at} hidden noStyle />

                <Form.Item label="Pickup Date">
                  <DatePicker
                    style={{ width: '100%' }}
                    placeholder="Date"
                    value={
                      getFieldValue(names.delivery_at)
                        ? moment(getFieldValue(names.delivery_at))
                        : null
                    }
                    onChange={(date, dateString) => {
                      form.setFieldValue(names.delivery_at, dateString);
                    }}
                  />
                </Form.Item>
              </>
            )}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
