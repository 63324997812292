import React from 'react';
import { Form, FormInstance, Input, InputNumber, Select, Button } from 'antd';
import { TYPE_MAP, TYPE_REGULAR } from '../data';
import { CNTR_SIZE_MAP } from '@/components/PossibleCharges';

interface Props {
  form: FormInstance;
}

export const ChassisTypeForm: React.FC<Props> = ({ form }) => {
  return (
    <Form layout="vertical" form={form} initialValues={{ type: TYPE_REGULAR }}>
      <Form.Item name="id" noStyle>
        <Input hidden />
      </Form.Item>
      <Form.Item label="Name" name="name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Type" name="type" rules={[{ required: false }]}>
        <Select>
          {Object.keys(TYPE_MAP).map((key) => (
            <Select.Option key={key} value={key}>
              {TYPE_MAP[key as unknown as keyof typeof TYPE_MAP]}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="CNTR Size"
        name="cntr_size"
        rules={[{ required: false }]}>
        <Select mode="multiple">
          {Object.keys(CNTR_SIZE_MAP).map((key) => (
            <Select.Option key={+key} value={+key}>
              {CNTR_SIZE_MAP[key as unknown as keyof typeof CNTR_SIZE_MAP]}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};
