import { FormInstance } from 'antd/es/form';
import React from 'react';
import { Documents } from '@/components/Upload/Documents';

interface ILogoTab {
  id: number | null;
  form: FormInstance;
  handleChange: (event: any) => void;
}

export const LogoTab: React.FC<ILogoTab> = ({ id }) => {
  return (
    <>
      {id && (
        <>
          <div>
            <Documents title="Logo" target="vendor_logo" targetId={id} />
          </div>
        </>
      )}
    </>
  );
};
