import { useState } from 'react';
import { Button, message } from 'antd';
import { TLtlShipmentAction } from '../../Interfaces/ActionInterface';
import actionUtils from '../../Interfaces/ActionInterface';

const LtlShipmentCreateBOL: React.FC<TLtlShipmentAction> = ({
  task,
  targetModel: ltlShipment,
  onDone,
  onSaveOperation,
}) => {
  const [loading, setLoading] = useState(false);

  const handleCreateBOL = async () => {
    try {
      onSaveOperation(
        task,
        actionUtils.CREAT_BOL,
        undefined,
        undefined,
        (loading: boolean) => {
          setLoading(loading);
        },
        onDone,
      );
    } catch (err: any) {
      message.error(err.data?.message || 'Failed to create BOL');
    }
  };

  return (
    <>
      <Button
        disabled={task?.disabled || loading}
        size="small"
        onClick={handleCreateBOL}
      >
        Create BOL
      </Button>
    </>
  );
};

export default LtlShipmentCreateBOL;
