import { Popover } from 'antd';
import React, { useMemo } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  TTarget,
  TARGET_TYPE_LTL_SHIPMENT,
  TARGET_TYPE_LTL_CONTAINERS,
} from '../Interfaces/ActionInterface';

const TaskTitilePopover: React.FC<TTarget> = ({ target }) => {
  const PopoverContent = useMemo(() => {
    switch (target) {
      case TARGET_TYPE_LTL_SHIPMENT:
        return <LtlShipmentContent />;
      case TARGET_TYPE_LTL_CONTAINERS:
        return <ContainerContent />;
      default:
        return <></>;
    }
  }, [target]);

  return (
    <Popover content={PopoverContent} title="Instruction">
      Task <InfoCircleOutlined />
    </Popover>
  );
};

const ContainerContent = () => {
  <p>
    <strong> Task Yellow: must excute in sequence</strong>
    <br />
    <strong>Doc icon</strong>
    <ul>
      <li>
        Red: any of these are not active: Selling rate, buy rate, DO or MBL or
        AN
      </li>
      <li>
        Blue: all of these are active: Selling rate, buy rate, DO or MBL or AN
      </li>
      <li>Green: all tasks active</li>
    </ul>
    <strong>Car icon</strong>
    <ul>
      <li>Red: Remind trucker to delivery on the next day is not active</li>
      <li>Blue: Remind trucker to delivery on the next day is active</li>
      <li>Green: all tasks active</li>
    </ul>
    <strong>WHS icon</strong>
    <ul>
      <li>Red: Remind warehouse to deliver on the next day is not active</li>
      <li>Blue: Remind warehouse to deliver on the next day is active</li>
      <li>Green: all tasks active</li>
    </ul>
  </p>;
};

const LtlShipmentContent = () => {
  return (
    <p>
      <strong> Task Yellow: must excute in sequence</strong>
      <br />
      <strong>Doc icon</strong>
      <ul>
        <li>
          Red: any of these are not active: Selling rate, buy rate, DO or MBL or
          AN
        </li>
        <li>
          Blue: all of these are active: Selling rate, buy rate, DO or MBL or AN
        </li>
        <li>Green: all tasks active</li>
      </ul>
      <strong>Car icon</strong>
      <ul>
        <li>Red: Remind trucker to delivery on the next day is not active</li>
        <li>Blue: Remind trucker to delivery on the next day is active</li>
        <li>Green: all tasks active</li>
      </ul>
      <strong>WHS icon</strong>
      <ul>
        <li>Red: Remind warehouse to deliver on the next day is not active</li>
        <li>Blue: Remind warehouse to deliver on the next day is active</li>
        <li>Green: all tasks active</li>
      </ul>
    </p>
  );
};

export default TaskTitilePopover;
