import { useApp } from '@/utils/useapp';
import {
  Button,
  Divider,
  Form,
  message,
  Popover,
  Rate,
  Row,
  Space,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { FC, useEffect, useMemo, useState } from 'react';
import { Skeleton } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Input from 'antd/lib/input/Input';
import { ModelType } from '../Interfaces/ModelType';
import {
  getTarget,
  ResponseType,
  RESPONSIBLE_TARGET_CLASS,
} from '../Interfaces/RepsonseType';
import { RatingTag } from '@/components/RatingTag';
import { QuestionInterface } from '../Interfaces/QuestionInterface';
import { InfoCircleOutlined } from '@ant-design/icons';
import useRating from '../useRating';
import _, { isNumber } from 'lodash';
import { RatingInterface } from '../Interfaces/RatingInterface';

const RatingForm: FC<
  ModelType &
    ResponseType & {
      ratingId?: number;
      onSumbit: (values: any) => void;
      autoRating?: any;
      allowHalf?: boolean;
    }
> = ({
  modelId,
  modelTarget,
  model,
  responsibleTarget,
  responsible,
  ratingId,
  onSumbit,
  autoRating,
  allowHalf = false,
}) => {
  const app = useApp();
  const { fetchQuestions } = useRating();
  const [data, setData] = useState<any>({});
  const [questions, setQuestions] = useState<QuestionInterface[]>([]);
  const [form] = useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [response, setResponse] = useState<RatingInterface>();

  const isSameResponsible = useMemo(() => {
    if (!responsibleTarget || !response || !ratingId) {
      return true;
    }

    const target = getTarget(response.responsible_type);

    return target == responsibleTarget;
  }, [response, responsibleTarget, ratingId]);

  const fetchQuestionData = async (_responsibleTarget: string) => {
    if (!_responsibleTarget || loading) {
      return;
    }
    setLoading(true);
    const _questions = await fetchQuestions({
      modelId,
      modelTarget,
      responsibleTarget: _responsibleTarget as ResponseTarget,
    });

    setQuestions(_questions);

    setLoading(false);
  };

  const fetchRating = async () => {
    if (!ratingId) {
      return;
    }

    try {
      const resp = await app.service.get(`ratings/${ratingId}`);

      const target = resp.data.responsible_type
        ? getTarget(resp.data.responsible_type)
        : null;

      if (target != responsibleTarget) {
        await fetchQuestionData(target);
      }

      form.setFieldsValue(resp.data.responses);
      setResponse(resp.data);
    } catch (e: any) {
      console.log(e);
      message.error(e.error || 'System Error');
    }
  };

  const handleSave = async (values: any) => {
    if (!isSameResponsible) {
      message.error('You are not allowed to rate this item');
      return;
    }
    setSubmitting(true);
    try {
      await onSumbit(values);
    } catch (err: any) {
      console.log(err);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    fetchQuestionData(responsibleTarget);
  }, [responsibleTarget]);

  useEffect(() => {
    fetchRating();
  }, [ratingId]);

  const desc = ['terrible', 'bad', 'normal', 'good', 'wonderful'];
  return (
    <>
      {loading ? (
        <Skeleton active />
      ) : (
        <Form layout="vertical" form={form} onFinish={handleSave}>
          {response?.id && <h4>Overall</h4>}
          <>
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
            <Form.Item hidden={!response?.id} noStyle shouldUpdate>
              {({ getFieldValue, setFieldValue }) => (
                <Space align="center">
                  <Form.Item
                    // noStyle
                    name={'overall'}
                  >
                    {getFieldValue('overall') && (
                      <RatingTag rating={getFieldValue('overall')} />
                    )}
                  </Form.Item>
                </Space>
              )}
            </Form.Item>
            <Divider />
            {(questions || []).map(
              (question: QuestionInterface, idx: number) => {
                const optionTexts = _.orderBy(question.options, 'point').map(
                  (option) => option.text,
                );
                return (
                  <div key={`questions_${idx}`}>
                    {!(
                      !responsible?.id &&
                      question?.custom_properties['allowAutoRating']
                    ) && (
                      <h4>
                        {idx + 1} . {question.title}{' '}
                        {question.description && (
                          <span className="text-gray">
                            ({question.description})
                          </span>
                        )}
                        <Popover
                          trigger={'hover'}
                          content={
                            <>
                              {question.options.map((option, idx) => (
                                <p key={idx}>
                                  <strong>{option.point}</strong> :{' '}
                                  {option.text}
                                </p>
                              ))}
                            </>
                          }
                        >
                          <span className="text-pointer">
                            <InfoCircleOutlined />
                          </span>
                        </Popover>
                      </h4>
                    )}
                    <Form.Item
                      hidden={
                        !responsible?.id &&
                        question?.custom_properties['allowAutoRating']
                      }
                      noStyle
                      shouldUpdate
                    >
                      {({ getFieldValue, setFieldValue }) => (
                        <Space align="start">
                          <Form.Item
                            initialValue={
                              question.custom_properties['allowAutoRating']
                                ? null
                                : ''
                            }
                            name={question.id}
                            rules={[
                              {
                                required: !question.custom_properties[
                                  'allowAutoRating'
                                ],
                                message: 'Please rate',
                              },
                            ]}
                          >
                            <Rate
                              disabled={
                                !isSameResponsible ||
                                question?.custom_properties['allowAutoRating']
                              }
                              allowHalf={allowHalf}
                              tooltips={optionTexts}
                              onChange={(v) => setFieldValue(question.id, v)}
                              value={getFieldValue(question.id)}
                            />
                          </Form.Item>
                          {isSameResponsible &&
                          !question?.custom_properties['allowAutoRating'] ? (
                            <Button
                              type="link"
                              onClick={() => setFieldValue(question.id, null)}
                            >
                              Clear
                            </Button>
                          ) : (
                            <Button disabled type="link">
                              (Auto Rating, No Action Needed)
                            </Button>
                          )}
                        </Space>
                      )}
                    </Form.Item>
                  </div>
                );
              },
            )}
            <Form.Item label="Comment" name="comment">
              <TextArea rows={6} />
            </Form.Item>
          </>
          {isSameResponsible && (
            <Button loading={submitting} type={'primary'} htmlType="submit">
              Save
            </Button>
          )}
        </Form>
      )}
    </>
  );
};

export default RatingForm;
