import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Typography,
  message,
} from 'antd';
import React from 'react';
import { useApp } from '@/utils/useapp';
import { get } from 'lodash';

export const SyncTab = ({ id }: { id: any }) => {
  const app = useApp();

  const [showApiToken, setShowApiToken] = React.useState(false);
  const [apiToken, setApiToken] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);

  const handleCancel = () => {
    setApiToken(null);
    setShowApiToken(false);
  };

  const handleRegenerateToken = async () => {
    setLoading(true);

    try {
      const resp = await app.service.patch(`vendors/${id}/regenerateToken`, {});

      setShowApiToken(true);
      setApiToken(get(resp, 'apiToken', null));

      message.success('Saved');
    } catch (e: any) {
      message.error(e.data.error || 'System Error');
    }

    setLoading(false);
  };

  return (
    <>
      <Row gutter={10}>
        <Col span={12}>
          <h3>Sycn</h3>
          <Form.Item label="Google Drive folder ID" name="folder_id">
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <h3>API Token</h3>
          <Button
            type="primary"
            onClick={() => {
              setShowApiToken(true);
            }}>
            API Token
          </Button>
          <Modal
            cancelText="Close"
            style={{ top: '35%' }}
            width="40%"
            title="Api Token"
            footer={false}
            open={showApiToken}
            onCancel={handleCancel}>
            <div>
              <Row className="mb-md">
                <Space>
                  <Typography.Text>API Token:</Typography.Text>
                  <Typography.Text>{showApiToken && apiToken}</Typography.Text>
                </Space>
              </Row>
              <Row>
                <Space>
                  <Button type="primary" onClick={handleRegenerateToken}>
                    generate token
                  </Button>
                  {/* {get(vendor, 'has_token', false) && (
              <Popconfirm
                placement="top"
                title={'Are you sure to delete?'}
                onConfirm={handleDeleteToken}
                okText="Yes"
                cancelText="No">
                <Button>Delete token</Button>
              </Popconfirm>
            )} */}
                </Space>
              </Row>
            </div>
          </Modal>
        </Col>
      </Row>
    </>
  );
};
