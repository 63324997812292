import React from 'react';
import { Modal, Button } from 'antd';
import { useApp } from '@/utils/useapp';

import { Excels } from '@/components/Upload/Excels';

const CTF: React.FC<{ onUploaded: () => void }> = ({ onUploaded }) => {
  const app = useApp();

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [enableEmptyUpload, setEnableEmptyUpload] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setEnableEmptyUpload(true);
    setIsModalVisible(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Bulk Upload CTF
      </Button>
      <Modal
        title="Bulk Upload CTF"
        open={isModalVisible}
        onCancel={handleCancel}>
        <Excels
          uploadAction="orderBills/bulk/ctf"
          enableEmptyUpload={enableEmptyUpload}
          onUploaded={onUploaded}
        />
      </Modal>
    </>
  );
};

export default CTF;
