import React, { useState, useEffect } from 'react';
import { Button, Table, Space, message, Select } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useApp } from '@/utils/useapp';
import { Header } from '@/components/CommonHeader';
import styles from 'res/css/ui.scss';

interface WhiteListItem {
  id: string;
  company_code: string;
  company_name: string;
  updated_at: string;
  creator: string;
}

const PickupDateWhitelist: React.FC = () => {
  const app = useApp();
  const [companyInput, setCompanyInput] = useState('');
  const [data, setData] = useState<WhiteListItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    [],
  );

  const columns: ColumnsType<WhiteListItem> = [
    {
      title: 'No',
      dataIndex: 'id',
      key: 'id',
      width: 80,
      align: 'center',
    },
    {
      title: 'Code',
      dataIndex: 'company_code',
      key: 'company_code',
      width: 150,
      align: 'center',
    },
    {
      title: 'Name',
      dataIndex: 'company_name',
      key: 'company_name',
      width: 200,
      align: 'center',
    },
    {
      title: 'Update Time',
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: 180,
      align: 'center',
    },
    {
      title: 'Creator',
      dataIndex: 'creator',
      key: 'creator',
      width: 150,
      align: 'center',
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      align: 'center',
      render: (_, record) => (
        <Button type="link" danger onClick={() => handleDelete(record.id)}>
          Delete
        </Button>
      ),
    },
  ];

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await app.service.get('tl/company/whitelist');
      setData(response.data);
    } catch (error) {
      message.error('Failed to fetch data');
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (value: string) => {
    if (!value) {
      setOptions([]);
      return;
    }
    setSearchLoading(true);
    try {
      const response = await app.service.get('companies/all', {
        params: { search: value, main_search: '1' },
      });
      const options = response.data.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));
      setOptions(options);
    } catch (error) {
      message.error('Failed to search company');
      setOptions([]);
    } finally {
      setSearchLoading(false);
    }
  };

  const handleAdd = async () => {
    if (!companyInput) {
      message.error('Please select a company');
      return;
    }
    try {
      await app.service.post('tl/company/whitelist', {
        data: {
          company_id: companyInput,
        },
      });
      message.success('Company added successfully');
      setCompanyInput(''); // 清空选择框
      fetchData();
    } catch (error) {
      message.error('Failed to add company');
    }
  };

  const handleDelete = async (key: string) => {
    try {
      await app.service.delete(`tl/company/whitelist/${key}`);
      message.success('Company deleted successfully');
      fetchData();
    } catch (error) {
      message.error('Failed to delete company');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.main}>
      <Header title="Pickup Date WhiteList" />

      <div style={{ padding: '24px' }}>
        <div style={{ marginBottom: '24px' }}>
          <Space size="middle">
            <span>Company</span>
            <Select
              showSearch
              allowClear
              placeholder="Search Company"
              onSearch={handleSearch}
              onChange={(value) => setCompanyInput(value)}
              options={options}
              style={{ width: 300 }}
              filterOption={false}
              notFoundContent={null}
              loading={searchLoading}
              value={companyInput}
            />
            <Button
              type="primary"
              onClick={handleAdd}
              style={{ borderRadius: '4px' }}
            >
              Add
            </Button>
          </Space>
        </div>

        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={false}
          bordered
          size="middle"
          scroll={{ x: 'max-content' }}
          rowKey="id"
        />
      </div>
    </div>
  );
};

export default PickupDateWhitelist;
