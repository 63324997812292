import React from 'react';
import { Tag } from 'antd';

export const RenderSpecificReminds: React.FC<{
  container: any;
  showNumber: boolean;
  reminds: Array<string>;
  showExtraCost?: boolean;
}> = ({ container, showNumber, reminds, showExtraCost = false }) => {
  return (
    <div className="mb-md space">
      {showNumber && container.number + ': '}
      {reminds.map((remind, i: number) => (
        <Tag color="red" key={container.number + i}>
          {remind}
        </Tag>
      ))}
    </div>
  );
};
