import { message } from 'antd';
import { useApp } from '@/utils/useapp';
import { useState } from 'react';

export const usePickupLocation = (
  onSelectCfsLocation?: (location: any) => void,
) => {
  const app = useApp();
  const [cfsLocation, setCfsLocation] = useState<any>(null);
  const fetchPickupLocation = async (id: number) => {
    try {
      const resp = await app.service.get(`cfs/cfsLocations/${id}`);
      return resp.data;
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
  };

  const updatePickupInfo = async (pickupCfsLocationId: number) => {
    if (!pickupCfsLocationId) {
      return;
    }
    const location = await fetchPickupLocation(pickupCfsLocationId);
    if (!location) {
      return;
    }
    setCfsLocation(location);
    if (onSelectCfsLocation) {
      onSelectCfsLocation(location);
    }
  };

  const updateCFSInfo = async (pickupCfsLocationId: number, data: any) => {
    try {
      await app.service.put(`cfs/cfsLocations/${pickupCfsLocationId}`, {
        data,
      });
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
  };

  return { cfsLocation, updatePickupInfo, updateCFSInfo };
};
