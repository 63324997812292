import { cache } from '@/stores';
import { CACHE_RATE_ENGINE_CONFIG_KEY } from '@/stores/cache';
import { useApp } from '@/utils/useapp';
import { Button, Form, Input, message, Popover } from 'antd';
import moment from 'moment';
import React from 'react';
import { AlgoFormular } from './AlgoCard';

interface EnableAlgoProps {
  rateEngineConfigId: number;
  algoFormular: AlgoFormular;
  algoId?: number;
}

const EnableAlgo: React.FC<EnableAlgoProps> = ({
  rateEngineConfigId,
  algoFormular,
  algoId,
}) => {
  const app = useApp();
  const [loading, setLoading] = React.useState(false);
  const [name, setName] = React.useState<string>('');

  const config = React.useMemo(() => {
    return cache
      .get(CACHE_RATE_ENGINE_CONFIG_KEY)
      ?.find((i) => i.id == rateEngineConfigId);
  }, []);

  const enableAlgo = async () => {
    setLoading(true);
    try {
      await app.service.post(`rateEngine/${rateEngineConfigId}/enableAlgo`, {
        data: {
          algo_id: algoId,
          formular: algoFormular,
          name: name,
        },
      });

      cache.fetch(CACHE_RATE_ENGINE_CONFIG_KEY, true);
      message.success('Saved');
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
    setLoading(false);
  };

  const setDefaultAlgoName = () => {
    setName(moment().format('YYYYMMDD') + ' ' + config?.name);
  };

  return (
    <>
      {algoId ? (
        <Button
          size="small"
          color="primary"
          loading={loading}
          onClick={enableAlgo}>
          Enable this Algo
        </Button>
      ) : (
        <Popover
          onOpenChange={(open) => open && setDefaultAlgoName()}
          content={
            <>
              <Form.Item label="Name">
                <Input
                  size="small"
                  value={name}
                  placeholder="Name for this algo"
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Item>
              <div>
                <Button
                  size="small"
                  disabled={!name}
                  loading={loading}
                  onClick={enableAlgo}>
                  Save
                </Button>
              </div>
            </>
          }
          trigger="click">
          <Button size="small" color="primary">
            Enable this Algo
          </Button>
        </Popover>
      )}
    </>
  );
};

export default EnableAlgo;
