import { Column } from '@ant-design/charts';
import { Col } from 'antd';
import { Row } from 'antd';
import { Card } from 'antd';

const columnConfig = {
  xField: 'week',
  yField: 'value',
  color: '#1890ff',
  label: {
    style: {
      fill: '#FFFFFF',
      opacity: 0.6,
    },
  },
  xAxis: {
    label: {
      autoHide: true,
      autoRotate: false,
    },
  },
  height: 200,
};

interface ChartData {
  week: string;
  value: number;
}

export interface TrendData {
  quote: ChartData[];
  shipment: ChartData[];
  ratio: ChartData[];
}

export const Trend = ({ quote, shipment, ratio }: TrendData) => {
  return (
    <Row gutter={8}>
      <Col span={8}>
        <Card title="Quote Trend">
          <Column {...columnConfig} data={quote} />
        </Card>
      </Col>
      <Col span={8}>
        <Card title="Shipment Trend">
          <Column {...columnConfig} data={shipment} />
        </Card>
      </Col>
      <Col span={8}>
        <Card title="Shipment/Quote Trend">
          <Column {...columnConfig} data={ratio} />
        </Card>
      </Col>
    </Row>
  );
};
