import React from 'react';
import copy from 'copy-to-clipboard';
import { Typography, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

const { Link } = Typography;

interface ICopyButton {
  value: string;
}
export const CopyButton: React.FC<ICopyButton> = ({ value }) => {
  const handleCopy = () => {
    if (copy(value)) {
      message.success('Copied');
    } else {
      message.error('Copy failed');
    }
  };

  return (
    <Link onClick={handleCopy}>
      <CopyOutlined />
    </Link>
  );
};
