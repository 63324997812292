import { UserSyncSelect } from '@/components/UserSyncSelect';
import { useApp } from '@/utils/useapp';
import { Button, Col, DatePicker, message, Space, Table, Tooltip } from 'antd';
import { Row } from 'antd';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';

interface RankData {
  brokerRank: {
    broker: string;
    carrier: string;
    shipments: number;
  }[];
  customerRank: {
    customer: string;
    customer_company_name: string;
    customer_company_code: string;
    shipments: number;
    pallets: number;
    volume: number;
    weight: number;
  }[];
  salesRank: {
    sales: string;
    shipments: number;
    customers: string;
  }[];
  opRank: {
    operator: string;
    shipments: number;
  }[];
  salesSupportRank: {
    salesSupport: string;
    shipments: number;
  }[];
  arRank: {
    sales: string;
    shipments: number;
  }[];
}

interface RankProps {
  startDate: string;
  endDate: string;
}

export const Rank: FC<RankProps> = ({ startDate, endDate }: RankProps) => {
  const app = useApp();
  const [loading, setLoading] = useState(false);
  const [rankData, setRankData] = useState<RankData>();
  const [dateRange, setDateRange] = useState<[string, string]>(['', '']);
  const [customerId, setCustomerId] = useState<number>();
  const fetchRankData = async (startDate: string, endDate: string) => {
    setLoading(true);
    try {
      const resp = await app.service.get('tl/ltl/dashboard/rank', {
        params: {
          start_date: startDate,
          end_date: endDate,
          customer_id: customerId,
        },
      });
      setRankData(resp.data);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setDateRange([startDate, endDate]);
    fetchRankData(startDate, endDate);
  }, [startDate, endDate]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Space>
          <UserSyncSelect
            style={{ width: 200 }}
            type="user"
            value={customerId}
            allowClear
            onSelect={(user: any) => setCustomerId(user?.id)}
          />
          <DatePicker.RangePicker
            value={[moment(dateRange[0]), moment(dateRange[1])]}
            onChange={(dates: any, dateStrings: [string, string]) => {
              if (dates) {
                setDateRange(dateStrings);
              }
            }}
          />
          <Button
            type="primary"
            onClick={() => fetchRankData(dateRange[0], dateRange[1])}
          >
            Search
          </Button>
        </Space>
      </Col>
      <Col span={8}>
        <Table
          title={() => (
            <span style={{ fontWeight: 'bold' }}>Broker - Carrier top5</span>
          )}
          loading={loading}
          dataSource={rankData?.brokerRank}
          columns={[
            { title: 'Broker', dataIndex: 'broker' },
            { title: 'Carrier', dataIndex: 'carrier' },
            { title: 'Shipments', dataIndex: 'shipments' },
          ]}
          pagination={false}
          size="small"
        />
      </Col>
      <Col span={8}>
        <Table
          title={() => (
            <span style={{ fontWeight: 'bold' }}>Customer - Shipment top5</span>
          )}
          loading={loading}
          dataSource={rankData?.customerRank}
          columns={[
            {
              title: 'Customer',
              dataIndex: 'customer',
              render: (text, record) => {
                return (
                  <Tooltip title={record.customer_company_name}>
                    {`${text}(${record.customer_company_code})`}
                  </Tooltip>
                );
              },
            },
            { title: 'Shipments', dataIndex: 'shipments' },
            { title: 'Pallet', dataIndex: 'pallets' },
            { title: 'Volume', dataIndex: 'volume' },
            { title: 'Weight', dataIndex: 'weight' },
          ]}
          pagination={false}
          size="small"
        />
      </Col>
      <Col span={8}>
        <Table
          title={() => (
            <span style={{ fontWeight: 'bold' }}>Sales - Shipment top5</span>
          )}
          loading={loading}
          dataSource={rankData?.salesRank}
          columns={[
            { title: 'Sales', dataIndex: 'sales' },
            { title: 'Shipments', dataIndex: 'shipments' },
            { title: 'Customer', dataIndex: 'customers' },
          ]}
          pagination={false}
          size="small"
        />
      </Col>
      <Col span={8}>
        <Table
          title={() => <span style={{ fontWeight: 'bold' }}>OP top5</span>}
          loading={loading}
          dataSource={rankData?.opRank}
          columns={[
            { title: 'Operator', dataIndex: 'operator' },
            { title: 'Shipments', dataIndex: 'shipments' },
          ]}
          pagination={false}
          size="small"
        />
      </Col>
      <Col span={8}>
        <Table
          title={() => (
            <span style={{ fontWeight: 'bold' }}>Sales Support top5</span>
          )}
          loading={loading}
          dataSource={rankData?.salesSupportRank}
          columns={[
            { title: 'Sales Support', dataIndex: 'salesSupport' },
            { title: 'Shipments', dataIndex: 'shipments' },
          ]}
          pagination={false}
          size="small"
        />
      </Col>
      <Col span={8}>
        <Table
          title={() => (
            <span style={{ fontWeight: 'bold' }}>AR Statistics</span>
          )}
          loading={loading}
          dataSource={rankData?.arRank}
          columns={[
            { title: 'Sales', dataIndex: 'sales' },
            { title: 'Shipments', dataIndex: 'shipments' },
          ]}
          pagination={false}
          size="small"
        />
      </Col>
    </Row>
  );
};
