import { CACHE_FACILITY_TYPE_KEY } from '@/stores/cache';
import React from 'react';
import SyncCacheModelSelect, {
  SyncCacheModelSelectProps,
} from './SyncCacheModelSelect';

export const FacilityTypeSelect: React.FC<SyncCacheModelSelectProps> = ({
  ...props
}) => {
  return <SyncCacheModelSelect {...props} cacheKey={CACHE_FACILITY_TYPE_KEY} />;
};
