import { useCallback, useMemo, useState } from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { useForm } from 'antd/lib/form/Form';
import AutoResizeTable from '@/components/AutoResizeTable';
import { Filter } from './components/Filter';
import { message } from 'antd';
import { toNumber } from 'lodash';

const sorter = (_a: any, _b: any) => {
  const a = isNaN(_a) ? -Number.MAX_VALUE : toNumber(_a);
  const b = isNaN(_b) ? -Number.MAX_VALUE : toNumber(_b);

  return a < b ? -1 : 1;
};

const Index = () => {
  const app = useApp();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [filter] = useForm();

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      const result = await app.service.get('rateEngine/verify', {
        params: {
          ...filter.getFieldsValue(),
        },
      });

      setData(result);
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error || 'System Error');
    }

    setLoading(false);
  }, []);

  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: 'IR',
        dataIndex: 'ir',
        width: 200,
        sorter: (a, b) => a.ir.localeCompare(b.ir),
      },
      {
        title: 'City',
        dataIndex: 'city',
        width: 200,
        sorter: (a, b) => a.city.localeCompare(b.city),
      },
      {
        title: 'Terminal',
        dataIndex: 'terminal',
        width: 200,
        sorter: (a, b) => a.terminal.localeCompare(b.terminal),
      },
      {
        title: 'Distance',
        dataIndex: 'distance',
        width: 200,
        sorter: (a, b) =>
          toNumber(a.distance) < toNumber(b.distance) ? -1 : 1,
      },
      {
        title: 'Rate Per Mile',
        dataIndex: 'rate_per_mile',
        width: 200,
        sorter: (a, b) => sorter(a.rate_per_mile, b.rate_per_mile),
      },
      {
        title: 'Current Rate',
        dataIndex: 'current_rate',
        width: 200,
        sorter: (a, b) => sorter(a.current_rate, b.current_rate),
      },
      {
        title: 'New Rate',
        dataIndex: 'new_rate',
        width: 200,
        sorter: (a, b) => sorter(a.new_rate, b.new_rate),
      },
      {
        title: 'Diff Percentage',
        dataIndex: 'diff_ratio',
        width: 200,
        sorter: (a, b) => sorter(a.diff_ratio, b.diff_ratio),
        render: (text) => (toNumber(text) * 100).toFixed(2) + '%',
      },
      {
        title: 'Diff',
        dataIndex: 'diff',
        width: 200,
        sorter: (a, b) => sorter(a.diff, b.diff),
      },
    ],
    [],
  );

  const handleSearch = () => {
    fetchData();
  };

  return (
    <div className={styles.main}>
      <Header title="Rate Engine Verify"></Header>

      <Filter loading={loading} filter={filter} onSearch={handleSearch} />

      <AutoResizeTable
        loading={loading}
        pagination={false}
        size="small"
        rowKey={(record) => record.ir + record.city + record.terminal}
        columns={columns}
        dataSource={data ? data : []}
        sticky
        scroll={{
          x: 'auto',
        }}
      />
    </div>
  );
};

export default Index;
