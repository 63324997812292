import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import { ApiRecord } from './interface/data';
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Space,
  Tag,
  Modal,
} from 'antd';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { useForm } from 'antd/lib/form/Form';
import usePagination from '@/components/usePagination';
import AutoResizeTable from '@/components/AutoResizeTable';
import { TCollection } from '@/types';
import { EyeOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const ApiRecords: React.FC = () => {
  const app = useApp();
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState<any>(null);

  const showModal = (title: string, content: any) => {
    setModalTitle(title);
    setModalContent(content);
    setModalVisible(true);
  };

  const [data, setData] = useState<TCollection<ApiRecord>>({
    data: [],
    meta: {
      total: 0,
      current_page: 1,
      per_page: 20,
      last_page: 1,
    },
  });
  const [filter] = useForm();

  const pagination = usePagination(data);

  const fetchData = useCallback(async (pagination?: any) => {
    setLoading(true);
    try {
      const result = await app.service.get('api-records', {
        params: {
          ...filter.getFieldsValue(),
          page: pagination?.current || 1,
          per_page: pagination?.pageSize || 20,
        },
      });
      setData(result);
    } catch (e: any) {
      message.error(e.error || 'System Error');
    }
    setLoading(false);
  }, []);

  const handleClearAll = async () => {
    filter.resetFields();
    await fetchData();
  };

  const renderJsonPreview = (text: any) => {
    if (!text) {
      return '-';
    }
    const stringified = JSON.stringify(text);
    if (stringified.length <= 50) {
      return stringified;
    }
    return stringified.substring(0, 50) + '...';
  };

  const columns: ColumnsType<ApiRecord> = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 80,
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        width: 100,
        render: (text) => <Tag color="blue">{text}</Tag>,
      },
      {
        title: 'API Path',
        dataIndex: 'url',
        key: 'url',
        width: 300,
        ellipsis: true,
      },
      {
        title: 'Method',
        dataIndex: 'request_method',
        key: 'request_method',
        width: 100,
        render: (text) => (
          <Tag color={text === 'GET' ? 'green' : 'blue'}>{text}</Tag>
        ),
      },
      {
        title: 'Request Params',
        dataIndex: 'request_params',
        key: 'request_params',
        width: 250,
        render: (text) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <div
              style={{
                flex: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {renderJsonPreview(text)}
            </div>
            <Button
              type="link"
              icon={<EyeOutlined />}
              onClick={() => showModal('Request Parameters', text)}
              style={{ padding: 0, minWidth: '32px' }}
            />
          </div>
        ),
      },
      {
        title: 'Response',
        dataIndex: 'response',
        key: 'response',
        width: 250,
        render: (text) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <div
              style={{
                flex: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {renderJsonPreview(text)}
            </div>
            <Button
              type="link"
              icon={<EyeOutlined />}
              onClick={() => showModal('Response Data', text)}
              style={{ padding: 0, minWidth: '32px' }}
            />
          </div>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'response_status_code',
        key: 'response_status_code',
        width: 100,
        render: (code) => (
          <Tag color={code >= 200 && code < 300 ? 'success' : 'error'}>
            {code}
          </Tag>
        ),
      },
      {
        title: 'Response Time(s)',
        dataIndex: 'response_time',
        key: 'response_time',
        width: 120,
      },
      {
        title: 'Reference Order Number',
        dataIndex: 'reference_order_number',
        key: 'reference_order_number',
        width: 150,
        render: (text) => text || '-',
      },
      {
        title: 'Created At',
        dataIndex: 'created_at',
        key: 'created_at',
        width: 180,
        render: (text) => dayjs(text).format('YYYY-MM-DD HH:mm:ss'),
      },
    ],
    [],
  );

  useEffect(() => {
    fetchData().then((r) => console.log(r));
  }, []);

  return (
    <div className={styles.main}>
      <Header title="API Records"></Header>
      <div
        style={{
          width: '60%',
          padding: '10px',
        }}
      >
        <Form layout="vertical" form={filter} onFinish={fetchData}>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item name="type" label="Type">
                <Input placeholder="Enter API type" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="url" label="API Path">
                <Input placeholder="Enter API path" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="reference_order_number"
                label="Reference Order Number"
              >
                <Input placeholder="Enter reference order number" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label={' '}>
                <Space>
                  <Button type="primary" htmlType="submit">
                    Search
                  </Button>
                  <Button onClick={handleClearAll}>Clear All</Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <AutoResizeTable
        loading={loading}
        pagination={pagination}
        size="small"
        rowKey="id"
        columns={columns}
        onChange={fetchData}
        dataSource={data.data}
        sticky
        scroll={{
          x: 1500,
        }}
      />

      <Modal
        title={modalTitle}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        width={800}
      >
        <pre
          style={{
            maxHeight: '600px',
            overflow: 'auto',
            backgroundColor: '#f5f5f5',
            padding: '16px',
            borderRadius: '4px',
            fontSize: '14px',
          }}
        >
          {JSON.stringify(modalContent, null, 2)}
        </pre>
      </Modal>
    </div>
  );
};

export default ApiRecords;
