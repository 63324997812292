import { useApp } from '@/utils/useapp';
import React, { useEffect, useState, useMemo } from 'react';
import { useObserver } from 'mobx-react';

import { SubTabInterface } from '../..';
import { DispatchTable } from '@/pages/dispatchs/components/Table/DispatchTable';
import { ManageColumns } from '@/pages/dispatchs/components/ManageColumns';
import { Filter as DispatchFilter } from '@/pages/dispatchs/components/Filter';
import type { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import update from 'immutability-helper';
import useDispatch from '@/pages/dispatchs/components/Table/useDispatch';
import { TCollection } from '@/types';
import _, { get } from 'lodash';
import useProgressTab from './useProgressTab';

export type OpDataItem = {
  task: string;
  task_id: number;
  whose_task: number;
  count: number;
  has_overdue: boolean;
  container_ids: Array<number>;
};

export const ProgressDetail: React.FC<
{
  searchQuery: string | null;
  opdashboardFilters: any;
  activeSubTab: any;
  selectedDate?: string | null;
  updateCustomProperties: (properties: any) => void;
} & SubTabInterface
> = ({
  searchQuery,
  activeOpdashboardTab,
  activeSubTab,
  selectedDate,
  updateCustomProperties,
  refresh = true,
  setRefresh,
  goToDispatch,
}) => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<TCollection<any>>();

  const activeFilters = useMemo(() => {
    return get(
      activeOpdashboardTab,
      `custom_properties.${activeSubTab.key}.filters`,
      [],
    );
  }, [activeOpdashboardTab?.custom_properties, activeSubTab]);

  const activeColumns = useMemo(() => {
    return get(
      activeOpdashboardTab,
      `custom_properties.${activeSubTab.key}.columns`,
      [],
    );
  }, [activeOpdashboardTab?.filters, activeSubTab]);

  const [isShowFilter, setIsShowFilter] = useState(false);
  const [dispatchFilters, setDispatchFilters] = React.useState<any[] | null>(
    null,
  );

  const [pagination, setPagination] = useState<TablePaginationConfig>();
  const [defaultColumns, setDefaultColumns] = useState<any>([]);
  const { joinParams } = useProgressTab();

  const {
    fetchData,
    saveUpdateTab,
    toFilterSortValue,
    onSort,
    sortOrder,
    ...props
  } = useDispatch();

  const fetchDispatchData = async (
    pagination?: TablePaginationConfig,
    params: any = {},
  ) => {
    if (!searchQuery && !params?.progress_type) {
      return;
    }

    const resp = await fetchData(pagination, {
      ...params,
      page: pagination?.current || 1,
      per_page: pagination?.pageSize || 20,
    });

    setData(resp);
    refresh && setRefresh(false);
  };

  const toSaveFilters = (filters: any) => {
    updateCustomProperties({ filters });
  };

  const handleRemoveSortInFilterValues = (
    filterValues: any,
    sortBy: string,
  ) => {
    if (!filterValues) {
      return;
    }

    const index = filterValues.findIndex((fv) => fv.attribute === 'sort');
    if (-1 === index) {
      return;
    }
    const sortIndex = filterValues[index].value.indexOf(`${sortBy}|`);
    if (-1 === sortIndex) {
      return;
    }

    toSaveFilters(update(filterValues, { $splice: [[index, 1]] }));
  };

  const handleSort = (pagination: any, sortBy: string, sortValue: string) => {
    if (!sortValue) {
      return handleRemoveSortInFilterValues(activeFilters, sortBy);
    }

    const label: any = columns.find((c) => c.key == sortBy);
    if (!label) {
      handleRemoveSortInFilterValues(activeFilters, sortBy);
      return;
    }

    const filterSortValue = toFilterSortValue(label.title, sortBy, sortValue);
    if (!activeFilters) {
      fetchDispatchData(
        { current: 1, pageSize: pagination?.pageSize || 20 },
        joinParams(
          activeSubTab.key,
          activeOpdashboardTab.id,
          activeFilters,
          {
            [activeSubTab.key]: selectedDate,
          },
          searchQuery,
        ),
      );
      return;
    }

    const index = activeFilters.findIndex((fv) => fv.attribute === 'sort');
    if (index !== -1) {
      toSaveFilters(
        update(activeFilters, { [index]: { $set: filterSortValue } }),
      );
    } else {
      toSaveFilters([...activeFilters, filterSortValue]);
    }
  };

  const checkedColumns = useMemo(() => {
    if (activeColumns.length === 0) {
      return defaultColumns?.map((c: any) => c.key);
    }

    return activeColumns;
  }, [defaultColumns, activeOpdashboardTab, activeColumns]);

  const columns: ColumnsType<any> = React.useMemo(() => {
    const sort = activeFilters?.find((f) => f.attribute === 'sort') || null;
    const _columns: ColumnsType<any> = defaultColumns?.map((c: any) => ({
      ...c,
      sortOrder: sortOrder(sort, c.key),
    }));

    if (activeColumns?.length === 0) {
      return _columns;
    }

    return _columns.filter((c) => activeColumns.includes(c.key));
  }, [activeSubTab, defaultColumns, activeColumns, activeFilters]);

  useEffect(() => {
    if (activeSubTab && selectedDate) {
      fetchDispatchData(
        { current: 1, pageSize: 20 },
        joinParams(
          activeSubTab.key,
          activeOpdashboardTab.id,
          activeFilters,
          {
            [activeSubTab.key]: selectedDate,
          },
          searchQuery,
        ),
      );
    }
  }, [selectedDate]);

  useEffect(() => {
    if (activeSubTab && activeOpdashboardTab && !refresh) {
      fetchDispatchData(
        { current: 1, pageSize: 20 },
        joinParams(
          activeSubTab.key,
          activeOpdashboardTab.id,
          activeFilters,
          {
            [activeSubTab.key]: selectedDate,
          },
          searchQuery,
        ),
      );
    }
  }, [activeSubTab]);

  useEffect(() => {
    if (activeSubTab && activeOpdashboardTab && refresh) {
      fetchDispatchData(
        { current: 1, pageSize: 20 },
        joinParams(
          activeSubTab.key,
          activeOpdashboardTab.id,
          activeFilters,
          {
            [activeSubTab.key]: selectedDate,
          },
          searchQuery,
        ),
      );
    }
  }, [refresh]);

  useEffect(() => {
    fetchDispatchData(
      { current: 1, pageSize: 20 },
      joinParams(
        activeSubTab.key,
        activeOpdashboardTab.id,
        activeFilters,
        {
          [activeSubTab.key]: selectedDate,
        },
        searchQuery,
      ),
    );
  }, [activeFilters]);

  return useObserver(() => {
    return (
      <div
      // loading={loading}
      // style={{ height: '58vh', overflow: 'hidden' }}
      >
        <div className="flex justify-between p-xs">
          <div>
            <DispatchFilter
              show={false}
              values={dispatchFilters || []}
              accountingMode={false}
              onChange={(v: any) => {
                toSaveFilters(v);
              }}
              removeAll={() => toSaveFilters([])}
              removeOne={(index: number) =>
                toSaveFilters(update(activeFilters, { $splice: [[index, 1]] }))
              }
              activeFilters={activeFilters || []}
              hideFilter={() => setIsShowFilter(false)}
              loading={loading}
            />
          </div>
          <div>
            <ManageColumns
              items={defaultColumns?.map((c: any) => {
                return (
                  {
                    key: c.key,
                    name: c.title instanceof Function ? c.label : c.title,
                  } || []
                );
              })}
              checkedColumns={checkedColumns}
              onChange={(list) => {
                updateCustomProperties({ columns: list });
              }}
            />
          </div>
        </div>

        <div
        //style={{ maxHeight: '50vh' }}
        >
          <DispatchTable
            data={data}
            columns={columns}
            setData={setData}
            enableSorter={searchQuery ? false : true}
            fetchData={(pagination, filter) =>
              fetchDispatchData(
                pagination,
                joinParams(
                  activeSubTab.key,
                  activeOpdashboardTab.id,
                  filter,
                  {
                    [activeSubTab.key]: selectedDate,
                  },
                  searchQuery,
                ),
              )
            }
            handleSort={handleSort}
            setDefaultColumns={setDefaultColumns}
            onSetPagination={setPagination}
            onClickPagination={(pagination) => {
              fetchDispatchData(
                pagination,
                joinParams(
                  activeSubTab.key,
                  activeOpdashboardTab.id,
                  activeFilters,
                  { [activeSubTab.key]: selectedDate },
                  searchQuery,
                ),
              );
            }}
            // onSetSelected={(
            //   selectedRowKeys: React.Key[],
            //   selectedRows: any[],
            // ) => {
            //   setSelectedRowKeys(selectedRowKeys);
            //   setSelectedRows(selectedRows);
            // }}

            // disabledResize={true}
            // scroll={{
            //   x: 1500,
            //   y: 480,
            // }}
          />
        </div>
      </div>
    );
  });
};
