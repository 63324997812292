import Icon, { HomeOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import React from 'react';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import styles from 'res/css/ui.scss';

const ResidentialSvg = () => (
  <svg
    viewBox="64 64 896 896"
    focusable="false"
    data-icon="gateway"
    width="1em"
    height="1em"
    fill="currentColor"
    aria-hidden="true">
    <path d="M336.009 736.03h31.999c17.672 0 31.998 14.327 31.998 32v31.998c0 17.672-14.326 31.999-31.998 31.999h-32c-17.672 0-31.998-14.327-31.998-32V768.03c0-17.672 14.326-31.999 31.999-31.999z m0-191.99h31.999c17.672 0 31.998 14.326 31.998 31.999v31.998c0 17.673-14.326 32-31.998 32h-32c-17.672 0-31.998-14.327-31.998-32V576.04c0-17.673 14.326-32 31.999-32z m383.981 95.995h31.999c17.672 0 31.999 14.326 31.999 31.999v31.999c0 17.672-14.327 31.998-32 31.998H719.99c-17.672 0-31.999-14.326-31.999-31.998v-32c0-17.672 14.327-31.998 32-31.998zM336.01 352.049h31.999c17.672 0 31.998 14.326 31.998 31.999v31.999c0 17.672-14.326 31.998-31.998 31.998h-32c-17.672 0-31.998-14.326-31.998-31.998v-32c0-17.672 14.326-31.998 31.999-31.998zM176.016 912.022h351.983c8.837 0 16-7.163 16-16V137.947c0-8.836-7.163-16-16-16-2.483 0-4.933 0.579-7.155 1.689L168.861 299.629a16 16 0 0 0-8.845 14.31v582.083c0 8.837 7.164 16 16 16z m447.979 0h223.99c8.835 0 15.999-7.163 15.999-16V536.907a15.998 15.998 0 0 0-7.52-13.567L607.995 368.048v527.975c0 8.836 7.163 15.999 16 15.999z m-527.975 0V304.05a63.998 63.998 0 0 1 35.377-57.24l383.98-191.99c42.552-21.277 92.618 9.666 92.618 57.24v191.89c11.38-0.026 23.06 3.043 33.919 9.83L897.9 473.77a63.998 63.998 0 0 1 30.08 54.27v383.98h31.998c17.672 0 31.999 14.327 31.999 32s-14.327 31.998-32 31.998H64.022c-17.672 0-31.999-14.326-31.999-31.999s14.327-31.998 32-31.998H96.02z"></path>
  </svg>
);
const _ResidentialIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ResidentialSvg} {...props} />
);

export const ResidentialIcon: React.FC = () => (
  <span className={styles.customer_icon}>
    <_ResidentialIcon />
  </span>
);
