import React, { useMemo, useState } from 'react';
import { message, Space, Table, Tag, Typography, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { get, reduce } from 'lodash';
import { MAX_VENDOR_SELECTION } from './data';
import { handleShiftKeyDown } from '@/utils/handleKeyDown';
import { min, max } from 'lodash';
import { useGetResizeTableHeight } from '@/components/AutoResizeTable/useGetResizeTableHeight';
import { VendorCell } from '@/components/VendorCell';

interface Props {
  data: any;
  loading: boolean;
  selectedRowKeys: React.Key[];
  showAllFilter: boolean;
  onSelect: (id: React.Key[]) => void;
}

const AbilityTag: React.FC<{ ability: boolean }> = ({ ability }) =>
  ability ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>;

export const ChooseVendors: React.FC<Props> = ({
  data,
  loading,
  selectedRowKeys,
  showAllFilter,
  onSelect,
}) => {
  const [tableHeight, handleGetTableHeight, id] = useGetResizeTableHeight();
  const [height, setHeight] = useState(500);

  React.useEffect(() => {
    handleGetTableHeight();
  }, [data]);

  React.useEffect(() => {
    if (showAllFilter) {
      setHeight(tableHeight - 270);
    } else {
      setHeight(tableHeight - 100);
    }
  }, [showAllFilter, tableHeight]);

  const availableCount = useMemo(
    () =>
      reduce(
        data,
        (result: number, record: any) => {
          if (get(record, 'canSendEmail', true)) {
            result++;
          }
          return result;
        },
        0,
      ),
    [data],
  );

  const rowSelection = {
    selectedRowKeys,
    renderCell: (checked, record, index, originNode) => {
      const sentEmailOfRequestedRateIds = get(
        record,
        'sentEmailOfRequestedRateIds',
      );
      if (
        sentEmailOfRequestedRateIds &&
        sentEmailOfRequestedRateIds.length > 0
      ) {
        return (
          <Tooltip
            placement="topLeft"
            title={sentEmailOfRequestedRateIds.join(',') + ' has Sent email'}>
            {originNode}
          </Tooltip>
        );
      }
      return originNode;
    },
    getCheckboxProps: (record: any) => ({
      disabled:
        !get(record, 'canSendEmail', true) ||
        (selectedRowKeys.length >= MAX_VENDOR_SELECTION &&
          !selectedRowKeys.includes(record.id)),
    }),
    onSelect: (record: any, selected: any, selectedRows: any, event: any) => {
      onSelect(selectedRows.map((s: any) => s.id));
    },

    onSelectAll: (selected: any, selectedRows: any) => {
      if (selectedRows.length > MAX_VENDOR_SELECTION) {
        message.error(`Couldn't select over ${MAX_VENDOR_SELECTION} records`);
        return false;
      }

      onSelect(selectedRows.map((s: any) => s.id));
    },
    onSelectMultiple: (selected: any, selectedRows: any, changeRows: any) => {
      onSelect(selectedRows.map((s: any) => s.id));
    },
  };

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      key: 'id',
      width: 80,
      render: (text, record, index) => <>{record.id}</>,
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor',
      key: 'vendor',
      width: 300,
      render: (text, record) => <VendorCell vendor={record} />,
    },
    {
      title: 'Contacts',
      dataIndex: 'contacts',
      key: 'contacts',
      width: 120,
      sorter: (a, b) => a?.contacts?.length - b?.contacts?.length,
      render: (text, record) => {
        const contacts = get(record, 'contacts', []);
        // const _contacts = contacts?.filter((c) => c?.request_rate);

        if (!contacts?.length) {
          return <>No Contacts</>;
        }

        return <>{contacts.length} Contacts</>;
      },
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
      width: 120,
      sorter: (a, b) => a.rating - b.rating,
    },
    {
      title: 'Driver Count',
      dataIndex: 'driver_count',
      key: 'driver_count',
      width: 120,
      sorter: (a, b) => a.driver_count - b.driver_count,
    },
    {
      title: 'Rates Count',
      dataIndex: 'rates_count',
      key: 'rates_count',
      width: 120,
      sorter: (a, b) => a.rates_count - b.rates_count,
    },
    {
      title: 'City',
      dataIndex: 'city_id',
      key: 'city_id',
      width: 160,
      render: (text, record) => {
        return <>{get(record, 'city.full_name', '')}</>;
      },
      sorter: (a, b) => a.city_id - b.city_id,
    },
    {
      title: 'Overweight Ability',
      dataIndex: 'overweight_ability',
      key: 'overweight_ability',
      width: 100,
      render: (text, record) => (
        <AbilityTag ability={record.overweight_ability} />
      ),
      // render: (text, record) => {
      //   return (
      //     <>
      //       {record.overweight_ability ? (
      //         <Tag color="green">Yes</Tag>
      //       ) : (
      //         <Tag color="red">No</Tag>
      //       )}
      //     </>
      //   );
      // },
      sorter: (a, b) => a.overweight_ability - b.overweight_ability,
    },
    {
      title: 'Hazmat Ability',
      dataIndex: 'hazmat_ability',
      key: 'hazmat_ability',
      width: 100,
      render: (text, record) => <AbilityTag ability={record.hazmat_ability} />,
      // render: (text, record) => {
      //   return (
      //     <>
      //       {record.hazmat_ability ? (
      //         <Tag color="green">Yes</Tag>
      //       ) : (
      //         <Tag color="red">No</Tag>
      //       )}
      //     </>
      //   );
      // },
      sorter: (a, b) => a.hazmat_ability - b.hazmat_ability,
    },
    {
      title: 'FBA Delivery',
      dataIndex: 'fba_delivery',
      key: 'fba_delivery',
      width: 100,
      render: (text, record) => <AbilityTag ability={record.fba_delivery} />,
      // render: (text, record) => {
      //   return (
      //     <>
      //       {record.fba_delivery ? (
      //         <Tag color="green">Yes</Tag>
      //       ) : (
      //         <Tag color="red">No</Tag>
      //       )}
      //     </>
      //   );
      // },
      sorter: (a, b) => a.fba_delivery - b.fba_delivery,
    },
    {
      title: 'Residential Ability',
      dataIndex: 'residential_ability',
      key: 'residential_ability',
      width: 100,
      render: (text, record) => (
        <AbilityTag ability={record.residential_ability} />
      ),
      // render: (text, record) => {
      //   return (
      //     <>
      //       {record.residential_ability ? (
      //         <Tag color="green">Yes</Tag>
      //       ) : (
      //         <Tag color="red">No</Tag>
      //       )}
      //     </>
      //   );
      // },
      sorter: (a, b) => a.residential_ability - b.residential_ability,
    },
    {
      title: 'Reefer Ability',
      dataIndex: 'reefer_ability',
      key: 'reefer_ability',
      width: 100,
      render: (text, record) => <AbilityTag ability={record.reefer_ability} />,
      // render: (text, record) => {
      //   return (
      //     <>
      //       {record.reefer_ability ? (
      //         <Tag color="green">Yes</Tag>
      //       ) : (
      //         <Tag color="red">No</Tag>
      //       )}
      //     </>
      //   );
      // },
      sorter: (a, b) => a.reefer_ability - b.reefer_ability,
    },
    {
      title: 'OOG Ability',
      dataIndex: 'out_of_gauge_ability',
      key: 'out_of_gauge_ability',
      width: 100,
      render: (text, record) => (
        <AbilityTag ability={record.out_of_gauge_ability} />
      ),
      sorter: (a, b) => a.out_of_gauge_ability - b.out_of_gauge_ability,
    },
    {
      title: 'OpenTop Ability',
      dataIndex: 'open_top_ability',
      key: 'open_top_ability',
      width: 100,
      render: (text, record) => (
        <AbilityTag ability={record.open_top_ability} />
      ),
      sorter: (a, b) => a.open_top_ability - b.open_top_ability,
    },
    {
      title: 'FlatRack Ability',
      dataIndex: 'flat_rack_ability',
      key: 'flat_rack_ability',
      width: 100,
      render: (text, record) => (
        <AbilityTag ability={record.flat_rack_ability} />
      ),
      sorter: (a, b) => a.flat_rack_ability - b.flat_rack_ability,
    },
    {
      title: 'Menards Approval Ability',
      dataIndex: 'menards_approval_ability',
      key: 'menards_approval_ability',
      width: 100,
      render: (text, record) => (
        <AbilityTag ability={record.menards_approval_ability} />
      ),
      sorter: (a, b) => a.menards_approval_ability - b.menards_approval_ability,
    },
    {
      title: 'Transloading Ability',
      dataIndex: 'transloading_ability',
      key: 'transloading_ability',
      width: 100,
      render: (text, record) => (
        <AbilityTag ability={record.transloading_ability} />
      ),
      sorter: (a, b) => a.transloading_ability - b.transloading_ability,
    },
    {
      title: 'Bonded Trucker Ability',
      dataIndex: 'boned_trucker_ability',
      key: 'boned_trucker_ability',
      width: 100,
      render: (text, record) => (
        <AbilityTag ability={record.boned_trucker_ability} />
      ),
      sorter: (a, b) => a.boned_trucker_ability - b.boned_trucker_ability,
    },
    {
      title: 'Own Chassis',
      dataIndex: 'has_own_chassis',
      key: 'has_own_chassis',
      width: 100,
      render: (text, record) => {
        const has_own_chassis = get(record, 'has_own_chassis');
        if (true === has_own_chassis) {
          return <Tag color="success">{'YES'}</Tag>;
        } else {
          return <Tag color="red">{'NO'}</Tag>;
        }
      },
      sorter: (a, b) => a.has_own_chassis - b.has_own_chassis,
    },
    {
      title: 'Enabled',
      dataIndex: 'enabled',
      key: 'enabled',
      width: 100,
      render: (text, record) => {
        const enabled = get(record, 'enabled');
        if (true === enabled) {
          return <Tag color="success">{'YES'}</Tag>;
        } else {
          return <Tag color="red">{'NO'}</Tag>;
        }
      },
      sorter: (a, b) => a.enabled - b.enabled,
    },
    {
      title: 'Favorited',
      dataIndex: 'favorited',
      key: 'favorited',
      width: 160,
      render: (text, record) => {
        const favorited = get(record, 'favorited');
        if (true === favorited) {
          return <Tag color="success">{'YES'}</Tag>;
        } else {
          return <Tag color="red">{'NO'}</Tag>;
        }
      },
      sorter: (a, b) => a.favorited - b.favorited,
    },
    {
      title: 'Blacklisted',
      dataIndex: 'blacklisted',
      key: 'blacklisted',
      width: 100,
      render: (text, record) => {
        const blacklisted = get(record, 'blacklisted');
        if (true === blacklisted) {
          return <Tag color="success">{'YES'}</Tag>;
        } else {
          return <Tag color="red">{'NO'}</Tag>;
        }
      },
      sorter: (a, b) => a.blacklisted - b.blacklisted,
    },
    {
      title: 'Promo',
      dataIndex: 'promo',
      key: 'promo',
      width: 100,
      render: (text, record) => {
        const promo = get(record, 'promo');
        if (true === promo) {
          return <Tag color="success">{'YES'}</Tag>;
        } else {
          return <Tag color="red">{'NO'}</Tag>;
        }
      },
      sorter: (a, b) => a.promo - b.promo,
    },
  ];

  return (
    <>
      <Table
        pagination={false}
        loading={loading}
        size="small"
        rowKey="id"
        footer={() => (
          <div className="flex justify-between">
            <div>
              <h3>Available({availableCount})</h3>
            </div>
            <div>
              <Space direction="vertical" align="end">
                <h3>
                  Selected count / Total count ({selectedRowKeys?.length || 0} /{' '}
                  {MAX_VENDOR_SELECTION})
                </h3>
              </Space>
            </div>
          </div>
        )}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        columns={columns}
        dataSource={data}
        scroll={{ x: 1500, y: height }}
        id={id}
        sticky
      />
    </>
  );
};
