import { useApp } from '@/utils/useapp';
import { useMemo } from 'react';
import {
  LTLCalendarItem,
  OPdashboardCalendarInterface as CalendarItem,
} from '@/Interfaces/OPdashboardTab/OPdashboardCalendarInterface';
import { OperationType } from '@/stores/opDashboardStore';
import { SubTabs } from '../ShipmentSubTab/data';
import { uniq } from 'lodash';

type TDateItemProps = {
  key: string;
  operationable_id: number[];
  nums: number | string;
  inactive: boolean;
  title: string;
  textColor: string;
  borderColor: string;
  bgColor: string;
};

export enum ProgressMapKeysEnum {
  pickup = 'pickup',
  delivered = 'delivered',
}

export type TDateRenderProps = {
  currentData: CalendarItem & LTLCalendarItem;
  isPre: boolean;
  isToday: boolean;
  isFuture: boolean;
  goToOperationTable: (operation_ids: number[]) => void;
};

export const DateRender: React.FC<TDateRenderProps> = ({
  currentData,
  isPre,
  isToday,
  isFuture,
  goToOperationTable,
}) => {
  const app = useApp();

  const pickupNums = useMemo(() => {
    if (isToday) {
      return (
        (currentData?.actual_pickup_date?.count || 0) +
        ' / ' +
        (currentData?.estimated_pickup_date?.count || 0)
      );
    }

    return (
      currentData?.[isPre ? 'actual_pickup_date' : 'estimated_pickup_date']
        ?.count ?? 0
    );
  }, [isToday, currentData]);

  const pickupOperationableId = useMemo(() => {
    if (isToday) {
      return uniq(
        currentData?.actual_pickup_date?.operationable_id || [],
      ).concat(currentData?.estimated_pickup_date?.operationable_id || []);
    }

    return (
      currentData?.[isPre ? 'actual_pickup_date' : 'estimated_pickup_date']
        ?.operationable_id ?? []
    );
  }, [currentData]);

  const deliveryNums = useMemo(() => {
    if (isToday) {
      return (
        (currentData?.actual_delivery_date?.count || 0) +
        ' / ' +
        (currentData?.estimated_delivery_date?.count || 0)
      );
    }

    return (
      currentData?.[isPre ? 'actual_delivery_date' : 'estimated_delivery_date']
        ?.count ?? 0
    );
  }, [isToday, currentData]);

  const deliveryOperationableId = useMemo(() => {
    if (isToday) {
      return uniq(
        currentData?.actual_delivery_date?.operationable_id || [],
      ).concat(currentData?.estimated_delivery_date?.operationable_id || []);
    }

    return (
      currentData?.[isPre ? 'actual_delivery_date' : 'estimated_delivery_date']
        ?.operationable_id ?? []
    );
  }, [currentData]);

  const handleGoToOperationTable = (
    key: string,
    operationable_id: number[],
  ) => {
    goToOperationTable(operationable_id || []);
  };

  const items: TDateItemProps[] = [
    {
      key: SubTabs.PickedUp,
      title: SubTabs.PickedUp,
      inactive: isFuture,
      nums: pickupNums,
      operationable_id: pickupOperationableId,
      textColor: '#FA541C',
      borderColor: '#FFD8BF',
      bgColor: '#FFF2E8',
    },
    {
      key: SubTabs.Delivery,
      title: SubTabs.Delivery,
      inactive: isFuture,
      nums: deliveryNums,
      operationable_id: deliveryOperationableId,
      textColor: '#52C41A',
      borderColor: '#D9F7BE',
      bgColor: '#F6FFED',
    },
  ];

  return (
    <>
      {items.map(
        (item) =>
          item.nums !== 0 && (
            <div>
              {!item.inactive ? (
                <div
                  style={{
                    color: item.textColor,
                    borderColor: item.borderColor,
                    border: '1px solid',
                    backgroundColor: item.bgColor,
                    borderRadius: '16px',
                    margin: '5px 0',
                    padding: '0 6px',
                  }}
                  className={'flex justify-between'}
                  onClick={() =>
                    handleGoToOperationTable(item.key, item.operationable_id)
                  }
                >
                  <div className="flex items-center">
                    {/* {icon} */}
                    <div>{item.title}</div>
                  </div>
                  <div>{item.nums}</div>
                </div>
              ) : (
                <div
                  className="flex justify-between"
                  style={{
                    color: '#b5b6b8',
                    borderColor: '#adaeb0',
                    border: '1px dash',
                    backgroundColor: '#fafafa',
                    borderRadius: '16px',
                    margin: '5px 0',
                    padding: '0 6px',
                  }}
                  onClick={() =>
                    handleGoToOperationTable(item.key, item.operationable_id)
                  }
                >
                  <div className="flex items-center">
                    {/* {inactiveIcon} */}
                    <div>{item.title}</div>
                  </div>
                  <div>{item.nums}</div>
                </div>
              )}
            </div>
          ),
      )}
    </>
  );
};
