import { FTLCarrierInterface } from '@/pages/truckload/ftl/carriers/Interfaces/FTLCarrierInterface';
import { LTLCarrierInterface } from '@/pages/truckload/ltl/carriers/Interfaces/LTLCarrierInterface';
import { CFSCarrierInterface } from '@/pages/truckload/cfs/carriers/Interfaces/CFSCarrierInterface';

export enum ModelTarget {
  LtlCarrier = 'ltl_carrier',
  CfsCarrier = 'cfs_carrier',
}

export type ModelType = {
  modelId: number;
  modelTarget: ModelTarget.LtlCarrier | ModelTarget.CfsCarrier; // modelTarget;
  model?: LTLCarrierInterface | FTLCarrierInterface | CFSCarrierInterface;
};
