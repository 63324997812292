import React from 'react';
import { Typography } from 'antd';
import { VendorDrawer } from '@/pages/entity/vendors/components/VendorDrawer';

export const TYPE_VENDOR = 'vendor';

type TNameLinkinkProps = {
  type: 'vendor';
  name: string;
  id: number;
  onClose?: () => void;
  onSaved?: (name: string) => void;
};

export const NameLink: React.FC<TNameLinkinkProps> = ({
  type,
  name,
  id,
  onClose,
  onSaved,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSaved = (data: any) => {
    setOpen(false);
    onSaved && onSaved(data?.name || '');
  };

  const handleClose = () => {
    setOpen(false);
    onClose && onClose();
  };

  return (
    <>
      <Typography.Link onClick={handleOpen}>{name}</Typography.Link>

      {open && (
        <>
          {type === TYPE_VENDOR && (
            <VendorDrawer
              visible={open}
              id={id}
              onSaved={handleSaved}
              onClose={handleClose}
            />
          )}
        </>
      )}
    </>
  );
};
