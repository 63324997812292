import { useApp } from '@/utils/useapp';
import { Button, Modal } from 'antd';

import { useMemo, useState } from 'react';
import React from 'react';
import { FileOutlined } from '@ant-design/icons';
import { Documents } from './Upload/Documents';

export const Upload: React.FC<{
  shipment: any;
  children?: React.ReactNode;
  onClosedWithUpdate?: () => void;
  onClosed?: () => void;
}> = ({ shipment, children, onClosedWithUpdate, onClosed }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [updated, setUpdated] = React.useState(true);

  const FileRender: any = children ? children : <FileOutlined />;

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    if (updated) {
      onClosedWithUpdate && onClosedWithUpdate();
    }
    onClosed && onClosed();
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="cursor-pointer" onClick={showModal}>
        {FileRender}
      </div>

      {isModalVisible && (
        <Modal
          width={'80vw'}
          destroyOnClose
          cancelText="Close"
          footer={
            <>
              <Button type="primary" onClick={handleCancel}>
                Close
              </Button>
            </>
          }
          title="Upload"
          open={isModalVisible}
          onCancel={handleCancel}
        >
          <div style={{ maxHeight: '65vh', overflowY: 'scroll' }}>
            <Documents
              onUpdated={() => setUpdated(true)}
              targetModel={shipment}
              title=""
              target="ltl_doc"
              targetId={shipment.id}
            />
          </div>
        </Modal>
      )}
    </>
  );
};
