export const RETURN_TO_DEPORT = 'Return to deport';
export const STAY_IN_WAREHOUSE = 'Stay in warehouse';
export const TBD = 'TBD';

export const LOADING_TYPE_TBD = 0;
export const LOADING_TYPE_PALLET_LOAD = 1;
export const LOADING_TYPE_FLOOR_LOAD = 2;
export const LOADING_TYPE_OTHER = 3;

export const LOADING_TYPE = {
  [LOADING_TYPE_TBD]: 'TBD',
  [LOADING_TYPE_PALLET_LOAD]: 'Pallet Load',
  [LOADING_TYPE_FLOOR_LOAD]: 'Floor Load',
  [LOADING_TYPE_OTHER]: 'Other',
};
