import React, { useEffect } from 'react';
import { Button, Form, FormInstance, Input } from 'antd';

import { Documents } from '@/components/Upload/Documents';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { useWatch } from 'antd/lib/form/Form';

interface Props {
  form: FormInstance;
}

export const ProjectForm: React.FC<Props> = ({ form }) => {
  const userId = useWatch('user_id', form);

  const [orders, setOrders] = React.useState([]);

  const fetchOrders = async (userId: number) => {
    if (!userId) {
      return;
    }
  };

  useEffect(() => {
    form.setFieldsValue({ user_id: userId });
  }, [userId]);

  return (
    <Form layout="vertical" form={form}>
      <Form.Item name="id" noStyle>
        <Input hidden />
      </Form.Item>

      <Form.Item
        label={'Name'}
        name="name"
        rules={[{ required: true, message: 'Name is required' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        name="user_id"
        label="User (Customer)"
        rules={[{ required: false, message: 'Customer is required' }]}>
        <UserSyncSelect
          statusTypes={{
            ALL: 0,
            ENABLED: 2,
            DISABLED: -1,
          }}
        />
      </Form.Item>
      <Form.Item label="Orders">
        <Form.List
          initialValue={form.getFieldValue('orders') || []}
          name="orders">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Form.Item label={''} key={field.key}>
                  <Form.Item
                    wrapperCol={{ xs: 10 }}
                    {...field}
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: 'Please input order uid',
                      },
                    ]}>
                    <Input
                      minLength={5}
                      placeholder="Please input order uid"
                      style={{
                        width: fields.length > 1 ? '95%' : '100%',
                      }}
                    />
                  </Form.Item>
                  {fields.length > 1 ? (
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      style={{ marginLeft: '10px' }}
                      onClick={() => remove(field.name)}
                    />
                  ) : null}
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{
                    width: '100%',
                  }}
                  icon={<PlusOutlined />}>
                  Add
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
      <Form.Item
        label={'Descripton'}
        name="description"
        rules={[{ required: false }]}>
        <Input.TextArea rows={4} />
      </Form.Item>
      <Form.Item label={'Memo'} name="memo" rules={[{ required: false }]}>
        <Input.TextArea rows={4} />
      </Form.Item>
    </Form>
  );
};
