import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  message,
  Select,
  Space,
  Switch,
  Tag,
  Tooltip,
  Modal,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { useForm } from 'antd/lib/form/Form';
import { Filter } from './components/Filter';
import { TCollection, TSorter } from '@/types';
import usePagination from '@/components/usePagination';
import { filter, get, has, set } from 'lodash';
import { Remark } from './components/Remark';
import { MBLLink } from '@/components/MBLLInk';
import { LogActivities } from '@/components/LogActivities';
import {
  InfoCircleOutlined,
  SyncOutlined,
  DollarCircleOutlined,
  CopyOutlined,
} from '@ant-design/icons';
import { Upload } from './components/Upload';
import { Trucking } from './components/Trucking/index';
import { EditableText } from '@/components/Editable';
import { NavTabs } from '@/components/NavTabs';
import { OrderDrawer } from './components/OrderDrawer';
import AutoResizeTable from '@/components/AutoResizeTable';
import { OrderIdColumn, TagBadge } from './components/OrderIdColumn';
import { OrderIdConfirmedAt } from './components/OrderIdConfirmedAt';
import { DrawerFormBulkUpdate } from './components/DrawerFormBulkUpdate';
import { IDUserIconPopover } from './components/IDUserIconPopover';
import { AccountingModal } from './components/AccountingModal';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import {
  ORDER_STATES,
  ORDER_STATE_CANCELED,
  ORDER_STATE_DESCRIPTION,
  ORDER_STATE_DRAFT,
} from './components/data';
import { ConsigneeSyncSelect } from '@/components/ConsigneeSyncSelect';
// import { SyncContainerState } from './components/SyncContainerState';
import { SnapshotRate } from './components/SnapshotRate';
import { SyncRateModal } from './components/SyncRateModal';
import qs from 'qs';
import { saveAs } from 'file-saver';
import { useLocation, history } from 'umi';
import { Message } from './components/Message';
import moment from 'moment-timezone';
import Import from './components/Import';

// const SALES_TYPE_OPTIONS = [
//   ...Object.keys(ORDER_SALES_TYPE_MAP).map((t: string) => ({
//     value: +t,
//     text: ORDER_SALES_TYPE_MAP[
//       t as unknown as keyof typeof ORDER_SALES_TYPE_MAP
//     ],
//   })),
// ];

const DOCustomFields: React.FC<{ order: any; render: any }> = React.memo(
  ({ order, render }) => {
    const [showMore, setShowMore] = useState(false);
    const dos = filter(
      [...get(order, 'delivery_orders', [])],
      (o) => o.state != 'canceled',
    );
    const first = dos.shift();
    return (
      <>
        {first && render(first)}
        {showMore &&
          dos.map(
            (d) => d && <React.Fragment key={d.id}>{render(d)}</React.Fragment>,
          )}
        {dos.length > 0 && !showMore && (
          <div>
            <a onClick={(e) => setShowMore(true)}>ShowMore({dos.length})</a>
          </div>
        )}
        {dos.length > 0 && showMore && (
          <div>
            <a onClick={(e) => setShowMore(false)}>ShowLess</a>
          </div>
        )}
      </>
    );
  },
);

DOCustomFields.displayName = 'DOCustomFields';

const states = ORDER_STATES;

export type TPerdiem = {
  '': number | string;
  'Perdiem Happening': number;
  'Perdiem Occurred': number;
};

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<TCollection<any>>();
  const [order, setOrder] = useState<any>(null);
  const [openBulkUpdate, setOpenBulkUpdate] = useState(false);
  const [updateData, setUpdateData] = useState<any>();
  const [activeRowId, setActiveRowId] = useState(0);
  // const [openSyncContainer, setOpenSyncContainer] = useState(false);
  const [openSyncRate, setOpenSyncRate] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const location = useLocation();

  const [statesCount, setStatesCount] = useState({});

  const [perdiem, setPerdiem] = useState<TPerdiem>({
    '': 0,
    'Perdiem Happening': 0,
    'Perdiem Occurred': 0,
  });

  // const [form] = useForm();
  const [filter] = useForm();

  const [activeKey, setActiveKey] = useState('');

  const pagination = usePagination(data);

  const parseUrlQueryString = () => {
    const values = qs.parse(location.search, { ignoreQueryPrefix: true });

    values.intermodal_region_id &&
      (values.intermodal_region_id = +values.intermodal_region_id);
    values.sales_id && (values.sales_id = +values.sales_id);
    values.company_id && (values.company_id = +values.company_id);

    filter.setFieldsValue(values);
    return values;
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const handleNewVendor = () => {
    showDrawer();
  };

  const fetchData = React.useCallback(
    async (pagination?: TablePaginationConfig, sorter: TSorter = {}) => {
      setLoading(true);

      const qsValue = qs.parse(location.search, { ignoreQueryPrefix: true });
      const _filter = Object.assign({}, filter.getFieldsValue(), qsValue);

      _filter.warehouse_city_id = get(_filter, 'warehouse_city.id', 0);

      delete _filter.warehouse_city;

      const uid = app.store.notification.view;
      if (uid) {
        _filter.search_id = uid;
        filter.setFieldValue('search_id', uid);
      }
      try {
        const resp = await app.service.get('orders', {
          params: {
            ..._filter,
            page: pagination?.current || 1,
            per_page: pagination?.pageSize || 20,
            ...sorter,
          },
        });

        setData(resp);

        if (has(location, 'query.id')) {
          return history.push({
            pathname: location.pathname,
          });
        }

        if (location.search) {
          return history.push('/orders');
        }
      } catch (err: any) {
        message.error(err.data?.message || err.data?.error);
      } finally {
        setLoading(false);
      }
    },
    [location.search],
  );

  const fetchoStatesCount = React.useCallback(async () => {
    setLoading(true);
    try {
      const resp = await app.service.get('orders/statesCount');
      setStatesCount(resp.data);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchoPerdiem = React.useCallback(async () => {
    setLoading(true);
    try {
      const resp = await app.service.get('containers/perdiem');
      setPerdiem(resp.data);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSearch = () => {
    fetchData();
  };

  const handlePatchUpdate = async (id: number, values: any) => {
    setLoading(true);
    try {
      await app.service.patch(`orders/${id}`, { data: values });
      fetchData(pagination);
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveBulkUpdate = async () => {
    fetchData({ page: 1 });
    fetchoStatesCount();
    handelCloseBulkUpdate();
  };
  const handleBulkUpdate = () => {
    if (selectedRowKeys.length <= 0) {
      message.error('Please select data to update');
      return false;
    }
    setOpenBulkUpdate(true);
  };

  const handelCloseBulkUpdate = () => {
    setSelectedRowKeys([]);
    setOpenBulkUpdate(false);
  };

  const refreshOrder = async (id: number) => {
    if (!data) {
      return;
    }
    const _order = await app.service.get(`orders/${id}`);
    const collection = data.data.map((item) => {
      if (item.id === _order.data.id) {
        return _order.data;
      }
      return item;
    });
    setData({ ...data, data: collection });
  };

  const handleEasyTrackOrder = async (id: number) => {
    setLoading(true);
    try {
      if (!data) {
        return;
      }
      await app.service.put(`orders/${id}/easyTrack`, {});
      message.success('Track Success');
      fetchData();
    } catch (error: any) {
      message.error(get(error, 'data.message', 'System Error'));
    } finally {
      setLoading(false);
    }
  };

  const handleChangeStatus = (id: number, value: any) => {
    if (value == ORDER_STATE_CANCELED) {
      setUpdateData({ id, state: value });
      Modal.confirm({
        title: 'Confirm',
        content:
          'Cancelling the order will sync status to all containers. Are you sure to cancel?',
        okText: 'confirm',
        cancelText: 'cancel',
        onOk: () => handlePatchUpdate(id, { state: value }),
      });
      // setOpenSyncContainer(true);
    } else {
      handlePatchUpdate(id, { state: value });
    }
  };

  // const handleYesToSyncContainer = async () => {
  //   await handlePatchUpdate(updateData.id, {
  //     state: updateData.state,
  //     isSync: true,
  //   });
  //   setOpenSyncContainer(false);
  //   setUpdateData(null);
  // };

  // const handleNoToSyncContainer = async () => {
  //   await handlePatchUpdate(updateData.id, { state: updateData.state });
  //   setOpenSyncContainer(false);
  //   setUpdateData(null);
  // };

  const handleExport = async () => {
    setLoading(true);

    try {
      await app.service.download('orders/export', {
        params: {
          ...filter.getFieldsValue(),
        },
      });
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
    setLoading(false);
  };

  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  // rowSelection object indicates the need for row selection
  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
  };

  const handleCopy = (id: number) => {
    setIsCopy(true);
    setVisible(true);
    setOrder({ id });
  };

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 200,
      render: (text, record) => {
        const today = moment().tz('America/Los_Angeles');
        const createdAt = get(
          record,
          'confirmed_at',
          get(record, 'created_at'),
        );
        const isToday = moment(createdAt).isSame(today, 'day');

        return (
          <div>
            <Space align="center">
              <OrderIdColumn record={record} refreshOrder={refreshOrder} />
              <IDUserIconPopover
                order={record}
                handlePatchUpdate={handlePatchUpdate}
              />
              {isToday && <TagBadge text="New" />}
              {record.state == ORDER_STATE_DRAFT && <TagBadge text="Draft" />}
              {record.user?.agent_id && <TagBadge text="Agent" />}
            </Space>
            <OrderIdConfirmedAt order={record} />
          </div>
        );
      },
    },
    // {
    //   title: 'CUS REF',
    //   dataIndex: 'customer_reference_number',
    //   key: 'customer_reference_number',
    //   width: 150,
    //   render: (text, record) => {
    //     return (
    //       <>
    //         <EditableText
    //           name="customer_reference_number"
    //           value={record.customer_reference_number || ''}
    //           onChange={(name: string, value: any) =>
    //             handlePatchUpdate(record.id, { [name]: value })
    //           }
    //         />
    //       </>
    //     );
    //   },
    // },
    {
      align: 'center',
      title: 'Verify',
      dataIndex: 'open_order_verified',
      key: 'open_order_verified',
      width: 80,
      render: (text, record) => {
        return (
          <>
            <Switch
              onChange={(v) =>
                handlePatchUpdate(record.id, { open_order_verified: v })
              }
              checked={!!record.open_order_verified}
            />
          </>
        );
      },
    },
    {
      title: 'MBL#',
      dataIndex: 'mbl',
      key: 'mbl',
      width: 160,
      render: (text, record) => <MBLLink mblNumber={record.mbl_number} />,
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      key: 'customer',
      width: 200,
      render: (text, record) => {
        return (
          <>
            <UserSyncSelect
              className="w100"
              allowClear
              value={record.user_id}
              onSelect={(user: any) =>
                handlePatchUpdate(record.id, { user_id: user.id })
              }
            />
          </>
        );
      },
    },
    {
      title: 'POD',
      dataIndex: 'port_of_discharge_eta',
      key: 'port_of_discharge_eta',
      sorter: true,
      width: 220,
      render: (text, record) => {
        return (
          <>
            <div>{record.port_of_discharge?.name}</div>
            <div>{record.port_of_discharge_eta}</div>
          </>
        );
      },
    },
    {
      title: 'IR',
      dataIndex: 'final_port_eta',
      key: 'final_port_eta',
      width: 220,
      sorter: true,
      render: (text, record) => {
        return (
          <>
            <div>{record.final_port?.name}</div>
            <div>{record.final_port_eta}</div>
          </>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (text, record) => {
        return (
          <>
            <Select
              className="w100"
              value={record.state}
              onChange={(v) => handleChangeStatus(record.id, v)}
            >
              {states.map((action) => (
                <Select.Option key={action} value={action}>
                  {action}
                </Select.Option>
              ))}
            </Select>
          </>
        );
      },
    },
    {
      align: 'center',
      title: 'Urgent',
      dataIndex: 'urgent',
      key: 'urgent',
      width: 80,
      render: (text, record) => {
        return (
          <>
            <Switch
              onChange={(v) => handlePatchUpdate(record.id, { urgent: v })}
              checked={record.urgent}
            />
          </>
        );
      },
    },

    {
      title: 'Action',
      align: 'center',
      dataIndex: 'action',
      key: 'action',
      width: 400,
      render: (text, record) => {
        return (
          <Space>
            <Button type="link" size="small">
              <Message
                open={!!app.store.notification.view}
                order={record}
                // onSave={(v) => handlePatchUpdate(record.id, v)}
              />
            </Button>
            <Button type="link" size="small">
              <Remark
                order={record}
                onSave={(v) => handlePatchUpdate(record.id, v)}
              />
            </Button>

            <Button type="link" size="small">
              <span className="text-gray">
                <Upload order={record} />
              </span>
            </Button>

            <Button type="link" size="small">
              <SnapshotRate order={record} />
            </Button>

            <Button type="link" size="small">
              <LogActivities
                id={record.id}
                icon={
                  <div className="text-gray">
                    <InfoCircleOutlined />
                  </div>
                }
                type="orders"
              />
            </Button>

            <Button type="link" size="small">
              <div className="text-gray">
                <Trucking order={record} refreshOrder={refreshOrder} />
              </div>
            </Button>
            <Button type="link" size="small">
              <div className="text-gray">
                <AccountingModal orderId={record.id} />
              </div>
            </Button>
            {/* <Button type="link" size="small">
              <div className="text-gray">
                <DollarCircleOutlined
                  onClick={() => {
                    setOrder(record);
                    setOpenSyncRate(true);
                  }}
                />
              </div>
            </Button> */}
            <Tooltip title="tracking">
              <Button
                type="link"
                size="small"
                onClick={() => handleEasyTrackOrder(record.id)}
              >
                <SyncOutlined className="text-gray" />
              </Button>
            </Tooltip>
            <Tooltip title="Copy order">
              <Button
                type="link"
                size="small"
                onClick={() => handleCopy(record.id)}
              >
                <CopyOutlined />
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    if (app.store.notification.view) {
      fetchData();
    }
  }, [app.store.notification.view]);

  useEffect(() => {
    if (!visible) {
      setIsCopy(false);
    }
  }, [visible]);

  React.useEffect(() => {
    parseUrlQueryString();
    fetchData();
    fetchoStatesCount();
    fetchoPerdiem();
  }, []);

  const handleTabChange = (key: string) => {
    filter.setFieldsValue({
      state: key,
    });
    setActiveKey(key);
    fetchData();
  };

  return (
    <div className={styles.main}>
      <Header
        title="Order"
        rightElement={
          <Space align="end" direction="horizontal">
            <Button type="primary" onClick={handleNewVendor}>
              New Order
            </Button>
            <Button onClick={handleBulkUpdate}>Bulk Update</Button>
            <Import onUploaded={fetchData} />
            <Button type="primary" onClick={handleExport} disabled={loading}>
              Export
            </Button>
          </Space>
        }
      />

      <NavTabs
        showIcon
        descriptionMap={ORDER_STATE_DESCRIPTION}
        items={statesCount}
        activeKey={activeKey}
        onChange={handleTabChange}
      />

      <Filter
        loading={loading}
        filter={filter}
        statesCount={statesCount}
        perdiem={perdiem}
        onSearch={handleSearch}
      />
      <div>
        <AutoResizeTable
          loading={loading}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          rowClassName={(record: any) => [
            record.id == activeRowId && 'rowActive',
          ]}
          onRow={(record: any, rowIndex: any) => {
            return {
              onClick: (event) => {
                setActiveRowId(record?.id || 0);
              },
            };
          }}
          onChange={(pagination, _2, sorter: any) => {
            const sort_value = sorter.order === 'ascend' ? 'asc' : 'desc';
            fetchData(pagination, {
              sort_by: sorter.field,
              sort_value,
            });
            filter.setFieldsValue({
              sort_by: sorter.field,
              sort_value,
            });
          }}
          pagination={pagination}
          bordered
          size="small"
          rowKey="id"
          columns={columns}
          dataSource={data?.data}
          scroll={{
            x: 1500,
          }}
          sticky
        />
      </div>
      {visible && (
        <OrderDrawer
          visible={visible}
          setVisible={setVisible}
          orderId={order?.id}
          isCopy={isCopy}
          handelAdded={fetchData}
          refreshOrder={refreshOrder}
        />
      )}

      <DrawerFormBulkUpdate
        orders={selectedRows}
        open={openBulkUpdate}
        onClose={handelCloseBulkUpdate}
        onSubmit={handleSaveBulkUpdate}
      />

      {/* <SyncContainerState
        open={openSyncContainer}
        loading={loading}
        onNo={handleNoToSyncContainer}
        onYes={handleYesToSyncContainer}
      /> */}

      <SyncRateModal
        order={order}
        open={openSyncRate}
        onCancel={() => {
          setOpenSyncRate(false);
        }}
        updateOrder={refreshOrder}
      />
    </div>
  );
};

export default Index;
