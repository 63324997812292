import { Button, Select, Input, Divider, Space, Tag } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { ChassisTypeDrawer } from '@/pages/entity/chassisType/components/ChassisTypeDrawer';
import { CACHE_CHASSIS_TYPE_KEY } from '@/stores/cache';
import { useApp } from '@/utils/useapp';
import {
  TYPE_REGULAR,
  TYPE_TRI_AXLE,
} from '@/pages/entity/chassisType/components/data';
import { useObserver } from 'mobx-react';
import { isEmpty } from 'lodash';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';

import { SyncCacheModelSelectProps } from './SyncCacheModelSelect';

type IChassisTypeSyncSelect = {
  selected: Array<number>;
  onSelect: (rows: Array<any>) => void;
};

export const ChassisTypeSyncSelect: React.FC<
IChassisTypeSyncSelect & SyncCacheModelSelectProps
> = ({ selected, onSelect, ...props }) => {
  const app = useApp();

  const [id, setId] = React.useState(0);
  const [visible, setVisible] = React.useState(false);
  const [chassisTypes, setChassisTypes] = React.useState<Array<any>>();

  const selectedOptions = useMemo(
    () => chassisTypes?.filter((c) => selected?.includes(c.id)) || [],
    [chassisTypes, selected],
  );

  const loading = useMemo(
    () => app.store.cache.loadingMap[CACHE_CHASSIS_TYPE_KEY] !== false,
    [app.store.cache],
  );

  const handleSaved = useCallback(
    (data: any) => {
      app.store.cache.fetch(CACHE_CHASSIS_TYPE_KEY, true);

      if (!chassisTypes?.find((c: any) => c.id == data.id)) {
        onSelect([...selectedOptions, data]);
      }
    },
    [selectedOptions, chassisTypes],
  );

  const handleClosed = () => {
    setVisible(false);
    setId(0);
  };

  const handleEdit = (id: number) => {
    setId(id);
    setVisible(true);
  };

  const handleOnChange = (value: any, options: any) => {
    onSelect(chassisTypes?.filter((c: any) => value.includes(c.id)));
  };

  const handleSelectAllChassisType = async () => {
    onSelect(chassisTypes);
  };
  const handleSelectAllRegularChassisType = () => {
    onSelect(chassisTypes?.filter((c: any) => c.type == TYPE_REGULAR));
  };

  const handleSelectAllTrixAxleChassisType = () => {
    onSelect(chassisTypes?.filter((c: any) => c.type == TYPE_TRI_AXLE));
  };

  const observeCache = () => {
    useEffect(() => {
      if (undefined == app.store.cache.data[CACHE_CHASSIS_TYPE_KEY]) {
        app.store.cache.fetch(CACHE_CHASSIS_TYPE_KEY);
      }

      if (app.store.cache.data[CACHE_CHASSIS_TYPE_KEY]) {
        setChassisTypes(app.store.cache.data[CACHE_CHASSIS_TYPE_KEY]);
      }
    }, [app.store.cache.data[CACHE_CHASSIS_TYPE_KEY]]);
  };

  const _props = useMemo(
    () => ({
      ...props,
      value: isEmpty(selected) ? [] : selected,
    }),
    [props, selected],
  );

  return useObserver(() => {
    observeCache();
    return (
      <>
        <Input.Group compact>
          <Button
            type="ghost"
            onClick={() => {
              setId(0);
              setVisible(true);
            }}
            className="ant-btn-icon-only select-modal-icon">
            <span className="icon-gray">
              {' '}
              <PlusOutlined />
            </span>
          </Button>
          <Select
            {..._props}
            loading={loading}
            optionFilterProp={'label'}
            showSearch
            mode="multiple"
            allowClear
            className="select-minus-edit-w"
            optionLabelProp="label"
            dropdownRender={(menu) => {
              return (
                <>
                  {menu}
                  <Divider style={{ margin: '8px 0' }} />
                  <Space style={{ padding: '0 8px 4px' }}>
                    <Button onClick={handleSelectAllChassisType}>All</Button>
                    <Button onClick={handleSelectAllRegularChassisType}>
                      All Regular Chassis
                    </Button>
                    <Button onClick={handleSelectAllTrixAxleChassisType}>
                      All Trix-axle Chassis
                    </Button>
                  </Space>
                </>
              );
            }}
            onChange={handleOnChange}
            dropdownStyle={{ zIndex: 10010 }}>
            {chassisTypes?.map((chassisType: any, index: number) => (
              <Select.Option
                key={index}
                value={chassisType.id}
                label={chassisType.name}>
                <div className="flex justify-between align-center">
                  <div>{chassisType.name}</div>
                  <div>
                    <Button
                      type="link"
                      onClick={(e) => handleEdit(chassisType.id)}>
                      <EditOutlined />
                    </Button>
                  </div>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Input.Group>
        {visible && (
          <ChassisTypeDrawer
            visible={visible}
            id={id}
            onClose={handleClosed}
            onSaved={handleSaved}
          />
        )}
      </>
    );
  });
};
