import { Space, Typography } from 'antd';
import { FTLShipmentInterface } from '../Interfaces/ShipmentInterface';
import { CopyButton } from '@/components/CopyButton';
import { useApp } from '@/utils/useapp';

const VendorShipmentIdColumn: React.FC<{
  shipment: FTLShipmentInterface;
}> = ({ shipment }) => {
  const app = useApp();

  const handleVendorShipmentClick = async () => {
    if (!shipment.vendor_id || !shipment.vendor_shipment_id) {
      return;
    }

    try {
      const resp = await app.service.get(`ftl/vendors/${shipment.vendor_id}`);
      const vendor = resp.data;
      if (!vendor.quick_link) {
        return;
      }

      const url =
        vendor.quick_link.includes('$') && vendor.use_shipment_id
          ? vendor.quick_link.replace('$', shipment.vendor_shipment_id)
          : vendor.quick_link;

      window.open(url, '_blank');
    } catch (e) {
      console.error('Failed to open vendor tracking:', e);
    }
  };

  return (
    <div>
      <Space>
        {(shipment.vendor_shipment_id ?? '') && (
          <>
            <Typography.Link onClick={handleVendorShipmentClick}>
              {shipment.vendor_shipment_id}
            </Typography.Link>
            {<CopyButton value={shipment.vendor_shipment_id ?? ''} />}
          </>
        )}
      </Space>
    </div>
  );
};

export default VendorShipmentIdColumn;
