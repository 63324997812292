import { useApp } from '@/utils/useapp';
import type { ColumnsType } from 'antd/lib/table';
import {
  Button,
  Drawer,
  Input,
  message,
  Space,
  Tag,
  Switch,
  InputNumber,
  Table,
  Tooltip,
  Radio,
  Select,
} from 'antd';
import React, { ReactElement } from 'react';
import { castArray, get, last } from 'lodash';
import { InfoCircleOutlined } from '@ant-design/icons';
import { cache } from '@/stores';
import {
  CACHE_ACTIVE_TERMINAL_KEY,
  CACHE_INTERMODALREGION_KEY,
  useAsyncCache,
} from '@/stores/cache';
import { ActiveTerminalSyncSelect } from '@/components/ActiveTerminalSyncSelect';

interface EditProps {
  intermodalRegionId?: number | number[]; // don't add intermodalRegionId if you want to create a new config
  rateEngineConfigId?: number;
  children: ReactElement;
  onSaved?: () => void;
}

const Edit: React.FC<EditProps> = ({
  intermodalRegionId,
  rateEngineConfigId,
  children,
  onSaved,
}) => {
  const app = useApp();
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const fetchData = React.useCallback(async () => {
    if (!rateEngineConfigId) {
      setData({});
      return;
    }

    setLoading(true);
    try {
      const resp = await app.service.get(
        `rateEngineConfigs/${rateEngineConfigId}`,
      );

      setData({ rate_engine_config: resp.data });
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
    setLoading(false);
  }, [rateEngineConfigId]);

  const addRange = React.useCallback(() => {
    let rangeStart = 0;
    const lastItem = last(get(data, 'rate_engine_config.ranges', []));
    if (lastItem && lastItem.end) {
      rangeStart = lastItem.end;
    }

    setData({
      ...data,
      rate_engine_config: {
        ...(data.rate_engine_config || {}),
        ranges: [
          ...(get(data, 'rate_engine_config.ranges') || []),
          {
            start: rangeStart,
            end: rangeStart + 100,
            markup: 0,
            min: 0,
          },
        ],
      },
    });
  }, [data]);

  const removeRange = React.useCallback(
    (index) => {
      setData({
        ...data,
        rate_engine_config: {
          ...data.rate_engine_config,
          ranges: data.rate_engine_config.ranges.filter(
            (_v: any, i: number) => i !== index,
          ),
        },
      });
    },
    [data],
  );

  const changeActive = React.useCallback(
    (checked: boolean) => {
      setData({
        ...data,
        rate_engine_config: {
          ...data.rate_engine_config,
          active: checked,
        },
      });
    },
    [data],
  );

  const changeLinearOnly = React.useCallback(
    (checked: boolean) => {
      setData({
        ...data,
        rate_engine_config: {
          ...data.rate_engine_config,
          linear_algo_only: checked,
        },
      });
    },
    [data],
  );

  const changeNoLinearAlgoMarkup = React.useCallback(
    (value: any) => {
      setData({
        ...data,
        rate_engine_config: {
          ...data.rate_engine_config,
          no_linear_algo_markup: value,
        },
      });
    },
    [data],
  );

  const changeName = React.useCallback(
    (value: any) => {
      setData({
        ...data,
        rate_engine_config: {
          ...data.rate_engine_config,
          name: value,
        },
      });
    },
    [data],
  );

  const changeRange = React.useCallback(
    (index: number, values: any) => {
      setData({
        ...data,
        rate_engine_config: {
          ...data.rate_engine_config,
          ranges: data.rate_engine_config.ranges.map((row: any, i: number) => {
            if (i === index) {
              return values;
            } else {
              return row;
            }
          }),
        },
      });
    },
    [data],
  );
  const changeToll = React.useCallback(
    (checked: boolean) => {
      setData({
        ...data,
        rate_engine_config: {
          ...data.rate_engine_config,
          toll: checked,
        },
      });
    },
    [data],
  );

  const changeTerminalLevel = React.useCallback(
    (checked: boolean) => {
      setData({
        ...data,
        rate_engine_config: {
          ...data.rate_engine_config,
          terminal_level: checked,
        },
      });
    },
    [data],
  );

  const handleSave = React.useCallback(async () => {
    if (!data || !data.rate_engine_config) {
      message.error('Please input data.');
      return;
    }

    setLoading(true);

    try {
      await app.service.patch(
        'rateEngine/updateIntermodalRegionRateEngineSetting',
        {
          data: {
            intermodal_region_ids: intermodalRegionId
              ? castArray(intermodalRegionId)
              : undefined,
            rate_engine_config: data.rate_engine_config,
          },
        },
      );
      cache.fetch(CACHE_INTERMODALREGION_KEY, true);
      message.success('saved');
      onSaved && onSaved();
    } catch (e: any) {
      message.error(e.data.message || e.data.error || 'System Error');
    }
    setLoading(false);
  }, [data]);

  const handleOpen = () => {
    setOpen(true);
    fetchData();
  };

  const handleClose = () => {
    setOpen(false);
    setData(null);
  };

  const columns: ColumnsType<any> = React.useMemo(
    () => [
      {
        title: 'Start',
        render: (text, record, index) => (
          <>
            <InputNumber
              size="small"
              placeholder="Start"
              value={record.start}
              onChange={(value) =>
                changeRange(index, {
                  ...record,
                  start: value,
                })
              }
            />
          </>
        ),
      },
      {
        title: 'End',
        render: (text, record, index) => (
          <>
            <InputNumber
              size="small"
              placeholder="End"
              value={record.end}
              onChange={(value) =>
                changeRange(index, {
                  ...record,
                  end: value,
                })
              }
            />
          </>
        ),
      },
      {
        title: (
          <>
            <span>Markup</span>
            <Tooltip title="If there is no markup, please leave 0; If it is a fixed value, please enter a numeric value, like 10, 20, 50...;If it is pecentage, please input data ending with %. For exmaple, 10%, 15%, 20%...;">
              <InfoCircleOutlined />
            </Tooltip>
          </>
        ),
        render: (text, record, index) => (
          <>
            <Input
              size="small"
              placeholder="Markup"
              value={record.markup}
              onChange={(e) => {
                let v = e.target.value;
                /* eslint-disable */
                v = v.replace(/[^\d\.%]/g, '');
                changeRange(index, {
                  ...record,
                  markup: v,
                });
              }}
            />
          </>
        ),
      },
      {
        title: 'Min',
        render: (text, record, index) => (
          <>
            <InputNumber
              size="small"
              placeholder="Min"
              value={record.min}
              onChange={(value) =>
                changeRange(index, {
                  ...record,
                  min: value,
                })
              }
            />
          </>
        ),
      },
      {
        title: (
          <Space>
            <span>Action</span>
            <Button size="small" onClick={() => addRange()}>
              Add
            </Button>
          </Space>
        ),
        render: (text, record: any, index) => (
          <>
            <Button size="small" onClick={() => removeRange(index)}>
              Remove
            </Button>
          </>
        ),
      },
    ],
    [changeRange, removeRange, addRange],
  );

  const addTerminal = React.useCallback(() => {
    setData({
      ...data,
      rate_engine_config: {
        ...(data.rate_engine_config || {}),
        terminal_level_setting: [
          ...(get(data, 'rate_engine_config.terminal_level_setting') || []),
          {
            method: 'auto',
          },
        ],
      },
    });
  }, [data]);

  const addAllTerminal = React.useCallback(async () => {
    const _terminals = await useAsyncCache(CACHE_ACTIVE_TERMINAL_KEY);
    const terminals = _terminals.filter(
      (t) => t.intermodal_region_id == intermodalRegionId,
    );

    setData({
      ...data,
      rate_engine_config: {
        ...(data.rate_engine_config || {}),
        terminal_level_setting: terminals.map((t) => ({
          terminal_id: t.id,
          method: 'auto',
        })),
      },
    });
  }, [data, intermodalRegionId]);

  const removeTerminal = React.useCallback(
    (index) => {
      setData({
        ...data,
        rate_engine_config: {
          ...data.rate_engine_config,
          terminal_level_setting:
            data.rate_engine_config.terminal_level_setting.filter(
              (_v: any, i: number) => i !== index,
            ),
        },
      });
    },
    [data],
  );

  const changeTerminal = React.useCallback(
    (index: number, values: any) => {
      setData({
        ...data,
        rate_engine_config: {
          ...data.rate_engine_config,
          terminal_level_setting:
            data.rate_engine_config.terminal_level_setting.map(
              (row: any, i: number) => {
                if (i === index) {
                  return values;
                } else {
                  return row;
                }
              },
            ),
        },
      });
    },
    [data],
  );

  const terminalColumns: ColumnsType<any> = React.useMemo(
    () => [
      {
        title: 'Terminal',
        width: '40%',
        render: (text, record: any, index) => {
          return (
            <ActiveTerminalSyncSelect
              size="small"
              className="w100"
              intermodalRegionId={intermodalRegionId}
              value={record.terminal_id}
              onChange={(value) =>
                changeTerminal(index, { ...record, terminal_id: value })
              }
            />
          );
        },
      },
      {
        title: 'Method',
        width: '15%',
        render: (text, record: any, index) => {
          return (
            <Select
              size="small"
              value={record.method}
              onChange={(value) =>
                changeTerminal(index, { ...record, method: value })
              }>
              <Select.Option value="auto">Auto</Select.Option>
              <Select.Option value="same">Same</Select.Option>
            </Select>
          );
        },
      },
      {
        title: 'Markup on Base',
        width: '25%',
        render: (text, record: any, index) => {
          return (
            <Input
              size="small"
              value={record.markup}
              onChange={(e) =>
                changeTerminal(index, { ...record, markup: e.target.value })
              }
            />
          );
        },
      },
      {
        title: (
          <Space>
            <span>Action</span>
            <Button size="small" onClick={() => addTerminal()}>
              Add
            </Button>
            <Button size="small" onClick={() => addAllTerminal()}>
              All
            </Button>
          </Space>
        ),
        width: '20%',
        render: (text, record: any, index) => (
          <>
            <Button size="small" onClick={() => removeTerminal(index)}>
              Remove
            </Button>
          </>
        ),
      },
    ],
    [
      changeTerminal,
      removeTerminal,
      addTerminal,
      addAllTerminal,
      intermodalRegionId,
    ],
  );

  return (
    <>
      <div onClick={handleOpen}>{children}</div>
      {!!open && data && (
        <Drawer
          title="Config Seting"
          placement="right"
          onClose={handleClose}
          width="960px"
          open={open}>
          <div>
            <div>
              <h3>Name</h3>
              <Input
                value={get(data, 'rate_engine_config.name')}
                onChange={(e) => changeName(e.target.value)}
                required
              />
            </div>
            <div className="mt-lg">
              <h3>Range</h3>
              <Table
                pagination={false}
                size="small"
                columns={columns}
                dataSource={get(data, 'rate_engine_config.ranges') || []}
              />
            </div>

            <div className="mt-lg">
              <h3>Active</h3>
              <Switch
                checkedChildren="Yes"
                unCheckedChildren="No"
                checked={!!get(data, 'rate_engine_config.active')}
                onChange={changeActive}
              />
            </div>

            <div className="mt-lg">
              <h3>Linear Algo Only</h3>
              <Switch
                checkedChildren="Yes"
                unCheckedChildren="No"
                checked={!!get(data, 'rate_engine_config.linear_algo_only')}
                onChange={changeLinearOnly}
              />
            </div>
            <div className="mt-lg">
              <h3>No Linear Algo Markup</h3>
              <Input
                value={get(data, 'rate_engine_config.no_linear_algo_markup')}
                onChange={(e) => changeNoLinearAlgoMarkup(e.target.value)}
              />
            </div>
            <div className="mt-lg">
              <h3>Toll</h3>
              <Switch
                checkedChildren="Yes"
                unCheckedChildren="No"
                checked={!!get(data, 'rate_engine_config.toll')}
                onChange={changeToll}
              />
            </div>
            <div className="mt-lg">
              <h3>Terminal Level</h3>
              <Switch
                checkedChildren="Yes"
                unCheckedChildren="No"
                checked={!!get(data, 'rate_engine_config.terminal_level')}
                onChange={changeTerminalLevel}
              />
            </div>
            {!!get(data, 'rate_engine_config.terminal_level') && (
              <>
                <div className="mt-lg">
                  <h3>Terminals</h3>
                  <Table
                    pagination={false}
                    size="small"
                    columns={terminalColumns}
                    dataSource={
                      get(data, 'rate_engine_config.terminal_level_setting') ||
                      []
                    }
                  />
                </div>
              </>
            )}

            <div className="mt-lg">
              <Button
                size="small"
                type="primary"
                onClick={handleSave}
                loading={loading}>
                Save
              </Button>
            </div>
          </div>
        </Drawer>
      )}
    </>
  );
};

export default Edit;
