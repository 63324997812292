import styles from 'res/css/ui.scss';
import React, { ReactElement } from 'react';
import { FormInstance } from 'antd/es/form';
import { Form, Switch } from 'antd';

type TFormItem = {
  form: FormInstance;
  name: string;
  label: string;
  elementControlledByStatus?: ReactElement;
  toListenNameRequired: (name: string, required: boolean) => void;
};
export const BulkUpdateFormItem: React.FC<TFormItem> = ({
  form,
  name,
  label,
  elementControlledByStatus = null,
  toListenNameRequired,
}) => {
  const [stateName, setStateName] = React.useState('state');

  const handleStateChange = (checked: any) => {
    form.setFieldValue(stateName, checked);
    toListenNameRequired(name, checked);
  };

  React.useEffect(() => {
    setStateName(name + 'State');
  }, [name]);

  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue }) => (
        <>
          <div style={{ marginBottom: '10px' }}>
            <Form.Item name={stateName} noStyle valuePropName="checked">
              <Switch onChange={handleStateChange} />
              <span> {label}</span>
            </Form.Item>
          </div>
          {elementControlledByStatus && (
            <Form.Item
              name={name}
              label=""
              rules={[
                {
                  required: getFieldValue([stateName]) ? true : false,
                  message: label + ' is required',
                },
              ]}>
              {elementControlledByStatus}
            </Form.Item>
          )}
        </>
      )}
    </Form.Item>
  );
};
