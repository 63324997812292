import { Card, Typography, Space, Tag } from 'antd';
import type { FileStatus, DocumentType, InvoiceType } from '../interface/types';

interface Props {
  file: FileStatus | null;
  previewUrl?: string;
}

const FilePreview = ({ file, previewUrl }: Props) => {
  const getDocumentTypeTag = (
    type?: DocumentType,
    invoiceType?: InvoiceType,
  ) => {
    const colorMap: Record<string, string> = {
      invoice: 'blue',
      mbl: 'green',
      unknown: 'default',
    };

    const invoiceColorMap: Record<string, string> = {
      DRAY: 'purple',
      LTL: 'orange',
      FTL: 'cyan',
    };

    return (
      <Space>
        {type && (
          <Tag color={colorMap[type]}>{type.toString().toUpperCase()}</Tag>
        )}
        {type === 'invoice' && invoiceType && (
          <Tag color={invoiceColorMap[invoiceType]}>{invoiceType}</Tag>
        )}
      </Space>
    );
  };

  const renderPreview = () => {
    if (!file || !previewUrl) {
      return (
        <div className="empty-preview">
          <Typography.Text type="secondary">
            Select a file to preview
          </Typography.Text>
        </div>
      );
    }

    const fileExtension = file.fileName.split('.').pop()?.toLowerCase();

    if (fileExtension === 'pdf') {
      return (
        <iframe
          src={`${previewUrl}#zoom=115`}
          style={{
            width: '100%',
            height: 'calc(100vh - 250px)',
            border: 'none',
          }}
          title="PDF preview"
        />
      );
    } else {
      return (
        <img
          src={previewUrl}
          alt={file.fileName}
          style={{
            maxWidth: '100%',
            maxHeight: 'calc(100vh - 250px)',
            objectFit: 'contain',
          }}
        />
      );
    }
  };

  return (
    <Card
      title={
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <span>File Preview</span>
          {file && getDocumentTypeTag(file.documentType, file.invoiceType)}
        </div>
      }
      className="section-card"
      bodyStyle={{
        padding: '16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 'calc(100vh - 250px)',
      }}
    >
      {renderPreview()}
    </Card>
  );
};

export default FilePreview;
