import React from 'react';
import { useApp } from '@/utils/useapp';
import { FormInstance, Form } from 'antd';

import {
  BELONG_MODEL_REQUESTED_RATE,
  REQUEST_IR,
  REQUEST_WAREHOUSE_CITY,
  REQUEST_WAREHOUSE_ZIPCODE,
  REQUEST_MEMO,
  MY_SIGNATURE,
} from '@/pages/setting/emailTemplate/components/data';
import { SendEmailWithTemplates } from '@/components/EmailForm/SendWithTemplate';
import { DocumentsStoreLocal } from '@/components/Upload/DocumentsStoreLocal';
interface Props {
  rates: any;
  intermodalRegion: any;
  requestWarehouses: any;
  sending: boolean;
  form: FormInstance;
  onSending: (sending: boolean) => void;
  onCancelSend: () => void;
  onSent: () => void;
}
const gridStyle: React.CSSProperties = {
  width: '50%',
  height: '90%',
  overflowY: 'scroll',
};

const Documents: React.FC<{
  form: FormInstance;
}> = ({ form }) => {
  const handleFilesChange = async (newFiles: any) => {
    form.setFieldValue('files', newFiles);
  };
  return (
    <>
      <Form.Item name={'files'} hidden noStyle></Form.Item>
      <Form.Item label="Documents">
        <DocumentsStoreLocal onFilesChange={handleFilesChange} />
      </Form.Item>
    </>
  );
};

export const SendEmail: React.FC<Props> = ({
  rates,
  intermodalRegion,
  requestWarehouses,
  sending,
  form,
  onCancelSend,
  onSending,
  onSent,
}) => {
  const app = useApp();
  const sendURI = 'requestedRates/autoSend';
  const previewURI = 'requestedRates/autoSendPreview';

  const replaceRequestIR = (toChangeStr: any) => {
    return toChangeStr.replaceAll(REQUEST_IR, intermodalRegion.name);
  };

  const resolveTemplateSubject = (subject: any) => {
    if (!subject) {
      return subject;
    }
    const len = requestWarehouses.length;
    let warehouseStr = '';
    if (len > 0) {
      warehouseStr +=
        requestWarehouses[0].name + ' ' + requestWarehouses[0].zipcode;
      if (len > 1) {
        warehouseStr += ' +' + (len - 1) + '';
      }
    }

    subject = replaceRequestIR(subject);
    subject = subject.replaceAll(REQUEST_WAREHOUSE_CITY, warehouseStr);
    subject = subject.replaceAll(REQUEST_WAREHOUSE_ZIPCODE, '');
    return subject;
  };

  const resolveTemplateBody = (body: any) => {
    if (!body) {
      return body;
    }

    let warehouseStr = '';
    let requestMemoStr = '';

    if (requestWarehouses.length == 1) {
      warehouseStr +=
        requestWarehouses[0].name + ' ' + requestWarehouses[0].zipcode;
    } else if (requestWarehouses.length > 1) {
      warehouseStr += '<ol>';

      requestWarehouses.map((w: any) => {
        warehouseStr += '<li><strong>';

        warehouseStr += w.name;
        if (w.zipcode) {
          warehouseStr += ' ' + w.zipcode;
        }

        warehouseStr += '</strong></li>';
      });

      warehouseStr += '</ol>';
    }

    if (rates.length == 1) {
      requestMemoStr += rates[0].memo;
    } else if (rates.length > 1) {
      requestMemoStr = '<ul>';

      rates.map(
        (r: any) => r.memo && (requestMemoStr += '<li>' + r.memo + '</li>'),
      );

      requestMemoStr += '</ul>';
    }

    body = replaceRequestIR(body);
    body = body.replaceAll(REQUEST_WAREHOUSE_CITY, warehouseStr);
    body = body.replaceAll(REQUEST_WAREHOUSE_ZIPCODE, '');
    body = body.replaceAll(REQUEST_MEMO, requestMemoStr);
    body = body.replaceAll(
      MY_SIGNATURE,
      app.store.auth?.user?.email_signature || '',
    );

    return body;
  };

  const resolveTemplate = (subject: string, body: string) => {
    subject = resolveTemplateSubject(subject);
    body = resolveTemplateBody(body);
    return { subject, body };
  };

  return (
    <>
      <SendEmailWithTemplates
        hasSendToList={false}
        target={BELONG_MODEL_REQUESTED_RATE}
        targetId={0}
        sending={sending}
        onSending={onSending}
        form={form}
        resolveTemplate={resolveTemplate}
        onCancelSend={onCancelSend}
        sendURI={sendURI}
        previewURI={previewURI}
        emailCustomProps={<Documents form={form} />}
        onSent={onSent}
      />
    </>
  );
};
