import { useEffect, useState } from 'react';
import { message } from 'antd';
import { useApp } from '@/utils/useapp';
import { Amount, AmountData } from './components/Amount';
import { Customer, CustomerData } from './components/Customer';
import { Trend, TrendData } from './components/Trend';
import { Rank } from './components/Rank';
import { Gap } from '@/components/Gap';
import moment from 'moment';
import { Efficiency, EfficiencyData } from './components/Efficiency';

interface IndexData {
  quoteAmount: AmountData;
  shipmentAmount: AmountData;
  customerAmount: CustomerData;
  trends: TrendData;
  efficiency: EfficiencyData;
}

const Index = () => {
  const [indexData, setIndexData] = useState<IndexData>({
    quoteAmount: {
      all: { value: 0, today: 0 },
      admin: { value: 0, today: 0 },
      client: { value: 0, today: 0 },
    },
    shipmentAmount: {
      all: { value: 0, today: 0 },
      admin: { value: 0, today: 0 },
      client: { value: 0, today: 0 },
    },
    customerAmount: {
      newCustomer: 0,
      lostCustomer: 0,
    },
    trends: {
      quote: [],
      shipment: [],
      ratio: [],
    },
    efficiency: {
      bol: [],
      pickup: [],
      delivery: [],
    },
  });
  const {
    quoteAmount,
    shipmentAmount,
    customerAmount,
    trends,
    efficiency,
  } = indexData;
  const app = useApp();

  const fetchIndexData = async () => {
    try {
      const resp = await app.service.get('tl/ltl/dashboard');
      setIndexData(resp.data);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    }
  };

  useEffect(() => {
    fetchIndexData();
  }, []);

  return (
    <div style={{ padding: '16px' }}>
      <Amount
        initialQuoteAmount={quoteAmount}
        initialShipmentAmount={shipmentAmount}
      />
      <Gap height="16px" />
      <Customer
        newCustomer={customerAmount.newCustomer}
        lostCustomer={customerAmount.lostCustomer}
      />
      <Gap height="16px" />
      <Trend
        quote={trends.quote}
        shipment={trends.shipment}
        ratio={trends.ratio}
      />
      <Gap height="32px" />
      <Rank
        startDate={moment().startOf('month').format('YYYY-MM-DD')}
        endDate={moment().endOf('month').format('YYYY-MM-DD')}
      />
      <Gap height="32px" />
      <Efficiency
        bol={efficiency.bol}
        pickup={efficiency.pickup}
        delivery={efficiency.delivery}
      />
    </div>
  );
};

export default Index;
