import React, { useEffect } from 'react';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Row,
  Space,
} from 'antd';
import moment from 'moment';
import { useApp } from '@/utils/useapp';
import { useForm } from 'antd/lib/form/Form';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { IntermodalRegionSelect } from '@/components/IntermodalRegionSelect';
import { ContainerTypeSelect } from '@/components/ContainerTypeSelect';
import { CitySelect } from '@/components/CitySelect';
import { OCEAN_PORT, RAMP_PORT } from '@/pages/dispatchs/components/data';

interface Props {
  model?: any;
  onCreated: () => void;
  onClose: () => void;
}

export const DrawerForm: React.FC<Props> = ({ model, onCreated, onClose }) => {
  const app = useApp();
  const [loading, setLoading] = React.useState(false);

  const [form] = useForm();

  const id = model?.id;

  const destinationType = Form.useWatch('destination_type', form);
  const portOfDischarge = Form.useWatch('port_of_discharge_id', form);
  const portOfDischargeEta = Form.useWatch('port_of_discharge_eta', form);

  useEffect(() => {
    if (destinationType && destinationType == OCEAN_PORT) {
      form.setFieldValue(
        'final_port_id',
        form.getFieldValue('port_of_discharge_id') || null,
      );
      form.setFieldValue(
        'final_port_eta',
        form.getFieldValue('port_of_discharge_eta') || null,
      );
    }
  }, [destinationType, portOfDischarge, portOfDischargeEta]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const resp = await app.service.get(`preOrders/${id}`);
      // console.log(resp.data);
      form.setFieldsValue({ ...resp.data });
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  const handelSave = async (values: any) => {
    setLoading(true);
    try {
      id
        ? await app.service.put(`preOrders/${id}`, {
          data: values,
        })
        : await app.service.post('preOrders', {
          data: values,
        });
      onCreated && onCreated();
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  React.useEffect(() => {
    form.resetFields();
    if (id) {
      form.setFieldsValue(model.filter || {});
      fetchData();
    }
  }, [id]);

  return (
    <>
      <Form
        layout="vertical"
        disabled={loading}
        onFinish={handelSave}
        form={form}>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item
              name="destination_type"
              label="Destination Type"
              rules={[
                { required: true, message: 'Destination Type is required.' },
              ]}>
              <Radio.Group>
                <Radio value={OCEAN_PORT}>Ocean Port</Radio>
                <Radio value={RAMP_PORT}>Ramp Port</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="Port of Discharge" shouldUpdate required>
              {({ getFieldValue, setFieldsValue }) => (
                <Input.Group compact>
                  <Form.Item
                    name="port_of_discharge_id"
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: 'Port of Discharge is required',
                      },
                    ]}>
                    <IntermodalRegionSelect
                      placeholder="Port of Discharge"
                      style={{ width: '40%' }}
                    />
                  </Form.Item>
                  <Form.Item
                    noStyle
                    name="port_of_discharge_eta"
                    rules={[{ required: true, message: 'ETA is required' }]}>
                    <Input hidden />
                  </Form.Item>
                  <DatePicker
                    placeholder="ETA"
                    style={{ width: '60%' }}
                    value={
                      getFieldValue('port_of_discharge_eta')
                        ? moment(getFieldValue('port_of_discharge_eta'))
                        : null
                    }
                    onChange={(date, dateString) =>
                      setFieldsValue({
                        port_of_discharge_eta: dateString,
                      })
                    }
                  />
                </Input.Group>
              )}
            </Form.Item>
            <Form.Item label="Final Port" shouldUpdate required>
              {({ getFieldValue, setFieldsValue }) => (
                <Input.Group compact>
                  <Form.Item
                    noStyle
                    name="final_port_id"
                    rules={[
                      { required: true, message: 'Final Port is required' },
                    ]}>
                    <IntermodalRegionSelect
                      disabled={getFieldValue('destination_type') == OCEAN_PORT}
                      placeholder="Select POD / Ramp"
                      style={{ width: '40%' }}
                    />
                  </Form.Item>
                  <Form.Item
                    noStyle
                    name="final_port_eta"
                    rules={[{ required: true, message: 'ETA is required' }]}>
                    <Input hidden />
                  </Form.Item>

                  <DatePicker
                    disabled={getFieldValue('destination_type') == OCEAN_PORT}
                    placeholder="ETA"
                    style={{ width: '60%' }}
                    value={
                      getFieldValue('final_port_eta')
                        ? moment(getFieldValue('final_port_eta'))
                        : null
                    }
                    onChange={(date, dateString) =>
                      setFieldsValue({
                        final_port_eta: dateString,
                      })
                    }
                  />
                </Input.Group>
              )}
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              <Form.Item
                name="user_id"
                label="user (Customer)"
                rules={[{ required: true, message: 'Customer is required' }]}
                shouldUpdate>
                <UserSyncSelect />
              </Form.Item>
            </Form.Item>
            <Form.Item
              label="Delivery Zipcode"
              className="mb0"
              name="delivery_zipcode"
              rules={[{ required: true, message: 'required' }]}>
              <Input />
            </Form.Item>
            <Row gutter={7}>
              <Col span={12}>
                <Form.Item
                  label="Container Quantity"
                  className="mb0"
                  name="cntr_qty"
                  rules={[{ required: true, message: 'required' }]}>
                  <InputNumber />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Container Size"
                  className="mb0"
                  name="cntr_size"
                  rules={[{ required: true, message: 'required' }]}>
                  <ContainerTypeSelect getValueForOption="code" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldValue }) => (
                <Form.Item
                  name="city_id"
                  label="City"
                  labelCol={{ span: 24 }}
                  rules={[{ required: true, message: 'required' }]}
                  wrapperCol={{ span: 24 }}>
                  <CitySelect
                    selected={getFieldValue('city')}
                    onSelect={(city: any) => {
                      setFieldValue('city', city || '');
                      setFieldValue('city_id', city?.id || '');
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>

            <Form.Item noStyle shouldUpdate>
              {() => (
                <Space>
                  <Form.Item label="DG" name="is_dg" valuePropName="checked">
                    <Checkbox />
                  </Form.Item>
                  <Form.Item label="SOC" name="is_soc" valuePropName="checked">
                    <Checkbox />
                  </Form.Item>
                  <Form.Item
                    label="OW"
                    name="is_overweight"
                    valuePropName="checked">
                    <Checkbox />
                  </Form.Item>
                  <Form.Item
                    label="REEFER"
                    name="is_reefer"
                    valuePropName="checked">
                    <Checkbox />
                  </Form.Item>
                </Space>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Sell Rate ID"
              name="rate_id"
              rules={[
                { required: false, message: 'Sell Rate ID is required' },
              ]}>
              <Input placeholder="Enter sell rate id" />
            </Form.Item>
            <Form.Item label="Customer Memo" name="customer_memo" shouldUpdate>
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
          <Col md={4}>
            <Space direction="horizontal">
              <Button type="primary" htmlType="submit" block>
                Save
              </Button>
              <Button block onClick={() => onClose()}>
                Cancel
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </>
  );
};
