import React from 'react';

export const MBLLink: React.FC<{
  mblNumber: string;
}> = ({ mblNumber }) => {
  const copyMblNumber = () => {
    navigator.clipboard.writeText(mblNumber);
  };

  return (
    <a
      onClick={copyMblNumber}
      href={`${BASE_URL}/ssl/${mblNumber}/redirect`}
      target="_blank"
      rel="noreferrer"
    >
      {mblNumber}
    </a>
  );
};
