import { CACHE_ADMIN_KEY } from '@/stores/cache';
import { useApp } from '@/utils/useapp';
import { MentionsInput, Mention } from 'react-mentions';
import { style } from './data';

export const EditMentionsInput = ({
  disabled = false,
  placeholder = 'Mention any admin by typing `@` followed by at least one char',
  comment,
  setComment,
}: {
  disabled?: boolean;
  placeholder?: string;
  comment: any;
  setComment: (comment: any) => void;
}) => {
  const app = useApp();

  const admins = app.store.cache.data[CACHE_ADMIN_KEY];

  if (admins === undefined) {
    app.store.cache.fetch(CACHE_ADMIN_KEY);
  }

  return (
    <MentionsInput
      defaultValue={''}
      disabled={disabled}
      value={comment}
      onChange={(e) => setComment(e.target.value || '')}
      placeholder={placeholder}
      required
      style={style}>
      <Mention
        displayTransform={(id, display) => `@${display}`}
        trigger="@"
        data={(admins || []).map((admin) => ({
          id: admin.id,
          display: admin.name,
        }))}
        style={{ backgroundColor: '#1dcae02e', color: '#024d65' }}
      />
    </MentionsInput>
  );
};
