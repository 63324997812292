import styles from 'res/css/ui.scss';
import { Button, Col, Form, Input, Row } from 'antd';
import React from 'react';
import { handleEnterKeyDown } from '@/utils/handleEnterKeyDown';

export type TFilter = {
  loading: boolean;
  filter: any;
  onSearch: () => void;
  onChange: (values: any) => void;
};

export const Filter: React.FC<TFilter> = ({
  loading,
  filter,
  onSearch,
  onChange,
}) => {
  const handleSearch = () => {
    onSearch();
  };

  const handleFilterChange = (key: string, value: any) => {
    onChange({ ...filter, [key]: value });
  };

  const handleReset = () => {
    onChange({});
  };

  return (
    <div className={styles.filter}>
      <div
        style={{
          width: '60%',
        }}>
        <Form onKeyDown={handleEnterKeyDown(() => handleSearch())}>
          <Row gutter={10}>
            <Col span={10}>
              <Form.Item
                className="mb0"
                labelCol={{ span: 24 }}
                label="Code"
                wrapperCol={{ span: 24 }}>
                <Input
                  placeholder="Code"
                  value={filter.code || ''}
                  onChange={(e) => handleFilterChange('code', e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                className="mb0"
                labelCol={{ span: 24 }}
                label="Name"
                wrapperCol={{ span: 24 }}>
                <Input
                  placeholder="Name"
                  value={filter.name || ''}
                  onChange={(e) => handleFilterChange('name', e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <div>
        <Row gutter={10}>
          <Col span={12}>
            <Button type="primary" disabled={loading} onClick={handleSearch}>
              Search
            </Button>
          </Col>
          <Col span={12}>
            <Button onClick={handleReset}>Clear All</Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};
