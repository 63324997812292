import { Col, Form, Input, Row, Select } from 'antd';
import React, { useEffect } from 'react';

export const DrawerForm: React.FC<any> = ({ form }) => {
  return (
    <div>
      <Form form={form}>
        <Row gutter={10}>
          <Col span={24}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input placeholder="Enter Name" />
            </Form.Item>
            <Form.Item
              label="Type"
              name="type"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}>
              <Input placeholder="Enter Type" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
