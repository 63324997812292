import { Button, Checkbox, Dropdown, Menu, Space } from 'antd';
import { useMemo, useState } from 'react';
import type { MenuProps } from 'antd';

type TItem = {
  name: string;
  key: string;
};

export const ManageColumns: React.FC<{
  items: TItem[];
  checkedColumns: string[];
  onChange: (list: string[]) => void;
}> = ({ items, checkedColumns, onChange }) => {
  const [visible, setVisible] = useState(false);

  const handleCheck = (e: any) => {
    const { name, checked } = e.target;
    if (checked) {
      onChange([...checkedColumns, name]);
    } else {
      onChange(checkedColumns.filter((item) => item !== name));
    }
  };

  const menu: MenuProps['items'] = items.map((item) => {
    return {
      label: (
        <Space key={item.key}>
          <Checkbox
            name={item.key}
            checked={checkedColumns.includes(item.key)}
            onChange={handleCheck}
          />
          <div>{item.name}</div>
        </Space>
      ),
      key: item.key,
    };
  });

  return (
    <div>
      <Dropdown
        open={visible}
        onOpenChange={() => setVisible(!visible)}
        menu={{ items: menu }}
        trigger={['click']}>
        <Button type="primary">Manage Columns</Button>
      </Dropdown>
    </div>
  );
};
