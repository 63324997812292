import { Table } from 'antd';
import { FC } from 'react';
import { ICashFlow } from '.';

const CashFlow: FC<{
  cashFlow: ICashFlow[];
  loading: boolean;
}> = ({ cashFlow, loading }) => {
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const columns = [
    {
      title: 'Stage',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Cash Flow Impact',
      dataIndex: 'total_profit',
      key: 'total_profit',
      render: (text: any) => {
        return text ? USDollar.format(text) : '-';
      },
    },
    {
      title: 'Avg. Aging',
      dataIndex: 'avg_aging',
      key: 'avg_aging',
      render: (text: any) => {
        return text ? text.toFixed(2) : '-';
      },
    },
    {
      title: 'Container Count',
      dataIndex: 'container_count',
      key: 'container_count',
    },
  ];

  return (
    <>
      <h2>Summary</h2>
      <Table
        size="small"
        loading={loading}
        columns={columns}
        dataSource={cashFlow}
        pagination={false}
      />
    </>
  );
};

export default CashFlow;
