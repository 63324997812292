import React from 'react';
import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Col,
  Tooltip,
  Select,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Space,
  Tag,
  TablePaginationConfig,
} from 'antd';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { VendorFormW9Drawer } from './components/VendorFormW9Drawer';
import { useForm } from 'antd/lib/form/Form';
import { TCollection } from '@/types';
import usePagination from '@/components/usePagination';
import AutoResizeTable from '@/components/AutoResizeTable';
import { get, truncate } from 'lodash';
import { FEDERAL_TAX_CLASSIFICATION_MAP } from './components/data';
import { RenderFormTypeTag } from './components/RenderFormTypeTag';

type TChassisType = {
  id: number;
  name: string;
  type: string;
  cntr_size: Array<number>;
};
const Index = () => {
  const app = useApp();

  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [data, setData] = React.useState<TCollection<TChassisType>>();
  const [wNineId, setWNineId] = React.useState(0);
  const [filter] = useForm();

  const pagination = usePagination(data);

  const fetchData = React.useCallback(
    async (pagination?: TablePaginationConfig) => {
      setLoading(true);

      try {
        const result = await app.service.get('vendorFormW9s', {
          params: {
            ...filter.getFieldsValue(),
            page: pagination?.current || 1,
            per_page: pagination?.pageSize || 20,
          },
        });

        setData(result);
      } catch (e: any) {
        message.error(e.error || 'System Error');
      }

      setLoading(false);
    },
    [],
  );

  const addNew = () => {
    setVisible(true);
    setWNineId(0);
  };

  const handleSaved = async (data: any) => {
    fetchData();
    handleClose();
  };

  const handleClose = () => {
    setVisible(false);
    setWNineId(0);
  };
  const handleRemove = async (id: number) => {
    setLoading(true);

    try {
      await app.service.delete(`vendorFormW9s/${id}`);

      message.success('Deleted');

      fetchData();
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  const handleEdit = async (record: any) => {
    setVisible(true);
    setWNineId(record.id);
  };

  const columns: ColumnsType<any> = React.useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 80,
        render: (text, record, index) => <>{record.id}</>,
      },
      {
        title: '1. Name',
        dataIndex: 'name',
        key: 'name',
        width: 200,
        render: (text, record) => (
          <Space>
            <RenderFormTypeTag formType={record.form_type} />
            <span>{text}</span>
          </Space>
        ),
      },
      {
        title: 'Business Name',
        dataIndex: 'business_name_or_disregarded_entity_name',
        key: 'business_name_or_disregarded_entity_name',
        width: 150,
        render: (text, record, index) => (
          <>
            <Tooltip title={text}>{truncate(text, { length: 30 })} </Tooltip>
          </>
        ),
      },
      {
        title: 'Tax Classif',
        dataIndex: 'federal_tax',
        key: 'federal_tax',
        width: 200,
        render: (text, record, index) => (
          <>
            <Tooltip
              title={
                FEDERAL_TAX_CLASSIFICATION_MAP[
                  text as unknown as keyof typeof FEDERAL_TAX_CLASSIFICATION_MAP
                ]
              }>
              {truncate(
                FEDERAL_TAX_CLASSIFICATION_MAP[
                  text as unknown as keyof typeof FEDERAL_TAX_CLASSIFICATION_MAP
                ],
                { length: 30 },
              )}{' '}
            </Tooltip>
            {get(record, 'tax_classification') && (
              <div>{record.tax_classification}</div>
            )}
          </>
        ),
      },
      // {
      //   title: 'Exempt payee code',
      //   dataIndex: 'exempt_payee_code',
      //   key: 'exempt_payee_code',
      //   width: 200,
      //   render: (text, record, index) => (
      //     <>
      //       <Tooltip title={text}>{truncate(text, { length: 30 })} </Tooltip>
      //     </>
      //   ),
      // },
      // {
      //   title: 'Exemption from FATCA reporting code',
      //   dataIndex: 'exemption_from_fatca_reporting_code',
      //   key: 'exemption_from_fatca_reporting_code',
      //   width: 200,

      //   render: (text, record, index) => (
      //     <>
      //       <Tooltip title={text}>{truncate(text, { length: 30 })} </Tooltip>
      //     </>
      //   ),
      // },
      {
        title: '5. Address',
        dataIndex: 'address',
        key: 'address',
        width: 150,

        render: (text, record, index) => (
          <>
            <Tooltip title={text}>{truncate(text, { length: 30 })} </Tooltip>
          </>
        ),
      },
      {
        title: '6. Location',
        dataIndex: 'city_state_zipcode',
        key: 'city_state_zipcode',
        width: 150,
        render: (text, record, index) => (
          <>
            <Tooltip title={text}>{truncate(text, { length: 30 })} </Tooltip>
          </>
        ),
      },
      // {
      //   title: '7. List account number(s)',
      //   dataIndex: 'list_account_number',
      //   key: 'list_account_number',
      //   width: 150,

      //   render: (text, record, index) => (
      //     <>
      //       <Tooltip title={text}>{truncate(text, { length: 30 })} </Tooltip>
      //     </>
      //   ),
      // },
      // {
      //   title: 'Requester’s name and address',
      //   dataIndex: 'requester_name_and_address',
      //   key: 'requester_name_and_address',
      //   width: 150,
      //   render: (text, record, index) => (
      //     <>
      //       <Tooltip title={text}>{truncate(text, { length: 30 })} </Tooltip>
      //     </>
      //   ),
      // },
      // {
      //   title: 'Social security number',
      //   dataIndex: 'social_security_number',
      //   key: 'social_security_number',
      //   width: 150,

      //   render: (text, record, index) => (
      //     <>
      //       <Tooltip title={text}>{truncate(text, { length: 30 })} </Tooltip>
      //     </>
      //   ),
      // },
      {
        title: 'TIN',
        dataIndex: 'employer_identification_number',
        key: 'employer_identification_number',
        width: 200,
        render: (text, record, index) => (
          <>
            <div>{record.social_security_number}</div>
            <div>{record.employer_identification_number}</div>
          </>
        ),
      },
      {
        title: 'MC#',
        dataIndex: 'mc_number',
        key: 'mc_number',
        width: 150,
      },
      {
        title: 'Agent or Headquarter',
        dataIndex: 'agent_or_headquarter',
        key: 'agent_or_headquarter',
        width: 150,
      },
      {
        align: 'center',
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 150,
        render: (text, record) => (
          <Space>
            <a onClick={() => handleEdit(record)}>Edit</a>
            <Popconfirm
              placement="left"
              title="Sure to delete?"
              okText="Confirm"
              cancelText="Cancel"
              onConfirm={() => handleRemove(record.id)}>
              <a>Delete</a>
            </Popconfirm>
          </Space>
        ),
      },
    ],
    [],
  );

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.main}>
      <Header
        title="Vendor Form W9"
        rightElement={
          <div>
            <Button type="primary" onClick={addNew}>
              New Vendor Form W9
            </Button>
          </div>
        }></Header>

      <div className={'p-md'}>
        <Form layout="vertical" form={filter} onFinish={fetchData}>
          <Row gutter={10}>
            <Col span={4}>
              <Form.Item name="name" label="Name">
                <Input />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="mc_number" label="MC#">
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Tax Classif" name="federal_tax">
                <Select allowClear>
                  {Object.keys(FEDERAL_TAX_CLASSIFICATION_MAP).map((key) => (
                    <Select.Option key={key} value={+key}>
                      {
                        FEDERAL_TAX_CLASSIFICATION_MAP[
                          key as unknown as keyof typeof FEDERAL_TAX_CLASSIFICATION_MAP
                        ]
                      }
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label={' '}>
                <Space>
                  <Button htmlType="submit" type="primary">
                    Search
                  </Button>
                  <Button onClick={() => filter.resetFields()}>
                    Clear All
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <AutoResizeTable
        loading={loading}
        pagination={pagination}
        size="small"
        rowKey="id"
        columns={columns}
        onChange={fetchData}
        dataSource={data?.data || []}
        sticky
        scroll={{
          x: 'auto',
        }}
      />

      <VendorFormW9Drawer
        visible={visible}
        id={wNineId}
        onClose={handleClose}
        onSaved={handleSaved}
      />
    </div>
  );
};

export default Index;
