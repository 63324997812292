import { useApp } from '@/utils/useapp';
import styles from 'res/css/ui.scss';
import {
  Button,
  Col,
  Divider,
  Drawer,
  Input,
  message,
  Popconfirm,
  Row,
  Space,
  Tag,
} from 'antd';
import React, { useMemo, useState } from 'react';
import { Header } from '@/components/CommonHeader';
import type { ColumnsType } from 'antd/lib/table';
import { get } from 'lodash';
import { DrawerForm } from './components/DawerForm';
import { useForm } from 'antd/lib/form/Form';
import AutoResizeTable from '@/components/AutoResizeTable';

const Index = () => {
  const app = useApp();

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [search, setSearch] = useState<string>('');
  const [googleSheets, setGoogleSheets] = useState<any>(null);
  const [form] = useForm();
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    setGoogleSheets(null);
    form.resetFields();
  };

  const handleNewVendor = () => {
    form.setFieldsValue({
      possible_charges: [],
    });
    showDrawer();
  };

  const fetchData = async () => {
    setLoading(true);

    try {
      const result = await app.service.get('googleSheets');
      setData(result.data);
    } catch (err: any) {
      message.error(err.data?.message || err.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const isNew = useMemo(() => {
    return !googleSheets;
  }, [googleSheets]);

  const handleSave = async () => {
    const data = await form.validateFields();

    setLoading(true);
    const formValue = Object.assign({}, data);

    formValue.vendor_id = get(formValue, 'vendor.id', 0);
    delete formValue.vendor;
    const request = isNew
      ? app.service.post('googleSheets', { data: formValue })
      : app.service.put(`googleSheets/${googleSheets}`, {
        data: formValue,
      });

    request
      .then(() => {
        fetchData();
        onClose();
        setGoogleSheets(null);
        form.resetFields();
      })
      .catch((err) => {
        message.error(err.data?.message || err.data?.error);
        setLoading(false);
      });
  };

  const handleRemove = (id: number) => {
    setLoading(true);
    app.service
      .delete(`googleSheets/${id}`)
      .then(() => {
        fetchData();
      })
      .catch((err) => {
        message.error(err.data?.message || err.data?.error);
        setLoading(false);
      });
  };

  const handleEdit = (id: number) => {
    const item = data.find((item) => item.id === id);
    if (!item) {
      return;
    }
    setVisible(true);
    setGoogleSheets(id);
    form.setFieldsValue({ ...item });
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      width: 80,
      render: (text, record) => <>{record.id}</>,
    },
    {
      title: 'Name',
      sorter: (a, b) => (a.name > b.name ? 1 : 0),
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor_id',
      key: 'vendor_id',
      width: 200,
      render: (value, record) => {
        return <>{record.vendor?.name || ''}</>;
      },
    },
    {
      title: 'Sheet ID',
      dataIndex: 'sheet_id',
      key: 'sheet_id',
      width: 200,
    },
    {
      title: 'Tab Name',
      dataIndex: 'tab',
      key: 'tab',
      width: 200,
    },
    {
      title: 'Enabled',
      dataIndex: 'enabled',
      key: 'enabled',
      width: 200,
      render: (text) => {
        return text ? 'Yes' : 'No';
      },
    },
    {
      align: 'center',
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 150,
      render: (text, record) => (
        <span>
          <a onClick={() => handleEdit(record.id)}>Edit</a>
          <Divider type="vertical" />
          <Popconfirm
            title="Sure to delete?"
            okText="Confirm"
            cancelText="Cancel"
            onConfirm={() => handleRemove(record.id)}>
            <a>Delete</a>
          </Popconfirm>
        </span>
      ),
    },
  ];

  const display = useMemo(() => {
    return [...data].filter((i) =>
      i.name.toLowerCase().includes(search.toLowerCase()),
    );
  }, [search, data]);

  return (
    <div className={styles.main}>
      <Header
        title="GoogleSheets"
        rightElement={
          <div>
            <Button type="primary" onClick={handleNewVendor}>
              New GoogleSheet
            </Button>
          </div>
        }></Header>

      <div className={styles.filter}>
        <div
          style={{
            width: '60%',
          }}>
          <Row>
            <Col>
              <Input
                allowClear
                placeholder="Search by Name"
                onChange={(e) => setSearch(e.target.value)}
              />
            </Col>
          </Row>
        </div>
      </div>
      <div>
        <AutoResizeTable
          pagination={false}
          loading={loading}
          size="small"
          rowKey="id"
          columns={columns}
          dataSource={display}
          scroll={{ x: 1500 }}
          sticky
        />
      </div>
      <Drawer
        title=""
        placement={'right'}
        width={'60%'}
        onClose={onClose}
        destroyOnClose={true}
        open={visible}
        extra={
          <Space>
            <Button onClick={onClose}>Close</Button>
            <Button type="primary" loading={loading} onClick={handleSave}>
              Save
            </Button>
          </Space>
        }>
        <DrawerForm form={form} />
      </Drawer>
    </div>
  );
};

export default Index;
